/* eslint-disable rxjs/no-implicit-any-catch */
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { Observable, throwError, combineLatest, of } from 'rxjs';
import { map, catchError, switchMap, first, tap } from 'rxjs/operators';

import { ConfigService } from '@fcom/core';
import { loginToken, profile } from '@fcom/core/selectors';
import { Profile } from '@fcom/core-api/login';
import { FinnairOrderPreview, FinnairOrderPreviewsResponse } from '@fcom/dapi/api/models';
import { finShare } from '@fcom/rx';
import { OrderPreviewsService } from '@fcom/order';

import { Trip, AddFrequentFlyerResponse, Journey } from '../interfaces/pax-trip.interface';
import { TripsActions } from '../store/actions';
import { FetchTripsStatus, State } from '../interfaces/store.interface';

@Injectable()
export class PaxTripService {
  private accessToken$: Observable<string>;
  private profile$: Observable<Profile>;

  constructor(
    private http: HttpClient,
    private orderPreviewsService: OrderPreviewsService,
    private store$: Store<State>,
    private configService: ConfigService
  ) {
    this.accessToken$ = this.store$.pipe(loginToken(), first(Boolean)) as Observable<string>;
    this.profile$ = this.store$.pipe(profile(), first(Boolean)) as Observable<Profile>;
  }

  getTrips(): Observable<Trip[]> {
    return this.orderPreviewsService.getUpcomingTrips().pipe(
      map((response: FinnairOrderPreviewsResponse) =>
        this.mapFetchOrderPreviewsResponse(response.orderPreviews, response.locations)
      ),
      finShare()
    );
  }

  addTrip(bookingReference: string): Observable<AddFrequentFlyerResponse> {
    return combineLatest([this.accessToken$, this.profile$]).pipe(
      switchMap(([token, memberProfile]: [string, Profile]) => {
        const apiUrl = `${this.configService.cfg.bookingServiceUrl}/booking/${bookingReference}/${memberProfile.lastname}/addFrequentFlyer`;
        const keyId = this.configService.cfg.bookingServiceApiKey;

        const httpOptions = {
          headers: new HttpHeaders({
            // eslint-disable-next-line camelcase
            oauth_token: token,
            'X-Api-Key': keyId,
            'Content-Type': 'application/json',
          }),
        };

        const params = {
          firstName: memberProfile.firstname,
          frequentFlyerNumber: memberProfile.memberNumber,
        };
        return this.http.post<AddFrequentFlyerResponse>(apiUrl, params, httpOptions);
      }),
      map((response) => {
        if (response.status.message === 'OK') {
          return response;
        }
        throw new Error('Error when adding booking');
      }),
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  verifyAddedTrip(bookingReference: string): Observable<boolean> {
    return this.getTrips().pipe(
      tap((trips: Trip[]) => {
        this.store$.dispatch(TripsActions.setTrips({ trips: trips, status: FetchTripsStatus.OK }));
      }),
      map((trips: Trip[]) => trips.some((trip) => trip.recLoc === bookingReference)),
      catchError(() => {
        this.store$.dispatch(TripsActions.setTrips({ trips: [], status: FetchTripsStatus.ERROR }));
        return of(false);
      })
    );
  }

  private mapFetchOrderPreviewsResponse(
    orderPreviews: FinnairOrderPreview[],
    locations: FinnairOrderPreviewsResponse['locations']
  ): Trip[] {
    return (
      orderPreviews?.map((orderPreview) => {
        return {
          passengers: [
            {
              lastName: orderPreview.lastName,
            },
          ],
          journeys: this.mapBoundPreviewsToJourneys(orderPreview, locations),
          lastName: orderPreview.lastName,
          recLoc: orderPreview.id,
        };
      }) || []
    );
  }

  private mapBoundPreviewsToJourneys(
    orderPreview: FinnairOrderPreview,
    locations: FinnairOrderPreviewsResponse['locations']
  ): Journey[] {
    return (
      orderPreview.bounds?.map((boundPreview) => {
        const departureLocation = locations[boundPreview.departure.locationCode];
        const arrivalLocation = locations[boundPreview.arrival.locationCode];
        return {
          passengerAmount: orderPreview.numberOfTravelers,
          departure: {
            airport: boundPreview.departure.locationCode,
            airportName: departureLocation.name,
            municipality: departureLocation.cityName,
            scheduledDateTime: boundPreview.departure.dateTime,
          },
          arrival: {
            airport: boundPreview.arrival.locationCode,
            airportName: arrivalLocation.name,
            municipality: arrivalLocation.cityName,
            scheduledDateTime: boundPreview.arrival.dateTime,
          },
          recLoc: orderPreview.id,
        };
      }) || []
    );
  }
}
