import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CmsTarget } from '@fcom/core-api';

@Component({
  selector: 'fin-cta-big-link',
  styleUrls: ['./cta-big-link.component.scss'],
  templateUrl: './cta-big-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CtaBigLinkComponent implements OnInit {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  teaserTitle$: Observable<string>;
  @Input()
  target$: Observable<CmsTarget>;
  @Input()
  teaserText$: Observable<string>;
  @Input()
  layout: 'small' | 'xsmall' | undefined;

  ctaButton$: Observable<object>;

  ngOnInit(): void {
    this.ctaButton$ = this.target$.pipe(
      filter((target) => !!target),
      map((target) => Object.assign({}, target))
    );
  }
}
