import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { LocationRouteCffService, SentryLogger } from '@fcom/core/services';
import { Location, LocationsMap } from '@fcom/core-api/interfaces';
import { finShare } from '@fcom/rx';
import { unsubscribe } from '@fcom/core/utils';

import { LocationAppState } from '../interfaces';
import { getLocation, getLocations } from '../store/selectors';
import { LocationActions } from '../store/actions';

@Injectable({
  providedIn: 'root',
})
export class LocationsService implements OnDestroy {
  private subscriptions = new Subscription();

  constructor(
    private store$: Store<LocationAppState>,
    private locationRouteCffService: LocationRouteCffService,
    private languageService: LanguageService,
    private sentryLogger: SentryLogger
  ) {}

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  triggerLocationFetch(locationCode: string): void {
    this.subscriptions.add(
      this.languageService.locale
        .pipe(
          switchMap((locale) => this.locationRouteCffService.bestGuessFor(locationCode, locale)),
          takeUntil(this.locationAlreadyInStore(locationCode))
        )
        .subscribe({
          next: (l) => this.saveLocation(l),
          error: (error: unknown) => this.sentryLogger.error('Error fetching location info', { error, locationCode }),
        })
    );
  }

  /**
   * Accepts an array of location codes and waits for all locations to finish loading before the
   * returned observable will complete.
   */
  triggerLocationFetchAll(locationCodes: string[]): Observable<{ [locationCode: string]: Location }> {
    return combineLatest(
      locationCodes.map((locationCode) => {
        this.triggerLocationFetch(locationCode);

        return this.retrieveLocation(locationCode);
      })
    ).pipe(
      filter((locations) => {
        return locations.every(Boolean) && locations.length === locationCodes.length;
      }),
      take(1),
      map((locations) => {
        return locations.reduce((acc, location) => {
          acc[location.locationCode] = location;

          return acc;
        }, {});
      })
    );
  }

  retrieveLocation(key: string): Observable<Location> {
    return this.store$.select(getLocation(key));
  }

  retrieveAllLocations(): Observable<LocationsMap> {
    return this.store$.select(getLocations());
  }

  saveLocation(destination: Location, oldDestination?: Location): void {
    if (oldDestination) {
      this.store$.dispatch(LocationActions.removeLocationData({ location: oldDestination }));
    }
    this.store$.dispatch(LocationActions.storeLocationData({ location: destination }));
  }

  private locationAlreadyInStore(locationCode: string): Observable<Location> {
    return this.retrieveLocation(locationCode).pipe(finShare(), filter<Location>(Boolean), take(1));
  }
}
