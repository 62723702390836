@if (usePopoverSelectors) {
  <button
    class="underline medium-type link-hover font-body-1 align-left"
    data-testid="discount-code-button"
    fcomPopover
    [options]="popoverOptions"
    [content]="popoverContent"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
} @else {
  <button
    class="underline medium-type link-hover font-body-1 align-left"
    data-testid="discount-code-button"
    (click)="openModal$.next()"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
}

<ng-template #buttonContent>
  {{
    (discountCode$ | async)
      ? ('promoCode.buttonWithCodeV2' | finLocalization: { code: (discountCode$ | async) })
      : ('promoCode.emptyButton' | finLocalization)
  }}
</ng-template>

<ng-template #popoverContent>
  <div class="scroll rounded-large">
    <header class="popover-header fill relative flex--column ps-medium-y ps-medium-x border-bottom">
      <div class="flex--nogrow flex--basis-header flex flex--row flex--center">
        <h2
          class="font-heading-4 flex-1 truncate overflow-hidden nordic-blue-900-text"
          [attr.id]="'popover-' + popoverOptions.popoverID + '-title'"
        >
          {{ 'promoCode.modalTitle' | finLocalization }}
        </h2>
        <button
          class="close-button flex--nogrow flex--self-stretch btn--no-outline focus-inset"
          [attr.title]="'actions.close' | finLocalization"
          [attr.aria-label]="'actions.close' | finLocalization"
          (click)="closePopover()"
        >
          <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.CLOSE_DELETE"></fcom-icon>
        </button>
      </div>
    </header>
    <ng-container *ngTemplateOutlet="discountCode; context: { popover: true }"></ng-container>
    <ng-container *ngTemplateOutlet="buttons; context: { popover: true }"></ng-container>
  </div>
</ng-template>

<fcom-modal
  *ngIf="modalOpen"
  class="nordic-blue-900-text"
  [(open)]="modalOpen"
  [title]="'promoCode.modalTitle' | finLocalization"
  [fitToContent]="true"
  [buttons]="ModalButtons.CUSTOM"
  data-testid="discount-code-modal"
>
  <ng-container *ngTemplateOutlet="discountCode; context: { popover: false }"></ng-container>
  <ng-container buttons *ngTemplateOutlet="buttons; context: { popover: false }"></ng-container>
</fcom-modal>

<ng-template #discountCode let-popover="popover">
  <div
    [ngClass]="{
      'ps-medium': popover,
      'ps-large': !popover,
    }"
  >
    <div class="pr-xsmall-b" data-testid="discount-code-form">
      <fcom-text-input
        [id]="'discount-code-input'"
        [parentForm]="discountCodeForm"
        [translationPrefix]="'bookingWidget.promoCode.inputLabel'"
        [controlName]="'discountCode'"
        [required]="false"
        [displayMargin]="false"
        [placeHolder]="'bookingWidget.promoCode.placeholder'"
        (keyup.enter)="checkDiscountCode()"
        finFocus
        [autofocus]="true"
      ></fcom-text-input>
    </div>

    <p
      data-testid="discount-code-login-warning"
      *ngIf="!(discountCodeRules$ | async)?.found && !discountCodeForm.controls.discountCode.hasError('custom')"
    >
      {{ 'promoCode.loginWarning' | finLocalization }}
    </p>
    <p data-testid="discount-code-generic-error" *ngIf="discountCodeForm.controls.discountCode.hasError('custom')">
      {{ 'promoCode.genericError' | finLocalization }}
    </p>

    @if ((discountCodeRules$ | async)?.found) {
      <div class="discount-code-rules">
        <div class="nordic-blue-900-text mr-xsmall-b font-body-1">{{ 'promoCode.termsTitle' | finLocalization }}</div>
        <div class="nordic-blue-900-text font-body-1">{{ (discountCodeRules$ | async).title }}</div>
        <div
          [ngClass]="{
            'mr-small-l': !popover,
          }"
          [innerHTML]="(discountCodeRules$ | async).terms | resolveLinks | finSafe: 'html'"
        ></div>
      </div>
    } @else {
      <fcom-link
        data-testid="discount-code-general-info"
        class="external-link bold-type"
        [href]="'promoCode.termsLink.url' | finLocalization"
        [external]="true"
        [openInNewTab]="true"
        [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.EXTERNAL_TAB, position: IconPosition.RIGHT }"
        >{{ 'promoCode.promoLinkText' | finLocalization }}</fcom-link
      >
    }
  </div>
</ng-template>

<ng-template #buttons let-popover="popover">
  <div
    [ngClass]="{
      'ps-medium-y ps-medium-x flex flex--end': popover,
    }"
  >
    @if ((discountCodeRules$ | async)?.found) {
      <fcom-button
        data-testid="discount-code-remove"
        class="mr-xsmall-r"
        [loading]="loading$ | async"
        [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
        [disabled]="false"
        [text]="'promoCode.removeCodeButton' | finLocalization"
        [aria]="{ label: 'promoCode.removeCodeButton' | finLocalization, role: 'button' }"
        (clickBtn)="removeCode()"
      ></fcom-button>
      <fcom-button
        data-testid="discount-code-continue"
        [loading]="loading$ | async"
        [disabled]="false"
        [text]="'actions.continue' | finLocalization"
        [aria]="{ label: 'actions.continue' | finLocalization, role: 'button' }"
        (clickBtn)="continue()"
      ></fcom-button>
    } @else {
      <fcom-button
        data-testid="discount-code-check"
        [ngClass]="{
          'mr-xsmall-r': !popover,
        }"
        [loading]="loading$ | async"
        [disabled]="
          discountCodeForm.controls.discountCode.value === null ||
          discountCodeForm.controls.discountCode.value === '' ||
          !discountCodeForm.controls.discountCode.valid
        "
        [text]="'promoCode.checkCodeButton' | finLocalization"
        [aria]="{ label: 'promoCode.checkCodeButton' | finLocalization, role: 'button' }"
        (clickBtn)="checkDiscountCode()"
      ></fcom-button>
    }
  </div>
</ng-template>
