<div class="nordic-blue-900-text inline-block">
  <h2 class="editorial-category-title mr-small-b">{{ editorialCategory.teaserTitle }}</h2>
  <div *ngFor="let q of editorialCategory.items" class="editorial-category-links font-body-1 light-type">
    <div *ngIf="!isShowAllLink(q)" class="mr-small-y">
      <a [finLink]="q.url" class="no-decoration nordic-blue-900-text">{{ q.teaserTitle }}</a>
    </div>
    <div *ngIf="isShowAllLink(q)" class="font-body-1 bold-type mr-small-t mr-large-b">
      <a [finLink]="q.url" class="no-decoration nordic-blue-900-text"
        >{{ q.teaserTitle }}
        <fcom-icon
          class="editorial-category-link-icon nordic-blue-900-text icon-small"
          [name]="SvgLibraryIcon.ARROW_RIGHT"
        ></fcom-icon
      ></a>
    </div>
  </div>
</div>
