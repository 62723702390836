export enum RootPaths {
  BOOKING_ROOT = 'booking',
  CHECK_IN_ROOT = 'check-in',
  SITE_SEARCH_ROOT = 'search',
  SELECT_LANGUAGE_ROOT = 'select-language',
  PLUS_ROOT = 'my-finnair-plus',
  OND_MARKETING_LANDING_ROOT = 'flights',
  FINNAIR_HOLIDAYS = 'aurinkomatkat',
  MANAGE_BOOKING_ROOT = 'manage',
  DISRUPTION_ROOT = 'manage/disruption',
  CANCEL_ROOT = 'manage/cancel',
  INVOLUNTARY_CANCEL_ROOT = 'manage/disruption/cancel',
  REACCOMMODATION_ROOT = 'manage/disruption-rebooking',
  ONE_UPGRADE_ROOT = 'manage/upgrade',
  VOLUNTARY_CHANGE_ROOT = 'manage/flight-change',
  TIMETABLE_ROOT = 'timetables',
  REFUNDS = 'refunds',
  CORPORATE = 'corporate',
  TRAVEL_READY = 'travel-ready',
}

export const ROUTER_LANG_PARAM = 'lang';

export const UTM_PARAMS = [
  'utm_source',
  'utm_campaign',
  'utm_medium',
  'utm_term',
  'utm_content',
  'utm_id',
  'gclid',
  'gbraid',
  'wbraid',
  'gclsrc',
  'ds_aid',
  'ds_e_ca_id',
  'ds_e_ag_id',
  'ds_e_ad_id',
  'ds_e_ca_name',
  'ds_e_ag_name',
  'ds_e_ad_name',
  'ds_sig',
  'ds_dest_url',
  'fbclid',
  'ad_id',
  'adset_id',
  'campaign_id',
  'ad_name',
  'adset_name',
  'campaign_name',
  'placement',
  'site_source_name',
  'dclid',
  'gad_source',
];

export enum CampaignTagToLabelKey {
  'fcomCampaignSpecial' = 'campaignPrice',
  'fcomCampaignFlash' = 'flashSaleOffer',
  'fcomCampaignBlackFriday' = 'blackFridayOffer',
  'fcomCampaignFinnairPlus' = 'finnairPlusOffer',
}

export const FINNISH_SITES = ['fi-fi', 'fi-sv', 'fi-en'];

export const NON_EUROPE_SITES = [
  'us-en',
  'ca-en',
  'ca-fr',
  'sg-en',
  'th-en',
  'in-en',
  'kr-en',
  'kr-ko',
  'cn-en',
  'cn-zh',
  'hk-en',
  'hk-zh',
  'jp-en',
  'jp-ja',
  'au-en',
];
