import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { UiTranslateModule } from '@fcom/ui-translate';
import {
  ButtonModule,
  FcomFormsModule,
  IconsModule,
  LoaderModule,
  NotificationsModule,
  UiComponentsModule,
} from '@fcom/ui-components';

import { ComponentsModule } from '../components/components.module';
import { NavigationTriggerModule } from '../components/header/navigation-trigger.module';
import { GtmModule } from '../gtm';
import { PipesModule } from '../pipes';
import {
  LoginButtonComponent,
  LoginFormComponent,
  LoginIframeComponent,
  LoginWidgetComponent,
  ProfileQuickViewComponent,
  LoginCardComponent,
  JoinFormComponent,
  LoginFormContainerComponent,
} from './components';
import { DirectiveModule } from '../directives';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { FinFormsModule } from '../forms';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    GtmModule,
    PipesModule,
    NavigationTriggerModule,
    UiTranslateModule,
    DirectiveModule,
    IconsModule,
    ButtonModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    FcomFormsModule,
    LoaderModule,
    NotificationsModule,
    FinFormsModule,
    UiComponentsModule,
  ],
  declarations: [
    LoginIframeComponent,
    LoginButtonComponent,
    ProfileQuickViewComponent,
    LoginWidgetComponent,
    LoginFormContainerComponent,
    LoginCardComponent,
    LoginFormComponent,
    JoinFormComponent,
    LoginDialogComponent,
  ],
  exports: [
    LoginIframeComponent,
    LoginButtonComponent,
    ProfileQuickViewComponent,
    LoginWidgetComponent,
    LoginFormContainerComponent,
    LoginCardComponent,
    LoginFormComponent,
    JoinFormComponent,
    LoginDialogComponent,
  ],
})
export class LoginModule {}
