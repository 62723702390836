import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { CmsDestination } from '@fcom/core-api';
import { OndMarketingOffersService } from '@fcom/ond-marketing-landing-core/services';

@Component({
  selector: 'cms-ond-marketing-offers-list',
  template: `
    <fin-ond-marketing-offers-list [destinationLocationCode$]="destinationLocationCode$">
    </fin-ond-marketing-offers-list>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsOndMarketingOffersListComponent implements OnInit {
  @Input()
  content$: Observable<CmsDestination>;

  destinationLocationCode$: Observable<string>;
  constructor(private ondMarketingOffersService: OndMarketingOffersService) {}

  ngOnInit(): void {
    this.destinationLocationCode$ = this.ondMarketingOffersService.getDestinationLocationCode(this.content$);
  }
}
