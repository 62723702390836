import { Component, OnInit } from '@angular/core';

import { ConfigService } from '@fcom/core/index';

@Component({
  selector: 'fin-header-without-navigation',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header-without-navigation.component.html',
})
export class HeaderWithoutNavigationComponent implements OnInit {
  enableNewLanguageSelector: boolean;

  constructor(private configService: ConfigService) {}

  ngOnInit(): void {
    this.enableNewLanguageSelector = this.configService.cfg.enableNewLanguageSelector;
  }
}
