import { isPresent } from '@fcom/core/utils';
import { Amount } from '@fcom/dapi';
import { FinnairTotalPricesDetails } from '@fcom/dapi/api/models';

export const isAmount = (t: any): t is Amount => !!t && isPresent(t.amount) && isPresent(t.currencyCode);

/**
 * Performs high-precision calculation for the given numbers.
 * Only accepts integers as the multiplying factor to avoid complex decimal calculations.
 *
 * @param amountToMultiply the amount to multiply
 * @param multiplyBy the multiplication factor, must be integer
 * @return {{amount: string, currencyCode: string}}
 */
export const multiplyAmount = (amountToMultiply: Amount, multiplyBy: number): Amount => {
  // eslint-disable-next-line id-blacklist
  if (!Number.isInteger(multiplyBy)) {
    throw new Error('Amount multiplication by floating point number is not supported');
  } else if (!isAmount(amountToMultiply)) {
    throw new Error(`failed isAmount check: ${JSON.stringify(amountToMultiply)}`);
  }
  const { amount, currencyCode } = amountToMultiply;
  const [, decimalDigits] = amount.split('.');
  const integerAmount = parseInt(amount.replace('.', ''), 10);
  const numberOfDigits = decimalDigits ? decimalDigits.length : 0;
  const newAmountInteger = `${integerAmount * multiplyBy}`;
  if (!/^-?\d+$/.test(newAmountInteger)) {
    throw new Error(`Number too large after multiplication, values until 20 digits are supported: ${newAmountInteger}`);
  }
  const newAmountString =
    numberOfDigits > 0
      ? [newAmountInteger.slice(0, -numberOfDigits), newAmountInteger.slice(-numberOfDigits)].join('.')
      : newAmountInteger;
  return {
    amount: newAmountString,
    currencyCode,
  };
};

export const hasUnitPrices = (prices: FinnairTotalPricesDetails, isRefund = false): boolean =>
  hasUnitPricesFlightsAndServices(prices, isRefund) || hasUnitPricesServices(prices);

export const hasUnitPricesFlightsAndServices = (prices: FinnairTotalPricesDetails, isRefund = false): boolean =>
  hasUnitPricesServices(prices, isRefund) && isPresent(prices.flight);

export const hasUnitPricesServices = (prices: FinnairTotalPricesDetails, isRefund = false): boolean => {
  const priceTotalPresent = isPresent(prices) && isPresent(prices.total);
  if (isRefund) {
    return priceTotalPresent;
  }
  return priceTotalPresent && isPresent(prices.services);
};

export const calculateLowesPrice = (
  price1: number | undefined | null,
  price2: number | undefined | null
): number | null => {
  if (!isPresent(price1) && !isPresent(price2)) {
    return null;
  }

  if (!isPresent(price1)) {
    return price2;
  }

  if (!isPresent(price2)) {
    return price1;
  }

  return price1 < price2 ? price1 : price2;
};
