import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { Observable } from 'rxjs';

import { SelectOption } from '../interfaces';

@Component({
  selector: 'fin-simple-select-field',
  styleUrls: ['./simple-select-field.component.scss'],
  templateUrl: './simple-select-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleSelectFieldComponent {
  @Input()
  group$: Observable<UntypedFormGroup>;
  @Input()
  name$: Observable<string>;
  @Input()
  modelName$: Observable<string>;
  @Input()
  label$: Observable<string>;
  @Input()
  options$: Observable<SelectOption[]>;
}
