import { Pipe, PipeTransform } from '@angular/core';

import { add, TimeUnit, pad } from '@fcom/core/utils';

@Pipe({
  name: 'finOffsetToLocalTime',
  pure: false, // Depends on date
})
export class OffsetToLocalTimePipe implements PipeTransform {
  transform(offsetMilliseconds: any, d: Date = new Date(Date.now())): string {
    const offset = parseInt(offsetMilliseconds, 10);
    // eslint-disable-next-line id-blacklist
    if (!d || Number.isNaN(offset)) {
      return '';
    }
    const date = add(d, offset, TimeUnit.millisecond);
    return `${pad(date.getUTCHours())}:${pad(date.getUTCMinutes())}`;
  }
}
