<div
  *ngIf="textContent$ | async"
  class="text-with-side-image flex pr-xlarge-t mr-large-y"
  [class.black-friday]="isBlackFriday$ | async"
  [class.section-marker]="showSectionMarker$ | async"
>
  <div class="text-with-side-image-article pr-xsmall-r">
    <cms-rich-text [content$]="textContent$"></cms-rich-text>
    <cms-link-block *ngIf="link$ | async" class="block pr-large-t" [content$]="link$"></cms-link-block>
  </div>
  <div
    class="text-with-side-image-image pr-xsmall-l flex flex--center overflow-hidden"
    [ngClass]="{ 'disable-cropping flex--align-start': (image$ | async)?.disableCropping }"
  >
    <fin-content-image *ngIf="image$ | async" [imageData$]="image$" [aspectRatio$]="aspectRatio$"></fin-content-image>
  </div>
</div>
