import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { CmsCollection, CmsTeaser } from '@fcom/core-api';
import { isNotEmpty, safeMap } from '@fcom/core/utils';

interface TextWithIcon {
  iconName: SvgLibraryIcon;
  text: string;
}

@Component({
  selector: 'cms-list-with-icons',
  templateUrl: './cms-list-with-icons.component.html',
  styleUrls: ['./cms-list-with-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsListWithIconsComponent implements OnInit {
  @Input()
  content$: Observable<CmsCollection>;
  @Input()
  useTeaserTitle: boolean;
  items$: Observable<TextWithIcon[]>;

  ngOnInit(): void {
    this.items$ = this.content$.pipe(
      map((content: CmsCollection) => safeMap(content, (c: CmsCollection) => c.items)),
      map((items: CmsTeaser[]) =>
        items.map((item) => ({
          text: this.useTeaserTitle ? item.teaserTitle : item.teaserText,
          iconName: item.localSettings?.iconName,
        }))
      ),
      filter(isNotEmpty),
      distinctUntilChanged()
    );
  }
}
