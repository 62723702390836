import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { enumValues, isNotEmpty, isPresent } from '@fcom/core/utils';

import { OndMarketingOffer, OndPairEnhancedResponse } from '../interfaces';
import { OndMarketingOffersService } from '../services';

const INITIAL_OFFER_AMOUNT = 4;
const OFFERS_TO_LOAD_AMOUNT = 3;

interface SortOption {
  value: string;
  label: string;
}

export enum OfferListSortBy {
  DEPARTURE = 'departureDate',
  PRICE_ASC = 'priceAscending',
  PRICE_DESC = 'priceDescending',
}

@Component({
  selector: 'fin-ond-marketing-offers-list',
  templateUrl: './ond-marketing-offers-list.component.html',
  styleUrls: ['./ond-marketing-offers-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OndMarketingOffersListComponent implements OnInit {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  originLocationCode$: Observable<string | undefined> = of(undefined);
  @Input()
  destinationLocationCode$: Observable<string | undefined> = of(undefined);
  @Input()
  links$: Observable<OndPairEnhancedResponse>;

  dynamicAnimationDisabled = false;
  moreOffersAmount: number = OFFERS_TO_LOAD_AMOUNT;
  offerAmount: number = INITIAL_OFFER_AMOUNT;
  defaultSort: OfferListSortBy = OfferListSortBy.PRICE_ASC;
  ondOffers$: Observable<OndMarketingOffer[]>;
  sortOptions$: Observable<SortOption[]>;
  heading$: Observable<string>;
  sortBy$: BehaviorSubject<OfferListSortBy> = new BehaviorSubject<OfferListSortBy>(this.defaultSort);

  constructor(
    private ondMarketingOffersService: OndMarketingOffersService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    const notSortedOffer$ = this.ondMarketingOffersService.getOffersOrFlightData(
      this.originLocationCode$,
      this.destinationLocationCode$,
      this.links$
    );

    this.ondOffers$ = combineLatest([notSortedOffer$, this.sortBy$]).pipe(
      map(([offers, sortBy]) => {
        return offers.sort((a, b) => {
          switch (sortBy) {
            case OfferListSortBy.PRICE_ASC:
              return parseFloat(a.price.amount) > parseFloat(b.price.amount) ? 1 : -1;
            case OfferListSortBy.PRICE_DESC:
              return parseFloat(a.price.amount) > parseFloat(b.price.amount) ? -1 : 1;
            case OfferListSortBy.DEPARTURE:
              return a.departureDate > b.departureDate ? 1 : -1;
            default:
              return 0;
          }
        });
      })
    );

    this.heading$ = this.ondMarketingOffersService.getHeading(
      this.originLocationCode$,
      this.destinationLocationCode$,
      this.ondOffers$,
      'mostSearchedDeals'
    );

    this.getSortOptions();
  }

  loadMoreOffers(): void {
    this.dynamicAnimationDisabled = false;
    this.offerAmount += this.moreOffersAmount;
  }

  sortOffers(value: OfferListSortBy): void {
    this.sortBy$.next(value);
  }

  private getSortOptions(): void {
    this.dynamicAnimationDisabled = true;
    const sortByValues: string[] = enumValues(OfferListSortBy);
    const translationKeys: string[] = sortByValues.map((key: string) => `ondMarketing.sortOfferOptions.${key}`);

    this.sortOptions$ = this.languageService.translateMultiple(translationKeys).pipe(
      map((labels: string[]): SortOption[] =>
        labels
          .map((label: string, i: number) => ({ value: sortByValues[i] as OfferListSortBy, label }))
          .filter((option: SortOption) => isPresent(option.label))
      ),
      filter(isNotEmpty)
    );
  }
}
