import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { isNull, isUndefined } from '@fcom/core/utils';

import { LanguageService } from '../services/language.service';

/**
 * Asynchronous has localization key pipe
 * - Uses LanguageService to check if localization key exist in translations
 *
 * @example
 *
 * When having
 *
 * localizations = {
 *   foo: 'My name is {me}',
 * }
 *
 * The pipe would translate the following
 *
 * 'foo' | finHasLocalizationKey -> true,
 * 'bar' | finHasLocalizationKey -> false
 */
@Pipe({
  name: 'finHasLocalizationKey',
  pure: false,
})
export class HasLocalizationKeyPipe implements PipeTransform, OnDestroy {
  private async: AsyncPipe;
  private cached$: Observable<boolean>;
  private cachedKey: string | string[];

  constructor(
    private ref: ChangeDetectorRef,
    private languageService: LanguageService
  ) {
    this.async = new AsyncPipe(this.ref);
  }

  transform(key: string | string[]): boolean {
    if (isUndefined(key) || isNull(key)) {
      return false;
    }

    if (this.valueChanged(key)) {
      this.update(key);
    }

    return this.async.transform(this.cached$);
  }

  ngOnDestroy(): void {
    this.async.ngOnDestroy();
  }

  private valueChanged(key: string | string[]): boolean {
    return !this.cached$ || key !== this.cachedKey;
  }

  private update(key: string | string[]): void {
    this.cached$ = this.languageService
      .translate(key, undefined, { logErrors: false })
      .pipe(map((str) => Boolean(str)));
    this.cachedKey = key;
  }
}
