import { CartStatus } from '@fcom/dapi/interfaces';
import { isNotEmpty, isPresent } from '@fcom/core/utils';
import { FinnairCart } from '@fcom/dapi/api/models';

const CART_ERROR_STATES: CartStatus[] = [CartStatus.INITIAL, CartStatus.CREATION_ERROR, CartStatus.LOAD_ERROR];

export const isCartInErrorStateOrDoWeHaveTravelers = (
  cartData: FinnairCart = {} as FinnairCart,
  cartStatus: CartStatus,
  initialRoute: boolean
): boolean => {
  return (
    CART_ERROR_STATES.indexOf(cartStatus) === -1 &&
    (!initialRoute ||
      (initialRoute && isNotEmpty(cartData.passengers) && cartData.passengers.every((t) => !!t.firstName)))
  );
};

export const isAncillariesSkipped = (cartData: FinnairCart = {} as FinnairCart): boolean => {
  return !isPresent(cartData) || !isPresent(cartData.controlData)
    ? false
    : !cartData.controlData.sellAncillariesOutbound && !cartData.controlData.sellAncillariesInbound;
};

export enum CountryCode {
  KOREA = 'KR',
  CHINA = 'CN',
}
