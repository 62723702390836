import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { CmsCollection, CmsContent } from '@fcom/core-api/interfaces/cms-interface';
import { isNotEmpty, safeMap } from '@fcom/core/utils';

@Component({
  selector: 'cms-important-links',
  styleUrls: ['./cms-important-links.component.scss'],
  templateUrl: './cms-important-links.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsImportantLinksComponent implements OnInit {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  content$: Observable<CmsCollection>;
  items$: Observable<CmsContent[]>;

  ngOnInit(): void {
    this.items$ = this.content$.pipe(
      map((content: CmsCollection) => safeMap(content, (c: CmsCollection) => c.items)),
      filter(isNotEmpty),
      distinctUntilChanged()
    );
  }
}
