import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ComponentsModule } from '../components/components.module';
import { DirectiveModule } from '../directives/directive.module';
import { PipesModule } from '../pipes/pipes.module';
import { MultivariateTestService } from './services/multivariate-test.service';

@NgModule({
  imports: [CommonModule, ComponentsModule, DirectiveModule, PipesModule],
  declarations: [],
  exports: [],
  providers: [MultivariateTestService],
})
export class MultivariateTestModule {}
