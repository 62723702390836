<ng-container *ngIf="disclaimer">
  <div class="flex flex--center pr-medium" [ngClass]="backgroundColor">
    <div class="flex--nogrow heather-700-text font-body-1">
      <img *ngIf="disclaimer.iconUrl" [src]="disclaimer.iconUrl" [alt]="disclaimer.teaserTitle" />
      <fcom-icon
        *ngIf="disclaimer.svgIcon"
        [name]="disclaimer.svgIcon"
        class="ps-medium-r nordic-blue-900-text icon-medium"
      ></fcom-icon>
    </div>
    <ng-container *ngIf="disclaimer.target; else contentBlock">
      <div class="flex-1 font-body-1">
        <a
          class="nordic-blue-900-text font-body-1"
          [finLink]="disclaimer.target.url"
          [external]="disclaimer.target.external"
          >{{ disclaimer.target.teaserTitle }}</a
        >
      </div>
    </ng-container>
    <ng-template #contentBlock>
      <div class="disclaimer-content flex-1 font-body-1" [innerHTML]="disclaimer.teaserText | finSafe: 'html'"></div>
    </ng-template>
  </div>
</ng-container>
