import {
  Component,
  forwardRef,
  ChangeDetectionStrategy,
  Injector,
  ChangeDetectorRef,
  Directive,
  Attribute,
  HostBinding,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, CheckboxRequiredValidator } from '@angular/forms';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { AbstractCheckboxComponent } from '../abstracts/abstract-checkbox.component';
import { CheckBoxTheme } from '../enums';

/**
 * The checkbox component can be used in either a reactive form, template driven form or standalone.
 *
 * @example
 * <fcom-checkbox
 *   [name]="name"
 *   [checked]="checked"
 *   [disabled]="disabled"
 *   (blur)="onBlur($event)"
 *   (focus)="onFocus($event)"
 *   (change)="onChange($event)">
 *   <span class="font-body-1 medium-type">{{ name }}</span>
 * </fcom-checkbox>
 */
@Component({
  selector: 'fcom-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent extends AbstractCheckboxComponent {
  tabIndex: number;

  readonly CheckBoxTheme = CheckBoxTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input() contentFullWidth = false;

  @Input() showUnselectedIcon = true;

  @HostBinding('attr.tabindex')
  hostTabindex = null;

  @HostBinding('class.checkbox-focused')
  get focusedState(): boolean {
    return this.focused;
  }

  @HostBinding('class.checkbox-disabled')
  get disabledState(): boolean {
    return this.disabled;
  }

  constructor(cdRef: ChangeDetectorRef, injector: Injector, @Attribute('tabindex') tabIndex: string) {
    super(cdRef, injector);
    this.tabIndex = parseInt(tabIndex, 10) || 0;
  }
}

/**
 * Validator for checkbox's required attribute in template-driven checkbox.
 * Current CheckboxRequiredValidator only work with `input type=checkbox` and does not
 * work with `fcom-checkbox`.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: `fcom-checkbox[required][formControlName],
             fcom-checkbox[required][formControl], fcom-checkbox[required][ngModel]`,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FcomCheckboxRequiredValidator),
      multi: true,
    },
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { '[attr.required]': 'required ? "" : null' },
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class FcomCheckboxRequiredValidator extends CheckboxRequiredValidator {}
