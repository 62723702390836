import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';

import { IconLibrary } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, combineLatest, NEVER, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { unsubscribe } from '@fcom/core/utils/index';
import { CmsArticle, CmsImageData, CmsTarget } from '@fcom/core-api';
import { AspectRatioString } from '@fcom/common/interfaces/images.interface';
import { WindowRef } from '@fcom/core/providers';

import { ButtonTheme } from '../buttons';
import { PromoTheme } from './enum';

@Component({
  selector: 'fcom-promo-card',
  templateUrl: './promo-card.component.html',
  styleUrls: ['./promo-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromoCardComponent implements OnInit, OnDestroy {
  readonly ButtonTheme = ButtonTheme;
  readonly IconLibrary = IconLibrary;
  readonly PromoTheme = PromoTheme;

  @Input()
  theme: PromoTheme = PromoTheme.WHITE;

  @Input()
  data$: Observable<CmsArticle> = NEVER;

  @Input()
  imageData$: Observable<CmsImageData> = NEVER;

  @Input()
  ctaData$: Observable<CmsTarget> = NEVER;

  @Input()
  @HostBinding('class.special-grid-size-for-laptop')
  specialGridSizeForLaptop = false;

  @Input()
  aspectRatio: AspectRatioString = '21x9';

  @Input()
  isTextOnTop = false;

  @HostBinding('class.double') isDouble = false;

  ctaTrigger$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private subscriptions: Subscription = new Subscription();

  constructor(
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private windowRef: WindowRef
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      combineLatest([this.data$, this.imageData$]).subscribe(() => {
        this.isDouble = true;
        this.cdRef.markForCheck();
      })
    );
    this.subscriptions.add(
      combineLatest([this.ctaData$, this.ctaTrigger$.pipe(filter(Boolean))])
        .pipe(map(([cta, _]) => cta))
        .subscribe((cta) => this.navigateToUrl(cta))
    );
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  navigateToUrl(cta: CmsTarget): void {
    if (cta?.url && !cta?.openInNewTab) {
      this.router.navigateByUrl(cta?.url);
    } else if (cta?.url && cta?.openInNewTab) {
      this.windowRef.nativeWindow.open(cta?.url, '_blank');
    }
  }
}
