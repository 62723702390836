import { LocalDate } from './tz-date';
import { isNull, isPresent, isUndefined } from './utils';

const isPrimitive = (a: unknown): a is string | number | boolean =>
  typeof a === 'string' || typeof a === 'number' || typeof a === 'boolean';
const isDate = (a: unknown): a is Date => a instanceof Date;
const isEmptyString = (a: unknown): a is '' => typeof a === 'string' && a.length === 0;
const isObject = (a: unknown): a is object => typeof a === 'object';
const isLocalDate = (a: unknown): a is LocalDate => a instanceof LocalDate;

export const cloneDeep = <T>(b: T): T => {
  if (isUndefined(b) || isNull(b)) {
    return b;
  } else if (isLocalDate(b)) {
    return new LocalDate(b.toString()) as any;
  } else if (isDate(b)) {
    // If new value is a Date, we clone it
    return new Date(b.getTime()) as any;
  } else if (isPrimitive(b)) {
    return b;
  } else if (Array.isArray(b)) {
    return mergeArray([], b) as any;
  } else {
    return mergeDeep({}, b);
  }
};

/**
 * Merge arrays deeply. Will clone objects with mergeDeep.
 * @param a first array
 * @param b second array|object
 * @param overWriteWithEmpty do we overwrite with empty value
 * @returns {any}
 */
const mergeArray = <A, B>(a: A[], b: B[] | { [key: number]: B }, overWriteWithEmpty = true): (A | B)[] => {
  const c = a.slice();
  Object.keys(b).forEach((e: string) => {
    if (isObject(b[e])) {
      c[e] = mergeDeep(a[e] || {}, b[e], overWriteWithEmpty);
      // eslint-disable-next-line no-prototype-builtins
    } else if (a.hasOwnProperty(e)) {
      c[e] = cloneDeep(b[e]);
    } else {
      c.push(cloneDeep(b[e]));
    }
  });
  return c;
};

function mergeObject<T>(a: Partial<T>, b: Partial<T>, overWriteWithEmpty?: boolean): T;
function mergeObject<T1, T2>(a: Partial<T1>, b: Partial<T2>, overWriteWithEmpty?: boolean): T1 & T2;

/**
 * Merge objects deeply. Will clone necessary properties.
 * ®
 * @example
 *
 * const a = { a: 'foo', b: { c: 'bar', d: { e: 'zoq' } } };
 * const b = { a: 'baz', b: { d: { f: 'fot' }, g: 'pik' } });
 * const c = mergeDeep(a, b);
 * console.log(c);
 * => {
 *   a: 'baz',
 *   b: {
 *     c: 'bar',
 *     d: {
 *       e: 'zoq',
 *       f: 'fot'
 *     },
 *     g: 'pik'
 *   }
 * }
 *
 * @param a the first object
 * @param b the second object
 * @param overWriteWithEmpty do we overwrite with empty value
 * @returns {any|({}&any)}
 */
function mergeObject(a: unknown, b: unknown, overWriteWithEmpty = true): unknown {
  const target = Object.assign({}, a);

  Object.keys(b).forEach((key) => {
    const val = b[key];
    if (Array.isArray(val)) {
      target[key] = mergeArray(a[key] || [], val, overWriteWithEmpty);
    } else if (isObject(val)) {
      target[key] = mergeDeep(target[key] || {}, val, overWriteWithEmpty);
    } else {
      if (overWriteWithEmpty || (isPresent(val) && !isEmptyString(val))) {
        target[key] = cloneDeep(val);
      }
    }
  });

  return target;
}

export function mergeDeep<A, B>(a: A[], b: B[] | { [key: number]: B }, overWriteWithEmpty?: boolean): (A | B)[];
export function mergeDeep<A extends string | number | boolean | Date, B>(a: A, b: B, overWriteWithEmpty?: boolean): B;
export function mergeDeep<A, B extends string | number | boolean | LocalDate>(
  a: A,
  b: B,
  overWriteWithEmpty?: boolean
): B;
export function mergeDeep<T>(a: Partial<T>, b: Partial<T>, overWriteWithEmpty?: boolean): T;
export function mergeDeep<A, B>(a: A, b: B, overWriteWithEmpty?: boolean): A & B;
/**
 * Merge objects/arrays/primitives deeply. Will clone necessary properties.
 * ®
 * @example
 *
 * const a = { a: 'foo', b: { c: 'bar', d: { e: 'zoq' } } };
 * const b = { a: 'baz', b: { d: { f: 'fot' }, g: 'pik' } });
 * const c = mergeDeep(a, b);
 * console.log(c);
 * => {
 *   a: 'baz',
 *   b: {
 *     c: 'bar',
 *     d: {
 *       e: 'zoq',
 *       f: 'fot'
 *     },
 *     g: 'pik'
 *   }
 * }
 *
 * @param a the first object
 * @param b the second object
 * @param overWriteWithEmpty do we overwrite with empty value
 * @returns {any|({}&any)}
 */
export function mergeDeep(a: unknown, b: unknown, overWriteWithEmpty = true): unknown {
  // If original is string, number, boolean, or date, we overwrite it
  if (isUndefined(a)) {
    return cloneDeep(b);
  } else if (isUndefined(b)) {
    return cloneDeep(a);
  } else if (isPrimitive(a) || isPrimitive(b) || isDate(a) || isLocalDate(b)) {
    return cloneDeep(b);
  } else if (isNull(b)) {
    return null;
  } else if (Array.isArray(a)) {
    return mergeArray(a, b as unknown[], overWriteWithEmpty);
  }
  return mergeObject(a, b, overWriteWithEmpty);
}

export function mergeDeepMany<T1, T2>(t1: T1, t2: T2): T1 & T2;
export function mergeDeepMany<T1, T2, T3>(t1: T1, t2: T2, t3: T3): T1 & T2 & T3;
export function mergeDeepMany<T>(...args: Partial<T>[]): T;
/**
 * Merge multiple objects deeply. Will clone necessary properties.
 * Same as MergeDeep function, but takes an array of any number of objects as an argument
 */
export function mergeDeepMany(...args: unknown[]): unknown {
  return args.reduce((arr, cur) => mergeDeep(arr, cur), undefined);
}
