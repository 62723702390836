import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { isEmpty, resolveTailName } from '@fcom/core/utils';

/**
 * Map airline codes into tail names.
 * airlineCodes Operating airline code array
 */
@Injectable()
@Pipe({
  name: 'finTailNames',
  pure: true,
})
export class TailNamesPipe implements PipeTransform {
  transform(airlineCodes: string[]): string[] {
    if (isEmpty(airlineCodes)) {
      return [];
    }

    return airlineCodes.map((code) => resolveTailName(code?.toLowerCase())).filter(Boolean);
  }
}
