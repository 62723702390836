import { Observable } from 'rxjs';

import {
  Cabin,
  Duration,
  FinnairBoundItem,
  FinnairCabinClass,
  ItineraryItemFlight,
  ItineraryItemLayover,
  MultiOffer,
  MultiOfferList,
  StatusCode,
} from '@fcom/dapi/api/models';
import { ExtendedFareFamily } from '@fcom/common-booking/interfaces';

export interface Airport {
  dateTime: string;
  city: string;
  airportCode: string;
  airport: string;
}

export interface MultiCityOffer extends MultiOffer {
  hash: string;
  fullId: string;
}

export interface MultiCityState
  extends Omit<MultiOfferList, 'hash' | 'offerIdPrefix' | 'status' | 'fareFamiliesPerCabin'> {
  offers: Array<MultiCityOffer>;
  selectedFareFamily?: string;
  selectedItinerary?: string[];
  hashMap: Record<string, string>;
  status: MultiCityStatus;
  statusCode?: StatusCode;
  requestedMoreFlightOfferIds: string[];
  fareFamiliesPerCabin: Partial<Record<Cabin, string[]>>;
}

export interface UFOInfo {
  departure: Airport;
  arrival: Airport;
  tails?: string[];
  duration: Duration;
  airlines?: string[];
  stops?: number;
  bound?: FinnairBoundItem;
  itinerary: (ItineraryItemFlight | ItineraryItemLayover)[];
  showViewDetails?: boolean;
  isByBusOnly?: boolean;
  focusOnViewDetails$?: Observable<boolean>;
  viewDetailsBtnKey?: string;
  itineraryWithDifferentCabinClass?: ItineraryItemFlight[];
  cabinClass?: FinnairCabinClass;
}

export interface OffersPerBound {
  itineraryId: string;
  cheapestOffer: MultiCityOffer;
  fareFamily: ExtendedFareFamily;
  boundInfo: UFOInfo;
}

export enum MultiCityStatus {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  PENDING_MORE_FLIGHTS = 'PENDING_MORE_FLIGHTS',
  ERROR = 'ERROR',
  READY = 'READY',
  NO_OFFERS = 'NO_OFFERS',
}
