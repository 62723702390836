import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';

import { combineLatest, Observable, of, startWith, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { finShare } from '@fcom/rx';
import { pathIsWithinHardcodedPath } from '@fcom/core/utils/app-url-utils';
import { RootPaths } from '@fcom/core/constants';

import { getQualtricsUrl, QualtricsSurvey } from '../../qualtrics/qualtrics.utils';
import { FeedbackService } from '../../services/feedback/feedback.service';
import { MediaQueryService } from '../../services';

@Component({
  selector: 'fin-feedback-widget',
  templateUrl: './feedback-widget.component.html',
  styleUrls: ['./feedback-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackWidgetComponent implements OnInit, OnDestroy {
  qualtricsUrl$: Observable<string>;
  removeGlobalListener: Function;
  openSurveyTrigger$: Subject<boolean> = new Subject();
  visibility$: Observable<boolean>;
  feedbackSurvey$: Observable<QualtricsSurvey>;

  constructor(
    private languageService: LanguageService,
    private feedbackService: FeedbackService,
    private router: Router,
    private mediaQueryService: MediaQueryService
  ) {}

  ngOnInit(): void {
    const navigationEvent$ = this.router.events.pipe(
      filter((event: Event) => event instanceof NavigationEnd),
      startWith({ url: this.router.url } as NavigationEnd)
    );
    const canShowInViewport$ = this.mediaQueryService.isBreakpoint$('tablet_down').pipe(map((value) => !value));

    const canShowFeedbackButton$ = navigationEvent$.pipe(
      switchMap((event: NavigationEnd) => {
        if (
          pathIsWithinHardcodedPath(event.url, RootPaths.MANAGE_BOOKING_ROOT) ||
          pathIsWithinHardcodedPath(event.url, RootPaths.CHECK_IN_ROOT)
        ) {
          return of(true);
        }
        return canShowInViewport$;
      })
    );

    const serviceVisibility$ = this.feedbackService.visibility$.pipe(distinctUntilChanged(), finShare());
    this.visibility$ = canShowFeedbackButton$.pipe(switchMap((canShow) => (canShow ? serviceVisibility$ : of(false))));
    this.feedbackSurvey$ = this.feedbackService.feedbackSurvey$.pipe(distinctUntilChanged());

    this.qualtricsUrl$ = this.feedbackSurvey$.pipe(
      switchMap((survey) => combineLatest([this.languageService.langKey, this.languageService.translate(survey.url)])),
      map((params) => getQualtricsUrl(params[0], this.languageService.langValue, params[1]))
    );
  }

  ngOnDestroy(): void {
    if (this.removeGlobalListener) {
      this.removeGlobalListener();
    }
  }

  openModal(event: MouseEvent): void {
    event.stopPropagation();

    this.openSurveyTrigger$.next(true);
  }
}
