/* eslint-disable */

/**
 * List of ineligibility & eligibility reasons for service catalog
 */
export enum FinnairServiceCatalogEligibilityReason {
  NOT_ELIGIBLE_EMAIL_MISSING = 'NOT_ELIGIBLE_EMAIL_MISSING',
  NOT_ELIGIBLE_CARRY_ON_INCLUDED = 'NOT_ELIGIBLE_CARRY_ON_INCLUDED',
  PURCHASE_NOT_ELIGIBLE_WHEN_FLIGHT_NOT_CONFIRMED = 'PURCHASE_NOT_ELIGIBLE_WHEN_FLIGHT_NOT_CONFIRMED',
  PURCHASE_NOT_ELIGIBLE_FOR_OTHER_AIRLINE = 'PURCHASE_NOT_ELIGIBLE_FOR_OTHER_AIRLINE',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  CANCELLATION_COVER_NOT_ELIGIBLE = 'CANCELLATION_COVER_NOT_ELIGIBLE',
  PURCHASE_SPECIAL_MEAL_NOT_ELIGIBLE = 'PURCHASE_SPECIAL_MEAL_NOT_ELIGIBLE',
  PURCHASE_BAGGAGE_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN = 'PURCHASE_BAGGAGE_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN',
  PURCHASE_SEAT_NOT_ELIGIBLE_AFTER_CHECK_IN_IS_CLOSED = 'PURCHASE_SEAT_NOT_ELIGIBLE_AFTER_CHECK_IN_IS_CLOSED',
  PURCHASE_SEAT_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN = 'PURCHASE_SEAT_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN',
  PURCHASE_SEAT_NOT_ELIGIBLE_WHEN_PNR_HAS_PETC = 'PURCHASE_SEAT_NOT_ELIGIBLE_WHEN_PNR_HAS_PETC',
  PURCHASE_SPECIAL_DIET_ELIGIBLE_PRIOR_TO_24_HOURS = 'PURCHASE_SPECIAL_DIET_ELIGIBLE_PRIOR_TO_24_HOURS',
  PURCHASE_LOUNGE_ACCESS_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN = 'PURCHASE_LOUNGE_ACCESS_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN',
  PURCHASE_FRESH_MEAL_NOT_ELIGIBLE_EX_HEL_WITHIN_7_HOURS = 'PURCHASE_FRESH_MEAL_NOT_ELIGIBLE_EX_HEL_WITHIN_7_HOURS',
  PURCHASE_FRESH_MEAL_NOT_ELIGIBLE_EX_EXCEPT_HEL_WITHIN_24_HOURS = 'PURCHASE_FRESH_MEAL_NOT_ELIGIBLE_EX_EXCEPT_HEL_WITHIN_24_HOURS',
  PURCHASE_PRE_ORDER_HOT_MEAL_NOT_ELIGIBLE_EX_HEL_WITHIN_16_HOURS = 'PURCHASE_PRE_ORDER_HOT_MEAL_NOT_ELIGIBLE_EX_HEL_WITHIN_16_HOURS',
  PURCHASE_PRE_ORDER_HOT_MEAL_NOT_ELIGIBLE_EX_EXCEPT_HEL_36_HOURS = 'PURCHASE_PRE_ORDER_HOT_MEAL_NOT_ELIGIBLE_EX_EXCEPT_HEL_36_HOURS',
  PURCHASE_MEAL_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN = 'PURCHASE_MEAL_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN',
  PURCHASE_PREORDER_HOT_MEAL_ELIGIBLE_PRIOR_TO_16_HOURS_EX_HELSINKI = 'PURCHASE_PREORDER_HOT_MEAL_ELIGIBLE_PRIOR_TO_16_HOURS_EX_HELSINKI',
  PURCHASE_PREORDER_HOT_MEAL_ELIGIBLE_PRIOR_TO_36_HOURS_EX_EXCEPT_HELSINKI = 'PURCHASE_PREORDER_HOT_MEAL_ELIGIBLE_PRIOR_TO_36_HOURS_EX_EXCEPT_HELSINKI',
  PURCHASE_FRESH_MEAL_ELIGIBLE_PRIOR_TO_7_HOURS_EX_HELSINKI = 'PURCHASE_FRESH_MEAL_ELIGIBLE_PRIOR_TO_7_HOURS_EX_HELSINKI',
  PURCHASE_FRESH_MEAL_ELIGIBLE_PRIOR_TO_24_HOURS_EX_EXCEPT_HELSINKI = 'PURCHASE_FRESH_MEAL_ELIGIBLE_PRIOR_TO_24_HOURS_EX_EXCEPT_HELSINKI',
  PURCHASE_WIFI_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN = 'PURCHASE_WIFI_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN',
  PURCHASE_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN = 'PURCHASE_NOT_ELIGIBLE_WHEN_ALREADY_FLOWN',
  PURCHASE_PET_IN_CABIN_NOT_AVAILABLE_IN_BUSINESS_CLASS = 'PURCHASE_PET_IN_CABIN_NOT_AVAILABLE_IN_BUSINESS_CLASS',
  PURCHASE_PETC_NOT_ELIGIBLE_WITH_CONFIRMED_INCOMPATIBLE_SEAT = 'PURCHASE_PETC_NOT_ELIGIBLE_WITH_CONFIRMED_INCOMPATIBLE_SEAT',
  NOT_ELIGIBLE_PRIORITY_SERVICES_ORIGIN = 'NOT_ELIGIBLE_PRIORITY_SERVICES_ORIGIN',
  NOT_ELIGIBLE_PRIORITY_SERVICES_CONNECTING_FLIGHT = 'NOT_ELIGIBLE_PRIORITY_SERVICES_CONNECTING_FLIGHT'
}

