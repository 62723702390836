import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable, map } from 'rxjs';

import { LanguageService } from '@fcom/ui-translate';
import { BasePaxAmount } from '@fcom/dapi';
import { SelectOption } from '@fcom/common';
import { ButtonTheme, IconPosition } from '@fcom/ui-components';

import { AmountUpdateType, AmRoomSelection } from '../../interfaces';

// copied from the old widget...
const PAX_AMOUNT_CONFIG = {
  adults: {
    maxPerRoom: 9,
    minPerRoom: 1,
    maxTotal: 9,
  },
  children: {
    maxPerRoom: 5,
    minPerRoom: 0,
    maxTotal: 6,
  },
  maxTotalPax: 9,
  maxTotalRooms: 3,
};

interface AmRoom {
  adults: number;
  children: number;
  childrenForm: UntypedFormGroup;
}

interface AmPaxAmountTotals {
  total: {
    adults: number;
    children: number;
  };
  remaining: {
    adults: number;
    children: number;
  };
}

@Component({
  selector: 'fin-am-room-selector',
  templateUrl: './am-room-selector.component.html',
  styleUrls: ['./am-room-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmRoomSelectorComponent implements OnInit {
  readonly AmountUpdateType = AmountUpdateType;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly ButtonTheme = ButtonTheme;
  readonly IconPosition = IconPosition;
  readonly IconLibrary = IconLibrary;

  @Input()
  disabled = false;

  @Output()
  setRooms = new EventEmitter<AmRoomSelection[]>();

  modalOpen = false;
  PAX_AMOUNT_CONFIG = PAX_AMOUNT_CONFIG;
  childAgeOptions$: Observable<SelectOption[]>;
  childAgeOptionsWithoutInfants$: Observable<SelectOption[]>;
  paxAmountTotals: AmPaxAmountTotals;
  selectedRooms: AmRoom[] = [];

  constructor(
    private languageService: LanguageService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.selectedRooms = [
      {
        adults: 1,
        children: 0,
        childrenForm: this.fb.group({}),
      },
    ];

    this.childAgeOptions$ = this.languageService
      .translateMultiple(['aurinkomatkat.paxAmount.year', 'aurinkomatkat.paxAmount.years'])
      .pipe(
        map(([year, years]) =>
          [...Array(18).keys()].map((y) => ({ name: `${y} ${y === 1 ? year : years}`, value: y.toString() }))
        )
      );

    this.childAgeOptionsWithoutInfants$ = this.childAgeOptions$.pipe(map((options) => options.slice(2)));

    this.updatePaxAmountTotals();
    this.emitSelectedRooms();
  }

  openModal(): void {
    this.modalOpen = true;
  }

  addRoom(): void {
    this.selectedRooms.push({
      adults: 1,
      children: 0,
      childrenForm: this.fb.group({}),
    });

    this.updatePaxAmountTotals();
  }

  removeRoom(roomIndex: number) {
    this.selectedRooms = [...this.selectedRooms.slice(0, roomIndex), ...this.selectedRooms.slice(roomIndex + 1)];

    this.updatePaxAmountTotals();
  }

  onPaxDetailsUpdate(type: keyof BasePaxAmount, updateType: AmountUpdateType, roomIndex: number): void {
    this.selectedRooms[roomIndex][type] =
      this.selectedRooms[roomIndex][type] + (updateType === AmountUpdateType.INCREMENT ? +1 : -1);

    if (type === 'children') {
      if (updateType === AmountUpdateType.INCREMENT) {
        this.selectedRooms[roomIndex].childrenForm.addControl(
          `room-${roomIndex}-child-${this.selectedRooms[roomIndex].children}`,
          this.fb.control(this.selectedRooms[roomIndex].children > this.selectedRooms[roomIndex].adults ? '2' : '0')
        );
      } else {
        this.selectedRooms[roomIndex].childrenForm.removeControl(
          `room-${roomIndex}-child-${this.selectedRooms[roomIndex].children + 1}`
        );
      }
    }

    this.updatePaxAmountTotals();
  }

  updatePaxAmountTotals(): void {
    this.paxAmountTotals = this.calculateTotalAndRemainingAmPaxAmount(this.selectedRooms);
  }

  emitSelectedRooms(): void {
    this.setRooms.emit(
      this.selectedRooms.map((room) => ({
        numberOfAdults: room.adults,
        childrenAges: Object.values(room.childrenForm.controls).map((control) => control.value),
      }))
    );
  }

  calculateTotalAndRemainingAmPaxAmount = (rooms: AmRoom[]): AmPaxAmountTotals => {
    const initial = {
      total: { adults: 0, children: 0 },
      remaining: { adults: PAX_AMOUNT_CONFIG.adults.maxTotal, children: PAX_AMOUNT_CONFIG.children.maxTotal },
    };

    return (
      rooms?.reduce((current, { adults, children }) => {
        const total = {
          adults: current.total.adults + adults,
          children: current.total.children + children,
        };
        const remainingTotal = PAX_AMOUNT_CONFIG.maxTotalPax - (total.adults + total.children);
        const remainingAdults = current.remaining.adults - adults;
        const remainingChildren = current.remaining.children - children;
        const remaining = {
          adults: remainingAdults <= remainingTotal ? remainingAdults : remainingTotal,
          children: remainingChildren <= remainingTotal ? remainingChildren : remainingTotal,
        };

        return {
          total,
          remaining,
        };
      }, initial) ?? initial
    );
  };
}
