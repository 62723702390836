import { Component, Input } from '@angular/core';

@Component({
  selector: 'fin-booking-plain-header-area',
  templateUrl: './booking-plain-header-area.component.html',
})
export class BookingPlainHeaderAreaComponent {
  @Input()
  title: string;
  @Input()
  subtitle: string;
  @Input()
  extraText?: string;
  @Input()
  headingClass = 'nordic-blue-900-text';
  @Input()
  enableBottomPadding = true;
  @Input()
  enableTopPadding = true;
}
