import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

import { unsubscribe } from '@fcom/core/utils';

import { ElementTypes, ElementActions, GaContext } from '../../interfaces';
import { GtmService } from '../services/gtm.service';

@Directive({
  selector: '[finElemTracker]',
})
export class ElementTrackerDirective implements OnInit, OnDestroy {
  @Input()
  finElemTracker: string;
  @Input()
  finElemContext: string;
  @Input()
  finElemType: ElementTypes;
  @Input()
  finElemIndex: number;
  @Input()
  finElemState: string;

  subscriptions: Subscription = new Subscription();

  private eventSubject = new Subject<number>();
  private eventObservable = this.eventSubject.asObservable().pipe(throttleTime(2000));

  constructor(private gtmService: GtmService) {}

  @HostListener('click', ['$event'])
  onClick(event): void {
    if (this.finElemType && this.finElemTracker && this.finElemType !== ElementTypes.INPUT) {
      this.eventSubject.next(event);
    }
  }

  @HostListener('blur', ['$event'])
  onBlur(event): void {
    // If tracked element is a fin-input-field (text input) we want to track the blur
    // instead of click meaning the inputting has finished and user moves on
    if (this.finElemType && this.finElemTracker && this.finElemType === ElementTypes.INPUT) {
      this.eventSubject.next(event);
    }
  }

  ngOnInit(): void {
    this.subscriptions.add(this.eventObservable.subscribe((event) => this.handleEvent(event)));
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  handleEvent(_event: unknown): void {
    this.gtmService.trackElement(
      this.finElemTracker,
      this.finElemContext ?? GaContext.NO_CONTEXT,
      this.finElemType,
      this.finElemState,
      ElementActions.CLICK
    );
  }
}
