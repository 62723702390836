import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

import { NotificationLayout, NotificationTheme } from '@fcom/ui-components';

import { GtmService } from '../../gtm';
import { GtmEvent } from '../../interfaces';

@Component({
  selector: 'fin-newsletter-notification',
  templateUrl: './newsletter-notification.component.html',
})
export class NewsletterNotificationComponent implements OnInit {
  readonly NotificationTheme = NotificationTheme;
  readonly NotificationLayout = NotificationLayout;

  showNotification = false;

  constructor(
    private router: Router,
    private gtmService: GtmService
  ) {}

  ngOnInit(): void {
    const route: ActivatedRouteSnapshot = this.router.routerState.snapshot.root;

    if (route.queryParamMap.has('newsletterSubscribed')) {
      this.gtmService.pushEventToDataLayer(GtmEvent.NEWSLETTER_SUBSCRIPTION_COMPLETED);
      this.showNotification = true;
      this.router.navigate([], {
        replaceUrl: true,
        state: { skipFilterSet: true },
      });
    }
  }
}
