import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TravelGuide } from '../../interfaces';

@Component({
  selector: 'cms-travel-guide',
  templateUrl: './cms-travel-guide.component.html',
  styleUrls: ['cms-travel-guide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsTravelGuideComponent implements OnInit {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  content$: Observable<TravelGuide>;

  travelGuide$: Observable<TravelGuide>;
  related$: Observable<TravelGuide[]>;

  ngOnInit(): void {
    this.travelGuide$ = this.content$;
    this.related$ = this.content$.pipe(map((tg) => tg.related));
  }
}
