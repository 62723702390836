import { Pipe, PipeTransform } from '@angular/core';

import { LanguageService } from '@fcom/ui-translate';
import { symbolToLangPath } from '@fcom/core/utils/cms-path-helpers';

@Pipe({
  name: 'resolveLinks',
})
export class ResolveLinksPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(value: string): string {
    return this.legacySiteLinkLocale(value);
  }

  private legacySiteLinkLocale(value = ''): string {
    if (!value) {
      return value;
    }
    return symbolToLangPath(value, this.languageService.ddsLocaleValue);
  }
}
