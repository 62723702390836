/* eslint-disable no-underscore-dangle */
import { createFeatureSelector, createSelector, select } from '@ngrx/store';

import { safeSelect, StateSelector } from '@fcom/core/selectors/selector-utils';
import { TravelerService, ServiceCatalogSelectionsForAllTravelers } from '@fcom/dapi';
import {
  FlightSeatSelections,
  SeatMapState,
  SeatMapStatus,
  SeatMapWithStatus,
  SeatSelectionForFlight,
} from '@fcom/common/interfaces/seat-map.interface';
import { entrySet, mapValues } from '@fcom/core/utils';
import { SeatmapSvgAndControlData, SubCategory } from '@fcom/dapi/api/models';

import { COMMON_BOOKING_FEATURE_KEY } from '../constants';
import { CommonBookingFeatureState, CommonBookingState } from '../store.interface';
import { getSeatPrice } from '../../modules/ancillaries/utils';

const _selectCommonBookingState = createFeatureSelector<CommonBookingFeatureState, CommonBookingState>(
  COMMON_BOOKING_FEATURE_KEY
);
const _seatMapState = createSelector(_selectCommonBookingState, (state: CommonBookingState) => state?.seatMap);

const _seatMapStateForFlight = (flightId: string) =>
  createSelector(_seatMapState, (sm: SeatMapState) => sm?.[flightId]);

const _seatSelectionsForFlight = (flightId: string) =>
  createSelector(_seatMapStateForFlight(flightId), (sm: SeatMapWithStatus) => sm?.selections);

/**
 * Selects the seatMap from the state via given flightId.
 */
export const seatMapForFlight = (
  flightId: string
): StateSelector<CommonBookingFeatureState, SeatmapSvgAndControlData> =>
  safeSelect(createSelector(_seatMapStateForFlight(flightId), (sm: SeatMapWithStatus) => sm?.seatMap));

export const seatMapWithStatus = (flightId: string): StateSelector<CommonBookingFeatureState, SeatMapWithStatus> =>
  select(createSelector(_seatMapStateForFlight(flightId), (sm: SeatMapWithStatus) => sm));

export const seatSelectionsForFlight = (
  flightId: string
): StateSelector<CommonBookingFeatureState, FlightSeatSelections> => select(_seatSelectionsForFlight(flightId));

export const seatSelectionsForFlightAndTraveler = (
  flightId: string,
  travelerId: string
): StateSelector<CommonBookingFeatureState, SeatSelectionForFlight> =>
  select(createSelector(_seatSelectionsForFlight(flightId), (ss: FlightSeatSelections) => ss?.[travelerId]));

export const seatMapForFlightStatus = (flightId: string): StateSelector<CommonBookingFeatureState, SeatMapStatus> =>
  select(createSelector(_seatMapStateForFlight(flightId), (ss: SeatMapWithStatus) => ss?.status));

export const seatSelections = (): StateSelector<CommonBookingFeatureState, ServiceCatalogSelectionsForAllTravelers> =>
  select(
    createSelector(_seatMapState, (sm: SeatMapState) =>
      sm
        ? mapValues(sm, (s) =>
            entrySet<SeatSelectionForFlight>(s.selections)
              .reduce<TravelerService[]>((all: TravelerService[], { key, value: { seatNumber } }) => {
                const seatPrice = getSeatPrice(
                  s.seatMap?.seats.find((seat) => seat.seatNumber === seatNumber),
                  key
                );
                return all.concat({
                  category: SubCategory.SEAT,
                  quantity: seatNumber ? 1 : 0,
                  variant: seatNumber,
                  seatNumber,
                  displayName: '',
                  title: '',
                  originalPricePerItem: seatPrice?.originalPrice ?? s.selections[key].originalPricePerItem,
                  pricePerItem: seatPrice?.price ?? s.selections[key].pricePerItem,
                  paid: s.selections[key].paid,
                  ticketed: s.selections[key].ticketed,
                  modifiable: s.selections[key].modifiable,
                  travelerId: key,
                  includedInTicketType: false,
                  includedInTierBenefit: false,
                });
              }, [])
              .filter(Boolean)
          )
        : undefined
    )
  );
