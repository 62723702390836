import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

@Component({
  selector: 'fin-form-input-checkbox',
  templateUrl: 'form-input-checkbox.component.html',
  styleUrls: ['form-input-checkbox.component.scss'],
})
export class FormInputCheckboxComponent implements OnInit {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  controlName: string;

  @Input()
  translationPrefix: string;

  @Input()
  id: string;

  @Input()
  checkboxValue: string | boolean = true;

  @Input()
  displayContainerStyles = true;

  @Input()
  descriptionText: string;

  @Input()
  labelClass = 'label font-body-1 ms-xsmall-l';

  isRequired = false;
  idOrControlName: string;
  control: AbstractControl;

  @ViewChild('inputElement', { static: true })
  inputElement: ElementRef;

  ngOnInit(): void {
    this.control = this.ctrlField;
    this.isRequired = this.required;
    this.idOrControlName = this.id || `${this.controlName}-${this.checkboxValue}`;
  }

  setFocus(): void {
    this.inputElement.nativeElement.focus();
  }

  get required(): boolean {
    return this.ctrlField.errors?.required;
  }

  get ctrlField(): AbstractControl {
    return this.parentForm.controls[this.controlName];
  }
}
