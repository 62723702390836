import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CmsContentType } from '@fcom/core-api';
import { finShare, safeMap } from '@fcom/rx';

import { Media, MediaPicture, MediaType, MediaVideo, PhraseGroup, TravelGuide } from '../../interfaces';
import { removeEmptyCmsText } from '../../services/cms-utils/cms-utils';

const isVideo = (o: Media): o is MediaVideo => o.type === 'video' && !!o.url;

const extractMedia: (data: any) => Media = (data = {}) => {
  if (!data.media) {
    return;
  }

  const video = data.media.find((m) => m.contentType === CmsContentType.CMVideo);
  const picture = data.media.find((m) => m.contentType === CmsContentType.CMPicture);

  if (video) {
    return {
      type: 'video',
      url: video.dataUrl,
    } as MediaVideo;
  } else if (picture) {
    return {
      type: 'picture',
      picture,
    } as MediaPicture;
  }
};

const mapTravelGuide: (data: TravelGuide) => TravelGuide = (data) => ({
  ...data,
  title: data.teaserTitle,
  text: removeEmptyCmsText(data.detailText),
  media: extractMedia(data),
  localSettings: data.localSettings || {},
});

@Component({
  selector: 'fin-travel-guide',
  templateUrl: './travel-guide.component.html',
  styleUrls: ['./travel-guide.component.scss'],
})
export class TravelGuideComponent implements OnInit {
  @Input()
  content$: Observable<TravelGuide>;
  @Input()
  showLabel: boolean;
  @Input()
  showHeading: boolean;

  public readonly DOCTYPE: typeof CmsContentType = CmsContentType;

  outputContent$: Observable<TravelGuide>;
  text$: Observable<string>;
  videoUrl$: Observable<string>;
  phraseGroups$: Observable<PhraseGroup[]>;
  mediaType$: Observable<MediaType>;

  ngOnInit(): void {
    this.outputContent$ = this.content$.pipe(map(mapTravelGuide));
    this.mediaType$ = this.outputContent$.pipe(
      safeMap((content) => content.media),
      safeMap((media) => media.type)
    );

    this.text$ = this.outputContent$.pipe(
      safeMap(({ text }) => text),
      finShare()
    );

    this.videoUrl$ = this.outputContent$.pipe(
      safeMap((content) => content.media),
      filter((media) => isVideo(media)),
      safeMap((media: MediaVideo) => media.url)
    );

    this.phraseGroups$ = this.outputContent$.pipe(
      safeMap((tg: TravelGuide) => tg.localSettings),
      safeMap((ls) => ls.commonPhrases)
    );
  }
}
