/*
 * IF YOU UPDATE THIS, BE SURE TO UPDATE cfg/README.md too
 */

/*
 * For increased security, the overrides should only be allowed for parameters that
 * are necessary to be changed for preview environments.
 */

import { JsConfig } from '@fcom/core/interfaces';

import { getPreviewCmsUrl } from './cmsPreviewUrls';
import { getRunTimeConfigOverrides } from './runtimeConfig';

let versionInfo;
// Import version information
try {
  versionInfo = require('../../../../target/version.json');
} catch (e) {
  versionInfo = undefined;
}

const jsConfigOverride: Partial<JsConfig> = {
  versionInfo,
};

if (process.env.ENV) {
  // Default environment is local
  const processEnv = process.env.ENV || 'local';
  const overrides = getRunTimeConfigOverrides(processEnv);

  if (overrides) {
    jsConfigOverride.cmsEnv = overrides.cmsEnv;
    jsConfigOverride.isCmsPreview = overrides.cmsEnv?.indexOf('preview') !== -1;
    jsConfigOverride.enableCorsCredentials = jsConfigOverride.isCmsPreview;
    jsConfigOverride.sentryEnv = overrides.sentryEnv;
    jsConfigOverride.indexHtml = overrides.indexHtml;

    const previewUrl = getPreviewCmsUrl(jsConfigOverride.cmsEnv);
    if (previewUrl) {
      jsConfigOverride.cmsUrl = previewUrl;
    }
  }
}

/* Ensure that the values are not overwritten by undefined */
Object.keys(jsConfigOverride).forEach((key) => {
  if (typeof jsConfigOverride[key] === 'undefined') {
    delete jsConfigOverride[key];
  }
});

export { jsConfigOverride };
