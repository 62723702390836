import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { CmsDestination } from '@fcom/core-api';
import { OndMarketingOffersService } from '@fcom/ond-marketing-landing-core/services';

@Component({
  selector: 'cms-ond-marketing-offers',
  template: `
    <fin-ond-marketing-offers [destinationLocationCode$]="destinationLocationCode$"></fin-ond-marketing-offers>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsOndMarketingOffersComponent implements OnInit {
  @Input()
  content$: Observable<CmsDestination>;

  destinationLocationCode$: Observable<string>;

  constructor(private ondMarketingOffersService: OndMarketingOffersService) {}

  ngOnInit(): void {
    this.destinationLocationCode$ = this.ondMarketingOffersService.getDestinationLocationCode(this.content$);
  }
}
