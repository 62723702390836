import { ChangeDetectionStrategy, Component } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';

import { ExpandComponent } from '../..';
import { NavigationMenuService } from '../../../services';
import { MenuItem, ElementTypes, GaContext } from '../../../interfaces';

@Component({
  selector: 'fin-light-section-navi',
  styleUrls: ['./light-section-navi.component.scss'],
  templateUrl: './light-section-navi.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LightSectionNaviComponent {
  readonly ElementTypes = ElementTypes;
  readonly activeNaviSection$: Observable<MenuItem>;
  readonly activeNaviItem$: Observable<MenuItem>;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TRACKING_CONTEXT = GaContext.SECTION_NAVIGATION;

  constructor(private navigationMenuService: NavigationMenuService) {
    this.activeNaviSection$ = this.navigationMenuService.activeNaviSection;
    this.activeNaviItem$ = this.navigationMenuService.activeNaviItem;
  }

  trackItems(index: number) {
    return index;
  }

  toggleDetails(expandComponent: ExpandComponent, external: boolean): void {
    if (!external) {
      expandComponent.toggleDetails();
    }
  }
}
