import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { CmsDataService } from '@fcom/core/services';
import { CmsContent, CmsTeaser } from '@fcom/core-api/interfaces';

/**
 * Used for rendering fragments from CMS whose styling is left entirely to the
 * responsibility of the UX.
 */
@Component({
  selector: 'cms-unstyled-fragment',
  styleUrls: ['./cms-unstyled-fragment.component.scss'],
  templateUrl: './cms-unstyled-fragment.component.html',
})
export class CmsUnstyledFragmentComponent implements OnInit {
  @Input()
  contentUrl$: Observable<string>;
  @Input()
  cmsContent$: Observable<CmsContent>;
  @Input()
  showIcon = false;
  @Input()
  showTitle = true;

  content$: Observable<CmsTeaser>;

  constructor(private cmsDataService: CmsDataService) {}

  ngOnInit(): void {
    this.content$ = this.cmsContent$
      ? (this.cmsContent$ as Observable<CmsTeaser>)
      : this.contentUrl$.pipe(
          filter(Boolean),
          switchMap((url: string) => this.cmsDataService.getFragmentJson<CmsTeaser>(url))
        );
  }
}
