export const latinizeRegex = /[éáűőúöüóíÉÁŰPŐÚÖÜÓÍ]/g;
export const latinizeMap = {
  é: 'e',
  á: 'a',
  ű: 'u',
  ő: 'o',
  ú: 'u',
  ö: 'o',
  ü: 'u',
  ó: 'o',
  í: 'i',
  É: 'E',
  Á: 'A',
  Ű: 'U',
  Ő: 'O',
  Ú: 'U',
  Ö: 'O',
  Ü: 'U',
  Ó: 'O',
  Í: 'I',
};
export const latinizeRegexLight = /[öäüáéÖÄÜÁÉ]/g;
export const latinizeMapLight = {
  ä: 'a',
  ö: 'o',
  ü: 'u',
  á: 'a',
  é: 'e',
  Ä: 'A',
  Ö: 'O',
  Ü: 'U',
  Á: 'A',
  É: 'E',
};

/**
 * provide latinize regex and mapping object to initialize util function to your scope
 */
export function latinize(re, obj) {
  const translateRegex = re;
  const translateObj = obj;
  return function (s: string) {
    if (!s) {
      return null;
    }

    return s.replace(translateRegex, function (match) {
      return translateObj[match];
    });
  };
}
