import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  Renderer2,
  ViewChild,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';

import { Observable, Subscription } from 'rxjs';
import { scan, filter, map } from 'rxjs/operators';

import { WindowRef } from '@fcom/core/providers/window';
import { unsubscribe } from '@fcom/core/utils';

const zeroHeight = '0';
const identity = (a) => a;
const negate = (a) => !a;
const risingEdge = (previousValue: boolean, newValue: boolean) => previousValue === false && newValue === true;

export enum TARGET_HEIGHT_ELEMENT {
  PARENT = 'parent',
  CHILDREN = 'children',
}

@Component({
  selector: 'fin-expand-content',
  styleUrls: ['./expand-content.component.scss'],
  templateUrl: './expand-content.component.html',
})
export class ExpandContentComponent implements AfterViewInit, OnDestroy {
  static ANIMATION_DURATION = 200;
  static HIDING_CLASS = 'is-softlyhidden';
  static TRANSITION_CLASS = 'height-transition';

  @Input()
  open$: Observable<boolean>;
  @Input()
  initialValue = false;

  @Input()
  expandContainerClass?: string | string[];

  @Input()
  expandContentClass?: string | string[];

  @Input()
  targetHeightElement: TARGET_HEIGHT_ELEMENT = TARGET_HEIGHT_ELEMENT.CHILDREN;

  @ViewChild('container', { static: true })
  container: ElementRef;
  @ViewChild('content', { static: true })
  content: ElementRef;

  private subscription: Subscription = new Subscription();
  private frameReference: number;
  private timeoutReference: ReturnType<typeof setTimeout>;

  constructor(
    private renderer: Renderer2,
    private window: WindowRef,
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  ngAfterViewInit(): void {
    const opening$ = this.open$.pipe(scan(risingEdge, this.initialValue), filter(identity));

    const closing$ = this.open$.pipe(map(negate), scan(risingEdge, !this.initialValue), filter(identity));

    if (this.initialValue) {
      this.removeHidingClass();
      this.setContainerAutoHeight();
    } else {
      this.addHidingClass();
      this.setContainerZeroHeight();
    }

    if (isPlatformServer(this.platform)) {
      return;
    }

    // After setting the initial value, we add the transition class
    this.renderer.addClass(this.container.nativeElement, ExpandContentComponent.TRANSITION_CLASS);

    const w = this.window.nativeWindow;
    this.subscription.add(
      opening$.subscribe(() => {
        this.renderer.addClass(this.container.nativeElement, ExpandContentComponent.TRANSITION_CLASS);
        clearTimeout(this.timeoutReference);
        this.ngZone.runOutsideAngular(() => {
          this.frameReference = w.requestAnimationFrame(() => {
            this.removeHidingClass();
            this.setContainerZeroHeight();
            this.frameReference = w.requestAnimationFrame(() => {
              this.setContainerCalculatedHeight();
            });
          });
        });
        this.timeoutReference = setTimeout(() => {
          this.setContainerAutoHeight();
          this.renderer.removeClass(this.container.nativeElement, ExpandContentComponent.TRANSITION_CLASS);
        }, ExpandContentComponent.ANIMATION_DURATION);
      })
    );

    this.subscription.add(
      closing$.subscribe(() => {
        this.renderer.addClass(this.container.nativeElement, ExpandContentComponent.TRANSITION_CLASS);
        clearTimeout(this.timeoutReference);
        this.ngZone.runOutsideAngular(() => {
          this.frameReference = w.requestAnimationFrame(() => {
            this.setContainerCalculatedHeight();
            this.frameReference = w.requestAnimationFrame(() => {
              this.setContainerZeroHeight();
            });
          });
        });
        this.timeoutReference = setTimeout(() => {
          this.addHidingClass();
        }, ExpandContentComponent.ANIMATION_DURATION);
      })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
    clearTimeout(this.timeoutReference);
    if (isPlatformBrowser(this.platform)) {
      this.window.nativeWindow.cancelAnimationFrame(this.frameReference);
    }
  }

  private removeHidingClass = () => {
    this.renderer.removeClass(this.content.nativeElement, ExpandContentComponent.HIDING_CLASS);
  };

  private addHidingClass = () => {
    this.renderer.addClass(this.content.nativeElement, ExpandContentComponent.HIDING_CLASS);
  };

  private setContainerCalculatedHeight = () => {
    if (this.content.nativeElement) {
      const targetHeight =
        this.targetHeightElement === TARGET_HEIGHT_ELEMENT.CHILDREN
          ? this.content.nativeElement.children[0].offsetHeight
          : this.content.nativeElement.offsetHeight;
      this.renderer.setStyle(this.container.nativeElement, 'height', `${targetHeight}px`);
    }
  };

  private setContainerZeroHeight = () => {
    this.renderer.setStyle(this.container.nativeElement, 'height', zeroHeight);
  };

  private setContainerAutoHeight = () => {
    this.renderer.setStyle(this.container.nativeElement, 'height', 'auto');
  };
}
