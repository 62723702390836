import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';

import { EMPTY, Observable, Subject } from 'rxjs';
import { filter, share, take } from 'rxjs/operators';

type ObserverOptions = {
  root: Element | null;
  rootMargin?: string;
  threshold?: number | number[];
};

@Injectable({
  providedIn: 'root',
})
export class ScrolledPastService {
  private observer: IntersectionObserver;
  private intersectionSubject = new Subject<IntersectionObserverEntry>();
  private intersectionSubject$ = this.intersectionSubject.asObservable().pipe(share());

  constructor(@Inject(PLATFORM_ID) private platform: object) {}

  listenToElement(element: Element, offset?: number): Observable<IntersectionObserverEntry> {
    if (isPlatformServer(this.platform)) {
      return EMPTY;
    }

    if (!this.observer) {
      const options: ObserverOptions = {
        root: null,
      };
      if (offset) {
        options.rootMargin = `${offset}px`;
      }

      this.observer = new IntersectionObserver(this.loadingCallback.bind(this), options);

      // FixMe: polyfill hack to poll for intersection. Without this the observer listens to window scroll and resize events
      (this.observer as any).POLL_INTERVAL = 200;
    }

    this.observer.observe(element);

    return new Observable<IntersectionObserverEntry>((obs) => {
      this.intersectionSubject$
        .pipe(
          filter((entry) => entry.target === element),
          take(1)
        )
        .subscribe((entry) => {
          obs.next(entry);
          obs.complete();
          this.observer.unobserve(element);
        });
    });
  }

  private loadingCallback(entries: IntersectionObserverEntry[]) {
    entries.filter((entry) => entry.isIntersecting).forEach((entry) => this.intersectionSubject.next(entry));
  }
}
