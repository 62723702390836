import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CmsContentType, CmsFormType, CmsTemplate, CmsViewType, TemplateName, CmsContent } from '@fcom/core-api';

enum Placement {
  HERO = 'hero',
  MAIN = 'main',
  MAIN_RIGHT = 'main-right',
  HIDDEN = 'hidden',
}

const setCustomPlacement = (content: CmsContent): string => {
  switch (content.template) {
    case TemplateName.CMS_DESTINATION_HERO:
      return Placement.HERO;
    case TemplateName.CMS_TRAVEL_GUIDE:
      return Placement.MAIN_RIGHT;
    case TemplateName.CMS_ITEMS:
      return Placement.MAIN;
    default: {
      if (content.viewType === CmsViewType.LINK_LIST_WITH_ICONS) {
        return Placement.MAIN_RIGHT;
      }
      return null;
    }
  }
};

const hasCustomPlacement = (content: CmsContent): boolean => {
  switch (content.template) {
    case TemplateName.CMS_DESTINATION_HERO:
    case TemplateName.CMS_TRAVEL_GUIDE:
      return true;
    default:
      if (content.viewType === CmsViewType.LINK_LIST_WITH_ICONS) {
        return true;
      }
      return false;
  }
};

const isTeaserWithItems = (item: CmsContent): boolean => {
  return item.contentType === CmsContentType.CMTeaser && Array.isArray(item.items) && item.items.length > 0;
};

const filterNonCustomPlacement = (data: CmsContent[]): CmsContent[] => {
  const filteredItems = [...data];
  return filteredItems
    .filter((item) => isTeaserWithItems(item) || !hasCustomPlacement(item))
    .map((item) => {
      if (isTeaserWithItems(item)) {
        item.items = filterNonCustomPlacement(item.items);
      }
      return item;
    });
};

const addCustomPlacement = (item: CmsContent): CmsContent => {
  const content: CmsContent = {
    ...item,
    customPlacement: setCustomPlacement(item),
  };
  if (item.contentType === CmsContentType.CMTeaser && Array.isArray(item.items) && item.items.length) {
    return {
      ...content,
      items: item.items.map(addCustomPlacement),
    };
  }
  return content;
};

const filterRegularContent = (data: CmsTemplate): CmsTemplate => {
  return {
    header: data.header,
    main: filterNonCustomPlacement(data.main.map(addCustomPlacement)),
    footer: data.footer,
  };
};

const filterMainLeftContent = (data: CmsTemplate): CmsContent => {
  for (const item of data.main.map(addCustomPlacement)) {
    if (item.customPlacement === Placement.MAIN && item.items) {
      return item.items[0];
    }
  }
};

const filterMainRightContent = (data: CmsTemplate): CmsContent[] => {
  return data.main
    .map(addCustomPlacement)
    .filter((item) => item.customPlacement === Placement.MAIN && item.items)
    .map((item) => item.items)
    .reduce((accumulatedChildItems, childItems) => {
      return accumulatedChildItems.concat(
        childItems.filter((item, index) => item.customPlacement === Placement.MAIN_RIGHT && index > 0)
      );
    }, []);
};

const filterHeroContent = (data: CmsTemplate): CmsContent => {
  return data.main.map(addCustomPlacement).find((item) => item.customPlacement === Placement.HERO);
};

@Component({
  selector: 'cms-destination',
  templateUrl: './cms-destination.component.html',
  styleUrls: ['./cms-destination.component.scss'],
})
export class CmsDestinationComponent implements OnInit {
  @Input()
  template$: Observable<CmsTemplate>;
  @Input()
  pageViewType$: Observable<string>;

  hero$: Observable<CmsContent>;
  mainLeft$: Observable<CmsContent>;
  mainRight$: Observable<CmsContent[]>;
  remainingContent$: Observable<CmsTemplate>;

  public readonly DOCTYPE: typeof CmsContentType = CmsContentType;
  public readonly VIEWTYPE: typeof CmsViewType = CmsViewType;
  public readonly FORMTYPE: typeof CmsFormType = CmsFormType;

  ngOnInit(): void {
    this.hero$ = this.template$.pipe(map(filterHeroContent));
    this.mainLeft$ = this.template$.pipe(map(filterMainLeftContent));
    this.mainRight$ = this.template$.pipe(map(filterMainRightContent));
    this.remainingContent$ = this.template$.pipe(map(filterRegularContent));
  }
}
