/* eslint-disable no-underscore-dangle */
import { createFeatureSelector, createSelector, select } from '@ngrx/store';

import { safeSelect, StateSelector } from '@fcom/core/selectors/selector-utils';
import {
  Category,
  FinnairChangeEligibility,
  FinnairCheckInEligibility,
  FinnairItineraryItemFlight,
  FinnairOrder,
  FinnairPassengerItem,
  FinnairServiceBoundItem,
  FinnairServiceItem,
} from '@fcom/dapi/api/models';
import { DapiErrorStatus, IdAndHash } from '@fcom/dapi';
import { BookingAppState } from '@fcom/common/interfaces/booking';
import { cloneDeep } from '@fcom/core/utils';

import {
  CommonBookingFeatureState,
  CommonBookingState,
  OrderPartStatus,
  OrderState,
  OrderStatus,
  PassengerNameRecord,
  QuantityForFragmentAndPassenger,
  UpdatingOrderPart,
} from '../store.interface';
import { COMMON_BOOKING_FEATURE_KEY } from '../constants';
import { mapServicesToBookingSummary } from '../utils/services.utils';
import { BookingSummaryService } from '../../interfaces';
import { combineServices, isBoundBasedCategory } from '../../modules/ancillaries/utils';
import { isFlight } from '../../utils/common-booking.utils';

const _selectCommonBookingState = createFeatureSelector<CommonBookingFeatureState, CommonBookingState>(
  COMMON_BOOKING_FEATURE_KEY
);
const _orderState = createSelector(_selectCommonBookingState, (state: CommonBookingState) => state?.order);
const _orderStatus = createSelector(_orderState, (_os) => _os?.orderStatus);
const _orderResponseErrorStatus = createSelector(_orderState, (_os) => _os?.orderResponseErrorStatus);
const _orderData = createSelector(_orderState, (_os) => _os?.orderData);
const _hasOrderData = createSelector(_orderState, (_os) => !!_os?.orderData);

const _orderDataPrimaryPassenger = createSelector(
  _orderData,
  (_od) => _od?.passengers.find((p) => p.id === _od.primaryTravelerId) || ({} as FinnairPassengerItem)
);
const _getOrderPassengers = createSelector(_orderData, (_od) => _od?.passengers.map((pax) => pax.id));
const _getCheckInEligibilities = createSelector(_orderData, (_od) => _od?.eligibilities?.checkIn || []);
const _orderFetchInformation = createSelector(_orderState, (_os) => _os?.orderFetchInformation);
const _PNR = createSelector(_orderState, (_os) => _os?.PNR);
const _categoryPartStatus = (category: Category) =>
  createSelector(_orderState, (_os: OrderState) => _os?.isUpdating?.[category] ?? OrderPartStatus.OK);
const _updatingOrderPart = () => createSelector(_orderState, (_os: OrderState) => _os?.isUpdating);
const _currentServices = createSelector(_orderData, (data) =>
  mapServicesToBookingSummary(data?.prices?.unpaid?.services, data?.passengers)
);
const _includedServices = (category: Category) =>
  createSelector(
    _orderData,
    (_od) => _od?.services?.included?.filter((service) => service.category === category) || ([] as FinnairServiceItem[])
  );
const _getUnpaidSeatsBySegmentId = (segmentId: string) =>
  createSelector(_orderData, (_od) => {
    return (
      _od?.services?.unpaid
        ?.flatMap((service) => (service.category === Category.SEAT ? service.bounds : []))
        .filter((bound) => bound.segments.some((segment) => segment.id === segmentId)) || []
    );
  });
const _includedVariantsQuantity = (variants: string[]) =>
  createSelector(_orderData, (order): QuantityForFragmentAndPassenger => {
    const included = order?.services?.included ?? [];
    return included.reduce((allCategoriesQuantity, selection: FinnairServiceItem) => {
      return selection.bounds
        .flatMap((bound) =>
          (isBoundBasedCategory(selection.category) ? [bound.segments[0]] : bound.segments).map((segment) => ({
            ...segment,
            bound,
          }))
        )
        .flatMap((segment) => segment.passengers.map((passengerService) => ({ ...passengerService, segment })))
        .flatMap((passenger) =>
          passenger.services
            .filter((service) => variants.includes(service.variant))
            .map((service) => {
              const fragmentId = isBoundBasedCategory(selection.category)
                ? passenger.segment.bound.id
                : passenger.segment.id;
              return { ...service, fragmentId, passenger };
            })
        )
        .reduce((fragmentQuantity, { fragmentId, quantity, passenger }) => {
          if (!fragmentQuantity[fragmentId]) {
            fragmentQuantity[fragmentId] = {};
          }
          fragmentQuantity[fragmentId][passenger.id] = quantity + (fragmentQuantity[fragmentId][passenger.id] ?? 0);
          return fragmentQuantity;
        }, allCategoriesQuantity);
    }, {});
  });

const _includedServicesMap = createSelector(
  _orderData,
  (_od) =>
    _od?.services?.included?.reduce((categories, service) => {
      categories[service.category] = cloneDeep(service);
      return categories;
    }, {}) ?? {}
);
const _combinedServices = createSelector(_orderData, (_od) =>
  combineServices([_od?.services?.included ?? [], _od?.services?.unpaid ?? []])
);
const _combinedServicesMap = createSelector(_combinedServices, (_services) =>
  _services.reduce((categories, service) => {
    categories[service.category] = cloneDeep(service);
    return categories;
  }, {})
);
const _orderFlights = () =>
  createSelector(
    _orderData,
    (od) => od?.bounds?.reduce((all, b) => all.concat(...b.itinerary.filter(isFlight)), []) ?? []
  );
const _acceptTerms = createSelector(_orderState, (_os: OrderState) => !!_os?.acceptTerms);
const _changeEligibilities = createSelector(_orderData, (_od: FinnairOrder) => _od?.eligibilities?.change ?? []);
const _changeableBoundIds = createSelector(_changeEligibilities, (_ce: Array<FinnairChangeEligibility>) =>
  _ce?.filter(({ isAllowedToUse }) => isAllowedToUse).map(({ id }) => id)
);

export const orderState = (): StateSelector<CommonBookingFeatureState, OrderState> => select(_orderState);
export const orderStatus = (): StateSelector<CommonBookingFeatureState, OrderStatus> => safeSelect(_orderStatus);
export const orderResponseErrorStatus = (): StateSelector<CommonBookingFeatureState, DapiErrorStatus> =>
  safeSelect(_orderResponseErrorStatus);
export const orderData = (emitUndefined = false): StateSelector<CommonBookingFeatureState, FinnairOrder> =>
  !emitUndefined ? safeSelect(_orderData) : select(_orderData);
export const hasOrderData = (): StateSelector<CommonBookingFeatureState, boolean> => select(_hasOrderData);
export const orderFlights = (): StateSelector<CommonBookingFeatureState, FinnairItineraryItemFlight[]> =>
  safeSelect(_orderFlights());

export const orderDataPrimaryPassenger = (): StateSelector<CommonBookingFeatureState, FinnairPassengerItem> =>
  select(_orderDataPrimaryPassenger);
export const getOrderPassengers = (): StateSelector<CommonBookingFeatureState, string[]> => select(_getOrderPassengers);
export const getCheckInEligibilities = (): StateSelector<CommonBookingFeatureState, FinnairCheckInEligibility[]> =>
  select(_getCheckInEligibilities);
export const PNR = (): StateSelector<CommonBookingFeatureState, PassengerNameRecord> => select(_PNR);
export const categoryPartStatus = (category: Category): StateSelector<CommonBookingFeatureState, OrderPartStatus> =>
  safeSelect(_categoryPartStatus(category));
export const updatingOrderPart = (): StateSelector<CommonBookingFeatureState, UpdatingOrderPart> =>
  select(_updatingOrderPart());
export const currentServices = (): StateSelector<BookingAppState, BookingSummaryService[]> =>
  safeSelect(_currentServices);
export const includedServices = (category: Category): StateSelector<CommonBookingFeatureState, FinnairServiceItem[]> =>
  safeSelect(_includedServices(category));
export const includedServicesMap = (): StateSelector<
  CommonBookingFeatureState,
  { [category: string]: FinnairServiceItem }
> => safeSelect(_includedServicesMap);

export const combinedServicesMap = (): StateSelector<
  CommonBookingFeatureState,
  { [category: string]: FinnairServiceItem }
> => safeSelect(_combinedServicesMap);

export const getChangeableBoundIds = (): StateSelector<CommonBookingFeatureState, string[]> =>
  select(_changeableBoundIds);

export const includedVariantsQuantity = (
  variants: string[]
): StateSelector<CommonBookingFeatureState, QuantityForFragmentAndPassenger> =>
  select(_includedVariantsQuantity(variants));

/**
 * To be used when fetching order information from the backend first time
 */
export const orderFetchInformation = (): StateSelector<CommonBookingFeatureState, IdAndHash> =>
  select(_orderFetchInformation);

export const acceptTerms = (): StateSelector<CommonBookingFeatureState, boolean> => safeSelect(_acceptTerms);

export const getUnpaidSeatsBySegmentId = (
  segmentId: string
): StateSelector<CommonBookingFeatureState, FinnairServiceBoundItem[]> =>
  safeSelect(_getUnpaidSeatsBySegmentId(segmentId));
