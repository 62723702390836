import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'fin-tag',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
  @Input()
  tagTitle: string;

  @Input()
  tagUrl: string;

  constructor() {}
}
