import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

import { isPresent } from '@fcom/core/utils';

@Component({
  selector: 'fin-form-group',
  styleUrls: ['form-group.component.scss'],
  templateUrl: 'form-group.component.html',
})
export class FormGroupComponent {
  @Input()
  group: UntypedFormGroup | AbstractControl;
  @Input()
  names: string[];
  @Input()
  translationPrefix: string;
  @Input()
  groupTranslationPrefix: string;
  @Input()
  legend: string = undefined;
  @Input()
  inline = false;
  @Input()
  required: boolean;
  @Input()
  showOnlyGroupLevelErrors = false;

  firstErrorKey(name?: string): string {
    const errors = this.showOnlyGroupLevelErrors || !isPresent(name) ? this.group.errors : this.group.get(name).errors;

    if (!errors) {
      return undefined;
    }
    return Object.keys(errors).filter((k) => errors[k])[0];
  }
}
