import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { LocationInfoMap } from '@fcom/dapi';
import { FinnairBoundItem } from '@fcom/dapi/api/models';
import { AppConstants } from '@fcom/booking/app-constants';
import { NotificationLayout, NotificationTheme } from '@fcom/ui-components';
import { SelectionPhase } from '@fcom/common/interfaces/booking';

@Component({
  selector: 'fin-itinerary',
  templateUrl: './itinerary.component.html',
  styleUrls: ['./arrow-block.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryComponent implements OnInit {
  public readonly OUTBOUND = AppConstants.BoundType.outbound;
  public readonly INBOUND = AppConstants.BoundType.inbound;
  public readonly NotificationTheme = NotificationTheme;
  public readonly NotificationLayout = NotificationLayout;
  public readonly SelectionPhase = SelectionPhase;
  @Input()
  outbound$: Observable<FinnairBoundItem>;
  @Input()
  inbound$: Observable<FinnairBoundItem>;
  @Input()
  locations$: Observable<LocationInfoMap>;
  @Input({ required: true })
  selectionPhase$: Observable<SelectionPhase>;
  @Input()
  isBookingFlow = false;

  hasAirportChangeBetweenBounds$: Observable<boolean>;

  ngOnInit(): void {
    this.hasAirportChangeBetweenBounds$ = combineLatest([
      this.outbound$.pipe(filter(Boolean)),
      this.inbound$.pipe(filter(Boolean)),
    ]).pipe(
      map(
        ([outbound, inbound]: [FinnairBoundItem, FinnairBoundItem]) =>
          outbound.arrival.locationCode !== inbound.departure.locationCode
      ),
      startWith(false)
    );
  }
}
