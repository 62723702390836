/* eslint-disable */
export enum FinnairCancelFlowType {
  RULE_24_HOUR = 'RULE_24_HOUR',
  MANUAL_REFUNDABLE = 'MANUAL_REFUNDABLE',
  INVOLUNTARY = 'INVOLUNTARY',
  AUTOMATIC_INVOLUNTARY = 'AUTOMATIC_INVOLUNTARY',
  MANUAL_INVOLUNTARY = 'MANUAL_INVOLUNTARY',
  INVOLUNTARY_NO_REFUND = 'INVOLUNTARY_NO_REFUND'
}

