<div *ngIf="(ondOffers$ | async)?.length > 0" class="mr-large-y">
  <h2 class="mr-small-b font-heading-3 nordic-blue-900-text" *ngIf="heading$ | async">
    {{ heading$ | async }}
  </h2>

  <div *ngIf="sortOptions$ | async as options" class="fill align-right mr-small-b">
    <select
      class="nordic-blue-900-text medium-type focus-inset"
      name="selectedSortBy"
      (change)="sortOffers($any($event.target).value)"
    >
      <option *ngFor="let option of options" [value]="option.value" [selected]="option.value === defaultSort">
        {{ option.label }}
      </option>
    </select>
  </div>

  <div class="ms-xxsmall-t">
    <div
      *ngFor="let ondOffer of ondOffers$ | async | slice: 0 : offerAmount; let i = index"
      class="offer-list-item flex border rounded grey-50-bg pr-xsmall mr-xxsmall-b animate-appear ond-list-item"
      [ngClass]="
        i + 1 > offerAmount - moreOffersAmount && !dynamicAnimationDisabled
          ? 'animate-appear--' + (i - offerAmount + moreOffersAmount)
          : ''
      "
    >
      <div class="flex-1 flex--basis0 flex--self-center pr-medium-r">
        <div class="light-type font-body-1 ms-xxsmall-b" data-testid="offer-list-location-codes">
          {{ ondOffer?.originLocationCode }} – {{ ondOffer?.destinationLocationCode }}
        </div>

        <div class="font-heading-3 nordic-blue-900-text bold-type ms-xxsmall-b">
          {{ ondOffer.originCityName }} -
          {{ ondOffer.destinationCityName }}
        </div>

        <div class="light-type font-body-2">
          {{ ondOffer?.departureDate | finDateFormat: 'FULL' }} – {{ ondOffer?.returnDate | finDateFormat: 'FULL' }}
        </div>
      </div>

      <div class="flex-1 flex--basis0 flex--self-center flex--column align-right pr-medium-l">
        <div class="mr-xsmall-b">
          <div class="nordic-blue-900-text light-type font-body-1 lowercase">
            {{ 'pricesStartingFrom' | finLocalization }}
          </div>

          <span class="heather-700-text font-heading-2 bold-type inline-block animate-pulse">
            {{ ondOffer?.price | finPrice: { roundPrice: true } }}
          </span>
        </div>

        <a
          [finLink]="ondOffer?.link"
          class="offer-list-item__link bold-type font-body-1 nordic-blue-900-text no-underline underline-hover"
        >
          {{ 'bookNow' | finLocalization }}
          <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.ARROW_RIGHT"></fcom-icon>
        </a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="(ondOffers$ | async)?.length > offerAmount" class="align-center">
  <button class="btn bold-type" (click)="loadMoreOffers()">{{ 'ondMarketing.loadMore' | finLocalization }}</button>
</div>
