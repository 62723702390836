import { Pipe, PipeTransform } from '@angular/core';

import { stripSpaces } from '@fcom/ui-components/utils/string.utils';

const CARD_NUMBER_LENGTH = 16;

@Pipe({
  name: 'finFormatCardNumber',
})
export class FormatCardNumberPipe implements PipeTransform {
  /**
   * Formats card numbers to a human readable format.
   *
   * @example '1234567812345678' (16) => '1234 5678 1234 5678'
   */
  transform(value: string): string {
    if ('string' !== typeof value) {
      throw new TypeError('Card number must be a string.');
    }
    return this.formatCardNumber(value);
  }

  private formatCardNumber(value: string): string {
    return value.length === CARD_NUMBER_LENGTH
      ? stripSpaces(value)
          .match(/.{1,4}/g)
          .join(' ')
      : value;
  }
}
