<fcom-modal
  class="survey-modal"
  [buttons]="null"
  [open]="modalOpen"
  [title]="'feedback.title' | finLocalization"
  (close)="onClose()"
>
  <iframe
    #surveyContainer
    class="ps-large"
    (load)="surveyLoaded()"
    [ngClass]="survey.name + '-iframe'"
    [title]="'feedback.title' | finLocalization"
    [src]="qualtricsUrl$ | async | finSafe: 'resource'"
  >
  </iframe>
</fcom-modal>
