import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { FareFamily, FinnairItineraryItemFlight } from '@fcom/dapi/api/models';
import { ElementTypes, GaContext } from '@fcom/common/interfaces';

import { isByBusOnly } from '../../../utils/common-booking.utils';

@Component({
  selector: 'fin-fare-family-details',
  templateUrl: './fare-family-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./fare-family-details.component.scss'],
})
export class FareFamilyDetailsComponent implements OnChanges {
  readonly ElementTypes: typeof ElementTypes = ElementTypes;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TRACKING_CONTEXT = GaContext.FLIGHT_SELECTION;

  @Input()
  fareFamilies: FareFamily[];

  @Input()
  itineraries?: FinnairItineraryItemFlight[][];

  @Input()
  showKoreaNotification: boolean;

  hasBusLeg = false;

  ngOnChanges(): void {
    this.hasBusLeg = this.itineraries?.some((itineraries) => {
      return itineraries.some((itinerary) => isByBusOnly(itinerary));
    });
  }
}
