export interface KeyCode {
  keyCode: number;
  key: string[];
}
export class KeyCodes {
  static ESC: KeyCode = { keyCode: 27, key: ['Escape'] };
  static SPACE: KeyCode = { keyCode: 32, key: ['Spacebar', ' '] };
  static ENTER: KeyCode = { keyCode: 13, key: ['Enter'] };
  static DOWN: KeyCode = { keyCode: 40, key: ['ArrowDown', 'Down'] };
  static UP: KeyCode = { keyCode: 38, key: ['ArrowUp', 'Up'] };
  static LEFT: KeyCode = { keyCode: 37, key: ['ArrowLeft', 'Left'] };
  static RIGHT: KeyCode = { keyCode: 39, key: ['ArrowRight', 'Right'] };
  static PAGE_UP: KeyCode = { keyCode: 33, key: ['PageUp'] };
  static PAGE_DOWN: KeyCode = { keyCode: 34, key: ['PageDown'] };
  static END: KeyCode = { keyCode: 35, key: ['End'] };
  static HOME: KeyCode = { keyCode: 36, key: ['Home'] };
  static TAB: KeyCode = { keyCode: 9, key: ['Tab'] };
  static KEYCODES_ARRAY: KeyCode[] = [
    KeyCodes.ESC,
    KeyCodes.SPACE,
    KeyCodes.ENTER,
    KeyCodes.DOWN,
    KeyCodes.UP,
    KeyCodes.LEFT,
    KeyCodes.RIGHT,
    KeyCodes.PAGE_UP,
    KeyCodes.PAGE_DOWN,
    KeyCodes.END,
    KeyCodes.HOME,
    KeyCodes.TAB,
  ];
}

export const isKeyboardEvent = (event: KeyboardEvent, keyCode: KeyCode): boolean => {
  if (event.key !== undefined) {
    return keyCode.key.indexOf(event.key) > -1;
  } else if (event.keyCode !== undefined) {
    return event.keyCode === keyCode.keyCode;
  }
  return false;
};

export const getKeyCodeFromEvent = (event: KeyboardEvent): KeyCode => {
  return KeyCodes.KEYCODES_ARRAY.find(
    (keyCode: KeyCode) => keyCode.keyCode === event.keyCode || keyCode.key.indexOf(event.key) > -1
  );
};
