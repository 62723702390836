import { FinnairBoundItem } from '@fcom/dapi/api/models';

/**
 * Determine if the the booking is complex (not return or one way flight)
 * @param bounds all bounds in the booking
 */
export const isComplexBooking = (bounds: FinnairBoundItem[]): boolean => {
  return (
    bounds.length > 2 || (bounds.length === 2 && bounds[0].departure.locationCode !== bounds[1].arrival.locationCode)
  );
};
