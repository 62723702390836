<section *ngIf="content$ | async as content" data-testid="list-with-heading">
  <h2
    *ngIf="content.teaserTitle"
    [class.section-marker]="!content.subjectTaxonomyTags.includes(TAXONOMY.NO_SECTION_MARKER)"
    class="pr-large-t"
  >
    {{ content.teaserTitle }}
  </h2>
  <fin-cms-json-content
    *ngFor="let item of content.items"
    [template$]="{ main: [item] } | finToObservable"
  ></fin-cms-json-content>
</section>
