import { createAction, props, union } from '@ngrx/store';

import { MultiCityOffer, MultiCityState, MultiCityStatus } from '@fcom/common/interfaces/booking';
import { Bound, FinnairLocation } from '@fcom/dapi/api/models';

export const setMultiCityOffers = createAction('[MultiCityActions] setOffers', props<{ offers: MultiCityState }>());
export const addMultiCityOffers = createAction(
  'MultiCityActions] addOffers',
  props<{
    offers: MultiCityOffer[];
    bounds: {
      [key: string]: Bound;
    };
    hashMap: {
      [key: string]: string;
    };
    locations: {
      [key: string]: FinnairLocation;
    };
  }>()
);
export const setMultiCitySelectedFareFamily = createAction(
  '[MultiCityActions setSelectedFareFamily',
  props<{
    fareFamily: string;
  }>()
);

export const addMultiCitySelectedItinerary = createAction(
  '[MultiCityActions] addSelectedItinerary',
  props<{ itinerary: string }>()
);
export const setMultiCitySelectedItinerary = createAction(
  '[MultiCityActions] setSelectedItinerary',
  props<{ itinerary: string[] }>()
);

export const reset = createAction('[MultiCityActions] reset');

export const resetSelections = createAction('[MultiCityActions] resetSelections');

export const setStatus = createAction('[MultiCityActions] setStatus', props<{ status: MultiCityStatus }>());

export const addRequestedMoreFlightOfferIds = createAction(
  '[MultiCityActions] addRequestedMoreFlightOfferIds',
  props<{
    offerId: string;
  }>()
);

const all = union({
  setMultiCityOffers,
  addMultiCityOffers,
  setMultiCitySelectedFareFamily,
  addMultiCitySelectedItinerary,
  setMultiCitySelectedItinerary,
  resetSelections,
  reset,
  setStatus,
  addRequestedMoreFlightOfferIds,
});

export type ActionsUnion = typeof all;
