import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { CmsCollection, CmsTarget, CmsTeaser } from '@fcom/core-api';
import { isNotEmpty, safeMap } from '@fcom/core/utils';

interface DescriptiveLink {
  title: string;
  text: string;
  target: CmsTarget;
  linkText: string;
}

@Component({
  selector: 'cms-descriptive-link-list',
  templateUrl: './cms-descriptive-link-list.component.html',
  styleUrls: ['./cms-descriptive-link-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsDescriptiveLinkListComponent implements OnInit {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  content$: Observable<CmsCollection>;
  items$: Observable<DescriptiveLink[]>;

  ngOnInit(): void {
    this.items$ = this.content$.pipe(
      map((content: CmsCollection) => safeMap(content, (c: CmsCollection) => c.items)),
      map((items: CmsTeaser[]) =>
        items.map((item) => ({
          title: item.teaserTitle,
          text: item.teaserText,
          target: item.target ? item.target : null,
          linkText: item.linkText,
        }))
      ),
      filter(isNotEmpty),
      distinctUntilChanged()
    );
  }
}
