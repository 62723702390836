import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnChanges, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { combineLatest, distinctUntilChanged, filter, map, Observable, Subscription } from 'rxjs';

import { ElementActions, ElementTypes, GaContext } from '@fcom/common';
import { TabTheme, TabLayoutType, RadioItemGroupOption } from '@fcom/ui-components';
import { GtmService } from '@fcom/common/gtm';
import { TypedFormGroup, TypedFormArray } from '@fcom/service-forms';

import { SortBy } from '../../interfaces';

@Component({
  selector: 'fin-flight-selection-sort-by',
  styleUrls: ['./flight-selection-sort-by.component.scss'],
  templateUrl: './flight-selection-sort-by.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlightSelectionSortByComponent implements OnChanges, OnInit {
  @Input()
  currencyCode: string;

  @Input()
  showPoints = false;

  @Input()
  resultsAmount: number;

  @Input()
  bestFlightPrice: string;

  @Input()
  fastestFlightPrice: string;

  @Input()
  lowestPrice: string;

  @Input()
  selectedSortBy$: Observable<SortBy>;

  @Input()
  isMobile$: Observable<boolean>;

  @Input()
  showBestFlightsTab = true;

  @Output()
  sortByChange: EventEmitter<SortBy> = new EventEmitter();

  shouldShowTabSubtitle = false;
  modalOpen = false;
  sortingForm: TypedFormGroup<UntypedFormArray | UntypedFormGroup | UntypedFormControl>;
  sortingOptions: RadioItemGroupOption[];
  allSortingOptions: RadioItemGroupOption[] = [
    { value: SortBy.BEST, label: 'flightSorting.bestFlights.plural', descriptionText: 'flightSorting.tooltip.text' },
    { value: SortBy.PRICE, label: 'flightSorting.lowestPrice' },
    { value: SortBy.DURATION, label: 'flightSorting.fastest' },
  ];
  subscriptions: Subscription = new Subscription();

  readonly SortBy = SortBy;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TabLayoutType = TabLayoutType;
  readonly TabTheme = TabTheme;

  constructor(
    private gtmService: GtmService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.sortingForm = this.fb.group({
      flightSorting: this.fb.control(''),
    }) as TypedFormGroup<TypedFormArray<UntypedFormGroup> | UntypedFormControl>;

    if (this.showBestFlightsTab) {
      this.sortingOptions = this.allSortingOptions;
    } else {
      this.sortingOptions = this.allSortingOptions.filter((option) => {
        return option.value !== SortBy.BEST;
      });
    }

    this.subscriptions.add(
      combineLatest([this.isMobile$, this.selectedSortBy$])
        .pipe(
          filter(([isMobile]) => isMobile === true),
          map(([_, selectedSortBy]) => {
            this.sortingForm.setValue({
              flightSorting: selectedSortBy,
            });
          }),
          distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
        )
        .subscribe()
    );

    this.onSortChangeFromModal();
  }

  ngOnChanges(): void {
    this.shouldShowTabSubtitle = this.resultsAmount !== 0;
  }

  onSortChangeFromTabs(event: { index: number; data: unknown }): void {
    this.gtmService.trackElement(
      `booking-flow-sort-change-${event.data}`,
      GaContext.BOOKING_FLOW,
      ElementTypes.TAB,
      undefined,
      ElementActions.CLICK
    );
    this.sortByChange.emit(event.data as SortBy);
  }

  onSortChangeFromModal(): void {
    this.subscriptions.add(
      this.sortingForm.valueChanges
        .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
        .subscribe((sort) => {
          this.gtmService.trackElement(
            `booking-flow-sort-change-${sort.flightSorting}`,
            GaContext.BOOKING_FLOW,
            ElementTypes.MODAL,
            undefined,
            ElementActions.CLICK
          );

          this.sortByChange.emit(sort.flightSorting);
        })
    );
  }

  openSortingModal(): void {
    this.modalOpen = true;
  }

  closeSortingModal(): void {
    this.modalOpen = false;
  }
}
