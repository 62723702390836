import { AfterContentInit, Directive, ElementRef, NgZone, OnDestroy } from '@angular/core';

import { getKeyCodeFromEvent, KeyCodes } from '@fcom/core/utils/keycodes';
import { isPresent } from '@fcom/core/utils/utils';

import { elementIsVisible } from '../../utils/dom.utils';

/**
 * Trap Focus directive.
 *
 * The fcomTrapFocus directive traps focus within an element.
 * This is intended to be used to create accessible experience for components like modal dialogs, where focus must be constrained.
 *
 * @example
 * <code>
 *   <pre>
 *    <div fcomTrapFocus>
 *      <p>Focus wont leave this area</p>
 *    </div>
 *   </pre>
 * </code>
 */
@Directive({
  selector: '[fcomTrapFocus]',
})
export class TrapFocusDirective implements AfterContentInit, OnDestroy {
  private removeFocusListener: () => void;

  constructor(
    private ngZone: NgZone,
    private el: ElementRef
  ) {}

  ngAfterContentInit(): void {
    this.ngZone.runOutsideAngular(() => {
      const keyDownListener = (event: KeyboardEvent): void => {
        return this.ngZone.run(() => {
          this.onKeyDown(event);
        });
      };

      this.el.nativeElement.addEventListener('keydown', keyDownListener);

      this.removeFocusListener = () => {
        this.el.nativeElement.removeEventListener('keydown', keyDownListener);
      };
    });
  }

  ngOnDestroy(): void {
    this.cleanUpEventHandlers();
  }

  onKeyDown(event: KeyboardEvent): void {
    if (!this.el.nativeElement) {
      return;
    }
    if (!(event.target instanceof Element)) {
      return;
    }

    const focusSelector = 'button, [href], input, select, textarea, [tabindex]';
    const focusableElements: Element[] = Array.from(
      (this.el.nativeElement as HTMLElement).querySelectorAll(focusSelector)
    )
      .filter((e) => e instanceof HTMLElement)
      .filter((e: HTMLElement) => !isPresent(e.getAttribute('disabled')) && e.getAttribute('tabindex') !== '-1')
      .filter((e: HTMLElement) => elementIsVisible(e, true));

    // shift + tab key
    // reverse focus
    if (KeyCodes.TAB === getKeyCodeFromEvent(event) && event.shiftKey && focusableElements.length > 0) {
      const maxIndex = focusableElements.length - 1;
      event.preventDefault();
      const index = focusableElements.findIndex((element) => element === event.target);
      const indexToFocus = index !== 0 ? index - 1 : maxIndex;
      (focusableElements[indexToFocus] as HTMLElement).focus();
    }
    // tab key
    // forward focus
    else if (
      KeyCodes.TAB === getKeyCodeFromEvent(event) &&
      focusableElements.length > 0 &&
      focusableElements[focusableElements.length - 1] === event.target
    ) {
      event.preventDefault();
      (focusableElements[0] as HTMLElement).focus();
    }
  }

  private cleanUpEventHandlers(): void {
    if (this.removeFocusListener) {
      this.removeFocusListener();
      this.removeFocusListener = undefined;
    }
  }
}
