<div *ngIf="campaign$ | async as campaign" class="campaign pr-xlarge-t mr-large-b flex">
  <div class="campaign-info flex-1 flex--basis0 flex--self-center nordic-blue-900-text pr-medium">
    <div class="campaign-info-slogan font-body-1 light-type">{{ campaign.introductionTitle | finEmpty }}</div>
    <h2 class="font-heading-1 campaign-info-title">{{ campaign.title | finEmpty }}</h2>
    <div *ngIf="campaign.amount" class="campaign-info-price-area flex flex--end">
      <div
        class="font-body-2 campaign-info-price-label flex--nogrow flex flex--column flex--center font-body-1 pr-xxsmall-r"
      >
        <div>
          <div>{{ 'travelType.' + campaign.priceTypeName | finLocalization | finEmpty }}</div>
          <div>{{ 'pricesStartingFrom' | finLocalization }}</div>
        </div>
      </div>
      <div class="font-heading-1 campaign-info-price flex--nogrow heather-700-text">
        {{ campaign.amount | finPrice: { roundPrice: true } }}
      </div>
    </div>
    <a
      *ngIf="campaign.teaserTitle"
      [finLink]="campaign.target?.url"
      [external]="campaign.target?.external"
      class="campaign-info-cta btn btn--small bold-type no-underline mr-medium-t"
      >{{ campaign.teaserTitle }}</a
    >
  </div>

  <div class="campaign-image flex-1 flex--basis0 flex--self-center relative">
    <fin-content-image
      [imageData$]="campaign.picture | finToObservable"
      [aspectRatio$]="aspectRatio$"
      class="block image-height"
    >
    </fin-content-image>
  </div>
</div>
