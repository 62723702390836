import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * @Unstable
 * Warning! This component is in active development and is not production ready.
 *
 * Documentation can be found in `storybook/stories/flights/flight-card.stories.mdx`
 */
@Component({
  selector: 'fcom-flight-card',
  templateUrl: './flight-card.component.html',
  styleUrls: ['./flight-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlightCardComponent {
  /**
   * Flight type (Departure, Return).
   * This is optional information, that is being shown according to the context
   *
   * @example
   * Departure
   */
  @Input() type = '';

  /**
   * Flight date (date, month, year).
   * This is optional information, that is being shown according to the context
   * (e.g. it is not being shown in the context of the flight search results within the booking flow).
   *
   * @example
   * 5 may 2020
   */
  @Input() date = '';

  /**
   * Travel class cabin (mandatory)
   */
  @Input({ required: true }) cabin: string;

  /**
   * fare brand (optional) the user has 	selected
   */
  @Input() fareBrand = '';

  /**
   * Boolean indicating if the component is in teh greyed out state
   */
  @Input() disabled = false;

  /**
   * Boolean indicating if the component should have a divider before notifications/buttons
   */
  @Input() showDivider = false;

  /**
   * String for disrupted label
   */
  @Input() disruptedLabel = '';
}
