/**
 *
 * @param goLiveDate Set a date to enable or disable a flag. By default it will be enabled on this date.
 * @param goOfflineDate If set, the flag will be disabled after the set date.
 * @returns
 */
export const isFlagEnabled = (goLiveDate: string, goOfflineDate?: string): boolean => {
  const goLive = Date.now() >= Date.parse(goLiveDate);
  const goOffline = goOfflineDate ? Date.now() <= Date.parse(goOfflineDate) : true;
  return goLive && goOffline;
};
