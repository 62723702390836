import { Validators, type AbstractControl, type ValidatorFn } from '@angular/forms';

import {
  EMAIL_REGEX,
  formatFinnairPlusCardNumber,
  isFinnairCustomerNumber,
  isFinnairPlusCardNumber,
  isValidIBAN,
  LocalDate,
} from '@fcom/core/utils';

import { isPnrCode } from './utils';

/**
 * Amadeus PNR sync validator
 */
export const pnrFormatValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null || control.value === '' || isPnrCode(control.value.toUpperCase())) {
      return null;
    } else {
      return { pattern: { value: control.value } };
    }
  };
};

/**
 * Ticket number validation, 13-digits and MANDATORY dash
 * Valid sample: 105-1231231231
 */
export const ticketNumberValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const dashPattern = /^(\d+-)+\d+$/;

    if (
      control.value === null ||
      control.value === '' ||
      (dashPattern.test(control.value) && control.value.length === 14)
    ) {
      return null;
    } else {
      return { pattern: { value: control.value } };
    }
  };
};

const TICKET_PATTERN = /^\d{3}[ -]?\d{10}$/;

/**
 * Ticket number validation, 13-digits and OPTIONAL dash
 * Valid samples: 105-1231231231, 105 1231231231, 1051231231231
 */
export const softTicketNumberValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: unknown } | null => {
    if (control.value !== null && control.value !== '') {
      const strippedValue = control.value ? control.value.replace(/[- ]/g, '') : null;
      if (!/^\d+$/.test(strippedValue)) {
        return { custom: 'formatInvalid' };
      } else if (!TICKET_PATTERN.test(control.value)) {
        return { pattern: { value: control.value } };
      }
    }
    return null;
  };
};

/**
 * FPlus number validation
 */
export const frequentFlyerValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (
      control.value === null ||
      control.value === '' ||
      isFinnairPlusCardNumber(formatFinnairPlusCardNumber(control.value))
    ) {
      return null;
    } else {
      return { pattern: true };
    }
  };
};

/**
 * FPlus number validation without AY prefix
 */
export const finnairPlusNumberValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const numericPattern = /^\d+$/;
    if (
      control.value === null ||
      control.value === '' ||
      (numericPattern.test(control.value) && isFinnairPlusCardNumber(control.value))
    ) {
      return null;
    } else {
      return { pattern: true };
    }
  };
};

export const usernameValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const invalidEmail = Validators.pattern(EMAIL_REGEX)(control);
    const invalidMemberNumber = finnairPlusNumberValidator()(control);
    if (control.value === '') {
      return null;
    } else if (invalidEmail && invalidMemberNumber) {
      return { ...invalidEmail, ...invalidMemberNumber };
    } else {
      return null;
    }
  };
};

/**
 * dateValidator
 */
export const dateValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null || control.value === '' || (control.value && control.value instanceof LocalDate)) {
      return null;
    } else {
      return { pattern: { value: control.value } };
    }
  };
};

export const dateStringValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null || control.value === '' || LocalDate.isLocalDate(control.value)) {
      return null;
    } else {
      return { pattern: { value: control.value } };
    }
  };
};

/**
 * The Required format is "(airport code) title".
 * @todo: could be deprecated now as option selection is forced on the
 */
export const locationFormatValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null || control.value === '' || control.value.locationCode) {
      return null;
    } else {
      return { forbiddenFormat: { value: control.value } };
    }
  };
};

/**
 * IBAN validation
 */
export const ibanValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null || control.value === '' || isValidIBAN(control.value)) {
      return null;
    } else {
      return { pattern: true };
    }
  };
};

/**
 * BIC/SWIFT validation
 * Checks that the string contains 8 or 11 alphanumeric characters
 * Source: https://en.wikipedia.org/wiki/ISO_9362
 */
export const bicValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const pattern = /^[a-zA-Z0-9]{8}$|^[a-zA-Z0-9]{11}$/;
    if (control.value === null || control.value === '' || pattern.test(control.value)) {
      return null;
    } else {
      return { pattern: true };
    }
  };
};

export const finnairCustomerNumberValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === null || control.value === '' || isFinnairCustomerNumber(control.value)) {
      return null;
    } else {
      return { pattern: true };
    }
  };
};
