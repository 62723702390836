<ng-container *ngIf="visibility$ | async">
  <button
    (click)="openModal($event)"
    class="nordic-blue-900-text ps-large-x ps-xsmall-y bold font-body-1 nordic-blue-50-bg rounded-top-medium"
  >
    {{ 'feedback.buttonTitle' | finLocalization }}
  </button>

  <fin-qualtrics
    *ngIf="feedbackSurvey$ | async as survey"
    [survey]="survey"
    [open]="openSurveyTrigger$ | async"
    (openChange)="openSurveyTrigger$.next($event)"
  >
  </fin-qualtrics>
</ng-container>
