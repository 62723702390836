import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { UiTranslateModule } from '@fcom/ui-translate';
import { UiComponentsModule } from '@fcom/ui-components';
import { CmsModule } from '@fcom/cms';

import { PipesModule, ComponentsModule } from '../../';
import { BaseLayoutComponent } from './base-layout.component';

@NgModule({
  imports: [
    RouterModule,
    ComponentsModule,
    CommonModule,
    PipesModule,
    UiTranslateModule,
    UiComponentsModule,
    CmsModule,
  ],
  declarations: [BaseLayoutComponent],
  exports: [BaseLayoutComponent],
})
export class BaseLayoutModule {}
