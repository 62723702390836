import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CmsTarget } from '@fcom/core-api';

import { toLinkData } from '../../services';

@Component({
  selector: 'cms-text-link',
  template: `
    <fin-text-link
      class="mr-large-b block"
      [target$]="cmsTarget$"
      [teaserTitle$]="content$ | finMap: 'teaserTitle'"
      [colorClass]="colorClass"
    ></fin-text-link>
  `,
})
export class CmsTextLinkComponent implements OnInit {
  @Input()
  content$: Observable<any>;
  @Input()
  colorClass: string;

  cmsTarget$: Observable<CmsTarget>;

  ngOnInit(): void {
    this.cmsTarget$ = this.content$.pipe(map((d) => toLinkData(d.target)));
  }
}
