import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { combineLatest, switchMap, Observable, of, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { isPresent, unsubscribe } from '@fcom/core/utils';
import { ConfigService } from '@fcom/core/services';
import { WindowRef } from '@fcom/core/providers';
import { CartActions } from '@fcom/booking/store/actions';
import { BookingAppState } from '@fcom/common/interfaces/booking';
import { navigateToError } from '@fcom/booking/utils/route-utils';
import { finShare } from '@fcom/rx';
import { GtmService } from '@fcom/common/gtm';
import { FinnairCart, FinnairOrder } from '@fcom/dapi/api/models';
import { cartData } from '@fcom/booking/store/selectors';
import { GtmEvent, GtmPurchaseFlow } from '@fcom/common/interfaces';
import { getGA4Items, resolvePurchaseFlow } from '@fcom/common/utils/gtm.utils';
import { Profile } from '@fcom/core-api/login';
import { profileOrNot } from '@fcom/core/selectors';
import { QualtricsSurveys } from '@fcom/common/qualtrics/qualtrics.utils';
import { FeedbackService } from '@fcom/common/services/feedback/feedback.service';

import { isAwardBooking } from '../../utils/common-booking.utils';
import { PaymentService } from '../../services';
import { CartOrOrder, PaymentFlow, PaymentServiceName, PaymentStatus } from '../../interfaces';
import { orderData, paymentRedirectionUrl, paymentStatus } from '../../store/selectors';

const getPurchaseFlow = (cartOrOrder: CartOrOrder, paymentFlow: PaymentFlow, profile: Profile): GtmPurchaseFlow => {
  switch (paymentFlow) {
    case PaymentFlow.VOLUNTARY_CHANGE:
      return GtmPurchaseFlow.VOLUNTARY_CHANGE;
    case PaymentFlow.MANAGE_BOOKING:
      return GtmPurchaseFlow.MANAGE_BOOKING;
    default:
      return resolvePurchaseFlow(profile, isAwardBooking(cartOrOrder.prices.unpaid));
  }
};

@Component({
  selector: 'fin-purchase-checkout',
  templateUrl: './purchase-checkout.component.html',
})
export class PurchaseCheckoutComponent implements OnInit, OnDestroy {
  @Input()
  serviceName: PaymentServiceName;

  @Input()
  paymentFlow: PaymentFlow;

  @Input()
  isEnableFeedbackMMB = false;

  checkoutUrl: string;

  readonly paymentStatus$: Observable<PaymentStatus>;

  private subscription: Subscription;

  constructor(
    private paymentService: PaymentService,
    private store$: Store<BookingAppState>,
    private configService: ConfigService,
    private windowRef: WindowRef,
    private router: Router,
    private languageService: LanguageService,
    private gtmService: GtmService,
    private feedbackService: FeedbackService
  ) {
    this.paymentStatus$ = this.store$.pipe(paymentStatus(), finShare());
    this.checkoutUrl = this.configService.cfg.checkoutUrl;
  }

  cleanup(): void {
    this.subscription = unsubscribe(this.subscription);
  }

  ngOnInit(): void {
    this.subscription = new Subscription();

    const cart$: Observable<FinnairCart> = this.store$.pipe(cartData());
    const order$: Observable<FinnairOrder | undefined> = this.store$.pipe(orderData(true));
    const cartOrOrder$ = order$.pipe(
      switchMap((order) => (order ? of(order) : cart$)),
      take(1)
    );

    this.subscription.add(
      combineLatest([cartOrOrder$, this.store$.pipe(profileOrNot())]).subscribe(([cartOrOrder, profile]) => {
        const purchaseFlow = getPurchaseFlow(cartOrOrder, this.paymentFlow, profile);
        this.gtmService.ecommerceEventGA4(GtmEvent.BEGIN_CHECKOUT, getGA4Items(cartOrOrder, purchaseFlow, true));
      })
    );

    this.subscription.add(
      this.store$.pipe(paymentRedirectionUrl(), filter(isPresent)).subscribe((receivedUrl: string) => {
        this.store$.dispatch(CartActions.reset());
        // TODO: fix back button
        // TODO: store somewhere that user has been redirected, so the backbutton would work
        this.windowRef.nativeWindow.location.href = receivedUrl;
      })
    );

    this.subscription.add(
      this.paymentStatus$.subscribe((status: PaymentStatus) => {
        if (status === PaymentStatus.INITIAL) {
          this.paymentService.queuePaymentInit(this.serviceName, this.paymentFlow);
        } else if (status === PaymentStatus.TECHNICAL_ERROR) {
          navigateToError(this.router, this.languageService.langValue, 'TECHNICAL_ERROR');
        }
      })
    );
    if (this.isEnableFeedbackMMB) {
      this.feedbackService.setSurvey(QualtricsSurveys.MMB_FEEDBACK);
      this.feedbackService.setFeedbackWidgetVisibility(true);
    }
  }

  ngOnDestroy(): void {
    this.cleanup();
  }
}
