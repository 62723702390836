import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { LocationInfoMap } from '@fcom/dapi';
import { FinnairItineraryItemFlight } from '@fcom/dapi/api/models';
import { TagTheme, NotificationLayout, NotificationTheme } from '@fcom/ui-components';
import { ConfigService } from '@fcom/core';

import { isSegmentUsingSAF } from '../../../utils/common-booking.utils';
import { BUS_AIRCRAFT_CODE, FlightChanges } from '../../../interfaces';

@Component({
  selector: 'fin-itinerary-timeline-flight',
  styleUrls: ['../itinerary.scss', '../arrow-block.scss'],
  templateUrl: './itinerary-timeline-flight.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryTimelineFlightComponent implements OnInit {
  readonly BUS_AIRCRAFT_CODE = BUS_AIRCRAFT_CODE;
  readonly NotificationTheme = NotificationTheme;
  readonly NotificationLayout = NotificationLayout;
  readonly TagTheme = TagTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  flight: FinnairItineraryItemFlight;

  @Input()
  firstLeg = false;

  @Input()
  lastLeg = false;

  @Input()
  locations: LocationInfoMap;

  @Input()
  flightChanges: FlightChanges;

  // Parent component passes down info if the sustainability feature should be shown in the flow in question
  // We want to show the sustainability feature in booking flow, but not in cancel or reacc flows
  @Input()
  isBookingFlow: boolean;

  // If the flight shown in this component is using SAF
  isFlightUsingSAF: boolean;
  enableShowingSAF: boolean;

  constructor(private configService: ConfigService) {}

  ngOnInit(): void {
    this.isFlightUsingSAF = isSegmentUsingSAF(this.flight);
    this.enableShowingSAF = this.configService.cfg.enableShowingSAF;
  }
}
