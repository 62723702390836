import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { AspectRatioString } from '@fcom/common/interfaces/images.interface';
import { CmsOffer } from '@fcom/core-api';
import { Amount } from '@fcom/dapi';
import { isPresent } from '@fcom/core/utils';

export interface CmsCampaign extends CmsOffer {
  introductionTitle: string;
  amount?: Amount;
  priceTypeName: string;
}

@Component({
  selector: 'cms-campaign',
  styleUrls: ['./cms-campaign.component.scss'],
  templateUrl: './cms-campaign.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsCampaignComponent implements OnInit {
  @Input()
  content$: Observable<CmsOffer>;
  campaign$: Observable<CmsCampaign>;
  aspectRatio$: Observable<AspectRatioString> = of('4x3' as AspectRatioString);

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.campaign$ = combineLatest([this.languageService.translate('number.currency'), this.content$]).pipe(
      map(([currencyCode, offer]: [string, CmsCampaign]) => {
        if (!isPresent(offer.price)) {
          return offer;
        }
        return { ...offer, amount: { currencyCode, amount: String(offer.price) } };
      })
    );
  }
}
