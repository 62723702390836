<div
  class="border white-bg"
  [ngClass]="{
    'rounded-large': roundedBottom,
    'ps-medium-b rounded-top-large': !roundedBottom,
  }"
>
  <fin-expander-card
    data-testid="flight-review-card-title"
    class="block"
    [expandOpen]="cardExpanderOpen"
    [iconCategory]="IconLibrary.SVG_LIBRARY"
    [iconName]="SvgLibraryIcon.AIRCRAFT_HORIZONTAL_BLOCK"
    [title]="title"
    [subTitleIcon]="paxIcon"
    [subTitle]="passengers.length"
    [showSubTitleOnMobile]="true"
    [descriptionContent]="descriptionContent"
    (expandChange)="toggleOpenCard($event)"
  >
    <ng-container ngProjectAs="expandedContent">
      <div class="ps-medium-x ps-medium-b" [class.dim]="dim">
        <div class="font-body-1 dark-grey flex">
          <div class="ps-xxsmall">
            <h4 data-testid="pax-flight-review-card-date">
              {{ flightOrBound?.departure?.dateTime | finDateFormat: 'DATE' }}
            </h4>
          </div>
        </div>
        <fin-ufo-with-modify-button
          class="block ps-medium-t"
          [flightOrBound]="flightOrBound"
          [title]="title"
          [hideModify]="hideModify"
          [hideViewDetails]="hideViewDetails"
          [paxReviewCard]="true"
          [locations]="locations"
          (viewDetails)="viewDetails.emit()"
          (modifyClicked)="modifyClicked.emit()"
        ></fin-ufo-with-modify-button>
      </div>
    </ng-container>
  </fin-expander-card>

  <ng-content></ng-content>
</div>

<ng-template #descriptionContent>
  <fin-flight-summary [class.dim]="dim" [flightOrBound]="flightOrBound"></fin-flight-summary>
</ng-template>
