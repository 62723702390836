import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, Location } from '@angular/common';

import { Store } from '@ngrx/store';
import { asapScheduler, combineLatest, Observable, of, Subscription, zip } from 'rxjs';
import { catchError, map, skipWhile } from 'rxjs/operators';

import { LanguageService, TranslateOptions } from '@fcom/ui-translate';
import { finShare, snapshot } from '@fcom/rx';

import { getIn, replaceMsgFormat, ReplaceParams, unsubscribe } from '../../utils';
import { LanguageActions } from '../../actions';
import { AppState, DdsLanguage } from '../../interfaces';
import { currentLocalization, ddsLanguage, language, locale } from '../../selectors';
import { shouldISendCmsKeyMissingError } from '../../utils/cms-utils';
import { ConfigService } from '../config/config.service';
import { CookieService } from '../cookie/cookie.service';
import { SentryLogger } from '../sentry/sentry.logger';
import { LocalizationProvider } from './localization.provider';
import { I18nKey, i18nKeyToDataMap, I18nLazyLoadingPrefix } from '../cms/cms-data.service';
import { ddsLangToNewLang } from '../../selectors/dds-languages';

const COOKIE_KEY = 'FinnairComLanguagePreference';

@Injectable()
export class FinLanguageService extends LanguageService implements OnDestroy {
  private readonly translateOptionsDefaults: Readonly<TranslateOptions> = {
    logErrors: true,
  };

  private lang$: Observable<string>;
  private localizations$: Observable<any>;
  private locale$: Observable<string>;
  private subscriptions = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private cookieService: CookieService,
    private localizationProvider: LocalizationProvider,
    private configService: ConfigService,
    private sentryLogger: SentryLogger,
    private location: Location,
    @Inject(PLATFORM_ID) private platform: object
  ) {
    super();
    this.allowableLangs = Object.keys(this.configService.cfg.allowedLocales);
    this.lang$ = this.languageFromStore().pipe(finShare());
    this.localizations$ = this.store$.pipe(currentLocalization(), finShare());
    this.locale$ = this.localeFromStore().pipe(finShare());
    this.updateLocaleBasedOnLanguage(this.lang$);
    this.setInitialLanguageFromCookieOrPath();
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  verifyLangAllowed = (lang: string, throwExpection = true): boolean => {
    const isLangSupported = lang && this.allowableLangs.includes(lang);
    const isDdsLang = this.allowableLangs.includes(ddsLangToNewLang(this.location.path()));
    if (!(isLangSupported || isDdsLang) && throwExpection) {
      throw new Error(`lang ${lang} not allowed. Allowable: ${this.allowableLangs}`);
    }
    return isLangSupported;
  };

  setLang(lang: string): void {
    const languageValue = this.verifyLangAllowed(lang) ? lang : ddsLangToNewLang(this.location.path());
    this.store$.dispatch(
      LanguageActions.setLanguage({
        language: languageValue,
        locale: this.configService.cfg.allowedLocales[languageValue],
      })
    );
    const expires = new Date();
    expires.setTime(expires.getTime() + FinLanguageService.TTL);
    this.cookieService.setItem(COOKIE_KEY, languageValue, expires);
  }

  get countryCode(): Observable<string> {
    return this.locale.pipe(
      map((l) => {
        const [, countryCode] = l.split('_');
        return countryCode || 'INT';
      })
    );
  }

  get cookieValue(): string {
    return this.cookieService.getItem(COOKIE_KEY) || FinLanguageService.FALLBACK_LANGUAGE;
  }

  // Example: ca-fr
  get lang(): Observable<string> {
    return this.lang$;
  }

  // Example: en or fr
  get langKey(): Observable<string> {
    return this.lang$.pipe(map((val) => val.slice(-2)));
  }

  // Example: en_GB for gb-en – see ALLOWED_LOCALES
  get locale(): Observable<string> {
    return this.locale$;
  }

  get localeValue(): string {
    return snapshot(this.store$.pipe(locale()));
  }

  get langValue(): string {
    return snapshot(this.store$.pipe(language()));
  }

  get langKeyValue(): string {
    return this.langValue.slice(-2);
  }

  get ddsLocaleValue(): DdsLanguage {
    return snapshot(this.store$.pipe(ddsLanguage()));
  }

  get localizations(): Observable<any> {
    return this.localizations$;
  }

  translate(key: string | string[], args: ReplaceParams = [], options?: TranslateOptions): Observable<any> {
    options = {
      ...this.translateOptionsDefaults,
      ...options,
    };

    const keyAsArray: string[] = Array.isArray(key) ? key : key.split('.');
    const rootKey = keyAsArray[0];
    if (rootKey === i18nKeyToDataMap.taxCodes.lazyLoadingPrefix) {
      this.localizationProvider.triggerLazyLocalizations(I18nKey.TAX_CODES);
    } else if (rootKey === i18nKeyToDataMap.countryRegions.lazyLoadingPrefix) {
      this.localizationProvider.triggerLazyLocalizations(I18nKey.COUNTRY_REGIONS);
    } else if (rootKey === i18nKeyToDataMap.serviceForms.lazyLoadingPrefix) {
      this.localizationProvider.triggerLazyLocalizations(I18nKey.SERVICE_FORMS);
    } else if (rootKey === i18nKeyToDataMap.corporate.lazyLoadingPrefix) {
      this.localizationProvider.triggerLazyLocalizations(I18nKey.CORPORATE);
    } else if (rootKey === i18nKeyToDataMap.travelReady.lazyLoadingPrefix) {
      this.localizationProvider.triggerLazyLocalizations(I18nKey.TRAVEL_READY);
    }

    return this.localizations$.pipe(
      skipWhile((localizations) => {
        if (!Object.values(I18nLazyLoadingPrefix).includes(rootKey as I18nLazyLoadingPrefix)) {
          return false;
        }
        return !localizations[rootKey]?.loaded; // wait till loaded is truthy
      }),
      map((localeString) => {
        const str: string = getIn(localeString, keyAsArray) as string;

        if (str === undefined && shouldISendCmsKeyMissingError(keyAsArray)) {
          throw new Error(`CMS missing key: ${key}`);
        }
        return str;
      }),
      catchError((err: unknown) => {
        if (options.logErrors) {
          this.sentryLogger.error((err as Error).message || JSON.stringify(err));
        }

        return of('');
      }),
      map((str) => replaceMsgFormat(str, args))
    );
  }

  translateMultiple(keys: string[], args: ReplaceParams = [], options?: TranslateOptions): Observable<string[]> {
    const translations: Array<Observable<string>> = keys.map((k) => this.translate(k, args, options));
    return zip(...translations);
  }

  private languageFromStore(): Observable<string> {
    return this.store$.pipe(language());
  }

  private localeFromStore(): Observable<string> {
    return this.store$.pipe(locale());
  }

  private setInitialLanguageFromCookieOrPath() {
    if (isPlatformBrowser(this.platform)) {
      const languageValue = this.getInitialLanguageValue();

      this.store$.dispatch(
        LanguageActions.setLanguage({
          language: languageValue,
          locale: this.configService.cfg.allowedLocales[languageValue],
        })
      );
    }
  }

  /**
   * Resolve initial language value either from url or from cookie
   */
  private getInitialLanguageValue(): string {
    const currentLang: string | undefined = this.location.path().split('/')[1];

    if (this.verifyLangAllowed(currentLang, false)) {
      return currentLang;
    } else if (this.verifyLangAllowed(this.cookieValue)) {
      return this.cookieValue;
    }

    return ddsLangToNewLang(this.location.path());
  }

  private updateLocaleBasedOnLanguage(languageValue$: Observable<string>): void {
    this.subscriptions.add(
      combineLatest([this.localizationProvider.getLocalizations(languageValue$), languageValue$]).subscribe(
        ([localeValue, lang]) =>
          asapScheduler.schedule(() =>
            this.store$.dispatch(
              LanguageActions.setLocalizations({
                language: lang,
                localization: localeValue,
              })
            )
          )
      )
    );
  }
}
