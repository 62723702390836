import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subscription, throwError } from 'rxjs';
import { timeout } from 'rxjs/operators';

import { unsubscribe } from '@fcom/core/utils';

import { NavigatorActions } from '../../actions';
import { ConfigService } from '../config/config.service';
import { AppState } from '../../interfaces';

export interface AkamaiTimegeoResponse {
  /**
   * Milliseconds from UNIX epoch as echoed by Akamai
   */
  msepoch: string;

  /**
   * The Akamai EdgeScape geolocation headers' lat value
   */
  lat: string;

  /**
   * The Akamai EdgeScape geolocation headers' long value
   */
  long: string;
}

@Injectable()
export class TimeAndGeoipProvider implements OnDestroy {
  private subscription: Subscription;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private store$: Store<AppState>
  ) {}

  ngOnDestroy(): void {
    this.subscription = unsubscribe(this.subscription);
  }

  triggerTimeAndGeoipFetch(): void {
    if (this.subscription) {
      return;
    }
    this.subscription = this.http
      .get(this.configService.cfg.timeGeoUrl)
      .pipe(
        timeout({
          each: 5000,
          with: () => throwError(() => new Error('getting time and geoip from server timed out after 5 seconds')),
        })
      )
      .subscribe({
        next: (data: AkamaiTimegeoResponse) => {
          const serverMillis: number = parseInt(data.msepoch, 10);
          const browserOffset: number = serverMillis - Date.now();
          this.store$.dispatch(
            NavigatorActions.setServerTime({ serverMillisFromEpoch: serverMillis, timeOffset: browserOffset })
          );
          this.store$.dispatch(
            NavigatorActions.setAkamaiGeolocation({
              location: {
                lat: parseFloat(data.lat),
                lng: parseFloat(data.long),
              },
            })
          );
        },
        error: () => {
          /* Fall back to client time */
          this.store$.dispatch(NavigatorActions.setServerTime({ serverMillisFromEpoch: Date.now(), timeOffset: 0 }));
        },
        complete: () => {
          this.subscription = unsubscribe(this.subscription);
        },
      });
  }
}
