import { createSelector, createFeatureSelector, select } from '@ngrx/store';

import { safeSelect, StateSelector } from '@fcom/core/selectors/selector-utils';
import { LocationsState } from '@fcom/locations/interfaces/locations-state.interface';
import { BookingAppState } from '@fcom/common/interfaces/booking';
import { mapValues } from '@fcom/core/utils';
import { GlobalBookingTravelClass, GlobalBookingTravelClassValue, LocalDate } from '@fcom/core';
import { LocationsMap, LocationRouteType } from '@fcom/core-api/interfaces';
import { PaxAmount } from '@fcom/dapi/interfaces';

import {
  CommonState,
  CommonFeatureState,
  GlobalBookingState,
  TravelClassState,
  TravelClasses,
  GlobalBookingFlight,
  LocationPair,
} from '../store.interface';
import { COMMON_FEATURE_KEY } from '../constants';
import { GlobalBookingTripDates, TripType } from '../../interfaces';

const mapToCachedLocations = (flights: GlobalBookingFlight[], locationsMap: LocationsMap): GlobalBookingFlight[] =>
  flights?.map(({ origin, destination, departureDate }) => ({
    origin: locationsMap?.[origin?.locationCode] ?? origin,
    destination: locationsMap?.[destination?.locationCode] ?? destination,
    departureDate: convertToLocalDateIfNeeded(departureDate),
  }));

const _cachedLocationsMap = createSelector(
  (appState: BookingAppState) => appState?.locations,
  (locationsState: LocationsState) => locationsState?.locations
);

const _selectCommonState = createFeatureSelector<CommonFeatureState, CommonState>(COMMON_FEATURE_KEY);

const _selectGlobalBookingState = createSelector(
  _selectCommonState,
  _cachedLocationsMap,
  (state: CommonState, cachedLocations: LocationsMap) => ({
    ...state.globalBooking,
    flights: mapToCachedLocations(state.globalBooking.flights, cachedLocations),
  })
);
const _getGlobalBookingTripType = createSelector(
  _selectGlobalBookingState,
  (globalBookingState: GlobalBookingState) => globalBookingState.tripType
);
const _getGlobalBookingFlights = createSelector(_selectGlobalBookingState, (globalBookingState) =>
  globalBookingState.flights.map((flight) => ({
    ...flight,
    departureDate: convertToLocalDateIfNeeded(flight.departureDate),
  }))
);

const _globalBookingTravelClassState = createSelector(
  _selectGlobalBookingState,
  (state: GlobalBookingState) => state.travelClasses
);

const convertToLocalDateIfNeeded = (date: LocalDate | string): LocalDate =>
  date ? (date instanceof LocalDate ? date : new LocalDate(date)) : undefined;

const _getGlobalBookingTravelDates = createSelector(_getGlobalBookingFlights, (flights: GlobalBookingFlight[]) => ({
  departureDate: convertToLocalDateIfNeeded(flights[0]?.departureDate),
  ...(flights[1]?.departureDate && {
    returnDate: convertToLocalDateIfNeeded(flights[1]?.departureDate),
  }),
}));

const _globalBookingAvailableTravelClasses = createSelector(
  _globalBookingTravelClassState,
  (travelClasses: TravelClassState): GlobalBookingTravelClass[] =>
    Object.keys(travelClasses.availability).reduce((availableClasses, travelClass: GlobalBookingTravelClass) => {
      if (travelClasses.availability[travelClass]) {
        availableClasses.push(travelClass);
      }
      return availableClasses;
    }, [])
);

const _getGlobalBookingTravelClass = createSelector(
  _selectGlobalBookingState,
  _globalBookingAvailableTravelClasses,
  (globalBookingState: GlobalBookingState, availableClasses: GlobalBookingTravelClass[]) =>
    availableClasses.includes(globalBookingState.travelClasses.selected)
      ? globalBookingState.travelClasses.selected
      : availableClasses[0]
);

const _globalBookingTravelClasses = createSelector(
  _globalBookingTravelClassState,
  (tcs: TravelClassState): TravelClasses =>
    tcs
      ? mapValues(tcs.availability, (available: boolean, key: GlobalBookingTravelClassValue) => ({
          available,
          selected: tcs.selected === key,
        }))
      : undefined
);
const _getGlobalBookingOrigin = createSelector(
  _selectGlobalBookingState,
  (globalBookingState: GlobalBookingState) => globalBookingState.origin
);

const _getGlobalBookingRouteType = createSelector(
  _selectGlobalBookingState,
  (globalBookingState: GlobalBookingState) => globalBookingState.routeType
);

const _getGlobalBookingDiscountCode = createSelector(
  _selectGlobalBookingState,
  (globalBookingState: GlobalBookingState) => globalBookingState.discountCode
);

const _globalBookingLocations = createSelector(_getGlobalBookingFlights, (flights: GlobalBookingFlight[]) =>
  flights.map(({ origin, destination }) => ({ origin, destination }))
);

export const _globalBookingPaxAmount = createSelector(
  _selectGlobalBookingState,
  (globalBookingState) => globalBookingState?.paxAmount
);
export const globalBookingTripType = (): StateSelector<CommonFeatureState, TripType> =>
  select(_getGlobalBookingTripType);
export const globalBookingTravelClass = (): StateSelector<CommonFeatureState, GlobalBookingTravelClass> =>
  select(_getGlobalBookingTravelClass);
export const globalBookingTravelClasses = (): StateSelector<CommonFeatureState, TravelClasses> =>
  select(_globalBookingTravelClasses);
export const globalBookingAvailableTravelClasses = (): StateSelector<CommonFeatureState, GlobalBookingTravelClass[]> =>
  select(_globalBookingAvailableTravelClasses);
export const globalBookingSelections = (): StateSelector<CommonFeatureState, GlobalBookingState> =>
  select(_selectGlobalBookingState);
export const globalBookingOrigin = (): StateSelector<CommonFeatureState, string> => select(_getGlobalBookingOrigin);
export const globalBookingRouteType = (): StateSelector<CommonFeatureState, LocationRouteType> =>
  select(_getGlobalBookingRouteType);
export const globalBookingDiscountCode = (): StateSelector<CommonFeatureState, string> =>
  select(_getGlobalBookingDiscountCode);
export const globalBookingLocations = (): StateSelector<CommonFeatureState, LocationPair[]> =>
  select(_globalBookingLocations);
export const globalBookingPaxAmount = (): StateSelector<CommonFeatureState, PaxAmount> =>
  safeSelect(_globalBookingPaxAmount);
export const globalBookingFlights = (): StateSelector<CommonFeatureState, GlobalBookingFlight[]> =>
  safeSelect(_getGlobalBookingFlights);
export const globalBookingTravelDates = (): StateSelector<CommonFeatureState, GlobalBookingTripDates> =>
  select(_getGlobalBookingTravelDates);
