@if (target$() | async; as link) {
  <a
    [finLink]="link.url"
    [external]="link.external"
    [openInNewTab]="link.openInNewTab"
    [attr.aria-label]="link.openInNewTab ? (teaserTitle$() | async) + newTabText : null"
    class="flex flex--middle font-body-1 bold-type nordic-blue-900-text"
    [ngClass]="{
      'no-underline': !underline(),
      'underline-hover': !underline(),
    }"
  >
    {{ teaserTitle$() | async }}
    @if (useIcon()) {
      <fcom-icon
        class="icon-medium"
        [name]="link.openInNewTab ? SvgLibraryIcon.EXTERNAL_TAB : SvgLibraryIcon.ARROW_RIGHT"
      ></fcom-icon>
    }
  </a>
}
