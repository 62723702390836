import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, combineLatest, Observable, of, Subscription, withLatestFrom } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { AppState, CountryCode } from '@fcom/core';
import {
  ButtonMode,
  ButtonSize,
  ButtonTheme,
  ButtonType,
  IconPosition,
  InputType,
  LoaderTheme,
  NotificationTheme,
} from '@fcom/ui-components';
import { LoginGender, LoginStep } from '@fcom/core-api/login';
import {
  EMAIL_PATTERN,
  isTruthy,
  isPresent,
  LocalDate,
  NAME_PATTERN,
  PASSWORD_PATTERN,
  PHONE_PATTERN,
  rangeBetween,
  rangeFrom,
  sortBy,
  TzDate,
  uniqueBy,
  unsubscribe,
} from '@fcom/core/utils';
import { finShare } from '@fcom/rx';
import { LoginActions } from '@fcom/core/actions';
import { LanguageService } from '@fcom/ui-translate';
import { WindowRef } from '@fcom/core/providers';
import { StepperStep } from '@fcom/ui-components/components/stepper/interfaces';
import { Consent, JoinFinnairPlusResponseData } from '@fcom/common';

import { ConsentService, JoinService } from '../../services';
import {
  ConsentGroup,
  ConsentTextId,
  ElementActions,
  ElementTypes,
  GaContext,
  SelectOption,
} from '../../../interfaces';
import { Airline } from '../../../interfaces/airlines';
import { finnairPlusNumberValidator } from '../../../utils/form-validators';
import { ToasterService } from '../../../services';
import { DataCloudService } from '../../../datacloud';
import { GtmService } from '../../../gtm';
import { JoinFinnairPlusRequestData } from '../../../interfaces/join-finnair-plus.interface';

export enum JoinFlowStep {
  BASIC_INFO = 'basic',
  CONTACT_DETAILS = 'contact',
  GUARDIAN_DETAILS = 'guardian',
  MARKETING_CONSENT = 'marketing',
  JOIN_COMPLETE = 'completed',
}

export interface Step extends StepperStep {
  step: JoinFlowStep;
}

@Component({
  selector: 'fin-join-form',
  templateUrl: './join-form.component.html',
})
export class JoinFormComponent implements OnInit, OnDestroy {
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonType = ButtonType;
  readonly ButtonSize = ButtonSize;
  readonly ButtonMode = ButtonMode;
  readonly IconLibrary = IconLibrary;
  readonly IconPosition = IconPosition;
  readonly LoaderTheme = LoaderTheme;
  readonly NotificationTheme = NotificationTheme;
  readonly LoginGender = LoginGender;
  readonly JoinFlowStep = JoinFlowStep;
  readonly InputType = InputType;
  readonly ConsentTextId = ConsentTextId;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  fullScreen = false;

  basicInfoForm: UntypedFormGroup;
  contactDetailsForm: UntypedFormGroup;
  marketingConsentForm: UntypedFormGroup;
  guardianInfoForm: UntypedFormGroup;

  stepperSteps$: Observable<Step[]>;
  stepperIndex$: Observable<number>;
  stepTranslations$: Observable<{ title: string; description: string }>;

  consentGroup$: Observable<ConsentGroup>;

  showError$: Observable<boolean>;
  currentStep$: BehaviorSubject<JoinFlowStep> = new BehaviorSubject<JoinFlowStep>(JoinFlowStep.BASIC_INFO);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  passwordVisibility$ = new BehaviorSubject<boolean>(false);
  confirmPasswordVisibility$ = new BehaviorSubject<boolean>(false);

  countryOptions: Observable<SelectOption[]>;

  phonePrefixes$: Observable<SelectOption[]>;
  availableDays$: Observable<SelectOption[]>;
  availableMonths$: Observable<SelectOption[]> = of(
    rangeFrom(1, 12).map((month) => ({ name: String(month), value: String(month) }))
  );
  availableYears$: Observable<SelectOption[]> = of(
    rangeBetween(LocalDate.now().minusYears(100).localYear, LocalDate.now().localYear)
      .reverse()
      .map((year) => ({ name: String(year), value: String(year) }))
  );
  isJunior$: Observable<boolean>;
  dateOfBirth$: Observable<LocalDate>;

  get basicInfoFormBirthDate(): UntypedFormGroup {
    return this.basicInfoForm.get('birthDate') as UntypedFormGroup;
  }
  get contactDetailsFormPassword(): UntypedFormGroup {
    return this.contactDetailsForm.get('password') as UntypedFormGroup;
  }
  get contactDetailsFormPhone(): UntypedFormGroup {
    return this.contactDetailsForm.get('phone') as UntypedFormGroup;
  }
  get guardianInfoFormPhone(): UntypedFormGroup {
    return this.guardianInfoForm.get('guardianPhoneNumber') as UntypedFormGroup;
  }

  private subscriptions = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private store$: Store<AppState>,
    private joinService: JoinService,
    private languageService: LanguageService,
    private consentService: ConsentService,
    private router: Router,
    private toasterService: ToasterService,
    private windowRef: WindowRef,
    private gtmService: GtmService,
    private dataCloudService: DataCloudService
  ) {}

  ngOnInit(): void {
    const countryCodes$: Observable<CountryCode[]> = this.languageService.translate('countryCodes').pipe(finShare());
    this.countryOptions = countryCodes$.pipe(
      map((countryCodes) => countryCodes.map(({ name, countryCode }) => ({ name, value: countryCode })))
    );

    this.basicInfoForm = this.fb.group({
      firstName: this.fb.control(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(56),
          Validators.pattern(NAME_PATTERN),
        ])
      ),
      lastName: this.fb.control(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(56),
          Validators.pattern(NAME_PATTERN),
        ])
      ),
      birthDate: this.fb.group(
        {
          dateOfBirth: ['', Validators.required],
          monthOfBirth: ['', Validators.required],
          yearOfBirth: ['', Validators.required],
        },
        { validators: [this.ageValidator()] }
      ),
      gender: ['', Validators.required],
    });

    this.contactDetailsForm = this.fb.group({
      phone: this.fb.group(
        {
          phonePrefix: this.fb.control(''),
          phoneNumber: this.fb.control(''),
        },
        { validators: [this.phoneValidator()] }
      ),
      email: this.fb.control('', Validators.compose([Validators.required, Validators.pattern(EMAIL_PATTERN)])),
      countryCode: this.fb.control('', Validators.required),
      password: this.fb.group(
        {
          pwd: this.fb.control('', Validators.compose([Validators.required, Validators.pattern(PASSWORD_PATTERN)])),
          confirmPwd: this.fb.control('', Validators.compose([Validators.required])),
        },
        { validators: [this.passwordMatchValidator()] }
      ),
      accept: this.fb.control(false),
    });

    this.guardianInfoForm = this.fb.group({
      guardianName: this.fb.control(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(56),
          Validators.pattern(NAME_PATTERN),
        ])
      ),
      guardianEmail: this.fb.control('', Validators.compose([Validators.required, Validators.pattern(EMAIL_PATTERN)])),
      guardianPhoneNumber: this.fb.group(
        {
          phonePrefix: this.fb.control(''),
          phoneNumber: this.fb.control(''),
        },
        { validators: [Validators.required, this.phoneValidator()] }
      ),
      guardianMemberNumber: this.fb.control(
        '',
        Validators.compose([Validators.required, finnairPlusNumberValidator()])
      ),
      consent: this.fb.control(false),
    });

    this.marketingConsentForm = this.fb.group({});

    this.consentGroup$ = this.consentService.getMarketingConsentTexts().pipe(filter(isPresent), finShare());

    this.subscriptions.add(
      this.consentGroup$.subscribe(({ consentTexts }) => {
        this.createMarketingConsentForm(consentTexts);
      })
    );

    this.availableDays$ = this.getDays(
      this.basicInfoForm.get('birthDate.yearOfBirth'),
      this.basicInfoForm.get('birthDate.monthOfBirth')
    ).pipe(map((days) => days.map((day) => ({ name: String(day), value: String(day) }))));

    this.dateOfBirth$ = combineLatest([
      this.basicInfoForm.get('birthDate.yearOfBirth').valueChanges,
      this.basicInfoForm.get('birthDate.monthOfBirth').valueChanges,
      this.basicInfoForm.get('birthDate.dateOfBirth').valueChanges,
    ]).pipe(
      map(([year, month, day]: [string, string, string]) => {
        if (year && month && day) {
          return LocalDate.forDate(year, month, day);
        }
        return undefined;
      }),
      finShare()
    );

    this.isJunior$ = this.dateOfBirth$.pipe(
      map((day) => day && day.gte(LocalDate.now().minusYears(16))),
      finShare(),
      startWith(false)
    );

    this.phonePrefixes$ = countryCodes$.pipe(
      take(1),
      tap((countryCodes: CountryCode[]) => {
        const phonePrefix =
          countryCodes.find(({ countryCode }) => countryCode === this.languageService.ddsLocaleValue.countrySite)
            ?.phonePrefix ?? '';
        this.contactDetailsForm.get('phone').get('phonePrefix').setValue(phonePrefix);
        this.guardianInfoForm.get('guardianPhoneNumber').get('phonePrefix').setValue(phonePrefix);
      }),
      map((countryCodes: CountryCode[]) =>
        sortBy(
          countryCodes.filter(uniqueBy(({ phonePrefix }) => phonePrefix)).map((cc) => ({
            name: `(+${cc.phonePrefix})`,
            value: cc.phonePrefix,
          })),
          (option) => option.name
        )
      )
    );
    this.stepTranslations$ = this.currentStep$.pipe(
      withLatestFrom(this.isJunior$),
      map(([step, junior]) => (junior && step === JoinFlowStep.CONTACT_DETAILS ? 'junior' : step)),
      map((prefix) => ({
        title: `login.join.${prefix}Title`,
        description: `login.join.${prefix}Description`,
      })),
      finShare()
    );

    this.stepperSteps$ = this.currentStep$.pipe(
      withLatestFrom(this.isJunior$),
      map(([currentStep, junior]) => {
        return currentStep === JoinFlowStep.JOIN_COMPLETE
          ? []
          : [
              JoinFlowStep.BASIC_INFO,
              JoinFlowStep.CONTACT_DETAILS,
              JoinFlowStep.GUARDIAN_DETAILS,
              JoinFlowStep.MARKETING_CONSENT,
            ]
              .filter((step) =>
                junior ? step !== JoinFlowStep.MARKETING_CONSENT : step !== JoinFlowStep.GUARDIAN_DETAILS
              )
              .map((step) => ({
                step,
                ariaLabel: `login.join.${step}Aria`,
              }));
      })
    );

    this.stepperIndex$ = this.currentStep$.pipe(
      withLatestFrom(this.stepperSteps$),
      map(([currentStep, steps]) => steps.findIndex(({ step }) => step === currentStep))
    );

    this.subscriptions.add(
      this.router.events.pipe(filter((e) => e instanceof NavigationStart)).subscribe(() => this.closeDialog())
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  openLogin(): void {
    this.trackEvent('open-login');
    this.closeDialog();
    this.store$.dispatch(LoginActions.setLoginPhaseStep({ step: LoginStep.CREDENTIALS }));
  }

  moveToStep(step: JoinFlowStep): void {
    this.trackEvent(`open-step-${step}`);
    this.currentStep$.next(step);
  }

  closeDialog(): void {
    this.trackEvent('close-dialog');
    this.joinService.joinDialogOpen$.next(false);
  }

  complete(isJunior = false): void {
    const data = this.signUpData(isJunior);
    this.loading$.next(true);
    this.subscriptions.add(
      this.joinService.joinFinnairPlus(data).subscribe({
        next: (responseData: JoinFinnairPlusResponseData) => {
          this.trackEvent('join-event', 'true');
          this.trackDataCloud(responseData.memberNumber);
          this.currentStep$.next(JoinFlowStep.JOIN_COMPLETE);
          this.loading$.next(false);
        },
        error: () => {
          this.trackEvent('join-event', 'false');
          this.showErrorAndClose();
        },
      })
    );
  }

  readMore(): void {
    this.trackEvent('read-more');
    this.subscriptions.add(
      this.languageService
        .translate('fragments.plusReadMore.url')
        .subscribe((url) => this.windowRef.nativeWindow.open(url, '_blank'))
    );
  }

  openStep(stepperStep: StepperStep): void {
    const { step } = stepperStep as Step;
    this.trackEvent(`open-stepper-step-${step}`);
    this.currentStep$.next(step);
  }

  removeLeadingZero(event: Event): void {
    if (event.type !== 'input') {
      return;
    }

    const inputEvent = event as InputEvent;
    const inputField = event.target as HTMLInputElement;

    if (!inputEvent.inputType && inputField.value[0] === '0') {
      this.contactDetailsFormPhone.get('phoneNumber').setValue(inputField.value.slice(1));
    }
  }

  private createMarketingConsentForm(consents: Consent[]) {
    const consentFields = consents.reduce((fields, consent) => {
      fields[consent.consentTextId] = this.fb.control(false, { nonNullable: true });
      return fields;
    }, {});
    this.marketingConsentForm = this.fb.group({
      ...consentFields,
    });
  }

  private signUpData(isJunior: boolean): JoinFinnairPlusRequestData {
    const { birthDate, firstName, lastName, gender } = this.basicInfoForm.getRawValue();
    const { accept, email, password, phone, countryCode } = this.contactDetailsForm.getRawValue();
    const date = TzDate.utcMidnight(birthDate.yearOfBirth, birthDate.monthOfBirth, birthDate.dateOfBirth);

    const data: JoinFinnairPlusRequestData = {
      firstName,
      lastName,
      gender,
      dateOfBirth: date.toTzString(),
      password: password.pwd,
      phoneNumber: `+${phone.phonePrefix}${phone.phoneNumber}`,
      enrollmentChannel: 'Web',
      language: this.languageService.langKeyValue,
      countryCode: countryCode,
      email,
    };

    if (isJunior) {
      const { consent, guardianEmail, guardianMemberNumber, guardianName, guardianPhoneNumber } =
        this.guardianInfoForm.getRawValue();
      return {
        ...data,
        consent,
        guardianName,
        guardianEmail,
        guardianMemberNumber,
        guardianPhoneNumber: `+${guardianPhoneNumber.phonePrefix}${guardianPhoneNumber.phoneNumber}`,
      };
    }

    const marketingConsentForm = this.marketingConsentForm.getRawValue();

    return {
      ...data,
      agreements: accept,
      marketingPermissions: marketingConsentForm,
    };
  }

  private getDays = (yearControl: AbstractControl, monthControl: AbstractControl): Observable<number[]> => {
    return combineLatest([
      yearControl.valueChanges.pipe(startWith(yearControl.value as string), filter(isTruthy)),
      monthControl.valueChanges.pipe(startWith(monthControl.value as string), filter(isTruthy)),
    ]).pipe(
      map(([year, month]: [string, string]) => LocalDate.getAmountOfDaysInMonth(Number(year), Number(month))),
      map((numberOfDays) => rangeFrom(1, numberOfDays)),
      startWith(rangeFrom(1, 31)),
      distinctUntilChanged(),
      finShare()
    );
  };

  private passwordMatchValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (
        !control.get('pwd').pristine &&
        !control.get('confirmPwd').pristine &&
        control.get('pwd').value !== control.get('confirmPwd').value
      ) {
        return { match: true };
      }
      return null;
    };
  };

  private phoneValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.get('phoneNumber').pristine && !control.get('phoneNumber').value.match(PHONE_PATTERN)) {
        return { pattern: true };
      }
      return null;
    };
  };

  private ageValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.get('dateOfBirth').value && control.get('monthOfBirth').value && control.get('yearOfBirth').value) {
        const date = LocalDate.forDate(
          control.get('yearOfBirth').value,
          control.get('monthOfBirth').value,
          control.get('dateOfBirth').value
        );
        if (date.isBetween(LocalDate.now().minusYears(2), LocalDate.now())) {
          return { tooYoung: true };
        }
        if (date.gte(LocalDate.now())) {
          return { invalid: true };
        }
      }
      return null;
    };
  };

  private showErrorAndClose(): void {
    this.toasterService.addMessageToQueue({
      id: 'join-failed',
      contentKey: 'login.join.flowError',
      theme: NotificationTheme.ALERT,
    });
    this.closeDialog();
  }

  private trackEvent(elementName: string, state: string = undefined): void {
    this.gtmService.trackElement(
      `join-form-${elementName}`,
      GaContext.FINNAIR_PLUS,
      ElementTypes.BUTTON,
      state,
      ElementActions.CLICK
    );
  }

  private trackDataCloud(memberNumber: string): void {
    const firstName = this.basicInfoForm.get('firstName').value;
    const lastName = this.basicInfoForm.get('lastName').value;
    const email = this.contactDetailsForm.get('email').value;
    const phone = this.contactDetailsForm.get('phone').value;
    this.dataCloudService.signupPaxDetails({
      firstName,
      lastName,
      emailAddress: email,
      countryCode: phone.phonePrefix,
      phoneNumber: phone.phoneNumber,
      memberProgram: Airline.FINNAIR,
      memberNumber,
    });
  }
}
