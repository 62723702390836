import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoaderType } from '@fcom/common/';
import { Location } from '@fcom/core-api';
import { unsubscribe } from '@fcom/core/utils';

export enum AutocompleteTheme {
  DEFAULT = 'default',
  WEB_2 = 'web-2',
}

@Component({
  selector: 'fin-locations-autocomplete',
  templateUrl: 'locations-autocomplete.component.html',
  styleUrls: ['./locations-autocomplete.component.scss'],
})
export class LocationsAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatAutocompleteTrigger, { static: true }) trigger;

  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  controlName: string;

  @Input()
  translationPrefix: string;

  @Input()
  inputLabel: string;

  @Input()
  inputType: string;

  @Input()
  placeHolder: string = null;

  @Input()
  options$: Subject<Location[]> = new Subject();

  @Input()
  loading$: Subject<boolean>;

  @Input()
  theme = AutocompleteTheme.DEFAULT;

  public readonly LoaderType = LoaderType;
  readonly AutocompleteTheme = AutocompleteTheme;
  optionsAmount$: Observable<number>;
  isRequired = false;

  private subscriptions: Subscription = new Subscription();

  constructor() {}

  ngAfterViewInit() {
    this.subscriptions.add(
      this.trigger.panelClosingActions.subscribe((e) => {
        if (!e?.source) {
          this.ctrlField.setValue(null);
          this.trigger.closePanel();
        }
      })
    );
  }

  displayLocation(location?: Location): string | undefined {
    return location ? `(${location.locationCode}) ${location.title}` : undefined;
  }

  ngOnInit() {
    this.isRequired = this.required;
    this.optionsAmount$ = this.options$.pipe(map((options) => options.length));
  }

  ngOnDestroy() {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  get required() {
    return this.ctrlField.errors?.required;
  }

  get ctrlField() {
    return this.parentForm.get(this.controlName);
  }
}
