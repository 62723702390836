import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { map, distinctUntilChanged } from 'rxjs/operators';

import { FinnairAmount } from '@fcom/dapi/api/models';
import { AppState } from '@fcom/core/interfaces';
import { pseudoLocal } from '@fcom/core/selectors';

@Component({
  selector: 'fin-total-expand',
  templateUrl: './total-expand.component.html',
  styleUrls: ['./total-expand.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalExpandComponent implements OnInit {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  constructor(private store$: Store<AppState>) {}

  @Input()
  expandId: string;

  @Input()
  total$: Observable<FinnairAmount>;

  @Input()
  initiallyOpen = false;

  @Input()
  loading$: Observable<boolean>;

  @Input()
  totalTranslationKey$: Observable<string>;

  @Input()
  titleTranslationKey: string;

  @Input()
  expandButtonAriaLabelKey: string;

  isOpen$: Observable<boolean>;

  ngOnInit(): void {
    // proxy the child state here for testability
    this.isOpen$ = this.store$.pipe(pseudoLocal(this.expandId), map(Boolean), distinctUntilChanged());
  }
}
