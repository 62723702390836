<form>
  <div class="flex">
    <fcom-text-input
      data-testid="additional-email"
      class="block ms-xsmall-t flex-1"
      [parentForm]="parentForm"
      [displayMargin]="false"
      [translationPrefix]="controlName"
      [controlName]="controlName"
      [placeHolder]="parentForm.get(controlName).valid ? placeholder : disabledPlaceholder"
      [displayMargin]="false"
    ></fcom-text-input>
    <fcom-button
      class="add-button flex--self-end ms-small-l ms-xxsmall-b"
      [text]="buttonText | finLocalization"
      [loading]="isLoading"
      [aria]="{ label: buttonText | finLocalization, loadingLabel: 'actions.loading' | finLocalization }"
      [disabled]="!parentForm.get(controlName).valid || !parentForm.get(controlName).value"
      (clickBtn)="addEmail.emit()"
      [type]="ButtonType.SUBMIT"
    >
    </fcom-button>
  </div>
</form>
<div class="tag-wrapper flex flex--wrap flex--start ms-small-t">
  <fcom-tag-button
    *ngFor="let email of emailsArray; let i = index"
    [title]="email"
    [text]="email"
    [disabled]="isLoading"
    [iconName]="SvgLibraryIcon.CLOSE_DELETE"
    (tagClicked)="removeEmail.emit(i)"
  >
  </fcom-tag-button>
</div>
