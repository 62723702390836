import { Injectable } from '@angular/core';

import { merge, Observable, Subject } from 'rxjs';

import { BreadcrumbItem } from '../../interfaces';

@Injectable()
export class BreadcrumbService {
  public breadcrumb$: Observable<BreadcrumbItem[]>;
  private breadcrumbItemsNormal$: Subject<BreadcrumbItem[]> = new Subject<BreadcrumbItem[]>(); // Populated from navigationPathList in CMS payload
  private breadcrumbItemsOverride$: Subject<BreadcrumbItem[]> = new Subject<BreadcrumbItem[]>(); // Populated by setBreadcrumb

  constructor() {
    this.constructBreadcrumbItemsNormal$();
    this.constructBreadcrumbItemsOverride$();
    this.constructBreadcrumbItems$();
  }

  public setBreadcrumbNormal(breadcrumbItems: BreadcrumbItem[]) {
    this.breadcrumbItemsNormal$.next(breadcrumbItems);
  }

  public setBreadcrumb(breadcrumbItems: BreadcrumbItem[]) {
    this.breadcrumbItemsOverride$.next(breadcrumbItems);
  }

  private constructBreadcrumbItems$() {
    this.breadcrumb$ = merge(this.breadcrumbItemsNormal$, this.breadcrumbItemsOverride$);
  }

  private constructBreadcrumbItemsNormal$() {
    this.breadcrumbItemsNormal$ = new Subject<BreadcrumbItem[]>();
  }

  private constructBreadcrumbItemsOverride$() {
    this.breadcrumbItemsOverride$ = new Subject<BreadcrumbItem[]>();
  }
}
