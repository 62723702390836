import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';

import { Bound } from '@fcom/dapi/api/models';

@Component({
  selector: 'fin-flight-selection-flight-summary',
  templateUrl: './flight-summary.component.html',
  styleUrls: ['./flight-summary.component.scss'],
})
export class FlightSelectionFlightSummaryComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input() listStyle: 'ordered' | 'departure-return' = 'departure-return';
  @Input({ required: true }) bounds: Observable<Bound[]>;
  @Output() clickedBound = new EventEmitter<{
    bound: Bound;
    index: number;
  }>();
}
