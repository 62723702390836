/* eslint-disable */

/**
 * Electronic document type represent the type of document a traveler could have in his reservation such as eticket number or emd number (services)
 */
export enum FinnairDocumentType {
  ETICKET = 'ETICKET',
  SERVICES = 'SERVICES'
}

