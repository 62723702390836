import { Component, createNgModuleRef, Injector, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { Observable } from 'rxjs';

import { CmsTemplate } from '@fcom/core-api';

@Component({
  selector: 'fin-render-lazy-wizard',
  templateUrl: './render-lazy-wizard.component.html',
  styleUrls: ['./render-lazy-wizard.component.scss'],
})
export class RenderLazyWizardComponent implements OnInit {
  @ViewChild('lazyLoadComponent', { read: ViewContainerRef })
  lazyLoadComponent!: ViewContainerRef;

  @Input()
  template$: Observable<CmsTemplate>;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.loadWizard();
  }

  async loadWizard() {
    const { MultiStepConditionalFormModule, MultiStepConditionalFormComponent } = await import(
      '@fcom/service-forms-lazy/components/multi-step-conditional-forms'
    );
    const moduleRef = createNgModuleRef(MultiStepConditionalFormModule, this.injector);

    const { instance } = this.lazyLoadComponent.createComponent(MultiStepConditionalFormComponent, {
      ngModuleRef: moduleRef,
    });
    instance.template$ = this.template$;
  }
}
