export const stopPropagation = (event: Event): false => {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }
  return false;
};

export const stopImmediatePropagation = (event: Event): false => {
  if (event) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }
  return false;
};
