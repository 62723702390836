import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output, Renderer2, Input } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { unsubscribe } from '@fcom/core/utils';
import { PopoverService } from '@fcom/ui-components/components/popover/service/popover.service';

@Directive({
  selector: '[finClickOutside]',
})
export class ClickOutsideDirective implements AfterViewInit, OnDestroy {
  @Input() clickOutsideException = '';

  @Output()
  finClickOutside: EventEmitter<Event> = new EventEmitter();

  private subscription = new Subscription();
  private timeout: any;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private popoverService: PopoverService
  ) {}

  ngAfterViewInit(): void {
    // setTimeout needed for the event not to occur when click inserts this directive in DOM
    this.timeout = setTimeout(() => {
      const globalClicks$ = new Observable<Event>((observer) =>
        this.renderer.listen('document', 'click', (event: Event) => observer.next(event))
      );
      this.subscription.add(
        globalClicks$
          .pipe(
            filter(
              (event: Event) => !this.elementRef.nativeElement.contains(event.target) && !this.exceptionInPath(event)
            )
          )
          .subscribe((event) => this.finClickOutside.emit(event))
      );
    });

    // This is a temporary compatibility "api" so this works together with popovers
    setTimeout(() => {
      this.subscription.add(
        this.popoverService.popoverOpen$.pipe(filter(Boolean)).subscribe(() => {
          this.finClickOutside.emit();
        })
      );
    });
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeout);
    unsubscribe(this.subscription);
  }

  private exceptionInPath(event: Event): boolean {
    if (this.clickOutsideException === '') {
      return false; // don't even check if input is not given
    }
    if (!event.composedPath) {
      return true; // native clicks have this but test suite clicks don't
    }
    return event.composedPath().find((pathElem: Element) => pathElem.localName === this.clickOutsideException)
      ? true
      : false;
  }
}
