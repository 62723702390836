import {
  AirCalendarList,
  FinnairAirCalendarChangeListResponse,
  FinnairCalendarChangeItineraryItem,
  FinnairRequestItineraryItem,
} from '@fcom/dapi/api/models';
import { isPresent } from '@fcom/core/utils';

export const isBookingCalendarList = (
  calendarList: FinnairAirCalendarChangeListResponse | AirCalendarList
): calendarList is AirCalendarList => isPresent((calendarList as AirCalendarList).finnairSearchParameters);

export const isChangeItinerary = (
  itinerary: FinnairCalendarChangeItineraryItem[] | FinnairRequestItineraryItem[]
): itinerary is FinnairCalendarChangeItineraryItem[] => {
  return (itinerary as FinnairCalendarChangeItineraryItem[]).some((item) => isPresent(item.boundId));
};
