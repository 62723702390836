<a
  class="link-hover no-underline flex flex--middle pr-xxsmall-t"
  [finLink]="ctaButton$ | finMap: 'url' | async"
  [external]="ctaButton$ | finMap: 'external' | async"
  [attr.target]="(ctaButton$ | finMap: 'external' | async) ? '_blank' : ''"
  *ngIf="!!(ctaButton$ | async)"
>
  <span
    class="nordic-blue-900-text"
    [ngClass]="{ 'font-heading-2': !layout, 'font-heading-3': layout === 'small', 'font-body-1': layout === 'xsmall' }"
    *ngIf="teaserTitle$ | async as tt"
    >{{ tt }}
  </span>
  <fcom-icon
    role="presentation"
    class="nordic-blue-900-text icon-medium"
    [ngClass]="{
      'mr-small-l': !layout,
      'mr-xsmall-l': layout === 'small',
      'ms-xxsmall-l': layout === 'xsmall',
    }"
    [name]="SvgLibraryIcon.ARROW_RIGHT"
  ></fcom-icon>
</a>
<div class="teaserText mr-xsmall-t" [innerHTML]="teaserText$ | async | finEmpty: false | finSafe: 'html'"></div>
