import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

import { Month, Day } from '../../../utils/date.interface';
import { CalendarService } from '../services/calendar.service';

@Component({
  selector: 'fcom-calendar-month',
  styleUrls: ['./month.component.scss'],
  templateUrl: './month.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonthComponent {
  @Input()
  set value(monthId: string) {
    this.month = this.calendarService.getMonth(monthId);
  }

  @Input() dayTemplate: TemplateRef<{ day: Day }>;

  @Input() isDateRange: boolean;

  /**
   * If we need to display empty weeks
   */
  @Input() set showEmptyWeeks(value: boolean) {
    this.shouldShowEmptyWeeks = value;
  }

  month: Month;
  shouldShowEmptyWeeks: boolean;

  constructor(private calendarService: CalendarService) {}

  onKeyDown(event: KeyboardEvent): void {
    this.calendarService.processKey(event);
  }
}
