import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, combineLatest, NEVER, Observable, of, Subscription, take } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

import { ElementTypes, SelectOption } from '@fcom/common/interfaces';
import {
  isBlank,
  isEmpty,
  isFrequentFlyerNumber,
  PHONE_PATTERN,
  PHONE_PREFIX_PATTERN,
  sortBy,
  uniqueBy,
  unsubscribe,
} from '@fcom/core/utils';
import { LanguageService } from '@fcom/ui-translate';
import {
  ControlData,
  FinnairFrequentFlyerCardItem,
  FinnairPassengerCode,
  FinnairPassengerItem,
} from '@fcom/dapi/api/models';
import { ValidationErrors } from '@fcom/common/utils';
import { TypedFormGroup } from '@fcom/service-forms';
import { finShare } from '@fcom/rx';
import {
  ButtonMode,
  ButtonSize,
  ButtonTheme,
  ButtonType,
  CheckBoxTheme,
  IconButtonSize,
  IconButtonTheme,
  IconPosition,
  LoaderTheme,
  RadioButtonTheme,
} from '@fcom/ui-components';
import { AppConstants } from '@fcom/booking/app-constants';
import { Profile, ProfileType } from '@fcom/core-api/login';
import { isFrequentFlyerFieldFilled } from '@fcom/booking/utils/passenger-utils';
import { CorporateUser } from '@fcom/common/interfaces/corporate/corporate.interface';
import { isCorporateAdminOrTravelBooker } from '@fcom/common/login/services/utils';

import { GENDER_OPTIONS } from '../../interfaces';

export const PaxFormFields = {
  gender: 'gender',
  firstName: 'firstName',
  lastName: 'lastName',
  type: 'type',
  email: 'email',
  phone: 'phone',
  phones: 'phones',
  phoneNumber: 'phoneNumber',
  phonePrefix: 'phonePrefix',
  birthDate: 'birthDate',
  yearOfBirth: 'yearOfBirth',
  monthOfBirth: 'monthOfBirth',
  dateOfBirth: 'dateOfBirth',
  frequentFlyerCard: 'frequentFlyerCard',
  frequentFlyerCards: 'frequentFlyerCards',
  companyCode: 'companyCode',
  cardNumber: 'cardNumber',
  joinFinnairPlus: 'joinFinnairPlus',
};

export interface TranslatedCountryCode {
  countryCode: string;
  name: string;
  phonePrefix: string;
}

const SEPARATOR: SelectOption = {
  name: '-------------------',
  value: '-------------------',
  disabled: true,
};

const countryCodeToOption = (cc: TranslatedCountryCode): SelectOption =>
  cc && {
    name: `${cc.name} (+${cc.phonePrefix})`,
    value: `${cc.countryCode}|${cc.phonePrefix}`,
  };

const matchingCountryCode =
  (countryCode = '') =>
  (cc: TranslatedCountryCode) =>
    countryCode.toLowerCase() === (cc.countryCode || '').toLowerCase();

export const validateFrequentFlyer = (
  formGroup: UntypedFormGroup,
  existingCards: FinnairFrequentFlyerCardItem[]
): ValidationErrors => {
  const companyCode = formGroup.get(PaxFormFields.companyCode);
  const cardNumber = formGroup.get(PaxFormFields.cardNumber);

  if (!companyCode.value && !cardNumber.value) {
    companyCode.setErrors(null);
    cardNumber.setErrors(null);
    return null;
  }

  if (!isFrequentFlyerNumber(companyCode.value, cardNumber.value) && !!companyCode.value) {
    cardNumber.setErrors({
      custom: 'notValidCardNumber',
    });
  } else if (
    existingCards?.some((card) => card.cardNumber === cardNumber.value && card.companyCode === companyCode.value)
  ) {
    cardNumber.setErrors({
      custom: 'sameCardNumberExists',
    });
  } else {
    cardNumber.setErrors(null);
  }

  companyCode.setErrors(Validators.required(companyCode));
  return null;
};

export const validatePhoneNumber = (
  formGroup: UntypedFormGroup,
  phoneNumberRegex: string,
  required: boolean
): ValidationErrors => {
  const phonePrefix = formGroup.get(PaxFormFields.phonePrefix);
  const phoneNumber = formGroup.get(PaxFormFields.phoneNumber);

  if (!phonePrefix.value && !phoneNumber.value && !required) {
    phonePrefix.setErrors(null);
    phoneNumber.setErrors(null);
    return null;
  }

  const phonePrefixValidation = Validators.compose([Validators.required, Validators.pattern(PHONE_PREFIX_PATTERN)])(
    phonePrefix
  );
  const phoneNumberValidation = Validators.compose([
    Validators.required,
    Validators.pattern(phoneNumberRegex || PHONE_PATTERN),
  ])(phoneNumber);

  phonePrefix.setErrors(phonePrefixValidation);
  phoneNumber.setErrors(phoneNumberValidation);
  return null;
};

@Component({
  selector: 'fin-pax-details-form-group',
  templateUrl: 'pax-details-form-group.component.html',
  styleUrls: ['pax-details-form-group.component.scss'],
})
export class PaxDetailsFormGroupComponent implements OnInit, OnChanges, OnDestroy {
  @HostBinding('class.relative')
  @HostBinding('class.block')
  defaultClass = true;

  readonly FinnairPassengerCode = FinnairPassengerCode;
  readonly ElementTypes = ElementTypes;
  readonly LoaderTheme = LoaderTheme;

  readonly GENDER_OPTIONS = GENDER_OPTIONS;
  readonly FIELDS = PaxFormFields;
  readonly REQUIRED = Validators.required;
  readonly IconPosition = IconPosition;
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonType = ButtonType;
  readonly ButtonMode = ButtonMode;
  readonly ButtonSize = ButtonSize;
  readonly CheckBoxTheme = CheckBoxTheme;
  readonly C = AppConstants;
  readonly IconButtonTheme = IconButtonTheme;
  readonly IconButtonSize = IconButtonSize;
  readonly IconLibrary = IconLibrary;
  readonly RadioButtonTheme = RadioButtonTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input() passengerFormGroup: TypedFormGroup<UntypedFormGroup | UntypedFormControl>;
  @Input() groupIndex: number;
  @Input() allowMultipleFFCards = false;
  @Input() trackingContext: string;

  @Input() controlData: ControlData;
  @Input() isMainPassenger = false;
  @Input() frequentFlyerAirlines$: Observable<SelectOption[]> = of([]);
  @Input() availableDates: { days$: Observable<number[]>; months: number[]; years: number[] };
  @Input() allFrequentFlyerCards: FinnairFrequentFlyerCardItem[];
  @Input() profileType$: Observable<ProfileType>;
  @Input() profile$: Observable<Profile> = NEVER;
  @Input() showContactDetails = false;
  @Input() hideDisabledFields = false;
  @Input() hideGroups = false;
  @Input() corporateUsers$: Observable<CorporateUser[]>;
  @Input() enablePaxDetailsRearrangement$?: Observable<boolean> = of(false);
  @Input() showCopyDetailsButton = false;
  @Input() copyFromPassengersList: FinnairPassengerItem[] = [];

  @Output() focusEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() blurEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() corporateUserSelected = new EventEmitter<CorporateUser>();
  @Output() corporateUserCleared = new EventEmitter<CorporateUser>();
  @Output() copyDetailsClicked: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('loaderContainer') loaderContainer: ElementRef;

  phonePrefixes$: Observable<SelectOption[]>;
  frequentFlyerCardOptions$: Observable<SelectOption[][]>;
  frequentFlyerGroupConfig = {
    [PaxFormFields.companyCode]: ['', []],
    [PaxFormFields.cardNumber]: ['', []],
  };
  phoneNumbersGroupConfig = {
    [PaxFormFields.phoneNumber]: ['', []],
    [PaxFormFields.phonePrefix]: ['', []],
  };

  frequentFlyerGroupOptions = {
    validators: (formGroup: UntypedFormGroup): ValidationErrors =>
      validateFrequentFlyer(formGroup, this.allFrequentFlyerCards),
  };
  phoneGroupOptions = {
    validators: (formGroup: UntypedFormGroup): ValidationErrors =>
      validatePhoneNumber(formGroup, this.controlData?.phoneNumberRegex, this.isMainPassenger),
  };

  copyFromPassengerGroup: UntypedFormGroup;
  copyFromPassengers: SelectOption[];

  isFFCardInputDisabled: boolean;
  hasFFCardValue: boolean;
  shouldShowFFCardSection: boolean;
  isJoinFinnairPlusDisabled: boolean;
  hasJoinFinnairPlusValue: boolean;
  shouldShowJoinFinnairPlusSection: boolean;

  dayOptions$: Observable<SelectOption[]>;
  monthOptions: SelectOption[];
  yearOptions: SelectOption[];
  frequentFlyerToggle$ = new BehaviorSubject<boolean>(false);
  frequentFlyerIsOpen$: Observable<boolean> = of(false);
  isCorporateAdminOrTravelBooker$: Observable<boolean>;
  showLoader$ = new BehaviorSubject<boolean>(false);

  subscriptions = new Subscription();

  constructor(
    private languageService: LanguageService,
    private elementRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initializeCopyFrom();

    this.isFFCardInputDisabled = this.passengerFormGroup.get(PaxFormFields.frequentFlyerCard)?.disabled;
    this.isJoinFinnairPlusDisabled = this.passengerFormGroup.get(PaxFormFields.joinFinnairPlus)?.disabled;

    this.hasFFCardValue =
      this.passengerFormGroup.get([PaxFormFields.frequentFlyerCard, 'companyCode'])?.value !== '' &&
      this.passengerFormGroup.get([PaxFormFields.frequentFlyerCard, 'cardNumber'])?.value !== '';
    this.hasJoinFinnairPlusValue = this.passengerFormGroup.get(PaxFormFields.joinFinnairPlus)?.value !== '';

    this.shouldShowFFCardSection = !(this.hideDisabledFields && this.isFFCardInputDisabled && !this.hasFFCardValue);

    this.shouldShowJoinFinnairPlusSection = !(
      this.hideDisabledFields &&
      this.isJoinFinnairPlusDisabled &&
      !this.hasJoinFinnairPlusValue
    );

    // availableDates is not set for non-american routes as DoB is not required.
    this.dayOptions$ = this.availableDates?.days$.pipe(
      map((days) => {
        return days.map((day) => {
          return { name: String(day), value: String(day) };
        });
      })
    );

    this.monthOptions = this.availableDates?.months.map((month) => {
      return { name: String(month), value: String(month) };
    });

    this.yearOptions = this.availableDates?.years.map((year) => {
      return { name: String(year), value: String(year) };
    });

    const phonePrefixSelected = this.passengerFormGroup.controls[PaxFormFields.phone]?.value[PaxFormFields.phonePrefix];
    const phoneNumberFilled = this.passengerFormGroup.controls[PaxFormFields.phone]?.value[PaxFormFields.phoneNumber];
    const emailFilled = this.passengerFormGroup.controls[PaxFormFields.email]?.value;

    if (emailFilled || phoneNumberFilled || phonePrefixSelected) {
      this.showContactDetails = true;
    }

    this.setupPhonePrefixes();
    this.setupJoinFinnairPlus();

    this.passengerFormGroup.controls[PaxFormFields.frequentFlyerCard]?.addValidators((formGroup: UntypedFormGroup) =>
      validateFrequentFlyer(formGroup, this.allFrequentFlyerCards)
    );
    this.passengerFormGroup.controls[PaxFormFields.phone]?.addValidators((formGroup: UntypedFormGroup) =>
      validatePhoneNumber(formGroup, this.controlData.phoneNumberRegex, this.isMainPassenger)
    );
    const frequentFlyerCards = this.passengerFormGroup.controls[PaxFormFields.frequentFlyerCards];
    if (frequentFlyerCards) {
      this.frequentFlyerCardOptions$ = combineLatest([
        frequentFlyerCards.valueChanges.pipe(startWith(frequentFlyerCards.value as [])),
        this.frequentFlyerAirlines$,
      ]).pipe(
        map(([currentSelection, frequentFlyerAirlines]) => {
          if (isEmpty(currentSelection)) {
            return [frequentFlyerAirlines];
          }
          const selectedCodes = currentSelection.map((selected) => selected.companyCode);
          return currentSelection.map(({ companyCode }) =>
            frequentFlyerAirlines.filter(
              (airline) => !selectedCodes.includes(airline.value) || companyCode === airline.value
            )
          );
        }),
        distinctUntilChanged(),
        finShare()
      );
    }

    const frequentFlyerCard = this.passengerFormGroup.get(PaxFormFields.frequentFlyerCard);
    if (frequentFlyerCard) {
      this.frequentFlyerIsOpen$ = combineLatest([
        frequentFlyerCard.valueChanges.pipe(
          startWith({ companyCode: '', cardNumber: '' }), // to trigger valueChanges when already logged in
          map(({ companyCode, cardNumber }) => {
            return !isBlank(companyCode) || !isBlank(cardNumber);
          })
        ),
        this.frequentFlyerToggle$,
      ]).pipe(
        map(([ffHasData, toggle]) => {
          return ffHasData || toggle;
        })
      );
    }

    this.isCorporateAdminOrTravelBooker$ = this.profile$.pipe(
      map((profile) => isCorporateAdminOrTravelBooker(profile))
    );
    this.subscriptions.add(
      this.showLoader$.pipe(filter(Boolean), take(1)).subscribe(() => {
        setTimeout(() => {
          this.setLoaderContainerSize();
        });
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['copyFromPassengersList']) {
      this.copyFromPassengers = this.getCopyFromList();
    }
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  private getCopyFromList(): SelectOption[] {
    return this.copyFromPassengersList.map((passenger) => ({
      name: `${passenger.firstName} ${passenger.lastName}`,
      value: passenger.id,
    }));
  }

  private initializeCopyFrom(): void {
    this.copyFromPassengers = this.getCopyFromList();

    this.copyFromPassengerGroup = this.fb.group({
      copyFromPassengers: [''],
    });

    this.subscriptions.add(
      this.copyFromPassengerGroup
        .get('copyFromPassengers')
        .valueChanges.pipe(filter((passengerId) => !!passengerId))
        .subscribe((passengerId) => {
          const copyFrom = this.copyFromPassengersList.find((p) => p.id === passengerId);

          this.passengerFormGroup.get(PaxFormFields.email).setValue(copyFrom.email);

          const createPhone = (number) =>
            this.fb.group(
              {
                [PaxFormFields.phonePrefix]: number?.countryPrefix
                  ? `${number.countryCode}|${number.countryPrefix}`
                  : '',
                [PaxFormFields.phoneNumber]: number?.number ?? '',
              },
              {
                validators: (formGroup: UntypedFormGroup) =>
                  validatePhoneNumber(formGroup, this.controlData?.phoneNumberRegex, this.isMainPassenger),
              }
            );

          this.passengerFormGroup.setControl(
            PaxFormFields.phones,
            this.fb.array(
              copyFrom.phoneNumbers?.length > 0
                ? copyFrom.phoneNumbers.map((number) => {
                    return createPhone(number);
                  })
                : [createPhone({})]
            )
          );
          this.passengerFormGroup.markAsDirty();
        })
    );
  }

  private setLoaderContainerSize(): void {
    const parentTop = this.elementRef.nativeElement.getBoundingClientRect().top;
    const loaderTop = this.loaderContainer.nativeElement.getBoundingClientRect().top;
    this.renderer.setStyle(this.loaderContainer.nativeElement, 'height', `calc(100% - ${loaderTop - parentTop}px`);
  }

  private setupPhonePrefixes() {
    this.phonePrefixes$ = this.languageService.translate('countryCodes').pipe(
      map((countryCodes: TranslatedCountryCode[]) => {
        const locations = [this.controlData?.originCountryCode, this.controlData?.destinationCountryCode];
        const baseOptions = sortBy(countryCodes.map(countryCodeToOption), (option) => option.name);
        const optionsBasedOnLocations = locations
          .map((code) => countryCodeToOption(countryCodes.find(matchingCountryCode(code))))
          .filter((option) => !!option)
          .filter(uniqueBy((country) => country['value']));
        return optionsBasedOnLocations.length === 0
          ? baseOptions
          : [...optionsBasedOnLocations, SEPARATOR, ...baseOptions];
      })
    );
  }

  showContactDetailsFields(): void {
    this.showContactDetails = true;
    this.changeDetectorRef.detectChanges();

    this.elementRef.nativeElement.querySelector('[data-testid="pax-details-email"] input')?.focus();
  }

  removeLeadingZero(event: Event): void {
    if (event.type !== 'input') {
      return;
    }

    const inputEvent = event as InputEvent;
    const inputField = event.target as HTMLInputElement;

    if (!inputEvent.inputType && inputField.value[0] === '0') {
      this.passengerFormGroup.get(this.FIELDS.phone).get(this.FIELDS.phoneNumber).setValue(inputField.value.slice(1));
    }
  }

  toggleFrequentFlyerSection(isOpen: boolean): void {
    const joinFinnairPlus = this.passengerFormGroup.get(PaxFormFields.joinFinnairPlus);
    this.frequentFlyerToggle$.next(isOpen);
    const frequentFlyer = this.passengerFormGroup.get(PaxFormFields.frequentFlyerCard);

    if (!isOpen) {
      frequentFlyer.get(PaxFormFields.cardNumber).setValue('');
      frequentFlyer.get(PaxFormFields.companyCode).setValue('');
    }
    if (isOpen && frequentFlyer.get(PaxFormFields.companyCode).value === '') {
      // Preselect Finnair
      frequentFlyer.get(PaxFormFields.companyCode).setValue('AY');
    }
    if (joinFinnairPlus.value === true) {
      joinFinnairPlus.setValue(false);
    }
  }

  toggleJoinFinnairPlus(): void {
    const joinFinnairPlus = this.passengerFormGroup.get(PaxFormFields.joinFinnairPlus);
    const frequentFlyer = this.passengerFormGroup.get(PaxFormFields.frequentFlyerCard);

    if (joinFinnairPlus.value) {
      this.frequentFlyerToggle$.next(false);
      frequentFlyer.get(PaxFormFields.cardNumber).setValue('');
      frequentFlyer.get(PaxFormFields.companyCode).setValue('');
    }
  }

  setupJoinFinnairPlus(): void {
    const frequentFlyer = this.passengerFormGroup.get(PaxFormFields.frequentFlyerCard);
    const joinFinnairPlus = this.passengerFormGroup.get(PaxFormFields.joinFinnairPlus);
    if (!frequentFlyer) {
      return;
    }
    // Set initial statuses
    if (
      isFrequentFlyerFieldFilled(
        frequentFlyer.get(PaxFormFields.cardNumber).value,
        frequentFlyer.get(PaxFormFields.companyCode).value
      )
    ) {
      this.frequentFlyerToggle$.next(true);
      joinFinnairPlus.setValue(false);
    } else if (joinFinnairPlus.value) {
      this.frequentFlyerToggle$.next(false);
      joinFinnairPlus.setValue(true);
    }
  }

  selectCorporateUser(user: CorporateUser): void {
    this.corporateUserSelected.emit(user);
    this.showContactDetails = true;
  }
}
