import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import {
  FinnairBoundItem,
  FinnairDisruptedBoundItem,
  FinnairItineraryItem,
  FinnairItineraryItemFlight,
  FinnairItineraryItemLayover,
  FinnairItineraryNote,
} from '@fcom/dapi/api/models';
import { Duration } from '@fcom/dapi';
import { AppConstants } from '@fcom/booking/app-constants';
import { BUS_AIRCRAFT_CODE } from '@fcom/booking/modules/ticket-selection/interfaces';
import { NotificationLayout, NotificationTheme } from '@fcom/ui-components';

@Component({
  selector: 'fin-itinerary-timeline-layover',
  styleUrls: ['../itinerary.scss', '../arrow-block.scss'],
  templateUrl: './itinerary-timeline-layover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryTimelineLayoverComponent {
  public readonly C: typeof AppConstants = AppConstants;
  readonly BUS_AIRCRAFT_CODE = BUS_AIRCRAFT_CODE;
  readonly NotificationLayout = NotificationLayout;
  readonly NotificationTheme = NotificationTheme;
  readonly FinnairItineraryNote = FinnairItineraryNote;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  layover: FinnairItineraryItemLayover;

  @Input()
  bound: FinnairBoundItem | FinnairDisruptedBoundItem;

  @Input()
  previousLeg: FinnairItineraryItem;

  @Input()
  nextLeg: FinnairItineraryItem;

  @Input()
  notification: string;

  @Input()
  oldDuration: Duration;

  @Input()
  isBookingFlow = false;

  toType(t: FinnairItineraryItem): FinnairItineraryItemFlight | FinnairItineraryItemLayover {
    return 'technicalStops' in t ? (t as FinnairItineraryItemFlight) : (t as FinnairItineraryItemLayover);
  }
}
