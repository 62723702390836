<ng-container *ngTemplateOutlet="enableNewLanguageSelector ? languageSelector : matrix"></ng-container>

<ng-template #languageSelector>
  <div class="df-laptop-up landing-page pr-large-t">
    <div class="country-selector-wrapper">
      <div class="country-selector align-center">
        <fcom-icon
          class="finnair-logo nordic-blue-900-text mr-large-y"
          [category]="IconLibrary.CUSTOM"
          [name]="CustomIcon.FINNAIR_LOGO"
        ></fcom-icon>
        <fin-country-and-language-selector
          [useH1]="true"
          [prefill]="false"
          [showCloseButton]="false"
          (applySelection)="applySelection($event)"
        ></fin-country-and-language-selector>
        <div class="ms-large-t font-body-2">Your country wasn't listed?</div>
        <a class="nordic-blue-900-text font-body-2" href="/en">Go to the international site</a>
      </div>
    </div>
    <div
      *ngIf="imageUrl$ | async"
      class="image-wrapper dn-laptop-down"
      [ngStyle]="{ 'background-image': 'url(' + (imageUrl$ | async) + ')' }"
    ></div>
  </div>
</ng-template>

<ng-template #matrix>
  <div class="grey-50-bg wd-langSelector grow pr-xsmall">
    <div class="white-bg pr-xsmall-x nordic-blue-900-text rounded border">
      <h1 class="pr-xsmall-y border-bottom font-body-1">Select your location and language</h1>
      <ul class="languages nordic-blue-900-text no-list-style font-body-1" role="list">
        <li
          class="language flex flex-nowrap flex-row border-bottom border-none-for-last pr-xsmall-y"
          *ngFor="let country of countries$ | async"
        >
          <div class="flex flex-1 flex--basis0">
            <span class="flex--nogrow"><span class="flag flag-{{ country.code | lowercase }}"></span></span>
            <span class="bold flex-1 pr-xsmall-l" [innerHTML]="country.name | finSafe: 'html'"></span>
          </div>

          <div class="flex-1 flex--basis0">
            <a
              class="nordic-blue-900-text pr-xsmall-l"
              *ngFor="let lang of country | finMap: 'languages'"
              [attr.aria-label]="lang.label"
              [finLink]="lang.link || lang.code"
              [attr.lang]="lang.ISOCode"
              [innerHTML]="lang.name | finSafe: 'html'"
              [attr.data-testid]="'lang-selection-link-' + lang.code"
            >
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
