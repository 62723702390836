import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, combineLatest, concatMap, noop, Observable, of, race, Subscription } from 'rxjs';
import { delay, filter, map, pairwise, take } from 'rxjs/operators';

import { CorporateUser } from '@fcom/common/interfaces/corporate/corporate.interface';
import { stopPropagation, unsubscribe } from '@fcom/core/utils';
import { TextInputComponent } from '@fcom/ui-components';
import { PassengerFormService, PrefillSource } from '@fcom/common-booking/services';
import { TypedFormGroup } from '@fcom/service-forms';

interface SearchForm {
  search: AbstractControl<string | undefined>;
}

@Component({
  selector: 'fin-corporate-passenger-search',
  templateUrl: './corporate-passenger-search.component.html',
  styleUrl: './corporate-passenger-search.component.scss',
})
export class CorporatePassengerSearchComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput: TextInputComponent;

  @Input()
  corporateUsers$: Observable<CorporateUser[]>;

  @Input({ required: true })
  passengerFormGroup: TypedFormGroup<UntypedFormGroup | UntypedFormControl>;

  @Output()
  userSelected = new EventEmitter<CorporateUser>();

  @Output()
  userCleared = new EventEmitter<CorporateUser>();

  @Output()
  toggleLoader = new EventEmitter<boolean>();

  form: FormGroup<SearchForm>;

  filteredUsers$: Observable<CorporateUser[] | undefined>;
  selectedUser$ = new BehaviorSubject<CorporateUser | undefined>(undefined);
  prefillDone = false;
  icon$: BehaviorSubject<SvgLibraryIcon> = new BehaviorSubject(SvgLibraryIcon.SEARCH);
  timer$: Observable<boolean | unknown>;

  subscriptions = new Subscription();

  constructor(private passengerFormService: PassengerFormService) {}

  ngOnInit(): void {
    this.form = new FormGroup<SearchForm>({
      search: new FormControl(),
    });

    this.filteredUsers$ = combineLatest([
      this.form.get('search').valueChanges,
      this.corporateUsers$,
      this.selectedUser$,
    ]).pipe(
      map(([input, users, selectedUser]) => {
        if (!input || selectedUser) {
          return undefined;
        }
        return users.filter((user) => {
          const name = `${user.firstname} ${user.lastname}`;
          return name.toLowerCase().includes(input.toLowerCase());
        });
      })
    );

    this.subscriptions.add(
      this.form
        .get('search')
        .valueChanges.pipe(take(1))
        .subscribe(() => {
          this.startTimer();
        })
    );

    // Clear selected user if user changes the input after user has been selected
    this.subscriptions.add(
      this.selectedUser$
        .pipe(
          filter(Boolean),
          concatMap(() => {
            return this.form.get('search').valueChanges.pipe(pairwise(), take(1));
          })
        )
        .subscribe(() => {
          this.clearSelectedUser();
        })
    );
  }

  selectUser(user: CorporateUser, e: Event): void {
    stopPropagation(e);
    this.selectedUser$.next(user);
    this.prefillDone = true;
    this.form.get('search').setValue(`${user.firstname} ${user.lastname}`);
    this.userSelected.emit(user);

    this.icon$.next(SvgLibraryIcon.CLOSE_DELETE);
    this.subscriptions.add(
      this.passengerFormService
        .prefillData({ group: this.passengerFormGroup }, [PrefillSource.CORPORATE], user)
        .subscribe(noop)
    );
  }

  clearInput(): void {
    if (!this.prefillDone) {
      return;
    }
    this.form.get('search').setValue('');
    this.searchInput.inputElement.nativeElement?.focus();
    this.clearSelectedUser();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  private clearSelectedUser(): void {
    this.icon$.next(SvgLibraryIcon.SEARCH);
    this.prefillDone = false;
    const user = this.selectedUser$.value;
    this.userCleared.emit(user);
    this.selectedUser$.next(undefined);
  }

  private startTimer(): void {
    this.timer$ = of(true).pipe(delay(1000));
    this.subscriptions.add(
      race(this.timer$, this.corporateUsers$).subscribe((val) => {
        if (val === true) {
          this.toggleLoader.emit(true);
        }
      })
    );
    this.subscriptions.add(this.corporateUsers$.pipe(take(1)).subscribe(() => this.toggleLoader.emit(false)));
  }
}
