import { RouterNavigatedAction } from '@ngrx/router-store';
import { createAction, props, union } from '@ngrx/store';

export const setSelectedBoundId = createAction(
  '[DisruptionActions] setSelectedBoundId',
  props<{ selectedBoundId: string }>()
);

const all = union({ setSelectedBoundId });

export type ActionsUnion = typeof all | RouterNavigatedAction;
