<div *ngIf="mappedContent$ | async as content" class="mr-large-b">
  <h2 class="mr-medium-b" *ngIf="content.teaserTitle && content.subjectTaxonomyTags.indexOf(TAXONOMY.NO_TITLE) === -1">
    {{ content.teaserTitle }}
  </h2>

  <fin-expand
    *ngFor="let item of content.items; let index = index; let last = last"
    class="block"
    [initiallyOpen]="(item.segment | decodeURI: true) === routeFragment"
    [class.border-bottom]="last"
    [key]="'cms-accordion-' + content.contentId"
    [id]="'cms-accordion-' + content.contentId + '-' + index"
    #expand
  >
    <div
      class="trigger flex flex--center border-top"
      tabindex="-1"
      [class.pr-xxsmall-y]="withPictures"
      [class.pr-small-y]="!withPictures"
    >
      <div *ngIf="item.previewPicture && withPictures" class="mr-xsmall-r">
        <fin-content-image
          class="valign-middle preview-image"
          [imageData$]="item.previewPicture | finToObservable"
          [aspectRatio$]="'16x9' | finToObservable"
        ></fin-content-image>
      </div>
      <div class="flex-1 nordic-blue-900-text" data-testid="accordion-teaser-title">
        <h2 *ngIf="!withPictures" class="mr-clear-t" data-testid="accordion-no-image-teaser-title">
          {{ item.teaserTitle }}
        </h2>
        <h3 *ngIf="withPictures" class="mr-clear-t" data-testid="accordion-image-teaser-title">
          {{ item.teaserTitle }}
        </h3>
      </div>
      <fcom-icon
        class="flex-1 flex--nogrow rotate icon-medium"
        [class.rotate-active]="expand.isOpen$ | async"
        [name]="SvgLibraryIcon.CHEVRON_DOWN"
      ></fcom-icon>
    </div>
    <div class="content">
      <fin-cms-json-content [template$]="{ main: [item] } | finToObservable"></fin-cms-json-content>
    </div>
  </fin-expand>
</div>
