/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { catchErrorAsJson } from '@fcom/rx';
import { DapiBaseService } from '../dapi-base-service';
import { DapiHttpResponse } from '../dapi-http-response';
import { DapiRequestBuilder } from '../dapi-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FinnairAlternativeOffers } from '../models/finnair-alternative-offers';


/**
 * Offer self reac api resource
 */
@Injectable({
  providedIn: 'root',
})
export class OffersSelfReaccService extends DapiBaseService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  /**
   * Path part for operation disruptionAlternativeOffers
   */
  static readonly DisruptionAlternativeOffersPath = '/api/offer/{orderId}/disruption-rebooking/alternatives';

  /**
   * disruptionAlternativeOffers.
   *
   * Fetch alternative offers for a disrupted Order
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disruptionAlternativeOffers()` instead.
   *
   * This method doesn't expect any request body.
   */
  disruptionAlternativeOffers$Response(rootUrl: string, params: {
    orderId: string;
    locale?: string;
    hash?: string;
    boundSearchDate?: string;
    secondBoundSearchDate?: string;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<{
[key: string]: FinnairAlternativeOffers;
}>> {
    const rb = new DapiRequestBuilder(rootUrl, OffersSelfReaccService.DisruptionAlternativeOffersPath, 'get');

    if (params) {
      rb.path('orderId', params.orderId, {});
      rb.query('locale', params.locale, {});
      rb.query('hash', params.hash, {});
      rb.query('boundSearchDate', params.boundSearchDate, {});
      rb.query('secondBoundSearchDate', params.secondBoundSearchDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<{
        [key: string]: FinnairAlternativeOffers;
        }>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * disruptionAlternativeOffers.
   *
   * Fetch alternative offers for a disrupted Order
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disruptionAlternativeOffers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  disruptionAlternativeOffers(rootUrl: string, params: {
    orderId: string;
    locale?: string;
    hash?: string;
    boundSearchDate?: string;
    secondBoundSearchDate?: string;
  },
  context?: HttpContext

): Observable<{
[key: string]: FinnairAlternativeOffers;
}> {
    return this.disruptionAlternativeOffers$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<{
[key: string]: FinnairAlternativeOffers;
}>) => r.body as {
[key: string]: FinnairAlternativeOffers;
})
    );
  }

}
