/* eslint-disable */

/**
 * List of ineligibility reasons for disruption rebooking
 */
export enum FinnairDisruptionRebookingIneligibilityReason {
  NOT_ELIGIBLE_EMAIL_MISSING = 'NOT_ELIGIBLE_EMAIL_MISSING',
  AMADEUS_REASON = 'AMADEUS_REASON',
  AURINKOMATKAT_TICKETS = 'AURINKOMATKAT_TICKETS',
  COMMERCIAL_COOPERATION_TICKETS = 'COMMERCIAL_COOPERATION_TICKETS',
  ID_TICKETS = 'ID_TICKETS',
  LEISURE_AND_STAFF = 'LEISURE_AND_STAFF',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  NOT_ELIGIBLE_SSR = 'NOT_ELIGIBLE_SSR',
  ONE_UPGRADE = 'ONE_UPGRADE'
}

