import { Component, Input } from '@angular/core';

import { CmsContent } from '@fcom/core-api';

@Component({
  selector: 'fin-informational-teaser',
  templateUrl: './informational-teaser.component.html',
  styleUrls: ['./informational-teaser.component.scss'],
})
export class InformationalTeaserComponent {
  @Input()
  informationalTeaser: CmsContent;

  constructor() {}
}
