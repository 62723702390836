<div [formGroup]="parentForm" class="light-type" [class.ps-small-t]="displayContainerStyles">
  <label class="checkbox-container flex" [for]="idOrControlName">
    <input
      #inputElement
      type="checkbox"
      [attr.name]="translationPrefix + '.label' | finLocalization"
      [attr.aria-required]="isRequired"
      [value]="checkboxValue"
      [id]="idOrControlName"
      [formControlName]="controlName"
      [name]="controlName"
      class="is-vishidden"
    />
    <span [ngSwitch]="control.value" class="checkbox-icon nordic-blue-900-text" aria-hidden="true">
      <fcom-icon *ngSwitchCase="true" class="icon-small" [name]="SvgLibraryIcon.CHECKBOX_SELECTED"></fcom-icon>
      <fcom-icon *ngSwitchDefault class="icon-small" [name]="SvgLibraryIcon.CHECKBOX_UNSELECTED"></fcom-icon>
    </span>
    <span *ngIf="!descriptionText" [ngClass]="labelClass">
      {{ translationPrefix + '.label' | finLocalization }}
      <span *ngIf="isRequired"> *</span>
    </span>

    <span *ngIf="descriptionText" class="label font-body-1 ps-xsmall-l">
      <div class="medium-type">
        {{ translationPrefix + '.label' | finLocalization }} <span *ngIf="isRequired"> *</span>
      </div>
      <div class="font-body-2 nordic-blue-900-text">{{ descriptionText }}</div>
    </span>
  </label>

  <div *ngIf="ctrlField.invalid && ctrlField.touched" class="error-label font-body-2 regular-type" aria-live="polite">
    <span *ngIf="ctrlField.errors?.required" class="error-900-text">
      {{ translationPrefix + '.required' | finLocalization }}
    </span>
  </div>
</div>
