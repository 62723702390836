import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnDestroy,
  Inject,
  PLATFORM_ID,
  NgZone,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { WindowRef } from '@fcom/core/providers/window';

const mutationObserverInitConfig: { [key: string]: MutationObserverInit } = {
  default: {
    attributes: false,
    childList: true,
    characterData: false,
    subtree: true,
  },
  travelRestrictions: {
    attributes: true,
    childList: true,
    characterData: false,
    subtree: true,
  },
};

/**
 * @class MutationObserverDirective
 * @description Observe DOM mutations according to the Inputted MutationObserver init configuration and emit them to the Output
 *
 * Usage example:
 *   <div
 *     mutationObserverInitConfigKey='chat'
 *     (finMutationObserver)="onDomChange($event)"
 *     #target
 *   ></div>
 */
@Directive({
  selector: '[finMutationObserver]',
})
export class MutationObserverDirective implements OnInit, OnDestroy {
  @Input()
  mutationObserverInitConfigKey = 'default';

  @Output()
  finMutationObserver: EventEmitter<MutationRecord> = new EventEmitter();

  @Output()
  finObserverRemoved: EventEmitter<void> = new EventEmitter();

  private changes: MutationObserver;

  constructor(
    private windowRef: WindowRef,
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platform: object,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platform) && this.detectMutationSupport()) {
      this.observeMutations();
    }
  }

  detectMutationSupport(): boolean {
    return 'MutationObserver' in this.windowRef.nativeWindow;
  }

  observeMutations(): void {
    const element: Node = this.elementRef.nativeElement;
    this.ngZone.runOutsideAngular(() => {
      this.changes = new MutationObserver((mutations: MutationRecord[]) => {
        mutations.forEach((mutation: MutationRecord) => this.ngZone.run(() => this.finMutationObserver.emit(mutation)));
      });

      this.changes.observe(element, mutationObserverInitConfig[this.mutationObserverInitConfigKey]);
    });
  }

  ngOnDestroy(): void {
    if (this.changes) {
      this.finObserverRemoved.emit();
      this.changes.disconnect();
    }
  }
}
