<div class="white-bg grow pr-xsmall-t mr-xsmall-b">
  <h2 class="flex flex--space-between mr-xsmall-b center font-heading-3" *ngIf="teaserTitle$ | async as tt">
    <span class="flex--nogrow flex--self-center bold-type nordic-blue-900-text">{{ tt }}</span>
  </h2>

  <div>
    <div
      class="teaserText font-body-1 mr-large-b"
      [innerHTML]="teaserText$ | async | finEmpty: false | finSafe: 'html'"
    ></div>

    <a
      class="link-hover inline-block ms-xsmall-x link-btn"
      [finLink]="ctaButton$ | finMap: 'url' | async"
      [external]="ctaButton$ | finMap: 'external' | async"
      [attr.target]="(ctaButton$ | finMap: 'external' | async) ? '_blank' : ''"
      *ngIf="!!(ctaButton$ | async)"
    >
      <img
        class="inline-block valign-middle"
        *ngIf="iconUrl$ | async as u"
        alt=""
        [attr.src]="u | finSafe: 'resource'"
        [style.height.em]="2"
        [style.width]="'auto'"
      />

      <span class="bold-type font-body-1">{{ ctaButton$ | finMap: 'title' | async }}</span>
    </a>
  </div>
</div>
