<div class="relative">
  <fin-content-image
    class="block image-height"
    [imageData$]="imageData$"
    [aspectRatio$]="imageAspectRatio$"
  ></fin-content-image>
  <div class="text-block absolute" *ngIf="teaserTitle$ | async">
    <h2 class="inline-block font-heading-1 nordic-blue-900-text white-bg pr-xxsmall text-block-width">
      {{ teaserTitle$ | async }}
    </h2>
  </div>
</div>
<div class="pr-xsmall-x white-bg nordic-blue-900-text" *ngIf="teaserText$ | async as tt">
  <span class="ingress" [innerHTML]="tt | finSafe: 'html'"></span>
</div>
