<div *ngIf="locationCode" (finScrolledPast)="toggleVisible(true)">
  <button class="layout__footer footer-offer" [class.layout__footer--open]="visible" (click)="navigateTo($event)">
    <div class="flex flex--center ps-xxsmall-y ps-small-x">
      <div class="flex--nogrow">
        <div class="pr-xxsmall-r">
          <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.AIRCRAFT_HORIZONTAL"></fcom-icon>
        </div>
      </div>
      <div class="flex-1 footer-offer__min-width">
        <div class="flex flex--center">
          <div class="flex-1 align-left footer-offer__min-width footer-offer__location">
            <div class="">
              <div class="truncate overflow-hidden font-body-1">{{ offer.location }}</div>
            </div>
          </div>
          <div class="flex-1 flex align-right align--right flex--nogrow">
            <div class="align-left" *ngIf="offer | finMap: 'locationCode' | finCheapestPricePipe | async as p">
              <div class="font-body-2">{{ 'pricesStartingFrom' | finLocalization }}</div>
              <div class="bold-type font-heading-3">{{ p[TripType.RETURN] | finPrice: { roundPrice: true } }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </button>
</div>
