import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

function combineTemplateStatic<R>(template: any): Observable<R> {
  const observableKeys = Object.keys(template);
  const observables = observableKeys
    .map((key) => template[key])
    .map((value) => (value instanceof Observable ? value : of(value)));

  return combineLatest(observables).pipe(
    map((results) =>
      observableKeys.reduce((resultObject, current, index) => {
        resultObject[current] = results[index];
        return resultObject;
      }, {} as R)
    )
  );
}

/**
 * @deprecated
 *
 * Should be removed and using combineLatest
 */
export const combineTemplate = combineTemplateStatic;
