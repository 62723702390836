import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';

import { SchemaService } from '@fcom/common/services';

import { FeedbackService } from '../../services/feedback/feedback.service';
import { QualtricsSurveys } from '../../qualtrics/qualtrics.utils';

@Component({
  selector: 'fin-front-page',
  styleUrls: ['./front-page.component.scss'],
  templateUrl: './front-page.component.html',
})
export class FrontPageComponent implements OnInit, OnDestroy {
  @HostBinding('class.col-f') defaultClass = true;

  constructor(
    private feedbackService: FeedbackService,
    private schemaService: SchemaService
  ) {}

  ngOnInit(): void {
    this.schemaService.addAirlineSchema();
    this.schemaService.addWebSiteSchema();
    this.feedbackService.setSurvey(QualtricsSurveys.FEEDBACK);
    this.feedbackService.setFeedbackWidgetVisibility(true);
  }

  ngOnDestroy(): void {
    this.feedbackService.setFeedbackWidgetVisibility(false);
  }
}
