import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { v4 as uuid } from 'uuid';

import { ModalButtons } from './interfaces';
import { ButtonTheme } from '../buttons/button';
import { coerceBooleanProperty } from '../../utils/utils';

/**
 * A modal component that displays its content in a scrollable div.
 *
 * @example
 * <b>Example with buttons from parameters</b>
 * The button is rendered inside the scrollable content
 *
 * <code>
 *   <pre>
 *    <fcom-modal
 *      [(open)]="modal.open"
 *      [title]="modal.title | finLocalization"
 *      [buttons]="ModalButtons.DONE">
 *      <div class="block pr-medium-x pr-medium-y">
 *        Some amazing content
 *      </div>
 *      <div messageBox>Place a notification above the buttons</div>
 *      <div buttons>Place custom buttons in the same place as the standard buttons</div>
 *    </fcom-modal>
 *   </pre>
 * </code>
 */
@Component({
  selector: 'fcom-modal',
  styleUrls: ['modal.component.scss'],
  templateUrl: 'modal.component.html',
  animations: [
    trigger('openClose', [
      transition(':enter', [style({ opacity: 0 }), animate('0.3s ease', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: '1' }), animate('0.3s ease', style({ opacity: 0 }))]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnDestroy {
  @ViewChild('closeButton') closeButton: ElementRef;
  @ViewChild('content') content: ElementRef;
  @ViewChild('modalOverlay') modalOverlay: ElementRef;
  @ViewChild('modalHeading') modalHeading: ElementRef;

  public readonly ModalButtons = ModalButtons;
  public readonly modalId = uuid();
  public readonly ButtonTheme = ButtonTheme;
  public readonly SvgLibraryIcon = SvgLibraryIcon;

  /**
   * Private boolean property storing the open state
   */
  private _openState = false;

  /**
   * Private boolean property storing the last attempted open state. Used for closing the modal after remainOpen changed to false
   */
  private _lastAttemptedState = false;

  /**
   * Called when the open state changes to closed
   */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  close: EventEmitter<void> = new EventEmitter();

  /**
   * @Deprecated - Use open instead
   */
  @Input()
  set isOpen(_open) {
    this.open = _open;
  }

  private _forceRemainOpen = false;

  /**
   * Stops the modal from closing until this value is changed to false. This disables
   * all buttons within the view and prevents close-button in header from rendering.
   *
   * This input replaces open being changed to false.
   */
  @Input()
  set forceRemainOpen(val: boolean) {
    this._forceRemainOpen = coerceBooleanProperty(val);
    // check if we need to toggle the open state
    if (!this._forceRemainOpen && !this._lastAttemptedState && this.open) {
      this.open = this._lastAttemptedState;
    }
  }
  get forceRemainOpen(): boolean {
    return this._forceRemainOpen;
  }

  /**
   * Boolean property indicating the state of the modal (i.e. open or closed).
   */
  @Input()
  set open(_open: boolean) {
    const open = coerceBooleanProperty(_open);
    this._lastAttemptedState = open;

    // prevent close routine if forceRemainOpen is true and currently closing
    if (!open && this.open && this.forceRemainOpen) {
      return;
    }

    if (this._openState === open) {
      return;
    }
    this._openState = open;

    this.openChange.emit(this._openState);

    if (this._openState) {
      // If the modal is being destroyed before it has closed, make sure to remove the class from the body.
      this.renderer.addClass(this.document.body, 'modal-open');
      this.renderer.addClass(this.findScrollContainer(this.modalOverlay), 'ios-scroll-touch');
      this.cd.detectChanges();
      this.setFocus();
    } else {
      // If the modal is being destroyed before it has closed, make sure to remove the class from the body.
      this.renderer.removeClass(this.document.body, 'modal-open');
      this.renderer.removeClass(this.findScrollContainer(this.modalOverlay), 'ios-scroll-touch');
      this.close.next();
      this.cd.detectChanges();
    }
  }

  get open(): boolean {
    return this._openState;
  }

  /**
   * Event emmiter Indicating the new state of the modal. Allows two-way data binding
   *
   * @example
   * <code>
   *   <pre>
   *     <fcom-modal [(open)]="openState"></fcom-modal>
   *   </pre>
   * </code>
   */
  @Output() openChange = new EventEmitter<boolean>();

  /**
   * The title to show in the header of the modal. If empty then the header is not rendered.
   */
  @Input()
  title: string | null;

  /**
   * The buttons to show in the footer of the modal. If empty then the footer is not rendered.
   */
  @Input()
  buttons: ModalButtons | null = ModalButtons.DONE;

  /**
   * Toggles the enabled/disabled state of the confirm button, with <code>ModalButtons.CONTINUE_CANCEL</code>
   */
  @Input()
  confirmEnabled = true;

  /**
   * Makes modal height to fit to content height
   */
  @Input()
  fitToContent = false;

  /**
   * Decides if overlay click event is propagated
   */
  @Input()
  overlayClickable = true;

  /**
   * Modal can take up the height of the screen
   */
  @Input()
  fullHeight = false;

  /**
   * Modal can take up the height of the screen in mobile
   */
  @Input()
  fullHeightMobile = true;

  /**
   * Extend max-width to 900px (45rem)
   */
  @Input()
  extraWide = false;

  /**
   * Add border-radius to modal content
   * Can be used to align styling if no header and footer is rendered
   */
  @Input()
  roundedContent = false;

  /**
   * ElementRef for an element that should receive focus when the modal opens
   */
  @Input()
  focusTarget?: ElementRef;

  /**
   * Called when modal is confirmed. Only applicable to certain buttons
   */
  @Output()
  confirm: EventEmitter<void> = new EventEmitter();

  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
    private elem: ElementRef
  ) {}

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'modal-open');
    this.renderer.removeClass(this.findScrollContainer(this.modalOverlay), 'ios-scroll-touch');

    if (this.open) {
      this.close.next();
    }
  }

  onClose(): void {
    if (!this.forceRemainOpen) {
      this.open = false;
    }
  }

  onCloseEvent(event: Event): void {
    event.stopPropagation();
    this.onClose();
  }

  onConfirm(): void {
    this.confirm.next();
    this.onClose();
  }

  /**
   * Only close when the overlay is clicked, and stop propagation to stop fcomClickOutside events
   * from firing on other elements.
   */
  overlayClick(event: MouseEvent): void {
    if (event.target === this.modalOverlay.nativeElement) {
      this.onCloseEvent(event);
    }
  }

  private setFocus(): void {
    if (this.focusTarget?.nativeElement && this.elem.nativeElement.contains(this.focusTarget.nativeElement)) {
      this.focusTarget.nativeElement.focus();
    } else if (this.title) {
      this.modalHeading.nativeElement.focus();
    } else {
      if (this.elem.nativeElement.querySelector('[data-focus-on-modal-open]')) {
        this.elem.nativeElement.querySelector('[data-focus-on-modal-open]').focus();
      } else {
        this.content.nativeElement.focus();
      }
    }
  }

  private findScrollContainer(elRef: ElementRef): HTMLElement {
    if (!elRef || !elRef.nativeElement) {
      return this.document.documentElement;
    }

    let el: HTMLElement = elRef.nativeElement;

    while (el.parentElement) {
      el = el.parentElement;
      if (el.classList.contains('scroll')) {
        return el;
      }
    }
    return this.document.documentElement;
  }
}
