import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { combineLatest, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { CmsDataService } from '@fcom/core';
import { MenuItem, MenuItemWithChildren, MenuJson } from '@fcom/common';
import { unsubscribe } from '@fcom/core/utils';
import { ProfileType } from '@fcom/core-api/login';
import { finShare } from '@fcom/rx';

import { LoyaltyRoutesPath } from '../interfaces';

const EMPTY_MENU: MenuJson = { top: [], main: [], bottom: [], template: '' };

@Injectable()
export class LoyaltyNavigationService implements OnDestroy {
  private currentRoute$: ReplaySubject<string> = new ReplaySubject(1);
  private subscriptions = new Subscription();

  constructor(
    private cmsDataService: CmsDataService,
    private languageService: LanguageService,
    private router: Router
  ) {
    this.subscriptions.add(
      this.router.events
        .pipe(
          filter((routerEvent) => routerEvent instanceof NavigationEnd),
          distinctUntilChanged(),
          map(({ url }: NavigationEnd) => url),
          startWith(this.router.url)
        )
        .subscribe(this.currentRoute$)
    );
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  getMenuItemsForProfileType(profile: ProfileType): Observable<MenuItemWithChildren[]> {
    const url = profile === ProfileType.FPLUS ? 'fragments.plusNavigation.url' : 'fragments.accountNavigation.url';
    return this.fetchMenu(url);
  }

  getActiveMenuItemTitle(menuItems$: Observable<MenuItemWithChildren[]>): Observable<string> {
    if (menuItems$) {
      return menuItems$.pipe(
        map((data) => ((data || []).find((item) => item.active) || ({} as MenuItem)).teaserTitle || '')
      );
    }
  }

  private fetchMenu(fragmentUrl: string): Observable<MenuItemWithChildren[]> {
    return combineLatest([
      this.languageService
        .translate(fragmentUrl)
        .pipe(switchMap((url) => (!url ? of(EMPTY_MENU) : this.cmsDataService.getFragmentJson(url)))),
      this.currentRoute$,
    ]).pipe(
      map(([menuJson, currentRoute]: [MenuJson, string]) => {
        menuJson.top.forEach((item) => {
          item.active = item.link === currentRoute.replace(/\?.*|(#.*)$/, '');
        });
        return menuJson.top.filter(({ link }) => !link.includes(LoyaltyRoutesPath.TIER_PROGRESS_AND_BENEFITS));
      }),
      distinctUntilChanged(),
      finShare()
    );
  }
}
