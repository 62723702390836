/* eslint-disable */
export enum FinnairApisRequirementKeyEnum {
  COUNTRY_OF_RESIDENCE = 'countryOfResidence',
  CITY_OF_RESIDENCE = 'cityOfResidence',
  PURPOSE_OF_VISIT = 'purposeOfVisit',
  PASSPORT = 'passport',
  VISA = 'visa',
  PERMANENT_RESIDENT_CARD = 'permanentResidentCard',
  DESTINATION_ADDRESS = 'destinationAddress',
  HOME_ADDRESS = 'homeAddress',
  EMERGENCY_CONTACT = 'emergencyContact'
}

