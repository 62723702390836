<ng-container *ngFor="let location of locations; let i = index">
  <div
    class="font-body-1"
    *ngIf="(tripType !== TripType.MULTICITY && i === 0) || tripType === TripType.MULTICITY"
    data-testid="trip-location-info"
  >
    <span> {{ location.originLocationCode | finLocationName }} {{ location.originLocationCode }}</span>
    <fcom-icon
      class="icon-medium ps-xxsmall-x"
      [name]="tripType === TripType.RETURN ? SvgLibraryIcon.RETURN_TRIP : SvgLibraryIcon.AIRCRAFT_HORIZONTAL"
      [informative]="true"
      role="img"
      aria-label="Flight icon"
    ></fcom-icon>
    <span>
      {{ location.destinationLocationCode | finLocationName }}
      {{ location.destinationLocationCode }}
    </span>
  </div>
</ng-container>
