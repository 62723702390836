/* copied from @angular/core/src/facade/lang */
export const looseIdentical = (a, b): boolean => {
  return a === b || (typeof a === 'number' && typeof b === 'number' && isNaN(a) && isNaN(b));
};

const keyLength = (o: Record<string, unknown>): number => {
  if (Object.keys) {
    return Object.keys(o).length;
  }
  let count = 0;
  for (const key in o) {
    // eslint-disable-next-line no-prototype-builtins
    if (o.hasOwnProperty(key)) {
      count++;
    }
  }
  return count;
};

const arraysEqual = (o1: unknown, o2: unknown): boolean => {
  if (Array.isArray(o1) && Array.isArray(o2)) {
    if (o1.length !== o2.length) {
      return false;
    }
    for (let i = 0; i < o1.length; i++) {
      if (!equals(o1[i], o2[i])) {
        return false;
      }
    }
    return true;
  }
  return false;
};

const objectsEqual = (o1: Record<string, unknown>, o2: Record<string, unknown>): boolean => {
  if (keyLength(o1) !== keyLength(o2)) {
    return false;
  }
  for (const key in o1) {
    if (!equals(o1[key], o2[key])) {
      return false;
    }
  }
  return true;
};

export const equals = (o1: unknown, o2: unknown): boolean => {
  if (looseIdentical(o1, o2)) {
    return true;
  }
  if (o1 === null || o2 === null) {
    return false;
  }
  if (typeof o1 !== 'object' || typeof o2 !== 'object') {
    return false;
  }
  /* eslint-disable dot-notation */
  if (o1['equals'] && o2['equals']) {
    return o1['equals'](o2);
  }
  /* eslint-enable dot-notation */

  if (Array.isArray(o1) || Array.isArray(o2)) {
    return arraysEqual(o1, o2);
  }
  return objectsEqual(o1 as Record<string, unknown>, o2 as Record<string, unknown>);
};
