import { ChangeDetectorRef, Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { isNull, isUndefined, ReplaceParams } from '@fcom/core/utils';

import { equals } from '../utils/equals';
import { LanguageService, TranslateOptions } from '../services/language.service';

/**
 * Asynchronous localization pipe
 * - Uses LanguageService to localize the input
 *
 * - Allows message format like substitution
 *
 * @example
 *
 * When having
 *
 * localizations = {
 *   foo: 'My name is {me}',
 *   bar: 'Hello {you}, my name is {me}'
 * }
 *
 * The pipe would translate the following
 *
 * 'foo' | finLocalization: { me: 'Matti' } -> 'My name is Matti',
 * 'bar' | finLocalization: { you: 'Matti', me: 'Teppo' } -> 'Hello Matti, my name is Teppo'
 *
 * Or the old way:
 *
 * localizations = {
 *   foo: 'My name is {0}',
 *   bar: 'Hello {1}, my name is {0}'
 * }
 *
 * The pipe would translate the following
 *
 * 'foo' | finLocalization:['Matti'] -> 'My name is Matti',
 * 'bar' | finLocalization:['Teppo', 'Matti'] -> 'Hello Matti, my name is Teppo'
 *
 * - TranslateOptions can be specified as a last optional parameter
 *
 * @example
 *
 * Error will not be thrown if translation is not found for the 'foo' key
 *
 * 'foo' | finLocalization:['Matti']:{ logErrors: false },
 * 'foo' | finLocalization:undefined:{ logErrors: false }
 */
@Pipe({
  name: 'finLocalization',
  pure: false,
})
export class LocalizationPipe implements PipeTransform, OnDestroy {
  private async: AsyncPipe;
  private cached$: Observable<any>;
  private cachedKey: string | string[];
  private cachedParams: ReplaceParams;
  private cachedOptions: TranslateOptions;

  constructor(
    private ref: ChangeDetectorRef,
    private languageService: LanguageService
  ) {
    this.async = new AsyncPipe(this.ref);
  }

  transform(key: string | string[], params: ReplaceParams = [], options?: TranslateOptions): any {
    if (isUndefined(key) || isNull(key)) {
      return undefined;
    }

    if (this.valueChanged(key, params, options)) {
      this.update(key, params, options);
    }
    return this.async.transform(this.cached$);
  }

  ngOnDestroy(): void {
    this.async.ngOnDestroy();
  }

  private valueChanged(key: string | string[], params: ReplaceParams, options?: TranslateOptions): boolean {
    return (
      !this.cached$ ||
      key !== this.cachedKey ||
      !equals(params, this.cachedParams) ||
      !equals(options, this.cachedOptions)
    );
  }

  private update(key: string | string[], params: ReplaceParams, options?: TranslateOptions): void {
    this.cached$ = this.languageService.translate(key, params, options).pipe(map((str) => (str ? str : '')));
    this.cachedKey = key;
    this.cachedParams = params;
    this.cachedOptions = options;
  }
}
