import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { IconLibrary } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { DdsLanguage } from '@fcom/core';
import { symbolToLangPath } from '@fcom/core/utils/cms-path-helpers';
import { CmsArticle, CmsContentType, CmsImageData, CmsPicture, CmsVideo } from '@fcom/core-api';
import { type IconName } from '@fcom/ui-components';

@Component({
  selector: 'fin-rich-text',
  styleUrls: ['./rich-text.component.scss'],
  templateUrl: './rich-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichTextComponent implements OnDestroy, OnInit {
  readonly DEFAULT_HEADER_LEVEL = 3;
  readonly IconLibrary = IconLibrary;

  @Input()
  title$: Observable<string>;
  @Input()
  content$: Observable<CmsArticle>;
  @Input()
  media$: Observable<CmsPicture[] | CmsVideo[]>;
  @Input()
  icons$: Observable<{ name: IconName; category?: IconLibrary }[]>;
  @Input()
  styleClasses$: Observable<string[]>;
  @Input()
  scaleImage = true;
  @Input()
  headerLevel: number;
  @Input()
  headerStyles = [];
  @ViewChild('contentWrapper', { static: true })
  contentWrapper: ElementRef;
  @ViewChild('articleWrapper', { static: true })
  articleWrapper: ElementRef;

  mappedContent$: Observable<string>;
  mappedImage$: Observable<CmsImageData>;
  mappedSegment: string;

  private ngDestroyed$ = new Subject();

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    const ddsLocale: DdsLanguage = this.languageService.ddsLocaleValue;

    this.mappedContent$ = this.content$.pipe(
      map((content) => content.detailText),
      map((contentHtml: string) => {
        const parsedContent = contentHtml
          .replace(/<table\b/g, '<div class="rich-text-table-wrapper pr-xsmall-t pr-medium-b"><table')
          .replace(/<\/table>/g, '</table></div>');

        return symbolToLangPath(parsedContent, ddsLocale);
      })
    );

    this.mappedImage$ = this.media$.pipe(
      filter((media) => media.length > 0),
      filter((media) => media[0].contentType === CmsContentType.CMPicture),
      map((media: CmsPicture[]) => media[0])
    );

    this.content$.pipe(takeUntil(this.ngDestroyed$)).subscribe((content) => {
      this.mappedSegment = content.segment;
    });
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next(true);
  }
}
