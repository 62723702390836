import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { BOOKING_STEPS } from '@fcom/common/config/booking-config';
import { LanguageService } from '@fcom/ui-translate';
import { getBookingStepLink } from '@fcom/common/utils/booking-common.utils';
import { ConfigService, SentryLogger, GlobalBookingTravelClass, LocationRouteCffService } from '@fcom/core';
import { add, isEmptyObjectOrHasEmptyValues, mapValues, TimeUnit, toISO8601, unique, valuesOf } from '@fcom/core/utils';
import { CmsDestination } from '@fcom/core-api';
import { DatePriceList, PriceList } from '@fcom/dapi/api/models';
import { InstantsearchService } from '@fcom/dapi/api/services';
import { finShare } from '@fcom/rx';

import { OndMarketingDataService } from './ond-marketing-data.service';
import { OndLocationsResponse, OndMarketingOffer, OndPairEnhancedResponse, OndPairRequestBody } from '../interfaces';
import { constructOndHeadingPath, getCurrentCountry, isCountryCode } from './utils';

export const OFFERS_CACHE_TIME_IN_MILLIS = 60 * 1000; // 1 minute

@Injectable()
export class OndMarketingOffersService {
  private flightsDataCache$: { [key: string]: { expires: number; stream$: Observable<OndMarketingOffer[]> } } = {};
  private offersDataCache$: { [key: string]: { expires: number; stream$: Observable<OndMarketingOffer[]> } } = {};
  private locationsDataCache$: { [key: string]: { expires: number; stream$: Observable<OndLocationsResponse> } } = {};

  constructor(
    private sentryLogger: SentryLogger,
    private ondMarketingDataService: OndMarketingDataService,
    private languageService: LanguageService,
    private instantsearchService: InstantsearchService,
    private configService: ConfigService,
    private locationRouteCffService: LocationRouteCffService
  ) {}

  fetchLocationPairs(requestBody: OndPairRequestBody): Observable<OndPairEnhancedResponse> {
    return this.ondMarketingDataService.fetchLocationPairs(requestBody).pipe(
      filter((pairs) => !isEmptyObjectOrHasEmptyValues(pairs)),
      switchMap((pairs) => {
        const translationsToFetch = valuesOf(pairs)
          .reduce((locationCodes, pair) => locationCodes.concat(pair.origin, pair.destination), [])
          .filter(unique);

        return this.getLocationData(translationsToFetch).pipe(
          map((locations) => {
            return mapValues(pairs, (pair) => {
              return {
                ...pair,
                originCityName: locations[pair.origin]?.cityName,
                originCountryName: locations[pair.origin]?.country,
                originCountryCode: locations[pair.origin]?.countryCode,
                destinationCityName: locations[pair.destination]?.cityName,
                destinationCountryName: locations[pair.destination]?.country,
                destinationCountryCode: locations[pair.destination]?.countryCode,
              };
            });
          })
        );
      }),
      catchError((e: unknown) => this.handleError<OndPairEnhancedResponse>(e, 'Error getting ond location pairs')),
      finShare()
    );
  }

  getDestinationLocationCode(content$: Observable<CmsDestination>): Observable<string> {
    return content$.pipe(
      filter<CmsDestination>(Boolean),
      map((content) => content.locationCode),
      filter<string>(Boolean),
      finShare()
    );
  }

  getHeading(
    originLocationCode$: Observable<string>,
    destinationLocationCode$: Observable<string>,
    ondOffers$: Observable<OndMarketingOffer[]>,
    headingType: string
  ): Observable<string> {
    return combineLatest([originLocationCode$, destinationLocationCode$, ondOffers$]).pipe(
      switchMap(([originLocationCode, destinationLocationCode, offers]) => {
        const origin = isCountryCode(originLocationCode) ? offers[0]?.originCountryName : offers[0]?.originCityName;
        const destination = isCountryCode(destinationLocationCode)
          ? offers[0]?.destinationCountryName
          : offers[0]?.destinationCityName;

        return this.languageService.translate(
          constructOndHeadingPath(headingType, {
            destination: destinationLocationCode,
            origin: originLocationCode,
          }),
          {
            origin,
            destination,
            originLocationCode: originLocationCode,
            destinationLocationCode: destinationLocationCode,
          }
        );
      }),
      distinctUntilChanged(),
      finShare()
    );
  }

  getOffersOrFlightData(
    originLocationCode$: Observable<string>,
    destinationLocationCode$: Observable<string>,
    links$: Observable<OndPairEnhancedResponse>
  ): Observable<OndMarketingOffer[]> {
    const destinationLocationCodes$ = destinationLocationCode$.pipe(
      switchMap((locationCode) => {
        if (locationCode) {
          return of([locationCode]);
        } else {
          return links$.pipe(
            map((links) => {
              return valuesOf(links).map((link) => link.destination);
            })
          );
        }
      })
    );

    return combineLatest([originLocationCode$, destinationLocationCodes$]).pipe(
      switchMap(([originLocationCode, destinationLocationCodes]) => {
        if (originLocationCode && !isCountryCode(originLocationCode)) {
          return this.getOfferData(destinationLocationCodes, originLocationCode);
        } else {
          return this.getFlightsData(
            [
              isCountryCode(originLocationCode)
                ? originLocationCode
                : getCurrentCountry(this.languageService.langValue),
            ],
            destinationLocationCodes,
            isCountryCode(originLocationCode) ? 'from' : 'to'
          );
        }
      }),
      finShare()
    );
  }

  private getFlightsData(
    departureLocationCodes: string[],
    destinationLocationCodes: string[],
    direction?: string
  ): Observable<OndMarketingOffer[]> {
    const cacheKey = departureLocationCodes.join('-') + destinationLocationCodes.join('-') + direction;
    if (!this.flightsDataCache$[cacheKey] || Date.now() >= this.flightsDataCache$[cacheKey].expires) {
      const stream$: Observable<OndMarketingOffer[]> = this.instantsearchService
        .postPricesForFlights(this.configService.cfg.instantSearchUrl, {
          body: {
            departureLocationCodes,
            destinationLocationCodes,
            locale: this.languageService.langValue,
          },
        })
        .pipe(
          map((data) =>
            valuesOf(data.prices)
              .map((destinations) => valuesOf(destinations))
              .flat()
              .map(this.flightPriceMapper)
          ),
          switchMap((offers) => this.enhanceOffersWithTranslations(offers)),
          catchError((e: unknown) =>
            this.handleError<OndMarketingOffer[]>(e, 'Error getting ond marketing flights' + ' data')
          ),
          finShare()
        );
      this.flightsDataCache$[cacheKey] = {
        stream$,
        expires: Date.now() + OFFERS_CACHE_TIME_IN_MILLIS,
      };
    }

    return this.flightsDataCache$[cacheKey].stream$;
  }

  private getOfferData(
    destinationLocationCodes: string[],
    departureLocationCode: string
  ): Observable<OndMarketingOffer[]> {
    const cacheKey = departureLocationCode + destinationLocationCodes.join('-');
    if (!this.offersDataCache$[cacheKey] || Date.now() >= this.offersDataCache$[cacheKey].expires) {
      const stream$: Observable<OndMarketingOffer[]> = this.instantsearchService
        .getPricesForOffers(this.configService.cfg.instantSearchUrl, {
          destinationLocationCodes,
          departureLocationCode,
          departureDate: toISO8601(add(new Date(), 1, TimeUnit.day)),
          numberOfDays: 60,
          lengthOfStay: '3-10',
          travelClasses: [GlobalBookingTravelClass.ECONOMY.toLowerCase()],
        })
        .pipe(
          map((data) =>
            valuesOf(data.prices)
              .map((destinations) => valuesOf(destinations))
              .flat(2)
              .map(this.offersPriceMapper)
          ),
          switchMap((offers) => this.enhanceOffersWithTranslations(offers)),
          catchError((e: unknown) =>
            this.handleError<OndMarketingOffer[]>(e, 'Error getting ond marketing offers data')
          ),
          finShare()
        );
      this.offersDataCache$[cacheKey] = {
        stream$,
        expires: Date.now() + OFFERS_CACHE_TIME_IN_MILLIS,
      };
    }

    return this.offersDataCache$[cacheKey].stream$;
  }

  private enhanceOffersWithTranslations(offers: OndMarketingOffer[]): Observable<OndMarketingOffer[]> {
    const translationsToFetch = offers
      .reduce(
        (locationCodes, offer) => locationCodes.concat(offer.originLocationCode, offer.destinationLocationCode),
        []
      )
      .filter(unique);
    return this.getLocationData(translationsToFetch).pipe(
      map((locations) => {
        return offers.map((offer) => {
          return {
            ...offer,
            originCityName: locations[offer.originLocationCode]?.cityName,
            originCountryName: locations[offer.originLocationCode]?.country,
            originCountryCode: locations[offer.originLocationCode]?.countryCode,
            destinationCityName: locations[offer.destinationLocationCode]?.cityName,
            destinationCountryName: locations[offer.destinationLocationCode]?.country,
            destinationCountryCode: locations[offer.destinationLocationCode]?.countryCode,
          };
        });
      })
    );
  }

  getAirportName(cityName: string, locale: string): Observable<string> {
    if (!cityName || !locale || cityName.length < 3) {
      return of(undefined);
    }
    return this.locationRouteCffService.bestGuessFor(cityName, locale).pipe(
      map((data) => data?.title),
      finShare()
    );
  }

  getLocationData(destinationCodeArray: string[]): Observable<OndLocationsResponse | {}> {
    const cacheKey = destinationCodeArray.join('-');
    if (!this.locationsDataCache$[cacheKey] || Date.now() >= this.locationsDataCache$[cacheKey].expires) {
      const stream$ =
        destinationCodeArray.length === 0
          ? of({}).pipe()
          : this.ondMarketingDataService.fetchLocations(destinationCodeArray).pipe(
              catchError((e: unknown) => this.handleError<OndLocationsResponse>(e, 'Error getting ond location data')),
              finShare()
            );
      this.locationsDataCache$[cacheKey] = {
        stream$,
        expires: Date.now() + OFFERS_CACHE_TIME_IN_MILLIS,
      };
    }
    return this.locationsDataCache$[cacheKey].stream$;
  }

  private flightPriceMapper = (priceData: PriceList): OndMarketingOffer => {
    const originLocationCode = priceData.from;
    const destinationLocationCode = priceData.to;
    const pricesForEconomy = priceData.travelClassPrices.find((travelClass) => travelClass.travelClass === 'Economy');
    return {
      originLocationCode,
      destinationLocationCode,
      departureDate: pricesForEconomy.fromDate,
      returnDate: pricesForEconomy.toDate,
      price: {
        amount: pricesForEconomy.price.toString(),
        currencyCode: priceData.currency,
      },
      link: getBookingStepLink(this.languageService.langValue, BOOKING_STEPS.ENTRY, {
        origin: originLocationCode,
        destination: destinationLocationCode,
        departureDate: pricesForEconomy.fromDate,
        returnDate: pricesForEconomy.toDate,
      }),
    };
  };

  private offersPriceMapper = (priceData: DatePriceList): OndMarketingOffer => {
    const originLocationCode = priceData.from;
    const destinationLocationCode = priceData.to;
    const pricesForEconomy = priceData.travelClassPrices.find((travelClass) => travelClass.travelClass === 'Economy');
    return {
      originLocationCode,
      destinationLocationCode,
      departureDate: priceData.fromDate,
      returnDate: priceData.toDate,
      price: {
        amount: pricesForEconomy.price.toString(),
        currencyCode: priceData.currency,
      },
      link: getBookingStepLink(this.languageService.langValue, BOOKING_STEPS.ENTRY, {
        origin: originLocationCode,
        destination: destinationLocationCode,
        departureDate: priceData.fromDate,
        returnDate: priceData.toDate,
      }),
    };
  };

  private handleError<T>(error: unknown, message: string): Observable<T> {
    if (
      error instanceof HttpErrorResponse &&
      error.error?.error !== 'No location pairs found.' &&
      !(message === 'Error getting ond marketing flights data' && error.error?.key === 'INVALID_LOCATION_CODE')
    ) {
      this.sentryLogger.error(message, { error });
    }
    return EMPTY;
  }
}
