import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

@Component({
  selector: 'fin-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  public url = input<string>('');
  title = input<string>();
  colour = input<string>('grey-200-bg');
}
