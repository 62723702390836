/* eslint-disable no-underscore-dangle */
import { createSelector } from '@ngrx/store';

import { safeSelect, StateSelector } from '@fcom/core/selectors/selector-utils';
import { Step } from '@fcom/common/interfaces';
import { BookingFeatureState } from '@fcom/common/interfaces/booking';

import { bookingFeatureState } from './booking-feature-state.selector';

const _bookingState = createSelector(bookingFeatureState, (_bs: BookingFeatureState) => _bs?.booking);

const _bookingStep = createSelector(_bookingState, (_state) => _state?.step);

export const bookingStep = (): StateSelector<BookingFeatureState, Step> => safeSelect(_bookingStep);
