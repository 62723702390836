import { Directive, ElementRef, OnInit, OnDestroy } from '@angular/core';

import { Subscription, fromEvent } from 'rxjs';

import { unsubscribe, KeyCodes, isKeyboardEvent, safeMap } from '@fcom/core/utils';

@Directive({
  selector: '[finPhoneNumberMask]',
})
export class PhoneNumberMaskDirective implements OnInit, OnDestroy {
  private subscription: Subscription;

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.subscription = new Subscription();

    this.addKeyPressEvent();
    this.addPasteEvent();
  }

  addKeyPressEvent(): void {
    this.subscription.add(
      fromEvent(this.element.nativeElement.querySelector('input'), 'keypress').subscribe((event: KeyboardEvent) => {
        if (isKeyboardEvent(event, KeyCodes.SPACE)) {
          event.preventDefault();
        }
      })
    );
  }

  addPasteEvent(): void {
    this.subscription.add(
      fromEvent(this.element.nativeElement.querySelector('input'), 'paste').subscribe((event: ClipboardEvent) => {
        const text =
          safeMap(
            event,
            (e: ClipboardEvent) => e.clipboardData,
            (d: DataTransfer) => d.getData('text/plain')
          ) || '';
        event.preventDefault();
        (event.target as HTMLInputElement).value = text.replace(/ /g, '');
      })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }
}
