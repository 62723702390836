import { BoundFareFamily, MultiCityOffer } from '@fcom/common/interfaces/booking';
import { isNotBlank, sortBy } from '@fcom/core/utils';
import { LocationRouteCffData, RouteCffField } from '@fcom/core-api/index';
import { GlobalBookingTravelClass } from '@fcom/core';
import { Cabin, FinnairCart, FinnairOrder } from '@fcom/dapi/api/models';
import { LocationUtil } from '@fcom/dapi/interfaces';

const travelClassToCffMap: { [key in GlobalBookingTravelClass]?: RouteCffField } = {
  [GlobalBookingTravelClass.ECONOMY]: 'cffEconomy',
  [GlobalBookingTravelClass.ECOPREMIUM]: 'cffPremiumEconomy',
  [GlobalBookingTravelClass.BUSINESS]: 'cffBusiness',
  [GlobalBookingTravelClass.MIXED]: 'cffMixed',
};

export const getBookingDestinationSummary = (booking: FinnairOrder | FinnairCart): LocationUtil[] => {
  const locations: LocationUtil[] = [];

  booking?.bounds?.forEach((bound) => {
    locations.push(
      {
        code: bound.departure.locationCode,
        name: getCityName(bound.departure.locationCode, booking.locations),
      },
      {
        code: bound.arrival.locationCode,
        name: getCityName(bound.arrival.locationCode, booking.locations),
      }
    );
  });

  return locations;
};

export const getCityName = (locationCode: string, locations: FinnairOrder['locations']): string => {
  const location = locations[locationCode];

  if (location?.cityName) {
    return location.cityName;
  }

  if (location && locations[location.cityCode]) {
    return locations[location.cityCode].cityName;
  }

  return '';
};

/**
 * Sorts the given array of FinnairBoundGroupWithLocation based on the lowest fare family price.
 *
 * @param results The array of FinnairBoundGroupWithLocation to sort.
 * @returns The sorted array of FinnairBoundGroupWithLocation.
 */
export const sortByPrice = <T extends { fareFamilies?: { price: string }[] }>(results: T[]): T[] => {
  return sortBy(
    results.filter((result) => result.fareFamilies && result.fareFamilies.length !== 0),
    (el) => {
      return el.fareFamilies
        .map((ff) => ff.price)
        .reduce<number>((min, price) => {
          const numericPrice = parseInt(price, 10);
          return !min || numericPrice < min ? numericPrice : min;
        }, null);
    }
  );
};

const travelClassAvailableForRoute = (
  travelClass: GlobalBookingTravelClass,
  routeCffData: LocationRouteCffData
): boolean => {
  const cffkey = travelClassToCffMap[travelClass];
  return isNotBlank(routeCffData?.[cffkey]);
};

export const mapCffsToTravelClasses = (routeCffData: LocationRouteCffData): GlobalBookingTravelClass[] =>
  Object.values(GlobalBookingTravelClass).filter((travelClass) =>
    travelClassAvailableForRoute(travelClass, routeCffData)
  );

export const mapLocationRouteCffDataToCabin = (obj: LocationRouteCffData): Cabin[] => {
  const keys = ['cffMixed', 'cffEconomy', 'cffPremiumEconomy', 'cffBusiness', 'cffFirst'];

  // Define a mapping object for category strings to Cabin values
  const categoryToCabin: { [key: string]: Cabin } = {
    MIXED: Cabin.MIXED,
    ECONOMY: Cabin.ECONOMY,
    PREMIUMECONOMY: Cabin.ECOPREMIUM,
    BUSINESS: Cabin.BUSINESS,
    FIRST: Cabin.FIRST,
  };

  return keys.reduce((result: Cabin[], key: string) => {
    if (Object.prototype.hasOwnProperty.call(obj, key) && key.startsWith('cff') && obj[key]) {
      const category = (key.replace('cff', '').charAt(0).toUpperCase() + key.slice(4)).toUpperCase();
      const cabin = categoryToCabin[category];
      if (cabin) {
        result.push(cabin);
      }
    }
    return result;
  }, []);
};

export const getFareFamiliesLowestPriceOrPoints = (
  fareFamilies: BoundFareFamily[],
  isAward: boolean
): number | null | undefined => {
  return fareFamilies?.reduce<number>((min, ff) => {
    const numericPrice = isAward ? parseInt(ff.points) : parseFloat(ff.price);
    return !min || numericPrice < min ? numericPrice : min;
  }, null);
};

export const findCheapestMultiCityOffer = (offers: MultiCityOffer[]): { price: string; offer: MultiCityOffer } => {
  return offers.reduce(
    (match, offer) => {
      const price = Number(offer.offerItems[0].totalPrice);

      return price < Number(match.price) ? { price: String(price), offer } : match;
    },
    { price: '999999', offer: null }
  );
};
