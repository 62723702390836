export enum PrefillProfileData {
  FIRST_NAME = 'firstname',
  LAST_NAME = 'lastname',
  FIRST_LAST_NAME = 'firstLastName',
  MEMBER_NUMBER = 'memberNumber',
  PARSED_MOBILE_PHONE = 'parsedMobilePhone',
  EMAIL = 'email',
  STREET_ADDRESS = 'streetAddress',
  CITY = 'city',
  POSTAL_CODE = 'postalCode',
  STATE_OR_PROVINCE = 'stateOrProvince',
  COUNTRY = 'country',
  LANGUAGE = 'lang',
}
