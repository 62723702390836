/* eslint-disable */

/**
 * List of ineligibility reasons for acknowledging disruption changes
 */
export enum FinnairAcknowledgeIneligibilityReason {
  AMADEUS_REASON = 'AMADEUS_REASON',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE'
}

