import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';

import { LanguageService } from '@fcom/ui-translate';
import { ElementTypes, GaContext } from '@fcom/common/interfaces';

@Component({
  selector: 'fin-country-selection',
  templateUrl: './country-selection.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountrySelectionComponent implements OnInit {
  constructor(private languageService: LanguageService) {}

  readonly ElementTypes = ElementTypes;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TRACKING_CONTEXT = GaContext.BOOKING_FLOW;

  countryCode$: Observable<string>;
  lang$: Observable<string>;

  ngOnInit(): void {
    this.countryCode$ = this.languageService.countryCode;
    this.lang$ = this.languageService.langKey;
  }
}
