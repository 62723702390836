<div [formGroup]="parentForm" [class.ms-xlarge-b]="displayMargin">
  <div class="relative ps-xxsmall-b">
    <label
      class="font-body-1 regular-type flex ps-xxsmall-b"
      *ngIf="!hideLabel"
      [for]="idOrControlName"
      [ngClass]="{
        'nordic-blue-400-text': !control.invalid || !control.touched,
        'error-900-text': control.invalid && control.touched,
      }"
    >
      <div class="flex-1">
        @if (label) {
          {{ label }}
        } @else {
          {{ labelPrefix }}
          {{ translationPrefix + '.label' | finLocalization }}
          {{ labelSuffix }}
        }
        <span data-testid="text-input-required-asterisk" class="flex-1 flex--nogrow" *ngIf="showAsterix"> *</span>
      </div>
      <ng-template #contentTooltip>
        <h2 class="font-body-1 bold-type nordic-blue-900-text">{{ tooltipHeading }}</h2>
        <p class="pr-xxsmall-t nordic-blue-400-text">{{ tooltipContent }}</p>
      </ng-template>

      <fcom-icon-button
        *ngIf="useCustomTooltipAction; else tooltipIcon"
        class="icon nordic-blue-900-text ms-small-r flex-1 flex--nogrow"
        (clickBtn)="tooltipClicked.emit($event)"
        [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.HELP }"
        [theme]="IconButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE"
        [size]="IconButtonSize.SMALL"
        [aria]="{ label: 'actions.info' | finLocalization }"
      ></fcom-icon-button>

      <ng-template #tooltipIcon>
        <fcom-icon-button
          *ngIf="tooltipContent"
          class="icon nordic-blue-900-text ms-small-r flex-1 flex--nogrow"
          (clickBtn)="tooltipClicked.emit($event)"
          [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.HELP }"
          [theme]="IconButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE"
          [size]="IconButtonSize.SMALL"
          [aria]="{ label: 'actions.info' | finLocalization }"
          fcomPopover
          [options]="tooltipOptions"
          [content]="contentTooltip"
          [ariaCloseText]="'actions.close'"
        >
        </fcom-icon-button>
      </ng-template>
    </label>
    <input
      class="font-body-1 medium-type ps-small border rounded-large fill"
      *ngIf="type === 'input'"
      [class.form-field-empty]="!(control.value || placeHolder || placeHolderText)"
      [class.sensitive]="isSensitive"
      [type]="inputType"
      [formControlName]="controlName"
      [id]="idOrControlName"
      [attr.placeholder]="placeHolderText ? placeHolderText : placeHolder ? (placeHolder | finLocalization) : null"
      [attr.aria-required]="isRequired"
      [attr.aria-describedby]="ariaOptions.describedby"
      [attr.aria-details]="ariaOptions.details"
      [attr.aria-activedescendant]="ariaOptions.activedescendant"
      [attr.aria-controls]="ariaOptions.controls"
      [attr.aria-expanded]="ariaOptions.expanded"
      [name]="controlName"
      [attr.autocomplete]="disableAutoComplete ? 'off' : ''"
      (input)="inputEvent.emit($event)"
      [ngClass]="{ 'ps-xxlarge-r': icon, 'link-hover': cursorType === CursorType.POINTER }"
      #inputElement
    />
    <fcom-icon
      *ngIf="icon"
      class="icon-alignment absolute"
      [class.no-label]="hideLabel"
      [class.disabled]="ctrlField.disabled"
      [name]="icon"
      (click)="iconClicked.emit($event)"
    >
    </fcom-icon>
    <span class="divider" *ngIf="parentForm.get(controlName)?.disabled === false"></span>
    <textarea
      class="font-body-1 medium-type ps-small border rounded-large white-bg fill"
      *ngIf="type === 'textarea'"
      [class.sensitive]="isSensitive"
      [class.resizeable]="isResizeable"
      type="text"
      [formControlName]="controlName"
      [id]="idOrControlName"
      [attr.placeholder]="placeHolder ? (placeHolder | finLocalization) : null"
      [attr.aria-required]="isRequired"
      [attr.aria-describedby]="ariaOptions.describedby"
      [attr.aria-details]="ariaOptions.details"
      [attr.aria-activedescendant]="ariaOptions.activedescendant"
      [attr.aria-controls]="ariaOptions.controls"
      [attr.aria-expanded]="ariaOptions.expanded"
      [rows]="rows"
      [attr.autocomplete]="disableAutoComplete ? 'off' : ''"
      (input)="inputEvent.emit($event)"
      #textareaElement
    ></textarea>
  </div>
  <div *ngIf="control.invalid && control.touched" class="error-label error-900-text font-body-2" aria-live="polite">
    <span *ngIf="control.errors?.required">
      {{ (translationErrorPrefix || translationPrefix) + '.required' | finLocalization }}
    </span>
    <span *ngIf="control.errors?.email || control.errors?.pattern">
      {{ (translationErrorPrefix || translationPrefix) + '.pattern' | finLocalization }}
    </span>
    <span *ngIf="control.errors?.duplicate">
      {{ (translationErrorPrefix || translationPrefix) + '.duplicate' | finLocalization }}
    </span>
    <span *ngIf="control.errors?.maxlength">
      {{ (translationErrorPrefix || translationPrefix) + '.maxlength' | finLocalization: control.errors?.maxlength }}
    </span>
    <span *ngIf="control.errors?.minlength">
      {{ (translationErrorPrefix || translationPrefix) + '.minlength' | finLocalization: control.errors?.minlength }}
    </span>
    <span *ngIf="control.errors?.custom">
      {{ (translationErrorPrefix || translationPrefix) + '.' + control.errors.custom | finLocalization }}
    </span>
  </div>
  <div
    *ngIf="characterLimit && characterCount"
    class="nordic-blue-900-text font-body-1"
    [attr.data-testid]="'character-limit-for-' + controlName"
  >
    {{ characterCount }} / {{ characterLimit }}
  </div>
</div>
