import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AfterViewInitDirective } from './after-view-init.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { FocusDirective } from './focus.directive';
import { LinkDirective } from './link.directive';
import { MutationObserverDirective } from './mutation-observer.directive';
import { PhoneNumberMaskDirective } from './phone-number-mask.directive';
import { ScrolledPastDirective } from './scrolled-past.directive';
import { ShowForLoginStatusDirective } from './show-for-login-status.directive';
import { ShowForProfileTiersDirective } from './show-for-profile-tiers.directive';
import { ClientOnlyDirective } from './client-only.directive';
import { LetDirective } from './let-directive';

const directives = [
  AfterViewInitDirective,
  ClickOutsideDirective,
  FocusDirective,
  LinkDirective,
  MutationObserverDirective,
  PhoneNumberMaskDirective,
  ScrolledPastDirective,
  ShowForLoginStatusDirective,
  ShowForProfileTiersDirective,
  ClientOnlyDirective,
  LetDirective,
];

@NgModule({
  imports: [CommonModule],
  providers: [],
  declarations: [...directives],
  exports: [...directives],
})
export class DirectiveModule {}
