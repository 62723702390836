import { Pipe, PipeTransform } from '@angular/core';

import { TzDate, pad } from '@fcom/core/utils';

export const formatTime = (input: string | TzDate): string => {
  if (!input) {
    return undefined;
  }
  const date: TzDate = typeof input === 'string' ? new TzDate(input) : input;
  return `${pad(date.localHours)}:${pad(date.localMinutes)}`;
};

@Pipe({
  name: 'finTime',
  pure: true,
})
export class TimeFormatPipe implements PipeTransform {
  transform(input: string | TzDate): string {
    return formatTime(input);
  }
}
