<div class="select-container relative flex db-tablet-down" [formGroup]="formGroup" *ngIf="group$ | async as formGroup">
  <label class="flex-1 flex--basis0 mr-small-r db-tablet-down" [attr.for]="name$ | async">{{ label$ | async }}</label>
  <select
    class="simple nordic-blue-900-text has-initial-value flex-1 flex--basis0"
    [id]="name$ | async"
    [formControlName]="modelName$ | async"
    [attr.name]="name$ | async"
  >
    <ng-container *ngFor="let option of options$ | finObsToArray">
      <option *ngIf="(option | async)?.name !== undefined" [value]="(option | async)?.value">
        {{ (option | async)?.name }}
      </option>
    </ng-container>
  </select>
</div>
