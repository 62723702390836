import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CmsPicture, CmsTarget } from '@fcom/core-api';
import { AspectRatios } from '@fcom/ui-components';

import { findAspectRatio, toLinkData } from '../../services';

const DEFAULT_RATIO = '16x9';

@Component({
  selector: 'cms-text-on-image',
  templateUrl: './cms-text-on-image.component.html',
})
export class CmsTextOnImageComponent implements OnInit {
  @Input()
  content$: Observable<any>;
  @Input()
  noText: boolean;

  imageData$: Observable<CmsPicture>;
  imageAspectRatio$: Observable<keyof typeof AspectRatios>;
  cmsTarget$: Observable<CmsTarget>;

  ngOnInit(): void {
    this.imageData$ = this.content$.pipe(map((d) => d.picture));
    this.cmsTarget$ = this.content$.pipe(map((d) => toLinkData(d.target)));
    this.imageAspectRatio$ = this.content$.pipe(map((d) => findAspectRatio(d.subjectTaxonomyTags) ?? DEFAULT_RATIO));
  }
}
