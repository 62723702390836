import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CmsTarget, CmsTaxonomyGeneral } from '@fcom/core-api';

import { toLinkData } from '../../services';

@Component({
  selector: 'cms-link-block',
  template: `
    <ng-container [ngSwitch]="linkType$ | async">
      <fin-cta-big-link
        *ngSwitchCase="'cmstyle-arrow-link'"
        [target$]="cmsTarget$"
        [teaserTitle$]="content$ | finMap: 'teaserTitle'"
        [teaserText$]="content$ | finMap: 'teaserText'"
        [layout]="'xsmall'"
        class="mr-xxsmall-b"
      >
      </fin-cta-big-link>
      <fin-link-block
        class="mr-large-b block"
        [class.align-center]="isCentered$ | async"
        *ngSwitchDefault
        [target$]="cmsTarget$"
        [teaserTitle$]="content$ | finMap: 'teaserTitle'"
        [cmsTags$]="cmsTags$"
      ></fin-link-block>
    </ng-container>
  `,
})
export class CmsLinkBlockComponent implements OnInit {
  @Input()
  content$: Observable<any>;

  cmsTarget$: Observable<CmsTarget>;
  linkType$: Observable<string>;
  cmsTags$: Observable<Array<CmsTaxonomyGeneral | string>>;
  isCentered$: Observable<boolean>;

  ngOnInit(): void {
    this.cmsTarget$ = this.content$.pipe(map((d) => toLinkData(d.target)));

    this.cmsTags$ = this.content$.pipe(map((content) => content.subjectTaxonomyTags));

    this.linkType$ = this.cmsTags$.pipe(
      map((tags) =>
        tags.find((tag) => {
          return tag.startsWith('cmstyle-');
        })
      )
    );

    this.isCentered$ = this.cmsTags$.pipe(map((a) => a.includes(CmsTaxonomyGeneral.CENTERED)));
  }
}
