import { createSelector, MemoizedSelector } from '@ngrx/store';

import { AppState } from '@fcom/core';

import { BookingWidgetState } from '../../interfaces/store.interface';

export interface BookingWidgetAppState {
  bookingWidget: BookingWidgetState;
}

export type BookingWidgetAndAppState = AppState & BookingWidgetAppState;

export const bookingWidgetFeatureState: MemoizedSelector<BookingWidgetAndAppState, BookingWidgetAppState> =
  createSelector(
    (appState: BookingWidgetAppState) => appState,
    (a) => a
  );
