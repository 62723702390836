<div class="ms-medium-b">
  <h2 class="nordic-blue-900-text font-heading-3">{{ content.teaserTitle }}</h2>
  <fin-date
    *ngIf="content.externallyDisplayedDate"
    class="block ms-small-y"
    [date]="content.externallyDisplayedDate"
  ></fin-date>
  <div [innerHTML]="content.teaserText | finSafe: 'html'"></div>
  <a
    *ngIf="content.url"
    [finLink]="content.url"
    (click)="emitClick()"
    class="flex flex--middle nordic-blue-900-text bold-type no-underline pr-xxsmall-t font-body-1 underline-hover"
  >
    <span class="title">{{ 'readMore' | finLocalization }}</span>
    <span class="is-vishidden">{{ content.teaserTitle }}</span>
    <span class="cta">
      <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.ARROW_RIGHT"></fcom-icon>
    </span>
  </a>
</div>
