import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import * as Sentry from '@sentry/browser';

import { SentryOptions } from '../../interfaces';
import { jsConfig } from '../config/jsConfig';
import { CAPTURE_MESSAGE, SentryLogger } from './sentry.logger';

const SENTRY_ENABLED = !!jsConfig.sentryDsn && typeof window !== 'undefined';
const CHUNKLOADERROR = /Loading chunk [\da-z\-_]+ failed/;
const CHUNKLOADERROR_NAME = 'ChunkLoadError_ForceReload';
const ONEHOUR = 1000 * 60 * 60; // One hour in ms

export const captureException: any = (error: Error) => {
  if (CHUNKLOADERROR.test(error.message) && !hasCurrentlyForceReloaded()) {
    console.error(error);
    // Handles ChunkLoadError by forcing reload, but only once per hour to avoid reload loop
    setReloadTimestamp();
    window.location.reload();
  } else if (SENTRY_ENABLED) {
    Sentry.captureException(error);
  } else {
    console.error(error);
  }
};

export function captureMessage(message: string, options: SentryOptions): void {
  if (SENTRY_ENABLED) {
    Sentry.withScope((scope) => {
      scope.setExtras(options.extra || {});
      Sentry.captureMessage(message, options.level);
    });
  } else {
    // eslint-disable-next-line no-console
    (console[options.level] || console.log)(message, options);
  }
}

export function shouldIncludeUserAgent(userAgent: string): boolean {
  const isOldIe = /MSIE [5678]\./.test(userAgent);
  const isOldAndroid = /Android [234]\.]/.test(userAgent) && !/Chrome/.test(userAgent);
  return !(isOldIe || isOldAndroid);
}

/**
 * Matches the errors caught by Sentry from Angular's internals.
 * Splits the response in four groups:
 * <code><pre>
 [
   "Http failure response for https://api-test.finnair.com/a/ancillary/api/services?cartId=002MJD4OTOEIC204&hash=cmVzb3VyY2VJZDowMDJNSkQ0T1RPRUlDMjA0JnRpbWVzdGFtcDoxNTIzNTk5MjY0NDkwJm9mZmljZUlkOkhFTEFZMDhNTyZsb2NhbGU6ZW5fRkkmaGFzaDpJU3p3Q1VPSnRpbXg2Vi93T05rcVdkYngrTEVJZU9GbmExM2o3NkV1enpzPSZ0cmF2ZWxlcklkczpBRFVMVDF8U0tILTktRVhUasd: 403 OK"
   "Http failure response for https://api-test.finnair.com/a/ancillary/api/services"
   "?cartId=002MJD4OTOEIC204&hash=cmVzb3VyY2VJZDowMDJNSkQ0T1RPRUlDMjA0JnRpbWVzdGFtcDoxNTIzNTk5MjY0NDkwJm9mZmljZUlkOkhFTEFZMDhNTyZsb2NhbGU6ZW5fRkkmaGFzaDpJU3p3Q1VPSnRpbXg2Vi93T05rcVdkYngrTEVJZU9GbmExM2o3NkV1enpzPSZ0cmF2ZWxlcklkczpBRFVMVDF8U0tILTktRVhUasd"
   ": 403 OK"
 ]
 </pre></code>
 * In essence: [FULL_MSG, BASE_MSG, QUERYSTRING, REST]. Rest will be empty string if not matched
 * @type {RegExp}
 */
const HTTP_RESPONSE_SPLIT_REGEX = /^((?:Http failure response for https?:\/\/)(?:[^?]*))([^:]*)(.*)/;
const splitMessageForHttpFailures = (msg: string): [string, { [key: string]: string }] => {
  const groups = HTTP_RESPONSE_SPLIT_REGEX.exec(msg);
  if (!groups || groups.length !== 4) {
    return [msg, {}];
  }
  const [, baseMsg, queryString, rest] = groups;
  return [baseMsg + rest, { queryString }];
};

const sanitizeData = (value) => {
  if (typeof value === 'string') {
    return value.replace(/(hash|cartId|orderId)=(\w+(==)?)/g, '$1=********');
  } else if (Array.isArray(value)) {
    return value.map(sanitizeData);
  } else if (typeof value === 'object' && value !== null) {
    return sanitizeObject(value);
  } else {
    return value;
  }
};

const sanitizeKeys = ['hash', 'cartId', 'orderId'];

const sanitizeObject = (input: { [key: string]: any }): { [key: string]: any } => {
  return Object.keys(input).reduce((all, key) => {
    all[key] = sanitizeKeys.indexOf(key) !== -1 ? '********' : sanitizeData(input[key]);
    return all;
  }, {});
};

/**
 * Set session storage item with unix timestamp of time that force reload was performed
 */
const setReloadTimestamp = () => {
  sessionStorage.setItem(CHUNKLOADERROR_NAME, new Date().getTime().toString());
};

const getReloadTimestamp = () => {
  return sessionStorage.getItem(CHUNKLOADERROR_NAME) ? parseInt(sessionStorage.getItem(CHUNKLOADERROR_NAME), 10) : null;
};

const hasCurrentlyForceReloaded = () => {
  const nowMinusHour = new Date().getTime() - ONEHOUR;
  return getReloadTimestamp() && new Date(getReloadTimestamp()).getTime() > nowMinusHour;
};

// This code should be executed in main.ts to ensure Sentry is set up before the application bootstrap
export const initSentry = (): void => {
  if (SENTRY_ENABLED) {
    Sentry.init({
      dsn: jsConfig.sentryDsn,
      release: `${jsConfig.sentryPrefix}${jsConfig.buildEnv}-${jsConfig.versionInfo.releaseGitCommitHash}`,
      environment: jsConfig.sentryEnv,
      allowUrls: [
        // To get sentry working locally
        /webpack-internal/,
        // MATCH Content-Security-Policy here
        /\.finnair\.com/,
        /\.googletagmanager\.com/,
        /\.google-analytics\.com/,
      ],
      normalizeDepth: 4,
      ignoreErrors: [
        '[object Object]',
        'Navigator.geolocation fetch failed',
        "Failed to execute 'sendBeacon' on 'Navigator': Refused to send beacon",
        /(?=.*QuotaExceededError)(?=.*snapinsPage_).*/, // Caused by Fluido/Salesforce chat and flooding from bots
      ],
      beforeSend: (event): Sentry.ErrorEvent => {
        if (loggingIsAllowedForEvent(event)) {
          const fixedRequest = { ...event.request, url: event.request.url.split('#')[0] };
          const [message, additional] = splitMessageForHttpFailures(event.message);
          return sanitizeObject({
            ...event,
            message,
            request: fixedRequest,
            extra: {
              ...event.extra,
              ...additional,
            },
          }) as Sentry.ErrorEvent;
        }
        return null;
      },
    });
  }
};

export function loggingIsAllowedForEvent(event: Sentry.Event): boolean {
  if (!SENTRY_ENABLED) {
    return false;
  }

  if (!shouldIncludeUserAgent(window.navigator.userAgent)) {
    return false;
  }

  if (!isSentryAllowedForDomain(event.request.url)) {
    return false;
  }

  // Filter out http 0 errors
  if ((event?.extra?.error as HttpErrorResponse)?.status === 0) {
    return false;
  }

  return true;
}

export const isSentryAllowedForDomain = (url: string): boolean =>
  /^http(s)?:\/\/www(-(dev|master|preprod|staging))?\.finnair\.com/.test(url);

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    captureException(error.originalError || error);
  }
}

@NgModule({
  providers: [{ provide: CAPTURE_MESSAGE, useValue: captureMessage }, SentryLogger],
})
export class SentryModule {}
