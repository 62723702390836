import { createAction, props, union } from '@ngrx/store';

import { SortBy } from '@fcom/common-booking';
import { Cabin } from '@fcom/dapi/api/models';
import { FlightFilters, FlightTimeOption } from '@fcom/common-booking/interfaces/flight-filters.interface';
import { OfferListFetchParams } from '@fcom/common/interfaces';
import { FinnairAirBounds } from '@fcom/common/interfaces/booking/offers.interface';

export const startLoadingOutbounds = createAction('[BoundsActions] LoadStartOutbounds');
export const startLoadingInbounds = createAction('[BoundsActions] LoadStartInbounds');
export const success = createAction('[BoundsActions] LoadSuccess');
export const error = createAction('[BoundsActions] LoadError');
export const invalidInput = createAction('[BoundsActions] InvalidInput');
export const noFlightsFound = createAction('[BoundsActions] NoFlightsFound');
export const reset = createAction('[BoundsActions] Reset');
export const resetAllFilters = createAction(
  '[BoundsActions] ResetAllFilters',
  props<{ keepCabin?: boolean; defaultCabin?: Cabin }>()
);
export const setOutbounds = createAction('[BoundsActions] SetOutbounds', props<{ bounds: FinnairAirBounds }>());
export const setInbounds = createAction('[BoundsActions] SetInbounds', props<{ bounds: FinnairAirBounds }>());
export const setLastRequestParams = createAction(
  '[BoundsActions] SetLastRequestParams',
  props<{ params: OfferListFetchParams }>()
);
export const setOutboundId = createAction('[BoundsActions] SetOutboundId', props<{ outboundId: string }>());
export const setOutboundFareFamily = createAction(
  '[BoundsActions] SetOutboundFareFamily',
  props<{ outboundFareFamily: string }>()
);
export const setOutboundAirBoundId = createAction(
  '[BoundsActions] SetOutboundAirBoundId',
  props<{ outboundAirBoundId: string }>()
);
export const setInboundId = createAction('[BoundsActions] SetInboundId', props<{ inboundId: string }>());
export const setInboundFareFamily = createAction(
  '[BoundsActions] SetInboundFareFamily',
  props<{ inboundFareFamily: string }>()
);
export const setInboundAirBoundId = createAction(
  '[BoundsActions] SetInboundAirBoundId',
  props<{ inboundAirBoundId: string }>()
);
export const setSortBy = createAction('[BoundsActions] SetSortBy', props<{ sortBy: SortBy }>());

export const setDepartureTime = createAction(
  '[BoundsActions], SetDepartureTime',
  props<{ departureTime: FlightTimeOption[] }>()
);

export const resetDepartureTime = createAction('[BoundsActions], ResetDepartureTime');

export const setArrivalTime = createAction(
  '[BoundsActions], SetArrivalTime',
  props<{ arrivalTime: FlightTimeOption[] }>()
);

export const resetArrivalTime = createAction('[BoundsActions], ResetArrivalTime');

export const setRefundable = createAction('[BoundsActions], SetRefundable', props<{ refundable: boolean }>());

export const resetRefundable = createAction('[BoundsActions], ResetRefundable');

export const setStopsCount = createAction('[BoundsActions], SetStopsCount', props<{ stopsCount: number }>());

export const resetStopsCount = createAction('[BoundsActions], ResetStopsCount');

export const setCabin = createAction('[BoundsActions], SetCabin', props<{ cabin: Cabin }>());

export const resetCabin = createAction('[BoundsActions], ResetCabin', props<{ cabin?: Cabin }>());

export const setAllFilters = createAction('[BoundsActions], SetAllFilters', props<{ filters: FlightFilters }>());

export const setOutboundCache = createAction(
  '[BoundsActions], SetOutboundCache',
  props<{
    hash: string;
    createdAt: number;
    bounds: FinnairAirBounds;
  }>()
);

export const resetOutboundCache = createAction('[BoundsActions] ResetOutboundCache');

const all = union({
  startLoadingOutbounds,
  startLoadingInbounds,
  success,
  error,
  invalidInput,
  noFlightsFound,
  reset,
  resetAllFilters,
  setOutbounds,
  setInbounds,
  setLastRequestParams,
  setOutboundId,
  setOutboundFareFamily,
  setOutboundAirBoundId,
  setInboundId,
  setInboundFareFamily,
  setInboundAirBoundId,
  setSortBy,
  setDepartureTime,
  resetDepartureTime,
  setArrivalTime,
  resetArrivalTime,
  setRefundable,
  resetRefundable,
  setStopsCount,
  resetStopsCount,
  setCabin,
  resetCabin,
  setAllFilters,
  setOutboundCache,
  resetOutboundCache,
});

export type ActionsUnion = typeof all;
