/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { catchErrorAsJson } from '@fcom/rx';
import { DapiBaseService } from '../dapi-base-service';
import { DapiHttpResponse } from '../dapi-http-response';
import { DapiRequestBuilder } from '../dapi-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TimetableFlights } from '../models/timetable-flights';


/**
 * Timetable resources
 */
@Injectable({
  providedIn: 'root',
})
export class TimetableService extends DapiBaseService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  /**
   * Path part for operation getTimetableForRoute
   */
  static readonly GetTimetableForRoutePath = '/api/timetable';

  /**
   * getTimetableForRoute.
   *
   * Return a flight timetable for the specified route and departure date
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTimetableForRoute()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimetableForRoute$Response(rootUrl: string, params: {

    /**
     * IATA code for airport, city or country
     */
    originLocationCode: string;

    /**
     * IATA code for airport, city or country
     */
    destinationLocationCode: string;

    /**
     * Flight date (yyyy-MM-dd)
     */
    departureDate: string;

    /**
     * Number of days to return, starting from departure date. Range: 1-35.
     */
    numberOfDays?: number;

    /**
     * Search direct flights only
     */
    directFlights?: boolean;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<TimetableFlights>> {
    const rb = new DapiRequestBuilder(rootUrl, TimetableService.GetTimetableForRoutePath, 'get');

    if (params) {
      rb.query('originLocationCode', params.originLocationCode, {});
      rb.query('destinationLocationCode', params.destinationLocationCode, {});
      rb.query('departureDate', params.departureDate, {});
      rb.query('numberOfDays', params.numberOfDays, {});
      rb.query('directFlights', params.directFlights, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<TimetableFlights>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * getTimetableForRoute.
   *
   * Return a flight timetable for the specified route and departure date
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTimetableForRoute$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTimetableForRoute(rootUrl: string, params: {

    /**
     * IATA code for airport, city or country
     */
    originLocationCode: string;

    /**
     * IATA code for airport, city or country
     */
    destinationLocationCode: string;

    /**
     * Flight date (yyyy-MM-dd)
     */
    departureDate: string;

    /**
     * Number of days to return, starting from departure date. Range: 1-35.
     */
    numberOfDays?: number;

    /**
     * Search direct flights only
     */
    directFlights?: boolean;
  },
  context?: HttpContext

): Observable<TimetableFlights> {
    return this.getTimetableForRoute$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<TimetableFlights>) => r.body as TimetableFlights)
    );
  }

}
