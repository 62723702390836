import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CmsArticle } from '@fcom/core-api';
import { isNotBlank } from '@fcom/core/utils';

@Component({
  selector: 'cms-icon-with-text',
  template:
    '<fin-icon-with-text [title$]="title$" [content$]="content$ | finMap:\'detailText\'" [icons$]="icons$"' +
    ' [styleClasses$]="content$ | finMap: \'subjectTaxonomyTags\'"></fin-icon-with-text>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsIconWithTextComponent implements OnInit {
  @Input()
  content$: Observable<CmsArticle>;

  title$: Observable<string>;
  icons$: Observable<SvgLibraryIcon[]>;

  ngOnInit(): void {
    this.title$ = this.content$.pipe(
      map((t) => t.teaserTitle),
      filter(isNotBlank)
    );

    this.icons$ = this.content$.pipe(
      map((content) => content.localSettings?.iconName),
      map((icon) => (Array.isArray(icon) ? icon : [icon])),
      map((icons) => icons.filter((x) => !!x)),
      filter((icons) => !!icons.length)
    );
  }
}
