import { Component, Input, OnInit } from '@angular/core';

import { CustomIcon, IconLibrary } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CmsTaxonomyGeneral } from '@fcom/core-api';
import { LanguageService } from '@fcom/ui-translate';

import { ElementTypes } from '../../interfaces';
import { NavigationMenuService } from '../../services';

export const LEGAL_DISCLAIMER_EXCLUDE_COUNTRIES = ['CN', 'AU', 'IL', 'IN', 'CH'];

@Component({
  selector: 'fin-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  @Input()
  content$: Observable<any>;

  readonly isVisible$: Observable<boolean>;
  readonly CmsTaxonomyGeneral = CmsTaxonomyGeneral;
  readonly CustomIcon = CustomIcon;
  readonly IconLibrary = IconLibrary;

  copyrightBanner$: Observable<Object>;
  showDisclaimer$ = of(true);
  year: number = new Date().getFullYear();
  readonly menuItems$: Observable<any>;
  public readonly ElementTypes = ElementTypes;

  constructor(
    private languageService: LanguageService,
    public navigationMenuService: NavigationMenuService
  ) {
    this.menuItems$ = this.navigationMenuService.menuItems$.pipe(map((data) => data.bottom));
    this.isVisible$ = this.navigationMenuService.isFooterVisible$;
  }

  ngOnInit(): void {
    this.copyrightBanner$ = this.languageService.translate('copyrightBanner');
    this.showDisclaimer$ = this.languageService.countryCode.pipe(
      map((countryCode) => {
        return !LEGAL_DISCLAIMER_EXCLUDE_COUNTRIES.includes(countryCode);
      })
    );
  }
}
