import { createReducer, on } from '@ngrx/store';

import { RedirectActions, RedirectActionsUnion } from '../actions';
import { RedirectState } from '../store.interface';

export const initialState: RedirectState = {
  pnr: undefined,
};

const reducer = createReducer(
  initialState,
  on(RedirectActions.setPNR, (state, { pnr }) => {
    return {
      ...state,
      pnr,
    };
  }),
  on(RedirectActions.clearPNR, () => initialState)
);

export function redirectReducer(state: RedirectState, action: RedirectActionsUnion): RedirectState {
  return reducer(state, action);
}
