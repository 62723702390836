/* eslint-disable */
export enum StatusCode {
  OK = 'OK',
  NO_FLIGHTS_FOUND = 'NO_FLIGHTS_FOUND',
  INVALID_INPUT = 'INVALID_INPUT',
  OTHER_ERROR = 'OTHER_ERROR',
  NO_FLIGHTS_LEFT_FOR_TODAY = 'NO_FLIGHTS_LEFT_FOR_TODAY',
  ENDPOINT_NOT_SUPPORTED = 'ENDPOINT_NOT_SUPPORTED',
  CART_FALLBACK_ERROR = 'CART_FALLBACK_ERROR',
  OFFICE_ID_NOT_ALLOWED = 'OFFICE_ID_NOT_ALLOWED',
  MISSING_SERVICE_CATALOG = 'MISSING_SERVICE_CATALOG'
}

