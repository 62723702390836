import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { isNotBlank } from '@fcom/core/utils';
import { CmsTeaser } from '@fcom/core-api';
import { finShare } from '@fcom/rx';

@Component({
  selector: 'cms-unstyled-teaser',
  templateUrl: './cms-unstyled-teaser.component.html',
})
export class CmsUnstyledTeaserComponent implements OnInit {
  @Input()
  content$: Observable<CmsTeaser>;
  @Input()
  showIcon = false;
  @Input()
  showTitle = true;

  title$: Observable<string>;
  text$: Observable<string>;
  icon$: Observable<string>;

  ngOnInit(): void {
    const shared$ = this.content$.pipe(finShare());
    this.title$ = shared$.pipe(
      filter((t: CmsTeaser) => isNotBlank(t.teaserTitle)),
      map((t: CmsTeaser) => t.teaserTitle)
    );
    this.text$ = shared$.pipe(map((t: CmsTeaser) => t.teaserText));
    this.icon$ = shared$.pipe(
      filter((t: CmsTeaser) => this.showIcon && isNotBlank(t.iconUrl)),
      map((t: CmsTeaser) => t.iconUrl)
    );
  }
}
