import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CmsEditorialArticle } from '@fcom/core-api';

@Component({
  selector: 'fin-article-child',
  templateUrl: './article-child.component.html',
  styleUrls: ['./article-child.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleChildComponent {
  @Input()
  content: CmsEditorialArticle;
}
