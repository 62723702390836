import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable, of } from 'rxjs';

import { TranslatedBenefitRow } from '@fcom/dapi/api/models';

import { BENEFITS_ICON_MAP, koreanColoredBenefits } from '../../../utils/benefit.utils';

export interface BenefitRowWithIcon extends TranslatedBenefitRow {
  children: BenefitRowWithIcon[];
  icon: SvgLibraryIcon;
}

@Component({
  selector: 'fin-benefit',
  templateUrl: './benefit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BenefitComponent implements OnInit {
  public benefitWithIcon?: BenefitRowWithIcon;

  isKoreanColor = false;
  hasChildren = false;

  @Input() benefit: TranslatedBenefitRow;
  @Input() showKoreaNotification: boolean;
  @Input() enableBenefitChanges$: Observable<boolean> = of(false);

  ngOnInit(): void {
    this.benefitWithIcon = this.extendWithIcon(this.benefit);
    this.isKoreanColor = this.showKoreaNotification && koreanColoredBenefits.includes(this.benefit.key);
    this.hasChildren = !!this.benefit.children?.length;
  }

  private extendWithIcon(benefit: TranslatedBenefitRow): BenefitRowWithIcon {
    return {
      ...benefit,
      children: benefit.children?.map(this.extendWithIcon),
      icon: BENEFITS_ICON_MAP[benefit.key] ?? SvgLibraryIcon.CHECKMARK,
    };
  }
}
