import { createAction, props, union } from '@ngrx/store';

import { PaymentStatus } from '../../interfaces';

export const setPaymentStatus = createAction('[PaymentActions] SetPaymentStatus', props<{ status: PaymentStatus }>());
export const setPaymentRedirectionUrl = createAction(
  '[PaymentActions] SetPaymentRedirectionUrl',
  props<{ url: string }>()
);

const all = union({ setPaymentStatus, setPaymentRedirectionUrl });

export type ActionsUnion = typeof all;
