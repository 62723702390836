<button
  class="tag-button ps-xsmall-x"
  [class.tag-default]="theme === TagTheme.DEFAULT"
  [class.tag-active]="theme === TagTheme.ACTIVE"
  [attr.aria-label]="title"
  [attr.title]="title"
  [disabled]="disabled"
  (blur)="blur.emit($event)"
  (click)="tagClicked.emit($event)"
>
  <ng-content *ngIf="!text"></ng-content>
  <span *ngIf="text" class="ps-xxsmall-y flex flex--center">
    <span class="font-body-1 regular-type">{{ text }}</span>
    <fcom-icon *ngIf="iconName" [category]="iconCategory" [name]="iconName" class="icon-small ps-xxsmall-l"></fcom-icon>
  </span>
</button>
