import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UiTranslateModule } from '@fcom/ui-translate';
import { IconsModule } from '@fcom/ui-components';

import { DirectiveModule } from '../../directives';
import { NavigationTriggerComponent } from './navigation/navigation-trigger/navigation-trigger.component';
import { ComponentsModule } from '../components.module';
import { PipesModule } from '../../pipes';
import { HeaderBoxesComponent } from './header-boxes.component';

@NgModule({
  imports: [CommonModule, ComponentsModule, PipesModule, DirectiveModule, UiTranslateModule, IconsModule],
  declarations: [NavigationTriggerComponent, HeaderBoxesComponent],
  exports: [NavigationTriggerComponent, HeaderBoxesComponent],
})
export class NavigationTriggerModule {}
