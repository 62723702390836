import { Step } from '@fcom/common/interfaces';

import { VoluntaryChangeSteps, VoluntaryChangeErrorSteps } from './interfaces/step.interface';

export const VOLUNTARY_CHANGE_STEPS: VoluntaryChangeSteps = {
  SELECT_BOUNDS: {
    id: 0,
    path: 'bounds',
    translationKey: 'selectBounds',
  },
  SELECT_DATES: {
    id: 1,
    path: 'dates',
    translationKey: 'selectDates',
  },
  SELECT_MATRIX: {
    id: 2,
    path: 'matrix',
    translationKey: 'checkPrices',
  },
  FLIGHT_SELECTION: {
    id: 3,
    path: 'flight-selection',
    translationKey: 'selectNewFlights',
  },
  PURCHASE_REVIEW: {
    id: 4,
    path: 'purchase-review',
    translationKey: 'review',
  },
  CHECKOUT: {
    id: 5,
    path: 'checkout',
    translationKey: 'payment',
  },
  PURCHASE_SUCCESS: {
    id: 6,
    path: 'confirmation',
    translationKey: 'confirmation',
  },
};

export const VOLUNTARY_CHANGE_ERROR_STEPS: VoluntaryChangeErrorSteps = {
  PAYMENT_ERROR: {
    path: 'payment/error',
  },
  ERROR: {
    path: 'error',
  },
  ERROR_ROUTE: {
    path: 'error/:error',
  },
};

export const VOLUNTARY_CHANGE_STEPS_ARRAY: Step[] = Object.keys(VOLUNTARY_CHANGE_STEPS)
  .filter((key) => !VOLUNTARY_CHANGE_STEPS[key].disabled)
  .map((key) => VOLUNTARY_CHANGE_STEPS[key]);
