import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

import { CustomIcon, IconLibrary } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, startWith, take } from 'rxjs/operators';

import { ConfigService, CountryState, unsubscribe } from '@fcom/core';
import { CmsTeaser } from '@fcom/core-api/interfaces';

import { CmsPageComponentMetaData, FragmentService, Links, PageMetaService } from '../../services';
import { getCountryStates } from '../../utils/language.utils';
import { ElementActions, ElementTypes, GaContext } from '../../interfaces';
import { CountryAndLanguageSelection } from '../country-and-language-selector/country-and-language-selector.component';
import { GtmService } from '../../gtm';

const parseLocalizedPathFromUrl = (langCode: string, url: string): string => {
  const everythingBeforeLangCode = new RegExp(`.+?(?=/${langCode})`);
  return url ? url.replace(everythingBeforeLangCode, '') : `/${langCode}`;
};

const LANG = 'en';
const DESCRIPTION =
  'Finnair flies between Europe, Asia and North America via Helsinki. Modern fleet - more comfort, lower emissions. Search and book your flights today.';
const META_TAGS: CmsPageComponentMetaData[] = [
  {
    tagName: 'meta',
    attributes: {
      name: 'description',
      content: DESCRIPTION,
    },
  },
  {
    tagName: 'meta',
    attributes: {
      name: 'og:description',
      content: DESCRIPTION,
    },
  },
  {
    tagName: 'meta',
    attributes: {
      name: 'og:site_name',
      content: 'Finnair',
    },
  },
];

@Component({
  selector: 'fin-select-language',
  styleUrls: ['./select-language.component.scss'],
  templateUrl: './select-language.component.html',
})
export class SelectLanguageComponent implements OnInit, OnDestroy {
  readonly CustomIcon = CustomIcon;
  readonly IconLibrary = IconLibrary;

  static readonly TITLE = 'Flight bookings | select country and language | Finnair';

  countries: CountryState[] = getCountryStates();
  countries$: Observable<CountryState[]>;
  subscriptions: Subscription = new Subscription();

  enableNewLanguageSelector: boolean;
  imageUrl$: Observable<string>;

  constructor(
    private router: Router,
    private pageMetaService: PageMetaService,
    private renderer: Renderer2,
    private fragmentService: FragmentService,
    private gtmService: GtmService,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    // TODO: after AB-test, refactor this to use BehaviorSubject which gets it's default value from getCountryStates()
    // subscribe to links and prevPageUrl and set the value of the BehaviourSubject from there
    // use getValue() to get the latest value in applySelection()
    this.countries$ = combineLatest([this.pageMetaService.links, this.pageMetaService.prevPageUrl$]).pipe(
      map(([links, prevPageUrl]) =>
        this.countries.map((country) => this.mapCountryLanguageLinks(country, links, prevPageUrl))
      ),
      distinctUntilChanged(),
      startWith(this.countries)
    );

    this.pageMetaService.setTitle(SelectLanguageComponent.TITLE);
    this.pageMetaService.setDocumentLanguage(LANG, this.renderer);
    this.pageMetaService.setMetaTags(META_TAGS, this.renderer);

    this.enableNewLanguageSelector = this.configService.cfg.enableNewLanguageSelector;

    this.imageUrl$ = this.fragmentService
      .getFragment<CmsTeaser>('fragments.countryLanguageSelector.url')
      .pipe(map((teaser) => teaser.picture.responsiveImageLinksData.portrait_ratio1x1['768']));
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  applySelection(selection: CountryAndLanguageSelection): void {
    this.gtmService.trackElement(
      'language-selector-select',
      GaContext.LANDING_PAGE,
      ElementTypes.BUTTON,
      selection.languageCode,
      ElementActions.SUBMIT
    );

    const subj$ = new BehaviorSubject<CountryState[]>(this.countries);
    this.subscriptions.add(this.countries$.subscribe(subj$));
    this.subscriptions.add(
      subj$.pipe(take(1)).subscribe((countries) => {
        const url = countries
          .find((country) => country.code === selection.country)
          ?.languages.find((lang) => lang.code === selection.languageCode)?.link;
        this.router.navigateByUrl(url || `/${selection.languageCode}`);
      })
    );
  }

  private mapCountryLanguageLinks = (country: CountryState, links: Links, prevPageUrl: string): CountryState => ({
    ...country,
    languages: country.languages.map((language) => ({
      ...language,
      link: this.getLocalizedLinkForLangCode(language.code, links, prevPageUrl),
    })),
  });

  private getLocalizedLinkForLangCode = (langCode: string, links: Links = {}, prevPageUrl: string): string => {
    const localizedCmsPageUrl = parseLocalizedPathFromUrl(langCode, links[langCode]);
    const localizedPrevPageUrl = prevPageUrl ? `/${langCode}${prevPageUrl}` : undefined;
    return localizedPrevPageUrl ?? localizedCmsPageUrl;
  };
}
