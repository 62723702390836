/* eslint-disable */

/**
 * List of tour operator code and name
 */
export enum TourOperatorCode {
  KU = 'KU',
  ST = 'ST',
  AL = 'AL',
  TM = 'TM',
  MI = 'MI'
}

