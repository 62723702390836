<div *ngIf="content$ | async as content" class="accordion-container mr-xlarge-b">
  <h2 *ngIf="content.teaserTitle">{{ content.teaserTitle }}</h2>

  <fin-expand
    *ngFor="let item of content.items; let index = index; let last = last"
    class="block"
    [class.border-bottom]="last"
    [key]="'cms-accordion-' + content.contentId"
    [id]="'cms-accordion-' + content.contentId + '-' + index"
    #expand
  >
    <div class="trigger pr-small-y flex flex--center border-top">
      <div class="flex-1 medium-type">
        <cms-heading-tag
          [level]="item.headerLevel ?? DEFAULT_HEADER_LEVEL"
          extraClasses="font-heading-3 nordic-blue-900-text"
          >{{ item.teaserTitle }}</cms-heading-tag
        >
      </div>
      <fcom-icon
        class="flex-1 flex--nogrow rotate icon-medium"
        [class.rotate-active]="expand.isOpen$ | async"
        [name]="SvgLibraryIcon.CHEVRON_DOWN"
      ></fcom-icon>
    </div>
    <div class="content">
      <fin-cms-json-content [template$]="{ main: [item] } | finToObservable"></fin-cms-json-content>
    </div>
  </fin-expand>
</div>

<div *ngIf="content$ | async as content" class="rich-text-container">
  <h2 *ngIf="content.teaserTitle">{{ content.teaserTitle }}</h2>
  <div class="content" *ngFor="let item of content.items; let index = index; let last = last">
    <cms-heading-tag
      [level]="item.headerLevel ?? DEFAULT_HEADER_LEVEL"
      extraClasses="font-heading-3 nordic-blue-900-text"
      >{{ item.teaserTitle }}</cms-heading-tag
    >
    <fin-cms-json-content [template$]="{ main: [item] } | finToObservable"></fin-cms-json-content>
  </div>
</div>
