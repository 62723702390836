import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { CustomIcon, IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';

import { GtmService } from '@fcom/common/gtm';
import { LanguageService } from '@fcom/ui-translate';
import { AppState, ConfigService, CountryState, StorageService, WindowRef } from '@fcom/core';
import { loginIsOpen, loginStatus } from '@fcom/core/selectors';
import { LoginStatus, LoginStep } from '@fcom/core-api/login';
import { isPresent, stopPropagation } from '@fcom/core/utils';
import { allowedConfs } from '@fcom/core/reducers/langs';
import { finShare, snapshot } from '@fcom/rx';
import { CmsTemplate, CmsViewType } from '@fcom/core-api/interfaces';
import { ButtonMode, ButtonSize, ButtonTheme, IconPosition } from '@fcom/ui-components';
import { LoginActions } from '@fcom/core/actions';
import { OrderService } from '@fcom/dapi/api/services';

import { CmsPageService, MediaQueryService, NavigationMenuService } from '../../services';
import {
  BreadcrumbItem,
  ConsentStatus,
  ElementActions,
  ElementTypes,
  GaContext,
  MenuItem,
  MenuJson,
  MultivariateTestId,
  onOpenGtmEvent,
  TestVariant,
} from '../../interfaces';
import { ConsentService, JoinService, LoginService, LoginWidgetComponent } from '../../login';
import { isNavigationEvent } from '../../utils';
import { LanguageSelectorContext } from '../language-selector/language-selector.component';
import { MultivariateTestService } from '../../multivariate-test/services/multivariate-test.service';

interface ExtendedMenuItem extends MenuItem {
  trackingId?: string;
}

const UPCOMING_TRIPS_STORAGE_KEY = 'amountOfUpcomingTrips';

@Component({
  selector: 'fin-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  @Input()
  showBreadcrumbs = true;

  @ViewChild(LoginWidgetComponent, { static: false }) loginWidget: LoginWidgetComponent;

  readonly ButtonSize = ButtonSize;
  readonly CustomIcon = CustomIcon;
  readonly IconLibrary = IconLibrary;
  readonly IconPosition = IconPosition;
  readonly ButtonMode = ButtonMode;
  readonly ButtonTheme = ButtonTheme;
  readonly ElementTypes = ElementTypes;
  readonly GaContext = GaContext;
  readonly homeLink$: Observable<string>;
  readonly currentLanguage$: Observable<string>;
  readonly LoginStatus = LoginStatus;
  readonly enableWelcomeBackMessage: boolean = false;
  readonly isVisible$: Observable<boolean>;
  readonly LANGUAGE_SELECTOR_CONTEXT = LanguageSelectorContext.TOP_NAVI;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  isMenuOpen$: Observable<boolean>;
  countries$: Observable<CountryState>;
  loginStatus$: Observable<LoginStatus>;
  oneworldLink$: Observable<BreadcrumbItem>;

  menuItems$: Observable<MenuJson>;
  topMenuItems$: Observable<ExtendedMenuItem[]>;
  expandedCategory$: Observable<MenuItem>;
  expandedOrActiveNaviCategory$: Observable<MenuItem>;
  activeNaviCategory$: Observable<MenuItem>;
  isOpen = false;
  pageHasDisruptions$: Observable<boolean>;
  enableLoginDialog = false;
  enableJoinDialog = false;
  loginDialogOpen$: Observable<boolean>;
  cookiePolicy$: Observable<boolean>;
  mobileOrTablet$: Observable<boolean>;
  isLandingPage$: Observable<boolean>;
  welcomeBackMessageOpen$: Observable<boolean>;
  enableNewLanguageSelector: boolean;
  isStickyNavTest$ = of(false);
  amountOfUpcomingTrips$: Observable<string>;

  constructor(
    private navigationMenuService: NavigationMenuService,
    private languageService: LanguageService,
    private configService: ConfigService,
    private loginService: LoginService,
    private store$: Store<AppState>,
    private mediaQueryService: MediaQueryService,
    private gtmService: GtmService,
    private router: Router,
    private pageService: CmsPageService,
    private consentService: ConsentService,
    public joinService: JoinService,
    private multivariateTestService: MultivariateTestService,
    private orderService: OrderService,
    private storageService: StorageService,
    private readonly windowRef: WindowRef
  ) {
    this.isVisible$ = this.navigationMenuService.isHeaderVisible$;
    this.isMenuOpen$ = this.navigationMenuService.isNavigationMenuOpen$;
    this.homeLink$ = this.languageService.lang.pipe(map((lang) => `/${lang}`));
    this.currentLanguage$ = this.languageService.langKey;
    this.countries$ = this.initListOfCountries();
    this.enableWelcomeBackMessage = this.configService.cfg.enableWelcomeBackMessage;
    this.enableLoginDialog = this.configService.cfg.enableLoginDialog;
    this.enableJoinDialog = this.configService.cfg.enableJoinDialog;
    this.enableNewLanguageSelector = this.configService.cfg.enableNewLanguageSelector;
  }

  ngOnInit(): void {
    this.loginStatus$ = this.store$.pipe(loginStatus(), finShare());
    this.oneworldLink$ = this.navigationMenuService.oneworldLink$;
    this.menuItems$ = this.navigationMenuService.menuItems$;
    const matchUrlLocalRegex = /^\/\w+-\w+\//;
    this.topMenuItems$ = this.menuItems$.pipe(
      map((menuItems) =>
        menuItems.top.map((menuItem) => ({
          ...menuItem,
          trackingId: menuItem.link.match(matchUrlLocalRegex) ? menuItem.link.replace(matchUrlLocalRegex, '') : 'book',
        }))
      )
    );
    this.expandedCategory$ = this.navigationMenuService.expandedNaviCategory;
    this.expandedOrActiveNaviCategory$ = this.navigationMenuService.expandedOrActiveNaviCategory$;
    this.activeNaviCategory$ = this.navigationMenuService.activeNaviCategory;
    const pagePath$ = this.router.events.pipe(
      filter<NavigationEnd>(isNavigationEvent),
      map((navigation) => navigation.url),
      startWith(this.router.url),
      distinctUntilChanged()
    );

    const amountOfUpcomingTrips$: Observable<number> = this.loginStatus$.pipe(
      filter((status) => status === LoginStatus.LOGGED_IN),
      switchMap(() =>
        this.orderService
          .fetchOrderPreviews(this.configService.cfg.orderUrl, {
            locale: this.languageService.localeValue,
          })
          .pipe(take(1))
      ),
      map((response) => response?.orderPreviews?.length || 0),
      tap((orderPreviews) => {
        this.storageService.SESSION.set(UPCOMING_TRIPS_STORAGE_KEY, orderPreviews);
      }),
      startWith(this.storageService.SESSION.get(UPCOMING_TRIPS_STORAGE_KEY) || 0),
      finShare()
    );

    const isMyTripsTestVariant$ = amountOfUpcomingTrips$.pipe(
      switchMap((amount) => {
        if (amount > 0) {
          return this.multivariateTestService
            .getTestVariant(MultivariateTestId.MY_TRIPS)
            .pipe(map((variantWithId) => variantWithId.variant === TestVariant.B));
        }
        return of(false);
      }),
      finShare()
    );

    this.amountOfUpcomingTrips$ = isMyTripsTestVariant$
      .pipe(
        switchMap((isTestVariant) =>
          isTestVariant
            ? amountOfUpcomingTrips$.pipe(
                map((amount) => {
                  if (amount < 6) {
                    return amount.toString();
                  }
                  return '5+';
                })
              )
            : of('')
        )
      )
      .pipe(finShare());

    this.pageHasDisruptions$ = pagePath$.pipe(
      switchMap((path) => this.pageService.getPageDataIfExists(path)),
      filter<CmsTemplate>(Boolean),
      map((template) => template.main),
      map((contents) =>
        contents.find(
          ({ viewTypeName }) => isPresent(viewTypeName) && viewTypeName.endsWith(CmsViewType.FRONT_PAGE_DISRUPTIONS)
        )
      ),
      map((disruptions) => disruptions?.items.length > 0),
      startWith(false)
    );

    this.cookiePolicy$ = this.consentService.cookieConsents$.pipe(
      map((cg) => cg?.consents?.every((c) => c.consentStatus !== ConsentStatus.WITHDRAWN)),
      startWith(false),
      finShare()
    );

    this.loginDialogOpen$ = this.store$.pipe(loginIsOpen());
    this.mobileOrTablet$ = combineLatest([
      this.mediaQueryService.isBreakpoint$('mobile'),
      this.mediaQueryService.isBreakpoint$('tablet'),
    ]).pipe(
      map(([mobile, tablet]) => mobile || tablet),
      distinctUntilChanged(),
      startWith(false)
    );

    this.isLandingPage$ = pagePath$.pipe(map((routeValue) => new RegExp('^/[^/]+$').test(routeValue)));

    this.welcomeBackMessageOpen$ = combineLatest([
      this.loginDialogOpen$,
      this.joinService.joinDialogOpen$,
      this.pageHasDisruptions$,
      this.cookiePolicy$,
      of(this.enableWelcomeBackMessage),
      this.loginStatus$,
      this.isLandingPage$,
      this.mobileOrTablet$,
    ]).pipe(
      map(
        ([
          loginDialogOpen,
          joinDialogOpen,
          pageHasDisruptions,
          cookiePolicy,
          enableWelcomeBackMessage,
          loginStatus,
          isLandingPage,
          mobileOrTablet,
        ]) =>
          !loginDialogOpen &&
          !joinDialogOpen &&
          !pageHasDisruptions &&
          cookiePolicy &&
          enableWelcomeBackMessage &&
          loginStatus === LoginStatus.NOT_LOGGED_IN &&
          isLandingPage &&
          !mobileOrTablet
      )
    );
  }

  openLoginWidget(event: MouseEvent): void {
    this.loginWidget.open();
    stopPropagation(event);
  }

  closeNaviMenu(): void {
    this.navigationMenuService.closeNaviMenu();
    this.isOpen = false;
  }

  setCategoryAsExpanded(event: Event, item: MenuItem): void {
    this.navigationMenuService.setCategoryAsExpanded(event, item);
  }

  trackItems(index: number): number {
    return index;
  }

  clickOutside(): void {
    this.isOpen = false;
    this.navigationMenuService.closeNaviMenu();
  }

  handleLoginButtonClick(event: MouseEvent, loginStatus: LoginStatus): void {
    if (loginStatus === LoginStatus.LOGGED_IN) {
      this.openLoginWidget(event);
    }

    if (loginStatus === LoginStatus.NOT_LOGGED_IN) {
      this.openLoginDialog();
    }
  }

  toggleNavigation(event: Event): void {
    stopPropagation(event);

    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      this.navigationMenuService.setExpandedCategory(snapshot(this.expandedOrActiveNaviCategory$));
    } else {
      this.navigationMenuService.closeNaviMenu();
    }

    this.gtmService.trackElement(
      'desktop-main-navigation-hamburger-navi',
      GaContext.DESKTOP_MAIN_NAVIGATION,
      ElementTypes.ACCORDION,
      undefined,
      this.isOpen ? ElementActions.OPEN : ElementActions.CLOSE
    );
  }

  headerGtmClickEvent(gtmId: string, gtmContext: GaContext): void {
    this.gtmService.trackElement(gtmId, gtmContext, ElementTypes.BUTTON, undefined, ElementActions.CLICK);
  }

  joinFinnairPlus(): void {
    if (this.enableJoinDialog) {
      this.loginWidget.close();
      this.joinService.joinDialogOpen$.next(true);
      this.headerGtmClickEvent(`join-form-open-${onOpenGtmEvent}`, GaContext.FINNAIR_PLUS);
    } else {
      this.loginService.redirectToJoin();
    }
  }

  openLoginDialog(): void {
    if (this.enableLoginDialog) {
      this.loginWidget.close();
      this.joinService.joinDialogOpen$.next(false);
      this.store$.dispatch(LoginActions.setLoginPhaseStep({ step: LoginStep.CREDENTIALS }));
    } else {
      this.loginService.redirectToLogin();
    }
  }

  closeLoginDialog(): void {
    this.store$.dispatch(LoginActions.clearLoginPhase());
  }

  scrollToTop(): void {
    this.windowRef.nativeWindow.scrollTo({ top: 0, behavior: 'smooth' });
  }

  private initListOfCountries(): Observable<CountryState> {
    return this.languageService.countryCode.pipe(
      map(
        (countryCode) =>
          allowedConfs.find((country) => country.code === countryCode) || {
            languages: [],
            name: '',
            code: '',
          }
      )
    );
  }
}
