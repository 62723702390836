<article [ngClass]="styleClasses$ | async" #articleWrapper [id]="mappedSegment | decodeURI: true">
  <div *ngIf="mappedImage$ | async" class="image-container">
    <fin-content-image
      class="block image-height mr-medium-b"
      [scale]="scaleImage"
      [imageData$]="mappedImage$"
    ></fin-content-image>
  </div>
  <ng-container *ngIf="title$ | async as t">
    <div class="mr-xsmall-b" *ngIf="icons$ | async as icons; else plainTextTitle">
      <fcom-icon
        *ngFor="let icon of icons"
        [name]="icon.name"
        [category]="icon.category ?? IconLibrary.SVG_LIBRARY"
        class="icon-large ms-small-r"
      ></fcom-icon>
      <span class="bold-type">{{ t }}</span>
    </div>
    <ng-template #plainTextTitle>
      <cms-heading-tag
        [level]="headerLevel ?? (content$ | async).headerLevel ?? DEFAULT_HEADER_LEVEL"
        [extraClasses]="headerStyles.concat((content$ | async).subjectTaxonomyTags ?? []).join(' ')"
      >
        {{ t }}
      </cms-heading-tag>
    </ng-template>
  </ng-container>
  <div #contentWrapper [innerHTML]="mappedContent$ | async | finSafe: 'html'"></div>
</article>
