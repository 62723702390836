import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';

import { BreadcrumbItem } from '../../../interfaces';
import { BreadcrumbService, NavigationMenuService } from '../../../services';

@Component({
  selector: 'fin-breadcrumb',
  styleUrls: ['./breadcrumb.component.scss'],
  templateUrl: './breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent implements OnInit {
  readonly currentLanguage$: Observable<string>;
  breadcrumbItems$: Observable<BreadcrumbItem[]>;
  currentPath$: Observable<string>;

  constructor(
    private languageService: LanguageService,
    private navigationMenuService: NavigationMenuService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.currentLanguage$ = this.languageService.lang;
  }

  ngOnInit(): void {
    const serviceBreadcrumb$ = this.breadcrumbService.breadcrumb$;
    const isLandingPage$ = this.navigationMenuService.isLandingPage$;

    this.currentPath$ = this.navigationMenuService.currentPath$;
    this.breadcrumbItems$ = combineLatest([serviceBreadcrumb$, isLandingPage$]).pipe(
      map(([serviceBreadcrumb, isLandingPage]) => {
        return isLandingPage ? [] : serviceBreadcrumb;
      })
    );
  }
}
