import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { mergeDeepMany } from '@fcom/core/utils';
import { safeMap } from '@fcom/rx';

import { bookingLocalization, featureLocalizations, localization } from './localization';
import { I18nKey } from '../cms/cms-data.service';

@Injectable()
export class LocalizationProvider {
  constructor() {}

  /* istanbul ignore next */
  triggerLazyLocalizations(_key: I18nKey): void {
    /* no-op */
  }

  getLocalizations(lang$: Observable<string>): Observable<any> {
    return lang$.pipe(
      map((l: string) => (l === 'fi-fi' ? 'fi-fi' : 'en')),
      safeMap((l: string) => mergeDeepMany(localization[l], bookingLocalization[l], featureLocalizations[l]) || {})
    );
  }
}
