import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiTranslateModule } from '@fcom/ui-translate';

import { LoaderComponent } from './loader.component';

@NgModule({
  declarations: [LoaderComponent],
  imports: [CommonModule, UiTranslateModule],
  exports: [LoaderComponent],
  providers: [],
})
export class LoaderModule {}
