import { JsConfig } from '@fcom/core';

export const getRunTimeConfigOverrides = (environment: string): Partial<JsConfig> => {
  if (environment.indexOf('-preview') !== -1) {
    return {
      buildEnv: environment,
      indexHtml: 'index-preview.html',
      sentryEnv: environment,
      cmsEnv: environment.replace('-', '_'),
    };
  }

  return undefined;
};
