import { isPresent } from './utils';

/**
 * Add leading zero to a number if <10
 * @param value
 * @param minLength Defines minimum length for number, default is 2
 */
export const pad = (value: number | string, minLength = 2, placeholder = '0'): string => {
  const count = minLength - value.toString().length;
  return (count > 0 ? new Array(count + 1).join(placeholder) : '') + value;
};

/**
 * Removes whitespaces and line terminators from the beginning and end of the given string.
 * Also, if there are whitespaces in the middle of the string, reduces whitespace amount to single whitespace.
 *
 * Used mostly in forms to clear whitespaces from name and email fields.
 *
 * @param value
 * @returns The given value without whitespaces
 */

export const trimAndReplaceWhitespace = (value: string): string => {
  return isPresent(value) && isPresent(value.trim) ? value.trim().replace(/\s+/g, ' ') : value;
};

export const removeWhitespaces = (value: string): string => {
  return isPresent(value) ? value.replace(/\s+/g, '') : value;
};
