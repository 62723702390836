import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  startWith,
  switchMap,
  switchMapTo,
} from 'rxjs/operators';

import { combineTemplate, finShare } from '@fcom/rx';
import { mergeDeep } from '@fcom/core/utils';

import { SentryLogger } from '../sentry/sentry.logger';
import { mapErrorForSentry } from '../../utils';
import { CmsDataService, I18nData, I18nKey, i18nKeyToDataMap } from '../cms/cms-data.service';
import { LocalizationProvider } from './localization.provider';

@Injectable()
export class CmsLocalizationProvider extends LocalizationProvider {
  private lazyLocalizationsObj: { [K in I18nKey]?: boolean } = {};
  private lazyLocalizationsSubject = new BehaviorSubject<{ [K in I18nKey]?: boolean }>(this.lazyLocalizationsObj);
  private lazyLocalizations$ = this.lazyLocalizationsSubject.asObservable();

  constructor(
    private cmsDataService: CmsDataService,
    private sentryLogger: SentryLogger,
    private router: Router,
    private location: Location
  ) {
    super();
  }

  triggerLazyLocalizations(key: I18nKey): void {
    this.lazyLocalizationsObj = {
      ...this.lazyLocalizationsObj,
      [key]: true,
    };
    this.lazyLocalizationsSubject.next(this.lazyLocalizationsObj);
  }

  getLocalizations(lang$: Observable<string>): Observable<any> {
    return combineTemplate({
      base: super.getLocalizations(lang$), // local localization file
      cms: this.immediateLocalizations(lang$, i18nKeyToDataMap.global),
      booking: this.immediateLocalizations(lang$, i18nKeyToDataMap.booking),
      taxCodes: this.lazyLoadLocalizations(lang$, i18nKeyToDataMap.taxCodes, ['localSettings', 'taxCodes']),
      countryRegions: this.lazyLoadLocalizations(lang$, i18nKeyToDataMap.countryRegions, [
        'localSettings',
        'countryRegions',
      ]),
      mmb: this.lazyLoadLocalizations(lang$, i18nKeyToDataMap.mmb),
      reaccommodation: this.lazyLoadLocalizations(lang$, i18nKeyToDataMap.reaccommodation),
      loyalty: this.lazyLoadLocalizations(lang$, i18nKeyToDataMap.loyalty),
      serviceForms: this.lazyLoadLocalizations(lang$, i18nKeyToDataMap.serviceForms),
      corporate: this.lazyLoadLocalizations(lang$, i18nKeyToDataMap.corporate),
      refunds: this.lazyLoadLocalizations(lang$, i18nKeyToDataMap.refunds),
      travelReady: this.lazyLoadLocalizations(lang$, i18nKeyToDataMap.travelReady),
    }).pipe(
      map(
        ({
          booking,
          cms,
          base,
          taxCodes,
          countryRegions,
          mmb,
          reaccommodation,
          loyalty,
          serviceForms,
          corporate,
          refunds,
          travelReady,
        }) =>
          [
            booking,
            cms,
            taxCodes,
            countryRegions,
            mmb,
            reaccommodation,
            loyalty,
            serviceForms,
            corporate,
            refunds,
            travelReady,
          ].reduce((combo, current) => mergeDeep(combo, current), base)
      ),
      finShare()
    );
  }

  private immediateLocalizations(lang$: Observable<string>, i18nData: I18nData): Observable<any> {
    return lang$.pipe(
      switchMap((l) => this.cmsDataService.getLocalization(l, i18nData)),
      catchError(this.throwCatchError(`Error fetching ${i18nData.key} localization from CMS`))
    );
  }

  private lazyLoadLocalizations(
    lang$: Observable<string>,
    i18nData: I18nData,
    pluckProperty: string[] = ['localSettings']
  ): Observable<any> {
    return this.lazyLocalizations$.pipe(
      pluck(i18nData.key),
      filter(Boolean),
      distinctUntilChanged(),
      switchMapTo(lang$),
      switchMap((language) => this.cmsDataService.getLocalization(language, i18nData, pluckProperty)),
      catchError(this.throwCatchError(`Error fetching ${i18nData.key} localization from CMS`, true)),
      startWith({})
    );
  }

  private throwCatchError(errorMsg: string, canHandleError = false): (error: any) => Observable<any> {
    return (error) => {
      this.sentryLogger.error(errorMsg, { error: mapErrorForSentry(error) });
      if (canHandleError) {
        return of({});
      } else {
        const [, lang] = this.location.path(false).split('/');
        this.router.navigate([lang || 'en', 'sorry', 'LOCALIZATION_ERROR']);
        return throwError(() => errorMsg);
      }
    };
  }
}
