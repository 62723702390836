<picture *ngIf="fallbackSrc && thumbnailSrc">
  <source
    *ngFor="let source of srcSet; trackBy: trackByFn"
    media="(min-width: {{ source.width }}px)"
    [attr.lazyLoad]="source.src"
    [attr.defaultImage]="thumbnailSrc"
  />
  <img
    [attr.fetchpriority]="fetchPriority"
    [attr.alt]="alt || ''"
    [attr.src]="thumbnailSrc"
    [lazyLoad]="fallbackSrc"
    [class]="hasAspectRatio ? 'ratio-' + ratio : ''"
    [ngClass]="{ fill: true, 'fill-parent': fillParent, scale: scale }"
    [style.aspect-ratio]="customAspectRatio"
  />
</picture>
