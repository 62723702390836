<section *ngIf="bound && locations" data-testid="itinerary-timeline">
  <div class="white-bg rounded">
    <fin-itinerary-timeline-bound-header
      [bound$]="bound | finToObservable"
      [boundType]="boundType"
      [locations$]="locations | finToObservable"
      [departureChanges]="departureChanges"
      [arrivalChanges]="arrivalChanges"
      [title]="title"
      [boundChangeDuration]="boundChangeDuration"
      [showNoChangesNotification]="showNoChangesNotification"
    ></fin-itinerary-timeline-bound-header>

    <div class="timeline font-body-1 pr-xxsmall-x" [class.pr-xxsmall-y]="boundType && bound">
      <ng-container *ngFor="let item of bound.itinerary; let first = first; let last = last; let i = index">
        <ng-container *finLet="toType(item) as leg" [ngSwitch]="leg.type">
          <ng-container *ngSwitchCase="'FLIGHT'">
            <fin-itinerary-timeline-flight
              [flight]="leg"
              [locations]="locations"
              [firstLeg]="first"
              [lastLeg]="last"
              [flightChanges]="legChanges.get(leg.id)"
              [isBookingFlow]="isBookingFlow"
            ></fin-itinerary-timeline-flight>
            <!-- TECHNICAL STOPS -->
            <fin-itinerary-timeline-leg-technical-stops
              [leg]="leg"
              [firstLeg]="first"
              [lastLeg]="last"
              [locations]="locations"
              [oldArrivalLocationCode]="legChanges.get(leg.id)?.arrivalLocationCode"
              [oldArrivalDateTime]="legChanges.get(leg.id)?.arrivalDateTime"
            ></fin-itinerary-timeline-leg-technical-stops>
          </ng-container>
          <!-- LAYOVER -->
          <ng-container *ngSwitchCase="'LAYOVER'">
            <fin-itinerary-timeline-layover
              [bound]="bound"
              [layover]="leg"
              [previousLeg]="bound.itinerary[i - 1]"
              [nextLeg]="bound.itinerary[i + 1]"
              [oldDuration]="legChanges.get('LAYOVER' + i)?.duration"
              [notification]="legChanges.get('LAYOVER' + i)?.notification"
              [isBookingFlow]="isBookingFlow"
            ></fin-itinerary-timeline-layover>
          </ng-container>
        </ng-container>
      </ng-container>
      <div *ngIf="arrivalChanges?.notification" class="timeline__right medium-type heather-700-text pr-medium-y">
        <fcom-notification
          *ngIf="arrivalChanges.notification"
          [theme]="NotificationTheme.WARNING"
          [layout]="NotificationLayout.BLOCK"
          [iconName]="SvgLibraryIcon.INFO"
          class="block"
        >
          {{ 'MMB.notifications.disruption.' + arrivalChanges.notification + '.text' | finLocalization }}
        </fcom-notification>
      </div>
    </div>
  </div>
</section>
