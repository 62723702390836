<fin-editorial-page-head
  [title]="(activeNaviSection$ | async)?.teaserTitle"
  [titleAsHeading]="false"
></fin-editorial-page-head>

<section class="bluewings-main mr-medium-t grid-max-width padding-left-gutter padding-right-gutter center">
  <div class="grid-col-12 grid-max-width no-row-gap white-bg relative rounded-large">
    <!-- Section navigation -->
    <fin-light-section-navi class="col-1-12 md-col-1-3 sm-col-2-11"></fin-light-section-navi>

    <!-- Bluewings content -->
    <section class="col-1-12 md-col-4-12 sm-col-2-11 mr-medium-t">
      <fin-cms-json-content class="grid-col-12 no-gutters no-row-gap" [template$]="template$"></fin-cms-json-content>
    </section>
  </div>
</section>
