<a
  *ngIf="target$ | async as link"
  [finLink]="link.url"
  [external]="link.external"
  class="btn relative nordic-blue-900-bg white-text no-underline inline-block"
  [class.black-friday]="isBlackFriday$ | async"
  [openInNewTab]="link.external"
>
  <span class="font-body-1 white-text bold-type">{{ teaserTitle$ | async }}</span>
  <span *ngIf="link.external" class="is-vishidden">({{ 'ariaLabels.newTab' | finLocalization }})</span>
</a>
