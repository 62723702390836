import { ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import { filter, Observable, Subscription } from 'rxjs';
import { pairwise } from 'rxjs/operators';

import { BookingAppState } from '@fcom/common/interfaces/booking';
import { LoginStatus, Profile } from '@fcom/core-api/login';
import { loginStatus, profileOrUndefinedIfNotLoggedIn } from '@fcom/core/selectors';
import { ScrollService } from '@fcom/common/services';
import { unsubscribe } from '@fcom/core/utils';

@Component({
  selector: 'fin-login-promotion',
  templateUrl: './login-promotion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPromotionComponent implements OnInit, OnDestroy {
  @Input() showGreeting = true;

  loggedInProfile$: Observable<Profile>;
  loginStatus$: Observable<LoginStatus>;

  private subscriptions: Subscription = new Subscription();
  readonly LoginStatus = LoginStatus;

  constructor(
    private store: Store<BookingAppState>,
    private elementRef: ElementRef,
    private scrollService: ScrollService
  ) {}

  ngOnInit(): void {
    this.loggedInProfile$ = this.store.pipe(profileOrUndefinedIfNotLoggedIn());
    this.loginStatus$ = this.store.pipe(loginStatus());

    this.subscriptions.add(
      this.loginStatus$
        .pipe(
          pairwise(),
          filter(([prev, current]) => {
            // only do the scroll when the login is done on this page, do not scroll when the user logged in on a previous step. pending > logged_in is how to detect this.
            return prev === LoginStatus.PENDING && current === LoginStatus.LOGGED_IN;
          })
        )
        .subscribe(() => {
          this.scrollService.smoothScroll(this.elementRef, { offsetY: 60 });
        })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }
}
