<div *ngIf="items$ | async as items" class="link-list-with-icons mr-xsmall-b">
  <h2 class="mr-xsmall-b">{{ (content$ | async)?.teaserTitle }}</h2>
  <ul>
    <li *ngFor="let item of items; let index = index; let last = last">
      <a
        *ngIf="item.target.url"
        class="nordic-blue-900-text no-decoration font-body-1 flex"
        [finLink]="item.target.url"
        [external]="item.target.external"
        [attr.target]="item.target.external ? '_blank' : null"
        [attr.rel]="item.target.external ? 'noopener noreferrer' : null"
      >
        <span>{{ item.teaserTitle }}</span>
        <fcom-icon [name]="SvgLibraryIcon.ARROW_RIGHT" class="flex icon-medium"></fcom-icon>
      </a>
    </li>
  </ul>
</div>
