import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { NEVER, Observable, Subscription } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { AppState, SentryLogger, mapErrorForSentry } from '@fcom/core';
import { GlobalActions } from '@fcom/core/actions';
import { ConfigService } from '@fcom/core/services/config/config.service';
import { FinnairOrder } from '@fcom/dapi/api/models';
import { finShare, retryWithBackoff } from '@fcom/rx';
import { unsubscribe } from '@fcom/core/utils';
import { DapiErrorStatus, DapiHttpErrorResponse, IdAndHash } from '@fcom/dapi';
import { OrderService } from '@fcom/dapi/api/services';

import { CommonBookingAncillaryService } from '../modules/ancillaries/services';
import { OrderActions, orderFetchInformation } from '../store';
import { QueueService } from './queue.service';

@Injectable()
export class BookingOrderService implements OnDestroy {
  static NUMBER_OF_RETRIES = 2;

  private subscription: Subscription;

  constructor(
    private store$: Store<AppState>,
    private sentryLogger: SentryLogger,
    private queueService: QueueService,
    private orderService: OrderService,
    private config: ConfigService,
    private languageService: LanguageService,
    private router: Router,
    private commonBookingAncillaryService: CommonBookingAncillaryService
  ) {}

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  /**
   * Triggers loading of order from the given order url.
   * @param information OrderFetchInformation
   * @param cancelStream$
   * @param includeServiceCatalog boolean
   * @param includePredictions boolean
   */
  triggerLoadOrder(
    information: IdAndHash,
    cancelStream$: Observable<any> = NEVER,
    includeServiceCatalog = false,
    includePredictions = false
  ): Observable<FinnairOrder> {
    unsubscribe(this.subscription);
    const orderData$ = this.loadOrder(information, includeServiceCatalog, includePredictions).pipe(
      takeUntil(cancelStream$)
    );
    this.subscription = orderData$.subscribe({
      next: (order) => {
        this.store$.dispatch(OrderActions.setOrderData({ order }));
        if (order.payments.length > 0) {
          this.store$.dispatch(GlobalActions.paymentVerified());
        } else {
          this.store$.dispatch(GlobalActions.paymentNotPaid());
        }

        this.store$.dispatch(
          GlobalActions.confirmation({
            route: this.router.routerState?.snapshot?.root?.firstChild?.firstChild?.routeConfig,
          })
        );
        //Set service catalog needs to happen after GlobalActions.confirmation's clean up
        if (includeServiceCatalog) {
          this.commonBookingAncillaryService.setServiceCatalog(order);
        }

        unsubscribe(this.subscription);
      },
      error: (error: unknown) => {
        const errorResponse = error as DapiHttpErrorResponse;

        if (errorResponse.status !== 404) {
          this.sentryLogger.error('Error fetching order', {
            error: mapErrorForSentry(errorResponse.error),
          });
        }

        const status = errorResponse?.error?.status ?? DapiErrorStatus.GENERIC_HTTP_ERROR;
        this.store$.dispatch(OrderActions.loadError({ status }));
        unsubscribe(this.subscription);
      },
      complete: () => {
        unsubscribe(this.subscription);
      },
    });
    return orderData$;
  }

  queueOrderLoad(triggerFetchServiceCatalog = false, triggerFetchPredictions = false): void {
    const cancelStream$ = this.queueService.toCancelEvent(this.queueService.cancelPayment);
    this.queueService.sendToQueue(
      'order',
      this.fromOrderFetchInformation((information) =>
        this.triggerLoadOrder(information, cancelStream$, triggerFetchServiceCatalog, triggerFetchPredictions)
      ),
      cancelStream$
    );
  }

  private loadOrder(
    information: IdAndHash,
    includeServiceCatalog: boolean,
    includePredictions: boolean
  ): Observable<FinnairOrder> {
    this.store$.dispatch(OrderActions.loadStart());
    return this.orderService
      .fetchOrder(this.config.cfg.orderUrl, {
        orderId: information.id,
        hash: information.hash,
        locale: this.languageService.localeValue,
        includeServiceCatalog,
        includePredictions,
      })
      .pipe(retryWithBackoff(BookingOrderService.NUMBER_OF_RETRIES), finShare());
  }

  private fromOrderFetchInformation = (fn: (information: IdAndHash) => Observable<FinnairOrder>) =>
    this.store$.pipe(orderFetchInformation(), filter(Boolean), take(1), switchMap(fn));
}
