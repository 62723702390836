<div
  [formGroup]="parentForm"
  class="autocomplete-container ms-xlarge-b"
  [class.theme-web2]="theme === AutocompleteTheme.WEB_2"
>
  <div class="relative ps-xxsmall-b" matAutocompleteOrigin #origin="matAutocompleteOrigin">
    <label
      class="font-body-1 flex ps-xxsmall-b"
      [for]="controlName"
      [ngClass]="{
        'grey-600-text': !(ctrlField.invalid && ctrlField.touched) && theme === AutocompleteTheme.DEFAULT,
        'nordic-blue-400-text': !(ctrlField.invalid && ctrlField.touched) && theme === AutocompleteTheme.WEB_2,
        'error-900-text': ctrlField.invalid && ctrlField.touched,
      }"
    >
      <div class="flex-1">
        {{ translationPrefix + '.label' | finLocalization }}
        <span *ngIf="isRequired" class="flex-1 flex--nogrow"> *</span>
      </div>
    </label>
    <div class="absolute loader" *ngIf="loading$ | async">
      <fin-loader [type]="LoaderType.INLINE_TEXT"></fin-loader>
    </div>
    <input
      class="font-body-1"
      autocomplete="off"
      [class.form-field-empty]="!(ctrlField.value || placeHolder)"
      [formControlName]="controlName"
      [id]="controlName"
      [matAutocomplete]="auto"
      [attr.placeholder]="placeHolder ? (placeHolder | finLocalization) : null"
      [matAutocompleteConnectedTo]="origin"
      [name]="controlName"
      [ngClass]="{
        'medium-type': theme === AutocompleteTheme.WEB_2,
        'ps-small': theme === AutocompleteTheme.WEB_2,
        'rounded-large': theme === AutocompleteTheme.WEB_2,
      }"
    />
    <span class="divider" *ngIf="theme !== AutocompleteTheme.WEB_2"></span>
  </div>
  <div *ngIf="ctrlField.invalid && ctrlField.touched" class="error-label error-900-text font-body-2">
    <span *ngIf="ctrlField.errors?.required">
      {{ translationPrefix + '.required' | finLocalization }}
    </span>
    <span *ngIf="ctrlField.errors?.forbiddenFormat || ctrlField.errors?.forbiddenLocation">
      {{ translationPrefix + '.pattern' | finLocalization }}
    </span>
  </div>
</div>
<mat-autocomplete
  #auto="matAutocomplete"
  [displayWith]="displayLocation"
  class="locations-autocomplete shadow font-body-1"
>
  <mat-option *ngFor="let option of options$ | async" [value]="option"
    >({{ option.locationCode }}) {{ option.title }}</mat-option
  >
</mat-autocomplete>
<div aria-live="polite" class="result-helper is-vishidden">
  <span tabindex="-1" *ngIf="(loading$ | async) === false && (optionsAmount$ | async) as results">
    {{ 'SERVICE_FORMS.NTServiceForms.helpers.autocomplete.results' | finLocalization: { amount: results } }}
  </span>
  <span tabindex="-1" *ngIf="(loading$ | async) === false && (optionsAmount$ | async) === undefined">
    {{ 'SERVICE_FORMS.NTServiceForms.helpers.autocomplete.noResults' | finLocalization }}
  </span>
  <div *ngIf="ctrlField.invalid && ctrlField.touched">
    <span *ngIf="ctrlField.errors?.required" tabindex="-1">
      {{ translationPrefix + '.required' | finLocalization }}
    </span>
    <span *ngIf="ctrlField.errors?.forbiddenFormat || ctrlField.errors?.forbiddenLocation" tabindex="-1">
      {{ translationPrefix + '.pattern' | finLocalization }}
    </span>
  </div>
</div>
