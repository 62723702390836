import { ChangeDetectorRef, Input, Component } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

import { v4 as uuid } from 'uuid';

import { coerceBooleanProperty } from '../../../utils/utils';

@Component({ template: '' })
export class AbstractNgModelComponent<T = any> implements ControlValueAccessor {
  @Input()
  cid: string = uuid();

  @Input()
  get disabled(): boolean {
    return this.pDisabled;
  }
  set disabled(dis) {
    this.pDisabled = coerceBooleanProperty(dis);
    this.cdRef.markForCheck();
  }
  protected pDisabled = false;

  @Input()
  set value(value: T) {
    this.pValue = value;
    this.notifyValueChange();
  }

  get value(): T {
    return this.pValue;
  }

  onChange: (value: T) => unknown;
  onTouched: () => unknown;

  protected pValue: T;

  constructor(protected cdRef: ChangeDetectorRef) {}

  notifyValueChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  writeValue(value: T): void {
    this.pValue = value;
    this.cdRef.markForCheck();
  }

  registerOnChange(fn: (value: T) => unknown): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => unknown): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
