import { createReducer, on } from '@ngrx/store';

import { TripsState, FetchTripsStatus } from '../../interfaces/store.interface';
import { TripsActions } from '../actions';

export const initialState: TripsState = Object.seal({ trips: [], status: FetchTripsStatus.INITIAL });

const tripsReducer = createReducer(
  initialState,
  on(TripsActions.setTrips, (state, { trips, status }) => ({
    ...state,
    trips: [...trips],
    status: status,
  }))
);

export function reducer(state: TripsState, action: TripsActions.TripsActionsUnion) {
  return tripsReducer(state, action);
}
