import { createAction, props, union } from '@ngrx/store';

import { Cabin, OfferList } from '@fcom/dapi/api/models';
import { SortBy } from '@fcom/common-booking';
import { FlightTimeOption } from '@fcom/common-booking/interfaces/flight-filters.interface';
import { OfferListFetchParams } from '@fcom/common/interfaces';

export const startLoading = createAction('[OffersActions] LoadStart');
export const success = createAction('[OffersActions] LoadSuccess');
export const error = createAction('[OffersActions] LoadError');
export const invalidInput = createAction('[OffersActions] InvalidInput');
export const noFlightsFound = createAction('[OffersActions] NoFlightsFound');
export const reset = createAction('[OffersActions] Reset');
export const setDepartureTime = createAction(
  '[OffersActions], SetDepartureTime',
  props<{ departureTime: FlightTimeOption[] }>()
);

export const resetDepartureTime = createAction('[OffersActions], ResetDepartureTime');
export const setArrivalTime = createAction(
  '[OffersActions], SetArrivalTime',
  props<{ arrivalTime: FlightTimeOption[] }>()
);

export const resetArrivalTime = createAction('[OffersActions], ResetArrivalTime');

export const setStopsCount = createAction('[OffersActions], SetStopsCount', props<{ stopsCount: number }>());

export const resetStopsCount = createAction('[OffersActions], ResetStopsCount');

export const setCabin = createAction('[OffersActions], SetCabin', props<{ cabin: Cabin }>());

export const resetCabin = createAction('[OffersActions], ResetCabin');
export const resetAllFilters = createAction('[OffersActions] ResetAllFilters', props<{ keepCabin?: boolean }>());
export const setOffers = createAction('[OffersActions] SetOffers', props<{ offers: OfferList }>());
export const offerSetLastRequestParams = createAction(
  '[OfferActions] SetLastRequestParams',
  props<{ params: OfferListFetchParams }>()
);
export const setOutboundId = createAction('[OfferActions] SetOutboundId', props<{ outboundId: string }>());
export const setOutboundFareFamily = createAction(
  '[OfferActions] SetOutboundFareFamily',
  props<{ outboundFareFamily: string }>()
);
export const setInboundId = createAction('[OfferActions] SetInboundId', props<{ inboundId: string }>());
export const setInboundFareFamily = createAction(
  '[OfferActions] SetInboundFareFamily',
  props<{ inboundFareFamily: string }>()
);
export const setSortBy = createAction('[OfferActions] SetSortBy', props<{ sortBy: SortBy }>());

const all = union({
  startLoading,
  success,
  error,
  invalidInput,
  noFlightsFound,
  reset,
  setOffers,
  offerSetLastRequestParams,
  setOutboundId,
  setOutboundFareFamily,
  setInboundId,
  setInboundFareFamily,
  setSortBy,
  resetAllFilters,
  setDepartureTime,
  resetDepartureTime,
  setArrivalTime,
  resetArrivalTime,
  setStopsCount,
  resetStopsCount,
  setCabin,
  resetCabin,
});

export type ActionsUnion = typeof all;
