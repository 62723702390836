import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ConfigService, SentryLogger } from '@fcom/core/services';

import { NewsletterSignupPayload, NewsletterSignupResponse } from '../../interfaces/newsletter-signup.interface';

@Injectable()
export class NewsletterSignupService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private sentryLogger: SentryLogger
  ) {}

  postSubscribeRequest(payload: NewsletterSignupPayload): Observable<NewsletterSignupResponse> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http
      .post<NewsletterSignupResponse>(
        `${this.configService.cfg.customerCommunicationsApiUrl}/newsletter`,
        payload,
        httpOptions
      )
      .pipe(
        catchError((err: unknown) => {
          this.sentryLogger.warn('Failed to post newsletter subscription call', { error: err });
          return throwError(() => err);
        })
      );
  }
}
