import { Observable, ReplaySubject, share } from 'rxjs';

interface FinShareConfig {
  refCount: boolean;
  bufferSize: number;
}

export const finShare =
  <T>(config: FinShareConfig = { bufferSize: 1, refCount: true }) =>
  (o$: Observable<T>): Observable<T> =>
    o$.pipe(
      share({
        connector: () => new ReplaySubject(config.bufferSize),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: config.refCount,
      })
    );
