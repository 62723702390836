export enum ButtonPosition {
  GRID = 'grid',
  GRID_FULL = 'grid_full',
  GRID_TWO_THIRDS_RIGHT = 'grid_two_thirds_right',
  FULL = 'full',
}

export enum ButtonLayout {
  COLUMN = 'COLUMN',
  ROW = 'ROW',
}
