import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LoaderColor, LoaderTheme } from './enums';

/**
 * This component can be used to display a loading icon with text in a variety of use cases.
 *
 * Make sure to set the theme to be the one required depending on if you need a full page or inline
 * layout.
 */
@Component({
  selector: 'fcom-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  readonly LoaderTheme = LoaderTheme;
  /**
   * The theme to use for the loader.
   */
  @Input()
  loaderType = LoaderTheme.EMBEDDED;

  /**
   * The heading tag to use above the loading icon, note that will not render with
   * LoaderTheme.INLINE or LoaderTheme.INLINE_TEXT.
   */
  @Input()
  title: string;

  /**
   * The paragraph tag to use below the loading icon, note that will not render with
   * LoaderTheme.INLINE or LoaderTheme.INLINE_TEXT.
   */
  @Input()
  paragraph: string;

  /**
   * The color to use with the loader. Defaults to nordic-blue-900
   */
  @Input()
  color: LoaderColor = LoaderColor.BLUE;
}
