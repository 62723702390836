/**
 * This is the width that is used for devices not supporting srcSet attribute. Found on every crop ratio.
 */
export const FALLBACK_WIDTH = 768;

/**
 * This is the width we use for initial default image as thumbnail
 */
export const THUMBNAIL_WIDTH = 40;

/**
 * ImageWidths to request and use for responsive sizing.
 * Sorted high to low
 */
export const IMAGE_WIDTHS = [1920, 1200, 1024, 768, 608, 600, 320, 300, 144];
