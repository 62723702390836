import { createAction, props, union } from '@ngrx/store';

import { Trip } from '../../interfaces/pax-trip.interface';
import { FetchTripsStatus } from '../../interfaces/store.interface';

export const setTrips = createAction('[TripActions] SetTrips', props<{ trips: Trip[]; status: FetchTripsStatus }>());
export const fetchTrips = createAction('[TripActions] FetchTrips');

const all = union({ setTrips, fetchTrips });
export type TripsActionsUnion = typeof all;
