<div class="relative" [class.has-background]="theme === CarouselTheme.HAS_BACKGROUND">
  <div class="button-prev z1 flex flex--start grow" *ngIf="prevButtonVisible" [class.grey]="hasGreyBackground">
    <button (click)="scrollPrev()" [attr.aria-hidden]="!prevButtonAriaLabel" [attr.aria-label]="prevButtonAriaLabel">
      <fcom-icon
        [class.white-bg]="whiteIcon"
        class="flex-1 flex--nogrow nordic-blue-900-text"
        [name]="leftIcon"
      ></fcom-icon>
    </button>
  </div>
  <div class="button-next z1 flex flex--end grow" *ngIf="nextButtonVisible" [class.grey]="hasGreyBackground">
    <button (click)="scrollNext()" [attr.aria-hidden]="!nextButtonAriaLabel" [attr.aria-label]="nextButtonAriaLabel">
      <fcom-icon
        [class.white-bg]="whiteIcon"
        class="flex-1 flex--nogrow nordic-blue-900-text"
        [name]="rightIcon"
      ></fcom-icon>
    </button>
  </div>
  <div
    #contentWrap
    class="content-wrap flex"
    [ngClass]="{
      'flex--center': align === 'center' && !(prevButtonVisible || nextButtonVisible),
      'flex--end': align === 'right',
    }"
    (scroll)="onScroll()"
  >
    <div #content class="content"><ng-content></ng-content></div>
  </div>
</div>
