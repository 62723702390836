import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { baseUrl } from '@finnairoyj/fcom-ui-styles';

import { PaginationComponent } from './pagination.component';
import { FcomFormsModule } from '../forms';
import { IconsModule } from '../icons';

@NgModule({
  declarations: [PaginationComponent],
  imports: [CommonModule, FcomFormsModule, IconsModule.forRoot({ baseUrl })],
  exports: [PaginationComponent],
})
export class PaginationModule {}
