import { Component, EventEmitter, Injector, Input, Output, ChangeDetectorRef } from '@angular/core';

import { CheckBoxTheme } from '../enums';
import { AbstractInputComponent } from './abstract-input.component';

@Component({ template: '' })
export class AbstractCheckboxComponent extends AbstractInputComponent<boolean> {
  @Input()
  get checked(): boolean {
    return this.pValue;
  }
  set checked(value: boolean) {
    if (value !== this.checked) {
      this.pValue = value;
      this.cdRef.markForCheck();
    }
  }
  protected pValue = false;

  @Input()
  name: string | null = null;

  @Input()
  tabIndex: number;

  @Input()
  theme: CheckBoxTheme = CheckBoxTheme.SQUARE;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  change = new EventEmitter<boolean>();

  constructor(cdRef: ChangeDetectorRef, injector: Injector) {
    super(cdRef, injector);
  }

  // Implemented as part of ControlValueAccessor.
  writeValue(value: any): void {
    this.checked = !!value;
  }

  clickEvent(event: Event): void {
    event.stopPropagation();

    if (!this.disabled || (this.disabled && this.checked)) {
      this.toggle();
      this._emitChangeEvent();
    }
  }

  interactionEvent(event: Event): void {
    event.stopPropagation();
  }

  toggle(): void {
    this.checked = !this.checked;
  }

  private _emitChangeEvent(): void {
    this.notifyValueChange();
    this.change.emit(this.checked);
  }
}
