import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

// Copied here from utils to avoid circular dependencies
const isNull = (obj) => obj === null && typeof obj === 'object';
const isUndefined = (obj) => typeof obj === 'undefined';
const isPresent = (obj) => !isUndefined(obj) && !isNull(obj);

export const safeMap =
  <T, R>(project: (value: T, index: number) => R, thisArg?: any) =>
  (source$: Observable<T>) => {
    return source$.pipe(filter(isPresent), map(project, thisArg));
  };
