import { combineLatest, Observable } from 'rxjs';
import { filter, map, scan } from 'rxjs/operators';

import {
  Amount,
  NO_MEAL_VARIANT,
  NO_UPGR_MEAL_VARIANT,
  SeatCharacteristics,
  SelectionsPerTraveler,
  TravelerService,
} from '@fcom/dapi/interfaces';
import {
  Category,
  FinnairCart,
  FinnairItineraryItemFlight,
  FinnairItineraryItemType,
  FinnairOrder,
  FinnairPassengerItem,
  FinnairPassengerServiceItem,
  FinnairPassengerServiceSelectionItem,
  FinnairServiceBoundItem,
  FinnairServiceItem,
  FinnairServices,
  FinnairServiceSegmentItem,
  SeatInfo,
  SeatmapSvgAndControlData,
  SeatPrice,
} from '@fcom/dapi/api/models';
import { isEmpty, PreciseNumber, uniqueBy, valuesOf } from '@fcom/core/utils';
import { SeatMapFlightInfo, SeatMapSeatInfo } from '@fcom/common/interfaces';

import { capitalizeEveryWordFirstLetter, getCMSTier } from '../../../utils/service.utils';
import { PriceAndFragment, seatCharacteristicsOrder, SeatMapTraveler, seatTypeInfo } from '../interfaces';
import { isBoundBasedCategory } from './category.utils';

export const getDefaultMealVariant = (flight: FinnairItineraryItemFlight): string => {
  if (flight && (!flight.isShortHaul || flight.cabinClass === 'BUSINESS')) {
    return NO_UPGR_MEAL_VARIANT;
  }
  return NO_MEAL_VARIANT;
};

export const getSeatPrice = (seat: SeatInfo, travelerId: string): SeatPrice => {
  return seat?.prices.find((p: SeatPrice) => p.travelerIds.indexOf(travelerId) !== -1);
};

export const getSeatInfoForTracking = (
  seatMap: SeatmapSvgAndControlData,
  seatElement: Element,
  activePax: SeatMapTraveler
): SeatMapSeatInfo => {
  const seatNo = seatElement.getAttribute('data-id');
  const seatInfo = seatMap.seats.find((s) => s.seatNumber === seatNo);
  const price = getSeatPrice(seatInfo, activePax.id);
  return {
    seatNo,
    seatType: getSeatType(seatInfo?.characteristics).className,
    price: price?.price?.amount,
    currency: price?.price?.currencyCode,
  };
};

export const getSeatMapFlightInfoFromCart = (
  cartOrOrder: FinnairCart | FinnairOrder
): SeatMapFlightInfo | undefined => {
  if (!cartOrOrder) {
    return undefined;
  }
  const bound = cartOrOrder.bounds[0];
  const itinerary = bound.itinerary.find(
    (i) => i.type === FinnairItineraryItemType.FLIGHT
  ) as FinnairItineraryItemFlight;
  const price = cartOrOrder.prices?.unpaid?.flight?.total.totalAmount;
  return {
    origin: bound.departure.locationCode,
    destination: bound.arrival.locationCode,
    departureTime: bound.departure.dateTime,
    acType: itinerary.aircraft.name,
    flightNo: itinerary.flightNumber,
    ticketType: bound.fareFamily.name,
    price: price?.amount,
    currency: price?.currencyCode,
  };
};

export const getSeatMapFlight = (flight: FinnairItineraryItemFlight): SeatMapFlightInfo | undefined => {
  if (!flight) {
    return undefined;
  }
  return {
    origin: flight.departure.locationCode,
    destination: flight.arrival.locationCode,
    departureTime: flight.departure.dateTime,
    acType: flight.aircraft?.name,
    flightNo: flight.flightNumber,
    ticketType: flight.bookingClass,
  };
};

export const getSeatType = (characteristics: string[] = []): { className: string; label: string; cmsKey: string } => {
  if (characteristics.length === 0) {
    return seatTypeInfo['regular'];
  }

  const orderedCharacteristics = [...characteristics].sort((a: SeatCharacteristics, b: SeatCharacteristics) => {
    const aIndex = seatCharacteristicsOrder.indexOf(a);
    const bIndex = seatCharacteristicsOrder.indexOf(b);

    // Deprioritise characteristics not in ordering array
    const aValue = aIndex === -1 ? seatCharacteristicsOrder.length + 1 : aIndex;
    const bValue = bIndex === -1 ? seatCharacteristicsOrder.length + 1 : bIndex;

    return aValue - bValue;
  });

  return seatTypeInfo[orderedCharacteristics[0]] || seatTypeInfo['regular'];
};

export const sumOfPricesForTravelersServiceSelection = (
  selections: SelectionsPerTraveler<TravelerService | TravelerService[]>
): Amount => {
  const prices: Amount[] = valuesOf(selections)
    .filter(Boolean)
    .reduce<TravelerService[]>((all: TravelerService[], service: TravelerService | TravelerService[]) => {
      const services = Array.isArray(service) ? service : [service];
      return all.concat(
        services.filter(Boolean).flatMap((s) => (s.quantity > 1 ? Array.from({ length: s.quantity }, () => s) : s))
      );
    }, [])
    .map((service) => service.pricePerItem);

  if (isEmpty(prices)) {
    return undefined;
  }

  const currencyCode: string = prices[0].currencyCode;
  const currencyCodesMatch = !prices.find((a: Amount) => a.currencyCode !== currencyCode);
  if (!currencyCodesMatch) {
    // Do not show price if we can't calculate it
    return undefined;
  }

  const amount: string = prices
    .map((p: Amount) => p.amount)
    .reduce((total: PreciseNumber, current: string) => total.add(PreciseNumber.fromString(current)), PreciseNumber.ZERO)
    .toString();
  return { amount, currencyCode };
};

export const calculateSubTotalPrice = (
  subtotalPriceSub$: Observable<Amount & { category: Category; fragmentId: string }>,
  categorySubject$: Observable<Category>
): Observable<Amount> => {
  const categoryPrices$ = subtotalPriceSub$.pipe(
    scan((acc: { [key: string]: PriceAndFragment[] }, value: Amount & { category: Category; fragmentId: string }) => {
      const newValue: PriceAndFragment = {
        price: { currencyCode: value.currencyCode, amount: value.amount },
        fragmentId: value.fragmentId,
      };
      if (!acc[value.category.toString()]) {
        acc[value.category.toString()] = [newValue];
      } else {
        const oldFragment = acc[value.category.toString()].find((v) => v.fragmentId === newValue.fragmentId);
        if (oldFragment) {
          oldFragment.price = newValue.price;
        } else {
          acc[value.category.toString()].push(newValue);
        }
      }
      return acc;
    }, {})
  );

  return combineLatest([categoryPrices$, categorySubject$.pipe(filter(Boolean))]).pipe(
    map(([categoryPrices, category]) => {
      const prices = categoryPrices[category.toString()];

      if (Array.isArray(prices) && prices?.length) {
        return prices
          .filter((a) => !!a.price.amount)
          .reduce(
            (all: Amount, priceAndFragment: PriceAndFragment) => {
              if (!all.currencyCode) {
                all.currencyCode = priceAndFragment.price.currencyCode;
              }
              all.amount = String(+priceAndFragment.price.amount + +all.amount);
              return all;
            },
            { amount: '0', currencyCode: null }
          );
      } else {
        return { amount: '0', currencyCode: null };
      }
    })
  );
};

const filterServicesByFunction = (
  serviceItems: FinnairServiceItem[],
  filterFunction: (service: FinnairPassengerServiceSelectionItem) => boolean
): FinnairServiceItem[] => {
  return serviceItems.reduce((allItems, serviceItem) => {
    if (
      serviceItem.bounds.some((b) =>
        b.segments.some((s) => s.passengers.some((p) => p.services.some((s) => filterFunction(s))))
      )
    ) {
      allItems.push({
        ...serviceItem,
        bounds: serviceItem.bounds.reduce((bounds, bound) => {
          if (bound.segments.some((s) => s.passengers.some((p) => p.services.some((s) => filterFunction(s))))) {
            const segments = bound.segments.reduce((segments, segment) => {
              if (segment.passengers.some((p) => p.services.some((s) => filterFunction(s)))) {
                const passengers = segment.passengers.reduce((passengers, passenger) => {
                  if (passenger.services.some((s) => filterFunction(s))) {
                    const services = passenger.services.filter((s) => filterFunction(s));
                    passengers.push({
                      ...passenger,
                      quantity: services.length,
                      services,
                    });
                  }
                  return passengers;
                }, [] as FinnairPassengerServiceItem[]);
                segments.push({
                  ...segment,
                  quantity: passengers.reduce((count, passenger) => count + passenger.quantity, 0),
                  passengers,
                });
              }
              return segments;
            }, [] as FinnairServiceSegmentItem[]);
            const divider = isBoundBasedCategory(serviceItem.category) ? segments.length : 1;
            bounds.push({
              ...bound,
              quantity: Math.floor(segments.reduce((count, segment) => count + segment.quantity, 0) / divider),
              segments,
            });
          }
          return bounds;
        }, [] as FinnairServiceBoundItem[]),
      });
    }
    return allItems;
  }, [] as FinnairServiceItem[]);
};

export const filterServices = (
  finnairServiceItems: FinnairServiceItem[] = [],
  filterFunction?: (service: FinnairPassengerServiceSelectionItem) => boolean
): FinnairServiceItem[] => {
  if (filterFunction) {
    return filterServicesByFunction([...finnairServiceItems], filterFunction);
  }

  return [...finnairServiceItems];
};

const isNotIncludedOrZeroPriced = (service: FinnairPassengerServiceSelectionItem): boolean => {
  return !(
    service.includedInTicketType ||
    service.includedInTierBenefit ||
    !service.totalPrice?.amount ||
    parseFloat(service.totalPrice?.amount) <= 0
  );
};

export const getServicesToShow = (
  services: FinnairServices | undefined,
  keyToShow: keyof Omit<FinnairServices, 'servicesOrder'>,
  removeIncludedInTicketTypeAndTierBenefitAndZeroPricedServices = false,
  showFallback = false
): FinnairServiceItem[] => {
  if (services?.[keyToShow]?.length) {
    return filterServices(
      services?.[keyToShow],
      removeIncludedInTicketTypeAndTierBenefitAndZeroPricedServices ? isNotIncludedOrZeroPriced : undefined
    );
  }
  if (showFallback) {
    return filterServices(
      services?.included,
      removeIncludedInTicketTypeAndTierBenefitAndZeroPricedServices ? isNotIncludedOrZeroPriced : undefined
    );
  }

  return [];
};

export const combineServices = <T extends FinnairServiceItem>(listOfServices: T[][]): T[] =>
  listOfServices.filter(Boolean).reduce(
    (combinedServices, services) =>
      services.reduce((allServices, service) => {
        const currentService = allServices.find((s) => s.category === service.category);
        if (!currentService) {
          allServices.push({ ...service });
        } else {
          currentService.bounds = [...currentService.bounds, ...service.bounds].reduce((allBounds, bound) => {
            const currentBound = allBounds.find((b) => b.id === bound.id);
            if (!currentBound) {
              allBounds.push({ ...bound });
            } else {
              currentBound.segments = [...currentBound.segments, ...bound.segments].reduce((allSegments, segment) => {
                const currentSegment = allSegments.find((s) => s.id === segment.id);
                if (!currentSegment) {
                  allSegments.push({ ...segment });
                } else {
                  currentSegment.passengers = [...currentSegment.passengers, ...segment.passengers].reduce(
                    (allPassengers, passenger) => {
                      const currentPassenger = allPassengers.find((p) => p.id === passenger.id);
                      if (!currentPassenger) {
                        allPassengers.push({ ...passenger });
                      } else {
                        currentPassenger.services = [...currentPassenger.services, ...passenger.services].filter(
                          uniqueBy((service) => service.id)
                        );
                      }
                      return allPassengers;
                    },
                    []
                  );
                }
                return allSegments;
              }, []);
              return allBounds;
            }
            return allBounds;
          }, []);
        }
        return allServices;
      }, combinedServices),
    []
  );

export const getPassengerTierLevel = (
  passengerId: string,
  passengerItems: FinnairPassengerItem[]
): string | undefined => {
  const passenger = passengerItems.find(({ id }) => id === passengerId);
  const tier = (passenger?.frequentFlyerCards || [])
    .find((c) => c.companyCode && c.companyCode.toLowerCase() === 'ay')
    ?.tierLevel?.toString();
  return tier ? capitalizeEveryWordFirstLetter(getCMSTier(tier)) : undefined;
};
