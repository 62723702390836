import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { merge, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo, skip, startWith, take } from 'rxjs/operators';

import { combineTemplate } from '@fcom/rx';
import { isPresent, unsubscribe } from '@fcom/core/utils';

import { OptionsAndValue, SelectOption } from '../interfaces';

const convertToSelectOption = (inputArray: any[]): SelectOption[] => {
  return inputArray.map((input: any): SelectOption => {
    if (typeof input === 'string' || Number.isFinite(input)) {
      const val = `${input}`;
      return { name: val, value: val };
    }
    if (!isPresent(input.name) || !isPresent(input.value)) {
      throw new Error(
        `SelectField received option that did not match { name?, value, disabled? }: ${JSON.stringify(input)}`
      );
    }
    return input as SelectOption;
  });
};

@Component({
  selector: 'fin-select-field',
  templateUrl: './select-field.component.html',
})
export class SelectFieldComponent implements OnDestroy, OnInit {
  @ViewChild('inputElement', { static: true })
  inputElement: ElementRef;

  @Output()
  focus: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  blur: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  group: UntypedFormGroup;
  @Input()
  hideError = false;
  @Input()
  name: string;
  @Input()
  modelName: string;
  @Input()
  hiddenLabel: string;
  @Input()
  autocomplete: string;
  @Input()
  translationPrefix: string;
  @Input()
  defaultOption: SelectOption;
  @Input()
  required: boolean;
  /**
   * Sets the options available for the select field.
   *
   * NOTE: Does not support empty '' values as custom options
   *
   * @param options
   */
  @Input()
  options$: Observable<any[]>;

  selectOptions$: Observable<SelectOption[]>;
  optionsAndValue$: Observable<OptionsAndValue>;
  hasInitialValue$: Observable<boolean>;
  subscription: Subscription = new Subscription();

  ngOnInit(): void {
    this.selectOptions$ = this.options$.pipe(filter(isPresent), map(convertToSelectOption));
    const currentModel = this.group.get(this.modelName);
    const fieldValue$ = currentModel.valueChanges.pipe(startWith(currentModel.value), distinctUntilChanged());
    this.optionsAndValue$ = combineTemplate<OptionsAndValue>({
      options: this.selectOptions$,
      value: fieldValue$,
    });
    this.hasInitialValue$ = merge(
      this.optionsAndValue$.pipe(
        take(1),
        map(({ options, value }) => this.hasOptionWithValue(options, value))
      ),
      fieldValue$.pipe(skip(1), mapTo(false))
    );
    this.subscription.add(
      this.optionsAndValue$.subscribe(({ options, value }) => {
        this.inputElement.nativeElement.value = value;
        this.clearModelIfValueNotPresentInOptions(options, value);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription = unsubscribe(this.subscription);
  }

  firstErrorKey(): string {
    const errors = this.group.get(this.modelName).errors;
    if (!errors) {
      return undefined;
    }
    return Object.keys(errors).filter((k) => errors[k])[0];
  }

  onChangeEvent(event: Event): void {
    const field = this.group.get(this.modelName);

    // Chrome on iOS does not trigger the "input" event on autofill.
    if ((event.target as HTMLSelectElement).value !== field.value) {
      field.setValue((event.target as HTMLSelectElement).value);
    }

    this.change.emit(`${this.translationPrefix}.info`);
  }

  hasOptionWithValue = (options: SelectOption[], value: string) =>
    isPresent(options.filter(isPresent).find((option) => option.value === value));

  private clearModelIfValueNotPresentInOptions(options: SelectOption[], value: string) {
    if (!this.hasOptionWithValue(this.defaultOption ? options.concat([this.defaultOption]) : options, value)) {
      // Clear the model if the value is not present in the new options
      this.group.get(this.modelName).setValue('');
    }
  }
}
