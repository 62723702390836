<ng-container *ngIf="isClickable; else nonClickableItem">
  <a
    class="medium-type font-body-1 link-hover underline-hover {{ customClasses }}"
    tabindex="0"
    [attr.data-testid]="'summary-clickable-item-' + type"
    (click)="clickAction.emit()"
    (keydown.enter)="clickAction.emit()"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </a>
</ng-container>
<ng-template #nonClickableItem>
  <span class="medium-type font-body-1 {{ customClasses }}" tabindex="0" [attr.data-testid]="'summary-item-' + type">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </span>
</ng-template>
<ng-template #content>
  <ng-content select=".content"></ng-content>
</ng-template>
