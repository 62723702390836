import { createSelector, select } from '@ngrx/store';

import { BookingFeatureState, PaxDetailsState } from '@fcom/common/interfaces/booking';

import { bookingFeatureState } from './booking-feature-state.selector';

// eslint-disable-next-line no-underscore-dangle
const _paxDetailsPassengers = createSelector(bookingFeatureState, (bfs: BookingFeatureState) => bfs?.paxDetails);
const _filteredPaxDetailsPassengers = (passengerIds: string[]) =>
  createSelector(_paxDetailsPassengers, (pxd: PaxDetailsState) => ({
    ...pxd,
    passengers: pxd?.passengers?.filter((p) => passengerIds.includes(p.travelerId)),
  }));

const _additionalEmails = createSelector(_paxDetailsPassengers, (pxd: PaxDetailsState) => pxd?.additionalEmails);

export const paxDetailTravelerSelector = createSelector(
  _paxDetailsPassengers,
  (pxd: PaxDetailsState) => pxd?.passengers
);

export const paxDetails = () => select(_paxDetailsPassengers);
export const additionalEmails = () => select(_additionalEmails);
export const filteredPaxDetails = (passengerIds: string[]) => select(_filteredPaxDetailsPassengers(passengerIds));
