import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { BUS_AIRCRAFT_CODE } from '@fcom/booking/modules/ticket-selection/interfaces';
import { BoundType, Duration, LocationInfoMap } from '@fcom/dapi';
import {
  FinnairBoundItem,
  FinnairDisruptedBoundItem,
  FinnairItineraryItem,
  FinnairItineraryItemFlight,
  FinnairItineraryItemLayover,
} from '@fcom/dapi/api/models';
import { NotificationLayout, NotificationTheme } from '@fcom/ui-components';

import { EndPointChanges, FlightChanges } from '../../interfaces/index';

@Component({
  selector: 'fin-itinerary-timeline',
  styleUrls: ['./itinerary.scss', 'arrow-block.scss'],
  templateUrl: './itinerary-timeline.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryTimelineComponent {
  readonly BUS_AIRCRAFT_CODE = BUS_AIRCRAFT_CODE;
  readonly NotificationLayout = NotificationLayout;
  readonly NotificationTheme = NotificationTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  /**
   * The translation prefix for strings that are not common to both departure and return flights.
   * E.g. "Departure" vs. "Return"
   */
  @Input()
  nonCommonTranslationPrefix: string;

  @Input()
  boundType: BoundType = null;

  @Input()
  bound: FinnairBoundItem | FinnairDisruptedBoundItem;

  @Input()
  locations: LocationInfoMap;

  @Input()
  isBookingFlow = false;

  @Input()
  title: string;

  @Input()
  legChanges: Map<string, FlightChanges> = new Map();

  @Input()
  departureChanges: EndPointChanges;

  @Input()
  arrivalChanges: EndPointChanges;

  @Input()
  boundChangeDuration: Duration;

  @Input()
  showNoChangesNotification: boolean;

  toType(t: FinnairItineraryItem): FinnairItineraryItemFlight | FinnairItineraryItemLayover {
    return 'technicalStops' in t ? (t as FinnairItineraryItemFlight) : (t as FinnairItineraryItemLayover);
  }
}
