import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';

import { CmsPicture } from '@fcom/core-api';
import { AspectRatios } from '@fcom/ui-components';

@Component({
  selector: 'fin-text-on-image-no-link',
  styleUrls: ['./text-on-image-no-link.component.scss'],
  templateUrl: './text-on-image-no-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextOnImageNoLinkComponent {
  @Input()
  teaserTitle$: Observable<string>;
  @Input()
  teaserText$: Observable<string> = EMPTY;
  @Input()
  imageData$: Observable<CmsPicture>;
  /**
   * The preferred aspect ratio of the image
   */
  @Input()
  imageAspectRatio$: Observable<keyof typeof AspectRatios>;
}
