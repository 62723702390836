import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CmsTarget } from '@fcom/core-api';

import { toLinkData } from '../../services';

@Component({
  selector: 'cms-cta-big-link',
  template: `
    <fin-cta-big-link
      [target$]="cmsTarget$"
      [teaserTitle$]="content$ | finMap: 'teaserTitle'"
      [teaserText$]="content$ | finMap: 'teaserText'"
      class="mr-medium-t mr-xlarge-b block"
    >
    </fin-cta-big-link>
  `,
  styleUrls: ['./cms-cta-big-link.component.scss'],
})
export class CmsCtaBigLinkComponent implements OnInit {
  @Input()
  content$: Observable<any>;

  cmsTarget$: Observable<CmsTarget>;

  ngOnInit(): void {
    this.cmsTarget$ = this.content$.pipe(map((d) => toLinkData(d.target)));
  }
}
