import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { CmsQueryListItem } from '@fcom/core-api';

@Component({
  selector: 'fin-teaser',
  templateUrl: './teaser.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeaserComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Output()
  linkClicked: EventEmitter<void> = new EventEmitter(); // mainly for click tracking if normal link tracking is not enough

  @Input()
  content: CmsQueryListItem;

  emitClick(): void {
    this.linkClicked.emit();
  }
}
