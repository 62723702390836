<section class="editorial-pagehead">
  <div class="editorial-pagehead__title grid-col-12 grid-max-width no-row-gap">
    <div class="col-1-12">
      <h1 *ngIf="titleAsHeading" class="editorial-title font-heading-1 nordic-blue-900-text mr-small-y">
        {{ title }}
      </h1>
      <span *ngIf="!titleAsHeading" class="editorial-title font-heading-1 nordic-blue-900-text mr-small-y inline-block">
        {{ title }}
      </span>
    </div>
  </div>
  <div class="dn-tablet-down editorial-pagehead__background absolute nordic-blue-50-bg"></div>
</section>
