import { Component, input } from '@angular/core';

import { Observable } from 'rxjs';
import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { CmsCollection, CmsContentType, CmsTaxonomy, CmsTaxonomyGeneral } from '@fcom/core-api';

import { getFontLevel, getHeaderLevel } from '../../services/cms-utils/cms-utils';

@Component({
  selector: 'cms-arrow-link-list',
  templateUrl: './cms-arrow-link-list.component.html',
  styleUrl: './cms-arrow-link-list.component.scss',
})
export class CmsArrowLinkListComponent {
  readonly DEFAULT_HEADER_LEVEL = 3;
  readonly CmsTaxonomyGeneral = CmsTaxonomyGeneral;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly CmsContentType = CmsContentType;

  content$ = input<Observable<CmsCollection>>();
  arrows = input<boolean>(true);

  headerLevel(tags: CmsTaxonomy[]): number {
    return getHeaderLevel(tags);
  }

  headerFontLevel(tags: CmsTaxonomy[]): string {
    return getFontLevel(tags);
  }
}
