<ng-container *ngIf="loggedInProfile$ | async as loggedInProfile; else loginCard">
  <fin-greeting-card *ngIf="showGreeting" [firstName]="loggedInProfile.firstname"></fin-greeting-card>
</ng-container>

<ng-template #loginCard>
  <fin-login-card
    *ngIf="(loginStatus$ | async) === LoginStatus.NOT_LOGGED_IN"
    [title]="'loginPromotion.loginHeading.inPaxDetails'"
    [benefitText]="'loginPromotion.benefitText'"
    [footnoteText]="'loginPromotion.footNote'"
    [showIcon]="false"
    [benefitMode]="'list'"
  >
  </fin-login-card>
</ng-template>
