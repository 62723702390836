import { createAction, props, union } from '@ngrx/store';

import { LoginAccessToken, LoginStep, Profile } from '@fcom/core-api/login';

export const setAccessToken = createAction('[LoginActions] SetToken', props<{ accessToken: LoginAccessToken }>());
export const clearAccessToken = createAction('[LoginActions] ClearToken');
export const setProfile = createAction('[LoginActions] SetProfile', props<{ profile: Profile }>());
export const setLoginError = createAction('[LoginActions] Error');
export const setNotLoggedIn = createAction('[LoginActions] SetNotLoggedIn');
export const setExecutionToken = createAction('[LoginActions] SetExecutionToken', props<{ token: string }>());

export const setLoginPhaseStep = createAction('[LoginActions] setLoginPhaseStep', props<{ step: LoginStep }>());
export const setLoginPhaseError = createAction('[LoginActions] setLoginPhaseError', props<{ hasError: boolean }>());
export const clearLoginPhase = createAction('[LoginActions] clearLoginPhase');

const all = union({
  setAccessToken,
  clearAccessToken,
  setProfile,
  setLoginError,
  setNotLoggedIn,
  clearLoginPhase,
  setLoginPhaseStep,
  setLoginPhaseError,
  setExecutionToken,
});

export type LoginActionUnion = typeof all;
