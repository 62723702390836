import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'fin-author',
  templateUrl: './author.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorComponent {
  @Input()
  author = '';

  constructor() {}
}
