<ng-template #loaderContent>
  <div class="loader-content flex--nogrow align-center">
    <h2 class="font-heading-4" *ngIf="!hideTitle">{{ title | finLocalization }}</h2>
    <div class="loader-spacer pr-medium-y">
      <div class="loader"></div>
    </div>
    <div class="loader-for font-body-1 grey-600-text">{{ waitingFor | finLocalization }}</div>
  </div>
</ng-template>

<ng-container [ngSwitch]="type">
  <!-- CASE 1/4: FULL_SCREEN -->
  <div *ngSwitchCase="LoaderType.FULL_SCREEN" class="loader-container fixed z5 top-left fill grow">
    <div class="loader-wrapper grid-max-width center grow flex flex--center nordic-blue-900-text white-bg">
      <ng-container *ngTemplateOutlet="loaderContent"></ng-container>
    </div>
  </div>

  <!-- CASE 2/4: EMBEDDED -->
  <div *ngSwitchCase="LoaderType.EMBEDDED" class="nordic-blue-900-text pr-large-y">
    <ng-container *ngTemplateOutlet="loaderContent"></ng-container>
  </div>

  <!-- CASE 3/4: INLINE -->
  <div *ngSwitchCase="LoaderType.INLINE" class="pr-large-y font-body-1">
    <div class="loader"></div>
  </div>

  <!-- CASE 4/4: INLINE_TEXT -->
  <div *ngSwitchCase="LoaderType.INLINE_TEXT" class="font-body-1 inline-block pr-small-x">
    <div class="loader"></div>
  </div>
</ng-container>
