import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  Inject,
  PLATFORM_ID,
  NgZone,
} from '@angular/core';
import { isPlatformServer } from '@angular/common';

import { Subject } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

import { ScrolledPastService } from './scrolled-past.service';

/**
 * Emits event in (scrolledPast) when the element is in viewport
 * or scrolled past
 */
@Directive({
  selector: '[finScrolledPast]',
})
export class ScrolledPastDirective implements AfterViewInit, OnDestroy {
  @Output()
  finScrolledPast: EventEmitter<boolean> = new EventEmitter<boolean>();

  private ngDestroyed$ = new Subject<void>();

  constructor(
    private el: ElementRef<HTMLElement>,
    private scrolledPastService: ScrolledPastService,
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  ngAfterViewInit(): void {
    if (isPlatformServer(this.platform)) {
      return;
    }
    this.listenToIntersectionEvents();
  }

  ngOnDestroy = (): void => {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  };

  private listenToIntersectionEvents() {
    const events$ = this.scrolledPastService.listenToElement(this.el.nativeElement);
    // Delay because we need to run in the following change detection cycle
    this.ngZone.runOutsideAngular(() => {
      events$.pipe(delay(1), takeUntil(this.ngDestroyed$)).subscribe(() => {
        this.finScrolledPast.emit(true);
        this.finScrolledPast.complete();
      });
    });
  }
}
