import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const PRICE_PATTERN = /^\d+(\.\d{1,2})?$/;
export const PHONE_NUMBER_PATTERN = /^\+[0-9\s]{1,15}$/;
export const PHONE_NUMBER_NO_SPACES_PATTERN = /^\+[0-9]{1,15}$/;
export const FLIGHT_NUMBER_PATTERN = /^[A-Za-z]{2}[0-9]{1,4}$/;
export const PAYMENT_IFSC_BIK_PATTERN = /^[a-z0-9]{11}$/i;
export const PAYMENT_KPP_PATTERN = /^[a-z0-9]{9}$/i;
export const PAYMENT_INN_PATTERN = /^[a-z0-9]{10}$/i;
export const CASE_KEY_PATTERN = /^\d-?\d{1,14}$/i;

export const atLeastOneRequired = (controls: string[]): ValidatorFn => {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const hasAtLeastOneValue = controls.some((control) => {
      return !!formGroup.get(control).value;
    });
    return hasAtLeastOneValue ? null : { atLeastOneRequired: true };
  };
};
