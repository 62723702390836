import { createSelector, createFeatureSelector, MemoizedSelector } from '@ngrx/store';

import { Location, LocationsMap } from '@fcom/core-api';

import { LocationsState, LocationAppState } from '../../interfaces';
import * as fromLocations from '../reducers/locations.reducer';

const selectLocationsState = createFeatureSelector<LocationAppState, LocationsState>('locations');

export const getLocation = (locationCode: string): MemoizedSelector<LocationAppState, Location> =>
  createSelector(selectLocationsState, (state) => {
    return fromLocations.getLocationData(state, locationCode);
  });

export const getLocations = (): MemoizedSelector<LocationAppState, LocationsMap> =>
  createSelector(selectLocationsState, (state) => {
    return state.locations;
  });
