import { Component, Input, HostBinding } from '@angular/core';

import { Breakpoint } from '@fcom/common/interfaces';
import { LegEndpoint } from '@fcom/dapi';

@Component({
  selector: 'fin-endpoint-time',
  templateUrl: './endpoint-time.component.html',
  styleUrls: ['./endpoint-time.component.scss'],
})
export class EndpointTimeComponent {
  @HostBinding('class.block') defaultClass = true;
  @Input()
  endpoint: LegEndpoint;

  @Input()
  reverse = false;

  @Input()
  wrapAt: Breakpoint;
}
