// TODO: possibly rename the file to be more generic

import { PaxAmount } from '@fcom/dapi';

export enum AmountUpdateType {
  INCREMENT,
  DECREMENT,
}

// TODO: check if needed after store integration
interface PaxAmountTypeMinMax {
  max: number;
  min: number;
}

export interface PaxAmountTypeConfig {
  adults: PaxAmountTypeMinMax;
  c15s: PaxAmountTypeMinMax;
  children: PaxAmountTypeMinMax;
  infants: PaxAmountTypeMinMax;
}

export interface PaxUpdateEvent {
  paxType: keyof PaxAmount;
  amount: number;
  updateType: AmountUpdateType;
}

export interface AmRoomSelection {
  numberOfAdults: number;
  childrenAges: number[];
}
