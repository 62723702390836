<div
  class="info-box ps-xxsmall-y ps-xsmall-x rounded flex flex--center flex--middle"
  [class.heather-50-bg]="theme === InfoBoxTheme.ALERT"
  [class.nordic-blue-50-bg]="theme === InfoBoxTheme.DEFAULT"
>
  <fcom-icon
    *ngIf="icon"
    class="ms-xsmall-r icon-medium flex--shrink0"
    [class.heather-700-text]="theme === InfoBoxTheme.ALERT"
    [class.nordic-blue-900-text]="theme === InfoBoxTheme.DEFAULT"
    [name]="icon"
  >
  </fcom-icon>
  <p
    class="marginless font-body-2"
    [class.heather-700-text]="theme === InfoBoxTheme.ALERT"
    [class.nordic-blue-900-text]="theme === InfoBoxTheme.DEFAULT"
  >
    <ng-container *ngIf="!!text; then textFromInput; else ngContent"></ng-container>
  </p>
</div>

<ng-template #textFromInput>
  {{ text }}
</ng-template>
<ng-template #ngContent>
  <ng-content></ng-content>
</ng-template>
