<div class="flex">
  <button
    [ngClass]="{ 'control-box pr-xxsmall-x': buttonPadding }"
    [class.nordic-blue-900-text]="model > min"
    [class.btn--disabled]="model === min"
    (click)="decrease($event)"
    [attr.aria-disabled]="model === min"
    [attr.aria-label]="decreaseLabel"
    [attr.aria-controls]="controls"
    data-testid="plus-minus-button-decrease"
  >
    <span class="circle inline-block">
      <fcom-icon [ngClass]="iconSize" [name]="SvgLibraryIcon.MINUS_OUTLINE"></fcom-icon>
    </span>
  </button>
  <div class="control-box align-center flex flex--column flex--center" aria-live="polite">
    <span
      class="block"
      [ngClass]="textSize"
      [class.nordic-blue-900-text]="model >= min && model > 0"
      [class.grey-600-text]="model === 0"
    >
      {{ title }}
    </span>
    <span *ngIf="description" class="block font-body-1 nordic-blue-900-text">
      {{ description | finEmpty }}
    </span>
  </div>
  <button
    [ngClass]="{ 'control-box pr-xxsmall-x': buttonPadding }"
    [class.nordic-blue-900-text]="model < max"
    [class.btn--disabled]="model === max"
    (click)="increase($event)"
    [attr.aria-disabled]="model === max"
    [attr.aria-label]="increaseLabel"
    [attr.aria-controls]="controls"
    data-testid="plus-minus-button-increase"
  >
    <span class="circle inline-block">
      <fcom-icon [ngClass]="iconSize" [name]="SvgLibraryIcon.PLUS_OUTLINE"></fcom-icon>
    </span>
  </button>
</div>
