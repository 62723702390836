import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, combineLatest, of, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AppState, StorageService } from '@fcom/core';
import { LoginStatus, Profile, ProfileType } from '@fcom/core-api/login';
import { loginStatus, profile, profileType } from '@fcom/core/selectors';
import { SentryLogger } from '@fcom/core/services/sentry/sentry.logger';
import { finShare } from '@fcom/rx';
import { unsubscribe } from '@fcom/core/utils';

import { PersonalizationSession } from '../interfaces/tracking.interface';

const SESSION_KEY = 'personalization-session';

@Injectable({
  providedIn: 'root',
})
export class PersonalizationTrackingService implements OnDestroy {
  readonly loginStatus$: Observable<LoginStatus>;
  readonly profile$: Observable<Profile>;
  readonly profileType$: Observable<ProfileType>;

  private subscriptions = new Subscription();

  constructor(
    private store$: Store<AppState>,
    private storageService: StorageService,
    private sentryLogger: SentryLogger
  ) {
    this.loginStatus$ = this.store$.pipe(
      loginStatus(),
      filter((status) => status === LoginStatus.NOT_LOGGED_IN || status === LoginStatus.LOGGED_IN),
      finShare()
    );
    this.profile$ = this.store$.pipe(profile(), finShare());
    this.profileType$ = this.store$.pipe(profileType(), finShare());
  }

  init(): void {
    this.subscriptions.add(
      combineLatest([this.profile$, this.profileType$]).subscribe(([memberProfile, memberProfileType]) => {
        this.storeSession(memberProfile, memberProfileType);
      })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  isNotLoggedInFPlusMember(): Observable<boolean> {
    return this.loginStatus$.pipe(
      map((status: LoginStatus) => {
        const storedSession = this.getStoredSession();
        if (status === LoginStatus.NOT_LOGGED_IN && storedSession && storedSession.profileType === ProfileType.FPLUS) {
          return true;
        }
        return false;
      })
    );
  }

  clearSession(): void {
    this.storageService.LOCAL.remove(SESSION_KEY);
  }

  private storeSession(memberProfile: Profile, memberProfileType: ProfileType): void {
    const session: PersonalizationSession = {
      startTime: Date.now(),
      profileType: memberProfileType,
      tier: memberProfile.tier,
      memberNumber: memberProfile.memberNumber,
    };

    this.storageService.LOCAL.set(SESSION_KEY, JSON.stringify(session));
  }

  private getStoredSession(): PersonalizationSession {
    const session = this.storageService.LOCAL.get(SESSION_KEY);
    let parsed: PersonalizationSession;
    if (session) {
      try {
        parsed = JSON.parse(session);
      } catch {
        this.sentryLogger.warn('Failed to parse personalization session from storage');
      }
    }
    return parsed;
  }
}

@Injectable()
export class NoopPersonalizationTrackingService {
  init(): void {}
  isNotLoggedInFPlusMember(): Observable<boolean> {
    return of(false);
  }
  clearTracking(): void {}
}
