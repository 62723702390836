import { Data } from '@angular/router';

import { Amount } from '@fcom/dapi';
import { CmsImageData } from '@fcom/core-api';

export interface OndMarketingOfferResponse {
  [locationMatchKey: string]: {
    prices: OndMarketingOfferInput[];
  };
}

export interface OndMarketingOfferInput {
  /**
   * currencyCode to use, eq EUR
   */
  currency: string;
  /**
   * Departure date string "2020-08-25"
   */
  departure: string;
  /**
   * From locationCode
   */
  from: string;
  /**
   * Offer price
   */
  price: number;
  /**
   * Return date string "2020-08-28"
   */
  return: string;
  /**
   * To locationCode
   */
  to: string;
}

export interface OndMarketingOffer {
  /**
   * IATA code for flight origin location
   */
  originLocationCode: string;
  /**
   * IATA code for flight destination location
   */
  destinationLocationCode: string;
  /**
   * ISO8601 (e.g. 2019-04-12)
   */
  departureDate: string;
  /**
   * ISO8601 (e.g. 2019-04-12)
   */
  returnDate: string;
  /**
   * The price for a single adult passenger
   */
  price: Amount;
  /**
   * The (internal) url to navigate to when clicked
   */
  link: string;
  /**
   * Origin city name translations for offer in UX
   */
  originCityName?: string;
  /**
   * Destination city name translations for offer in UX
   */
  destinationCityName?: string;
  /**
   * Origin country name translations for offer in UX
   */
  originCountryName?: string;
  /**
   * Destination country name translations for offer in UX
   */
  destinationCountryName?: string;
  /**
   * Origin country code translations for offer in UX
   */
  originCountryCode?: string;
  /**
   * Destination country code translations for offer in UX
   */
  destinationCountryCode?: string;
}

export interface OndMarketingOffersRequestBody {
  departureFrom: string;
  departureTo: string;
  destinations: string[];
  duration: string | number;
  origin: string;
}

export interface OndLocationsRequestBody {
  destinations: string[];
  lang: string;
}

export type OndLocationsResponse = OndLocationsCountryResponse | OndLocationsLocationResponse;

export interface OndLocationsCountryResponse {
  [key: string]: OndLocationsCountryData;
}

export interface OndLocationsCountryData {
  countryName: string;
}

export interface OndLocationsLocationResponse {
  [key: string]: OndLocationData;
}

export interface OndMarketingFlightData {
  [locationMatchKey: string]: OndMarketingFlightDataItem;
}

export interface OndMarketingFlightDataItem {
  from: string;
  to: string;
  departure: string;
  return: string;
  currency: string;
  price: number;
}

export interface OndFlightsRequestBody {
  origins: string[];
  destinations: string[];
}

export interface FlightPair {
  origin: string | null;
  destination: string | null;
  ignoredSEOTitle?: string;
  originAirport?: string;
  destinationAirport?: string;
}

export interface OndStream {
  flightParams: FlightPair;
  locationData: OndLocationsResponse;
}

export interface OndDataStream {
  data: Data;
  locationData: OndLocationsResponse;
}

interface OndLocationInfo {
  /**
   * The name of the corresponding city.
   * E.g. { name: 'Helsinki Vantaa', cityName: 'Helsinki' }
   */
  cityName: string;

  /**
   * Two-letter state code (uppercase)
   * Usually null, probably for U.S. states only. E.g. JFK has "NY".
   */
  stateCode?: string;

  /**
   * Two-letter country code (uppercase)
   */
  countryCode: string;

  /**
   * Picture which is mapped from locations api
   */
  picture?: CmsImageData;
}

export interface OndLocationData extends OndLocationInfo {
  country?: string;
  countryUrl?: string;
  destinationUrl?: string;
  continentCode: string;
}

export interface OndPairEnhanced extends OndPair {
  /**
   * Translation added in UX
   */
  originCityName: string;
  originCountryName: string;
  destinationCityName: string;
  destinationCountryName: string;
}

export interface OndPair {
  origin: string;
  originCountry: string;
  destination: string;
  destinationCountry: string;
  title: string;
  detailText: string | null;
  htmlDescription: string;
  lang: string;
}

export type OndPairRequestBody =
  | FromCityOndPairRequestBody
  | FromCountryOndPairRequestBody
  | ToCityOndPairRequestBody
  | ToLocaleRequestBody
  | ToCountryOndPairRequestBody;

export type FromCityOndPairRequestBody =
  | {
      origin: string;
      lang: string;
    }
  | {
      origin: string;
      destination: string;
      lang: string;
    }
  | {
      origin: string;
      destinationCountry: string;
      lang: string;
    };

export type FromCountryOndPairRequestBody =
  | {
      originCountry: string;
      lang: string;
    }
  | {
      originCountry: string;
      destination: string;
      lang: string;
    }
  | {
      originCountry: string;
      destinationCountry: string;
      lang: string;
    };

export type ToCityOndPairRequestBody =
  | {
      destination: string;
      lang: string;
    }
  | {
      destination: string;
      origin: string;
      lang: string;
    }
  | {
      destination: string;
      originCountry: string;
      lang: string;
    };

export type ToCountryOndPairRequestBody =
  | {
      destinationCountry: string;
      lang: string;
    }
  | {
      destinationCountry: string;
      origin: string;
      lang: string;
    }
  | {
      destinationCountry: string;
      originCountry: string;
      lang: string;
    };

export type ToLocaleRequestBody =
  | {
      destinationCountry: string;
    }
  | {
      destinationCountry: string;
      origin: string;
    }
  | {
      destinationCountry: string;
      originCountry: string;
    };

export interface OndPairEnhancedResponse {
  [key: string]: OndPairEnhanced;
}

export interface OndPairResponse {
  [key: string]: OndPair;
}

export enum OndPageType {
  LANDING = 'landing',
  LISTING = 'listing',
}

export interface OndLink {
  origin: string;
  destination?: string;
  link: string;
  originCityName: string;
  originCountryName: string;
  destinationCityName?: string;
  destinationCountryName?: string;
}

export interface OndLinkObject {
  link: string;
  locationName: string;
  countryCode?: string;
}

export interface OndLinkTranslations {
  to: string;
  from: string;
  fromTo: string;
}

export enum OndMarketingRoutesPath {
  FROM = 'from',
  FROM_COUNTRY = 'from-country',
  CITY_TO_CITY = 'city-to-city',
  COUNTRY_TO_COUNTRY = 'country-to-country',
}
