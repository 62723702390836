import { PaxAmount } from '@fcom/dapi';

export const mapPaxAmountToPaxBreakdown = (
  { adults, c15s, children, infants }: PaxAmount,
  translations: Record<string, string>
): string =>
  [
    { key: adults > 1 ? 'adults' : 'adult', amount: adults },
    { key: children + c15s > 1 ? 'children' : 'child', amount: children + c15s },
    { key: infants > 1 ? 'infants' : 'infant', amount: infants },
  ]
    .filter(({ amount }) => amount > 0)
    .map(({ key, amount }) => `${amount} ${translations[key]}`)
    .join(', ');
