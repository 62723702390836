import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';

import { v4 as uuid } from 'uuid';

export interface Step {
  id: string;
  name: string;
  active?: boolean;
  disabled?: boolean;
}

@Component({
  selector: 'fin-step-breadcrumb',
  styleUrls: ['./step-breadcrumb.component.scss'],
  templateUrl: './step-breadcrumb.component.html',
})
export class StepBreadcrumbComponent implements OnInit, OnChanges {
  @Input() steps: Step[] = [];

  @Output() breadcrumbClick: EventEmitter<Step> = new EventEmitter();

  activeIndex: number;

  id: string;

  constructor() {}

  ngOnInit() {
    this.id = uuid();
    this.findActiveIndex();
  }

  ngOnChanges() {
    this.findActiveIndex();
    this.scrollToActiveItem();
  }

  findActiveIndex() {
    (this.steps || []).forEach((step, i) => {
      if (step.active) {
        this.activeIndex = i;
      }
    });
  }

  onBreadcrumbClick(step: Step, index: number) {
    if (!step.disabled && index < this.activeIndex) {
      this.breadcrumbClick.emit(step);
    }
  }

  private scrollToActiveItem(): void {
    const elem = document.getElementById(`${this.id}-${this.activeIndex}`);
    if (elem?.scrollIntoView) {
      elem.scrollIntoView();
    }
  }
}
