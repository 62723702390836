import { DdsLanguage } from '../interfaces';

export const ddsLanguages: { [locale: string]: DdsLanguage } = {
  de_AT: {
    countrySite: 'AT',
    language: 'DE',
  },
  en_AT: {
    countrySite: 'AT',
    language: 'GB',
  },
  en_AU: {
    countrySite: 'AU',
    language: 'GB',
  },
  en_BE: {
    countrySite: 'BE',
    language: 'GB',
  },
  fr_CA: {
    countrySite: 'CA',
    language: 'FR',
  },
  en_CA: {
    countrySite: 'CA',
    language: 'GB',
  },
  de_CH: {
    countrySite: 'CH',
    language: 'DE',
  },
  en_CH: {
    countrySite: 'CH',
    language: 'GB',
  },
  fr_CH: {
    countrySite: 'CH',
    language: 'FR',
  },
  en_CN: {
    countrySite: 'CN',
    language: 'GB',
  },
  zh_CN: {
    countrySite: 'CN',
    language: 'CN',
  },
  zh_HK: {
    countrySite: 'HK',
    language: 'GB',
  },
  en_CZ: {
    countrySite: 'CZ',
    language: 'GB',
  },
  de_DE: {
    countrySite: 'DE',
    language: 'DE',
  },
  en_DE: {
    countrySite: 'DE',
    language: 'GB',
  },
  da_DK: {
    countrySite: 'DK',
    language: 'DK',
  },
  en_DK: {
    countrySite: 'DK',
    language: 'GB',
  },
  en_EE: {
    countrySite: 'EE',
    language: 'GB',
  },
  et_EE: {
    countrySite: 'EE',
    language: 'ET',
  },
  en_ES: {
    countrySite: 'ES',
    language: 'GB',
  },
  es_ES: {
    countrySite: 'ES',
    language: 'ES',
  },
  en_FI: {
    countrySite: 'FI',
    language: 'GB',
  },
  fi_FI: {
    countrySite: 'FI',
    language: 'FI',
  },
  sv_FI: {
    countrySite: 'FI',
    language: 'SE',
  },
  en_FR: {
    countrySite: 'FR',
    language: 'GB',
  },
  fr_FR: {
    countrySite: 'FR',
    language: 'FR',
  },
  en_GB: {
    countrySite: 'GB',
    language: 'GB',
  },
  en_HK: {
    countrySite: 'HK',
    language: 'GB',
  },
  en_HU: {
    countrySite: 'HU',
    language: 'GB',
  },
  en_IE: {
    countrySite: 'IE',
    language: 'GB',
  },
  en_IS: {
    countrySite: 'IS',
    language: 'GB',
  },
  en_IL: {
    countrySite: 'IL',
    language: 'GB',
  },
  en_IN: {
    countrySite: 'IN',
    language: 'GB',
  },
  en_IT: {
    countrySite: 'IT',
    language: 'GB',
  },
  it_IT: {
    countrySite: 'IT',
    language: 'IT',
  },
  en_JP: {
    countrySite: 'JP',
    language: 'GB',
  },
  ja_JP: {
    countrySite: 'JP',
    language: 'JP',
  },
  en_KR: {
    countrySite: 'KR',
    language: 'GB',
  },
  ko_KR: {
    countrySite: 'KR',
    language: 'KO',
  },
  en_LT: {
    countrySite: 'LT',
    language: 'GB',
  },
  en_LV: {
    countrySite: 'LV',
    language: 'GB',
  },
  en_NL: {
    countrySite: 'NL',
    language: 'GB',
  },
  en_NO: {
    countrySite: 'NO',
    language: 'GB',
  },
  no_NO: {
    countrySite: 'NO',
    language: 'NO',
  },
  en_PL: {
    countrySite: 'PL',
    language: 'GB',
  },
  pl_PL: {
    countrySite: 'PL',
    language: 'PL',
  },
  en_RU: {
    countrySite: 'RU',
    language: 'GB',
  },
  ru_RU: {
    countrySite: 'RU',
    language: 'RU',
  },
  en_SE: {
    countrySite: 'SE',
    language: 'GB',
  },
  sv_SE: {
    countrySite: 'SE',
    language: 'SE',
  },
  en_SG: {
    countrySite: 'SG',
    language: 'GB',
  },
  en_TH: {
    countrySite: 'TH',
    language: 'GB',
  },
  en_US: {
    countrySite: 'US',
    language: 'GB',
  },
};

export const ddsLocalesToNewLocales = {
  'at/gb': 'at-en',
  'au/gb': 'au-en',
  'be/gb': 'be-en',
  'ca/gb': 'ca-en',
  'ch/gb': 'ch-en',
  'cn/gb': 'cn-en',
  'cz/gb': 'cz-en',
  'de/gb': 'de-en',
  'dk/gb': 'dk-en',
  'ee/gb': 'ee-en',
  'es/gb': 'es-en',
  'fi/gb': 'fi-en',
  'fr/gb': 'fr-en',
  'gb/gb': 'gb-en',
  'hk/gb': 'hk-en',
  'hu/gb': 'hu-en',
  'ie/gb': 'ie-en',
  'il/gb': 'il-en',
  'in/gb': 'in-en',
  'is/gb': 'is-en',
  'it/gb': 'it-en',
  'jp/gb': 'jp-en',
  'kr/gb': 'kr-en',
  'lt/gb': 'lt-en',
  'lv/gb': 'lv-en',
  'nl/gb': 'nl-en',
  'no/gb': 'no-en',
  'pl/gb': 'pl-en',
  'ru/gb': 'ru-en',
  'se/gb': 'se-en',
  'sg/gb': 'sg-en',
  'th/gb': 'th-en',
  'us/gb': 'us-en',
  'at/de': 'at-de',
  'ca/fr': 'ca-fr',
  'ch/de': 'ch-de',
  'ch/fr': 'ch-fr',
  'de/de': 'de-de',
  'ee/et': 'ee-et',
  'es/es': 'es-es',
  'fi/fi': 'fi-fi',
  'fr/fr': 'fr-fr',
  'it/it': 'it-it',
  'kr/ko': 'kr-ko',
  'no/no': 'no-no',
  'pl/pl': 'pl-pl',
  'ru/ru': 'ru-ru',
  'int/gb': 'en',
  'se/se': 'se-sv',
  'cn/cn': 'cn-zh',
  'dk/dk': 'dk-da',
  'fi/se': 'fi-sv',
  'jp/jp': 'jp-ja',
};

export const ddsLangToNewLang = (url: string): string =>
  ddsLocalesToNewLocales[url?.split('/').slice(1, 3).join('/').toLowerCase()];
