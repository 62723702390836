<div
  role="radiogroup"
  class="ms-xlarge-b"
  [attr.aria-labelledby]="'radio-group-label-' + id"
  [attr.id]="'radio-group-' + id"
>
  <span
    class="font-body-1 nordic-blue-900-text"
    [attr.id]="'radio-group-label-' + id"
    [class.error-900-text]="ctrlField.invalid && ctrlField.touched"
  >
    {{ translationPrefix + '.label' | finLocalization }}
    <span *ngIf="required"> *</span>
  </span>

  <ng-content></ng-content>

  <div
    *ngIf="ctrlField.invalid && ctrlField.touched"
    class="error-label error-900-text font-body-2 mr-xxsmall-t"
    aria-live="polite"
  >
    <span *ngIf="ctrlField.errors?.required">
      {{ (translationErrorPrefix || translationPrefix) + '.required' | finLocalization }}
    </span>
  </div>
</div>
