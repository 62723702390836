import { Directive, ElementRef, Input, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';

import { findFirstFocusableElement } from '@fcom/ui-components/utils/dom.utils';

@Directive({
  selector: '[finFocus]',
})
export class FocusDirective implements AfterViewInit {
  @Input() autofocus = false;

  constructor(
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  ngAfterViewInit(): void {
    if (isPlatformServer(this.platform)) {
      return;
    }

    if (this.autofocus) {
      this.elementRef.nativeElement.focus?.() || findFirstFocusableElement(this.elementRef.nativeElement)?.focus();
    }
  }
}
