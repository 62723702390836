import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

interface Airport {
  dateTime: string;
  city: string;
  airportCode: string;
  airport: string;
}

/**
 * @Unstable
 * Warning! This component is in active development and is not production ready.
 *
 * Documentation can be found in `storybook/stories/flights/timeline.stories.mdx`
 */
@Component({
  selector: 'fcom-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimelineComponent implements OnInit {
  /**
   * Object containing the information for the Departure airport, including
   * - city name
   * - airport name
   * - airport code
   * - formatted dateTime of departure @example "08:45 Thu"
   *
   * Required
   */
  @Input({ required: true }) departure: Airport;

  /**
   * Object containing the information for the Arrival airport, including
   * - city name
   * - airport name
   * - airport code
   * - formatted dateTime of arrival @example "08:45 Thu"
   *
   * Required
   */
  @Input({ required: true }) arrival: Airport;

  @Input() isByBusOnly = false;

  departureIcon: SvgLibraryIcon;
  arrivalIcon: SvgLibraryIcon;

  ngOnInit(): void {
    this.departureIcon = this.isByBusOnly ? SvgLibraryIcon.BUS : SvgLibraryIcon.DEPARTURE;
    this.arrivalIcon = this.isByBusOnly ? SvgLibraryIcon.BUS : SvgLibraryIcon.ARRIVAL;
  }
}
