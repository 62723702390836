import { RootPaths } from '@fcom/core/constants';

import { OndLinkTranslations, OndMarketingRoutesPath, OndPairEnhanced } from '../interfaces';
import { encodeIgnoredSEOTitle } from './utils';

export const createFromCityLink = (
  language: string,
  city: string,
  linkTranslations: OndLinkTranslations,
  ondPair: OndPairEnhanced
): string => {
  return `
  /${language}
  /${RootPaths.OND_MARKETING_LANDING_ROOT}
  /${OndMarketingRoutesPath.FROM}
  /${city.toLowerCase()}
  /${encodeIgnoredSEOTitle(linkTranslations.from.replace('{origin}', ondPair.originCityName))}
  `.replace(/\s/g, '');
};

export const createFromCountryLink = (
  language: string,
  country: string,
  countryName: string,
  linkTranslations: OndLinkTranslations
): string => {
  return `
  /${language}
  /${RootPaths.OND_MARKETING_LANDING_ROOT}
  /${OndMarketingRoutesPath.FROM_COUNTRY}
  /${country}
  /${encodeIgnoredSEOTitle(linkTranslations.from.replace('{origin}', countryName))}
  `.replace(/\s/g, '');
};

export const createCityToCityLink = (
  language: string,
  origin: string,
  destination: string,
  linkTranslations: OndLinkTranslations,
  pair: OndPairEnhanced
): string => {
  return `
  /${language}
  /${RootPaths.OND_MARKETING_LANDING_ROOT}
  /${OndMarketingRoutesPath.CITY_TO_CITY}
  /${origin.toLowerCase()}
  /${destination.toLowerCase()}
  /${encodeIgnoredSEOTitle(
    linkTranslations.fromTo.replace('{origin}', pair.originCityName).replace('{destination}', pair.destinationCityName)
  )}
  `.replace(/\s/g, '');
};

export const createCountryToCountryLink = (
  language: string,
  origin: string,
  destination: string,
  linkTranslations: OndLinkTranslations,
  pair: OndPairEnhanced
): string => {
  return `
  /${language}
  /${RootPaths.OND_MARKETING_LANDING_ROOT}
  /${OndMarketingRoutesPath.COUNTRY_TO_COUNTRY}
  /${origin}
  /${destination}
  /${encodeIgnoredSEOTitle(
    linkTranslations.fromTo
      .replace('{origin}', pair.originCountryName)
      .replace('{destination}', pair.destinationCountryName)
  )}
  `.replace(/\s/g, '');
};
