import { Pipe, PipeTransform } from '@angular/core';

import { Duration } from '@fcom/dapi';

/**
 * @deprecated
 *
 * Should be removed after translations are updated to with named parameters for bookingSearch.duration
 */
@Pipe({
  name: 'finTemporaryDurationPipe',
})
export class TemporaryDurationPipe implements PipeTransform {
  transform(duration: Duration): any {
    return Object.assign({ 0: duration.hours, 1: duration.minutes }, duration);
  }
}
