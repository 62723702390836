import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { defaultIfEmpty, filter, merge, take, Subscription } from 'rxjs';

import { AppState, StorageService } from '@fcom/core';
import { loginIsOpen } from '@fcom/core/selectors';
import { unsubscribe } from '@fcom/core/utils';

import { JoinService } from '../../login/services';

export const WELCOME_BACK_MESSAGE_CLOSED_KEY = 'welcome-back-message-closed';

@Injectable()
export class WelcomeBackMessageService implements OnDestroy {
  private subscriptions: Subscription;

  constructor(
    private store$: Store<AppState>,
    private joinService: JoinService,
    private storageService: StorageService
  ) {
    if (!this.storageService.SESSION.get(WELCOME_BACK_MESSAGE_CLOSED_KEY)) {
      this.subscriptions = merge(this.store$.pipe(loginIsOpen()), this.joinService.joinDialogOpen$)
        .pipe(filter(Boolean), take(1), defaultIfEmpty(false))
        .subscribe((_) => this.close());
    }
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  close(): void {
    this.storageService.SESSION.set(WELCOME_BACK_MESSAGE_CLOSED_KEY, true);
  }
}
