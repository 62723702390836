/* eslint-disable */

/**
 * Which check-in version to use
 */
export enum FinnairCheckInVersion {
  LEGACY = 'LEGACY',
  NEXTGEN = 'NEXTGEN'
}

