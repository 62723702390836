<div class="wrapper" [class.disabled]="disabled">
  <button
    class="block fill relative ps-medium"
    [attr.aria-controls]="expandContainerId"
    [disabled]="disabled"
    (click)="toggleOpen()"
  >
    <div class="header flex flex--top">
      <div class="header-icon flex--nogrow flex flex--top ps-small-r" [class.dn-mobile]="hideIconInMobile">
        <fcom-icon [category]="iconCategory" [name]="iconName" class="icon-large" [ngClass]="iconClasses"> </fcom-icon>
      </div>
      <div class="">
        <div class="header-content flex flex--align-end align-left">
          <h2
            class="header-title nordic-blue-900-text font-heading-4 ps-small-r"
            [class.capitalize]="capitalizeTitle"
            [attr.aria-describedby]="'subtitle-' + expandContainerId"
          >
            {{ title }}
          </h2>
          <div
            *ngIf="subTitle || subTitleIcon"
            class="header-subtitle-wrapper flex--nogrow flex flex--center"
            [ngClass]="{ 'dn-mobile': !showSubTitleOnMobile }"
          >
            <fcom-icon
              *ngIf="subTitleIcon"
              [name]="subTitleIcon"
              [category]="iconCategory"
              class="icon-small ps-xxsmall-r"
              [ngClass]="iconClasses"
            >
            </fcom-icon>
            <div
              *ngIf="subTitle"
              class="header-subtitle font-body-1 nordic-blue-900-text"
              [attr.id]="'subtitle-' + expandContainerId"
            >
              {{ subTitle }}
            </div>
          </div>
        </div>

        <div
          *ngIf="(!expandOpen || showDescriptionAlways) && descriptionContent"
          [ngClass]="descriptionClasses"
          class="nordic-blue-900-text font-body-1 align-left"
          data-testid="booking-expander-description"
          data-hj-suppress
        >
          <ng-container *ngTemplateOutlet="descriptionContent"></ng-container>
        </div>
      </div>
    </div>

    <fcom-icon
      class="ms-medium chevron-down rotate absolute nordic-blue-900-text"
      [name]="SvgLibraryIcon.CHEVRON_DOWN"
      [class.rotate-active]="expandOpen"
    ></fcom-icon>
  </button>

  <fcom-expand
    [ariaId]="expandContainerId"
    class="block"
    [open]="expandOpen"
    [ariaLabelledBy]="'pax-expander-' + expandContainerId"
  >
    <ng-content select="expandedContent"></ng-content>
  </fcom-expand>

  <ng-content select="content"></ng-content>
</div>
