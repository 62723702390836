import { JsConfig } from '../../interfaces';
import { environment } from '../../../../environments/environment';

const getJsConfig = (): JsConfig => {
  let overrides;
  if (environment.isServer) {
    overrides = require('@fcom/server/server/jsConfigOverride').jsConfigOverride;
  } else {
    // @TODO: How to handle separate configs for real/dev servers
    try {
      overrides = require('jsConfigOverride');
    } catch (e) {
      overrides = {};
    }
  }

  return Object.assign({}, environment, overrides);
};

export const jsConfig = getJsConfig();
