<div class="relative ps-medium-t" [class.disabled]="disabled" [formGroup]="group">
  <input
    #inputElement
    [id]="name"
    [formControlName]="modelName"
    [class.form-field-empty]="!(group.get(modelName)?.value?.length > 0)"
    [attr.name]="name"
    [attr.type]="inputType"
    [attr.pattern]="inputPattern"
    [attr.autocomplete]="autocomplete"
    [attr.disabled]="disabled ? 'disabled' : null"
    (focus)="focus.emit([translationPrefix, 'info'].join('.'))"
    (blur)="blur.emit([translationPrefix, 'info'].join('.'))"
    (change)="onChangeEvent($event)"
  />
  <label [attr.for]="name" class="font-body-2 overflow-hidden truncate">
    <span class="is-vishidden" aria-hidden="true" *ngIf="!!hiddenLabel">{{ hiddenLabel }}</span>
    <span>
      {{ [translationPrefix, 'label'].join('.') | finLocalization }}
      <span *ngIf="required"> *</span>
    </span>
  </label>
</div>

<div
  class="error-label font-body-2"
  *ngIf="group.get(modelName)?.invalid && group.get(modelName)?.touched && !hideError"
>
  <span class="error-900-text">
    {{ [translationPrefix, firstError().key].join('.') | finLocalization: firstError().data }}
  </span>
</div>
