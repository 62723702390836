import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { LocalDate } from '@fcom/core/utils';

import { FinnairBoundItemWithLocation } from '../../../interfaces';
@Component({
  selector: 'fin-bound-container',
  templateUrl: './bound-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./bound-container.component.scss'],
})
export class BoundContainerComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  bound: FinnairBoundItemWithLocation;
  @Input()
  label: string;
  @Input()
  date: string | LocalDate;
  @Input()
  active: boolean;
  @Input()
  disabled: boolean;
}
