<div class="align-left">
  <div class="flex flex--column-reverse">
    <div class="flex flex--middle ms-medium-b">
      <h1 *ngIf="useH1" class="font-heading-4">Hi! Please set your location and language.</h1>
      <h4 *ngIf="!useH1" tabindex="0">Location and language</h4>
    </div>
    <div class="flex flex--space-between flex--align-start">
      <fcom-icon
        *ngIf="!useH1"
        class="icon-medium ms-xsmall-r flex--self-center"
        [name]="SvgLibraryIcon.LANGUAGE"
      ></fcom-icon>
      <fcom-icon-button
        *ngIf="showCloseButton"
        [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.CLOSE_DELETE }"
        [theme]="IconButtonTheme.LIGHT_TERTIARY_GHOST"
        [size]="IconButtonSize.MEDIUM"
        [aria]="{ label: 'Close' }"
        (clickBtn)="emitClose()"
        data-testid="country-selector-close-button"
      >
      </fcom-icon-button>
    </div>
  </div>
  <div class="relative" [ngClass]="{ 'show-flag': showFlag }">
    <span
      *ngIf="showFlag"
      class="flag flag-{{ formGroup.controls.country.value | lowercase }} absolute ms-small-l z1"
    ></span>
    <fin-combo-box
      [id]="'select-country'"
      [label]="'Location'"
      [parentForm]="formGroup"
      [controlName]="'country'"
      [translationPrefix]="'countryLanguageSelectorEnOnly'"
      [options]="countryOptions"
      [searchable]="true"
      [searchLabel]="'Select or start typing'"
      [clearSearchLabel]="'Clear search'"
      [optionTemplate]="countryOptionTemplate"
      [infoText]="
        (showCountryShopWarning$ | async) ? 'Prices on this site are in the currency of the country of departure.' : ''
      "
      (dropDownOpen)="updateShowFlag($event)"
    ></fin-combo-box>
  </div>

  <div>
    <fin-combo-box
      [id]="'select-language'"
      [label]="'Language'"
      [selectLabelTranslationKey]="'countryLanguageSelectorEnOnly.selectLabel'"
      [parentForm]="formGroup"
      [controlName]="'language'"
      [translationPrefix]="'countryLanguageSelectorEnOnly'"
      [options]="languageOptions$ | async"
      [selectLabel]="'Select'"
      [disableSelectOption]="(languageOptions$ | async) === undefined"
    ></fin-combo-box>
  </div>

  <fcom-notification *ngIf="showWarning" [theme]="NotificationTheme.WARNING" class="block ms-medium-b">
    Changing these preferences will reload the page, and the input information will be lost.
  </fcom-notification>

  <div>
    <fcom-button
      [disabled]="!formGroup.valid"
      [mode]="ButtonMode.FILL"
      (clickBtn)="apply()"
      [text]="'Apply preferences'"
      data-testid="apply-button"
    ></fcom-button>
  </div>
</div>

<ng-template #countryOptionTemplate let-name="name" let-value="value">
  <span class="flex ps-small fill"
    ><span class="flag flag-{{ value | lowercase }} mr-xsmall-r"></span><span>{{ name }}</span></span
  >
</ng-template>
