import { isPlatformServer } from '@angular/common';

import { routerReducer } from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap, State, UPDATE, MetaReducer } from '@ngrx/store';

import { MANAGE_BOOKING_FEATURE_KEY } from '@fcom/manage-booking/constants';
import { COMMON_FEATURE_KEY } from '@fcom/common/store';
import { COMMON_BOOKING_FEATURE_KEY } from '@fcom/common-booking/store';
import { DESTINATION_SEARCH_FILTER_STATE_FEATURE_NAME } from '@fcom/destination-search/interfaces';
import { VOLUNTARY_CHANGE_FEATURE_KEY } from '@fcom/voluntary-change/constants';
import { TIMETABLE_FEATURE_KEY } from '@fcom/timetable/constants';
import { REFUNDS_FEATURE_KEY } from '@fcom/refunds-common/constants';
import { UPGRADE_FEATURE_KEY } from '@fcom/one-upgrade/constants';
import { ALTERNATIVE_OFFERS_STATE_FEATURE_NAME } from '@fcom/reaccommodation/interfaces';
import { LOYALTY_FEATURE_KEY } from '@fcom/loyalty-core';

import { AppState } from '../interfaces';
import { SentryLogger, StorageService, StorageType } from '../services';
import { initStoreAction, updateStoreAction } from '../store/init-store.action';
import { KEYS_TO_LOCAL_STORAGE } from '../store/storage-versions';
import { StorageKey, storageSync } from '../utils';
import { languageReducer } from './language.reducer';
import { loginReducer } from './login.reducer';
import * as fromNavigator from './navigator.reducer';
import * as fromPseudoLocal from './pseudo-local.reducer';

export const reducers: ActionReducerMap<AppState> = {
  language: languageReducer,
  login: loginReducer,
  navigator: fromNavigator.reducer,
  pseudoLocal: fromPseudoLocal.reducer,
  router: routerReducer,
};

/**
 * @param keys
 * @param type either SESSION or LOCAL
 * @param allowStorageRehydration
 * @param storageService
 */
export function storageSyncInBrowser(
  type: StorageType,
  keys: StorageKey[],
  allowStorageRehydration: boolean,
  storageService: StorageService,
  platformID: object,
  sentryLogger: SentryLogger
) {
  return (reducer: ActionReducer<AppState>): ActionReducer<AppState> => {
    if (isPlatformServer(platformID)) {
      return reducer;
    } else {
      const storage = storageService[type];
      return storageSync({
        keys,
        rehydrate: allowStorageRehydration, // Disable state re-hydration in karma specs
        storage,
        sentryLogger,
      })(reducer);
    }
  };
}

export function dispatchInitialStateReducer(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return (state: AppState, action: Action) => {
    switch (action.type) {
      case State.INIT:
        return reducer(state, initStoreAction({ payload: state }));
      case UPDATE:
        return reducer(state, updateStoreAction({ payload: state, features: action?.['features'] || [] }));
      default:
        return reducer(state, action);
    }
  };
}

export function localStorageSyncMetaReducer(
  allowStorageRehydration: boolean,
  storageService: StorageService,
  platformID: object,
  sentryLogger: SentryLogger
): MetaReducer<AppState> {
  return storageSyncInBrowser(
    'LOCAL',
    // To enforce type-safety, we only allow storing full object trees to local storage
    KEYS_TO_LOCAL_STORAGE,
    allowStorageRehydration,
    storageService,
    platformID,
    sentryLogger
  );
}

export function sessionStorageSyncMetaReducer(
  allowStorageRehydration: boolean,
  storageService: StorageService,
  platformID: object,
  sentryLogger: SentryLogger
): MetaReducer<AppState> {
  return storageSyncInBrowser(
    'SESSION',
    [
      { name: 'booking' },
      { name: 'priceCalendar' },
      { name: 'locations' },
      { name: 'navigator', keys: ['browserGeolocation', 'akamaiGeolocation'] },
      { name: 'cart' },
      { name: 'payment' },
      { name: ALTERNATIVE_OFFERS_STATE_FEATURE_NAME },
      { name: UPGRADE_FEATURE_KEY },
      { name: 'offers', keys: ['sortBy'] },
      { name: 'order' },
      { name: 'surcharges' },
      { name: 'paxDetails' },
      { name: LOYALTY_FEATURE_KEY },
      { name: MANAGE_BOOKING_FEATURE_KEY },
      { name: COMMON_FEATURE_KEY },
      { name: DESTINATION_SEARCH_FILTER_STATE_FEATURE_NAME },
      { name: COMMON_BOOKING_FEATURE_KEY },
      { name: VOLUNTARY_CHANGE_FEATURE_KEY },
      { name: TIMETABLE_FEATURE_KEY, keys: ['search'] },
      { name: REFUNDS_FEATURE_KEY },
    ],
    allowStorageRehydration,
    storageService,
    platformID,
    sentryLogger
  );
}
