<div class="mr-large-t">
  <div *ngIf="informationalTeaser.teaserTitle !== ''" class="bold-type nordic-blue-900-text font-body-1 ms-large-b">
    {{ informationalTeaser.teaserTitle }}
  </div>
  <div
    *ngIf="informationalTeaser.teaserTitle !== ''"
    class="ms-medium-b"
    [innerHTML]="informationalTeaser.teaserText | finSafe: 'html'"
  ></div>
  <div
    class="grid-col-12 no-gutters ms-large-b informational-images {{
      informationalTeaser.items.length === 1 ? 'informational-images--single' : 'informational-images--multiple'
    }}"
    *ngIf="informationalTeaser.items.length > 0"
  >
    <fin-content-image
      *ngFor="let contentImage of informationalTeaser.items"
      class="block image-height informational-image shadow col-1-12 ms-small-b"
      [imageData$]="contentImage | finToObservable"
      [aspectRatio$]="(informationalTeaser.items.length === 1 ? '21x9' : '1x1') | finToObservable"
    ></fin-content-image>
  </div>
  <div *ngIf="informationalTeaser.localSettings.extraText" class="font-body-1 ms-medium-t">
    {{ informationalTeaser.localSettings.extraText }}
  </div>
</div>
