import {
  Directive,
  Input,
  HostListener,
  ComponentFactoryResolver,
  ElementRef,
  Injector,
  ComponentRef,
  ApplicationRef,
  EmbeddedViewRef,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
  TemplateRef,
  Renderer2,
  AfterViewInit,
  Inject,
  PLATFORM_ID,
  OnChanges,
} from '@angular/core';
import { isPlatformServer } from '@angular/common';

import { fromEvent, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { stopPropagation, unsubscribe } from '@fcom/core/utils';

import { PopoverComponent } from '../component/popover.component';
import { PopoverData, PopoverOptions } from '../popover.interface';
import { PopoverService } from '../service/popover.service';

@Directive({
  selector: '[fcomPopover]',
})
export class PopoverDirective implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  private cid = uuid();
  private _options: PopoverOptions;

  @Input()
  set options(value: PopoverOptions) {
    this._options = value;
    if (value && value.popoverID) {
      this.cid = value.popoverID;
    }
  }

  get options(): PopoverOptions {
    return {
      ...this._options,
      popoverID: this.cid,
    };
  }

  @Input()
  content: TemplateRef<ElementRef>;

  @Input()
  closeText: string;

  @Input()
  ariaCloseText: string;

  @Output()
  closeClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  popoverClosed: EventEmitter<void> = new EventEmitter();

  private componentRef: ComponentRef<any>;
  private isPopoverDestroyed = true;
  private onContent = false;
  private subscriptions = new Subscription();

  @HostListener('click', ['$event'])
  onClick(clickEvent: Event): void {
    if (this.options && this.options.enableOnClick) {
      if (this.options.enableStopPropagation) {
        stopPropagation(clickEvent);
      }
      this.togglePopover();
    }
  }

  @HostListener('focusin')
  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.options && this.options.enableOnHover && this.isPopoverDestroyed) {
      this.createPopover();
    }
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  onMouseLeave(): void {
    if (this.options && this.options.enableOnHover && !this.isPopoverDestroyed) {
      setTimeout(() => {
        if (!this.onContent) {
          this.destroyPopover();
        }
      }, 10);
    }
  }

  @HostListener('document:keydown.escape')
  onKeydownHandler(): void {
    this.tryDestroyPopover(true);
  }

  // Prevents triggering behaviour of focused element in the popover when pressing enter
  @HostListener('keydown.enter', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    if (this.options && this.options.enableOnClick) {
      event.preventDefault();
      this.createPopover({ focusReady: false });
    }
  }

  @HostListener('keyup.enter', ['$event'])
  onKeyup(): void {
    if (this.isPopoverDestroyed === false) {
      this.componentRef.instance.focusReady$.next(true);
    }
  }

  constructor(
    private readonly triggerRef: ElementRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly injector: Injector,
    private readonly appRef: ApplicationRef,
    private readonly renderer: Renderer2,
    private readonly popoverService: PopoverService,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  ngOnDestroy(): void {
    this.destroyPopover();
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  ngOnInit(): void {
    if (!this.options || (!this.options.enableOnClick && !this.options.enableOnHover && !this.options.openByDefault)) {
      this.options = Object.assign({}, this.options, { enableOnClick: true });
    }
    if (this.options && this.options.enableOnClick) {
      this.renderer.setAttribute(this.triggerRef.nativeElement, 'aria-expanded', 'false');
      this.renderer.setAttribute(this.triggerRef.nativeElement, 'aria-controls', this.cid);
    } else if (this.options && this.options.enableOnHover) {
      this.renderer.setAttribute(this.triggerRef.nativeElement, 'aria-describedby', this.cid);
    }
  }

  ngAfterViewInit(): void {
    if (this.options.openByDefault) {
      this.createPopover();
    }
  }

  updatePopoverData(): void {
    if (!this.componentRef?.instance) {
      return;
    }

    this.componentRef.instance.popoverData = {
      content: this.content,
      closeText: this.closeText,
      ariaCloseText: this.ariaCloseText,
    } as PopoverData;

    this.componentRef.instance.popoverOptions = this.options;
    this.subscriptions.add(
      this.componentRef.instance.closeClick.pipe(take(1)).subscribe((closeClick: boolean) => {
        this.closeClick.emit(closeClick);
        this.destroyPopover(true);
      })
    );
  }

  private appendComponentToElement(component: any, focusReady: boolean): void {
    if (this.isPopoverDestroyed === false) {
      return;
    }

    this.componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);
    this.componentRef.instance.mouseOnContent.subscribe((onContent: boolean) => {
      this.onContent = onContent;
    });

    this.componentRef.instance.focusReady$?.next(focusReady);
    this.componentRef.instance.triggerRef = this.triggerRef;
    this.updatePopoverData();
    this.appRef.attachView(this.componentRef.hostView);
    const domElem = (this.componentRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;

    if (this.options && this.options.enableOnHover) {
      domElem.setAttribute('aria-hidden', 'true');
    }
    this.renderer.appendChild(document.body, domElem);
  }

  ngOnChanges(): void {
    this.updatePopoverData();

    if (this.componentRef) {
      this.componentRef.instance?.changeDetectorRef?.detectChanges();
    }
  }

  createPopover({ focusReady } = { focusReady: true }): void {
    this.popoverService.open(this);
    if (this.options && this.options.enableOnClick) {
      this.renderer.setAttribute(this.triggerRef.nativeElement, 'aria-expanded', 'true');
      this.triggerRef.nativeElement.classList.add('popover-open');
    }
    this.appendComponentToElement(PopoverComponent, focusReady);
    this.addScrollListener();
    this.isPopoverDestroyed = false;
  }

  addScrollListener(): void {
    if (this.options.closeOnScroll && !isPlatformServer(this.platform)) {
      this.subscriptions.add(
        fromEvent(window, 'scroll')
          .pipe(
            filter(() => {
              return !this.isPopoverDestroyed;
            }),
            take(1)
          )
          .subscribe(() => this.tryDestroyPopover())
      );
    }
  }

  togglePopover(): void {
    if (!this.componentRef || this.isPopoverDestroyed) {
      this.createPopover();
    } else if (!this.isPopoverDestroyed) {
      this.destroyPopover(true);
    }
  }

  private tryDestroyPopover(shouldFocusOnHost = false): void {
    if (!this.isPopoverDestroyed) {
      this.destroyPopover(shouldFocusOnHost);
    }
  }

  destroyPopover(shouldFocusOnHost = false): void {
    if (this.isPopoverDestroyed) {
      return;
    }

    if (this.options && this.options.enableOnClick) {
      if (shouldFocusOnHost) {
        this.triggerRef.nativeElement.focus();
      }
      this.renderer.setAttribute(this.triggerRef.nativeElement, 'aria-expanded', 'false');
      this.triggerRef.nativeElement.classList.remove('popover-open');
    }

    const domElem = (this.componentRef.hostView as EmbeddedViewRef<unknown>).rootNodes[0] as HTMLElement;
    if (!document.body.contains(domElem)) {
      return;
    }
    document.body.removeChild(domElem);

    this.appRef.detachView(this.componentRef.hostView);
    this.componentRef.destroy();
    this.popoverService.close();
    this.popoverClosed.emit();
    this.isPopoverDestroyed = true;
  }
}
