import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter, map, take } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { ElementActions, ElementTypes, GaContext, ToasterService } from '@fcom/common';
import { GtmService } from '@fcom/common/gtm';
import { SentryLogger } from '@fcom/core';
import { NotificationTheme } from '@fcom/ui-components';
import { LanguageService } from '@fcom/ui-translate';
import { unsubscribe } from '@fcom/core/utils';

import { ISeatMapVr, LayoutVariant, SceneVariant } from './interfaces';

declare let SeatMapVr: ISeatMapVr;

@Injectable({
  providedIn: 'root',
})
export class Cms3DSeatMapService implements OnDestroy {
  private containerId = 'seat-map-3d-view';
  /**
   * When script or style files are updated, new integrity hash needs to be calculated:
   * ´shasum -b -a 384 SeatMapVR3D.min.js | awk '{ print $1 }' | xxd -r -p | base64´
   * @private
   */
  private script = {
    id: '3d-seat-map-script',
    integrity: 'sha384-315sOhCRQO35BWpIbQthdje4pJnp5033TmUXFKzLch5NLVWWaFg7hIWsI462M/9P',
    url: 'https://finnair.3dseatmapvr.com/SeatMapVR3D/4.1.0/SeatMapVR3D.min.js',
  };
  private style = {
    id: '3d-seat-map-styles',
    integrity: 'sha384-bgQWp22XNBkuUDudFsd+vRbmZUAuWUAXiNbgAZYfTQaVxB6kYKWqP1vDFpgQR/mz',
    url: 'https://finnair.3dseatmapvr.com/SeatMapVR3D/4.1.0/SeatMapVR3D.min.css',
  };

  subscriptions = new Subscription();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platform: object,
    private translate: LanguageService,
    private toaster: ToasterService,
    private router: Router,
    private location: Location,
    private sentryLogger: SentryLogger,
    private gtmService: GtmService
  ) {
    this.subscriptions.add(
      this.router.events
        .pipe(
          filter(() => isPlatformBrowser(this.platform)),
          filter((e) => e instanceof NavigationEnd),
          take(1),
          filter((e: NavigationEnd) => e.url.includes('#3dseatmap')),
          map((e) => e.url)
        )
        .subscribe((url) => {
          const parts = url.split('/');
          const cabinClass = parts.pop();
          const fleet = parts.pop();
          this.open3dSeatMap(fleet, cabinClass);
          this.location.go(url.split('#')[0]); // Remove the hash part from the URL
        })
    );
  }

  public async open3dSeatMap(fleet: string, cabinClass: string): Promise<void> {
    await this.setup3dSeatMap();
    this.handleOpen(fleet, cabinClass);
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  private handleOpen(fleet: string, cabinClass: string) {
    if (!fleet || !LayoutVariant[fleet] || !cabinClass || !SceneVariant[cabinClass]) {
      this.sentryLogger.error('Invalid 3D Seat Map link', { fleet, cabinClass });
      this.displayToasterError();
      return;
    }
    SeatMapVr.selectScene({
      layout: LayoutVariant[fleet],
      scene: SceneVariant[cabinClass],
      fullScreen: true,
      mode: 0,
    });
  }

  private async setup3dSeatMap() {
    if (typeof SeatMapVr === 'undefined' || !SeatMapVr.isInitialized()) {
      await this.injectFiles();
      this.addContainer();
      await this.initConfig();
    }
  }

  private initConfig(): Promise<void> {
    const getGtmAnalyticFunction = (analyticName: string) => () => this.gtmAnalytic(analyticName);
    return new Promise((resolve) => {
      this.translate
        .translate('3dseatmap')
        .pipe(take(1))
        .subscribe((i18n) => {
          SeatMapVr.config({
            resource_cdn: 'https://finnair.3dseatmapvr.com',
            ambientSound: 'https://finnair.3dseatmapvr.com/SeatMapVR3D/bgsound.mp3',
            destinationSelector: `#${this.containerId}`,
            onUserChangeScene: getGtmAnalyticFunction('scene-changed'),
            onUserCloseViewer: getGtmAnalyticFunction('viewer-closed'),
            onUserMove360: getGtmAnalyticFunction('360-moved'),
            onUserOpenInfo: getGtmAnalyticFunction('info-opened'),
            i18n,
          });
          SeatMapVr.init();
          resolve();
        });
    });
  }

  private gtmAnalytic(analyticName: string) {
    return this.gtmService.trackElement(
      `3d-seatmap-${analyticName}`,
      GaContext.CONTENT,
      ElementTypes.BUTTON,
      undefined,
      ElementActions.CLICK
    );
  }

  private async injectFiles() {
    return Promise.all([this.injectScript(), this.injectStyleSheet()]).catch(() => {
      this.displayToasterError();
    });
  }

  private injectScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.document.getElementById(this.script.id)) {
        return resolve();
      }
      const script = this.document.createElement('script');
      script.id = this.script.id;
      script.type = 'text/javascript';
      script.src = this.script.url;
      script.integrity = this.script.integrity;
      script.crossOrigin = 'anonymous';
      script.onload = () => {
        resolve();
      };
      script.onerror = () => reject();
      this.document.body.appendChild(script);
    });
  }

  private injectStyleSheet(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.document.getElementById(this.style.id)) {
        return resolve();
      }
      const link = this.document.createElement('link');
      link.rel = 'stylesheet';
      link.id = this.style.id;
      link.href = this.style.url;
      link.integrity = this.style.integrity;
      link.crossOrigin = 'anonymous';
      link.onload = () => {
        resolve();
      };
      link.onerror = () => reject();
      this.document.body.appendChild(link);
    });
  }

  private addContainer() {
    if (this.document.getElementById(this.containerId)) {
      return;
    }
    const container = this.document.createElement('div');
    container.id = this.containerId;
    this.document.body.appendChild(container);
  }

  private displayToasterError() {
    this.toaster.addMessageToQueue({
      id: 'INVALID_3D_SEAT_MAP',
      contentKey: 'errors.generic.title', // Something went wrong
      theme: NotificationTheme.ALERT,
      autoClose: true,
    });
  }
}
