import { createAction, props, union } from '@ngrx/store';

import { SeatMapForFlight } from '@fcom/common/interfaces/seat-map.interface';
import { FinnairCart, FinnairOrder, SeatInfo } from '@fcom/dapi/api/models';

export const startLoading = createAction('[SeatMapActions] LoadStart', props<{ flightId: string }>());
export const setNoAvailability = createAction('[SeatMapActions] SetNoAvailability', props<{ flightId: string }>());
export const error = createAction('[SeatMapActions] LoadError', props<{ flightId: string }>());
export const reset = createAction('[SeatMapActions] Reset');
export const clearSelections = createAction('[SeatMapActions] ClearSelections');
export const resetSeatSelections = createAction(
  '[SeatMapActions] ResetSeatSelections',
  props<{ cartOrOrder: FinnairCart | FinnairOrder }>()
);
export const setSeatForFlight = createAction(
  '[SeatMapActions] SetSeatSelection',
  props<{ flightId: string; travelerId: string; seat: SeatInfo }>()
);
export const setSeatMap = createAction('[SeatMapActions] SetSeatMap', props<{ seatMapForFlight: SeatMapForFlight }>());
export const loadSeatSelectionsFromCart = createAction(
  '[SeatMapActions] LoadSeatSelectionsFromCart',
  props<{ cartData: FinnairCart }>()
);

const all = union({
  startLoading,
  setNoAvailability,
  error,
  reset,
  clearSelections,
  resetSeatSelections,
  setSeatForFlight,
  setSeatMap,
  loadSeatSelectionsFromCart,
});

export type ActionsUnion = typeof all;
