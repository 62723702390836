import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { isNotBlank } from '@fcom/core/utils';
import { CmsTeaser, CmsImageData, CmsMenuFoodItem } from '@fcom/core-api';
import { finShare, safeMap } from '@fcom/rx';
import { toKebabCase } from '@fcom/ui-components';

const DEFAULT_HEADER_LEVEL = 2;

@Component({
  selector: 'cms-teaser',
  templateUrl: './cms-teaser.component.html',
  styleUrls: ['./cms-teaser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsTeaserComponent implements OnInit {
  @Input()
  content$: Observable<CmsTeaser | CmsMenuFoodItem>;
  @Input()
  showIcon = false;
  @Input()
  headerLevel: number = DEFAULT_HEADER_LEVEL;

  title$: Observable<string>;
  text$: Observable<string>;
  icon$: Observable<string>;
  ingredients$: Observable<string>;
  image$: Observable<CmsImageData>;
  layoutVariant$: Observable<string>;
  sectionTestId$: Observable<string> | null = null;
  subjectTaxonomyTags$: Observable<string[]>;

  ngOnInit(): void {
    const shared$ = this.content$.pipe(finShare());
    this.sectionTestId$ = shared$.pipe(
      safeMap((t: CmsTeaser): string | null => {
        const sectionId = toKebabCase(t.teaserTitle);
        return sectionId ? `section-${sectionId}` : null;
      }),
      filter(isNotBlank)
    );
    this.title$ = shared$.pipe(
      safeMap((t: CmsTeaser) => t.teaserTitle),
      filter(isNotBlank)
    );
    this.text$ = shared$.pipe(safeMap((t: CmsTeaser) => t.teaserText));
    this.icon$ = shared$.pipe(
      safeMap((t: CmsTeaser) => t.iconUrl),
      filter(isNotBlank),
      filter(() => this.showIcon)
    );
    this.image$ = shared$.pipe(safeMap((t: CmsTeaser) => t.picture));
    this.subjectTaxonomyTags$ = shared$.pipe(safeMap((t: CmsTeaser) => t.subjectTaxonomyTags));
    this.layoutVariant$ = shared$.pipe(
      safeMap((t: CmsTeaser) => (t.viewTypeName || 'section-two-thirds-right').split('--')[0])
    ); // @todo: Split can be removed once the layoutvariant split into tags happens in core media. Also still need to fallback this in case of 'null'

    /**
     * @todo: Meal info shown as teaser is first additional content type here.
     * If more types are starting to clutter this component with specific fields,
     * refactor them all into own components.
     */
    this.ingredients$ = shared$.pipe(safeMap((t: CmsMenuFoodItem) => t.ingredients));
  }
}
