<div
  class="switch"
  [class.selected]="status === ToggleSwitchStatus.CHECKED"
  (click)="handleStatusChange($event, name, status)"
>
  <input
    type="checkbox"
    [name]="name"
    [checked]="status === ToggleSwitchStatus.ACTIVE || status === ToggleSwitchStatus.CHECKED"
    (click)="handleStatusChange($event, name, status)"
    [disabled]="disabled"
    [attr.aria-labelledby]="labelledBy"
  />
  <span [ngClass]="{ disabled: disabled }" class="slider round"></span>
</div>
