import { Inject, Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Subscription } from 'rxjs';

import { unsubscribe } from '@fcom/core/utils/unsubscribe';

type SymbolDefs = [SVGSymbolElement, SVGDefsElement];

@Injectable({
  providedIn: 'root',
})
export abstract class IconsService implements OnDestroy {
  private globalSVG: SVGElement = null;
  private globalDefs: SVGDefsElement = null;

  private readonly rendered: Set<string> = new Set();

  protected subscriptions: Subscription;
  protected readonly _renderer: Renderer2;

  abstract addIcon(iconRef: string, url: string): Promise<void>;

  constructor(
    @Inject(DOCUMENT) protected readonly document: Document,
    rendererFactory: RendererFactory2
  ) {
    this._renderer = rendererFactory.createRenderer(null, null);
    this.createGlobalSVG();
    this.subscriptions = new Subscription();
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  protected alreadyRendered(id: string): boolean {
    if (
      !this.globalSVG ||
      this.rendered.has(id) ||
      Array.from(this.globalSVG.childNodes).find((child) => (child as SVGSymbolElement | SVGDefsElement).id === id)
    ) {
      this.rendered.add(id);
      return true;
    }
    return false;
  }

  protected addToGlobal(iconRef: string, symbol: SVGSymbolElement, def: SVGDefsElement): void {
    if (this.alreadyRendered(iconRef)) {
      return;
    }

    this.appendSymbol(symbol);
    this.appendDefs(def);
    this.rendered.add(iconRef);
  }

  protected _svgSymbolElementFromString(str: string): SymbolDefs | never {
    /**
     * need to create a div element and embed a svg containing the symbol
     * as setting innerHTML of the svg element is not implemented in universal
     */
    const symbolDiv = this._renderer.createElement('DIV');
    const defsDiv = this._renderer.createElement('DIV'); // separate div required because IE loses children if you replace the innerHTML
    defsDiv.innerHTML = str;
    const defs: SVGDefsElement = defsDiv.querySelector('defs');

    symbolDiv.innerHTML = `<svg>${str.replace('<svg', '<symbol').replace(/<defs>.*<\/defs>/, '')}</svg>`;

    const symbol = symbolDiv.querySelector('symbol') as SVGSymbolElement;
    if (!symbol) {
      throw new Error('No SVG Symbol found in loaded contents');
    }

    return [symbol, defs];
  }

  private createGlobalSVG(): void {
    if (!this.document || this.globalSVG) {
      return;
    }

    this.globalSVG = this.document.querySelector('svg#globalsvg');
    this.globalDefs = this.document.querySelector('svg#globalsvg defs');
    if (!this.globalSVG) {
      this.globalSVG = this._renderer.createElement('svg', 'svg');
      this.globalSVG.id = 'globalsvg';
      this.globalSVG.setAttribute('width', '0');
      this.globalSVG.setAttribute('height', '0');
      this.globalSVG.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
      this.globalSVG.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');

      this.globalDefs = this._renderer.createElement('defs', 'svg');
      this._renderer.appendChild(this.globalSVG, this.globalDefs);
      this._renderer.appendChild(this.document.body, this.globalSVG);
    }
  }

  private appendSymbol(symbol: SVGSymbolElement): void {
    if (this.globalSVG) {
      this._renderer.appendChild(this.globalSVG, symbol);
    }
  }

  private appendDefs(def: SVGDefsElement): void {
    if (def && this.globalDefs) {
      Array.from(def.childNodes).forEach((node) => {
        this._renderer.appendChild(this.globalDefs, node);
      });
    }
  }
}
