import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { CmsDestination } from '@fcom/core-api';
import { unsubscribe } from '@fcom/core/utils';
import { OndPageType, OndPairEnhancedResponse } from '@fcom/ond-marketing-landing-core/interfaces';
import { OndMarketingOffersService } from '@fcom/ond-marketing-landing-core/services';
import { getCurrentCountry } from '@fcom/ond-marketing-landing-core/services/utils';

@Component({
  selector: 'cms-ond-marketing-cross-linking',
  template: `
    <fin-ond-marketing-cross-linking
      class="section-two-thirds-right col-1-12 md-col-1-9 ms-large-b"
      [route]="route.url | async"
      [links$]="links$"
      [ondPageType]="ondPageType"
    >
    </fin-ond-marketing-cross-linking>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsOndMarketingCrossLinkingComponent implements OnInit, OnDestroy {
  @Input()
  content$: Observable<CmsDestination>;
  links$: Observable<OndPairEnhancedResponse>;
  ondPageType: OndPageType = OndPageType.LANDING;
  private subscriptions: Subscription = new Subscription();

  constructor(
    readonly route: ActivatedRoute,
    private ondMarketingOffersService: OndMarketingOffersService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.links$ = this.ondMarketingOffersService.getDestinationLocationCode(this.content$).pipe(
      switchMap((destinationLocationCode) =>
        this.ondMarketingOffersService.fetchLocationPairs({
          destination: destinationLocationCode,
          originCountry: getCurrentCountry(this.languageService.langValue),
          lang: this.languageService.langValue,
        })
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions = unsubscribe(this.subscriptions);
  }
}
