import { NgModuleRef } from '@angular/core';

import { TimeAndGeoipProvider, GeolocationService } from '@fcom/core/services';
import { captureException, initSentry } from '@fcom/core/services/sentry/sentry.module';
import { parseGeolocationFromUrlHash } from '@fcom/dapi/utils';

import { ClientModule } from './app/client.module';
import { environment } from '../../environments/environment';

initSentry();

export { captureException };

export const preInit = (module: NgModuleRef<ClientModule>) => {
  const geoIpProvider: TimeAndGeoipProvider = module.injector.get(TimeAndGeoipProvider);
  const geolocationService: GeolocationService = module.injector.get(GeolocationService);

  geoIpProvider.triggerTimeAndGeoipFetch();
  geolocationService.init(parseGeolocationFromUrlHash());
};

export const init = (): Function => {
  if (!environment.storageRoot) {
    const match = /storageRoot=(\w+)/.exec(window.location.search);
    window['storageRoot'] = match?.[1] || '';
  }

  return () => {};
};
