export enum Airline {
  FINNAIR = 'AY',
  AMERICAN_AIRLINES = 'AA',
  BRAATHENS_REGIONAL_AIRLINES = 'TF',
  BRITHIS_AIRWAYS = 'BA',
  CATHAY_PACIFIC = 'CX',
  DRAGONAIR = 'KA',
  IBERIA = 'IB',
  JAPAN_AIRLINES = 'JL',
  JUNEYAO_AIRLINES = 'HO',
  LAN_AIRLINES = 'LA',
  MALAYSIAN = 'MH',
  QANTAS = 'QF',
  QATAR_AIRWAYS = 'QR',
  ROYAL_JORDANIAN = 'RJ',
  S7_AIRLINES = 'S7',
  SRI_LANKAN = 'UL',
  TAM_AIRLINES = 'JJ',
  US_AIRWAYS = 'US',
}
