import { Injectable } from '@angular/core';

import { map, first } from 'rxjs/operators';
import { Observable, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';

import { DateRange } from '@fcom/ui-components';
import { LanguageService } from '@fcom/ui-translate';
import { DateFormat, DateFormatInput, LocalDate, Pattern, TzDate } from '@fcom/core/utils';
import { formatTime } from '@fcom/common';
import { getCityName } from '@fcom/common/utils/booking.utils';
import { FinnairBoundItem, FinnairOrder } from '@fcom/dapi/api/models';
import { Airport } from '@fcom/common/interfaces/booking';

import { CommonBookingFeatureState, orderData } from '../store';

/**
 * Copied from finDateFormat
 */
const getPattern = (input: string) => DateFormat[input] || new Pattern(input);

@Injectable()
export class UtilsService {
  constructor(
    private languageService: LanguageService,
    private store$: Store<CommonBookingFeatureState>
  ) {}
  private currentDate: LocalDate = LocalDate.now();
  /**
   * Copied and modified from finDateFormat
   */
  getDate(input: DateFormatInput, pattern: string): Observable<string> {
    return this.languageService.translate('date').pipe(
      map((translations: any) => {
        return new DateFormat(translations).format(input, getPattern(pattern));
      })
    );
  }

  getLocation(locationCode: string, dateTime: string): Observable<Airport> {
    return combineLatest([
      this.store$.pipe(
        orderData(),
        map((order) => order?.locations)
      ),
      this.getDate(dateTime, 'WEEKDAY'),
    ]).pipe(
      first(),
      map(([locations, day]) => ({
        dateTime: `${formatTime(dateTime)} ${day}`,
        city: getCityName(locationCode, locations),
        airportCode: locationCode,
        airport: locations[locationCode].name,
      }))
    );
  }
  getDisabledDateRange(selectedBoundsForChange: Array<FinnairBoundItem>, booking: FinnairOrder): DateRange {
    if (booking?.bounds?.length === 1 || selectedBoundsForChange?.length === 2 || !selectedBoundsForChange[0]) {
      return [];
    }
    const selectedBound = selectedBoundsForChange[0];
    const selectedBoundDeparture = new TzDate(selectedBound.departure.dateTime).toLocalDate();
    const otherBound = booking?.bounds?.find((bound) => bound.id !== selectedBound.id);
    const otherBoundDeparture = new TzDate(otherBound?.departure?.dateTime).toLocalDate();

    if (selectedBoundDeparture.lte(otherBoundDeparture)) {
      return [otherBoundDeparture, this.currentDate.plusDays(361)];
    }
    return [this.currentDate, new TzDate(otherBound.arrival.dateTime).toLocalDate()];
  }
}
