<ng-container>
  <div class="book-flights-cta" *ngIf="flightsPageRedirectionUrl$ | async as flightsPageUrl">
    <a id="bookNowButton" class="btn bold-type no-underline" [finLink]="flightsPageUrl" [external]="true"
      >{{ 'bookNow' | finLocalization }}
    </a>
  </div>

  <div class="mr-xxsmall-t section col-1-12 md-col-2-11">
    <smt-gcovwidget
      #smartvelWidget
      [attr.apikey]="smartvelApiKey"
      lang="en"
      [attr.roundtrip]="(oneway$ | async) !== true"
      [attr.destination]="destination$ | async"
      [attr.origin]="origin$ | async"
      (finMutationObserver)="onWidgetChange($event)"
      mutationObserverInitConfigKey="travelRestrictions"
    ></smt-gcovwidget>
  </div>
</ng-container>
