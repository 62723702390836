<ng-container *ngIf="content$ | async as content">
  <div
    *ngIf="itemsObs$ | async as items"
    [ngClass]="{ 'pr-small-t': !content.subjectTaxonomyTags.includes(TAXONOMY.NO_PADDING) }"
    class="mr-xlarge-b"
    [class.section-marker]="!content.subjectTaxonomyTags.includes(TAXONOMY.NO_SECTION_MARKER)"
  >
    <h2
      [ngClass]="{
        'pr-medium-t': !content.subjectTaxonomyTags.includes(TAXONOMY.NO_PADDING),
        'align-center': content.subjectTaxonomyTags.includes(TAXONOMY.CENTERED),
      }"
      *ngIf="content.teaserTitle as title"
    >
      {{ title }}
    </h2>
    <div class="pr-medium-b" *ngIf="content.teaserText" [innerHTML]="content.teaserText | finSafe: 'html'"></div>

    <div class="discover-now-area flex">
      <div *ngFor="let item of items" class="discover-now-item flex-1 flex--basis0 pr-medium-r">
        <!-- FIXME: add icons once CMS return them -->
        <fin-content-image
          *ngIf="item?.media?.length"
          class="block mr-medium-b"
          [imageData$]="item.media[0] | finToObservable"
        ></fin-content-image>
        <h3 class="nordic-blue-900-text">{{ item.teaserTitle }}</h3>
        <div [innerHTML]="item.teaserText | finSafe: 'html'" class="discover-now-teaser-text"></div>
        <a
          *ngFor="let related of item.related"
          [finLink]="related?.target?.url"
          [external]="related?.target?.external"
          [attr.target]="related?.target?.openInNewTab ? '_blank' : null"
          class="flex flex--middle nordic-blue-900-text bold-type no-underline pr-xxsmall-t font-body-1 underline-hover"
        >
          <span class="title">{{ related?.teaserTitle }}</span>
          <span class="cta">
            <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.ARROW_RIGHT"></fcom-icon>
          </span>
        </a>
      </div>
    </div>
  </div>
</ng-container>
