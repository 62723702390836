import { Injectable } from '@angular/core';

import { Observable, EMPTY } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { Amount } from '@fcom/dapi';

import { isPresent, isTruthy } from '../../utils';
import { Abbreviation, CustomAbbreviationSettings, PriceSettings } from '../../interfaces/currency.interface';
import {
  ABBR_SETTINGS,
  DEFAULT_ABBR_SETTINGS,
  DEFAULT_SETTINGS,
  MAX_ABBR_DIGITS,
  ROUNDER,
  currencySettings,
} from './currency-settings';

@Injectable()
export class CurrencyService {
  constructor(private languageService: LanguageService) {}

  formatPrice(price: Amount, settings?: PriceSettings): Observable<string> {
    if (!isTruthy(price) || !isTruthy(price.amount) || !isTruthy(price.currencyCode)) {
      return EMPTY;
    }
    const mergedSettings = Object.assign({}, DEFAULT_SETTINGS, currencySettings[price.currencyCode], settings);
    // eslint-disable-next-line no-sparse-arrays
    const [, plusSign] = price.amount.match(/(^\+)\d{1,}/) || [, ''];

    const priceNumber = mergedSettings.absoluteValue ? Math.abs(Number(price.amount)) : Number(price.amount);

    let amount: string = priceNumber.toFixed(2);
    if (mergedSettings.roundPrice && mergedSettings.roundDigits) {
      amount =
        plusSign +
        (Math.ceil(priceNumber / ROUNDER[mergedSettings.roundDigits]) * ROUNDER[mergedSettings.roundDigits]).toString();
    }
    if (mergedSettings.stripZeroCents) {
      amount = plusSign + amount.replace(/\.0{1,3}$/, '');
    }
    return this.languageService.translate('number').pipe(
      map((i18n) => {
        const customSymbol = mergedSettings.customAbbrSettings?.symbol;

        const currencyFormat =
          mergedSettings.useCustomFormat && isPresent(mergedSettings.customAbbrSettings?.format)
            ? mergedSettings.customAbbrSettings.format
            : i18n.currencyFormat;

        const code =
          mergedSettings.useSymbol &&
          isPresent(mergedSettings.symbol) &&
          (!isPresent(customSymbol) || !mergedSettings.abbreviate)
            ? mergedSettings.symbol
            : '';

        if (mergedSettings.abbreviate) {
          const { value, abbreviated } = this.abbreviateAmount(
            Number(amount).toFixed(0),
            i18n.thousandAbbreviation,
            i18n.millionAbbreviation,
            mergedSettings?.customAbbrSettings
          );

          const shouldSetSymbol = !abbreviated && !isPresent(mergedSettings?.symbol);
          const symbol = shouldSetSymbol && isPresent(customSymbol) ? customSymbol : '';
          const outputString = currencyFormat.replace(/1/, value).replace(/\s/g, '');
          return outputString.replace(/\$/, code).replace(/XYZ/, symbol).trim();
        }

        const outputAmount = amount
          .replace('.', i18n.decimalSeparator)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${i18n.thousandSeparator}`);
        const symbol = mergedSettings.useSymbol && isPresent(mergedSettings?.symbol) ? '' : price.currencyCode;
        const outputString = currencyFormat.replace(/1/, outputAmount);

        return outputString.replace(/\$/, code).replace(/XYZ/, symbol).trim();
      })
    );
  }

  formatPoints(amount: number, abbreviate = false): Observable<string> {
    return this.languageService.translate('number').pipe(
      switchMap((i18n) =>
        this.formatPrice(
          {
            amount: amount.toString(),
            currencyCode: i18n.currency,
          },
          {
            useSymbol: false,
            abbreviate,
            stripZeroCents: true,
          }
        ).pipe(map((points) => points.replace(i18n.currency, '').trim()))
      )
    );
  }

  private abbreviateAmount(
    amount: string,
    thousandAbbr: string,
    millionAbbr: string,
    customAbbrSettings: CustomAbbreviationSettings
  ): { value: string; abbreviated?: boolean } {
    if (!isPresent(amount) || amount.length <= 4) {
      return { value: amount, abbreviated: false };
    }

    const { divider, decimals }: Abbreviation =
      isPresent(customAbbrSettings) && customAbbrSettings?.useSymbol?.includes(this.languageService.langValue)
        ? customAbbrSettings.settings[amount.length] || customAbbrSettings.settings[MAX_ABBR_DIGITS]
        : ABBR_SETTINGS[amount.length] || DEFAULT_ABBR_SETTINGS;

    const basicAbbr: string =
      amount.length < MAX_ABBR_DIGITS || (isPresent(customAbbrSettings) && Math.log10(divider) < MAX_ABBR_DIGITS - 1)
        ? thousandAbbr
        : millionAbbr;
    const abbreviation: string =
      isPresent(customAbbrSettings) && customAbbrSettings?.useSymbol?.includes(this.languageService.langValue)
        ? customAbbrSettings.symbol
        : basicAbbr;
    const value = `${(Number(amount) / divider).toFixed(decimals).replace(/\.0{1,3}$/, '')}${abbreviation}`;

    return { value, abbreviated: true };
  }
}
