import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { stopPropagation } from '@fcom/core/utils';

type IconSizeUnit = 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';
type FontSizeUnit =
  | 'heading-1'
  | 'heading-2'
  | 'heading-3'
  | 'heading-4'
  | 'heading-5'
  | 'body-1'
  | 'body-2'
  | 'caption-1';
type TextSize = `${'font'}-${FontSizeUnit}`;
type IconSize = `${'icon'}-${IconSizeUnit}`;

@Component({
  selector: 'fin-plus-minus-selector',
  styleUrls: ['./plus-minus-selector.component.scss'],
  templateUrl: './plus-minus-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlusMinusSelectorComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  min: number;
  @Input()
  max: number;
  @Input()
  model: number;
  @Input()
  title: string;
  @Input()
  description: string;
  @Input()
  decreaseLabel: string;
  @Input()
  increaseLabel: string;
  @Input()
  controls: string;
  @Input()
  iconSize?: IconSize = 'icon-large';
  @Input()
  textSize?: TextSize = 'font-heading-5';
  @Input()
  buttonPadding = true;

  /**
   * Emits the number the value should be increased with
   * @type {EventEmitter}
   */
  @Output()
  increment: EventEmitter<number> = new EventEmitter<number>();

  /**
   * Emits the number the value should be decreased with
   * @type {EventEmitter}
   */
  @Output()
  decrement: EventEmitter<number> = new EventEmitter<number>();

  increase(event: Event): void {
    if (this.model < this.max) {
      this.increment.emit(1);
    }
    stopPropagation(event);
  }

  decrease(event: Event): void {
    if (this.model > this.min) {
      this.decrement.emit(1);
    }
    stopPropagation(event);
  }
}
