<div class="checkbox flex medium-type">
  <label class="fill flex flex-1 flex--nogrow ps-medium-r" [for]="cid" [ngClass]="{ 'link-hover': !disabled }">
    <input
      class="is-vishidden"
      type="checkbox"
      [checked]="checked"
      [attr.tabIndex]="tabIndex"
      [attr.name]="name"
      [attr.value]="value"
      [attr.id]="cid"
      [attr.disabled]="disabled ? 'disabled' : undefined"
      (change)="interactionEvent($event)"
      (click)="clickEvent($event)"
      (blur)="blurEvent()"
      (focus)="focusEvent()"
    />
    <span
      aria-hidden="true"
      class="icons ms-xsmall-r nordic-blue-900-text inline-block flex--self-start"
      [ngClass]="theme"
    >
      <fcom-icon
        *ngIf="!checked && showUnselectedIcon"
        [name]="
          theme === CheckBoxTheme.SQUARE
            ? SvgLibraryIcon.CHECKBOX_UNSELECTED
            : SvgLibraryIcon.CHECKMARK_BUTTON_UNSELECTED
        "
        class="icon-medium"
      ></fcom-icon>
      <fcom-icon
        *ngIf="checked"
        [name]="
          theme === CheckBoxTheme.SQUARE ? SvgLibraryIcon.CHECKBOX_SELECTED : SvgLibraryIcon.CHECKMARK_BUTTON_SELECTED
        "
        class="icon-medium"
      ></fcom-icon>
    </span>
    <span
      data-testid="text-content"
      class="text"
      [class.content-full-width]="contentFullWidth"
      [class.fill]="contentFullWidth"
    >
      <ng-content></ng-content>
    </span>
  </label>
</div>
