/* eslint-disable */

// =====================================================================================================================
// | ADD YOUR NEW AND CHANGED TRANSLATIONS HERE IN FINNISH AND ENGLISH.                                                |
// | TEXTS IN THIS FILE WERE NOT CHECKED WITH COPYWRITERS YET, ONLY FOR DEMO PURPOSES.                                 |
// | ONLY ADD LABELS HERE THAT ARE NOT YET VISIBLE FOR THE END USERS. YOU SHOULD                                       |
// | REVIEW ALL TEXTS WITH A COPYWRITER BEFORE THEY ARE MADE AVAILABLE FOR USERS.                                      |
// =====================================================================================================================

import * as globalLocalizationEn from './localization-wip/globalLocalizationEn.json';
import * as bookingLocalizationEn from './localization-wip/bookingLocalizationEn.json';
import * as mmbLocalizationEn from './localization-wip/mmbLocalizationEn.json';
import * as reaccommodationLocalizationEn from './localization-wip/reaccommodationLocalizationEn.json';
import * as loyaltyLocalizationEn from './localization-wip/loyaltyLocalizationEn.json';
import * as serviceformsLocalizationEn from './localization-wip/serviceformsLocalizationEn.json';
import * as refundsLocalizationEn from './localization-wip/refundsLocalizationEn.json';
import * as corporateLocalizationEn from './localization-wip/corporateLocalizationEn.json';
import * as travelReadyLocalizationEn from './localization-wip/travelReadyLocalizationEn.json';
import * as globalLocalizationFiFi from './localization-wip/globalLocalizationFiFi.json';
import * as bookingLocalizationFiFi from './localization-wip/bookingLocalizationFiFi.json';
import * as mmbLocalizationFiFi from './localization-wip/mmbLocalizationFiFi.json';
import * as reaccommodationLocalizationFiFi from './localization-wip/reaccommodationLocalizationFiFi.json';
import * as loyaltyLocalizationFiFi from './localization-wip/loyaltyLocalizationFiFi.json';
import * as serviceformsLocalizationFiFi from './localization-wip/serviceformsLocalizationFiFi.json';
import * as refundsLocalizationFiFi from './localization-wip/refundsLocalizationFiFi.json';
import * as corporateLocalizationFiFi from './localization-wip/corporateLocalizationFiFi.json';
import * as travelReadyLocalizationFiFi from './localization-wip/travelReadyLocalizationFiFi.json';

export const workInProgress = {
  globalLocalizationEn,
  bookingLocalizationEn,
  mmbLocalizationEn,
  reaccommodationLocalizationEn,
  loyaltyLocalizationEn,
  serviceformsLocalizationEn,
  refundsLocalizationEn,
  corporateLocalizationEn,
  travelReadyLocalizationEn,
  globalLocalizationFiFi,
  bookingLocalizationFiFi,
  mmbLocalizationFiFi,
  reaccommodationLocalizationFiFi,
  loyaltyLocalizationFiFi,
  serviceformsLocalizationFiFi,
  refundsLocalizationFiFi,
  corporateLocalizationFiFi,
  travelReadyLocalizationFiFi,
};
/* eslint-enable */
