import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  type OnChanges,
  type OnDestroy,
  type OnInit,
} from '@angular/core';
import { Validators, type AbstractControl, type FormControlStatus, type UntypedFormGroup } from '@angular/forms';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, Subscription, type Observable, type Subject } from 'rxjs';

import { IntlJoinPipe } from '@fcom/common/pipes/intl-join.pipe';
import { SentryLogger } from '@fcom/core/services';
import { stopPropagation, unsubscribe } from '@fcom/core/utils';

import { ButtonMode } from '../../buttons';
import { IconPosition } from '../../icons';
import { AttachmentError, AttachmentStatus, type Attachment, type FileUploaderI18n } from '../../interfaces';
import { ATTACHMENT_ALLOWED_FILE_EXTENSIONS } from '../enums';
import { type AttachmentResponse } from '../interfaces';
import { attachmentStatusValidator } from './file-uploader-validators';

const ONE_MB_IN_BYTES = 1048576;

type AttachmentErrors = Record<AttachmentError, string[]>;
type FileErrors = Record<AttachmentError, string>;

@Component({
  selector: 'fcom-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [IntlJoinPipe],
})
export class FileUploaderComponent implements OnChanges, OnInit, OnDestroy {
  readonly AttachmentError = AttachmentError;
  readonly ButtonMode = ButtonMode;
  readonly IconLibrary = IconLibrary;
  readonly IconPosition = IconPosition;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input() allowedFileTypes: string[] = ATTACHMENT_ALLOWED_FILE_EXTENSIONS;
  @Input() customAttachLabel = '';
  @Input() customRequiredLabel = '';
  @Input() disabled = false;
  @Input() id: string;
  @Input() maxSizeInBytes: number;
  @Input() multiple: boolean;
  @Input() uploadService: (file: File) => Observable<AttachmentResponse>;
  @Input({ required: true }) controlName: string;
  @Input({ required: true }) i18n: FileUploaderI18n;
  @Input({ required: true }) parentForm: UntypedFormGroup;

  @Output() fileDeleted: EventEmitter<Attachment> = new EventEmitter<Attachment>();
  @Output() filesUpdated: EventEmitter<Attachment[]> = new EventEmitter<Attachment[]>();

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  dragInProgress$: Subject<boolean> = new BehaviorSubject<boolean>(false);
  idOrControlName: string;
  maxSizeMb: number | undefined;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    private intlJoin: IntlJoinPipe,
    private sentryLogger: SentryLogger
  ) {}

  /**
   * Transforms an array of allowed file types to a string
   * for the `accept` property of the file input.
   *
   * @example ['gif', 'jpg', 'png']
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept MDN documentation} for more information.
   * @returns {string} `'.gif,.jpg,.png'`
   */
  get acceptValues(): string {
    return this.allowedFileTypes.length ? `.${this.allowedFileTypes.join(',.')}` : '';
  }

  get attachments(): Attachment[] {
    return this.ctrlField.value?.filter?.((a: Attachment): boolean => a?.status !== AttachmentStatus.ERROR) || [];
  }

  get attachmentErrorKeys(): AttachmentError[] {
    return Object.keys(this.fileErrors) as AttachmentError[];
  }

  get attachmentsWithErrors(): Attachment[] {
    return this.ctrlField.value?.filter?.((a: Attachment): boolean => a?.status === AttachmentStatus.ERROR) || [];
  }

  get containsErrors(): boolean {
    const errorStatuses: AttachmentError[] = [AttachmentError.REQUIRED];
    return (
      !this.disabled &&
      this.ctrlField.touched &&
      (Object.keys(this.ctrlField.errors || {}).filter((attachmentError: AttachmentError): boolean =>
        errorStatuses.includes(attachmentError)
      ).length > 0 ||
        Boolean(this.attachmentsWithErrors.length))
    );
  }

  get ctrlField(): AbstractControl<Attachment[]> {
    return this.parentForm.get(this.controlName);
  }

  get fieldDisabled(): boolean {
    return this.disabled || this.ctrlField.disabled || (!this.multiple && this.attachments.length === 1);
  }

  get fieldRequired(): boolean {
    return this.ctrlField.hasValidator(Validators.required);
  }

  /**
   * @example English
   * ```ts
   * console.log(this.fileErrorLabels);
   * {
   *   [AttachmentError.DUPLICATE]: 'file.jpg',
   *   [AttachmentError.EXTENSION]: 'file1.jpg and file2.png',
   *   [AttachmentError.MAX_SIZE]: 'file.jpg',
   *   [AttachmentError.MALWARE]: 'file1.exe, file2.exe and file3.exe'
   *   [AttachmentError.UNKNOWN]: 'file1.jpg, file2.png and file3.exe'
   * }
   * ```
   * @returns An object with attachment errors as keys and a localized string of file names as values.
   */
  get fileErrorLabels(): FileErrors {
    const errors: FileErrors = Object.keys(this.fileErrors).reduce(
      (accumulator: FileErrors, key: string): FileErrors => {
        accumulator[key] = this.intlJoin.transform(this.fileErrors[key]);
        return accumulator;
      },
      {} as FileErrors
    );
    return errors;
  }

  /**
   * @example
   * ```ts
   * console.log(this.fileErrors);
   * {
   *   [AttachmentError.DUPLICATE]: ['file.jpg'],
   *   [AttachmentError.EXTENSION]: ['file1.jpg', 'file2.png'],
   *   [AttachmentError.MAX_SIZE]: ['file.jpg'],
   *   [AttachmentError.MALWARE]: ['file1.exe', 'file2.exe', 'file3.exe']
   *   [AttachmentError.UNKNOWN]: ['file1.jpg', 'file2.png', 'file3.exe']
   * }
   * ```
   * @returns An object with attachment errors as keys and an array of file names as values.
   */
  get fileErrors(): AttachmentErrors {
    return this.attachmentsWithErrors
      .sort((a: Attachment, b: Attachment): number => a.file.name.localeCompare(b.file.name))
      .reduce((accumulator: AttachmentErrors, currentValue: Attachment): AttachmentErrors => {
        accumulator[currentValue.error] = [...(accumulator[currentValue.error] || []), currentValue.file.name];
        return accumulator;
      }, {} as AttachmentErrors);
  }

  get filesSupported(): string {
    return this.intlJoin.transform(this.allowedFileTypes);
  }

  get isUploading(): boolean {
    return this.attachments.some((a: Attachment): boolean => a.status === AttachmentStatus.UPLOADING);
  }

  get labelAttach(): string {
    return this.customAttachLabel || (this.multiple ? this.i18n.attachFiles : this.i18n.attachFile);
  }

  get labelRequired(): string {
    return this.customRequiredLabel || (this.multiple ? this.i18n.errors.requiredMultiple : this.i18n.errors.required);
  }

  ngOnChanges(): void {
    this.initValidators();
  }

  ngOnInit(): void {
    this.idOrControlName = this.id || this.controlName;
    this.maxSizeMb = this.maxSizeInBytes ? +(this.maxSizeInBytes / ONE_MB_IN_BYTES).toFixed(1) : undefined;

    this.initValidators();

    this.subscriptions.add(
      this.ctrlField.valueChanges.subscribe((updatedAttachments: Attachment[]): void => {
        if (!updatedAttachments) {
          return;
        }
        this.filesUpdated.emit(updatedAttachments);
        if (this.uploadService) {
          this.uploadAttachments(updatedAttachments.filter((attachment: Attachment): boolean => !attachment.status));
        }
        this.ctrlField.markAsTouched();
        this.ctrlField.updateValueAndValidity({ emitEvent: false });
        this.cdr.markForCheck();
      })
    );

    this.subscriptions.add(
      this.parentForm.statusChanges.subscribe((value: FormControlStatus): void => {
        if ('INVALID' === value && this.ctrlField.touched) {
          this.ctrlField.updateValueAndValidity({ onlySelf: true });
        }
      })
    );

    this.cdr.markForCheck();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  deleteAttachment(attachment: Attachment): void {
    if (!attachment) {
      return;
    }
    this.ctrlField.setValue(
      this.ctrlField.value.filter((a: Attachment): boolean => a.file.name !== attachment.file.name)
    );
    this.fileDeleted.emit(attachment);
  }

  onDragEnter(enable: boolean): void {
    if (this.fieldDisabled) {
      return;
    }
    this.dragInProgress$.next(enable);
  }

  onDragOver(event: DragEvent): void {
    stopPropagation(event);
    if (this.fieldDisabled) {
      event.dataTransfer.dropEffect = 'none';
      event.dataTransfer.effectAllowed = 'none';
    }
  }

  @HostListener('cancel', ['$event'])
  onFileCancel(event: Event): void {
    // Prevent triggering error when no files are returned from the input
    // and some already exist in the attachment list
    if (this.attachments.length) {
      stopPropagation(event);
      return;
    }
    this.ctrlField.markAsTouched();
  }

  @HostListener('change', ['$event'])
  onFileChange(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const validatedAttachments: Attachment[] = Array.from(target.files).map(
      (f: File): Attachment => this.validateFile(f)
    );
    this.removeErrors();
    if (validatedAttachments.length) {
      stopPropagation(event);
      this.ctrlField.setValue([...this.attachments, ...validatedAttachments]);
    }
  }

  onFileDrop(event: DragEvent): void {
    if (this.fieldDisabled) {
      return;
    }
    stopPropagation(event);
    const files: FileList = event?.dataTransfer?.files;
    this.dragInProgress$.next(false);
    this.removeErrors();
    if (files) {
      const validatedAttachments: Attachment[] = Array.from(files).map(
        (file: File): Attachment => this.validateFile(file)
      );
      this.ctrlField.setValue([...this.attachments, ...validatedAttachments]);
    }
  }

  /**
   * Fixes bug on Chrome where the cancel event gets fired instead of the change event.
   * @see {@link https://stackoverflow.com/q/42355858}
   */
  @HostListener('click', ['$event'])
  onClick(): void {
    this.removeErrors();
    this.fileInput.nativeElement.value = null;
  }

  removeErrors(): void {
    this.ctrlField.setErrors(null, { emitEvent: false });
    this.ctrlField.setValue(
      this.attachments.filter((a: Attachment): boolean => a.status !== AttachmentStatus.ERROR),
      { emitEvent: false }
    );
  }

  openFileSelector(): void {
    if (this.disabled) {
      return;
    }
    this.removeErrors();
    this.fileInput.nativeElement.click();
  }

  private errorCallback(attachment: Attachment, errorMessage: unknown): void {
    const error: AttachmentError =
      'MALICIOUS_CONTENT_DETECTED' === errorMessage ? AttachmentError.MALWARE : AttachmentError.UNKNOWN;
    this.updateAttachmentDetails(attachment, { error, status: AttachmentStatus.ERROR });
    this.ctrlField.setErrors({ [error]: { value: [attachment] } });
    this.ctrlField.updateValueAndValidity();
    this.sentryLogger.warn('Failed to upload attachment:', { error: errorMessage });
  }

  private fileEquals(a: File, b: File): boolean {
    return a.name === b.name && a.size === b.size && a.lastModified === b.lastModified;
  }

  private initValidators(): void {
    this.ctrlField.addValidators(attachmentStatusValidator());
  }

  private uploadAttachment(attachment: Attachment): void {
    this.subscriptions.add(
      this.uploadService(attachment.file).subscribe({
        next: (response: AttachmentResponse): void => this.uploadCallback(attachment, response),
        error: (error: unknown): void => this.errorCallback(attachment, error),
      })
    );
  }

  private updateAttachmentDetails(attachment: Attachment, details: Partial<Attachment>): void {
    if (!attachment) {
      return;
    }
    const updatedAttachments: Attachment[] = this.attachments.map(
      (a: Attachment): Attachment => (this.fileEquals(a.file, attachment.file) ? { ...a, ...details } : a)
    );
    this.ctrlField.setValue(updatedAttachments);
  }

  private uploadAttachments(attachmentsToUpload: Attachment[]): void {
    if (!attachmentsToUpload.length) {
      return;
    }
    const updatedAttachments: Attachment[] = this.attachments.map(
      (attachment: Attachment): Attachment =>
        attachment.status ? attachment : { ...attachment, status: AttachmentStatus.UPLOADING }
    );
    this.ctrlField.setValue(updatedAttachments);
    for (const attachment of attachmentsToUpload) {
      this.uploadAttachment(attachment);
    }
  }

  private uploadCallback(attachment: Attachment, response: AttachmentResponse): void {
    const fileId = response?.attachmentId;
    if (fileId) {
      this.updateAttachmentDetails(attachment, { status: AttachmentStatus.READY, fileId });
    } else {
      this.updateAttachmentDetails(attachment, { status: AttachmentStatus.ERROR });
    }
  }

  private validateFile(file: File): Attachment {
    const attachment: Attachment = { file };
    const fileExtension: string = file.name.split('.').pop();
    if (this.attachments.map((a: Attachment): string => a.file.name).includes(file?.name)) {
      attachment.error = AttachmentError.DUPLICATE;
      attachment.status = AttachmentStatus.ERROR;
    }
    if (!this.allowedFileTypes.includes('*') && !this.allowedFileTypes.includes(fileExtension)) {
      attachment.error = AttachmentError.EXTENSION;
      attachment.status = AttachmentStatus.ERROR;
    }
    if (file.size > this.maxSizeInBytes) {
      attachment.error = AttachmentError.MAX_SIZE;
      attachment.status = AttachmentStatus.ERROR;
    }
    return attachment;
  }
}
