import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { makeStateKey, StateKey } from '@angular/platform-browser';

import { Observable, throwError } from 'rxjs';
import { map, catchError, filter } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { ConfigService, StateTransferService } from '@fcom/core';
import { SentryLogger } from '@fcom/core/services/sentry/sentry.logger';
import { isNotEmpty, isPresent } from '@fcom/core/utils';
import { finShare } from '@fcom/rx';

import {
  DestinationSearchItem,
  DestinationSearchItemType,
  DestinationSearchQueryParamNames,
  DestinationSearchResponseData,
  DestinationSearchResponseItem,
  DestinationSearchResult,
} from '../interfaces/destination-search.interface';
import { findDestinationsForQueryParams } from '../utils/flatten';
import { latinizeRegexLight, latinizeMapLight, latinize } from '../utils/latinize';

@Injectable()
export class DestinationSearchService {
  readonly latinizeRegex = latinizeRegexLight;
  readonly latinizeMap = latinizeMapLight;
  readonly latinize = latinize(this.latinizeRegex, this.latinizeMap);

  destinationSearchResult$: Observable<DestinationSearchResult>;

  static createStateKey = (): StateKey<DestinationSearchResult> => makeStateKey<DestinationSearchResult>('dsr');

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private sentryLogger: SentryLogger,
    private languageService: LanguageService,
    private stateTransferService: StateTransferService
  ) {
    this.destinationSearchResult$ = this.fetchDestinationSearch().pipe(finShare());
  }

  public destinationSearch(restriction?: DestinationSearchQueryParamNames): Observable<DestinationSearchItem[]> {
    return this.restrictedDestinationSearchItems(restriction).pipe(
      filter(isPresent),
      map((destinations) => this.recursiveSort(destinations))
    );
  }

  private restrictedDestinationSearchItems(
    restriction?: DestinationSearchQueryParamNames
  ): Observable<DestinationSearchItem[]> {
    if (!restriction) {
      return this.destinationSearchItems();
    }
    return this.destinationSearchItems().pipe(
      map((destinations) => this.getRestrictedDestinationsTree(destinations, restriction))
    );
  }

  private destinationSearchItems(): Observable<DestinationSearchItem[]> {
    return this.destinationSearchResult$.pipe(map((searchResult: DestinationSearchResult) => searchResult?.items));
  }

  private fetchDestinationSearch(): Observable<DestinationSearchResult> {
    return this.stateTransferService.wrapToStateCache(DestinationSearchService.createStateKey(), () => {
      return this.destinationSearchQuery().pipe(
        map((result) => {
          // map DestinationSearchResult item to DSDestination[] by converting tags array to a tags Set
          return {
            info: result.info,
            items: this.mapResponseItemsToItems(result.items),
          };
        })
      );
    });
  }

  private mapResponseItemsToItems(items: DestinationSearchResponseItem[]): DestinationSearchItem[] {
    if (!items) {
      return;
    }
    return items.map((o) => {
      // @TODO: Remove delete responsiveImageData when CMS has migrated away from these
      if (o?.picture?.['responsiveImageData']) {
        delete o.picture['responsiveImageData'];
      }
      return {
        ...o,
        countryMaster: o.countryMaster || o.country,
        continentMaster: o.continentMaster || o.continent,
        id: (o.type + (o.titleMaster || o.title)).toLowerCase(),
        items: this.mapResponseItemsToItems(o.items),
        tags: new Set(o.tags),
        normalizedTitle: this.latinize(o.title),
        normalizedTitleMaster: this.latinize(o.titleMaster || o.title),
        titleMaster: o.titleMaster || o.title,
        directFlight: o.directFlight,
      } as DestinationSearchItem;
    });
  }

  private destinationSearchQuery(): Observable<DestinationSearchResponseData> {
    const langValue = this.languageService.langValue;
    const apiUrl = this.configService.cfg.destinationsSearchUrl;
    const xApiKey = this.configService.cfg.destinationsSearchApiKey;
    const httpOptions = {
      headers: new HttpHeaders({
        'x-api-key': xApiKey,
        'Content-Type': 'application/json',
      }),
    };
    const apiUrlLocalized = langValue ? `${apiUrl}?lang=${langValue}` : apiUrl;

    return this.http.get<DestinationSearchResponseData>(apiUrlLocalized, httpOptions).pipe(
      map((data: DestinationSearchResponseData) => {
        return data;
      }),
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      catchError((response: any) => {
        this.sentryLogger.warn('Failed to fetch destinations for destinations main page', { error: response.error });
        return throwError(() => 'Failed to fetch destinations for destinations main page');
      })
    );
  }

  private getRestrictedDestinationsTree(
    allDestinations: DestinationSearchItem[],
    restrictions: DestinationSearchQueryParamNames
  ): DestinationSearchItem[] {
    const restrictToDestinationsItems = findDestinationsForQueryParams(allDestinations, restrictions);
    const restrictIDs = restrictToDestinationsItems.map((el) => el.id);
    const restrictedDestinationsTree = this.reduceRestrictedDestinations(allDestinations, restrictIDs);

    return isNotEmpty(restrictedDestinationsTree) ? restrictedDestinationsTree : allDestinations;
  }

  private reduceRestrictedDestinations(destinations: DestinationSearchItem[], restrictIDs: string[]) {
    return destinations.reduce((acc, item) => {
      const newItem = { ...item };
      const isMatch = restrictIDs.includes(item.id);

      if (!isMatch && item.items) {
        newItem.items = this.reduceRestrictedDestinations(item.items, restrictIDs);
      }
      if (isMatch || newItem.items?.length) {
        acc.push(newItem);
      }
      return acc;
    }, []);
  }

  private recursiveSort(destinations: DestinationSearchItem[]): DestinationSearchItem[] {
    if (!destinations) {
      return [];
    }
    return (
      [...destinations].sort(this.sortByAlphabetical).map((d) => ({
        ...d,
        ...((d.type !== DestinationSearchItemType.DESTINATION ?? {
          items: this.recursiveSort(d.items),
        }) as DestinationSearchItem),
      })) ?? []
    );
  }

  private sortByAlphabetical(a: DestinationSearchItem, b: DestinationSearchItem) {
    return a.title > b.title ? 1 : -1;
  }
}
