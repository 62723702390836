<div class="continue-reading">
  <input #input type="checkbox" [attr.id]="inputId" class="is-vishidden" aria-hidden="true" />

  <div class="collapsible" [attr.aria-hidden]="!input.checked">
    <ng-content></ng-content>
  </div>

  <label [attr.for]="inputId" class="font-body-1 bold-type nordic-blue-900-text block pr-xsmall">
    <span class="show-when-not-checked" [attr.aria-hidden]="input.checked">
      <fcom-icon [name]="SvgLibraryIcon.CHEVRON_DOWN" class="icon-medium"></fcom-icon
      ><span class="font-body-1 pr-xxsmall-l">{{ 'readMore' | finLocalization }}</span>
    </span>
    <span class="show-when-checked" [attr.aria-hidden]="!input.checked">
      <fcom-icon [name]="SvgLibraryIcon.CHEVRON_UP" class="icon-medium"></fcom-icon
      ><span class="font-body-1 pr-xxsmall-l">{{ 'readLess' | finLocalization }}</span>
    </span>
  </label>
</div>
