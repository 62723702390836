import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

import { fromEvent, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';

import { WindowRef } from '@fcom/core';
import { finShare } from '@fcom/rx';

const breakpoints = {
  mobile: 'only screen and (max-width: 599px)',
  tablet: 'only screen and (min-width: 600px) and (max-width: 1023px)',
  tablet_down: 'only screen and (max-width: 1023px)',
  laptop_up: 'only screen and (min-width: 1024px)',
  small_desktop: 'only screen and (min-width: 1024px) and (max-width: 1599px)',
  large_desktop: 'only screen and (min-width: 1600px)',
};

export type MediaQueryBreakpoint = keyof typeof breakpoints;

@Injectable({
  providedIn: 'root',
})
export class MediaQueryService {
  private resizeEvent$: Observable<Event>;

  constructor(
    private windowRef: WindowRef,
    @Inject(PLATFORM_ID) private platform: unknown
  ) {
    if (isPlatformBrowser(this.platform)) {
      this.resizeEvent$ = fromEvent<Event>(this.windowRef.nativeWindow, 'resize').pipe(
        debounceTime(100),
        distinctUntilChanged(),
        finShare()
      );
    }
  }

  isBreakpoint$(breakpoint: keyof typeof breakpoints): Observable<boolean> {
    if (isPlatformServer(this.platform)) {
      return of(false);
    }

    return this.resizeEvent$.pipe(
      map(() => this.matchesMedia(breakpoints[breakpoint])),
      startWith(this.matchesMedia(breakpoints[breakpoint])),
      distinctUntilChanged(),
      finShare()
    );
  }

  getResizeEvent$(): Observable<Event> {
    if (isPlatformServer(this.platform)) {
      return new Subject();
    }

    return this.resizeEvent$;
  }

  private matchesMedia(mediaQuery: string): boolean {
    return this.windowRef.nativeWindow.matchMedia(mediaQuery).matches ?? false;
  }
}
