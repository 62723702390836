import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ExternalTemplateService {
  constructor(private http: HttpClient) {}

  loadExternalHtml(queryString: string): Observable<any> {
    return this.http.get(queryString, { responseType: 'text' }).pipe(
      map((response) => {
        if (response) {
          return response;
        }
        throw new Error('Error fetching external template');
      }),
      catchError((err: unknown) => {
        return throwError(() => err);
      })
    );
  }
}
