<fin-travel-guide [content$]="travelGuide$" [showHeading]="true"></fin-travel-guide>

<ng-container *ngIf="related$ | async as content">
  <ng-container *ngFor="let item of content; let index = index; let last = last">
    <fin-expand
      *ngIf="item.teaserTitle"
      class="block"
      [class.border-bottom]="last"
      [key]="'cms-travel-guide-' + item.contentId"
      [id]="'cms-travel-guide-' + item.contentId + '-' + index"
      #expand
    >
      <div class="trigger flex flex--center border-top pr-small-y">
        <div class="flex-1">
          <h3 class="mr-clear-t nordic-blue-900-text">{{ item.teaserTitle }}</h3>
        </div>
        <fcom-icon
          class="flex-1 flex--nogrow rotate icon-medium"
          [class.rotate-active]="expand.isOpen$ | async"
          [name]="SvgLibraryIcon.CHEVRON_DOWN"
        ></fcom-icon>
      </div>
      <div class="content">
        <fin-travel-guide [content$]="item | finToObservable" [showHeading]="false"></fin-travel-guide>
      </div>
    </fin-expand>
  </ng-container>
</ng-container>
