import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { v4 as uuid } from 'uuid';

import { type IconName } from '@fcom/ui-components';

@Component({
  selector: 'fin-expander-card',
  templateUrl: 'expander-card.component.html',
  styleUrls: ['expander-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpanderCardComponent implements OnInit {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input() iconName: IconName = SvgLibraryIcon.SINGLE_PAX;
  @Input() iconCategory: IconLibrary = IconLibrary.SVG_LIBRARY;
  @Input() iconClasses: string;
  @Input() title: string;
  @Input() capitalizeTitle = true;
  @Input() subTitle: string | number;
  @Input() subTitleIcon: IconName;
  @Input() showSubTitleOnMobile = false;
  @Input() description: string;
  @Input() descriptionClasses: string;
  @Input() expandOpen: boolean;
  @Input() descriptionContent: TemplateRef<ElementRef>;
  @Input() disabled = false;
  @Input() hideIconInMobile = true;
  @Input() showDescriptionAlways = false;

  expandContainerId: string;

  @Output()
  expandChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.expandContainerId = uuid();
  }

  toggleOpen(): void {
    this.expandChange.emit(this.expandOpen);
  }
}
