import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { symbolToLangPath, unsubscribe, urlToPathname } from '@fcom/core/utils';
import { LanguageService } from '@fcom/ui-translate/services/language.service';

import { LinkSize, LinkTheme } from './enums';
import { IconPosition } from '../icons';
import { AriaOptions } from '../interfaces';
import { LinkIcon } from './interfaces';

@Component({
  selector: 'fcom-link',
  templateUrl: './link.component.html',
  styleUrl: './link.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent implements OnInit, OnChanges, OnDestroy {
  readonly IconPosition = IconPosition;
  readonly LinkSize = LinkSize;
  readonly LinkTheme = LinkTheme;

  /**
   * The size of the link
   */
  @Input() size: LinkSize = LinkSize.MEDIUM;

  /**
   * Different themes for dark and light backgrounds
   */
  @Input() theme: LinkTheme = LinkTheme.LIGHT;

  /**
   * Url of the link. If missing, component will be rendered as button.
   */
  @Input() href: Observable<string> | string;

  /**
   * Flag to indicate whether to convert url to pathName for router
   */
  @Input() external: boolean;

  /**
   * Whether to open link in a new tab
   */
  @Input() openInNewTab: boolean;

  /**
   * Adds a nofollow rel for the link
   */
  @Input() nofollow: boolean;

  /**
   * Flag to disable the button. Doesn't have any effect on regular link.
   */
  @Input() disabled: boolean;

  /**
   * ARIA properties
   */
  @Input() aria: AriaOptions;

  /**
   * Icon for the link. Sets the default icon for external links.
   */
  @Input()
  get icon(): LinkIcon {
    if (this._icon) {
      return this._icon;
    }
    if (this.external) {
      return {
        name: SvgLibraryIcon.EXTERNAL_TAB,
        category: IconLibrary.SVG_LIBRARY,
        position: IconPosition.RIGHT,
      };
    }
    return undefined;
  }
  set icon(icon: LinkIcon) {
    this._icon = icon;
  }

  @Output()
  afterNavigation: EventEmitter<Event> = new EventEmitter();
  @Output()
  beforeNavigation: EventEmitter<Event> = new EventEmitter();

  subscription = new Subscription();
  linkVal: string;
  rel: string;
  target: string;

  private _icon: LinkIcon;

  constructor(
    private languageService: LanguageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.href) {
      this.subscribeToLink(this.href);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.href) {
      this.subscribeToLink(changes.href.currentValue);
    }
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  subscribeToLink(link: Observable<string> | string): void {
    this.subscription = unsubscribe(this.subscription);
    if (link instanceof Observable) {
      this.subscription = link
        .pipe(map((linkStr) => this.legacySiteLinkLocale(linkStr)))
        .subscribe((linkResult) => this.setLinkValue(linkResult));
    } else {
      this.setLinkValue(this.legacySiteLinkLocale(link));
    }
  }

  handleClick(event: MouseEvent): void {
    this.beforeNavigation.emit(event);
    if (!(event.ctrlKey || event.metaKey) && !this.external && !event.defaultPrevented && !this.disabled) {
      event.stopPropagation();
      event.preventDefault();
      if (this.linkVal) {
        this.router.navigateByUrl(this.linkVal);
      }
      this.afterNavigation.emit(event);
    }
  }

  legacySiteLinkLocale(url = ''): string {
    if (!url) {
      return url;
    }
    const ddsLocale = this.languageService.ddsLocaleValue;
    return symbolToLangPath(url, ddsLocale);
  }

  private setLinkValue = (link: string) => {
    this.linkVal = this.toActualLink(link);
    if (this.openInNewTab) {
      this.target = '_blank';
      this.rel = 'noopener';
    }
    if (this.nofollow) {
      this.rel = 'nofollow';
    }
  };

  private toActualLink = (l: string): string => (this.external ? l : urlToPathname(l, { preserveQueryParams: true }));
}
