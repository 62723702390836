import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { LanguageService } from '@fcom/ui-translate';
import { ConfigService } from '@fcom/core/services';
import { finShare, retryWithBackoff } from '@fcom/rx';

import { OndLocationsRequestBody, OndLocationsResponse, OndPairRequestBody, OndPairResponse } from '../interfaces';

const RETRY_COUNT = 2;

@Injectable()
export class OndMarketingDataService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private languageService: LanguageService
  ) {}

  public fetchLocations(destinationLocationCodes: string[]): Observable<OndLocationsResponse> {
    const body: OndLocationsRequestBody = {
      destinations: destinationLocationCodes,
      lang: this.languageService.langValue,
    };

    return this.http
      .post<OndLocationsResponse>(`${this.configService.cfg.ondMarketingOffersUrl}/fetch-locations`, body)
      .pipe(retryWithBackoff(RETRY_COUNT), finShare());
  }

  public fetchLocationPairs(body: OndPairRequestBody): Observable<OndPairResponse> {
    return this.http
      .post<OndPairResponse>(`${this.configService.cfg.ondMarketingOffersUrl}/fetch-location-pairs`, body)
      .pipe(finShare());
  }
}
