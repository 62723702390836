import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { FinnairBoundItem, FinnairDisruptedBoundItem, FinnairLocation } from '@fcom/dapi/api/models';

import { FinnairBoundItemWithLocation } from '../../interfaces';

@Component({
  selector: 'fin-flight-review-card',
  templateUrl: 'flight-review-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlightReviewCardComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  viewDetailsClosed$: Observable<string>;
  @Input()
  details: FinnairBoundItemWithLocation | FinnairBoundItem | FinnairDisruptedBoundItem;
  @Input()
  title: string;
  @Input()
  fareFamilyLabel: string;
  @Input()
  locations?: { [key: string]: FinnairLocation };
  @Input()
  hideModify: boolean;
  @Input()
  hideViewDetails: boolean;
  @Input()
  expandOpen = true;
  @Input()
  expandMode = false;
  @Input()
  expandButtonHiddenText?: string;
  @Input()
  notificationTranslationKey?: string;
  @Input()
  showDivider = false;

  expandContainerId = uuid();
  expandButtonId = uuid();

  @Output()
  modifyClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  viewDetails: EventEmitter<void> = new EventEmitter<void>();

  toggleExpand(): void {
    this.expandOpen = !this.expandOpen;
  }
}
