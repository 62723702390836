<div class="font-body-1" [formGroup]="parentForm" [class.mr-large-b]="displayContainerStyles">
  <div [formArrayName]="controlName">
    <ng-container *ngFor="let group of formArray.controls; let i = index">
      <div
        [formGroupName]="i"
        [ngClass]="{
          'mr-medium-t': i > 0 && !isNestedArray && !compressedList,
          'mr-xsmall-t': i > 0 && isNestedArray,
          'pr-small-x pr-small-t grey-25-bg nested-array-item': isNestedArray,
        }"
        [class]="'form-array-item-' + controlName"
        *ngIf="i === 0 || !hideGroups"
      >
        <div
          *ngIf="displayLabels"
          class="bold-type nordic-blue-900-text mr-xxsmall-b item-label"
          [class.font-body-1]="isNestedArray"
          [class.font-body-1]="!isNestedArray"
        >
          {{ translationPrefix + '.label' | finLocalization }} {{ i + 1 }}
        </div>
        <ng-container *ngTemplateOutlet="formGroup; context: { parentFormGroup: group, index: i }"></ng-container>
        <fcom-button
          *ngIf="i >= minGroups"
          class="remove-button"
          [attr.data-testid]="'remove-' + controlName"
          [class.mr-small-b]="!compressedList"
          [class.disabled-area]="disabled"
          [ngClass]="'form-array-remove-' + controlName"
          (clickBtn)="removeFormGroup(i)"
          [disabled]="disabled"
          [text]="(translationPrefix + '.remove' | finLocalization) + (displayLabels ? ' ' + (i + 1).toString() : '')"
          [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.TRASH, position: IconPosition.LEFT }"
          [theme]="ButtonTheme.LIGHT_TERTIARY_GHOST"
        >
        </fcom-button>
      </div>
    </ng-container>
  </div>
  <div
    *ngIf="!hideGroups"
    [ngClass]="{ 'pr-small-x grey-25-bg nested-array-item': isNestedArray }"
    [class.mr-xsmall-t]="formArray.length > 0"
  >
    <fcom-button
      #addGroupButtonElement
      class="add-button"
      [ngClass]="'form-array-add-' + controlName"
      [attr.data-testid]="'add-' + controlName"
      [class.mr-xsmall-y]="!compressedList"
      [class.disabled-area]="disabled"
      [class.nordic-blue-900-text]="formArray.length < maxGroups"
      [class.nordic-blue-900-text]="formArray.length >= maxGroups"
      (clickBtn)="addFormGroup()"
      [disabled]="formArray.length >= maxGroups || disabled"
      [aria]="{ describedby: 'addPassengerDescription' }"
      [text]="translationPrefix + '.add' | finLocalization"
      [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.PLUS, position: IconPosition.LEFT }"
      [theme]="ButtonTheme.LIGHT_TERTIARY_GHOST"
      [focus$]="focusToButton$"
    >
    </fcom-button>
    <span id="addPassengerDescription" class="is-vishidden">{{ 'formArray.addNewItem' | finLocalization }}</span>
  </div>
</div>
