/* eslint-disable */

/**
 * Returns all passenger type used at Finnair. Order of the passenger in enum matters
 */
export enum FinnairPassengerCode {
  ADT = 'ADT',
  YTH = 'YTH',
  YCD = 'YCD',
  C_15 = 'C15',
  CHD = 'CHD',
  INF = 'INF'
}

