import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { UiTranslateModule } from '@fcom/ui-translate';
import { ModalModule } from '@fcom/ui-components';
import { CmsModule } from '@fcom/cms';

import { DirectiveModule } from '../directives';
import { FinFormsModule } from '../forms';
import { PipesModule } from '../pipes';
import { QualtricsComponent } from './components/qualtrics.component';

@NgModule({
  imports: [
    CommonModule,
    DirectiveModule,
    PipesModule,
    CmsModule,
    FinFormsModule,
    FormsModule,
    UiTranslateModule,
    ModalModule,
  ],
  declarations: [QualtricsComponent],
  exports: [QualtricsComponent],
})
export class QualtricsModule {}
