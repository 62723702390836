import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
  AfterViewInit,
  Input,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';

import { Subscription, Observable, Subject } from 'rxjs';
import { map, switchMap, tap, filter } from 'rxjs/operators';

import { unsubscribe, urlToPathname } from '@fcom/core/utils';
import { CmsTemplate } from '@fcom/core-api';

import { ExternalTemplateService } from '../../services';

@Component({
  selector: 'cms-external-page-renderer',
  templateUrl: './external-page-renderer.html',
})
export class CmsExternalPageRendererComponent implements OnInit, OnDestroy, AfterViewInit {
  @Inject(DOCUMENT) document: any;

  @Input()
  template$: Observable<CmsTemplate>;

  @ViewChild('contentWrapper', { static: true })
  contentWrapper: ElementRef;

  @ViewChild('scriptWrapper', { static: true })
  scriptWrapper: ElementRef;

  externalHtml: any;
  removeGlobalListener: Function;
  scriptToBeLoaded$: Subject<string> = new Subject();
  allowScriptLoad$: Subject<boolean> = new Subject();

  private subscriptions: Subscription = new Subscription();

  constructor(
    private externalTemplateService: ExternalTemplateService,
    private renderer: Renderer2,
    private router: Router,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platform)) {
      this.externalHtml = this.template$.pipe(
        map((template) => template.main.find((item) => item.contentType === 'CMExternalLink')),
        switchMap((link: any) => {
          return this.externalTemplateService.loadExternalHtml(link.url).pipe(
            tap((htmlAsText) => {
              const elementForParsing: HTMLDivElement = document.createElement('div');
              elementForParsing.innerHTML = htmlAsText;

              this.scriptToBeLoaded$.next(elementForParsing.getElementsByTagName('script')[0].src);
            }),
            map((htmlAsText) => {
              const elementForParsing: HTMLDivElement = document.createElement('div');
              elementForParsing.innerHTML = htmlAsText;

              const scripts = elementForParsing.getElementsByTagName('script');
              let i = scripts.length;

              while (i) {
                i--;
                scripts[i].parentNode.removeChild(scripts[i]);
              }
              return elementForParsing.innerHTML;
            })
          );
        })
      );

      // Wait until .innerHTML is in place and ngAfterViewInit sends true to allowScriptLoad$
      this.subscriptions.add(
        this.allowScriptLoad$
          .pipe(
            filter(Boolean),
            switchMap(() => this.scriptToBeLoaded$)
          )
          .subscribe((scriptToLoad) => this.loadScripts(scriptToLoad))
      );
    }
  }

  ngAfterViewInit(): void {
    this.useRouterForLinkClicks();
    this.allowScriptLoad$.next(true);
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);

    if (this.removeGlobalListener) {
      this.removeGlobalListener();
    }

    // Dispatch global event so that external script will erase event bindings, intervals and variables
    this.broadcastEvent();
  }

  private broadcastEvent(): void {
    if (isPlatformServer(this.platform)) {
      return;
    }

    const evt = document.createEvent('Event');
    evt.initEvent('finUnloadExternalScripts', true, true);
    document.dispatchEvent(evt);
  }

  private loadScripts(scriptToLoad): void {
    if (isPlatformServer(this.platform)) {
      return;
    }

    const script: HTMLScriptElement = document.createElement('script');
    script.type = 'text/javascript';
    script.src = scriptToLoad;

    this.renderer.appendChild(this.scriptWrapper.nativeElement, script);
  }

  private useRouterForLinkClicks() {
    if (isPlatformServer(this.platform)) {
      return;
    }
    this.removeGlobalListener = this.renderer.listen('document', 'click', (event) => {
      const target = event.target;
      const isInsideTheElement = this.contentWrapper.nativeElement.contains(target);

      if (!isInsideTheElement) {
        return;
      }
      if (target.tagName.toLowerCase() === 'a' && target.target !== '_blank') {
        // Application internal URL, use the router to navigate
        this.router.navigateByUrl(urlToPathname(target.href));
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    });
  }
}
