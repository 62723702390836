import { Component, Input, OnInit } from '@angular/core';

import { combineLatest, distinctUntilChanged, map, Observable, switchMap, tap } from 'rxjs';
import { Store } from '@ngrx/store';

import { CmsImageData, Location } from '@fcom/core-api';
import { PageMetaService, TripType } from '@fcom/common';
import { SchemaService } from '@fcom/common/services';
import { LocationRouteCffService, AppState } from '@fcom/core';
import { LanguageService } from '@fcom/ui-translate';
import { finShare } from '@fcom/rx';
import { ContentType } from '@fcom/common/interfaces';
import { globalBookingOrigin } from '@fcom/common/store';
import { CmsTripTypeMap } from '@fcom/core/utils/tripType.utils';

/**
 * Hero component for destination page
 *
 * @example
 * <fin-destination-hero
 *  [title]=""
 *  [backgroundImage]=""
 *  [destinationCode]=""
 *  [enableAward]=""
 *  [enableAurinkomatkat]=""
 *  [enablePromoCode]=""
 * >
 * </fin-destination-hero>
 */
@Component({
  selector: 'fin-destination-hero',
  templateUrl: './destination-hero.component.html',
  styleUrls: ['./destination-hero.component.scss'],
})
export class DestinationHeroComponent implements OnInit {
  readonly TripType = TripType;
  readonly CmsTripTypeMap = CmsTripTypeMap;

  @Input()
  title: string;
  @Input()
  backgroundImage: CmsImageData;
  @Input()
  destinationCode: string;
  @Input()
  enableAward: boolean;
  @Input()
  enableAurinkomatkat: boolean;
  @Input()
  enablePromoCode: boolean;
  @Input()
  contentType: ContentType;
  @Input()
  isInCampaign: boolean;
  @Input()
  campaignLabel: string;
  @Input()
  campaignEndText: string;
  @Input()
  enableBlackTeaser: boolean;

  selectedOriginLocationName$: Observable<string>;
  showOneWayPrice$: Observable<boolean>;
  origin$: Observable<Location>;
  originLocationCode$: Observable<string>;
  originLocationCodeFromStore$: Observable<string>;

  constructor(
    private languageService: LanguageService,
    private locationRouteCffService: LocationRouteCffService,
    private pageMetaService: PageMetaService,
    private store$: Store<AppState>,
    private schemaService: SchemaService
  ) {
    this.originLocationCodeFromStore$ = this.store$.pipe(globalBookingOrigin());

    this.origin$ = combineLatest([
      this.originLocationCodeFromStore$,
      this.pageMetaService.defaultOriginLocationCode$,
    ]).pipe(
      switchMap(([selectedOrigin, defaultOrigin]) =>
        this.locationRouteCffService.bestGuessFor(selectedOrigin ?? defaultOrigin, this.languageService.localeValue)
      ),
      distinctUntilChanged(),
      finShare()
    );

    this.originLocationCode$ = combineLatest([
      this.origin$,
      this.pageMetaService.defaultOriginLocationCode$,
      this.originLocationCodeFromStore$,
    ]).pipe(
      map(
        ([origin, defaultLocationCode, selectedOrigin]) => selectedOrigin ?? origin.locationCode ?? defaultLocationCode
      )
    );

    this.selectedOriginLocationName$ = combineLatest([
      this.pageMetaService.defaultOriginLocationCode$,
      this.origin$,
    ]).pipe(map(([originLocationCode, originLocation]) => originLocation.cityName ?? originLocationCode));
  }

  ngOnInit(): void {
    const destination$ = this.locationRouteCffService.bestGuessFor(
      this.destinationCode,
      this.languageService.localeValue
    );

    this.showOneWayPrice$ = combineLatest([this.origin$, destination$]).pipe(
      tap(([origin, destination]) => {
        this.schemaService.addFlightSchema({
          departureAirportName: origin.title,
          departureAirportIataCode: origin.locationCode,
          arrivalAirportName: destination.title,
          arrivalAirportIataCode: destination.locationCode,
        });
      }),
      map(([origin, destination]) => origin.continentCode === 'eu' && destination.continentCode === 'eu')
    );
  }
}
