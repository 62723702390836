export enum LoaderTheme {
  FULL_SCREEN = 'FULL_SCREEN',
  EMBEDDED = 'EMBEDDED',
  INLINE = 'INLINE',
  INLINE_TEXT = 'INLINE_TEXT',
  BUTTON = 'BUTTON',
}

export enum LoaderColor {
  BLUE = 'blue',
  WHITE = 'white',
}
