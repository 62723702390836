import { ErrorHandler, NgModule } from '@angular/core';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { MarketingPreferencesModule } from '@fcom/common/login/marketing-preferences.module';
import { MultivariateTestModule } from '@fcom/common/multivariate-test/multivariate-test.module';
import { ToasterModule } from '@fcom/common/components/toaster/toaster.module';
import { LoginModule, LoginService } from '@fcom/common/login';
import { LocationsModule } from '@fcom/locations';
import { SentryErrorHandler, SentryModule } from '@fcom/core/services';
import { jsConfig } from '@fcom/core/services/config/jsConfig';
import { IconsService } from '@fcom/ui-components/components/icons';
import { ClientIconsService } from '@fcom/ui-components/components/icons/services/client-icons.service';

import { AppComponent } from './app.component';
import { AppModule } from './app.module';
import { ClientLoginService } from '../services/client-login.service';

// State tree

const imports = [];
if (jsConfig.enableDevTools) {
  console.log('NGRX DEVTOOL ENABLED');
  /**
   * To use the devtools, you need the browser extension.
   * See https://github.com/ngrx/store-devtools
   */
  imports.push(
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    })
  );
}

@NgModule({
  imports: [
    AppModule,
    LoginModule,
    MarketingPreferencesModule.forRoot(),
    ToasterModule,
    MultivariateTestModule,
    // LocationsModule has to be declared at very top as we need to be able to listen language changes
    LocationsModule,
    ...imports,
    // Sentry needs to be last
    SentryModule,
  ],
  providers: [
    {
      provide: LoginService,
      useClass: ClientLoginService,
    },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    {
      provide: IconsService,
      useClass: ClientIconsService,
    },
  ],
  bootstrap: [AppComponent],
})
export class ClientModule {}
