import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { CmsQueryList } from '@fcom/core-api';

@Component({
  selector: 'fin-related-teaser',
  templateUrl: './related-teaser.component.html',
  styleUrls: ['./related-teaser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedTeaserComponent {
  @Output()
  linkClicked: EventEmitter<void> = new EventEmitter(); // mainly for click tracking if normal link tracking is not enough

  @Input()
  relatedTeaser: CmsQueryList;

  emitClick(): void {
    this.linkClicked.emit();
  }
}
