import { Step } from '@fcom/common';
import { StringNumberBooleanMap } from '@fcom/core-api';

export type ManageBookingStepParamsType = StringNumberBooleanMap | null;
export type ManageBookingErrorStepWithParams<_T extends ManageBookingStepParamsType = null> = Step;

export interface CheckInSteps {
  CHECK_IN_STEPS: Step;
  CHECK_IN_FLOW: Step;
  CHECK_IN_BOARDING_PASS: Step;
  CHECK_IN_CONFIRMATION: Step;
}

export interface ManageBookingSteps extends CheckInSteps {
  CHECK_IN: Step;
  ENTRY: Step;
  VIEW: Step;
  VIEW_PASSENGERS: Step;
  TRAVEL_PREPARATION: Step;
  REVIEW: Step;
  CHECKOUT: Step;
  CONFIRMATION: Step;
  CAR_RENTAL: Step;
}

export interface ManageBookingErrorSteps {
  PAYMENT_ERROR: ManageBookingErrorStepWithParams;
  ERROR: ManageBookingErrorStepWithParams;
  ERROR_ROUTE: ManageBookingErrorStepWithParams;
}

export const MANAGE_BOOKING_STEPS: ManageBookingSteps = {
  CHECK_IN: {
    path: 'check-in',
    data: {
      server: true,
    },
  },
  CHECK_IN_BOARDING_PASS: {
    path: 'check-in/boarding-pass',
  },
  CHECK_IN_CONFIRMATION: {
    path: 'check-in/confirmation',
  },
  CHECK_IN_FLOW: {
    path: 'check-in/flow',
  },
  CHECK_IN_STEPS: {
    path: 'check-in/step',
  },
  ENTRY: {
    path: '',
    data: {
      server: true,
    },
  },
  VIEW: {
    path: 'view',
  },
  VIEW_PASSENGERS: {
    path: 'view/passengers',
  },
  TRAVEL_PREPARATION: {
    path: 'view/travel-preparations',
  },
  REVIEW: {
    path: 'review',
  },
  CHECKOUT: {
    path: 'checkout',
  },
  CONFIRMATION: {
    path: 'confirmation',
  },
  CAR_RENTAL: {
    path: 'view/car-rental',
  },
};

export const ERROR_STEPS: ManageBookingErrorSteps = {
  PAYMENT_ERROR: {
    path: 'payment/error',
  },
  ERROR: {
    path: 'error',
  },
  ERROR_ROUTE: {
    path: 'error/:error',
  },
};
