import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpInterceptor, HttpHandler, HttpErrorResponse } from '@angular/common/http';

import { Observable, ObservableInput, fromEvent, throwError } from 'rxjs';
import { retry, take } from 'rxjs/operators';

import { WindowRef } from '../providers';
import { ConfigService } from '../services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private windowRef: WindowRef,
    private configService: ConfigService
  ) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      request.url.includes(`${this.configService.cfg.casHost}/cas/login`) ||
      request.url.includes(this.configService.cfg.loyaltyServices.legacy.loyaltyMemberUrl)
    ) {
      return next.handle(request).pipe(
        retry({
          count: 1,
          delay: (error: HttpErrorResponse): ObservableInput<Event> => {
            if (error.status !== 428) {
              return throwError(() => error);
            }
            return fromEvent(this.windowRef.nativeWindow.document, 'akamai-challenge-noreload').pipe(take(1));
          },
        })
      );
    }
    return next.handle(request);
  }
}
