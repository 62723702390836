export type DdsLanguageType =
  | 'CN'
  | 'DE'
  | 'DK'
  | 'ET'
  | 'ES'
  | 'FI'
  | 'FR'
  | 'GB'
  | 'IT'
  | 'JP'
  | 'KO'
  | 'NO'
  | 'PL'
  | 'RU'
  | 'SE';
export type DdsCountrySiteType =
  | 'INT'
  | 'AT'
  | 'AU'
  | 'BE'
  | 'CA'
  | 'CH'
  | 'CN'
  | 'CZ'
  | 'DE'
  | 'DK'
  | 'EE'
  | 'ES'
  | 'FI'
  | 'FR'
  | 'GB'
  | 'HK'
  | 'HU'
  | 'IE'
  | 'IL'
  | 'IN'
  | 'IS'
  | 'IT'
  | 'JP'
  | 'KR'
  | 'LT'
  | 'LV'
  | 'NL'
  | 'NO'
  | 'PL'
  | 'RU'
  | 'SE'
  | 'SG'
  | 'TH'
  | 'US';

export interface DdsLanguage {
  countrySite: DdsCountrySiteType;
  language: DdsLanguageType;
}

export enum DdsServiceCard {
  BOOKING = '1',
  MANAGE_MY_BOOKING = '2',
}

export enum DdsPageType {
  responsiveDeepLink = 'RDPL',
  finnairLoyaltyProfile = 'RPLS',
  updateFinnairLoyaltyProfile = 'FPUP',
}

export type DdsCasBaseParams = {
  /**
   * Four-letter code of DDS page
   */
  PAGE: DdsPageType;
  LANGUAGE: DdsLanguageType;
  COUNTRY_SITE: DdsCountrySiteType;
};

export interface DdsCasAdditionalParams {
  DEEPLINK?: boolean;
  SERVICECARD?: DdsServiceCard;
  PREFILLED_INPUT?: string;
  'update-profile-tab'?: string;
}

export type DdsCasRedirectParams = DdsCasBaseParams & DdsCasAdditionalParams;
