<div class="absolute z5 float-right mr-large-r mr-xsmall-t">
  <div
    *ngIf="messageType$ | async; else content"
    fcomPopover
    [options]="popoverOptions"
    [content]="contentPopover"
    [ariaCloseText]="'welcomeBackMessage.closeText' | finLocalization"
    (closeClick)="onCloseClick()"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</div>
<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<ng-template #contentPopover>
  <div [ngSwitch]="messageType$ | async" class="align-left nordic-blue-900-text">
    <ng-container *ngSwitchCase="MessageType.ANONYMOUS">
      <h2 class="font-heading-4 normal-type ms-xxsmall-t">
        {{ 'welcomeBackMessage.anonymousUserTextTitle' | finLocalization }}
      </h2>

      <p class="font-body-1 ms-medium-t regular-type">
        {{ 'welcomeBackMessage.anonymousUserTextBody' | finLocalization }}
      </p>
      <div class="align-right ms-medium-t">
        <fcom-button
          class="font-body-1 bold-type"
          (clickBtn)="onJoinButtonClick()"
          [text]="'welcomeBackMessage.anonymousUserTextButton' | finLocalization"
          data-testid="welcome-back-message-join-button"
        ></fcom-button>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="MessageType.FPLUS_MEMBER">
      <h2 class="font-heading-4 normal-type ms-xxsmall-t">
        {{ 'welcomeBackMessage.plusMemberTextTitle' | finLocalization }}
      </h2>
      <p class="font-body-1 ms-medium-t regular-type">
        {{ 'welcomeBackMessage.plusMemberTextBody' | finLocalization }}
      </p>
      <div class="align-right ms-medium-t">
        <fcom-button
          class="font-body-1 bold-type"
          (clickBtn)="onLoginButtonClick()"
          [text]="'welcomeBackMessage.plusMemberTextButton' | finLocalization"
          data-testid="welcome-back-message-login-button"
        ></fcom-button>
      </div>
    </ng-container>
  </div>
</ng-template>
