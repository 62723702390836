<ng-container *ngIf="disruptions" data-elastic-exclude>
  <ng-container *ngFor="let item of getMappedDisruptions(); trackBy: disruptionItemId">
    <fcom-notification
      *ngIf="!item.hidden"
      class="border-bottom"
      [horizontal]="true"
      [layout]="NotificationLayout.EDGE_TO_EDGE"
      [theme]="item.level"
      [linkText]="item.linkText || (item.linkUrl ? ('readMore' | finLocalization) : null)"
      [linkUrl]="item.linkUrl"
      [isClosable]="isGlobalBanner ? showGlobalBannerClose(item) : true"
      (linkClick)="onLinkClick($event, item)"
      (closeClick)="onClose(item)"
    >
      {{ item.text }}
    </fcom-notification>
  </ng-container>
</ng-container>
