import { Observable } from 'rxjs';

export const fileToBase64 = (file: File): Observable<string> => {
  return new Observable<string>((observer) => {
    const reader = new FileReader();
    reader.onload = () => {
      observer.next(reader.result?.toString());
      observer.complete();
    };
    reader.onerror = (err) => {
      observer.error(err);
    };
    reader.readAsDataURL(file);
  });
};
