import { throwError, Observable, of, TimeoutError } from 'rxjs';
import { retryWhen, switchMap, delay } from 'rxjs/operators';

export const DEFAULT_RETRY_BACKOFF_TIME = 1000;
export const STATUS_CODES_FOR_RETRY = [408, 504];
/**
 * Retries with a backoff period between the retries.
 * Use with the <code>.let</code> operator
 * @example
 * <code><pre>
 * httpClient
 *   .get(...)
 *   .pipe(retryWithBackoff(3))
 *   .subscribe(...);
 * </pre></code>
 *
 * @param {number} maxCount=1 the number of times to retry, defaults to 1.
 * @param {number} backoffMs=1000 the amount of milliseconds to wait between retries
 * @param {number[]} retryCodes if retryCodes array specified, means all failed requests with status codes not listed in this array will throw an error immediately without retrying.
 * @return
 */
export function retryWithBackoff<T>(
  maxCount = 1,
  backoffMs = DEFAULT_RETRY_BACKOFF_TIME,
  retryCodes = STATUS_CODES_FOR_RETRY
) {
  return (o$: Observable<T>) =>
    o$.pipe(
      retryWhen((attempts$: Observable<any>) => {
        const errorWhenMaxCountExceeded = (err, idx) => {
          if (err instanceof TimeoutError || (err.status && retryCodes.length && !retryCodes.includes(err.status))) {
            return throwError(() => err);
          }

          return idx + 1 > maxCount ? throwError(() => err) : of(idx);
        };
        return attempts$.pipe(switchMap(errorWhenMaxCountExceeded), delay(backoffMs));
      })
    );
}
