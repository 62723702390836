<div class="grid-max-width" [ngClass]="{ 'grid-col-12': !noGrid }">
  <div
    [ngClass]="{
      'sm-col-2-11 md-col-3-10 xl-col-4-9': !noGrid,
    }"
    class="col-f pr-xlarge-y align-center"
  >
    <fcom-icon class="nordic-blue-900-text icon-large" [name]="iconName"></fcom-icon>
    <h1 class="h1r pr-xsmall-t nordic-blue-900-text">{{ title ? (title | finLocalization) : 'Sorry!' }}</h1>
    <h2 class="h2r pr-xsmall-t nordic-blue-900-text">
      {{ subtitle ? (subtitle | finLocalization) : 'Something went wrong' }}
    </h2>
    <p class="font-body-1 pr-medium-y">
      {{
        info
          ? (info | finLocalization)
          : 'An unexpected error occurred. Unfortunately you will need to restart your session. We apologize for the inconvenience.'
      }}
    </p>
    <button
      *ngIf="showButton"
      class="btn btn--white border nordic-blue-900-border font-body-1 bold-type"
      (click)="handleButtonClick()"
    >
      {{ cta ? (cta | finLocalization) : 'Refresh' }}
    </button>
  </div>
</div>
