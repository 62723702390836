import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ToggleSwitchStatus } from './interfaces';

@Component({
  selector: 'fcom-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent {
  readonly ToggleSwitchStatus = ToggleSwitchStatus;

  /** Name of the toggle switch*/
  @Input()
  name: string;

  /** There are three different toggle switch status,the switch actions will also go with following follow
   * CHECKED-> ACTIVE -> UNCHECKED, UNCHECKED -> ACTIVE-> CHECKED
   * ACTIVE: clear button without grey overlay, it is always checked
   * CHECKED: button turns green with grey overlay
   * UNCHECKED: button turns grey
   */
  @Input()
  status: ToggleSwitchStatus;

  /** Disable the switch to do further actions*/
  @Input()
  disabled = false;

  @Input()
  labelledBy?: string;

  /** Emit the switch status*/
  @Output()
  statusChange: EventEmitter<{ name: string; status: ToggleSwitchStatus }> = new EventEmitter();

  handleStatusChange(event: Event, name: string, status: ToggleSwitchStatus): void {
    /* Stopping propagation is to stop div.switch element from firing another click event.
     * Note: even with this stopPropagation fix this component fires an event twice when interacted by clicking (keyboard interaction fires once).
     */
    event.stopPropagation();

    /*if the switch status is already CHECKED,
    preventDefault to make sure the button not directly turn from CHECKED TO UNCHECKED.*/
    if (status === ToggleSwitchStatus.CHECKED) {
      event.preventDefault();
    }

    if (!this.disabled) {
      this.statusChange.emit({ name, status });
    }
  }
}
