<div class="relative ps-medium-t" [formGroup]="group">
  <select
    #inputElement
    [class.has-initial-value]="hasInitialValue$ | async"
    [class.form-field-empty]="group.get(modelName).value === ''"
    [id]="name"
    [formControlName]="modelName"
    [attr.name]="name"
    [attr.autocomplete]="autocomplete"
    (focus)="focus.emit([translationPrefix, 'info'].join('.'))"
    (blur)="blur.emit([translationPrefix, 'info'].join('.'))"
    (change)="onChangeEvent($event)"
  >
    <option *ngIf="!defaultOption" value selected disabled>
      {{ [translationPrefix, 'default'].join('.') | finLocalization }}
    </option>
    <option *ngIf="defaultOption" [value]="defaultOption.value">
      {{ [translationPrefix, 'default'].join('.') | finLocalization }}
    </option>
    <ng-container *ngFor="let option of selectOptions$ | finObsToArray">
      <option
        *ngIf="(option | async)?.name !== undefined"
        [value]="(option | async)?.value"
        [disabled]="(option | async)?.disabled"
      >
        {{ (option | async)?.name }}
      </option>
    </ng-container>
  </select>
  <label [attr.for]="name" class="font-body-2 overflow-hidden truncate">
    <span class="is-vishidden" aria-hidden="true" *ngIf="!!hiddenLabel">{{ hiddenLabel }}</span>
    <span>
      {{ [translationPrefix, 'label'].join('.') | finLocalization }}
      <span *ngIf="required"> *</span>
    </span>
  </label>
</div>

<div
  class="error-label font-body-2"
  *ngIf="group.get(modelName)?.invalid && group.get(modelName)?.touched && !hideError"
>
  <span class="error-900-text">
    {{ [translationPrefix, firstErrorKey()].join('.') | finLocalization }}
  </span>
</div>
