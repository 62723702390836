import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FinnairTravelEndpoint } from '@fcom/dapi/api/models';
import { LocationInfoMap } from '@fcom/dapi/interfaces';

@Component({
  selector: 'fin-itinerary-timeline-leg-endpoint',
  styleUrls: ['../itinerary.scss', '../arrow-block.scss'],
  templateUrl: './leg-endpoint.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryTimelineLegEndpointComponent {
  @Input()
  endpoint: FinnairTravelEndpoint;

  @Input()
  locations: LocationInfoMap;

  @Input()
  oldEndpointDateTime: string;

  @Input()
  oldEndpointLocationCode: string;
}
