<div class="flex flex--row stepper" *ngIf="mode === StepperMode.HORIZONTAL">
  <div
    role="tablist"
    *ngFor="let step of steps; let last = last; let index = index"
    [class.ms-xxsmall-r]="!last"
    [ngClass]="{
      'align-center center flex-1 flex--basis0': showLabels,
    }"
  >
    <button
      role="tab"
      (click)="selectStep($event, steps[index])"
      [attr.aria-label]="step.ariaLabel | finLocalization"
      [class.inverted]="theme === StepperTheme.DARK"
      [disabled]="index > activeStep"
    >
      <span
        *ngIf="showLabels"
        [ngClass]="{
          'nordic-blue-100-text regular-type': index > activeStep && theme === StepperTheme.LIGHT,
          'nordic-blue-300-text': index > activeStep && theme === StepperTheme.DARK,
        }"
        [class.white-text]="theme === StepperTheme.DARK"
        [class.medium-type]="index <= activeStep"
        class="block ps-small-b font-body-2"
        >{{ step.title | finLocalization }}</span
      >
      <div class="atom inline-block rounded-large ps-xxsmall-y ps-xxlarge-l center"></div>
    </button>
  </div>
</div>
<div *ngIf="mode === StepperMode.RADIAL" class="flex flex--middle">
  <div
    *ngIf="showLabels"
    class="ps-xsmall-r font-body-1 bold-type"
    [class.nordic-blue-400-text]="theme === StepperTheme.LIGHT"
    [class.white-text]="theme === StepperTheme.DARK"
  >
    {{ steps?.[activeStep]?.title || steps?.[activeStep]?.ariaLabel | finLocalization }}
  </div>
  <div class="progress relative" [class]="theme">
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax="100"
      [attr.aria-valuenow]="(activeStep / steps.length) * 100"
    >
      <circle class="progress-bg" cx="24" cy="24" r="22" stroke-width="4" fill="none" />
      <circle
        class="progress-circle"
        cx="24"
        cy="24"
        r="22"
        [attr.stroke]="theme === StepperTheme.LIGHT ? '' : ''"
        stroke-width="4"
        fill="none"
        stroke-dasharray="138.23"
        stroke-linecap="round"
        [attr.stroke-dashoffset]="(1 - (activeStep + 1) / steps.length) * 138.23"
        transform="rotate(-90 24 24)"
        id="progress-circle-bar"
      />
    </svg>
    <div class="progress-text absolute z1 nordic-blue-900-text bold-type font-body-1">
      {{ activeStep + 1 }}/{{ steps.length }}
    </div>
  </div>
</div>
