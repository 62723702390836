import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { finShare } from '@fcom/rx';
import { ConfigService, TzDate } from '@fcom/core';
import { LanguageService } from '@fcom/ui-translate';
import { FinnairOrderPreview, FinnairOrderPreviewsResponse } from '@fcom/dapi/api/models';
import { OrderService } from '@fcom/dapi/api/services';

@Injectable()
export class OrderPreviewsService {
  constructor(
    private orderService: OrderService,
    private languageService: LanguageService,
    private configService: ConfigService
  ) {}

  getUpcomingTrips(): Observable<FinnairOrderPreviewsResponse> {
    return this.orderService
      .fetchOrderPreviews(this.configService.cfg.orderUrl, {
        locale: this.languageService.localeValue,
      })
      .pipe(
        map((response: FinnairOrderPreviewsResponse) => ({
          ...response,
          orderPreviews: this.getSortedOrderPreviewsByDepartureDate(response.orderPreviews),
        })),
        finShare()
      );
  }

  private getSortedOrderPreviewsByDepartureDate(orderPreviews: FinnairOrderPreview[]): FinnairOrderPreview[] {
    return [...(orderPreviews || [])].sort((previousOrder, nextOrder) =>
      this.compareOrderPreviewDates(previousOrder, nextOrder)
    );
  }

  private compareOrderPreviewDates(previousOrder: FinnairOrderPreview, nextOrder: FinnairOrderPreview): number {
    const previousDate = TzDate.of(previousOrder.bounds[0].departure.dateTime).toLocalDate();
    const nextDate = TzDate.of(nextOrder.bounds[0].departure.dateTime).toLocalDate();
    if (previousDate.gt(nextDate)) {
      return 1;
    }
    if (previousDate.lt(nextDate)) {
      return -1;
    }
    return 0;
  }
}
