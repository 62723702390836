import { HttpErrorResponse } from '@angular/common/http';

import { RestError, StatusCode } from '../api/models';

export class DapiHttpErrorResponse extends HttpErrorResponse {
  error: DapiError;

  constructor(init: Partial<DapiHttpErrorResponse>) {
    super(init);
  }
}

export interface DapiError extends Omit<RestError, 'status'> {
  level: DapiErrorLevel;
  key: DapiErrorCode;
  status: DapiErrorStatus;
  uxErrorType?: string; // Added in ux
}

export enum DapiErrorCode {
  OFFER_NOT_FOUND = 'OFFER_NOT_FOUND',
  UNABLE_TO_RETRIEVE_OFFER = 'UNABLE_TO_RETRIEVE_OFFER',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
  AMADEUS_MESSAGE = 'AMADEUS_MESSAGE',
  INVALID_HMAC = 'INVALID_HMAC',
  ORDER_NOT_FOUND = 'ORDER_NOT_FOUND',
  ORDER_GROUP_ERROR = 'ORDER_GROUP_ERROR',
  ORDER_CANCELLED = 'ORDER_ALREADY_CANCELLED',
  ORDER_GROUP_FETCH_WITH_INFANT = 'ORDER_GROUP_FETCH_WITH_INFANT_ERROR',
  GROUP_ORDER_NOT_TICKETED = 'GROUP_ORDER_NOT_TICKETED',
  ALTERNATIVE_OFFERS_NOT_FOUND = 'ALTERNATIVE_OFFERS_NOT_FOUND',
  UNABLE_TO_DISPLAY_ORDER = 'AMADEUS_UNABLE_TO_DISPLAY_ORDER_ERROR',
  SEVERAL_RECORDS_FOR_TRAVEL_DOCUMENT = 'SEVERAL_RECORDS_FOUND_FOR_TRAVEL_DOC',
  ORDER_UNEXPECTED_ERROR = 'ORDER_UNEXPECTED_ERROR',
  ORDER_TIMEOUT_ERROR = 'ORDER_TIMEOUT_ERROR',
  CHECK_IN_ACCEPT_UNRECOVERABLE_FAILURE = 'CHECK_IN_ACCEPT_UNRECOVERABLE_FAILURE',
  CHECK_IN_ACCEPT_FAILED = 'CHECK_IN_ACCEPT_FAILED',
}

export enum DapiErrorLevel {
  ERROR = 'ERROR',
}

export enum UXDapiError {
  GENERIC_HTTP_ERROR = 'GENERIC_HTTP_ERROR', // When we don't have status we map it to this
}

export const DapiErrorStatus = { ...StatusCode, ...UXDapiError };
export type DapiErrorStatus = StatusCode | UXDapiError;
