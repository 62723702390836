/* eslint-disable */
export enum FinnairAlternativeOfferConfirmationStatusEnum {
  STARTED = 'STARTED',
  REBOOKED = 'REBOOKED',
  DOCUMENTS_ISSUED = 'DOCUMENTS_ISSUED',
  CHECKED_IN = 'CHECKED_IN',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR'
}

