/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { catchErrorAsJson } from '@fcom/rx';
import { DapiBaseService } from '../dapi-base-service';
import { DapiHttpResponse } from '../dapi-http-response';
import { DapiRequestBuilder } from '../dapi-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CallerType } from '../models/caller-type';
import { SeatmapModel } from '../models/seatmap-model';
import { SeatmapSvgAndControlData } from '../models/seatmap-svg-and-control-data';
import { StandaloneSeatmapParams } from '../models/standalone-seatmap-params';


/**
 * Seatmap related resources
 */
@Injectable({
  providedIn: 'root',
})
export class SeatmapService extends DapiBaseService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  /**
   * Path part for operation getSeatmapFromCart
   */
  static readonly GetSeatmapFromCartPath = '/api/seatmap';

  /**
   * getSeatmapByCart.
   *
   * Gets the seatmap from cart as SVG and control data as JSON for a flight
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSeatmapFromCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeatmapFromCart$Response(rootUrl: string, params: {
    cartId?: string;
    orderId?: string;
    flightId: string;
    currencyCode?: string;
    departureDateTime?: string;
    version?: number;
    locale?: string;
    hash?: string;
    Caller?: CallerType;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<SeatmapSvgAndControlData>> {
    const rb = new DapiRequestBuilder(rootUrl, SeatmapService.GetSeatmapFromCartPath, 'get');

    if (params) {
      rb.query('cartId', params.cartId, {});
      rb.query('orderId', params.orderId, {});
      rb.query('flightId', params.flightId, {});
      rb.query('currencyCode', params.currencyCode, {});
      rb.query('departureDateTime', params.departureDateTime, {});
      rb.query('version', params.version, {});
      rb.query('locale', params.locale, {});
      rb.query('hash', params.hash, {});
      rb.header('Caller', params.Caller, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<SeatmapSvgAndControlData>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * getSeatmapByCart.
   *
   * Gets the seatmap from cart as SVG and control data as JSON for a flight
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSeatmapFromCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeatmapFromCart(rootUrl: string, params: {
    cartId?: string;
    orderId?: string;
    flightId: string;
    currencyCode?: string;
    departureDateTime?: string;
    version?: number;
    locale?: string;
    hash?: string;
    Caller?: CallerType;
  },
  context?: HttpContext

): Observable<SeatmapSvgAndControlData> {
    return this.getSeatmapFromCart$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<SeatmapSvgAndControlData>) => r.body as SeatmapSvgAndControlData)
    );
  }

  /**
   * Path part for operation getStandaloneSeatmap
   */
  static readonly GetStandaloneSeatmapPath = '/api/seatmap';

  /**
   * getStandaloneSeatmap.
   *
   * Return the seat map for a given flight as SVG and control data as JSON
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getStandaloneSeatmap()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getStandaloneSeatmap$Response(rootUrl: string, params: {
    Caller?: CallerType;
    body: StandaloneSeatmapParams
  },
  context?: HttpContext

): Observable<DapiHttpResponse<SeatmapSvgAndControlData>> {
    const rb = new DapiRequestBuilder(rootUrl, SeatmapService.GetStandaloneSeatmapPath, 'post');

    if (params) {
      rb.header('Caller', params.Caller, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<SeatmapSvgAndControlData>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * getStandaloneSeatmap.
   *
   * Return the seat map for a given flight as SVG and control data as JSON
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getStandaloneSeatmap$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getStandaloneSeatmap(rootUrl: string, params: {
    Caller?: CallerType;
    body: StandaloneSeatmapParams
  },
  context?: HttpContext

): Observable<SeatmapSvgAndControlData> {
    return this.getStandaloneSeatmap$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<SeatmapSvgAndControlData>) => r.body as SeatmapSvgAndControlData)
    );
  }

  /**
   * Path part for operation getSeatmapFromOrder
   */
  static readonly GetSeatmapFromOrderPath = '/api/seatmap/by-order';

  /**
   * getSeatmapFromOrder.
   *
   * Gets the seatmap from order as SVG and control data as JSON
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSeatmapFromOrder()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeatmapFromOrder$Response(rootUrl: string, params: {
    cartId?: string;
    orderId?: string;
    flightId: string;
    currencyCode?: string;
    departureDateTime?: string;
    version?: number;
    locale?: string;
    hash?: string;
    Caller?: CallerType;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<SeatmapSvgAndControlData>> {
    const rb = new DapiRequestBuilder(rootUrl, SeatmapService.GetSeatmapFromOrderPath, 'get');

    if (params) {
      rb.query('cartId', params.cartId, {});
      rb.query('orderId', params.orderId, {});
      rb.query('flightId', params.flightId, {});
      rb.query('currencyCode', params.currencyCode, {});
      rb.query('departureDateTime', params.departureDateTime, {});
      rb.query('version', params.version, {});
      rb.query('locale', params.locale, {});
      rb.query('hash', params.hash, {});
      rb.header('Caller', params.Caller, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<SeatmapSvgAndControlData>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * getSeatmapFromOrder.
   *
   * Gets the seatmap from order as SVG and control data as JSON
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSeatmapFromOrder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeatmapFromOrder(rootUrl: string, params: {
    cartId?: string;
    orderId?: string;
    flightId: string;
    currencyCode?: string;
    departureDateTime?: string;
    version?: number;
    locale?: string;
    hash?: string;
    Caller?: CallerType;
  },
  context?: HttpContext

): Observable<SeatmapSvgAndControlData> {
    return this.getSeatmapFromOrder$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<SeatmapSvgAndControlData>) => r.body as SeatmapSvgAndControlData)
    );
  }

  /**
   * Path part for operation getSeatmapJson
   */
  static readonly GetSeatmapJsonPath = '/api/seatmap/json';

  /**
   * getSeatmapJson.
   *
   * Gets the seatmap as JSON for a flight
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSeatmapJson()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeatmapJson$Response(rootUrl: string, params: {
    cartId?: string;
    orderId?: string;
    flightId: string;
    currencyCode?: string;
    departureDateTime?: string;
    version?: number;
    locale?: string;
    Caller?: CallerType;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<SeatmapModel>> {
    const rb = new DapiRequestBuilder(rootUrl, SeatmapService.GetSeatmapJsonPath, 'get');

    if (params) {
      rb.query('cartId', params.cartId, {});
      rb.query('orderId', params.orderId, {});
      rb.query('flightId', params.flightId, {});
      rb.query('currencyCode', params.currencyCode, {});
      rb.query('departureDateTime', params.departureDateTime, {});
      rb.query('version', params.version, {});
      rb.query('locale', params.locale, {});
      rb.header('Caller', params.Caller, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<SeatmapModel>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * getSeatmapJson.
   *
   * Gets the seatmap as JSON for a flight
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSeatmapJson$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeatmapJson(rootUrl: string, params: {
    cartId?: string;
    orderId?: string;
    flightId: string;
    currencyCode?: string;
    departureDateTime?: string;
    version?: number;
    locale?: string;
    Caller?: CallerType;
  },
  context?: HttpContext

): Observable<SeatmapModel> {
    return this.getSeatmapJson$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<SeatmapModel>) => r.body as SeatmapModel)
    );
  }

  /**
   * Path part for operation getSeatmapSvg
   */
  static readonly GetSeatmapSvgPath = '/api/seatmap/svg';

  /**
   * getSeatmapSvg.
   *
   * Gets the seatmap as SVG for a flight
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSeatmapSvg()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeatmapSvg$Response(rootUrl: string, params: {
    cartId?: string;
    orderId?: string;
    flightId: string;
    currencyCode?: string;
    departureDateTime?: string;
    version?: number;
    locale?: string;
    Caller?: CallerType;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<string>> {
    const rb = new DapiRequestBuilder(rootUrl, SeatmapService.GetSeatmapSvgPath, 'get');

    if (params) {
      rb.query('cartId', params.cartId, {});
      rb.query('orderId', params.orderId, {});
      rb.query('flightId', params.flightId, {});
      rb.query('currencyCode', params.currencyCode, {});
      rb.query('departureDateTime', params.departureDateTime, {});
      rb.query('version', params.version, {});
      rb.query('locale', params.locale, {});
      rb.header('Caller', params.Caller, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'image/svg+xml',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<string>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * getSeatmapSvg.
   *
   * Gets the seatmap as SVG for a flight
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSeatmapSvg$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeatmapSvg(rootUrl: string, params: {
    cartId?: string;
    orderId?: string;
    flightId: string;
    currencyCode?: string;
    departureDateTime?: string;
    version?: number;
    locale?: string;
    Caller?: CallerType;
  },
  context?: HttpContext

): Observable<string> {
    return this.getSeatmapSvg$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<string>) => r.body as string)
    );
  }

}
