import { Component, Input } from '@angular/core';

import { NEVER, Observable } from 'rxjs';

import { MenuItem, NavigationMenuService } from '@fcom/common';
import { CmsContentType, CmsFormType, CmsTemplate, CmsViewType, PageViewType } from '@fcom/core-api';

@Component({
  selector: 'cms-child-page',
  templateUrl: './cms-child-page.component.html',
  styleUrls: ['./cms-child-page.component.scss'],
})
export class CmsChildPageComponent {
  @Input()
  template$: Observable<CmsTemplate>;

  @Input()
  title$: Observable<string> = NEVER;

  activeNaviSection$: Observable<MenuItem>;

  public readonly DOCTYPE: typeof CmsContentType = CmsContentType;
  public readonly VIEWTYPE: typeof CmsViewType = CmsViewType;
  public readonly FORMTYPE: typeof CmsFormType = CmsFormType;
  public readonly PAGETYPE: typeof PageViewType = PageViewType;

  constructor(private navigationMenuService: NavigationMenuService) {
    this.activeNaviSection$ = this.navigationMenuService.activeNaviSection;
  }
}
