import { Component, Input } from '@angular/core';

import { IconLibrary } from '@finnairoyj/fcom-ui-styles/enums';

import { type IconName } from '@fcom/ui-components';

@Component({
  selector: 'fin-section-review-item',
  templateUrl: 'section-review-item.component.html',
  styleUrls: ['section-review-item.component.scss'],
})
export class SectionReviewItemComponent {
  @Input()
  icon: IconName;

  @Input()
  category: IconLibrary = IconLibrary.SVG_LIBRARY;
}
