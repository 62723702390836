import { Observable, of, switchMap, forkJoin, map } from 'rxjs';

import { LocationRouteCffService } from '@fcom/core';
import { AmDestinationOffer, AmDestinationResponseItem } from '@fcom/destination-search/interfaces';
import { BlockOfferData } from '@fcom/cms';

export const mapToAmDestinationOffer = (amDestination: AmDestinationResponseItem): AmDestinationOffer => ({
  dest: amDestination.dest,
  bookUrl: amDestination.bookUrl,
  price: {
    // AM service is only avalible in finnish market
    currencyCode: 'EUR',
    amount: amDestination.price.toString(),
  },
  duration: amDestination.duration,
});

export const getOfferWithAmDestination = (
  offer: BlockOfferData[],
  amDestinationList$: Observable<AmDestinationResponseItem[]>,
  locationRouteCffService: LocationRouteCffService
): Observable<BlockOfferData[]> =>
  amDestinationList$.pipe(
    switchMap((amDestinationList: AmDestinationResponseItem[]) =>
      forkJoin(offer.map((offer) => addAmDestination(amDestinationList, offer, locationRouteCffService)))
    )
  );

const addAmDestination = (
  amDestinationList: AmDestinationResponseItem[],
  offer: BlockOfferData,
  locationRouteCffService: LocationRouteCffService
): Observable<BlockOfferData> => {
  if (!amDestinationList || !offer) {
    return of(offer);
  }
  const amDestination = amDestinationList.find((amDestination) => amDestination.dest === offer?.destination);
  //If target destination match none of destination in am list, then convert the city name to finnish to check again
  if (!amDestination) {
    return locationRouteCffService.getLocaleCityName(offer?.destination, 'fi_FI').pipe(
      map((localeCityName) => {
        const destination = amDestinationList.find((amDestination) => amDestination.destName_fi === localeCityName);
        return { ...offer, amDestination: destination ? mapToAmDestinationOffer(destination) : null };
      })
    );
  }
  return of({ ...offer, amDestination: amDestination ? mapToAmDestinationOffer(amDestination) : null });
};
