import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  PLATFORM_ID,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Subscription } from 'rxjs';

import { unsubscribe } from '@fcom/core/utils';
import { HtmlString } from '@fcom/core-api/interfaces';

@Component({
  selector: 'fin-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss'],
})
export class InputCheckboxComponent implements OnInit, OnDestroy {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  group: UntypedFormGroup;
  @Input()
  modelName: string;
  @Input()
  name: string;
  @Input()
  translationPrefix: string;
  @Input()
  block = false;
  @Input()
  useLabelContent = false;
  @Input()
  labelClass = 'font-body-1';
  @Input()
  hideError = false;
  @Input()
  errorClasses = '';
  @Input()
  containerClass = '';
  @Input()
  innerHtml: HtmlString;
  @Input()
  required: boolean;
  @Input()
  labelDataTestId: string;

  @Output()
  focus: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  blur: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  checkboxChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('inputElement', { static: true })
  inputElement: ElementRef;

  errorKey: string;
  subscription: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  ngOnInit(): void {
    const control = this.group.get(this.modelName);

    this.subscription = control.valueChanges.subscribe((s) => {
      if (isPlatformBrowser(this.platform) && this.inputElement.nativeElement !== this.document.activeElement) {
        this.inputElement.nativeElement.value = s;
      }
    });
    this.subscription.add(
      control.statusChanges.subscribe(() => {
        this.errorKey =
          !this.hideError && control.invalid
            ? [this.translationPrefix, Object.keys(control.errors)[0]].join('.')
            : undefined;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription = unsubscribe(this.subscription);
  }
}
