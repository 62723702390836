import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { Observable, combineLatest } from 'rxjs';
import { switchMap, distinctUntilChanged } from 'rxjs/operators';

import { CurrencyService } from '@fcom/core';

/**
 * Asynchronous offers price pipe that supports localizations.
 * Uses currencyService to get price in localized format.
 */
@Injectable()
@Pipe({
  name: 'finOffersPrice',
  pure: true,
})
export class OffersPricePipe implements PipeTransform {
  constructor(private currencyService: CurrencyService) {}

  transform(
    offerPrice$: Observable<string>,
    offerCurrencyCode$: Observable<string>,
    doPriceRounding = false
  ): Observable<string> {
    return combineLatest([
      offerPrice$.pipe(distinctUntilChanged()),
      offerCurrencyCode$.pipe(distinctUntilChanged()),
    ]).pipe(
      switchMap(([amount, currencyCode]) =>
        this.currencyService.formatPrice({ amount, currencyCode }, { roundPrice: doPriceRounding })
      )
    );
  }
}
