import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'fin-booking-header-summary-item',
  templateUrl: './booking-header-summary-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingHeaderSummaryItemComponent {
  @Input() type: string;
  @Input() isClickable = false;
  @Input() customClasses: string;
  @Output() clickAction: EventEmitter<void> = new EventEmitter();
}
