import { OndLocationsResponse, FlightPair, OndLink, OndLocationsCountryData, OndLocationData } from '../interfaces';

const COUNTRY_CODE_LENGTH = 2;
const DESTINATION_CODE_LENGTH = 3;

export const OND_LINK_LIST_SLICE_SIZE = 12;

export const LOCATION_API_DEBOUNCE_TIME_MS = 200;

export const isCountryCode = (code) => code && code.length === COUNTRY_CODE_LENGTH;

export const isDestinationCode = (code) => code && code.length === DESTINATION_CODE_LENGTH;

export const transformLocationCode = (locationCode): string => {
  let code = locationCode || '';

  // @TODO: override master to Norway for testing purposes since int is not supported yet
  if (code === 'master') {
    code = 'no';
  }

  return isCountryCode(code) ? code.toLowerCase() : code.toUpperCase();
};

export const getFlightType = (flightPair: FlightPair, complex = true): string => {
  if (flightPair.origin && flightPair.destination) {
    if ((isDestinationCode(flightPair.origin) && isDestinationCode(flightPair.destination)) || !complex) {
      return 'fromTo';
    } else if (isCountryCode(flightPair.origin) && isDestinationCode(flightPair.destination)) {
      return 'to';
    } else {
      return 'countryToCountry';
    }
  } else if (flightPair.destination) {
    return 'to';
  } else {
    if (isDestinationCode(flightPair.origin) || !complex) {
      return 'from';
    } else {
      return 'fromCountry';
    }
  }
};

export const constructOndHeadingPath = (headingType: string, flightPair: FlightPair): string => {
  const basePath = 'ondMarketing.headings';
  return `${basePath}.${getFlightType(flightPair)}.${headingType}`;
};

export const getLocationName = (code: string, locationData: OndLocationsResponse): string => {
  const location = locationData[code];

  const cityName = location ? (location as OndLocationData).cityName : '';
  const countryName = location ? (location as OndLocationsCountryData).countryName : '';

  return cityName ? cityName : countryName;
};

export const replaceSpaces = (text: string, separator = '-'): string => {
  return text.replace(/[ ]/g, separator);
};

export const encodeBraces = (text: string): string => {
  return text.replace(/\(/g, '%28').replace(/\)/g, '%29');
};

export const encodeIgnoredSEOTitle = (text: string): string => {
  return encodeBraces(encodeURIComponent(replaceSpaces(text)));
};

export const sortLinkArray = (
  linkArray: OndLink[],
  locationData: OndLocationsResponse,
  sortProperty: string
): OndLink[] => {
  const cloneArray = linkArray.slice(0);
  cloneArray.sort((a, b) => {
    if (locationData[a.origin] && locationData[b.origin]) {
      // sort by origin first
      if (locationData[a.origin][sortProperty] < locationData[b.origin][sortProperty]) {
        return -1;
      }
      if (locationData[a.origin][sortProperty] > locationData[b.origin][sortProperty]) {
        return 1;
      }
    }
    if (a.destination && b.destination && locationData[a.destination] && locationData[b.destination]) {
      // if same origin, sort by destination
      if (locationData[a.destination][sortProperty] < locationData[b.destination][sortProperty]) {
        return -1;
      }
      if (locationData[a.destination][sortProperty] > locationData[b.destination][sortProperty]) {
        return 1;
      }
    }
    return 0;
  });
  return cloneArray;
};

export const getCurrentCountry = (langValue: string): string => {
  return langValue.split('-')[0] !== 'master' ? langValue.split('-')[0] : 'no'; // @TODO: handle master language
  // override master to Norway for testing purposes since int is not supported yet
};
