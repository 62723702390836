<section *ngIf="content$ | finMap: 'tabs' | async as tabs">
  <h3 class="font-heading-3" *ngIf="(content$ | finMap: 'teaserTitle' | async) !== ''">
    {{ content$ | finMap: 'teaserTitle' | async }}
  </h3>
  <div
    *ngIf="(content$ | finMap: 'teaserText' | async) !== null"
    class="pr-medium-b"
    [innerHTML]="content$ | finMap: 'teaserText' | async | finSafe: 'html'"
  ></div>

  <div role="tablist" class="tablist">
    <div *ngFor="let tab of tabs" class="tab flex flex--column flex--align-stretch">
      <fcom-button
        [text]="tab.title"
        [aria]="{
          selected: (currentTab$ | async) === tab,
          controls: 'panel-' + tab.content.segment,
          role: 'tab',
        }"
        [removeFromNavigation]="(currentTab$ | async) === tab"
        [theme]="
          (currentTab$ | async) === tab ? ButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE : ButtonTheme.LIGHT_TERTIARY_GHOST
        "
        [id]="'tab-' + tab.content.segment"
        (click)="selectTab(tab)"
      ></fcom-button>
    </div>
  </div>

  <div
    *ngFor="let tab of tabs"
    tabindex="0"
    role="tabpanel"
    [id]="'panel-' + tab.content.segment"
    [class.is-hidden]="(currentTab$ | async) !== tab"
    [attr.aria-labelledby]="'tab-' + tab.content.segment"
    [attr.aria-hidden]="(currentTab$ | async) !== tab"
  >
    <fin-cms-json-content [template$]="{ main: [tab.content] } | finToObservable"></fin-cms-json-content>
  </div>
</section>
