import { Injectable } from '@angular/core';

import { JsConfig } from '../../interfaces';
import { jsConfig } from './jsConfig';

/**
 * Reads config from global variable
 */
@Injectable()
export class ConfigService {
  jsConfig: JsConfig = jsConfig;

  get cmsUrl(): string {
    return this.jsConfig.cmsUrl;
  }

  get sendLoginTokenForUrls(): string[] {
    return [
      this.jsConfig.offersUrl,
      this.jsConfig.cartUrl,
      this.jsConfig.orderUrl,
      this.jsConfig.loyaltyServices.legacy.apiProfileUrl,
      `${this.jsConfig.loyaltyServices.legacy.apiUrl}/profile`,
      `${this.jsConfig.oneUpgradeRootUrl}/members`,
      `${this.jsConfig.oneUpgradeRootUrl}/v2/members`,
    ];
  }

  get useDapiUATForUrls(): string[] {
    return [
      this.jsConfig.offersUrl,
      this.jsConfig.instantSearchUrl,
      this.jsConfig.cartUrl,
      this.jsConfig.orderUrl,
      this.jsConfig.servicesUrl,
    ];
  }

  get sendApiGwKeyForUrls(): string[] {
    return [
      this.jsConfig.loyaltyServices.memberService.gauthUrl,
      this.jsConfig.loyaltyServices.legacy.apiProfileUrl,
      this.jsConfig.loyaltyServices.legacy.apiUrl,
      this.jsConfig.loyaltyServices.legacy.loyaltyMemberUrl,
    ];
  }

  get cfg(): JsConfig {
    return this.jsConfig;
  }

  get ondMarketingOffersUrl(): string {
    return this.jsConfig.ondMarketingOffersUrl;
  }

  // Do not add new getters for direct attributes of jsConfig, just use get cfg() instead
  // It is ok to add getters for child attributes of jsConfig attributes.
}
