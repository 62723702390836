import { Observable } from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';

import { safeMap } from '@fcom/rx';
import { isPresent } from '@fcom/core/utils';

import { BaseState, LatLng, NavigatorState, TimeData } from '../interfaces';

export const navigatorState =
  () =>
  (state$: Observable<BaseState>): Observable<NavigatorState> =>
    state$.pipe(
      safeMap((s: BaseState) => s.navigator),
      filter(isPresent)
    );

export const serverTime =
  () =>
  (state$: Observable<BaseState>): Observable<TimeData> =>
    state$.pipe(
      navigatorState(),
      safeMap((s: NavigatorState): TimeData => s.timeData),
      filter(isPresent),
      filter((timeData: TimeData) => isPresent(timeData.timeOffset)),
      filter((timeData: TimeData) => isPresent(timeData.serverMillisFromEpoch)),
      distinctUntilChanged(
        (t1: TimeData, t2: TimeData) =>
          t1.timeOffset === t2.timeOffset && t1.serverMillisFromEpoch === t2.serverMillisFromEpoch
      )
    );

export const browserGeolocation =
  () =>
  (state$: Observable<BaseState>): Observable<LatLng> =>
    state$.pipe(
      navigatorState(),
      safeMap((s: NavigatorState) => s.browserGeolocation),
      filter(isPresent),
      distinctUntilChanged()
    );

export const isBrowserGeolocationLoading =
  () =>
  (state$: Observable<BaseState>): Observable<boolean> =>
    state$.pipe(
      navigatorState(),
      safeMap((s: NavigatorState) => Boolean(s.browserGeolocationLoading)),
      distinctUntilChanged()
    );

export const akamaiGeolocation =
  () =>
  (state$: Observable<BaseState>): Observable<LatLng> =>
    state$.pipe(
      navigatorState(),
      safeMap((s: NavigatorState) => s.akamaiGeolocation),
      filter(isPresent),
      distinctUntilChanged()
    );
