import {
  DataCloudFlightSelectionData,
  DataCloudIdentityData,
  OfferListFetchParams,
  PreFlightSearchParams,
} from '../../interfaces';

export abstract class DataCloudService {
  abstract preFlightSearch(data: PreFlightSearchParams): void;

  abstract flightSearch(data: OfferListFetchParams): void;

  abstract flightSelectionSelect(data: DataCloudFlightSelectionData[]): void;

  abstract paxDetails(data: DataCloudIdentityData): void;

  abstract mmbPaxDetails(data: DataCloudIdentityData): void;

  abstract signupPaxDetails(data: DataCloudIdentityData): void;

  abstract subscriberId(subscriberId: string): void;

  abstract completePurchase(): void;
}
