import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

@Component({
  selector: 'fin-link',
  templateUrl: 'link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  url: string;

  @Input()
  text: string;
}
