import { type IconSet } from '../../ui-components';

export interface BenefitItemTargetedDescription {
  targetType: string;
  /**
   * detail description for example used in order level
   */
  description: string;
}

export interface BenefitItemV2 {
  benefitItemKey: BenefitItemKeyEnum;
  /**
   * Title of the tier benefit, secnerio such as loyalty page
   */
  title: string;
  description: Description;
  icon?: IconSet;
  amount: number;
  /**
   * the start date of this benefit.
   */
  effectiveFrom?: string;
  /**
   * the end date of this benefit.
   */
  effectiveTo?: string;
  displayTags: string;
}

export enum BenefitItemKeyEnum {
  AdvanceSeatReservation = 'advanceSeatReservation',
  Wifi = 'wifi',
  Lounge = 'lounge',
  ContactInfo = 'contactInfo',
  ConditionalConfirmedSeat = 'conditionalConfirmedSeat',
  Drink = 'drink',
  ExtraBags = 'extraBags',
  Newspaper = 'newspaper',
  OneWorldBenefits = 'oneWorldBenefits',
  PointsAndOffers = 'pointsAndOffers',
  PrioritySecurity = 'prioritySecurity',
  Snack = 'snack',
  SpecialBags = 'specialBags',
  OneWorldBags = 'oneWorldBags',
  TaxFreeShoppingOutsideEU = 'taxFreeShoppingOutsideEU',
  TierStatusHold = 'tierStatusHold',
  MemberOffers = 'memberOffers',
  CarryonBagTier = 'carryonBagTier',
  MilestoneReward = 'milestoneReward',
  SportEquipment = 'sportEquipment',
  FastTrack = 'fastTrack',
  SnackDrink = 'snackDrink',
  GiveAwayCard = 'giveAwayCard',
  EasyBookings = 'easyBookings',
  TierAward = 'tierAward',
}

export interface Description {
  /**
   * General description for tier benefit, scenerio such as loyalty content, mobile profile content
   */
  generalDescription: string;
  /**
   * targeted description for tier benefit, scenerio such as order level content
   */
  targetedDescription: Array<BenefitItemTargetedDescription>;
}

export interface TierLevelBenefits {
  [tierLevel: string]: BenefitItemV2[];
}
