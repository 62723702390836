import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { share, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { combineTemplate } from '@fcom/rx';
import { Amount } from '@fcom/dapi';

import { Offer } from '../../interfaces';

// TODO: is this component really used? Should we remove it
@Component({
  selector: 'fin-offer-details',
  templateUrl: './offer-details.component.html',
})
export class OfferDetailsComponent implements OnInit {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  offer: Offer;
  @Input()
  currencyCode$: Observable<string>;
  @Input()
  language$: Observable<string>;

  linkUrl$: Observable<string>;
  price$: Observable<Amount>;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.price$ = combineTemplate<Amount>({
      amount: this.offer.price.toString(),
      currencyCode: this.currencyCode$,
    }).pipe(share());

    this.linkUrl$ = this.language$.pipe(
      map((lang) => {
        return `/${lang}/booking?destination=${this.offer.iata}`;
      }),
      share()
    );
  }

  navigate(event: Event): void {
    event.preventDefault();
    this.router.navigateByUrl((event.currentTarget as HTMLAnchorElement).pathname);
  }
}
