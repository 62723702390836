import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { isNull, isUndefined } from '@fcom/core/utils';

import { equals } from '../utils/equals';
import { LanguageService } from '../services/language.service';

/**
 * Asynchronous plural localization pipe
 * - Uses LanguageService to localize the input
 *
 * @example
 *
 * When having
 *
 * localizations = {
 *   foo: {
 *     singular: "{0} piece"
 *     plural: "{0} pieces"
 *   },
 *   bar: {
 *     singular: "{count} piece"
 *     plural: "{count} pieces"
 *   },
 * }
 *
 * The pipe would translate the following
 *
 * 'foo' | finPluralLocalization: 1 -> '1 piece',
 * 'foo' | finPluralLocalization: 2 -> '2 pieces'
 * 'bar' | finPluralLocalization: 1: 'count' -> '1 piece'
 * 'bar' | finPluralLocalization: 2: 'count' -> '2 pieces'
 */
@Pipe({
  name: 'finPluralLocalization',
  pure: false,
})
export class PluralLocalizationPipe implements PipeTransform, OnDestroy {
  private async: AsyncPipe;
  private cached$: Observable<string>;
  private cachedKey: string;
  private cachedCount: number;
  private cachedFieldName: string;

  constructor(
    private ref: ChangeDetectorRef,
    private languageService: LanguageService
  ) {
    this.async = new AsyncPipe(this.ref);
  }

  transform(key: string, count: number, fieldName?: string): string {
    if (isUndefined(key) || isNull(key) || typeof count !== 'number') {
      return undefined;
    }

    if (this.valueChanged(key, count, fieldName)) {
      this.update(key, count, fieldName);
    }

    return this.async.transform(this.cached$);
  }

  ngOnDestroy(): void {
    this.async.ngOnDestroy();
  }

  private valueChanged(key: string, count: number, fieldName: string): boolean {
    return (
      !this.cached$ || key !== this.cachedKey || fieldName !== this.cachedFieldName || !equals(count, this.cachedCount)
    );
  }

  private update(key: string, count: number, fieldName: string): void {
    const keyToUse = count === 1 ? `${key}.singular` : `${key}.plural`;
    this.cached$ = this.languageService
      .translate(keyToUse, fieldName ? { [fieldName]: count } : [count])
      .pipe(map((str) => (str ? str : '')));
    this.cachedKey = key;
    this.cachedFieldName = fieldName;
    this.cachedCount = count;
  }
}
