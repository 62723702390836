import { HttpErrorResponse, HttpResponse } from '@angular/common/http';

import { MappedError, MappedHttpError } from '../interfaces';

const jsonStringifyErrorReplacer = (_key: string, value: any) => {
  if (value instanceof Error) {
    const cache = [];
    return Object.getOwnPropertyNames(value).reduce((acc, propertyKey) => {
      if (cache.indexOf(propertyKey) !== -1) {
        // Circular reference
        return acc;
      }
      cache.push(propertyKey);
      acc[propertyKey] = value[propertyKey];
      return acc;
    }, {});
  }
  return value;
};

/**
 * Use for better Sentry error messages in extra parameter.
 * @param error
 */
export const mapErrorForSentry = (error: any): MappedHttpError | MappedError => {
  if (error instanceof HttpErrorResponse) {
    return {
      ...error,
      headers: error.headers.keys().reduce((all, key) => {
        all[key] = error.headers.getAll(key).join(', ');
        return all;
      }, {}),
    };
  }
  return {
    json: JSON.stringify(error, jsonStringifyErrorReplacer),
    errorCode: error?.errorCode || error?.key,
    errorMessage: error?.errorMessage || error?.message,
  };
};

const errorResponse = (response: HttpResponse<any> | HttpErrorResponse | any) => {
  if (response instanceof HttpResponse) {
    return response.body || {};
  }
  if (response instanceof HttpErrorResponse) {
    return response.error || {};
  }
  return response;
};

const jsonOrString = (o) => {
  if (typeof o === 'string') {
    try {
      return JSON.parse(o);
    } catch (e) {
      return { message: o };
    }
  }
  return o;
};

/**
 * Use for mapping errors in observable catch blocks
 * XHR errors should always respond with Response object and other errors we pass through.
 * @param error
 * @returns {any}
 */
export const mapError = <T>(error: HttpResponse<any> | HttpErrorResponse | any = {}): T => {
  return jsonOrString(errorResponse(error));
};
