import { ROUTER_NAVIGATED } from '@ngrx/router-store';

import { pathIsWithinHardcodedPath } from '@fcom/core/utils/app-url-utils';
import { RootPaths } from '@fcom/core/constants';

import { UpgradeOfferState } from '../store.interface';
import { UpgradeOfferActions } from '../actions';

export const initialState: UpgradeOfferState = {};

export function upgradeOfferReducer(state = initialState, action: UpgradeOfferActions.ActionsUnion): UpgradeOfferState {
  switch (action.type) {
    case UpgradeOfferActions.setUpgradeOffer.type:
      return action.offer;
    case UpgradeOfferActions.clearUpgradeOffer.type:
      return initialState;
    case ROUTER_NAVIGATED: {
      const isInBooking = pathIsWithinHardcodedPath(action.payload.routerState.url, RootPaths.BOOKING_ROOT);
      if (!isInBooking) {
        return initialState;
      }
      return state;
    }
    default:
      return state;
  }
}
