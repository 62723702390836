import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UrlSegment } from '@angular/router';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { uniqueBy, valuesOf } from '@fcom/core/utils';
import { finShare } from '@fcom/rx';

import { getCurrentCountry, OND_LINK_LIST_SLICE_SIZE } from '../services/utils';
import {
  createCityToCityLink,
  createCountryToCountryLink,
  createFromCityLink,
  createFromCountryLink,
} from '../services/link-utils';
import {
  OndLink,
  OndLinkObject,
  OndLinkTranslations,
  OndMarketingRoutesPath,
  OndPageType,
  OndPairEnhanced,
  OndPairEnhancedResponse,
} from '../interfaces/';

const byCityName = (a: OndPairEnhanced, b: OndPairEnhanced) => {
  if (a.originCityName === b.originCityName) {
    return a.destinationCityName < b.destinationCityName ? -1 : a.destinationCityName > b.destinationCityName ? 1 : 0;
  } else {
    return a.originCityName < b.originCityName ? -1 : 1;
  }
};

const byCountryName = (a: OndPairEnhanced, b: OndPairEnhanced) => {
  if (a.originCountryName === b.originCountryName) {
    return a.destinationCountryName < b.destinationCountryName
      ? -1
      : a.destinationCountryName > b.destinationCountryName
        ? 1
        : 0;
  } else {
    return a.originCountryName < b.originCountryName ? -1 : 1;
  }
};

@Component({
  selector: 'fin-ond-marketing-cross-linking',
  templateUrl: './ond-marketing-cross-linking.component.html',
  styleUrls: ['./ond-marketing-cross-linking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OndMarketingCrossLinkingComponent implements OnInit {
  readonly ondMarketingRoutesPath = OndMarketingRoutesPath;
  readonly ondPageTypes = OndPageType;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  route: UrlSegment[];
  @Input()
  links$: Observable<OndPairEnhancedResponse>;
  @Input()
  ondPageType: OndPageType;

  ondLinks$: Observable<OndLink[]>;
  fromLink$: Observable<OndLinkObject>;
  fromCountry$: Observable<OndLinkObject>;
  linkListSliceSize: number = OND_LINK_LIST_SLICE_SIZE;

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    const linkTranslations$ = this.languageService.translateMultiple([
      'ondMarketing.seoUrl.to',
      'ondMarketing.seoUrl.from',
      'ondMarketing.seoUrl.fromTo',
    ]);

    const linksAndTranslations$ = combineLatest([this.links$, linkTranslations$]);

    this.fromLink$ = linksAndTranslations$.pipe(
      map(([ondPairs, translations]) => {
        const linkTranslations = this.handleLinkTranslations(translations);

        const locationCode = this.route[1].path.toUpperCase();
        const ondFromLocationCode = valuesOf(ondPairs).find((pair) => pair.origin === locationCode);

        const locationName =
          this.route.some((route) => route.path === OndMarketingRoutesPath.CITY_TO_CITY) &&
          this.route.length > 1 &&
          ondFromLocationCode
            ? ondFromLocationCode.originCityName || ondFromLocationCode.originCountryName
            : '';
        const link =
          this.ondPageType === OndPageType.LANDING && this.route.length > 1
            ? createFromCityLink(this.languageService.langValue, locationCode, linkTranslations, ondFromLocationCode)
            : '';

        return { link, locationName };
      })
    );

    this.fromCountry$ = linksAndTranslations$.pipe(
      map(([ondPairs, translations]) => {
        const linkTranslations = this.handleLinkTranslations(translations);

        const countryCode =
          this.ondPageType === OndPageType.LANDING
            ? this.route[1].path
            : getCurrentCountry(this.languageService.langValue);

        const countryName =
          valuesOf(ondPairs).find((pair) => pair.originCountry === countryCode)?.originCountryName ?? '';

        return {
          countryCode,
          link: createFromCountryLink(this.languageService.langValue, countryCode, countryName, linkTranslations),
          locationName: countryName,
        };
      })
    );

    this.ondLinks$ = linksAndTranslations$.pipe(
      map(([ondPairs, translations]) => {
        const linkTranslations = this.handleLinkTranslations(translations);
        if (this.shouldCreateCountryToCountryLinks()) {
          return this.createCountryToCountryLinks(ondPairs, linkTranslations);
        } else if (
          this.ondPageType === OndPageType.LISTING &&
          this.route.some((route) => route.path === OndMarketingRoutesPath.FROM)
        ) {
          return this.createFromCityLinks(ondPairs, linkTranslations);
        } else {
          return this.createCityToCityLinks(ondPairs, linkTranslations);
        }
      }),
      finShare()
    );
  }

  private shouldCreateCountryToCountryLinks(): boolean {
    return (
      (this.links$ && this.route.some((route) => route.path === OndMarketingRoutesPath.FROM_COUNTRY)) ||
      (this.ondPageType === OndPageType.LISTING &&
        this.route.some((route) => route.path === OndMarketingRoutesPath.COUNTRY_TO_COUNTRY))
    );
  }

  private createCountryToCountryLinks(
    ondPairs: OndPairEnhancedResponse,
    linkTranslations: OndLinkTranslations
  ): OndLink[] {
    const pairsToUse =
      this.ondPageType === OndPageType.LISTING ? valuesOf(ondPairs).sort(byCountryName) : valuesOf(ondPairs);

    return pairsToUse
      .map((ondPair) => ({
        destination: ondPair.destinationCountry,
        origin: ondPair.originCountry,
        link: createCountryToCountryLink(
          this.languageService.langValue,
          ondPair.originCountry,
          ondPair.destinationCountry,
          linkTranslations,
          ondPair
        ),
        originCityName: ondPair.originCityName,
        originCountryName: ondPair.originCountryName,
        destinationCityName: ondPair.destinationCityName,
        destinationCountryName: ondPair.destinationCountryName,
      }))
      .filter(uniqueBy((pair) => pair.originCountryName + pair.destinationCountryName))
      .filter((pair) => pair.origin !== pair.destination);
  }

  private createFromCityLinks(ondPairs: OndPairEnhancedResponse, linkTranslations: OndLinkTranslations): OndLink[] {
    const pairsToUse =
      this.ondPageType === OndPageType.LISTING ? valuesOf(ondPairs).sort(byCityName) : valuesOf(ondPairs);

    return pairsToUse
      .map((ondPair) => ({
        origin: ondPair.origin,
        link: createFromCityLink(this.languageService.langValue, ondPair.origin, linkTranslations, ondPair),
        originCityName: ondPair.originCityName,
        originCountryName: ondPair.originCountryName,
        destinationCityName: ondPair.destinationCityName,
        destinationCountryName: ondPair.destinationCountryName,
      }))
      .filter(uniqueBy((pair) => pair.origin));
  }

  private createCityToCityLinks(ondPairs: OndPairEnhancedResponse, linkTranslations: OndLinkTranslations): OndLink[] {
    const pairsToUse =
      this.ondPageType === OndPageType.LISTING ? valuesOf(ondPairs).sort(byCityName) : valuesOf(ondPairs);
    return pairsToUse.map((ondPair) => ({
      origin: ondPair.origin,
      destination: ondPair.destination,
      link: createCityToCityLink(
        this.languageService.langValue,
        ondPair.origin,
        ondPair.destination,
        linkTranslations,
        ondPair
      ),
      originCityName: ondPair.originCityName,
      originCountryName: ondPair.originCountryName,
      destinationCityName: ondPair.destinationCityName,
      destinationCountryName: ondPair.destinationCountryName,
    }));
  }

  private handleLinkTranslations([to, from, fromTo]: string[]): OndLinkTranslations {
    return {
      to,
      from,
      fromTo,
    };
  }
}
