import { createReducer, on } from '@ngrx/store';

import { NotificationActions } from '../actions';
import { NotificationsState } from '../store.interface';

export const initialState: NotificationsState = {
  viewedNotifications: [],
};

const notificationReducer = createReducer(
  initialState,
  on(NotificationActions.setViewedNotification, (state, { id }) => ({
    ...state,
    viewedNotifications: [...state.viewedNotifications, id],
  })),
  on(NotificationActions.clearViewedNotification, (state, { id }) => ({
    ...state,
    viewedNotifications: state.viewedNotifications.filter((idInStore) => idInStore !== id),
  })),
  on(NotificationActions.clearAllViewedNotification, (state) => ({
    ...state,
    viewedNotifications: [],
  }))
);

export function reducer(state: NotificationsState, action: NotificationActions.NotificationsActionsUnion) {
  return notificationReducer(state, action);
}

export const getViewedNotifications = (state: NotificationsState) => state.viewedNotifications;
