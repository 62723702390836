<div
  [formGroup]="parentForm"
  class="radio-group"
  role="radiogroup"
  [attr.aria-labelledby]="'radio-group-label-' + controlName"
>
  <div *ngIf="translationPrefix && titleText" class="font-body-1 ps-xxsmall-b" data-testid="radio-item-group-title">
    <span class="nordic-blue-400-text">
      {{ translationPrefix + '.label' | finLocalization }}
      <span *ngIf="isRequired"> *</span>
    </span>
  </div>
  <div
    class="flex"
    [class]="theme"
    [ngClass]="{
      'flex--row': theme === RadioButtonTheme.ROUND && isHorizontal,
      'flex--column': theme === RadioButtonTheme.ROUND && !isHorizontal,
    }"
  >
    <ng-container *ngFor="let option of options; let first = first; let last = last">
      <fcom-radio-item
        [ngClass]="{
          'first-radio': first,
          'last-radio': last,
          'ps-medium-l': !first && isHorizontal && theme === RadioButtonTheme.ROUND,
          'ps-xxsmall-y': theme === RadioButtonTheme.ROUND,
        }"
        [parentForm]="parentForm"
        [controlName]="controlName"
        [translationPrefix]="translationPrefix"
        [label]="option.label"
        [value]="option.value"
        [name]="name"
        [theme]="theme"
        [first]="first"
        [last]="last"
        [renewedComponent]="true"
        [descriptionText]="option.descriptionText"
        [isSensitive]="isSensitive"
      ></fcom-radio-item>
    </ng-container>
  </div>

  <div
    *ngIf="translationPrefix && errorText && control.invalid && control.touched"
    class="error-label font-body-2 ps-small-t"
    aria-live="polite"
  >
    <span *ngIf="control.errors?.required" class="error-900-text">
      {{ translationPrefix + '.required' | finLocalization }}
    </span>
  </div>

  <div
    *ngIf="translationPrefix && helperText && !(control.invalid && control.touched)"
    class="helper-label font-body-2 ps-xxsmall-t"
  >
    <span class="nordic-blue-400-text">
      {{ translationPrefix + '.helper' | finLocalization }}
    </span>
  </div>
</div>
