/* eslint-disable */

/**
 * Boarding pass send status
 */
export enum CheckInBoardingPassByEmailResponseStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}

