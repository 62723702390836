import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  ViewChild,
} from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { NEVER, Observable, Subscription } from 'rxjs';

import { SentryLogger } from '@fcom/core';
import { unsubscribe } from '@fcom/core/utils/unsubscribe';

import { CarouselTheme } from './enums';
import { type IconName } from '../icons';

const SCROLL_RATIO_DEFAULT = 0.9;
export type carouselAlign = 'left' | 'center' | 'right';

@Component({
  selector: 'fcom-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterViewInit, OnDestroy {
  public readonly CarouselTheme = CarouselTheme;

  @ViewChild('content', { static: true })
  content: ElementRef;

  @ViewChild('contentWrap', { static: true })
  contentWrap: ElementRef;

  @Input()
  leftIcon: IconName = SvgLibraryIcon.CHEVRON_LEFT;

  @Input()
  rightIcon: IconName = SvgLibraryIcon.CHEVRON_RIGHT;

  @Input()
  align: carouselAlign = 'left';

  @Input()
  navigateFullWidth = false;

  @Input({ required: true })
  prevButtonAriaLabel: string;

  @Input({ required: true })
  nextButtonAriaLabel: string;

  @Input()
  hasGreyBackground = false;

  @Input()
  whiteIcon = false;

  @Input() theme: CarouselTheme = CarouselTheme.DEFAULT;

  @Input() contentChanged$: Observable<void> = NEVER;

  prevButtonVisible = false;
  nextButtonVisible = false;
  offset = 0;
  subscription = new Subscription();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() private logger?: SentryLogger
  ) {}

  scrollRatio = (): number => (this.navigateFullWidth ? 1 : SCROLL_RATIO_DEFAULT);

  @HostListener('window:resize')
  onResize(): void {
    this.updateButtonVisibility();
  }

  @HostListener('scroll')
  onScroll(): void {
    this.offset = this.contentWrap.nativeElement.scrollLeft;
    this.updateButtonVisibility();
  }

  ngAfterViewInit(): void {
    if (this.content.nativeElement.children && this.content.nativeElement.children.length > 0) {
      setTimeout(() => {
        this.updateButtonVisibility();
        this.changeDetectorRef.detectChanges();
      }, 0);
    } else {
      this.logger?.error('Tried to initialize carousel without content');
    }

    this.subscription.add(
      this.contentChanged$.subscribe(() => {
        setTimeout(() => {
          this.updateButtonVisibility();
          this.changeDetectorRef.detectChanges();
        }, 0);
      })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  scrollPrev(): void {
    const containerWidth = this.contentWrap.nativeElement.offsetWidth;
    const scrollRatio = this.scrollRatio();
    const newOffset = this.offset > containerWidth ? this.offset - containerWidth * scrollRatio : 0;
    this.contentWrap.nativeElement.scrollLeft = newOffset;
    this.offset = newOffset;
    this.updateButtonVisibility();
  }

  scrollNext(): void {
    const containerWidth = this.contentWrap.nativeElement.offsetWidth as number;
    const contentWidth = this.content.nativeElement.children[0].scrollWidth as number;
    const scrollRatio = this.scrollRatio();
    const newOffset =
      contentWidth > containerWidth + this.offset + containerWidth * scrollRatio
        ? this.offset + containerWidth * scrollRatio
        : contentWidth - containerWidth;
    this.contentWrap.nativeElement.scrollLeft = newOffset;
    this.offset = newOffset;
    this.updateButtonVisibility();
  }

  scrollTo(value: number): void {
    this.contentWrap.nativeElement.scrollLeft = value;
  }

  private updateButtonVisibility(): void {
    const containerWidth = this.contentWrap.nativeElement.offsetWidth as number;
    const contentWidth = this.content.nativeElement.children[0].scrollWidth as number;

    if (contentWidth > containerWidth) {
      if (containerWidth + this.offset >= contentWidth) {
        this.prevButtonVisible = true;
        this.nextButtonVisible = false;
      } else if (this.offset > 0) {
        this.prevButtonVisible = this.nextButtonVisible = true;
      } else {
        this.prevButtonVisible = false;
        this.nextButtonVisible = true;
      }
    } else {
      this.prevButtonVisible = this.nextButtonVisible = false;
    }
  }
}
