import { GlobalBookingTravelClass } from '@fcom/core';
import { uniqueBy } from '@fcom/core/utils';
import {
  Bound,
  FareFamily,
  FareInformation,
  FinnairBoundFareFamily,
  FinnairCabinClass,
  Offer,
} from '@fcom/dapi/api/models';
import { FlightTimeOption } from '@fcom/common-booking/interfaces/flight-filters.interface';
import { shouldTimeBeKept } from '@fcom/common-booking/utils/date-utils';
import { BoundFareFamily } from '@fcom/common/interfaces/booking';

import { ItineraryDetails } from '../interfaces';

const cabinClassPriorityOrder = [
  GlobalBookingTravelClass.FIRST.toString(),
  GlobalBookingTravelClass.BUSINESS.toString(),
  GlobalBookingTravelClass.ECOPREMIUM.toString(),
  GlobalBookingTravelClass.ECONOMY.toString(),
];

export const effectiveCabinClass = (fareFamily: FinnairBoundFareFamily): string => {
  const fareFamilyCabinClasses = fareFamily?.fareInformation
    ?.filter(uniqueBy((fareInformation) => fareInformation.cabinClass))
    .map((fareInformation) => fareInformation.cabinClass)
    .sort((a, b) => cabinClassPriorityOrder.indexOf(a) - cabinClassPriorityOrder.indexOf(b));
  return fareFamilyCabinClasses?.[0] ?? undefined;
};

const cabinClasses = [...cabinClassPriorityOrder].reverse();
const getHighestCabinClassIndex = (fareFamily: FinnairBoundFareFamily): number => {
  return Math.max(
    ...fareFamily.fareInformation.map((fareInfo) => {
      return cabinClasses.indexOf(fareInfo.cabinClass as FinnairCabinClass);
    })
  );
};

export const getHighestCabinClass = (fareFamily: FinnairBoundFareFamily): string => {
  const index = getHighestCabinClassIndex(fareFamily);
  return cabinClasses[index];
};

export const filterAndSortFareFamilyByCabinClass = (
  fareFamilies: FinnairBoundFareFamily[]
): FinnairBoundFareFamily[] => {
  return [...fareFamilies]
    .sort((a, b) => parseFloat(a.totalPrice) - parseFloat(b.totalPrice))
    .filter(uniqueBy((fareFamily) => effectiveCabinClass(fareFamily)))
    .sort((a, b) => {
      return getHighestCabinClassIndex(a) - getHighestCabinClassIndex(b);
    });
};

const sortValue = (number: string): number => +number || Infinity;

export const getBoundFareFamilies = (
  fareFamilies: FareFamily[],
  offers: Offer[],
  bound: Bound,
  type: 'outbound' | 'inbound'
): BoundFareFamily[] => {
  return fareFamilies
    .map((f) => {
      const cheapestOffer = offers
        .filter((offer) =>
          type === 'outbound'
            ? offer.outboundFareFamily === f.fareFamilyCode && offer.outboundId === bound.boundId
            : offer.inboundFareFamily === f.fareFamilyCode && offer.inboundId === bound.boundId
        )
        .sort(
          (a, b) =>
            sortValue(a.totalPointsPrice) - sortValue(b.totalPointsPrice) ||
            sortValue(a.totalPrice) - sortValue(b.totalPrice)
        )[0];
      if (!cheapestOffer) {
        return undefined;
      }
      return {
        ...f,
        fareFamilyCode: f.fareFamilyCode,
        price: type === 'outbound' ? cheapestOffer.outboundPrice : cheapestOffer.inboundPrice,
        totalPrice: cheapestOffer.totalPrice,
        points: type === 'outbound' ? cheapestOffer.outboundPointsPrice : cheapestOffer.inboundPointsPrice,
        totalPoints: cheapestOffer.totalPointsPrice,
        id: bound.boundId,
        quota: bound.quotas[f.fareFamilyCode],
        fareInformation:
          type === 'outbound' ? cheapestOffer.outboundFareInformation : cheapestOffer.inboundFareInformation,
      };
    })
    .filter(Boolean)
    .sort((a, b) => sortValue(a.points) - sortValue(b.points) || sortValue(a.price) - sortValue(b.price));
};

export const isDepartureTimeInGivenOptions = (departureTimes: FlightTimeOption[], departureTime: string): boolean => {
  return !departureTimes || departureTimes.length === 3 || shouldTimeBeKept(departureTimes, departureTime);
};

export const isArrivalTimeInGivenOptions = (arrivalTimes: FlightTimeOption[], arrivalTime: string): boolean => {
  return !arrivalTimes || arrivalTimes.length === 3 || shouldTimeBeKept(arrivalTimes, arrivalTime);
};

export const matchesBoundStopCount = (stopsCount: number, stops: number): boolean => {
  return stopsCount == null || (stopsCount === 0 ? stops === 0 : stops >= stopsCount);
};

export const containsOnlyOneCabin = (
  details: (ItineraryDetails | FareInformation)[],
  cabinClass?: GlobalBookingTravelClass
): boolean => {
  const cClass = cabinClass ?? details?.[0]?.cabinClass;
  return details.every((fi) => fi.cabinClass === cClass);
};
