<div *ngIf="details" class="rounded-large border white-bg">
  <div
    class="pr-small-x pr-xsmall-y font-body-1 flex flex--space-between"
    [class.border-bottom]="expandOpen"
    data-testid="flight-review-card-title-bar"
  >
    <div data-testid="flight-review-card-title">
      {{ title }}
      {{ ' ' }}
      {{ details?.departure?.dateTime | finDateFormat: 'DATE' }}
    </div>
    <div>
      <fcom-tag data-testid="flight-review-card-fare-family-label" class="mr-xsmall-l" [class.mr-xsmall-r]="expandMode">
        {{ fareFamilyLabel }}
      </fcom-tag>
      <button
        *ngIf="expandMode"
        (click)="toggleExpand()"
        class="nordic-blue-900-text medium-type"
        [attr.aria-controls]="expandContainerId"
        [attr.aria-expanded]="expandOpen"
        [id]="expandButtonId"
        data-testid="chevron-button"
      >
        <fcom-icon [class.rotate-active]="expandOpen" class="chevron" [name]="SvgLibraryIcon.CHEVRON_DOWN"> </fcom-icon>
        <span *ngIf="expandButtonHiddenText" class="is-vishidden">
          {{ expandButtonHiddenText }}
        </span>
      </button>
    </div>
  </div>
  <fcom-expand class="block" [open]="expandOpen" [ariaId]="expandContainerId" [ariaLabelledBy]="expandButtonId">
    <fcom-notification *ngIf="notificationTranslationKey" class="pr-small-t pr-small-x">{{
      notificationTranslationKey | finLocalization
    }}</fcom-notification>
    <fin-ufo-with-modify-button
      [locations]="locations"
      [flightOrBound]="details"
      [viewDetailsClosed]="viewDetailsClosed$ | async"
      [hideModify]="hideModify"
      [hideViewDetails]="hideViewDetails"
      [title]="title"
      (viewDetails)="viewDetails.emit()"
      (modifyClicked)="modifyClicked.emit()"
    ></fin-ufo-with-modify-button>
    <div *ngIf="showDivider" class="border-top-dotted"></div>
    <ng-content select=".bottom-content"></ng-content>
  </fcom-expand>
</div>
