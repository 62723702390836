import { Component, Input, Output, EventEmitter } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { GtmClickOutboundLinkClass } from '@fcom/common';
import { ModalButtons } from '@fcom/ui-components';
import { GtmService } from '@fcom/common/gtm';

@Component({
  selector: 'fin-am-destination-continue-modal',
  templateUrl: './am-destination-continue-modal.component.html',
})
export class AmDestinationContinueModalComponent {
  readonly ModalButtons = ModalButtons;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  constructor(private gtmService: GtmService) {}

  @Input()
  open = false;

  @Input()
  bookUrl: string;

  @Output()
  closeModal: EventEmitter<void> = new EventEmitter();

  navigateToAmBooking(): void {
    const fullUrl = `${this.bookUrl}&utm_content=destination_list`;
    this.gtmService.clickOutboundEvent({
      link_url: fullUrl,
      link_classes: GtmClickOutboundLinkClass.DESTINATION_LISTING_CARD,
      outbound: true,
    });
    // open a new am tab
    window.open(fullUrl, '_blank');
  }
}
