import { Injectable } from '@angular/core';

import { ValidationErrors } from '@fcom/common/utils';
import { LocalDate } from '@fcom/core/utils';
import { FinnairPassengerCode } from '@fcom/dapi/api/models';

@Injectable()
export class PaxValidationService {
  /**
   * Validates the passengers age based on business rules:
   *
   * - Infants must be infants for the whole trip (cannot be 2 years old at the time of last flight's arrival)
   * - Infants must be born -1 days before current day
   * - Children must be at most 11 years of age
   *
   * @param paxType the type of the traveling passenger
   * @param birthDate the passengers birth date (midnight is fine)
   * @param minBirthDateInclusive the minimum local date
   * @param maxBirthDateInclusive the local arrival date and time of the last flight
   * @return {ValidationErrors} errors occurred during validation or `null` if valid.
   */
  validatePaxAge(
    paxType: FinnairPassengerCode | string,
    birthDate: LocalDate,
    minBirthDateInclusive: LocalDate,
    maxBirthDateInclusive: LocalDate
  ): ValidationErrors {
    switch (paxType) {
      case FinnairPassengerCode.C_15:
        return this.validateAge(birthDate, minBirthDateInclusive, maxBirthDateInclusive, 'c15TooOld', 'c15TooYoung');
      case FinnairPassengerCode.CHD:
        return this.validateAge(
          birthDate,
          minBirthDateInclusive,
          maxBirthDateInclusive,
          'childTooOld',
          'childTooYoung'
        );
      case FinnairPassengerCode.INF:
        return this.validateAge(
          birthDate,
          minBirthDateInclusive,
          maxBirthDateInclusive,
          'infantTooOld',
          'infantNotBorn'
        );
      default:
        return this.validateAge(birthDate, null, maxBirthDateInclusive, null, 'adultTooYoung');
    }
  }

  private validateAge(
    birthDate: LocalDate,
    minBirthDateInclusive: LocalDate,
    maxBirthDateInclusive: LocalDate,
    minBirthDateInclusiveErrorKey: string,
    maxBirthDateInclusiveErrorKey: string
  ): ValidationErrors {
    if (birthDate.gt(maxBirthDateInclusive)) {
      return { [maxBirthDateInclusiveErrorKey]: true };
    }
    if (minBirthDateInclusive && birthDate.lt(minBirthDateInclusive)) {
      return { [minBirthDateInclusiveErrorKey]: true };
    }
    return null;
  }
}
