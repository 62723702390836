<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<ng-container [ngSwitch]="level">
  <h1 [ngClass]="extraClasses" *ngSwitchCase="1">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h1>
  <h2 [ngClass]="extraClasses" *ngSwitchCase="2">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h2>
  <h4 [ngClass]="extraClasses" *ngSwitchCase="4">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h4>
  <h5 [ngClass]="extraClasses" *ngSwitchCase="5">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h5>
  <h6 [ngClass]="extraClasses" *ngSwitchCase="6">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h6>
  <h3 [ngClass]="extraClasses" *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h3>
</ng-container>
