import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'finSafe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {
    this.sanitizer = sanitizer;
  }

  transform(input: string, type: string): any {
    let value: any;

    if (!input) {
      input = '';
    }

    switch (type) {
      case 'html':
        value = this.sanitizer.bypassSecurityTrustHtml(input);
        break;
      case 'style':
        value = this.sanitizer.bypassSecurityTrustStyle(input);
        break;
      case 'script':
        value = this.sanitizer.bypassSecurityTrustScript(input);
        break;
      case 'url':
        value = this.sanitizer.bypassSecurityTrustUrl(input);
        break;
      case 'resource':
        value = this.sanitizer.bypassSecurityTrustResourceUrl(input);
        break;
      default:
        value = input;
        break;
    }

    if (value === '') {
      throw new Error(`Illegal type '${type}' given.`);
    }

    return value;
  }
}
