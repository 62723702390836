<div class="relative dn-mobile">
  <fcom-button
    [theme]="ButtonTheme.LIGHT_TERTIARY_GHOST"
    [text]="(countries$ | finMap: 'name' | async) + ' - ' + (currentLanguage$ | async | uppercase)"
    [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.LANGUAGE, position: IconPosition.LEFT }"
    (clickBtn)="openLanguageSelector()"
    data-testid="open-language-selector-button"
    fcomPopover
    [content]="contentPopover"
    [options]="popoverOptions"
  >
  </fcom-button>
</div>
<div class="relative dn-tablet-up">
  <fcom-button
    [theme]="ButtonTheme.LIGHT_TERTIARY_GHOST"
    [text]="(countries$ | finMap: 'code' | async) + ' - ' + (currentLanguage$ | async | uppercase)"
    [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.LANGUAGE, position: IconPosition.LEFT }"
    (clickBtn)="openLanguageSelector()"
    data-testid="open-language-selector-button"
    fcomPopover
    [content]="contentPopover"
    [options]="popoverOptions"
  >
  </fcom-button>
</div>

<ng-template #contentPopover>
  <div class="language-selector" data-testid="language-selector">
    <fin-country-and-language-selector
      [showWarning]="showWarning"
      (applySelection)="selectLanguageAndRedirect($event)"
      (closeClick)="closeLanguageSelector()"
    ></fin-country-and-language-selector>
  </div>
</ng-template>
