import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { map, Observable } from 'rxjs';

import { CmsCollection, CmsTaxonomyGeneral } from '@fcom/core-api';

@Component({
  selector: 'cms-accordion',
  templateUrl: './cms-accordion.component.html',
  styleUrls: ['cms-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsAccordionComponent implements OnInit {
  @Input()
  content$: Observable<CmsCollection>;

  mappedContent$: Observable<CmsCollection>;

  routeFragment: string;

  @Input()
  withPictures = false;

  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TAXONOMY: typeof CmsTaxonomyGeneral = CmsTaxonomyGeneral;

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.routeFragment = this.activatedRoute.snapshot.fragment;
    /**
     * Avoid accordion items printing the title twice.
     */
    this.mappedContent$ = this.content$.pipe(
      map((content) => ({
        ...content,
        items: content.items.map((item) => ({
          ...item,
          subjectTaxonomyTags: (item.subjectTaxonomyTags || []).concat([CmsTaxonomyGeneral.NO_TITLE]),
        })),
      }))
    );
  }
}
