import { Pipe, PipeTransform } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { getIn, isPresent } from '@fcom/core/utils';

@Pipe({
  name: 'finMap',
})
export class MapPipe implements PipeTransform {
  transform<T, U extends keyof T>(input: Observable<T>, selector: U | [U]): Observable<T[U]>;
  transform(input: Observable<unknown>, selector: string | string[]): Observable<any>;
  transform<T, U extends keyof T>(input: T, selector: U | [U]): T[U];
  transform(input: unknown, selector: string | string[]): any;
  transform(input: unknown, selector: string | string[]): unknown {
    if (input && typeof (input as any[]).map === 'function') {
      return (input as any[]).map((inp) => getIn(inp, selector));
    } else if (input && input instanceof Observable) {
      return input.pipe(map((inp) => getIn(inp, selector)));
    } else if (!isPresent(selector)) {
      return input;
    }
    return getIn(input, selector);
  }
}
