import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { ButtonSize, ButtonTheme, IconButtonSize, IconButtonTheme } from '../../buttons';
import { type IconName, type IconSet } from '../../icons';
import { LoaderTheme } from '../../loader';

export enum NotificationTheme {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ALERT = 'alert',
  SUSTAINABILITY = 'sustainability',
  CAMPAIGN = 'campaign',
  TRANSPARENT = 'transparent',
}

export enum NotificationLayout {
  BLOCK = 'block',
  EDGE_TO_EDGE = 'edge_to_edge',
  OVERLAY = 'overlay',
}

export enum NotificationButtonStyle {
  BUTTON = 'button',
  LINK = 'link',
}

export interface NotificationI18n {
  closeAriaLabel: string;
}

/**
 * A component to display varied forms of notifications.
 *
 * Zeplin designs are available [here](https://zpl.io/adG00Wl)
 *
 * There is many options available, but in many cases you won't need most of these.
 *
 * @example
 * <fcom-notification
 *   [theme]="theme"
 *   [layout]="layout"
 *   [isClosable]="isClosable"
 *   [linkText]="linkText"
 *   [linkUrl]="linkUrl"
 *   [iconName]="SvgLibraryIcon.INFO_BLOCK"
 *   [contentSpaceClass]="ContentSpaceClass.PX_SMALL_Y"
 *   (linkClick)="linkClick($event)"
 *   (closeClick)="closeClick($event)">
 *   {{text}}
 * </fcom-notification>
 */
@Component({
  selector: 'fcom-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnInit, OnChanges {
  readonly NotificationButtonStyle = NotificationButtonStyle;
  readonly NotificationLayout = NotificationLayout;
  readonly NotificationTheme = NotificationTheme;

  readonly ButtonSize = ButtonSize;
  readonly ButtonTheme = ButtonTheme;
  readonly IconButtonSize = IconButtonSize;
  readonly IconButtonTheme = IconButtonTheme;
  readonly LoaderTheme = LoaderTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @HostBinding('class') hostClass = 'block';

  /**
   * What color scheme to use for the component
   */
  @Input()
  theme: NotificationTheme = NotificationTheme.INFO;

  /**
   * Whether the component should be block, full width or toast
   */
  @Input()
  layout: NotificationLayout = NotificationLayout.BLOCK;

  /**
   * Determines the style of buttons in the notification component,
   * allowing links to be shown instead of traditional buttons.
   */
  @Input()
  buttonStyle: NotificationButtonStyle;

  /**
   * Override the close icon
   */
  @Input()
  closeIcon: IconSet = {
    category: IconLibrary.SVG_LIBRARY,
    name: SvgLibraryIcon.CLOSE_DELETE,
  };

  /**
   * Displays a loading animation.
   */
  @Input()
  isLoading = false;

  /**
   * Whether to show the close icon or not
   */
  @Input()
  isClosable = false;

  @Input()
  title: string;

  /**
   * Whether to display link text wrapped (on the same line) or not (on a new line).
   */
  @Input()
  horizontal = false;

  /**
   * In case of EDGE_TO_EDGE layout is used without the need of wrapping the content in columns,
   * then this flag should be set to true
   */
  @Input()
  excludeColumns = false;

  /**
   * Reduces paddings, font size and icon size
   */
  @Input()
  compact = false;

  /**
   * The text to use for a link. If no URL is passed it will be rendered as a button. Otherwise, it
   * will be an A tag.
   */
  @Input()
  linkText: string;

  /**
   * The url to go to. If you wish to handle this behavior yourself you can listen to the linkClick
   * event and `event.preventDefault` to stop the browser changing the page.
   */
  @Input()
  linkUrl: string;

  /**
   * Whether the link should open in a new window or not. Only applicable when linkText + linkUrl
   * is provided.
   */
  @Input()
  linkOpenInNewWindow = false;

  /**
   * The text to use for a secondary link. If no URL is passed it will be rendered as a button. Otherwise, it
   * will be an A tag.
   */
  @Input()
  secondaryLinkText: string;

  /**
   * The url to go with secondary link. If you wish to handle this behavior yourself you can listen to the linkClick
   * event and `event.preventDefault` to stop the browser changing the page.
   */
  @Input()
  secondaryLinkUrl: string;

  /**
   * Whether the link should open in a new window or not. Only applicable when linkText + linkUrl
   * is provided.
   */
  @Input()
  secondaryLinkOpenInNewWindow = false;

  /**
   * An icon to override the default icon used. Note that each NotificationTheme has a different
   * default icon
   */
  @Input()
  iconName: IconName;

  @Input()
  iconCategory: IconLibrary = IconLibrary.SVG_LIBRARY;

  /**
   * Any i18n labels used by the component. In most cases you can use the default for the component
   */
  @Input()
  i18n: NotificationI18n = {
    closeAriaLabel: 'actions.close',
  };

  /**
   * When the button/a tag is clicked the parent component can listen and handle the event. Note
   * that the event is sent so that you can stopPropagation/preventDefault if needed.
   */
  @Output()
  linkClick = new EventEmitter<Event>();

  /**
   * When the secondary button/a tag is clicked the parent component can listen and handle the event. Note
   * that the event is sent so that you can stopPropagation/preventDefault if needed.
   */
  @Output()
  secondaryLinkClick = new EventEmitter<Event>();

  /**
   * The close button was clicked and the notification should be closed. The parent component
   * should either remove the notification from the dom or hide it in some way.
   */
  @Output()
  closeClick = new EventEmitter<Event>();

  displayedIcon: IconName;

  ngOnInit(): void {
    this.displayedIcon = this.iconName ?? this.getDefaultIconForTheme();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.theme && !this.iconName) || (changes.iconName && !changes.iconName.currentValue)) {
      this.displayedIcon = this.getDefaultIconForTheme();
    }

    if (changes.iconName && changes.iconName.currentValue) {
      this.displayedIcon = changes.iconName.currentValue;
    }
  }

  get notificationStyle(): Record<string, boolean> {
    return {
      'nordic-blue-50-bg': this.theme === NotificationTheme.INFO,
      'success-50-bg': this.theme === NotificationTheme.SUCCESS,
      'alert-50-bg': this.theme === NotificationTheme.WARNING,
      'error-50-bg': this.theme === NotificationTheme.ALERT,
      'birch-50-bg': this.theme === NotificationTheme.SUSTAINABILITY,
      'heather-700-bg': this.theme === NotificationTheme.CAMPAIGN,
      'nordic-blue-900-text': [
        NotificationTheme.INFO,
        NotificationTheme.SUCCESS,
        NotificationTheme.WARNING,
        NotificationTheme.ALERT,
        NotificationTheme.SUSTAINABILITY,
      ].includes(this.theme),
      'white-text': this.theme === NotificationTheme.CAMPAIGN,
      'rounded-large': [NotificationLayout.BLOCK, NotificationLayout.OVERLAY].includes(this.layout),
      shadow: this.layout === NotificationLayout.OVERLAY,
    };
  }

  private getDefaultIconForTheme(): SvgLibraryIcon {
    switch (this.theme) {
      case NotificationTheme.SUCCESS:
        return SvgLibraryIcon.CHECKMARK_BLOCK;
      case NotificationTheme.WARNING:
      case NotificationTheme.ALERT:
        return SvgLibraryIcon.ALERT_TRIANGLE;
      case NotificationTheme.SUSTAINABILITY:
        return SvgLibraryIcon.SUSTAINABILITY;
      case NotificationTheme.CAMPAIGN:
        return SvgLibraryIcon.DISCOUNT;
      case NotificationTheme.INFO:
      case NotificationTheme.TRANSPARENT:
      default:
        return SvgLibraryIcon.INFO_BLOCK;
    }
  }
}
