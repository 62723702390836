import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { OndMarketingDataService } from '@fcom/ond-marketing-landing-core/services';
import { UiComponentsModule } from '@fcom/ui-components';
import { ComponentsModule } from '@fcom/common/components';
import { UiTranslateModule } from '@fcom/ui-translate';

import { DestinationSearchService } from './services/destination-search.service';
import { AmDestinationService } from './services/am-destination.service';
import { AmDestinationContinueModalComponent } from './am-destination-continue-modal/am-destination-continue-modal.component';
@NgModule({
  imports: [CommonModule, UiComponentsModule, ComponentsModule, UiTranslateModule],
  declarations: [AmDestinationContinueModalComponent],
  exports: [AmDestinationContinueModalComponent],
  providers: [DestinationSearchService, OndMarketingDataService, AmDestinationService],
})
export class DestinationSearchModule {}
