<div class="align-center" *ngIf="countryCode$ | async as countryCode">
  <a
    class="df-tablet-up flex--center no-underline nordic-blue-900-text"
    [finLink]="'/'"
    [finElemTracker]="'flight-selection-country-selector'"
    [finElemContext]="TRACKING_CONTEXT"
    [finElemType]="ElementTypes.BUTTON"
    [attr.aria-label]="
      ('menu.language' | finLocalization) +
      ' ' +
      ('countries.' + countryCode | lowercase | finLocalization) +
      ' - ' +
      (lang$ | async | uppercase)
    "
  >
    <div>
      <fcom-icon class="icon-medium ms-xxsmall-r" [name]="SvgLibraryIcon.LANGUAGE"></fcom-icon>
    </div>
    <div>
      <span class="font-body-1 dn-laptop-down"
        >{{ 'countries.' + countryCode | lowercase | finLocalization }} - {{ lang$ | async | uppercase }}</span
      >
      <span class="font-body-1 dn-laptop-up">{{ countryCode }}/{{ lang$ | async | uppercase }}</span>
    </div>
  </a>
</div>
