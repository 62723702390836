import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

import { Subscription } from 'rxjs';

import { unsubscribe } from '@fcom/core/utils';

@Component({
  selector: 'fin-input-field',
  templateUrl: './input-field.component.html',
})
export class InputFieldComponent implements OnInit, OnDestroy {
  @ViewChild('inputElement', { static: true })
  inputElement: ElementRef;

  @Output()
  focus: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  blur: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  change: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  group: UntypedFormGroup;
  @Input()
  hideError: boolean;
  @Input()
  modelName: string;
  @Input()
  name: string;
  @Input()
  autocomplete: string;
  @Input()
  hiddenLabel: string;
  @Input()
  translationPrefix: string;
  @Input()
  required: boolean;
  @Input()
  disabled: boolean;

  @Input()
  set type(type: string) {
    if (type === 'tel') {
      this.inputPattern = '[0-9]*';
    }
    this.inputType = type || 'text';
  }

  inputPattern: string = undefined;
  inputType: string;

  subscription: Subscription;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  ngOnInit(): void {
    this.subscription = this.group.get(this.modelName).valueChanges.subscribe((s) => {
      if (isPlatformBrowser(this.platform) && this.inputElement.nativeElement !== this.document.activeElement) {
        this.inputElement.nativeElement.value = s;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription = unsubscribe(this.subscription);
  }

  firstError(): any {
    const errors = this.group.get(this.modelName).errors;
    if (!errors) {
      return { key: undefined, data: undefined };
    }
    const key = Object.keys(errors).filter((k) => errors[k])[0];
    return {
      key,
      data: errors[key],
    };
  }

  onChangeEvent(event: Event): void {
    const field = this.group.get(this.modelName);

    // Chrome on iOS does not trigger the "input" event on autofill.
    if ((event.target as HTMLInputElement).value !== field.value) {
      field.setValue((event.target as HTMLInputElement).value);
    }

    this.change.emit([this.translationPrefix, 'info'].join('.'));
  }
}
