import { Component, Input } from '@angular/core';

import { PhraseGroup } from '../../interfaces';

@Component({
  selector: 'fin-travel-guide-phrases',
  templateUrl: './travel-guide-phrases.component.html',
  styleUrls: ['./travel-guide-phrases.component.scss'],
})
export class TravelGuidePhrasesComponent {
  @Input()
  phraseGroup: PhraseGroup;
}
