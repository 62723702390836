<section class="cms-full-width-booking-widget relative">
  <fin-content-image
    class="background-image"
    [imageData$]="backgroundImage | finToObservable"
    [aspectRatio$]="aspectRatio$"
  ></fin-content-image>
  <div class="grid-max-width grid-col-12 bg-container">
    <div class="col-1-12 mr-xlarge-y">
      <fin-booking-widget
        [enableAurinkomatkat]="enableAurinkomatkat"
        [defaultLocations$]="defaultLocationPairs | finToObservable"
      ></fin-booking-widget>
    </div>
  </div>
</section>
