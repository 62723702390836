import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { CmsDisclaimer } from '@fcom/core-api';

@Component({
  selector: 'cms-disclaimer',
  styleUrls: ['./cms-disclaimer.component.scss'],
  templateUrl: './cms-disclaimer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsDisclaimerComponent {
  @Input()
  content$: Observable<CmsDisclaimer>;
}
