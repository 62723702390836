import { CmsCampaign } from '../services/cms/cms-data.service';

/**
 * get campaign object if found otherwise set default values
 * @param campaigns list of CmsCampaign objects
 * @param locationCode
 * @returns CmsCampaign object for given locationCode
 */
export const getDestinationInCampaign = (campaigns: CmsCampaign[], locationCode: string): CmsCampaign => {
  const defaultCampaign: CmsCampaign = {
    destinations: [],
    campaignEndText: '',
    enableBlackTeaser: false,
  };

  // returns the first campaign element where the locationCode is present, otherwise return default campaign element
  return (
    campaigns.find(
      (campaign: CmsCampaign) =>
        campaign?.destinations?.some((dest: string) => dest === locationCode) ||
        Object.values(campaign?.destinationsByOrigin || {})
          .flat()
          .some((dest: string) => dest === locationCode)
    ) || defaultCampaign
  );
};
