import { LanguageActions } from '@fcom/core/actions';
import { setIn, deleteIn, DataUtils } from '@fcom/core/utils';

import { LocationsState } from '../../interfaces';
import { LocationActions } from '../actions';

export const initialState: LocationsState = Object.seal({
  language: null,
  locations: {},
  search: {
    locations: [],
    input: null,
  },
});

export function locationsReducer(state = initialState, action: LocationActions.LocationActionsUnion): LocationsState {
  switch (action.type) {
    case LanguageActions.setLanguage.type:
      if (action.language !== state.language) {
        return setIn(initialState, 'language', action.language);
      }
      return state;
    case LocationActions.storeLocationData.type:
      if (!action.location?.locationCode) {
        return state;
      }
      return setIn(state, ['locations', action.location.locationCode], action.location);
    case LocationActions.removeLocationData.type:
      if (!action.location?.locationCode) {
        return state;
      }
      return deleteIn(state, ['locations', action.location.locationCode]);
    case LocationActions.cleanLocationSearch.type:
      return setIn(state, 'search', { locations: [], input: null });
    case LocationActions.updateLocationSearch.type:
      if (!action.searchData) {
        return state;
      }
      return setIn(state, ['search', 'locations'], action.searchData);
    case LocationActions.updateLocationSearchInput.type:
      if (!action.input) {
        return state;
      } else if (state.search.input !== action.input) {
        // Reset locations if input changes
        return DataUtils.wrap(state)
          .setIn(['search', 'locations'], [])
          .setIn(['search', 'input'], action.input)
          .value();
      }
      return setIn(state, ['search', 'input'], action.input);
    default:
      return state;
  }
}

export const getLocationData = (state: LocationsState, locationCode: string) =>
  state?.locations?.[locationCode] || null;
