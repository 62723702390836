<figure *ngFor="let media of mediaObject" class="article-image-wrapper ms-clear-x ms-clear-t mr-medium-b">
  <div *ngIf="media.contentType === 'CMVideo'; else elseBlock">
    <fin-video
      [videoUrl$]="media.dataUrl | finToObservable"
      [videoTitle$]="media.teaserTitle | finToObservable"
    ></fin-video>
  </div>
  <ng-template #elseBlock>
    <fin-content-image
      class="block image-height article-content-image"
      [imageData$]="media | finToObservable"
    ></fin-content-image>
  </ng-template>
  <figcaption
    *ngIf="media.teaserText"
    [innerHTML]="media.teaserText | finSafe: 'html'"
    class="article-image-caption font-body-2 light-type ms-xxsmall-t nordic-blue-900-text"
  ></figcaption>
</figure>
<div class="ms-large-b article-markup" *ngIf="markup" [innerHTML]="markup | finSafe: 'html'"></div>
