<div *ngIf="items$ | async as items" class="ms-large-b">
  <h2 class="mr-small-b">{{ (content$ | async)?.teaserTitle }}</h2>
  <div class="descriptive-link-list flex flex--align-start flex--wrap flex--space-between">
    <div *ngFor="let item of items" class="descriptive-link-item mr-small-b">
      <h3 class="descriptive-link-title font-heading-5 nordic-blue-900-text">{{ item.title }}</h3>
      <div class="descriptive-link-text ms-small-y" [innerHTML]="item.text"></div>
      <div *ngIf="item.target && item.linkText" class="font-body-1 bold-type">
        <a
          [finLink]="item.target.url"
          [external]="item.target.external"
          [attr.target]="item.target.openInNewTab ? '_blank' : ''"
          class="descriptive-link no-decoration nordic-blue-900-text"
        >
          <span
            >{{ item.linkText }}
            <fcom-icon
              class="arrow-icon nordic-blue-900-text icon-small ms-xxsmall-l"
              [name]="SvgLibraryIcon.ARROW_RIGHT"
            ></fcom-icon>
          </span>
        </a>
      </div>
    </div>
  </div>
</div>
