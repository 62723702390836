<hr class="col-1-12 ms-medium-b" *ngIf="relatedTeaser.items.length > 0" />
<div class="col-1-12" data-elastic-exclude>
  <div class="related-article ms-small-b" *ngFor="let article of relatedTeaser.items">
    <a class="no-underline" (click)="emitClick()" [finLink]="article.url">
      <div class="related-title nordic-blue-900-text ps-xxsmall-t font-body-1 medium-type">
        {{ article.teaserTitle }}
      </div>
      <fin-date [date]="article.externallyDisplayedDate"></fin-date>
    </a>
  </div>
</div>
