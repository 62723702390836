/* eslint-disable */
import { NgModule, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SelfReaccService } from './services/self-reacc.service';
import { CheckinService } from './services/checkin.service';
import { OrderService } from './services/order.service';
import { OrderChangeService } from './services/order-change.service';
import { AdvancePassengerInformationService } from './services/advance-passenger-information.service';
import { EmergencyContactService } from './services/emergency-contact.service';

/**
 * Module that provides all services.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    SelfReaccService,
    CheckinService,
    OrderService,
    OrderChangeService,
    AdvancePassengerInformationService,
    EmergencyContactService,
  ],
})
export class OrderApiModule {
  constructor(
    @Optional() @SkipSelf() parentModule: OrderApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('OrderApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
