/* eslint-disable */
// .___________. __    __   __       _______.    __       _______.
// |           ||  |  |  | |  |     /       |   |  |     /       |
// `---|  |----`|  |__|  | |  |    |   (----`   |  |    |   (----`
//     |  |     |   __   | |  |     \   \       |  |     \   \
//     |  |     |  |  |  | |  | .----)   |      |  | .----)   |
//     |__|     |__|  |__| |__| |_______/       |__| |_______/
//
// .__   __.   ______   .___________.   .___________. __    __   _______
// |  \ |  |  /  __  \  |           |   |           ||  |  |  | |   ____|
// |   \|  | |  |  |  | `---|  |----`   `---|  |----`|  |__|  | |  |__
// |  . `  | |  |  |  |     |  |            |  |     |   __   | |   __|
// |  |\   | |  `--'  |     |  |            |  |     |  |  |  | |  |____
// |__| \__|  \______/      |__|            |__|     |__|  |__| |_______|
//
// .______    __          ___       ______  _______
// |   _  \  |  |        /   \     /      ||   ____|
// |  |_)  | |  |       /  ^  \   |  ,----'|  |__
// |   ___/  |  |      /  /_\  \  |  |     |   __|
// |  |      |  `----./  _____  \ |  `----.|  |____
// | _|      |_______/__/     \__\ \______||_______|
//
// ____    ____  ______    __    __          ___      .______       _______
// \   \  /   / /  __  \  |  |  |  |        /   \     |   _  \     |   ____|
//  \   \/   / |  |  |  | |  |  |  |       /  ^  \    |  |_)  |    |  |__
//   \_    _/  |  |  |  | |  |  |  |      /  /_\  \   |      /     |   __|
//     |  |    |  `--'  | |  `--'  |     /  _____  \  |  |\  \----.|  |____
//     |__|     \______/   \______/     /__/     \__\ | _| `._____||_______|
//
//  __        ______     ______    __  ___  __  .__   __.   _______
// |  |      /  __  \   /  __  \  |  |/  / |  | |  \ |  |  /  _____|
// |  |     |  |  |  | |  |  |  | |  '  /  |  | |   \|  | |  |  __
// |  |     |  |  |  | |  |  |  | |    <   |  | |  . `  | |  | |_ |
// |  `----.|  `--'  | |  `--'  | |  .  \  |  | |  |\   | |  |__| |
// |_______| \______/   \______/  |__|\__\ |__| |__| \__|  \______|
//
//  _______   ______   .______
// |   ____| /  __  \  |   _  \
// |  |__   |  |  |  | |  |_)  |
// |   __|  |  |  |  | |      /
// |  |     |  `--'  | |  |\  \----.
// |__|      \______/  | _| `._____|
//
//  __   _______    ____    ____  ______    __    __
// |  | |   ____|   \   \  /   / /  __  \  |  |  |  |
// |  | |  |__       \   \/   / |  |  |  | |  |  |  |
// |  | |   __|       \_    _/  |  |  |  | |  |  |  |
// |  | |  |            |  |    |  `--'  | |  `--'  |
// |__| |__|            |__|     \______/   \______/
//
//      ___      .______       _______         ___       _______   _______   __  .__   __.   _______
//     /   \     |   _  \     |   ____|       /   \     |       \ |       \ |  | |  \ |  |  /  _____|
//    /  ^  \    |  |_)  |    |  |__         /  ^  \    |  .--.  ||  .--.  ||  | |   \|  | |  |  __
//   /  /_\  \   |      /     |   __|       /  /_\  \   |  |  |  ||  |  |  ||  | |  . `  | |  | |_ |
//  /  _____  \  |  |\  \----.|  |____     /  _____  \  |  '--'  ||  '--'  ||  | |  |\   | |  |__| |
// /__/     \__\ | _| `._____||_______|   /__/     \__\ |_______/ |_______/ |__| |__| \__|  \______|
//
// .__   __.  ___________    __    ____
// |  \ |  | |   ____\   \  /  \  /   /
// |   \|  | |  |__   \   \/    \/   /
// |  . `  | |   __|   \            /
// |  |\   | |  |____   \    /\    /
// |__| \__| |_______|   \__/  \__/
//
// .___________..______          ___      .__   __.      _______. __          ___   .___________. __    ______   .__   __.      _______.
// |           ||   _  \        /   \     |  \ |  |     /       ||  |        /   \  |           ||  |  /  __  \  |  \ |  |     /       |
// `---|  |----`|  |_)  |      /  ^  \    |   \|  |    |   (----`|  |       /  ^  \ `---|  |----`|  | |  |  |  | |   \|  |    |   (----`
//     |  |     |      /      /  /_\  \   |  . `  |     \   \    |  |      /  /_\  \    |  |     |  | |  |  |  | |  . `  |     \   \
//     |  |     |  |\  \----./  _____  \  |  |\   | .----)   |   |  `----./  _____  \   |  |     |  | |  `--'  | |  |\   | .----)   |
//     |__|     | _| `._____/__/     \__\ |__| \__| |_______/    |_______/__/     \__\  |__|     |__|  \______/  |__| \__| |_______/
//
// =====================================================================================================================
// | DO NOT CHANGE THESE VALUES! THESE ARE ALREADY IMPORTED TO CMS.                                                    |
// | WHEN THEY ARE TRANSLATED AND COPIED TO TEST CONTENT, THEY WILL BE REMOVED FROM HERE.                              |
// =====================================================================================================================
import * as globalLocalizationEn from './step2-in-translation/globalLocalizationEn.json';
import * as bookingLocalizationEn from './step2-in-translation/bookingLocalizationEn.json';
import * as mmbLocalizationEn from './step2-in-translation/mmbLocalizationEn.json';
import * as reaccommodationLocalizationEn from './step2-in-translation/reaccommodationLocalizationEn.json';
import * as loyaltyLocalizationEn from './step2-in-translation/loyaltyLocalizationEn.json';
import * as serviceformsLocalizationEn from './step2-in-translation/serviceformsLocalizationEn.json';
import * as refundsLocalizationEn from './step2-in-translation/refundsLocalizationEn.json';
import * as corporateLocalizationEn from './step2-in-translation/corporateLocalizationEn.json';
import * as travelReadyLocalizationEn from './step2-in-translation/travelReadyLocalizationEn.json';
import * as globalLocalizationFiFi from './step2-in-translation/globalLocalizationFiFi.json';
import * as bookingLocalizationFiFi from './step2-in-translation/bookingLocalizationFiFi.json';
import * as mmbLocalizationFiFi from './step2-in-translation/mmbLocalizationFiFi.json';
import * as reaccommodationLocalizationFiFi from './step2-in-translation/reaccommodationLocalizationFiFi.json';
import * as loyaltyLocalizationFiFi from './step2-in-translation/loyaltyLocalizationFiFi.json';
import * as serviceformsLocalizationFiFi from './step2-in-translation/serviceformsLocalizationFiFi.json';
import * as refundsLocalizationFiFi from './step2-in-translation/refundsLocalizationFiFi.json';
import * as corporateLocalizationFiFi from './step2-in-translation/corporateLocalizationFiFi.json';
import * as travelReadyLocalizationFiFi from './step2-in-translation/travelReadyLocalizationFiFi.json';

export const inTranslation = {
  globalLocalizationEn,
  bookingLocalizationEn,
  mmbLocalizationEn,
  reaccommodationLocalizationEn,
  loyaltyLocalizationEn,
  serviceformsLocalizationEn,
  refundsLocalizationEn,
  corporateLocalizationEn,
  travelReadyLocalizationEn,
  globalLocalizationFiFi,
  bookingLocalizationFiFi,
  mmbLocalizationFiFi,
  reaccommodationLocalizationFiFi,
  loyaltyLocalizationFiFi,
  serviceformsLocalizationFiFi,
  refundsLocalizationFiFi,
  corporateLocalizationFiFi,
  travelReadyLocalizationFiFi,
};
/* eslint-enable */
