import { Component, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { MenuItem, NavigationMenuService } from '@fcom/common';
import { CmsContentType, CmsFormType, CmsTemplate, CmsViewType, PageViewType } from '@fcom/core-api';

@Component({
  selector: 'cms-bluewings-main-page',
  templateUrl: './cms-bluewings-main-page.component.html',
  styleUrls: ['./cms-bluewings-main-page.component.scss'],
})
export class CmsBluewingsMainPageComponent {
  @Input()
  template$: Observable<CmsTemplate>;

  activeNaviSection$: Observable<MenuItem>;

  public readonly DOCTYPE: typeof CmsContentType = CmsContentType;
  public readonly VIEWTYPE: typeof CmsViewType = CmsViewType;
  public readonly FORMTYPE: typeof CmsFormType = CmsFormType;
  public readonly PAGETYPE: typeof PageViewType = PageViewType;

  constructor(private navigationMenuService: NavigationMenuService) {
    this.activeNaviSection$ = this.navigationMenuService.activeNaviSection;
  }
}
