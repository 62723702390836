export enum PaymentStatus {
  /** The payment has not been initialized */
  INITIAL,

  /** The payment is being verified */
  PENDING_VERIFICATION,

  /** The payment is verified */
  VERIFIED,

  /** Payment processing failed. This is unrecoverable error situation and the user should be shown technical error page. */
  TECHNICAL_ERROR,
}

export interface PaymentState {
  paymentStatus: PaymentStatus;
  redirectionUrl: string;
  paymentIds: string[];
}

export enum PaymentFlow {
  BOOKING = 'BOOKING',
  MANAGE_BOOKING = 'MANAGE_BOOKING',
  VOLUNTARY_CHANGE = 'VOLUNTARY_CHANGE',
  CHECK_IN = 'CHECK_IN',
  CHECK_IN_FUNNEL = 'CHECK_IN_FUNNEL',
}

export type PaymentServiceName =
  | 'finnaircom'
  | 'web_award'
  | 'web_mmb_ancillary'
  | 'finnairmobileapp_booking'
  | 'finnairmobileapp_award'
  | 'web_change_flow'
  | 'web_corporate_flow'
  | 'web_checkin';
