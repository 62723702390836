import { Injectable } from '@angular/core';

import { BehaviorSubject, delay, Observable } from 'rxjs';

@Injectable()
export class CampaignBannerService {
  public readonly visibility$: Observable<boolean>;
  private campaignBannerVisibility$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor() {
    this.visibility$ = this.campaignBannerVisibility$.asObservable().pipe(delay(0));
  }

  setCampaignBannerVisibility(visibility: boolean): void {
    this.campaignBannerVisibility$.next(visibility);
  }
}
