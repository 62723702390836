import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { UiComponentsModule } from '@fcom/ui-components';
import { UiTranslateModule } from '@fcom/ui-translate';
import { LoyaltyTierService, ComponentsModule } from '@fcom/common';
import { DirectiveModule } from '@fcom/common/directives';
import { PipesModule } from '@fcom/common/pipes/pipes.module';
import { LocalizationProvider } from '@fcom/core/services/language/localization.provider';
import { I18nKey } from '@fcom/core';
import { CommonBookingComponentsModule } from '@fcom/common-booking/components';
import { CmsModule } from '@fcom/cms';
import { OrderModule } from '@fcom/order';

import { LoyaltyNavigationService, PaxTripService } from './services';
import { LoyaltySectionNavigationComponent } from './components/loyalty-section-navigation/loyalty-section-navigation.component';
import { LoyaltyUpcomingTripsComponent } from './components/loyalty-upcoming-trips/loyalty-upcoming-trips.component';
import { LoyaltyAddTripsComponent } from './components/loyalty-add-trips/loyalty-add-trips.component';
import { LoyaltyGlobalNotificationComponent } from './components/loyalty-global-notification/loyalty-global-notification.component';
import { LoyaltyViewComponent } from './views/loyalty-view/loyalty-view.component';
import { LoyaltyLayoutComponent } from './views/loyalty-layout/loyalty-layout.component';
import { LoyaltyMyTripsComponent } from './views/loyalty-my-trips/loyalty-my-trips.component';
import { reducers as loyaltyReducers } from './store/reducers';
import { TripsEffects } from './store/effects/trips.effects';
import { LOYALTY_FEATURE_KEY } from './constants';
import { AnnouncementCardComponent } from './components/announcement-card/announcement-card.component';
import { PlusCardComponent } from './components/plus-card/plus-card.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    ComponentsModule,
    DirectiveModule,
    PipesModule,
    UiComponentsModule,
    UiTranslateModule,
    StoreModule.forFeature(LOYALTY_FEATURE_KEY, loyaltyReducers),
    EffectsModule.forFeature([TripsEffects]),
    RouterModule,
    CommonBookingComponentsModule,
    CmsModule,
    OrderModule,
  ],
  declarations: [
    LoyaltySectionNavigationComponent,
    LoyaltyUpcomingTripsComponent,
    LoyaltyAddTripsComponent,
    LoyaltyMyTripsComponent,
    LoyaltyViewComponent,
    LoyaltyLayoutComponent,
    LoyaltyGlobalNotificationComponent,
    AnnouncementCardComponent,
    PlusCardComponent,
  ],
  exports: [
    LoyaltySectionNavigationComponent,
    LoyaltyUpcomingTripsComponent,
    LoyaltyMyTripsComponent,
    LoyaltyViewComponent,
    LoyaltyLayoutComponent,
    AnnouncementCardComponent,
    PlusCardComponent,
  ],
  providers: [LoyaltyNavigationService, PaxTripService, LoyaltyTierService],
})
export class LoyaltyCoreModule {
  constructor(private localizationProvider: LocalizationProvider) {
    this.localizationProvider.triggerLazyLocalizations(I18nKey.LOYALTY);
  }
}
