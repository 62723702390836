<div class="booking-entry relative">
  <cms-disruptions [disruptions]="disruptions$ | async"></cms-disruptions>
  <div class="relative pr-xlarge-y bg-container">
    <fin-content-image
      class="background-image"
      [imageData$]="backgroundImg$"
      [fetchPriority]="'high'"
    ></fin-content-image>
    <fin-booking-widget [compactMode]="true"></fin-booking-widget>
  </div>
</div>
