import { ActivatedRouteSnapshot } from '@angular/router';

import { RootPaths, UTM_PARAMS } from '../constants';

export const pathIsAnyHardcodedPath = (path: string): boolean => {
  const pathArr = path.split('/');

  return Object.values(RootPaths).some((root) => pathArr[2] === root || pathArr.length <= 2);
};

export const pathIsWithinHardcodedPath = (path: string, testHardcodedPath: RootPaths): boolean =>
  testHardcodedPath === path?.split('/')?.[2];

export const matchesLastPathSegment = (path: string, testPath: string): boolean => {
  const pathArr = sanitizePath(path).split('/');

  return testPath === pathArr[pathArr.length - 1];
};

/**
 * Used to return a route path without hash or query params
 * @param str
 */
export const sanitizePath = (str: string): string => {
  return str.split('#')[0].split('?')[0];
};

export const urlWithoutQueryString = (url: string): string => url.split('?')[0];

export const urlQueryString = (url: string): string | undefined => url.split('?')[1];

export const pathSegmentsToPathString = (segments: ActivatedRouteSnapshot[]): string => {
  return segments
    .map((activeRoute) => activeRoute.url)
    .filter((urlArray) => urlArray.length)
    .flatMap((urlArray) => urlArray)
    .join('/');
};

export const filteredUtmParamsQueryString = (url: string): string => {
  const allowedUtmParamsQueryString =
    urlQueryString(url)
      ?.split('&')
      ?.reduce((query, param) => {
        const key = param.split('=')[0];
        if (UTM_PARAMS.includes(key)) {
          return `${query}${query.length > 0 ? '&' : ''}${param}`;
        }
        return query;
      }, '') ?? '';

  return allowedUtmParamsQueryString ? `?${allowedUtmParamsQueryString}` : '';
};
