<div [formGroup]="parentForm" [class.ms-xlarge-b]="displayMargin">
  <div class="relative ps-xxsmall-b">
    <label
      *ngIf="!disableLabel"
      class="font-body-1 regular-type flex ps-xxsmall-b"
      [for]="idOrControlName"
      [ngClass]="{
        'nordic-blue-400-text': !control.invalid || !control.touched,
        'error-900-text': control.invalid && control.touched,
      }"
    >
      <div class="flex-1">
        {{ label || (translationPrefix + '.label' | finLocalization) }}
        <span *ngIf="showAsterix" class="flex-1 flex--nogrow" data-testid="asterisk"> *</span>
      </div>
      <ng-template #contentTooltip>
        <h2 class="font-body-1 bold-type nordic-blue-900-text">{{ tooltipHeading }}</h2>
        <p class="pr-xxsmall-t nordic-blue-400-text">{{ tooltipContent }}</p>
      </ng-template>
      <button
        type="button"
        *ngIf="tooltipContent"
        fcomPopover
        [options]="tooltipOptions"
        [content]="contentTooltip"
        [ariaCloseText]="'actions.close' | finLocalization"
        [attr.aria-label]="'actions.info' | finLocalization"
        class="icon nordic-blue-900-text ms-small-r flex-1 flex--nogrow align-right"
        [class.error-900-text]="control.invalid && control.touched"
      >
        <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.HELP_BLOCK"></fcom-icon>
      </button>
    </label>
    <select
      class="has-initial-value font-body-1 medium-type ps-small border rounded-large fill"
      [ngClass]="{
        'grey-600-text': !control.value,
      }"
      data-testid="select-from-list"
      [formControlName]="controlName"
      [id]="idOrControlName"
      [name]="controlName"
      [attr.aria-required]="fieldRequired"
      #selectElement
    >
      <option value [selected]="!ctrlField.value" *ngIf="!disableSelectOption">
        {{ selectLabelTranslationKey | finLocalization }}
      </option>
      <option
        *ngFor="let option of options"
        [value]="option.value"
        [disabled]="option.disabled"
        [selected]="option.value === ctrlField.value"
        class="font-body-1"
      >
        {{ option.name ? option.name : (translationPrefix + '.' + option.value | finLocalization) }}
      </option>
    </select>
    <span class="divider"></span>
  </div>
  <div *ngIf="ctrlField.invalid && ctrlField.touched" class="error-label font-body-2" aria-live="polite">
    <span *ngIf="ctrlField.errors?.required" class="error-900-text">
      {{ translationPrefix + '.required' | finLocalization }}
    </span>
  </div>
</div>
