import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { youtubeIdFromUrl } from '@fcom/core/utils';
import { finShare, safeMap } from '@fcom/rx';
import { StaticPicture } from '@fcom/core-api';
import { ModalButtons } from '@fcom/ui-components';

@Component({
  selector: 'fin-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoComponent implements OnInit {
  readonly ModalButtons = ModalButtons;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  videoUrl$: Observable<string>;
  @Input()
  videoTitle$?: Observable<string>;
  @Input()
  showPopup = false;
  @Input()
  noMargin = false;
  @ViewChild('videoIframe', { static: false })
  iframe: ElementRef;
  @ViewChild('videoWrapper', { static: false })
  videoWrapper: ElementRef;

  videoId$: Observable<string>;
  videoFinalUrl$: Observable<SafeResourceUrl>;
  youtubePreviewPicture$: Observable<StaticPicture>;
  previewVisible$: Observable<boolean>;
  iframeVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.previewVisible$ = this.iframeVisible$.pipe(map((visible) => !visible));
    this.videoId$ = this.videoUrl$.pipe(safeMap(youtubeIdFromUrl), finShare());
    this.videoFinalUrl$ = this.videoId$.pipe(
      safeMap(
        (videoId) =>
          `https://www.youtube.com/embed/${videoId}` +
          '?modestbranding=1' + // Show as little YouTube branding as possible
          '&rel=0' + // Show related videos out of same channel only
          '&autoplay=1' // Autoplay when loaded (as only loaded on click interaction)
      ),
      map((url) => this.sanitizer.bypassSecurityTrustResourceUrl(url)),
      finShare()
    );
    this.youtubePreviewPicture$ = this.videoId$.pipe(
      safeMap((videoId: string): StaticPicture => {
        return {
          thumbnailSrc: `//i.ytimg.com/vi/${videoId}/default.jpg`,
          fullSizeUrl: `//i.ytimg.com/vi/${videoId}/hqdefault.jpg`,
        };
      }),
      finShare()
    );
  }

  playVideo(): void {
    this.iframeVisible$.next(true);
  }
}
