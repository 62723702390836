import { createAction, props, union } from '@ngrx/store';

import { PaxDetailsFormData } from '@fcom/dapi/interfaces';

export const setPaxDetails = createAction(
  '[PaxDetailsActions] SetPaxDetails',
  props<{ passengers: PaxDetailsFormData[] }>()
);
export const setSinglePaxDetails = createAction(
  '[PaxDetailsActions] SetSinglePaxDetails',
  props<{ passenger: PaxDetailsFormData }>()
);
export const reset = createAction('[PaxDetailsActions] Reset');
export const setCorporateCode = createAction(
  '[PaxDetailsActions] SetCorporateCode',
  props<{ corporateCode: string }>()
);
export const setAdditionalEmails = createAction(
  '[PaxDetailsActions] SetAdditionalEmails',
  props<{ additionalEmails: string[] }>()
);

const all = union({ setPaxDetails, setSinglePaxDetails, reset, setCorporateCode, setAdditionalEmails });

export type ActionsUnion = typeof all;
