import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CmsOffer } from '@fcom/core-api';

import { BlockOfferData } from '../../interfaces';
import { toPromoBlock } from '../../services';

@Component({
  selector: 'cms-category',
  templateUrl: './cms-category.component.html',
  styleUrls: ['./cms-category.component.scss'],
})
export class CmsCategoryComponent implements OnInit {
  @Input()
  content$: Observable<CmsOffer>;
  offerData$: Observable<BlockOfferData>;

  ngOnInit(): void {
    this.offerData$ = this.content$.pipe(map((c: CmsOffer) => toPromoBlock(c)));
  }
}
