import { createReducer, on } from '@ngrx/store';

import { NavigatorActions } from '../actions';
import { NavigatorState } from '../interfaces';

export const initialState: NavigatorState = {
  browserGeolocationLoading: false,
};

const navigatorReducer = createReducer(
  initialState,
  on(NavigatorActions.setAkamaiGeolocation, (state, { location }) => ({
    ...state,
    akamaiGeolocation: { ...location },
  })),
  on(NavigatorActions.setBrowserGeolocation, (state, { location }) => ({
    ...state,
    browserGeolocation: { ...location },
  })),
  on(NavigatorActions.setBrowserGeolocationLoading, (state) => ({
    ...state,
    browserGeolocationLoading: true,
  })),
  on(NavigatorActions.removeBrowserGeolocationLoading, (state) => ({
    ...state,
    browserGeolocationLoading: false,
  })),
  on(NavigatorActions.setServerTime, (state, { serverMillisFromEpoch, timeOffset }) => ({
    ...state,
    timeData: { serverMillisFromEpoch, timeOffset },
  }))
);

export function reducer(state: NavigatorState, action: NavigatorActions.NavigatorActionsUnion) {
  return navigatorReducer(state, action);
}
