<ng-container *ngIf="content$ | async as c">
  <div
    *ngFor="let item of c.items; let i = index"
    class="heather-50-bg pr-medium-x pr-large-y ms-xlarge-y text-tip-container flex"
  >
    <div class="text-tip-top mr-xlarge-x align-center">
      <span *ngIf="i === 0 && c.teaserTitle" class="text-tip-slogan mr-xxsmall-b font-body-1 block"
        ><strong>{{ c.teaserTitle }}</strong></span
      >
      <span class="text-tip-heading bold-type nordic-blue-900-text block">{{ item.teaserTitle }}</span>
    </div>
    <div [innerHtml]="item.teaserText" class="text-tip-content"></div>
  </div>
</ng-container>
