import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';

import { CmsTarget } from '@fcom/core-api';

@Component({
  selector: 'fin-text-link',
  templateUrl: './text-link.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextLinkComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  teaserTitle$: Observable<string>;
  @Input()
  target$: Observable<CmsTarget>;
  @Input()
  colorClass: string;
}
