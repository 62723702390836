import { FinnairOrder, PaymentTransaction } from '@fcom/dapi/api/models';

export enum DeviceForPayment {
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  LAPTOP = 'LAPTOP',
  DESKTOP = 'DESKTOP',
}

const devices = [
  { type: DeviceForPayment.MOBILE, maxWidth: 600 },
  { type: DeviceForPayment.TABLET, maxWidth: 1024 },
  { type: DeviceForPayment.LAPTOP, maxWidth: 1600 },
  { type: DeviceForPayment.DESKTOP, maxWidth: Infinity },
];

export const mapDeviceForPayment = (size: number): DeviceForPayment =>
  devices.reduce((_type: DeviceForPayment, currentType, i: number) => {
    if (currentType.maxWidth > size && (i === 0 || devices[i - 1].maxWidth <= size)) {
      _type = currentType.type;
    }
    return _type;
  }, devices[0].type);

export const mapPaymentMethod = (order: FinnairOrder): PaymentTransaction => {
  const purchasedTSMS =
    order.checkouts?.sort((a, b) => +a.timestamp - +b.timestamp)[0]?.items.map((item) => item.id) ??
    order.services?.pending?.flatMap((category) =>
      category.bounds.flatMap((bound) =>
        bound.segments.flatMap((segment) =>
          segment.passengers.flatMap((passenger) => passenger.services.map(({ id }) => id))
        )
      )
    ) ??
    [];

  return order.payments?.find((payment) => payment.associatedIds?.some((id) => purchasedTSMS.includes(id)));
};
