/**
 * Expected result from Location (route) CFF microservice.
 */
import { CmsImageData } from './cms-interface';

export enum LocationRouteType {
  AJB = 'AJB',
  SJB = 'SJB',
  DEFAULT = 'default',
}

export type SurchargeVendor = 'VI' | 'CA' | 'DC' | 'AX' | 'JC' | 'TP';
export type SurchargeDisplayName =
  | 'Visa'
  | 'Visa Debit'
  | 'Mastercard'
  | 'Mastercard Debit'
  | 'Diners Club'
  | 'Diners Club Debit'
  | 'American Express'
  | 'American Express Debit'
  | 'JCB'
  | 'UATP';

export enum CardType {
  CREDIT = 'credit',
  DEBIT = 'debit',
}

export interface SurchargeSetting {
  vendor: SurchargeVendor;
  displayName: SurchargeDisplayName;
  percentage: string | null;
  corporate: boolean;
  cardType: CardType;
}

export interface LocationRouteCffData {
  cffEconomy: string;
  cffPremiumEconomy: string | null;
  cffBusiness: string | null;
  cffMixed: string | null;
  hasCorporateCodeOverride: boolean;
  hasMatrixEnabled: boolean;
  market: string;
  officeId: string;
  fpp: boolean;
  originLocation: string;
  destinationLocation: string;
  currency: string;
  surcharges: SurchargeSetting[];
  routeType: LocationRouteType;
  isCountryShop: boolean;
}

export type RouteCffField = keyof Pick<
  LocationRouteCffData,
  'cffEconomy' | 'cffPremiumEconomy' | 'cffBusiness' | 'cffMixed'
>;

/**
 * A single location match item from Location (location match) microservice.
 */
export interface Location {
  /**
   * true, if the location is the nearest airport determined by the user's geolocation
   */
  isFromUserLocation?: boolean;
  distanceKm?: number;
  title: string;
  country: string;
  countryCode: string;
  cityName?: string;
  locationCode: string;

  /**
   * The city code if not equal to locationCode.
   * E.g., locationCityCode=BER for locationCode=TXL, but undefined for HEL
   */
  locationCityCode?: string;

  AJBPartner: boolean;
  SJBPartner: boolean;
  airAward: boolean;
  airPartner: boolean;
  airAlliance: boolean;

  type: 'city' | 'airport';

  latitude: number;
  longitude: number;

  /**
   * Picture matched from cms destination page to location
   */
  picture?: CmsImageData | null;
  continentCode: string;
}

/**
 * Expected results from Location (location match) microservice.
 */
export interface LocationMatchData {
  ok: boolean;
  count: number;
  locale: string;
  items: Location[];
}

/**
 * Expected result from Location (geolocation match) microservice.
 */
export interface LocationGeoLocData {
  ok: boolean;
  locale: string;
  item: Location;
}

export interface LocationSuggestions {
  departures: Location[];
  destinations: Location[];
}

export interface SuggestedLocationsData {
  count: number;
  items: Location[];
}

/**
 * Expected result from Location (suggested locations) microservice.
 */
export interface SuggestedLocationsResponse {
  ok: boolean;
  error?: any;
  errorMessage?: any;
  locale?: string;
  departures: SuggestedLocationsData;
  destinations: SuggestedLocationsData;
}

export type LocationsMap = {
  [locationCode: string]: Location;
};

export type SearchData = {
  locations: Location[];
  input: string;
};
