import { Type } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Event } from '@angular/router';

export const recurseLangParam = (route: ActivatedRouteSnapshot): string => {
  let r = route;
  while (r) {
    const lang = r.params['lang'];
    if (lang) {
      return lang;
    }
    r = r.parent;
  }
  return undefined;
};

/**
 *  Checked if the router state event rendered a component
 */
export const isComponentRenderedByRouter = <T>(state: ActivatedRouteSnapshot, comp: Type<T>): boolean => {
  let route = state.firstChild;
  while (route) {
    if (route.component === comp || route?.data?.componentType === comp) {
      return true;
    }
    route = route.firstChild;
  }
  return false;
};

export const isNavigationEvent = (event: Event): event is NavigationEnd => event instanceof NavigationEnd;
