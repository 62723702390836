<div class="boxes fill flex">
  <div class="flex-1 flex--basis0 flex flex--start flex--start flex--self-center">
    <ng-content select="[left]"></ng-content>
  </div>

  <div class="flex-1 flex flex--center flex--self-center">
    <a
      *ngIf="showLink"
      [finLink]="homeLink$"
      class="dn-laptop-up inherit-color flex"
      (click)="this.homeGtmEvent.emit()"
    >
      <span class="is-vishidden">{{ 'actions.home' | finLocalization }}</span>
      <fcom-icon class="finnair-emblem" [category]="IconLibrary.CUSTOM" [name]="CustomIcon.FINNAIR_EMBLEM"></fcom-icon>
    </a>
    <fcom-icon
      *ngIf="!showLink"
      class="dn-laptop-up finnair-emblem"
      [category]="IconLibrary.CUSTOM"
      [name]="CustomIcon.FINNAIR_EMBLEM"
    ></fcom-icon>
    <ng-content select="[center]"></ng-content>
  </div>

  <div class="flex-1 flex--basis0 flex flex--end flex--self-center align-right">
    <ng-content select="[right]"></ng-content>
  </div>
</div>
