import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';

import { COMMON_BOOKING_FEATURE_KEY } from './constants';
import { reducers } from './reducers';

@NgModule({
  imports: [StoreModule.forFeature(COMMON_BOOKING_FEATURE_KEY, reducers)],
})
export class CommonBookingStoreModule {}
