import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { stopPropagation } from '@fcom/core/index';

import { StepperStep } from './interfaces';
import { StepperMode, StepperTheme } from './enums';

@Component({
  selector: 'fcom-stepper',
  styleUrls: ['./stepper.component.scss'],
  templateUrl: './stepper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent {
  readonly StepperTheme = StepperTheme;
  readonly StepperMode = StepperMode;

  @Input()
  showLabels = true;

  @Input()
  steps: StepperStep[] = [];

  @Input()
  activeStep = 0;

  @Input()
  theme = StepperTheme.LIGHT;

  @Input()
  mode = StepperMode.HORIZONTAL;

  @Output()
  clicked: EventEmitter<StepperStep> = new EventEmitter<StepperStep>();

  selectStep(event: Event, step: StepperStep): void {
    stopPropagation(event);
    this.clicked.emit(step);
  }
}
