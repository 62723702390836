import { ActionReducerMap } from '@ngrx/store';

import * as fromTrips from '../reducers/trips.reducer';
import * as milestones from '../reducers/milestones.reducer';
import { LoyaltyState } from '../../interfaces/store.interface';

export const reducers: ActionReducerMap<LoyaltyState> = {
  trips: fromTrips.reducer,
  milestones: milestones.reducer,
};
