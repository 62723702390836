<form (ngSubmit)="sendConsents()" class="cookie-settings text">
  <div class="align-center">
    <div class="cookie-settings-intro mr-small-b">
      <cms-unstyled-fragment [contentUrl$]="cookieSettingsUrl$"></cms-unstyled-fragment>
    </div>
  </div>

  <ng-container *ngFor="let consent of consents$ | async; let i = index">
    <input
      type="checkbox"
      name="consent"
      (click)="handleIsCheckChange(consent.consentTextId)"
      [id]="consent.consentTextId"
    />
    <label name="consent-label" [for]="consent.consentTextId">
      <span class="content">
        <span aria-hidden="true" [innerHTML]="consent.text | finSafe: 'html'"></span>
        <span class="is-vishidden">
          <ng-container *ngFor="let consent2 of consents$ | async; let k = index">
            <span *ngIf="i >= k" [innerHTML]="consent2.text | finSafe: 'html'"></span>
          </ng-container>
        </span>
      </span>
      <fcom-toggle-switch
        class="mr-xxsmall-t"
        [name]="consent.consentTextId"
        [status]="getStatus(consent.consentTextId)"
      ></fcom-toggle-switch>
    </label>
  </ng-container>

  <div class="pr-xsmall-t font-body-1 align-center button-group flex">
    <fcom-button
      [text]="'cookieConsent.allowSelection' | finLocalization"
      [type]="ButtonType.SUBMIT"
      [mode]="ButtonMode.FILL"
      class="allow-selection mr-xxsmall"
    >
    </fcom-button>
    <fcom-button
      [text]="'cookieConsent.allowAll' | finLocalization"
      [type]="ButtonType.SUBMIT"
      [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
      [mode]="ButtonMode.FILL"
      (clickBtn)="allowAllCookies()"
      class="allow-all mr-xxsmall"
    >
    </fcom-button>
  </div>
</form>
