import { Inject, InjectionToken, Injectable } from '@angular/core';

import { SentryOptions } from '../../interfaces';

export const CAPTURE_MESSAGE: InjectionToken<SentryLogger> = new InjectionToken('sentryCaptureMessage');

@Injectable()
export class SentryLogger {
  constructor(@Inject(CAPTURE_MESSAGE) private captureMessage: Function) {}

  error = (message: string, extra?: any): void => this.log(message, { level: 'error', extra });
  info = (message: string, extra?: any): void => this.log(message, { level: 'info', extra });
  warn = (message: string, extra?: any): void => this.log(message, { level: 'warning', extra });

  private log(message: string, options: SentryOptions): void {
    this.captureMessage(message, options);
  }
}
