import { Pipe, PipeTransform } from '@angular/core';

import { LanguageService } from '@fcom/ui-translate';

/**
 * Pipe that joins an array of strings with a localized conjunction.
 *
 * @example
 * ```html
 * <p>{{ ['one', 'two', 'three'] | finIntlJoin }}</p>
 * ```
 * @external Intl.ListFormat
 * @param value - An array of strings to join.
 * @requires LanguageService
 * @returns A string with the joined values i.e. 'one, two and three' .
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/ListFormat MDN documentation} for more information.
 */
@Pipe({ name: 'finIntlJoin' })
export class IntlJoinPipe implements PipeTransform {
  private cachedLocale: string;
  private cachedTranslation: string;
  private cachedValue: string[];
  private listFormat: Intl.ListFormat;

  private readonly listOptions: Intl.ListFormatOptions = {
    style: 'long',
    type: 'conjunction',
  };

  constructor(private languageService: LanguageService) {
    this.cachedLocale = this.languageService.langValue || 'en';
    this.listFormat = new Intl.ListFormat(this.cachedLocale, this.listOptions);
  }

  transform(value: string[]): string {
    if (!value || !Array.isArray(value) || (Boolean(value.length) && 'string' !== typeof value[0])) {
      throw new TypeError('Value for INTL join must be an array of strings.');
    }
    if (this.valueChanged(value)) {
      this.updateCache(value);
    }
    return this.cachedTranslation;
  }

  private updateCache(value: string[]): void {
    this.cachedLocale = this.languageService.langValue || 'en';
    this.listFormat = new Intl.ListFormat(this.cachedLocale, this.listOptions);
    this.cachedTranslation = this.listFormat.format(value);
    this.cachedValue = value;
  }

  private valueChanged(value: string[]): boolean {
    return (
      !this.cachedTranslation ||
      this.cachedLocale !== this.languageService.langValue ||
      JSON.stringify(value) !== JSON.stringify(this.cachedValue)
    );
  }
}
