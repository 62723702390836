export enum Aircraft {
  ATR = 'AT7',
  E90 = 'E90',
  A319 = '319',
  A320 = '320',
  A321 = '321',
  A32B = '32B', // A321 Sharklets
  A330 = '333',
  A350 = '359',
}
