import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UiTranslateModule } from '@fcom/ui-translate';
import { IconsModule, NotificationsModule } from '@fcom/ui-components';

import { PipesModule } from '../../pipes';
import { ToasterService } from '../../services';
import { ComponentsModule } from '../components.module';
import { ToasterComponent } from './toaster.component';

@NgModule({
  imports: [CommonModule, PipesModule, ComponentsModule, UiTranslateModule, IconsModule, NotificationsModule],
  declarations: [ToasterComponent],
  exports: [ToasterComponent],
  providers: [ToasterService],
})
export class ToasterModule {}
