import { ChangeDetectorRef, Injectable, Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { DateFormat, Pattern, DateFormatInput } from '@fcom/core/utils';

const getPattern = (input: string) => DateFormat[input] || new Pattern(input);

/**
 * Asynchronous DateFormat pipe that supports localizations.
 * Uses TranslateService to get localized date formats and names.
 *
 * Syntax:
 * <Date|Day|number> 'pattern' [defaultValue = '']
 *
 * Built-in patterns (prints are examples for Finnish locale on SAT 2016-01-02):
 * FULL           => ti 2.1.2016
 * FULL_NO_YEAR   => ti 2.1.
 * DATE           => 2.1.2016
 * DATE_SHORT     => 2.1.
 * MONTH          => Tammikuu
 * MONTH_SHORT    => Tam
 * WEEKDAY        => Lauantai
 * WEEKDAY_SHORT  => la
 * VOICE_OVER_DATE => Lauantai, 2 Tammikuu
 *
 * Pattern is considered as a custom one if pattern's value doesn't match any build-in format.
 * Custom pattern is related to Java SimpleDateFormat syntax (examples for SAT 2016-01-02 on Finnish locale):
 * yy = year in 2 digits => 16
 * yyyy = year in 4 digits => 2016
 * M = month in 1-2 digit => 1
 * MM = month in 2 digits => 01
 * MMM = month's short name => Tam
 * MMMM = month's name => Tammikuu
 * d = day of month in 1-2 digits => 2
 * dd = day of month in 2 digits => 02
 * E = weekday's short name => la
 * EE = weekday's name => Lauantai
 */
@Injectable()
@Pipe({
  name: 'finDateFormat',
  pure: false,
})
export class DateFormatPipe implements OnDestroy, PipeTransform {
  private async: AsyncPipe;
  private cached$: Observable<any>;
  private cachedInput: DateFormatInput;
  private cachedPattern: string;
  private cachedUseUserDate: boolean;

  constructor(
    private ref: ChangeDetectorRef,
    private languageService: LanguageService
  ) {
    this.async = new AsyncPipe(this.ref);
  }

  transform(input: DateFormatInput, pattern: string, useUserDate = false): string {
    if (this.valueChanged(input, pattern, useUserDate)) {
      this.update(input, pattern, useUserDate);
    }
    return this.async.transform(this.cached$);
  }

  ngOnDestroy(): void {
    this.async.ngOnDestroy();
  }

  private valueChanged(input: DateFormatInput, pattern: string, useUserDate: boolean): boolean {
    return (
      !this.cached$ ||
      input !== this.cachedInput ||
      pattern !== this.cachedPattern ||
      useUserDate !== this.cachedUseUserDate
    );
  }

  private update(input: DateFormatInput, pattern: string, useUserDate: boolean): void {
    this.cached$ = this.languageService.translate('date').pipe(
      map((translations: any) => {
        if (!pattern) {
          return 'ERROR';
        }
        if (!input) {
          return '';
        }
        return new DateFormat(translations).format(input, getPattern(pattern), useUserDate);
      })
    );
    this.cachedInput = input;
    this.cachedPattern = pattern;
    this.cachedUseUserDate = useUserDate;
  }
}
