import { Component, Input, OnInit } from '@angular/core';

import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ModalButtons } from '@fcom/ui-components';
import { asPaxTypeKey } from '@fcom/dapi/utils';
import { Amount, PriceService } from '@fcom/dapi';
import {
  FinnairCharge,
  FinnairPassengerCode,
  FinnairPassengerItem,
  FinnairPrice,
  FinnairTotalPricesDetails,
  FinnairAmount,
} from '@fcom/dapi/api/models';
import { safeMap as safeMapObs } from '@fcom/rx';
import { isPresent } from '@fcom/core/utils';
import { hasUnitPrices } from '@fcom/common/utils';

import { getPassengerServices } from '../../../utils/order.utils';

interface BreakdownModel extends FinnairPassengerItem {
  prices: FinnairPrice;
  services: PriceService[];
  total: FinnairAmount;
}
interface MultiplePassengerRefundBreakdownModel {
  prices: FinnairPrice;
  total: FinnairAmount;
}

interface TaxModalModel {
  taxes: FinnairCharge[];
  ptc: string;
  total: Amount;
}

const hasPassengers = (passengers: FinnairPassengerItem[]): boolean => isPresent(passengers) && passengers.length > 0;

@Component({
  selector: 'fin-refund-price-breakdown',
  templateUrl: './refund-price-breakdown.component.html',
})
export class RefundPriceBreakdownComponent implements OnInit {
  public readonly ModalButtons = ModalButtons;
  public readonly asPaxTypeKey = asPaxTypeKey;
  public readonly FinnairPassengerCode = FinnairPassengerCode;

  @Input()
  prices$: Observable<FinnairTotalPricesDetails>;
  @Input()
  passengers$: Observable<FinnairPassengerItem[]>;
  @Input()
  expandOpen = false;

  breakdowns$: Observable<BreakdownModel[]>;
  totalPrice$: Observable<Amount>;
  showSubTotals$: Observable<boolean>;
  taxModalOpen = false;
  taxModalData: TaxModalModel;
  multiplePassengerRefundBreakdown$: Observable<MultiplePassengerRefundBreakdownModel>;

  ngOnInit(): void {
    this.totalPrice$ = this.prices$.pipe(safeMapObs((prices) => ({ ...prices.total?.total?.totalAmount })));
    this.showSubTotals$ = this.passengers$.pipe(
      safeMapObs((passengers: FinnairPassengerItem[]) => passengers.length > 1)
    );

    this.breakdowns$ = combineLatest([this.passengers$, this.prices$]).pipe(
      filter(([passengers, prices]) => hasPassengers(passengers) && hasUnitPrices(prices, true)),
      map(([passengers, prices]) =>
        passengers
          .filter((passenger) => parseFloat(prices.total.totalPerPax?.[passenger.id]?.totalAmount.amount) > 0)
          .map((passenger: FinnairPassengerItem) => ({
            ...passenger,
            prices: prices.flight?.totalPerPax?.[passenger.id] || prices.services?.totalPerPax?.[passenger.id],
            services: getPassengerServices(prices.services?.totalPerCategory, passenger),
            total: prices.total.totalPerPax?.[passenger.id]?.totalAmount,
          }))
      )
    );

    this.multiplePassengerRefundBreakdown$ = combineLatest([this.passengers$, this.prices$]).pipe(
      filter(
        ([passengers, prices]) => hasPassengers(passengers) && hasUnitPrices(prices, true) && passengers.length > 1
      ),
      map(([_, prices]) => ({
        prices: prices.total.total,
        total: prices.total.total.totalAmount,
      }))
    );
  }

  openTaxModal(event: Event, taxes: FinnairCharge[], ptc: string, total: Amount): void {
    event.stopPropagation();

    this.taxModalOpen = true;
    this.taxModalData = {
      taxes,
      ptc,
      total,
    };
  }

  closeTaxModal(): void {
    this.taxModalData = null;
  }
}
