<ng-container [ngSwitch]="noText">
  <fin-text-on-image
    *ngSwitchCase="false"
    [teaserTitle$]="content$ | finMap: 'teaserTitle'"
    [teaserText$]="content$ | finMap: 'teaserText'"
    [imageData$]="imageData$"
    [target$]="cmsTarget$"
    [imageAspectRatio$]="imageAspectRatio$"
  ></fin-text-on-image>
  <fin-text-on-image
    *ngSwitchCase="true"
    [imageData$]="imageData$"
    [target$]="cmsTarget$"
    [imageAspectRatio$]="imageAspectRatio$"
  ></fin-text-on-image>
</ng-container>
