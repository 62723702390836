<div [formGroup]="parentForm" class="ms-large-b">
  <ng-container [formArrayName]="controlName" *ngFor="let iteratedInput of formArray.controls; let i = index">
    <fin-form-input-checkbox
      [parentForm]="parentForm.get(controlName)"
      [checkboxValue]="checkboxList[i].id"
      [translationPrefix]="checkboxList[i].label"
      [controlName]="i + ''"
    ></fin-form-input-checkbox>
  </ng-container>
</div>
