import { createReducer, on } from '@ngrx/store';

import { GlobalActions } from '@fcom/core/actions';
import { updateStoreAction } from '@fcom/core/store/init-store.action';
import { CartActions } from '@fcom/booking/store/actions';

import { PaymentState, PaymentStatus } from '../../interfaces';
import { PaymentActions } from '../actions';

export const initialState: PaymentState = Object.seal({
  paymentStatus: PaymentStatus.INITIAL,
  redirectionUrl: null,
  paymentIds: [],
});

type PaymentStateKey<T> = T extends keyof PaymentState ? T : never;

const PAYMENT_STATUS: PaymentStateKey<'paymentStatus'> = 'paymentStatus'; // Persisted
const PAYMENT_REDIRECTIONURL: keyof PaymentState = 'redirectionUrl'; // Persisted

export const paymentReducer = createReducer(
  initialState,
  on(updateStoreAction, CartActions.setCartData, () => {
    return initialState;
  }),
  on(PaymentActions.setPaymentStatus, (state, { status }) => {
    return {
      ...state,
      [PAYMENT_STATUS]: status,
    };
  }),
  on(PaymentActions.setPaymentRedirectionUrl, (state, { url }) => {
    return {
      ...state,
      [PAYMENT_REDIRECTIONURL]: url,
    };
  }),
  on(GlobalActions.paymentVerified, (state) => {
    return {
      ...state,
      [PAYMENT_STATUS]: PaymentStatus.VERIFIED,
    };
  }),
  on(GlobalActions.setPaymentIdPaid, (state, { paymentId }) => {
    return {
      ...state,
      [PAYMENT_STATUS]: PaymentStatus.INITIAL,
      paymentIds: [...new Set([...state.paymentIds, paymentId])],
    };
  }),
  on(GlobalActions.paymentNotPaid, (state) => {
    return {
      ...state,
      [PAYMENT_STATUS]: PaymentStatus.INITIAL,
    };
  })
);
