/* eslint no-irregular-whitespace: "off" -- Required for `replaceNonBreakingSpaces` example. */

/**
 * Replaces extra spaces with a single space.
 *
 * @example
 * ```ts
 * const replaced = replaceExtraSpaces('a b c d');
 * console.log(replaced); // 'a b c d'
 * ```
 * @example
 * ```ts
 * const replaced = replaceExtraSpaces('a  b   c    d');
 * console.log(replaced); // 'a b c d'
 * ```
 */
export const replaceExtraSpaces = (value: string): string => {
  return value.replace(/[\u0020\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u200B]+/g, ' ');
};

/**
 * Replaces all non-breaking spaces with a regular space.
 *
 * @example
 * ```ts
 * const replaced = replaceNonBreakingSpaces('a b  c   d');
 * console.log(replaced); // 'a b  c   d'
 * ```
 * @example
 * ```ts
 * const replaced = replaceNonBreakingSpaces('a&nbsp;b&nbsp;&nbsp;c&nbsp;&nbsp;&nbsp;d');
 * console.log(replaced); // 'a b c d'
 * ```
 */
export const replaceNonBreakingSpaces = (value: string): string => {
  return value.replace(/[\u00A0\u202F]|&nbsp;/g, ' ');
};

/**
 * Removes all spaces from a string.
 *
 * @example
 * ```ts
 * const stripped = stripSpaces('1234 5678 1234 5678');
 * console.log(stripped); // '1234567812345678'
 */
export const stripSpaces = (value: string): string => {
  return value.replace(/\s/g, '');
};

/**
 * Converts kebab-case or separate words to camelCase.
 *
 * @example
 * ```ts
 * const converted = toCamelCase('hello-world');
 * console.log(converted); // 'helloWorld'
 * ```
 */
export const toCamelCase = (value: string): string => {
  return value
    .replace(/-/g, ' ')
    .replace(/\s(.)/g, (v: string): string => v.toUpperCase())
    .replace(/\s/g, '')
    .replace(/^(.)/, (v: string): string => v.toLowerCase());
};

/**
 * Converts a string to kebab-case.
 *
 * Only supports the following characters: `a-z, å, ä, ö, 0-9, -`.
 * Underscore characters `_` will be converted to hyphens `-`.
 * Other special characters will be removed.
 *
 * @example
 * ```ts
 * const converted = toKebabCase('Hello World!');
 * console.log(converted); // 'hello-world'
 * ```
 */
export const toKebabCase = (value: string): string => {
  return value
    .toLowerCase()
    .replace(/[^a-zåäö0-9-]+/g, '-')
    .replace(/[\s_]+/g, '-')
    .replace(/-{2,}/g, '-')
    .replace(/^-/, '')
    .replace(/-$/, '');
};

export type ReplaceParams = Array<string | number> | { [key: string]: string | number };

/**
 * Removes leading and trailing spaces in a string.
 * Supports multiline strings (i.e. from textareas).
 *
 * @example
 * ```ts
 * const value = '  Hello World!  ';
 * console.log(trimContent(value)); // Hello World!
 * ```
 * @example
 * ```ts
 * const value = `  Hello
 *   World!  `;
 * console.log(trimContent(value));
 * // Output:
 * // 'Hello
 * // World!'
 * ```
 */
export const trimContent = (content: string): string => {
  return content
    .split('\n')
    .map((v: string): string => v.trim())
    .join('\n')
    .trim();
};
