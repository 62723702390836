<!-- Traditional booking grid -->
<div
  *ngIf="(tripType$ | async) !== TripType.MULTICITY"
  class="selector-grid grid-col-12 no-gutters"
  [class.ps-medium-b]="!isGlobalBookingWidget"
  [class.ps-small-b]="isGlobalBookingWidget"
  data-testid="booking-widget"
  [ngClass]="[layout, isGlobalBookingWidget ? WidgetTheme.DARK : WidgetTheme.LIGHT]"
>
  <fin-trip-type-selector
    [isGlobalBookingWidget]="isGlobalBookingWidget"
    [enableMultiCity]="enableMultiCity"
    (setTripType)="setTripType($event)"
    [highLight$]="globalBookingWidgetSelectionChanges$ | finMap: SelectionType.TRIP_TYPE"
  ></fin-trip-type-selector>
  <fin-location-selector
    [locationPair$]="locations$"
    [showPreviousSearches]="true"
    (setLocations)="setLocations($event)"
    [isGlobalBookingWidget]="isGlobalBookingWidget"
    [highLightOrigin$]="globalBookingWidgetSelectionChanges$ | finMap: LocationType.ORIGIN"
    [highLightDestination$]="globalBookingWidgetSelectionChanges$ | finMap: LocationType.DESTINATION"
  ></fin-location-selector>

  <ng-container *ngIf="(showCompact$ | async) === false || isGlobalBookingWidget">
    <fin-pax-amount-selector
      (setPaxAmount)="setPaxAmount($event)"
      [highLight$]="globalBookingWidgetSelectionChanges$ | finMap: SelectionType.PAX"
    ></fin-pax-amount-selector>
    <fin-travel-dates-selector
      [travelDates$]="travelDates$"
      [isOneway$]="(tripType$ | async) === TripType.ONEWAY | finToObservable"
      [showAddReturn]="(tripType$ | async) === TripType.ONEWAY"
      [prices$]="prices$"
      [showFlexibleDatesSelection]="layout !== WidgetLayout.HORIZONTAL"
      [identifier]="identifier"
      (setTravelDates)="setTravelDates($event, false)"
      (setFlexibleDates)="setFlexibleDates($event)"
      (addReturn)="onAddReturnClick()"
      [highLight$]="globalBookingWidgetSelectionChanges$ | finMap: SelectionType.TRAVEL_DATES"
    ></fin-travel-dates-selector>
  </ng-container>
</div>

<!-- Multicity booking grid -->
<ng-container *ngIf="(tripType$ | async) === TripType.MULTICITY">
  <div data-testid="booking-widget-multicity" class="selector-grid multicity grid-col-12 no-gutters ms-small-b">
    <fin-trip-type-selector
      [isGlobalBookingWidget]="isGlobalBookingWidget"
      [enableMultiCity]="enableMultiCity"
      (setTripType)="setTripType($event)"
    ></fin-trip-type-selector>
    <fin-pax-amount-selector (setPaxAmount)="setPaxAmount($event)"></fin-pax-amount-selector>
  </div>
  <fin-multicity-selector
    data-testid="multi-city-selector"
    [enableMultiCity]="enableMultiCity"
  ></fin-multicity-selector>
</ng-container>
<!-- Footer -->
<div
  class="footer"
  *ngIf="(showCompact$ | async) === false || isGlobalBookingWidget || (tripType$ | async) === TripType.MULTICITY"
  [class.flex]="layout === WidgetLayout.HORIZONTAL"
  [ngClass]="{
    'flex flex--space-between flex--middle white-text':
      layout === WidgetLayout.HORIZONTAL && (bookingFlowType$ | async) !== WidgetTab.AWARD,
    'flex--end': layout === WidgetLayout.HORIZONTAL && (bookingFlowType$ | async) === WidgetTab.AWARD,
  }"
  data-testid="booking-widget-footer"
>
  <div *ngIf="showDiscountCode$ | async">
    <fin-discount-code [isGlobalBookingWidget]="isGlobalBookingWidget"></fin-discount-code>
  </div>
  <div class="flex flex--middle flex--end">
    <div
      class="flex-2 ps-medium-t"
      *ngIf="(bookingFlowType$ | async) === WidgetTab.FLIGHT && layout !== WidgetLayout.HORIZONTAL"
      data-testid="booking-widget-footer-price"
    >
      <ng-container *ngIf="(tripType$ | async) !== TripType.MULTICITY">
        <div class="font-body-1 regular-type">
          {{
            ((tripType$ | async) === TripType.ONEWAY
              ? 'bookingWidget.priceCalendar.oneWayPerAdult'
              : 'bookingWidget.priceCalendar.roundTripPerAdult'
            ) | finLocalization
          }}
        </div>
        <div class="nordic-900-text bold-type font-heading-4">
          {{ startingPrice$ | async | finPrice: { stripZeroCents: true, useSymbol: true } | finEmpty: '&ndash;' }}
        </div>
      </ng-container>
    </div>
    <fcom-button
      class="continue-btn"
      [class.ps-xxsmall-b]="isGlobalBookingWidget"
      [theme]="ButtonTheme.LIGHT_PRIMARY_CTA_HEATHER"
      [size]="ButtonSize.LARGE"
      [mode]="ButtonMode.FILL"
      [loading]="loading$ | async"
      [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
      [text]="(isGlobalBookingWidget ? 'bookingSearch.startNewSearch' : 'bookingSearch.search') | finLocalization"
      [disabled]="
        isGlobalBookingWidget ? (isNewSearchEnabled$ | async) === false : (continueEnabled$ | async) === false
      "
      (clickBtn)="startSearch()"
      data-testid="booking-widget-search-button"
    >
    </fcom-button>
  </div>
</div>
<fin-booking-widget-notifications [locations$]="flights$" [tripType$]="tripType$"></fin-booking-widget-notifications>
<fin-air-calendar
  *ngIf="airCalendarPrices$ | async"
  [paxAmount$]="paxAmount$"
  [travelDates$]="travelDates$"
  [tripType$]="tripType$"
  [prices$]="airCalendarPrices$"
  [isAward]="(bookingFlowType$ | async) === WidgetTab.AWARD"
  [totalPoints]="(profile$ | async)?.awardPoints"
  (selectDates)="setTravelDates($event, true)"
  (continue)="navigateToBookingFlow(true, $event)"
></fin-air-calendar>
