import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { finShare } from '@fcom/rx';

import { PopoverDirective } from '../directive/popover.directive';

/**
 * PopoverService makes sure that if there are multiple popovers in one view, only one of them is open at the time
 */
@Injectable({
  providedIn: 'root',
})
export class PopoverService {
  private openPopover: PopoverDirective = null;

  /**
   * This is a compatibility "API" to be used with current login/join forms/dialogs
   * that were implemented before the latest popover (popover) refactoring.
   *
   * DISCLAIMER: The login and join forms will be refactored later to use the newly refactored
   * popover component. It was decided that due to an upcoming A/B test, we would
   * manage the behaviour with something like this instead of a full refactoring.
   */
  _popoverOpen$ = new BehaviorSubject(false);

  get popoverOpen$(): Observable<boolean> {
    return this._popoverOpen$.asObservable().pipe(finShare());
  }

  open(popover: PopoverDirective): void {
    if (this.openPopover) {
      this.openPopover.destroyPopover();
    }
    this.openPopover = popover;
    this._popoverOpen$.next(true);
  }

  close(shouldFocusOnHost?: boolean): void {
    this._popoverOpen$.next(false);
    if (this.openPopover) {
      this.openPopover.destroyPopover(shouldFocusOnHost);
    }

    this.openPopover = null;
  }

  closeByContext(context: string): void {
    if (this.openPopover && this.openPopover.options.context === context) {
      this.close();
    }
  }
}
