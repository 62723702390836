import { createReducer, on } from '@ngrx/store';

import { setIn } from '@fcom/core/utils';

import { BookingWidgetActions } from '../actions';
import { BookingWidgetState, NotificationWarning, WidgetTab } from '../../interfaces';

export const initialState: BookingWidgetState = {
  instantSearchMonitoring: {},
  prices: {},
  warnings: {},
  activeTab: WidgetTab.FLIGHT,
};

export function bookingWidgetReducer(
  state: BookingWidgetState = initialState,
  action: BookingWidgetActions.BookingWidgetActionsUnion
): BookingWidgetState {
  return stateReducer(state, action);
}

const stateReducer = createReducer(
  initialState,
  on(BookingWidgetActions.clearState, () => ({ ...initialState })),
  on(BookingWidgetActions.setInstantSearchMonitoring, (state, { instantSearchMonitoring }) =>
    setIn(state, 'instantSearchMonitoring', instantSearchMonitoring)
  ),
  on(BookingWidgetActions.resetPrices, (state) => setIn(state, 'prices', initialState.prices)),
  on(BookingWidgetActions.setFullYearPrices, (state, { prices }) => setIn(state, 'prices.fullYear', prices)),
  on(BookingWidgetActions.setFollowingMonthPrices, (state, { prices }) =>
    setIn(state, 'prices.followingMonth', prices)
  ),
  on(BookingWidgetActions.setHistogramPrices, (state, { prices }) => setIn(state, 'prices.histogram', prices)),
  on(BookingWidgetActions.setNotificationWarning, (state, { key, isActive, data }) =>
    setIn(state, 'warnings', { ...state.warnings, [key]: { isActive, data } })
  ),
  on(BookingWidgetActions.clearNotificationWarning, (state, { key }) => {
    const { [key]: _, ...updatedWarnings } = state.warnings;
    return setIn(state, 'warnings', updatedWarnings);
  }),
  on(BookingWidgetActions.setAirCalendarPrices, (state, { prices }) => setIn(state, 'prices.airCalendar', prices)),
  on(BookingWidgetActions.clearServiceNotificationWarnings, (state) => {
    const {
      [NotificationWarning.NO_FLIGHTS]: _,
      [NotificationWarning.SEASONAL_ROUTE]: __,
      [NotificationWarning.NO_AWARD_FLIGHTS]: ___,
      [NotificationWarning.NO_FLIGHTS_FOR_ROUTE]: ____,
      [NotificationWarning.NO_AWARD_FLIGHTS_FOR_ROUTE]: _____,
      ...updatedWarnings
    } = state.warnings;
    return setIn(state, 'warnings', updatedWarnings);
  }),
  on(BookingWidgetActions.clearNoFlightsNotificationWarnings, (state) => {
    const {
      [NotificationWarning.NO_FLIGHTS]: _,
      [NotificationWarning.NO_AWARD_FLIGHTS]: __,
      [NotificationWarning.NO_FLIGHTS_FOR_ROUTE]: ___,
      [NotificationWarning.NO_AWARD_FLIGHTS_FOR_ROUTE]: ____,
      ...updatedWarnings
    } = state.warnings;
    return setIn(state, 'warnings', updatedWarnings);
  }),
  on(BookingWidgetActions.setActiveTab, (state, { activeTab }) => setIn(state, 'activeTab', activeTab))
);
