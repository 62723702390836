@if (
  navigationType === CalendarNavigationType.ARROWS ||
  (navigationType === CalendarNavigationType.MIXED && !dataModel?.isMobile)
) {
  <div class="control-bar ps-xxlarge-x">
    <fcom-icon-button
      class="control absolute prev"
      [class.translate]="showTags"
      [disabled]="dataModel?.prevDisabled"
      [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.ARROW_LEFT }"
      [theme]="IconButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE"
      [size]="IconButtonSize.MEDIUM"
      [aria]="{ label: uiLabels?.showPreviousMonth }"
      (clickBtn)="onNavigateEvent($event, CalendarNavigationEvent.PREV)"
    >
    </fcom-icon-button>

    @if (showTags) {
      <div class="ms-medium-x ps-xsmall scrollable-tags" data-testid="calendar-chips">
        @for (tagItem of dataModel?.monthTags; track tagItem; let i = $index) {
          <fcom-tag-button
            #tag
            [class.ms-xsmall-l]="i !== 0"
            [theme]="
              i === visibleMonthIndex || (i === visibleMonthIndex + 1 && dataModel?.displayMonths === 2)
                ? TagTheme.ACTIVE
                : TagTheme.DEFAULT
            "
            [text]="tagItem"
            (tagClicked)="selectMonth(i)"
          >
          </fcom-tag-button>
        }
      </div>
    }

    <fcom-icon-button
      class="control absolute next"
      [class.translate]="showTags"
      [disabled]="dataModel?.nextDisabled"
      [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.ARROW_RIGHT }"
      [theme]="IconButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE"
      [size]="IconButtonSize.MEDIUM"
      [aria]="{ label: uiLabels?.showNextMonth }"
      (clickBtn)="onNavigateEvent($event, CalendarNavigationEvent.NEXT)"
    >
    </fcom-icon-button>
  </div>
}

<div
  class="monthsArea flex-1 white-bg fill ps-xsmall-b"
  tabindex="-1"
  [class.mixed]="navigationType === CalendarNavigationType.MIXED"
>
  <div class="months flex nowrap" #calendarMonths>
    @for (month of dataModel?.months; track month) {
      <fcom-calendar-month
        #calendarMonth
        id="{{ month.monthArrayIndex }}"
        class="month fill"
        [class.two-month-view]="displayMonths === 2"
        [value]="month.id"
        [isDateRange]="isDateRange"
        [showEmptyWeeks]="showEmptyWeeks"
        [dayTemplate]="dateTemplate"
      ></fcom-calendar-month>
    }
  </div>
</div>

<ng-template #dateTemplate let-day="day">
  @if (day.id) {
    <button
      class="day fill font-body-1 medium-type"
      [disabled]="day.disabled"
      [attr.tabindex]="day.tabindex"
      [attr.data-day]="day.id"
      [attr.aria-label]="day.ariaLabel"
      [class.nordic-blue-900-bg]="
        day.selected ||
        (isDateRange &&
          dataModel.selectedDates?.startDate &&
          !dataModel.selectedDates?.endDate &&
          day.date.gt(dataModel.selectedDates?.startDate) &&
          day.date.equals(this.hoveredDate))
      "
      [class.white-text]="
        day.selected ||
        (isDateRange &&
          dataModel.selectedDates?.startDate &&
          !dataModel.selectedDates?.endDate &&
          day.date.gt(dataModel.selectedDates?.startDate) &&
          day.date.equals(this.hoveredDate))
      "
      [class.nordic-blue-50-bg]="isInside(day) || isHovered(day)"
      [class.selectionStart]="isDateRange && day.selected && dataModel.selectedDates?.startDate?.equals(day.date)"
      [class.selectionEnd]="isDateRange && day.selected && dataModel.selectedDates?.endDate?.equals(day.date)"
      [class.selectionSingle]="!isDateRange && day.selected"
      [class.selectableDay]="!day.disabled"
      [class.disabled-area]="day.disabled"
      (mouseenter)="hoveredDate = day?.date"
      (mouseleave)="hoveredDate = null"
      (click)="selectDay(day); $event.preventDefault()"
    >
      @if (dayTemplate) {
        <ng-container
          [ngTemplateOutlet]="dayTemplate"
          [ngTemplateOutletContext]="{ dayString: day.value, dayValue: day }"
        >
        </ng-container>
      } @else {
        <div *ngIf="!dayTemplate">{{ day.value }}</div>
      }
    </button>
  } @else {
    <div class="day">&nbsp;</div>
  }
</ng-template>
