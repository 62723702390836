import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { v4 as uuid } from 'uuid';
import { Observable, map, of } from 'rxjs';

import { PopoverOptions } from '../popover/popover.interface';
import { PopoverPosition } from '../popover/popover.enum';
import { ButtonTheme } from '../buttons/button';
import { ModalButtons } from '../modal';
import { NotificationLayout, NotificationTheme } from '../../components/notifications';

/**
 * A expandable area controllable via inputs.
 *
 * This only handles the expandable area and not the button to trigger this content area. This
 * should be done in a case-by-case basis.
 *
 * @example
 *
 * <code>
 *   <pre>
 *       <fcom-filters
 [hasFiltering]="hasFiltering"
 [numberToShowWhenFiltered]="number"
 [headerText]="headerText"
 [buttonText]="buttonText"
 [buttonTextWhenFiltered]="'Filtered'"
 (primaryFooterButtonClick)="showResults()"
 (secondaryFooterButtonClick)="showResults()"
 *       >
 *          <div>
 *            <p>Can have some static and non-interactable content...</p>
 *            <fcom-checkbox [checked]="checked" (change)="changeHandledInParentComponent()">
 *               <span class="font-body-1">...but I understand that the point is to have interaction here</span>
 *            </fcom-checkbox>
 *          </div>
 *       </fcom-filters>
 *   </pre>
 * </code>
 */
@Component({
  selector: 'fcom-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersComponent implements OnInit {
  readonly ButtonTheme = ButtonTheme;
  readonly IconLibrary = IconLibrary;
  readonly ModalButtons = ModalButtons;
  readonly NotificationTheme = NotificationTheme;
  readonly NotificationLayout = NotificationLayout;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  expandButtonId = uuid();

  @Input()
  disabled = false;

  @Input()
  buttonText: string;

  @Input()
  buttonTextWhenFiltered: string;

  @Input()
  headerText: string;

  @Input()
  hasFiltering = false;

  @Input()
  numberToShowWhenFiltered?: number;

  @Input()
  primaryFooterButtonTitle: string;

  @Input()
  modalTitle: string;

  @Input()
  notificationContent: string;

  @Input()
  isModalOpen: boolean;

  @Input()
  displayNotification: boolean;

  @Input()
  isMobile$: Observable<boolean>;

  @Input()
  popoverOptionsOverride$: Observable<Partial<PopoverOptions>> = of({});

  @Output()
  primaryFooterButtonClick: EventEmitter<void> = new EventEmitter();

  @Input()
  secondaryFooterButtonTitle: string;

  @Output()
  secondaryFooterButtonClick: EventEmitter<void> = new EventEmitter();

  @Output()
  popoverOpeningClick: EventEmitter<void> = new EventEmitter();

  @Output()
  showModal = new EventEmitter();

  @Output()
  closeModal = new EventEmitter();

  @Output()
  popoverClosed = new EventEmitter();

  popoverOptions$: Observable<PopoverOptions>;

  ngOnInit(): void {
    this.popoverOptions$ = this.popoverOptionsOverride$.pipe(
      map((override) => ({
        popoverID: `filters-popover-${this.expandButtonId}`,
        openByDefault: false,
        enableOnClick: true,
        showCloseButton: true,
        showArrowCaret: false,
        showLeftBorder: false,
        popoverPosition: PopoverPosition.BOTTOM,
        roundedCorners: true,
        hasPadding: false,
        ...override,
      }))
    );
  }
}
