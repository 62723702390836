import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { AmHolidayTypeDuration } from '../../interfaces/am-api.interface';

@Component({
  selector: 'fin-am-duration-selector',
  templateUrl: './am-duration-selector.component.html',
  styleUrls: ['./am-duration-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmDurationSelectorComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  disabled = false;

  @Input()
  selectedDuration: AmHolidayTypeDuration;

  @Input()
  durationOptions: AmHolidayTypeDuration[];

  @Output()
  setDuration = new EventEmitter<AmHolidayTypeDuration>();

  modalOpen = false;

  openModal(): void {
    this.modalOpen = true;
  }

  onDurationSelect(duration: AmHolidayTypeDuration): void {
    this.setDuration.emit(duration);
    this.modalOpen = false;
  }
}
