import {
  FinnairBoundItem,
  FinnairItineraryItem,
  FinnairItineraryItemFlight,
  FinnairItineraryItemLayover,
  FinnairItineraryItemType,
  FinnairItineraryNote,
} from '@fcom/dapi/api/models';
import { isComplexBooking } from '@fcom/manage-booking-common/utils';

import { BoundReaccommodationStatus, BoundReaccommodationType } from '../interfaces';

/**
 * Get notification message if user has selected flight that has different airport or date than original flight
 * @param cancelled bound that has been cancelled
 * @param selected new bound the user has selected
 */
export const getNotificationMessageForNewFlight = (cancelled: FinnairBoundItem, selected: FinnairBoundItem): string => {
  const differentAirport = differentAirportSelected(cancelled, selected);
  const differentDate = differentDateSelected(cancelled, selected);

  if (differentAirport && differentDate) {
    return 'reaccommodation.notifications.differentAirportAndDate';
  } else if (differentDate) {
    return 'reaccommodation.notifications.differentDate';
  } else if (differentAirport) {
    return 'reaccommodation.notifications.differentAirport';
  }

  return null;
};

/**
 * Determine if user has selected flight that has different location than in original flight
 * @param cancelled bound that has been cancelled
 * @param selected new bound the user has selected
 */
export const differentAirportSelected = (cancelled: FinnairBoundItem, selected: FinnairBoundItem): boolean => {
  const bound = cancelled?.disruptedBound?.disruptedBound || cancelled;
  return (
    bound?.departure?.locationCode !== selected.departure.locationCode ||
    bound?.arrival?.locationCode !== selected.arrival.locationCode
  );
};

/**
 * Determine if user has selected flight that has different date than in original flight
 * @param cancelled bound that has been cancelled
 * @param selected new bound the user has selected
 */
export const differentDateSelected = (cancelled: FinnairBoundItem, selected: FinnairBoundItem): boolean => {
  const bound = cancelled?.disruptedBound?.disruptedBound || cancelled;
  return (
    isDifferentDay(bound.departure.dateTime, selected.departure.dateTime) ||
    isDifferentDay(bound.arrival.dateTime, selected.arrival.dateTime)
  );
};

/**
 * Determine if the bound has a layover
 * @param bound a bound of an itinerary
 */
export const boundHasOvernightLayover = (bound: FinnairBoundItem): boolean => {
  return bound.itinerary.some((item: FinnairItineraryItem) => {
    return (
      item.type === FinnairItineraryItemType.LAYOVER &&
      (item as FinnairItineraryItemLayover).note === FinnairItineraryNote.HAS_NEXT_DAY_TRANSFER
    );
  });
};

/**
 * Determine if datetimes have different days
 * @param start datetime (e.g. 2019-09-13T14:26:19.828Z)
 * @param end datetime (e.g. 2019-09-13T14:26:19.828Z)
 */
export const isDifferentDay = (start: string, end: string): boolean => {
  return start?.split('T')[0] !== end?.split('T')[0];
};

/**
 * Get the bound type for a bound (outbound, inbound, complex)
 * @param bounds all bounds in the booking
 * @param index index for the bound to be determined
 */
export const getBoundType = (bounds: FinnairBoundItem[], index: number): BoundReaccommodationType => {
  if (isComplexBooking(bounds)) {
    return BoundReaccommodationType.COMPLEX;
  } else {
    return index === 0 ? BoundReaccommodationType.OUTBOUND : BoundReaccommodationType.INBOUND;
  }
};

/**
 * Get heading for a single bound based on type and status
 * @param type wheter bound is inbound or outbound
 * @param status wheter bound is rebooked, new or cancelled
 */
export const getBoundHeadingLabel = (type: BoundReaccommodationType, status: BoundReaccommodationStatus): string => {
  return `reaccommodation.boundHeadings.${type}.${status || BoundReaccommodationStatus.DEFAULT}`;
};

export const getFareFamilyLabel = (bound: FinnairBoundItem): string =>
  bound.fareFamily?.name || (bound?.itinerary[0] as FinnairItineraryItemFlight)?.cabinClass;

export default {
  getFareFamilyLabel,
  getBoundHeadingLabel,
  getBoundType,
  isDifferentDay,
  boundHasOvernightLayover,
  differentDateSelected,
  differentAirportSelected,
  getNotificationMessageForNewFlight,
};
