import { Pipe, PipeTransform } from '@angular/core';

import { Observable, of } from 'rxjs';

import { LanguageService } from '@fcom/ui-translate';
import { Duration } from '@fcom/dapi';
// todo: remove OrderSummaryDuration after updating type in booking service
import { Duration as OrderSummaryDuration } from '@fcom/dapi/api/models';
import { finShare } from '@fcom/rx';

/**
 * Transforms the duration object to a human readable format based on translations
 */
@Pipe({
  name: 'finDuration',
})
export class DurationPipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}
  transform(dur: Duration | OrderSummaryDuration): Observable<string> {
    if (!dur) {
      return of(undefined);
    }
    const { hours, minutes } = dur;
    return this.languageService.translate('bookingSearch.duration', [hours, minutes]).pipe(finShare());
  }
}
