import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { ElementTypes, GaContext } from '@fcom/common/interfaces';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[finSideBarNavigationItem]',
  styleUrls: ['./sidebar-navigation-item.component.scss'],
  templateUrl: './sidebar-navigation-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarNavigationItemComponent {
  readonly ElementTypes = ElementTypes;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TRACKING_CONTEXT = GaContext.SECTION_NAVIGATION;

  @Input()
  title: string;

  @Input()
  link: string = undefined;

  @Input()
  external = false;

  @Input()
  openInNewTab = false;

  @Input()
  active = false;

  @Input()
  loginRequired = false;

  @Input()
  styleClasses = 'nordic-blue-900-text';

  @Output()
  linkClicked: EventEmitter<Event> = new EventEmitter();

  onLinkClick(clickEvent: Event): void {
    clickEvent.stopPropagation();
    this.linkClicked.emit(clickEvent);
  }
}
