<div [formGroup]="parentForm" [class.ms-xlarge-b]="displayMargin">
  <div class="relative ps-xxsmall-b">
    <label class="font-body-1 grey-600-text" [for]="idOrControlName" [class.block]="type === FormInputType.TEXTAREA">
      {{ labelPrefix }}
      {{ translationPrefix + '.label' | finLocalization }}
      {{ labelSuffix }}
      <span *ngIf="isRequired"> *</span>
    </label>
    <input
      class="font-body-1 light-type"
      *ngIf="type === FormInputType.INPUT"
      [class.form-field-empty]="!(control.value || placeHolder)"
      [class.sensitive]="isSensitive"
      type="text"
      [formControlName]="controlName"
      [id]="idOrControlName"
      [attr.placeholder]="placeHolder ? (placeHolder | finLocalization) : null"
      [attr.aria-required]="isRequired"
      [name]="controlName"
      #inputElement
    />
    <span class="divider" *ngIf="parentForm.get(controlName)?.disabled === false"></span>
    <textarea
      class="font-body-1 border grey-200-bg fill ps-xsmall ms-small-t"
      *ngIf="type === FormInputType.TEXTAREA"
      [class.sensitive]="isSensitive"
      [class.resizeable]="isResizeable"
      type="text"
      [formControlName]="controlName"
      [id]="idOrControlName"
      [rows]="rows"
      #textareaElement
    ></textarea>
  </div>
  <div
    *ngIf="control.invalid && control.touched"
    class="error-label error-900-text font-body-2 light-type"
    aria-live="polite"
  >
    <span *ngIf="control.errors?.required">
      {{ translationPrefix + '.required' | finLocalization }}
    </span>
    <span *ngIf="control.errors?.pattern">
      {{ translationPrefix + '.pattern' | finLocalization }}
    </span>
    <span *ngIf="control.errors?.duplicate">
      {{ translationPrefix + '.duplicate' | finLocalization }}
    </span>
    <span *ngIf="control.errors?.maxlength">
      {{ translationPrefix + '.maxLength' | finLocalization }}
    </span>
    <span *ngIf="control.errors?.minlength">
      {{ translationPrefix + '.minLength' | finLocalization }}
    </span>
  </div>
  <div
    *ngIf="characterLimit && characterCount"
    class="nordic-blue-900-text font-body-1"
    [attr.data-testid]="'character-limit-for-' + controlName"
  >
    {{ characterCount }} / {{ characterLimit }}
  </div>
</div>
