import { Pipe, PipeTransform } from '@angular/core';

import { isBlank } from '@fcom/core/utils';

@Pipe({
  name: 'decodeURI',
})
export class DecodeURIPipe implements PipeTransform {
  transform(value: string, clearSpecial = false): string {
    return isBlank(value) ? '' : decodeURIComponent(clearSpecial ? value.replace(/\+/g, ' ') : value);
  }
}
