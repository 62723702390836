<fin-navigation></fin-navigation>

<span class="block">
  <router-outlet></router-outlet>
</span>

<router-outlet name="marketingPreferences"></router-outlet>

<fin-client-only [componentType]="toasterComponentType"></fin-client-only>

<fin-client-only [componentType]="cookiePolicyType"></fin-client-only>

<fin-client-only [componentType]="chatComponentType"></fin-client-only>
<fin-client-only [componentType]="chatGlobalVisibilityComponentType"></fin-client-only>

<fin-client-only [componentType]="feedbackWidgetComponentType"></fin-client-only>
