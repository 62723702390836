import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconsModule } from '../icons/icons.module';
import { CarouselComponent } from './carousel.component';

@NgModule({
  declarations: [CarouselComponent],
  imports: [CommonModule, IconsModule],
  exports: [CarouselComponent],
})
export class CarouselModule {}
