import { Injectable, OnDestroy, signal } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  of,
  startWith,
  switchMap,
  take,
  tap,
  withLatestFrom,
  iif,
} from 'rxjs';

import { finShare } from '@fcom/rx';
import { LocalDate, equals, isNotBlank, isPresent, unsubscribe, isEmptyObjectOrHasEmptyValues } from '@fcom/core';
import { FlightSearchParams, GlobalBookingTripDates, RecommendationService, TripType } from '@fcom/common';
import {
  CommonFeatureState,
  GlobalBookingActions,
  globalBookingDiscountCode,
  GlobalBookingState,
  globalBookingSelections,
  globalBookingTravelDates,
} from '@fcom/common/store';
import { FINNISH_SITES } from '@fcom/core/constants';
import { GlobalBookingTravelClass } from '@fcom/core/interfaces';
import { LoginStatus, Profile } from '@fcom/core-api/login';
import { Amount } from '@fcom/dapi/interfaces';
import { AirCalendarList } from '@fcom/dapi/api/models';
import { LanguageService } from '@fcom/ui-translate';
import { loginStatus, profile } from '@fcom/core/selectors';

import {
  getStartingFromPrice,
  createDeeplinkPathFromFlightSearchParams,
  hasCorrectAmountOfFlights,
} from '../utils/utils';
import {
  BookingWidgetActions,
  BookingWidgetAppState,
  airCalendarPrices,
  followingMonthPrices,
  fullYearPrices,
  histogramPrices,
  notificationWarnings,
} from '../store';
import {
  DatePickerPrices,
  InstantSearchBaseParams,
  PreviousSearch,
  WidgetTab,
  PriceParams,
  Warnings,
  NotificationWarning,
  SelectionType,
  GlobalBookingWidgetSelectionChangeMap,
} from '../interfaces';
import { BookingWidgetGtmService } from './booking-widget-gtm.service';
import { BookingWidgetCalendarService } from './booking-widget-calendar.service';
import { BookingWidgetFlightService } from './booking-widget-flight.service';
import { BookingWidgetTripService } from './booking-widget-trip.service';

@Injectable({ providedIn: 'root' })
export class BookingWidgetService implements OnDestroy {
  private _profile$: Observable<Profile>;
  private _notificationWarning$: Observable<Warnings>;
  private _loginStatus$: Observable<LoginStatus>;
  private _travelDates$: Observable<GlobalBookingTripDates>;
  private _continueEnabled$: Observable<boolean>;
  private _discountCode$: Observable<string>;
  private _flightSearchParams$: Observable<FlightSearchParams>;
  private _startingPrice$: Observable<Amount> = of(null);
  private _prices$: Observable<DatePickerPrices>;
  private _airCalendarPrices$: Observable<AirCalendarList>;
  private _priceRequestBaseParams$: Observable<InstantSearchBaseParams>;
  private _flexibleDates$ = new BehaviorSubject<boolean>(false);
  private _subscription = new Subscription();
  private _showCompact$ = new BehaviorSubject<boolean>(true);
  private _originalBookingSelection$ = new BehaviorSubject<GlobalBookingState>(undefined);
  private _globalBookingWidgetSelectionChanges$ = new BehaviorSubject<GlobalBookingWidgetSelectionChangeMap>({});
  private _isMultiCity$: Observable<boolean>;
  private usePopoverSelectorsSignal = signal(false);

  readonly usePopoverSelectors = this.usePopoverSelectorsSignal.asReadonly();

  get notificationWarning$(): Observable<Warnings> {
    return this._notificationWarning$;
  }

  get profile$(): Observable<Profile> {
    return this._profile$;
  }

  get loginStatus$(): Observable<LoginStatus> {
    return this._loginStatus$;
  }

  get travelDates$(): Observable<GlobalBookingTripDates> {
    return this._travelDates$;
  }

  get discountCode$(): Observable<string> {
    return this._discountCode$;
  }

  get flexibleDates$(): Observable<boolean> {
    return this._flexibleDates$.asObservable();
  }

  get continueEnabled$(): Observable<boolean> {
    return this._continueEnabled$;
  }

  get startingPrice$(): Observable<Amount> {
    return this._startingPrice$;
  }

  get airCalendarPrices$(): Observable<AirCalendarList> {
    return this._airCalendarPrices$;
  }

  get prices$(): Observable<DatePickerPrices> {
    return this._prices$;
  }

  get showCompact$(): Observable<boolean> {
    return this._showCompact$.asObservable();
  }

  get isMultiCity$(): Observable<boolean> {
    return this._isMultiCity$;
  }

  get originalBookingSelection$(): Observable<GlobalBookingState> {
    return this._originalBookingSelection$.asObservable();
  }

  get globalBookingWidgetSelectionChanges$(): Observable<GlobalBookingWidgetSelectionChangeMap> {
    return this._globalBookingWidgetSelectionChanges$.asObservable();
  }

  constructor(
    private store$: Store<CommonFeatureState & BookingWidgetAppState>,
    private languageService: LanguageService,
    private bookingWidgetGtmService: BookingWidgetGtmService,
    private recommendationService: RecommendationService,
    private bookingWidgetCalendarService: BookingWidgetCalendarService,
    private router: Router,
    private bookingWidgetFlightService: BookingWidgetFlightService,
    private bookingWidgetTripService: BookingWidgetTripService
  ) {
    // selectors
    this._profile$ = this.store$.pipe(profile(), startWith(undefined), finShare());
    this._loginStatus$ = this.store$.pipe(loginStatus(), finShare());

    this._notificationWarning$ = this.store$.pipe(notificationWarnings(), finShare());

    this._travelDates$ = this.store$.pipe(
      globalBookingTravelDates(),
      distinctUntilChanged(
        (prev, next) => prev.departureDate?.id === next.departureDate?.id && prev.returnDate?.id === next.returnDate?.id
      ),
      finShare()
    );

    this._discountCode$ = this.store$.pipe(
      globalBookingDiscountCode(),
      map((discountCode: string) => (isPresent(discountCode) ? discountCode : null)),
      finShare()
    );

    this._airCalendarPrices$ = this.store$.pipe(airCalendarPrices(), finShare());

    this._flightSearchParams$ = combineLatest([
      this.bookingWidgetTripService.selectedTripType$,
      this.bookingWidgetTripService.selectedTravelClass$,
      this.bookingWidgetFlightService.flights$,
      this.bookingWidgetTripService.paxAmount$,
      this.bookingWidgetTripService.activeTab$,
      this._discountCode$,
      this._profile$,
    ]).pipe(
      map(([tripType, travelClass, flights, paxAmount, tab, discountCode, userProfile]) => ({
        tripType,
        travelClass,
        flights,
        paxAmount,
        locale: this.languageService.localeValue,
        isAward: tab === WidgetTab.AWARD,
        promoCode: discountCode,
        isCorporate: userProfile?.isCorporate,
      })),
      finShare()
    );

    this._priceRequestBaseParams$ = combineLatest([
      this.bookingWidgetTripService.selectedTripType$,
      this.bookingWidgetTripService.selectedTravelClass$,
      this.bookingWidgetFlightService.locations$.pipe(
        filter((locations) => !!(locations.origin && locations.destination)),
        distinctUntilChanged(
          (previous, current) =>
            previous?.origin?.locationCode === current?.origin?.locationCode &&
            previous?.destination?.locationCode === current?.destination?.locationCode
        )
      ),
      this.bookingWidgetTripService.activeTab$,
    ]).pipe(
      filter(([, , , tab]) => tab === WidgetTab.FLIGHT || tab === WidgetTab.AWARD),
      withLatestFrom(this.bookingWidgetTripService.paxAmount$),
      map(([[tripType, travelClass, locations], paxAmount]) => ({
        tripType,
        travelClass,
        locations,
        paxAmount,
      })),
      filter((params) => this.priceParamsAreValid(params) && params.tripType !== TripType.MULTICITY),
      map((params) => this.mapToPriceRequestBaseParams(params)),
      distinctUntilChanged((prev, next) => !this.priceBaseParamsChanged(prev, next)),
      finShare()
    );

    this._subscription.add(
      this.bookingWidgetTripService.selectedTripType$
        .pipe(filter((tripType) => tripType === TripType.MULTICITY))
        .subscribe(() =>
          this.store$.dispatch(
            BookingWidgetActions.clearNotificationWarning({ key: NotificationWarning.SEASONAL_ROUTE })
          )
        )
    );

    this._subscription.add(
      combineLatest([
        this.bookingWidgetTripService.selectedTripType$,
        this.bookingWidgetFlightService.flights$,
        this.bookingWidgetTripService.activeTab$,
      ])
        .pipe(
          withLatestFrom(this._notificationWarning$),
          filter(([, warnings]) => Object.keys(warnings).length > 0)
        )
        .subscribe(() => {
          this.store$.dispatch(BookingWidgetActions.clearNoFlightsNotificationWarnings());
        })
    );

    this._subscription.add(
      this._priceRequestBaseParams$
        .pipe(
          withLatestFrom(this._notificationWarning$),
          filter(([, warnings]) => Object.keys(warnings).length > 0)
        )
        .subscribe(() =>
          this.store$.dispatch(
            BookingWidgetActions.clearNotificationWarning({ key: NotificationWarning.SEASONAL_ROUTE })
          )
        )
    );

    this._continueEnabled$ = combineLatest([
      this.bookingWidgetTripService.routeCffReady$,
      this._flightSearchParams$,
    ]).pipe(
      map(([routeCffReady, flightSearchParams]) => routeCffReady && this.validateFlightSearchParams(flightSearchParams))
    );

    this._prices$ = combineLatest([
      this.store$.pipe(fullYearPrices()),
      this.store$.pipe(followingMonthPrices()),
      this.store$.pipe(histogramPrices()),
      this.bookingWidgetTripService.selectedTripType$,
      this._travelDates$,
      this.bookingWidgetTripService.activeTab$,
    ]).pipe(
      map(([fullYear, followingMonth, histogram, tripType, { departureDate, returnDate }, tab]) => {
        if (tab !== WidgetTab.FLIGHT) {
          return undefined;
        }

        const onlyDepartureSelectedAndNotOneway =
          isPresent(departureDate) && tripType !== TripType.ONEWAY && !isPresent(returnDate);
        const prices = onlyDepartureSelectedAndNotOneway ? followingMonth : fullYear;

        const pricesWithSelections =
          departureDate && returnDate ? { ...prices, [returnDate?.id]: followingMonth?.[returnDate?.id] } : prices;

        return {
          calendar: pricesWithSelections,
          histogram,
        };
      }),
      finShare()
    );

    this._startingPrice$ = combineLatest([
      this._prices$,
      this._travelDates$,
      this.bookingWidgetTripService.selectedTripType$,
    ]).pipe(
      map(([prices, travelDates, tripType]) => getStartingFromPrice(prices, travelDates, tripType === TripType.ONEWAY))
    );

    this._isMultiCity$ = this._flightSearchParams$.pipe(map((params) => params.flights.length > 2));

    this._subscription.add(
      this._flightSearchParams$
        .pipe(
          filter((flightSearchParams) => this.validateFlightSearchParams(flightSearchParams)),
          distinctUntilChanged((prev, next) => equals(prev, next))
        )
        .subscribe((flightSearchParams) => this.bookingWidgetGtmService.preFlightSearch(flightSearchParams))
    );

    // subscription
    this.fetchPriceBasedOnSelection();
    this.fetchFollowingMonthPrice();
    this.updatePriceAndCompactStatus();
  }

  ngOnDestroy(): void {
    unsubscribe(this._subscription);
    this.resetSelection();
  }

  setPreviousSearchChange(): void {
    this._subscription.add(
      combineLatest([this.bookingWidgetFlightService.selectedPreviousSearches$, this.originalBookingSelection$])
        .pipe(
          distinctUntilChanged(
            ([preSearch, preOriginal], [nextSearch, nextOrignal]) =>
              equals(preSearch, nextSearch) && equals(preOriginal, nextOrignal)
          ),
          filter(([previousSearch, originalSelection]) => isPresent(previousSearch) && isPresent(originalSelection))
        )
        .subscribe(([previousSearches, originalBookingSelection]) => {
          this.previousSearchChanged(previousSearches, originalBookingSelection).forEach((changedType) => {
            this.setSelectionChangeType(changedType);
          });
        })
    );
  }

  setTravelDates(
    dates: GlobalBookingTripDates,
    index: number,
    isAirCalendar = false,
    isGlobalBookingWidget = false
  ): void {
    if (isGlobalBookingWidget) {
      this.setSelectionChangeType(SelectionType.TRAVEL_DATES);
    }

    if (!isAirCalendar) {
      // TODO: check if we would like a separate and different multi-city event as this does not really translate for that purpose
      this.bookingWidgetGtmService.trackElementEvent(
        'travel-dates',
        `DEPARTURE: ${dates.departureDate}, RETURN: ${dates.returnDate}`
      );
    }

    this.store$.dispatch(GlobalBookingActions.setFlightDate({ dates, index }));
  }

  setDiscountCode(discountCode: string, isGlobalBookingWidget = false): void {
    if (isGlobalBookingWidget) {
      this.setSelectionChangeType(SelectionType.DISCOUNT_CODE);
    }
    this.store$.dispatch(GlobalBookingActions.setDiscountCode({ discountCode }));
    if (discountCode) {
      this.bookingWidgetGtmService.trackElementEvent('promo-code-modal-done', discountCode);
    }
  }

  setFlexibleDates(isFlexibleDates: boolean): void {
    this._flexibleDates$.next(isFlexibleDates);
  }

  isAMTabEnabled(enableAM: boolean): Observable<boolean> {
    return of(enableAM && FINNISH_SITES.includes(this.languageService.langValue));
  }

  validateFlightSearchParams({ flights, tripType, travelClass, paxAmount }: FlightSearchParams): boolean {
    const travelClassIsValid = isPresent(travelClass);
    const paxAmountIsValid = paxAmount.adults >= 1;
    const flightsAreValid = flights.every((flight, i) => {
      const previousFlight = flights[i - 1];
      const hasConsecutiveDates = !previousFlight || flight.departureDate?.gte(previousFlight.departureDate);
      return flight.origin && flight.destination && flight.departureDate && hasConsecutiveDates;
    });

    return travelClassIsValid && paxAmountIsValid && flightsAreValid && hasCorrectAmountOfFlights(tripType, flights);
  }

  resetSelection(): void {
    this.store$.dispatch(GlobalBookingActions.resetSelection());
  }

  expandCompactWidget(): void {
    if (this._showCompact$.getValue()) {
      this._showCompact$.next(false);
    }
  }

  setCompactWidget(showCompactWigetStatus: boolean): void {
    this._showCompact$.next(showCompactWigetStatus);
  }

  startNewSearch(): void {
    this.bookingWidgetGtmService.trackElementEvent('global-widget-start-new-search');
    this.resetLocalOriginalBookingSelection();
    //reset shadow
    this._subscription.add(
      this._flightSearchParams$.pipe(take(1)).subscribe((flightSearchParams) => {
        this.bookingWidgetFlightService.setPreviousSearchToLocalStorage(flightSearchParams);
        this.router.navigateByUrl(
          createDeeplinkPathFromFlightSearchParams({
            ...flightSearchParams,
            locale: this.languageService.langValue,
          })
        );
      })
    );
  }

  navigateToBookingFlow(
    fromMatrix = false,
    airCalendarPrice: string | undefined = undefined,
    loading$: Subject<boolean>
  ): void {
    this._subscription.add(
      this._continueEnabled$
        .pipe(
          take(1),
          filter(Boolean),
          withLatestFrom(this._flightSearchParams$, this._flexibleDates$, this._startingPrice$),
          switchMap(([_, flightSearchParams, flexibleDates, startingPrice]) => {
            loading$.next(true);
            this.sendRecommendationsFlightSearchEvent(flightSearchParams);

            if (fromMatrix) {
              return of({ continueToBooking: true, flightSearchParams, airCalendarPrice, startingPrice });
            } else {
              return iif(
                () => flexibleDates || flightSearchParams.isAward,
                this.bookingWidgetCalendarService.getAirCalendar(flightSearchParams).pipe(map(() => false)),
                this.bookingWidgetCalendarService.checkFlightAvailabilityAndContinue(flightSearchParams)
              ).pipe(
                map((continueToBooking) => ({
                  continueToBooking,
                  flightSearchParams,
                  startingPrice,
                  airCalendarPrice,
                }))
              );
            }
          }),
          tap(() => loading$.next(false)),
          filter(({ continueToBooking }) => continueToBooking),
          map(({ flightSearchParams, startingPrice, airCalendarPrice }) => {
            this.bookingWidgetFlightService.setPreviousSearchToLocalStorage(flightSearchParams);
            return {
              deeplink: createDeeplinkPathFromFlightSearchParams({
                ...flightSearchParams,
                locale: this.languageService.langValue,
              }),
              instantSearchMonitoring: {
                airCalendarPrice,
                instantSearchPrice: startingPrice?.amount,
              },
            };
          })
        )
        .subscribe(({ deeplink, instantSearchMonitoring }) => {
          this.store$.dispatch(BookingWidgetActions.setInstantSearchMonitoring({ instantSearchMonitoring }));
          this.router.navigateByUrl(deeplink);
        })
    );
  }

  navigateToMultiCityBookingFlow(loading$: Subject<boolean>): void {
    this._subscription.add(
      this.continueEnabled$
        .pipe(
          take(1),
          filter(Boolean),
          withLatestFrom(this._flightSearchParams$),
          switchMap(([continueToBooking, flightSearchParams]) => {
            loading$.next(true);
            return of({ continueToBooking, flightSearchParams });
          }),
          tap(() => loading$.next(false)),
          filter(({ continueToBooking }) => continueToBooking),
          map(({ flightSearchParams }) => {
            this.bookingWidgetFlightService.setPreviousSearchToLocalStorage(flightSearchParams);
            return {
              deeplink: createDeeplinkPathFromFlightSearchParams({
                ...flightSearchParams,
                locale: this.languageService.langValue,
              }),
            };
          })
        )
        .subscribe(({ deeplink }) => {
          this.router.navigateByUrl(deeplink);
        })
    );
  }

  setUsePopoverSelectors(value: boolean): void {
    this.usePopoverSelectorsSignal.set(value);
  }

  setLocalOriginalBookingSelection(): void {
    if (!this._originalBookingSelection$.getValue()) {
      this._subscription.add(
        combineLatest([
          this._globalBookingWidgetSelectionChanges$.pipe(startWith({})),
          this.bookingWidgetFlightService.selectedPreviousSearches$.pipe(startWith(undefined)),
        ])
          .pipe(
            distinctUntilChanged(
              ([preChange, preSearch], [nextChange, nextSearch]) =>
                equals(preChange, nextChange) && equals(preSearch, nextSearch)
            ),
            filter(([change, search]) => !isEmptyObjectOrHasEmptyValues(change) || isPresent(search)),
            withLatestFrom(this.store$.pipe(globalBookingSelections())),
            take(1)
          )
          .subscribe(([_, flightSelection]) => {
            this._originalBookingSelection$.next(flightSelection);
          })
      );
    }
  }

  resetLocalOriginalBookingSelection(): void {
    this._globalBookingWidgetSelectionChanges$.next({});
    this.bookingWidgetFlightService.resetSelectedPreviousSearch();
    this._originalBookingSelection$.next(undefined);
  }

  setGlobalBookingSelectionToOriginal(): void {
    this.store$.dispatch(
      GlobalBookingActions.updateSelection({
        selection: this._originalBookingSelection$.getValue(),
      })
    );
    this.resetLocalOriginalBookingSelection();
  }

  setSelectionChangeType(type: SelectionType): void {
    const currentChange = this._globalBookingWidgetSelectionChanges$.getValue();
    if (!currentChange[type]) {
      this._globalBookingWidgetSelectionChanges$.next({
        ...currentChange,
        [type]: true,
      });
    }
  }

  private previousSearchChanged(
    previousSearches: PreviousSearch,
    originalBookingSelection: GlobalBookingState
  ): SelectionType[] {
    const selectionChangeType = [];
    if (!equals(previousSearches.paxAmount, originalBookingSelection.paxAmount)) {
      selectionChangeType.push(SelectionType.PAX);
    }
    if (!equals(previousSearches.tripType, originalBookingSelection.tripType)) {
      selectionChangeType.push(SelectionType.TRIP_TYPE);
    }
    if (previousSearches.flights[0].origin.locationCode !== originalBookingSelection.flights[0].origin.locationCode) {
      selectionChangeType.push(SelectionType.ORIGIN);
    }
    if (
      previousSearches.flights[0].destination.locationCode !==
      originalBookingSelection.flights[0].destination.locationCode
    ) {
      selectionChangeType.push(SelectionType.DESTINATION);
    }
    if (
      !equals(
        previousSearches.flights.map(({ departureDate }) => departureDate),
        originalBookingSelection.flights.map(({ departureDate }) => departureDate)
      )
    ) {
      selectionChangeType.push(SelectionType.TRAVEL_DATES);
    }
    return selectionChangeType;
  }

  private updatePriceAndCompactStatus(): void {
    this._subscription.add(
      this.bookingWidgetFlightService.locations$
        .pipe(
          map(({ origin, destination }) => isNotBlank(origin?.locationCode) && isNotBlank(destination?.locationCode)),
          distinctUntilChanged()
        )
        .subscribe((hasBothLocations) => {
          if (!hasBothLocations) {
            BookingWidgetActions.resetPrices();
          }
          this._showCompact$.next(!hasBothLocations);
        })
    );
  }

  // following month prices need to be fetched only for round trips
  private fetchFollowingMonthPrice(): void {
    this._subscription.add(
      combineLatest([
        this._priceRequestBaseParams$,
        this._travelDates$.pipe(distinctUntilChanged((prev, next) => prev.departureDate === next.departureDate)),
      ])
        .pipe(
          filter(([{ oneway }, travelDates]) => travelDates?.departureDate && !oneway),
          switchMap(([baseParams, travelDates]) =>
            this.bookingWidgetCalendarService.getPricesForFollowingMonth({
              ...baseParams,
              departureDate: travelDates.departureDate?.id,
              numberOfDays: 30,
            })
          )
        )
        .subscribe((prices) => {
          this.store$.dispatch(BookingWidgetActions.setFollowingMonthPrices({ prices }));
        })
    );
  }

  private fetchPriceBasedOnSelection(): void {
    // We fetch calendar prices whenever user changes the selections
    this._subscription.add(
      this._priceRequestBaseParams$
        .pipe(
          switchMap((baseParams) =>
            this.bookingWidgetCalendarService.getPricesForFullYear({
              ...baseParams,
              startDate: LocalDate.now().id,
            })
          )
        )
        .subscribe(({ fullYear, histogram }) => {
          this.store$.dispatch(BookingWidgetActions.setFullYearPrices({ prices: fullYear }));
          this.store$.dispatch(BookingWidgetActions.setHistogramPrices({ prices: histogram }));
        })
    );
  }

  private sendRecommendationsFlightSearchEvent(params: FlightSearchParams): void {
    const { origin, destination } = params.flights[0];

    this._subscription.add(
      this.recommendationService
        .sendFlightSearchEvent(
          params.flights[0].departureDate.toISOString().substring(0, 10),
          params.flights[1]?.departureDate?.toISOString().substring(0, 10),
          origin.type === 'airport' ? origin.locationCode : undefined,
          destination.type === 'airport' ? destination.locationCode : undefined,
          origin.type === 'city' ? origin.locationCode : undefined,
          destination.type === 'city' ? destination.locationCode : undefined,
          // combining adults + c15s was in the initial requirement and logic was the same in the old widget
          params.paxAmount.adults + params.paxAmount.c15s,
          params.paxAmount.children + params.paxAmount.infants
        )
        .subscribe()
    );
  }

  private priceParamsAreValid({ locations, travelClass, tripType, paxAmount }: PriceParams): boolean {
    return (
      isPresent(travelClass) &&
      isPresent(tripType) &&
      isPresent(locations?.origin?.locationCode) &&
      isPresent(locations?.destination?.locationCode) &&
      paxAmount?.adults >= 1
    );
  }

  private priceBaseParamsChanged(prev: InstantSearchBaseParams, next: InstantSearchBaseParams): boolean {
    return (
      prev.departureLocationCode !== next.departureLocationCode ||
      prev.destinationLocationCode !== next.destinationLocationCode ||
      prev.travelClass !== next.travelClass ||
      prev.oneway !== next.oneway
    );
  }

  private mapToPriceRequestBaseParams({
    tripType,
    travelClass,
    locations,
    paxAmount,
  }: PriceParams): InstantSearchBaseParams {
    return {
      departureLocationCode: locations.origin.locationCode,
      destinationLocationCode: locations.destination.locationCode,
      travelClass: travelClass === GlobalBookingTravelClass.MIXED ? undefined : travelClass,
      adults: paxAmount.adults + paxAmount.c15s,
      children: paxAmount.children,
      infants: paxAmount.infants,
      numberOfDays: 360,
      oneway: tripType === TripType.ONEWAY,
      directFlights: false,
      locale: this.languageService.localeValue,
    };
  }
}
