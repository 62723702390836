import { Action, ActionReducer, INIT, UPDATE } from '@ngrx/store';

import { isPresent } from '@fcom/core/utils/utils';

import { Storage } from '../interfaces';
import { mergeDeep } from './merge';
import { SentryLogger } from '../services';

export interface StorageKey {
  name: string;
  keys?: string[];
}

export interface StorageConfig {
  storage: Storage;
  rehydrate: boolean;
  keys: StorageKey[];
  sentryLogger: SentryLogger;
}

export interface ExtendedAction extends Action {
  payload?: any;
  features?: string[];
}

export interface StateData {
  [key: string]: any;
}

const pickKeys = (obj: string | StateData, keys: string[] = []) => {
  if (typeof obj === 'string') {
    return '';
  }
  return keys.reduce((values: StateData, key: string) => {
    values[key] = obj?.[key];
    return values;
  }, {});
};

const syncStateToStorage = <State>(config: StorageConfig, state: State) => {
  config.keys.forEach((key: StorageKey) => {
    const partOfState: string | StateData = state[key.name];
    if (isPresent(partOfState)) {
      const newPartOfState: string | StateData = Array.isArray(key.keys)
        ? pickKeys(partOfState, key.keys)
        : partOfState;

      try {
        config.storage.set(
          key.name,
          typeof newPartOfState === 'string' ? newPartOfState : JSON.stringify(newPartOfState)
        );
      } catch (e) {
        config.sentryLogger.warn('Failed to sync state to storage', {
          storageKeyName: key.name,
          error: e,
        });
      }
    }
  });
};

const parseData = (jsonString: any) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return jsonString;
  }
};

const getStateFromStorage = (config: StorageConfig) => {
  if (config.rehydrate) {
    return config.keys.reduce((stateFromStorage, key: StorageKey) => {
      const storageData = config.storage.get(key.name);
      if (isPresent(storageData)) {
        return mergeDeep(stateFromStorage, { [key.name]: parseData(storageData) });
      }
      return stateFromStorage;
    }, {});
  }
  return {};
};

export const storageSync =
  (config: StorageConfig) =>
  <State>(reducer: ActionReducer<State, ExtendedAction>) => {
    return function (state: State, action: ExtendedAction) {
      const stateToUpdate: State =
        action.type === UPDATE || action.type === INIT ? mergeDeep(state, getStateFromStorage(config)) : state;
      const nextState: State = reducer(stateToUpdate, action);
      syncStateToStorage(config, nextState);
      return nextState;
    };
  };
