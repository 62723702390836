export enum GtmLocationEventType {
  INPUT = 'input',
  CLOSE = 'close',
  SELECTION = 'selection',
}

export interface GtmPassengers {
  adults: number;
  children: number;
  infants: number;
}
