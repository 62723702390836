/**
 * Parses user's text input to date
 * Format has to match the given pattern
 * Patterns can be combinations of yyyy MM dd using . / or - as separators
 * Numbers are automatically padded in case a leading zero is not provided
 * e.g. MM/dd/yyyy as pattern will work with 1/31/2020 as input returning 2020-31-01
 */
import { pad } from '@fcom/core/utils';

export class ParseUserInput {
  constructor(
    private date: string,
    private format: string
  ) {}

  getISOString(): string {
    let dateArray: string[];
    let formatArray: string[];
    if (this.format.includes('.')) {
      dateArray = this.date.split('.');
      formatArray = this.format.split('.');
    } else if (this.format.includes('/')) {
      dateArray = this.date.split('/');
      formatArray = this.format.split('/');
    } else if (this.format.includes('-')) {
      dateArray = this.date.split('-');
      formatArray = this.format.split('-');
    }
    const dateObj: any = formatArray.reduce((obj, key, index) => ({ ...obj, [key]: dateArray[index] }), {});

    if (
      parseInt(dateObj.yyyy, 10) < 2999 &&
      parseInt(dateObj.yyyy, 10) > 1900 &&
      parseInt(dateObj.MM, 10) < 13 &&
      parseInt(dateObj.MM, 10) > 0 &&
      parseInt(dateObj.dd, 10) < 32 &&
      parseInt(dateObj.dd, 10) > 0
    ) {
      return `${dateObj.yyyy as string}-${pad(dateObj.MM)}-${pad(dateObj.dd)}`;
    }
    return undefined;
  }
}
