import { Injectable } from '@angular/core';

import { IconLibrary } from '@finnairoyj/fcom-ui-styles/enums';

import { icons } from '@finnairoyj/fcom-ui-styles';

import { type IconName, type IconSet } from '../icons.interfaces';
import { IconsService } from './icons.service';

/**
 * Helper service for adding Icons from the ui-styles icons manifest to the icons service without inserting an fcom-icon into the dom
 */
@Injectable({
  providedIn: 'root',
})
export class IconsPreloadService {
  private icons = icons;

  constructor(private readonly iconsService: IconsService) {}

  /**
   * function for adding icons by name from the ui-styles icons manifest into the icon service
   *
   * @param iconsData
   */
  preloadIcons(iconsData: IconSet | IconSet[]): void {
    if (!iconsData) {
      return;
    }
    if (Array.isArray(iconsData)) {
      iconsData.forEach((iconData) => this.addIcon(iconData.name, iconData.category || IconLibrary.SVG_LIBRARY));
    } else {
      this.addIcon(iconsData.name, iconsData.category || IconLibrary.SVG_LIBRARY);
    }
  }

  private addIcon(name: IconName, category: IconLibrary): void {
    if (this.icons[category]?.[name]) {
      const iconRef = `${String(category)}/${String(name)}`;
      this.iconsService.addIcon(iconRef, this.icons[category][name]);
    }
  }
}
