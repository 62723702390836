<ul class="no-list-style font-body-1 medium-type">
  @for (item of items; track item) {
    <li *ngIf="item.withDivider" class="ps-xsmall-y divider"><hr /></li>
    <li>
      <a
        *ngIf="item.link"
        (beforeNavigation)="handleClick(item.id, $event)"
        class="flex no-underline nordic-blue-900-text ps-medium-x ps-small-y"
        [class.current-item]="item.active"
        [finLink]="item.link"
        [external]="item.external"
        [attr.target]="item.openInNewTab ? '_blank' : ''"
        [attr.data-testid]="item.attrs?.['data-testid']"
      >
        <ng-container [ngTemplateOutlet]="itemContent" [ngTemplateOutletContext]="{ item }"></ng-container>
      </a>
      <button
        *ngIf="!item.link"
        class="fill align-left ps-medium-x ps-small-y"
        [attr.data-testid]="item.attrs?.['data-testid']"
        (click)="handleClick(item.id, $event)"
      >
        <ng-container [ngTemplateOutlet]="itemContent" [ngTemplateOutletContext]="{ item }"></ng-container>
      </button>
    </li>
  }
</ul>

<ng-template #itemContent let-item="item">
  <fcom-icon
    *ngIf="item.icon?.position === IconPosition.LEFT"
    class="icon-medium ms-xsmall-r"
    [name]="item.icon.name"
    [category]="item.icon.category"
  ></fcom-icon>
  {{ item.teaserTitle }}
  <fcom-icon
    *ngIf="item.icon?.position === IconPosition.RIGHT"
    class="icon-medium ms-xsmall-l"
    [name]="item.icon.name"
    [category]="item.icon.category"
  ></fcom-icon>
  <fcom-icon
    *ngIf="!item.icon && item.external"
    class="icon-medium ms-xsmall-l"
    [name]="SvgLibraryIcon.EXTERNAL_TAB"
  ></fcom-icon>
</ng-template>
