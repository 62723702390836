import { Step } from '../interfaces';
import { BookingErrorSteps, BookingSteps } from '../interfaces/booking/booking-step.interface';

export const ERROR_STEPS: BookingErrorSteps = {
  ERROR_ROUTE: {
    phase: 0,
    id: 0,
    path: 'error/:error',
    translationKey: null,
  },
  ERROR: {
    phase: 0,
    id: 0,
    path: 'error',
    translationKey: null,
  },
  PAYMENT_ERROR: {
    phase: 0,
    id: 0,
    path: 'payment/error',
    translationKey: null,
  },
};

let phaseGenerator = 0;
let idGenerator = 0;
export const BOOKING_STEPS: BookingSteps = {
  ENTRY: {
    phase: ++phaseGenerator,
    id: ++idGenerator,
    path: '',
    translationKey: 'tripDetails',
  },
  FLIGHT_SELECTION: {
    phase: ++phaseGenerator,
    id: ++idGenerator,
    path: 'flight-selection',
    translationKey: 'flights',
  },
  PAXDETAILS: {
    phase: ++phaseGenerator,
    id: ++idGenerator,
    path: 'passenger-details',
    translationKey: 'passengers',
  },
  ANCILLARIES: {
    phase: ++phaseGenerator,
    id: ++idGenerator,
    path: 'ancillaries',
    translationKey: 'extras',
  },
  PURCHASE_REVIEW: {
    phase: ++phaseGenerator,
    id: ++idGenerator,
    path: 'purchase-review',
    translationKey: 'review',
  },
  CHECKOUT: {
    phase: phaseGenerator,
    id: ++idGenerator,
    path: 'checkout',
    translationKey: 'payment',
  },
  PURCHASE_SUCCESS: {
    phase: ++phaseGenerator,
    id: ++idGenerator,
    path: 'confirmation',
    translationKey: 'success',
  },
};

export const BOOKING_STEPS_ARRAY: Step[] = Object.keys(BOOKING_STEPS)
  .filter((key) => !BOOKING_STEPS[key].disabled)
  .map((key) => BOOKING_STEPS[key]);

export const FLIGHT_SELECTION_CACHE_EXPIRY = 1000 * 60 * 15; // 15 minutes

export const fareFamiliesWithoutCampaignFiltering = {
  ABCLASIC04: 'ABCCAMP01', // (BIZ, ES TO NA)
  ABCLASIC01: 'ABCCAMP01', // (BIZ, GB TO NA)
  AECLASIC01: 'AECCAMP01', // (ECO, ES/GB TO NA)
  APECLASI01: 'APECCAMP01', // (PE, ES/GB TO NA)
};
