import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { CmsCollection, CmsTaxonomyGeneral } from '@fcom/core-api';

@Component({
  selector: 'cms-list-with-heading',
  styleUrls: ['./cms-list-with-heading.component.scss'],
  templateUrl: './cms-list-with-heading.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsListWithHeadingComponent {
  readonly TAXONOMY = CmsTaxonomyGeneral;

  @Input()
  content$: Observable<CmsCollection>;
}
