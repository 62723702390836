import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { captureException, init, preInit } from '@fcom/app/client-init';
import { ClientModule } from '@fcom/app/app/client.module';

import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const initCb = init();

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(ClientModule, { preserveWhitespaces: false })
    .then((module: NgModuleRef<ClientModule>) => {
      preInit(module);
      initCb();
    })
    .catch((error: Error) => {
      console.error('Application bootstrap failed', error);
      captureException(error);
    });
});
