<div *ngIf="(ondOffers$ | async)?.length > 0" class="mr-large-y">
  <h2 class="mr-small-b font-heading-3 nordic-blue-900-text" *ngIf="heading$ | async">
    {{ heading$ | async }}
  </h2>

  <div class="ond-marketing-offers grid-col-12">
    <div
      *ngFor="let ondOffer of ondOffers$ | async | slice: 0 : offerAmount; let i = index"
      class="ond-marketing-offer-tile flex flex--column border rounded grey-50-bg pr-small animate-appear"
      [ngClass]="
        i + 1 > offerAmount - moreOffersAmount ? 'animate-appear--' + (i - offerAmount + moreOffersAmount) : ''
      "
    >
      <div class="flex-1">
        <div class="light-type font-body-1 ms-xxsmall-b">
          {{ ondOffer?.originLocationCode }} – {{ ondOffer?.destinationLocationCode }}
        </div>

        <div class="font-heading-3 nordic-blue-900-text bold-type ms-xxsmall-b">
          {{ ondOffer.originCityName }} -
          {{ ondOffer.destinationCityName }}
        </div>

        <div class="light-type mr-small-b font-body-2">
          {{ ondOffer?.departureDate | finDateFormat: 'FULL' }} – {{ ondOffer?.returnDate | finDateFormat: 'FULL' }}
        </div>

        <div class="mr-xsmall-b align-right">
          <div class="nordic-blue-900-text light-type font-body-1 lowercase">
            {{ 'pricesStartingFrom' | finLocalization }}
          </div>

          <span class="heather-700-text font-heading-2 bold-type">
            {{ ondOffer?.price | finPrice: { roundPrice: true } }}
          </span>
        </div>
      </div>

      <button
        *ngIf="ondOffer.link"
        (click)="navigateToUrl(ondOffer?.link)"
        class="ond-marketing-offer-tile__link bold-type align-left font-body-1 nordic-blue-900-text no-underline underline-hover"
      >
        {{ 'bookNow' | finLocalization }}
        <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.ARROW_RIGHT"></fcom-icon>
      </button>
    </div>
  </div>
</div>

<div *ngIf="(ondOffers$ | async)?.length > offerAmount" class="align-center">
  <button class="btn bold-type" (click)="loadMoreOffers()">{{ 'ondMarketing.loadMore' | finLocalization }}</button>
</div>
