import { isPresent } from './utils';
import { mergeDeep } from './merge';

/**
 * Converts 'foo.bar' to ['foo', 'bar'].
 * Keeps arrays intact.
 * throws error if not string or array
 */
export function pathToArray(path: string | string[]): string[] {
  if (Array.isArray(path)) {
    return path;
  } else if (typeof path === 'string') {
    return path.split('.');
  } else {
    throw new Error('Only Array<string> and dot-separated string supported as path');
  }
}

function getInArray<T, U extends keyof T>(object: T, paths: [U]): T[U];
function getInArray<T, U extends keyof T, V extends keyof T[U]>(object: T, paths: [U, V]): T[U][V];
function getInArray<T, U extends keyof T, V extends keyof T[U], W extends keyof T[U][V]>(
  object: T,
  paths: [U, V, W]
): T[U][V][W];
function getInArray<T, U extends keyof T, V extends keyof T[U], W extends keyof T[U][V], X extends keyof T[U][V][W]>(
  object: T,
  paths: [U, V, W, X]
): T[U][V][W][X];
function getInArray(object: unknown, paths: string[]): unknown {
  let retValue = object;
  for (const path of paths) {
    retValue = retValue?.[path];
    if (!retValue) {
      break;
    }
  }

  return retValue;
}

/**
 * Null-safe deep get for objects.
 *
 * @example
 * const a = {
 *   b: {
 *     c: {
 *       d: 'heps'
 *     }
 *   }
 * }
 * getIn(a, 'b.c.d') => 'heps'
 * getIn(a, 'b.c.d.e') => undefined
 * getIn(a, 'b') => { b: { ... } }
 *
 */
export function getIn<T, U extends keyof T>(o: T, path: U | [U]): T[U];
export function getIn<T, U extends keyof T, V extends keyof T[U]>(o: T, path: [U, V]): T[U][V];
export function getIn<T, U extends keyof T, V extends keyof T[U], W extends keyof T[U][V]>(
  o: T,
  path: [U, V, W]
): T[U][V][W];
export function getIn<T, U extends keyof T, V extends keyof T[U], W extends keyof T[U][V], X extends keyof T[U][V][W]>(
  o: T,
  path: [U, V, W, X]
): T[U][V][W][X];
export function getIn<T>(o: T, path: string | string[]): unknown;
export function getIn(o: unknown, path: string | string[]): unknown {
  return getInArray(o, pathToArray(path) as any);
}

/**
 * Creates a recursive object tree with the given keys and the value at the leaf
 * @param keys
 * @param value
 * @param index
 * @returns {any}
 *
 * Example:
 *
 * createObject(['foo','bar','baz'], 'qux') => { foo: { bar: { baz: 'qux' } } }
 */
const createObject = (keys: string[], value: object | undefined, index = keys.length - 1): object | undefined => {
  if (index < 0) {
    return value;
  }
  return createObject(keys, { [keys[index]]: value }, index - 1);
};

const setInArray = function setInArrayFn<T>(object: T, paths: string[], value: any): T {
  if (getIn(object, paths) === value) {
    return object;
  }
  /* To ensure object values are overwritten, we first clear the value */
  const clearValueFirst = createObject(paths, undefined);
  const toAssign = createObject(paths, value);
  return mergeDeep<T>(mergeDeep<T>(object, clearValueFirst, true), toAssign, true);
};

/**
 * Sets value in target key.
 * Returns a new reference of each ancestor if value changes, but keeps the other branches intact.
 */
export const setIn = function setInFn<T>(o: T, path: string | string[], value: any): T {
  return setInArray(o, pathToArray(path), value);
};

const mergeInArray = function mergeInArrayFn<T>(object: T, paths: string[], value: any): T {
  if (getIn(object, paths) === value) {
    return object;
  }
  const toAssign = createObject(paths, value);
  return mergeDeep(object, toAssign, true);
};

export const mergeIn = <T>(o: T, path: string | string[], value: any): T => mergeInArray(o, pathToArray(path), value);

export const updateIn = <T>(o: T, path: string | string[], transform: (val: any) => any) => {
  const paths = pathToArray(path);
  const previousValue = getIn(o, paths);
  return setIn(o, paths, transform(Object.seal(previousValue)));
};

export const deleteIn = <T>(o: T, path: string | string[]): T => {
  const pathsInArray = pathToArray(path);
  if (pathsInArray.length === 0) {
    throw new Error('Cannot delete empty path');
  }
  const pathsToParent = pathsInArray.slice(0, -1);
  const keyToDelete = pathsInArray[pathsInArray.length - 1];
  const parent = pathsInArray.length === 1 ? o : getIn(o, pathsToParent);

  if (!isPresent(parent)) {
    return o;
  }

  if (Array.isArray(parent)) {
    const parentArray = parent;
    const index = parseInt(keyToDelete, 10);
    // eslint-disable-next-line id-blacklist
    if (Number.isNaN(index)) {
      throw new Error('Trying to delete non-numeric key from array');
    }
    if (index >= parentArray.length) {
      return o;
    } else {
      const temp = parentArray.slice(0);
      temp.splice(index, 1);
      if (pathsToParent.length === 0) {
        return temp as any as T;
      }
      return setIn(o, pathsToParent, temp);
    }
  }

  // eslint-disable-next-line no-prototype-builtins
  if (!parent.hasOwnProperty(keyToDelete)) {
    return o;
  }

  const newValue = setIn(o, path, undefined);
  const newParent = pathsToParent.length === 0 ? newValue : getIn(newValue, pathsToParent);
  delete newParent[keyToDelete];
  return newValue;
};

export class DataUtils<T> {
  /**
   * Helper method for chaining, e.g., setIn() calls
   * @param value
   * @returns {DataUtils<Y>}
   */
  static wrap<Y>(value: Y): DataUtils<Y> {
    return new DataUtils<Y>(value);
  }

  constructor(private data: T) {}

  setIn<U extends keyof T>(path: U | [U], value: T[U]): this;
  setIn<U extends keyof T, V extends keyof T[U]>(path: [U, V], value: T[U][V]): this;
  setIn<U extends keyof T, V extends keyof T[U], W extends keyof T[U][V]>(path: [U, V, W], value: T[U][V][W]): this;
  setIn<U extends keyof T, V extends keyof T[U], W extends keyof T[U][V], X extends keyof T[U][V][W]>(
    path: [U, V, W, X],
    value: T[U][V][W][X]
  ): this;
  setIn(path: string | string[], value: unknown): this {
    this.data = setIn(this.data, path, value);
    return this;
  }

  mergeIn<U extends keyof T>(path: U | [U], value: T[U]): this;
  mergeIn<U extends keyof T, V extends keyof T[U]>(path: [U, V], value: T[U][V]): this;
  mergeIn<U extends keyof T, V extends keyof T[U], W extends keyof T[U][V]>(path: [U, V, W], value: T[U][V][W]): this;
  mergeIn<U extends keyof T, V extends keyof T[U], W extends keyof T[U][V], X extends keyof T[U][V][W]>(
    path: [U, V, W, X],
    value: T[U][V][W][X]
  ): this;
  mergeIn(path: string | string[], value: unknown): this {
    this.data = mergeIn(this.data, path, value);
    return this;
  }

  deleteIn<U extends keyof T>(path: U | [U]): DataUtils<Omit<T, U>>;
  deleteIn<U extends keyof T, V extends keyof T[U]>(path: [U, V]): this;
  deleteIn<U extends keyof T, V extends keyof T[U], W extends keyof T[U][V]>(path: [U, V, W]): this;
  deleteIn<U extends keyof T, V extends keyof T[U], W extends keyof T[U][V], X extends keyof T[U][V][W]>(
    path: [U, V, W, X]
  ): this;
  deleteIn(path: string[]): DataUtils<T>;
  deleteIn(path: string | string[]): this {
    this.data = deleteIn(this.data, path);
    return this;
  }

  updateIn<U extends keyof T>(path: U | [U], mutator: (val: T[U]) => T[U]): this;
  updateIn<U extends keyof T, V extends keyof T[U]>(path: [U, V], mutator: (val: T[U][V]) => T[U][V]): this;
  updateIn<U extends keyof T, V extends keyof T[U], W extends keyof T[U][V]>(
    path: [U, V, W],
    mutator: (val: T[U][V][W]) => T[U][V][W]
  ): this;
  updateIn<U extends keyof T, V extends keyof T[U], W extends keyof T[U][V], X extends keyof T[U][V][W]>(
    path: [U, V, W, X],
    mutator: (val: T[U][V][W][X]) => T[U][V][W][X]
  ): this;
  updateIn(path: string | string[], mutator: (val: any) => any): this {
    this.data = updateIn(this.data, path, mutator);
    return this;
  }

  value = () => this.data;
}
