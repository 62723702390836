<div class="booking-summary grow flex flex--column">
  <div
    class="nordic-blue-900-bg flex flex--nogrow flex--end ps-xxsmall-y booking-summary-close cf font-body-1 pr-medium-x white-text fill dn-tablet-up"
  >
    <div class="flex-1 flex flex--middle">
      <fcom-icon
        class="flex--nogrow ms-xsmall-r icon-medium"
        [name]="onlyShowAncillaries ? SvgLibraryIcon.SHOPPING_CART : SvgLibraryIcon.INFO_OUTLINE"
      ></fcom-icon>
      <div class="font-body-1 ps-medium-y">
        <span *ngIf="!onlyShowAncillaries">{{ 'services.sidebar.title' | finLocalization }}</span>
        <span *ngIf="onlyShowAncillaries && (currentStep$ | async)?.phase !== STEPS.ANCILLARIES.phase">{{
          'cart.title' | finLocalization
        }}</span>
      </div>
    </div>
    <fcom-icon-button
      class="flex flex--middle"
      [icon]="{
        name: SvgLibraryIcon.CLOSE_DELETE,
        category: IconLibrary.SVG_LIBRARY,
      }"
      [aria]="{
        label: 'actions.close' | finLocalization,
      }"
      [theme]="IconButtonTheme.DARK_TERTIARY_GHOST"
      (clickBtn)="close.emit()"
    ></fcom-icon-button>
  </div>
  <div class="mobile-scroll-wrapper">
    <div class="sections flex-1 fill" *ngIf="!onlyShowAncillaries">
      <fin-booking-summary-section
        (edit)="edit.emit(STEPS.ENTRY)"
        [editText]="'services.sidebar.modifySearch' | finLocalization"
        [skipEditAlign]="true"
        [title]="'bookingSummary.tripDetails'"
        [trackingContext]="flowContext"
        [isContent]="false"
        [isChangeButton]="true"
      >
      </fin-booking-summary-section>
      <fin-booking-summary-section
        (edit)="edit.emit(STEPS.FLIGHT_SELECTION)"
        [innerIconClass]="'dark-gray-color'"
        [innerClass]="'pr-small-t'"
        [isChangeButton]="true"
        [trackingContext]="flowContext"
        [isDisabled]="(currentStep$ | async)?.phase === STEPS.FLIGHT_SELECTION.phase"
      >
        <fin-booking-summary-flight
          *ngFor="let bound of currentOffer$ | async; let i = index; let first = first"
          class="block ps-large-b"
          [ngClass]="{
            'ps-large-t border-top': !first,
          }"
          [title]="
            'bookingSummary.boundTitle'
              | finLocalization
                : {
                    index: i + 1,
                    departureCity: bound.departure.cityName,
                    arrivalCity: bound.arrival.cityName,
                  }
          "
          [bound$]="bound | finToObservable"
        ></fin-booking-summary-flight>
      </fin-booking-summary-section>
    </div>

    <div
      class="sections flex-1 fill"
      *ngIf="
        !onlyShowAncillaries &&
        (travelers$ | async)?.length &&
        (currentStep$ | async).phase > STEPS.FLIGHT_SELECTION.phase
      "
    >
      <fin-booking-summary-section
        [icon]="SvgLibraryIcon.SINGLE_PAX"
        [title]="'bookingSummary.passengers'"
        (edit)="edit.emit(STEPS.PAXDETAILS)"
        [trackingContext]="flowContext"
        [isDisabled]="(currentStep$ | async)?.phase === STEPS.PAXDETAILS.phase"
      >
        <fin-booking-summary-traveler
          *ngFor="let traveler of travelers$ | finObsToArray"
          [traveler$]="traveler"
        ></fin-booking-summary-traveler>
      </fin-booking-summary-section>
    </div>

    <div class="sections flex-1 fill ancillary-summary" *ngIf="hasUnpaidServices$ | async">
      <fin-booking-summary-section
        [icon]="SvgLibraryIcon.FLIGHT_OFFER"
        [title]="'bookingSummary.ancillaries'"
        [isChangeButton]="false"
        [isContent]="false"
        [trackingContext]="flowContext"
      >
      </fin-booking-summary-section>

      <ng-container *ngFor="let service$ of unpaidServices$ | finObsToArray; index as i; last as isLast">
        <fin-booking-summary-section
          class="ancillary"
          [icon]="(service$ | async)?.icon"
          [isChangeButton]="isLast && !hideChangeButton"
          (edit)="edit.emit(STEPS.ANCILLARIES)"
          [innerClass]="'ancillary-' + (service$ | async)?.code"
          [innerIconClass]="'dark-gray-color'"
          [trackingContext]="flowContext"
          [isDisabled]="onlyShowAncillaries || (currentStep$ | async)?.phase === STEPS.ANCILLARIES.phase"
        >
          <fin-booking-summary-ancillary
            [title]="'ancillaries.' + (service$ | async)?.code + '.extraTitle'"
            [service$]="service$"
          ></fin-booking-summary-ancillary>
        </fin-booking-summary-section>
      </ng-container>
    </div>
  </div>
</div>
