import { Component, Input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';

@Component({
  selector: 'fin-icon-with-text',
  styleUrls: ['./icon-with-text.component.scss'],
  templateUrl: './icon-with-text.component.html',
})
export class IconWithTextComponent {
  @Input()
  title$: Observable<string>;
  @Input()
  content$: Observable<string>;
  @Input()
  icons$: Observable<SvgLibraryIcon[]>;
  @Input()
  styleClasses$: Observable<string[]>;
}
