import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconsModule } from '../icons/icons.module';
import { PopoverComponent } from './component/popover.component';
import { PopoverDirective } from './directive/popover.directive';
import { FocusOutDirectiveModule } from '../../directives/focus-out/focus-out.directive.module';
import { TrapFocusModule } from '../../directives/trap-focus';

@NgModule({
  declarations: [PopoverComponent, PopoverDirective],
  imports: [CommonModule, IconsModule, FocusOutDirectiveModule, TrapFocusModule],
  exports: [PopoverComponent, PopoverDirective],
})
export class PopoverModule {}
