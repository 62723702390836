import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import {
  FinnairBoundItem,
  FinnairDisruptedBoundItem,
  FinnairItineraryItemFlight,
  FinnairLocation,
  FinnairPassengerCode,
  FinnairPassengerItem,
} from '@fcom/dapi/api/models';

@Component({
  selector: 'fin-flight-card',
  templateUrl: './flight-card.component.html',
  styleUrls: ['./flight-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlightCardComponent implements OnChanges {
  readonly IconLibrary = IconLibrary;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  flightOrBound: FinnairBoundItem | FinnairDisruptedBoundItem | FinnairItineraryItemFlight;
  @Input()
  title: string;
  @Input()
  dim = false;
  @Input()
  locations?: { [key: string]: FinnairLocation };
  @Input()
  passengers: FinnairPassengerItem[];
  @Input()
  hideModify = false;
  @Input()
  hideViewDetails = false;
  @Input()
  expanderOpen: boolean;
  @Input()
  roundedBottom = true;

  cardExpanderOpen: boolean;
  paxIcon: SvgLibraryIcon;

  @Output()
  modifyClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  viewDetails: EventEmitter<void> = new EventEmitter<void>();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['passengers']) {
      this.paxIcon = this.getPaxNumberIcon();
    }

    if (changes['expanderOpen']) {
      this.cardExpanderOpen = changes['expanderOpen'].currentValue;
    }
  }

  toggleOpenCard(isOpen: boolean): void {
    this.cardExpanderOpen = !isOpen;
  }

  private getPaxNumberIcon(): SvgLibraryIcon {
    if (this.passengers.length === 1) {
      return SvgLibraryIcon.SINGLE_PAX;
    }

    return this.passengers.some(({ passengerTypeCode }) => passengerTypeCode === FinnairPassengerCode.INF)
      ? SvgLibraryIcon.ADULT_INFANT
      : SvgLibraryIcon.MULTI_PAX;
  }
}
