import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable, of } from 'rxjs';

import { OndMarketingOffer, OndPairEnhancedResponse } from '../interfaces';
import { OndMarketingOffersService } from '../services';

const INITIAL_OFFER_AMOUNT = 6;
const OFFERS_TO_LOAD_AMOUNT = 3;

@Component({
  selector: 'fin-ond-marketing-offers',
  templateUrl: './ond-marketing-offers.component.html',
  styleUrls: ['./ond-marketing-offers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OndMarketingOffersComponent implements OnInit {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  originLocationCode$: Observable<string | undefined> = of(undefined);
  @Input()
  destinationLocationCode$: Observable<string | undefined> = of(undefined);
  @Input()
  links$: Observable<OndPairEnhancedResponse>;

  moreOffersAmount: number = OFFERS_TO_LOAD_AMOUNT;
  offerAmount: number = INITIAL_OFFER_AMOUNT;

  ondOffers$: Observable<OndMarketingOffer[]>;
  heading$: Observable<string>;

  constructor(
    private ondMarketingOffersService: OndMarketingOffersService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.ondOffers$ = this.ondMarketingOffersService.getOffersOrFlightData(
      this.originLocationCode$,
      this.destinationLocationCode$,
      this.links$
    );
    this.heading$ = this.ondMarketingOffersService.getHeading(
      this.originLocationCode$,
      this.destinationLocationCode$,
      this.ondOffers$,
      'mostPopularDeals'
    );
  }

  loadMoreOffers(): void {
    this.offerAmount += this.moreOffersAmount;
  }

  navigateToUrl(url: string): void {
    if (url) {
      this.router.navigateByUrl(url);
    }
  }
}
