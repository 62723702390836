<div class="flight-card mr-small">
  <ng-content select="fcom-notification, [notifications]"></ng-content>
  <div class="mr-xsmall-t" [class.disabled]="disabled">
    <!-- LabelDate and TicketType -->

    <div class="flex flex--space-between flex--wrap pr-xsmall-b labels font-body-2">
      <div>
        <span class="label" *ngIf="date"> {{ type }} {{ date }} </span>
        <span *ngIf="disruptedLabel" class="label label--error">{{ disruptedLabel | finLocalization }}</span>
      </div>
      <fcom-tag *ngIf="cabin || fareBrand"
        >{{ cabin ? ('travelClass.' + cabin.toLowerCase() | finLocalization) : '' }} {{ fareBrand }}</fcom-tag
      >
    </div>

    <ng-content select="fcom-ufo"></ng-content>
  </div>

  <div *ngIf="showDivider" class="border-top-dotted mr-small-t mr-xsmall-b"></div>

  <div class="mr-xsmall-t buttons flex flex--end flex--column hide-empty">
    <ng-content select="fcom-button"></ng-content>
  </div>
</div>
