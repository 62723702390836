import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

@Component({
  selector: 'fin-form-error',
  styleUrls: ['./form-error.component.scss'],
  templateUrl: './form-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormErrorComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  errorText: string;
}
