<div [formGroup]="parentForm" [class.ms-xlarge-b]="displayMargin">
  <div class="relative ps-xxsmall-b">
    <label class="font-body-1 grey-600-text" [for]="idOrControlName">
      {{ translationPrefix + '.label' | finLocalization }}
      <span *ngIf="isRequired"> *</span>
    </label>
    <select
      class="has-initial-value font-body-1"
      [formControlName]="controlName"
      [id]="idOrControlName"
      [name]="controlName"
      [attr.aria-required]="isRequired"
      #selectElement
    >
      <option value selected disabled>{{ selectLabelTranslationKey | finLocalization }}</option>
      <option *ngFor="let option of options" [value]="option.value" [disabled]="option.disabled" class="font-body-1">
        {{ option.name ? option.name : (translationPrefix + '.' + option.value | finLocalization) }}
      </option>
    </select>
    <span class="divider"></span>
  </div>
  <div *ngIf="ctrlField.invalid && ctrlField.touched" class="error-label font-body-2" aria-live="polite">
    <span *ngIf="ctrlField.errors?.required" class="error-900-text">
      {{ translationPrefix + '.required' | finLocalization }}
    </span>
  </div>
</div>
