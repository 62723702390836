<fieldset>
  <div *ngIf="heading" class="flex ms-medium-b">
    <legend class="group-heading bold-type font-body-1 flex-1 flex--center" [attr.id]="'check-group-' + name">
      {{ heading | finLocalization }}
    </legend>
    <div *ngIf="headingInfo" class="heading-info flex-1 align-right font-body-1">
      <button class="nordic-blue-900-text underline overflow-hidden" (click)="headingInfoClick.emit($event)">
        {{ headingInfo }}
        <fcom-icon [name]="SvgLibraryIcon.HELP_BLOCK" class="icon-small nordic-blue-900-text ms-xxsmall-l"></fcom-icon>
      </button>
    </div>
  </div>

  <span class="is-vishidden" *ngIf="hiddenText">{{ hiddenText | finLocalization }}</span>

  <div class="flex flex--column group-options">
    <ng-content></ng-content>
  </div>
</fieldset>
