import { createAction, props, union } from '@ngrx/store';

import { FareRuleCategory, FinnairCart } from '@fcom/dapi/api/models';
import { PartialCartFareRuleRecord } from '@fcom/dapi/interfaces/cart.interface';

export const creationStart = createAction('[CartActions] CreationStart');
export const creationSuccess = createAction('[CartActions] CreationSuccess', props<{ cartUrl: string }>());
export const creationError = createAction('[CartActions] CreationError');
export const loadStart = createAction('[CartActions] LoadStart');
export const loadError = createAction('[CartActions] LoadError');
export const updateStart = createAction('[CartActions] UpdateStart');
export const updateError = createAction('[CartActions] UpdateError');
/**
 * The update was unsuccessful, but does not affect the whole cart
 * @type {string}
 */
export const updateNotCompleted = createAction('[CartActions] UpdateNotCompleted');
export const reset = createAction('[CartActions] Reset');
export const setCartData = createAction('[CartActions] SetCartData', props<{ cartData: FinnairCart }>());
export const isUpdatingPartialCart = createAction(
  '[CartActions] isUpdatingPartialCart',
  props<{ cartPartIds: string | string[] }>()
);
export const errorUpdatingPartialCart = createAction(
  '[CartActions] ErrorUpdatingPartialCart',
  props<{ cartPartIds: string | string[] }>()
);
export const clearUpdatingPartialCart = createAction(
  '[CartActions] clearUpdatingPartialCart',
  props<{ cartPartIds: string | string[] }>()
);
export const acceptTerms = createAction('[CartActions] AcceptTerms');

export const fareRulesLoadStart = createAction(
  '[CartActions] FareRuleLoadStart',
  props<{ travelerId: string; category: FareRuleCategory }>()
);
export const fareRulesLoadEnd = createAction(
  '[CartActions] FareRuleLoadEnd',
  props<{ travelerId: string; category: FareRuleCategory }>()
);
export const fareRulesError = createAction(
  '[CartActions] FareRuleError',
  props<{ travelerId: string; category: FareRuleCategory }>()
);
export const fareRulesCreateCategories = createAction(
  '[CartActions] FareRuleCreateCategory',
  props<{ travelerId: string; rules: PartialCartFareRuleRecord }>()
);
export const fareRulesClear = createAction('[CartActions] FareRulesClear');

export const all = union({
  creationStart,
  creationSuccess,
  creationError,
  loadStart,
  loadError,
  updateStart,
  updateError,
  updateNotCompleted,
  reset,
  setCartData,
  isUpdatingPartialCart,
  errorUpdatingPartialCart,
  clearUpdatingPartialCart,
  acceptTerms,
  fareRulesLoadStart,
  fareRulesLoadEnd,
  fareRulesError,
  fareRulesCreateCategories,
  fareRulesClear,
});

export type ActionsUnion = typeof all;
