import { createAction, props, union } from '@ngrx/store';

import { Step } from '@fcom/common';
import { GlobalBookingTravelClass } from '@fcom/core';
import { TravelType } from '@fcom/common/interfaces/booking';

export const reset = createAction('[BookingActions] Reset');

export const setStep = createAction('[BookingActions] SetStep', props<{ step: Step }>());

// @todo Check do we need this because this fields was moved to the globalBooking?
export const selectTravelClass = createAction(
  '[BookingActions] SelectTravelClass',
  props<{ travelClass: GlobalBookingTravelClass }>()
);
export const setTravelType = createAction('[BookingActions] SetTravelType', props<{ travelType: TravelType }>());

const all = union({
  reset,

  setStep,
  selectTravelClass,
  setTravelType,
});

export type ActionsUnion = typeof all;
