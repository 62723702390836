import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { NotificationLayout, NotificationTheme } from '@fcom/ui-components';
import { CmsCollection, CmsTeaser, CmsViewType } from '@fcom/core-api';
import { getViewedNotifications, NotificationActions } from '@fcom/common/store';
import { AppState } from '@fcom/core';
import { TestVariant } from '@fcom/common';

interface DisruptionMessage {
  id: number;
  level: NotificationTheme;
  text: string;
  linkText: string;
  linkUrl: string;
  hidden: boolean;
  linkInNewTab: boolean;
}

/**
 * There is 4 levels of disruptions for Finnair 1-4, we only show level 2 and up on the homepage.
 */
const disruptionLevelMap = {
  [CmsViewType.DISRUPTION_LEVEL_1]: NotificationTheme.SUCCESS,
  [CmsViewType.DISRUPTION_LEVEL_2]: NotificationTheme.INFO,
  [CmsViewType.DISRUPTION_LEVEL_3]: NotificationTheme.WARNING,
  [CmsViewType.DISRUPTION_LEVEL_4]: NotificationTheme.ALERT,
};

@Component({
  selector: 'cms-disruptions',
  templateUrl: './cms-disruptions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsDisruptionsComponent implements OnInit, OnDestroy {
  @Input()
  disruptions: CmsCollection;

  @Input()
  showLevelOne = false;

  @Input()
  testVariant: TestVariant = TestVariant.A;

  @Input()
  isGlobalBanner = false;

  viewedNotifications: number[] = [];

  readonly NotificationLayout = NotificationLayout;

  private destroyed$ = new Subject();

  constructor(
    private cdr: ChangeDetectorRef,
    private store$: Store<AppState>
  ) {}

  getMappedDisruptions(): DisruptionMessage[] {
    if (!this.disruptions || !this.disruptions.items) {
      return [];
    }

    return this.disruptions.items
      .map((item: CmsTeaser): DisruptionMessage => {
        if (!disruptionLevelMap[item.viewTypeName]) {
          return null;
        }

        return {
          id: item.contentId,
          level: disruptionLevelMap[item.viewTypeName],
          text: item.teaserTitle,
          linkText: item.linkText ?? item.target?.teaserTitle ?? '',
          linkUrl: item.target?.url,
          hidden: this.viewedNotifications.includes(item.contentId),
          linkInNewTab: item.target?.openInNewTab,
        };
      })
      .filter(Boolean);
  }

  onClose(item: DisruptionMessage): void {
    this.store$.dispatch(NotificationActions.setViewedNotification({ id: item.id }));
  }

  /**
   * For links that want to open in a new tab override the default behavior and manually open in
   * new tab/window.
   */
  onLinkClick(event: Event, item: DisruptionMessage): void {
    if (item.linkInNewTab) {
      event.preventDefault();
      window.open(item.linkUrl, '_blank');
    }
  }

  /**
   * Used by angular to track items in the *ngFor loop and reduce DOM manipulations
   */
  disruptionItemId(_i: number, item: DisruptionMessage): number {
    return item.id;
  }

  ngOnInit(): void {
    this.store$
      .pipe(select(getViewedNotifications))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((notificationIds) => {
        this.viewedNotifications = notificationIds;
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  showGlobalBannerClose(item: DisruptionMessage): boolean {
    return item.level === NotificationTheme.SUCCESS || item.level === NotificationTheme.INFO;
  }
}
