import { Inject, Injectable, InjectionToken } from '@angular/core';

import { convertStringDatesToDateObjects } from '@fcom/core/utils';

import { Storage } from '../../interfaces';
import { WindowRef } from '../../providers/window';
import { DEFAULT_STORAGE_ROOT } from './constants';
import { environment } from '../../../../environments/environment';

export const SESSION_STORAGE_VERSION = new InjectionToken<string>('SessionStorageVersion');
export const LOCAL_STORAGE_VERSION = new InjectionToken<string>('LocalStorageVersion');

export type StorageName = 'localStorage' | 'sessionStorage';
export type StorageType = 'SESSION' | 'LOCAL';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public static readonly STORAGE_VERSION_KEY = '__STORAGE_VERSION__';

  SESSION: Storage;
  LOCAL: Storage;

  private root: string;

  constructor(
    private windowRef: WindowRef,
    @Inject(SESSION_STORAGE_VERSION) sessionStorageVersion: string,
    @Inject(LOCAL_STORAGE_VERSION) localStorageVersion: string
  ) {
    if (environment.storageRoot) {
      this.root = environment.storageRoot;
    } else {
      this.root = this.windowRef.nativeWindow['storageRoot'] || DEFAULT_STORAGE_ROOT;
    }
    this.SESSION = this.store('sessionStorage', sessionStorageVersion);
    this.LOCAL = this.store('localStorage', localStorageVersion);
  }

  private storageAvailable(type: StorageName): boolean {
    try {
      const storage = window[type];
      const x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return false;
    }
  }

  private store(storageName: StorageName, storageVersion: string): Storage {
    const storageAvailable = this.storageAvailable(storageName);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    const storage: Storage = {
      set(key: string, object: any): void {
        if (storageAvailable) {
          self.setItem(storageName, key, object);
        }
      },
      get(key: string, convertDates = false): any {
        if (storageAvailable) {
          let value = self.getItem(storageName, key);
          if (value && convertDates) {
            value = convertStringDatesToDateObjects(value);
          }
          return value;
        }
        return undefined;
      },
      remove(key: string): void {
        if (storageAvailable) {
          self.removeItem(storageName, key);
        }
      },
      keys(): string[] {
        if (storageAvailable) {
          return self.keys(storageName);
        }
        return [];
      },
      clear(): void {
        this.keys().forEach(this.remove);
      },
    };

    if (storageAvailable && storageVersion && storage.get(StorageService.STORAGE_VERSION_KEY) !== storageVersion) {
      // TODO: Migrate if needed
      storage.clear();
      storage.set(StorageService.STORAGE_VERSION_KEY, storageVersion);
    }

    return storage;
  }

  private setItem(storageName: string, key: string, object: any): void {
    window[storageName].setItem(`${this.root}.${key}`, object);
  }

  private getItem(storageName: string, key: string): any {
    return window[storageName].getItem(`${this.root}.${key}`);
  }

  private removeItem(storageName: string, key: string): any {
    window[storageName].removeItem(`${this.root}.${key}`);
  }

  private keys(storageName: string): string[] {
    return Object.keys(window[storageName])
      .map((key) => key.substring(this.root.length + 1))
      .filter(Boolean);
  }
}
