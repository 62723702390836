import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';

import { BehaviorSubject, of, ReplaySubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ConfigService, NativeBridgeService, PostMessenger, WindowRef } from '@fcom/core';
import { LoginPostMessage } from '@fcom/core-api/login';
import { unsubscribe } from '@fcom/core/utils';
import { safeMap } from '@fcom/rx';

import { LoginService } from '../services';

@Component({
  selector: 'fin-login-iframe',
  templateUrl: './login-iframe.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginIframeComponent implements OnInit, OnDestroy {
  renderIframe$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  iframeSrc$: ReplaySubject<string> = new ReplaySubject(1);
  supportedEventTypes = ['loginSuccess', 'mobileAppLoginSuccess', 'mobileAppGuestLogin'];

  private messenger: PostMessenger<LoginPostMessage>;
  private subscriptions: Subscription = new Subscription();

  constructor(
    private loginService: LoginService,
    private windowRef: WindowRef,
    private nativeBridgeService: NativeBridgeService,
    private configService: ConfigService,
    @Inject(PLATFORM_ID) private platform: object
  ) {
    const originSelf = this.windowRef.nativeWindow.location.origin;
    this.messenger = new PostMessenger(this.windowRef, of([this.configService.cfg.apiBaseUrl, originSelf]), platform);
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platform)) {
      this.renderIframe$.next(false);
      this.renderIframe$.complete();
      return;
    }
    this.listenToLoginPostMessages();
    if (!this.nativeBridgeService.isInsideNativeWebView || this.nativeBridgeService.isLocalView) {
      this.renderIframeOnLoginTrigger();
      this.loginService.initSsoLoginSession();
    }
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  private listenToLoginPostMessages() {
    this.subscriptions.add(
      this.messenger.inbound$
        .pipe(
          filter((msg) => {
            // Filter out Devtool postMessages and the Augury chrome extension
            return msg && this.supportedEventTypes.includes(msg.eventType);
          })
        )
        .subscribe((msg: LoginPostMessage) => {
          this.loginService.handleLoginEvent(msg);
          this.renderIframe$.next(false);
        })
    );
    this.nativeBridgeService.clientReady();
  }

  private renderIframeOnLoginTrigger() {
    this.subscriptions.add(
      this.loginService.loginTrigger$
        .pipe(
          safeMap(
            (nonce) =>
              `${this.configService.cfg.apiBaseUrl}${
                this.configService.cfg.uxAuthBasePath
              }/init?nonce=${encodeURIComponent(nonce)}&redirectPath=${encodeURIComponent(
                this.windowRef.nativeWindow.location.href
              )}`
          )
        )
        .subscribe((iframeSrc) => {
          this.iframeSrc$.next(iframeSrc);
          this.renderIframe$.next(true);
        })
    );
  }
}
