import { getBookingStepLink } from '@fcom/common/utils/booking-common.utils';
import { isBlank, MILLIS_IN_HOUR, MILLIS_IN_MINUTE, pad, safeMap } from '@fcom/core/utils';
import { CmsOffer, CmsTaxonomy } from '@fcom/core-api';
import { AspectRatios } from '@fcom/ui-components';
import { BOOKING_STEPS } from '@fcom/common/config/booking-config';

import { BlockOfferData, Destination } from '../../interfaces';

export const offsetToTimeZone = (offsetMillis) => {
  const negative = offsetMillis < 0;
  const offset = Math.abs(offsetMillis);
  const hours = Math.floor(offset / MILLIS_IN_HOUR);
  const remainder = offset % MILLIS_IN_HOUR;
  const minutes = Math.floor(remainder / MILLIS_IN_MINUTE);
  if (hours === 0 && minutes === 0) {
    return 'UTC';
  }
  const timeStr = `UTC${negative ? '-' : '+'}${hours}`;
  if (minutes > 0) {
    return `${timeStr}:${pad(minutes)}`;
  }
  return timeStr;
};

/**
 * CMS destination (nestingDepth = 1) to destination
 */
export const toDestination: (obj: any) => Destination = ({
  currency,
  defaultLocation: {
    locationCode,
    geoLocation: { countryCode },
  },
  flightDuration,
  localSettings: { temperature, rainfall },
  numberOfFlights,
  picture,
  timeZoneOffset,
  title,
  htmlTitle,
}) => ({
  avgRainfall: rainfall,
  avgTemperature: temperature,
  currencyCode: currency,
  flightDuration,
  imageData: picture,
  locationCode,
  countryCode,
  numberOfFlights,
  timeZoneOffset,
  timeZone: offsetToTimeZone(timeZoneOffset),
  title,
  htmlTitle,
});

export const toOfferLink = (obj: any, lang: string): string => {
  if (obj.booking) {
    return getBookingStepLink(lang, BOOKING_STEPS.ENTRY, { destination: obj.booking });
  }
  return obj.url;
};

export const toPromoBlock = (data: CmsOffer, lang?: string): BlockOfferData => {
  const link = data?.target?.url ? data.target.url : toOfferLink(data, lang);
  const title = data.title || data?.target?.title;
  // TODO: Make CMS return empty teaserTitle as CTA if left empty, not copy of the title
  const callToAction = data.teaserTitle === data.title ? null : data.teaserTitle;

  return {
    title: title,
    price: data.price,
    subtitle: data.teaserText,
    callToAction: callToAction,
    link: link,
    flag: data.flag,
    imageData: data.picture,
    destination: data.defaultLocationCode ?? resolveLocationCode(data),
  };

  function resolveLocationCode(cmsOffer: CmsOffer): string {
    if (isBlank(cmsOffer.booking)) {
      return safeMap(cmsOffer.target, (target) => target.locationCode);
    }
    return cmsOffer.booking;
  }
};

export const toLinkData = (a: any) =>
  !a
    ? null
    : {
        title: a.teaserTitle || a.title,
        external: !!a.external,
        url: a.url,
        openInNewTab: !!a.openInNewTab,
      };

export const findAspectRatio = (tags: CmsTaxonomy[]): keyof typeof AspectRatios | undefined =>
  tags
    ? (Object.keys(AspectRatios).find(
        (aspectRatio) => tags.indexOf(('cmstyle-image-aspect-ratio-' + aspectRatio) as CmsTaxonomy) >= 0
      ) as keyof typeof AspectRatios)
    : undefined;
