import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';

import { CmsCollection } from '@fcom/core-api';

@Component({
  selector: 'cms-conditional-accordion',
  templateUrl: './cms-conditional-accordion.component.html',
  styleUrls: ['cms-conditional-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsConditionalAccordionComponent {
  readonly DEFAULT_HEADER_LEVEL = 3;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  content$: Observable<CmsCollection>;
}
