import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { animate, style, transition, trigger, AnimationEvent } from '@angular/animations';

import { v4 as uuid } from 'uuid';

import { SentryLogger } from '@fcom/core';

export const EXPAND_ANIMATION_TIME = 300;

/**
 * A expandable area controllable via inputs.
 *
 * This only handles the expandable area and not the button to trigger this content area. This
 * should be done in a case-by-case basis.
 *
 * @example
 *
 * <code>
 *   <pre>
 *     <div class="wrapper border rounded">
 *       <button class="block pr-xsmall align-right fill" id="button-id" aria-controls="region-id">
 *         A description of an area to expand
 *         <fcom-icon class="mr-xsmall-l" [name]="SvgLibraryIcon.CHEVRON_DOWN"></fcom-icon>
 *       </button>
 *
 *       <fcom-expand
 *        [open]="open"
 *        [ariaId]="'region-id'"
 *        [ariaLabelledBy]="button-id"
 *        class="block">
 *         <div class="pr-xsmall border-top">
 *           <h1 class="pr-small-b">My test content</h1>
 *           <p class="pr-small-b">A really descriptive and cool paragraph</p>
 *           <p class="pr-small-b">A really descriptive and cool paragraph</p>
 *           <p>A really descriptive and cool paragraph</p>
 *         </div>
 *       </fcom-expand>
 *     </div>
 *   </pre>
 * </code>
 */
@Component({
  selector: 'fcom-expand',
  templateUrl: './expand.component.html',
  styleUrls: ['./expand.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      transition(':enter', [
        style({ height: 0 }),
        animate(`${EXPAND_ANIMATION_TIME}ms cubic-bezier(.17,.67,.83,.67)`, style({ height: '*' })),
      ]),
      transition(':leave', [animate(`${EXPAND_ANIMATION_TIME}ms cubic-bezier(.17,.67,.83,.67)`, style({ height: 0 }))]),
    ]),
  ],
})
export class ExpandComponent implements OnInit, OnDestroy {
  /**
   * Boolean indicating whether the expandable area should be open or closed
   */
  @Input() open = false;

  /**
   * ID for the expanded container.
   * The button toggling the expand should have an `aria-controls="ID"`
   * attribute with the matching ID string.
   */
  @Input() ariaId: string = uuid();

  /**
   * Id of the toggle button that expands and collapses the region.
   * Required for accessibility. This will throw a logger.error if not set.
   */
  @Input() ariaLabelledBy: string;

  animationTimeOut: ReturnType<typeof setTimeout>;

  constructor(private logger: SentryLogger) {}

  ngOnInit(): void {
    if (!this.ariaLabelledBy) {
      this.logger.error('ariaLabelledBy Is required for accessibility');
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.animationTimeOut);
  }

  onAnimationStart(event: AnimationEvent): void {
    event.element.classList.add('transition');
    clearTimeout(this.animationTimeOut);

    this.animationTimeOut = setTimeout(() => {
      event?.element?.classList?.remove('transition');
    }, EXPAND_ANIMATION_TIME);
  }
}
