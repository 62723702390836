import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { StepProgressState } from '../../enums';
import { StepProgress } from '../../interfaces/step-progress.interface';

const TOTAL_CIRCLE_STROKE_LENGTH = 100;
const INITIAL_OFFSET = 25;

@Component({
  selector: 'fin-step-progress-circle',
  templateUrl: './step-progress-circle.component.html',
  styleUrls: ['./step-progress-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepProgressCircleComponent implements OnInit {
  readonly StepProgressState = StepProgressState;
  @Input() steps: StepProgress[];

  segmentStrokeLength: number;
  segmentStrokeDasharray: string;
  totalStrokeLength: number;
  gapLength: number;
  initialOffset: number;

  ngOnInit(): void {
    if (this.steps.length > 1) {
      this.segmentStrokeLength = Math.trunc(TOTAL_CIRCLE_STROKE_LENGTH / this.steps.length) - 2;
      this.segmentStrokeDasharray = `${this.segmentStrokeLength} ${
        TOTAL_CIRCLE_STROKE_LENGTH - this.segmentStrokeLength
      }`;
      this.totalStrokeLength = this.segmentStrokeLength * this.steps.length;
      this.gapLength = +((TOTAL_CIRCLE_STROKE_LENGTH - this.totalStrokeLength) / this.steps.length).toFixed(2);
      this.initialOffset = +(INITIAL_OFFSET - this.gapLength / 2).toFixed(2);

      this.steps.forEach((step, index) => {
        if (index === 0) {
          step.strokeDashOffset = this.initialOffset;
        } else {
          step.strokeDashOffset = +(
            TOTAL_CIRCLE_STROKE_LENGTH -
            this.segmentStrokeLength * index +
            this.initialOffset -
            this.gapLength * index
          ).toFixed(2);
        }
      });
    }
  }
}
