export const BUS_AIRCRAFT_CODE = 'BUS';

export const GENDER_OPTIONS = [{ value: 'mr' }, { value: 'ms' }];

export enum Title {
  MS = 'Ms.',
  MRS = 'Mrs.',
  MR = 'Mr.',
}

export enum FrequentFlyerTierLevel {
  PLUS = 'PLUS',
  SILVER = 'SILV',
  GOLD = 'GOLD',
  PLATINUM = 'PLAT',
  LUMO = 'LUMO',
  JUNIOR = 'JUNR',
}
