import { CustomIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { CmsTaxonomyPicture } from '@fcom/core-api/interfaces';
import { ProfileTier } from '@fcom/core-api/login';

export const TIER_LIMITS = {
  [ProfileTier.SILVER]: 30000,
  [ProfileTier.GOLD]: 80000,
  [ProfileTier.PLATINUM]: 150000,
  [ProfileTier.LUMO]: 450000,
};

export const AVIOS_TIER_LIMITS = {
  [ProfileTier.SILVER]: 15_000,
  [ProfileTier.GOLD]: 45_000,
  [ProfileTier.PLATINUM]: 80_000,
  [ProfileTier.LUMO]: 300_000,
};

export const TIER_FLIGHT_LIMITS = {
  [ProfileTier.SILVER]: 20,
  [ProfileTier.GOLD]: 46,
  [ProfileTier.PLATINUM]: 76,
  [ProfileTier.LUMO]: 150,
};

export const LIFETIME_TIER_TEXT = 'Lifetime';

export const LIFETIME_TIER_LIMITS = {
  [ProfileTier.GOLD]: 3000000,
  [ProfileTier.PLATINUM]: 5000000,
};

export const AVIOS_LIFETIME_TIER_LIMITS = {
  [ProfileTier.GOLD]: 2_000_000,
  [ProfileTier.PLATINUM]: 3_300_000,
};

export enum TierState {
  MAINTAIN = 'maintain',
  GAIN = 'gain',
  HIGHEST = 'highest',
  // hold tier state is no longer offered to members and can be ignored.
  // The statusHold property in the member profile still exists,
  // along with the back-end implementation for it in case of a future use case.
  // HOLD = 'hold',
}

export enum TierName {
  BASIC = 'Basic',
  SILVER = 'Silver',
  GOLD = 'Gold',
  PLATINUM = 'Platinum',
  LUMO = 'Platinum Lumo',
  JUNIOR = 'Junior',
}

export type TierDataMap = Record<
  ProfileTier[number],
  {
    cardIcon?: CustomIcon;
    membership: string;
    oneworldLogo: CmsTaxonomyPicture;
    tierKey: string;
    tierName: TierName;
  }
>;

export const TIER_DATA_MAP: TierDataMap = {
  [ProfileTier.BASIC]: {
    membership: 'plus.dashboard.membership.basic',
    cardIcon: CustomIcon.PLUS_CARD_BASIC,
    oneworldLogo: CmsTaxonomyPicture.FINNAIR_ONEWORLD_LOGO,
    tierName: TierName.BASIC,
    tierKey: 'basic',
  },
  [ProfileTier.SILVER]: {
    membership: 'plus.dashboard.membership.silver',
    cardIcon: CustomIcon.PLUS_CARD_SILVER,
    oneworldLogo: CmsTaxonomyPicture.FINNAIR_ONEWORLD_GEM_RUBY_LOGO,
    tierName: TierName.SILVER,
    tierKey: 'silver',
  },
  [ProfileTier.GOLD]: {
    membership: 'plus.dashboard.membership.gold',
    cardIcon: CustomIcon.PLUS_CARD_GOLD,
    oneworldLogo: CmsTaxonomyPicture.FINNAIR_ONEWORLD_GEM_SAPPHIRE_LOGO,
    tierName: TierName.GOLD,
    tierKey: 'gold',
  },
  [ProfileTier.PLATINUM]: {
    membership: 'plus.dashboard.membership.platinum',
    cardIcon: CustomIcon.PLUS_CARD_PLATINUM,
    oneworldLogo: CmsTaxonomyPicture.FINNAIR_ONEWORLD_GEM_EMERALD_LOGO,
    tierName: TierName.PLATINUM,
    tierKey: 'platinum',
  },
  [ProfileTier.LUMO]: {
    membership: 'plus.dashboard.membership.lumo',
    cardIcon: CustomIcon.PLUS_CARD_LUMO,
    oneworldLogo: CmsTaxonomyPicture.FINNAIR_ONEWORLD_GEM_EMERALD_LOGO,
    tierName: TierName.LUMO,
    tierKey: 'lumo',
  },
  [ProfileTier.JUNIOR]: {
    membership: 'plus.dashboard.membership.junior',
    cardIcon: CustomIcon.PLUS_CARD_JUNIOR,
    oneworldLogo: CmsTaxonomyPicture.FINNAIR_ONEWORLD_LOGO,
    tierName: TierName.JUNIOR,
    tierKey: 'junior',
  },
};
