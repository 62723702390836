import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Subscription } from 'rxjs';

import { SelectOption } from '@fcom/common/interfaces';
import { unsubscribe } from '@fcom/core/index';

import { PopoverOptions } from '../../popover';

@Component({
  selector: 'fin-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss'],
})
export class SelectInputComponent implements OnInit, OnDestroy, OnChanges {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  controlName: string;

  @Input()
  translationPrefix: string;

  @Input()
  options: SelectOption[];

  @Input()
  id: string;

  @Input()
  selectLabelTranslationKey = 'select';

  @Input()
  required: boolean;

  @Input()
  displayMargin = true;

  idOrControlName: string;
  control: AbstractControl;

  @ViewChild('selectElement', { static: true })
  selectElement: ElementRef;

  protected subscriptions: Subscription = new Subscription();

  get fieldRequired(): boolean {
    return this.required || this.ctrlField.hasValidator(Validators.required);
  }

  get showAsterix(): boolean {
    return this.required ?? this.fieldRequired;
  }

  get ctrlField(): AbstractControl {
    return this.parentForm.get(this.controlName);
  }

  @Input()
  label: string;

  @Input()
  tooltipHeading: string;

  @Input()
  tooltipContent: string;

  @Input()
  tooltipOptions: PopoverOptions;

  @Input()
  disableLabel = false;

  @Input()
  disableSelectOption = false;

  ngOnInit(): void {
    this.control = this.ctrlField;
    this.idOrControlName = this.id || this.controlName;
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  setFocus(): void {
    this.selectElement.nativeElement.focus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.options || changes.options.firstChange) {
      return;
    }

    /**
     * Without this delay then selecting the value does not update the DOM when the new options
     * have rendered.
     */
    setTimeout(() => {
      const hasValueInOptions = changes.options.currentValue.find((option) => option.value === this.ctrlField.value);

      if (!hasValueInOptions) {
        this.ctrlField.patchValue('');
      } else {
        this.ctrlField.patchValue(this.ctrlField.value);
      }
    }, 0);
  }
}
