import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { MenuListItem } from '@fcom/common/interfaces';

import { IconPosition } from '../../icons';

export interface MenuListClickEvent {
  itemId: string;
  originalEvent: Event;
}

@Component({
  selector: 'fcom-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrl: './menu-list.component.scss',
})
export class MenuListComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input({ required: true })
  items: MenuListItem[];

  @Output()
  itemClicked = new EventEmitter<MenuListClickEvent>();

  handleClick(itemId: string, event: Event): void {
    this.itemClicked.emit({
      itemId,
      originalEvent: event,
    });
  }

  protected readonly IconPosition = IconPosition;
}
