export enum QualtricsMessageTypes {
  OPTIN_RESPONSE = 'optInResponse',
  SURVEY_DONE = 'surveyDone',
  CONFSURVEY_DONE = 'confSurveyDone',
  NPS_RATING_RESPONSE = 'npsRatingResponse',
}

export const messageAllowedForSurvey = (surveyName: string, messageType: string): boolean => {
  return messageMap[messageType].indexOf(surveyName) !== -1;
};

export const QUALTRICS_DOMAIN = 'https://finnair.eu.qualtrics.com';

export interface QualtricsSurvey {
  name: string;
  url: string;
}

interface QualtricsSurveyMap {
  FEEDBACK: QualtricsSurvey;
  CORPORATE_FEEDBACK: QualtricsSurvey;
  MMB_FEEDBACK: QualtricsSurvey;
  CHECK_IN: QualtricsSurvey;
  PURCHASE_FEEDBACK: QualtricsSurvey;
  AWARD_FEEDBACK: QualtricsSurvey;
  UPGRADE_FEEDBACK: QualtricsSurvey;
  PAGE_FEEDBACK: QualtricsSurvey;
}

export const QualtricsSurveys: QualtricsSurveyMap = {
  FEEDBACK: { name: 'qualtrics-feedback', url: 'feedback.generalSurveyUrl' },
  CORPORATE_FEEDBACK: { name: 'qualtrics-corporate-feedback', url: 'feedback.corporateSurveyUrl' },
  MMB_FEEDBACK: { name: 'qualtrics-mmb-feedback', url: 'feedback.mmbSurveyUrl' },
  CHECK_IN: { name: 'qualtrics-check-in-feedback', url: 'feedback.checkInSurveyUrl' },
  PURCHASE_FEEDBACK: { name: 'qualtrics-purchase-feedback', url: 'feedback.postBookingSurveyUrl' },
  AWARD_FEEDBACK: { name: 'qualtrics-award-feedback', url: 'feedback.postAwardBookingSurveyUrl' },
  UPGRADE_FEEDBACK: { name: 'qualtrics-upgrade-feedback', url: 'feedback.upgradeSurveyUrl' },
  PAGE_FEEDBACK: { name: 'qualtrics-page-feedback', url: 'feedback.pageSurveyUrl' },
};

const messageMap = {
  [QualtricsMessageTypes.OPTIN_RESPONSE]: [],
  [QualtricsMessageTypes.SURVEY_DONE]: [QualtricsSurveys.FEEDBACK.name],
  [QualtricsMessageTypes.CONFSURVEY_DONE]: [QualtricsSurveys.PURCHASE_FEEDBACK.name],
  [QualtricsMessageTypes.NPS_RATING_RESPONSE]: [],
};

const getQualtricsLanguage = (languageSite: string, fallbackLanguage: string): string => {
  switch (languageSite) {
    case 'hk-zh':
      return 'zh-t';
    case 'cn-zh':
      return 'zh-s';
    case 'es-es':
      return 'es-es';
    default:
      return fallbackLanguage;
  }
};

export const getQualtricsUrl = (fallbackLanguage: string, languageSite: string, url: string): string =>
  url ? `${url}?Q_Language=${getQualtricsLanguage(languageSite, fallbackLanguage)}` : null;
