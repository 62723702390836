/* eslint-disable */
import { NgModule, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { InstantsearchService } from './services/instantsearch.service';
import { TimetableService } from './services/timetable.service';

/**
 * Module that provides all services.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    InstantsearchService,
    TimetableService,
  ],
})
export class InstantsearchApiModule {
  constructor(
    @Optional() @SkipSelf() parentModule: InstantsearchApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('InstantsearchApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
