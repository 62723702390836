import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';

import { ModalModule, UiComponentsModule } from '@fcom/ui-components';
import { UiTranslateModule } from '@fcom/ui-translate';
import { PipesModule } from '@fcom/common';
import { DirectiveModule } from '@fcom/common/directives/directive.module';
import { FinFormsModule } from '@fcom/common/forms';
import { ComponentsModule } from '@fcom/common/components/components.module';
import { LoginModule } from '@fcom/common/login';

import { BookingWidgetGtmService } from './services/booking-widget-gtm.service';
import { BOOKING_WIDGET_FEATURE_KEY } from './store/constants';
import { BookingWidgetCalendarService } from './services/booking-widget-calendar.service';
import { BookingWidgetAmService } from './services/booking-widget-am.service';
import {
  AirCalendarComponent,
  AmBookingWidgetComponent,
  AmDurationSelectorComponent,
  AmRoomSelectorComponent,
  BookingWidgetCommonComponent,
  BookingWidgetComponent,
  BookingWidgetNotificationComponent,
  BookingWidgetSelectorComponent,
  CarTrawlerBookingWidgetComponent,
  DiscountCodeComponent,
  GlobalBookingWidgetSummaryHeaderComponent,
  LocationSelectorComponent,
  MulticitySelectorComponent,
  PaxAmountSelectorComponent,
  PreviousSearchesComponent,
  TravelDatesSelectorComponent,
  TripTypeSelectorComponent,
} from './components';
import { bookingWidgetReducer } from './store/reducers/booking-widget.reducer';

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    UiComponentsModule,
    UiTranslateModule,
    DirectiveModule,
    ModalModule,
    FinFormsModule,
    ComponentsModule,
    LoginModule,
    StoreModule.forFeature(BOOKING_WIDGET_FEATURE_KEY, bookingWidgetReducer),
  ],
  providers: [BookingWidgetCalendarService, BookingWidgetGtmService, BookingWidgetAmService],
  declarations: [
    AirCalendarComponent,
    BookingWidgetComponent,
    BookingWidgetSelectorComponent,
    PaxAmountSelectorComponent,
    LocationSelectorComponent,
    PreviousSearchesComponent,
    TripTypeSelectorComponent,
    TravelDatesSelectorComponent,
    DiscountCodeComponent,
    BookingWidgetNotificationComponent,
    AmBookingWidgetComponent,
    AmRoomSelectorComponent,
    AmDurationSelectorComponent,
    GlobalBookingWidgetSummaryHeaderComponent,
    BookingWidgetCommonComponent,
    MulticitySelectorComponent,
    CarTrawlerBookingWidgetComponent,
  ],
  exports: [
    BookingWidgetComponent,
    BookingWidgetSelectorComponent,
    GlobalBookingWidgetSummaryHeaderComponent,
    BookingWidgetCommonComponent,
    TravelDatesSelectorComponent,
  ],
})
export class BookingWidgetModule {}
