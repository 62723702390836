import { Observable } from 'rxjs';
import { filter, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { getIn, isPresent, isTruthy } from '@fcom/core/utils';
import { safeMap } from '@fcom/rx';

import { BaseState, DdsLanguage, LanguageState } from '../interfaces';
import { ddsLanguages } from './dds-languages';

const DEFAULT_LANG: DdsLanguage = { countrySite: 'INT', language: 'GB' };

const mapToDdsLanguage = (localeValue: string): DdsLanguage => ddsLanguages[localeValue] || DEFAULT_LANG;

/**
 * Selects the current language from the state$, e.g., fi-fi, en
 */
export const language =
  () =>
  (state$: Observable<BaseState>): Observable<string> =>
    state$.pipe(
      safeMap((s: BaseState) => s.language),
      safeMap((s: LanguageState) => s.value),
      filter((s) => isTruthy(s)),
      distinctUntilChanged()
    );

/**
 * Selects the current locale from the state$, e.g., fi_FI, en_GB
 */
export const locale =
  () =>
  (state$: Observable<BaseState>): Observable<string> =>
    state$.pipe(
      safeMap((s: BaseState) => s.language),
      safeMap((s: LanguageState) => s.locale),
      filter((s) => isTruthy(s)),
      distinctUntilChanged()
    );

/**
 * Selects the current dds language from the state$, e.g., { countrySite: 'en', language: 'gb' }
 */
export const ddsLanguage =
  () =>
  (state$: Observable<BaseState>): Observable<DdsLanguage> =>
    state$.pipe(
      safeMap((s: BaseState) => s.language),
      safeMap((s: LanguageState) => s.locale),
      filter(isTruthy),
      map(mapToDdsLanguage),
      distinctUntilChanged()
    );

/**
 * Selects to localization by the given language
 * @param lang the language key
 */
export const localization =
  (lang: string) =>
  (state$: Observable<BaseState>): Observable<any> =>
    state$.pipe(
      safeMap((s: BaseState) => s.language),
      safeMap((s: LanguageState) => s.localizations),
      distinctUntilChanged(),
      safeMap((s: any) => s[lang]),
      filter(isPresent)
    );

/**
 * Selects the current localizations based on the language
 */
export const currentLocalization =
  () =>
  (state$: Observable<BaseState>): Observable<any> =>
    state$.pipe(
      language(),
      filter(isPresent),
      switchMap((lang) => state$.pipe(localization(lang))),
      filter(isPresent)
    );

/**
 * Selects the localization key for current localizations
 */
export const translate =
  (key: string | string[]) =>
  (state$: Observable<BaseState>): Observable<any> =>
    state$.pipe(
      currentLocalization(),
      safeMap((localizations) => getIn(localizations, key))
    );
