import {
  Category,
  FinnairOrder,
  FinnairPassengerItem,
  FinnairPassengerServiceSelectionItem,
  FinnairServiceCategoryPriceItem,
  FinnairServiceItem,
  FinnairTotalPricesDetails,
} from '@fcom/dapi/api/models';
import { isPresent } from '@fcom/core/utils';
import { PriceService } from '@fcom/dapi/interfaces';

import { combineServices, filterServices } from '../modules/ancillaries/utils/ancillary.utils';

export const getLastPaidServices = (order: FinnairOrder): FinnairServiceItem[] => {
  const purchasedTSMS =
    order.checkouts?.sort((a, b) => +a.timestamp - +b.timestamp)[0]?.items.map((item) => item.id) ?? [];

  return purchasedTSMS.length > 0
    ? filterServices(
        combineServices([order.services.included, order.services.pending]),
        (service: FinnairPassengerServiceSelectionItem) => purchasedTSMS.includes(service.id)
      )
    : order.services.pending;
};

export const getLastPaidPrices = (order: FinnairOrder): FinnairTotalPricesDetails | undefined => {
  const lastCheckoutId = order.checkouts?.[0]?.id;
  return order.prices.checkouts?.find((checkout) => checkout.id === lastCheckoutId)?.prices ?? order.prices.pending;
};

export const hasPassengers = (passengers: FinnairPassengerItem[]): boolean =>
  isPresent(passengers) && passengers.length > 0;

export const getPassengerServices = (
  pricesForCategory: FinnairServiceCategoryPriceItem[] = [],
  passenger: FinnairPassengerItem
): PriceService[] => {
  const sortOrder: string[] = ['seat', 'baggage', 'meal', 'wifi', 'travelComfort', 'lounge', 'cover'].reverse();
  return pricesForCategory
    .map((c) => {
      const name = `${c.category}Count`;
      const count = c.totalPerPax[passenger.id]?.quantity ?? 0;
      const totalAmount = c.totalPerPax[passenger.id]?.price?.totalAmount;
      const originalTotalAmount = c.totalPerPax[passenger.id]?.price?.originalTotalAmount;
      return {
        id: c.category,
        total: totalAmount ?? null,
        originalTotal: originalTotalAmount ?? null,
        count,
        key: `purchase.payment.${name}`,
        [name]: count,
      };
    })
    .filter((service) => service.count > 0)
    .sort((a, b) => sortOrder.indexOf(b.id) - sortOrder.indexOf(a.id));
};

export const getCategoryServices = (
  pricesForCategory: FinnairServiceCategoryPriceItem[] = [],
  ancillaryCategory: Category
): FinnairServiceCategoryPriceItem => {
  return pricesForCategory.find((categories) => categories.category === ancillaryCategory);
};

export const showTravelExtrasTitle = (pricesForCategory: FinnairServiceCategoryPriceItem[] = []): boolean => {
  const hasSaf = pricesForCategory.some((item) => item.category === Category.SAF);
  const hasOtherAncillaries = pricesForCategory.some((item) => item.category !== Category.SAF);

  return !hasSaf || hasOtherAncillaries;
};

export const passengerHasSaf = (
  pricesForCategory: FinnairServiceCategoryPriceItem[] = [],
  passenger: FinnairPassengerItem
): boolean => {
  return (
    pricesForCategory.length &&
    pricesForCategory.some((service) => service.category === Category.SAF && service.totalPerPax[passenger.id])
  );
};

export const passengerHasOtherServices = (
  pricesForCategory: FinnairServiceCategoryPriceItem[] = [],
  passenger: FinnairPassengerItem
): boolean => {
  return (
    pricesForCategory.length &&
    pricesForCategory.reduce((acc, service) => {
      return acc || Boolean(service.category !== Category.SAF && service.totalPerPax[passenger.id]);
    }, false)
  );
};
