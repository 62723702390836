import { LowestPriceForFlights, TravelClassDatePrice } from '@fcom/dapi/api/models';
import { entrySet } from '@fcom/core/utils';
import { GlobalBookingTravelClass } from '@fcom/core';

import { MarketingOffer, MarketingOfferPrices } from '../interfaces';

const getMarketingOfferPrice = (
  lowestPrice: TravelClassDatePrice[],
  currencyCode: string,
  travelClass: GlobalBookingTravelClass
): MarketingOfferPrices[] =>
  lowestPrice && lowestPrice.length > 0
    ? lowestPrice.map((price) => ({
        travelClass,
        price: {
          amount: price.price.toFixed(2),
          tripType: price.tripType,
          currencyCode,
        },
      }))
    : [];

// get marketing offer for both oneway and return price
export const mapToMarketingOffers = (lowestPriceForFlights: LowestPriceForFlights): MarketingOffer[] => {
  return entrySet(lowestPriceForFlights.prices)
    .map((currentPrice) => {
      const originLocation = currentPrice.key;
      return entrySet(currentPrice.value)
        .map((destinationAndPrices) => {
          const destinationLocation = destinationAndPrices.key;
          const lowestPriceForEconomy = destinationAndPrices.value.travelClassPrices.filter(
            (t) => t.travelClass?.toUpperCase() === GlobalBookingTravelClass.ECONOMY
          );
          const lowestPriceForPremiumEconomy = destinationAndPrices.value.travelClassPrices.filter(
            (t) => t.travelClass?.toUpperCase() === GlobalBookingTravelClass.ECOPREMIUM
          );
          const lowestPriceForBusiness = destinationAndPrices.value.travelClassPrices.filter(
            (t) => t.travelClass?.toUpperCase() === GlobalBookingTravelClass.BUSINESS
          );
          if (
            !lowestPriceForEconomy?.length &&
            !lowestPriceForPremiumEconomy?.length &&
            !lowestPriceForBusiness?.length
          ) {
            return null;
          }
          return {
            origin: originLocation,
            destination: destinationLocation,
            airportCode: destinationAndPrices.value.to,
            prices: [
              ...getMarketingOfferPrice(
                lowestPriceForEconomy,
                destinationAndPrices.value.currency,
                GlobalBookingTravelClass.ECONOMY
              ),
              ...getMarketingOfferPrice(
                lowestPriceForPremiumEconomy,
                destinationAndPrices.value.currency,
                GlobalBookingTravelClass.ECOPREMIUM
              ),
              ...getMarketingOfferPrice(
                lowestPriceForBusiness,
                destinationAndPrices.value.currency,
                GlobalBookingTravelClass.BUSINESS
              ),
            ],
          };
        })
        .filter(Boolean);
    })
    .reduceRight((accumulator, currentValue) => accumulator.concat(currentValue), []);
};
