import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'finEncode',
  pure: false, // Depends on date
})
export class EncodePipe implements PipeTransform {
  transform(inputString: string): string {
    return `https://finnair.com${encodeURI(inputString)}`;
  }
}
