<h2 *ngIf="showHeading" class="mr-large-b font-heading-2">
  <span *ngIf="showLabel" class="block light-type travel-guide-label">{{ 'travelGuide' | finLocalization }}</span>
  <span class="bold-type">{{ outputContent$ | finMap: 'title' | async }}</span>
</h2>

<fin-video
  *ngIf="(mediaType$ | async) === 'video'"
  [videoUrl$]="videoUrl$"
  [videoTitle$]="outputContent$ | finMap: 'title'"
></fin-video>

<div *ngIf="(mediaType$ | async) === 'picture'" class="mr-xsmall-y">
  <fin-content-image [imageData$]="outputContent$ | finMap: 'media.picture'"></fin-content-image>
</div>

<ng-container *ngIf="phraseGroups$ | async as pg; else normalText">
  <fin-travel-guide-phrases
    *ngFor="let phraseGroup of pg"
    [phraseGroup]="phraseGroup"
    class="travel-guide-text block"
  ></fin-travel-guide-phrases>
</ng-container>

<ng-template #normalText>
  <span
    [innerHTML]="text$ | async | finEmpty | finSafe: 'html'"
    class="travel-guide-text block mr-small-t mr-large-b"
  ></span>
</ng-template>
