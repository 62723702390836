import { Route } from '@angular/router';

import { createReducer, on } from '@ngrx/store';

import { GlobalActions } from '@fcom/core/actions';
import { updateStoreAction } from '@fcom/core/store/init-store.action';
import { Category, FinnairPassengerServiceItem, FinnairServiceItem } from '@fcom/dapi/api/models';
import { FlightSeatSelections, SeatMapState, SeatMapStatus } from '@fcom/common/interfaces/seat-map.interface';
import { SeatCharacteristics } from '@fcom/dapi/interfaces';
import { compareAsJson, DataUtils } from '@fcom/core/utils';
import { GlobalBookingActions } from '@fcom/common/store';
import { BookingActions, CartActions, OffersActions } from '@fcom/booking/store/actions';
import { BookingAppState } from '@fcom/common/interfaces/booking';
import { RootPaths } from '@fcom/core/constants';
import { leaveError } from '@fcom/common/store/actions/error.actions';

import { OrderActions, SeatMapActions } from '../actions';
import { combineIncludedAndUnpaidSeats } from '../../modules/ancillaries/utils';
import { COMMON_BOOKING_FEATURE_KEY } from '../constants';
import { CommonBookingFeatureState } from '../store.interface';

export const initialState: SeatMapState = Object.seal({});

const flightServices = (service: FinnairServiceItem | undefined, flightId: string) => {
  if (!service) {
    return [];
  }
  return [
    ...service.bounds.reduce(
      (allServices, b) => allServices.concat(...(b.segments.find((s) => s.id === flightId)?.passengers || [])),
      []
    ),
  ];
};

const toFlightSeatSelections = (
  seatSelectionsInCart: FinnairServiceItem,
  flightId: string,
  includedSeats: FinnairPassengerServiceItem[],
  setPaidStatus: boolean
): FlightSeatSelections =>
  flightServices(seatSelectionsInCart, flightId).reduce(
    (acc: FlightSeatSelections, service: FinnairPassengerServiceItem) => {
      const seatService = service.services.find((s) => !!s.seatNumber) || service.services[0];
      acc[service.id] = {
        seatNumber: seatService.seatNumber,
        seatType: seatService.seatType,
        characteristics: seatService.characteristics as SeatCharacteristics[],
        paid: setPaidStatus
          ? includedSeats.some((includedSeat) => compareAsJson(includedSeat, service)) &&
            parseFloat(seatService.totalPrice?.amount) > 0
          : false,
        modifiable: seatService.modifiable ?? true,
        ticketed: seatService.ticketed ?? false,
        travelerId: service.id,
        pricePerItem: seatService.unitPrice,
      };
      return acc;
    },
    {}
  );

const loadSeatSelections = function (
  state: SeatMapState,
  seatSelections: FinnairServiceItem | undefined,
  includedSeats: FinnairServiceItem[],
  setPaidStatus: boolean
) {
  const clearedSelectionsAndLoadingStatus = Object.keys(state).reduce((s: SeatMapState, currentFlightId: string) => {
    return DataUtils.wrap(s)
      .deleteIn([currentFlightId, 'selections'])
      .updateIn([currentFlightId, 'status'], (status: SeatMapStatus) =>
        status === SeatMapStatus.PENDING ? SeatMapStatus.INITIAL : status
      )
      .value();
  }, state);
  return (
    seatSelections?.bounds.reduce((s: SeatMapState, currentBound) => {
      return currentBound.segments.reduce((s2: SeatMapState, currentSegment) => {
        const newSelections = toFlightSeatSelections(
          seatSelections,
          currentSegment.id,
          includedSeats.flatMap((service) =>
            service.bounds
              .filter((bound) => bound.id === currentBound.id)
              .flatMap((bound) =>
                bound.segments
                  .filter((segment) => segment.id === currentSegment.id)
                  .flatMap((segment) => segment.passengers)
              )
          ),
          setPaidStatus
        );
        return (
          DataUtils.wrap(s2)
            // Set default value for seat map status
            .updateIn([currentSegment.id, 'status'], (status: SeatMapStatus) => status || SeatMapStatus.INITIAL)
            .setIn([currentSegment.id, 'selections'], newSelections)
            .value()
        );
      }, s);
    }, clearedSelectionsAndLoadingStatus) || clearedSelectionsAndLoadingStatus
  );
};

function isCart(payload: BookingAppState | CommonBookingFeatureState): payload is BookingAppState {
  return !!(payload as BookingAppState)?.cart?.cartData;
}

function getServices(payload: BookingAppState | CommonBookingFeatureState): FinnairServiceItem[] {
  return isCart(payload)
    ? payload?.cart?.cartData?.services.unpaid
    : combineIncludedAndUnpaidSeats(
        payload?.commonBooking?.order?.orderData?.services?.included || [],
        payload?.commonBooking?.order?.orderData?.services?.unpaid || []
      );
}

export const seatMapReducer = createReducer(
  initialState,
  on(updateStoreAction, (state, { payload, features }) => {
    if (features.includes(COMMON_BOOKING_FEATURE_KEY)) {
      const services: FinnairServiceItem[] = getServices(payload);
      return loadSeatSelections(
        state,
        services?.find((s) => s.category === Category.SEAT),
        !isCart(payload)
          ? ((payload as CommonBookingFeatureState)?.commonBooking?.order?.orderData?.services?.included ?? []).filter(
              (s) => s.category === Category.SEAT
            )
          : [],
        !isCart(payload)
      );
    }
    return state;
  }),
  on(OrderActions.setOrderData, (state, { order, keepSeatsData }) => {
    // TODO: Should use UX model instead of backend model
    const services: FinnairServiceItem[] = combineIncludedAndUnpaidSeats(
      order?.services?.included ?? [],
      order?.services?.unpaid ?? []
    );
    return loadSeatSelections(
      keepSeatsData ? state : initialState,
      services?.find((s) => s.category === Category.SEAT),
      (order?.services?.included ?? []).filter((s) => s.category === Category.SEAT),
      true
    );
  }),
  on(SeatMapActions.resetSeatSelections, (state, { cartOrOrder }) => {
    const seatServices: FinnairServiceItem = combineIncludedAndUnpaidSeats(
      cartOrOrder?.services?.included ?? [],
      cartOrOrder?.services?.unpaid ?? []
    )?.find((s) => s.category === Category.SEAT);
    const includedSeatServices: FinnairServiceItem[] = (cartOrOrder?.services?.included ?? [])?.filter(
      (s) => s.category === Category.SEAT
    );
    return loadSeatSelections(state, seatServices, includedSeatServices, true);
  }),
  on(SeatMapActions.startLoading, (state, { flightId }) => {
    return DataUtils.wrap(state).mergeIn(flightId, { status: SeatMapStatus.PENDING }).value();
  }),
  on(SeatMapActions.setNoAvailability, (state, { flightId }) => {
    return DataUtils.wrap(state).mergeIn(flightId, { status: SeatMapStatus.OK_NO_AVAILABILITY }).value();
  }),
  on(SeatMapActions.error, (state, { flightId }) => {
    return DataUtils.wrap(state).setIn(flightId, { status: SeatMapStatus.LOAD_ERROR }).value();
  }),
  on(SeatMapActions.setSeatForFlight, (state, { flightId, travelerId, seat }) => {
    return DataUtils.wrap(state)
      .updateIn([flightId, 'selections'], (selections: FlightSeatSelections) => {
        if (!selections) {
          return {};
        }
        const conflictingTravelerIds: string[] = Object.keys(selections)
          .filter((innerTravelerId) => innerTravelerId !== travelerId)
          .filter((innerTravelerId) => !!selections[innerTravelerId])
          .filter((innerTravelerId) => selections[innerTravelerId].seatNumber === seat?.seatNumber);

        return conflictingTravelerIds.reduce(
          (_acc, conflictingId) =>
            DataUtils.wrap(selections)
              .setIn([conflictingId, 'seatNumber'], null)
              .setIn([conflictingId, 'seatType'], null)
              .setIn([conflictingId, 'characteristics'], null)
              .value(),
          selections
        );
      })
      .setIn([flightId, 'selections', travelerId, 'seatNumber'], seat?.seatNumber || null)
      .setIn([flightId, 'selections', travelerId, 'seatType'], seat?.type || null)
      .setIn(
        [flightId, 'selections', travelerId, 'characteristics'],
        (seat?.characteristics as SeatCharacteristics[]) || null
      )
      .setIn([flightId, 'selections', travelerId, 'travelerId'], travelerId)
      .setIn([flightId, 'selections', travelerId, 'paid'], false)
      .setIn([flightId, 'selections', travelerId, 'ticketed'], false)
      .setIn([flightId, 'selections', travelerId, 'modifiable'], true)
      .setIn(
        [flightId, 'selections', travelerId, 'pricePerItem'],
        seat?.prices?.find((p) => p.travelerIds.includes(travelerId))?.price
      )
      .value();
  }),
  on(SeatMapActions.setSeatMap, (state, { seatMapForFlight }) => {
    return DataUtils.wrap(state)
      .mergeIn(seatMapForFlight.flightId, {
        status: SeatMapStatus.OK,
        seatMap: seatMapForFlight.seatMap,
      })
      .value();
  }),
  on(SeatMapActions.loadSeatSelectionsFromCart, CartActions.setCartData, (state, { cartData }) => {
    return loadSeatSelections(
      state,
      cartData?.services.unpaid?.find((s) => s.category === Category.SEAT),
      [],
      false
    );
  }),
  on(
    SeatMapActions.reset,
    SeatMapActions.clearSelections,
    BookingActions.setTravelType,
    BookingActions.selectTravelClass,
    GlobalBookingActions.setFlights,
    GlobalBookingActions.updateFlight,
    GlobalBookingActions.setPaxAmount,
    GlobalBookingActions.increasePaxAmountField,
    GlobalBookingActions.decreasePaxAmountField,
    GlobalActions.confirmation,
    leaveError,
    OffersActions.reset,
    OffersActions.setInboundId,
    OffersActions.setInboundFareFamily,
    OffersActions.setOutboundId,
    OffersActions.setOutboundFareFamily,
    (state, action) => {
      if ((action as { route?: Route }).route?.path === RootPaths.MANAGE_BOOKING_ROOT) {
        return state;
      }
      return initialState;
    }
  )
);
