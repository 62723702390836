import { LatLng } from '@fcom/core';

const hashParametersFromUrl = (): Record<string, string> => {
  return window.location.hash
    .substring(1)
    .split(';')
    .map((keyVal) => keyVal.split('='))
    .reduce((obj, valuesArr) => {
      obj[valuesArr[0]] = valuesArr[1];
      return obj;
    }, {});
};

export const parseGeolocationFromUrlHash = (): LatLng => {
  try {
    const params = hashParametersFromUrl();
    const lng = parseFloat(params.lng);
    const lat = parseFloat(params.lat);

    return !isNaN(lng) && !isNaN(lat) ? { lng, lat } : undefined;
  } catch (e) {
    return undefined;
  }
};
