import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { finShare, safeMap } from '@fcom/rx';

import { BaseState, PseudoLocalState } from '../interfaces';

/**
 * Selects the current value from the state via given key.
 */
export const pseudoLocal =
  (key: string) =>
  (state$: Observable<BaseState>): Observable<any> =>
    state$.pipe(
      safeMap((s: BaseState) => s.pseudoLocal),
      safeMap((s: PseudoLocalState) => s[key]),
      distinctUntilChanged(),
      finShare()
    );
