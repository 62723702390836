import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ConfigService } from '../services';

// Match api.finnair.com, api-test.finnair.com etc
const URL_MATCHER = /api(-\w+)?\.finnair\.com/;

@Injectable({
  providedIn: 'root',
})
export class WithCredentialsInterceptor implements HttpInterceptor {
  constructor(private configService: ConfigService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.configService.cfg.forceWithCredentials || !this.isApiCall(req)) {
      return next.handle(req);
    }

    return next.handle(req.clone({ withCredentials: true }));
  }

  private isApiCall(req: HttpRequest<any>): boolean {
    return URL_MATCHER.test(req.url);
  }
}
