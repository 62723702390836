import { NgModule, Optional, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';

import { UiTranslateModule } from '@fcom/ui-translate';

import { IconsModule } from '../icons/icons.module';
import { ModalComponent } from './modal.component';
import { ClickOutsideDirectiveModule } from '../../directives/click-outside/click-outside-directive.module';
import { ButtonModule } from '../buttons/index';
import { TrapFocusModule } from '../../directives/trap-focus/trap-focus.module';

@NgModule({
  declarations: [ModalComponent],
  imports: [CommonModule, UiTranslateModule, IconsModule, ClickOutsideDirectiveModule, TrapFocusModule, ButtonModule],
  exports: [ModalComponent],
  providers: [],
})
export class ModalModule {
  constructor(@Optional() @Inject(ANIMATION_MODULE_TYPE) animations: string) {
    if (!animations) {
      throw new Error('You need to import the BrowserAnimationsModule or NoopAnimationsModule in your application!');
    }
  }
}
