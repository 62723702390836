import { Directive, PLATFORM_ID, Inject, ViewContainerRef, TemplateRef, OnInit, Input } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

/**
 * A structural directive that conditionally includes a template based on if it
 * is being rendered server-side or client-side.
 *
 * @example
 * <span *finClientOnly ></span>
 */
@Directive({ selector: '[finClientOnly]' })
export class ClientOnlyDirective<T = unknown> implements OnInit {
  private clientOnly = true;

  /**
   * The Boolean expression indicating if the directive should be applied.
   * If false the content will always be rendered.
   */
  @Input()
  set finClientOnly(condition: boolean) {
    if (!(condition === null || condition === undefined)) {
      this.clientOnly = condition;
    }
    this.updateView();
  }

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<T>,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  ngOnInit(): void {
    this.updateView();
  }

  updateView() {
    this.viewContainer.clear();

    if (!this.clientOnly || isPlatformBrowser(this.platform)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
