<div *ngIf="(isVisible$ | async) && (menuItems$ | async)?.length > 0" class="grey-25-bg" data-elastic-exclude>
  <div class="footer grid-col-12 grid-max-width">
    <div class="grey-25-bg col-f sm-col-1-12 md-col-1-12 ps-xxlarge-t border-bottom">
      <fcom-icon
        aria-hidden="true"
        class="nordic-blue-900-text finnair-emblem-medium"
        [category]="IconLibrary.CUSTOM"
        [name]="CustomIcon.FINNAIR_EMBLEM"
      ></fcom-icon>
      <div class="footer-section flex flex--space-between ms-small-t">
        <nav
          class="section-block"
          *ngFor="let item of (menuItems$ | async)?.slice(1); let i = index"
          attr.aria-labelledby="{{ 'footer-section-nav-' + i }}"
        >
          <h2
            [id]="'footer-section-nav-' + i"
            class="links-list-heading inline-block font-body-1 nordic-blue-900-text ms-xlarge-t"
          >
            {{ item.teaserTitle }}
          </h2>
          <ul *ngIf="item.items" role="list" class="ms-xlarge-y flex flex--space-between no-list-style flex--column">
            <li *ngFor="let child of item.items" role="listitem">
              <a
                class="block no-underline nordic-blue-900-text font-body-2 ps-xsmall-y footer-link inline-block"
                *ngIf="child.link"
                [finLink]="child.link"
                [finElemTracker]="'footer-left-section: ' + child.link"
                [finElemContext]="'footer'"
                [finElemType]="ElementTypes.LINK"
                [external]="child.external"
                [nofollow]="child.subjectTaxonomyTags?.includes(CmsTaxonomyGeneral.LINK_NOFOLLOW)"
                [attr.target]="child.openInNewTab ? '_blank' : ''"
              >
                <span class="valign-middle">{{ child.teaserTitle }}</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="ms-xlarge-y font-body-2 col-f sm-col-1-12 md-col-1-12 nordic-blue-900-text regular-type fill">
      <div>
        <span>{{ copyrightBanner$ | finMap: 'copyright' | async }} {{ year }}. </span>
        <span data-testid="footer-legal-disclaimer" *ngIf="showDisclaimer$ | async">{{
          copyrightBanner$ | finMap: 'legalDisclaimer' | async
        }}</span>
      </div>
      <span
        class="licence font-body-1 nordic-blue-900-text regular-type nowrap"
        [innerHTML]="copyrightBanner$ | finMap: 'licensesHTML' | async"
      ></span>
    </div>
  </div>
</div>
