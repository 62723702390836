import { Injectable } from '@angular/core';
import { makeStateKey, StateKey } from '@angular/platform-browser';

import { EMPTY, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { SentryLogger, StateTransferService, GlobalBookingTravelClass } from '@fcom/core';
import { ConfigService } from '@fcom/core/services/config/config.service';
import { InstantsearchService } from '@fcom/dapi/api/services';
import { DestinationSearchItem, DestinationSearchItemType } from '@fcom/destination-search/interfaces';
import { DestinationSearchService } from '@fcom/destination-search/services/destination-search.service';
import { flattenDestinations } from '@fcom/destination-search/utils/flatten';
import { isNotEmpty } from '@fcom/core/utils';
import { PageMetaService, TripType } from '@fcom/common';
import { finShare } from '@fcom/rx';

import { MarketingOffer } from '../../interfaces';
import { mapToMarketingOffers } from '../../utils';

@Injectable()
export class CheapestPriceForAllDestinationsService {
  private marketingOffers$: Observable<MarketingOffer[]>;
  private marketingOffersOneWay$: Observable<MarketingOffer[]>;
  private marketingOffersBothWay$: Observable<MarketingOffer[]>;
  private marketingOffersByOriginOneWay: { [origin: string]: Observable<MarketingOffer[]> } = {};
  private marketingOffersByOriginBothWay: { [origin: string]: Observable<MarketingOffer[]> } = {};

  static createStateKey = (originLocationCode: string, tripTypes: TripType[]): StateKey<MarketingOffer[]> =>
    makeStateKey<MarketingOffer[]>(`mos-${originLocationCode}${tripTypes.toString()}`);

  constructor(
    private configService: ConfigService,
    private sentryLogger: SentryLogger,
    private stateTransferService: StateTransferService,
    private destinationSearchService: DestinationSearchService,
    private instantSearchService: InstantsearchService,
    private pageMetaService: PageMetaService
  ) {
    if (!configService.cfg.showMarketingOffers) {
      this.marketingOffers$ = EMPTY;
      this.marketingOffersOneWay$ = EMPTY;
      this.marketingOffersBothWay$ = EMPTY;
      return;
    }
    this.marketingOffers$ = this.pageMetaService.defaultOriginLocationCode$.pipe(
      switchMap((origin) => this.getMarketingOffers(origin, [TripType.RETURN])),
      catchError((e: unknown) => this.handleError(e, [TripType.RETURN])),
      finShare()
    );

    this.marketingOffersOneWay$ = this.pageMetaService.defaultOriginLocationCode$.pipe(
      switchMap((origin) => this.getMarketingOffers(origin, [TripType.ONEWAY])),
      catchError((e: unknown) => this.handleError(e, [TripType.ONEWAY])),
      finShare()
    );

    this.marketingOffersBothWay$ = this.pageMetaService.defaultOriginLocationCode$.pipe(
      switchMap((origin) => this.getMarketingOffers(origin, [TripType.ONEWAY, TripType.RETURN])),
      catchError((e: unknown) => this.handleError(e, [TripType.ONEWAY, TripType.RETURN])),
      finShare()
    );
  }

  offerForSingleTrip(destination: string, oneWay = false, origin?: string): Observable<MarketingOffer> {
    const offers$ = origin
      ? this.getOffers(oneWay, origin)
      : oneWay
        ? this.marketingOffersOneWay$
        : this.marketingOffers$;
    return offers$.pipe(
      map((marketingOffers: MarketingOffer[]) =>
        marketingOffers.filter((marketingOffer) => marketingOffer.destination === destination)
      ),
      filter(isNotEmpty),
      map(
        (data: MarketingOffer[]): MarketingOffer => ({
          ...data[0],
          prices: data[0].prices.filter((price) =>
            oneWay ? price.price.tripType === TripType.ONEWAY : price.price.tripType === TripType.RETURN
          ),
        })
      )
    );
  }

  offerFor(destination: string, tripType: TripType): Observable<MarketingOffer> {
    return this.marketingOffersBothWay$.pipe(
      map((marketingOffers: MarketingOffer[]) =>
        marketingOffers.filter((marketingOffer) => marketingOffer.destination === destination)
      ),
      filter(isNotEmpty),
      map((data: MarketingOffer[]): MarketingOffer => {
        const targetPrice = data[0].prices.filter((offer) => offer.price.tripType === tripType);
        return { ...data[0], prices: targetPrice };
      })
    );
  }

  offers(): Observable<MarketingOffer[]> {
    return this.marketingOffers$;
  }

  oneWayOffers(selectedOriginLocationCode?: string): Observable<MarketingOffer[]> {
    return this.getOffers(true, selectedOriginLocationCode);
  }

  bothWayOffers(selectedOriginLocationCode?: string): Observable<MarketingOffer[]> {
    return this.getOffers(false, selectedOriginLocationCode);
  }

  private getOffers(isOneWay: boolean, originLocationCode?: string): Observable<MarketingOffer[]> {
    return this.pageMetaService.defaultOriginLocationCode$.pipe(
      switchMap((defaultOrigin) => {
        if (!originLocationCode || defaultOrigin === originLocationCode) {
          return isOneWay ? this.marketingOffersOneWay$ : this.marketingOffersBothWay$;
        }

        const offers = isOneWay ? this.marketingOffersByOriginOneWay : this.marketingOffersByOriginBothWay;

        if (offers[originLocationCode]) {
          return offers[originLocationCode];
        }

        offers[originLocationCode] = this.getMarketingOffers(
          originLocationCode,
          isOneWay ? [TripType.ONEWAY] : [TripType.ONEWAY, TripType.RETURN]
        );

        return offers[originLocationCode];
      })
    );
  }

  private getMarketingOffers(originLocationCode: string, tripTypes: TripType[]): Observable<MarketingOffer[]> {
    return this.stateTransferService.wrapToStateCache(
      CheapestPriceForAllDestinationsService.createStateKey(originLocationCode, tripTypes),
      () => {
        return this.destinationSearchService.destinationSearch().pipe(
          take(1),
          map((destinationItems: DestinationSearchItem[]) => {
            return flattenDestinations(destinationItems)
              .filter((item) => item.type === DestinationSearchItemType.DESTINATION && item.locationCode)
              .map((item) => item.locationCode);
          }),
          mergeMap((destinationLocationCodes: string[]) =>
            this.instantSearchService.getPricesForFlights(this.configService.cfg.instantSearchUrl, {
              departureLocationCodes: [originLocationCode],
              destinationLocationCodes: destinationLocationCodes.sort(),
              travelClasses: [
                GlobalBookingTravelClass.ECONOMY.toLowerCase(),
                GlobalBookingTravelClass.ECOPREMIUM.toLowerCase(),
                GlobalBookingTravelClass.BUSINESS.toLowerCase(),
              ],
              tripTypes,
            })
          ),
          map(mapToMarketingOffers)
        );
      }
    );
  }

  private handleError(error: unknown, tripTypes: string[]): Observable<[]> {
    this.sentryLogger.error(`Error getting destination ${tripTypes.toString()} offer data`, { error });
    return of([]);
  }
}
