import { createAction, props, union } from '@ngrx/store';

import { setLanguage } from '@fcom/core/actions/language.actions';
import { Location } from '@fcom/core-api/interfaces';

export const storeLocationData = createAction('[Locations] Store', props<{ location: Location }>());
export const removeLocationData = createAction('[Locations] Remove', props<{ location: Location }>());
export const cleanLocationSearch = createAction('[Locations] Clean search');
export const updateLocationSearch = createAction(
  '[Locations] Update search destination',
  props<{ searchData: Location[] }>()
);
export const updateLocationSearchInput = createAction('[Locations] Update search input', props<{ input: string }>());

const all = union({
  setLanguage,
  storeLocationData,
  removeLocationData,
  cleanLocationSearch,
  updateLocationSearch,
  updateLocationSearchInput,
});

export type LocationActionsUnion = typeof all;
