import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Store } from '@ngrx/store';

import { LanguageService } from '@fcom/ui-translate';
import { RootPaths } from '@fcom/core';
import { WindowRef } from '@fcom/core/providers';

import { leaveError } from '../../store/actions/error.actions';

export const enum ERROR_CTA_ACTIONS {
  NAVIGATE_TO_BOOKING = 'NAVIGATE_TO_BOOKING',
  NAVIGATE_TO_MANAGE_BOOKING = 'NAVIGATE_TO_MANAGE_BOOKING',
  NAVIGATE_TO_MANAGE_BOOKING_VIEW_PNR = 'NAVIGATE_TO_MANAGE_BOOKING_VIEW_PNR',
  REFRESH = 'REFRESH',
  NONE = 'NONE',
}

@Component({
  selector: 'fin-error',
  templateUrl: 'error.component.html',
})
export class ErrorComponent implements OnInit {
  @Input()
  title: string;
  @Input()
  subtitle: string;
  @Input()
  info: string;
  @Input()
  cta: string;
  @Input()
  showButton = true;
  @Input()
  iconName: SvgLibraryIcon = SvgLibraryIcon.PET;
  @Input()
  ctaAction: ERROR_CTA_ACTIONS = ERROR_CTA_ACTIONS.REFRESH;
  @Input()
  noGrid = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private languageService: LanguageService,
    private windowRef: WindowRef,
    private store$: Store
  ) {}

  ngOnInit(): void {
    // Use queryParams as mapping data to show as currently state object in the router is not updating when
    // skipLocationChange: true used
    const errorParams = this.route.snapshot.queryParams ?? {};

    this.iconName = errorParams['iconName'] ?? this.iconName;
    this.title = errorParams['title'] ?? this.title;
    this.subtitle = errorParams['subtitle'] ?? this.subtitle;
    this.info = errorParams['info'] ?? this.info;
    this.cta = errorParams['cta'] ?? this.cta;
    this.showButton = errorParams['showButton'] ? errorParams['showButton'] !== 'false' : this.showButton;
    this.noGrid = errorParams['noGrid'] ? errorParams['noGrid'] === 'true' : this.noGrid;

    const getAction = (action: ERROR_CTA_ACTIONS | string): ERROR_CTA_ACTIONS => {
      switch (action) {
        case ERROR_CTA_ACTIONS.NAVIGATE_TO_BOOKING:
          return ERROR_CTA_ACTIONS.NAVIGATE_TO_BOOKING;
        case ERROR_CTA_ACTIONS.NAVIGATE_TO_MANAGE_BOOKING:
          return ERROR_CTA_ACTIONS.NAVIGATE_TO_MANAGE_BOOKING;
        case ERROR_CTA_ACTIONS.NAVIGATE_TO_MANAGE_BOOKING_VIEW_PNR:
          return ERROR_CTA_ACTIONS.NAVIGATE_TO_MANAGE_BOOKING_VIEW_PNR;
        case ERROR_CTA_ACTIONS.REFRESH:
          return ERROR_CTA_ACTIONS.REFRESH;
        default:
          return ERROR_CTA_ACTIONS.NONE;
      }
    };
    this.ctaAction = errorParams['ctaAction'] ? getAction(errorParams['ctaAction']) : this.ctaAction;
  }

  handleButtonClick(): void {
    switch (this.ctaAction) {
      case ERROR_CTA_ACTIONS.NAVIGATE_TO_BOOKING:
        this.store$.dispatch(leaveError());
        this.router.navigateByUrl(`${this.languageService.langValue}/${RootPaths.BOOKING_ROOT}`);
        break;
      case ERROR_CTA_ACTIONS.NAVIGATE_TO_MANAGE_BOOKING:
        this.router.navigateByUrl(`${this.languageService.langValue}/${RootPaths.MANAGE_BOOKING_ROOT}`);
        break;
      case ERROR_CTA_ACTIONS.NAVIGATE_TO_MANAGE_BOOKING_VIEW_PNR:
        this.router.navigateByUrl(`${this.languageService.langValue}/${RootPaths.MANAGE_BOOKING_ROOT}/view`);
        break;
      case ERROR_CTA_ACTIONS.REFRESH:
        this.windowRef.nativeWindow.location.reload();
        break;
      default:
      // Nothing
    }
  }
}
