import { createSelector, createFeatureSelector } from '@ngrx/store';

import { CommonState, CommonFeatureState } from '../store.interface';
import * as fromNotification from '../reducers/notification.reducer';
import { COMMON_FEATURE_KEY } from '../constants';

const selectCommonState = createFeatureSelector<CommonFeatureState, CommonState>(COMMON_FEATURE_KEY);
const selectNotificationState = createSelector(selectCommonState, (state: CommonState) => state.notifications);

export const getViewedNotifications = createSelector(selectNotificationState, fromNotification.getViewedNotifications);
