import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, map, withLatestFrom } from 'rxjs/operators';

import { ConfigService, SentryLogger } from '@fcom/core/services';
import { AvailabilityEntry, LowestPriceOfPeriod } from '@fcom/dapi/api/models';
import { finShare, retryWithBackoff } from '@fcom/rx';
import { LocalDate, mapErrorForSentry } from '@fcom/core';
import { InstantsearchService } from '@fcom/dapi/api/services';
import { LanguageService } from '@fcom/ui-translate';

import {
  HistogramBar,
  CalendarPrices,
  InstantSearchBaseParams,
  InstantSearchFollowingMonthParams,
  InstantSearchFullYearParams,
  PriceCalendarParams,
  TripType,
} from '../../interfaces';
import { createHistogramData, mapInstantSearchPricesToCalendarPrices } from '../../utils';

const DAYS_YEAR = 360;
const DAYS_MONTH = 30;

@Injectable()
export class PriceCalendarService {
  static NUMBER_OF_RETRIES = 2;

  constructor(
    private languageService: LanguageService,
    private configService: ConfigService,
    private instantsearchService: InstantsearchService,
    private sentryLogger: SentryLogger
  ) {}

  getPricesForFullYear(
    params: PriceCalendarParams
  ): Observable<{ fullYear: CalendarPrices; histogram: HistogramBar[]; availability?: AvailabilityEntry[] }> {
    const fullYearParams = this.convertToFullYearParams(params);
    return this.instantsearchService.getPricesForPeriod(this.configService.cfg.instantSearchUrl, fullYearParams).pipe(
      withLatestFrom(this.languageService.translate('date')),
      map(([response, dateTranslations]: [LowestPriceOfPeriod, Record<string, string>]) => {
        const fullYear = mapInstantSearchPricesToCalendarPrices(response);
        const histogram = createHistogramData(response, Object.keys(fullYear), dateTranslations);

        return {
          fullYear,
          histogram,
          availability: response?.availability,
        };
      }),
      retryWithBackoff(PriceCalendarService.NUMBER_OF_RETRIES),
      catchError((err: unknown) => {
        this.sentryLogger.error('Error finding prices for period', {
          error: mapErrorForSentry(err),
        });
        return of({ fullYear: {}, histogram: [] });
      }),
      finShare()
    );
  }

  getPricesForFollowingMonth(params: PriceCalendarParams, departureDate: LocalDate): Observable<CalendarPrices> {
    const followingMonthParams = this.convertToFollowingMonthParams(params, departureDate);

    return this.instantsearchService
      .getPricesForPeriodWithFixedDepartureDate(this.configService.cfg.instantSearchUrl, followingMonthParams)
      .pipe(
        map((response: LowestPriceOfPeriod) => mapInstantSearchPricesToCalendarPrices(response)),
        retryWithBackoff(PriceCalendarService.NUMBER_OF_RETRIES),
        catchError((err: unknown) => {
          this.sentryLogger.error('Error finding prices for following month', {
            error: mapErrorForSentry(err),
          });
          return of({});
        }),
        finShare()
      );
  }

  private getCommonParams({
    origin,
    destination,
    paxAmount,
  }: PriceCalendarParams): Omit<InstantSearchBaseParams, 'numberOfDays'> {
    return {
      departureLocationCode: origin,
      destinationLocationCode: destination,
      adults: paxAmount.adults + paxAmount.c15s,
      children: paxAmount.children,
      infants: paxAmount.infants,
      locale: this.languageService.localeValue,
    };
  }

  private convertToFollowingMonthParams(
    params: PriceCalendarParams,
    departureDate: LocalDate
  ): InstantSearchFollowingMonthParams {
    const commonParams = this.getCommonParams(params);
    return { ...commonParams, numberOfDays: DAYS_MONTH, departureDate: departureDate.id };
  }

  private convertToFullYearParams(params: PriceCalendarParams): InstantSearchFullYearParams {
    const commonParams = this.getCommonParams(params);
    return {
      ...commonParams,
      oneway: params.tripType === TripType.ONEWAY,
      startDate: LocalDate.now().id,
      numberOfDays: DAYS_YEAR,
    };
  }
}
