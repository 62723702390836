export enum IconButtonSize {
  SMALL = 'icon-button-small',
  MEDIUM = 'icon-button-medium',
  LARGE = 'icon-button-large',
}

export enum IconButtonTheme {
  LIGHT_PRIMARY_CTA_HEATHER = 'light-primary-cta-heather',
  LIGHT_PRIMARY_NORDIC_BLUE = 'light-primary-nordic-blue',
  LIGHT_SECONDARY_OUTLINE = 'light-secondary-outline',
  LIGHT_TERTIARY_GHOST = 'light-tertiary-ghost',
  DARK_PRIMARY_NORDIC_BLUE = 'dark-primary-nordic-blue',
  DARK_PRIMARY_CTA_HEATHER = 'dark-primary-cta-heather',
  DARK_SECONDARY_OUTLINE = 'dark-secondary-outline',
  DARK_TERTIARY_GHOST = 'dark-tertiary-ghost',
}
