import { Injectable, NgZone } from '@angular/core';

import { Observable } from 'rxjs';
import { switchMap, startWith, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { add, LocalDate, startOf, TimeUnit, TzDate } from '@fcom/core/utils';
import { finShare } from '@fcom/rx';

import { AppState, TimeData } from '../../interfaces';
import { serverTime } from '../../selectors';

const INTERVAL: number = 60 * 1000; // 1min

@Injectable()
export class TimeService {
  timer$: Observable<number>;
  currentTime$: Observable<any>;
  listOfTimeout: Array<number> = [];
  listOfInterval: Array<number> = [];

  constructor(
    private store$: Store<AppState>,
    private ngZone: NgZone
  ) {
    const serverTimeObs$ = this.store$.pipe(serverTime(), finShare());

    this.timer$ = this.tickAndReplayFullMinuteTimestamp(serverTimeObs$);

    this.currentTime$ = serverTimeObs$.pipe(map((data: TimeData) => this.calcTime(data.timeOffset)));
  }

  /**
   * Returns the timestamp for full minutes. Emits when the minute should
   * change.
   * @return {Observable<number>}
   */
  get currentMinuteTimestamp(): Observable<number> {
    return this.timer$;
  }

  /**
   * Emits and replays a single value with the current local date
   * @return {Observable<LocalDate>}
   */
  get currentLocalDate(): Observable<LocalDate> {
    return this.currentTime.pipe(take(1), map(TzDate.fromMillis), map(LocalDate.fromTzDate), finShare());
  }

  /**
   * A non-replayed stream
   * @return {Observable<number>}
   */
  get currentTime(): Observable<number> {
    return this.currentTime$;
  }

  clearTimers(): void {
    this.listOfInterval.forEach((i) => {
      window.clearInterval(i); // clear all the timeouts
    });
    this.listOfTimeout.forEach((i) => {
      window.clearTimeout(i); // clear all the timeouts
    });

    this.listOfInterval = [];
    this.listOfTimeout = [];
  }

  private getMsToFullMinute(serverMillis: number): number {
    return startOf(add(new Date(serverMillis), 1, TimeUnit.minute), TimeUnit.minute).getTime() - serverMillis;
  }

  private calcTime(offset: number): number {
    return Date.now() + offset;
  }

  private timerOutsideAngular(startAfterMs: number, intervalMs: number): Observable<void> {
    return new Observable<void>((observer) => {
      // We need to run interval outside angular zone. So protractor will not wait indefinitely.
      let timeout: any;
      let interval: any;

      this.ngZone.runOutsideAngular(() => {
        const nextValue = () => this.ngZone.run(() => observer.next());
        timeout = setTimeout(() => {
          interval = setInterval(nextValue, intervalMs);
          this.listOfInterval.push(interval);
          nextValue();
        }, startAfterMs);
        this.listOfTimeout.push(timeout);
      });

      return () => {
        clearTimeout(timeout);
        clearInterval(interval);
      };
    });
  }

  private tickAndReplayFullMinuteTimestamp(verifiedTimeOnStartup$: Observable<TimeData>): Observable<number> {
    return verifiedTimeOnStartup$.pipe(
      switchMap((timeData: TimeData) =>
        this.timerOutsideAngular(this.getMsToFullMinute(timeData.serverMillisFromEpoch), INTERVAL).pipe(
          startWith(undefined as any),
          map(() => this.calcTime(timeData.timeOffset))
        )
      ),
      finShare()
    );
  }
}
