/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { catchErrorAsJson } from '@fcom/rx';
import { DapiBaseService } from '../dapi-base-service';
import { DapiHttpResponse } from '../dapi-http-response';
import { DapiRequestBuilder } from '../dapi-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FinnairAirBoundsChangeRequest } from '../models/finnair-air-bounds-change-request';
import { FinnairOrder } from '../models/finnair-order';


/**
 * Order change endpoint
 */
@Injectable({
  providedIn: 'root',
})
export class OrderChangeService extends DapiBaseService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  /**
   * Path part for operation postOrderChanges
   */
  static readonly PostOrderChangesPath = '/api/v2/order-change/{orderId}';

  /**
   * postOrderChanges.
   *
   * POST new itinerary selection during voluntary change flow
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postOrderChanges()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOrderChanges$Response(rootUrl: string, params: {
    orderId: string;
    hash?: string;
    locale?: string;
    body: FinnairAirBoundsChangeRequest
  },
  context?: HttpContext

): Observable<DapiHttpResponse<void>> {
    const rb = new DapiRequestBuilder(rootUrl, OrderChangeService.PostOrderChangesPath, 'post');

    if (params) {
      rb.path('orderId', params.orderId, {});
      rb.query('hash', params.hash, {});
      rb.query('locale', params.locale, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as DapiHttpResponse<void>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * postOrderChanges.
   *
   * POST new itinerary selection during voluntary change flow
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postOrderChanges$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postOrderChanges(rootUrl: string, params: {
    orderId: string;
    hash?: string;
    locale?: string;
    body: FinnairAirBoundsChangeRequest
  },
  context?: HttpContext

): Observable<void> {
    return this.postOrderChanges$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getOrderChanges
   */
  static readonly GetOrderChangesPath = '/api/v2/order-change/{orderId}/{orderChangeId}';

  /**
   * getOrderChanges.
   *
   * GET temporary PNR created during the change flow
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOrderChanges()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderChanges$Response(rootUrl: string, params: {
    orderId: string;
    orderChangeId: string;
    locale?: string;
    hash?: string;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<FinnairOrder>> {
    const rb = new DapiRequestBuilder(rootUrl, OrderChangeService.GetOrderChangesPath, 'get');

    if (params) {
      rb.path('orderId', params.orderId, {});
      rb.path('orderChangeId', params.orderChangeId, {});
      rb.query('locale', params.locale, {});
      rb.query('hash', params.hash, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<FinnairOrder>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * getOrderChanges.
   *
   * GET temporary PNR created during the change flow
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOrderChanges$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOrderChanges(rootUrl: string, params: {
    orderId: string;
    orderChangeId: string;
    locale?: string;
    hash?: string;
  },
  context?: HttpContext

): Observable<FinnairOrder> {
    return this.getOrderChanges$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<FinnairOrder>) => r.body as FinnairOrder)
    );
  }

}
