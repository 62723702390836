<ng-template #cmsItemLoop let-items="items" let-template="template">
  <ng-container *ngFor="let childContent of items">
    <ng-container
      *ngTemplateOutlet="
        cmsTemplate;
        context: {
          content: childContent,
          contentObservable: childContent | finToObservable,
          template: template,
        }
      "
    ></ng-container>
  </ng-container>
</ng-template>

<ng-template #cmsTemplate let-content="content" let-contentObservable="contentObservable" let-template="template">
  <ng-container [ngSwitch]="content.contentType">
    <ng-container *ngSwitchCase="DOCTYPE.CMTeaser">
      <ng-container [ngSwitch]="content.viewType">
        <ng-container *finShowForLoginStatus="content.showForLoginStatus">
          <ng-container *finShowForProfileTiers="content.showForProfileTiers">
            <cms-text-on-image
              class="col-1-12 md-col-5-12 pr-xsmall-b"
              *ngSwitchCase="VIEWTYPE.TEXT_ON_IMAGE"
              [content$]="contentObservable"
            ></cms-text-on-image>
            <cms-cta-with-image-and-text
              class="mr-large-b"
              [ngClass]="content.gridClass || 'col-1-12'"
              *ngSwitchCase="VIEWTYPE.CTA_WITH_IMAGE_AND_TEXT"
              [content$]="contentObservable"
            ></cms-cta-with-image-and-text>
            <cms-cta-big-link
              class="section-two-thirds-right"
              *ngSwitchCase="VIEWTYPE.CTA_BIG_LINK"
              [content$]="contentObservable"
            ></cms-cta-big-link>
            <cms-cta-link
              class="section-two-thirds-right"
              *ngSwitchCase="VIEWTYPE.CTA_LINK"
              [content$]="contentObservable"
            ></cms-cta-link>
            <cms-link-block
              [class]="content.gridClass || 'col-1-12 md-col-5-12'"
              *ngSwitchCase="VIEWTYPE.LINK_BLOCK"
              [content$]="contentObservable"
            ></cms-link-block>
            <cms-text-link
              [class]="content.gridClass || 'col-1-12 md-col-5-12'"
              *ngSwitchCase="VIEWTYPE.TEXT_LINK"
              [content$]="contentObservable"
            ></cms-text-link>
            <cms-destination-hero
              class="col-1-12 md-col-5-12"
              *ngSwitchCase="VIEWTYPE.HERO"
              [content$]="contentObservable"
            ></cms-destination-hero>
            <cms-full-width-booking-widget
              *ngSwitchCase="VIEWTYPE.FULL_WIDTH_BOOKING_WIDGET"
              [backgroundImage]="content?.picture || content?.destination?.picture"
              [locations]="[{ destinationLocationCode: content?.destination?.defaultLocation?.locationCode }]"
              [enableAward]="content?.localSettings?.enableAward"
              [enablePromoCode]="content?.localSettings?.enablePromoCode"
              [enableAurinkomatkat]="content?.localSettings?.enableAurinkomatkat"
            ></cms-full-width-booking-widget>
            <cms-horizontal-title
              [ngClass]="content.gridClass || 'col-1-12 md-col-2-11'"
              *ngSwitchCase="VIEWTYPE.HORIZONTAL_TITLE"
              [content$]="contentObservable"
            ></cms-horizontal-title>
            <cms-disclaimer
              class="col-1-12 md-col-5-12 pr-xsmall-b"
              *ngSwitchCase="VIEWTYPE.DISCLAIMER"
              [content$]="contentObservable"
            ></cms-disclaimer>
            <ng-container *ngSwitchCase="VIEWTYPE.ITEMS">
              <ng-container
                *ngTemplateOutlet="cmsItemLoop; context: { items: content.items, template: template }"
              ></ng-container>
            </ng-container>
            <cms-teaser
              [class]="'section-full-width decorated-heading mr-xlarge-t'"
              *ngSwitchCase="VIEWTYPE.DECORATED_HEADING"
              [content$]="contentObservable"
            ></cms-teaser>
            <cms-teaser
              class="mr-small-t"
              [ngClass]="content.gridClass || 'col-1-12'"
              [headerLevel]="1"
              *ngSwitchCase="VIEWTYPE.MAIN_ARTICLE"
              [content$]="contentObservable"
            ></cms-teaser>
            <cms-back-link
              [content$]="contentObservable"
              [ngClass]="content.gridClass || 'col-1-12'"
              *ngSwitchCase="VIEWTYPE.BACK_LINK"
              [content$]="contentObservable"
            ></cms-back-link>
            <fin-render-lazy-component
              *ngSwitchCase="VIEWTYPE.RETRIEVE_PAGE_CONTENT"
              [viewType]="VIEWTYPE.RETRIEVE_PAGE_CONTENT"
              [class]="'col-1-12'"
            ></fin-render-lazy-component>
            <cms-teaser
              class="mr-small-t"
              [ngClass]="content.gridClass || 'col-1-12 md-col-5-12'"
              *ngSwitchDefault
              [content$]="contentObservable"
            ></cms-teaser>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DOCTYPE.FIMenuFoodItem">
      <cms-teaser [class]="content.gridClass || 'col-1-12 md-col-5-12'" [content$]="contentObservable"></cms-teaser>
    </ng-container>
    <ng-container *ngSwitchCase="DOCTYPE.CMArticle">
      <ng-container [ngSwitch]="content.viewType">
        <ng-container *finShowForLoginStatus="content.showForLoginStatus">
          <ng-container *finShowForProfileTiers="content.showForProfileTiers">
            <cms-travel-guide
              [class]="content.gridClass || 'col-1-12'"
              *ngSwitchCase="VIEWTYPE.TRAVEL_GUIDE"
              [content$]="contentObservable"
            ></cms-travel-guide>
            <ng-container *ngSwitchCase="VIEWTYPE.COLLAPSE_RELATED_CONTENT">
              <cms-rich-text
                [class]="content.gridClass || 'col-1-12 md-col-5-12'"
                [content$]="contentObservable"
              ></cms-rich-text>
              <cms-show-more>
                <ng-container
                  *ngTemplateOutlet="cmsItemLoop; context: { items: content.media, template: template }"
                ></ng-container>
                <ng-container
                  *ngTemplateOutlet="cmsItemLoop; context: { items: content.related, template: template }"
                ></ng-container>
              </cms-show-more>
            </ng-container>
            <cms-icon-with-text
              *ngSwitchCase="VIEWTYPE.ICON_WITH_TEXT"
              [class]="content.gridClass || 'col-1-12 md-col-5-12'"
              [content$]="contentObservable"
            ></cms-icon-with-text>
            <cms-text-with-side-image
              *ngSwitchCase="VIEWTYPE.TEXT_WITH_SIDE_IMAGE"
              [class]="content.gridClass || 'col-1-12 md-col-2-11'"
              [content$]="contentObservable"
            ></cms-text-with-side-image>
            <ng-container *ngSwitchCase="VIEWTYPE.MAIN_ARTICLE">
              <cms-rich-text
                class="mr-small-t"
                [ngClass]="content.gridClass || 'col-1-12'"
                [headerLevel]="1"
                [content$]="contentObservable"
              ></cms-rich-text>
              <ng-container
                *ngTemplateOutlet="cmsItemLoop; context: { items: content.media, template: template }"
              ></ng-container>
              <ng-container
                *ngTemplateOutlet="cmsItemLoop; context: { items: content.related, template: template }"
              ></ng-container>
            </ng-container>
            <fin-article-bluewings
              *ngSwitchCase="VIEWTYPE.BLUEWINGS_ARTICLE"
              [content]="content"
              class="col-1-12 md-col-1-8 ms-xsmall-b mr-small-t"
            ></fin-article-bluewings>
            <fin-article-child
              *ngSwitchCase="VIEWTYPE.CHILD_ARTICLE"
              [content]="content"
              class="col-1-12 md-col-1-8 ms-xsmall-b"
            ></fin-article-child>
            <fin-render-lazy-component
              *ngSwitchCase="VIEWTYPE.DESTINATION_SEARCH_HERO"
              [viewType]="content.viewType"
              [content]="content"
              [ngClass]="content.gridClass || 'col-1-12'"
              class="fill block grey-25-bg"
            ></fin-render-lazy-component>
            <ng-container *ngSwitchDefault>
              <cms-rich-text
                class="mr-small-t"
                [ngClass]="content.gridClass || 'col-1-12 md-col-5-12'"
                [content$]="contentObservable"
              ></cms-rich-text>
              <ng-container
                *ngTemplateOutlet="cmsItemLoop; context: { items: content.media, template: template }"
              ></ng-container>
              <ng-container
                *ngTemplateOutlet="cmsItemLoop; context: { items: content.related, template: template }"
              ></ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DOCTYPE.FICategory">
      <ng-container [ngSwitch]="content.viewType">
        <ng-container *ngSwitchDefault>
          <fin-destination-category-hero
            class="col-1-12 md-col-1-12 mr-small-b"
            [content]="content"
          ></fin-destination-category-hero>
          <cms-category
            *ngFor="let childContent of content.children"
            [content$]="childContent | finToObservable"
          ></cms-category>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DOCTYPE.CMExternalLink">
      <ng-container [ngSwitch]="content.viewType">
        <cms-external-link
          class="col-1-12 md-col-5-12 mr-medium-t mr-small-b"
          *ngSwitchDefault
          [content$]="contentObservable"
        ></cms-external-link>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DOCTYPE.CMCollection">
      <ng-container [ngSwitch]="content.viewType">
        <ng-container *finShowForLoginStatus="content.showForLoginStatus">
          <ng-container *finShowForProfileTiers="content.showForProfileTiers">
            <cms-article-collection
              *ngSwitchCase="VIEWTYPE.ARTICLE_COLLECTION"
              [content$]="contentObservable"
              [ngClass]="content.gridClass || 'col-1-12 md-col-2-10'"
            >
            </cms-article-collection>
            <cms-offers-grid
              class="col-1-12 md-col-2-11"
              *ngSwitchCase="VIEWTYPE.OFFERS_GRID"
              [content]="content"
            ></cms-offers-grid>
            <fin-trending-destinations-grid
              class="col-1-12 md-col-2-11"
              *ngSwitchCase="VIEWTYPE.TRENDING_DESTINATIONS"
              [content]="content"
            ></fin-trending-destinations-grid>
            <cms-show-more class="col-1-12 md-col-5-12" *ngSwitchCase="VIEWTYPE.SHOW_MORE">
              <ng-container
                *ngTemplateOutlet="cmsItemLoop; context: { items: content.items, template: template }"
              ></ng-container>
            </cms-show-more>
            <cms-badge
              class="col-1-12 md-col-5-12"
              *ngSwitchCase="VIEWTYPE.BADGE"
              [content$]="contentObservable"
            ></cms-badge>
            <cms-accordion
              [class]="content.gridClass || 'col-1-12 md-col-5-12'"
              *ngSwitchCase="VIEWTYPE.ACCORDION"
              [content$]="contentObservable"
            ></cms-accordion>
            <cms-accordion
              [class]="content.gridClass || 'col-1-12 md-col-5-12'"
              *ngSwitchCase="VIEWTYPE.ACCORDION_WITH_PICTURES"
              [content$]="contentObservable"
              [withPictures]="true"
            ></cms-accordion>
            <cms-conditional-accordion
              [class]="content.gridClass || 'col-1-12 md-col-5-12'"
              *ngSwitchCase="VIEWTYPE.CONDITIONAL_ACCORDION"
              [content$]="contentObservable"
            ></cms-conditional-accordion>
            <cms-content-switcher-dropdown
              class="mr-large-t"
              [ngClass]="content.gridClass || 'col-1-12'"
              *ngSwitchCase="VIEWTYPE.CONTENT_DROPDOWN"
              [content$]="contentObservable"
            ></cms-content-switcher-dropdown>
            <cms-content-switcher-tabs
              class="mr-large-t"
              [ngClass]="content.gridClass || 'col-1-12 md-col-5-12'"
              *ngSwitchCase="VIEWTYPE.CONTENT_TABS"
              [content$]="contentObservable"
            ></cms-content-switcher-tabs>
            <cms-unique-selling-point
              *ngSwitchCase="VIEWTYPE.UNIQUE_SELLING_POINT"
              class="col-1-12 md-col-1-12"
              [content$]="contentObservable"
            ></cms-unique-selling-point>
            <!-- Rendered separately inside BookingEntryComponent - until someone figures out how to render these there -->
            <ng-container *ngSwitchCase="VIEWTYPE.FRONT_PAGE_DISRUPTIONS"></ng-container>
            <ng-container *ngSwitchCase="VIEWTYPE.DISRUPTIONS"></ng-container>
            <cms-important-links
              *ngSwitchCase="VIEWTYPE.FRONT_PAGE_LINKS"
              [class]="content.gridClass || 'col-1-12 md-col-5-12'"
              [content$]="contentObservable"
            ></cms-important-links>
            <cms-discover-now
              class="col-1-12 md-col-2-11"
              *ngSwitchCase="VIEWTYPE.FRONT_PAGE_ARTICLES"
              [content$]="contentObservable"
            ></cms-discover-now>
            <cms-link-list-with-icons
              [class]="content.gridClass || 'col-1-12 md-col-9-12'"
              *ngSwitchCase="VIEWTYPE.LINK_LIST_WITH_ICONS"
              [content$]="contentObservable"
            ></cms-link-list-with-icons>
            <cms-articles-with-icons
              [class]="content.gridClass"
              *ngSwitchCase="VIEWTYPE.ARTICLES_WITH_ICONS"
              [content$]="contentObservable"
            ></cms-articles-with-icons>
            <cms-descriptive-link-list
              [class]="content.gridClass || 'col-1-12'"
              *ngSwitchCase="VIEWTYPE.DESCRIPTIVE_LINK_LIST"
              [content$]="contentObservable"
            ></cms-descriptive-link-list>
            <cms-list-with-icons
              [class]="content.gridClass || 'col-1-12 md-col-5-12'"
              *ngSwitchCase="VIEWTYPE.LIST_WITH_ICONS"
              [content$]="contentObservable"
              [useTeaserTitle]="true"
            ></cms-list-with-icons>
            <cms-list-with-icons
              [class]="content.gridClass || 'col-1-12 md-col-5-12'"
              *ngSwitchCase="VIEWTYPE.LIST_WITH_ICONS_TEASERTEXT"
              [content$]="contentObservable"
              [useTeaserTitle]="false"
            ></cms-list-with-icons>
            <cms-list-with-heading
              [class]="content.gridClass || 'col-1-12 md-col-2-11'"
              *ngSwitchCase="VIEWTYPE.LIST_WITH_HEADING"
              [content$]="contentObservable"
            ></cms-list-with-heading>
            <fin-editorial-category
              [class]="content.gridClass || 'col-1-12 md-col-span-6'"
              [editorialCategory]="content"
              *ngSwitchCase="VIEWTYPE.HIGHLIGHT_LIST"
            ></fin-editorial-category>
            <cms-arrow-link-list
              [class]="content.gridClass || 'col-1-12 mr-xlarge-b'"
              [content$]="contentObservable"
              *ngSwitchCase="VIEWTYPE.COLLECTION_ARROW_LINKS"
            ></cms-arrow-link-list>
            <fin-teaser-list
              class="col-1-12 mr-xlarge-b"
              [content$]="contentObservable"
              *ngSwitchCase="VIEWTYPE.TEASER_LIST"
            ></fin-teaser-list>
            <fin-informational-teaser
              class="col-1-12"
              [informationalTeaser]="content"
              *ngSwitchCase="VIEWTYPE.INFORMATIONAL_TEASER"
            ></fin-informational-teaser>
            <fin-radio-list
              class="col-1-12"
              [content$]="contentObservable"
              *ngSwitchCase="VIEWTYPE.RADIO_COLLECTION"
            ></fin-radio-list>
            <ng-container *ngSwitchDefault>
              <h2 class="col-1-12 md-col-5-12" *ngIf="content.teaserTitle" class="nordic-blue-900-text mr-xxsmall-x">
                {{ content.teaserTitle }}
              </h2>
              <div *ngIf="content.gridClass; else cmCollectionCmsItemLoop" [class]="content.gridClass">
                <ng-container *ngTemplateOutlet="cmCollectionCmsItemLoop"></ng-container>
              </div>
              <ng-template #cmCollectionCmsItemLoop>
                <ng-container
                  *ngTemplateOutlet="cmsItemLoop; context: { items: content.items, template: template }"
                ></ng-container>
              </ng-template>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DOCTYPE.CMPersonalized">
      <ng-container
        *ngTemplateOutlet="cmsItemLoop; context: { items: content.items, template: template }"
      ></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DOCTYPE.CMQueryList">
      <ng-container [ngSwitch]="content.viewType">
        <fin-teaser-list
          class="mr-xlarge-b"
          [class]="content.gridClass || 'col-1-12'"
          [content$]="contentObservable"
          *ngSwitchCase="VIEWTYPE.TEASER_LIST"
        ></fin-teaser-list>
        <fin-editorial-category
          [class]="content.gridClass || 'col-1-12'"
          [editorialCategory]="content"
          *ngSwitchDefault
        ></fin-editorial-category>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DOCTYPE.CMRelated">
      <ng-container [ngSwitch]="content.viewType">
        <ng-container *ngSwitchCase="VIEWTYPE.TAG_LIST">
          <div class="col-1-12 ms-large-b">
            <fin-tag *ngFor="let tag of content.items" [tagUrl]="tag.url" [tagTitle]="tag.title"></fin-tag>
          </div>
        </ng-container>
        <fin-related-teaser
          class="col-1-12 md-col-1-8 mr-xlarge-b"
          *ngSwitchCase="VIEWTYPE.RELATED_LIST"
          [relatedTeaser]="content"
        ></fin-related-teaser>
        <cms-cmrelated
          class="col-1-12 mr-xlarge-b"
          [content]="content"
          *ngSwitchCase="VIEWTYPE.TEASER_LIST"
        ></cms-cmrelated>
        <fin-editorial-category
          [class]="content.gridClass || 'col-1-12'"
          [editorialCategory]="content"
          *ngSwitchDefault
        ></fin-editorial-category>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DOCTYPE.FIOffer">
      <ng-container [ngSwitch]="content.viewType">
        <!-- Do not render FrontPageOffer. Offer is rendered as HeroOffer inside BookingWidget. -->
        <ng-container *ngSwitchCase="VIEWTYPE.FRONT_PAGE_OFFER"></ng-container>
        <cms-teaser
          [class]="content.gridClass || 'col-1-12 md-col-5-12'"
          *ngSwitchDefault
          [content$]="contentObservable"
        ></cms-teaser>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DOCTYPE.CMPlaceholder">
      <ng-container [ngSwitch]="content.viewType">
        <ng-container *finShowForLoginStatus="content.showForLoginStatus">
          <ng-container *finShowForProfileTiers="content.showForProfileTiers">
            <!-- hr is legacy and should not be used in future content, still present in old content -->
            <hr *ngSwitchCase="VIEWTYPE.HORIZONTAL_RULER" class="pr-xxsmall-y mr-xxsmall-x mr-large-y" />
            <cms-ond-marketing-offers
              [class]="content.gridClass || 'col-1-12 md-col-5-12'"
              *ngSwitchCase="VIEWTYPE.OND_OFFERS"
              [content$]="contentObservable"
            ></cms-ond-marketing-offers>
            <cms-ond-marketing-offers-list
              [class]="content.gridClass || 'col-1-12 md-col-5-12'"
              *ngSwitchCase="VIEWTYPE.OND_OFFERS_LIST"
              [content$]="contentObservable"
            ></cms-ond-marketing-offers-list>

            <cms-ond-marketing-cross-linking
              [class]="content.gridClass || 'col-1-12 md-col-5-12'"
              *ngSwitchCase="VIEWTYPE.OND_CROSSLINKING"
              [content$]="contentObservable"
            ></cms-ond-marketing-cross-linking>
            <fin-render-lazy-component
              *ngSwitchCase="VIEWTYPE.DESTINATIONS_SEARCH"
              [viewType]="content.viewType"
              [content]="content"
              [content$]="contentObservable"
              [template]="template"
              class="fill block grey-25-bg"
            ></fin-render-lazy-component>
            <fin-travel-restrictions
              *ngSwitchCase="VIEWTYPE.TRAVEL_RESTRICTIONS"
              class="fill block mr-small-t col-1-12 md-col-2-11"
            ></fin-travel-restrictions>
            <fin-newsletter-signup-form
              *ngSwitchCase="VIEWTYPE.NEWSLETTER_SIGNUP_FORM"
              [class]="content.gridClass || 'grid-col-12 grid-max-width no-row-gap'"
            ></fin-newsletter-signup-form>
            <fin-render-lazy-component
              *ngSwitchCase="
                [
                  VIEWTYPE.CASE_ATTACHMENT_FORM,
                  VIEWTYPE.CORPORATE_CONTACT_FORM,
                  VIEWTYPE.GIFT_CARD_INFORMATION_FORM,
                  VIEWTYPE.JOIN_CORPORATE_FORM,
                  VIEWTYPE.SAF_DOWNLOADABLE_FORM,
                  VIEWTYPE.VAT_RECEIPT_FORM,
                ].includes(content.viewType)
                  ? content.viewType
                  : ''
              "
              [template]="template"
              [viewType]="content.viewType"
              class="col-1-12 md-col-2-9 xl-col-2-7"
            ></fin-render-lazy-component>
            <fin-render-lazy-component
              *ngSwitchDefault
              [viewType]="content.viewType"
              [class]="content.gridClass || 'col-1-12 md-col-5-12'"
            ></fin-render-lazy-component>
          </ng-container>
        </ng-container>
        <cms-book-now
          class="col-1-12 md-col-1-8 article-offer"
          *ngSwitchCase="VIEWTYPE.LOCATION_BANNER"
          [locationCode]="content.locationCode"
        ></cms-book-now>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="DOCTYPE.CMVideo">
      <fin-video
        [class]="content.gridClass || 'col-1-12 md-col-5-12 mr-xlarge-b'"
        [videoUrl$]="contentObservable | finMap: 'dataUrl'"
        [videoTitle$]="contentObservable | finMap: 'teaserTitle'"
      ></fin-video>
    </ng-container>
  </ng-container>
</ng-template>

<!-- ROOT -->
<ng-container [ngSwitch]="pageViewType$ | async">
  <ng-container *ngSwitchCase="'section-page-with-title'">
    <div class="top-heading col-1-12 mr-medium-b" *ngIf="(showSideTitle$ | async) === false && (title$ | async)">
      <h1 class="main-page-heading">
        {{ title$ | async }}
      </h1>
    </div>
    <!-- SECTION NAVI -->
    <fin-section-navi
      [title]="title$ | async"
      [showTitle$]="showSideTitle$"
      class="col-1-12 md-col-1-4"
      [class.disruptions-visible]="disruptions$ | async"
    ></fin-section-navi>
    <cms-disruptions
      *ngIf="disruptions$ | async"
      class="col-1-12 md-col-5-12 ms-xxsmall-y"
      [showLevelOne]="true"
      [disruptions]="disruptions$ | async"
    ></cms-disruptions>
    <ng-container *ngFor="let content of template$ | finMap: 'main' | async">
      <ng-container
        *ngTemplateOutlet="
          cmsTemplate;
          context: {
            content: content,
            contentObservable: content | finToObservable,
            template: template$ | async,
          }
        "
      ></ng-container>
    </ng-container>
    <!-- /SECTION NAVI -->
  </ng-container>
  <ng-container *ngSwitchDefault>
    <!-- DEFAULT -->
    <cms-disruptions
      *ngIf="disruptions$ | async"
      class="col-1-12 mr-xxsmall-b mr-small-t"
      [showLevelOne]="true"
      [disruptions]="disruptions$ | async"
    ></cms-disruptions>
    <ng-container *ngFor="let content of template$ | finMap: 'main' | async">
      <ng-container
        *ngTemplateOutlet="
          cmsTemplate;
          context: {
            content: content,
            contentObservable: content | finToObservable,
            template: template$ | async,
          }
        "
      ></ng-container>
    </ng-container>
    <!-- /DEFAULT -->
  </ng-container>
</ng-container>
