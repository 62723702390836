/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { catchErrorAsJson } from '@fcom/rx';
import { DapiBaseService } from '../dapi-base-service';
import { DapiHttpResponse } from '../dapi-http-response';
import { DapiRequestBuilder } from '../dapi-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FareRuleCategory } from '../models/fare-rule-category';
import { FareRules } from '../models/fare-rules';
import { FinnairCart } from '../models/finnair-cart';
import { FinnairCartRequest } from '../models/finnair-cart-request';
import { FinnairPassengerRequest } from '../models/finnair-passenger-request';
import { FinnairServiceRequestItem } from '../models/finnair-service-request-item';


/**
 * Cart resources currently used
 */
@Injectable({
  providedIn: 'root',
})
export class CartService extends DapiBaseService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  /**
   * Path part for operation createCart
   */
  static readonly CreateCartPath = '/api/v2/cart';

  /**
   * createCart.
   *
   * Creates an empty cart and returns the cart id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCart()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCart$Response(rootUrl: string, params: {
    hash: string;
    body?: FinnairCartRequest
  },
  context?: HttpContext

): Observable<DapiHttpResponse<void>> {
    const rb = new DapiRequestBuilder(rootUrl, CartService.CreateCartPath, 'post');

    if (params) {
      rb.query('hash', params.hash, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as DapiHttpResponse<void>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * createCart.
   *
   * Creates an empty cart and returns the cart id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCart$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCart(rootUrl: string, params: {
    hash: string;
    body?: FinnairCartRequest
  },
  context?: HttpContext

): Observable<void> {
    return this.createCart$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getCart
   */
  static readonly GetCartPath = '/api/v2/cart/{cartId}';

  /**
   * getCart.
   *
   * Fetches current cart content using id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCart$Response(rootUrl: string, params: {
    cartId: string;
    hash?: string;
    locale?: string;
    includeServiceCatalog?: boolean;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<FinnairCart>> {
    const rb = new DapiRequestBuilder(rootUrl, CartService.GetCartPath, 'get');

    if (params) {
      rb.path('cartId', params.cartId, {});
      rb.query('hash', params.hash, {});
      rb.query('locale', params.locale, {});
      rb.query('includeServiceCatalog', params.includeServiceCatalog, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<FinnairCart>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * getCart.
   *
   * Fetches current cart content using id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCart(rootUrl: string, params: {
    cartId: string;
    hash?: string;
    locale?: string;
    includeServiceCatalog?: boolean;
  },
  context?: HttpContext

): Observable<FinnairCart> {
    return this.getCart$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<FinnairCart>) => r.body as FinnairCart)
    );
  }

  /**
   * Path part for operation updatePassengerDetailsInCart
   */
  static readonly UpdatePassengerDetailsInCartPath = '/api/v2/cart/{cartId}/passengers';

  /**
   * updatePassengerDetailsInCart.
   *
   * Update passenger details in cart
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePassengerDetailsInCart()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePassengerDetailsInCart$Response(rootUrl: string, params: {
    cartId: string;
    locale?: string;
    hash?: string;
    body: FinnairPassengerRequest
  },
  context?: HttpContext

): Observable<DapiHttpResponse<void>> {
    const rb = new DapiRequestBuilder(rootUrl, CartService.UpdatePassengerDetailsInCartPath, 'put');

    if (params) {
      rb.path('cartId', params.cartId, {});
      rb.query('locale', params.locale, {});
      rb.query('hash', params.hash, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as DapiHttpResponse<void>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * updatePassengerDetailsInCart.
   *
   * Update passenger details in cart
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePassengerDetailsInCart$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePassengerDetailsInCart(rootUrl: string, params: {
    cartId: string;
    locale?: string;
    hash?: string;
    body: FinnairPassengerRequest
  },
  context?: HttpContext

): Observable<void> {
    return this.updatePassengerDetailsInCart$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation addPassengerDetailsToCart
   */
  static readonly AddPassengerDetailsToCartPath = '/api/v2/cart/{cartId}/passengers';

  /**
   * addPassengerDetailsToCart.
   *
   * Adds passenger details into cart
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPassengerDetailsToCart()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPassengerDetailsToCart$Response(rootUrl: string, params: {
    cartId: string;
    locale?: string;
    hash?: string;
    body: FinnairPassengerRequest
  },
  context?: HttpContext

): Observable<DapiHttpResponse<void>> {
    const rb = new DapiRequestBuilder(rootUrl, CartService.AddPassengerDetailsToCartPath, 'post');

    if (params) {
      rb.path('cartId', params.cartId, {});
      rb.query('locale', params.locale, {});
      rb.query('hash', params.hash, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as DapiHttpResponse<void>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * addPassengerDetailsToCart.
   *
   * Adds passenger details into cart
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addPassengerDetailsToCart$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addPassengerDetailsToCart(rootUrl: string, params: {
    cartId: string;
    locale?: string;
    hash?: string;
    body: FinnairPassengerRequest
  },
  context?: HttpContext

): Observable<void> {
    return this.addPassengerDetailsToCart$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getFareRulesByCart
   */
  static readonly GetFareRulesByCartPath = '/api/v2/cart/{cartId}/rules';

  /**
   * getFareRulesByCart.
   *
   * Fetches fare rules based the cart offers, traveler id and fare rule category. Raw rules are always returned in English.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFareRulesByCart()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFareRulesByCart$Response(rootUrl: string, params: {
    cartId: string;
    travelerId: string;
    ruleCategory: FareRuleCategory;
    hash?: string;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<FareRules>> {
    const rb = new DapiRequestBuilder(rootUrl, CartService.GetFareRulesByCartPath, 'get');

    if (params) {
      rb.path('cartId', params.cartId, {});
      rb.query('travelerId', params.travelerId, {});
      rb.query('ruleCategory', params.ruleCategory, {});
      rb.query('hash', params.hash, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<FareRules>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * getFareRulesByCart.
   *
   * Fetches fare rules based the cart offers, traveler id and fare rule category. Raw rules are always returned in English.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFareRulesByCart$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFareRulesByCart(rootUrl: string, params: {
    cartId: string;
    travelerId: string;
    ruleCategory: FareRuleCategory;
    hash?: string;
  },
  context?: HttpContext

): Observable<FareRules> {
    return this.getFareRulesByCart$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<FareRules>) => r.body as FareRules)
    );
  }

  /**
   * Path part for operation deleteAllSeats
   */
  static readonly DeleteAllSeatsPath = '/api/v2/cart/{cartId}/seats';

  /**
   * deleteSeatSelection.
   *
   * Delete all seat selections in cart
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAllSeats()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAllSeats$Response(rootUrl: string, params: {
    cartId: string;
    hash?: string;
  },
  context?: HttpContext

): Observable<DapiHttpResponse<void>> {
    const rb = new DapiRequestBuilder(rootUrl, CartService.DeleteAllSeatsPath, 'delete');

    if (params) {
      rb.path('cartId', params.cartId, {});
      rb.query('hash', params.hash, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as DapiHttpResponse<void>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * deleteSeatSelection.
   *
   * Delete all seat selections in cart
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAllSeats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAllSeats(rootUrl: string, params: {
    cartId: string;
    hash?: string;
  },
  context?: HttpContext

): Observable<void> {
    return this.deleteAllSeats$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setServices
   */
  static readonly SetServicesPath = '/api/v2/cart/{cartId}/services';

  /**
   * manageServicesToCart.
   *
   * Adds/Remove/Updates services to the cart
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setServices()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setServices$Response(rootUrl: string, params: {
    cartId: string;
    hash?: string;
    locale?: string;
    body: Array<FinnairServiceRequestItem>
  },
  context?: HttpContext

): Observable<DapiHttpResponse<void>> {
    const rb = new DapiRequestBuilder(rootUrl, CartService.SetServicesPath, 'post');

    if (params) {
      rb.path('cartId', params.cartId, {});
      rb.query('hash', params.hash, {});
      rb.query('locale', params.locale, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as DapiHttpResponse<void>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * manageServicesToCart.
   *
   * Adds/Remove/Updates services to the cart
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setServices$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setServices(rootUrl: string, params: {
    cartId: string;
    hash?: string;
    locale?: string;
    body: Array<FinnairServiceRequestItem>
  },
  context?: HttpContext

): Observable<void> {
    return this.setServices$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<void>) => r.body as void)
    );
  }

}
