<div class="error fill heather-700-bg nordic-blue-900-text rounded mr-large-b">
  <div class="error-container flex ps-small">
    <div class="icon ps-small-r">
      <fcom-icon [name]="SvgLibraryIcon.INFO" class="flex icon-large white-text"></fcom-icon>
    </div>
    <span class="error-item flex-1">
      <span class="bold-type font-body-1 white-text">{{ errorText }}</span>
    </span>
  </div>
</div>
