import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ImageComponent } from './image/image.component';

@NgModule({
  declarations: [ImageComponent],
  imports: [CommonModule, LazyLoadImageModule],
  exports: [ImageComponent],
  providers: [],
})
export class ImagesModule {}
