import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, delay, map } from 'rxjs';

export const CONTEXT = 'https://schema.org/';
export const BOARDING_POLICY = 'https://schema.org/GroupBoardingPolicy';
export const FINNAIR_LOGO =
  'https://brand.finnair.com/resource/crblob/2280812/db35da1bac637e775363ca7efcd66da9/finnair-logo-data.svg';
export const FINNAIR_COMPANY = 'Finnair';
export const FINNAIR_COMPAN_ALT = 'Finnair Oyj';
export const FINNAIR_IATA = 'AY';
export const FINNAIR_URL = 'https://www.finnair.com/';
export const ABOUT_TEXT =
  'Finnair is the flag carrier and largest airline of Finland, with its headquarters in Vantaa and its main hub at Helsinki Airport.';
export const IATA_CODE = 'AY';

export const ONEWORLD = 'Oneworld';
export const ONEWORLD_URL = 'https://www.oneworld.com';

export const FINNAIR_MEDIA_LINKS = [
  'https://fi.wikipedia.org/wiki/Finnair',
  'https://www.wikidata.org/wiki/Q201821',
  'https://www.facebook.com/finnairsuomi/',
  'https://twitter.com/FinnairSuomi',
  'https://www.linkedin.com/company/finnair/',
  'https://www.instagram.com/finnair/',
  'https://www.tiktok.com/@finnair',
];

export interface Schema {
  [key: string]: any;
}
export interface SchemaItem {
  schemaType: SchemaTypes;
  schemaSource: Schema;
}

interface ArticleSchemaParam {
  readonly id: string;
  readonly headline: string;
  readonly description: string;
  readonly datePublished: string;
  readonly dateModified: string;
  readonly image?: {
    readonly url: string;
    readonly width: string;
    readonly height: string;
  };
}

interface FlightSchemaParam {
  departureAirportName: string;
  departureAirportIataCode: string;
  arrivalAirportName: string;
  arrivalAirportIataCode: string;
}

export enum SchemaTypes {
  ARTICLE = 'Article',
  FLIGHT = 'Flight',
  ORGANIZATION = 'Organization',
  WEBPAGE = 'WebPage',
  AIRLINE = 'Airline',
  WEBSITE = 'WebSite',
}

export enum LinkTypes {
  IMAGE_OBJECT = 'ImageObject',
  AIRLINE = 'Airline',
  AIRPORT = 'Airport',
  CONTACT_POINT = 'ContactPoint',
  SEARCH_ACTION = 'SearchAction',
  POST_ADDRESS = 'PostalAddress',
}

const schemaDefaultParams = {
  '@context': CONTEXT,
  author: {
    '@type': SchemaTypes.ORGANIZATION,
    name: FINNAIR_COMPANY,
  },
  publisher: {
    '@type': SchemaTypes.ORGANIZATION,
    name: FINNAIR_COMPANY,
    logo: {
      '@type': LinkTypes.IMAGE_OBJECT,
      url: FINNAIR_LOGO,
      width: '533',
      height: '57',
    },
  },
};

@Injectable({ providedIn: 'root' })
export class SchemaService {
  private schemasBehaviorSubject$: BehaviorSubject<SchemaItem[]> = new BehaviorSubject([]);

  public schemas$: Observable<Schema[]>;

  constructor() {
    this.schemas$ = this.schemasBehaviorSubject$.asObservable().pipe(
      delay(0),
      map((allSchemas) => allSchemas.map(({ schemaSource }) => schemaSource))
    );
  }

  public addArticleSchema({ id, headline, description, image, datePublished, dateModified }: ArticleSchemaParam): void {
    const schemaSource: Schema = {
      '@type': SchemaTypes.ARTICLE,
      ...schemaDefaultParams,
      mainEntityOfPage: {
        '@type': SchemaTypes.WEBPAGE,
        '@id': id,
      },
      headline,
      description,
      datePublished,
      dateModified,
    };
    if (image) {
      schemaSource.image = {
        '@type': LinkTypes.IMAGE_OBJECT,
        url: image.url,
        width: image.width,
        height: image.height,
      };
    }
    this.updateSchema({
      schemaType: SchemaTypes.ARTICLE,
      schemaSource,
    });
  }

  public addAirlineSchema(): void {
    this.updateSchema({
      schemaType: SchemaTypes.AIRLINE,
      schemaSource: {
        '@context': CONTEXT,
        '@type': SchemaTypes.AIRLINE,
        name: FINNAIR_COMPANY,
        url: FINNAIR_URL,
        logo: {
          '@type': LinkTypes.IMAGE_OBJECT,
          url: FINNAIR_LOGO,
          width: '533',
          height: '57',
        },
        alternateName: FINNAIR_COMPAN_ALT,
        sameAs: FINNAIR_MEDIA_LINKS,
        memberOf: {
          '@type': SchemaTypes.ORGANIZATION,
          name: ONEWORLD,
          url: ONEWORLD_URL,
        },
        iataCode: IATA_CODE,
        foundingDate: '1923-11-01',
        address: {
          '@type': LinkTypes.POST_ADDRESS,
          streetAddress: 'Tietotie 9',
          addressLocality: 'Vantaa',
          postalCode: '01530',
          addressCountry: 'FI',
        },
      },
    });
  }

  public addWebSiteSchema(): void {
    this.updateSchema({
      schemaType: SchemaTypes.WEBSITE,
      schemaSource: {
        '@context': CONTEXT,
        '@type': SchemaTypes.WEBSITE,
        url: FINNAIR_URL,
        name: FINNAIR_COMPANY,
        about: ABOUT_TEXT,
        publisher: {
          '@type': SchemaTypes.ORGANIZATION,
          name: FINNAIR_COMPANY,
          url: FINNAIR_URL,
        },
        potentialAction: {
          '@type': LinkTypes.SEARCH_ACTION,
          target: 'https://www.finnair.com/fi-fi/search?query={search_term_string}',
          'query-input': 'required name=search_term_string',
        },
      },
    });
  }

  public addFlightSchema({
    departureAirportName,
    departureAirportIataCode,
    arrivalAirportName,
    arrivalAirportIataCode,
  }: FlightSchemaParam): void {
    this.updateSchema({
      schemaType: SchemaTypes.FLIGHT,
      schemaSource: {
        '@context': CONTEXT,
        '@type': SchemaTypes.FLIGHT,
        provider: {
          '@type': LinkTypes.AIRLINE,
          name: FINNAIR_COMPANY,
          iataCode: FINNAIR_IATA,
          boardingPolicy: BOARDING_POLICY,
        },
        seller: {
          '@type': LinkTypes.AIRLINE,
          name: FINNAIR_COMPANY,
          iataCode: FINNAIR_IATA,
        },
        departureAirport: {
          '@type': LinkTypes.AIRPORT,
          name: departureAirportName,
          iataCode: departureAirportIataCode,
        },
        arrivalAirport: {
          '@type': LinkTypes.AIRPORT,
          name: arrivalAirportName,
          iataCode: arrivalAirportIataCode,
        },
      },
    });
  }

  public clearSchemas(): void {
    this.schemasBehaviorSubject$.next([]);
  }

  private updateSchema(newSchema: SchemaItem) {
    const current: SchemaItem[] = this.schemasBehaviorSubject$.getValue();
    if (current.some(({ schemaType }) => schemaType === newSchema.schemaType)) {
      this.schemasBehaviorSubject$.next([
        ...current.filter(({ schemaType }) => schemaType !== newSchema.schemaType),
        newSchema,
      ]);
    } else {
      this.schemasBehaviorSubject$.next([...current, newSchema]);
    }
  }
}
