<a
  [finLink]="link"
  [external]="external"
  [finElemTracker]="'level-1: ' + link"
  [finElemContext]="TRACKING_CONTEXT"
  [finElemType]="ElementTypes.LINK"
  [attr.target]="openInNewTab ? '_blank' : ''"
  [attr.aria-current]="active ? 'page' : null"
  [attr.aria-disabled]="active ? 'true' : null"
  (click)="onLinkClick($event)"
  [class]="
    'sidebar-navigation-item ps-large-x mr-xxsmall-y no-decoration flex flex--middle ' +
    styleClasses +
    (active ? ' active' : '')
  "
>
  {{ title }}
  <span *ngIf="active" class="is-vishidden">{{ 'navigation.currentPage' | finLocalization }}</span
  ><fcom-icon
    *ngIf="external"
    class="icon-small ms-xxsmall-l"
    [dataTestId]="'icon-' + SvgLibraryIcon.EXTERNAL_TAB"
    [name]="SvgLibraryIcon.EXTERNAL_TAB"
  ></fcom-icon>
  <fcom-icon
    *ngIf="loginRequired"
    class="icon-small ms-xxsmall-l"
    [dataTestId]="'icon-' + SvgLibraryIcon.LOCK_OPEN"
    [name]="SvgLibraryIcon.LOCK_OPEN"
  ></fcom-icon>
</a>
