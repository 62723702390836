import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';

import { CmsTeaser } from '@fcom/core-api';

@Component({
  selector: 'cms-horizontal-title',
  styleUrls: ['./horizontal-title.component.scss'],
  templateUrl: './horizontal-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HorizontalTitleComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;
  @Input()
  content$: Observable<CmsTeaser>;

  @Input()
  headerLevel = 2;
}
