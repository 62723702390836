export enum WidgetLayout {
  DEFAULT = 'default',
  HORIZONTAL = 'horizontal',
}

export enum ExpanderStatus {
  OPEN = 'open',
  CLOSE = 'close',
}

export enum WidgetTheme {
  LIGHT = 'theme--light',
  DARK = 'theme--dark',
}

export enum LocationType {
  ORIGIN = 'origin',
  DESTINATION = 'destination',
}

export enum SelectionType {
  TRIP_TYPE = 'tripType',
  TRAVEL_DATES = 'travelDates',
  PAX = 'PAX',
  DISCOUNT_CODE = 'discountCode',
  ORIGIN = LocationType.ORIGIN,
  DESTINATION = LocationType.DESTINATION,
}

export type GlobalBookingWidgetSelectionChangeMap = Partial<Record<SelectionType, boolean>>;
