/* eslint-disable rxjs/no-subject-value */
import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { LocalDate } from '@fcom/core/utils/tz-date';
import { DateFormat, isDeepEqual, isPresent, Pattern, quantityOfMonths, toMonthId, WeekdayMap } from '@fcom/core/index';

import { areSelectedDatesChanged, getMonthByDate } from '../../../utils/date.utils';
import { CalendarViewModel, DateSelection, Day, Month, SpacerType } from '../../../utils/date.interface';
import { Calendar, CalendarNavigationType, DateRange } from '../interfaces';

type CalendarDataUpdate = Partial<{
  minDate: LocalDate | null;
  maxDate: LocalDate | null;
  displayMonths: 1 | 2;
  navigationType: CalendarNavigationType;
  selectedDates: DateSelection;
  isDateRange: boolean;
  showTags: boolean;
  scrollOnInit: boolean;
  disabledDateRanges: DateRange[];
  dateLabels: any;
  selectedLabel: string;
}>;
@Injectable()
export class CalendarService {
  private _VALIDATORS: {
    [K in keyof Partial<CalendarDataUpdate>]: (v: Partial<CalendarDataUpdate>[K]) => Partial<CalendarViewModel> | void;
  } = {
    minDate: (date: LocalDate | null): Partial<CalendarDataUpdate> | void => {
      if (!this._state.minDate?.equals(date)) {
        return { minDate: date };
      }
    },
    maxDate: (date: LocalDate | null): Partial<CalendarDataUpdate> | void => {
      if (!this._state.maxDate?.equals(date)) {
        return { maxDate: date };
      }
    },
    displayMonths: (displayMonths: 1 | 2): Partial<CalendarDataUpdate> | void => {
      if (
        typeof displayMonths === 'number' &&
        (displayMonths === 1 || displayMonths === 2) &&
        this._state.displayMonths !== displayMonths
      ) {
        return { displayMonths };
      }
    },
    navigationType: (navigationType: CalendarNavigationType): Partial<CalendarDataUpdate> | void => {
      if (this._state.navigationType !== navigationType) {
        return { navigationType };
      }
    },
    selectedDates: (selectedDates: DateSelection): Partial<CalendarDataUpdate> | void => {
      if (areSelectedDatesChanged(this._state.selectedDates, selectedDates)) {
        return { selectedDates };
      }
    },
    isDateRange: (isDateRange: boolean): Partial<CalendarDataUpdate> | void => {
      if (this._state.isDateRange !== isDateRange) {
        return { isDateRange };
      }
    },
    showTags: (showTags: boolean): Partial<CalendarDataUpdate> | void => {
      if (this._state.showTags !== showTags) {
        return { showTags };
      }
    },
    scrollOnInit: (scrollOnInit: boolean): Partial<CalendarDataUpdate> | void => {
      if (this._state.scrollOnInit !== scrollOnInit) {
        return { scrollOnInit };
      }
    },
    disabledDateRanges: (disabledDateRanges: DateRange[] | null): void => {
      const sortedDateRanges = disabledDateRanges
        ?.filter((disabledDateRange) => disabledDateRange.length)
        ?.map(([date1, date2]) => {
          const startDate = date1.lte(date2) ? date1 : date2;
          const endDate = date1.gt(date2) ? date1 : date2;

          return [startDate, endDate];
        })
        .sort(([start1], [start2]) => start1.toDate().getTime() - start2.toDate().getTime());

      this._disabledDateRanges = isPresent(disabledDateRanges) ? sortedDateRanges : disabledDateRanges;
    },
    dateLabels: (dateLabels: any): void => {
      this._dateLabels = dateLabels;
    },
    selectedLabel: (selectedLabel: string): void => {
      this._selectedLabel = selectedLabel;
    },
  };

  private today: LocalDate = LocalDate.now();
  private weekdayMap: WeekdayMap = new WeekdayMap('mon');
  private _state: CalendarViewModel = {
    displayMonths: 2,
    isDateRange: false,
    scrollOnInit: true,
    showTags: false,
    minDate: null,
    maxDate: null,
    firstDate: null,
    lastDate: null,
    focusDate: null,
    selectedDates: { startDate: undefined, endDate: undefined },
    isMobile: false,
    months: [],
    monthTags: [],
    navigationType: CalendarNavigationType.MIXED,
    prevDisabled: false,
    nextDisabled: false,
    loadAmount: 0,
  };

  private _selectedLabel;
  private _disabledDateRanges;
  private _dateLabels;
  private _dataModel$ = new Subject<CalendarViewModel>();

  get dataModel$(): Observable<CalendarViewModel> {
    return this._dataModel$;
  }

  set(options: Partial<CalendarViewModel>): void {
    const updates = Object.keys(options)
      .map((key) => this._VALIDATORS[key]?.(options[key]))
      .reduce((data, part) => ({ ...data, ...part }), {});

    if (isPresent(updates) && Object.keys(updates).length > 0) {
      this.nextState(updates);
    }
  }

  /**
   * Screen size has changed
   * @param isMobile
   */
  changeCalendarView(_isMobile: boolean): void {
    const { isMobile, navigationType, months, focusDate } = this._state;

    if (isMobile !== _isMobile) {
      this.nextState({ isMobile: _isMobile });

      const isVerticalScroll = _isMobile && navigationType === CalendarNavigationType.MIXED;

      if (isVerticalScroll) {
        // calculate the month that is visible
        // load from 0 to the monthId + 1 that is visible
        const currentMonthId = getMonthByDate(months, focusDate)?.monthArrayIndex;

        if (months.length < currentMonthId) {
          this.loadMonths(currentMonthId + 1);
        }
      } else {
        this.navigateToMonth(focusDate);
      }
    }
  }

  /**
   * Navigate to month method
   * @param date
   */
  navigateToMonth(date: LocalDate): void {
    const firstDate = this.toValidDate(date);

    if (!this._state.firstDate?.equals(date)) {
      // month has changed
      this.nextState({ firstDate });
    }
  }

  /**
   * Processes a keyboard event
   * @param event
   */
  processKey(event: KeyboardEvent): void {
    const { focusDate, lastDate, firstDate } = this._state;

    switch (event.key) {
      case 'PageUp':
        this.focusDate(focusDate.minusMonths(1));
        break;
      case 'PageDown':
        this.focusDate(focusDate.plusMonths(1));
        break;
      case 'End':
        this.focusDate(lastDate);
        break;
      case 'Home':
        this.focusDate(firstDate);
        break;
      case 'ArrowLeft':
        this.focusDate(focusDate.minusDays(1));
        break;
      case 'ArrowUp':
        this.focusDate(focusDate.minusDays(7));
        break;
      case 'ArrowRight':
        this.focusDate(focusDate.plusDays(1));
        break;
      case 'ArrowDown':
        this.focusDate(focusDate.plusDays(7));
        break;
      case 'Enter':
      case 'Space':
        this.selectDate(focusDate);
        break;
      default:
        return;
    }
    event.preventDefault();
    event.stopPropagation();
  }

  focusDate(date?: LocalDate | null): void {
    // we update the focusDate only if the previous and next date are different and is actual date
    if (isPresent(date) && !this._state.focusDate?.equals(date)) {
      this.nextState({ focusDate: date });
    }
  }

  /**
   * Select dates
   * @param date to be selected
   */
  selectDate(date?: LocalDate): void {
    const { startDate, endDate } = this._state.selectedDates;

    const result: DateSelection = { startDate: undefined, endDate: undefined };

    if (!isPresent(startDate) || isPresent(endDate) || date.lt(startDate) || !this._state.isDateRange) {
      result.startDate = date;
    } else {
      result.startDate = startDate;
      result.endDate = date;
    }

    this.nextState({ selectedDates: result });
    this.focusDate(date);
  }

  /**
   * Loads more months starting from minDate
   * @param amount moths to be loaded
   */
  loadMonths(amount: number, focusDate?: LocalDate | undefined): void {
    const { minDate, maxDate } = this._state;
    const maxMonths = quantityOfMonths(minDate, maxDate);

    if (amount <= maxMonths) {
      this.nextState({ loadAmount: amount, ...(isPresent(focusDate) && { focusDate }) });
    }
  }

  /**
   * Gets month data by monthId
   * @param monthId
   * @returns Month | undefined
   */
  getMonth(monthId: string): Month | undefined {
    return this._state.months.find((month) => month.id === monthId);
  }

  // creates month tags
  private createMonthTags(minDate: LocalDate, maxDate: LocalDate): string[] {
    return Array.from({ length: quantityOfMonths(minDate, maxDate) }, (_, i) => {
      const month = minDate.plusMonths(i);
      return new DateFormat(this._dateLabels).format(
        LocalDate.forDate(month.localYear, month.localMonth, 1),
        DateFormat['SHORT_MONTH_AND_YEAR']
      );
    });
  }

  // creates months
  private createMonths(
    firstAvailableDate: LocalDate,
    lastAvailableDate: LocalDate,
    displayMonths: number,
    loadAmount = 0
  ): Calendar {
    const { months } = this._state;
    const monthsAmount = loadAmount > 0 ? loadAmount : displayMonths;

    // move old months to a temporary array
    const monthsToReuse: Month[] = months.splice(0, months.length);

    // generate new first dates
    const firstDates = Array.from({ length: monthsAmount }, (_, i) => {
      const firstMonthDate: LocalDate = firstAvailableDate.plusMonths(i);

      months[i] = null;

      const reusedIndex = monthsToReuse.findIndex(
        (month) => month.value === firstMonthDate.localMonth && month.year === firstMonthDate.localYear
      );
      // move reused month back to months
      if (reusedIndex !== -1) {
        months[i] = monthsToReuse.splice(reusedIndex, 1)[0];
      }

      return firstMonthDate;
    });

    // rebuild not found months
    firstDates.forEach((firstMonthDate, i) => {
      if (months[i] === null) {
        const firstDate = firstMonthDate.firstDayOfMonth.lt(this.today)
          ? firstAvailableDate
          : firstMonthDate.firstDayOfMonth;

        months[i] = this.createMonth(firstDate, firstMonthDate.firstDayOfMonth, lastAvailableDate);
      }
    });

    return { today: this.today as unknown as Day, months };
  }

  // creates a single month with weeks and days
  private createMonth(firstDate: LocalDate, firstOfMonth: LocalDate, endDate: LocalDate): Month {
    const totalWeeks = 6;
    const daysPerWeek = 7;
    const weeks = [];
    const monthBase = {
      id: toMonthId(firstOfMonth),
      year: firstOfMonth.localYear,
      value: firstOfMonth.localMonth,
      weeks,
      weekDays: this._dateLabels,
      label: new DateFormat(this._dateLabels).format(
        LocalDate.forDate(firstOfMonth.localYear, firstOfMonth.localMonth, 1),
        DateFormat['LONG_MONTH_AND_YEAR']
      ),
      monthArrayIndex: quantityOfMonths(this.today, firstOfMonth) - 1,
    } as Month;
    const lastOfMonth =
      monthBase.id === toMonthId(endDate) ? endDate.localDay : firstOfMonth.plusMonths(1).minusDays(1).localDay;

    let day = new LocalDate(firstDate.id);

    // month has weeks
    for (let week = 0; week < totalWeeks; week++) {
      const currentWeek = { days: [] };

      // week has days
      for (let weekday = 0; weekday < daysPerWeek; weekday++) {
        if (day.localMonth !== firstOfMonth.localMonth) {
          break;
        }

        // Add spacer to the start if needed
        if (week === 0 && weekday === 0 && this.weekdayMap.get(day.weekday) > 0) {
          currentWeek.days.push({
            spacer: this.weekdayMap.get(day.weekday),
            spacerType: SpacerType.START,
          });

          weekday = weekday + this.weekdayMap.get(day.weekday);
        }

        if (day.lte(endDate)) {
          // Add actual day
          const label = new DateFormat(this._dateLabels).format(day, new Pattern('dateFormatFullDetailed', true));

          currentWeek.days.push({
            id: day.id,
            // month: monthBase,
            value: day.localDay,
            date: day,
            weekday: this.weekdayMap.get(day.weekday),
            today: day.equals(this.today),
            focused: false,
            selected: false,
            disabled: this._disabledDateRanges ? this.isDateDisabled(day) : false,
            tabindex: -1,
            ariaLabel: label,
          });
        }

        // Add spacer to the end if needed
        if (lastOfMonth === day.localDay && weekday !== 6) {
          currentWeek.days.push({
            spacer: 7 - day.weekday,
            spacerType: SpacerType.END,
          });
        }

        day = day.plusDays(1);
      }

      if (currentWeek.days.length === 0) {
        currentWeek.days.push({ spacer: 7, spacerType: SpacerType.END });
      }
      weeks.push(currentWeek);
    }

    return monthBase;
  }

  private nextState(options: Partial<CalendarViewModel>) {
    const newState = this.updateState(options);
    if (!isDeepEqual(newState, this._state)) {
      this.updateMonthsState(newState);
      this._state = newState;
      this._dataModel$.next(this._state);
    }
  }

  private updateMonthsState(state: CalendarViewModel) {
    const { selectedDates, focusDate } = state;

    state.months.forEach((month) => {
      month.weeks.forEach((week) => {
        week.days.forEach((day) => {
          // skip the spacers
          if (day?.spacer) {
            return;
          }

          if (isPresent(focusDate)) {
            day.focused = focusDate.equals(day.date);
          }

          // calculating tabindex
          day.tabindex = isPresent(focusDate) && day.date.equals(focusDate) ? 0 : -1;

          // override context disabled
          day.disabled = this._disabledDateRanges ? this.isDateDisabled(day.date) : false;

          // update day selection
          day.selected = selectedDates.startDate?.equals(day.date) || selectedDates.endDate?.equals(day.date);

          const label = new DateFormat(this._dateLabels).format(day.date, new Pattern('dateFormatFullDetailed', true));

          // update day aria label
          day.ariaLabel = `${label} ${day.selected ? this._selectedLabel : ''}`;
        });
      });
    });
  }

  private updateState(options: Partial<CalendarViewModel>) {
    const state = Object.assign({}, this._state, options);
    const isVerticalScroll = state.isMobile && state.navigationType === CalendarNavigationType.MIXED;

    let startDate = this.firstSelectableDay(state.firstDate, state.minDate, state.maxDate);

    if ('isMobile' in options && state.isMobile && state.navigationType !== CalendarNavigationType.MIXED) {
      // early break if it is mobile and navigation type is different than MIXED
      // only the combination of navigation type MIXED and isMobile should trigger full update of the state
      return state;
    }

    if ('showTags' in options) {
      if (state.showTags) {
        state.monthTags = this.createMonthTags(state.minDate, state.maxDate);
      } else {
        state.monthTags = [];
      }
    }

    // focus date changed
    if ('focusDate' in options) {
      const focusDate = this.getDateInRange(state.focusDate, state.minDate, state.maxDate);
      state.focusDate = focusDate;
      startDate = this.getDateInRange(this.toValidDate(focusDate, state).firstDayOfMonth, state.minDate, state.maxDate);

      // nothing to rebuild if only focus changed and it is still visible
      if (
        state.months.length > 0 &&
        state.focusDate &&
        !state.focusDate.lt(state.firstDate) &&
        !state.focusDate.gt(state.lastDate)
      ) {
        return state;
      }
    }

    // selectedDates changes
    if ('selectedDates' in options && this.validateSelectedDates(options.selectedDates, state)) {
      if (isPresent(state.selectedDates.startDate) && state.scrollOnInit && !isVerticalScroll) {
        const isFirstMonth = state.selectedDates.startDate.firstDayOfMonth.equals(state.minDate?.firstDayOfMonth);
        const date = this.toValidDate(state.selectedDates.startDate, state);
        startDate = isFirstMonth && !isPresent(state.firstDate) ? state.minDate : date;
      }
    }

    // first date changed
    if ('firstDate' in options) {
      state.firstDate = this.getDateInRange(state.firstDate, state.minDate, state.maxDate);
      startDate = state.firstDate;
    }

    // rebuilding months
    if (isPresent(startDate)) {
      if (isVerticalScroll && state.loadAmount > 0 && !startDate.equals(state.minDate)) {
        // adjust focusDate and startDate when it is vertical scroll
        state.focusDate = startDate;
        startDate = state.minDate;
      }

      if (isVerticalScroll && 'selectedDates' in options) {
        // we need to patch the already loaded months
        state.loadAmount = state.months.length;
      }

      const months = this.createMonths(startDate, state.maxDate, state.displayMonths, state.loadAmount).months;

      // updating months and boundary dates
      state.months = months;
      const firstValidVisibleDate = months[0].weeks[0].days.find((day) => isPresent(day?.id))?.date;
      state.firstDate = firstValidVisibleDate?.firstDayOfMonth?.gt(state.minDate)
        ? firstValidVisibleDate.firstDayOfMonth
        : state.minDate;

      const lastValidVisibleDate = months[months.length - 1].weeks[0].days.find((day) => isPresent(day?.id))?.date;
      state.lastDate = lastValidVisibleDate?.lastDayOfMonth?.lt(state.maxDate)
        ? lastValidVisibleDate.lastDayOfMonth
        : state.maxDate;

      // reset loadMore after the update
      state.loadAmount = 0;

      // adjusting focus after months were built
      if (!state.focusDate || !isVerticalScroll) {
        state.focusDate = startDate;
      }

      // updating arrows visibility
      if (
        !isPresent(this._state.firstDate) ||
        !state.firstDate.equals(this._state.firstDate) ||
        'minDate' in options ||
        'maxDate' in options ||
        !isVerticalScroll
      ) {
        state.prevDisabled =
          isPresent(state.minDate) &&
          state.minDate.isBetween(state.firstDate.firstDayOfMonth, state.firstDate.lastDayOfMonth);
        state.nextDisabled =
          isPresent(state.maxDate) &&
          state.maxDate.isBetween(state.lastDate.firstDayOfMonth, state.lastDate.lastDayOfMonth);
      }
    }
    return state;
  }

  private getDateInRange(
    date?: LocalDate | null,
    minDate?: LocalDate | null,
    maxDate?: LocalDate | null
  ): LocalDate | null {
    if (isPresent(date) && isPresent(minDate) && date.lt(minDate)) {
      return minDate;
    }
    if (isPresent(date) && isPresent(maxDate) && date.gt(maxDate)) {
      return maxDate;
    }

    return date || null;
  }

  private validateSelectedDates(selectedDates: DateSelection, state: CalendarViewModel) {
    const { minDate, maxDate } = state;
    const { startDate, endDate } = selectedDates;

    if (isPresent(startDate) && !startDate.isBetween(minDate, maxDate)) {
      return false;
    }

    if (isPresent(endDate) && (!isPresent(startDate) || !endDate.isBetween(startDate, maxDate))) {
      return false;
    }

    return true;
  }

  private toValidDate(date: LocalDate, state = this._state): LocalDate {
    // we make sure it is a valid date
    const isValidDate = isPresent(date) && date.isBetween(state.minDate, state.maxDate);
    // based of the chosen view we need to make sure we set valid first date
    const isLastMonth = date.firstDayOfMonth.equals(state.maxDate?.firstDayOfMonth) && state.displayMonths === 2;

    if (isValidDate && !isLastMonth) {
      return date;
    } else if (isValidDate && isLastMonth) {
      return date.minusMonths(1);
    } else {
      return this.today;
    }
  }

  /**
   * Gets first valid non disable date
   * @param firstDate visible from state
   * @param minDate of calendar from state
   * @param maxDate of calendar from state
   * @returns valid non disable date
   */
  private firstSelectableDay(firstDate: LocalDate, minDate: LocalDate, maxDate: LocalDate): LocalDate {
    let validDate = isPresent(firstDate) && firstDate?.isBetween(minDate, maxDate) ? firstDate : minDate;

    if (this._disabledDateRanges) {
      for (const [startDate, endDate] of this._disabledDateRanges) {
        // if the current validDate is before the start of the current range,
        // it's a valid selectable date since it's not within any disabled range
        if (validDate.lt(startDate)) {
          return validDate;
        }

        // if the current validDate is within a disabled range, move it to the day after the end of this range
        if (validDate.isBetween(startDate, endDate)) {
          validDate = endDate.plusDays(1);
        }
      }
    }
    return validDate;
  }

  /**
   * Checks if given date is disabled
   * @param date to be checks
   * @returns boolean
   */
  private isDateDisabled(date: LocalDate) {
    return this._disabledDateRanges
      .filter((disabledDateRange) => disabledDateRange.length)
      .some((disabledDateRange) => date.isBetween(disabledDateRange[0], disabledDateRange[1]));
  }
}
