import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { IconName, NotificationLayout, NotificationTheme } from '@fcom/ui-components';

import { ToasterService } from '../../services';
import { ToasterMessage } from '../../interfaces/toaster-message.interface';

const TOASTER_VISIBLE_MS = 5000;

@Component({
  selector: 'fin-toaster',
  styleUrls: ['toaster.component.scss'],
  templateUrl: 'toaster.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterComponent implements OnDestroy {
  message$: Observable<ToasterMessage>;
  messageToRemove$: Observable<string>;
  subscriptions: Subscription = new Subscription();
  messages: { [key: string]: ToasterMessage } = {};
  beneathModal = false;
  iconCategory: IconLibrary = IconLibrary.SVG_LIBRARY;

  readonly NotificationTheme = NotificationTheme;
  readonly NotificationLayout = NotificationLayout;
  private timeoutHandle: any[] = [];

  constructor(
    private toasterService: ToasterService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    this.message$ = this.toasterService.toasterMessage$;
    this.messageToRemove$ = this.toasterService.messageToRemove$;

    // Adding new messages to list.

    this.subscribeToRouter();
    this.subscriptions.add(
      this.message$.pipe(filter(Boolean)).subscribe((message: ToasterMessage) => {
        message = {
          theme: message?.theme ?? NotificationTheme.INFO,
          iconName: this.getIconName(message?.theme),
          isCloseClicked: false,
          isDisplayed: false,
          ...message,
        };

        const oldMessage = this.messages[message.id];
        if (oldMessage) {
          this.messages[oldMessage.id] = message;
        } else {
          this.insertNewMessage(message);
        }

        if (message.beneathModal) {
          this.beneathModal = true;
        }

        this.cdr.markForCheck();
      })
    );

    this.subscriptions.add(
      this.messageToRemove$.pipe(filter(Boolean)).subscribe((id: string) => {
        this.close(id);
      })
    );
  }

  getToastTrackKey(message: number): number {
    return message;
  }

  close(key: string): void {
    this.setSlidingEffectOnMessages(key);
    setTimeout(() => {
      this.removeMessage(key);
      this.beneathModal = Object.values(this.messages).some((message) => message.beneathModal);
      this.cdr.markForCheck();
    }, 1000);
  }

  callbackClicked(key: string, callback: (...args: any[]) => void): void {
    this.close(key);
    callback();
  }

  setSlidingEffectOnMessages = (key: string): void => {
    if (this.messages[key]) {
      this.messages[key].isCloseClicked = true;
      Object.values(this.messages).forEach((message) => (message.isDisplayed = true));
      this.cdr.markForCheck();
    }
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.timeoutHandle.forEach((timeout) => {
      clearTimeout(timeout);
    });
  }

  private insertNewMessage(message: ToasterMessage): void {
    this.messages[message.id] = message;

    if (message.autoClose) {
      this.timeoutHandle.push(setTimeout(() => this.close(message.id), TOASTER_VISIBLE_MS));
    }
  }

  /**
   * Remove/keep notification based on the navigated route,
   * allowing us to remove the notification when reroute
   */
  private subscribeToRouter() {
    this.subscriptions = this.router.events
      .pipe(filter((event: Event) => event instanceof NavigationEnd))
      .subscribe(() => {
        Object.keys(this.messages).forEach((messageKey) => {
          if (!this.messages[messageKey].keepMessageAfterNavigation) {
            this.removeMessage(messageKey);
          }
        });
        this.cdr.markForCheck();
      });
  }

  removeMessage = (messageKey: string): void => {
    this.messages = Object.keys(this.messages)
      .filter((key) => key !== messageKey)
      .reduce((result, current) => {
        result[current] = this.messages[current];
        return result;
      }, {});
  };

  getIconName = (theme: Exclude<NotificationTheme, NotificationTheme.TRANSPARENT> | undefined): IconName => {
    switch (theme) {
      case NotificationTheme.ALERT:
        return SvgLibraryIcon.CLOSE_DELETE_BLOCK;
      case NotificationTheme.WARNING:
        return SvgLibraryIcon.ALERT_TRIANGLE;
      case NotificationTheme.SUCCESS:
        return SvgLibraryIcon.CHECKMARK_BLOCK;
      default:
        return SvgLibraryIcon.INFO_BLOCK;
    }
  };
}
