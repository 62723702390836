<div class="grow">
  <fin-itinerary-timeline
    *ngIf="(selectionPhase$ | async) === SelectionPhase.OUTBOUND"
    [bound]="outbound$ | async"
    [locations]="locations$ | async"
    [boundType]="OUTBOUND"
    [isBookingFlow]="isBookingFlow"
  ></fin-itinerary-timeline>

  <fcom-notification
    data-testid="airport-change-notification"
    *ngIf="hasAirportChangeBetweenBounds$ | async"
    [theme]="NotificationTheme.WARNING"
    [layout]="NotificationLayout.BLOCK"
  >
    <span>{{ 'itinerary.note' | finLocalization }}:</span>
    <span>{{ 'itinerary.airportChangeBetweenBounds' | finLocalization }}</span>
  </fcom-notification>

  <fin-itinerary-timeline
    *ngIf="(selectionPhase$ | async) === SelectionPhase.INBOUND"
    [bound]="inbound$ | async"
    [locations]="locations$ | async"
    [boundType]="INBOUND"
    [isBookingFlow]="isBookingFlow"
  ></fin-itinerary-timeline>
</div>
