import { Injectable } from '@angular/core';

import { Attributes, IntersectionObserverHooks } from 'ng-lazyload-image';

import { CmsImageData, StaticPicture } from '@fcom/core-api';
import { buildUrl } from '@fcom/core/utils';
import { THUMBNAIL_WIDTH } from '@fcom/ui-components';

import {
  ASPECT_RATIO_PROPERTY_MAP,
  AspectRatioString,
  CmsAspectRatio,
  FALLBACK_WIDTH,
  IMAGE_WIDTHS,
  ResponsiveImageData,
  SrcAndWidth,
} from '../../interfaces';

export const isStaticPicture = (o: any): o is StaticPicture => !!o && (!!o.fullSizeUrl || !!o.thumbnailSrc);
export const isCmsImageData = (o: any): o is CmsImageData => !!o && !!o.responsiveImageLinksData;

export function toCmsAspectRatio(s: AspectRatioString): CmsAspectRatio {
  return ASPECT_RATIO_PROPERTY_MAP[s];
}

export function mapToResponsiveImage(pic: CmsImageData | StaticPicture, aspect: CmsAspectRatio): ResponsiveImageData {
  if (isStaticPicture(pic)) {
    return {
      fallbackSrc: pic.fullSizeUrl,
      thumbnailSrc: pic.thumbnailSrc,
      srcSet: [],
    };
  }

  const images = pic.responsiveImageLinksData[aspect];
  const widestWidth = getWidestImageWidth(images);

  const srcSet = IMAGE_WIDTHS.map((width) => {
    return {
      width,
      src: buildUrl(images[widestWidth], { imwidth: width }),
    };
  });

  return {
    fallbackSrc: buildUrl(images[FALLBACK_WIDTH], { imwidth: FALLBACK_WIDTH }),
    thumbnailSrc: buildUrl(images[FALLBACK_WIDTH], { imwidth: THUMBNAIL_WIDTH }),
    srcSet,
  };
}

export function getWidestImageWidth(imageWidthMap: object): number {
  return Object.keys(imageWidthMap)
    .map((key) => +key)
    .filter(Boolean)
    .reduce((widest: number, width: number): number => (widest <= width ? width : widest), 0);
}

export function toSrcSetString(srcSet: SrcAndWidth[]): string {
  return srcSet.map(({ src, width }) => `${src} ${width}w`).join(', ');
}

function isImageElement(element: HTMLImageElement | HTMLDivElement): element is HTMLImageElement {
  return element.nodeName.toLowerCase() === 'img';
}

export function getSrc(img: HTMLImageElement) {
  // Return the loaded src. Default loadImage returns the entire srcSet, which breaks setting the background-image url

  return typeof img.currentSrc !== 'undefined' ? img.currentSrc : img.src;
}
@Injectable()
export class LazyLoadImageHooks extends IntersectionObserverHooks {
  /**
   * Override loadImage function if div element and useSrcset so that a promise
   * to the loaded src is returned instead of a promise to the srcset string
   */
  loadImage(attributes: Attributes): Promise<string> {
    if (!isImageElement(attributes.element) && attributes.useSrcset) {
      const img = new Image();
      if ('srcset' in img && 'sizes' in img) {
        img.sizes = '100vw';
        img.srcset = attributes.imagePath;
      } else {
        (img as HTMLImageElement).src = attributes.imagePath;
      }

      if (attributes.decode && img.decode) {
        return img.decode().then(() => getSrc(img));
      }

      return new Promise((resolve, reject) => {
        img.onload = () => resolve(getSrc(img));
        img.onerror = () => reject(null);
      });
    }
    return super.loadImage(attributes) as Promise<string>;
  }
}
