<ng-container *ngIf="(isMobile$ | async) === false; else mobileSort">
  <div data-testid="sorting-desktop" class="mr-small-t ms-small-b">
    <fcom-tabs
      #tabs
      [prevButtonAriaLabel]="'carousel.previous' | finLocalization"
      [nextButtonAriaLabel]="'carousel.next' | finLocalization"
      [theme]="TabTheme.CLASSIC"
      [layoutType]="TabLayoutType.STRETCH"
      (clickedTab)="onSortChangeFromTabs($event)"
    >
      <fcom-tab
        *ngIf="showBestFlightsTab"
        [title]="
          (resultsAmount === 1 ? 'flightSorting.bestFlights.singular' : 'flightSorting.bestFlights.plural')
            | finLocalization
        "
        [data]="SortBy.BEST"
        [subtitle]="
          shouldShowTabSubtitle
            ? ('flightSorting.flightsFrom'
              | finLocalization: { amount: { amount: bestFlightPrice, currencyCode } | finPrice })
            : '-'
        "
        [contentPopover]="contentPopover"
        [active]="(selectedSortBy$ | async) === SortBy.BEST"
      >
      </fcom-tab>
      <fcom-tab
        *ngIf="true"
        [title]="'flightSorting.lowestPrice' | finLocalization"
        [data]="SortBy.PRICE"
        [subtitle]="
          shouldShowTabSubtitle
            ? ('flightSorting.flightsFrom'
              | finLocalization
                : {
                    amount: showPoints
                      ? ('award.points' | finLocalization: { pointsAmount: lowestPrice | finPoints })
                      : ({ amount: lowestPrice, currencyCode } | finPrice),
                  })
            : '-'
        "
        [active]="(selectedSortBy$ | async) === SortBy.PRICE"
      >
      </fcom-tab>
      <fcom-tab
        *ngIf="true"
        [title]="'flightSorting.fastest' | finLocalization"
        [data]="SortBy.DURATION"
        [subtitle]="
          shouldShowTabSubtitle
            ? ('flightSorting.flightsFrom'
              | finLocalization
                : {
                    amount: showPoints
                      ? ('award.points' | finLocalization: { pointsAmount: fastestFlightPrice | finPoints })
                      : ({ amount: fastestFlightPrice, currencyCode } | finPrice),
                  })
            : '-'
        "
        [active]="(selectedSortBy$ | async) === SortBy.DURATION"
      >
      </fcom-tab>
    </fcom-tabs>

    <ng-template #contentPopover>
      <h2 class="align-left font-body-1 bold-type nordic-blue-900-text">
        {{ 'flightSorting.tooltip.title' | finLocalization }}
      </h2>
      <p class="pr-xxsmall-t regular-type align-left">{{ 'flightSorting.tooltip.text' | finLocalization }}</p>
    </ng-template>
  </div>
</ng-container>

<ng-template #mobileSort>
  <button
    data-testid="sorting-mobile"
    class="sort-mobile-button ms-small-y flex flex--no-wrap flex--center rounded-medium font-body-1 white-bg border nordic-blue-400-border"
    (click)="openSortingModal()"
  >
    <fcom-icon
      class="icon-small ms-xxsmall-l flex flex--self-center"
      [name]="SvgLibraryIcon.SORT_DESCENDING"
    ></fcom-icon>
    <span class="ps-small-y ps-xxsmall-l">
      <ng-container [ngSwitch]="selectedSortBy$ | async">
        <span *ngIf="showBestFlightsTab">
          <span *ngSwitchCase="SortBy.BEST">{{
            (resultsAmount === 1 ? 'flightSorting.bestFlights.singular' : 'flightSorting.bestFlights.plural')
              | finLocalization
          }}</span>
        </span>
        <span *ngSwitchCase="SortBy.PRICE">{{ 'flightSorting.lowestPrice' | finLocalization }}</span>
        <span *ngSwitchCase="SortBy.DURATION">{{ 'flightSorting.fastest' | finLocalization }}</span>
      </ng-container>
    </span>
  </button>

  <fcom-modal
    [(open)]="modalOpen"
    [title]="'flightSorting.sortingCriteria' | finLocalization"
    (close)="closeSortingModal()"
    data-testid="sorting-mobile-modal"
  >
    <div class="ps-medium flex flex--column flex--left-center">
      <fcom-radio-item-group
        [options]="sortingOptions"
        [parentForm]="sortingForm"
        [controlName]="'flightSorting'"
        [translationPrefix]="'flightSorting'"
        [titleText]="false"
      >
      </fcom-radio-item-group>
    </div>
  </fcom-modal>
</ng-template>
