import { Component, Input, OnInit } from '@angular/core';

import { CmsDisclaimer } from '@fcom/core-api/interfaces';

@Component({
  selector: 'fin-disclaimer',
  templateUrl: 'disclaimer.component.html',
  styleUrls: ['disclaimer.component.scss'],
})
export class DisclaimerComponent implements OnInit {
  @Input()
  disclaimer: CmsDisclaimer;

  backgroundColor: string;

  ngOnInit(): void {
    this.backgroundColor = this.disclaimer.backgroundColor
      ? `${this.disclaimer.backgroundColor}-bg`
      : 'nordic-blue-50-bg';
  }
}
