/**
 * Todo: move this file to a more logical location. It is only imported in 2 locations in @fcom/common/components, so maybe move under @fcom/common
 * Similar with CountryState, CountryLanguage interfaces
 */

import { CountryState, CountryLanguage } from '../interfaces';

// declare const DefinedConstants;
// const ALLOWED_LOCALES = DefinedConstants.ALLOWED_LOCALES;
// const SHOW_IN_LANGUAGE_SELECTION = DefinedConstants.SHOW_IN_LANGUAGE_SELECTION;
const ALLOWED_LOCALES = {
  'master-en': 'en_GB',
  'at-de': 'de_AT',
  'at-en': 'en_AT',
  'au-en': 'en_AU',
  'be-en': 'en_BE',
  'ca-en': 'en_CA',
  'ca-fr': 'fr_CA',
  'ch-de': 'de_CH',
  'ch-en': 'en_CH',
  'ch-fr': 'fr_CH',
  'cn-en': 'en_CN',
  'cn-zh': 'zh_CN',
  'cz-en': 'en_CZ',
  'de-de': 'de_DE',
  'de-en': 'en_DE',
  'dk-da': 'da_DK',
  'dk-en': 'en_DK',
  'ee-en': 'en_EE',
  'ee-et': 'et_EE',
  en: 'en',
  'es-en': 'en_ES',
  'es-es': 'es_ES',
  'fi-en': 'en_FI',
  'fi-fi': 'fi_FI',
  'fi-sv': 'sv_FI',
  'fr-en': 'en_FR',
  'fr-fr': 'fr_FR',
  'gb-en': 'en_GB',
  'hk-zh': 'zh_HK',
  'hk-en': 'en_HK',
  'hu-en': 'en_HU',
  'is-en': 'en_IS',
  'ie-en': 'en_IE',
  'il-en': 'en_IL',
  'in-en': 'en_IN',
  'it-en': 'en_IT',
  'it-it': 'it_IT',
  'jp-en': 'en_JP',
  'jp-ja': 'ja_JP',
  'kr-en': 'en_KR',
  'kr-ko': 'ko_KR',
  'lt-en': 'en_LT',
  'lv-en': 'en_LV',
  'nl-en': 'en_NL',
  'no-en': 'en_NO',
  'no-no': 'no_NO',
  'pl-en': 'en_PL',
  'pl-pl': 'pl_PL',
  'ru-en': 'en_RU',
  'ru-ru': 'ru_RU',
  'se-en': 'en_SE',
  'se-sv': 'sv_SE',
  'sg-en': 'en_SG',
  'th-en': 'en_TH',
  'us-en': 'en_US',
};
const SHOW_IN_LANGUAGE_SELECTION = [
  'au-en',
  'at-de',
  'at-en',
  'be-en',
  'ca-en',
  'ca-fr',
  'cn-zh',
  'cn-en',
  'cz-en',
  'dk-da',
  'dk-en',
  'ee-et',
  'ee-en',
  'fi-fi',
  'fi-sv',
  'fi-en',
  'fr-fr',
  'fr-en',
  'de-de',
  'de-en',
  'hk-zh',
  'hk-en',
  'hu-en',
  'is-en',
  //'in-en', India site temporarily removed due to legal reasons 9/2023
  'ie-en',
  'il-en',
  'it-it',
  'it-en',
  'jp-ja',
  'jp-en',
  'kr-ko',
  'kr-en',
  'lv-en',
  'lt-en',
  'no-no',
  'no-en',
  'pl-pl',
  'pl-en',
  'ru-ru',
  'ru-en',
  'sg-en',
  'es-es',
  'es-en',
  'se-sv',
  'se-en',
  'ch-de',
  'ch-fr',
  'ch-en',
  'th-en',
  'nl-en',
  'gb-en',
  'us-en',
  'en',
];

const languageConfs: CountryState[] = [
  {
    name: 'Australia',
    code: 'AU',
    languages: [{ name: 'English', code: 'au-en' }],
  },
  {
    name: 'Austria',
    code: 'AT',
    languages: [
      { name: 'Deutsch', code: 'at-de' },
      { name: 'English', code: 'at-en' },
    ],
  },
  {
    name: 'Belgium',
    code: 'BE',
    languages: [{ name: 'English', code: 'be-en' }],
  },
  {
    name: 'Canada',
    code: 'CA',
    languages: [
      { name: 'English', code: 'ca-en' },
      { name: 'Français', code: 'ca-fr' },
    ],
  },
  {
    name: 'China',
    code: 'CN',
    languages: [
      { name: '简体中文', code: 'cn-zh', translation: 'Chinese' },
      { name: 'English', code: 'cn-en' },
    ],
  },
  {
    name: 'Czech Republic',
    code: 'CZ',
    languages: [{ name: 'English', code: 'cz-en' }],
  },
  {
    name: 'Denmark',
    code: 'DK',
    languages: [
      { name: 'Dansk', code: 'dk-da' },
      { name: 'English', code: 'dk-en' },
    ],
  },
  {
    name: 'Estonia',
    code: 'EE',
    languages: [
      { name: 'Eesti', code: 'ee-et' },
      { name: 'English', code: 'ee-en' },
    ],
  },
  {
    name: 'Finland',
    code: 'FI',
    languages: [
      { name: 'Suomeksi', code: 'fi-fi' },
      { name: 'Svenska', code: 'fi-sv' },
      { name: 'English', code: 'fi-en' },
    ],
  },
  {
    name: 'France',
    code: 'FR',
    languages: [
      { name: 'Français', code: 'fr-fr' },
      { name: 'English', code: 'fr-en' },
    ],
  },
  {
    name: 'Germany',
    code: 'DE',
    languages: [
      { name: 'Deutsch', code: 'de-de' },
      { name: 'English', code: 'de-en' },
    ],
  },
  {
    name: 'Hong Kong, China',
    code: 'HK',
    languages: [
      { name: '繁體中文', code: 'hk-zh' },
      { name: 'English', code: 'hk-en' },
    ],
  },
  {
    name: 'Hungary',
    code: 'HU',
    languages: [{ name: 'English', code: 'hu-en' }],
  },
  {
    name: 'Iceland',
    code: 'IS',
    languages: [{ name: 'English', code: 'is-en' }],
  },
  {
    name: 'India',
    code: 'IN',
    languages: [{ name: 'English', code: 'in-en' }],
  },
  {
    name: 'Ireland',
    code: 'IE',
    languages: [{ name: 'English', code: 'ie-en' }],
  },
  {
    name: 'Israel',
    code: 'IL',
    languages: [{ name: 'English', code: 'il-en' }],
  },
  {
    name: 'Italy',
    code: 'IT',
    languages: [
      { name: 'Italiano', code: 'it-it' },
      { name: 'English', code: 'it-en' },
    ],
  },
  {
    name: 'Japan',
    code: 'JP',
    languages: [
      { name: '日本語', code: 'jp-ja', translation: 'Japanese' },
      { name: 'English', code: 'jp-en' },
    ],
  },
  {
    name: 'Korea',
    code: 'KR',
    languages: [
      { name: '한국어', code: 'kr-ko', translation: 'Korean' },
      { name: 'English', code: 'kr-en' },
    ],
  },
  {
    name: 'Latvia',
    code: 'LV',
    languages: [{ name: 'English', code: 'lv-en' }],
  },
  {
    name: 'Lithuania',
    code: 'LT',
    languages: [{ name: 'English', code: 'lt-en' }],
  },
  {
    name: 'The Netherlands',
    code: 'NL',
    languages: [{ name: 'English', code: 'nl-en' }],
  },
  {
    name: 'Norway',
    code: 'NO',
    languages: [
      { name: 'Norsk', code: 'no-no' },
      { name: 'English', code: 'no-en' },
    ],
  },
  {
    name: 'Poland',
    code: 'PL',
    languages: [
      { name: 'Polski', code: 'pl-pl' },
      { name: 'English', code: 'pl-en' },
    ],
  },
  {
    name: 'Russia',
    code: 'RU',
    languages: [
      { name: 'Русский', code: 'ru-ru', translation: 'Russian' },
      { name: 'English', code: 'ru-en' },
    ],
  },
  {
    name: 'Singapore',
    code: 'SG',
    languages: [{ name: 'English', code: 'sg-en' }],
  },
  {
    name: 'Spain',
    code: 'ES',
    languages: [
      { name: 'Español', code: 'es-es' },
      { name: 'English', code: 'es-en' },
    ],
  },
  {
    name: 'Sweden',
    code: 'SE',
    languages: [
      { name: 'Svenska', code: 'se-sv' },
      { name: 'English', code: 'se-en' },
    ],
  },
  {
    name: 'Switzerland',
    code: 'CH',
    languages: [
      { name: 'Deutsch', code: 'ch-de' },
      { name: 'Français', code: 'ch-fr' },
      { name: 'English', code: 'ch-en' },
    ],
  },
  {
    name: 'Thailand',
    code: 'TH',
    languages: [{ name: 'English', code: 'th-en' }],
  },
  {
    name: 'United Kingdom',
    code: 'GB',
    languages: [{ name: 'English', code: 'gb-en' }],
  },
  {
    name: 'United States',
    code: 'US',
    languages: [{ name: 'English', code: 'us-en' }],
  },
  {
    name: 'International',
    code: 'INT',
    languages: [{ name: 'English', code: 'en' }],
  },
  {
    name: 'International Master',
    code: 'MASTER',
    languages: [{ name: 'English', code: 'master-en' }],
  },
];

const filterLanguageConfs = (lconfs: CountryState[], filterFunc: (arg0: CountryLanguage) => boolean): CountryState[] =>
  lconfs
    .map((ls: CountryState) => ({
      ...ls,
      languages: ls.languages.filter(filterFunc),
    }))
    .filter((ls) => ls.languages.length > 0);

const allowedConfs: CountryState[] = filterLanguageConfs(languageConfs, (l) => l.code in ALLOWED_LOCALES);
const shownConfs: CountryState[] = filterLanguageConfs(
  languageConfs,
  (l) => SHOW_IN_LANGUAGE_SELECTION.indexOf(l.code) !== -1
);

export { allowedConfs, shownConfs };
