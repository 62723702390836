import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { map, filter, startWith, switchMap, withLatestFrom } from 'rxjs/operators';

import { CmsCollection, CmsContent, CmsTemplate } from '@fcom/core-api';
import { safeMap } from '@fcom/core/utils';
import { SelectOption } from '@fcom/common/interfaces';
import { finShare } from '@fcom/rx';

@Component({
  selector: 'cms-content-switcher-dropdown',
  templateUrl: './cms-content-switcher-dropdown.component.html',
  styleUrls: ['./cms-content-switcher-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsContentSwitcherDropdownComponent implements OnInit {
  @Input()
  content$: Observable<CmsCollection>;

  contentOptions$: Observable<SelectOption[]>;
  contentForm$: Observable<UntypedFormGroup>;
  selectedContent$: Observable<CmsTemplate>;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.contentOptions$ = this.content$.pipe(
      map((c) =>
        c.items.map((ct) => ({
          name: ct.teaserTitle,
          value: ct.teaserTitle,
        }))
      )
    );

    const initialValue$ = this.content$.pipe(
      filter((c) => c.items.length > 0),
      map((c) => safeMap(c.items[0], (i) => i.teaserTitle))
    );

    this.contentForm$ = initialValue$.pipe(
      map((content) => this.fb.group({ content })),
      finShare()
    );

    this.selectedContent$ = this.contentForm$.pipe(
      withLatestFrom(initialValue$),
      switchMap(([contentForm, startValue]) => contentForm.get('content').valueChanges.pipe(startWith(startValue))),
      withLatestFrom(this.content$.pipe(map((c) => c.items))),
      map(([selectedContent, contentData]) =>
        contentData.find((item: CmsContent) => item.teaserTitle === selectedContent)
      ),
      filter(Boolean),
      map((template) => ({
        header: null,
        main: [template as CmsCollection],
        footer: null,
      }))
    );
  }
}
