<div class="cf">
  <a
    href="{{ link$ | finMap: 'url' | async }}"
    class="btn btn--arrow nordic-blue-900-bg white-text pr-xsmall col-1-1"
    rel="noopener noreferrer"
    target="_blank"
  >
    {{ link$ | finMap: 'text' | async }}
  </a>
</div>
