import { shownConfs } from '@fcom/core/reducers/langs';
import { CountryLanguage, CountryState } from '@fcom/core/interfaces';

import { Links } from '../services';

const parseLocalizedPathFromUrl = (langCode: string, url: string): string => {
  const everythingBeforeLangCode = new RegExp(`.+?(?=/${langCode})`);
  return url ? url.replace(everythingBeforeLangCode, '') : `/${langCode}`;
};

export const getLocalizedLinkForLangCode = (langCode: string, links: Links = {}, prevPageUrl: string): string => {
  const localizedCmsPageUrl = parseLocalizedPathFromUrl(langCode, links[langCode]);
  const localizedPrevPageUrl = prevPageUrl ? `/${langCode}${prevPageUrl}` : undefined;
  return localizedPrevPageUrl ?? localizedCmsPageUrl;
};

export const getCountryStates = (): CountryState[] =>
  shownConfs
    .map((country) => ({
      ...country,
      languages: country.languages.map((language): CountryLanguage => {
        const translation = language.translation ? ` (${language.translation})` : '';
        return {
          ...language,
          label: `${country.name}, ${language.name}${translation}`,
          ISOCode: language.code.split('-').pop(),
        };
      }),
    }))
    .reduce((countryList, country) => {
      // Set Finland as first country in the list
      if (country.code === 'FI') {
        countryList.unshift(country);
      } else {
        countryList.push(country);
      }
      return countryList;
    }, []);
