import { NgModule, Optional, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';

import { UiTranslateModule } from '@fcom/ui-translate';

import { PopoverModule } from '../popover';
import { IconsModule } from '../icons/icons.module';
import { CarouselModule } from '../carousel/carousel.module';
import { TabsComponent } from './tabs/index';
import { TabComponent } from './tab/tab.component';

@NgModule({
  declarations: [TabsComponent, TabComponent],
  imports: [CommonModule, IconsModule, CarouselModule, PopoverModule, UiTranslateModule],
  exports: [TabsComponent, TabComponent],
  providers: [],
})
export class TabsModule {
  constructor(@Optional() @Inject(ANIMATION_MODULE_TYPE) animations: string) {
    if (!animations) {
      throw new Error('You need to import the BrowserAnimationsModule or NoopAnimationsModule in your application!');
    }
  }
}
