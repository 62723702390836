<div #container class="scroll-container">
  <button
    #scrollHandleLeft
    class="scroll-container-button scroll-container-button-left border nordic-blue-900-border circle white-bg shadow ps-xxsmall"
    (click)="scrollLeft()"
    [tabindex]="disableKeyboardNavigation ? -1 : 0"
    data-testid="scroll-left-button"
  >
    <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.CHEVRON_LEFT"></fcom-icon>
  </button>

  <button
    #scrollHandleRight
    class="scroll-container-button scroll-container-button-right border nordic-blue-900-border circle white-bg shadow ps-xxsmall"
    (click)="scrollRight()"
    [tabindex]="disableKeyboardNavigation ? -1 : 0"
    data-testid="scroll-right-button"
  >
    <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.CHEVRON_RIGHT"></fcom-icon>
  </button>

  <ng-content></ng-content>
</div>
