import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { type IconSet } from '../../icons';
import { type AriaOptions } from '../../interfaces';
import { IconButtonSize, IconButtonTheme } from './enums';
import { ButtonType } from '../button';

@Component({
  selector: 'fcom-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss', '../buttons-shared-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent {
  /**
   * The size of the button, currently 3 static sizes
   */
  @Input() size: IconButtonSize = IconButtonSize.MEDIUM;

  /**
   * There are 8 themes available right now that renders different backgrounds, font colors and borders
   */
  @Input() theme: IconButtonTheme = IconButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE;

  /**
   * An icon to include, with its category and name
   */
  @Input({ required: true }) icon: IconSet;
  /**
   * The aria tags that must be added to a button or link
   */
  @Input({ required: true }) aria: AriaOptions = {};

  /**
   * Whether the button should be disabled or not
   */
  @Input() disabled = false;

  /**
   * Whether the button should appear as disabled but be enabled
   */
  @Input() appearDisabled = false;

  /**
   * The pre-translated hover text to display
   */
  @Input() title?: string;

  /**
   * If button should render as an a tag, then pass in the url
   */
  @Input() href?: string;

  /**
   * If link, should it open in a new tab
   */
  @Input() openInNewTab?: boolean;

  /**
   * If link, is it external
   */
  @Input() externalLink?: boolean;

  /**
   * The type of button, for example "button" or "submit"
   */
  @Input() type: ButtonType = ButtonType.BUTTON;

  @Output() clickBtn = new EventEmitter<Event>();

  public readonly IconButtonSize = IconButtonSize;
  public readonly IconButtonTheme = IconButtonTheme;

  onClick(event: Event): void {
    if (this.disabled || this.aria.disabled) {
      event.preventDefault();
      return;
    }

    this.clickBtn.emit(event);
  }
}
