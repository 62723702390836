/* eslint-disable no-underscore-dangle */
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { safeSelect, StateSelector } from '@fcom/core/selectors/selector-utils';

import { PaymentState, PaymentStatus } from '../../interfaces/payment.interface';
import { CommonBookingFeatureState, CommonBookingState } from '../store.interface';
import { COMMON_BOOKING_FEATURE_KEY } from '../constants';

const _selectCommonBookingState = createFeatureSelector<CommonBookingFeatureState, CommonBookingState>(
  COMMON_BOOKING_FEATURE_KEY
);
const _paymentState = createSelector(_selectCommonBookingState, (state: CommonBookingState) => state?.payment);
const _paymentStatus = createSelector(_paymentState, (_ps: PaymentState) => _ps?.paymentStatus);
const _paymentIds = createSelector(_paymentState, (_ps: PaymentState) => _ps?.paymentIds);
const _paymentRedirectionUrl = createSelector(_paymentState, (_ps: PaymentState) => _ps?.redirectionUrl);

export const paymentStatus = (): StateSelector<CommonBookingFeatureState, PaymentStatus> => safeSelect(_paymentStatus);

export const paymentIdsPaid = (): StateSelector<CommonBookingFeatureState, string[]> => safeSelect(_paymentIds);
export const paymentRedirectionUrl = (): StateSelector<CommonBookingFeatureState, string> =>
  safeSelect(_paymentRedirectionUrl);
