export enum TabTheme {
  DEFAULT = 'default',
  CLASSIC = 'classic',
  CHIPS = 'chips',
}

export enum TabLayoutType {
  DEFAULT = 'default',
  IN_MODAL = 'in-modal',
  STRETCH = 'stretch',
  HAS_BACKGROUND = 'has-background',
}

export enum TabIconPosition {
  TOP = 'top',
  LEFT = 'left',
}
