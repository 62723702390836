import { Injectable, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Event, NavigationEnd, Router } from '@angular/router';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { unsubscribe } from '@fcom/core/utils';
import { pathIsAnyHardcodedPath, urlWithoutQueryString } from '@fcom/core/utils/app-url-utils';

const CSS_CLASS_PREFIX = 'content-wrapper-route-';
const CSS_CLASS_DEFAULT = 'none';
const CSS_CLASS_FRONT_PAGE = 'front-page';
const CSS_CLASS_DYNAMIC = 'dynamic';

/**
 * Provides the HTML element class for the application's content wrapper element
 * as an Observable
 * @class NavigatedPathToContentWrapperService
 */
@Injectable()
export class NavigatedPathToContentWrapperService implements OnDestroy {
  private _contentWrapperNavigatedClass$: BehaviorSubject<string> = new BehaviorSubject(
    CSS_CLASS_PREFIX + CSS_CLASS_DEFAULT
  );
  private subscriptions: Subscription;
  private router: Router;

  constructor(@Inject(PLATFORM_ID) private platform: object) {}

  /**
   * HTML element class for the application's content wrapper element
   */
  public get contentWrapperNavigatedClass$(): Observable<string> {
    return this._contentWrapperNavigatedClass$;
  }

  public setRouter(router: Router) {
    this.router = router;
    if (isPlatformBrowser(this.platform)) {
      this.subscribeToRouter();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  /**
   * Set <body><fin-app><div id="content-wrapper">'s class based on the navigated route,
   * allowing us to display/hide the chat bubble based on the navigated route
   */
  private subscribeToRouter() {
    this.subscriptions = this.router.events
      .pipe(filter((event: Event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.setClass(event.urlAfterRedirects);
      });
  }

  private setClass(routerEventPath: string) {
    const path = urlWithoutQueryString(routerEventPath);
    if (pathIsAnyHardcodedPath(path)) {
      const navigatedMainArea = path.split('/')[2] || CSS_CLASS_FRONT_PAGE;
      this._contentWrapperNavigatedClass$.next(CSS_CLASS_PREFIX + navigatedMainArea);
    } else {
      this._contentWrapperNavigatedClass$.next(CSS_CLASS_PREFIX + CSS_CLASS_DYNAMIC);
    }
  }
}
