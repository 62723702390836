import { AppState } from '@fcom/core';
import { FareFamily, FinnairOrder, TourOperatorCode } from '@fcom/dapi/api/models';
import { DapiErrorStatus, IdAndHash, ServicesState } from '@fcom/dapi/interfaces';
import { SeatMapState } from '@fcom/common/interfaces/seat-map.interface';

import { PaymentState } from '../interfaces';
import { COMMON_BOOKING_FEATURE_KEY } from './constants';

export interface PassengerNameRecord {
  recLoc: string;
  lastName?: string;
  firstName?: string;
  tourOperatorCode?: TourOperatorCode | undefined;
}

export enum OrderStatus {
  /** The order has not been initialized */
  INITIAL,

  /** The order is created, but is waiting for data to be refreshed from server */
  PENDING,

  /** The order failed to load from the given location */
  LOAD_ERROR,

  /** The order has been created and is ok */
  OK,
}

export enum OrderPartStatus {
  OK,
  UPDATING,
  ERROR,
}
export interface UpdatingOrderPart {
  [category: string]: OrderPartStatus;
}

export interface OrderState {
  /** The current status of the order. */
  orderStatus: OrderStatus;

  /** This is the actual order data from the `GET ${orderUrl}` response. */
  orderData?: FinnairOrder;

  /** Error response status */
  orderResponseErrorStatus?: DapiErrorStatus;

  orderFetchInformation?: IdAndHash;

  isUpdating?: UpdatingOrderPart;

  PNR?: PassengerNameRecord;

  acceptTerms?: boolean;
}

export interface DisruptionState {
  selectedBoundId?: string;
}

export interface UpgradeOfferState {
  currentFareFamily?: FareFamily;
  upgradeFareFamily?: FareFamily;
  outboundId?: string;
  inboundId?: string;
  timestamp?: number;
  outboundPrice?: string;
  outboundUpgradePrice?: string;
  inboundPrice?: string;
  inboundUpgradePrice?: string;
  totalUpgradePrice?: number;
}

export interface RedirectState {
  pnr: PassengerNameRecord;
}

export interface CommonBookingState {
  services?: ServicesState;
  order?: OrderState;
  seatMap?: SeatMapState;
  payment?: PaymentState;
  disruption?: DisruptionState;
  upgradeOffer?: UpgradeOfferState;
  redirect?: RedirectState;
}

export interface CommonBookingFeatureState extends AppState {
  readonly [COMMON_BOOKING_FEATURE_KEY]: CommonBookingState;
}

export interface QuantityForFragmentAndPassenger {
  [fragmentId: string]: {
    [passengerId: string]: number;
  };
}
