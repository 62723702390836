import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiTranslateModule } from '@fcom/ui-translate';
import { PipesModule } from '@fcom/common/pipes/pipes.module';

import { ButtonModule } from '../buttons/button.module';
import { IconsModule } from '../icons/icons.module';
import { ExpandModule } from '../expand/expand.module';
import { ImagesModule } from '../images/images.module';
import { NotificationsModule } from '../notifications/notifications.module';
import { TripCardComponent } from './trip-card/trip-card.component';
import { FlightCardComponent } from './flight-card/flight-card.component';
import { UnifiedFlightObjectComponent } from './unified-flight-object/unified-flight-object.component';
import { TimelineComponent } from './timeline/timeline.component';
import { TagModule } from '../tag';

const components = [TripCardComponent, FlightCardComponent, UnifiedFlightObjectComponent, TimelineComponent];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    IconsModule,
    UiTranslateModule,
    NotificationsModule,
    ExpandModule,
    ImagesModule,
    TagModule,
    PipesModule,
    ButtonModule,
  ],
  exports: [...components],
  providers: [],
})
export class FlightsModule {}
