import { Component, Input } from '@angular/core';

import { CmsContent } from '@fcom/core-api';

/**
 * Hero component for destination category page
 *
 * @example
 * <fin-destination-category-hero [content]="content"></fin-destination-category-hero>
 */
@Component({
  selector: 'fin-destination-category-hero',
  templateUrl: './destination-category-hero.component.html',
})
export class DestinationCategoryHeroComponent {
  @Input()
  content: CmsContent;
}
