import { NgModule, Optional, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';

import { ExpandComponent } from './expand.component';

@NgModule({
  declarations: [ExpandComponent],
  imports: [CommonModule],
  exports: [ExpandComponent],
  providers: [],
})
export class ExpandModule {
  constructor(@Optional() @Inject(ANIMATION_MODULE_TYPE) animations: string) {
    if (!animations) {
      throw new Error('You need to import the BrowserAnimationsModule or NoopAnimationsModule in your application!');
    }
  }
}
