<fin-fare-family-table
  [fareFamilies]="fareFamilies"
  [showKoreaNotification]="showKoreaNotification"
></fin-fare-family-table>

<article class="font-body-1">
  <section class="mr-xsmall-t">
    <div
      class="ticket-type-rules-info nordic-blue-900-text mr-medium-y ms-medium-x font-body-1"
      [innerHTML]="'bookingSearch.boundSelection.rulesMayVary' | finLocalization | finSafe: 'html'"
    ></div>
    <fcom-notification *ngIf="hasBusLeg" class="block mr-xsmall-b">
      {{ 'bookingSearch.boundSelection.ticketTypeBusNotification' | finLocalization }}
    </fcom-notification>
    <fin-expand
      [id]="'generalFareFamilyRulesAndRestrictions'"
      [key]="'generalFareFamilyRulesAndRestrictions'"
      [finElemTracker]="'benefits-more-information'"
      [finElemContext]="TRACKING_CONTEXT"
      [finElemType]="ElementTypes.ACCORDION"
      #expand
    >
      <div class="trigger">
        <div class="nordic-blue-900-text">
          <h3 class="font-heading-3 float-left">
            {{ 'bookingSearch.boundSelection.informationAboutBenefits' | finLocalization }}
          </h3>
          <fcom-icon
            class="inline-block rotate float-right icon-medium"
            [class.rotate-active]="expand.isOpen$ | async"
            [name]="SvgLibraryIcon.CHEVRON_DOWN"
          ></fcom-icon>
        </div>
      </div>
      <div class="content pr-medium-y">
        <cms-unstyled-fragment
          class="benefits-information"
          [contentUrl$]="'fragments.benefitsInformation.url' | finLocalization | finToObservable"
        ></cms-unstyled-fragment>
      </div>
    </fin-expand>
  </section>
</article>
