import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { UiTranslateModule } from '@fcom/ui-translate';
import { IconsModule, ModalModule, ButtonModule, ToggleSwitchModule } from '@fcom/ui-components';
import { CmsModule } from '@fcom/cms';

import { DirectiveModule } from '../../directives';
import { FinFormsModule } from '../../forms';
import { PipesModule } from '../../pipes';
import { ComponentsModule } from '../components.module';
import { CookiePolicyComponent } from './cookie-policy.component';
import { CookieSettingsComponent } from './cookie-settings.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    DirectiveModule,
    PipesModule,
    CmsModule,
    FinFormsModule,
    FormsModule,
    UiTranslateModule,
    IconsModule,
    ModalModule,
    ButtonModule,
    ToggleSwitchModule,
  ],
  declarations: [CookiePolicyComponent, CookieSettingsComponent],
  exports: [CookiePolicyComponent],
})
export class CookiePolicyModule {}
