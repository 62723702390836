import { isPresent } from './utils';
import { pad } from './format';
import { LocalDate } from './tz-date';

const normalizeDate = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

const ISO_8601_DATE_TIME_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{1,3})?(([+-]\d{2}:\d{2})|Z)?$/i;
const ISO_8601_DATE_REGEX = /^\d{4}-\d{2}-\d{2}$/;
const TIME_REGEX = /^([0-1]?\d|2[0-3]):([0-5]?\d)$/;

export enum TimeUnit {
  millisecond,
  second,
  minute,
  hour,
  day,
  month,
  year,
}

export const toMonthId = (date: LocalDate): string => {
  return date.id.substr(0, 7);
};

export const MILLIS_IN_DAY = 24 * 60 * 60 * 1000;

export const MILLIS_IN_HOUR = 60 * 60 * 1000;

export const MILLIS_IN_MINUTE = 60 * 1000;

export const MILLIS_IN_SECOND = 1000;

export const startOfUTC = (date: Date, unit: TimeUnit): Date => {
  const copy: Date = new Date(date.getTime());
  if (unit >= TimeUnit.second) {
    copy.setUTCMilliseconds(0);
  }
  if (unit >= TimeUnit.minute) {
    copy.setUTCSeconds(0);
  }
  if (unit >= TimeUnit.hour) {
    copy.setUTCMinutes(0);
  }
  if (unit >= TimeUnit.day) {
    copy.setUTCHours(0);
  }
  if (unit >= TimeUnit.month) {
    copy.setUTCDate(1);
  }
  if (unit >= TimeUnit.year) {
    copy.setUTCMonth(0);
  }
  return copy;
};

export const startOf = (date: Date, unit: TimeUnit): Date => {
  const copy: Date = new Date(date.getTime());
  if (unit >= TimeUnit.second) {
    copy.setMilliseconds(0);
  }
  if (unit >= TimeUnit.minute) {
    copy.setSeconds(0);
  }
  if (unit >= TimeUnit.hour) {
    copy.setMinutes(0);
  }
  if (unit >= TimeUnit.day) {
    copy.setHours(0);
  }
  if (unit >= TimeUnit.month) {
    copy.setDate(1);
  }
  if (unit >= TimeUnit.year) {
    copy.setMonth(0);
  }
  return copy;
};

export const before = (date: Date, of: Date, unit: TimeUnit): boolean => {
  return startOf(date, unit).getTime() < startOf(of, unit).getTime();
};

export const after = (date: Date, of: Date, unit: TimeUnit): boolean => {
  return before(of, date, unit);
};

export const same = (date1: Date, date2: Date, unit: TimeUnit): boolean => {
  return startOf(date1, unit).getTime() === startOf(date2, unit).getTime();
};

export const diff = (date1: Date, date2: Date, unit?: TimeUnit): number => {
  const func = (fdate1: Date, fdate2: Date, divider: number): number => {
    return Math.floor((fdate2.getTime() - fdate1.getTime()) / divider);
  };

  switch (unit) {
    case TimeUnit.second:
      return func(date1, date2, MILLIS_IN_SECOND);
    case TimeUnit.minute:
      return func(date1, date2, MILLIS_IN_MINUTE);
    case TimeUnit.hour:
      return func(date1, date2, MILLIS_IN_HOUR);
    case TimeUnit.day:
      return func(normalizeDate(date1), normalizeDate(date2), MILLIS_IN_DAY);
    default:
      return func(date1, date2, 1);
  }
};

export const add = (date: Date, amount: number, unit: TimeUnit): Date => {
  const func = (callback: (date: Date) => void): Date => {
    const copy: Date = new Date(date.getTime());
    callback(copy);
    return copy;
  };
  switch (unit) {
    case TimeUnit.millisecond:
      return func((d) => {
        d.setMilliseconds(d.getMilliseconds() + amount);
      });
    case TimeUnit.second:
      return func((d) => {
        d.setSeconds(d.getSeconds() + amount);
      });
    case TimeUnit.minute:
      return func((d) => {
        d.setMinutes(d.getMinutes() + amount);
      });
    case TimeUnit.hour:
      return func((d) => {
        d.setHours(d.getHours() + amount);
      });
    case TimeUnit.day:
      return func((d) => {
        d.setDate(d.getDate() + amount);
      });
    case TimeUnit.month:
      return func((d) => {
        d.setMonth(d.getMonth() + amount);
      });
    case TimeUnit.year:
      return func((d) => {
        d.setFullYear(d.getFullYear() + amount);
      });
    default:
  }
};

export const subtract = (date: Date, amount: number, unit: TimeUnit): Date => add(date, -amount, unit);

export const quantityOfMonths = (startDate: LocalDate, endDate: LocalDate): number => {
  return (endDate.localYear - startDate.localYear) * 12 + (endDate.localMonth - startDate.localMonth) + 1;
};

export class WeekdayMap {
  private static NAMES: object = {
    sun: 0,
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
  };
  private content: number[] = [0, 1, 2, 3, 4, 5, 6];

  constructor(weekStartsOn: string) {
    const offset = WeekdayMap.NAMES[weekStartsOn.toLowerCase()] || 0;
    // Rotate weekdays to correct order
    // eslint-disable-next-line prefer-spread
    this.content.unshift.apply(this.content, this.content.splice(-offset));
  }

  get(day: number): number {
    const index = day >= 0 ? day : 7 + (day % 7);
    return this.content[index % 7];
  }
}

export const toISO8601 = (date: Date): string => {
  // TODO: is it really necessary to do this way? will the server accept Date.toISOString()?
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
};

export const toISO8601UTC = (date: Date): string => {
  return date.toISOString().substr(0, 10);
};

export const isISODateTimeString = (input: any): boolean => {
  return typeof input === 'string' && ISO_8601_DATE_TIME_REGEX.test(input);
};

export const isISODateString = (input: any): boolean => {
  return typeof input === 'string' && ISO_8601_DATE_REGEX.test(input);
};

/**
 * Returns true is a string is in time format, overwize false
 * Accepts formats "xx:yy", "x:yy", "xx:y", "x:y"
 */
export const isTime = (input: string): boolean => {
  return TIME_REGEX.test(input);
};

export const convertStringDatesToDateObjects = (convertData: any) => {
  if (!isPresent(convertData)) {
    return convertData;
  }
  if (typeof convertData === 'string') {
    if (isISODateTimeString(convertData)) {
      return new Date(convertData);
    }
    if (isISODateString(convertData)) {
      return new LocalDate(convertData);
    }
    return convertData;
  }
  if (typeof convertData === 'number' || convertData instanceof Date || typeof convertData === 'boolean') {
    return convertData;
  }
  if (Array.isArray(convertData)) {
    return convertData.map(convertStringDatesToDateObjects);
  }

  const target = Object.assign({}, convertData);

  Object.keys(convertData).forEach((key) => {
    const val = convertData[key];

    if (Array.isArray(val)) {
      target[key] = val.map(convertStringDatesToDateObjects);
    } else {
      target[key] = convertStringDatesToDateObjects(val);
    }
  });

  return target;
};
