import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';

import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { ProfileTier } from '@fcom/core-api/login';
import { AppState } from '@fcom/core';
import { profile } from '@fcom/core/selectors';
import { finShare } from '@fcom/rx';
import { unsubscribe } from '@fcom/core/utils';

@Directive({
  selector: '[finShowForProfileTiers]',
})
export class ShowForProfileTiersDirective implements OnDestroy {
  private hasView = false;
  private subscriptions = new Subscription();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store$: Store<AppState>
  ) {}

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('finShowForProfileTiers') set finShowForProfileTiers(tiers: ProfileTier[]) {
    if (!tiers || tiers.length === 0) {
      if (!this.hasView) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.hasView = true;
      }
    } else {
      this.subscriptions.add(
        this.store$.pipe(profile(), finShare()).subscribe((memberProfile) => {
          if (tiers.includes(memberProfile.tier)) {
            if (!this.hasView) {
              this.viewContainer.createEmbeddedView(this.templateRef);
              this.hasView = true;
            }
          } else {
            this.viewContainer.clear();
            this.hasView = false;
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }
}
