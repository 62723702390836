<ng-container *ngIf="hero$ | async as content">
  <fin-cms-json-content
    class="destinationHero"
    [template$]="{ main: [content] } | finToObservable"
  ></fin-cms-json-content>
</ng-container>

<section class="destination-main ms-xxlarge-y">
  <div class="grid-col-12 grid-max-width no-row-gap">
    <!-- Main left (first element) -->
    <ng-container *ngIf="mainLeft$ | async as content">
      <fin-cms-json-content
        class="destination-main__left col-1-12 md-col-1-8"
        [template$]="{ main: [content] } | finToObservable"
      ></fin-cms-json-content>
    </ng-container>
    <!-- Main right (2nd and 3rd element) -->
    <section class="destination-main__right col-1-12 md-col-9-12" *ngIf="mainRight$ | async as content">
      <ng-container *ngFor="let item of content">
        <fin-cms-json-content [template$]="{ main: [item] } | finToObservable"></fin-cms-json-content>
        <hr role="presentation" />
      </ng-container>
    </section>
    <!-- Main full width (rest of the items) -->
    <ng-container *ngIf="remainingContent$ | async">
      <fin-cms-json-content
        class="destination-main__bottom col-1-12 ms-xxlarge-y"
        [template$]="remainingContent$"
      ></fin-cms-json-content>
    </ng-container>
  </div>
</section>
