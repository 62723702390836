import { ChangeDetectionStrategy, Component, Input, OnDestroy, EventEmitter, OnInit, Output } from '@angular/core';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, Observable, Subscription, map } from 'rxjs';

import { AppConstants } from '@fcom/booking/app-constants';
import { Airline, GaContext, SelectOption } from '@fcom/common/interfaces';
import { ProfileType } from '@fcom/core-api/login';
import {
  ControlData,
  FinnairFrequentFlyerCardItem,
  FinnairPassengerCode,
  FinnairPassengerItem,
} from '@fcom/dapi/api/models';
import { ButtonTheme, IconPosition, ModalButtons, NotificationTheme } from '@fcom/ui-components';
import { PaxDetailsFormData } from '@fcom/dapi/interfaces';
import { FREQUENT_FLIER_AIRLINES, unsubscribe } from '@fcom/core/utils';

import { PassengerDetails, PaxFormFields } from '../../services';

const airlineToOption = ({ name, code }: Airline): SelectOption =>
  name && {
    name,
    value: code,
  };

@Component({
  selector: 'fin-passenger-info-renewed',
  templateUrl: './passenger-info-renewed.component.html',
  styleUrl: './passenger-info-renewed.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassengerInfoRenewedComponent implements OnInit, OnDestroy {
  readonly ButtonTheme = ButtonTheme;
  readonly C = AppConstants;
  readonly FIELDS = PaxFormFields;
  readonly FinnairPassengerCode = FinnairPassengerCode;
  readonly IconLibrary = IconLibrary;
  readonly IconPosition = IconPosition;
  readonly ModalButtons = ModalButtons;
  readonly NotificationTheme = NotificationTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TRACKING_CONTEXT = GaContext.BOOKING_FLOW;

  private subscription: Subscription = new Subscription();

  @Input()
  showEdit = false;

  @Input()
  isEditButtonDisabled$: Observable<boolean>;

  @Input()
  isEditButtonLoading$: Observable<boolean>;

  @Input()
  isMainPax: boolean;

  @Input()
  paxIndex: number;

  @Input()
  allFrequentFlyerCards$: Observable<FinnairFrequentFlyerCardItem[]>;

  @Input()
  controlData$: Observable<ControlData>;

  @Input()
  passengerFormGroup: PassengerDetails;

  @Input()
  extendedPassenger: FinnairPassengerItem & {
    paxTypeKey: string;
    cards: (FinnairFrequentFlyerCardItem & { airlineName: string })[];
  };

  @Input()
  profileType$: Observable<ProfileType>;

  @Input()
  selectableLeadPassengers$: Observable<SelectOption[]>;

  @Input()
  availableDays: { days$: Observable<number[]>; months: number[]; years: number[] };

  @Output()
  handleSave: EventEmitter<PaxDetailsFormData> = new EventEmitter<PaxDetailsFormData>();

  @Output()
  backToPaxDetails: EventEmitter<void> = new EventEmitter<void>();

  frequentFlyerAirlines$: Observable<SelectOption[]>;

  isEditingPassenger$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  initialFormData: BehaviorSubject<PaxDetailsFormData> = new BehaviorSubject<PaxDetailsFormData>(null);

  isFormSaved: BehaviorSubject<boolean> = new BehaviorSubject(false);

  ngOnInit(): void {
    this.frequentFlyerAirlines$ = this.setupAirlinePrefixes(this.paxIndex);
  }

  onOpenEditModal(): void {
    this.initialFormData = this.passengerFormGroup.group.getRawValue();
    this.isEditingPassenger$.next(true);
  }

  onCloseEditModal(): void {
    this.isEditingPassenger$.next(false);

    if (!this.isFormSaved.getValue()) {
      this.passengerFormGroup.group.reset(this.initialFormData);
    }

    this.isFormSaved.next(false);
  }

  onConfirmEditModal(): void {
    this.isEditingPassenger$.next(false);

    if (!this.passengerFormGroup.group.dirty) {
      return;
    } else if (!this.passengerFormGroup.group.disabled && this.passengerFormGroup.group.valid) {
      this.handleSave.emit(this.passengerFormGroup.group.getRawValue());
    }

    this.isFormSaved.next(true);
  }

  ngOnDestroy(): void {
    this.subscription = unsubscribe(this.subscription);
  }

  private setupAirlinePrefixes(passengerIndex) {
    return this.profileType$.pipe(
      map((pType: ProfileType) =>
        FREQUENT_FLIER_AIRLINES.filter((airline) => {
          return !(airline.name === 'Finnair' && pType === ProfileType.FINNAIR_ACCOUNT && passengerIndex === 0);
        }).map(airlineToOption)
      )
    );
  }
}
