import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { connectable, Observable, ReplaySubject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { ChatComponent, ChatGlobalVisibilityComponent } from '@fcom/chat';
import { FeedbackWidgetComponent } from '@fcom/common/components';
import { CookiePolicyComponent } from '@fcom/common/components/cookie-policy/cookie-policy.component';
import { ToasterComponent } from '@fcom/common/components/toaster/toaster.component';
import { PseudoLocalActions } from '@fcom/core/actions';
import { AppState } from '@fcom/core/interfaces';
import { isNotBlank, isPresent, isTruthy, unsubscribe } from '@fcom/core/utils';
import { PageMetaService } from '@fcom/common/services';
import { RootPaths, ROUTER_LANG_PARAM } from '@fcom/core/constants';
import { isNavigationEvent } from '@fcom/common/utils';

const getRouteFromUrl = (langCode: string, url: string): string => {
  if (!isPresent(langCode) || !isPresent(url)) {
    return '';
  }

  const everythingUntilAndIncludingLangCode = new RegExp(`^(.*?)${langCode}`);
  return url.replace(everythingUntilAndIncludingLangCode, '');
};

@Component({
  selector: 'fin-set-language',
  templateUrl: './language-setting-root.component.html',
})
export class LanguageSettingRootComponent implements OnDestroy {
  subscription = new Subscription();

  cookiePolicyType: typeof CookiePolicyComponent = CookiePolicyComponent;
  toasterComponentType: typeof ToasterComponent = ToasterComponent;
  chatComponentType: typeof ChatComponent = ChatComponent;
  chatGlobalVisibilityComponentType: typeof ChatGlobalVisibilityComponent = ChatGlobalVisibilityComponent;
  feedbackWidgetComponentType: typeof FeedbackWidgetComponent = FeedbackWidgetComponent;

  private insideBooking$: Observable<boolean>;

  constructor(
    private languageService: LanguageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store$: Store<AppState>,
    private pageMetaService: PageMetaService
  ) {
    const c$ = connectable(
      this.router.events.pipe(
        filter(isNavigationEvent),
        map((event: NavigationEnd) =>
          isTruthy(event.url.match(new RegExp(`^/${this.languageService.langValue}/booking`)))
        )
      ),
      {
        connector: () => new ReplaySubject<boolean>(1),
        resetOnDisconnect: false,
      }
    );

    c$.connect();
    this.insideBooking$ = c$;
    this.subscription.add(
      this.activatedRoute.params.pipe(map((p) => p['lang'])).subscribe((lang) => {
        this.languageService.setLang(lang);
      })
    );
    this.subscription.add(
      this.insideBooking$.subscribe((isInsideBooking) => {
        this.store$.dispatch(PseudoLocalActions.setValue({ key: 'isInsideBooking', data: isInsideBooking }));
      })
    );

    this.subscription.add(
      this.router.events.pipe(filter(isNavigationEvent)).subscribe(({ url }: NavigationEnd) => {
        this.updatePrevPageUrl(url);
      })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  private updatePrevPageUrl = (url: string): void => {
    const rootRoutes =
      this.router.config
        ?.find((r) => r.path === `:${ROUTER_LANG_PARAM}`)
        ?.children?.map((r) => r.path)
        .filter((route) => isNotBlank(route) && !route.startsWith(RootPaths.MANAGE_BOOKING_ROOT)) ?? [];
    const langCode = this.activatedRoute.snapshot.paramMap.get(ROUTER_LANG_PARAM);
    const route = getRouteFromUrl(langCode, url);
    const matchesRootRoute = rootRoutes.some((rr) => route === `/${rr}` || route.startsWith(`/${rr}/`));
    this.pageMetaService.prevPageUrl$.next(matchesRootRoute ? route : undefined);
  };
}
