import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { isNotBlank } from '@fcom/core/utils';
import { CmsTeaser } from '@fcom/core-api';
import { finShare, safeMap } from '@fcom/rx';

@Component({
  selector: 'cms-back-link',
  templateUrl: './cms-back-link.component.html',
  styleUrls: ['./cms-back-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsBackLinkComponent implements OnInit {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  content$: Observable<CmsTeaser>;

  title$: Observable<string>;

  ngOnInit(): void {
    const shared$ = this.content$.pipe(finShare());
    this.title$ = shared$.pipe(
      safeMap((t: CmsTeaser) => t.teaserTitle),
      filter(isNotBlank)
    );
  }
}
