import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject } from 'rxjs';

import { AirCalendarList, FinnairAirCalendarChangeListResponse, FinnairOrder } from '@fcom/dapi/api/models';
import { LocalDate, isPresent, rangeFrom } from '@fcom/core/utils';
import { Amount } from '@fcom/dapi';

import { GtmService } from '../../gtm';
import { GaContext, ElementTypes, ElementActions } from '../../interfaces';
import { isBookingCalendarList, isChangeItinerary } from '../../utils';

enum GtmDateAvailability {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
}

interface MatrixPrices {
  [departure: string]: {
    [arrival: string]: Amount;
  };
}

@Component({
  selector: 'fin-price-matrix',
  templateUrl: './price-matrix.component.html',
  styleUrls: ['./price-matrix.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceMatrixComponent implements OnInit {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input() isAward = false;
  @Input() calendarList: FinnairAirCalendarChangeListResponse | AirCalendarList;
  @Input() order?: FinnairOrder;

  @Output() selectedDates = new EventEmitter<{ dates: [string, string]; price?: Amount }>();

  selectedDeparture$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  selectedReturn$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  announceDates$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  showDeparture = false;
  showReturn = false;
  departureDates: string[];
  returnDates: string[];
  pricesForDates: MatrixPrices;
  isBookingCalendar: boolean;
  cheapestPrice: number;

  constructor(private gtmService: GtmService) {}

  ngOnInit(): void {
    this.isBookingCalendar = isBookingCalendarList(this.calendarList);
    const itinerary = this.isBookingCalendar
      ? (this.calendarList as AirCalendarList).finnairSearchParameters.itinerary
      : (this.calendarList as FinnairAirCalendarChangeListResponse).searchParameters.itinerary;
    const isChangeCalendar = isChangeItinerary(itinerary);

    this.showDeparture = isChangeCalendar ? itinerary[0]?.toChange : isPresent(itinerary[0]);
    this.showReturn = isChangeCalendar ? itinerary[1]?.toChange : isPresent(itinerary[1]);

    const departureDate = isChangeCalendar
      ? itinerary.find((b) => b.toChange).departureDate
      : itinerary[0].departureDate;
    this.departureDates = this.weekFromDate(new Date(departureDate));
    this.returnDates =
      this.showDeparture && this.showReturn ? this.weekFromDate(new Date(itinerary[1].departureDate)) : [''];

    this.pricesForDates = this.getMatrixPrices(this.calendarList, isChangeCalendar, this.isAward);
    this.cheapestPrice =
      isPresent(this.calendarList?.airCalendars) &&
      Math.min(...this.calendarList.airCalendars.map((item) => (this.isAward ? +item.totalPoints : +item.totalPrice)));

    const initialDeparture = this.departureDates[3];
    const initialReturn = this.returnDates[3] ?? '';
    this.selectedDeparture$.next(initialDeparture);
    this.selectedReturn$.next(initialReturn);
    const initialPrice = this.pricesForDates[initialDeparture]?.[initialReturn];

    if (initialPrice) {
      this.selectedDates.next({ dates: [initialDeparture, initialReturn], price: initialPrice });
    }

    if (isChangeCalendar) {
      const availability = initialPrice ? GtmDateAvailability.AVAILABLE : GtmDateAvailability.UNAVAILABLE;
      this.trackDateAvailability(availability);
    }
  }

  selectDate(departureDate: string, returnDate: string): void {
    if (this.hasPricesForDates(departureDate, returnDate)) {
      this.selectedDeparture$.next(departureDate);
      this.selectedReturn$.next(returnDate);
      this.selectedDates.next({
        dates: [departureDate, returnDate],
        price: this.isAward
          ? { amount: this.pricesForDates[departureDate][returnDate].amount, currencyCode: '' }
          : this.pricesForDates[departureDate][returnDate],
      });
      this.announceDates$.next(true);
    }
  }

  private weekFromDate(baseDate: Date): Array<string> {
    return rangeFrom(-3, 7).map((i) => LocalDate.forDateObj(baseDate).plusDays(i).toString());
  }

  private hasPricesForDates(departureDate: string, returnDate: string) {
    const departurePrices = this.pricesForDates[departureDate];
    if (departurePrices) {
      return Boolean(departurePrices[returnDate]);
    }
    return false;
  }

  private getMatrixPrices(
    calendarList: FinnairAirCalendarChangeListResponse | AirCalendarList,
    useBalancePrice: boolean,
    isAward = false
  ): MatrixPrices {
    return calendarList?.airCalendars?.reduce(
      (all: MatrixPrices, { arrival, balancePrice, departure, totalPrice, totalPoints }) => {
        const price: Amount = {
          amount: useBalancePrice ? balancePrice : isAward ? totalPoints : totalPrice,
          currencyCode: calendarList.currency,
        };

        return {
          ...all,
          [departure]: {
            ...all[departure],
            [arrival || '']: price,
          },
        };
      },
      {}
    );
  }

  private trackDateAvailability(availability: GtmDateAvailability): void {
    this.gtmService.trackElement(
      `view-pnr_change_dates-${availability}`,
      GaContext.MANAGE_BOOKING,
      ElementTypes.MODAL,
      undefined,
      ElementActions.VIEW,
      `recLoc-${this.order.otherInformation.analyticsToken}`
    );
  }
}
