import { LocalDate } from '@fcom/core/index';

import { DateSelection, Month } from './date.interface';

/**
 * Util method to find a month that contains date out of given months
 * @param months to be checked
 * @param targetDate represents the date we are searching for
 * @returns found month or undefined
 */
export const getMonthByDate = (months: Month[], targetDate: LocalDate): Month | undefined => {
  return months.find((month) => month.weeks.some((week) => week.days.some((day) => targetDate.equals(day?.date))));
};

/**
 * Checks if prev value and next value are same
 * @param prev DateSelection
 * @param next DateSelection
 * @returns
 */
export const areSelectedDatesChanged = (prev: DateSelection, next: DateSelection): boolean => {
  return (
    !(prev?.startDate === next.startDate || prev?.startDate?.equals(next.startDate)) ||
    !(prev?.endDate === next.endDate || prev?.endDate?.equals(next.endDate))
  );
};
