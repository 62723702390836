import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { isPlatformServer } from '@angular/common';

import { Store } from '@ngrx/store';

import { CartStatus } from '@fcom/dapi/interfaces';
import { snapshot } from '@fcom/rx';
import { BookingAppState } from '@fcom/common/interfaces/booking';

import { cartStatus, cartUrl } from '../store/selectors';
import { BookingCartService } from './booking-cart.service';

@Injectable()
export class BookingGuard implements CanActivate {
  private initialRoute = true;

  constructor(
    private cartService: BookingCartService,
    private store$: Store<BookingAppState>,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  canActivate(_route: ActivatedRouteSnapshot): boolean {
    if (isPlatformServer(this.platform)) {
      return true;
    }

    if (this.initialRoute) {
      const currentCartStatus: CartStatus = snapshot(this.store$.pipe(cartStatus()));
      const cUrl = snapshot(this.store$.pipe(cartUrl()));
      if ([CartStatus.INITIAL, CartStatus.OK].indexOf(currentCartStatus) === -1 && !!cUrl) {
        this.cartService.triggerLoadCart(cUrl);
      }
    }

    this.initialRoute = false;
    return true;
  }
}
