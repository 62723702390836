<div class="timeline__cell">
  <div class="timeline__left flex--column flex--center" role="presentation">
    <div class="flex--nogrow bubble bubble-plane-start">
      <fcom-icon
        class="icon-medium nordic-blue-900-text"
        [name]="flight.aircraft?.code === BUS_AIRCRAFT_CODE ? SvgLibraryIcon.BUS : SvgLibraryIcon.DEPARTURE"
        *ngIf="flight.aircraft?.code !== BUS_AIRCRAFT_CODE"
      ></fcom-icon>
    </div>
    <div class="flex-1 line line-solid"></div>
    <!-- This bubble will be rendered in flight stops later on -->
    <div
      class="flex--nogrow bubble bubble-plane-end"
      *ngIf="!flight.technicalStops || flight.technicalStops.length === 0"
    >
      <fcom-icon
        class="icon-medium nordic-blue-900-text"
        [name]="flight.aircraft?.code === BUS_AIRCRAFT_CODE ? SvgLibraryIcon.BUS : SvgLibraryIcon.ARRIVAL"
      ></fcom-icon>
    </div>
  </div>
  <div class="timeline__right pr-xsmall-l">
    <p
      [class.mr-xxsmall-t]="firstLeg"
      [class.mr-xxlarge-b]="flight.aircraft?.code === BUS_AIRCRAFT_CODE && flight.technicalStops && firstLeg"
    >
      <span class="is-vishidden">{{ 'departure' | finLocalization }}</span>
      <fcom-notification
        *ngIf="flightChanges?.notification"
        [theme]="NotificationTheme.ALERT"
        [layout]="NotificationLayout.BLOCK"
        [iconName]="SvgLibraryIcon.ALERT_BLOCK"
        class="block timeline__right medium-type heather-700-text pr-xsmall-b"
      >
        {{ 'MMB.notifications.' + flightChanges.notification + '.text' | finLocalization }}
      </fcom-notification>
      <fin-itinerary-timeline-leg-endpoint
        [endpoint]="flight.departure"
        [oldEndpointDateTime]="flightChanges?.departureDateTime"
        [oldEndpointLocationCode]="flightChanges?.departureLocationCode"
        [locations]="locations"
      ></fin-itinerary-timeline-leg-endpoint>
    </p>
    <ng-container
      *ngIf="
        flight.aircraft?.code !== BUS_AIRCRAFT_CODE ||
        (flight.aircraft?.code === BUS_AIRCRAFT_CODE && !firstLeg) ||
        (flight.aircraft?.code === BUS_AIRCRAFT_CODE && !flight.technicalStops && !lastLeg)
      "
    >
      <p class="light-type" *ngIf="flight.departure.terminal && flight.aircraft?.code !== BUS_AIRCRAFT_CODE">
        <span>{{ 'bookingSearch.flight.terminal' | finLocalization }}</span>
        <span *ngIf="flightChanges?.departureTerminal" class="grey-700-text strike-through mr-xxsmall-r">
          {{ flightChanges?.departureTerminal }}
        </span>
        <span>
          {{ flight.departure.terminal }}
        </span>
      </p>
      <p data-testid="itinenary-timeline-flight-details" class="nordic-blue-900-text mr-xsmall-y flex flex--column">
        <span *ngIf="flightChanges?.flightNumber" class="grey-700-text strike-through mr-xxsmall-r">
          {{ flightChanges.flightNumber }}</span
        >
        <span>{{ flight.flightNumber }}</span>

        <span>{{ 'bookingSummary.operatedBy' | finLocalization }} {{ flight.operatingAirline?.name }}</span>
        <span *ngIf="flightChanges?.operatingAirline" class="grey-700-text strike-through mr-xxsmall-r">
          {{ flightChanges.operatingAirline }}</span
        >

        <span *ngIf="flightChanges?.aircraft" class="grey-700-text strike-through mr-xxsmall-r">
          {{ flightChanges.aircraft }}
        </span>
        <span data-testid="flight-aircraft-name">{{ flight?.aircraft?.name }}</span>

        <span *ngIf="flightChanges?.duration" class="grey-700-text strike-through mr-xxsmall-r">
          {{ 'bookingSearch.duration' | finLocalization: (flightChanges.duration | finTemporaryDurationPipe) }}
        </span>
        <span>{{ 'bookingSearch.duration' | finLocalization: (flight.duration | finTemporaryDurationPipe) }}</span>
        <span *ngIf="flight?.cabinClass">
          {{ 'travelClass.' + flight.cabinClass?.toLowerCase() | finLocalization }}
          {{ flight.bookingClass }}
        </span>
        <fcom-tag
          *ngIf="isBookingFlow && isFlightUsingSAF && enableShowingSAF"
          class="ms-xxsmall-t"
          data-testid="itinenary-timeline-saf-chip"
          [theme]="TagTheme.SUSTAINABILITY"
          >{{ 'safNotification.chipText' | finLocalization }}</fcom-tag
        >
      </p>
    </ng-container>

    <ng-container *ngIf="!flight.technicalStops || flight.technicalStops.length === 0">
      <p class="mr-xsmall-t">
        <span class="is-vishidden">{{ 'arrival' | finLocalization }}</span>
        <fin-itinerary-timeline-leg-endpoint
          [endpoint]="flight.arrival"
          [oldEndpointDateTime]="flightChanges?.arrivalDateTime"
          [oldEndpointLocationCode]="flightChanges?.arrivalLocationCode"
          [locations]="locations"
        ></fin-itinerary-timeline-leg-endpoint>
      </p>
      <p class="light-type" *ngIf="flight.arrival.terminal && flight.aircraft?.code !== BUS_AIRCRAFT_CODE">
        {{ 'bookingSearch.flight.terminal' | finLocalization }}
        <span *ngIf="flightChanges?.arrivalTerminal" class="grey-700-text strike-through mr-xxsmall-r">
          {{ flightChanges.arrivalTerminal }}
        </span>
        {{ flight.arrival.terminal }}
      </p>
    </ng-container>
  </div>
</div>
