import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';

import { AspectRatioString } from '@fcom/common';
import { CmsImageData, CmsMedia, CmsQueryListItem } from '@fcom/core-api';

@Component({
  selector: 'fin-teaser-card',
  templateUrl: './teaser-card.component.html',
  styleUrls: ['./teaser-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeaserCardComponent {
  @Input()
  alignLeft = false;
  @Input()
  aspectRatio: AspectRatioString;
  @Input()
  content: CmsQueryListItem;

  @Output()
  linkClicked: EventEmitter<void> = new EventEmitter(); // mainly for click tracking if normal link tracking is not enough

  emitClick(): void {
    this.linkClicked.emit();
  }

  getPics(media: CmsMedia[]): CmsImageData[] {
    return media.filter((m) => 'responsiveImageLinksData' in m) as CmsImageData[];
  }
}
