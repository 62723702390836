import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

import { sanitizeWhiteListedPath } from '@fcom/core/utils/cms-path-helpers';
import { CmsContentType, CmsPage, CmsTemplate } from '@fcom/core-api/interfaces/cms-interface';
import { CmsTemplateService } from '@fcom/cms/services/template/cms-template.service';
import { ConfigService } from '@fcom/core/services';

import { PageMetaService } from '../../services/page-meta/page-meta.service';
import { NavigationMenuService } from '../navigation-menu/navigation-menu.service';

@Injectable({
  providedIn: 'root',
})
export class CmsPageService {
  private renderer: Renderer2;
  private path$: ReplaySubject<string> = new ReplaySubject(1);
  private template$: Observable<CmsTemplate>;

  constructor(
    private pageMetaService: PageMetaService,
    private cmsTemplateService: CmsTemplateService,
    private router: Router,
    private configService: ConfigService,
    private navigationMenuService: NavigationMenuService,
    rendererFactory: RendererFactory2
  ) {
    this.template$ = this.path$.pipe(
      distinctUntilChanged(),
      switchMap((p) => this.cmsTemplateService.load(p)),
      // filter out errors as cmsTemplateService returns them instead of throwing them
      filter((x) => !!x && !('type' in x) && !('error' in x))
    );
    // cannot inject renderer2 directly into services
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * If possible try to avoid duplicate loading of pages with this
   * If you know page is loaded somewhere use getPageDataIfExists instead
   * @param urlPath
   */
  getPageData(urlPath: string, customRegexp?: string): Observable<CmsTemplate> {
    this.path$.next(this.getCurrentUrl(urlPath, customRegexp));

    return this.template$.pipe(
      tap((template) => {
        const cmsPage = template?.header?.[0] as CmsPage;
        if (cmsPage?.oneworld) {
          this.navigationMenuService.setOneworldLink(cmsPage?.oneworld);
        }
      })
    );
  }

  getPageDataIfExists(urlPath: string): Observable<CmsTemplate> {
    return this.cmsTemplateService.getTemplateForPathIfExists(sanitizeWhiteListedPath(urlPath, this.configService.cfg));
  }

  getPageDataAndSetMeta(urlPath: string, customRegexp?: string): Observable<CmsTemplate> {
    return this.getPageData(urlPath, customRegexp).pipe(
      tap((template) =>
        this.setMeta(template.header.find((obj) => obj.contentContentType === CmsContentType.CMChannel) as CmsPage)
      )
    );
  }

  setMeta(content: CmsPage): void {
    this.pageMetaService.setTitle(content.seoTitle || content.title);
    this.pageMetaService.moveMetaAndLinksToHead(content.metaData, this.renderer);
  }

  private getCurrentUrl(urlPath: string, customRegexp?: string): string {
    const sanitizedUrl = sanitizeWhiteListedPath(urlPath, this.configService.cfg);

    const urlRegex = new RegExp(`${customRegexp || urlPath}/.*`);

    return this.router.url.replace(urlRegex, sanitizedUrl);
  }
}
