<section *ngIf="contentForm$ | async as form">
  <fin-form-fields>
    <h3 *ngIf="content$ | finMap: 'teaserTitle' | async" class="content-switcher-title font-heading-3">
      {{ content$ | finMap: 'teaserTitle' | async }}
    </h3>
    <div class="dropdown-container pr-large-b bold-type">
      <fin-simple-select-field
        class="block"
        [modelName$]="'content' | finToObservable"
        [name$]="'content' | finToObservable"
        [label$]="content$ | finMap: 'teaserText' | async | finStripHtml | finToObservable"
        [group$]="contentForm$"
        [options$]="contentOptions$"
      >
      </fin-simple-select-field>
    </div>
  </fin-form-fields>

  <fin-cms-json-content [template$]="selectedContent$"></fin-cms-json-content>
</section>
