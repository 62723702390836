<label [formGroup]="parentForm" [attr.aria-checked]="parentForm.value[controlName] === radioKey">
  <div
    class="flex relative regular-type radio-wrapper"
    [class.ps-small-t]="displayContainerStyles"
    [class.grey-600-text]="parentForm.get(controlName).disabled"
  >
    <input
      class="is-vishidden"
      role="radio"
      [class.sensitive]="isSensitive"
      [id]="controlName + '-' + radioKey"
      type="radio"
      [value]="radioKey"
      [attr.name]="controlName"
      [formControlName]="controlName"
      [name]="controlName"
    />
    <span
      aria-hidden="true"
      class="flex flex--align-start"
      [class.nordic-blue-900-text]="!parentForm.get(controlName).disabled"
      [class.grey-200-text]="parentForm.get(controlName).disabled"
    >
      <fcom-icon
        *ngIf="parentForm.value[controlName] === radioKey"
        class="radio-icon icon-small"
        [dataTestId]="'icon-' + SvgLibraryIcon.RADIOBUTTON_SELECTED"
        [name]="SvgLibraryIcon.RADIOBUTTON_SELECTED"
      ></fcom-icon>
      <fcom-icon
        *ngIf="parentForm.value[controlName] !== radioKey"
        class="radio-icon icon-small"
        [dataTestId]="'icon-' + SvgLibraryIcon.RADIOBUTTON_UNSELECTED"
        [name]="SvgLibraryIcon.RADIOBUTTON_UNSELECTED"
      ></fcom-icon>
    </span>
    <span *ngIf="!description" class="label font-body-1 ms-xsmall-l">
      {{ translationPrefix + '.' + radioKey | finLocalization }}
    </span>
    <div *ngIf="description" class="label font-body-1 ps-xsmall-l inline fill">
      <div class="medium-type">{{ descriptionTitle }}</div>
      <div class="font-body-2 grey-700-text">{{ descriptionText }}</div>
      <div class="nordic-blue-900-text"><ng-content></ng-content></div>
    </div>
  </div>
</label>
