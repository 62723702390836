<div class="toaster-container" [class.beneath-modal]="beneathModal">
  <div class="flex flex--start flex--column absolute toaster">
    <ng-container *ngFor="let message of messages | keyvalue; let index = index; trackBy: getToastTrackKey">
      <fcom-notification
        class="ms-xsmall-t align-left"
        aria-live="assertive"
        [class.animate-slide-from-left]="!message.value.isCloseClicked && !message.value.isDisplayed"
        [class.animate-slide-to-right]="message.value.isCloseClicked"
        [theme]="message.value.theme"
        [iconCategory]="iconCategory"
        [iconName]="message.value.iconName"
        [layout]="NotificationLayout.OVERLAY"
        [isClosable]="true"
        [linkText]="message.value.target?.linkText"
        [linkUrl]="message.value.target?.linkUrl"
        [linkOpenInNewWindow]="message.value.target?.linkOpenInNewWindow"
        (closeClick)="close(message.value.id)"
        (linkClick)="
          message.value.target?.callback ? callbackClicked(message.value.id, message.value.target?.callback) : null
        "
      >
        <div *ngIf="message.value.contentHTML" [innerHTML]="message.value.contentHTML"></div>
        <ng-container *ngIf="!message.value.contentHTML">
          {{ message.value.content || (message.value.contentKey | finLocalization) }}
        </ng-container>
      </fcom-notification>
    </ng-container>
  </div>
</div>
