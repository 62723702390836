/* eslint-disable no-underscore-dangle */
import { filter } from 'rxjs/operators';
import { createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';

import { BoundType, PaxAmount, Quotas } from '@fcom/dapi';
import { isPresent, isUndefined, LocalDate, unique } from '@fcom/core/utils';
import { safeSelect, StateSelector } from '@fcom/core/selectors/selector-utils';
import { Bound, Cabin, FareFamily, FinnairItineraryItem, Offer, OfferList } from '@fcom/dapi/api/models';
import { getGtmFlightSelectionDataForFareFamily } from '@fcom/common/utils/gtm.utils';
import { GtmFlightSelectionData, GtmPurchaseFlow, OfferListFetchParams } from '@fcom/common/interfaces';
import { FlightFilters, FlightTimeOption } from '@fcom/common-booking/interfaces/flight-filters.interface';
import { SortBy } from '@fcom/common-booking';
import { BookingFeatureState } from '@fcom/common/interfaces/booking/booking-state.interface';
import {
  AirOffersState,
  AirOffersStatus,
  BoundFareFamilies,
  BoundInfoWithFareFamilies,
  FareFamilyMap,
  QuotasPerBound,
} from '@fcom/common/interfaces/booking';

import {
  effectiveCabinClass,
  getBoundFareFamilies,
  isArrivalTimeInGivenOptions,
  isDepartureTimeInGivenOptions,
  matchesBoundStopCount,
} from '../../modules/ticket-selection/utils/offer.utils';
import { bookingFeatureState } from './booking-feature-state.selector';
import { resolvePreselection } from './offer-selector.utils';

const _airOffersState = createSelector(bookingFeatureState, (_bfs: BookingFeatureState) => _bfs?.offers);
const _offerStatus = createSelector(_airOffersState, (_aos: AirOffersState) => _aos?.status);
export const currentOffersSelector = createSelector(_airOffersState, (_aos: AirOffersState) => _aos?.currentOffers);
const _offersHash = createSelector(currentOffersSelector, (_o: OfferList) => _o?.hash);
const _offersCurrency = createSelector(currentOffersSelector, (_o: OfferList) => _o?.currency);
const _searchParameters = createSelector(currentOffersSelector, (_o: OfferList) => _o?.finnairSearchParameters);
const _offers = createSelector(currentOffersSelector, (_o: OfferList) => _o?.offers);

const _boundDepartureTime = createSelector(_airOffersState, (_aos: AirOffersState) => _aos?.filters?.departureTime);
const _boundArrivalTime = createSelector(_airOffersState, (_aos: AirOffersState) => _aos?.filters?.arrivalTime);
const _boundStopsCount = createSelector(_airOffersState, (_aos: AirOffersState) => _aos?.filters?.stopsCount);
const _boundCabin = createSelector(_airOffersState, (_aos: AirOffersState) => _aos?.filters?.cabin);
const _filters = createSelector(_airOffersState, (_aos: AirOffersState) => _aos?.filters);
export const offerFilters = (): StateSelector<BookingFeatureState, FlightFilters> => safeSelect(_filters);

const _filterOffersInbounds = createSelector(
  _airOffersState,
  _boundDepartureTime,
  _boundArrivalTime,
  _boundStopsCount,
  _boundCabin,
  (
    _aos: AirOffersState,
    departureTimes: FlightTimeOption[],
    arrivalTimes: FlightTimeOption[],
    stopsCount: number,
    cabin: Cabin
  ) => {
    if (!_aos?.currentOffers) {
      return _aos?.currentOffers;
    }

    const inbounds = filterBounds('inbound', _aos.currentOffers, departureTimes, arrivalTimes, stopsCount, cabin);

    return {
      ..._aos.currentOffers,
      inbounds,
      fareFamilies: filterFareFamilies('inbound', _aos.currentOffers, cabin),
    };
  }
);

const _filterOffersOutbounds = createSelector(
  _airOffersState,
  _boundDepartureTime,
  _boundArrivalTime,
  _boundStopsCount,
  _boundCabin,
  (
    _aos: AirOffersState,
    departureTimes: FlightTimeOption[],
    arrivalTimes: FlightTimeOption[],
    stopsCount: number,
    cabin: Cabin
  ) => {
    if (!_aos?.currentOffers) {
      return _aos?.currentOffers;
    }
    const outbounds = filterBounds('outbound', _aos.currentOffers, departureTimes, arrivalTimes, stopsCount, cabin);

    return {
      ..._aos.currentOffers,
      outbounds,
      fareFamilies: filterFareFamilies('outbound', _aos?.currentOffers, cabin),
    };
  }
);

export const outboundsOfferSelector = createSelector(
  _airOffersState,
  (airBoundsState: AirOffersState) => airBoundsState?.currentOffers?.outbounds
);
export const inboundsOfferSelector = createSelector(
  _airOffersState,
  (airBoundsState: AirOffersState) => airBoundsState?.currentOffers?.inbounds
);

export const oneWaySelector = createSelector(_searchParameters, (_p) =>
  isPresent(_p) ? _p.itinerary.length === 1 : undefined
);
const _departureDate = createSelector(
  _searchParameters,
  (p) => (p?.itinerary?.[0].departureDate && new LocalDate(p.itinerary[0].departureDate)) || undefined
);
const _returnDate = createSelector(_searchParameters, oneWaySelector, (params, oneway) =>
  oneway
    ? null
    : (params?.itinerary?.[1].departureDate && new LocalDate(params.itinerary[1].departureDate)) || undefined
);
const _lastRequestParams = createSelector(_airOffersState, (_abs: AirOffersState) => _abs?.lastRequestParams);
const _serializedLastRequestParams = createSelector(_lastRequestParams, (lastRequestParams: OfferListFetchParams) =>
  JSON.stringify(lastRequestParams)
);
export const fareFamiliesSelector = createSelector(currentOffersSelector, (_o: OfferList) => _o?.fareFamilies);
export const selectedOutboundIdSelector = createSelector(
  _airOffersState,
  (_aos: AirOffersState) => _aos?.selectedOutboundId
);
export const selectedInboundIdSelector = createSelector(
  _airOffersState,
  (_aos: AirOffersState) => _aos?.selectedInboundId
);
export const selectedOutboundFareFamilyCodeSelector = createSelector(
  _airOffersState,
  (_aos: AirOffersState) => _aos?.selectedOutboundFareFamilyCode
);
export const selectedInboundFareFamilyCodeSelector = createSelector(
  _airOffersState,
  (_aos: AirOffersState) => _aos?.selectedInboundFareFamilyCode
);
export const selectedSortBySelector = createSelector(_airOffersState, (_aos: AirOffersState) => _aos?.sortBy);
const _boundSelectionsDone = createSelector(
  oneWaySelector,
  selectedOutboundIdSelector,
  selectedInboundIdSelector,
  (oneway, outboundId, inboundId) => !!(outboundId && (oneway || inboundId))
);

const _offersFilteredByBoundIds = createSelector(
  _offers,
  oneWaySelector,
  selectedOutboundIdSelector,
  selectedInboundIdSelector,
  (offers: Offer[] = [], isOneWay: boolean, outboundId: string, inboundId: string) => {
    const byOutboundIdIfPresent = (o) => !outboundId || o.outboundId === outboundId;
    const byInboundIdIfPresent = (o) => !inboundId || o.inboundId === inboundId;
    const filteredByOutbound = offers.filter(byOutboundIdIfPresent);
    return isOneWay ? filteredByOutbound : filteredByOutbound.filter(byInboundIdIfPresent);
  }
);

export const _currentOffer = createSelector(
  _offers,
  oneWaySelector,
  selectedOutboundIdSelector,
  selectedInboundIdSelector,
  selectedOutboundFareFamilyCodeSelector,
  selectedInboundFareFamilyCodeSelector,
  (
    offers: Offer[] = [],
    isOneway: boolean,
    outboundId: string,
    inboundId: string,
    outboundFareFamilyCode: string,
    inboundFareFamilyCode: string
  ) => {
    const outboundMatch = (o) => o.outboundId === outboundId && o.outboundFareFamily === outboundFareFamilyCode;
    const inboundMatch = (o) => o.inboundId === inboundId && o.inboundFareFamily === inboundFareFamilyCode;
    if (isOneway) {
      return offers.find((o) => outboundMatch(o));
    }
    return offers.find((o) => outboundMatch(o) && inboundMatch(o));
  }
);

const _fareFamilyPreselection = createSelector(_offersFilteredByBoundIds, resolvePreselection);

const _quotasForBounds = createSelector(
  currentOffersSelector,
  selectedOutboundIdSelector,
  selectedInboundIdSelector,
  (offerList: OfferList, outboundId, inboundId) => {
    if (!offerList) {
      return undefined;
    }
    const outboundQuotas: Quotas = (offerList?.outbounds?.[outboundId] as any)?.quotas || {};
    const inboundQuotas: Quotas = (offerList?.inbounds?.[inboundId] as any)?.quotas || {};
    return {
      outbound: outboundQuotas,
      inbound: inboundQuotas,
    };
  }
);

/**
 * Selects true or false dependending whether the selected ticket types have no baggage.
 * Handles both one way and return flights.
 * @param {Offer} offer
 */
const _hasZeroBaggageOnEitherFlight = (offer: Offer) =>
  createSelector(
    fareFamiliesSelector,
    oneWaySelector, // TODO: is this really needed here, should be deducible from the offer
    (fareFamilies: FareFamilyMap, isOneWay: boolean) => {
      if (!fareFamilies) {
        return undefined;
      }
      const outboundFareFamily: FareFamily = fareFamilies[offer.outboundFareFamily];
      const outboundBaggage = outboundFareFamily?.checkedBaggage || 0;
      if (isOneWay) {
        return outboundBaggage === 0;
      }
      const inboundFareFamily: FareFamily = fareFamilies[offer.inboundFareFamily];
      const inboundBaggage = inboundFareFamily?.checkedBaggage || 0;
      return outboundBaggage === 0 || inboundBaggage === 0;
    }
  );

const _outboundSelectionGTMData = (paxAmount: PaxAmount) =>
  createSelector(
    currentOffersSelector,
    selectedOutboundIdSelector,
    selectedOutboundFareFamilyCodeSelector,
    (offerList, outboundId, outboundFareFamilyCode) => {
      const bound = offerList?.outbounds[outboundId];
      const offer = bound
        ? offerList.offers.find((o) => o.outboundId === outboundId && o.outboundFareFamily === outboundFareFamilyCode)
        : undefined;

      if (bound && offer) {
        return getGtmFlightSelectionDataForFareFamily(
          bound,
          BoundType.outbound,
          bound.itinerary as FinnairItineraryItem[],
          offerList.fareFamilies[offer.outboundFareFamily]?.brandName,
          offer.outboundPrice,
          offer.outboundPointsPrice,
          offer.outboundFareInformation,
          offerList.currency,
          paxAmount,
          offer.outboundPointsPrice ? GtmPurchaseFlow.AWARD : GtmPurchaseFlow.BOOKING
        );
      }

      return undefined;
    }
  );

const _inboundSelectionGTMData = (paxAmount: PaxAmount) =>
  createSelector(
    currentOffersSelector,
    selectedInboundIdSelector,
    selectedInboundFareFamilyCodeSelector,
    (offerList, boundId, fareFamilyCode) => {
      const bound = offerList?.inbounds?.[boundId];
      const offer = bound
        ? offerList.offers.find((o) => o.inboundId === boundId && o.inboundFareFamily === fareFamilyCode)
        : undefined;

      if (bound && offer) {
        return getGtmFlightSelectionDataForFareFamily(
          bound,
          BoundType.inbound,
          bound.itinerary as FinnairItineraryItem[],
          offerList.fareFamilies[offer.inboundFareFamily]?.brandName,
          offer.inboundPrice,
          offer.inboundPointsPrice,
          offer.inboundFareInformation,
          offerList.currency,
          paxAmount,
          offer.inboundPointsPrice ? GtmPurchaseFlow.AWARD : GtmPurchaseFlow.BOOKING
        );
      }

      return undefined;
    }
  );

const _flightSelectionGTMDataForOffers = (
  bound: BoundInfoWithFareFamilies,
  boundType: BoundType,
  paxAmount: PaxAmount
) =>
  createSelector(currentOffersSelector, (offerList) => {
    return offerList
      ? bound.fareFamilies.map((fareFamily) =>
          getGtmFlightSelectionDataForFareFamily(
            bound,
            boundType,
            bound.itinerary as FinnairItineraryItem[],
            offerList.fareFamilies[fareFamily.fareFamilyCode]?.brandName,
            fareFamily.price,
            fareFamily.points,
            fareFamily.fareInformation,
            offerList.currency,
            paxAmount,
            fareFamily.points ? GtmPurchaseFlow.AWARD : GtmPurchaseFlow.BOOKING
          )
        )
      : undefined;
  });

const filterBounds = (
  type: 'inbound' | 'outbound',
  offerList: OfferList,
  departureTimes: FlightTimeOption[],
  arrivalTimes: FlightTimeOption[],
  stopsCount: number,
  cabin: Cabin
): { [key: string]: Bound } => {
  const { fareFamilies, offers } = offerList;
  const boundKey = type === 'inbound' ? 'inbounds' : 'outbounds';
  const bounds = offerList[boundKey];

  if (!bounds) {
    return bounds;
  }

  return Object.entries(bounds).reduce((filteredBoundObject, [id, bound]) => {
    const shouldKeepBound =
      matchesBoundStopCount(stopsCount, bound.stops) &&
      isArrivalTimeInGivenOptions(arrivalTimes, bound.arrival.dateTime) &&
      isDepartureTimeInGivenOptions(departureTimes, bound.departure.dateTime) &&
      isCabinIncludedInBoundOffer(cabin, fareFamilies, offers, bound, type);

    if (shouldKeepBound) {
      return { ...filteredBoundObject, [id]: bound };
    }
    return filteredBoundObject;
  }, {});
};

const filterFareFamilies = (
  type: 'inbound' | 'outbound',
  offerList: OfferList,
  cabin: Cabin
): Record<string, FareFamily> => {
  if (cabin === Cabin.MIXED) {
    return offerList.fareFamilies;
  }

  const fareFamilyIdKey: 'inboundFareFamily' | 'outboundFareFamily' = `${type}FareFamily`;
  const fareFamilyInfoKey: 'inboundFareInformation' | 'outboundFareInformation' = `${type}FareInformation`;

  return (
    Object.entries(offerList.fareFamilies)
      // Get the cabin classes for the fareFamilyId based on the currentOffer offers lits
      .map(([fareFamilyId, fareFamily]) => {
        const fareFamilyCabinClasses = offerList.offers
          .filter((o) => o[fareFamilyIdKey] === fareFamilyId)
          .flatMap((o) => o[fareFamilyInfoKey].map((info) => info.cabinClass) ?? [])
          .filter(unique);

        return {
          fareFamilyId,
          fareFamily,
          fareFamilyCabinClasses,
        };
      })
      // Convert the array to object and exclude the ones that don't contain the cabin
      .reduce<Record<string, FareFamily>>((acc, fi) => {
        if (fi.fareFamilyCabinClasses.includes(cabin)) {
          acc[fi.fareFamilyId] = fi.fareFamily;
        }
        return acc;
      }, {})
  );
};

/**
 * Selects the current status of the air offers query
 */
export const offersStatus = (): StateSelector<BookingFeatureState, AirOffersStatus> => safeSelect(_offerStatus);

/**
 * Selects the current offers from the state. Will not emit if not present.
 */
export const currentOffers = (): StateSelector<BookingFeatureState, OfferList> => safeSelect(currentOffersSelector);

/**
 * Filters the current offers outbounds from the state. Will not emit if not present.
 */
export const filteredCurrentOffersOutbound = (): StateSelector<BookingFeatureState, OfferList> =>
  safeSelect(_filterOffersOutbounds);

/**
 * Filters the current offers inbound from the state. Will not emit if not present.
 */
export const filteredCurrentOffersInbound = (): StateSelector<BookingFeatureState, OfferList> =>
  safeSelect(_filterOffersInbounds);

/**
 * Gets the hash returned with the offers
 */
export const offersHash = (): StateSelector<BookingFeatureState, string> => safeSelect(_offersHash);
/**
 * Gets the currency code used with the offers
 */
export const offersCurrency = (): StateSelector<BookingFeatureState, string> => safeSelect(_offersCurrency);
export const isOneWayOffers = (): StateSelector<BookingFeatureState, boolean> => safeSelect(oneWaySelector);

export const offersDepartureDate = (): StateSelector<BookingFeatureState, LocalDate> => safeSelect(_departureDate);

export const offersReturnDate = (): StateSelector<BookingFeatureState, LocalDate> =>
  pipe(
    select(_returnDate),
    filter((o) => !isUndefined(o))
  );

/**
 * Params of the last request
 */
export const offerLastRequestParams = (): StateSelector<BookingFeatureState, OfferListFetchParams> =>
  select(_lastRequestParams);

/**
 * Serialized version of the params of the last request
 */
export const offerSerializedLastRequestParams = (): StateSelector<BookingFeatureState, string> =>
  select(_serializedLastRequestParams);

/**
 * Selects the current fareFamilies of offers from the state. Will not emit if not present.
 */
export const currentFareFamilies = (): StateSelector<BookingFeatureState, FareFamilyMap> =>
  select(fareFamiliesSelector);
export const selectedOutboundId = (): StateSelector<BookingFeatureState, string> => select(selectedOutboundIdSelector);
export const selectedOutboundFareFamilyCode = (): StateSelector<BookingFeatureState, string> =>
  select(selectedOutboundFareFamilyCodeSelector);
export const selectedInboundId = (): StateSelector<BookingFeatureState, string> => select(selectedInboundIdSelector);

export const selectedInboundFareFamilyCode = (): StateSelector<BookingFeatureState, string> =>
  select(selectedInboundFareFamilyCodeSelector);
export const boundSelectionsDone = (): StateSelector<BookingFeatureState, boolean> => select(_boundSelectionsDone);

export const offersFilteredByBoundIds = (): StateSelector<BookingFeatureState, Offer[]> =>
  select(_offersFilteredByBoundIds);

export const currentOffer = (): StateSelector<BookingFeatureState, Offer> => select(_currentOffer);
export const fareFamilyPreselection = (): StateSelector<BookingFeatureState, BoundFareFamilies> =>
  select(_fareFamilyPreselection);

export const quotasForBounds = (): StateSelector<BookingFeatureState, QuotasPerBound> => safeSelect(_quotasForBounds);

export const hasZeroBaggageOnEitherFlight = (offer: Offer): StateSelector<BookingFeatureState, boolean> =>
  safeSelect(_hasZeroBaggageOnEitherFlight(offer));

export const selectedTicketSortBy = (): StateSelector<BookingFeatureState, SortBy> => select(selectedSortBySelector);

export const outboundOffersSelectionGtmData = (
  paxAmount: PaxAmount
): StateSelector<BookingFeatureState, GtmFlightSelectionData> => safeSelect(_outboundSelectionGTMData(paxAmount));

export const inboundOffersSelectionGtmData = (
  paxAmount: PaxAmount
): StateSelector<BookingFeatureState, GtmFlightSelectionData> => safeSelect(_inboundSelectionGTMData(paxAmount));

export const flightSelectionGtmDataForOffers = (
  bound: BoundInfoWithFareFamilies,
  boundType: BoundType,
  paxAmount: PaxAmount
): StateSelector<BookingFeatureState, GtmFlightSelectionData[]> =>
  safeSelect(_flightSelectionGTMDataForOffers(bound, boundType, paxAmount));

const isCabinIncludedInBoundOffer = (
  cabin: Cabin,
  fareFamilies: { [key: string]: FareFamily },
  offers: Offer[],
  bound: Bound,
  type: 'outbound' | 'inbound'
) => {
  if (cabin && cabin === Cabin.MIXED) {
    return true;
  }

  const boundFareFamilies = getBoundFareFamilies(Object.values(fareFamilies), offers, bound, type);

  return boundFareFamilies.some((ff) => effectiveCabinClass(ff) === cabin);
};
