import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ElementTrackerDirective } from './directives/element-tracker.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [ElementTrackerDirective],
  exports: [ElementTrackerDirective],
})
export class GtmModule {}
