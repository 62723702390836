import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  Inject,
  PLATFORM_ID,
  Output,
  EventEmitter,
} from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';

import { Observable, Subscription, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { unsubscribe, urlToPathname, symbolToLangPath } from '@fcom/core/utils';

@Directive({
  selector: '[finLink]',
})
export class LinkDirective implements OnChanges, OnInit, OnDestroy {
  @Input()
  finLink: Observable<string> | string;
  @Input()
  external = false;
  @Input()
  openInNewTab = false;
  @Input()
  nofollow = false;

  @Output()
  afterNavigation: EventEmitter<Event> = new EventEmitter();
  @Output()
  beforeNavigation: EventEmitter<Event> = new EventEmitter();

  @HostBinding()
  href: string;

  private linkVal: string;
  private clicks: Subscription;
  private subscription: Subscription;

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private languageService: LanguageService,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  ngOnInit(): void {
    if (isPlatformServer(this.platform)) {
      return;
    }
    this.clicks = fromEvent(this.elementRef.nativeElement, 'click').subscribe((ev: KeyboardEvent) =>
      this.handleClick(ev)
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['finLink']) {
      this.subscribeToLink(changes['finLink'].currentValue);
    }
  }

  legacySiteLinkLocale(url = ''): string {
    if (!url) {
      return url;
    }
    const ddsLocale = this.languageService.ddsLocaleValue;
    return symbolToLangPath(url, ddsLocale);
  }

  subscribeToLink(link: Observable<string> | string): void {
    this.subscription = unsubscribe(this.subscription);
    if (link instanceof Observable) {
      this.subscription = link
        .pipe(map((d) => this.legacySiteLinkLocale(d)))
        .subscribe((link) => this.setLinkValue(link));
    } else {
      this.setLinkValue(this.legacySiteLinkLocale(link));
    }
  }

  ngOnDestroy(): void {
    this.subscription = unsubscribe(this.subscription);
    this.clicks = unsubscribe(this.clicks);
  }

  handleClick(event: KeyboardEvent): void {
    this.beforeNavigation.emit(event);
    if (!(event.ctrlKey || event.metaKey) && !this.external && !event.defaultPrevented) {
      event.stopPropagation();
      event.preventDefault();
      if (this.linkVal) {
        this.router.navigateByUrl(this.linkVal);
        this.afterNavigation.emit(event);
      }
    }
    // Cmd/Ctrl + click, opens in new tab on most browsers, do nothing
  }

  private setLinkValue = (link: string) => {
    const l = this.toActualLink(link);
    this.linkVal = l;
    this.href = l;
    if (this.openInNewTab) {
      this.elementRef.nativeElement.target = '_blank';
      // Target _blank handles this in new browsers but for backward compatibility we set this too still
      this.elementRef.nativeElement.rel = 'noopener';
      if (this.nofollow) {
        this.elementRef.nativeElement.rel = 'noopener nofollow';
      }
    }

    if (!this.openInNewTab && this.nofollow) {
      this.elementRef.nativeElement.rel = 'nofollow';
    }
  };

  private toActualLink = (l: string): string => (this.external ? l : urlToPathname(l, { preserveQueryParams: true }));
}
