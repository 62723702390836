import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { ToasterMessage } from '../../interfaces';

const MESSAGE_DELAY_MS = 1000;

@Injectable()
export class ToasterService {
  public readonly toasterMessage$: Observable<ToasterMessage>;
  public readonly messageToRemove$: Observable<string>;
  private queue$: Subject<ToasterMessage> = new Subject();
  private removalQueue$: Subject<string> = new Subject();

  constructor() {
    this.toasterMessage$ = this.queue$.pipe(startWith(null as ToasterMessage));
    this.messageToRemove$ = this.removalQueue$.pipe(startWith(null as string));
  }

  addMessageToQueue(message: ToasterMessage): void {
    if (message.delay) {
      setTimeout(() => {
        this.queue$.next(message);
      }, MESSAGE_DELAY_MS);
    } else {
      this.queue$.next(message);
    }
  }

  removeMessage(id: string): void {
    this.removalQueue$.next(id);
  }
}
