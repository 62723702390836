import { Component, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { ExternalLink } from '../../interfaces';

@Component({
  selector: 'fin-external-link',
  templateUrl: './external-link.component.html',
})
export class ExternalLinkComponent {
  @Input()
  link$: Observable<ExternalLink>;

  constructor() {}
}
