export interface ImageData {
  /**
   * Data url (base64) for the low-resolution version of the picture
   */
  thumbnailDataUrl?: string;

  /**
   * Url to the full size image. If not present, only the blurred thumbnail
   * will be shown
   */
  fullSizeUrl?: string;
}

export interface SrcAndWidth {
  src: string;
  width: number;
}

export interface Dimensions {
  width: number;
  height: number;
}

export interface ResponsiveImageData {
  fallbackSrc: string;
  thumbnailSrc: string;
  srcSet: SrcAndWidth[];
}

export type AspectRatioString = '1x1' | '4x3' | '3x4' | '16x9' | '21x9';
export type CmsAspectRatio =
  | 'portrait_ratio1x1'
  | 'landscape_ratio4x3'
  | 'portrait_ratio3x4'
  | 'landscape_ratio16x9'
  | 'landscape_ratio21x9';

export const ASPECT_RATIO_PROPERTY_MAP: { readonly [aspectRatioString in AspectRatioString]: CmsAspectRatio } = {
  '1x1': 'portrait_ratio1x1',
  '4x3': 'landscape_ratio4x3',
  '3x4': 'portrait_ratio3x4',
  '16x9': 'landscape_ratio16x9',
  '21x9': 'landscape_ratio21x9',
};

/**
 * This is the width that is used for devices not supporting srcSet attribute. Found on every crop ratio.
 */
export const FALLBACK_WIDTH = 768;

export const IMAGE_WIDTHS = [144, 300, 320, 600, 608, 768, 1024, 1200, 1920];
