import { createFeatureSelector, createSelector } from '@ngrx/store';

import { safeSelect, StateSelector } from '@fcom/core/selectors/selector-utils';

import { CommonBookingFeatureState, CommonBookingState } from '../store.interface';
import { COMMON_BOOKING_FEATURE_KEY } from '../constants';

const _selectCommonBookingState = createFeatureSelector<CommonBookingFeatureState, CommonBookingState>(
  COMMON_BOOKING_FEATURE_KEY
);

const _selectedBoundIdState = createSelector(
  _selectCommonBookingState,
  (state: CommonBookingState) => state?.disruption?.selectedBoundId
);

export const selectedBoundId = (): StateSelector<CommonBookingFeatureState, string> =>
  safeSelect(_selectedBoundIdState);
