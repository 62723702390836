import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { isNull, stopPropagation } from '@fcom/core/utils';
import { Cabin, FinnairCabinClass } from '@fcom/dapi/api/models';
import { CmsTemplate } from '@fcom/core-api';
import { TagTheme, TabLayoutType } from '@fcom/ui-components';
import { ElementActions, ElementTypes, GaContext } from '@fcom/common/interfaces';
import { LanguageService } from '@fcom/ui-translate';
import { GtmService } from '@fcom/common/gtm';
import { CmsTemplateService } from '@fcom/cms';

interface CabinClassContent {
  id: string;
  template: Observable<CmsTemplate>;
}
@Component({
  selector: 'fin-cabin-class-modal',
  styleUrls: ['./cabin-class-modal.component.scss'],
  templateUrl: './cabin-class-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CabinClassModalComponent implements OnInit {
  @Input()
  cabinClasses$: Observable<FinnairCabinClass[] | Cabin[]>;

  public readonly TagTheme = TagTheme;
  public readonly TabLayoutType = TabLayoutType;

  cabinClassModalOpen = false;
  selectedCabinClassId: string;
  availableCabinClasses$: Observable<CabinClassContent[]>;
  template$: Observable<CmsTemplate>;

  constructor(
    private languageService: LanguageService,
    private gtmService: GtmService,
    private templateService: CmsTemplateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.availableCabinClasses$ = this.cabinClasses$.pipe(
      map((cabinClasses: FinnairCabinClass[]) => {
        const cmsMap = {
          [FinnairCabinClass.ECONOMY]: { id: 'economy', url: 'fragments.cabinModalEconomy.url' },
          [FinnairCabinClass.ECOPREMIUM]: { id: 'ecopremium', url: 'fragments.cabinModalEcopremium.url' },
          [FinnairCabinClass.BUSINESS]: { id: 'business', url: 'fragments.cabinModalBusiness.url' },
        };

        return cabinClasses.map((cabinClass) => {
          return {
            id: cmsMap[cabinClass].id,
            template: this.getCollectionUrl(cmsMap[cabinClass].url).pipe(
              switchMap((url) => {
                return this.templateService.load(url);
              })
            ),
          };
        });
      })
    );
  }

  openModal(e: Event, id: string): void {
    stopPropagation(e);
    this.selectedCabinClassId = id;
    this.cabinClassModalOpen = true;
    this.gtmService.trackElement(
      'open-cabin-class-chip',
      GaContext.FLIGHT_SELECTION,
      ElementTypes.BUTTON,
      id,
      ElementActions.CLICK
    );

    if (isNull(e)) {
      this.changeDetectorRef.markForCheck();
    }
  }

  closeModal(): void {
    this.cabinClassModalOpen = false;
  }

  getCollectionUrl(fragmentKey: string): Observable<string> {
    return this.languageService.translate(fragmentKey);
  }
}
