import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { EMPTY, Observable, Subject, Subscription, filter, map, tap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';

import { finShare } from '@fcom/rx';
import { isNotBlank, isPresent, unsubscribe } from '@fcom/core/utils';
import {
  ButtonSize,
  ButtonTheme,
  IconPosition,
  LoaderTheme,
  ModalButtons,
  NotificationLayout,
  NotificationTheme,
  PopoverOptions,
  PopoverService,
} from '@fcom/ui-components';
import { CmsDataService, PromoCodeTerms } from '@fcom/core';
import { LanguageService } from '@fcom/ui-translate';
import { BookingAppState } from '@fcom/common/interfaces/booking';
import { globalBookingDiscountCode } from '@fcom/common/store';
import { ElementActions } from '@fcom/common';

import { FormStatus } from '../../../service-forms';
import { BookingWidgetService } from '../../services/booking-widget.service';
import { DiscountCodeRulesState } from '../../interfaces';
import { BookingWidgetGtmService } from '../../services/booking-widget-gtm.service';
import { discountCodeValidator } from '../../utils/utils';
import { defaultWidgetPopoverOptions } from '../../constants';

@Component({
  selector: 'fin-discount-code',
  templateUrl: './discount-code.component.html',
  styleUrls: ['./discount-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountCodeComponent implements OnInit, OnDestroy {
  //TODO: can be removed after one time discount code campaign
  readonly MarketingPrefixDiscountCode = 'KIITOS';
  readonly FormStatus = FormStatus;
  readonly ModalButtons = ModalButtons;
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonSize = ButtonSize;
  readonly NotificationTheme = NotificationTheme;
  readonly NotificationLayout = NotificationLayout;
  readonly LoaderTheme = LoaderTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly IconLibrary = IconLibrary;
  readonly IconPosition = IconPosition;

  readonly popoverOptions: PopoverOptions = {
    ...defaultWidgetPopoverOptions,
    popoverID: 'discountCodePopover',
    disableAutoFocus: true,
    alignToLeft: true,
  };
  readonly usePopoverSelectors = this.bookingWidgetService.usePopoverSelectors();

  modalOpen = false;
  discountCode$: Observable<string>;
  discountCodeForm: FormGroup<{ discountCode: FormControl<string> }>;
  discountCodeRules$: Observable<DiscountCodeRulesState>;
  loading$: Subject<boolean> = new Subject<boolean>();
  openModal$: Subject<void> = new Subject<void>();
  private subscriptions: Subscription = new Subscription();

  @Input() isGlobalBookingWidget = false;

  constructor(
    private cmsDataService: CmsDataService,
    private languageService: LanguageService,
    private bookingWidgetGtmService: BookingWidgetGtmService,
    private store$: Store<BookingAppState>,
    private bookingWidgetService: BookingWidgetService,
    private popoverService: PopoverService
  ) {}

  ngOnInit(): void {
    this.discountCode$ = this.store$.pipe(
      globalBookingDiscountCode(),
      map((discountCode: string) => (isPresent(discountCode) ? discountCode : null)),
      finShare()
    );

    this.discountCodeForm = new FormGroup({
      discountCode: new FormControl('', discountCodeValidator()),
    });

    this.subscriptions.add(
      this.discountCode$.subscribe((discountCode: string) => {
        if (discountCode) {
          this.discountCodeForm.controls.discountCode.patchValue(discountCode, { emitEvent: false });
        }
      })
    );

    this.subscriptions.add(
      this.discountCodeForm.controls.discountCode.valueChanges
        .pipe(filter(Boolean), finShare())
        .subscribe((code: string) => {
          this.discountCodeForm.controls.discountCode.patchValue(code.toUpperCase(), { emitEvent: false });
          if (
            !this.discountCodeForm.controls.discountCode.valid &&
            !this.discountCodeForm.controls.discountCode.touched
          ) {
            this.discountCodeForm.controls.discountCode.markAsTouched();
          }
        })
    );

    this.subscriptions.add(
      this.openModal$.pipe(withLatestFrom(this.discountCode$)).subscribe(([_openModal, discountCode]) => {
        if (!(isPresent(discountCode) && isNotBlank(discountCode))) {
          this.resetForm();
        }
        this.modalOpen = true;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  checkDiscountCode(): void {
    const discountCodeValue = this.discountCodeForm.controls.discountCode.value;
    this.bookingWidgetGtmService.trackElementEvent('discount-code-check', discountCodeValue, ElementActions.CLICK);
    if (!this.discountCodeForm.valid || discountCodeValue === null || discountCodeValue === '') {
      return;
    }
    if (discountCodeValue === 'KIITOS') {
      this.discountCodeForm.controls.discountCode.setErrors({ custom: 'notFoundError' });
      return;
    }
    this.loading$.next(true);
    this.discountCodeRules$ = this.cmsDataService
      .getPromoCodeTerms(
        //TODO: can be removed after one time discount code campaign
        discountCodeValue.startsWith(this.MarketingPrefixDiscountCode)
          ? this.MarketingPrefixDiscountCode
          : discountCodeValue,
        this.languageService.langValue,
        this.languageService.localeValue
      )
      .pipe(
        map((terms: PromoCodeTerms) => ({
          found: !!terms,
          terms: terms?.description,
          title: terms?.title,
        })),
        tap((data: DiscountCodeRulesState) => {
          this.loading$.next(false);
          if (!data.found) {
            this.discountCodeForm.controls.discountCode.setErrors({ custom: 'notFoundError' });
          } else {
            this.discountCodeForm.controls.discountCode.disable();
            this.discountCodeForm.controls.discountCode.setErrors(null);
            this.bookingWidgetGtmService.trackElementEvent(
              'discount-code-accepted',
              discountCodeValue,
              ElementActions.VIEW
            );
          }
        }),
        finShare()
      );
  }

  closePopover(): void {
    this.popoverService.close();
  }

  resetForm(): void {
    this.discountCodeForm.reset();
    this.discountCodeRules$ = EMPTY;
    this.discountCodeForm.controls.discountCode.enable();
  }

  removeCode(): void {
    this.bookingWidgetService.setDiscountCode(null, this.isGlobalBookingWidget);
    this.resetForm();
  }

  continue(): void {
    this.bookingWidgetService.setDiscountCode(
      this.discountCodeForm.controls.discountCode.value,
      this.isGlobalBookingWidget
    );
    this.bookingWidgetGtmService.trackElementEvent(
      'promo-code-modal-done',
      this.discountCodeForm.controls.discountCode.value
    );
    this.modalOpen = false;
    this.popoverService.close();
  }
}
