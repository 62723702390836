import { isBlank } from '@fcom/core/utils/utils';

import { ResponsiveImage, StaticImage } from '.';

export const buildUrl = (baseUrl = '', parameters: any, joinChar = '&'): string => {
  let urlParamSeparator = '';
  if (!isBlank(baseUrl)) {
    if (!baseUrl.includes('?')) {
      urlParamSeparator = '?';
    } else {
      urlParamSeparator = joinChar;
    }
  }
  return (
    baseUrl +
    urlParamSeparator +
    Object.keys(parameters)
      .map((key) => `${key}=${String(parameters[key])}`)
      .join(joinChar)
  );
};

/**
 * TypeGuard to distinguish between ResponsiveImage and StaticImage.
 * Returns true if StaticImage
 */
export const isStaticImage = (image: ResponsiveImage | StaticImage): image is StaticImage => {
  return (
    !!image && ((image as StaticImage).fullSizeUrl !== undefined || (image as StaticImage).thumbnailSrc !== undefined)
  );
};

const gcd = (a: number, b: number): number => (b ? gcd(b, a % b) : a);

export const aspectRatio = (width: number, height: number): string => {
  const divisor = gcd(width, height);

  return `${width / divisor}/${height / divisor}`;
};

/**
 * Get the max image size Url from the responsiveImageLinksData
 */
export const getWidestImageWidth = (imageWidthMap: { [key: number]: string }): number => {
  return Math.max(
    ...Object.keys(imageWidthMap)
      .map((key) => +key)
      .filter(Boolean),
    0
  );
};
