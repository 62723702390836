<ul class="no-list-style">
  <li>
    <button
      *ngFor="let bound of bounds | async; let index = index; let first = first; let last = last"
      (click)="clickedBound.emit({ bound, index })"
      class="flight-summary flex fill ps-small border"
      [ngClass]="{
        'rounded-top-large': first && !last,
        'rounded-bottom-large': !first && last,
        'rounded-large': first && last,
        first: first,
      }"
      [attr.aria-label]="
        'multiCity.flightSelection.returnToBoundAria'
          | finLocalization
            : {
                bound: index + 1,
                origin: bound.departure.locationCode | finLocationName,
                destination: bound.arrival.locationCode | finLocationName,
                date: bound.departure.dateTime | finDateFormat: 'DATE',
              }
      "
    >
      <div class="flex-1 align-left">
        <span *ngIf="listStyle === 'ordered'" class="bold-type">
          {{ 'multiCity.flightSelection.flightNumber' | finLocalization: [index + 1] }},
        </span>
        <span *ngIf="listStyle === 'departure-return'" class="bold-type">
          {{
            index === 0
              ? ('ticketSelection.departure' | finLocalization)
              : ('ticketSelection.return' | finLocalization)
          }},</span
        >
        <span class="ps-xxsmall-l">{{ bound.departure.dateTime | finDateFormat: 'DATE' }}</span>
      </div>
      <div class="flex-1 align-right">
        <span>
          <span class="medium-type">{{ bound.departure.dateTime | finTime }}</span>
          <span class="ps-xsmall-x">{{ bound.departure.locationCode | finLocationName }}</span>
        </span>
        <span class="ps-xsmall-r">-</span>
        <span>
          <span class="medium-type">{{ bound.arrival.dateTime | finTime }}</span>
          <span class="ps-xsmall-x">{{ bound.arrival.locationCode | finLocationName }}</span>
        </span>
        <fcom-icon [name]="SvgLibraryIcon.CHECKMARK"></fcom-icon>
      </div>
    </button>
  </li>
</ul>
