import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CmsTarget } from '@fcom/core-api';

@Component({
  selector: 'fin-cta-with-image-and-text',
  styleUrls: ['./cta-with-image-and-text.component.scss'],
  templateUrl: './cta-with-image-and-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CtaWithImageAndTextComponent implements OnInit {
  @Input()
  teaserTitle$: Observable<string>;
  @Input()
  target$: Observable<CmsTarget>;
  @Input()
  teaserText$: Observable<string>;
  @Input()
  iconUrl$: Observable<string>;

  ctaButton$: Observable<object>;

  ngOnInit(): void {
    this.ctaButton$ = this.target$.pipe(
      filter((target) => !!target),
      map((target) => Object.assign({}, target))
    );
  }
}
