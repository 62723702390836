import { DOCUMENT, Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, computed, input } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { ConsentService } from '@fcom/common/login';
import { QualtricsSurvey, QualtricsSurveys, getQualtricsUrl } from '@fcom/common/qualtrics/qualtrics.utils';
import { LanguageService } from '@fcom/ui-translate';
import { ConsentTextId } from '@fcom/common';
import { PageViewType } from '@fcom/core-api';

@Component({
  selector: 'fin-qualtrics-embedded-page-feedback',
  templateUrl: './qualtrics-embedded-page-feedback.component.html',
  styleUrl: './qualtrics-embedded-page-feedback.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QualtricsEmbeddedPageFeedbackComponent implements OnInit {
  readonly PageViewType = PageViewType;
  readonly survey: QualtricsSurvey = QualtricsSurveys.PAGE_FEEDBACK;

  private survey$: BehaviorSubject<QualtricsSurvey>;

  pageType = input<string>('');
  surveyName = computed<string>(() => this.survey.name);
  classByPageType = computed<string>(() => {
    switch (this.pageType()) {
      case PageViewType.BLUEWINGS_LANDING_PAGE:
      case PageViewType.EDITORIAL_LANDING_PAGE:
        return 'md-col-4-12 sm-col-2-11 mr-xxsmall-l';
      case PageViewType.EDITORIAL_CATEGORY_PAGE:
        return 'md-col-4-12 sm-col-2-11 mr-xxsmall-l mr-xxlarge-t';
      case PageViewType.PREMIUM_ECONOMY_LANDING_PAGE_2021:
        return 'md-col-4-12 sm-col-2-11 pr-large-t';
      case PageViewType.SECTION_PAGE_WITH_TITLE:
        return 'md-col-5-12 pr-large-b';
      case 'full': // manually set pageType in timetables component
        return 'mr-clear-l mr-clear-r mr-large-t';
      case PageViewType.NO_GRID_FULL_WIDTH_PAGE_WITH_TITLE:
      case PageViewType.NO_GRID_FULL_WIDTH_PAGE:
      default:
        return 'flex--center mr-xlarge-t';
    }
  });

  qualtricsUrl$: Observable<string>;
  consentGiven$: Observable<boolean>;

  showFeedback = false;

  constructor(
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
    private consentService: ConsentService,
    @Inject(DOCUMENT) private document: Document,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.survey$ = new BehaviorSubject<QualtricsSurvey>(this.survey);

    this.qualtricsUrl$ = this.survey$.pipe(
      filter(Boolean),
      distinctUntilChanged((a, b) => a.name === b.name && a.url === b.url),
      switchMap((survey) => this.languageService.translate(survey.url)),
      filter(Boolean),
      map(
        (url) =>
          getQualtricsUrl(this.languageService.langKeyValue, this.languageService.langValue, url) +
          `&current_page_url=${this.location.path()}&pagePath=${this.strippedPath()}&locale=${this.languageService.ddsLocaleValue.countrySite}`
      )
    );

    this.consentGiven$ = this.consentService
      .getCookieConsentStatusById(ConsentTextId.COOKIE_ANALYTICS)
      .pipe(distinctUntilChanged());

    this.showFeedback = this.checkVisibility();
  }

  strippedPath(): string {
    const path: string = this.location.path();
    const stripParams: string = path.split('?')[0];
    const splitPath: string[] = stripParams.split('/');
    if (path.includes('/int/gb/') || splitPath.length <= 2) {
      return path;
    }
    return splitPath.slice(2).join('/');
  }

  checkVisibility(): boolean {
    const pathUrl: string = this.location.path();
    const activatedRouteSnapshot: UrlSegment[] = this.activatedRoute.snapshot?.url;

    // if frontpage && NOT timetables (was being evaluated as frontpage)
    if (activatedRouteSnapshot?.length < 1 && !pathUrl.includes('timetables')) {
      return false;
    }

    // hide for given paths
    if (this.hideFeedback(pathUrl)) {
      return false;
    }

    // hide for 404 page
    if (pathUrl.includes('404-not-found')) {
      return false;
    }

    return true;
  }

  private hideFeedback(pathUrl: string): boolean {
    //parse through alternateUrls to remove feedback on page for all languages
    const alternateUrl: HTMLElement = this.document?.querySelector('link[hreflang="en-GB"]');
    const alternateUrlHref: string = alternateUrl?.getAttribute('href');

    return [
      'flash-sale',
      'finnair100',
      'offers',
      'special-offers',
      'business-class-flights',
      'customer-care-and-contact-information',
      'newsletter',
      'compensation-wizard',
      'contact-finnair-corporate-programme',
      'join-finnair-corporate-programme',
      'claim-missing-points',
      'contact-and-request-forms',
    ].some((page) => pathUrl.includes(page) || alternateUrlHref?.includes(page));
  }
}
