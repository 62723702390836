import { Injectable, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { select, Store } from '@ngrx/store';
import { combineLatest, filter, map, Observable, share, Subscription, switchMap, take } from 'rxjs';

import { OfferListFetchParams } from '@fcom/common/interfaces';
import { ConfigService, SentryLogger, unsubscribe } from '@fcom/core/index';
import { OffersMultiService } from '@fcom/dapi/api/services';
import {
  BookingAppState,
  MultiCityOffer,
  MultiCityState,
  MultiCityStatus,
  OffersPerBound,
  UFOInfo,
} from '@fcom/common/interfaces/booking';
import { retryWithBackoff, snapshot } from '@fcom/rx';
import {
  Bound,
  Cabin,
  FareInformation,
  FinnairBoundItem,
  FinnairCabinClass,
  FinnairLocation,
  ItineraryItemFlight,
  ItineraryItemLayover,
  MultiOfferList,
} from '@fcom/dapi/api/models';
import { LanguageService } from '@fcom/ui-translate';
import { isFlight } from '@fcom/common-booking/utils/common-booking.utils';
import { globalBookingFlights } from '@fcom/common/store';

import { BoundsActions, MultiCityActions } from '../../../store/actions';
import { BookingQueueService } from '../../../services';
import {
  selectMultiCity,
  selectMultiCityAvailableFareFamilies,
  selectMultiCityFareFamiliesPerCabin,
  selectMultiCityRequestedMoreFlightOfferIds,
  selectMultiCitySelectedFareFamily,
  selectMultiCitySelectedItinerary,
} from '../../../store/selectors';
import { BUS_AIRCRAFT_CODE } from '../interfaces';
import { convertOfferListFetchParamsToFinnairSearchParameters } from '../../../utils';

@Injectable()
export class BookingMultiCityService implements OnDestroy {
  static NUMBER_OF_RETRIES = 3;

  static ITINERARY_ID_REGEX = /^[a-z,A-Z]{3}-[0-9]{1,2}-/;
  static ITINERARY_SEGMENT_ID_REGEX = /SEG-.+?-/;

  offers$: Observable<MultiCityState>;
  selectedItinerary$: Observable<string[]>;
  selectedFareFamily$: Observable<string>;

  private subscriptions = new Subscription();
  locations$: Observable<{ [key: string]: FinnairLocation }>;

  constructor(
    private configService: ConfigService,
    private offersMultiService: OffersMultiService,
    private store$: Store<BookingAppState>,
    private sentryLogger: SentryLogger,
    private bookingQueueService: BookingQueueService,
    private languageService: LanguageService
  ) {
    this.offers$ = this.store$.pipe(select(selectMultiCity), filter(Boolean));
    this.selectedItinerary$ = this.store$.pipe(select(selectMultiCitySelectedItinerary));
    this.selectedFareFamily$ = this.store$.pipe(select(selectMultiCitySelectedFareFamily));
    this.locations$ = this.offers$.pipe(map((offers) => offers.locations));
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  fetchMultiCityOffers(params: OfferListFetchParams): void {
    this.store$.dispatch(MultiCityActions.reset());
    this.store$.dispatch(MultiCityActions.setStatus({ status: MultiCityStatus.PENDING }));

    this.subscriptions.add(
      this.offersMultiService
        .findMultiOfferList(this.configService.cfg.offersUrl, {
          body: convertOfferListFetchParamsToFinnairSearchParameters(params),
        })
        .pipe(share(), retryWithBackoff(BookingMultiCityService.NUMBER_OF_RETRIES))
        .subscribe({
          next: (data: MultiOfferList) => {
            if (data.status === 'NO_FLIGHTS_FOUND') {
              this.store$.dispatch(MultiCityActions.setStatus({ status: MultiCityStatus.NO_OFFERS }));
              return;
            }

            const offers = {
              ...data,
              statusCode: data.status,
              offers: data.offers.map((offer) => {
                return {
                  ...offer,
                  hash: this.getUniqueHashIdentifier(data.hash),
                  fullId: data.offerIdPrefix + offer.offerId,
                  itinerary: offer.itinerary.map((itineraryId) => {
                    return itineraryId.replace(BookingMultiCityService.ITINERARY_ID_REGEX, '');
                  }),
                };
              }),
              bounds: this.mergeDuplicatedBounds(data.bounds),
              hashMap: {
                [this.getUniqueHashIdentifier(data.hash)]: data.hash,
              },
            };

            delete offers.offerIdPrefix;
            delete offers.hash;
            delete offers.status;

            this.store$.dispatch(
              MultiCityActions.setMultiCityOffers({
                offers: offers as unknown as MultiCityState,
              })
            );
            this.store$.dispatch(BoundsActions.setLastRequestParams({ params }));
            this.store$.dispatch(MultiCityActions.setStatus({ status: MultiCityStatus.READY }));
          },
          error: (error: HttpErrorResponse) => {
            this.sentryLogger.error('Error fetching multi-city offers', { error });
            this.store$.dispatch(MultiCityActions.setStatus({ status: MultiCityStatus.ERROR }));
          },
        })
    );
  }

  fetchMultiCityMoreFlights(offer: MultiCityOffer, currentBound: number): void {
    const requestedMoreFlightOfferIds = snapshot(this.store$.pipe(select(selectMultiCityRequestedMoreFlightOfferIds)));

    /**
     * We request more flights for an offer and bound id and we want to avoid repeat calls to the
     * API. We could store this as an object with the 2 params separate, but that makes the
     * comparison more complex.
     */
    if (requestedMoreFlightOfferIds.includes(`${offer.fullId}|${currentBound}`)) {
      return;
    }

    this.store$.dispatch(
      MultiCityActions.addRequestedMoreFlightOfferIds({
        offerId: `${offer.fullId}|${currentBound}`,
      })
    );
    this.store$.dispatch(MultiCityActions.setStatus({ status: MultiCityStatus.PENDING_MORE_FLIGHTS }));

    this.subscriptions.add(
      this.offers$
        .pipe(
          take(1),
          switchMap((offers) => {
            return this.offersMultiService.findMoreFlights(this.configService.cfg.offersUrl, {
              hash: offers.hashMap[offer.hash],
              body: {
                airOfferId: offer.offerId,
                boundIndex: currentBound + 1,
              },
            });
          }),
          share(),
          retryWithBackoff(BookingMultiCityService.NUMBER_OF_RETRIES)
        )
        .subscribe({
          next: (data: MultiOfferList) => {
            this.store$.dispatch(
              MultiCityActions.addMultiCityOffers({
                offers: data.offers.map((offerData) => {
                  return {
                    ...offerData,
                    hash: this.getUniqueHashIdentifier(data.hash),
                    fullId: data.offerIdPrefix + offerData.offerId,
                    itinerary: offerData.itinerary.map((itineraryId) => {
                      return itineraryId.replace(BookingMultiCityService.ITINERARY_ID_REGEX, '');
                    }),
                  };
                }),
                bounds: this.mergeDuplicatedBounds(data.bounds),
                locations: data.locations,
                hashMap: {
                  [this.getUniqueHashIdentifier(data.hash)]: data.hash,
                },
              })
            );
            this.store$.dispatch(MultiCityActions.setStatus({ status: MultiCityStatus.READY }));
          },
          error: (error: HttpErrorResponse) => {
            this.sentryLogger.error('Error fetching multi-city more-flights', { error });
            this.store$.dispatch(MultiCityActions.setStatus({ status: MultiCityStatus.READY }));
          },
        })
    );
  }

  createCart(selectedOffer: MultiCityOffer): void {
    this.subscriptions.add(
      this.offers$.pipe(take(1)).subscribe((offers) => {
        const locale = this.languageService.localeValue;

        this.bookingQueueService.queueCartCreate(selectedOffer.offerId, locale, offers.hashMap[selectedOffer.hash]);
      })
    );
  }

  /**
   * Converts an array of multi-city offers into unique available itineraries per bound.
   *
   * For example, when we display bound 1 to the user. There may be 5 unique flights displayed, but
   * 100 actual offers. This means there is many offers that match to each displayed option.
   */
  getOffersPerBounds(): Observable<OffersPerBound[][]> {
    return combineLatest([
      this.store$.pipe(globalBookingFlights()),
      this.offers$,
      this.store$.pipe(select(selectMultiCitySelectedItinerary)),
      this.store$.pipe(select(selectMultiCitySelectedFareFamily)),
      this.store$.pipe(select(selectMultiCityAvailableFareFamilies)),
      this.store$.pipe(select(selectMultiCityFareFamiliesPerCabin)),
    ]).pipe(
      map(([flights, offers, selectedItinerary, selectedFareFamily, availableFareFamilies, ffCabin]) => {
        return flights.map((_flight, index) => {
          return offers.offers.reduce((uniqueFlights: OffersPerBound[], offer) => {
            if (uniqueFlights.some((uniqueFlight) => uniqueFlight.itineraryId === offer.itinerary[index])) {
              return uniqueFlights;
            }

            const matchingOffers = offers.offers.filter((listOffer) => {
              const hasMatchingFareFamily = Object.values(listOffer.offerItems[0].fareFamilyCodePerBound).includes(
                selectedFareFamily
              );

              return (
                hasMatchingFareFamily &&
                listOffer.itinerary.every((itineraryId, j) => {
                  return (
                    (!selectedItinerary[j] || itineraryId === selectedItinerary[j]) &&
                    offer.itinerary[index] === listOffer.itinerary[index]
                  );
                })
              );
            });

            if (index !== selectedItinerary.length || !matchingOffers.length || !selectedFareFamily) {
              return uniqueFlights;
            }

            const cheapestMatchingOffer = this.findCheapestOffer(matchingOffers);

            const filteredFareFamily = availableFareFamilies.find((ff) => ff.fareFamilyCode === selectedFareFamily);

            uniqueFlights.push({
              itineraryId: cheapestMatchingOffer.offer.itinerary[index],
              cheapestOffer: cheapestMatchingOffer.offer,
              fareFamily: {
                ...filteredFareFamily,
                totalPrice: cheapestMatchingOffer.price,
              },
              boundInfo: this.getUFOInfo(offers, cheapestMatchingOffer.offer, index, selectedFareFamily, ffCabin),
            });

            return uniqueFlights;
          }, []);
        });
      })
    );
  }

  findCheapestOffer(offers: MultiCityOffer[]): { price: string; offer: MultiCityOffer } {
    return offers.reduce(
      (match, offer) => {
        const price = Number(offer.offerItems[0].totalPrice);

        return price < Number(match.price) ? { price: String(price), offer } : match;
      },
      { price: '999999', offer: null }
    );
  }

  getCabinForFareFamily(fareFamilyCode: string, cabins: Partial<Record<Cabin, string[]>>): Cabin | FinnairCabinClass {
    return Object.keys(cabins).reduce<Cabin>((acc, cabin: Cabin) => {
      if (cabins[cabin].includes(fareFamilyCode)) {
        return cabin;
      }

      return acc;
    }, null);
  }

  getCurrency(): Observable<string> {
    return this.offers$.pipe(map((offers) => offers.currency));
  }

  private mergeDuplicatedBounds(bounds: { [key: string]: Bound }) {
    return Object.keys(bounds).reduce((newBounds, boundId) => {
      newBounds[boundId.replace(BookingMultiCityService.ITINERARY_ID_REGEX, '')] = bounds[boundId];

      return newBounds;
    }, {});
  }

  private getUFOInfo(
    offers: MultiCityState,
    offer: MultiCityOffer,
    boundIndex: number,
    ffCode: string,
    cabins: Partial<Record<Cabin, string[]>>
  ): UFOInfo {
    const matchingBound = {
      ...offers.bounds[offer.itinerary[boundIndex]],
      itinerary: offers.bounds[offer.itinerary[boundIndex]].itinerary.map((item) => ({
        ...item,
        bookingClass: this.getFareInformation(offer, item)?.bookingClass,
        cabinClass: this.getFareInformation(offer, item)?.cabinClass,
      })) as unknown as Bound['itinerary'],
    };

    const cabinClass = this.getCabinForFareFamily(ffCode, cabins) as FinnairCabinClass;

    return {
      departure: {
        city: offers.locations[matchingBound.departure.locationCode].cityName,
        airportCode: matchingBound.departure.locationCode,
        airport: offers.locations[matchingBound.departure.locationCode].name,
        dateTime: matchingBound.departure.dateTime,
      },
      arrival: {
        city: offers.locations[matchingBound.arrival.locationCode].cityName,
        airportCode: matchingBound.arrival.locationCode,
        airport: offers.locations[matchingBound.arrival.locationCode].name,
        dateTime: matchingBound.arrival.dateTime,
      },
      tails: matchingBound.operatingAirlineCodes,
      duration: matchingBound.duration,
      airlines: matchingBound.uniqueAirlineNames,
      bound: matchingBound as unknown as FinnairBoundItem,
      stops: matchingBound.stops,
      itinerary: matchingBound.itinerary,
      cabinClass,
      isByBusOnly: matchingBound.itinerary
        .filter(isFlight)
        .flatMap((flight) => flight.aircraft?.code)
        .every((aircraftCode) => aircraftCode?.toUpperCase() === BUS_AIRCRAFT_CODE),
      itineraryWithDifferentCabinClass: this.containsOnlyOneCabin(matchingBound.itinerary, cabinClass),
    };
  }

  getFareInformation(offer: MultiCityOffer, item: ItineraryItemFlight): FareInformation | undefined {
    return Object.values(offer.offerItems[0].fareInformationPerBound).reduce<FareInformation | undefined>(
      (acc, fareInfos) =>
        acc ??
        fareInfos.find(
          (fi) =>
            fi.segmentId.replace(BookingMultiCityService.ITINERARY_SEGMENT_ID_REGEX, '') ===
            item.id?.replace(BookingMultiCityService.ITINERARY_SEGMENT_ID_REGEX, '')
        ),
      undefined
    );
  }

  containsOnlyOneCabin(
    itinerary: (ItineraryItemFlight | ItineraryItemLayover)[],
    cabinClass: FinnairCabinClass
  ): ItineraryItemFlight[] {
    return itinerary.filter((item) => isFlight(item) && item.cabinClass !== cabinClass);
  }

  getUniqueHashIdentifier(hash: string): string {
    return hash.substring(hash.length - 12);
  }
}
