import { Component, Output, EventEmitter } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

@Component({
  selector: 'fin-flight-filters-empty-state',
  templateUrl: './flight-filters-empty-state.component.html',
  styleUrls: ['./flight-filters-empty-state.component.scss'],
})
export class FlightFiltersEmptyStateComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Output()
  clearAllFilters: EventEmitter<void> = new EventEmitter();
}
