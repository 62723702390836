/* eslint-disable no-underscore-dangle */
import { createSelector, MemoizedSelector, select } from '@ngrx/store';

import { safeSelect, StateSelector } from '@fcom/core/selectors/selector-utils';
import { Amount, BoundType, PaxAmount } from '@fcom/dapi/interfaces';
import { isPresent, LocalDate, mergeDeep } from '@fcom/core/utils';
import {
  Cabin,
  FareFamily,
  FinnairAirBoundsRequest,
  FinnairBoundFareFamily,
  FinnairCabinClass,
  FinnairItineraryItemFlight,
  FareInformation,
} from '@fcom/dapi/api/models';
import { GtmFlightSelectionData, GtmPurchaseFlow, OfferListFetchParams } from '@fcom/common/interfaces';
import { getGtmFlightSelectionDataForFareFamily } from '@fcom/common/utils/gtm.utils';
import { SortBy } from '@fcom/common-booking';
import { shouldTimeBeKept } from '@fcom/common-booking/utils/date-utils';
import { FlightFilters, FlightTimeOption } from '@fcom/common-booking/interfaces/flight-filters.interface';
import { isFlight } from '@fcom/common-booking/utils/common-booking.utils';
import { FLIGHT_SELECTION_CACHE_EXPIRY } from '@fcom/common/config/booking-config';
import {
  AirBoundsState,
  AirOffersStatus,
  BookingAppState,
  BookingFeatureState,
  FareFamilyMap,
} from '@fcom/common/interfaces/booking';
import { FinnairAirBounds, FinnairBoundGroupWithLocation } from '@fcom/common/interfaces/booking/offers.interface';

import { bookingFeatureState } from './booking-feature-state.selector';
import { TicketSelectionParams } from '../../modules/ticket-selection/services';

const _airBoundsState = createSelector(bookingFeatureState, (_bfs: BookingFeatureState) => _bfs?.bounds);
const _boundStatus = createSelector(_airBoundsState, (_abs: AirBoundsState) => _abs?.status);
const _boundRefundale = createSelector(_airBoundsState, (_abs: AirBoundsState) => _abs?.filters?.refundable);
const _boundDepartureTime = createSelector(_airBoundsState, (_abs: AirBoundsState) => _abs?.filters?.departureTime);
const _boundArrivalTime = createSelector(_airBoundsState, (_abs: AirBoundsState) => _abs?.filters?.arrivalTime);
const _boundStopsCount = createSelector(_airBoundsState, (_abs: AirBoundsState) => _abs?.filters?.stopsCount);
const _boundCabin = createSelector(_airBoundsState, (_abs: AirBoundsState) => _abs?.filters?.cabin);
export const outboundsSelector = createSelector(
  _airBoundsState,
  (airBoundsState: AirBoundsState) => airBoundsState?.outbounds
);
export const inboundsSelector = createSelector(
  _airBoundsState,
  (airBoundsState: AirBoundsState) => airBoundsState?.inbounds
);
/**
 * TODO: It would be safer to use the inbound hash (if existing) because it has newer timestamp than outbound.
 */
const _boundsHash = createSelector(outboundsSelector, (_b: FinnairAirBounds) => _b?.hash);
const _boundsCurrency = createSelector(outboundsSelector, (_b: FinnairAirBounds) => _b?.currency);
const _request = createSelector(outboundsSelector, (_b: FinnairAirBounds) => _b?.request);
const _inboundsFareFamiliesSorting = createSelector(
  inboundsSelector,
  (_b: FinnairAirBounds) => _b?.sorting?.fareFamilies
);
const _outboundsFareFamiliesSorting = createSelector(
  outboundsSelector,
  (_b: FinnairAirBounds) => _b?.sorting?.fareFamilies
);

const _outboundsSelectedCheapestOffer = createSelector(
  _airBoundsState,
  (_b: AirBoundsState) => _b?.isSelectedCheapestOffer
);

const _oneWayBoundsSelector = createSelector(_request, (_r) => (_r ? _r.itineraries.length === 1 : undefined));
const _lastRequestParams = createSelector(_airBoundsState, (_abs: AirBoundsState) => _abs?.lastRequestParams);
const _serializedLastRequestParams = createSelector(_lastRequestParams, (lastRequestParams: OfferListFetchParams) =>
  JSON.stringify(lastRequestParams)
);
const _departureDate = createSelector(
  _lastRequestParams,
  (lastRequestParams: OfferListFetchParams) => lastRequestParams?.flights?.[0]?.departureDate
);
const _returnDate = createSelector(
  _lastRequestParams,
  (lastRequestParams: OfferListFetchParams) => lastRequestParams?.flights?.[1]?.departureDate
);
const _outboundsBoundGroupsSelector = createSelector(outboundsSelector, (_obs: FinnairAirBounds) => _obs?.boundGroups);
const _inboundsBoundGroupsSelector = createSelector(inboundsSelector, (_obs: FinnairAirBounds) => _obs?.boundGroups);
export const selectedOutboundAirBoundIdSelector = createSelector(
  _airBoundsState,
  (_abs: AirBoundsState) => _abs?.selectedOutboundAirBoundId
);
export const selectedInboundAirBoundIdSelector = createSelector(
  _airBoundsState,
  (_abs: AirBoundsState) => _abs?.selectedInboundAirBoundId
);
const _selectedAirBoundIdsSelector = createSelector(
  _oneWayBoundsSelector,
  selectedOutboundAirBoundIdSelector,
  selectedInboundAirBoundIdSelector,
  (oneway: boolean, outboundAirBoundId: string, inboundAirBoundId: string): string[] => {
    if (oneway) {
      return [outboundAirBoundId];
    }
    return [outboundAirBoundId, inboundAirBoundId];
  }
);
const _selectedOutboundBoundsSelector = createSelector(
  _outboundsBoundGroupsSelector,
  selectedOutboundAirBoundIdSelector,
  (
    _outboundBoundGroups: FinnairBoundGroupWithLocation[] = [],
    selectedOutBoundFareFamilyId: string
  ): FinnairBoundGroupWithLocation | undefined =>
    _outboundBoundGroups.find((b: FinnairBoundGroupWithLocation) =>
      b.fareFamilies.some((ff) => ff.id === selectedOutBoundFareFamilyId)
    )
);
const _selectedInboundBoundsSelector = createSelector(
  _inboundsBoundGroupsSelector,
  selectedInboundAirBoundIdSelector,
  (
    inboundBoundGroups: FinnairBoundGroupWithLocation[] = [],
    selectedInBoundFareFamilyId: string
  ): FinnairBoundGroupWithLocation | undefined =>
    inboundBoundGroups.find((b: FinnairBoundGroupWithLocation) =>
      b.fareFamilies.some((ff) => ff.id === selectedInBoundFareFamilyId)
    )
);
const _selectedOutBoundAirBoundSelector = createSelector(
  _selectedOutboundBoundsSelector,
  selectedOutboundAirBoundIdSelector,
  (_b: FinnairBoundGroupWithLocation, _ffId: string): FinnairBoundFareFamily =>
    isPresent(_b) ? _b.fareFamilies.find((ff) => ff.id === _ffId) : undefined
);
const _selectedInBoundAirBoundSelector = createSelector(
  _selectedInboundBoundsSelector,
  selectedInboundAirBoundIdSelector,
  (_b: FinnairBoundGroupWithLocation, _ffId: string): FinnairBoundFareFamily =>
    isPresent(_b) ? _b.fareFamilies.find((ff) => ff.id === _ffId) : undefined
);
const _selectedOutboundFareFamilyCodeBoundsSelector = createSelector(
  _selectedOutBoundAirBoundSelector,
  (_ff) => _ff?.fareFamilyCode
);
const _selectedInboundFareFamilyCodeBoundsSelector = createSelector(
  _selectedInBoundAirBoundSelector,
  (_ff) => _ff?.fareFamilyCode
);
export const selectedOutboundIdBoundsSelector = createSelector(
  _selectedOutboundBoundsSelector,
  (_b: FinnairBoundGroupWithLocation) => _b?.sortingId
);
export const selectedInboundIdBoundsSelector = createSelector(
  _selectedInboundBoundsSelector,
  (_b: FinnairBoundGroupWithLocation) => _b?.sortingId
);
const _outboundFareFamilies = createSelector(outboundsSelector, (_b: FinnairAirBounds) => _b?.fareFamilies);
const _inboundFareFamilies = createSelector(inboundsSelector, (_b: FinnairAirBounds) => _b?.fareFamilies);
const _selectedOutBoundFareFamilyBenefitsSelector = createSelector(
  _outboundFareFamilies,
  _selectedOutboundFareFamilyCodeBoundsSelector,
  (_ffMap: FareFamilyMap, _ffCode: string): FareFamily => _ffMap?.[_ffCode]
);
const _selectedInBoundFareFamilyBenefitsSelector = createSelector(
  _inboundFareFamilies,
  _selectedInboundFareFamilyCodeBoundsSelector,
  (_ffMap: FareFamilyMap, _ffCode: string): FareFamily => _ffMap?.[_ffCode]
);
const mapItinerary = (fareFamilyCode: string, bound: FinnairBoundGroupWithLocation | undefined) => {
  if (!bound) {
    return [];
  }

  const fareInformation = bound.fareFamilies.find((f) => f.fareFamilyCode === fareFamilyCode)?.fareInformation;

  return bound.details.itinerary.filter(isFlight).map((flight, index) => ({
    ...flight,
    bookingClass: fareInformation?.[index]?.bookingClass,
    cabinClass: fareInformation?.[index]?.cabinClass as FinnairCabinClass,
  }));
};
const _selectedOutBoundItinerarySelector = createSelector(
  _selectedOutboundFareFamilyCodeBoundsSelector,
  _selectedOutboundBoundsSelector,
  (_ffCode, bound): FinnairItineraryItemFlight[] => mapItinerary(_ffCode, bound)
);
const _selectedInBoundItinerarySelector = createSelector(
  _selectedInboundFareFamilyCodeBoundsSelector,
  _selectedInboundBoundsSelector,
  (_ffCode, bound): FinnairItineraryItemFlight[] => mapItinerary(_ffCode, bound)
);
const _fareFamilies = createSelector(_outboundFareFamilies, _inboundFareFamilies, (outboundFFs, inboundFFs) =>
  mergeDeep(outboundFFs, inboundFFs)
);
const _selectedAirBoundsSortBySelector = createSelector(_airBoundsState, (_abs: AirBoundsState) => _abs?.sortBy);

const _flightSelectionGtmDataForAirBound = (
  bound: FinnairBoundGroupWithLocation,
  boundType: BoundType,
  paxAmount: PaxAmount
) =>
  createSelector(
    _boundsCurrency,
    _fareFamilies,
    _lastRequestParams,
    (currencyCode: string, fareFamilyBenefitsMap: FareFamilyMap, lastRequestParams): GtmFlightSelectionData[] =>
      isPresent(currencyCode) && isPresent(fareFamilyBenefitsMap) && isPresent(paxAmount)
        ? bound.fareFamilies.map((fareFamily) =>
            getGtmFlightSelectionDataForFareFamily(
              bound.details,
              boundType,
              bound.details.itinerary,
              fareFamilyBenefitsMap[fareFamily.fareFamilyCode]?.brandName,
              fareFamily.price,
              undefined,
              fareFamily.fareInformation,
              currencyCode,
              paxAmount,
              lastRequestParams.isAward ? GtmPurchaseFlow.AWARD : GtmPurchaseFlow.BOOKING
            )
          )
        : undefined
  );

const _boundSelectionGtmDataSelectorGenerator = (
  boundType: BoundType,
  boundSelector: MemoizedSelector<BookingAppState, FinnairBoundGroupWithLocation>,
  fareFamilySelector: MemoizedSelector<BookingAppState, FinnairBoundFareFamily>,
  paxAmount: PaxAmount
) =>
  createSelector(
    boundSelector,
    fareFamilySelector,
    _boundsCurrency,
    _fareFamilies,
    _lastRequestParams,
    (
      bound: FinnairBoundGroupWithLocation,
      fareFamily: FinnairBoundFareFamily,
      currencyCode: string,
      fareFamilyBenefitsMap: FareFamilyMap,
      lastRequestParams
    ): GtmFlightSelectionData => {
      return isPresent(bound) &&
        isPresent(fareFamily) &&
        isPresent(currencyCode) &&
        isPresent(fareFamilyBenefitsMap) &&
        isPresent(paxAmount)
        ? getGtmFlightSelectionDataForFareFamily(
            bound.details,
            boundType,
            bound.details.itinerary,
            fareFamilyBenefitsMap[fareFamily.fareFamilyCode]?.brandName,
            fareFamily.price,
            undefined,
            fareFamily.fareInformation,
            currencyCode,
            paxAmount,
            lastRequestParams.isAward ? GtmPurchaseFlow.AWARD : GtmPurchaseFlow.BOOKING
          )
        : undefined;
    }
  );

/**
 * Selects true or false dependending whether the selected ticket types have no baggage.
 * Handles both one way and return travel types.
 * @param {BookingOffer} offer
 */
const _hasZeroBaggageOnEitherBound = createSelector(
  _fareFamilies,
  _oneWayBoundsSelector,
  _selectedOutboundFareFamilyCodeBoundsSelector,
  _selectedInboundFareFamilyCodeBoundsSelector,
  (fareFamilies: FareFamilyMap, isOneWay: boolean, outboundFareFamilyCode: string, inboundFareFamilyCode: string) => {
    if (!fareFamilies) {
      return undefined;
    }
    const outboundFareFamily: FareFamily = fareFamilies[outboundFareFamilyCode];
    const outboundBaggage = outboundFareFamily?.checkedBaggage || 0;
    if (isOneWay) {
      return outboundBaggage === 0;
    }
    const inboundFareFamily: FareFamily = fareFamilies[inboundFareFamilyCode];
    const inboundBaggage = inboundFareFamily?.checkedBaggage || 0;
    return outboundBaggage === 0 || inboundBaggage === 0;
  }
);

/**
 * Selects fare families brands.
 * Handles both one way and return travel types.
 */
const _getSelectedFareFamiliesBrands = createSelector(
  _fareFamilies,
  _oneWayBoundsSelector,
  _selectedOutboundFareFamilyCodeBoundsSelector,
  _selectedInboundFareFamilyCodeBoundsSelector,
  (fareFamilies: FareFamilyMap, isOneWay: boolean, outboundFareFamilyCode: string, inboundFareFamilyCode: string) => {
    if (!fareFamilies) {
      return undefined;
    }
    const outboundFareFamily: FareFamily = fareFamilies[outboundFareFamilyCode];
    if (isOneWay) {
      return [outboundFareFamily.brandName];
    }
    const inboundFareFamily: FareFamily = fareFamilies[inboundFareFamilyCode];
    return [outboundFareFamily.brandName, inboundFareFamily.brandName];
  }
);

const _boundsTotalPrice = createSelector(
  _selectedOutBoundAirBoundSelector,
  _selectedInBoundAirBoundSelector,
  _boundsCurrency,
  (outboundFareFamily, inboundFareFamily, currencyCode): Amount => {
    const totalSum = inboundFareFamily?.totalPrice || outboundFareFamily?.totalPrice;
    const amount = totalSum === '0' ? undefined : totalSum;

    return { amount, currencyCode };
  }
);

const _filteredOutbounds = createSelector(
  outboundsSelector,
  _fareFamilies,
  _boundRefundale,
  _boundDepartureTime,
  _boundArrivalTime,
  _boundStopsCount,
  _boundCabin,
  filterBounds
);

const _filteredInbounds = createSelector(
  inboundsSelector,
  _fareFamilies,
  _boundRefundale,
  _boundDepartureTime,
  _boundArrivalTime,
  _boundStopsCount,
  _boundCabin,
  filterBounds
);

export const inboundsFareFamiliesSortingSelector = (): StateSelector<BookingFeatureState, string[]> =>
  select(_inboundsFareFamiliesSorting);

export const outboundsFareFamiliesSortingSelector = (): StateSelector<BookingFeatureState, string[]> =>
  select(_outboundsFareFamiliesSorting);

/**
 * Selects the current status of the air bounds query
 */
export const boundsStatus = (): StateSelector<BookingFeatureState, AirOffersStatus> => safeSelect(_boundStatus);

export const boundsRefundable = (): StateSelector<BookingFeatureState, boolean> => safeSelect(_boundRefundale);

export const boundsDepartureTime = (): StateSelector<BookingFeatureState, FlightTimeOption[]> =>
  safeSelect(_boundDepartureTime);

export const boundsReturnTime = (): StateSelector<BookingFeatureState, FlightTimeOption[]> =>
  safeSelect(_boundArrivalTime);

/**
 * Selects the outbounds from the state. Will not emit if not present.
 */
export const outbounds = (): StateSelector<BookingFeatureState, FinnairAirBounds> => select(outboundsSelector);

/**
 * Selects the inbounds from the state. Will not emit if not present.
 */
export const inbounds = (): StateSelector<BookingFeatureState, FinnairAirBounds> => select(inboundsSelector);

/**
 * Selects the filtered outbounds if present
 */
export const filteredOutbounds = (): StateSelector<BookingFeatureState, FinnairAirBounds> => select(_filteredOutbounds);

/**
 * Selects the filtered inbounds if present
 */
export const filteredInbounds = (): StateSelector<BookingFeatureState, FinnairAirBounds> => select(_filteredInbounds);

/**
 * Gets the hash returned with the bounds
 */
export const boundsHash = (): StateSelector<BookingFeatureState, string> => safeSelect(_boundsHash);

/**
 * Gets the currency code used with the bounds
 */
export const boundsCurrency = (): StateSelector<BookingFeatureState, string> => safeSelect(_boundsCurrency);

/**
 *  Selects all possible fare families
 */
export const fareFamiliesBounds = (): StateSelector<BookingFeatureState, FinnairAirBounds['fareFamilies']> =>
  select(_fareFamilies);

/**
 * Selects whether the bounds query was for one way
 */
export const isOneWayBounds = (): StateSelector<BookingFeatureState, boolean> => safeSelect(_oneWayBoundsSelector);

/**
 * Params of the last request
 */
export const lastRequestParams = (): StateSelector<BookingFeatureState, TicketSelectionParams> =>
  select(_lastRequestParams);

/**
 * Serialized version of the params of the last request
 */
export const serializedLastRequestParams = (): StateSelector<BookingFeatureState, string> =>
  select(_serializedLastRequestParams);

export const airBoundsLastRequestDepartureDate = (): StateSelector<BookingFeatureState, LocalDate> =>
  select(_departureDate);
export const airBoundsLastRequestReturnDate = (): StateSelector<BookingFeatureState, LocalDate> => select(_returnDate);
export const selectedOutboundBound = (): StateSelector<BookingFeatureState, FinnairBoundGroupWithLocation> =>
  select(_selectedOutboundBoundsSelector);
export const selectedInboundBound = (): StateSelector<BookingFeatureState, FinnairBoundGroupWithLocation> =>
  select(_selectedInboundBoundsSelector);
export const selectedOutboundIdBounds = (): StateSelector<BookingFeatureState, string> =>
  select(selectedOutboundIdBoundsSelector);
export const selectedOutboundFareFamilyCodeBounds = (): StateSelector<BookingFeatureState, string> =>
  select(_selectedOutboundFareFamilyCodeBoundsSelector);
export const selectedInboundIdBounds = (): StateSelector<BookingFeatureState, string> =>
  select(selectedInboundIdBoundsSelector);
export const selectedInboundFareFamilyCodeBounds = (): StateSelector<BookingFeatureState, string> =>
  select(_selectedInboundFareFamilyCodeBoundsSelector);
export const selectedOutboundAirBoundId = (): StateSelector<BookingFeatureState, string> =>
  select(selectedOutboundAirBoundIdSelector);
export const selectedInboundAirBoundId = (): StateSelector<BookingFeatureState, string> =>
  select(selectedInboundAirBoundIdSelector);
export const selectedOutboundAirBound = (): StateSelector<BookingFeatureState, FinnairBoundFareFamily> =>
  select(_selectedOutBoundAirBoundSelector);
export const selectedInboundAirBound = (): StateSelector<BookingFeatureState, FinnairBoundFareFamily> =>
  select(_selectedInBoundAirBoundSelector);
export const selectedAirBoundIds = (): StateSelector<BookingFeatureState, string[]> =>
  select(_selectedAirBoundIdsSelector);
export const selectedOutboundFareFamilyBenefits = (): StateSelector<BookingFeatureState, FareFamily> =>
  select(_selectedOutBoundFareFamilyBenefitsSelector);
export const selectedInboundFareFamilyBenefits = (): StateSelector<BookingFeatureState, FareFamily> =>
  select(_selectedInBoundFareFamilyBenefitsSelector);
export const selectedOutboundItinerary = (): StateSelector<BookingFeatureState, FinnairItineraryItemFlight[]> =>
  select(_selectedOutBoundItinerarySelector);
export const selectedInboundItinerary = (): StateSelector<BookingFeatureState, FinnairItineraryItemFlight[]> =>
  select(_selectedInBoundItinerarySelector);
export const airBoundsRequest = (): StateSelector<BookingFeatureState, FinnairAirBoundsRequest> => select(_request);

export const selectedAirBoundsTicketSortBy = (): StateSelector<BookingFeatureState, SortBy> =>
  select(_selectedAirBoundsSortBySelector);

export const hasZeroBaggageOnEitherBound = (): StateSelector<BookingFeatureState, boolean> =>
  safeSelect(_hasZeroBaggageOnEitherBound);
export const boundsTotalPrice = (): StateSelector<BookingFeatureState, Amount> => select(_boundsTotalPrice);

// Analytics related selectors
export const outboundSelectionGtmData = (
  paxAmount: PaxAmount
): StateSelector<BookingFeatureState, GtmFlightSelectionData> =>
  safeSelect(
    _boundSelectionGtmDataSelectorGenerator(
      BoundType.outbound,
      _selectedOutboundBoundsSelector,
      _selectedOutBoundAirBoundSelector,
      paxAmount
    )
  );
export const inboundSelectionGtmData = (
  paxAmount: PaxAmount
): StateSelector<BookingFeatureState, GtmFlightSelectionData> =>
  safeSelect(
    _boundSelectionGtmDataSelectorGenerator(
      BoundType.inbound,
      _selectedInboundBoundsSelector,
      _selectedInBoundAirBoundSelector,
      paxAmount
    )
  );
export const flightSelectionGtmDataForAirBound = (
  bound: FinnairBoundGroupWithLocation,
  boundType: BoundType,
  paxAmount: PaxAmount
): StateSelector<BookingFeatureState, GtmFlightSelectionData[]> =>
  safeSelect(_flightSelectionGtmDataForAirBound(bound, boundType, paxAmount));

export const selectedCheapestOffer = (): StateSelector<BookingAppState, boolean> =>
  select(_outboundsSelectedCheapestOffer);

export const selectFareFamiliesBrands = (): StateSelector<BookingFeatureState, string[]> =>
  select(_getSelectedFareFamiliesBrands);

function filterBounds(
  bound: FinnairAirBounds,
  fareFamilies: FareFamilyMap,
  refundable: boolean,
  departureTimes: FlightTimeOption[],
  arrivalTimes: FlightTimeOption[],
  stopCount: number,
  cabin: Cabin
): FinnairAirBounds {
  let filteredItems = bound;
  if (!filteredItems) {
    return filteredItems;
  }

  if (departureTimes && departureTimes.length !== 3) {
    filteredItems = {
      ...filteredItems,
      boundGroups: filteredItems.boundGroups?.filter((b) => {
        const dateTime = b.details.departure.dateTime;

        return shouldTimeBeKept(departureTimes, dateTime);
      }),
    };
  }

  if (arrivalTimes && arrivalTimes.length !== 3) {
    filteredItems = {
      ...filteredItems,
      boundGroups: filteredItems.boundGroups?.filter((b) => {
        const dateTime = b.details.arrival.dateTime;

        return shouldTimeBeKept(arrivalTimes, dateTime);
      }),
    };
  }

  if (refundable) {
    filteredItems = {
      ...filteredItems,
      boundGroups: filteredItems.boundGroups?.map((b) => ({
        ...b,
        fareFamilies: b.fareFamilies.filter(
          (ff) => fareFamilies[ff.fareFamilyCode].translatedBenefitRows.find((b) => b.key === 'cancel')?.isPositive
        ),
      })),
    };
  }

  if (stopCount != null) {
    filteredItems = {
      ...filteredItems,
      boundGroups: filteredItems.boundGroups?.filter((boundGroup) =>
        stopCount === 0 ? boundGroup.details.stops === 0 : boundGroup.details.stops >= stopCount
      ),
    };
  }

  if (cabin && cabin !== Cabin.MIXED) {
    filteredItems = {
      ...filteredItems,
      boundGroups: filteredItems.boundGroups
        ?.map((b) => ({
          ...b,
          fareFamilies: b.fareFamilies.filter((fareFamily) => {
            const fareInformation = fareFamily.fareInformation.filter(
              ({ cabinClass }: FareInformation) => cabinClass === cabin
            );

            return fareInformation.length > 0;
          }),
        }))
        .filter((boundGroup) => boundGroup.fareFamilies.length > 0),
    };
  }

  return filteredItems;
}

const _filters = createSelector(_airBoundsState, (_abs: AirBoundsState) => _abs?.filters);
export const filters = (): StateSelector<BookingFeatureState, FlightFilters> => select(_filters);

const _outboundCache = (hash: string, currentTime: number) => {
  return createSelector(_airBoundsState, (_abs: AirBoundsState) => {
    const resultInCache = _abs?.outboundCache[hash];

    // Cache expires after 15 minutes
    if (resultInCache?.createdAt + FLIGHT_SELECTION_CACHE_EXPIRY > currentTime) {
      return resultInCache.results;
    }

    return null;
  });
};

export const outboundCache = (
  hash: string,
  currentTime: number
): StateSelector<BookingFeatureState, FinnairAirBounds> => select(_outboundCache(hash, currentTime));
