import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

import { CmsContent, CmsContentType, CmsTemplate, CmsViewType } from '@fcom/core-api';
import { isPresent } from '@fcom/core/utils';

type FindByType<T extends CmsContent> = OperatorFunction<CmsContent[], T>;

export const findByType = <T extends CmsContent>(viewType: CmsViewType, exactMatch = true): FindByType<T> =>
  map(
    (contents: CmsContent[]) =>
      contents.find(
        ({ viewTypeName }: T) =>
          isPresent(viewTypeName) && (exactMatch ? viewTypeName === viewType : viewTypeName.endsWith(viewType))
      ) as T
  );

export const filterWithoutType = (
  viewType: CmsViewType,
  contentKey: 'header' | 'main' | 'footer'
): OperatorFunction<CmsTemplate, CmsTemplate> =>
  map((template: CmsTemplate) => ({
    ...template,
    [contentKey]: template[contentKey].filter((c) => c.viewTypeName !== viewType),
  }));

export const filterWithoutContentType = (
  contentType: CmsContentType,
  contentKey: 'header' | 'main' | 'footer'
): OperatorFunction<CmsTemplate, CmsTemplate> =>
  map((template: CmsTemplate) => ({
    ...template,
    [contentKey]: template[contentKey].filter((c) => c.contentType !== contentType),
  }));

export const transformListContent = (content: string, iconNameToUse: SvgLibraryIcon): string =>
  content
    .replace(/<ul(.*?)>/g, (_, item) => `<ul${item.replace(/ class="(.*?)"/, '')} class="no-list-style">`)
    .replace(
      /<li([^>]*)>([\s\S]*?)<\/li>/gi,
      `<li$1><div class="flex flex--nowrap">
      <fcom-icon class="mr-xsmall-r flex--nogrow" name="${iconNameToUse}"></fcom-icon>
      <div class="flex-1">$2</div>
    </div></li>`
    );
