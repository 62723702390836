import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { PseudoLocalActions } from '@fcom/core/actions';
import { AppState } from '@fcom/core';
import { pseudoLocal } from '@fcom/core/selectors';
import { finShare, snapshot } from '@fcom/rx';
import { unsubscribe } from '@fcom/core/utils';

import { TARGET_HEIGHT_ELEMENT } from './expand-content.component';

@Component({
  selector: 'fin-expand',
  templateUrl: 'expand.component.html',
})
export class ExpandComponent implements OnInit, OnDestroy {
  @Input()
  id: string;

  @Input()
  key: string;

  @Input()
  initiallyOpen: boolean;

  @Input()
  clearSelectionOnDestroy = false;

  @Input()
  expandContainerClass?: string | string[];

  @Input()
  expandContentClass?: string | string[];

  @Input()
  disabled = false;

  @Input()
  targetHeightElement: TARGET_HEIGHT_ELEMENT = TARGET_HEIGHT_ELEMENT.CHILDREN;

  @Input()
  useCustomTrigger = false;

  @Output()
  expandOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  subscription: Subscription = new Subscription();
  isOpen$: Observable<boolean>;
  initialValue: boolean;

  constructor(private store$: Store<AppState>) {}

  ngOnInit(): void {
    if (this.initiallyOpen) {
      this.store$.dispatch(PseudoLocalActions.setValue({ key: this.key, data: this.id }));
    }

    // The get initial render not to flicker when open
    this.initialValue = snapshot(this.store$.pipe(pseudoLocal(this.key))) === this.id;

    this.expandOpen.emit(this.initialValue);

    const openState$: Observable<boolean> = this.store$.pipe(
      pseudoLocal(this.key),
      map((value) => value === this.id),
      distinctUntilChanged(),
      finShare()
    );

    this.subscription.add(openState$.subscribe((item) => this.expandOpen.emit(item)));
    this.isOpen$ = openState$;
  }

  ngOnDestroy(): void {
    if (this.clearSelectionOnDestroy) {
      this.store$.dispatch(PseudoLocalActions.setValue({ key: this.key, data: undefined }));
    }
    this.subscription = unsubscribe(this.subscription);
  }

  toggleDetails(event?: Event): void {
    const currentValue: string = snapshot(this.store$.pipe(pseudoLocal(this.key)));
    const newValue: string = currentValue === this.id ? undefined : this.id;
    this.store$.dispatch(PseudoLocalActions.setValue({ key: this.key, data: newValue }));
    event?.preventDefault();
  }
}
