import { Breadcrumb } from '@fcom/ui-components';

import { Stage } from './interfaces/stage.interface';

export const UPGRADE_FEATURE_KEY = 'upgrade';

export const BREADCRUMBS: Breadcrumb[] = [
  {
    id: Stage.PASSENGERS,
    name: null,
    translationKey: 'passengers',
    active: false,
  },
  {
    id: Stage.FLIGHTS,
    name: null,
    translationKey: 'flights',
    active: false,
  },
  {
    id: Stage.REVIEW,
    name: null,
    translationKey: 'review',
    active: false,
  },
];

export const WAITLIST_CUTOFF = 60 * 24 * 60 * 60 * 1000; // 60 day in milliseonds

export const FINNAIR_LIKE_AIRLINECODES = ['N7', 'AY'];

export const OFFERS_V2_TIMEOUT = 30000; // V2Offers API has long processing times, so for now we add timeout for long queries
