<ng-template
  #priceLine
  let-label="label"
  let-value="value"
  let-originalValue="originalValue"
  let-labelContent="labelContent"
  let-bold="bold"
>
  <tr>
    <th scope="row" class="align-left" [ngClass]="{ 'light-type': !bold, 'medium-type ms-xsmall-t': bold }">
      <ng-container *ngIf="label; else labelContent">{{ label }}</ng-container>
    </th>
    <td class="align-right" [ngClass]="{ 'medium-type ms-xsmall-t': bold }">
      <span class="nordic-blue-900-text strike-through pr-xxsmall-r" *ngIf="originalValue">{{ originalValue }}</span
      >{{ value }}
    </td>
  </tr>
</ng-template>

<fin-expand
  class="flex--basis-full block pr-small-x mr-small-t pr-small-b"
  *ngFor="let breakdown of breakdowns$ | finObsToArray; let last = last"
  [class.border-bottom]="!last"
  [class.rounded-large]="last"
  [key]="'price-breakdown-expand-' + (breakdown | async)?.id"
  [id]="'price-breakdown-expand-' + (breakdown | async)?.id"
  [initiallyOpen]="expandOpen"
  #expand
>
  <div class="trigger flex" *ngIf="breakdown | async as passenger">
    <div class="flex-1">
      <h3 class="font-body-1 flex transform-none">
        <fcom-icon
          class="flex flex-1 flex--nogrow flex--self-start icon-medium nordic-blue-900-text"
          [name]="
            passenger.passengerTypeCode === FinnairPassengerCode.INF
              ? SvgLibraryIcon.ADULT_INFANT
              : SvgLibraryIcon.SINGLE_PAX
          "
        ></fcom-icon>
        <span class="flex-1 flex--self-center ps-xsmall-l medium-type" data-hj-suppress>
          {{ passenger?.title ? ('passenger.title.' + passenger?.title | lowercase | finLocalization) : '' }}
          {{ passenger?.firstName }} {{ passenger?.lastName }} ({{
            'passenger.' + asPaxTypeKey(passenger.passengerTypeCode).toLowerCase() | finLocalization | titlecase
          }})
        </span>
      </h3>
    </div>
    <fcom-icon
      class="flex-1 flex--nogrow rotate flex--self-start icon-medium nordic-blue-900-text"
      [class.rotate-active]="expand.isOpen$ | async"
      [name]="SvgLibraryIcon.CHEVRON_DOWN"
    ></fcom-icon>
  </div>
  <div class="font-body-1 ps-xsmall-t content" *ngIf="breakdown | async as pax">
    <table class="fill tax-table">
      <caption class="is-vishidden">
        {{
          'purchase.review.taxBreakDown.header' | finLocalization
        }}
      </caption>

      <tbody>
        <ng-container
          *ngIf="showBaseFare"
          [ngTemplateOutlet]="priceLine"
          [ngTemplateOutletContext]="{
            label: 'purchase.review.baseFare' | finLocalization,
            originalValue: pax.prices?.originalBaseFare ? (pax.prices?.originalBaseFare | finPrice) : undefined,
            value:
              showPoints && pax.prices?.totalPoints
                ? ('award.points' | finLocalization: { pointsAmount: +pax.prices?.totalPoints.amount | finPoints })
                : (pax.prices?.baseFare | finPrice),
            bold: false,
          }"
        >
        </ng-container>
        <ng-template #labelContent>
          <button
            *ngIf="showTotalTax"
            (click)="
              openTaxModal(
                $event,
                pax.prices.taxes,
                asPaxTypeKey(pax.passengerTypeCode).toLowerCase(),
                pax.prices.totalTax
              )
            "
            class="underline nordic-blue-900-text"
          >
            {{ 'purchase.review.taxTotal' | finLocalization }}
          </button>
        </ng-template>
        <ng-container
          *ngIf="showTotalTax"
          [ngTemplateOutlet]="priceLine"
          [ngTemplateOutletContext]="{
            value: pax.prices?.totalTax || { amount: '0', currencyCode: pax.prices?.baseFare.currencyCode } | finPrice,
            labelContent: pax.prices?.totalTax ? labelContent : null,
            label: pax.prices?.totalTax ? null : ('purchase.review.taxTotal' | finLocalization),
          }"
        >
        </ng-container>
        <ng-container *ngFor="let fee of pax.prices?.fees">
          <ng-container
            [ngTemplateOutlet]="priceLine"
            [ngTemplateOutletContext]="{
              label: 'purchase.review.' + fee.code | finLocalization,
              value: fee | finPrice,
              bold: false,
            }"
          >
          </ng-container>
        </ng-container>
        <ng-container *ngFor="let service of pax.services">
          <ng-container
            *ngIf="service.count"
            [ngTemplateOutlet]="priceLine"
            [ngTemplateOutletContext]="{
              label:
                service.key
                | finLocalization
                  : {
                      seatCount: service.count,
                      mealCount: service.count,
                      baggageCount: service.count,
                      loungeCount: service.count,
                      travelComfortCount: service.count,
                      wifiCount: service.count,
                      coverCount: service.count,
                      petCount: service.count,
                      sportCount: service.count,
                      specialCount: service.count,
                      firearmCount: service.count,
                      medicCount: service.count,
                      specialNeedCount: service.count,
                      childCount: service.count,
                      amount: service.count,
                    },
              value: service.total | finPrice,
              originalValue: service.originalTotal | finPrice,
              bold: false,
            }"
          >
          </ng-container>
        </ng-container>
      </tbody>
      <tfoot>
        <ng-container *ngIf="showSubTotals$ | async">
          <ng-container
            [ngTemplateOutlet]="priceLine"
            [ngTemplateOutletContext]="{
              label:
                ('price.total' | finLocalization) +
                ' (' +
                ('passenger.' + asPaxTypeKey(pax.passengerTypeCode).toLowerCase() | finLocalization) +
                ')',
              value:
                showPoints && pax.prices?.totalPoints
                  ? ('award.points' | finLocalization: { pointsAmount: +pax.prices?.totalPoints.amount | finPoints }) +
                    '&nbsp;+&nbsp;' +
                    (pax.total | finPrice)
                  : (pax.total | finPrice),
              bold: true,
            }"
          >
          </ng-container>
        </ng-container>
      </tfoot>
    </table>
  </div>
</fin-expand>

<fcom-modal
  [(open)]="taxModalOpen"
  [title]="'purchase.review.taxTotal' | finLocalization"
  [buttons]="ModalButtons.DONE"
  (close)="closeTaxModal()"
>
  <div class="pr-large">
    <cms-unstyled-fragment
      *ngIf="'fragments.purchaseTaxesAndRefund.url' | finLocalization"
      class="block font-body-1 unstyled-fragment-line-height pr-medium-b"
      [contentUrl$]="'fragments.purchaseTaxesAndRefund.url' | finLocalization | finToObservable"
    >
    </cms-unstyled-fragment>

    <table class="fill dictionary-table tax-table font-body-1" *ngIf="taxModalData">
      <caption class="is-vishidden">
        {{
          'purchase.review.taxBreakDown.header' | finLocalization
        }}
      </caption>
      <thead>
        <tr>
          <th class="bold-type ms-medium-y" scope="col" colspan="2">
            {{ 'purchase.review.taxBreakDown.per.' + taxModalData.ptc | finLocalization }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let tax of taxModalData.taxes" class="flex flex--space-between">
          <th scope="row">
            {{ 'TAX_CODES.' + tax.code.slice(0, 2) | finLocalization | finEmpty: tax.code.slice(0, 2) }}
          </th>
          <td>{{ tax | finPrice }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="flex flex--space-between">
          <th scope="row">{{ 'price.total' | finLocalization }}</th>
          <td>{{ taxModalData.total | finPrice }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</fcom-modal>
