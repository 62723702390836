/* eslint-disable */

/**
 * Document type
 */
export enum FinnairBoardingPassDocumentType {
  PDF = 'PDF',
  APPLE_WALLET = 'APPLE_WALLET',
  RAW_DATA = 'RAW_DATA'
}

