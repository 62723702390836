import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiTranslateModule } from '@fcom/ui-translate';

import { IconsModule } from '../icons/icons.module';
import { DrawerComponent } from './drawer.component';
import { ClickOutsideDirectiveModule } from '../../directives/click-outside/click-outside-directive.module';
import { TrapFocusModule } from '../../directives/trap-focus/trap-focus.module';
import { ButtonModule } from '../buttons';

@NgModule({
  declarations: [DrawerComponent],
  imports: [CommonModule, IconsModule, UiTranslateModule, ClickOutsideDirectiveModule, TrapFocusModule, ButtonModule],
  exports: [DrawerComponent],
})
export class DrawerModule {}
