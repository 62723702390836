import { Component, Input, OnInit } from '@angular/core';

import { Observable, of, iif, combineLatest } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';

import { CmsDataService, LocationRouteCffService } from '@fcom/core';
import { CmsContentWithDestination, CmsImageData } from '@fcom/core-api';
import { GtmService } from '@fcom/common/gtm';
import { LanguageService } from '@fcom/ui-translate';
import { AmDestinationService } from '@fcom/destination-search/services/am-destination.service';
import { ContentType } from '@fcom/common/interfaces';
import { getDestinationInCampaign } from '@fcom/core/utils/cms-data-utils';

/**
 * CMS - Hero component for destination page
 *
 * @example
 * <fin-destination-hero [content$]="content$" ></fin-destination-hero>
 */
@Component({
  selector: 'cms-destination-hero',
  templateUrl: './cms-destination-hero.component.html',
})
export class CmsDestinationHeroComponent implements OnInit {
  @Input()
  content$: Observable<CmsContentWithDestination>;

  heroConfig$: Observable<{
    title: string;
    backgroundImage: CmsImageData;
    destinationCode: string;
    enableAward: boolean;
    enablePromoCode: boolean;
    enableAurinkomatkat: boolean;
    contentType: ContentType;
    isInCampaign: boolean;
    campaignLabel: string;
    campaignEndText: string;
    campaignBlackTeaser: boolean;
  }>;

  constructor(
    private gtmService: GtmService,
    private amDestinationService: AmDestinationService,
    private locationRouteCffService: LocationRouteCffService,
    private languageService: LanguageService,
    private cmsDataService: CmsDataService
  ) {}

  ngOnInit(): void {
    this.heroConfig$ = combineLatest([
      this.content$,
      this.cmsDataService.getOngoingCampaigns(this.languageService.langValue, this.languageService.localeValue),
    ]).pipe(
      tap(([content]) => {
        this.gtmService.destinationPromotion(
          'destination-hero-banner',
          content.destination.defaultLocation.locationCode,
          content.destination.defaultLocation.geoLocation?.countryCode
        );
      }),
      map(([content, campaigns]) => {
        const locationInCampaign = getDestinationInCampaign(
          campaigns,
          content.destination.defaultLocation.locationCode
        );

        return {
          title: content.destination.title,
          backgroundImage: content.destination.picture,
          destinationCode: content.destination.defaultLocation.locationCode,
          enableAward: this.getConfigProperty<boolean>(content.localSettings, 'enableAward', true),
          enablePromoCode: this.getConfigProperty<boolean>(content.localSettings, 'enablePromoCode', true),
          enableAurinkomatkat: false,
          contentType: ContentType.SINGLE_DESTINATION,
          isInCampaign: campaigns.some(
            (campaign) =>
              campaign?.destinations?.some(
                (dest: string) => dest === content.destination.defaultLocation.locationCode
              ) ||
              Object.values(campaign?.destinationsByOrigin || {})
                .flat()
                .some((dest: string) => dest === content.destination.defaultLocation.locationCode)
          ),
          campaignLabel: locationInCampaign?.campaignLabel,
          campaignEndText: locationInCampaign?.campaignEndText,
          campaignBlackTeaser: locationInCampaign?.enableBlackTeaser,
        };
      }),
      switchMap((config) =>
        iif(
          //only fetch the amlist and locale city name when it is FI site
          () => this.languageService.localeValue.includes('FI'),
          this.amDestinationService.amDestinationList().pipe(
            // first check if the am destination list includes the destinationCode,
            // if not in the list return the list for further check
            map((amList) => (amList.map(({ dest }) => dest).includes(config.destinationCode) ? null : amList)),
            switchMap((amList) =>
              iif(
                () => !!amList,
                // if the destinationCode is not find in am destination list, then check if the destination name match
                this.locationRouteCffService.getLocaleCityName(config.destinationCode, 'fi_FI').pipe(
                  map((localeCityName) => ({
                    ...config,
                    // enable the aurinkomatkat when the aurinko matka service is avalible and it is FI site
                    enableAurinkomatkat: amList.map(({ destName_fi }) => destName_fi).includes(localeCityName),
                  }))
                ),
                of({ ...config, enableAurinkomatkat: true })
              )
            )
          ),
          of(config)
        )
      )
    );
  }

  private getConfigProperty<T>(obj: Record<string, unknown>, key: string, defaultVal: T): T {
    if (typeof obj[key] === 'boolean') {
      return obj[key] as T;
    }

    return defaultVal;
  }
}
