import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';

import {
  PersonalizationTrackingService,
  NoopPersonalizationTrackingService,
} from './services/personalization-tracking.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  exports: [],
})
export class PersonalizationModule {
  static forClient(): ModuleWithProviders<PersonalizationModule> {
    return {
      ngModule: PersonalizationModule,
      providers: [{ provide: PersonalizationTrackingService, useClass: PersonalizationTrackingService }],
    };
  }

  static forServer(): ModuleWithProviders<PersonalizationModule> {
    return {
      ngModule: PersonalizationModule,
      providers: [{ provide: PersonalizationTrackingService, useClass: NoopPersonalizationTrackingService }],
    };
  }
}
