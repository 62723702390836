<div *ngIf="items$ | async as items" class="list-with-icons ms-large-b">
  <h3 class="mr-clear-t mr-medium-b">{{ (content$ | async)?.teaserTitle }}</h3>
  <ul>
    <li *ngFor="let item of items" class="flex flex--middle ms-medium-b">
      <span
        data-testid="list-with-icons-icon-wrapper"
        class="icon-circle flex flex--center flex--middle white-text nordic-blue-900-bg mr-xsmall-r"
      >
        <fcom-icon [name]="item.iconName" *ngIf="item.iconName"></fcom-icon>
      </span>
      <span class="nordic-blue-900-text light-type font-body-1" [innerHTML]="item.text"></span>
    </li>
  </ul>
</div>
