import { Component, Input, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { unsubscribe } from '@fcom/core/utils';
import { SelectOption } from '@fcom/common/interfaces';

/**
 * @deprecated Use SelectInputComponent from @fcom/ui-components instead.
 */
@Component({
  selector: 'fin-form-input-select',
  templateUrl: 'form-input-select.component.html',
  styleUrls: ['./form-input-select.component.scss'],
})
export class FormInputSelectComponent implements OnInit, OnDestroy {
  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  controlName: string;

  @Input()
  translationPrefix: string;

  @Input()
  options: SelectOption[];

  @Input()
  id: string;

  @Input()
  selectLabelTranslationKey = 'select';

  @Input()
  required = false;

  @Input()
  displayMargin = true;

  isRequired = false;
  idOrControlName: string;
  control: AbstractControl;

  @ViewChild('selectElement', { static: true })
  selectElement: ElementRef;

  protected subscriptions: Subscription = new Subscription();

  get fieldRequired(): boolean {
    return this.required || this.ctrlField.hasValidator(Validators.required);
  }

  get ctrlField(): AbstractControl {
    return this.parentForm.get(this.controlName);
  }

  ngOnInit(): void {
    this.control = this.ctrlField;
    this.isRequired = this.required || this.fieldRequired;
    this.idOrControlName = this.id || this.controlName;
    this.subscriptions.add(
      this.control.statusChanges.subscribe(() => {
        this.isRequired = this.fieldRequired;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  setFocus(): void {
    this.selectElement.nativeElement.focus();
  }
}
