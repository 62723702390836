import { createAction, props, union } from '@ngrx/store';

import { HistogramBar } from '@fcom/common';
import { AirCalendarList } from '@fcom/dapi/api/models';

import {
  CalendarPrices,
  InstantSearchMonitoring,
  NotificationWarning,
  SeasonalNotificationData,
  WidgetTab,
} from '../../interfaces';

export const clearState = createAction('[BookingWidget] clearState');

export const setInstantSearchMonitoring = createAction(
  '[BookingWidget] setInstantSearchMonitoring',
  props<{ instantSearchMonitoring: InstantSearchMonitoring }>()
);

export const resetPrices = createAction('[BookingWidget] resetPrices');
export const setFullYearPrices = createAction('[BookingWidget] setFullYearPrices', props<{ prices: CalendarPrices }>());
export const setFollowingMonthPrices = createAction(
  '[BookingWidget] setFollowingMonthPrices',
  props<{ prices: CalendarPrices }>()
);
export const setHistogramPrices = createAction(
  '[BookingWidget] setHistogramPrices',
  props<{ prices: HistogramBar[] }>()
);
export const setAirCalendarPrices = createAction(
  '[BookingWidget] setAirCalendarPrices',
  props<{ prices: AirCalendarList }>()
);

export const setNotificationWarning = createAction(
  '[BookingWidget] setNotificationWarning',
  props<{ key: NotificationWarning; isActive: boolean; data?: SeasonalNotificationData }>()
);

export const clearNotificationWarning = createAction(
  '[BookingWidget] clearNotificationWarning',
  props<{ key: NotificationWarning }>()
);

export const setActiveTab = createAction('[BookingWidget] SetActiveTab', props<{ activeTab: WidgetTab }>());

export const clearServiceNotificationWarnings = createAction('[BookingWidget] clearServiceNotificationWarnings');

export const clearNoFlightsNotificationWarnings = createAction('[BookingWidget] clearNoFlightsNotificationWarnings');

const all = union({
  clearState,
  setInstantSearchMonitoring,
  resetPrices,
  setFullYearPrices,
  setFollowingMonthPrices,
  setHistogramPrices,
  setNotificationWarning,
  clearNotificationWarning,
  clearServiceNotificationWarnings,
  clearNoFlightsNotificationWarnings,
  setAirCalendarPrices,
  setActiveTab,
});

export type BookingWidgetActionsUnion = typeof all;
