import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { IconLibrary } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CmsArticle, CmsPicture, CmsTaxonomyGeneral, CmsVideo } from '@fcom/core-api';
import { isNotBlank } from '@fcom/core/utils';
import { type IconName } from '@fcom/ui-components';

@Component({
  selector: 'cms-rich-text',
  template: `
    <fin-rich-text
      [class]="'block ' + extraClasses"
      [title$]="title$"
      [headerLevel]="headerLevel"
      [headerStyles]="headerStyles"
      [media$]="media$"
      [content$]="content$"
      [icons$]="icons$"
      [scaleImage]="scaleImage"
      [styleClasses$]="styleClasses$"
    ></fin-rich-text>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsRichTextComponent implements OnInit {
  @Input()
  content$: Observable<CmsArticle>;
  @Input()
  headerLevel: number;
  @Input()
  headerStyles = [];
  @Input()
  scaleImage = true;
  @Input()
  extraClasses = 'mr-large-b';

  title$: Observable<string>;
  media$: Observable<CmsPicture[] | CmsVideo[]>;
  icons$: Observable<{ name: IconName; category?: IconLibrary }[]>;
  styleClasses$: Observable<string[]>;

  ngOnInit(): void {
    this.title$ = this.content$.pipe(
      filter((content) => !content.subjectTaxonomyTags.some((tag) => tag === CmsTaxonomyGeneral.NO_TITLE)),
      map((t) => t.teaserTitle),
      filter(isNotBlank)
    );

    this.media$ = this.content$.pipe(
      // Mark response explicitly as array of CmsImageData or an Array of CmsVideo but not a mix
      map((content) => content.media as CmsPicture[] | CmsVideo[]),
      filter((media) => media.length > 0)
    );

    this.icons$ = this.content$.pipe(
      filter((content) => !!content.localSettings?.iconName),
      map((content) => {
        const names = content.localSettings?.iconName;
        const nameArr = Array.isArray(names) ? names : [names];
        const categories = content.localSettings?.iconCategory;
        const categoryArr = Array.isArray(categories) ? categories : [categories];
        return nameArr.map((name, index) => {
          return {
            name,
            category: categoryArr?.[index] ?? IconLibrary.SVG_LIBRARY,
          };
        });
      })
    );

    this.styleClasses$ = this.content$.pipe(
      map((content) => content.subjectTaxonomyTags),
      map((tags) =>
        tags.filter((tag) => {
          return tag.startsWith('cmstyle-');
        })
      )
    );
  }
}
