import { HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { DapiHttpErrorResponse } from '@fcom/dapi';

export const catchErrorAsJson =
  <T>() =>
  (o$: Observable<T>): Observable<T> =>
    o$.pipe(
      catchError((response: unknown) => {
        if (response instanceof HttpErrorResponse && typeof response.error === 'string') {
          try {
            const error = JSON.parse(response.error);
            return throwError(() => new DapiHttpErrorResponse({ ...response, error: error }));
            // eslint-disable-next-line no-empty
          } catch (_err) {}
        }
        return throwError(() => response);
      })
    );
