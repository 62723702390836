import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Observable } from 'rxjs';

import { CmsCollection } from '@fcom/core-api';

@Component({
  selector: 'cms-badge',
  styleUrls: ['cms-badge.component.scss'],
  templateUrl: 'cms-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsBadgeComponent {
  @Input()
  content$: Observable<CmsCollection>;

  constructor() {}
}
