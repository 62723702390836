<ng-container *ngIf="campaignLink$ | async as campaignLink">
  <div
    *ngIf="visibility$ | async"
    class="campaign-banner"
    [class.black-bg]="campaignLink.enableBlackTeaser"
    [class.heather-700-bg]="!campaignLink.enableBlackTeaser"
  >
    <div class="grid-col-12 grid-max-width">
      <div class="align-center col-1-12">
        <a
          [finLink]="campaignLink.link.url"
          [finElemTracker]="'campaign-banner'"
          [finElemContext]="'top-navigation'"
          [finElemType]="ElementTypes.BANNER"
          class="grey-25-text ps-xsmall inline-block font-body-1 mr-medium-x bold-type"
        >
          {{ campaignLink.text }}
        </a>
      </div>
    </div>
  </div>
</ng-container>
