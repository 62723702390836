<div *ngIf="activeNaviSection$ | async as section" class="section-navi-wrapper" data-elastic-exclude>
  <div class="section-navi df-laptop-down flex--column fill">
    <div
      class="section-title inline-block relative pr-medium-x pr-large-y z1 white-bg"
      [class.dn-laptop-down]="sectionNavi.isOpen$ | async"
      *ngIf="title && (showTitle$ | async) === true"
    >
      <h1 class="font-heading-1 nordic-blue-900-text">{{ title }}</h1>
    </div>

    <nav [attr.aria-label]="(activeNaviItem$ | async).teaserTitle">
      <ul
        *ngIf="section.items.length"
        class="section-navi-items no-list-style ps-large-bottom nordic-blue-900-text section-desktop-body dn-laptop-down"
      >
        <li
          finSideBarNavigationItem
          [link]="section.link"
          [title]="section.teaserTitle"
          [external]="section.external"
          [openInNewTab]="section.openInNewTab"
          [active]="section.active"
          [loginRequired]="section.loginRequired"
          [styleClasses]="'nordic-blue-900-text'"
        ></li>
        <ng-container *ngFor="let item of section.items; trackBy: trackByIndex">
          <li
            finSideBarNavigationItem
            *ngIf="item.link && !item.hideInSectionNavi"
            [link]="item.link"
            [title]="item.teaserTitle"
            [external]="item.external"
            [openInNewTab]="item.openInNewTab"
            [active]="item.active"
            [loginRequired]="item.loginRequired"
            [styleClasses]="'nordic-blue-900-text'"
          ></li>
        </ng-container>
      </ul>

      <fin-expand
        class="relative block fill section-accordion-body dn-laptop-up"
        [key]="'section-navi'"
        [id]="'section-navi'"
        [finElemTracker]="'navigation-open: ' + section.link"
        [finElemContext]="TRACKING_CONTEXT"
        [finElemType]="ElementTypes.LINK"
        [class.dn-laptop-down]="section.items.length < 1"
        #sectionNavi
      >
        <div class="trigger flex flex--center pr-xsmall-y">
          <div class="flex-1 ps-large-l">
            <div class="flex flex--start nordic-blue-900-text transform-none font-body-1 bold-type">
              {{ (activeNaviItem$ | async).teaserTitle }}
            </div>
          </div>
          <div class="ps-large-r">
            <fcom-icon
              class="flex-1 flex--nogrow rotate nordic-blue-900-text inline-block icon-medium"
              [class.rotate-active]="sectionNavi.isOpen$ | async"
              [name]="SvgLibraryIcon.CHEVRON_DOWN"
            ></fcom-icon>
          </div>
        </div>
        <div class="content">
          <ul class="section-navi-items no-list-style ps-large nordic-blue-900-text grey-50-bg">
            <li
              finSideBarNavigationItem
              [link]="section.link"
              [title]="section.teaserTitle"
              [external]="section.external"
              [openInNewTab]="section.openInNewTab"
              [active]="section.active"
              [loginRequired]="section.loginRequired"
              [styleClasses]="'nordic-blue-900-text'"
              (linkClicked)="toggleDetails(sectionNavi, section.external)"
            ></li>
            <ng-container *ngFor="let item of section.items; trackBy: trackByIndex">
              <li
                finSideBarNavigationItem
                *ngIf="item.link && !item.hideInSectionNavi"
                [link]="item.link"
                [title]="item.teaserTitle"
                [external]="item.external"
                [openInNewTab]="item.openInNewTab"
                [active]="item.active"
                [loginRequired]="item.loginRequired"
                [styleClasses]="'nordic-blue-900-text'"
                (linkClicked)="toggleDetails(sectionNavi, item.external)"
              ></li>
            </ng-container>
          </ul>
        </div>
      </fin-expand>
    </nav>
  </div>
</div>
<div *ngIf="(activeNaviSection$ | async) === undefined" class="section-navi fill ms-medium-b" data-elastic-exclude>
  <div
    *ngIf="title && (showTitle$ | async) === true"
    class="section-title inline-block relative ps-large-x ps-large-y z1 white-bg"
  >
    <h1 class="font-heading-1 bold-type nordic-blue-900-text">{{ title }}</h1>
  </div>
</div>
