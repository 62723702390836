<p>
  <span data-testid="flight-summary-departure-date">
    {{ flightOrBound.departure.dateTime | finDateFormat: 'DATE' }}
  </span>
  /
  <span data-testid="flight-summary-departure">
    {{ flightOrBound.departure.dateTime | finTime }} {{ flightOrBound.departure.locationCode }}
  </span>
  <span>
    <fcom-icon [name]="SvgLibraryIcon.AIRCRAFT_HORIZONTAL" class="icon-small"></fcom-icon>
  </span>
  <span data-testid="flight-summary-arrival-date">
    {{ flightOrBound.arrival.dateTime | finTime }}
  </span>
  <span
    data-testid="flight-summary-plus-one"
    *ngIf="returnIsDifferentDay"
    class="flex--self-start font-body-2 nordic-blue-900-text valign-top"
  >
    +1
  </span>
  <span data-testid="arrival-location-code">
    {{ flightOrBound.arrival.locationCode }}
  </span>
  /
  <span data-testid="flight-summary-airline-name" *ngFor="let uniqueAirlineName of uniqueAirlineNames; let last = last">
    {{ uniqueAirlineName }}<ng-container *ngIf="!last">,</ng-container>
  </span>
  / <span data-testid="flight-summary-duration"> {{ flightOrBound?.duration | finDuration | async }} </span> /
  <span data-testid="flight-summary-stops" [ngSwitch]="stops">
    <ng-container class="" *ngSwitchCase="0">{{ 'bookingSummary.direct' | finLocalization }}</ng-container>
    <ng-container *ngSwitchCase="1">{{ 'stopOver' | finLocalization: [stops] }}</ng-container>
    <ng-container *ngSwitchDefault>{{ 'stopOvers' | finLocalization: [stops] }}</ng-container>
  </span>
  <span *ngIf="fareFamily || cabinClass">
    /
    <span data-testid="flight-summary-brand-name"> {{ fareFamily ?? (cabinClass | finLocalization) }} </span>
  </span>
</p>
