import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable } from '@angular/core';

import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { WindowRef } from '@fcom/core';
import { findScrollContainer } from '@fcom/core/utils';

const HEADER_OFFSET = 40;
const ease = (t: number): number => 1 + --t * t * t * t * t;

const trimOffsets = (rect: ClientRect, parentRect: ClientRect): ClientRect => {
  return {
    ...rect,
    top: rect.top - parentRect.top,
    bottom: rect.bottom - parentRect.top,
  };
};

const getNativeElement = (target: ElementRef | HTMLElement) => {
  if (target instanceof HTMLElement) {
    return target;
  }
  return target.nativeElement;
};

type ScrollPosition = 'start' | 'center' | 'end';

interface ScrollOptions {
  duration?: number;
  offsetX?: number;
  offsetY?: number;
  edgeX?: ScrollPosition;
  edgeY?: ScrollPosition;
  container?: HTMLElement;
}

const defaultScrollOptions: ScrollOptions = {
  duration: 500,
  edgeX: 'start',
  edgeY: 'start',
  offsetX: 0,
  offsetY: 0,
};

@Injectable()
export class ScrollService {
  static readonly INVALID_INPUT_SELECTOR =
    'input.ng-invalid.ng-touched, select.ng-invalid.ng-touched, textarea.ng-invalid.ng-touched';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private windowRef: WindowRef
  ) {}

  scrollToImmediate(
    target: ElementRef | HTMLElement,
    offset = 0,
    includeHeaderHeight = false,
    smoothScroll = false
  ): void {
    const minusOffset: number = includeHeaderHeight ? HEADER_OFFSET + offset : offset;
    const scrollContainer = this.resolveScrollContainer(target);
    const [, scrollTop] = this.resolveScrollPositions(scrollContainer, target, {
      ...defaultScrollOptions,
      offsetY: minusOffset,
    });

    if (smoothScroll) {
      this.smoothScroll(target, {
        ...defaultScrollOptions,
        offsetY: minusOffset,
      });
    } else {
      this.setScroll(scrollContainer, scrollTop);
      this.dispatchScrollEvent(scrollContainer);
    }
  }

  smoothScroll(target: ElementRef | HTMLElement, scrollOptions: ScrollOptions = {}): void {
    const useScrollOptions = { ...defaultScrollOptions, ...scrollOptions };
    const { duration, container } = useScrollOptions;
    const containerToUse = container ?? this.resolveScrollContainer(target);
    const [scrollLeft, scrollTop] = this.resolveScrollPositions(containerToUse, target, useScrollOptions);
    this.smoothAnimate(
      containerToUse,
      duration,
      Date.now(),
      containerToUse.scrollLeft,
      containerToUse.scrollTop,
      scrollLeft,
      scrollTop
    );
  }

  listen(element: ElementRef | HTMLElement, shouldFindScrollContainer = true): Observable<ClientRect> {
    const nativeElement = getNativeElement(element);
    const scrollContainer = shouldFindScrollContainer
      ? findScrollContainer(nativeElement) || this.document.documentElement
      : nativeElement;
    return fromEvent(this.findEventContainer(scrollContainer), 'scroll').pipe(
      map(() =>
        this.isHtmlEvent(scrollContainer)
          ? nativeElement.getBoundingClientRect()
          : trimOffsets(nativeElement.getBoundingClientRect(), scrollContainer.getBoundingClientRect())
      )
    );
  }

  scrollToFirstInvalidInput(element: ElementRef | HTMLElement, offset = 0, smoothScroll = false): void {
    const nativeElement = getNativeElement(element);
    const firstInvalidElement = nativeElement.querySelector(ScrollService.INVALID_INPUT_SELECTOR);
    if (firstInvalidElement) {
      if (smoothScroll) {
        this.scrollToImmediate(firstInvalidElement, offset, false, true);
      } else {
        this.scrollToImmediate(firstInvalidElement, offset);
      }
    } else {
      console.log(`WARN: no invalid input found with selector ${ScrollService.INVALID_INPUT_SELECTOR}`);
    }
  }

  scrollTop(): void {
    this.windowRef.nativeWindow.scrollTo(0, 0);
  }

  private smoothAnimate(
    container: HTMLElement,
    duration: number,
    startTime: number,
    startX: number,
    startY: number,
    xToScroll: number,
    yToScroll: number
  ): void {
    const time = Date.now();
    const elapsed = Math.min((time - startTime) / duration, 1);
    const currentEase = ease(elapsed);
    const currentX = startX + (xToScroll - startX) * currentEase;
    const currentY = startY + (yToScroll - startY) * currentEase;

    this.setScroll(container, currentY, currentX);

    if (currentX !== xToScroll || currentY !== yToScroll) {
      requestAnimationFrame(() =>
        this.smoothAnimate(container, duration, startTime, startX, startY, xToScroll, yToScroll)
      );
    }
  }

  private resolveScrollContainer(target: ElementRef | HTMLElement) {
    const nativeElement = getNativeElement(target);
    return findScrollContainer(nativeElement) || this.document.documentElement;
  }

  private resolveScrollPositions(
    container: HTMLElement,
    target: ElementRef | HTMLElement,
    scrollOptions: ScrollOptions
  ): [number, number] {
    const nativeElement = getNativeElement(target);
    const viewportHeight = this.getViewPortHeight(container);
    const targetRect = nativeElement.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    const containerOffsetTop: number = this.isHtmlEvent(container) ? 0 : containerRect.top;

    const [containerScrollLeft, containerScrollTop] = this.getScrollPosition(container);

    const scrollLeft = this.resolveX(containerScrollLeft, containerRect, targetRect, scrollOptions);
    const scrollTop = this.resolveY(containerOffsetTop, viewportHeight, containerScrollTop, targetRect, scrollOptions);

    return [scrollLeft, scrollTop];
  }

  private resolveX(
    containerScrollLeft: number,
    containerRect: DOMRect,
    targetRect: DOMRect,
    { offsetX, edgeX }: ScrollOptions
  ): number {
    const elementLeft = targetRect.left - containerRect.left;
    const [containerEdge, targetEdge] = this.resolveEdgeX(containerRect, targetRect, edgeX);

    const elementRelativeEdge = elementLeft + targetEdge;
    const scrollChange = elementRelativeEdge - containerEdge;

    return containerScrollLeft + scrollChange + offsetX;
  }

  private resolveEdgeX(containerRect: DOMRect, targetRect: DOMRect, edgeX: ScrollPosition): [number, number] {
    if (edgeX === 'center') {
      return [containerRect.width / 2, targetRect.width / 2];
    } else if (edgeX === 'end') {
      return [containerRect.width, targetRect.width];
    } else {
      return [0, 0];
    }
  }

  private resolveY(
    containerOffsetTop: number,
    viewPortHeight: number,
    containerScrollTop: number,
    targetRect: DOMRect,
    { offsetY, edgeY }: ScrollOptions
  ): number {
    const [containerEdge, targetEdge] = this.resolveEdgeY(viewPortHeight, targetRect, edgeY);
    return containerScrollTop - containerOffsetTop + targetRect.top - containerEdge + targetEdge - offsetY;
  }

  private resolveEdgeY(viewPortHeight: number, targetRect: DOMRect, edgeY: ScrollPosition): [number, number] {
    if (edgeY === 'center') {
      return [viewPortHeight / 2, targetRect.height / 2];
    } else if (edgeY === 'end') {
      return [viewPortHeight, targetRect.height];
    } else {
      return [0, 0];
    }
  }

  private getScrollPosition(container: HTMLElement): [number, number] {
    // For Microsoft Edge we need to read the scrollTop from document.body
    const currentScrollTop: number =
      container.parentNode instanceof Document
        ? container.scrollTop ||
          (this.document.body.parentNode as HTMLElement).scrollTop ||
          this.document.body.scrollTop
        : container.scrollTop;

    const currentScrollLeft: number =
      container.parentNode instanceof Document
        ? container.scrollLeft ||
          (this.document.body.parentNode as HTMLElement).scrollLeft ||
          this.document.body.scrollLeft
        : container.scrollLeft;

    return [currentScrollLeft, currentScrollTop];
  }

  private setScroll(scrollContainer: HTMLElement, newScrollTopValue: number, newScrollLeftValue?: number): void {
    // Fix for Microsoft Edge scrollTop not working in the html element
    if (scrollContainer.parentNode instanceof Document) {
      scrollContainer.parentNode.defaultView.scrollTo(newScrollLeftValue ?? 0, newScrollTopValue);
    } else {
      scrollContainer.scrollTop = newScrollTopValue;
      if (newScrollLeftValue) {
        scrollContainer.scrollLeft = newScrollLeftValue;
      }
    }
  }

  private isHtmlEvent(scrollContainer: HTMLElement): boolean {
    return scrollContainer === this.document.documentElement;
  }

  private findEventContainer(scrollContainer: HTMLElement): HTMLElement | Window {
    return this.isHtmlEvent(scrollContainer) ? this.windowRef.nativeWindow : scrollContainer;
  }

  private dispatchScrollEvent(scrollContainer: HTMLElement): void {
    const evt = this.document.createEvent('MouseEvent');
    evt.initEvent('scroll', true, true);
    scrollContainer.dispatchEvent(evt);
  }

  private getViewPortHeight(container: HTMLElement): number {
    if (this.isHtmlEvent(container)) {
      return this.windowRef.nativeWindow['visualViewport']?.height ?? this.windowRef.nativeWindow.innerHeight;
    }

    return container.getBoundingClientRect().height;
  }
}
