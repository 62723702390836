import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserAgentService {
  userAgent: string;

  constructor(@Inject(PLATFORM_ID) private platform: unknown) {
    if (isPlatformBrowser(this.platform)) {
      this.userAgent = navigator.userAgent;
    }
  }

  isMobileDevice(): boolean {
    const regexs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i];
    return regexs.some((b) => this.userAgent.match(b));
  }

  isTabletDevice(): boolean {
    const regex =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(.*(IT|AT))))/i;
    return regex.test(this.userAgent);
  }

  isDesktopDevice(): boolean {
    return !this.isMobileDevice() && !this.isTabletDevice();
  }

  isAndroid(): boolean {
    return this.isMobileDevice() && /android/i.test(this.userAgent);
  }

  isIos(): boolean {
    return this.isMobileDevice() && /iPad|iPhone|iPod/.test(this.userAgent) && !(window as any).MSStream;
  }
}
