import { props, createAction, union } from '@ngrx/store';

export const setLanguage = createAction('[Language] Set language', props<{ language: string; locale: string }>());
export const setLocalizations = createAction(
  '[Language] Set language/locale',
  props<{ language: string; localization: any }>()
);

const all = union({ setLanguage, setLocalizations });

export type LanguageActionUnion = typeof all;
