<div
  class="bound-container flex flex--align-start flex--space-between nowrap flex--middle pr-xsmall-y pr-small-x border rounded-large grey-50-bg"
  [class.active]="active"
  [class.shadow-highlight]="active"
  [class.disabled]="disabled"
  data-testid="bound-container"
>
  <div class="font-body-2 ms-xsmall-r" data-testid="bound-container-title">
    <!--
    -->{{ label + ' ' + (date | finDateFormat: 'DATE')
    }}<!--
  --></div>

  <div class="bound-container__details font-body-1 flex flex-1 flex--end fill" data-testid="bound-container-details">
    <ng-container *ngIf="bound">
      <div class="truncate-wrapper flex">
        <span class="medium-type">{{
          (bound.departure.dateTime | finTime) + ' ' + (bound.departure.dateTime | finDateFormat: 'E')
        }}</span>
        <span class="city-name ms-xsmall-l truncate overflow-hidden">{{
          bound.departure.cityName || bound.departure.name || bound.departure.locationCode
        }}</span>
        <span class="ms-xsmall-l">{{ '-' }}</span>
      </div>
      <div class="truncate-wrapper flex">
        <span class="medium-type ms-xsmall-l">{{
          (bound.arrival.dateTime | finTime) + ' ' + (bound.arrival.dateTime | finDateFormat: 'E')
        }}</span>
        <span class="city-name ms-xsmall-l truncate overflow-hidden">{{
          bound.arrival.cityName || bound.arrival.name || bound.arrival.locationCode
        }}</span>
        <fcom-icon
          class="flex--nogrow icon-small ms-xsmall-l heather-700-text"
          [name]="SvgLibraryIcon.CHECKMARK"
        ></fcom-icon>
      </div>
    </ng-container>
    <div *ngIf="!bound" class="font-body-2 nordic-blue-900-text">
      {{ 'ticketSelection.noFlightSelected' | finLocalization }}
    </div>
  </div>
</div>
