export interface NewsletterSignupPayload {
  firstName: string;
  lastName: string;
  email: string;
  country: string;
  language: string;
  pageUrl: string;
  source?: NewsLetterSource;
  marketingNewsletter?: boolean;
  advertising?: boolean;
}

export enum NewsLetterSource {
  NEWSLETTER_FORM = 'newsletter_form',
  BOOKING_FLOW = 'Booking_flow',
}

export interface NewsletterSignupResponse {
  message: string;
}
