import { Cabin } from '@fcom/dapi/api/models';

export enum FlightTimeOption {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  EVENING = 'EVENING',
}

export type FlightFilters = {
  departureTime?: FlightTimeOption[];
  arrivalTime?: FlightTimeOption[];
  refundable?: boolean;
  stopsCount?: number | null;
  cabin?: Cabin;
};

export const defaultFilterValues = {
  defaultStopsOption: null,
  defaultRefundabilityOption: false,
  defaultTravelClass: Cabin.MIXED,
  defaultTimeOptions: [...Object.values(FlightTimeOption)],
};

export const FAKE_LOAD_TIME = 600;
