import { titleToLoginGender } from '@fcom/common-booking/utils/common-booking.utils';
import {
  CorporateUserRole,
  Profile,
  ProfileTier,
  RawCorporateProfile,
  RawUnknownProfile,
  SiebelBoolean,
} from '@fcom/core-api/login';
import { serializeParams } from '@fcom/core/utils';

export interface RedirectUrlParameters {
  lang: string;
  nonce: string;
  display?: string;
  redirectPath?: string;
}

export interface RedirectToLoginParams {
  redirectPath?: string;
  shouldLogoutFirst?: boolean;
}

export const parseProfile = (rawProfile: RawUnknownProfile): Profile => {
  const profile =
    rawProfile.tier === ProfileTier.CORPORATE
      ? mapCorporateToStandardProfile(rawProfile as RawCorporateProfile)
      : rawProfile;

  // For newly joined Junior members nextTier can be Basic in Siebel even the real next tier would be Silver
  if (profile.nextTier && profile.nextTier.nextTier === ProfileTier.BASIC) {
    profile.nextTier = {
      ...profile.nextTier,
      nextTier: ProfileTier.SILVER,
    };
  }

  const parsedProfile: Profile = {
    ...profile,
    isJuniorMember: profile.junior === SiebelBoolean.TRUE,
    isCorporate: profile.tier === ProfileTier.CORPORATE,
    corporate: {
      ...profile.corporate,
      userRole: rawProfile?.corporateUserProfile?.role || CorporateUserRole.TRAVELLER,
    },
    parsedMobilePhone: {
      ...(profile.parsedMobilePhone || {}),
    },
  };

  delete parsedProfile.junior;

  if (!parsedProfile.isCorporate) {
    delete parsedProfile.corporate;
  }

  return parsedProfile;
};

/**
 * Maps a corporate profile to a normal user profile, with an additional `corporate` object.
 *
 * There are many keys that have unknown mappings/no available data at the moment.
 *
 * expirationDate: profile.corporateUserProfile.expires, // maybe?
 * hasCreditCard: false,
 * joinDate: profile.corporateUserProfile.issued, // maybe?
 * lang: 'en',
 * marketingPermissions: {
 *   suppressAllEmails: false,
 *   suppresseMailWeeklyOffers: false,
 *   suppressPartnerPromotions: false,
 *   suppressSMSPromos: false,
 * },
 * memberStatus?: LoginMemberStatus;
 * nextTier?: LoginNextTier;
 * preferredMealType?: string;
 * preferredSeatType?: string;
 * householdSize?: number;
 * childrenBirthYears?: string[];
 * topicsOfInterest?: string[];
 * travelProfile?: {
 *   receiveFlightInfoEmail?: boolean;
 *   receiveFlightInfoSMS?: boolean;
 * };
 * lifetimeTierPoints: 0,
 * cardType: null,
 * cardExpirationDate: null,
 * sebCard: false,
 * statusHold: false, ,
 */
const mapCorporateToStandardProfile = (profile: RawCorporateProfile): RawUnknownProfile => {
  return {
    addresses: [],
    awardPoints: null,
    corporate: {
      ...profile.corporate,
      userId: profile.corporateUserProfile.userId,
    },
    dateOfBirth: profile.corporateUserProfile.dateOfBirth,
    email: profile.corporateUserProfile.email,
    firstname: profile.corporateUserProfile.firstname,
    gender: titleToLoginGender(profile.corporateUserProfile.title),
    junior: SiebelBoolean.FALSE,
    lastname: profile.corporateUserProfile.lastname,
    memberNumber: profile.corporateUserProfile.finnairPlus,
    mobilePhone: profile.corporateUserProfile.mobile,
    partStatus: {
      ...profile.partStatus,
    },
    status: profile.status,
    tier: profile.tier,
    passport: {
      passportCountry: null,
      passportExpirationDate: profile.corporateUserProfile.expires,
      passportIssueDate: profile.corporateUserProfile.issued,
      passportNumber: profile.corporateUserProfile.passportNumber,
    },
    parsedMobilePhone: profile.corporateUserProfile.parsedMobilePhone,
  };
};

const dec2hex = (dec: number): string => `0${dec.toString(16)}`.substr(-2);

/**
 * Returns a cryptographically secure nonce (for browsers supporting crypto, i.e., >=IE10)
 * @return {string} the secure random string
 */
export const secureRandomString = (cr: Crypto | undefined): string => {
  const length = 64;
  if (!cr) {
    // NOTE: this is not cryptographically secure for IE10
    let str = '';
    while (str.length < length) {
      str += Math.random().toString(36).substr(2);
    }
    return str.substr(0, length);
  }
  const arr = new Uint32Array(length / 2);
  cr.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
};

/**
 * Create redirectUrl.
 * Automatically adds the redirectPath parameter to default to current path if not given as parameter.
 *
 * @param {Location} location window.location
 * @param {string} uxAuthUrl https://api-dev.finnair.com/d/fcom/ux-auth-dev
 * @param {string} path the path to redirect to, e.g. `/fi-fi`
 * @param {{}} params additional query parameters for the redirect
 */
export const createRedirectUrl = (
  location: Location = {} as Location,
  uxAuthUrl: string,
  path: string,
  params: Partial<RedirectUrlParameters> = {}
): string => {
  const p = {
    redirectPath: location.href,
    ...params,
    ...(params.redirectPath && { redirectPath: (location.origin ?? '') + params.redirectPath }),
  };
  return [uxAuthUrl, path, '?', serializeParams(p)].join('');
};

export const isCorporateAdminOrTravelBooker = (profile?: Profile): boolean =>
  profile?.corporate?.userRole === CorporateUserRole.ADMIN ||
  profile?.corporate?.userRole === CorporateUserRole.TRAVEL_BOOKER;

export const isCorporateAdmin = (profile?: Profile): boolean =>
  profile?.corporate?.userRole === CorporateUserRole.ADMIN;
