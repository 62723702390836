import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

/**
 * Synchronously take the current value out of the store.
 * Will only work if the stream is replayable.
 *
 * We only use snapshot on Store by Developer policy or in unit tests.
 */
export const snapshot = <T>(o$?: Observable<T>): T => {
  let r: T;
  if (o$ instanceof Observable) {
    // eslint-disable-next-line rxjs/no-ignored-subscription
    o$.pipe(take(1)).subscribe((s) => (r = s));
  }
  return r;
};
