import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, exhaustMap, tap } from 'rxjs/operators';

import { TripsActions } from '../actions';
import { PaxTripService } from '../../services/pax-trip.service';
import { Trip } from '../../interfaces/pax-trip.interface';
import { FetchTripsStatus, State } from '../../interfaces/store.interface';

@Injectable()
export class TripsEffects {
  fetchTrips$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TripsActions.fetchTrips.type),
        exhaustMap(() => this.paxTripService.getTrips()),
        tap((trips: Trip[]) => {
          this.store.dispatch(TripsActions.setTrips({ trips: trips, status: FetchTripsStatus.OK }));
        }),
        catchError(() => {
          this.store.dispatch(TripsActions.setTrips({ trips: [], status: FetchTripsStatus.ERROR }));
          return EMPTY;
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions<TripsActions.TripsActionsUnion>,
    private paxTripService: PaxTripService,
    private store: Store<State>
  ) {}
}
