import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { ButtonType, ButtonTheme, ButtonMode, ToggleSwitchStatus } from '@fcom/ui-components';

import { Consent, ConsentStatus, ConsentTextId } from '../../interfaces';

const cookieConsentsValues = [
  ConsentTextId.COOKIE_ANALYTICS,
  ConsentTextId.COOKIE_PERSONALIZATION,
  ConsentTextId.COOKIE_ADVERTISING,
];

@Component({
  selector: 'fin-cookie-settings',
  styleUrls: ['./cookie-settings.component.scss'],
  templateUrl: './cookie-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookieSettingsComponent implements OnInit {
  @Input()
  consents$: Observable<Consent[]>;
  @Output()
  consentDataSelection: EventEmitter<{ [key: string]: boolean }> = new EventEmitter();
  @Output()
  modalClose = new EventEmitter();

  public readonly ButtonType = ButtonType;
  readonly ConsentStatus = ConsentStatus;
  readonly ToggleSwitchStatus = ToggleSwitchStatus;
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonMode = ButtonMode;

  cookieSettingsUrl$: Observable<string>;
  consentValue: ConsentTextId[];

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    this.cookieSettingsUrl$ = this.languageService.translate('fragments.cookieSettings.url').pipe(
      distinctUntilChanged(),
      filter((v) => !!v)
    );
    this.consentValue = [ConsentTextId.NOT_REAL_CONSENT_VALUE];
  }

  sendConsents(): void {
    this.consentDataSelection.emit(
      cookieConsentsValues.reduce(
        (all, consentId) => ({
          ...all,
          [consentId]: this.consentValue.includes(consentId),
        }),
        {}
      )
    );
  }

  allowAllCookies(): void {
    this.consentValue = cookieConsentsValues;
  }

  handleIsCheckChange(name: ConsentTextId): void {
    if (this.consentValue.includes(name)) {
      this.consentValue = this.consentValue.filter((val) => val !== name);
    } else {
      this.consentValue = this.consentValue.concat([name]);
    }
  }

  getStatus(currentId: ConsentTextId): ToggleSwitchStatus {
    // always checked the first option
    if (ConsentTextId.NOT_REAL_CONSENT_VALUE === currentId) {
      return ToggleSwitchStatus.CHECKED;
    }
    if (this.consentValue.includes(currentId)) {
      return ToggleSwitchStatus.ACTIVE;
    }
    return ToggleSwitchStatus.UNCHECKED;
  }
}
