<a
  *ngIf="target$ | async as link"
  [finLink]="link.url"
  [external]="link.external"
  [ngClass]="colorClass || 'nordic-blue-900-text'"
  class="block no-underline align-right font-body-1 bold-type pr-xxsmall-r underline-hover"
>
  {{ teaserTitle$ | async }}
  <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.ARROW_RIGHT"></fcom-icon>
</a>
