<div
  class="wrapper grid-max-width padding-left-gutter padding-right-gutter center z5"
  [ngClass]="{
    'fixed fill--pos': [LoginWidgetContext.BOOKING, LoginWidgetContext.MMB].includes(context),
    relative: ![LoginWidgetContext.BOOKING, LoginWidgetContext.MMB].includes(context),
  }"
>
  <div
    class="login-widget"
    [ngClass]="{
      'login-widget-open': isOpen$ | async,
      'booking-context': context === LoginWidgetContext.BOOKING,
      'mmb-context': context === LoginWidgetContext.MMB,
    }"
  >
    <div class="widget-content overflow-hidden white-bg shadow-highlight" *ngIf="widgetInDom$ | async">
      <button
        class="absolute ms-large-r ms-large-t close"
        [ngClass]="{
          'white-text': (darkBg$ | async) === true,
          'nordic-blue-900-text': (darkBg$ | async) === false,
        }"
        [attr.aria-label]="'actions.close' | finLocalization"
        [attr.title]="'actions.close' | finLocalization"
        (click)="close()"
      >
        <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.CLOSE_DELETE"></fcom-icon>
      </button>
      <fin-profile-quick-view
        *ngIf="(loginStatus$ | async) === LoginStatus.LOGGED_IN"
        [profile$]="profile$"
        (popOverClosed)="close()"
      ></fin-profile-quick-view>
      <div *ngIf="(loginStatus$ | async) === LoginStatus.NOT_LOGGED_IN" class="login-or-join grow relative z1">
        <div class="login-or-join-top relative ps-large-x ps-medium-t ps-large-b border-bottom z2">
          <span class="login-title nordic-blue-900-text bold-type font-body-1 inline-block">{{
            'navigation.login' | finLocalization
          }}</span>
          <span class="inline-block ms-large-b nordic-blue-900-text font-body-1 regular-type">{{
            'loginWidget.loginText' | finLocalization
          }}</span>
          <fcom-button
            [text]="'navigation.login' | finLocalization"
            (clickBtn)="redirectToLogin()"
            class="block login-button"
          ></fcom-button>
        </div>
        <div class="ps-large">
          <span class="nordic-blue-900-text bold-type font-body-1 inline-block">{{
            'loginWidget.joinTitle' | finLocalization
          }}</span>
          <span class="inline-block ps-large-b nordic-blue-900-text font-body-1 regular-type">{{
            'loginWidget.joinText' | finLocalization
          }}</span>
          <fcom-button
            [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
            [text]="'loginWidget.joinButton' | finLocalization"
            (clickBtn)="redirectToJoin()"
            class="block join-button"
          >
          </fcom-button>
        </div>
      </div>
    </div>
  </div>
</div>
