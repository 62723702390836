import {
  Component,
  forwardRef,
  ChangeDetectionStrategy,
  EventEmitter,
  Input,
  ChangeDetectorRef,
  Injector,
  HostBinding,
  Attribute,
  Output,
  OnInit,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { replaceMsgFormat } from '@fcom/core/utils/replaceMsgFormat';

import { AbstractInputComponent } from '../abstracts/abstract-input.component';
import { PopoverOptions } from '../../popover/popover.interface';

@Component({
  selector: 'fcom-floating-input',
  templateUrl: './floating-input.component.html',
  styleUrls: ['./floating-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FloatingInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatingInputComponent extends AbstractInputComponent implements OnInit {
  @HostBinding('class.floatLabel')
  get doesLabelFloat(): boolean {
    return this.floatLabel;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return !this.floatLabel || this.focused || !this.empty || !!this.placeholder;
  }

  @HostBinding('attr.tabindex')
  get hostTabIndex(): null {
    // Set host tabindex to null after having transferred the tabStop to the input element
    return null;
  }

  /** Label text for the input */
  @Input()
  label = '';

  /** Additional label for screen reader accessibility. Read before the label */
  @Input()
  hiddenLabel: string;

  /** Instructional text providing additional information on what to enter in the input */
  @Input()
  instruction: string;

  /** Autocomplete attribute specifies whether or not an input field should have autocomplete enabled. */
  @Input()
  autocomplete: string;

  /** Placeholder text of the element. */
  @Input()
  placeholder = '';

  /** Input type of the element. */
  @Input()
  get type(): string {
    return this.inputType;
  }

  set type(type: string) {
    if (type === 'tel') {
      this.inputPattern = this.inputPattern || '[0-9]*';
    }
    this.inputType = type || 'text';
    this.cdRef.markForCheck();
  }

  inputType = 'text';

  /** Input pattern for validation in template-driven forms. */
  @Input()
  inputPattern: string;

  /**
   * Boolean attribute indicating if the label should float.
   * Use with caution. Intention by designers to deprecate the floating option at a later date.
   */
  @Input()
  floatLabel = true;

  /** Boolean indicating if the simple styling class should be added. */
  @Input()
  simple = false;

  /** Boolean indicating if errors should be hidden from the user */
  @Input()
  hideError = false;

  /** Boolean indicating if the sensitive class should be added to hide the input from quantum metrics */
  @Input()
  sensitive = false;

  /** Name value will be applied to the input element if present */
  @Input()
  name: string | null = null;

  /**
   * Object containing the error key and corresponding error text to show the user. Error text can contain placeholders
   * Todo: improve validation message documentation.
   */
  @Input()
  validationMessages: { [key: string]: string } = {};

  /**
   * Name of the icon to display at the end of the input
   */
  @Input()
  iconName: SvgLibraryIcon;

  /**
   * Labels for aria attribute of the icon
   */
  @Input()
  iconAriaLabel: string;

  @Output() iconClicked: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  tooltipHeading: string;

  @Input()
  tooltipContent: string;

  @Input()
  tooltipOptions: PopoverOptions;

  tabIndex: number;

  constructor(cdRef: ChangeDetectorRef, injector: Injector, @Attribute('tabindex') tabIndex: string) {
    super(cdRef, injector);
    this.tabIndex = parseInt(tabIndex, 10) || 0;
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  get empty(): boolean {
    return !(this.value || '').trim();
  }

  private firstError(): any {
    if (!this.ngControl) {
      return { key: undefined, data: undefined };
    }
    const errors = this.ngControl.errors;
    if (!errors) {
      return { key: undefined, data: undefined };
    }
    const key = Object.keys(errors).filter((k) => errors[k])[0];

    return {
      key,
      data: errors[key],
    };
  }

  get printError(): string {
    const firstError = this.firstError();
    if (!firstError.key) {
      return '';
    }
    const searchKey = firstError.key.toLowerCase();

    for (const key in this.validationMessages) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.validationMessages.hasOwnProperty(key) && key.toLowerCase() === searchKey) {
        return replaceMsgFormat(this.validationMessages[key], firstError.data);
      }
    }

    return '';
  }

  emitClickedIcon(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.iconClicked.emit();
  }
}
