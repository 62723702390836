<fin-corporate-passenger-search
  *ngIf="isCorporateAdminOrTravelBooker$ | async"
  [corporateUsers$]="corporateUsers$"
  [passengerFormGroup]="passengerFormGroup"
  (userSelected)="selectCorporateUser($event)"
  (userCleared)="corporateUserCleared.emit($event)"
  (toggleLoader)="showLoader$.next($event)"
></fin-corporate-passenger-search>

<div class="absolute fill grow z1 loader-container pr-large-t" *ngIf="showLoader$ | async" #loaderContainer>
  <fcom-loader [loaderType]="LoaderTheme.INLINE"></fcom-loader>
</div>

<div *ngIf="(enablePaxDetailsRearrangement$ | async) === false" class="mandatory font-body-1 dark-grey flex flex--end">
  {{ 'paxDetails.mandatory' | finLocalization }}
</div>

<div class="flex flex--column">
  <div
    class="flex flex--column"
    *ngIf="(enablePaxDetailsRearrangement$ | async) === false && passengerFormGroup.contains(FIELDS.gender)"
  >
    <div
      class="title font-body-1 nordic-blue-900 bold-type pr-xsmall-t"
      [ngClass]="{
        'error-900-text':
          passengerFormGroup.get(FIELDS.gender).invalid && passengerFormGroup.get(FIELDS.gender).touched,
      }"
    >
      <span> {{ 'paxDetails.fields.gender.label' | finLocalization }} * </span>
      <ng-template #contentTooltip>
        <h2 class="font-body-1 bold-type nordic-blue-900-text">
          {{ 'paxDetails.fields.gender.tooltipHeading' | finLocalization }}
        </h2>
        <p class="pr-xxsmall-t nordic-blue-400-text regular-type">
          {{ 'paxDetails.fields.gender.tooltipContent' | finLocalization }}
        </p>
      </ng-template>
      <fcom-icon-button
        fcomPopover
        class="icon ms-xxsmall-l"
        [content]="contentTooltip"
        [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.HELP }"
        [theme]="IconButtonTheme.LIGHT_PRIMARY_NORDIC_BLUE"
        [size]="IconButtonSize.SMALL"
        [aria]="{ label: 'actions.info' | finLocalization }"
        [ariaCloseText]="'actions.close' | finLocalization"
      >
      </fcom-icon-button>
    </div>
    <div class="gender pr-xxsmall-b font-body-1 flex">
      <fcom-radio-item
        *ngFor="let options of GENDER_OPTIONS"
        class="mr-small-r"
        [controlName]="FIELDS.gender"
        [translationPrefix]="'paxDetails.fields.gender'"
        [parentForm]="passengerFormGroup"
        [radioKey]="options.value"
        [id]="'gender-' + options.value + '-' + groupIndex"
        [name]="'gender-' + groupIndex"
      >
      </fcom-radio-item>
    </div>
    <div
      *ngIf="passengerFormGroup.get(FIELDS.gender).invalid && passengerFormGroup.get(FIELDS.gender).touched"
      class="gender-error-label font-body-2"
      aria-live="polite"
    >
      <span *ngIf="passengerFormGroup.get(FIELDS.gender).errors?.required" class="error-900-text">
        {{ 'paxDetails.fields.' + FIELDS.gender + '.required' | finLocalization }}
      </span>
    </div>
  </div>

  <div class="flex name-wrapper">
    <fcom-text-input
      *ngFor="let inputName of [FIELDS.firstName, FIELDS.lastName]"
      class="input-name block ms-small-t flex-1 flex--basis0"
      [class.is-hidden]="hideDisabledFields && passengerFormGroup.get(inputName)?.disabled"
      [required]="true"
      [parentForm]="passengerFormGroup"
      [displayMargin]="false"
      [translationPrefix]="'paxDetails.fields.' + inputName"
      [controlName]="inputName"
      [id]="inputName + '-' + groupIndex"
      [placeHolder]="'paxDetails.fields.' + inputName + '.placeholder'"
      [displayMargin]="false"
      [attr.data-testid]="inputName + '-' + groupIndex"
      [tooltipHeading]="'paxDetails.fields.' + inputName + '.tooltipHeading' | finLocalization"
      [tooltipContent]="'paxDetails.fields.' + inputName + '.tooltipContent' | finLocalization"
    ></fcom-text-input>
  </div>
  <div *ngIf="enablePaxDetailsRearrangement$ | async" class="gender pr-xsmall-t">
    <fcom-radio-item-group
      [options]="GENDER_OPTIONS"
      [controlName]="FIELDS.gender"
      [name]="'gender-' + groupIndex"
      [translationPrefix]="'paxDetails.fields.gender'"
      [parentForm]="passengerFormGroup"
      [theme]="RadioButtonTheme.BOX"
      [helperText]="true"
      [isRequired]="true"
    >
    </fcom-radio-item-group>
  </div>
</div>

<ng-template #emailAndPhoneNumber>
  <div
    [attr.data-testid]="'copy-from-' + groupIndex"
    class="nordic-blue-25-bg ps-xsmall-y rounded-large"
    *ngIf="copyFromPassengers.length > 0"
  >
    <div class="ps-small">
      <p class="font-body-1 nordic-blue-400-text bold-type ps-medium-b">
        {{ 'paxDetails.copyFrom.title' | finLocalization }}
      </p>

      <fin-select-input
        [attr.data-testid]="'form-field-copyFromPassengers-' + groupIndex"
        [controlName]="'copyFromPassengers'"
        [translationPrefix]="'paxDetails.copyFrom'"
        [selectLabelTranslationKey]="'paxDetails.copyFrom.default'"
        [parentForm]="copyFromPassengerGroup"
        [required]="false"
        [options]="copyFromPassengers"
        [displayMargin]="false"
      >
      </fin-select-input>
    </div>
  </div>

  <fcom-text-input
    [attr.data-testid]="'pax-details-email-' + groupIndex"
    *ngIf="passengerFormGroup.get(FIELDS.type).value !== FinnairPassengerCode.INF"
    class="block ms-small-t flex-1"
    [parentForm]="passengerFormGroup"
    [displayMargin]="false"
    [translationPrefix]="'paxDetails.fields.email'"
    [controlName]="FIELDS.email"
    [id]="'email-' + groupIndex"
    [placeHolder]="'paxDetails.fields.email.placeholder'"
    [displayMargin]="false"
  ></fcom-text-input>

  <fin-form-array
    [attr.data-testid]="'pax-details-phones-' + groupIndex"
    *ngIf="
      passengerFormGroup.get(FIELDS.phones) && passengerFormGroup.get(FIELDS.type).value !== FinnairPassengerCode.INF
    "
    [parentForm]="passengerFormGroup"
    [controlName]="'phones'"
    [translationPrefix]="'paxDetails.fields.phones'"
    [displayLabels]="false"
    [minGroups]="1"
    [maxGroups]="3"
    [hideGroups]="hideGroups"
    [displayContainerStyles]="false"
    [compressedList]="true"
    [formGroupConfig]="phoneNumbersGroupConfig"
    [formGroupOptions]="phoneGroupOptions"
    [disabled]="passengerFormGroup.get(FIELDS.phones)?.disabled"
  >
    <ng-template let-otherPhone="parentFormGroup" let-index="index">
      <fin-form-group
        class="block field wd-phone ms-small-t"
        [group]="otherPhone"
        [translationPrefix]="'paxDetails.fields'"
        [attr.data-index]="index"
        [names]="[FIELDS.phonePrefix, FIELDS.phoneNumber]"
        [showOnlyGroupLevelErrors]="true"
      >
        <div class="phone-wrapper flex">
          <fin-select-input
            class="input-phone flex-1 flex--basis0"
            [controlName]="FIELDS.phonePrefix"
            [id]="'tel-country-code-' + groupIndex"
            [translationPrefix]="'paxDetails.fields.phonePrefix'"
            [parentForm]="otherPhone"
            [displayMargin]="false"
            [options]="phonePrefixes$ | async"
          >
          </fin-select-input>
          <fcom-text-input
            class="flex-1 flex--basis0"
            [translationPrefix]="'paxDetails.fields.phoneNumber'"
            [controlName]="FIELDS.phoneNumber"
            [id]="'tel-national-' + groupIndex"
            [parentForm]="otherPhone"
            [displayMargin]="false"
            [placeHolder]="'paxDetails.fields.phoneNumber.placeholder'"
          >
          </fcom-text-input>
        </div>
      </fin-form-group>
    </ng-template>
  </fin-form-array>

  <fin-form-group
    [attr.data-testid]="'pax-details-phone-' + groupIndex"
    *ngIf="
      passengerFormGroup.get(FIELDS.phone) && passengerFormGroup.get(FIELDS.type).value !== FinnairPassengerCode.INF
    "
    class="block field wd-phone"
    [group]="passengerFormGroup.get(FIELDS.phone)"
    [translationPrefix]="'paxDetails.fields'"
    [names]="[FIELDS.phonePrefix, FIELDS.phoneNumber]"
    [showOnlyGroupLevelErrors]="true"
  >
    <div class="phone-wrapper flex">
      <fin-select-input
        class="input-phone flex-1 flex--basis0 ms-small-t"
        [controlName]="FIELDS.phonePrefix"
        [id]="'tel-country-code-' + groupIndex"
        [translationPrefix]="'paxDetails.fields.phonePrefix'"
        [parentForm]="passengerFormGroup.get(FIELDS.phone)"
        [displayMargin]="false"
        [options]="phonePrefixes$ | async"
        [required]="isMainPassenger"
        [attr.aria-required]="isMainPassenger"
        [selectLabelTranslationKey]="'paxDetails.fields.phonePrefix.placeholder'"
      >
      </fin-select-input>

      <fcom-text-input
        class="flex-1 flex--basis0 ms-small-t"
        [controlName]="FIELDS.phoneNumber"
        [id]="'tel-national-' + groupIndex"
        [translationPrefix]="'paxDetails.fields.phoneNumber'"
        [parentForm]="passengerFormGroup.get(FIELDS.phone)"
        [displayMargin]="false"
        [required]="isMainPassenger"
        [placeHolder]="'paxDetails.fields.phoneNumber.placeholder'"
        (inputEvent)="removeLeadingZero($event)"
      >
      </fcom-text-input>
    </div>
    <cms-unstyled-fragment
      *ngIf="'fragments.optOutTelephoneSolicitation.url' | finLocalization"
      class="block font-body-1 mr-large-t"
      [contentUrl$]="'fragments.optOutTelephoneSolicitation.url' | finLocalization | finToObservable"
    >
    </cms-unstyled-fragment>
  </fin-form-group>

  <div
    [attr.data-testid]="'copy-to-all-passengers-' + groupIndex"
    class="nordic-blue-25-bg flex flex--left-center copy-details ms-xsmall-t ps-small rounded-large"
    *ngIf="showCopyDetailsButton"
  >
    <fcom-button
      (clickBtn)="copyDetailsClicked.emit()"
      [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
      [text]="'paxDetails.copyDetailsToAll.button' | finLocalization"
      [aria]="{
        disabled:
          passengerFormGroup.disabled || !passengerFormGroup.valid || !passengerFormGroup.get(FIELDS.email).value,
      }"
    ></fcom-button>
    <div class="font-body-1">{{ 'paxDetails.copyDetailsToAll.info' | finLocalization }}</div>
  </div>
</ng-template>

<fin-form-group
  *ngIf="passengerFormGroup.get(FIELDS.birthDate) !== null"
  class="block field wd-birthDay pr-xsmall-y"
  [class.is-hidden]="hideDisabledFields && passengerFormGroup.get(FIELDS.birthDate)?.disabled"
  [group]="passengerFormGroup.get(FIELDS.birthDate)"
  [translationPrefix]="'paxDetails.fields'"
  [groupTranslationPrefix]="'paxDetails.fields.birthDate'"
  [required]="true"
  [names]="[FIELDS.dateOfBirth, FIELDS.monthOfBirth, FIELDS.yearOfBirth]"
  [showOnlyGroupLevelErrors]="true"
>
  <div class="title font-body-1 nordic-blue-900">
    {{ 'paxDetails.dateOfBirthLabel' | finLocalization }}
  </div>
  <div class="birthday-wrapper flex">
    <fin-select-input
      class="bday-day flex-1 flex--basis0 ms-small-t"
      data-field="bday-day"
      [controlName]="FIELDS.dateOfBirth"
      [id]="'bday-day-' + groupIndex"
      [translationPrefix]="'paxDetails.fields.dateOfBirth'"
      [parentForm]="passengerFormGroup.get(FIELDS.birthDate)"
      [displayMargin]="false"
      [options]="dayOptions$ | async"
    >
    </fin-select-input>

    <fin-select-input
      class="bday-month flex-1 flex--basis0 ms-small-t"
      data-field="bday-month"
      [controlName]="FIELDS.monthOfBirth"
      [id]="'bday-month-' + groupIndex"
      [translationPrefix]="'paxDetails.fields.monthOfBirth'"
      [parentForm]="passengerFormGroup.get(FIELDS.birthDate)"
      [displayMargin]="false"
      [options]="monthOptions"
    >
    </fin-select-input>

    <fin-select-input
      class="flex-1 flex--basis0 ms-small-t"
      data-field="bday-year"
      [controlName]="FIELDS.yearOfBirth"
      [id]="'bday-year-' + groupIndex"
      [translationPrefix]="'paxDetails.fields.yearOfBirth'"
      [parentForm]="passengerFormGroup.get(FIELDS.birthDate)"
      [displayMargin]="false"
      [options]="yearOptions"
    >
    </fin-select-input>
  </div>
</fin-form-group>

<ng-container *ngIf="!isMainPassenger && passengerFormGroup.get(FIELDS.type).value !== FinnairPassengerCode.INF">
  <fcom-button
    *ngIf="!showContactDetails"
    [attr.data-testid]="'contact-details-button-' + groupIndex"
    class="ps-small-t"
    [theme]="ButtonTheme.LIGHT_TERTIARY_GHOST"
    [text]="'paxDetails.addContactDetails.title' | finLocalization"
    [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.PLUS, position: IconPosition.LEFT }"
    [type]="ButtonType.BUTTON"
    (clickBtn)="showContactDetailsFields()"
    [attr.aria-label]="'paxDetails.addContactDetails.description' | finLocalization"
  ></fcom-button>
</ng-container>

<ng-container *ngIf="showContactDetails || isMainPassenger">
  <ng-container *ngTemplateOutlet="emailAndPhoneNumber"></ng-container>
</ng-container>

<ng-container *ngIf="!allowMultipleFFCards; else multipleCards">
  <ng-container *ngIf="shouldShowFFCardSection || shouldShowJoinFinnairPlusSection">
    <div
      class="title font-body-1 bold-type nordic-blue-900 pr-xsmall-t"
      [attr.data-testid]="'frequent-flyer-section-' + groupIndex"
    >
      {{ 'paxDetails.frequentFlyerSection.title' | finLocalization }}
    </div>

    <fin-form-group
      [attr.data-testid]="'add-frequent-flyer-details-' + groupIndex"
      *ngIf="shouldShowFFCardSection && passengerFormGroup.get(FIELDS.frequentFlyerCard) !== null"
      class="block frequent-flyer field pr-xsmall-y"
      [group]="passengerFormGroup.get(FIELDS.frequentFlyerCard)"
      [translationPrefix]="'paxDetails.fields'"
      [groupTranslationPrefix]="'paxDetails.fields.cardNumber'"
      [required]="false"
      [names]="[FIELDS.companyCode, FIELDS.cardNumber]"
      [showOnlyGroupLevelErrors]="true"
    >
      <fcom-checkbox
        class="ms-large-b"
        [theme]="CheckBoxTheme.ROUNDED"
        [name]="'frequent-flyer-checkbox'"
        [disabled]="passengerFormGroup.get(FIELDS.frequentFlyerCard)?.disabled"
        [checked]="frequentFlyerIsOpen$ | async"
        (change)="toggleFrequentFlyerSection($event)"
      >
        <div class="flex flex--column">
          <span class="flex flex--column font-body-1 medium-type privatedata" data-hj-suppress>
            {{ 'paxDetails.fields.frequentFlyerCard.title' | finLocalization }}
          </span>
          <span
            class="font-body-2 light-type grey-600-text privatedata"
            *ngIf="(frequentFlyerIsOpen$ | async) === false"
            data-hj-suppress
          >
            {{ 'paxDetails.fields.frequentFlyerCard.label' | finLocalization }}
          </span>
        </div>
      </fcom-checkbox>
      <div class="frequent-flyer-wrapper flex" *ngIf="(frequentFlyerIsOpen$ | async) === true">
        <fin-select-input
          class="frequent-flyer-airline flex-1 flex--basis0 ms-small-t"
          [translationPrefix]="'paxDetails.fields.companyCode'"
          [controlName]="FIELDS.companyCode"
          [id]="'frequent-flyer-airline-' + groupIndex"
          [parentForm]="passengerFormGroup.get(FIELDS.frequentFlyerCard)"
          [displayMargin]="false"
          [options]="frequentFlyerAirlines$ | async"
          [selectLabelTranslationKey]="'paxDetails.fields.companyCode.placeholder'"
        >
        </fin-select-input>
        <fcom-text-input
          class="flex-1 flex--basis0 ms-small-t"
          [translationPrefix]="'paxDetails.fields.cardNumber'"
          [controlName]="FIELDS.cardNumber"
          [id]="'frequent-flyer-number-' + groupIndex"
          [parentForm]="passengerFormGroup.get(FIELDS.frequentFlyerCard)"
          [displayMargin]="false"
          [finElemTracker]="'frequent-flyer-number'"
          [finElemType]="ElementTypes.INPUT"
          [finElemContext]="trackingContext + '_pax-details'"
          [placeHolder]="'paxDetails.fields.cardNumber.placeholder'"
        >
        </fcom-text-input>
      </div>
    </fin-form-group>
    <ng-container
      *ngIf="isMainPassenger && (profileType$ | async) === undefined && shouldShowJoinFinnairPlusSection"
      [formGroup]="passengerFormGroup"
    >
      <fcom-checkbox
        [attr.data-testid]="'join-finnair-plus-' + groupIndex"
        class="ms-large-y"
        [theme]="CheckBoxTheme.ROUNDED"
        [name]="FIELDS.joinFinnairPlus"
        [formControlName]="FIELDS.joinFinnairPlus"
        [finElemTracker]="'join-finnair-plus'"
        [finElemContext]="'booking-flow_pax-details'"
        [finElemType]="C.ElementTypes.CHECKBOX"
        (change)="toggleJoinFinnairPlus()"
      >
        <div class="flex flex--column">
          <span class="font-body-1 medium-type privatedata" data-hj-suppress>
            {{ 'paxDetails.fields.joinFinnairPlus.title' | finLocalization }}
          </span>
          <span class="font-body-2 light-type grey-600-text privatedata" data-hj-suppress>
            {{ 'paxDetails.fields.joinFinnairPlus.label' | finLocalization }}
          </span>
        </div>
      </fcom-checkbox>
    </ng-container>
  </ng-container>
</ng-container>

<div
  *ngIf="(enablePaxDetailsRearrangement$ | async) === true"
  class="mandatory font-body-1 dark-grey ms-xsmall-t flex flex--end"
>
  {{ 'paxDetails.mandatory' | finLocalization }}
</div>

<ng-template #multipleCards>
  <h3 class="font-body-1 nordic-blue-900-text mr-small-t">
    {{ 'paxDetails.frequentFlyerDetails' | finLocalization }}
  </h3>
  <p class="mr-xsmall-t mr-xsmall-b">{{ 'paxDetails.frequentFlyerHelp' | finLocalization }}</p>
  <fin-form-array
    [parentForm]="passengerFormGroup"
    [controlName]="'frequentFlyerCards'"
    [translationPrefix]="'paxDetails.fields.frequentFlyerCards'"
    [displayLabels]="false"
    [minGroups]="1"
    [maxGroups]="3"
    [hideGroups]="hideGroups"
    [displayContainerStyles]="false"
    [compressedList]="true"
    [disabled]="passengerFormGroup.get(FIELDS.frequentFlyerCards)?.disabled"
    [formGroupConfig]="frequentFlyerGroupConfig"
    [formGroupOptions]="frequentFlyerGroupOptions"
  >
    <ng-template let-otherCard="parentFormGroup" let-index="index">
      <fin-form-group
        class="block frequent-flyer-multiple field pr-xsmall-b"
        [attr.data-index]="index"
        [group]="otherCard"
        [translationPrefix]="'paxDetails.fields'"
        [groupTranslationPrefix]="'paxDetails.fields.cardNumber'"
        [required]="false"
        [names]="[FIELDS.companyCode, FIELDS.cardNumber]"
        [showOnlyGroupLevelErrors]="true"
      >
        <div class="frequent-flyer-wrapper flex">
          <fin-select-input
            class="frequent-flyer-airline flex-1 flex--basis0 ms-small-t"
            [translationPrefix]="'paxDetails.fields.companyCode'"
            [controlName]="FIELDS.companyCode"
            [id]="'frequent-flyer-airline-' + groupIndex"
            [parentForm]="otherCard"
            [displayMargin]="false"
            [options]="frequentFlyerCardOptions$ | finMap: '' + index | async"
          >
          </fin-select-input>
          <fcom-text-input
            class="flex-1 flex--basis0 ms-small-t"
            [translationPrefix]="'paxDetails.fields.cardNumber'"
            [controlName]="FIELDS.cardNumber"
            [id]="'frequent-flyer-number-' + groupIndex"
            [parentForm]="otherCard"
            [displayMargin]="false"
            [finElemTracker]="'frequent-flyer-number'"
            [finElemType]="ElementTypes.INPUT"
            [finElemContext]="trackingContext + '_pax-details'"
          >
          </fcom-text-input>
        </div>
      </fin-form-group>
    </ng-template>
  </fin-form-array>
</ng-template>
