import { textMatch } from '@fcom/core/utils';

import {
  DestinationSearchItem,
  DestinationSearchItemType,
  DestinationSearchQueryParamNames,
} from '../interfaces/destination-search.interface';

export const flattenDestinations = (destinations: DestinationSearchItem[]): DestinationSearchItem[] => {
  return destinations?.reduce((flattened: DestinationSearchItem[], destination: DestinationSearchItem) => {
    if (destination?.items) {
      const flattenedChildren = flattenDestinations(destination.items);
      const { items: _, ...destWithoutChildren } = destination;
      return [...flattened, destWithoutChildren, ...flattenedChildren];
    } else {
      return [...flattened, destination];
    }
  }, []);
};

export const findDestinationsForQueryParams = (
  destinations: DestinationSearchItem[],
  preSelectedDestinations: DestinationSearchQueryParamNames
): DestinationSearchItem[] => {
  return flattenDestinations(destinations)?.filter((d: DestinationSearchItem) => {
    switch (d.type) {
      case DestinationSearchItemType.DESTINATION:
        return preSelectedDestinations?.destination?.some((locationCode) => textMatch(d.locationCode, locationCode));
      case DestinationSearchItemType.COUNTRY:
        return preSelectedDestinations?.country?.some(
          (countryCodeOrName) =>
            textMatch(d.countryCode, countryCodeOrName) || textMatch(d.titleMaster, countryCodeOrName)
        );
      case DestinationSearchItemType.CONTINENT:
        return preSelectedDestinations?.continent?.some((continentName) => textMatch(d.titleMaster, continentName));
      default:
        return false;
    }
  });
};
