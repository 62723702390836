import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ModalModule } from '../../components/modal';
import { NotificationsModule } from '../../components/notifications';
import { FiltersComponent } from './filters.component';
import { ButtonModule } from '../buttons/button.module';
import { IconsModule } from '../icons/icons.module';
import { ClickOutsideDirectiveModule } from '../../directives/click-outside';
import { PopoverModule } from '../popover';

@NgModule({
  declarations: [FiltersComponent],
  imports: [
    CommonModule,
    IconsModule,
    ButtonModule,
    ClickOutsideDirectiveModule,
    PopoverModule,
    ModalModule,
    NotificationsModule,
  ],
  exports: [FiltersComponent],
  providers: [],
})
export class FiltersModule {}
