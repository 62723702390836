<ng-container *ngIf="breadcrumbItems$ | async as items">
  <nav
    *ngIf="items.length > 0"
    [attr.aria-label]="'navigation.breadcrumb' | finLocalization"
    class="breadcrumbs-container nordic-blue-900-text grid-max-width padding-left-gutter padding-right-gutter ps-small-t ps-xsmall-b center light-type"
  >
    <ul class="no-list-style">
      <li class="font-body-2">
        <a [finLink]="'/' + (currentLanguage$ | async)" class="nordic-blue-900-text no-decoration breadcrumb-item">{{
          'actions.home' | finLocalization
        }}</a>
      </li>
      <ng-container *ngFor="let item of items">
        <li *ngIf="item.url && item.url !== '/' + (currentLanguage$ | async)" class="font-body-2">
          <span aria-hidden="true" class="ps-xxsmall-x">/</span>
          <span>
            <a
              *ngIf="item.url !== (currentPath$ | async)"
              [finLink]="item.url"
              class="nordic-blue-900-text no-decoration breadcrumb-item"
              >{{ item.title }}</a
            >
            <span
              *ngIf="item.url === (currentPath$ | async)"
              class="nordic-blue-900-text no-decoration medium-type breadcrumb-item"
              [attr.aria-current]="'page'"
              >{{ item.title }}
            </span>
          </span>
        </li>
      </ng-container>
    </ul>
  </nav>
</ng-container>
