import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'cms-heading-tag',
  templateUrl: './cms-heading-tag.component.html',
  styleUrls: ['./cms-heading-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsHeadingTagComponent {
  @Input()
  level = 3;

  @Input()
  extraClasses: string;
}
