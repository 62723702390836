import { stringHashCode } from '@fcom/core/utils';

import { StorageKey } from '../utils';
import { jsConfig } from '../services/config/jsConfig';

export function sessionStorageVersionFactory() {
  return jsConfig.versionInfo.releaseGitCommitHash;
}

export type LocalStorageSchema = {};

export const storedData: LocalStorageSchema = {};

export const KEYS_TO_LOCAL_STORAGE: StorageKey[] = Object.keys(storedData).map((name) => ({ name }));

/**
 * This is used for storing the hashes of the different local storage schema versions.
 * If you need to migrate data from a previous version, you can check this dictionary
 * for the correct migration script.
 * @type {{0: string}}
 */
export const localStorageVersionHashes = {
  0: '3938',
};

export function generateLocalStorageHash(): string {
  return String(stringHashCode(JSON.stringify(storedData)));
}
