<div
  class="wrap"
  [class.pr-large-t]="enableTopPadding"
  [class.pr-large-b]="enableBottomPadding"
  data-testid="booking-plain-header"
>
  <h1 class="font-heading-3 ms-clear bold-type {{ headingClass }}">{{ title }}</h1>
  <p class="font-body-1 nordic-blue-900-text" *ngIf="subtitle">{{ subtitle }}</p>
  <p class="font-body-1 mr-small-t nordic-blue-900-text" *ngIf="extraText">{{ extraText }}</p>
</div>
