import { type AbstractControl, type ValidationErrors, type ValidatorFn } from '@angular/forms';

import { AttachmentError, AttachmentStatus, type Attachment } from '../../interfaces';

/**
 * Validates attachment statuses based on files used with the `fcom-file-uploader` component.
 * @example Validation error:
 * ```ts
 * {
 *   [AttachmentError.UPLOADING]: {
 *     value: [{ error: AttachmentError.UPLOADING, file: 'file.pdf', status: AttachmentStatus.UPLOADING }]
 *   }
 * }
 * ```
 * @returns `null` or :
 * - `uploading` validation error with an array of files not matching the extensions given.
 */
export const attachmentStatusValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    const attachmentsStillUploading: Attachment[] = control.value
      .filter(Boolean)
      .filter((attachment: Attachment): boolean => attachment.status === AttachmentStatus.UPLOADING);
    const errors: ValidationErrors = {};
    if (attachmentsStillUploading.length) {
      errors[AttachmentError.UPLOADING] = { value: attachmentsStillUploading };
    }
    return Object.keys(errors).length ? errors : null;
  };
};
