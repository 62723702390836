/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

/**
 * Base class for services
 */
@Injectable()
export class DapiBaseService {
  constructor(
    protected http: HttpClient
  ) {
  }
}
