import { Component, Input, OnChanges, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

import { CmsImageData, StaticPicture } from '@fcom/core-api';

import { AspectRatioString } from '../../interfaces';
import {
  isCmsImageData,
  isStaticPicture,
  mapToResponsiveImage,
  toCmsAspectRatio,
  toSrcSetString,
} from './images.utils';

@Component({
  selector: 'fin-block-image',
  styleUrls: ['./block-image.component.scss'],
  templateUrl: './block-image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockImageComponent implements OnChanges {
  /**
   * The preferred aspect ratio of the image. Only relevant when using `CmsImageData` as input.
   */
  @Input() aspectRatio: AspectRatioString = '4x3';

  /**
   * Urls to use for the image
   */
  @Input() imageData: CmsImageData | StaticPicture;

  /**
   * Whether to show the image with a gray overlay or not.
   * Defaults to false
   */
  @Input()
  overlay = false;

  /**
   * When the overlay is active, should the image be blurred also
   * Defaults to false
   */
  @Input()
  overlayBlur = false;

  /**
   * Fixes the height of the block image
   * Defaults to false
   */
  @Input()
  fixed = false;

  srcSet: string;
  fallbackSrc: string;
  thumbnailSrc: string;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnChanges(): void {
    this.mapImageToResponsiveImageData();
    this.cd.markForCheck();
  }

  private mapImageToResponsiveImageData(): void {
    const cmsAspectRatio = toCmsAspectRatio(this.aspectRatio) || toCmsAspectRatio('4x3');

    if (isStaticPicture(this.imageData) || (isCmsImageData(this.imageData) && cmsAspectRatio)) {
      const responsiveImageData = mapToResponsiveImage(this.imageData, cmsAspectRatio);
      this.srcSet = responsiveImageData.srcSet.length > 0 ? toSrcSetString(responsiveImageData.srcSet) : '';
      this.fallbackSrc = responsiveImageData.fallbackSrc;
      this.thumbnailSrc = responsiveImageData.thumbnailSrc;
    } else {
      this.srcSet = '';
      this.fallbackSrc = '';
      this.thumbnailSrc = '';
    }
  }
}
