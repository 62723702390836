import { Component, Input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

export enum InfoBoxSize {
  DEFAULT = 'Default',
}

export enum InfoBoxTheme {
  DEFAULT = 'Default',
  ALERT = 'Alert',
}

/**
 * @Deprecated
 * This component has been deprecated and you should move to fcom-notification. It will be removed
 * in an upcoming major release.
 *
 * Shows a info box banner. Example usage:
 *
 * @example
 * <fcom-info-box
 *   [theme]="InfoBoxTheme.DEFAULT"
 *   [size]="InfoBoxSize.DEFAULT"
 *   icon="info">
 * {{ 'TRANSLATION.KEY' | finLocalization }}
 * </fcom-info-box>
 */
@Component({
  selector: 'fcom-info-box',
  templateUrl: './info-box.component.html',
})
export class InfoBoxComponent {
  public readonly InfoBoxSize = InfoBoxSize;
  public readonly InfoBoxTheme = InfoBoxTheme;
  public readonly SvgLibraryIcon = SvgLibraryIcon;
  /**
   * @deprecated will be removed in v10 release. superseded by ng-content
   * Text content of the info-box-component
   * If not provided ng-content will be rendered instead
   */
  @Input()
  text = '';

  /**
   * @deprecated will be removed in v10 release. superseded by theme
   * Boolean indicating if alert styling should be used
   */
  @Input()
  set isAlert(isAlert) {
    this.theme = isAlert ? InfoBoxTheme.ALERT : InfoBoxTheme.DEFAULT;
  }

  /**
   * The paddings, text sizes and icon sizes to use for the component.
   *
   * Note. This only has 1 option right now and is not hooked up to the template.
   */
  @Input()
  size: InfoBoxSize = InfoBoxSize.DEFAULT;

  /**
   * The theme to use for the component, this styles the component as an alert or notification.
   */
  @Input()
  theme: InfoBoxTheme = InfoBoxTheme.DEFAULT;

  /**
   * Name of the icon to be displayed
   *
   * @example SvgLibraryIcon.INFO
   */
  @Input()
  icon: SvgLibraryIcon;
}
