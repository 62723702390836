<fcom-modal
  [buttons]="ModalButtons.CONTINUE_CANCEL"
  [fitToContent]="true"
  [(open)]="open"
  [title]="'aurinkomatkat.continueModal.title' | finLocalization: { holidayType: 'Kaupunkiloma' }"
  (close)="closeModal.emit()"
  (confirm)="navigateToAmBooking()"
>
  <div class="mr-large-x mr-small-y">
    <div class="nordic-blue-900-text mr-small-b font-heading-3 flex flex--middle">
      <fcom-icon class="icon-large mr-xsmall-r" [name]="SvgLibraryIcon.AM_LOGO"></fcom-icon>
      <div>
        {{ 'aurinkomatkat.continueModal.contentTitle' | finLocalization }}
      </div>
    </div>
    <div class="font-body-1 black-text regular-type">{{ 'aurinkomatkat.continueModal.content' | finLocalization }}</div>
  </div>
</fcom-modal>
