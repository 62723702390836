import { FinnairLocation } from '../api/models';

export interface Quotas {
  [fareFamilyCode: string]: number | null;
}

export interface LocationInfoMap {
  [id: string]: FinnairLocation;
}

export enum BoundType {
  outbound = 'outbound',
  inbound = 'inbound',
  multi = 'multi',
}

export interface CheapestBoundPrice {
  boundId: string;
  cheapestPrice: string;
}
