import { createActionGroup, emptyProps, props, union } from '@ngrx/store';

import { PassengerNameRecord } from '../store.interface';

export const RedirectActions = createActionGroup({
  source: 'RedirectActions',
  events: {
    'Set PNR': props<{ pnr: PassengerNameRecord }>(),
    'Clear PNR': emptyProps(),
  },
});

const redirectUnion = union(
  Object.keys(RedirectActions).reduce((actions, key) => (actions[key] = RedirectActions[key]), {})
);

export type RedirectActionsUnion = typeof redirectUnion;
