<ng-template #pricePromo let-price="price" let-type="type" let-isInCampaign="campaign">
  <div class="price-promo-text ps-xxsmall-t" *ngIf="price && type">
    <div class="promo__price-from light-type font-body-2 nordic-blue-900-text" [class.align-right]="!isInCampaign">
      <div>{{ 'travelType.' + type | finLocalization }}</div>
    </div>
  </div>
  <div
    class="price-promo-value heather-700-text font-heading-3 flex flex--self-center"
    *ngIf="price && type"
    [attr.data-testid]="'hero-price-' + type"
  >
    {{ price | finPrice: { roundPrice: true } }}
  </div>
</ng-template>

<section class="hero pr-small-t">
  <div class="hero__top grid-col-12 grid-max-width no-row-gap">
    <div class="hero__top-left mr-medium-b col-1-12 sm-col-1-6 flex--self-start">
      <!--
        TODO: Phase 2, destination main page
        <div>Button to go back</div>
      -->
      <h1 class="font-heading-3">
        <div class="hero__top-lead font-body-1 nordic-blue-900-text light-type">
          {{ 'flightsTo' | finLocalization }}
        </div>
        <div class="hero__top-destination font-heading-1 nordic-blue-900-text">
          {{ title }}
        </div>
      </h1>
    </div>
    <div
      class="hero__top-right promo mr-medium-b col-1-12 sm-col-7-12"
      *ngIf="destinationCode | finCheapestPricePipe: originLocationCode$ | async as prices"
    >
      <ng-container *ngIf="selectedOriginLocationName$ | async as selectedOriginLocationName">
        <div class="flex bold-type">
          <div [class.promo__prices-wrapper]="!isInCampaign">
            <div *ngIf="!isInCampaign" class="font-body-1 nordic-blue-900-text">
              {{ 'economyClass' | finLocalization }}
            </div>
            <div
              *ngIf="isInCampaign"
              class="font-body-1 white-text"
              [class.black-bg]="enableBlackTeaser"
              [class.heather-700-bg]="!enableBlackTeaser"
              data-testid="hero-campaign-badge"
            >
              <p class="ps-xsmall">
                {{ 'destinationSearch.' + campaignLabel | finLocalization }}
                {{ campaignEndText }}
              </p>
            </div>
            <div class="promo__prices grid-col-12 ps-xxsmall-t ps-clear-x no-row-gap" [class.campaign]="isInCampaign">
              <ng-container *ngIf="showOneWayPrice$ | async">
                <ng-container
                  *ngTemplateOutlet="
                    pricePromo;
                    context: {
                      price: prices[TripType.ONEWAY],
                      type: CmsTripTypeMap[TripType.ONEWAY],
                      isInCampaign: isInCampaign,
                    }
                  "
                ></ng-container>
              </ng-container>
              <ng-container
                *ngTemplateOutlet="
                  pricePromo;
                  context: {
                    price: prices[TripType.RETURN],
                    type: CmsTripTypeMap[TripType.RETURN],
                    isInCampaign: isInCampaign,
                  }
                "
              ></ng-container>
            </div>
          </div>
        </div>
        <div class="light-type font-body-2 ms-xxsmall-t nordic-blue-900-text">
          {{ 'destinationSearch.bestFareForThisDestination' | finLocalization: { origin: selectedOriginLocationName } }}
        </div>
      </ng-container>
    </div>
  </div>
  <cms-full-width-booking-widget
    [backgroundImage]="backgroundImage"
    [locations]="[
      { originLocationCode: originLocationCodeFromStore$ | async, destinationLocationCode: destinationCode },
    ]"
    [enableAward]="enableAward"
    [enableAurinkomatkat]="enableAurinkomatkat"
    [enablePromoCode]="enablePromoCode"
    [contentType]="contentType"
  ></cms-full-width-booking-widget>
</section>
