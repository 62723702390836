import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import {
  CmsContentType,
  CmsQueryList,
  CmsQueryListItem,
  CmsTaxonomy,
  CmsTaxonomyGeneral,
  CmsViewType,
} from '@fcom/core-api';
import { sanitizeWhiteListedPath, stopPropagation, unsubscribe } from '@fcom/core/utils';
import { ConfigService, SentryLogger } from '@fcom/core';
import { finShare } from '@fcom/rx';
import { ButtonTheme, IconPosition, LoaderColor, LoaderTheme } from '@fcom/ui-components';

import { CmsTemplateService } from '../../../services';
import { getFontLevel, getHeaderLevel } from '../../../services/cms-utils/cms-utils';

@Component({
  selector: 'fin-teaser-list',
  templateUrl: './teaser-list.component.html',
  styleUrls: ['./teaser-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeaserListComponent implements OnInit, OnDestroy {
  readonly DEFAULT_HEADER_LEVEL = 3;
  readonly TAXONOMY: typeof CmsTaxonomyGeneral = CmsTaxonomyGeneral;
  protected readonly LoaderTheme = LoaderTheme;
  protected readonly LoaderColor = LoaderColor;
  protected readonly ButtonTheme = ButtonTheme;
  readonly IconLibrary = IconLibrary;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly IconPosition = IconPosition;
  readonly CmsContentType = CmsContentType;

  path: string;
  loading: boolean;

  numberOfPages$: Observable<number>;
  showLoadMore$: Observable<boolean>;
  currentPage$ = new BehaviorSubject(0);
  nextPageLink$: Observable<string>;
  items$: BehaviorSubject<CmsQueryListItem[]> = new BehaviorSubject<CmsQueryListItem[]>([]);

  subscription = new Subscription();

  headerLevel(tags: CmsTaxonomy[]): number {
    return getHeaderLevel(tags);
  }

  headerFontLevel(tags: CmsTaxonomy[]): string {
    return getFontLevel(tags);
  }

  renderHeading(tags: CmsTaxonomy[]): boolean {
    if (
      tags?.includes(this.TAXONOMY.TEXT_LINK_LEFT_LIST) ||
      tags?.includes(this.TAXONOMY.QUERY_TEASER_LIST_THIRD) ||
      tags?.includes(this.TAXONOMY.QUERY_LIST_NEWS_COLLECTION)
    ) {
      return true;
    }
    return false;
  }

  constructor(
    private router: Router,
    private configService: ConfigService,
    private cmsTemplateService: CmsTemplateService,
    private sentryLogger: SentryLogger
  ) {}

  @Input()
  content$: Observable<CmsQueryList>;

  @ViewChildren('listItem', { read: ElementRef }) listItems: QueryList<ElementRef>;

  ngOnInit(): void {
    this.path = sanitizeWhiteListedPath(this.router.url, this.configService.cfg);
    const parsedPageNum = this.router.parseUrl(this.path).queryParams.pageNum;
    if (parsedPageNum) {
      this.currentPage$.next(+parsedPageNum);
    }
    this.numberOfPages$ = this.content$.pipe(
      map((content) => content.numberOfPages || 1),
      finShare()
    );
    this.showLoadMore$ = combineLatest([this.numberOfPages$, this.currentPage$]).pipe(
      map(([numberOfPages, currentPage]) => currentPage < numberOfPages - 1)
    );
    this.subscription.add(
      this.content$
        .pipe(
          map((content) => content.items),
          take(1)
        )
        .subscribe((items) => this.items$.next(items))
    );
    this.nextPageLink$ = this.currentPage$.pipe(
      map((page) => {
        const urlWithoutPageNum = this.path.replace(/[?&]pageNum=\d/, '');
        const delimiter = urlWithoutPageNum.includes('?') ? '&' : '?';
        return `${urlWithoutPageNum}${delimiter}pageNum=${page + 1}`;
      })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  loadNextItems(event: Event): boolean {
    if (this.loading) {
      return false;
    }
    stopPropagation(event);
    this.loading = true;
    const nextPage = this.currentPage$.value + 1;
    this.currentPage$.next(nextPage);
    const delimiter = this.path.includes('?') ? '&' : '?';
    const templatePath = `${this.path}${delimiter}pageNum=${nextPage}`;
    this.subscription.add(
      this.cmsTemplateService
        .load(templatePath)
        .pipe(
          take(1),
          catchError((error: unknown) => {
            this.sentryLogger.error('Error loading additional query list items', { error, path: templatePath });
            this.loading = false;
            throw error;
          })
        )
        .subscribe((template) => {
          const currentItems = this.items$.value;
          const firstNewItemIndex = currentItems.length;
          const newItems: CmsQueryListItem[] = template.main
            .filter((content) => content.viewType === CmsViewType.TEASER_LIST)
            ?.flatMap((content: CmsQueryList) => content.items);
          this.items$.next(currentItems.concat(newItems));
          this.loading = false;
          // Set focus to first new element to improve a11y
          this.setFocus(firstNewItemIndex);
        })
    );
    return false;
  }

  trackByUrl(_index: number, item: CmsQueryListItem): string {
    return item.url;
  }

  private setFocus(index: number): void {
    setTimeout(() => {
      this.listItems.get(index)?.nativeElement?.querySelector('a')?.focus();
    });
  }
}
