import {
  AfterViewInit,
  Component,
  Input,
  Renderer2,
  HostBinding,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { PageMetaService, NavigationMenuService } from '@fcom/common/services';
import { CmsPage } from '@fcom/core-api';

@Component({
  selector: 'cms-page',
  template: '<ng-content></ng-content>',
})
export class CmsPageComponent implements AfterViewInit, OnInit, OnChanges {
  @Input()
  content: CmsPage;

  @HostBinding('class.is-hidden') defaultClass = true;

  constructor(
    private pageMetaService: PageMetaService,
    private renderer: Renderer2,
    private navigationMenuService: NavigationMenuService
  ) {}

  ngOnInit(): void {
    if (typeof this.content.isHeaderVisible !== 'undefined') {
      this.navigationMenuService.setHeaderVisibility(this.content.isHeaderVisible);
    }
    if (typeof this.content.isFooterVisible !== 'undefined') {
      this.navigationMenuService.setFooterVisibility(this.content.isFooterVisible);
    }
    this.pageMetaService.setDefaultOriginLocationCode(this.content.defaultOriginLocationCode ?? 'HEL');
  }

  ngAfterViewInit(): void {
    this.pageMetaService.setTitle(this.content.seoTitle || this.content.title);
    this.pageMetaService.moveMetaAndLinksToHead(this.content.metaData, this.renderer);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['content']) {
      this.pageMetaService.setTitle(this.content.seoTitle || this.content.title);
      // Make the pageMetaService to sync the latest metaData
      this.pageMetaService.moveMetaAndLinksToHead(this.content.metaData, this.renderer);
    }
  }
}
