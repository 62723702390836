<div class="timeline__cell" *ngFor="let stop of leg.technicalStops; let lastStop = last">
  <div class="timeline__left" role="presentation">
    <div *ngIf="leg.aircraft?.code !== BUS_AIRCRAFT_CODE" class="flex--nogrow bubble bubble-heather"></div>
    <div *ngIf="leg.aircraft?.code === BUS_AIRCRAFT_CODE" class="flex--nogrow bubble bubble-plane-end">
      <fcom-icon class="icon-medium nordic-blue-900-text" [name]="SvgLibraryIcon.BUS"></fcom-icon>
    </div>
    <div class="flex-1 line line-solid"></div>
    <!-- For last stop, we show the endpoint bubble, which should be white if the leg is also the last -->
    <div class="flex--nogrow bubble bubble-plane-end" *ngIf="lastStop">
      <fcom-icon
        class="icon-medium nordic-blue-900-text"
        [name]="leg.aircraft?.code === BUS_AIRCRAFT_CODE ? SvgLibraryIcon.BUS : SvgLibraryIcon.ARRIVAL"
      ></fcom-icon>
    </div>
  </div>

  <div class="timeline__right flex flex--column pr-xsmall-l">
    <p>
      <fin-itinerary-timeline-leg-endpoint
        [endpoint]="stop.arrival"
        [locations]="locations"
      ></fin-itinerary-timeline-leg-endpoint>
    </p>
    <p
      *ngIf="leg.aircraft?.code !== BUS_AIRCRAFT_CODE"
      class="medium-type heather-700-text"
      data-testid="technical-stop"
    >
      {{ 'itinerary.technicalStop' | finLocalization }}<br />
      {{ stop.duration | finDuration | async }}
    </p>

    <ng-container *ngIf="leg.aircraft?.code === BUS_AIRCRAFT_CODE && lastStop">
      <p class="grey-80-text mr-xsmall-y">
        {{ leg.flightNumber }}<br />
        {{ 'operatedBy' | finLocalization: [leg && leg.operatingAirline && leg.operatingAirline.name] }}<br />
        {{ leg?.aircraft?.name }}<br />
        {{ 'bookingSearch.duration' | finLocalization: (leg.duration | finTemporaryDurationPipe) }}
      </p>
    </ng-container>

    <ng-container *ngIf="lastStop">
      <!-- Display the whole leg's endpoint if the stop was last in the leg -->
      <p
        [ngClass]="{
          'mr-xxlarge-t': leg.aircraft?.code === BUS_AIRCRAFT_CODE && lastLeg,
          'mr-xsmall-t': leg.aircraft?.code !== BUS_AIRCRAFT_CODE,
        }"
      >
        <span class="is-vishidden">{{ 'arrival' | finLocalization }}</span>
        <fin-itinerary-timeline-leg-endpoint
          [endpoint]="leg.arrival"
          [oldEndpointDateTime]="oldArrivalDateTime"
          [oldEndpointLocationCode]="oldArrivalLocationCode"
          [locations]="locations"
        ></fin-itinerary-timeline-leg-endpoint>
      </p>
      <p class="light-type" *ngIf="leg.arrival.terminal && leg.aircraft?.code !== BUS_AIRCRAFT_CODE">
        {{ 'bookingSearch.flight.terminal' | finLocalization }}
        {{ leg.arrival.terminal }}
      </p>
    </ng-container>
  </div>
</div>
