import { isPresent } from './utils';
import { pad } from './format';

export class PreciseNumber {
  static ZERO: PreciseNumber = PreciseNumber.from(0, 0);

  static fromString(s: string): PreciseNumber {
    const [before, after]: string[] = s.split('.');
    return PreciseNumber.from(parseInt(before + after, 10), isPresent(after) ? after.length : 0);
  }

  static from(n: number, decimals: number): PreciseNumber {
    return new PreciseNumber(n, decimals);
  }

  constructor(
    public readonly n: number,
    public readonly numDecimals: number
  ) {}

  withPrecision(precision: number): PreciseNumber {
    if (precision === this.numDecimals) {
      return this;
    }

    if (precision > this.numDecimals) {
      const newN = Math.pow(10, precision - this.numDecimals) * this.n;
      return PreciseNumber.from(newN, precision);
    }
    throw new Error('Would loose precision');
  }

  round(precision: number): PreciseNumber {
    if (precision === this.numDecimals) {
      return this;
    }
    const newN = Math.round(Math.pow(10, precision - this.numDecimals) * this.n);
    return PreciseNumber.from(newN, precision);
  }

  floor(precision: number): PreciseNumber {
    if (precision === this.numDecimals) {
      return this;
    }
    const newN = Math.floor(Math.pow(10, precision - this.numDecimals) * this.n);
    return PreciseNumber.from(newN, precision);
  }

  add(other: PreciseNumber): PreciseNumber {
    const numDecimals = Math.max(other.numDecimals, this.numDecimals);
    const a = this.withPrecision(numDecimals);
    const b = other.withPrecision(numDecimals);
    return PreciseNumber.from(a.n + b.n, numDecimals);
  }

  minus(other: PreciseNumber): PreciseNumber {
    const numDecimals = Math.max(other.numDecimals, this.numDecimals);
    const a = this.withPrecision(numDecimals);
    const b = other.withPrecision(numDecimals);
    return PreciseNumber.from(a.n - b.n, numDecimals);
  }

  divide(other: PreciseNumber): PreciseNumber {
    const numDecimals = Math.max(other.numDecimals, this.numDecimals);
    const a = this.withPrecision(numDecimals);
    const b = other.withPrecision(numDecimals);
    return PreciseNumber.from(a.n / b.n, 0).floor(numDecimals);
  }

  multiply(other: PreciseNumber): PreciseNumber {
    const numDecimals = Math.max(other.numDecimals, this.numDecimals);
    const a = this.withPrecision(numDecimals);
    const b = other.withPrecision(numDecimals);
    return PreciseNumber.from(a.n * b.n, numDecimals * 2).round(numDecimals);
  }

  toString(): string {
    if (this.numDecimals === 0) {
      return String(this.n);
    }
    const sign: string = this.n < 0 ? '-' : '';
    const numberStr: string = pad(String(Math.abs(this.n)), this.numDecimals + 1);
    const wholePart: string = numberStr.slice(0, numberStr.length - this.numDecimals);
    const decimalPart: string = numberStr.slice(-this.numDecimals);
    return `${sign}${wholePart}.${decimalPart}`;
  }
}
