<div
  *ngIf="endpoint"
  class="endpoint-time grow fill flex flex--wrap flex--middle flex--column {{ wrapAt ? 'wrap-at-' + wrapAt : '' }}"
  [class.flex--row-reverse]="reverse"
>
  <div
    class="endpoint-time-location flex--nogrow font-body-1 regular-type rounded white-text ps-xsmall-x ms-xxsmall-y nordic-blue-900-bg"
    [ngClass]="{ 'align-end': reverse, 'align-start': !reverse }"
  >
    {{ endpoint.locationCode }}
  </div>
  <div
    class="endpoint-time-date-time flex--nogrow font-body-1 light-type"
    [ngClass]="{ 'align-right': reverse, 'align-left': !reverse }"
  >
    <time [attr.datetime]="endpoint.dateTime">{{ endpoint.dateTime | finTime }}</time>
    <span class="ps-xxsmall-l">{{ endpoint.dateTime | finDateFormat: 'WEEKDAY_SHORT' }}</span>
  </div>
</div>
