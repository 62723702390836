import { breakpoints } from '@finnairoyj/fcom-ui-styles';

import { Breakpoint } from '../interfaces/breakpoint.interface';

export const getCurrentBreakPoint = (): Breakpoint => {
  const currentWidth: number = window.innerWidth;
  const breakpointOrder = [Breakpoint.DESKTOP, Breakpoint.LAPTOP, Breakpoint.TABLET, Breakpoint.MOBILE];

  return breakpointOrder.find((breakpointName) => {
    return currentWidth >= Number(breakpoints[breakpointName].replace('px', ''));
  });
};
