import { OnInit, Component, Input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';

import { GtmService } from '@fcom/common/gtm';
import { ElementActions, ElementTypes, GaContext } from '@fcom/common/interfaces';
import { FragmentService } from '@fcom/common/services';
import { CmsArticle } from '@fcom/core-api';

@Component({
  selector: 'fin-sustainability-review-card',
  templateUrl: 'sustainability-review-card.component.html',
  styleUrls: ['sustainability-review-card.component.scss'],
})
export class SustainabilityReviewCardComponent implements OnInit {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  towardsSustainabilityMessage$: Observable<CmsArticle>;

  @Input()
  headerClass = 'font-heading-3';

  @Input()
  showHeader = true;

  @Input()
  isLinkExternal = false;

  constructor(
    private fragmentService: FragmentService,
    private gtmService: GtmService
  ) {}

  ngOnInit(): void {
    this.towardsSustainabilityMessage$ = this.fragmentService.getFragment<CmsArticle>(
      'fragments.towardsSustainability.url'
    );
  }

  trackReadAboutSustainability(): void {
    this.gtmService.trackElement(
      'booking-confirmed-read-more-about-sustainability',
      GaContext.BOOKING_FLOW,
      ElementTypes.LINK,
      undefined,
      ElementActions.CLICK
    );
  }
}
