<div class="timeline__cell" role="presentation">
  <div class="timeline__left flex--column flex--center">
    <div
      class="flex-1 line line-dotted"
      [class.next-day]="layover.note === C.FinnairItineraryNote.HAS_NEXT_DAY_TRANSFER"
      [class.long-wait]="layover.note === C.FinnairItineraryNote.HAS_LONG_TRANSFER"
      [class.airport-change]="layover.note === C.FinnairItineraryNote.REQUIRES_AIRPORT_CHANGE"
    ></div>
  </div>
  <div class="timeline__right medium-type heather-700-text pr-medium-y pr-xsmall-l">
    <ng-template #layoverContent>
      <p *ngIf="layover.note === C.FinnairItineraryNote.HAS_NEXT_DAY_TRANSFER; else normalTranfer">
        {{ 'itinerary.overnightTransfer' | finLocalization }}
      </p>
      <ng-template #normalTranfer>
        <p>{{ 'itinerary.transfer' | finLocalization }}</p>
      </ng-template>

      <p *ngIf="layover.requiresAirportChange; else potentialTerminalChange" class="bold-type">
        {{ 'itinerary.airportChange' | finLocalization }}
      </p>
      <ng-template #potentialTerminalChange>
        <p *ngIf="layover.requiresTerminalChange; else transportationChange" class="bold-type">
          {{ 'itinerary.terminalChange' | finLocalization }}
        </p>
        <ng-template #transportationChange>
          <ng-container *ngIf="layover.requiresTransportationMethodChange; else regularTransfer" class="bold-type">
            <ng-container *finLet="toType(previousLeg) as previous">
              <span class="bold-type" *ngIf="previous.aircraft?.code === BUS_AIRCRAFT_CODE">{{
                'itinerary.fromBusToFlight' | finLocalization
              }}</span>
            </ng-container>
            <ng-container *finLet="toType(nextLeg) as next">
              <span class="bold-type" *ngIf="next.aircraft?.code === BUS_AIRCRAFT_CODE">{{
                'itinerary.fromFlightToBus' | finLocalization
              }}</span>
            </ng-container>
          </ng-container>
          <ng-template #regularTransfer>
            <p class="bold-type">{{ 'itinerary.planeChange' | finLocalization }}</p>
          </ng-template>
        </ng-template>
      </ng-template>
      <p>
        {{ 'itinerary.transferTime' | finLocalization: { duration: '' } }}
        <span *ngIf="oldDuration" class="grey-700-text strike-through mr-xxsmall-r">
          {{ oldDuration | finDuration | async }}
        </span>
        {{ layover.duration | finDuration | async }}
      </p>
      <p *ngIf="layover.note" class="regular-type pr-xsmall-t nordic-blue-900-text">
        <span>{{ 'itinerary.note' | finLocalization }}: </span>
        <span>{{
          'itinerary.notes.' +
            (isBookingFlow && layover.note === FinnairItineraryNote.REQUIRES_AIRPORT_CHANGE ? 'booking.' : '') +
            layover.note | finLocalization
        }}</span>
      </p>
      <div *ngIf="notification" class="timeline__right medium-type heather-700-text pr-small-y">
        <fcom-notification
          [theme]="NotificationTheme.WARNING"
          [layout]="NotificationLayout.BLOCK"
          [iconName]="SvgLibraryIcon.INFO"
          class="block"
        >
          {{ 'MMB.notifications.disruption.' + notification + '.text' | finLocalization }}
        </fcom-notification>
      </div>
    </ng-template>

    <ng-container
      *ngIf="
        layover.note !== C.FinnairItineraryNote.HAS_NEXT_DAY_TRANSFER &&
          layover.note !== C.FinnairItineraryNote.REQUIRES_AIRPORT_CHANGE;
        else importantNote
      "
    >
      <ng-container *ngTemplateOutlet="layoverContent"></ng-container>
    </ng-container>

    <ng-template #importantNote>
      <div class="arrow-block rounded mr-xsmall-l">
        <div class="arrow-left heather-50-bg border z2"></div>
        <div class="pr-xsmall heather-50-bg relative z3">
          <ng-container *ngTemplateOutlet="layoverContent"></ng-container>
        </div>
      </div>
    </ng-template>
  </div>
</div>
