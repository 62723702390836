import { Component, Inject, OnDestroy, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { NavigationMenuService, ScrollService } from '@fcom/common/services';
import { unsubscribe } from '@fcom/core/utils';
import { isNavigationEvent } from '@fcom/common/utils';

@Component({
  selector: 'fin-language-setting-layout',
  templateUrl: './language-setting-layout.component.html',
  styleUrls: [],
})
export class LanguageSettingLayoutComponent implements OnDestroy {
  subscriptions: Subscription = new Subscription();
  isFooterOpen$: Observable<boolean>;

  constructor(
    private navigationMenuService: NavigationMenuService,
    private router: Router,
    private scrollService: ScrollService,
    @Inject(PLATFORM_ID) private platform: object
  ) {
    this.subscriptions.add(
      this.router.events
        .pipe(
          filter(isNavigationEvent),
          filter(() => isPlatformBrowser(this.platform))
        )
        .subscribe(() => {
          this.scrollService.scrollTop();
        })
    );
    this.isFooterOpen$ = this.navigationMenuService.isFooterOpen$;
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }
}
