import { Pipe, PipeTransform } from '@angular/core';

import { isUndefined, isNull } from '@fcom/core/utils';

export const NBSP = '\u00A0';

@Pipe({
  name: 'finEmpty',
})
export class EmptyPipe implements PipeTransform {
  transform(input: string, replaceWith: any = NBSP): string {
    if (isUndefined(input) || isNull(input) || input === '') {
      return replaceWith;
    }
    return input;
  }
}
