import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { CmsEditorialArticle, CmsTaxonomyGeneral } from '@fcom/core-api';
import { SchemaService } from '@fcom/common/services/schema/schema.service';
import { toISO8601UTC } from '@fcom/core/utils/date';
@Component({
  selector: 'fin-article-bluewings',
  templateUrl: './article-bluewings.component.html',
  styleUrls: ['./article-bluewings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleBluewingsComponent implements OnInit {
  @Input()
  content: CmsEditorialArticle;

  constructor(
    private schemaService: SchemaService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    const media = this.content.media.length ? this.content.media[0] : null;

    this.schemaService.addArticleSchema({
      id: this.document.location.href,
      headline: this.content.teaserTitle,
      description: this.content.teaserText,
      dateModified: toISO8601UTC(new Date(this.content.creationDate)),
      datePublished: toISO8601UTC(
        new Date(
          this.content.localSettings?.firstPublished
            ? this.content.localSettings.firstPublished
            : this.content.creationDate
        )
      ),
      image: {
        url: media?.['data'],
        width: media?.['width']?.toString(),
        height: media?.['height']?.toString(),
      },
    });
  }

  readonly CmsTaxonomyGeneral = CmsTaxonomyGeneral;
}
