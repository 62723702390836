/* eslint-disable no-underscore-dangle */
import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { safeSelect, StateSelector } from '@fcom/core/selectors/selector-utils';
import {
  Amount,
  CartStatus,
  NO_VARIANTS,
  SelectedServiceData,
  SelectionsPerTraveler,
  ServiceAvailability,
  ServiceCatalog,
  ServiceCatalogCategory,
  ServiceCatalogCategoryServicesMap,
  ServiceCatalogSelections,
  ServiceCatalogSelectionsForAllTravelers,
  ServiceCatalogService,
  ServiceCatalogServices,
  ServicesPerTraveler,
  ServicesState,
  ServicesStatus,
  TravelerService,
} from '@fcom/dapi/interfaces';
import {
  entrySet,
  isEmpty,
  isEmptyObjectOrHasEmptyValues,
  isPresent,
  mapValues,
  PreciseNumber,
  valuesOf,
} from '@fcom/core/utils';
import { Category, FinnairServiceCatalogCampaign, SubCategory } from '@fcom/dapi/api/models';
import {
  PaxServiceCategories,
  PaxServiceCategory,
} from '@fcom/booking/modules/pax-details/interfaces/pax-ancillaries.interface';
import { cartStatusSelector } from '@fcom/booking/store/selectors';
import { finShare } from '@fcom/rx';
import { CustomServiceType, ServiceSelections } from '@fcom/dapi/interfaces/services.interface';

import { CommonBookingFeatureState, QuantityForFragmentAndPassenger } from '../';
import { CommonBookingState } from '../store.interface';
import { COMMON_BOOKING_FEATURE_KEY } from '../constants';
import { isBoundBasedCategory } from '../../modules/ancillaries/utils';

const _selectCommonBookingState = createFeatureSelector<CommonBookingFeatureState, CommonBookingState>(
  COMMON_BOOKING_FEATURE_KEY
);
const _servicesState = createSelector(_selectCommonBookingState, (state: CommonBookingState) => state?.services);

const _serviceCatalog = createSelector(_servicesState, (ss: ServicesState) => ss?.services);

const _serviceAvailability = createSelector(_servicesState, (ss: ServicesState) => ss?.serviceAvailability);

const _serviceCatalogCampaigns = createSelector(_serviceCatalog, (catalog: ServiceCatalog) => catalog?.campaigns);

const _campaignsShown = createSelector(_servicesState, (state: ServicesState) => state?.campaignsShown ?? []);

const _serviceSelections = createSelector(_servicesState, (ss: ServicesState) => ss?.selections);

const _servicesStatus = createSelector(_servicesState, (ss: ServicesState) => ss?.status);

const _servicesForCategory = (category: Category) =>
  createSelector(_serviceCatalog, (sc) =>
    sc ? sc.categories?.find((c) => c.category === category)?.services || {} : undefined
  );

const _serviceCategories = createSelector(_serviceCatalog, (sc) => sc?.categories);

const _serviceCategoriesMap = createSelector(_serviceCatalog, (sc) =>
  sc?.categories.reduce((categories, category) => {
    categories[category.category] = category.services;
    return categories;
  }, {})
);

const _servicesReady = createSelector(
  cartStatusSelector,
  _servicesStatus,
  (cartStatus: CartStatus, servicesStatus: ServicesStatus) => {
    return cartStatus === CartStatus.OK && servicesStatus === ServicesStatus.OK;
  }
);

const _servicesForFragmentAndCategory = (fragmentId: string, category: Category) =>
  createSelector(_servicesForCategory(category), (ss: ServiceCatalogServices) =>
    ss ? ss[fragmentId] || {} : undefined
  );

const _serviceSelectionsForCategory = <T>(category: Category) =>
  createSelector(
    _servicesState,
    (ss: ServicesState): ServiceCatalogSelections<T> => (ss ? ss.selections?.[category] || {} : undefined)
  );

const _variantsQuantity = (variants: string[]) =>
  createSelector(_serviceSelections, (selections): QuantityForFragmentAndPassenger => {
    return Object.values(selections ?? {}).reduce(
      (allCategoriesQuantity, selection: ServiceCatalogSelections<TravelerService | TravelerService[]>) => {
        return entrySet(selection).reduce((quantitiesForFragment, service) => {
          return entrySet(service.value).reduce((innerQuantitiesForFragment, p) => {
            const services = [].concat(p.value);
            const newQuantity = services
              .filter(Boolean)
              .filter((s) => variants.includes(s.variant) && !s.includedInTicketType && !s.includedInTierBenefit)
              .reduce(
                (quantity, passengerService) => quantity + (passengerService.quantity ?? 1),
                innerQuantitiesForFragment[service.key]?.[p.key] ?? 0
              );

            if (!innerQuantitiesForFragment[service.key]) {
              innerQuantitiesForFragment[service.key] = {};
            }
            innerQuantitiesForFragment[service.key][p.key] = newQuantity;
            return innerQuantitiesForFragment;
          }, quantitiesForFragment);
        }, allCategoriesQuantity);
      },
      {}
    );
  });

const _upsell = createSelector(_servicesState, (ss: ServicesState): SelectedServiceData => ss?.upsell);

const _serviceSelectionsForFragmentAndCategory = <T>(fragmentId: string, category: Category) =>
  createSelector(_serviceSelectionsForCategory(category), (ss: ServiceCatalogSelections<T>) =>
    ss ? ss[fragmentId] || {} : undefined
  );

const _serviceSelectionsForFragmentAndTraveler = <T>(fragmentId: string, travelerId: string, category: Category) =>
  createSelector(
    _serviceSelectionsForFragmentAndCategory(fragmentId, category),
    (selection: SelectionsPerTraveler<T>): T => selection?.[travelerId]
  );

const _allSelectionsForCategory = <T>(category: Category) =>
  createSelector(
    _servicesForCategory(category),
    _serviceSelectionsForCategory<T>(category),
    (services, selections): [ServiceCatalogServices, ServiceCatalogSelections<T>] => {
      return [services, selections];
    }
  );

const _selectionsForCategories = (categories: Category[]) =>
  createSelector(
    _serviceSelections,
    (selections): ServiceSelections =>
      categories.reduce((allCategories, category: Category) => {
        return {
          ...allCategories,
          [category]: selections?.[category] ?? {},
        };
      }, {})
  );

const _serviceMapForCategories = (categories: Category[]) =>
  createSelector(_serviceCategoriesMap, _serviceCategories, (categoriesMap, serviceCategories) => {
    const categoryMap = categories.reduce((allCategories, category: Category) => {
      const serviceCategory = serviceCategories?.find((sc) => sc.category === category);
      return isPresent(categoriesMap?.[category])
        ? [
            ...allCategories,
            {
              services: categoriesMap[category],
              translations: serviceCategory?.translations ?? {},
              media: serviceCategory?.media ?? {},
              category,
              isBoundBased: isBoundBasedCategory(category),
            },
          ]
        : allCategories;
    }, []);

    return isEmptyObjectOrHasEmptyValues(categoryMap) ? undefined : categoryMap;
  });

export const serviceCategories = (): StateSelector<CommonBookingFeatureState, ServiceCatalogCategory[]> =>
  safeSelect(_serviceCategories);

export const serviceCategoriesMap = (): StateSelector<CommonBookingFeatureState, ServiceCatalogCategoryServicesMap> =>
  safeSelect(_serviceCategoriesMap);

export const serviceCatalog = (): StateSelector<CommonBookingFeatureState, ServiceCatalog> => select(_serviceCatalog);

export const serviceAvailability = (): StateSelector<CommonBookingFeatureState, ServiceAvailability> =>
  safeSelect(_serviceAvailability);

export const serviceCatalogCampaigns = (): StateSelector<CommonBookingFeatureState, FinnairServiceCatalogCampaign[]> =>
  safeSelect(_serviceCatalogCampaigns);

export const serviceCatalogCampaignIdsForFragment = (
  fragmentId: string
): StateSelector<CommonBookingFeatureState, Record<Category, string[]>> =>
  pipe(
    safeSelect(_serviceCatalogCampaigns),
    map((campaigns) =>
      campaigns.reduce(
        (campaignsAndCategories, campaign) => {
          campaign.categories
            .filter((category) => !!category.fragments[fragmentId])
            .forEach((campaignCategory) => {
              if (campaignsAndCategories[campaignCategory.id]) {
                campaignsAndCategories[campaignCategory.id] = [
                  ...campaignsAndCategories[campaignCategory.id],
                  campaign.id,
                ];
              } else {
                campaignsAndCategories[campaignCategory.id] = [campaign.id];
              }
            });
          return campaignsAndCategories;
        },
        {} as Record<Category, string[]>
      )
    ),
    startWith({} as Record<Category, string[]>)
  );
export const campaignsShown = (): StateSelector<CommonBookingFeatureState, string[]> => safeSelect(_campaignsShown);

export const serviceSelectionsForFragment = <T>(
  fragmentId: string,
  category: Category
): StateSelector<CommonBookingFeatureState, SelectionsPerTraveler<T>> =>
  safeSelect(_serviceSelectionsForFragmentAndCategory<T>(fragmentId, category));

export const variantsQuantity = (
  variants: string[]
): StateSelector<CommonBookingFeatureState, QuantityForFragmentAndPassenger> => {
  return select(_variantsQuantity(variants));
};

export const serviceSelectionsForFragmentAndTraveler = <T>(
  fragmentId: string,
  travelerId: string,
  category: Category
): StateSelector<CommonBookingFeatureState, T> =>
  safeSelect(_serviceSelectionsForFragmentAndTraveler<T>(fragmentId, travelerId, category));

export const servicesState = (): StateSelector<CommonBookingFeatureState, ServicesState> => select(_servicesState);
export const servicesStatus = (): StateSelector<CommonBookingFeatureState, ServicesStatus> => select(_servicesStatus);
export const servicesReady = (): StateSelector<CommonBookingFeatureState, boolean> => select(_servicesReady);
export const upsell = (): StateSelector<CommonBookingFeatureState, SelectedServiceData> => select(_upsell);

export const servicesForCategory = (
  category: Category
): StateSelector<CommonBookingFeatureState, ServiceCatalogServices> => safeSelect(_servicesForCategory(category));

export const servicesForFragmentAndCategory = (
  fragmentId: string,
  category: Category
): StateSelector<CommonBookingFeatureState, ServicesPerTraveler> =>
  safeSelect(_servicesForFragmentAndCategory(fragmentId, category));

export const serviceMapForCategories = (
  categories: Category[]
): StateSelector<CommonBookingFeatureState, ServiceCatalogCategory[]> =>
  safeSelect(_serviceMapForCategories(categories));

export const serviceSelectionsForCategoryAllTravelers = (
  category: Category
): StateSelector<CommonBookingFeatureState, ServiceCatalogSelectionsForAllTravelers> =>
  pipe(
    select(_allSelectionsForCategory<TravelerService | TravelerService[]>(category)),
    map(([services, selections]) => {
      return entrySet(selections).reduce((all, selection) => {
        all[selection.key] = entrySet(selection.value).reduce<TravelerService[]>(
          (allServices: TravelerService[], traveler) => {
            const servicesForTraveler: ServiceCatalogService[] = services?.[selection.key]?.[traveler.key] || [];
            const travelerServices = Array.isArray(traveler.value) ? traveler.value : [traveler.value];
            const translatedServices = travelerServices
              .filter(Boolean)
              .map(addTranslationsToService(servicesForTraveler));
            translatedServices.forEach((service) => (service.travelerId = traveler.key));
            return allServices.concat(translatedServices);
          },
          []
        );
        return all;
      }, {});
    }),
    finShare()
  );

export const serviceMapForCategoriesForTravelers = (
  travelerIds: string[],
  categories: Category[]
): StateSelector<CommonBookingFeatureState, PaxServiceCategories> =>
  pipe(
    safeSelect(_serviceMapForCategories(categories)),
    map((serviceCategories: PaxServiceCategory[]) => {
      return travelerIds.reduce((serviceMapForTravelers, travelerId) => {
        return {
          ...serviceMapForTravelers,
          [travelerId]: serviceCategories.map((category) => ({
            ...category,
            services: mapValues(category.services, (fragment, fragmentId) => {
              const travelerOrGroup = fragmentId === CustomServiceType.JOURNEY ? CustomServiceType.GROUP : travelerId;
              // Filter out unwanted service variants and subCategories
              return fragment?.[travelerOrGroup]
                ?.filter((service) => ![...NO_VARIANTS, '_NOLOUNGE'].includes(service.variant))
                .filter((service) => ![SubCategory.HEAVY_OR_LARGE].includes(service.subCategory));
            }),
          })),
        };
      }, {});
    })
  );

export const selectionMapForCategoriesForTravelers = (
  travelerIds: string[],
  categories: Category[]
): StateSelector<CommonBookingFeatureState, ServiceSelections> =>
  pipe(
    safeSelect(_selectionsForCategories(categories)),
    map((selections) => {
      return travelerIds.reduce(
        (selectionsForTravelers, travelerId) => ({
          ...selectionsForTravelers,
          [travelerId]: mapValues(selections, (category) =>
            mapValues(category, (fragment, fragmentId) => {
              const travelerOrGroup = fragmentId === CustomServiceType.JOURNEY ? CustomServiceType.GROUP : travelerId;
              return fragment?.[travelerOrGroup];
            })
          ),
        }),
        {}
      );
    })
  );

export const totalPriceForSelectionsForCategories = (
  categories: Category[]
): StateSelector<CommonBookingFeatureState, Amount> =>
  pipe(
    safeSelect(_selectionsForCategories(categories)),
    map((selections: ServiceSelections): (Amount & { quantity: number })[] =>
      valuesOf(selections as { [key: string]: ServiceCatalogSelections<TravelerService | TravelerService[]> })
        .flatMap((category) =>
          valuesOf(category).flatMap((fragment) =>
            valuesOf(fragment).flatMap((travelerSelection) => {
              if (Array.isArray(travelerSelection)) {
                return travelerSelection?.map((selection) => ({
                  ...selection.pricePerItem,
                  quantity: selection.quantity,
                }));
              }
              return isPresent(travelerSelection)
                ? { ...travelerSelection.pricePerItem, quantity: travelerSelection.quantity }
                : undefined;
            })
          )
        )
        .filter(Boolean)
    ),
    map(
      (allPrices: (Amount & { quantity: number })[]): Amount =>
        !isEmpty(allPrices)
          ? allPrices.reduce(
              (totalPrice, selectionPrice) => {
                const selectionSubTotal = PreciseNumber.fromString(selectionPrice.amount).multiply(
                  PreciseNumber.from(selectionPrice.quantity, 0)
                );

                return {
                  currencyCode: totalPrice.currencyCode || selectionPrice.currencyCode,
                  amount: PreciseNumber.fromString(totalPrice.amount).add(selectionSubTotal).toString(),
                };
              },
              {
                currencyCode: null,
                amount: '0',
              }
            )
          : undefined
    )
  );

function addTranslationsToService(servicesForTraveler: ServiceCatalogService[]) {
  return (travelerService): TravelerService => {
    const serviceForTraveler = servicesForTraveler.find((s) => s.variant === travelerService.variant);
    if (serviceForTraveler) {
      return {
        ...travelerService,
        ...(travelerService.category === Category.SEAT ? { seatNumber: travelerService.variant } : {}),
        title: serviceForTraveler.translations?.title,
        maxBaggageWeight: serviceForTraveler.parameters?.baggageWeight,
      };
    }
    return travelerService;
  };
}
