import { isPresent, TzDate } from '@fcom/core/utils';
import {
  FinnairBoundItem,
  FinnairCabinClass,
  FinnairCheckInEligibility,
  FinnairCheckInEligibilityReason,
  FinnairCheckInVersion,
  FinnairCompletionStatus,
  FinnairItineraryItem,
  FinnairItineraryItemFlight,
  FinnairOrder,
  FinnairPhoneNumberItem,
  FinnairTravelRequirementsEligibility,
} from '@fcom/dapi/api/models';
import { IdAndHash } from '@fcom/dapi';
import { CountryCode } from '@fcom/core';
import { isFlight } from '@fcom/common-booking/utils/common-booking.utils';

import { BoundSelectionType } from '../enums';

export const carTrawlerCountryLangMap = {
  'dk-da': 'da-DK',
  'ch-de': 'de-CH',
  'de-de': 'de-DE',
  'gb-en': 'en-GB',
  'us-en': 'en-US',
  'es-es': 'es-ES',
  'ee-et': 'et-EE',
  'fi-fi': 'fi-FI',
  'ca-fr': 'fr-CA',
  'fr-fr': 'fr-FR',
  'it-it': 'it-IT',
  'jp-ja': 'ja-JP',
  'kr-ko': 'ko-KR',
  'no-no': 'no-NO',
  'pl-pl': 'pl-PL',
  'ru-ru': 'ru-RU',
  'se-sv': 'sv-SE',
  'cn-zh': 'zh-CN',
  'hk-zh': 'zh-HK',
  'tw-zh': 'zh-TW',
};

export const carTrawlerSupportedCurrencies = [
  'EUR',
  'SEK',
  'DKK',
  'NOK',
  'CHF',
  'PLN',
  'HUF',
  'CZK',
  'GBP',
  'USD',
  'AUD',
  'CAD',
  'HKD',
  'CNY',
  'SGD',
  'JPY',
  'KRW',
  'INR',
  'THB',
];

export const departureIsAfterJuneFirst2023 = (nextBound: FinnairBoundItem): boolean =>
  new TzDate(nextBound.departure.dateTime).isAfter(new TzDate('2023-06-01T00:00:00+03:00'));

export const bookingIncludesBags = (bounds: FinnairBoundItem[]): boolean => {
  return (bounds || []).reduce((acc, cur) => acc + cur.freeBaggageAllowance.total, 0) > 0;
};
const REMOVE_TIMEZONE = /(Z|\+|-)(\d|:){0,5}$/;

/**
 * Determine if there has been a disruption in bound
 * @param bound Information about the bound
 */
export const isDisruptedBound = (bound: FinnairBoundItem): boolean => {
  return !!bound.disruptedBound && !!bound.disruptedBound.disruptedBound;
};

/**
 * Determine if check in is already open for the bound
 * @param bound Information about the bound
 */
export const boundHasCheckInOpen = (
  bound: FinnairBoundItem,
  checkinEligibilities: FinnairCheckInEligibility[]
): boolean => {
  return checkinEligibilities.some((eligibility) => eligibility.id === bound.id && eligibility.isAllowedToUse);
};

const boundHasAllCustomersCheckedIn = (
  bound: FinnairBoundItem,
  checkinEligibilities: FinnairCheckInEligibility[]
): boolean => {
  const boundEligibility = checkinEligibilities.find(({ id }) => id === bound.id);
  return (
    boundEligibility?.isAllowedToUse &&
    boundEligibility?.version === FinnairCheckInVersion.NEXTGEN &&
    Object.values(boundEligibility?.flightTravelerEligibility ?? {}).every((travelerEligibilities) =>
      travelerEligibilities.every((eligibility) => eligibility.isCheckedIn && eligibility.isAllowedToUse)
    )
  );
};

/**
 * Returns true if the given bound is open for checkin and all are not checked in
 * @param order Information about the whole booking
 * @param bound Bound to handle
 */
export const isBoundWithCheckInOpenAndNotAllAreCheckedIn = (order: FinnairOrder, bound: FinnairBoundItem): boolean => {
  return (
    boundHasCheckInOpen(bound, order.eligibilities.checkIn) &&
    !boundHasAllCustomersCheckedIn(bound, order.eligibilities.checkIn)
  );
};

/**
 * Return first bound that has not checkin open with given reason
 * @param order Information about the whole booking
 * @param bound Bound information
 * @param reason Eligibility reason we want to get
 */
export const isBoundHavingCheckInEligibility = (
  order: FinnairOrder,
  bound: FinnairBoundItem,
  reason: FinnairCheckInEligibilityReason
): boolean => {
  return !!order.eligibilities.checkIn.find(
    (eligibility) => eligibility.id === bound.id && !eligibility.isAllowedToUse && eligibility.reason === reason
  );
};

export const hasChangeEligibleBounds = (booking: FinnairOrder): boolean => {
  return booking.eligibilities?.change?.some((eligibility) => eligibility.isAllowedToUse);
};

/**
 * Removes layovers from itinerary array
 * @param itinerary array of all itineraries
 */
export const filterFlightsFromItinerary = (itinerary: FinnairItineraryItem[]): FinnairItineraryItemFlight[] =>
  itinerary.filter(isFlight);

export const filterFlightsFromAllBounds = (booking?: FinnairOrder): FinnairItineraryItemFlight[] =>
  booking?.bounds?.flatMap((bound) => bound.itinerary.filter(isFlight)) ?? [];

/**
 * Return itineraries where is a different cabin class than the bound has.
 * For example there might be a bound from OUL to BKK where OUL - HEL does not have business class available
 * @param bound Information of a single bound in a booking
 */
export const getItinerariesWithMixedCabinClasses = (bound: FinnairBoundItem): FinnairItineraryItemFlight[] => {
  if (bound.cabinClass !== FinnairCabinClass.BUSINESS) {
    return [];
  }
  return bound.itinerary.filter(isFlight).filter((flight) => flight.cabinClass !== FinnairCabinClass.BUSINESS);
};

export const travelRequirementsRequired = (order: FinnairOrder, isApisRequired = false): boolean => {
  const apisObject = (key, obj) => {
    const { [key]: _remove, ...newObj } = obj;
    return newObj;
  };

  if (!isPresent(order.eligibilities.travelRequirement)) {
    return false;
  }

  return Object.values(
    isApisRequired
      ? apisObject('emergencyContactDetails', order.eligibilities.travelRequirement)
      : order.eligibilities.travelRequirement
  ).some(
    (item: FinnairTravelRequirementsEligibility) => item.required && item.status === FinnairCompletionStatus.INCOMPLETE
  );
};

/**
 * Extract Id and Hash from FinnairOrder
 * @param order
 */
export const getIdAndHash = (order: FinnairOrder): IdAndHash => {
  return {
    id: order.id,
    hash: order.hash,
  };
};

export const numbersWithOptionCode = (number: FinnairPhoneNumberItem, codes: CountryCode[]): string | undefined => {
  const phoneNumber: CountryCode | undefined = codes.find(
    (code) => code.phonePrefix === number.countryPrefix || code.countryCode === number.countryCode
  );
  return phoneNumber ? `${phoneNumber.countryCode}|${phoneNumber.phonePrefix}` : undefined;
};

export const getTrackingDescription = (changeableBoundIds: string[], selectedBoundsId: string[]): string => {
  if (selectedBoundsId.length === changeableBoundIds.length) {
    return BoundSelectionType.ALL;
  }
  if (selectedBoundsId.length > 1) {
    return BoundSelectionType.SOME;
  }
  return changeableBoundIds.indexOf(selectedBoundsId[0]) === 0
    ? BoundSelectionType.OUTBOUND
    : BoundSelectionType.INBOUND;
};

export const getCarTrawlerLang = (finnairLocale: string): string => {
  return carTrawlerCountryLangMap[finnairLocale] || 'en';
};

export const supportedCarTrawlerCurrencies = (finnairCurrency: string): string => {
  return carTrawlerSupportedCurrencies.includes(finnairCurrency) ? finnairCurrency : 'EUR';
};

/**
 * Manipulate an ISO 8601 dateTime string into the format that CarTrawler requires.
 *
 * Example format that CarTrawler requires 2024-06-20T05:15:00
 *
 * 1) CarTrawler wants local datetime, but no timezone in the string.
 * 2) CarTrawler transfers.finnair.com site does not allow milliseconds in the string
 */
export const getCarTrawlerDateTime = (dateTime: string, addHours: number): string => {
  return new TzDate(dateTime.replace(REMOVE_TIMEZONE, 'Z')).addHours(addHours).toISOString().split('.')[0];
};
