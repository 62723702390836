enum PersonalizationParameter {
  TIER = 'tier',
  GROUP = 'group',
}

export enum CreditCardPersonalizationParameter {
  AKTIA_CREDIT_CARD = 'aktiavisacreditcard',
  AKTIA_LIGHT_CREDIT_CARD = 'aktiavisalightcreditcard',
}

// Only used for core media preview mechanics
enum CmsPreviewPersonalizationParameter {
  P13N_TEST = 'p13n_test',
  P13N_TEST_CONTEXT = 'p13n_testcontext',
  PREVIEW_DATE = 'previewDate',
}
export const CmsPersonalizationParameter = {
  ...PersonalizationParameter,
  ...CreditCardPersonalizationParameter,
  ...CmsPreviewPersonalizationParameter,
};
export type CmsPersonalizationParameterValue =
  `${(typeof CmsPersonalizationParameter)[keyof typeof CmsPersonalizationParameter]}`;
