import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';

import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, merge, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { CmsDataService, ConfigService, NativeBridgeService } from '@fcom/core';
import { CmsTaxonomyGeneral } from '@fcom/core-api/interfaces';
import { ProfileType } from '@fcom/core-api/login';
import { profileType } from '@fcom/core/selectors';
import { LoyaltyRoutesPath } from '@fcom/loyalty-core/interfaces';
import { addClass, deepCopy, getWhitelistedCmsParamsMapped, removeClass, unsubscribe } from '@fcom/core/utils';
import { finShare } from '@fcom/rx';
import { BookingAndAppState, BookingLocationCodes } from '@fcom/common/interfaces/booking';

import { BreadcrumbItem, MenuItem, MenuJson } from '../../interfaces';
import { globalBookingFlights } from '../../store';

const EMPTY_MENU: MenuJson = { top: [], main: [], bottom: [], template: '' };

// Mark active item and breadcrumbs leading to it
const initMenu = (menuData: MenuJson, currentUrl: string): MenuJson => {
  const initMenuItems = (menu, url, main = false) => {
    let onCurrentPath = false;
    const fixedUrl = url?.replace('/manage/check-in', '/check-in');
    menu.forEach((item) => {
      item.active =
        fixedUrl && (fixedUrl === item.link || (main && !item.items && item.link && isBaseUrl(fixedUrl, item.link)));
      item.breadcrumb = !!(item.items && initMenuItems(item.items, url, main));
      if (item.active || item.breadcrumb) {
        onCurrentPath = true;
      }
    });
    return onCurrentPath;
  };
  menuData.currentUrl = currentUrl;
  initMenuItems(menuData.main, currentUrl, true);
  initMenuItems(menuData.top, currentUrl);
  return menuData;
};

const isBaseUrl = (url: string, baseUrl: string): boolean => {
  const array = url.split('/');
  const subarray = baseUrl.split('/');
  return subarray.every((a, i) => a === array[i]);
};

@Injectable()
export class NavigationMenuService implements OnDestroy {
  public modalOpenSubject$: Subject<boolean> = new Subject();
  public isLandingPage$: Observable<boolean>;
  public isHeaderVisible$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isFooterVisible$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public deprecatedDestinationsBreadcrumbItem$: Observable<BreadcrumbItem>; // Reference to the "Destinations" Menu Item in the navigation structure. Deprecated, only used by ond-marketing-landing.component until OnD response contains breadcrumb
  public expandedOrActiveNaviCategory$: Observable<MenuItem>;
  public menuItems$: Observable<MenuJson>;
  public isNavigationMenuOpen$: Observable<boolean>;
  public isFooterOpen$: Observable<boolean>;
  public currentPath$: Observable<string>;

  private isMenuOpenSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false); // Start closed
  private isFooterOpenSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private expandedNaviCat$: BehaviorSubject<MenuItem> = new BehaviorSubject<MenuItem>(null);
  private oneworldLinkSubject$: ReplaySubject<BreadcrumbItem> = new ReplaySubject(1);
  private subscriptions: Subscription;

  constructor(
    private cmsDataService: CmsDataService,
    private languageService: LanguageService,
    private nativeBridgeService: NativeBridgeService,
    private router: Router,
    private store$: Store<BookingAndAppState>,
    private configService: ConfigService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platform: object
  ) {
    const routerEvents$: ReplaySubject<NavigationEnd> = new ReplaySubject(1);

    // Construct public observables from private Subjects to encapsulate the Subjects, prevents .next calls
    this.isNavigationMenuOpen$ = this.isMenuOpenSubject$.asObservable();
    this.isFooterOpen$ = this.isFooterOpenSubject$.asObservable();
    const locationCodes$ = this.store$.pipe(
      globalBookingFlights(),
      map((flights) =>
        flights
          .filter(({ origin, destination }) => origin?.locationCode && destination?.locationCode)
          .map(({ origin, destination }) => ({
            originLocationCode: origin?.locationCode,
            destinationLocationCode: destination?.locationCode,
          }))
      ),
      finShare()
    );

    const profileType$ = this.store$.pipe(profileType(), finShare());

    if (!this.subscriptions) {
      this.subscriptions = new Subscription();
    }
    this.subscriptions.add(
      this.router.events
        .pipe(
          filter((routerEvent) => routerEvent instanceof NavigationEnd),
          distinctUntilChanged(),
          tap((_a) => this.isMenuOpenSubject$.next(false))
        )
        .subscribe(routerEvents$)
    );

    this.constructMenuItemsObservable(routerEvents$, profileType$, locationCodes$);
    this.constructIsLandingPageObservable(languageService, routerEvents$);
    this.constructCurrentPathObservable(routerEvents$);
    this.constructDestinationsMenuItem$();
    this.constructExpandedOrActiveNaviCategory$();

    this.subscriptions.add(
      merge(this.isMenuOpenSubject$, this.modalOpenSubject$)
        .pipe(filter(() => isPlatformBrowser(this.platform)))
        .subscribe((open) => {
          if (open) {
            this.openModal();
          } else {
            this.closeModal();
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  setHeaderVisibility(isVisible: boolean): void {
    this.isHeaderVisible$.next(isVisible);
  }

  setFooterVisibility(isVisible: boolean): void {
    this.isFooterVisible$.next(isVisible);
  }

  setOneworldLink(item: BreadcrumbItem): void {
    this.oneworldLinkSubject$.next(item);
  }

  get oneworldLink$(): Observable<BreadcrumbItem> {
    return this.oneworldLinkSubject$.asObservable();
  }

  toggleMenuOpen(): void {
    if (this.nativeBridgeService.isInsideNativeWebView) {
      this.nativeBridgeService.openNativeMenu();
    } else {
      // eslint-disable-next-line rxjs/no-subject-value
      const prev = this.isMenuOpenSubject$.value;
      this.isMenuOpenSubject$.next(!prev);
      if (!prev) {
        this.closeNaviMenu();
      }
    }
  }

  closeMenu(): void {
    // eslint-disable-next-line rxjs/no-subject-value
    if (this.isMenuOpenSubject$.getValue()) {
      this.toggleMenuOpen();
    }
  }

  openModal(): void {
    addClass(this.document.body, 'modal-open');
  }

  closeModal(): void {
    removeClass(this.document.body, 'modal-open');
  }

  setFooterOpen(): void {
    this.isFooterOpenSubject$.next(true);
  }

  setFooterClosed(): void {
    this.isFooterOpenSubject$.next(false);
  }

  get expandedNaviCategory(): Observable<MenuItem> {
    // TODO Rename to expandedNaviCategory$
    return this.expandedNaviCat$.asObservable();
  }

  get activeNaviCategory(): Observable<MenuItem> {
    // TODO Rename to activeNaviCategory$
    return this.menuItems$.pipe(
      map((menu) => {
        return menu.main.find((item) => item.breadcrumb);
      })
    );
  }

  get activeNaviSection(): Observable<MenuItem> {
    // TODO Rename to activeNaviSelection$
    return this.activeNaviCategory.pipe(
      map((catMenu) => {
        return catMenu?.items?.find((item) => item.breadcrumb || item.active);
      })
    );
  }

  get activeNaviItem(): Observable<MenuItem> {
    // TODO Rename to activeNaviItem$
    return this.activeNaviSection.pipe(
      map((catSection) => {
        return !catSection || catSection.active
          ? catSection
          : catSection.items.find((item) => item.breadcrumb || item.active);
      })
    );
  }

  setCategoryAsExpanded(event: Event, category: MenuItem): void {
    event.stopImmediatePropagation();
    event.preventDefault();

    // eslint-disable-next-line rxjs/no-subject-value
    if (category === this.expandedNaviCat$.getValue()) {
      this.closeNaviMenu();
    } else {
      this.setExpandedCategory(category);
    }
  }

  setExpandedCategory(category: MenuItem): void {
    this.expandedNaviCat$.next(category);
  }

  closeNaviMenu(): void {
    this.expandedNaviCat$.next(null);
  }

  private constructMenuItemsObservable(
    routerEvents$: Observable<NavigationEnd>,
    profileType$: Observable<ProfileType>,
    locationCodes$: Observable<BookingLocationCodes[]>
  ) {
    const navigation$ = routerEvents$.pipe(
      take(1),
      switchMap((routerEvent) => {
        const whiteListedQueryParams =
          this.configService.cfg.cmsEnv !== 'prod' && this.configService.cfg.isCmsPreview
            ? getWhitelistedCmsParamsMapped(routerEvent.url)
            : [];

        return this.languageService.translate('fragments.navigation.url').pipe(
          distinctUntilChanged(),
          switchMap((url) =>
            !url
              ? of(EMPTY_MENU)
              : this.cmsDataService.getFragmentJson(
                  url + (whiteListedQueryParams.length > 0 ? `?${whiteListedQueryParams.join('&')}` : '')
                )
          )
        );
      })
    );

    this.menuItems$ = combineLatest([navigation$, routerEvents$, profileType$, locationCodes$]).pipe(
      map(([menuJson, routerEvent, memberProfileType, locationCodes]) => {
        const menu = deepCopy(menuJson) as MenuJson;
        menu.main = this.markMenuItemAsHiddenInNavigation(menu.main, memberProfileType);
        if (memberProfileType !== ProfileType.FPLUS) {
          menu.main = this.filterMenuItemsByPath(
            menu.main,
            `/${this.languageService.langValue}/${LoyaltyRoutesPath.PLUS}`
          );
        }
        if (locationCodes.length > 0) {
          menu.main = this.prefillTimetableLink(menu.main, locationCodes[0]);
        }
        return [menu, routerEvent];
      }),
      map(([menuJson, routerEvent]: [MenuJson, NavigationEnd]) => {
        this.closeNaviMenu();
        return initMenu(menuJson, this.splitPathFromUrl(routerEvent.url));
      }),
      finShare()
    );
  }

  private prefillTimetableLink(menuItems: MenuItem[], locationCode: BookingLocationCodes) {
    return menuItems.map((menuItem: MenuItem) => ({
      ...menuItem,
      items: menuItem.items?.map((item) =>
        item.link?.includes('timetables')
          ? {
              ...item,
              link: `${item.link}?dest=${locationCode.destinationLocationCode}&origin=${locationCode.originLocationCode}`,
            }
          : item
      ),
    }));
  }

  private splitPathFromUrl(url: string): string {
    let path: string;
    if (url) {
      path = url.split('?')[0];
      path = path.split('#')[0];
      path = path.split(';')[0];
    }
    return path;
  }

  private constructDestinationsMenuItem$(): void {
    // TODO: Remove deprecatedDestinationsBreadcrumbItem$ as soon as ond-marketing-landing.component does not use it anymore.
    this.deprecatedDestinationsBreadcrumbItem$ = this.menuItems$.pipe(
      // Hardcoded: "Destinations" item expected to be at main[1].items[0].
      map((a) => {
        const menuItem = a.main[1].items[0];
        return { title: menuItem.teaserTitle, url: menuItem.link } as BreadcrumbItem;
      }),
      // Don't emit the same item again every time menuItems updates its state, only re-emit when destinations link value actually changes (=change of language only)
      distinctUntilKeyChanged('url')
    );
  }

  private constructIsLandingPageObservable(languageService: LanguageService, routerEvents$: Observable<NavigationEnd>) {
    this.isLandingPage$ = routerEvents$.pipe(
      map((routerEvent: RouterEvent) => {
        return routerEvent.url === `/${languageService.langValue}`;
      })
    );
  }

  private constructCurrentPathObservable(routerEvents$: Observable<NavigationEnd>) {
    this.currentPath$ = routerEvents$.pipe(
      map((routerEvent: RouterEvent) => {
        return routerEvent.url;
      })
    );
  }

  private constructExpandedOrActiveNaviCategory$(): void {
    this.expandedOrActiveNaviCategory$ = combineLatest([this.activeNaviCategory, this.expandedNaviCategory]).pipe(
      map(([activeNaviCategory, expandedNaviCategory]) =>
        expandedNaviCategory !== null ? expandedNaviCategory : activeNaviCategory
      ),
      finShare()
    );
  }

  private filterMenuItemsByPath(menuItems: MenuItem[], path: string) {
    return menuItems.map((menuItem: MenuItem) => {
      if (menuItem.items) {
        menuItem.items = menuItem.items.filter((item) => item.link !== path);
      }
      return menuItem;
    });
  }

  private markMenuItemAsHiddenInNavigation(menuItems: MenuItem[], loggedProfileType: ProfileType) {
    const setHasVisibleChildren = (items: MenuItem[]): void => {
      items.forEach((menuItem: MenuItem) => {
        if (menuItem.items) {
          menuItem.hasVisibleChildren = menuItem.items.some((item) => !item.hideInMenu);
        }
      });
    };

    const checkItemVisibility = (item: MenuItem) => {
      item.hideInMenu = false;
      item.hideInSectionNavi = false;
      item.breadcrumbApplicationOverride = false;

      if (item.subjectTaxonomyTags?.includes(CmsTaxonomyGeneral.BREADCRUMB_APPLICATION_OVERRIDE)) {
        item.breadcrumbApplicationOverride = true;
      }
      if (item.subjectTaxonomyTags?.includes(CmsTaxonomyGeneral.HIDE_FROM_MAIN_MENU)) {
        item.hideInMenu = true;
      }
      if (item.subjectTaxonomyTags?.includes(CmsTaxonomyGeneral.HIDE_FROM_SECTION_NAVI)) {
        item.hideInSectionNavi = true;
      }
      if (item.subjectTaxonomyTags?.includes(CmsTaxonomyGeneral.SHOW_FOR_NOT_LOGGED_IN) && loggedProfileType) {
        item.hideInMenu = true;
        item.hideInSectionNavi = true;
      }
      if (item.subjectTaxonomyTags?.includes(CmsTaxonomyGeneral.SHOW_FOR_LOGGED_IN) && !loggedProfileType) {
        item.hideInMenu = true;
        item.hideInSectionNavi = true;
      }
      if (
        item.subjectTaxonomyTags?.includes(CmsTaxonomyGeneral.SHOW_FOR_LOGGED_IN_PLUS) &&
        loggedProfileType &&
        loggedProfileType !== ProfileType.FPLUS
      ) {
        item.hideInMenu = true;
        item.hideInSectionNavi = true;
      }

      if (item.items) {
        checkItemsVisibility(item.items);
        setHasVisibleChildren(item.items);
      }
      return item;
    };

    const checkItemsVisibility = (items: MenuItem[]) => {
      return items?.map((item) => checkItemVisibility(item));
    };

    return checkItemsVisibility(menuItems);
  }
}
