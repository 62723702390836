import { Component, EventEmitter, Input, Output, ChangeDetectorRef, OnInit, Injector, DoCheck } from '@angular/core';
import { NgControl } from '@angular/forms';

import { AbstractNgModelComponent } from './abstract-ng-model.component';
import { coerceBooleanProperty } from '../../../utils/utils';

@Component({ template: '' })
export class AbstractInputComponent<T = string> extends AbstractNgModelComponent<T> implements OnInit, DoCheck {
  @Input()
  get readonly(): boolean {
    return this.pReadOnly;
  }
  set readonly(req) {
    this.pReadOnly = coerceBooleanProperty(req);
    this.cdRef.markForCheck();
  }
  public pReadOnly = false;

  @Input()
  get required(): boolean {
    return this.pRequired;
  }
  set required(req) {
    this.pRequired = coerceBooleanProperty(req);
    this.cdRef.markForCheck();
  }
  public pRequired = false;

  @Input()
  placeholder = '';

  @Input()
  get type(): string {
    return this.pType;
  }
  set type(type: string) {
    this.pType = type;
  }
  private pType = 'text';

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  blur = new EventEmitter<void>();

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  focus = new EventEmitter<void>();

  ngControl: NgControl;
  errorState = false;
  focused = false;

  constructor(
    cdRef: ChangeDetectorRef,
    private injector: Injector
  ) {
    super(cdRef);
  }

  ngOnInit(): void {
    this.ngControl = this.injector.get<NgControl>(NgControl, null);
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
    }
  }

  blurEvent(): void {
    this.focused = false;
    this.blur.next();
    if (this.onTouched) {
      this.onTouched();
    }
    this.cdRef.markForCheck();
  }

  focusEvent(): void {
    this.focused = true;
    this.focus.next();
    this.cdRef.markForCheck();
  }
}
