import { sortBy } from '@fcom/core/utils';
import { Offer } from '@fcom/dapi/api/models';

export const resolvePreselection = (offers: Offer[] = []) => {
  const offersSortedByPrice: Offer[] = sortBy(offers, (offer) => +offer.totalPrice);
  const firstOffer: Offer = offersSortedByPrice[0];

  return {
    outboundFareFamily: firstOffer ? firstOffer.outboundFareFamily || '' : '',
    inboundFareFamily: firstOffer ? firstOffer.inboundFareFamily || '' : '',
    outboundFareInformation: firstOffer ? firstOffer.outboundFareInformation || undefined : undefined,
    inboundFareInformation: firstOffer ? firstOffer.inboundFareInformation || undefined : undefined,
  };
};
