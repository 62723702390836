import { Component, EventEmitter, OnDestroy, OnInit, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { BehaviorSubject, Observable, Subscription, map, startWith, tap } from 'rxjs';

import { LanguageService } from '@fcom/ui-translate';
import { ButtonMode, IconButtonSize, IconButtonTheme, NotificationTheme } from '@fcom/ui-components';
import { unsubscribe } from '@fcom/core/utils';
import { CountryState } from '@fcom/core';
import { finShare } from '@fcom/rx';

import { SelectOption } from '../../interfaces';
import { getCountryStates } from '../../utils/language.utils';
import { COUNTRY_SHOP_COUNTRIES } from '../../utils/booking-common.utils';

export interface CountryAndLanguageSelection {
  country: string;
  languageCode: string;
}

@Component({
  selector: 'fin-country-and-language-selector',
  templateUrl: './country-and-language-selector.component.html',
  styleUrls: ['country-and-language-selector.component.scss'],
})
export class CountryAndLanguageSelectorComponent implements OnInit, OnDestroy {
  readonly ButtonMode = ButtonMode;
  readonly IconButtonSize = IconButtonSize;
  readonly IconButtonTheme = IconButtonTheme;
  readonly IconLibrary = IconLibrary;
  readonly NotificationTheme = NotificationTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  showWarning = false;

  @Input()
  useH1 = false;

  @Input()
  prefill = true;

  @Input()
  showCloseButton = true;

  @Output()
  applySelection = new EventEmitter<CountryAndLanguageSelection>();

  @Output()
  closeClick = new EventEmitter<void>();

  subscriptions: Subscription = new Subscription();
  countries: CountryState[] = getCountryStates();

  countryOptions: SelectOption[];
  formGroup: UntypedFormGroup;
  showFlag = false;

  languageOptions$: Observable<SelectOption[]>;
  showCountryShopWarning$ = new BehaviorSubject<boolean>(false);

  constructor(
    private fb: UntypedFormBuilder,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.countryOptions = this.countries.map(({ name, code }) => ({ name, value: code }));

    const langValue = this.languageService.langValue === 'master-en' ? 'gb-en' : this.languageService.langValue;

    const country = langValue?.includes('-') ? langValue.split('-')?.[0] : 'int';

    this.formGroup = this.fb.group({
      country: this.fb.control(this.prefill ? country.toUpperCase() : '', Validators.required),
      language: this.fb.control('', Validators.required),
    });

    this.languageOptions$ = this.formGroup.get('country').valueChanges.pipe(
      startWith(this.prefill ? country.toUpperCase() : ''),
      tap((countryCode) => {
        this.showCountryShopWarning$.next(countryCode && !COUNTRY_SHOP_COUNTRIES.includes(countryCode.toLowerCase()));
      }),
      map(
        (countryCode) =>
          this.countries
            .find((country) => country.code === countryCode)
            ?.languages.map((countryLanguage) => ({ name: countryLanguage.name, value: countryLanguage.code })) || []
      ),
      finShare()
    );

    this.subscriptions.add(
      this.languageOptions$.subscribe((options) => {
        if (!options || options.length === 0) {
          this.formGroup.controls.language.disable();
        } else {
          this.formGroup.controls.language.enable();
          const matchingValue = options.find((option) => option.value === langValue);

          if (matchingValue) {
            this.formGroup.controls.language.setValue(matchingValue.value);
          } else if (options.length === 1) {
            this.formGroup.controls.language.setValue(options[0].value);
          }

          this.showFlag = true;
        }
      })
    );
  }

  apply(): void {
    const country = this.formGroup.controls.country.value;
    const languageCode = this.formGroup.controls.language.value;

    this.applySelection.emit({ country, languageCode });
  }

  updateShowFlag(dropDownOpen: boolean): void {
    this.showFlag = !dropDownOpen && this.formGroup.controls.country.value;
  }

  emitClose(): void {
    this.closeClick.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }
}
