import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CmsArticle, CmsCollection, CmsContentType, CmsTeaser, CmsViewType } from '@fcom/core-api';
import { isNotBlank } from '@fcom/core';

@Component({
  selector: 'cms-articles-with-icons',
  templateUrl: './cms-articles-with-icons.component.html',
  styleUrls: ['./cms-articles-with-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsArticlesWithIconsComponent implements OnInit {
  protected readonly CmsContentType = CmsContentType;
  protected readonly CmsViewType = CmsViewType;
  protected readonly SvgLibraryIcon = SvgLibraryIcon;

  @HostBinding('class.col-1-12')
  defaultClass = true;

  @Input()
  content$: Observable<CmsCollection>;

  articles$: Observable<CmsArticle[]>;
  link$: Observable<CmsTeaser>;
  hasGridClass$: Observable<boolean>;

  ngOnInit(): void {
    this.articles$ = this.content$.pipe(
      map((collection) => collection.items.filter((i) => i.contentType === CmsContentType.CMArticle) as CmsArticle[])
    );
    this.link$ = this.content$.pipe(
      map((collection) => collection.items.find((i) => i.contentType === CmsContentType.CMTeaser) as CmsTeaser),
      filter(Boolean)
    );
    this.hasGridClass$ = this.content$.pipe(map((collection) => isNotBlank(collection.gridClass)));
  }
}
