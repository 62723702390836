declare const window: Window;

export abstract class WindowRef {
  get nativeWindow(): Window {
    throw new Error('unimplemented');
  }
}

export class BrowserWindowRef extends WindowRef {
  get nativeWindow(): Window {
    // return the global native browser window object
    return window;
  }
}

export class NullWindowRef extends WindowRef {}

export const WINDOW_PROVIDERS = [{ provide: WindowRef, useClass: BrowserWindowRef }];
