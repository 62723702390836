@if (content$() | async; as content) {
  <div class="arrow-link-list flex flex--column row-gap-24">
    @if (content.teaserTitle) {
      <cms-heading-tag
        [level]="headerLevel(content.subjectTaxonomyTags || []) ?? DEFAULT_HEADER_LEVEL"
        [extraClasses]="headerFontLevel(content.subjectTaxonomyTags || []) ?? 'font-heading-3'"
        >{{ content.teaserTitle }}</cms-heading-tag
      >
    }

    @for (item of content.items; track item.contentId) {
      <fin-text-link-left
        [target$]="
          item.contentType === CmsContentType.CMExternalLink
            ? (item | finToObservable)
            : (item.target | finToObservable)
        "
        [teaserTitle$]="item.teaserTitle | finToObservable"
      ></fin-text-link-left>
    }
  </div>
}
