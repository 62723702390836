import { ActionReducerMap } from '@ngrx/store';

import * as fromNotification from './notification.reducer';
import { globalBookingReducer } from './global-booking.reducer';
import { CommonState } from '../store.interface';

export const reducers: ActionReducerMap<CommonState> = {
  notifications: fromNotification.reducer,
  globalBooking: globalBookingReducer,
};
