<section class="child-page grid-max-width center">
  <div class="grid-col-12 grid-max-width no-row-gap white-bg relative rounded-large">
    <!-- Section navigation -->
    <fin-light-section-navi class="col-1-12 md-col-1-3 sm-col-2-11"></fin-light-section-navi>

    <!-- Child page content -->
    <section class="col-1-12 md-col-4-12 sm-col-2-11 mr-medium-t content-section">
      <div class="grid-col-12 col-1-12 no-gutters">
        <h1
          *ngIf="title$ | async as title"
          class="article-title nordic-blue-900-text mr-small-y font-heading-1 col-1-12 md-col-1-8"
        >
          {{ title }}
        </h1>
      </div>
      <fin-cms-json-content class="grid-col-12 no-gutters no-row-gap" [template$]="template$"></fin-cms-json-content>
    </section>
  </div>
</section>
