export enum WidgetTab {
  FLIGHT = 'flight',
  AWARD = 'award',
  AM = 'am',
  CARTRAWLER = 'carTrawler',
}

export interface InstantSearchBaseParams {
  departureLocationCode: string;
  destinationLocationCode: string;
  numberOfDays: number;
  travelClass?: string;
  oneway?: boolean;
  adults?: number;
  children?: number;
  infants?: number;
  directFlights?: boolean;
  locale?: string;
}

export interface InstantSearchFullYearParams extends InstantSearchBaseParams {
  startDate: string;
}

export interface InstantSearchFollowingMonthParams extends InstantSearchBaseParams {
  departureDate: string;
}
