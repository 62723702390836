import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MenuItem, NavigationMenuService } from '@fcom/common';
import { CmsContent, CmsContentType, CmsFormType, CmsTemplate, CmsViewType, PageViewType } from '@fcom/core-api';

@Component({
  selector: 'cms-editorial-category-page',
  templateUrl: './cms-editorial-category-page.component.html',
  styleUrls: ['./cms-editorial-category-page.component.scss'],
})
export class CmsEditorialCategoryPageComponent implements OnInit {
  @Input()
  template$: Observable<CmsTemplate>;

  alteredTemplate$: Observable<CmsTemplate>;
  activeNaviSection$: Observable<MenuItem>;

  public readonly DOCTYPE: typeof CmsContentType = CmsContentType;
  public readonly VIEWTYPE: typeof CmsViewType = CmsViewType;
  public readonly FORMTYPE: typeof CmsFormType = CmsFormType;
  public readonly PAGETYPE: typeof PageViewType = PageViewType;

  constructor(private navigationMenuService: NavigationMenuService) {
    this.activeNaviSection$ = this.navigationMenuService.activeNaviSection;
  }

  ngOnInit(): void {
    this.alteredTemplate$ = this.template$.pipe(
      map((template) => {
        if (template?.header?.[0]?.viewTypeName !== this.PAGETYPE.EDITORIAL_CATEGORY_PAGE) {
          return template;
        }

        const alteredTemplate = template;
        alteredTemplate.main = alteredTemplate.main.map((contentItem: CmsContent) => {
          if (
            (contentItem.contentType === CmsContentType.CMTeaser &&
              contentItem.viewTypeName !== CmsViewType.BACK_LINK) ||
            (contentItem.contentType === CmsContentType.CMArticle && contentItem.main)
          ) {
            return Object.assign(contentItem, { viewType: CmsViewType.MAIN_ARTICLE });
          }
          return contentItem;
        });

        return alteredTemplate;
      })
    );
  }
}
