declare const jest: unknown;

export const elementIsVisible = (element: HTMLElement, includeVisuallyHidden: boolean): boolean => {
  return (
    !element.classList.contains('is-hidden') &&
    (includeVisuallyHidden || (!includeVisuallyHidden && !element.classList.contains('is-vishidden'))) &&
    // If this is run in Jest, Jest does not render the elements into DOM, and they don't get a height or a width
    (typeof jest !== 'undefined' ||
      // if this is run on browser, we should check for widths and height
      !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length))
  );
};

export const getBreakpoint = (width: number): string => {
  switch (true) {
    case width < 600:
      return 'mobile';
    case width < 1024:
      return 'tablet';
    case width < 1600:
      return 'laptop';
    default:
      return 'desktop';
  }
};

export const findFirstFocusableElement = (node: HTMLElement): HTMLElement | HTMLInputElement | null => {
  const focusableSelector = `button:not([disabled]), [href]:not([disabled]), input:not([type="hidden"]):not([disabled]), 
    select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])`;
  const focusableElements = Array.from(node.querySelectorAll<HTMLElement | HTMLInputElement>(focusableSelector));
  const firstValidElement = focusableElements.find((element) => {
    return (
      !(element.offsetWidth === 0 && element.offsetHeight === 0) &&
      !element.hidden &&
      (element.tabIndex === undefined || element.tabIndex >= 0)
    );
  });

  return firstValidElement || null;
};
