import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

import { isFinnairPlusCardNumber, EMAIL_REGEX } from '@fcom/core/utils';

/**
 * FPlus number validation without AY prefix
 */
export const finnairPlusNumberValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value === '' || control.value === null || isFinnairPlusCardNumber(control.value.toUpperCase())) {
      return null;
    } else {
      return { pattern: true };
    }
  };
};

export const usernameValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const invalidEmail = Validators.pattern(EMAIL_REGEX)(control);
    const invalidMemberNumber = finnairPlusNumberValidator()(control);
    if (control.value === '' || control.value === null) {
      return null;
    } else if (invalidEmail && invalidMemberNumber) {
      return { pattern: true };
    } else {
      return null;
    }
  };
};
