import { CmsTemplate, CmsPage } from '@fcom/core-api';
import { PageMetaService } from '@fcom/common/services';

export const setPageMetadata = (template: CmsTemplate, pageMetaService: PageMetaService): void => {
  const header = template.header && (template.header[0] as CmsPage);
  if (!header) {
    return;
  }
  pageMetaService.setTitle(header.seoTitle || header.title);
  const metaDescription = header?.metaData?.find((meta) => meta.attributes.name === 'description');
  if (metaDescription?.attributes?.content) {
    pageMetaService.setDescription(metaDescription.attributes.content);
  }
};
