import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Subject, Observable, merge } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { CmsTab, CmsTabsCollection } from '@fcom/core-api';
import { KeyCodes, isKeyboardEvent } from '@fcom/core/utils';
import { ButtonTheme } from '@fcom/ui-components';
import { GtmService } from '@fcom/common/gtm';
import { ElementActions, ElementTypes, GaContext } from '@fcom/common/interfaces';

@Component({
  selector: 'cms-content-switcher-tabs',
  templateUrl: './cms-content-switcher-tabs.component.html',
  styleUrls: ['./cms-content-switcher-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsContentSwitcherTabsComponent implements OnInit {
  @Input()
  content$: Observable<CmsTabsCollection>;

  currentTab$: Observable<CmsTab>;

  readonly ButtonTheme = ButtonTheme;

  private currentTabSub$: Subject<CmsTab> = new Subject();

  constructor(private gtmService: GtmService) {}

  ngOnInit(): void {
    this.currentTab$ = merge(
      this.content$.pipe(
        map((c) => c.tabs[0]),
        take(1)
      ),
      this.currentTabSub$
    );
  }

  selectTab(tab: CmsTab): void {
    this.currentTabSub$.next(tab);

    this.trackElement(tab.content.segment);
  }

  handleKeyPress(event: KeyboardEvent, tab: HTMLButtonElement): void {
    const element: HTMLElement = this.getElement(event, tab);
    const buttonElement: HTMLButtonElement = element?.querySelector('button');
    if (buttonElement) {
      buttonElement.click();
      buttonElement.focus();
    }
  }

  private getElement(event: KeyboardEvent, tab: HTMLButtonElement): HTMLElement {
    const lastChild = tab.parentElement.parentElement.children[tab.parentElement.parentElement.children.length - 1];
    const firstChild = tab.parentElement.parentElement.children[0];

    if (isKeyboardEvent(event, KeyCodes.HOME)) {
      return firstChild as HTMLElement;
    } else if (isKeyboardEvent(event, KeyCodes.END)) {
      return lastChild as HTMLElement;
    } else if (isKeyboardEvent(event, KeyCodes.RIGHT)) {
      return (tab.parentElement.nextElementSibling || firstChild) as HTMLElement;
    } else if (isKeyboardEvent(event, KeyCodes.LEFT)) {
      return (tab.parentElement.previousElementSibling || lastChild) as HTMLElement;
    } else {
      return undefined;
    }
  }

  private trackElement(label: string) {
    this.gtmService.trackElement(
      `tab-${label}`,
      GaContext.CONTENT,
      ElementTypes.BUTTON,
      undefined,
      ElementActions.CLICK
    );
  }
}
