import { LocalDate } from '@fcom/core/utils';

import { Month, Day } from '../../utils/date.interface';

export interface Calendar {
  today: Day;
  months: Month[];
}

export type DateRange = [LocalDate?, LocalDate?];

export enum CalendarNavigationType {
  ARROWS = 'arrows',
  MIXED = 'mixed',
}

export enum CalendarNavigationEvent {
  PREV,
  NEXT,
}
