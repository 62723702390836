import { createSelector, select } from '@ngrx/store';

import { safeSelect, StateSelector } from '@fcom/core/selectors/selector-utils';
import { HistogramBar } from '@fcom/common';
import { AirCalendarList } from '@fcom/dapi/api/models';

import { BookingWidgetState, CalendarPrices, InstantSearchMonitoring, Warnings, WidgetTab } from '../../interfaces';
import { BookingWidgetAppState, bookingWidgetFeatureState } from './booking-widget-feature-state.selector';

const _bookingWidgetState = createSelector(
  bookingWidgetFeatureState,
  (_bs: BookingWidgetAppState) => _bs?.bookingWidget
);

export const _instantSearchMonitoringSelector = createSelector(
  _bookingWidgetState,
  (_bw: BookingWidgetState) => _bw?.instantSearchMonitoring
);

const _fullYearPrices = createSelector(_bookingWidgetState, (bw: BookingWidgetState) => bw?.prices?.fullYear ?? {});
const _followingMonthPrices = createSelector(
  _bookingWidgetState,
  (bw: BookingWidgetState) => bw?.prices?.followingMonth ?? {}
);
const _histogramPrices = createSelector(_bookingWidgetState, (bw: BookingWidgetState) => bw?.prices?.histogram ?? []);
const _airCalendarPrices = createSelector(_bookingWidgetState, (bw: BookingWidgetState) => bw?.prices?.airCalendar);

const _warningSelector = createSelector(_bookingWidgetState, (bw: BookingWidgetState) => bw?.warnings ?? {});

const _activeTabSelector = createSelector(
  _bookingWidgetState,
  (bw: BookingWidgetState) => bw?.activeTab ?? WidgetTab.FLIGHT
);

export const instantSearchMonitoring = (): StateSelector<BookingWidgetAppState, InstantSearchMonitoring> =>
  safeSelect(_instantSearchMonitoringSelector);

export const fullYearPrices = (): StateSelector<BookingWidgetAppState, CalendarPrices> => select(_fullYearPrices);
export const followingMonthPrices = (): StateSelector<BookingWidgetAppState, CalendarPrices> =>
  select(_followingMonthPrices);
export const histogramPrices = (): StateSelector<BookingWidgetAppState, HistogramBar[]> => select(_histogramPrices);
export const airCalendarPrices = (): StateSelector<BookingWidgetAppState, AirCalendarList> =>
  select(_airCalendarPrices);
export const notificationWarnings = (): StateSelector<BookingWidgetAppState, Warnings> => select(_warningSelector);
export const activeTab = (): StateSelector<BookingWidgetAppState, WidgetTab> => select(_activeTabSelector);
