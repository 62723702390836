import { StringNumberBooleanMap } from '@fcom/core-api/index';

import { Step } from '..';

/**
 * All possible query parameter names for all booking routes
 */
export enum BookingQueryParams {
  origin = 'origin',
  destination = 'destination',
  cabin = 'cabin',
  adults = 'adults',
  c15s = 'c15s',
  children = 'children',
  infants = 'infants',
  childrenAges = 'children_ages',
  oneway = 'oneway',
  departureDate = 'departureDate',
  returnDate = 'returnDate',
  directFlights = 'directFlights',
  departureFlightNumbers = 'departureFlightNumbers',
  returnFlightNumbers = 'returnFlightNumbers',
  departureFareFamily = 'departureFareFamily',
  returnFareFamily = 'returnFareFamily',
  isAward = 'isAward',
  promoCode = 'promoCode',
  json = 'json',
  tripType = 'tripType',
}

/**
 * The names of parameters applicable for booking entry route
 */
// origin, destination, departureDate and returnDate are all Optional
export type BookingEntryParamNames =
  | BookingQueryParams.origin
  | BookingQueryParams.destination
  | BookingQueryParams.departureDate
  | BookingQueryParams.returnDate;

export type RequiredFlightSelectionParamNames =
  | BookingQueryParams.origin
  | BookingQueryParams.destination
  | BookingQueryParams.cabin
  | BookingQueryParams.children
  | BookingQueryParams.infants
  | BookingQueryParams.departureDate;

export type OptionalFlightSelectionParamNames =
  | BookingQueryParams.adults
  | BookingQueryParams.c15s
  | BookingQueryParams.returnDate
  | BookingQueryParams.directFlights
  | BookingQueryParams.childrenAges
  | BookingQueryParams.departureFlightNumbers
  | BookingQueryParams.returnFlightNumbers
  | BookingQueryParams.departureFareFamily
  | BookingQueryParams.returnFareFamily
  | BookingQueryParams.isAward
  | BookingQueryParams.promoCode;

export type FlightSelectionParamNames = RequiredFlightSelectionParamNames | OptionalFlightSelectionParamNames;

/**
 * The parameters applicable for booking entry route.
 */
export type BookingEntryParams = { [k in BookingEntryParamNames]?: string };
export type FlightSelectionParams = { [k in RequiredFlightSelectionParamNames]: string | number | boolean } & {
  [k in OptionalFlightSelectionParamNames]?: string | number | boolean;
};

export type BookingStepParamsType = StringNumberBooleanMap | null;
export type BookingStepWithParams<_T extends BookingStepParamsType = null> = Step;
export type BookingErrorStepWithParams<_T extends BookingStepParamsType = null> = Step;

export interface BookingErrorSteps {
  ERROR: BookingErrorStepWithParams;
  ERROR_ROUTE: BookingErrorStepWithParams;
  PAYMENT_ERROR: BookingErrorStepWithParams;
}

export interface BookingSteps {
  ENTRY: BookingStepWithParams<BookingEntryParams>;
  CHECKOUT: BookingStepWithParams;
  FLIGHT_SELECTION: BookingStepWithParams<FlightSelectionParams>;
  PAXDETAILS: BookingStepWithParams;
  ANCILLARIES: BookingStepWithParams;
  PURCHASE_REVIEW: BookingStepWithParams;
  PURCHASE_SUCCESS: BookingStepWithParams;
}
