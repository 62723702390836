import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { AppState, ConfigService, NativeBridgeService } from '@fcom/core';
import { loginStatus, loginToken } from '@fcom/core/selectors';
import { isPresent } from '@fcom/core/utils';
import { LoginStatus } from '@fcom/core-api/login';

const containsUrl = (urlsToCheck: string[], url: string): boolean =>
  isPresent(urlsToCheck.find((urlToCheck: string) => url.indexOf(urlToCheck) >= 0));

@Injectable({
  providedIn: 'root',
})
export class LoginTokenInterceptor implements HttpInterceptor {
  static readonly LOGIN_TOKEN_HEADER = 'Authorization';
  static readonly LOGIN_TOKEN_PREFIX = 'Bearer ';

  constructor(
    private configService: ConfigService,
    private nativeBridgeService: NativeBridgeService,
    private store$: Store<AppState>
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!containsUrl(this.configService.sendLoginTokenForUrls, req.url)) {
      return next.handle(req);
    }
    return combineLatest([
      this.store$.pipe(
        loginStatus(),
        filter((status: LoginStatus) => this.hasCorrectStatus(req, status))
      ),
      this.store$.pipe(loginToken()),
    ]).pipe(
      take(1),
      switchMap(([_status, token]) => {
        const request: HttpRequest<any> = isPresent(token)
          ? req.clone({
              headers: req.headers.set(
                LoginTokenInterceptor.LOGIN_TOKEN_HEADER,
                `${LoginTokenInterceptor.LOGIN_TOKEN_PREFIX}${btoa(token)}`
              ),
              withCredentials: true,
            })
          : req;
        return next.handle(request);
      })
    );
  }

  private hasCorrectStatus(req: HttpRequest<any>, status: LoginStatus): boolean {
    const notLoggedInsideApp = status === LoginStatus.INITIAL && this.nativeBridgeService.isInsideNativeWebView;
    const statusNotInitialOrPending = status !== LoginStatus.INITIAL && status !== LoginStatus.PENDING;
    const hasCorrectReqUrl = this.configService.cfg.loyaltyServices.legacy.apiProfileUrl.indexOf(req.url) >= 0;
    const statusPendingAndCustomerProfileUrl = status === LoginStatus.PENDING && hasCorrectReqUrl;

    return notLoggedInsideApp || statusNotInitialOrPending || statusPendingAndCustomerProfileUrl;
  }
}
