import { Subscription } from 'rxjs';

export const unsubscribe = (sub: Subscription): Subscription => {
  if (sub && !sub.closed) {
    sub.unsubscribe();
  }
  return undefined;
};

export const removeListener = (unregistrar: Function): undefined => {
  if (unregistrar) {
    unregistrar();
  }
  return undefined;
};
