import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { ButtonType } from '@fcom/ui-components';

@Component({
  selector: 'fin-additional-email',
  templateUrl: './additional-email.component.html',
  styleUrls: ['./additional-email.component.scss'],
})
export class AdditionalEmailComponent {
  @Input()
  parentForm: UntypedFormGroup;
  @Input()
  controlName: string;
  @Input()
  emailsArray: string[] = [];
  @Input()
  placeholder: string;
  @Input()
  disabledPlaceholder: string;
  @Input()
  buttonText: string;
  @Input()
  isLoading: boolean;
  @Output()
  addEmail: EventEmitter<void> = new EventEmitter();
  @Output()
  removeEmail: EventEmitter<number> = new EventEmitter<number>();

  readonly ButtonType = ButtonType;
  readonly SvgLibraryIcon = SvgLibraryIcon;
}
