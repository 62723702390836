import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { EMPTY, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TzDate } from '@fcom/core/utils';
import { retryWithBackoff } from '@fcom/rx';

import { ConfigService } from '../config/config.service';
import { SentryLogger } from '../sentry/sentry.logger';
import { BenefitItemKeyEnum, BenefitItemV2, TierLevelBenefits } from '../../interfaces/tier-benefit.interface';

const FIRST_OF_JUNE = '2023-06-01T00:00:00+03:00';

@Injectable()
export class TierBenefitService {
  benefitKeys: BenefitItemKeyEnum[] = [
    BenefitItemKeyEnum.CarryonBagTier,
    BenefitItemKeyEnum.ExtraBags,
    BenefitItemKeyEnum.SportEquipment,
    BenefitItemKeyEnum.AdvanceSeatReservation,
    BenefitItemKeyEnum.Wifi,
    BenefitItemKeyEnum.Lounge,
    BenefitItemKeyEnum.ContactInfo,
    BenefitItemKeyEnum.FastTrack,
    BenefitItemKeyEnum.SnackDrink,
    BenefitItemKeyEnum.GiveAwayCard,
    BenefitItemKeyEnum.MemberOffers,
    BenefitItemKeyEnum.EasyBookings,
    BenefitItemKeyEnum.TierAward,
    BenefitItemKeyEnum.SpecialBags,
  ];

  constructor(
    private http: HttpClient,
    private config: ConfigService,
    private sentryLogger: SentryLogger
  ) {}

  /**
   * Function to fetch JSON content from the Benefit server
   *
   * @param tierLevels
   * @param locale
   * e.g. { tierLevels = GOLD }
   */
  getBenefitJson(tierLevels: string, locale: string): Observable<TierLevelBenefits> {
    return this.http
      .get<TierLevelBenefits>(`${this.config.cfg.tierBenefitUrl}?tierLevels=${tierLevels}&locale=${locale}`, {
        withCredentials: this.config.cfg.enableCorsCredentials,
      })
      .pipe(
        retryWithBackoff(2),
        catchError((err: unknown) => {
          this.sentryLogger.warn('Error retrieving tier benefits', { error: err });
          return EMPTY;
        })
      );
  }

  filterBenefits(tierBenefits: BenefitItemV2[] = []): BenefitItemV2[] {
    const currentDate = TzDate.now();
    return tierBenefits.filter((benefit) => {
      if (!this.benefitKeys.includes(benefit.benefitItemKey)) {
        return false;
      }
      switch (benefit.benefitItemKey) {
        case BenefitItemKeyEnum.SpecialBags:
          return currentDate.isBefore(new TzDate(FIRST_OF_JUNE));
        case BenefitItemKeyEnum.SportEquipment:
        case BenefitItemKeyEnum.CarryonBagTier:
          return !currentDate.isBefore(new TzDate(FIRST_OF_JUNE));
        case BenefitItemKeyEnum.ExtraBags:
          return currentDate.isBefore(new TzDate(FIRST_OF_JUNE)) ? benefit.effectiveTo : benefit.effectiveFrom;
        default:
          return benefit.effectiveFrom;
      }
    });
  }
}
