import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, catchError, map, of, throwError } from 'rxjs';

import { ConfigService, SentryLogger } from '@fcom/core';
import { Location } from '@fcom/core-api';
import { finShare } from '@fcom/rx';

import {
  AmOrigin,
  AmDestination,
  AmDestinationsResponse,
  AmHolidayTypeItem,
  AmLocation,
  AmAvailability,
} from '../interfaces/am-api.interface';
import { formatAmPaxGroup } from '../utils/utils.am';
import { AmRoomSelection } from '../interfaces';

@Injectable()
export class BookingWidgetAmService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private sentryLogger: SentryLogger
  ) {}

  getOrigins(): Observable<Location[]> {
    return this.amApiQuery<AmOrigin[]>('origins').pipe(
      map((data: AmOrigin[]) => {
        if (!data) {
          throwError(() => 'Empty origins response from AM');
        }
        return data.map(this.mapAmOriginToLocation);
      }),
      catchError((error: unknown) => {
        this.sentryLogger.warn('AM origins request failed', { error });
        return of([]);
      }),
      finShare()
    );
  }

  getDestinations(origin: string): Observable<AmLocation[]> {
    return this.amApiQuery<AmDestinationsResponse>(`destinations/${origin}`).pipe(
      map((data: AmDestinationsResponse) => {
        if (!data?.destinations) {
          throwError(() => 'Empty destinations response from AM');
        }
        return data.destinations.map((destination) =>
          this.mapAmDestinationToAmLocation(destination, data.holidayTypes)
        );
      }),
      catchError((error: unknown) => {
        this.sentryLogger.warn('AM destinations request failed', { error });
        return of([]);
      }),
      finShare()
    );
  }

  getAvailability(
    originLocationCode: string,
    destinationLocationCode: string,
    rooms: AmRoomSelection[]
  ): Observable<AmAvailability> {
    return this.amApiQuery<AmAvailability>('availability', {
      dep: originLocationCode,
      arr: destinationLocationCode,
      g: formatAmPaxGroup(rooms),
    }).pipe(
      catchError((error: unknown) => {
        this.sentryLogger.warn('AM availability request failed', { error });
        return of({});
      }),
      finShare()
    );
  }

  private mapAmOriginToLocation(amOrigin: AmOrigin): Location {
    return {
      title: amOrigin.city,
      country: '',
      countryCode: amOrigin.countryCode,
      cityName: amOrigin.city,
      locationCode: amOrigin.code,
      AJBPartner: false,
      SJBPartner: false,
      airAward: false,
      airPartner: false,
      airAlliance: false,
      type: 'city',
      latitude: 0,
      longitude: 0,
      continentCode: 'eu',
    };
  }

  private mapAmDestinationToAmLocation(
    amDestination: AmDestination,
    holidayTypes: {
      [key: string]: AmHolidayTypeItem;
    }
  ): AmLocation {
    return {
      location: {
        title: amDestination.city,
        country: amDestination.country,
        countryCode: amDestination.countryCode,
        cityName: amDestination.city,
        locationCode: amDestination.destinationCode,
        AJBPartner: false,
        SJBPartner: false,
        airAward: false,
        airPartner: false,
        airAlliance: false,
        type: 'city',
        latitude: 0,
        longitude: 0,
        continentCode: '',
      },
      holidayType: amDestination.holidayType,
      holidayTypeItem: holidayTypes[amDestination.holidayType],
      searchable: amDestination.searchable,
    };
  }

  private amApiQuery<T>(endpoint: string, params = {}): Observable<T> {
    const apiUrl = this.configService.cfg.amApiUrl;
    return this.http.get<T>(`${apiUrl}/${endpoint}`, { params });
  }
}
