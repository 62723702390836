import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';

import { CmsPicture, CmsTarget } from '@fcom/core-api';
import { AspectRatios } from '@fcom/ui-components';

@Component({
  selector: 'fin-text-on-image',
  templateUrl: './text-on-image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextOnImageComponent {
  @Input()
  teaserTitle$: Observable<string>;
  @Input()
  teaserText$: Observable<string> = EMPTY;
  @Input()
  imageData$: Observable<CmsPicture>;
  /**
   * The preferred aspect ratio of the image
   */
  @Input()
  imageAspectRatio$: Observable<keyof typeof AspectRatios>;
  @Input()
  target$: Observable<CmsTarget>;
}
