import { Component, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';

import { unsubscribe } from '@fcom/core/utils';
import { CookieService } from '@fcom/core';
import { LanguageService } from '@fcom/ui-translate';

import { SchemaService, NavigationMenuService, ScrollService } from '../../services';
import { isNavigationEvent } from '../../utils';

export const INDIA_REDIRECT_COOKIE = 'india-redirect';
export const INDIA_REDIRECT_CLOSED_COOKIE = 'india-redirect-closed';

@Component({
  selector: 'fin-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: [],
})
export class BaseLayoutComponent implements OnDestroy {
  subscriptions: Subscription = new Subscription();
  isFooterOpen$: Observable<boolean>;
  showIndiaRedirectNotification = false;

  constructor(
    private navigationMenuService: NavigationMenuService,
    private router: Router,
    private scrollService: ScrollService,
    private cookieService: CookieService,
    private languageService: LanguageService,
    private schemaService: SchemaService,
    @Inject(PLATFORM_ID) private platform: object
  ) {
    this.subscriptions.add(
      this.router.events
        .pipe(
          filter(isNavigationEvent),
          filter(() => isPlatformBrowser(this.platform)),
          distinctUntilChanged((o: NavigationEnd, n) => o.url.split('?')[0] === n.url.split('?')[0]) // If path changed (not only parameters)
        )
        .subscribe(() => {
          this.scrollService.scrollTop();
          this.schemaService.clearSchemas();
        })
    );
    this.isFooterOpen$ = this.navigationMenuService.isFooterOpen$;

    if (
      this.cookieService.getItem(INDIA_REDIRECT_COOKIE) &&
      isPlatformBrowser(this.platform) &&
      !this.cookieService.getItem(INDIA_REDIRECT_CLOSED_COOKIE) &&
      this.languageService.localeValue === 'en'
    ) {
      this.showIndiaRedirectNotification = true;
      this.cookieService.removeItem(INDIA_REDIRECT_COOKIE, '/', 'www.finnair.com');
    }
  }

  onCloseIndiaNotification(): void {
    this.showIndiaRedirectNotification = false;
    this.cookieService.setItem(INDIA_REDIRECT_CLOSED_COOKIE, true);
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }
}
