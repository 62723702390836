/* eslint-disable */

/**
 * Cabin
 */
export enum Cabin {
  ECONOMY = 'ECONOMY',
  ECOPREMIUM = 'ECOPREMIUM',
  BUSINESS = 'BUSINESS',
  FIRST = 'FIRST',
  MIXED = 'MIXED'
}

