<div class="pagination flex flex--row border-bottom border-left border-right rounded-bottom-large">
  <div class="text ps-medium-y ps-xsmall-x" *ngIf="textBeforePages">{{ textBeforePages }}</div>
  <fin-select-input
    class="change-pagination-count-select ps-xxsmall-t ps-xsmall-x"
    [parentForm]="reactiveForm"
    [controlName]="'paginationCount'"
    [options]="options"
    [disableLabel]="true"
    [disableSelectOption]="true"
    data-text-input
  ></fin-select-input>
  <div class="page-count ps-medium-y ps-xsmall-x">
    {{ startIndex === 0 ? startIndex + 1 : startIndex }}-{{ endIndex > totalItems ? totalItems : endIndex }} /
    {{ totalItems }}
  </div>
  <div class="page-selector ps-medium-y ps-xsmall-x">
    <fcom-icon
      dataTestId="pagination-prev"
      class="icon-small ms-small-r"
      [ngClass]="{
        'nordic-blue-100-text': currentPage === FIRST_PAGE,
        'link-hover': currentPage !== FIRST_PAGE,
      }"
      [name]="SvgLibraryIcon.CHEVRON_LEFT"
      (click)="prevPagination()"
    ></fcom-icon>
    <fcom-icon
      dataTestId="pagination-next"
      class="icon-small ms-small-l"
      [ngClass]="{
        'nordic-blue-100-text': currentPage === totalPages,
        'link-hover': currentPage !== totalPages,
      }"
      [name]="SvgLibraryIcon.CHEVRON_RIGHT"
      (click)="nextPagination()"
    ></fcom-icon>
  </div>
</div>
