<ng-container *ngIf="renewedComponent">
  <ng-container *ngTemplateOutlet="radioItemBoxTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="radioItemRoundTemplate"></ng-container>
</ng-container>

<ng-template #radioItemRoundTemplate>
  <label
    [attr.data-testid]="value"
    *ngIf="theme === RadioButtonTheme.ROUND"
    [class]="theme"
    [formGroup]="parentForm"
    [attr.aria-checked]="parentForm.value[controlName] === value"
    [class.disabled]="control.disabled"
    [class.error]="control.invalid && control.touched"
    class="renewed-component"
  >
    <div class="flex relative regular-type radio-wrapper" [class.grey-600-text]="control.disabled">
      <input
        class="is-vishidden"
        role="radio"
        [class.sensitive]="isSensitive"
        [id]="id"
        type="radio"
        [attr.name]="name || controlName"
        [value]="value"
        [formControlName]="controlName"
        [name]="controlName"
      />
      <span
        aria-hidden="true"
        [class.nordic-blue-900-text]="!control.disabled"
        [class.grey-200-text]="control.disabled"
        [class.ps-xxsmall-t]="description"
      >
        <fcom-icon
          *ngIf="parentForm.value[controlName] === value"
          class="radio-icon icon-medium"
          [dataTestId]="'icon-' + SvgLibraryIcon.RADIOBUTTON_SELECTED"
          [name]="SvgLibraryIcon.RADIOBUTTON_SELECTED"
        ></fcom-icon>
        <fcom-icon
          *ngIf="parentForm.value[controlName] !== value"
          class="radio-icon icon-medium"
          [class.error-900-text]="control.invalid && control.touched"
          [dataTestId]="'icon-' + SvgLibraryIcon.RADIOBUTTON_UNSELECTED"
          [name]="SvgLibraryIcon.RADIOBUTTON_UNSELECTED"
        ></fcom-icon>
      </span>
      <div class="full-width ms-xsmall-l">
        <span class="label font-body-1 medium-type nordic-blue-900-text">
          {{ (label ? label : translationPrefix + '.' + value) | finLocalization }}
        </span>
        <div *ngIf="descriptionText" class="font-body-1 inline fill">
          <div class="description-label font-body-2 nordic-blue-400-text">{{ descriptionText | finLocalization }}</div>
          <div class="nordic-blue-900-text"><ng-content></ng-content></div>
        </div>
      </div>
    </div>
  </label>
</ng-template>

<ng-template #radioItemBoxTemplate>
  <label
    [attr.data-testid]="value"
    *ngIf="theme === RadioButtonTheme.BOX"
    [class]="theme"
    [ngClass]="{
      'first rounded-left-large': first,
      'last rounded-right-large': last,
      checked: parentForm.value[controlName] === value,
      'error-900-border error': control.invalid && control.touched,
      disabled: control.disabled,
    }"
    class="renewed-component flex relative flex--center border nordic-blue-400-border"
    [formGroup]="parentForm"
    [attr.aria-checked]="parentForm.value[controlName] === value"
  >
    <div class="radio-wrapper relative">
      <span class="icon absolute ps-small-l" *ngIf="parentForm.value[controlName] === value">
        <fcom-icon class="icon-small" [name]="SvgLibraryIcon.CHECKMARK"></fcom-icon>
      </span>
      <div class="flex flex--center regular-type ps-xxlarge-x">
        <input
          class="is-vishidden"
          role="radio"
          [id]="id"
          type="radio"
          [class.sensitive]="isSensitive"
          [attr.name]="name || controlName"
          [value]="value"
          [formControlName]="controlName"
          [name]="controlName"
        />
        <span class="label font-body-1 nordic-blue-900-text medium-type nowrap">
          {{ (label ? label : translationPrefix + '.' + value) | finLocalization }}
        </span>
      </div>
    </div>
  </label>
</ng-template>

<label
  *ngIf="!renewedComponent"
  [formGroup]="parentForm"
  [attr.aria-checked]="parentForm.value[controlName] === radioKey"
  [class.disabled]="control.disabled"
>
  <div
    class="flex relative regular-type radio-wrapper"
    [class.ps-small-t]="displayContainerStyles"
    [class.grey-600-text]="control.disabled"
  >
    <input
      class="is-vishidden"
      role="radio"
      [class.sensitive]="isSensitive"
      [id]="controlName + '-' + radioKey"
      type="radio"
      [attr.name]="name || controlName"
      [value]="radioKey"
      [formControlName]="controlName"
      [name]="controlName"
    />
    <span
      aria-hidden="true"
      class="flex flex--align-start ps-xxsmall-t"
      [class.nordic-blue-900-text]="!control.disabled"
      [class.grey-200-text]="control.disabled"
    >
      <fcom-icon
        *ngIf="parentForm.value[controlName] === radioKey"
        class="radio-icon icon-small"
        [dataTestId]="'icon-' + SvgLibraryIcon.RADIOBUTTON_SELECTED"
        [name]="SvgLibraryIcon.RADIOBUTTON_SELECTED"
      ></fcom-icon>
      <fcom-icon
        *ngIf="parentForm.value[controlName] !== radioKey"
        class="radio-icon icon-small"
        [dataTestId]="'icon-' + SvgLibraryIcon.RADIOBUTTON_UNSELECTED"
        [name]="SvgLibraryIcon.RADIOBUTTON_UNSELECTED"
      ></fcom-icon>
    </span>
    <div class="full-width ms-xsmall-l">
      <div *ngIf="description" class="label font-body-1 ps-xsmall-l inline fill">
        <div class="medium-type">{{ descriptionTitle }}</div>
        <div class="font-body-2 grey-700-text" [innerHTML]="descriptionText"></div>
        <div class="nordic-blue-900-text"><ng-content></ng-content></div>
      </div>
      <span *ngIf="!description" class="label font-body-1">
        {{ translationPrefix + '.' + radioKey | finLocalization }}
      </span>
    </div>
  </div>
</label>
