<fin-skip-link [main]="main"></fin-skip-link>

<router-outlet name="header"></router-outlet>

<main #main [class.layout--footer-open]="isFooterOpen$ | async" role="main">
  <router-outlet></router-outlet>
</main>

<footer>
  <router-outlet name="footer"></router-outlet>
</footer>
