import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable, of } from 'rxjs';

import { CmsArticle, CmsCollection, CmsContentType, CmsPicture, CmsVideo, CmsViewType } from '@fcom/core-api';
import { AspectRatios } from '@fcom/ui-components';

@Component({
  selector: 'cms-article-collection',
  templateUrl: './cms-article-collection.component.html',
  styleUrls: ['./cms-article-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsArticleCollectionComponent {
  readonly CmsContentType = CmsContentType;
  readonly CmsViewType = CmsViewType;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  aspectRatio$?: Observable<keyof typeof AspectRatios> = of('16x9');

  @Input()
  content$: Observable<CmsCollection<CmsPicture | CmsVideo | CmsArticle>>;

  @Input()
  useGrid = true;

  @Input()
  disableTeaserTitle = false;

  @Input()
  sectionWrapperClass = 'section pr-large-y';

  @Input()
  sectionClass = 'section-one-third-right pr-xlarge-b';
}
