/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { catchErrorAsJson } from '@fcom/rx';
import { DapiBaseService } from '../dapi-base-service';
import { DapiHttpResponse } from '../dapi-http-response';
import { DapiRequestBuilder } from '../dapi-request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FinnairSearchMoreFlightsParameters } from '../models/finnair-search-more-flights-parameters';
import { FinnairSearchParameters } from '../models/finnair-search-parameters';
import { MultiOfferList } from '../models/multi-offer-list';


/**
 * Multicity offer api resource
 */
@Injectable({
  providedIn: 'root',
})
export class OffersMultiService extends DapiBaseService {
  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  /**
   * Path part for operation findMoreFlights
   */
  static readonly FindMoreFlightsPath = '/api/multi/moreFlights';

  /**
   * findMoreFlights.
   *
   * Find more flight offers for given offer id and bound
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findMoreFlights()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findMoreFlights$Response(rootUrl: string, params: {
    hash: string;
    body: FinnairSearchMoreFlightsParameters
  },
  context?: HttpContext

): Observable<DapiHttpResponse<MultiOfferList>> {
    const rb = new DapiRequestBuilder(rootUrl, OffersMultiService.FindMoreFlightsPath, 'post');

    if (params) {
      rb.query('hash', params.hash, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<MultiOfferList>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * findMoreFlights.
   *
   * Find more flight offers for given offer id and bound
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findMoreFlights$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findMoreFlights(rootUrl: string, params: {
    hash: string;
    body: FinnairSearchMoreFlightsParameters
  },
  context?: HttpContext

): Observable<MultiOfferList> {
    return this.findMoreFlights$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<MultiOfferList>) => r.body as MultiOfferList)
    );
  }

  /**
   * Path part for operation findMultiOfferList
   */
  static readonly FindMultiOfferListPath = '/api/multi/offerList';

  /**
   * findMultiOfferList.
   *
   * Find flight offers for multi city trip given origins, destinations, dates and travelers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findMultiOfferList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findMultiOfferList$Response(rootUrl: string, params: {
    body: FinnairSearchParameters
  },
  context?: HttpContext

): Observable<DapiHttpResponse<MultiOfferList>> {
    const rb = new DapiRequestBuilder(rootUrl, OffersMultiService.FindMultiOfferListPath, 'post');

    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as DapiHttpResponse<MultiOfferList>;
      }),
      catchErrorAsJson()
    );
  }

  /**
   * findMultiOfferList.
   *
   * Find flight offers for multi city trip given origins, destinations, dates and travelers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findMultiOfferList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findMultiOfferList(rootUrl: string, params: {
    body: FinnairSearchParameters
  },
  context?: HttpContext

): Observable<MultiOfferList> {
    return this.findMultiOfferList$Response(rootUrl, params, context).pipe(
      map((r: DapiHttpResponse<MultiOfferList>) => r.body as MultiOfferList)
    );
  }

}
