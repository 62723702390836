<button
  *ngIf="!useCustomTrigger"
  class="font-body-1 inherit-color block no-decoration focus-inset fill align-left"
  [attr.aria-controls]="'expand-component-' + id"
  [attr.aria-expanded]="isOpen$ | async"
  [disabled]="disabled"
  (click)="toggleDetails($event)"
>
  <ng-content select=".trigger"></ng-content>
</button>

<fin-expand-content
  [open$]="isOpen$"
  [initialValue]="initialValue"
  [id]="'expand-component-' + id"
  [expandContainerClass]="expandContainerClass"
  [expandContentClass]="expandContentClass"
  [ngClass]="expandContentClass"
  [targetHeightElement]="targetHeightElement"
>
  <ng-content select=".content"></ng-content>
</fin-expand-content>
