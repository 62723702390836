import { createAction, props, union } from '@ngrx/store';
import { RouterNavigatedAction } from '@ngrx/router-store';

import { UpgradeOfferState } from '../store.interface';

export const setUpgradeOffer = createAction(
  '[UpgradeOfferActions] SetUpgradeOffer',
  props<{ offer: UpgradeOfferState }>()
);
export const clearUpgradeOffer = createAction('[UpgradeOfferActions] ClearUpgradeOffer');

const all = union({ setUpgradeOffer, clearUpgradeOffer });

export type ActionsUnion = typeof all | RouterNavigatedAction;
