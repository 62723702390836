import { Component, Input, OnInit } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { CmsCollection, CmsOffer } from '@fcom/core-api';
import { PromotionItem, PromotionType, TripType } from '@fcom/common';
import { GtmService } from '@fcom/common/gtm';
import { getOfferWithAmDestination } from '@fcom/common/utils';
import { AmDestinationService } from '@fcom/destination-search/services/am-destination.service';
import { LocationRouteCffService } from '@fcom/core';

import { BlockOfferData } from '../../interfaces';
import { toPromoBlock } from '../../services';

@Component({
  selector: 'cms-offers-grid',
  template: `
    <fin-offer-grid
      [title]="content.teaserTitle"
      [offers$]="offers$"
      [cmsTags]="content.subjectTaxonomyTags || []"
      (flightHotelClicked)="openAmContinueModal($event)"
      [tripType]="tripType"
    ></fin-offer-grid>
    <fin-am-destination-continue-modal
      [open]="isAmContinueModalOpen"
      (closeModal)="closeAmContinueModal()"
      [bookUrl]="flightHotelUrl"
    ></fin-am-destination-continue-modal>
  `,
})
export class CmsOffersGridComponent implements OnInit {
  @Input()
  content: CmsCollection;

  offers$: Observable<BlockOfferData[]>;
  tripType: TripType;
  flightHotelUrl: string;
  isAmContinueModalOpen = false;

  constructor(
    private languageService: LanguageService,
    private gtmService: GtmService,
    private amDestinationService: AmDestinationService,
    private locationRouteCffService: LocationRouteCffService
  ) {}

  ngOnInit(): void {
    this.offers$ = this.languageService.lang.pipe(
      map((lang) => this.content.items.map((offer) => toPromoBlock(offer as CmsOffer, lang))),
      switchMap((offers) =>
        this.languageService.localeValue.includes('FI')
          ? getOfferWithAmDestination(
              offers,
              this.amDestinationService.amDestinationList(),
              this.locationRouteCffService
            )
          : of(offers)
      ),
      tap((offers) => {
        const promotionItems: PromotionItem[] = offers.map((offer, index) => ({
          position: `${index}`,
          id: 'top-destinations',
          product: offer.destination,
          type: 'static',
        }));

        this.gtmService.internalPromotion(promotionItems, PromotionType.VIEW);
      })
    );

    this.tripType = TripType[this.content.localSettings?.tripType?.toUpperCase()] ?? TripType.RETURN;
  }

  openAmContinueModal(flightHotelUrl): void {
    this.isAmContinueModalOpen = true;
    this.flightHotelUrl = flightHotelUrl;
  }

  closeAmContinueModal(): void {
    this.isAmContinueModalOpen = false;
  }
}
