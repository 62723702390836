/* eslint-disable no-underscore-dangle */
import { HttpParams } from '@angular/common/http';

import { createSelector, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { safeSelect, StateSelector } from '@fcom/core/selectors/selector-utils';
import { entrySet, isPresent } from '@fcom/core/utils';
import {
  IdAndHash,
  CartPartStatus,
  CartState,
  CartStatus,
  UpdatingCartPart,
  CartFareRules,
} from '@fcom/dapi/interfaces';
import { Category, FinnairCart, FinnairItineraryItemFlight } from '@fcom/dapi/api/models';
import { BookingFeatureState } from '@fcom/common/interfaces/booking';
import { mapFlightsFromCartData } from '@fcom/common/utils/map-flights-from-cart-data';

import { bookingFeatureState } from './booking-feature-state.selector';

export const cartUrlToIdAndHash = (url: string): IdAndHash => {
  const [urlPath, queryPart] = url.split('?');
  const hash = new HttpParams({ fromString: queryPart }).get('hash');
  const id = urlPath.split('/').filter(Boolean).slice(-1)[0];
  return { id, hash };
};

const _cartState = createSelector(bookingFeatureState, (_bfs: BookingFeatureState) => _bfs?.cart);
const _cartUrl = createSelector(_cartState, (_cs: CartState) => _cs?.cartUrl);
export const cartDataSelector = createSelector(_cartState, (_cs: CartState) => _cs?.cartData);
export const cartStatusSelector = createSelector(_cartState, (_cs: CartState) => _cs?.cartStatus);
const _cartIdAndHash = createSelector(_cartUrl, (url) => (url ? cartUrlToIdAndHash(url) : undefined));
const _cartFirstFlightDepartureDate = createSelector(cartDataSelector, (cd) => cd?.bounds[0]?.departure.dateTime);
const _cartLastFlightArrivalDate = createSelector(
  cartDataSelector,
  (cd) => (cd?.bounds[1] || cd?.bounds[0])?.arrival.dateTime
);
const _cartFlights = createSelector(cartDataSelector, (cd): FinnairItineraryItemFlight[] => mapFlightsFromCartData(cd));
const _cartBoundsTitle = createSelector(cartDataSelector, (cd) => {
  if (!cd) {
    return undefined;
  }
  if (!isPresent(cd.bounds[1])) {
    return `${cd.bounds[0].departure.locationCode}–${cd.bounds[0].arrival.locationCode}`;
  }
  // TODO: this is actually wrong for open jaw trips
  return `${cd.bounds[0].departure.locationCode}–${cd.bounds[0].arrival.locationCode}–${cd.bounds[0].departure.locationCode}`;
});
const _cartDirty = createSelector(_cartState, (_cs: CartState) => !!_cs?.dirty);
const _acceptTerms = createSelector(_cartState, (_cs: CartState) => !!_cs?.acceptTerms);

export const cartState = (): StateSelector<BookingFeatureState, CartState> => safeSelect(_cartState);
export const cartUrl = (): StateSelector<BookingFeatureState, string> => safeSelect(_cartUrl);
export const cartData = (): StateSelector<BookingFeatureState, FinnairCart> => safeSelect(cartDataSelector);
export const cartStatus = (): StateSelector<BookingFeatureState, CartStatus> => safeSelect(cartStatusSelector);

/**
 * Selects current cart id and hash.
 * @return [currentCartId, currentCartHash]
 */
export const cartIdAndHash = (): StateSelector<BookingFeatureState, IdAndHash> => safeSelect(_cartIdAndHash);

/**
 * Selects the first flight's departure date time as string from the Cart data.
 * Emits only after cart data is present.
 */
export const cartFirstFlightDepartureDate = (): StateSelector<BookingFeatureState, string> =>
  safeSelect(_cartFirstFlightDepartureDate);

/**
 * Selects the last flight's arrival date time as string from the Cart data.
 * Emits only after cart data is present.
 */
export const cartLastFlightArrivalDate = (): StateSelector<BookingFeatureState, string> =>
  safeSelect(_cartLastFlightArrivalDate);

/**
 * Selects all flight's from the Cart data.
 * Emits only after cart data is present.
 */
export const cartFlights = (): StateSelector<BookingFeatureState, FinnairItineraryItemFlight[]> =>
  safeSelect(_cartFlights);

export const cartFlight = (flightId: string): StateSelector<BookingFeatureState, FinnairItineraryItemFlight> =>
  safeSelect(
    createSelector(_cartFlights, (_flights: FinnairItineraryItemFlight[]) =>
      _flights.find((flight) => flight.id === flightId)
    )
  );

const _cartPartStatusForCategory = (category: Category) =>
  createSelector(
    createSelector(_cartState, (_cs: CartState) => _cs?.isUpdating),
    createSelector(_cartIdAndHash, (c) => c?.id),
    (updating: UpdatingCartPart = {}, currentCartId: string) => {
      const updates: CartPartStatus[] = entrySet(updating)
        .filter((entry) => entry.key.indexOf(category) !== -1 && entry.key.indexOf(currentCartId) !== -1)
        .map((e) => e.value);
      return updates.length > 0
        ? updates.reduce(
            (current, update) =>
              update === CartPartStatus.ERROR
                ? update
                : current !== CartPartStatus.ERROR && update === CartPartStatus.UPDATING
                  ? update
                  : current,
            CartPartStatus.OK
          )
        : CartPartStatus.OK;
    }
  );

export const cartPartStatusForCategory =
  (category: Category) =>
  (state$: Observable<BookingFeatureState>): Observable<CartPartStatus> =>
    state$.pipe(select(_cartPartStatusForCategory(category))).pipe(startWith(CartPartStatus.OK));

export const cartBoundsTitle = (): StateSelector<BookingFeatureState, string> => safeSelect(_cartBoundsTitle);

/**
 * Is cart in dirty state
 * When in dirty state we should do ancillary updates to backend otherwise we could skip them
 */
export const isCartDirty = (): StateSelector<BookingFeatureState, boolean> => safeSelect(_cartDirty);

export const acceptTerms = (): StateSelector<BookingFeatureState, boolean> => safeSelect(_acceptTerms);

const _fareRules = createSelector(_cartState, (_cs: CartState) => _cs?.fareRules);
export const fareRules = (): StateSelector<BookingFeatureState, CartFareRules> => select(_fareRules);
