import { Type } from '@angular/core';
import { Route } from '@angular/router';

import { Step } from './../interfaces';
import { ClientOnlyComponent } from '../components/helpers/client-only.component';

export function createRoute<T>(
  step: Step,
  componentType: Type<T>,
  onUnload: any = [],
  canActivateGuard: any = [],
  resolveGuard: any = []
): Route {
  return {
    path: step.path,
    component: step.data?.server ? componentType : ClientOnlyComponent,
    data: {
      componentType,
      ...step.data,
    },
    canActivate: canActivateGuard,
    resolve: resolveGuard,
    canDeactivate: onUnload,
  };
}
