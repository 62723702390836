<section class="flex flex--column flex--space-between grow timeline">
  <div class="flex flex-1">
    <div class="left flex--nogrow flex flex--column flex--middle ms-xsmall-r">
      <fcom-icon class="icon-medium" [name]="departureIcon"></fcom-icon>
      <div class="flex-1 border-left ms-xxsmall-y"></div>
    </div>
    <div *ngIf="departure" class="overflow-hidden truncate mr-xsmall-b">
      <ng-container
        *ngTemplateOutlet="airportText; context: { airport: departure, labelKey: 'departure' }"
      ></ng-container>
    </div>
  </div>
  <div class="flex">
    <div class="left flex--nogrow flex flex--column flex--middle ms-xsmall-r">
      <fcom-icon class="icon-medium" [name]="arrivalIcon"></fcom-icon>
    </div>
    <div *ngIf="arrival" class="overflow-hidden truncate">
      <ng-container *ngTemplateOutlet="airportText; context: { airport: arrival, labelKey: 'arrival' }"></ng-container>
    </div>
  </div>
</section>

<ng-template #airportText let-airport="airport" let-labelKey="labelKey">
  <span class="is-vishidden">{{ labelKey | finLocalization }}</span>
  <span class="medium-type ms-xxsmall-r font-body-1">{{ airport.dateTime }}</span>
  <span class="ms-xxsmall-r font-body-1 overflow-hidden truncate">
    {{ airport.city }}
  </span>
  <span class="nordic-blue-900-text font-body-2 df-desktop-down">
    <span class="airport flex-1 overflow-hidden truncate">{{ airport.airport }}<span>&nbsp;</span></span>
    <span class="flex--shrink0">({{ airport.airportCode }})</span>
  </span>
</ng-template>
