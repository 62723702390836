import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from '@fcom/common/pipes';
import { ComponentsModule } from '@fcom/common/components';
import { UiTranslateModule } from '@fcom/ui-translate';
import { UiComponentsModule } from '@fcom/ui-components';
import { DirectiveModule } from '@fcom/common';

import { TravelRestrictionsComponent } from './travel-restrictions.component';

@NgModule({
  imports: [CommonModule, PipesModule, UiTranslateModule, UiComponentsModule, ComponentsModule, DirectiveModule],
  declarations: [TravelRestrictionsComponent],
  exports: [TravelRestrictionsComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TravelRestrictionsModule {}
