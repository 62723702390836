import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IconLibrary } from '@finnairoyj/fcom-ui-styles/enums';

import { type IconName } from '../icons';
import { TagTheme } from './interfaces';

@Component({
  selector: 'fcom-tag-button',
  styleUrls: ['tag-button.component.scss'],
  templateUrl: 'tag-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagButtonComponent {
  public readonly TagTheme = TagTheme;

  @Input()
  text: string;

  @Input()
  title: string;

  @Input()
  iconName: IconName;

  @Input()
  iconCategory: IconLibrary = IconLibrary.SVG_LIBRARY;

  @Input()
  theme: TagTheme = TagTheme.DEFAULT;

  @Input()
  disabled = false;

  @Output()
  tagClicked: EventEmitter<Event> = new EventEmitter<Event>();

  @Output()
  blur: EventEmitter<Event> = new EventEmitter<Event>();
}
