import { Component, Input, OnInit, OnDestroy, HostListener } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';

import { Observable, Subscription } from 'rxjs';

import { unsubscribe } from '@fcom/core/utils';

const KEYCODE_E = 69;

@Component({
  selector: 'fin-form-input-number',
  templateUrl: 'form-input-number.component.html',
})
export class FormInputNumberComponent implements OnInit, OnDestroy {
  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  controlName: string;

  @Input()
  translationPrefix: string;

  @Input()
  placeHolder: string = null;

  @Input()
  isSensitive = false;

  @Input()
  id: string;

  @Input()
  labelPrefix: string;

  @Input()
  labelSuffix: string;

  @Input()
  min: number;

  @Input()
  max: number;

  @Input()
  step: number;

  isRequired = false;
  label$: Observable<string>;
  idOrControlName: string;
  field: AbstractControl;
  hasFocus = false;

  private subscriptions: Subscription = new Subscription();

  // Number input allows e character as an exponential which would be confusing for users
  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (this.hasFocus && event.keyCode === KEYCODE_E) {
      event.preventDefault();
    }
  }

  get required() {
    return this.ctrlField.errors?.required;
  }

  get ctrlField() {
    return this.parentForm.get(this.controlName);
  }

  ngOnInit() {
    this.field = this.ctrlField;
    this.isRequired = this.required;
    this.idOrControlName = this.id || this.controlName;

    this.subscriptions.add(
      this.field.statusChanges.subscribe(() => {
        if (this.field.value) {
          return;
        }
        this.isRequired = this.required;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions = unsubscribe(this.subscriptions);
  }
}
