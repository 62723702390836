import { Component, Input } from '@angular/core';

import { LanguageService } from '@fcom/ui-translate';
import { ModalButtons } from '@fcom/ui-components';
import { ConfigService } from '@fcom/core/services';

@Component({
  selector: 'fin-content-with-modal',
  styleUrls: ['content-with-modal.component.scss'],
  templateUrl: 'content-with-modal.component.html',
})
export class ContentWithModalComponent {
  @Input() content: string;

  public readonly ModalButtons = ModalButtons;

  modalOpen = false;
  cmsUrl: string;

  constructor(
    private languageService: LanguageService,
    private configService: ConfigService
  ) {}

  openModalIfLink(event): void {
    const target = event.target;

    if (
      target &&
      typeof target.tagName === 'string' &&
      target.tagName.toLowerCase() === 'a' &&
      target.getAttribute('data-contentid')
    ) {
      const contentId: string = target.getAttribute('data-contentid');
      this.cmsUrl = `${this.configService.cmsUrl}/service/json/v1/ids/${this.languageService.langValue}/${contentId}`;
      this.modalOpen = true;
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
