export const getPreviewCmsUrl = (environment: string) => {
  switch (environment) {
    case 'fake':
      return 'http://localhost.finnair.com:5000/fake-cms';
    case 'dev_preview':
    case 'test_preview':
      return 'https://test-preview.coremedia.dev.app.finnair.com';
    case 'preprod_preview':
      return 'https://preprod-preview.coremedia.preprod.app.finnair.com';
    case 'staging_preview':
    case 'prod_preview':
      return 'https://prod-preview.coremedia.prod.app.finnair.com';
    default:
      return undefined;
  }
};
