import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { CmsCollection, CmsContent, CmsTarget } from '@fcom/core-api';
import { isNotEmpty, safeMap } from '@fcom/core/utils';

/**
 * Maps links with url in root object to use 'target' object instead
 * @param items
 * @returns copy of items with url within 'target'
 */
const mapUrlToTarget = (items: CmsTarget[] | CmsContent[]) => {
  const formattedItems: CmsContent[] = [];
  items.forEach((item) => {
    if (item.target?.url) {
      formattedItems.push(item);
    } else if (item.url) {
      const formattedItem = { ...item };
      formattedItem.target = {
        url: item.url,
        external: item.external,
      };
      formattedItems.push(formattedItem);
    }
  });
  return formattedItems;
};

@Component({
  selector: 'cms-link-list-with-icons',
  templateUrl: './cms-link-list-with-icons.component.html',
  styleUrls: ['./cms-link-list-with-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsLinkListWithIconsComponent implements OnInit {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  content$: Observable<CmsCollection>;
  items$: Observable<CmsContent[]>;

  ngOnInit(): void {
    this.items$ = this.content$.pipe(
      map((content: CmsCollection) => safeMap(content, (c: CmsCollection) => c.items)),
      map(mapUrlToTarget),
      filter(isNotEmpty),
      distinctUntilChanged()
    );
  }
}
