<span *ngIf="oldEndpointDateTime" class="grey-700-text itinerary-endpoint-time strike-through mr-xxsmall-r">
  {{ oldEndpointDateTime | finDateFormat: 'WEEKDAY_SHORT' }}
  {{ oldEndpointDateTime | finTime }}
</span>
<span class="bold-type itinerary-endpoint-time">
  {{ endpoint.dateTime | finDateFormat: 'WEEKDAY_SHORT' }}
  {{ endpoint.dateTime | finTime }}
</span>
<span
  *ngIf="locations && oldEndpointLocationCode"
  class="grey-700-text itinerary-endpoint-location strike-through mr-xxsmall-r"
>
  {{ locations[oldEndpointLocationCode]?.name }}
  ({{ oldEndpointLocationCode }}),
  {{ locations[oldEndpointLocationCode]?.cityName }}
</span>
<span *ngIf="locations && endpoint" class="medium-type itinerary-endpoint-location">
  {{ locations[endpoint?.locationCode]?.name }}
  ({{ endpoint.locationCode }}),
  {{ locations[endpoint?.locationCode]?.cityName }}
</span>
