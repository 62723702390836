import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ExternalLink } from '../../interfaces';

@Component({
  selector: 'cms-external-link',
  template: '<fin-external-link [link$]="link$"></fin-external-link>',
})
export class CmsExternalLinkComponent implements OnInit {
  @Input()
  content$: Observable<any>;

  link$: Observable<ExternalLink>;

  ngOnInit(): void {
    this.link$ = this.content$.pipe(
      map((data) => ({
        url: data.url,
        text: data.teaserTitle,
        target: '_blank',
      }))
    );
  }
}
