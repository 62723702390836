<div [ngClass]="'wrapper flex flex--row ' + content.subjectTaxonomyTags" *ngIf="content$ | async as content">
  <cms-heading-tag [level]="headerLevel" extraClasses="ps-small-b nordic-blue-900-text title">
    {{ content.teaserTitle }}
  </cms-heading-tag>
  <div class="font-body-1 nordic-blue-900-text text">
    <div [innerHTML]="content.teaserText | finSafe: 'html'"></div>
    <a
      *ngIf="content.target?.url && content.linkText"
      [finLink]="content.target.url"
      class="flex flex--middle nordic-blue-900-text bold-type no-underline pr-xxsmall-t font-body-1 underline-hover"
    >
      {{ content.linkText }}
      <fcom-icon
        class="editorial-category-link-icon nordic-blue-900-text icon-small"
        [name]="SvgLibraryIcon.ARROW_RIGHT"
      ></fcom-icon>
    </a>
  </div>
</div>
