import { Observable, pipe } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { createFeatureSelector, createSelector, select } from '@ngrx/store';

import { LoginAccessToken, LoginPhase, LoginState, LoginStatus, Profile, ProfileType } from '@fcom/core-api/login';
import { finShare, safeMap } from '@fcom/rx';

import { BaseState } from '../interfaces';
import { safeSelect, StateSelector } from './selector-utils';

const ACCESS_TOKEN: keyof LoginState = 'accessToken';
const TOKEN: keyof LoginAccessToken = 'token';

export const loginSelector = createFeatureSelector<LoginState>('login');
export const loginStatusSelector = createSelector(loginSelector, (l) => l.loginStatus);
export const profileTypeSelector = createSelector(loginSelector, (l) => l?.type || undefined);
export const profileSelector = createSelector(loginSelector, (l) => l.profile);
export const tokenSelector = createSelector(loginSelector, (l) => {
  return l?.[ACCESS_TOKEN]?.[TOKEN] || undefined;
});
export const loginPhaseSelector = createSelector(loginSelector, (l) => l?.loginPhase || undefined);
export const loginErrorSelector = createSelector(loginPhaseSelector, (phase) => !!phase?.hasError);

export const loginState = (): StateSelector<BaseState, LoginState> => safeSelect(loginSelector);
export const loginStatus = (): StateSelector<BaseState, LoginStatus> =>
  pipe(safeSelect(loginStatusSelector), distinctUntilChanged());

export const profileType = (): StateSelector<BaseState, ProfileType> => select(profileTypeSelector);
export const loginPhase = (): StateSelector<BaseState, LoginPhase> => select(loginPhaseSelector);
export const loginHasError = (): StateSelector<BaseState, boolean> => select(loginErrorSelector);

/** Profile, waits until we have one. Waits until user is logged in. See also profileOrUndefinedIfNotLoggedIn */
export const profile = (): StateSelector<BaseState, Profile> =>
  pipe(safeSelect(profileSelector), distinctUntilChanged());

/** Profile if logged in, undefined if not ready yet or if not logged in */
export const profileOrNot = (): StateSelector<BaseState, Profile | undefined> => select(profileSelector);

/** Token from the LoginAccessToken. Can be undefined. */
export const loginToken = (): StateSelector<BaseState, string> => select(tokenSelector);

/**
 * Profile, waits over the INITIAL and PENDING states to know if we have a profile,
 * then returns either the profile or undefined
 */
export const profileOrUndefinedIfNotLoggedIn =
  () =>
  (state$: Observable<BaseState>): Observable<Profile | undefined> =>
    state$.pipe(
      loginState(),
      filter(
        (l: LoginState) =>
          l.loginStatus === LoginStatus.LOGGED_IN ||
          l.loginStatus === LoginStatus.NOT_LOGGED_IN ||
          l.loginStatus === LoginStatus.ERROR
      ),
      safeMap((l: LoginState) => l.profile), // l.profile is undefined if not logged in
      finShare()
    );

/**
 * Waits until the profile is available, if logged in and has a first name and last name they are returned otherwise null
 */
export const profileName =
  () =>
  (state$: Observable<BaseState>): Observable<string | null> =>
    state$.pipe(
      profileOrNot(),
      filter(Boolean),
      map((profileData: Profile) => {
        if (profileData.firstname && profileData.lastname) {
          return `${profileData.firstname} ${profileData.lastname}`;
        }

        return null;
      })
    );

export const loginIsOpen =
  () =>
  (state$: Observable<BaseState>): Observable<boolean> =>
    state$.pipe(
      loginPhase(),
      map((phase) => !!phase?.step),
      finShare(),
      distinctUntilChanged()
    );
