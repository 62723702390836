import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { UiTranslateModule } from '@fcom/ui-translate';
import { IconsModule } from '@fcom/ui-components';

import { DirectiveModule } from '../directives/directive.module';
import { PipesModule } from '../pipes';
import { InputFieldComponent } from './input-field.component';
import { SelectFieldComponent } from './select-field.component';
import { FormFieldsComponent } from './form-fields.component';
import { FormGroupComponent } from './form-group.component';
import { InputCheckboxComponent } from './input-checkbox.component';
import { SimpleSelectFieldComponent } from './simple-select-field.component';
import { PlusMinusSelectorComponent } from './plus-minus-selector/plus-minus-selector.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    DirectiveModule,
    UiTranslateModule,
    IconsModule,
  ],
  declarations: [
    InputCheckboxComponent,
    InputFieldComponent,
    SelectFieldComponent,
    SimpleSelectFieldComponent,
    FormFieldsComponent,
    FormGroupComponent,
    PlusMinusSelectorComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    InputCheckboxComponent,
    InputFieldComponent,
    SelectFieldComponent,
    SimpleSelectFieldComponent,
    FormFieldsComponent,
    FormGroupComponent,
    PlusMinusSelectorComponent,
  ],
})
export class FinFormsModule {}
