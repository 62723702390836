export enum LayoutVariant {
  A330 = 'A330_300_CONFIG1',
  A350 = 'A350_CONFIG1',
}

export enum SceneVariant {
  economy = 'economy',
  ecopremium = 'ecopremium',
  business = 'business',
  galley = 'galley',
  main = 'main',
}

export interface SeatMapVrConfig {
  destinationSelector: string;
  resource_cdn: string;
  ambientSound?: string;
  lang?: string;
  i18n?: Record<string, string>;
  error?: (code: string, desc: string) => void;
  onUserChangeScene?: () => void;
  onUserCloseViewer?: () => void;
  onUserMaximizeViewer?: () => void;
  onUserMove360?: () => void;
  onUserOpenInfo?: () => void;
  onUserMusicOn?: () => void;
  onUserMusicOff?: () => void;
  onUserLightsOn?: () => void;
  onUserLightsOff?: () => void;
}

export interface SeatMapVrSceneConfig {
  layout: LayoutVariant;
  scene: SceneVariant;
  fullScreen?: boolean;
  mode?: number;
}

export interface ISeatMapVr {
  config: (config: SeatMapVrConfig) => void;
  selectScene: (scene: SeatMapVrSceneConfig) => void;
  isInitialized: () => boolean;
  destroy: () => void;
  init: () => void;
}
