import { Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CmsTarget } from '@fcom/core-api';

import { toLinkData } from '../../services';

@Component({
  selector: 'cms-cta-with-image-and-text',
  template: `
    <fin-cta-with-image-and-text
      [target$]="cmsTarget$"
      [teaserTitle$]="content$ | finMap: 'teaserTitle'"
      [teaserText$]="content$ | finMap: 'teaserText'"
      [iconUrl$]="content$ | finMap: 'iconUrl'"
    >
    </fin-cta-with-image-and-text>
  `,
})
export class CmsCtaWithImageAndTextComponent implements OnInit {
  @Input()
  content$: Observable<any>;

  cmsTarget$: Observable<CmsTarget>;

  ngOnInit(): void {
    this.cmsTarget$ = this.content$.pipe(map((d) => toLinkData(d.target)));
  }
}
