import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';

import { LocationsPipeModule, LocationNamePipe } from './pipes';
import { locationsReducer } from './store/reducers';
import { LOCATIONS_FEATURE_KEY } from './constants';

@NgModule({
  imports: [StoreModule.forFeature(LOCATIONS_FEATURE_KEY, locationsReducer), LocationsPipeModule],
  declarations: [],
  exports: [LocationNamePipe],
  providers: [],
})
export class LocationsModule {}
