import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, Optional } from '@angular/core';

import { SentryLogger } from '@fcom/core/services';
import { isTruthy } from '@fcom/core/utils/utils';

import { ResponsiveImage, ResponsiveImageData, SrcSet, StaticImage } from '../interfaces';
import { FALLBACK_WIDTH, IMAGE_WIDTHS, THUMBNAIL_WIDTH } from '../constants';
import { AspectRatios, FetchPriorityEnum } from '../enums';
import { aspectRatio, buildUrl, getWidestImageWidth, isStaticImage } from '../utils';

/**
 * Displays an image with lazyloading and a blurred placeholder.
 * This component uses ng-lazyload-image to load the images.
 * If a ResponsiveImage is passed, then the component selects the image
 * with the corresponding ratio and uses a srcset to adjust the image width
 * based on screensize.
 *
 * @example
 * <fcom-image
 *   [alt]="alt
 *   [imageData]="imageData"
 *   [ratio]="ratio">
 * </fcom-image>
 */
@Component({
  selector: 'fcom-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent implements OnChanges {
  /**
   * text for the images alt attribute.
   */
  @Input() alt = '';

  /**
   * Stretches image to fill the container.
   */
  @Input() fillParent = false;

  /**
   * Image data to use.
   * Can be ResponsiveImage data in which case the ratio to use should be set.
   * Or this can be staticImage data in which case no ratio is required.
   */
  @Input() imageData: ResponsiveImage | StaticImage;

  /**
   * The preferred aspect ratio of the image. Defaults to 16x9
   */
  @Input() ratio: keyof typeof AspectRatios = '16x9';

  /**
   * Scale the picture size with transform or not. Defaults to 1.1 scale.
   */
  @Input() scale = true;

  @Input()
  maxWidthImage = IMAGE_WIDTHS[0];

  /**
   * Define fetch priority of the image
   */
  @Input() fetchPriority?: keyof typeof FetchPriorityEnum;

  thumbnailSrc: string;
  fallbackSrc: string;
  srcSet: SrcSet;
  hasAspectRatio: boolean;
  customAspectRatio?: string;

  constructor(
    private cdr: ChangeDetectorRef,
    @Optional() private logger: SentryLogger
  ) {}

  ngOnChanges(): void {
    if (!this.imageData || this.invalidResponsiveImageRatio(this.imageData, this.ratio)) {
      this.logger?.error(
        'Both imageData and ratio must be set if imageData is ResponsiveImage. If ratio is ORIGINAL, the properties data, width and height are required.'
      );
      return;
    }

    const { fallbackSrc, thumbnailSrc, srcSet, hasAspectRatio, customAspectRatio } = this.mapToResponsiveImage(
      this.imageData,
      AspectRatios[this.ratio]
    );

    this.hasAspectRatio = hasAspectRatio;
    this.thumbnailSrc = thumbnailSrc;
    this.fallbackSrc = fallbackSrc;
    this.customAspectRatio = !this.fillParent ? customAspectRatio : undefined;
    this.srcSet = srcSet;
    this.cdr.markForCheck();
  }

  trackByFn(_index: number, item: { width: number; src: string }): string {
    return item.src;
  }

  /**
   * Process the ImageData and ratio to get the image data to pass to the template
   */
  private mapToResponsiveImage(pic: ResponsiveImage | StaticImage, aspect: AspectRatios): ResponsiveImageData {
    if (isStaticImage(pic)) {
      return {
        fallbackSrc: pic.fullSizeUrl,
        thumbnailSrc: pic.thumbnailSrc,
        srcSet: [],
        hasAspectRatio: false,
      };
    }

    const images =
      aspect === AspectRatios.ORIGINAL
        ? { [FALLBACK_WIDTH]: buildUrl(pic.data, { impolicy: 'crop', width: pic.width, height: pic.height }) }
        : pic.responsiveImageLinksData[aspect];

    const widestWidth = aspect === AspectRatios.ORIGINAL ? FALLBACK_WIDTH : getWidestImageWidth(images);

    const srcSet = IMAGE_WIDTHS.filter((item) => item <= this.maxWidthImage).map((width) => {
      return {
        width,
        src: buildUrl(images[widestWidth], { imwidth: width }),
      };
    });

    const disableCropping = isTruthy(pic.disableCropping) && !!pic.width && !!pic.height;

    const fallbackSrc = buildUrl(images[FALLBACK_WIDTH], { imwidth: FALLBACK_WIDTH });

    return {
      fallbackSrc,
      thumbnailSrc: buildUrl(images[FALLBACK_WIDTH], { imwidth: THUMBNAIL_WIDTH }),
      srcSet,
      hasAspectRatio:
        aspect === AspectRatios.ORIGINAL || disableCropping ? false : fallbackSrc.includes('impolicy=crop'),
      customAspectRatio: disableCropping ? aspectRatio(pic.width, pic.height) : undefined,
    };
  }

  /**
   * Checks if the pic is invalid as a ResponsiveImage type.
   *
   * This checks that the pic is a ResponsiveImage type,
   * has a ratio and the ResponsiveImage can handle the requested ratio
   */
  private invalidResponsiveImageRatio(pic: ResponsiveImage | StaticImage, ratio: keyof typeof AspectRatios): boolean {
    if (isStaticImage(pic)) {
      return false;
    }

    return (
      !ratio ||
      !(
        (ratio !== 'ORIGINAL' && pic.responsiveImageLinksData && pic.responsiveImageLinksData[AspectRatios[ratio]]) ||
        (ratio === 'ORIGINAL' && pic.data && pic.width && pic.height)
      )
    );
  }
}
