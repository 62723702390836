<div
  *ngIf="fareFamily$ | async as fareFamily"
  class="fare-family border rounded-large overflow-hidden white-bg"
  [class.selected]="fareFamily.selected"
  [class.disabled]="fareFamily.disabled"
>
  <label
    class="flex flex--column nordic-blue-25-bg"
    [style.--brand-color]="fareFamily.benefits.brandColor"
    [attr.aria-label]="
      'ticketSelection.aria.fareFamily.fareFamilyOption'
        | finLocalization
          : {
              fareFamily: fareFamily.benefits.brandName,
              price:
                {
                  amount: fareFamily.totalPrice,
                  currencyCode: currencyCode,
                } | finPrice,
              index: aria.index + 1,
              totalCount: aria.count,
            }
    "
    [attr.data-testid]="'fare-family-label-' + fareFamily.fareFamilyCode"
  >
    <input
      [attr.data-testid]="'fare-family-checkbox-' + fareFamily.fareFamilyCode"
      (change)="checkboxChange($event)"
      type="checkbox"
      class="is-vishidden"
      [checked]="fareFamily.selected"
      [disabled]="fareFamily.disabled"
    />

    <fcom-tag
      *ngIf="fareFamily.badgeType === FareFamilyBadgeType.POPULAR"
      [theme]="TagTheme.POPULAR"
      [roundingStyle]="TagRoundingStyle.ONLY_BOTTOM_LEFT"
      class="absolute popular-badge"
      [attr.data-testid]="'badge-tag'"
    >
      {{ 'ticketSelection.fareFamily.ribbonText' | finLocalization }}
    </fcom-tag>

    <div class="ps-small" aria-hidden="true">
      <fcom-icon
        class="white-bg circle checkbox-icon"
        [dataTestId]="'fare-family-icon-' + fareFamily.fareFamilyCode"
        [name]="
          fareFamily.selected ? SvgLibraryIcon.CHECKMARK_BUTTON_SELECTED : SvgLibraryIcon.CHECKMARK_BUTTON_UNSELECTED
        "
      ></fcom-icon>
      <div class="fare-family-name font-body-1 medium-type nordic-blue-900-text ms-xsmall-t">
        {{ fareFamily.benefits.brandName }}
      </div>
      <div class="fare-family-price font-heading-4" [class.with-original-price]="fareFamily.originalTotalPrice">
        <span class="total-price-text">
          {{
            {
              amount: fareFamily.totalPrice,
              currencyCode: currencyCode,
            } | finPrice
          }}
        </span>
        <span *ngIf="fareFamily.originalTotalPrice" class="strike-through font-body-2 light-type">
          {{
            {
              amount: fareFamily.originalTotalPrice,
              currencyCode: currencyCode,
            } | finPrice
          }}
        </span>
      </div>
    </div>
    <div class="fare-family-brand-color-bar ps-xxsmall" [style.background-color]="fareFamily.benefits.brandColor"></div>
  </label>
  <div class="fare-family-benefits ps-xxsmall-x">
    <ng-container *ngIf="isNotAward$ | async">
      <fcom-tag class="ms-small-l ms-small-t">
        <fcom-icon class="ms-xxsmall-r icon-small" [name]="SvgLibraryIcon.AVIOS_PLECTRUM"></fcom-icon>
        <span *ngIf="fareFamily.totalAviosAccrual">
          {{ 'avios.collectPointsTag' | finLocalization: { points: fareFamily.totalAviosAccrual?.toLocaleString() } }}
        </span>
        <span *ngIf="!fareFamily.totalAviosAccrual">
          {{ 'avios.collectUndefinedPoints' | finLocalization }}
        </span>
      </fcom-tag>
    </ng-container>

    <div class="benefits-list flex-1 benefits ms-small-b">
      <fin-benefit
        class="block ps-small-x ps-xxsmall-y"
        *ngFor="let benefit of fareFamilyBenefit$ | async"
        [benefit]="benefit"
      ></fin-benefit>
    </div>
  </div>
</div>
