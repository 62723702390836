<ng-template #content>
  {{ [translationPrefix, 'label'].join('.') | finLocalization }}
  <span *ngIf="required"> *</span>
</ng-template>

<ng-template #contentWithLink>
  <div [innerHtml]="innerHtml | finSafe: 'html'" class="font-body-1"></div>
</ng-template>

<label [formGroup]="group" [class]="containerClass" [attr.data-testid]="labelDataTestId">
  <input
    #inputElement
    type="checkbox"
    [attr.name]="name"
    [formControlName]="modelName"
    (focus)="focus.emit([translationPrefix, 'info'].join('.'))"
    (blur)="blur.emit([translationPrefix, 'info'].join('.'))"
    (change)="checkboxChange.emit(inputElement.checked)"
    class="is-vishidden"
  />
  <span
    [ngSwitch]="group.get(modelName).value === true"
    class="checkbox-icon nordic-blue-900-text"
    aria-hidden="true"
    [class.block]="block"
  >
    <fcom-icon
      *ngSwitchCase="true"
      class="icon-medium"
      [dataTestId]="'icon-' + SvgLibraryIcon.CHECKBOX_SELECTED"
      [name]="SvgLibraryIcon.CHECKBOX_SELECTED"
    ></fcom-icon>
    <fcom-icon
      *ngSwitchDefault
      class="icon-medium"
      [dataTestId]="'icon-' + SvgLibraryIcon.CHECKBOX_UNSELECTED"
      [name]="SvgLibraryIcon.CHECKBOX_UNSELECTED"
    ></fcom-icon>
  </span>
  <span [ngSwitch]="useLabelContent">
    <ng-content *ngSwitchCase="true"></ng-content>
    <span *ngSwitchDefault [class]="labelClass">
      <div *ngIf="!innerHtml; then content; else contentWithLink"></div>
    </span>
  </span>
</label>

<div [class]="errorClasses" [class.error-label]="true" *ngIf="errorKey">
  <span class="error-900-text font-body-2">
    {{ errorKey | finLocalization }}
  </span>
</div>
