import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { EMPTY, Observable, PartialObserver, Subject } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

import { CartState, IdAndHash } from '@fcom/dapi';
import { SeatMapStatus } from '@fcom/common/interfaces/seat-map.interface';
import { snapshot } from '@fcom/rx';
import { noop } from '@fcom/core/utils';
import { FinnairCart, FinnairServiceRequestItem, SubCategory } from '@fcom/dapi/api/models';
import { BookingAppState, PaxDetailsState } from '@fcom/common/interfaces/booking';
import { QueueService } from '@fcom/common-booking/services/queue.service';
import { seatMapForFlightStatus } from '@fcom/common-booking/store/selectors';

import { CartActions } from '../store/actions';
import { AncillaryService } from '../modules/ancillaries/services/ancillary.service';
import { getUpdateId } from '../utils';
import { cartFlights, cartIdAndHash, cartState, cartUrl } from '../store/selectors';
import { BookingCartService } from './booking-cart.service';

@Injectable()
export class BookingQueueService {
  private cancelCartCreate$ = new Subject<void>();
  private cancelCartUpdate$ = new Subject<void>();
  private cancelPaxUpdate$ = new Subject<void>();
  private cancelSeatMapFetch$ = new Subject<void>();

  constructor(
    private cartService: BookingCartService,
    private ancillaryService: AncillaryService,
    private store$: Store<BookingAppState>,
    private queueService: QueueService
  ) {}

  queueCartCreate(selectedOfferId: string, locale: string, offersHash: string): void {
    // TODO: We should remember old offer selected so we can skip new cart creation when using the same offer.
    // For now we just create the cart again when offer is selected.
    const queueId = [selectedOfferId, locale, offersHash].join('_');
    this.queueService.startQueue(queueId);

    // We need to cancel any pax updates to previous carts
    this.cancelCartCreate$.next();
    this.cancelCartUpdate$.next();
    this.cancelPaxUpdate$.next();
    this.queueService.cancelServiceAvailability$.next();
    this.queueService.cancelPayment.next();

    const cancelStream$ = this.queueService.toCancelEvent(this.cancelCartCreate$);
    this.queueService.sendToQueue(
      'createCart',
      this.cartService.triggerCreateCart(selectedOfferId, locale, offersHash, cancelStream$),
      cancelStream$
    );
  }

  queueCartCreateByBounds(selectedAirBoundIds: string[], locale: string, boundsHash: string): void {
    // TODO: We should remember old offer selected so we can skip new cart creation when using the same offer.
    // For now we just create the cart again when offer is selected.
    const queueId = [selectedAirBoundIds.join('_'), locale, boundsHash].join('_');
    this.queueService.startQueue(queueId);

    // We need to cancel any pax updates to previous carts
    this.cancelCartCreate$.next();
    this.cancelCartUpdate$.next();
    this.cancelPaxUpdate$.next();
    this.queueService.cancelServiceAvailability$.next();
    this.queueService.cancelPayment.next();

    const cancelStream$ = this.queueService.toCancelEvent(this.cancelCartCreate$);
    this.queueService.sendToQueue(
      'createCart',
      this.cartService.triggerCreateCartByBounds(selectedAirBoundIds, locale, boundsHash, cancelStream$),
      cancelStream$
    );
  }

  queuePassengersAndCorporateCodeUpdate(passengersAndCorporateCode: PaxDetailsState): void {
    this.cancelCartUpdate$.next();
    this.queueService.cancelPayment.next();

    const cancelStream$ = this.queueService.toCancelEvent(this.cancelCartUpdate$);
    this.queueService.sendToQueue(
      'paxDetailsAndCorporateCodeUpdate',
      this.fromCartState((cart: CartState) => {
        const persistedCartUpdateExist = Boolean(
          cart.cartData?.passengers &&
            cart.cartData?.passengers.length > 0 &&
            cart.cartData?.passengers.find((t) => !!t.firstName)
        );

        return this.cartService.triggerTravelersAndCorporateCodeUpdate(
          cart.cartUrl,
          passengersAndCorporateCode,
          cancelStream$,
          persistedCartUpdateExist
        );
      }),
      cancelStream$
    );
  }

  queueTriggerFetchSeatMap(flightId: string, currencyCode?: string): void {
    const status = snapshot(this.store$.pipe(seatMapForFlightStatus(flightId)));
    if (status !== SeatMapStatus.OK && status !== SeatMapStatus.OK_NO_AVAILABILITY) {
      this.cancelSeatMapFetch$.next();
      const cancelStream$ = this.queueService.toCancelEvent(this.cancelSeatMapFetch$);
      this.queueService.sendToQueue(
        'TriggerFetchSeatmap',
        this.fromCartIdAndHash(({ id, hash }: IdAndHash) =>
          this.ancillaryService.triggerFetchSeatMap(flightId, id, hash, currencyCode, cancelStream$)
        ),
        cancelStream$
      );
    }
  }

  queueServicesUpdate(
    request: FinnairServiceRequestItem | FinnairServiceRequestItem[],
    observe: PartialObserver<any> = { complete: noop }
  ): void {
    const allRequests = Array.isArray(request) ? request : [request];
    this.queueService.cancelPayment.next();
    this.updatePartialCart(
      `${allRequests.map((r) => r.category).join('-')}Update`,
      (url, cancelStream$) => this.cartService.triggerUpdateServices(allRequests, url, cancelStream$),
      observe,
      ...allRequests.map((r) => getUpdateId(r.category, r.fragmentId))
    );
  }

  queueSeatSelectionReset(observe: PartialObserver<any> = { complete: noop }): void {
    this.queueService.cancelPayment.next();

    const flights = snapshot(this.store$.pipe(cartFlights()));
    const flightIds: string[] = flights.map((key) => getUpdateId(SubCategory.SEAT, key.id));

    this.updatePartialCart(
      'seatSelectionReset',
      (url, cancelStream$) => this.cartService.triggerResetSeatSelections(url, cancelStream$),
      observe,
      ...flightIds
    );
  }

  private updatePartialCart(
    actionName: string,
    update: (cartUrl: string, cancelStream$: Observable<void>) => Observable<FinnairCart>,
    observe: PartialObserver<any>,
    ...cartPartIds: string[]
  ): void {
    this.store$.dispatch(CartActions.isUpdatingPartialCart({ cartPartIds }));
    const cancelStream$ = this.queueService.toCancelEvent(this.cancelPaxUpdate$);

    this.queueService.sendToQueue(
      actionName,
      this.fromCartUrl((url: string) => {
        return update(url, cancelStream$).pipe(
          tap({
            complete: () => this.store$.dispatch(CartActions.clearUpdatingPartialCart({ cartPartIds })),
            error: () => this.store$.dispatch(CartActions.errorUpdatingPartialCart({ cartPartIds })),
          }),
          tap(observe),
          catchError(() => EMPTY)
        );
        // Should just complete the stream, booking cart service logs the error to sentry
      }),
      cancelStream$
    );
  }

  private fromCartState = (fn: (cart: CartState) => Observable<any>) =>
    this.store$.pipe(
      cartState(),
      filter((cart) => cart && !!cart.cartUrl),
      take(1),
      switchMap(fn)
    );

  private fromCartUrl = (fn: (url: string) => Observable<any>) =>
    this.store$.pipe(cartUrl(), filter(Boolean), take(1), switchMap(fn));

  private fromCartIdAndHash = (fn: (currentCartIdAndHash: IdAndHash) => Observable<any>) =>
    this.store$.pipe(
      cartIdAndHash(),
      filter(({ id, hash }: IdAndHash) => !!id && !!hash),
      take(1),
      switchMap((c) => fn(c))
    );
}
