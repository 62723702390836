<ng-container data-testid="saf-notification">
  <ng-container *ngIf="towardsSustainabilityMessage$ | async as content">
    <h2 *ngIf="showHeader" data-testid="saf-notification-main-header" class="mr-medium-y" [ngClass]="headerClass">
      {{ 'towardsSustainability.mainHeader' | finLocalization }}
    </h2>

    <div class="wrapper flex white-bg border rounded-large pr-small">
      <div class="content-description">
        <h2
          *ngIf="content.teaserTitle as title"
          class="font-heading-4 flex flex--center flex--space-between nordic-blue-900-text ms-xsmall-b"
        >
          {{ title }}
        </h2>
        <p
          class="nordic-blue-900-text mr-xsmall-b"
          *ngIf="content.teaserText"
          [innerHTML]="content.teaserText | finSafe: 'html'"
        ></p>
        <a
          data-testid="saf-notification-read-more-link"
          class="font-body-1 nordic-blue-900-text blue-light-hover medium-type underline overflow-hidden align-left"
          rel="noopener noreferrer"
          target="_blank"
          [finLink]="'sustainableTravel.link.url' | finLocalization"
          [external]="isLinkExternal"
          (click)="trackReadAboutSustainability()"
          >{{ 'towardsSustainability.readMore' | finLocalization }}</a
        >
      </div>
      <div class="flex flex--row flex--self-center">
        <div class="flex flex--column align-center birch-600-text">
          <span class="medium-type font-body-1">{{ 'towardsSustainability.infographic.text' | finLocalization }}</span>

          <span class="flex flex--center content-icon bold-type font-heading-3">{{
            'towardsSustainability.infographic.percentage' | finLocalization
          }}</span>

          <div class="medium-type font-body-1">
            <span>{{ 'towardsSustainability.infographic.details' | finLocalization }}</span>
            <button
              type="button"
              fcomPopover
              [content]="contentTooltip"
              [ariaCloseText]="'actions.close' | finLocalization"
              [attr.aria-label]="'actions.info' | finLocalization"
              class="icon birch-600-text ms-xxsmall-l"
              data-testid="fossil-co2-emission"
            >
              <fcom-icon class="icon-small" [name]="SvgLibraryIcon.INFO_BLOCK"></fcom-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <ng-template #contentTooltip>
      <h4 class="align-left">{{ 'towardsSustainability.tooltip.title' | finLocalization }}</h4>
      <p data-testid="saf-notification-tooltip-text" class="pr-xxsmall-t regular-type align-left">
        {{ 'towardsSustainability.tooltip.text' | finLocalization }}
      </p>
    </ng-template>
  </ng-container>
</ng-container>
