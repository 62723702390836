import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UiTranslateModule } from '@fcom/ui-translate';
import { IconsModule, ModalModule, UiComponentsModule } from '@fcom/ui-components';
import { PipesModule } from '@fcom/common/pipes';
import { ComponentsModule } from '@fcom/common/components/components.module';
import { DirectiveModule } from '@fcom/common/directives';
import { MediaQueryService, PhonePrefixService } from '@fcom/common/services';
import { GtmModule } from '@fcom/common/gtm';
import { FinFormsModule } from '@fcom/common/forms';
import { CommonServiceFormsModule } from '@fcom/service-forms/components/common/common-service-forms.module';
import { LoginModule } from '@fcom/common/login';
import { BookingAirBoundsService } from '@fcom/booking/modules/ticket-selection/services';
import { CorporatePassengerSearchComponent } from '@fcom/booking/modules/pax-details/components/corporate-passenger-search/corporate-passenger-search.component';
import { CorporateService } from '@fcom/common/services/corporate/corporate.service';
import { CmsModule } from '@fcom/cms';

import { UtilsService } from './services/utils.service';
import { AdditionalEmailComponent } from './components/additional-email/additional-email.component';
import { LocationsPipeModule } from '../locations';
import { PurchaseCheckoutComponent } from './components/purchase-checkout/purchase-checkout.component';
import { QueueService } from './services/queue.service';
import { PaymentService } from './services/payment.service';
import { PassengerInfoComponent } from './components/passenger-info/passenger-info.component';
import { PassengerFormService } from './services/passenger-form.service';
import { PaxValidationService } from './services/pax-validation.service';
import { ItineraryComponent } from './components/itinerary/itinerary.component';
import { ItineraryTimelineComponent } from './components/itinerary/itinerary-timeline.component';
import { SectionReviewComponent } from './components/section-review/section-review.component';
import { SectionReviewItemComponent } from './components/section-review/section-review-item.component';
import { BookingPlainHeaderAreaComponent } from './components/booking-plain-header-area/booking-plain-header-area.component';
import { PriceBreakdownComponent } from './components/price-breakdown/price-breakdown.component';
import { EndpointTimeComponent } from './components/endpoint-time/endpoint-time.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { FlightReviewCardComponent } from './components/flight-review-card/flight-review-card.component';
import { AirBoundsFareFamilyOptionComponent } from './components/flight-selection/fare-family-option/fare-family-option.component';
import { BoundContainerComponent } from './components/flight-selection/bound-container/bound-container.component';
import { BenefitComponent } from './components/flight-selection/benefit/benefit.component';
import { FareFamilyDetailsComponent } from './components/flight-selection/fare-family-details/fare-family-details.component';
import { FareFamilyTableComponent } from './components/flight-selection/fare-family-table/fare-family-table.component';
import { PaxDetailsFormGroupComponent } from './components/pax-details/pax-details-form-group.component';
import { ExpanderCardComponent } from './components/expander-card/expander-card.component';
import { UpsellOptionComponent } from './components/flight-selection/upsell-option/upsell-option.component';
import { ItineraryTimelineBoundHeaderComponent } from './components/itinerary/bound-header/itinerary-timeline-bound-header.component';
import { ItineraryTimelineLayoverComponent } from './components/itinerary/layover/itinerary-timeline-layover.component';
import { ItineraryTimelineLegEndpointComponent } from './components/itinerary/leg-endpoint/leg-endpoint.component';
import { ItineraryTimelineLegTechnicalStopsComponent } from './components/itinerary/leg-technical-stops/leg-technical-stops.component';
import { ItineraryTimelineFlightComponent } from './components/itinerary/flight/itinerary-timeline-flight.component';
import { GreetingCardComponent } from './components/login-promotion/greeting-card/greeting-card.component';
import { LoginPromotionComponent } from './components/login-promotion/login-promotion.component';
import { PaxFlightReviewSummaryComponent } from './components/pax-details/pax-flight-review-summary/pax-flight-review-summary.component';
import { BoundSelectionItemComponent } from './components/bound-selection-item/bound-selection-item.component';
import { FareFamilyDetailsV2Component } from './components/flight-selection/fare-family-details-v2/fare-family-details-v2.component';
import { CountrySelectionComponent } from './components/country-selection/country-selection.component';
import { FlightCardComponent } from './components/flight-card/flight-card.component';
import { FlightSummaryComponent } from './components/flight-card/flight-summary/flight-summary.component';
import { FlightSelectionFiltersComponent } from './components/flight-selection-filters/flight-selection-filters.component';
import { FlightSelectionSortByComponent } from './components/flight-selection-sort-by/flight-selection-sort-by.component';
import { FlightFiltersEmptyStateComponent } from './components/flight-filters-empty-state/flight-filters-empty-state.component';
import { PriceBreakdownRenewedComponent } from './components/price-breakdown-renewed/price-breakdown-renewed.component';
import { PassengerInfoRenewedComponent } from './components/passenger-info-renewed/passenger-info-renewed.component';
import { PassengerInfoListComponent } from './components/passenger-info-list/passenger-info-list.component';
import { RefundDetailComponent } from './components/refund-detail/refund-detail.component';
import { BookingWidgetModule } from '../booking-widget/booking-widget.module';
import { RefundPriceBreakdownComponent } from './components/refund-detail/refund-price-breakdown/refund-price-breakdown.component';
import { UFOWithModifyButtonComponent } from './components/ufo-with-modify-button/ufo-with-modify-button.component';
import { FlightSelectionFlightSummaryComponent } from './components/flight-selection/flight-summary/flight-summary.component';
import { FlightSelectionFareFamilyListComponent } from './components/flight-selection/fare-family-list/fare-family-list.component';
import { FlightSelectionFareFamilyListOptionComponent } from './components/flight-selection/fare-family-list-option/fare-family-list-option.component';
import { CabinClassModalComponent } from './components/flight-selection/cabin-class-modal/cabin-class-modal.component';

const COMPONENTS = [
  PassengerInfoComponent,
  ItineraryComponent,
  ItineraryTimelineComponent,
  ItineraryTimelineLegEndpointComponent,
  ItineraryTimelineLegTechnicalStopsComponent,
  ItineraryTimelineBoundHeaderComponent,
  ItineraryTimelineLayoverComponent,
  ItineraryTimelineFlightComponent,
  SectionReviewComponent,
  RefundDetailComponent,
  SectionReviewItemComponent,
  BookingPlainHeaderAreaComponent,
  PriceBreakdownComponent,
  RefundPriceBreakdownComponent,
  PurchaseCheckoutComponent,
  EndpointTimeComponent,
  SubHeaderComponent,
  FlightReviewCardComponent,
  AirBoundsFareFamilyOptionComponent,
  BoundContainerComponent,
  BenefitComponent,
  FareFamilyDetailsComponent,
  FareFamilyTableComponent,
  PaxDetailsFormGroupComponent,
  ExpanderCardComponent,
  UpsellOptionComponent,
  GreetingCardComponent,
  LoginPromotionComponent,
  FlightCardComponent,
  PaxFlightReviewSummaryComponent,
  FlightSummaryComponent,
  BoundSelectionItemComponent,
  FareFamilyDetailsV2Component,
  CountrySelectionComponent,
  FlightSelectionFiltersComponent,
  FlightSelectionSortByComponent,
  FlightFiltersEmptyStateComponent,
  AdditionalEmailComponent,
  PriceBreakdownRenewedComponent,
  PassengerInfoRenewedComponent,
  PassengerInfoListComponent,
  CorporatePassengerSearchComponent,
  UFOWithModifyButtonComponent,
  FlightSelectionFlightSummaryComponent,
  FlightSelectionFareFamilyListComponent,
  FlightSelectionFareFamilyListOptionComponent,
  CabinClassModalComponent,
];

@NgModule({
  providers: [
    PaymentService,
    QueueService,
    MediaQueryService,
    UtilsService,
    BookingAirBoundsService,
    PassengerFormService,
    PaxValidationService,
    PhonePrefixService,
    CorporateService,
  ],
  imports: [
    LocationsPipeModule,
    FormsModule,
    ReactiveFormsModule,
    FinFormsModule,
    CommonModule,
    DirectiveModule,
    UiTranslateModule,
    UiComponentsModule,
    IconsModule,
    PipesModule,
    ModalModule,
    ComponentsModule,
    GtmModule,
    CommonServiceFormsModule,
    LoginModule,
    BookingWidgetModule,
    CmsModule,
  ],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class CommonBookingComponentsModule {}
