<div class="nordic-blue-50-bg">
  <ul class="ribbon-list flex flex--space-around no-list-style md-col-3-10 ms-medium-y" role="list">
    <li *ngFor="let link of links; let i = index" role="listitem">
      <a
        [finLink]="link"
        class="block no-underline nordic-blue-900-text font-body-2 ps-xsmall-y footer-link inline-block"
        ><span class="valign-middle">{{ 'ondMarketing.ribbon.' + linkTranslationKeys[i] | finLocalization }}</span></a
      >
    </li>
  </ul>
</div>
