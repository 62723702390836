import { DataUtils } from '@fcom/core/utils';

import { LanguageActions } from '../actions';
import { LanguageState } from '../interfaces';

export const initialState: LanguageState = Object.seal({
  value: '',
  locale: '',
  localizations: {},
});

export function languageReducer(state = initialState, action: LanguageActions.LanguageActionUnion): LanguageState {
  switch (action.type) {
    case LanguageActions.setLanguage.type:
      return DataUtils.wrap(state).setIn('locale', action.locale).setIn('value', action.language).value();
    case LanguageActions.setLocalizations.type:
      return DataUtils.wrap(state).setIn(['localizations', action.language], action.localization).value();
    default:
      return state;
  }
}
