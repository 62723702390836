<h1 *ngIf="content.teaserTitle" class="article-title nordic-blue-900-text mr-small-b ms-clear-t font-heading-1">
  {{ content.teaserTitle }}
</h1>
<div
  class="article-lead nordic-blue-900-text bold-type ms-large-b"
  [innerHTML]="content.teaserText | finSafe: 'html'"
></div>
<figure *ngFor="let contentImage of content.media" class="hero-image ms-clear-x ms-clear-t ms-small-b">
  <fin-content-image class="block image-height" [imageData$]="contentImage | finToObservable"></fin-content-image>
  <figcaption
    *ngIf="contentImage.teaserText"
    [innerHTML]="contentImage.teaserText | finSafe: 'html'"
    class="hero-image-caption font-body-2 light-type nordic-blue-900-text ms-xxsmall-t ms-large-b"
  ></figcaption>
</figure>
<div class="ms-large-t article-markup ms-large-b" [innerHTML]="content.detailText | finSafe: 'html'"></div>
<fin-detail-text
  *ngFor="let detailTextItem of content.detailTextItems"
  [mediaObject]="detailTextItem.media"
  [markup]="detailTextItem.markup"
></fin-detail-text>
