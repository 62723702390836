import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ComponentsModule } from '@fcom/common';

import { LanguageSettingLayoutComponent } from './language-setting-layout.component';

@NgModule({
  imports: [RouterModule, ComponentsModule, CommonModule],
  declarations: [LanguageSettingLayoutComponent],
  exports: [LanguageSettingLayoutComponent],
})
export class LanguageSettingLayoutModule {}
