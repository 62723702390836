<fieldset>
  <legend *ngIf="legend" [class.inline-block]="inline">
    {{ legend }}
    <span *ngIf="required"> *</span>
  </legend>

  <ng-content></ng-content>

  <div *ngFor="let name of names" class="error-label font-body-2">
    <span
      *ngIf="!showOnlyGroupLevelErrors && group.get(name)?.invalid && group.get(name)?.touched"
      class="error-900-text"
    >
      {{ [translationPrefix, name, firstErrorKey(name)].join('.') | finLocalization }}
    </span>
  </div>

  <div *ngIf="firstErrorKey() && group?.invalid && group?.touched" class="error-label font-body-2">
    <div class="error-900-text">
      {{ [groupTranslationPrefix || translationPrefix, firstErrorKey()].join('.') | finLocalization }}
    </div>
  </div>
</fieldset>
