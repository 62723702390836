import { isPresent } from '@fcom/core/utils';

export const onlyChangedValues =
  (previousSelections: string[]) =>
  ([val, i]: [string, number]): boolean =>
    previousSelections[i] !== val;

/**
 * Will go through an array of strings, and look for indexes that have a certain value.
 * Will ignore one index in the process, and return the indexes that have the certain value.
 * @param input Array of strings to be looped through
 * @param indexToIgnore
 * @param newValue
 * @returns An array of indexes
 */

export const indexesOfWantedValue = (input: string[], indexToIgnore: number, newValue: string): number[] =>
  input.map((val, i) => (i !== indexToIgnore && val === newValue ? i : undefined)).filter(isPresent);
