import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { v4 as uuid } from 'uuid';

import { RadioButtonTheme } from '../enums';

@Component({
  selector: 'fcom-radio-item',
  templateUrl: './radio-item.component.html',
  styleUrls: ['./radio-item.component.scss'],
})
export class RadioItemComponent implements OnInit {
  readonly RadioButtonTheme = RadioButtonTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  id: string;

  @Input() parentForm: UntypedFormGroup;

  @Input() controlName: string;

  @Input() translationPrefix: string;

  /**
   * @deprecated
   */
  @Input() radioKey: string;

  @Input() isSensitive = false;

  /**
   * @deprecated
   */
  @Input() displayContainerStyles = true;

  @Input() label: string;

  @Input() value: string;

  @Input() theme = RadioButtonTheme.ROUND;

  @Input() first = false;

  @Input() last = false;

  @Input() renewedComponent = false;

  /**
   * @deprecated
   */
  @Input() description = false;

  @Input() isHorizontal = false;

  /**
   * @deprecated
   */
  @Input() descriptionTitle?: string;

  @Input() descriptionText?: string;

  @Input() name?: string;

  control: AbstractControl;

  ngOnInit(): void {
    this.control = this.parentForm.get(this.controlName);
    this.id = uuid();
  }
}
