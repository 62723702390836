import { Component, ViewChild, ElementRef, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { map, take, filter, switchMap } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { ButtonType, LoaderTheme } from '@fcom/ui-components';
import { unsubscribe } from '@fcom/core/utils';
import { loginToken, profile, profileType } from '@fcom/core/selectors';
import { AppState } from '@fcom/core';
import { ProfileType, Profile } from '@fcom/core-api/login';
import { GtmService } from '@fcom/common/gtm';
import { Consent, ConsentGroup, ConsentStatus, ConsentTextId, SelectOption } from '@fcom/common';
import { ConsentService } from '@fcom/common/login/services/consent.service';
import { finShare } from '@fcom/rx';

import { FormStatus } from '../interfaces';
import { SubmitService } from '../services/submit.service';
import { trackFormState } from '../services/utils';

const MARKETING_LANGUAGES = ['FI', 'SV', 'EN'];

const UPDATE_DIRECT_MARKETING_PREFERENCES = 'update-direct-marketing-preferences';

@Component({
  selector: 'fin-direct-marketing-preferences-form',
  templateUrl: 'direct-marketing-preferences-form.component.html',
  styleUrls: ['./direct-marketing-preferences-form.component.scss'],
})
export class DirectMarketingPreferencesFormComponent implements OnInit, OnDestroy {
  readonly ConsentTextId = ConsentTextId;
  readonly FormStatus = FormStatus;
  readonly ButtonType = ButtonType;
  readonly LoaderTheme = LoaderTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  public notification: ElementRef;

  @ViewChild('fcomNotification', { static: false })
  set notificationElem(notificationElem: ElementRef) {
    this.notification = notificationElem;
  }

  @ViewChild('formContainer', { static: true })
  formContainer: ElementRef;

  reactiveForm: UntypedFormGroup;
  languages$: Observable<SelectOption[]>;
  formStatus: FormStatus = FormStatus.LOADING;
  consents$: Observable<Consent[]>;
  consentGroupIntroductionText$: Observable<string>;
  consentGroupProfileAfterwordText$: Observable<string>;

  private subscriptions: Subscription = new Subscription();
  private accessToken$: Observable<string>;
  private profile$: Observable<Profile>;

  constructor(
    private fb: UntypedFormBuilder,
    private store$: Store<AppState>,
    private languageService: LanguageService,
    private gtmService: GtmService,
    private marketingConsentService: ConsentService,
    private submitService: SubmitService,
    private cdRef: ChangeDetectorRef
  ) {
    this.reactiveForm = fb.group({});
  }

  ngOnInit(): void {
    this.formStatus = FormStatus.LOADING;
    this.languages$ = this.getLanguageOptions();

    this.profile$ = this.store$.pipe(profile(), take(1));

    this.accessToken$ = this.store$.pipe(
      loginToken(),
      filter((v) => !!v),
      take(1)
    );

    const accountType$: Observable<ProfileType> = this.store$.pipe(
      profileType(),
      filter((v) => !!v),
      take(1)
    );

    const consentGroup$: Observable<ConsentGroup> = combineLatest([this.accessToken$, accountType$]).pipe(
      switchMap(([token, type]) => this.marketingConsentService.getMarketingConsents({ token }, type)),
      filter((v) => !!v),
      finShare()
    );

    this.consentGroupIntroductionText$ = consentGroup$.pipe(map((group) => group.introductionText || ''));
    this.consentGroupProfileAfterwordText$ = consentGroup$.pipe(map((group) => group.profileAfterwordText || ''));
    this.consents$ = consentGroup$.pipe(map((group) => group.consents));

    this.subscriptions.add(
      combineLatest([this.profile$, this.consents$]).subscribe(([userProfile, consents]) => {
        this.createForm(userProfile, consents);
        this.formStatus = FormStatus.INITIAL;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }

  onSubmit(): void {
    if (this.reactiveForm.valid) {
      this.formStatus = FormStatus.LOADING;
      trackFormState(UPDATE_DIRECT_MARKETING_PREFERENCES, 'send-attempt', this.gtmService);

      const consentsRequest$ = combineLatest([this.accessToken$, this.consents$]).pipe(
        switchMap(([token, consents]) => {
          const updatedConsents = consents.map((consent) => {
            return {
              ...consent,
              consentStatus: this.reactiveForm.value[consent.consentTextId]
                ? ConsentStatus.ACCEPTED
                : ConsentStatus.DECLINED,
            };
          });

          return this.marketingConsentService.sendConsents({ token }, updatedConsents);
        })
      );

      const memberServiceRequest$ = combineLatest([this.profile$, this.accessToken$]).pipe(
        switchMap(([userProfile, token]) =>
          this.submitService.directMarketingPreferences(this.reactiveForm.value, userProfile.memberNumber, token)
        )
      );

      this.subscriptions.add(
        consentsRequest$.pipe(switchMap(() => memberServiceRequest$)).subscribe({
          next: () => {
            this.formStatus = FormStatus.COMPLETE;
            trackFormState(UPDATE_DIRECT_MARKETING_PREFERENCES, 'success', this.gtmService);
            this.autoScrollToNotification();
          },
          error: () => {
            this.formStatus = FormStatus.ERROR;
            trackFormState(UPDATE_DIRECT_MARKETING_PREFERENCES, 'error', this.gtmService);
            this.autoScrollToNotification();
          },
        })
      );
    }
  }

  autoScrollToNotification(): void {
    this.cdRef.detectChanges();
    this.notification.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  private createForm(userProfile: Profile, consents: Consent[]) {
    const consentFields = consents.reduce((fields, consent) => {
      fields[consent.consentTextId] = [consent.consentStatus === ConsentStatus.ACCEPTED];
      return fields;
    }, {});

    this.reactiveForm = this.fb.group({
      preferredLanguage: [userProfile.lang ? userProfile.lang.toUpperCase() : ''],
      ...consentFields,
    });
  }

  private getLanguageOptions(): Observable<SelectOption[]> {
    return this.languageService.translate('SERVICE_FORMS.CSServiceForms.fields.language').pipe(
      map((languages) =>
        MARKETING_LANGUAGES.map(
          (lang) =>
            ({
              value: lang.toUpperCase(),
              name: languages[lang],
            }) as SelectOption
        )
      )
    );
  }
}
