import { Component, Input } from '@angular/core';

@Component({
  selector: 'fin-skip-link',
  templateUrl: './skip-link.component.html',
  styleUrls: ['./skip-link.component.scss'],
})
export class SkipLinkComponent {
  @Input() main: HTMLElement;

  skipToMainContent() {
    this.main.setAttribute('tabindex', '-1');
    this.main.focus();
  }
}
