<div *ngIf="ondPageType === ondPageTypes.LANDING" [ngSwitch]="route[0].path">
  <div class="mr-large-y" *ngSwitchCase="ondMarketingRoutesPath.CITY_TO_CITY">
    <h2 class="mr-small-b font-heading-3 nordic-blue-900-text">
      {{ 'ondMarketing.popularRoutesHeading' | finLocalization }}
    </h2>

    <div class="ond-marketing-crosslinking grid-col-12">
      <div class="ond-marketing-crosslinking-tile flex flex--column">
        <a
          *ngIf="fromLink$ | async as fromLink"
          [finLink]="fromLink.link"
          class="bold-type font-body-1 nordic-blue-900-text no-underline underline-hover"
        >
          {{ 'ondMarketing.crossLinking.from' | finLocalization: { city: fromLink.locationName } }}
          <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.ARROW_RIGHT"></fcom-icon>
        </a>
      </div>
    </div>
  </div>

  <div class="mr-large-y" *ngSwitchCase="ondMarketingRoutesPath.FROM">
    <h2 class="mr-small-b font-heading-3 nordic-blue-900-text">
      {{ 'ondMarketing.exploreRoutesHeading' | finLocalization }}
    </h2>
    <div class="ond-marketing-crosslinking grid-col-12">
      <div
        *ngFor="let link of ondLinks$ | async | slice: 0 : linkListSliceSize"
        class="ond-marketing-crosslinking-tile flex-1 flex--column"
      >
        <a [finLink]="link.link" class="bold-type font-body-1 nordic-blue-900-text no-underline underline-hover">
          {{ link.originCityName }} -
          {{ link.destinationCityName }}
          <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.ARROW_RIGHT"></fcom-icon>
        </a>
      </div>
    </div>
  </div>

  <div class="mr-large-y" *ngSwitchCase="ondMarketingRoutesPath.FROM_COUNTRY">
    <h2 class="mr-small-b font-heading-3 nordic-blue-900-text">
      {{ 'ondMarketing.exploreRoutesHeading' | finLocalization }}
    </h2>
    <div class="ond-marketing-crosslinking grid-col-12">
      <div
        *ngFor="let link of ondLinks$ | async | slice: 0 : linkListSliceSize"
        class="ond-marketing-crosslinking-tile flex-1 flex--column"
      >
        <a [finLink]="link.link" class="bold-type font-body-1 nordic-blue-900-text no-underline underline-hover">
          {{ link.originCountryName }} -
          {{ link.destinationCountryName }}
          <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.ARROW_RIGHT"></fcom-icon>
        </a>
      </div>
    </div>
  </div>

  <div class="mr-large-y" *ngSwitchCase="ondMarketingRoutesPath.COUNTRY_TO_COUNTRY">
    <h2 class="mr-small-b font-heading-3 nordic-blue-900-text">
      {{ 'ondMarketing.popularRoutesHeading' | finLocalization }}
    </h2>

    <div class="ond-marketing-crosslinking grid-col-12">
      <div class="ond-marketing-crosslinking-tile flex flex--column">
        <a
          *ngIf="fromCountry$ | async as fromCountry"
          [finLink]="fromCountry.link"
          class="bold-type font-body-1 nordic-blue-900-text no-underline underline-hover"
        >
          {{ 'ondMarketing.crossLinking.from' | finLocalization: { city: fromCountry.locationName } }}
          <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.ARROW_RIGHT"></fcom-icon>
        </a>
      </div>
    </div>
  </div>

  <div class="mr-large-y" *ngSwitchDefault>
    <ng-container *ngIf="(ondLinks$ | async)?.length">
      <h2 class="mr-small-b font-heading-3 nordic-blue-900-text">
        {{ 'ondMarketing.exploreRoutesHeading' | finLocalization }}
      </h2>
      <div class="ond-marketing-crosslinking grid-col-12">
        <div
          *ngFor="let link of ondLinks$ | async | slice: 0 : linkListSliceSize"
          class="ond-marketing-crosslinking-tile flex-1 flex--column"
        >
          <a [finLink]="link.link" class="bold-type font-body-1 nordic-blue-900-text no-underline underline-hover">
            {{ link.originCityName }} -
            {{ link.destinationCityName }}
            <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.ARROW_RIGHT"></fcom-icon>
          </a>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div *ngIf="ondPageType === ondPageTypes.LISTING" [ngSwitch]="route[0].path">
  <div class="mr-large-y" *ngSwitchCase="ondMarketingRoutesPath.CITY_TO_CITY">
    <div class="ond-marketing-crosslinking grid-col-12">
      <div *ngFor="let link of ondLinks$ | async" class="ond-marketing-crosslinking-tile flex-1 flex--column">
        <a [finLink]="link.link" class="bold-type font-body-1 nordic-blue-900-text no-underline underline-hover">
          {{ link.originCityName }} -
          {{ link.destinationCityName }}
          <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.ARROW_RIGHT"></fcom-icon>
        </a>
      </div>
    </div>
  </div>

  <div class="mr-large-y" *ngSwitchCase="ondMarketingRoutesPath.FROM_COUNTRY">
    <div class="ond-marketing-crosslinking grid-col-12">
      <div *ngIf="fromCountry$ | async as fromCountry" class="ond-marketing-crosslinking-tile flex flex--column">
        <a
          *ngIf="fromCountry.link"
          [finLink]="fromCountry.link"
          class="bold-type font-body-1 nordic-blue-900-text no-underline underline-hover"
        >
          {{ 'ondMarketing.crossLinking.from' | finLocalization: { city: fromCountry.locationName } }}
          <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.ARROW_RIGHT"></fcom-icon>
        </a>
      </div>
    </div>
  </div>

  <div class="mr-large-y" *ngSwitchCase="ondMarketingRoutesPath.COUNTRY_TO_COUNTRY">
    <div class="ond-marketing-crosslinking grid-col-12">
      <div *ngFor="let link of ondLinks$ | async" class="ond-marketing-crosslinking-tile flex-1 flex--column">
        <a [finLink]="link.link" class="bold-type font-body-1 nordic-blue-900-text no-underline underline-hover">
          {{ link.originCountryName }} -
          {{ link.destinationCountryName }}
          <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.ARROW_RIGHT"></fcom-icon>
        </a>
      </div>
    </div>
  </div>

  <div class="mr-large-y" *ngSwitchCase="ondMarketingRoutesPath.FROM">
    <div class="ond-marketing-crosslinking grid-col-12">
      <div *ngFor="let link of ondLinks$ | async" class="ond-marketing-crosslinking-tile flex-1 flex--column">
        <a [finLink]="link.link" class="bold-type font-body-1 nordic-blue-900-text no-underline underline-hover">
          {{ 'ondMarketing.crossLinking.from' | finLocalization: { city: link.originCityName } }}
          <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.ARROW_RIGHT"></fcom-icon>
        </a>
      </div>
    </div>
  </div>
</div>
