import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable, startWith } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { CmsDataService, RootPaths, WindowRef } from '@fcom/core';
import { CorporateUserRole, Profile, ProfileTier } from '@fcom/core-api/login';
import { pathIsWithinHardcodedPath } from '@fcom/core/utils/app-url-utils';
import { isNotBlank } from '@fcom/core/utils';
import { MenuItemIcon, MenuListItem } from '@fcom/common/interfaces';
import { LoyaltyRoutesPath } from '@fcom/loyalty-core/interfaces';
import { MenuListClickEvent } from '@fcom/ui-components/components/notifications/menu-list/menu-list.component';
import { IconPosition } from '@fcom/ui-components/components/icons';

import { MenuJson } from '../../../interfaces';
import { isCorporateAdminOrTravelBooker, LoginService } from '../../services';

export type TierDataMap = Record<
  ProfileTier[number],
  {
    name?: string;
    juniorName?: string;
    links: string;
    className: string;
  }
>;

const logoutIcon: MenuItemIcon = {
  category: IconLibrary.SVG_LIBRARY,
  name: SvgLibraryIcon.LOGOUT,
  position: IconPosition.LEFT,
};

@Component({
  selector: 'fin-profile-quick-view',
  styleUrls: ['profile-quick-view.component.scss'],
  templateUrl: 'profile-quick-view.component.html',
})
export class ProfileQuickViewComponent implements OnInit {
  @Output()
  popOverClosed = new EventEmitter<void>();

  @Input({ required: true })
  profile$: Observable<Profile>;

  readonly IconLibrary = IconLibrary;
  readonly ProfileTier = ProfileTier;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly LOGOUT_ID = 'logout-link';

  readonly tierDataMap: TierDataMap = {
    [ProfileTier.ACCOUNT]: {
      name: 'finnairPlus.tier.account',
      links: 'fragments.quickProfileViewLinksAccount.url',
      className: 'tier-account',
    },
    [ProfileTier.BASIC]: {
      name: 'finnairPlus.tier.basic',
      links: 'fragments.quickProfileViewLinksBasic.url',
      className: 'tier-basic',
    },
    [ProfileTier.JUNIOR]: {
      name: 'finnairPlus.tier.junior',
      links: 'fragments.quickProfileViewLinksJunior.url',
      className: 'tier-junior',
    },
    [ProfileTier.SILVER]: {
      name: 'finnairPlus.tier.silver',
      juniorName: 'finnairPlus.tier.juniorSilver',
      links: 'fragments.quickProfileViewLinksSilver.url',
      className: 'tier-silver',
    },
    [ProfileTier.GOLD]: {
      name: 'finnairPlus.tier.gold',
      juniorName: 'finnairPlus.tier.juniorGold',
      links: 'fragments.quickProfileViewLinksGold.url',
      className: 'tier-gold',
    },
    [ProfileTier.PLATINUM]: {
      name: 'finnairPlus.tier.platinum',
      juniorName: 'finnairPlus.tier.juniorPlatinum',
      links: 'fragments.quickProfileViewLinksPlatinum.url',
      className: 'tier-platinum',
    },
    [ProfileTier.LUMO]: {
      name: 'finnairPlus.tier.lumo',
      links: 'fragments.quickProfileViewLinksLumo.url',
      className: 'tier-lumo',
    },
    [ProfileTier.CORPORATE]: {
      links: 'fragments.quickProfileViewLinksCorporate.url',
      className: 'tier-corporate',
    },
    [CorporateUserRole.ADMIN]: {
      links: 'fragments.quickProfileViewLinksCorporateAdmin.url',
      className: 'tier-corporate',
    },
  };

  menuItems$: Observable<MenuListItem[]>;

  constructor(
    private loginService: LoginService,
    private elementRef: ElementRef,
    private languageService: LanguageService,
    private cmsDataService: CmsDataService,
    private windowRef: WindowRef
  ) {}

  ngOnInit(): void {
    this.menuItems$ = this.profile$.pipe(
      switchMap((memberProfile: Profile) => {
        const links = this.getLinks(memberProfile);
        return this.languageService.translate(links).pipe(
          filter(isNotBlank),
          switchMap((url) => this.cmsDataService.getFragmentJson<MenuJson>(url)),
          map(({ top }) => top.filter(({ link }) => !link.includes(LoyaltyRoutesPath.TIER_PROGRESS_AND_BENEFITS))),
          startWith([])
        );
      }),
      switchMap((menuItems: MenuListItem[]) => {
        return this.languageService.translate('login.logoutButton').pipe(
          map((logoutText) => [
            ...menuItems,
            {
              teaserTitle: logoutText,
              withDivider: true,
              icon: logoutIcon,
              id: this.LOGOUT_ID,
              external: false,
              attrs: {
                'data-testid': 'profile-quick-view-logout-btn',
              },
            },
          ])
        );
      }),
      distinctUntilChanged()
    );
  }

  handleClick(e: MenuListClickEvent): void {
    if (e.itemId === this.LOGOUT_ID) {
      this.logout(e.originalEvent);
    }
    this.popOverClosed.emit();
  }

  logout(event: Event): void {
    const inFinnairPlus = pathIsWithinHardcodedPath(this.windowRef.nativeWindow.location.pathname, RootPaths.PLUS_ROOT);
    const inCorporate = pathIsWithinHardcodedPath(this.windowRef.nativeWindow.location.pathname, RootPaths.CORPORATE);
    const redirectPath = inFinnairPlus
      ? `/${this.languageService.langValue}`
      : inCorporate
        ? `/${this.languageService.langValue}/${RootPaths.CORPORATE}`
        : undefined;
    this.loginService.logout(redirectPath ? { redirectPath } : undefined);
    event.preventDefault();
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.popOverClosed.emit();
    }
  }

  getLinks(memberProfile: Profile): string {
    if (isCorporateAdminOrTravelBooker(memberProfile)) {
      return this.tierDataMap[CorporateUserRole.ADMIN].links;
    }
    return this.tierDataMap[memberProfile.tier]?.links ?? 'fragments.quickProfileViewLinksBasic.url';
  }
}
