<div class="blockimage">
  <div class="blockimage__content">
    <ng-content></ng-content>
  </div>
  <div
    class="blockimage__image"
    [defaultImage]="thumbnailSrc"
    [lazyLoad]="srcSet || fallbackSrc"
    [errorImage]="fallbackSrc"
    [useSrcset]="!!srcSet"
    [decode]="true"
    [class.blockimage__image--has-overlay]="overlay"
    [class.blockimage__image--blur-overlay]="overlayBlur"
    [class.blockimage__image--fixed]="fixed"
  ></div>
</div>
