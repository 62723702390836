<fin-login-form-container
  [fullScreen]="fullScreen"
  *finLet="step$ | async as step"
  (returnClicked)="resetLogin($event)"
  (closeClicked)="closeDialog()"
  [title]="loginTranslations[step].title"
  [description]="loginTranslations[step].description"
  [error]="(showError$ | async) ? loginTranslations[step].error : undefined"
  [icon]="
    step === LoginStep.CREDENTIALS
      ? SvgLibraryIcon.FINNAIR_EMBLEM_BLOCK
      : step === LoginStep.POST_LOST_PASSWORD_SENT
        ? SvgLibraryIcon.CHECKMARK_BLOCK
        : undefined
  "
  data-hj-suppress
>
  <ng-container [ngSwitch]="step">
    <ng-container *ngSwitchCase="LoginStep.CREDENTIALS">
      <form (submit)="login()" [formGroup]="reactiveForm">
        <div class="ms-medium-y">
          <fcom-text-input
            [translationPrefix]="'login.fields.member'"
            [parentForm]="reactiveForm"
            [displayMargin]="false"
            [required]="false"
            [controlName]="'member'"
            [placeHolder]="'login.memberExample'"
          ></fcom-text-input>
        </div>
        <fcom-text-input
          [translationPrefix]="'login.fields.password'"
          [parentForm]="reactiveForm"
          [displayMargin]="false"
          [required]="false"
          [controlName]="'pwd'"
          [inputType]="InputType.PASSWORD"
        ></fcom-text-input>

        <button
          type="button"
          class="underline nordic-blue-900-text fill align-right ms-small-y"
          [ngClass]="fullScreen ? 'font-body-1' : 'font-body-2'"
          (click)="forgotPassword()"
        >
          {{ 'login.forgot' | finLocalization }}
        </button>

        <fcom-checkbox [name]="'keep'" [formControlName]="'keep'" [cid]="'keep'">
          <span [ngClass]="fullScreen ? 'font-body-1' : 'font-body-2'">{{
            'login.keepLoggedIn' | finLocalization
          }}</span>
        </fcom-checkbox>

        <fcom-button
          [type]="ButtonType.SUBMIT"
          data-testid="login-form-login-button"
          [loading]="loading$ | async"
          [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
          [disabled]="!reactiveForm.valid"
          [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
          [icon]="
            (loading$ | async)
              ? {
                  category: IconLibrary.SVG_LIBRARY,
                  name: SvgLibraryIcon.TRANSFER_LOADER,
                  position: IconPosition.RIGHT,
                }
              : null
          "
          [mode]="ButtonMode.FILL"
          [text]="'login.login' | finLocalization"
          class="ms-large-t ms-small-b"
        ></fcom-button>
      </form>
      <fcom-button
        (clickBtn)="corporateLogin()"
        [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
        [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
        [mode]="ButtonMode.FILL"
        [text]="'login.corporateLogin' | finLocalization"
      ></fcom-button>
    </ng-container>

    <ng-container *ngSwitchCase="LoginStep.FORGOT_PASSWORD">
      <form (submit)="postLostPassword()" [formGroup]="forgotPasswordForm">
        <div class="ms-medium-b">
          <fcom-radio-item-group
            [options]="[{ value: PostLostPasswordBy.EMAIL }, { value: PostLostPasswordBy.SMS }]"
            [parentForm]="forgotPasswordForm"
            [controlName]="'postLostPasswordBy'"
            [isSensitive]="true"
            [translationPrefix]="'login.forgotPassword.fields.postLostPasswordBy'"
          >
          </fcom-radio-item-group>
        </div>
        <fcom-text-input
          [translationPrefix]="'login.forgotPassword.fields.member'"
          [parentForm]="forgotPasswordForm"
          [displayMargin]="false"
          [required]="false"
          [controlName]="'member'"
          [placeHolder]="'login.memberExample'"
          class="block ms-medium-b"
        ></fcom-text-input>
        <fcom-text-input
          [translationPrefix]="'login.forgotPassword.fields.lastName'"
          [parentForm]="forgotPasswordForm"
          [displayMargin]="false"
          [required]="false"
          [controlName]="'lastName'"
        ></fcom-text-input>

        <fcom-button
          [type]="ButtonType.SUBMIT"
          [loading]="loading$ | async"
          [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
          [disabled]="!forgotPasswordForm.valid"
          [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
          [icon]="
            (loading$ | async)
              ? {
                  category: IconLibrary.SVG_LIBRARY,
                  name: SvgLibraryIcon.TRANSFER_LOADER,
                  position: IconPosition.RIGHT,
                }
              : null
          "
          [mode]="ButtonMode.FILL"
          [text]="'login.forgotPassword.submit' | finLocalization"
          class="ms-large-t"
        ></fcom-button>
      </form>
    </ng-container>

    <ng-container *ngSwitchCase="LoginStep.POST_LOST_PASSWORD_SENT">
      <fcom-button
        [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
        [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.SINGLE_PAX, position: IconPosition.LEFT }"
        [mode]="ButtonMode.FILL"
        [text]="'login.login' | finLocalization"
        (clickBtn)="resetLogin($event)"
      >
      </fcom-button>
    </ng-container>

    <ng-container *ngSwitchCase="LoginStep.TWO_FACTOR_CODE">
      <form (submit)="login2fa()" [formGroup]="authenticatorForm">
        <fcom-text-input
          [displayMargin]="false"
          [required]="false"
          [translationPrefix]="'login.fields.2faCode'"
          [parentForm]="authenticatorForm"
          [controlName]="'code'"
          [inputType]="InputType.PASSWORD"
        ></fcom-text-input>

        <fcom-button
          class="ms-small-y"
          [type]="ButtonType.SUBMIT"
          [loading]="loading$ | async"
          [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
          [disabled]="!authenticatorForm.valid"
          [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
          [icon]="
            (loading$ | async)
              ? {
                  category: IconLibrary.SVG_LIBRARY,
                  name: SvgLibraryIcon.TRANSFER_LOADER,
                  position: IconPosition.RIGHT,
                }
              : null
          "
          [mode]="ButtonMode.FILL"
          [text]="'login.login' | finLocalization"
        ></fcom-button>
      </form>

      <p [ngClass]="fullScreen ? 'font-body-1' : 'font-body-2'" class="ms-small-y">
        {{ 'login.2faRequestCode' | finLocalization }}
      </p>
      <fcom-button
        [loading]="loading$ | async"
        [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
        [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
        [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
        [mode]="ButtonMode.FILL"
        [text]="'login.2faRequestCodeBtn' | finLocalization"
        (clickBtn)="requestSMS()"
      ></fcom-button>
    </ng-container>

    <ng-container *ngSwitchCase="[LoginStep.TWO_FACTOR_PHONE, LoginStep.TWO_FACTOR_SMS].includes(step) ? step : null">
      <form (submit)="loginSms(step === LoginStep.TWO_FACTOR_PHONE)" [formGroup]="smsCodeForm">
        <fcom-text-input
          [displayMargin]="false"
          [required]="false"
          [translationPrefix]="'login.fields.2faSmsCode'"
          [parentForm]="smsCodeForm"
          [controlName]="'code'"
          [inputType]="InputType.PASSWORD"
        ></fcom-text-input>

        <fcom-button
          class="ms-small-y"
          [type]="ButtonType.SUBMIT"
          [loading]="loading$ | async"
          [aria]="{ loadingLabel: 'actions.loading' | finLocalization }"
          [disabled]="!smsCodeForm.valid"
          [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
          [icon]="
            (loading$ | async)
              ? {
                  category: IconLibrary.SVG_LIBRARY,
                  name: SvgLibraryIcon.TRANSFER_LOADER,
                  position: IconPosition.RIGHT,
                }
              : null
          "
          [mode]="ButtonMode.FILL"
          [text]="'login.login' | finLocalization"
        ></fcom-button>
      </form>
    </ng-container>

    <ng-container *ngSwitchCase="LoginStep.LOCKED">
      <fcom-button
        [size]="fullScreen ? ButtonSize.LARGE : ButtonSize.MEDIUM"
        [mode]="ButtonMode.FILL"
        [text]="'login.lockedCta' | finLocalization"
        (clickBtn)="forgotPassword()"
      ></fcom-button>
    </ng-container>
  </ng-container>
</fin-login-form-container>
