import { Component, input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';

import { CmsTarget } from '@fcom/core-api';
import { LocalizationPipe } from '@fcom/ui-translate';

@Component({
  selector: 'fin-text-link-left',
  templateUrl: './text-link-left.component.html',
  styleUrl: './text-link-left.component.scss',
})
export class TextLinkLeftComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly newTabText = ` (${this.finLocalization.transform('ariaLabels.newTab')})`;

  teaserTitle$ = input<Observable<string>>();
  target$ = input<Observable<CmsTarget>>();
  useIcon = input<boolean>(true);
  underline = input<boolean>(false);

  constructor(private finLocalization: LocalizationPipe) {}
}
