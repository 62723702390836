<a
  *ngIf="linkVal"
  class="bold-type"
  [href]="linkVal"
  [ngClass]="{
    'font-body-1': size === LinkSize.MEDIUM,
    'font-body-2': size === LinkSize.SMALL,
    light: theme === LinkTheme.LIGHT,
    dark: theme === LinkTheme.DARK,
    disabled,
  }"
  [attr.rel]="rel"
  [attr.target]="target"
  [attr.aria-label]="aria && aria.label"
  [attr.aria-describedby]="aria && aria.describedby"
  [attr.aria-controls]="aria && aria.controls"
  [attr.aria-disabled]="disabled || (aria && aria.disabled)"
  [attr.aria-selected]="aria && aria.selected"
  (click)="handleClick($event)"
>
  <ng-container *ngTemplateOutlet="linkText"></ng-container>
</a>
<button
  *ngIf="!linkVal"
  class="underline bold-type"
  [ngClass]="{
    'font-body-1': size === LinkSize.MEDIUM,
    'font-body-2': size === LinkSize.SMALL,
    light: theme === LinkTheme.LIGHT,
    dark: theme === LinkTheme.DARK,
    disabled,
  }"
  [attr.aria-label]="aria && aria.label"
  [attr.aria-describedby]="aria && aria.describedby"
  [attr.aria-controls]="aria && aria.controls"
  [attr.aria-disabled]="disabled || (aria && aria.disabled)"
  [attr.aria-selected]="aria && aria.selected"
  (click)="handleClick($event)"
>
  <ng-container *ngTemplateOutlet="linkText"></ng-container>
</button>

<ng-template #linkText>
  <ng-container *ngIf="icon?.position === IconPosition.LEFT">
    <ng-container *ngTemplateOutlet="iconContainer"></ng-container>
  </ng-container>

  <ng-content></ng-content>

  <ng-container *ngIf="icon?.position === IconPosition.RIGHT">
    <ng-container *ngTemplateOutlet="iconContainer"></ng-container>
  </ng-container>
</ng-template>

<ng-template #iconContainer>
  <fcom-icon
    [category]="icon.category"
    [name]="icon.name"
    [ngClass]="{
      'ms-xxsmall-r': icon.position === IconPosition.LEFT,
      'ms-xxsmall-l': icon.position === IconPosition.RIGHT,
      'icon-medium': size === LinkSize.MEDIUM,
      'icon-small': size === LinkSize.SMALL,
    }"
  ></fcom-icon>
</ng-template>
