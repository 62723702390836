import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiTranslateModule } from '@fcom/ui-translate';

import { IconsModule } from '../icons';
import { TagButtonComponent } from './tag-button.component';
import { TagComponent } from './tag.component';

@NgModule({
  declarations: [TagButtonComponent, TagComponent],
  imports: [CommonModule, UiTranslateModule, IconsModule],
  exports: [TagButtonComponent, TagComponent],
  providers: [],
})
export class TagModule {}
