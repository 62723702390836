import { createAction, props, union } from '@ngrx/store';

import {
  SelectedServiceData,
  SelectionsPerTraveler,
  ServiceAvailability,
  ServiceCatalog,
  TravelerService,
} from '@fcom/dapi/interfaces';
import { Category } from '@fcom/dapi/api/models';

export const startLoading = createAction('[ServicesActions] LoadStart');
export const error = createAction('[ServicesActions] LoadError');
export const setSelectionsForFragment = createAction(
  '[ServicesActions] SetSelectionsForFragment',
  props<{ category: Category; fragmentId: string; selections: SelectionsPerTraveler<TravelerService[]> }>()
);
export const setSelectionForFragmentForTraveler = createAction(
  '[ServicesActions] setSelectionForFragmentForTraveler',
  props<{
    category: Category;
    fragmentId: string;
    travelerId: string;
    selection: TravelerService;
  }>()
);
export const setCoverForId = createAction(
  '[ServicesActions] SetCoverForJourney',
  props<{ journeyId: string; travelerId: string; service: TravelerService }>()
);
export const setServiceCatalog = createAction(
  '[ServicesActions] SetServiceCatalog',
  props<{ catalog: ServiceCatalog }>()
);

export const setServiceAvailability = createAction(
  '[ServicesActions] SetServiceAvailability',
  props<{ serviceAvailability: ServiceAvailability }>()
);
export const setCampaignShown = createAction('[ServicesActions] SetCampaignShown', props<{ id: string }>());
export const setUpsell = createAction('[ServicesActions] setUpsell', props<SelectedServiceData>());
export const clearUpsell = createAction('[ServicesActions] clearUpsell');
export const reset = createAction('[ServicesActions] Reset');

export const resetCategory = createAction('[ServicesActions] ResetCategory', props<{ category: Category }>());
const all = union({
  startLoading,
  error,
  setCampaignShown,
  setSelectionsForFragment,
  setSelectionForFragmentForTraveler,
  setCoverForId,
  setServiceCatalog,
  setServiceAvailability,
  setUpsell,
  clearUpsell,
  reset,
  resetCategory,
});

export type ActionsUnion = typeof all;
