import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Subject } from 'rxjs';

import { PrechatFields } from '../interfaces';

/*
As the SalesForce chat is provided as an external JavaScript snippet and instructed to be loaded with a <script> tag,
we load it by injecting it to the document head.
*/

interface Scripts {
  [src: string]: {
    loaded: boolean;
  };
}

interface HTMLScriptElementWithReady extends HTMLScriptElement {
  readyState: 'loaded' | 'complete' | any;
  onreadystatechange: any;
}

@Injectable()
export class SalesforceChatService {
  private scripts: Scripts = {};
  public openChat$: Subject<PrechatFields> = new Subject<PrechatFields>();

  constructor(@Inject(DOCUMENT) private document: Document) {}

  load(...scripts: string[]): Promise<any[]> {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  private loadScript(src: string) {
    return new Promise((resolve, reject) => {
      if (!this.scripts[src]) {
        if (document.querySelector(`script[src="${src}"]`)) {
          this.scripts[src] = { loaded: true };
        } else {
          this.scripts[src] = { loaded: false };
        }
      }

      if (!this.scripts[src].loaded) {
        const script: HTMLScriptElementWithReady = this.document.createElement('script') as HTMLScriptElementWithReady;
        script.type = 'text/javascript';
        script.src = src;

        const resolveLoaded = (srcUrl: string) => {
          this.scripts[srcUrl].loaded = true;
          resolve({ src: srcUrl, loaded: true });
        };

        if (script.readyState) {
          script.onreadystatechange = () => {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
              script.onreadystatechange = null;
              resolveLoaded(src);
            }
          };
        } else {
          script.onload = () => {
            resolveLoaded(src);
          };
        }
        script.onerror = (_error: any) => {
          reject({ src, loaded: false });
        };
        document.getElementsByTagName('head')[0].appendChild(script);
      } else {
        resolve({ src, loaded: true });
      }
    });
  }
}
