import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { CustomIcon, IconLibrary, SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable, filter, switchMap } from 'rxjs';

import { IconPosition, NotificationLayout } from '@fcom/ui-components';
import { LanguageService } from '@fcom/ui-translate';
import { ConfigService } from '@fcom/core';
import { GtmService } from '@fcom/common/gtm';
import { ElementActions, ElementTypes, GaContext } from '@fcom/common';

import { getCarTrawlerLang } from '../../../manage-booking/utils/booking.utils';

const DEFAULT_CARTRAWLER_NOTIFICATION = 'fragments.bookingWidgetCarTrawlerDefault.url';
const ENGLISH_CARTRAWLER_NOTIFICATION = 'fragments.bookingWidgetCarTrawlerToEnglish.url';

@Component({
  selector: 'fin-cartrawler-booking-widget',
  templateUrl: './cartrawler-booking-widget.component.html',
  styleUrls: ['./cartrawler-booking-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarTrawlerBookingWidgetComponent implements OnInit {
  readonly CustomIcon = CustomIcon;
  readonly IconLibrary = IconLibrary;
  readonly IconPosition = IconPosition;
  readonly NotificationLayout = NotificationLayout;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  carTrawlerUrlRedirect: string;
  carTrawlerLocale: string;

  currentLanguage$: Observable<string>;
  translatedContentUrl$: Observable<string>;

  constructor(
    private languageService: LanguageService,
    private configService: ConfigService,
    private gtmService: GtmService
  ) {}

  ngOnInit(): void {
    this.currentLanguage$ = this.languageService.langKey;
    this.carTrawlerLocale = getCarTrawlerLang(this.languageService.langValue);

    this.translatedContentUrl$ = this.currentLanguage$.pipe(
      switchMap((currentLang) => this.getTranslatedUrl(currentLang)),
      filter(Boolean)
    );

    this.gtmService.trackElement(
      'booking-widget-tab-button_cartrawler-rental',
      GaContext.THIRD_PARTY_ANCILLARY,
      ElementTypes.TAB,
      'company: cartrawler',
      ElementActions.VIEW
    );
  }

  getLocaleLang(): string {
    return this.carTrawlerLocale.split('-')[0];
  }

  getTranslatedUrl(currentLang: string): Observable<string> {
    const localeLang = this.getLocaleLang();
    let fragmentUrlKey = DEFAULT_CARTRAWLER_NOTIFICATION;

    const { apiUrl, clientId } = this.configService.cfg.carRental;
    const langPath = currentLang === localeLang ? currentLang : 'en';
    fragmentUrlKey = currentLang === localeLang ? DEFAULT_CARTRAWLER_NOTIFICATION : ENGLISH_CARTRAWLER_NOTIFICATION;

    this.carTrawlerUrlRedirect = `${apiUrl}/${langPath}/home?clientId=${clientId}`;

    return this.languageService.translate(fragmentUrlKey);
  }

  trackClick(): void {
    this.gtmService.trackElement(
      'booking-widget-tab-button_cartrawler-rental',
      GaContext.THIRD_PARTY_ANCILLARY,
      ElementTypes.LINK,
      'company: cartrawler',
      ElementActions.CLICK
    );
  }
}
