/* eslint-disable */

/**
 * Represents a status
 */
export enum FinnairCompletionStatus {
  COMPLETED = 'COMPLETED',
  INCOMPLETE = 'INCOMPLETE'
}

