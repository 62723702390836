import { NgModule, Provider, ModuleWithProviders } from '@angular/core';

import { LocalizationPipe } from './pipes/localization.pipe';
import { LanguageService, MockLanguageService } from './services/language.service';
import { HasLocalizationKeyPipe } from './pipes/has-localization-key.pipe';
import { PluralLocalizationPipe } from './pipes/plural-localization.pipe';

export interface TranslateModuleConfig {
  languageService?: Provider;
}

@NgModule({
  declarations: [LocalizationPipe, HasLocalizationKeyPipe, PluralLocalizationPipe],
  exports: [LocalizationPipe, HasLocalizationKeyPipe, PluralLocalizationPipe],
  providers: [LocalizationPipe, HasLocalizationKeyPipe, PluralLocalizationPipe],
})
export class UiTranslateModule {
  /**
   * Use this method in your root module to provide the TranslateService
   */
  static forRoot(config: TranslateModuleConfig = {}): ModuleWithProviders<UiTranslateModule> {
    return {
      ngModule: UiTranslateModule,
      providers: [config.languageService || { provide: LanguageService, useClass: MockLanguageService }],
    };
  }
}
