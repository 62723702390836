import { ChangeDetectorRef, Injectable, Pipe, OnDestroy, PipeTransform } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Location } from '@fcom/core-api';

import { LocationsService } from '../services/locations.service';

@Injectable()
@Pipe({
  name: 'finLocationName',
  pure: false,
})
export class LocationNamePipe implements OnDestroy, PipeTransform {
  private async: AsyncPipe;
  private cachedInputs: string[] = [];
  private location$: Observable<Location>;

  constructor(
    private ref: ChangeDetectorRef,
    private locationsService: LocationsService
  ) {
    this.async = new AsyncPipe(this.ref);
  }

  transform(key: string): string {
    this.location$ = this.locationsService.retrieveLocation(key);

    const locationName$: Observable<string> = this.location$.pipe(
      map((location: Location) => {
        if (!location) {
          if (!this.cachedInputs.includes(key)) {
            this.locationsService.triggerLocationFetch(key);
            this.cachedInputs.push(key);
          }

          return key;
        }

        if (!location.cityName && !location.title) {
          return key;
        }

        return location.cityName ? location.cityName : location.title;
      })
    );

    return this.async.transform(locationName$);
  }

  ngOnDestroy(): void {
    this.async.ngOnDestroy();
  }
}
