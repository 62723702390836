<ng-template #btnTemplate let-item>
  <button class="no-underline link-hover flex flex--row" (click)="emitClick(item.data)">
    {{ item.options.btnText }} <fcom-icon class="ps-xsmall-l" [name]="item.options.btnIcon"></fcom-icon>
  </button>
</ng-template>
<table class="table fill relative">
  <caption class="is-vishidden">
    {{
      caption
    }}
  </caption>
  <thead *ngIf="headers?.length > 0 && !!headerTemplate" [ngClass]="{ 'dn-mobile': mobileTemplate || mobileHeader }">
    <tr>
      <th
        class="align-left ps-medium-y ps-xsmall-x border-bottom border-top nordic-blue-100-border"
        scope="col"
        [class.border-left]="index === 0"
        [class.border-right]="index === headers.length - 1"
        [class.rounded-top-left-large]="index === 0"
        [class.rounded-top-right-large]="index === headers.length - 1"
        [ngClass]="header?.options?.class"
        *ngFor="let header of headers; index as index"
        #tableHeader
      >
        <ng-container *ngTemplateOutlet="headerTemplate; context: { $implicit: header }"></ng-container>
      </th>
    </tr>
  </thead>
  <thead *ngIf="mobileHeader" class="dn-tablet-up">
    <tr>
      <th
        class="ps-medium-y ps-xsmall-x border-left border-top border-right nordic-blue-100-border rounded-top-large"
        scope="col"
      >
        <ng-container *ngTemplateOutlet="mobileHeader"></ng-container>
      </th>
    </tr>
  </thead>
  <tbody [ngClass]="{ 'dn-mobile': mobileTemplate }">
    <tr
      *ngFor="let row of tableData; index as rowIndex"
      [ngClass]="{ 'action-row': !!row.rowAction }"
      (click)="row.rowAction && emitClick(row.rowAction.data)"
    >
      <td
        *ngFor="let header of headers; index as columnIndex"
        class="border-bottom nordic-blue-100-border ps-small-y ps-xsmall-x"
        [class.border-left]="columnIndex === 0"
        [class.border-right]="columnIndex === headers.length - 1"
        [class.border-top]="rowIndex === 0 && !headerTemplate"
        [class.rounded-top-left-large]="rowIndex === 0 && columnIndex === 0 && !headerTemplate"
        [class.rounded-top-right-large]="rowIndex === 0 && columnIndex === headers.length - 1 && !headerTemplate"
        [class.rounded-bottom-left-large]="rowIndex === tableData.length - 1 && columnIndex === 0 && !usePagination"
        [class.rounded-bottom-right-large]="
          rowIndex === tableData.length - 1 && columnIndex === headers.length - 1 && !usePagination
        "
        [ngClass]="row[header.key]?.options?.class"
      >
        <ng-container *ngIf="!row[header.key]?.options?.useBtn">
          <ng-container *ngTemplateOutlet="cellTemplate; context: { $implicit: row[header.key].data }"></ng-container>
        </ng-container>

        <ng-container *ngIf="row[header.key]?.options?.useBtn">
          <ng-container
            *ngTemplateOutlet="
              btnTemplate;
              context: { $implicit: { data: row[header.key].data, options: row[header.key]?.options } }
            "
          ></ng-container>
        </ng-container>
      </td>
    </tr>
  </tbody>
  <tbody *ngIf="mobileTemplate" class="dn-tablet-up">
    <tr
      *ngFor="let row of tableData; index as rowIndex"
      [ngClass]="{ 'action-row': !!row.rowAction }"
      (click)="row.rowAction && emitClick(row.rowAction.data)"
    >
      <td
        class="border-left border-right border-bottom nordic-blue-100-border ps-small-y ps-xsmall-x"
        [class.border-top]="rowIndex === 0"
        [class.rounded-large]="tableData.length === 1 && !mobileHeader"
        [class.rounded-top-large]="rowIndex === 0 && !mobileHeader && tableData.length > 1"
        [class.rounded-bottom-large]="rowIndex === tableData.length - 1 && !usePagination && tableData.length > 1"
      >
        <ng-container *ngTemplateOutlet="mobileTemplate; context: { $implicit: row }"></ng-container>
      </td>
    </tr>
  </tbody>
  <tbody *ngIf="loading" class="absolute fill grow block top-left">
    <tr class="absolute fill grow">
      <td class="absolute fill grow loader">
        <div class="grey-25-bg border fill grow rounded-large flex flex--center" data-testid="table-loader">
          <fcom-loader [loaderType]="LoaderTheme.INLINE"></fcom-loader>
        </div>
      </td>
    </tr>
  </tbody>
  <tbody *ngIf="!loading && !tableData?.length && noResultsMsg" class="no-results">
    <tr>
      <td
        [colSpan]="headers?.length || 1"
        class="grey-25-bg align-center border-left border-bottom border-right rounded-bottom-large pr-large-y ps-large-x"
      >
        <span class="font-body-1 medium-type inline-block" data-testid="table-no-results">{{ noResultsMsg }}</span>
      </td>
    </tr>
  </tbody>
</table>
<fcom-pagination
  *ngIf="usePagination"
  [totalItems]="totalPaginationItems"
  [textBeforePages]="textBeforePages"
  (indexesChange)="changeIndexes($event)"
  [itemsPerPageOptions]="itemsPerPageOptions"
></fcom-pagination>
