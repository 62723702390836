import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { safeMap } from '@fcom/rx';

/**
 */
@Injectable()
@Pipe({
  name: 'finCountryName',
  pure: false,
})
export class CountryNamePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(key: string): Observable<string> {
    return this.languageService.translate('countryCodes').pipe(
      map((countries: any[]) =>
        countries.find((country) => {
          return country.countryCode === key.toUpperCase();
        })
      ),
      safeMap(({ name }) => name)
    );
  }
}
