import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { map, take, catchError } from 'rxjs/operators';

import { MaintenanceConfigResponse, MaintenanceConfigFeature } from '../../interfaces';
import { SentryLogger } from '../sentry/sentry.logger';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceConfigService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private sentryLogger: SentryLogger
  ) {}

  /**
   * Fetches response from maintenance config endpoint
   */
  getMaintenanceConfig(): Observable<MaintenanceConfigResponse> {
    return this.http.get<MaintenanceConfigResponse>(`${this.configService.cfg.configUrl}/config`).pipe(
      catchError((err: unknown) => {
        this.sentryLogger.error('Failed to fetch config for features under maintenance break', { error: err });
        return of({});
      })
    );
  }

  /**
   * Returns boolean value for the feature to check the disabled status
   * @param key feature to get from config response
   */
  getStatusForFeature(key: MaintenanceConfigFeature): Observable<boolean> {
    return this.getMaintenanceConfig().pipe(
      take(1),
      map((config) => {
        // fallback to false when fetched feature is not in response
        // or if value for it is not boolean as expected
        if (!config[key] || typeof config[key] !== 'boolean') {
          return false;
        }

        return config[key];
      })
    );
  }
}
