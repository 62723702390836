import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TagRoundingStyle, TagTheme } from './interfaces';

@Component({
  selector: 'fcom-tag',
  styleUrls: ['tag.component.scss'],
  templateUrl: 'tag.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
  readonly TagTheme = TagTheme;
  readonly TagRoundingStyle = TagRoundingStyle;

  @Input()
  theme: TagTheme = TagTheme.DEFAULT;

  @Input()
  roundingStyle = TagRoundingStyle.DEFAULT;
}
