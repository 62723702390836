import { FrequentFlyerTierLevel } from '../interfaces/utils.interface';

export const getCMSTier = (tier: string): string => {
  switch (tier) {
    case FrequentFlyerTierLevel.JUNIOR.toString():
      return 'JUNIOR';
    case FrequentFlyerTierLevel.PLUS.toString():
      return 'BASIC';
    case FrequentFlyerTierLevel.SILVER.toString():
      return 'SILVER';
    case FrequentFlyerTierLevel.GOLD.toString():
      return 'GOLD';
    case FrequentFlyerTierLevel.PLATINUM.toString():
      return 'PLATINUM';
    case FrequentFlyerTierLevel.LUMO.toString():
      return 'PLATINUM LUMO';
    default:
      return '';
  }
};

export const capitalizeEveryWordFirstLetter = (str: string | undefined): string | undefined => {
  if (str !== undefined && str.trim() !== '') {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
  return undefined;
};
