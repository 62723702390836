import { ROUTER_NAVIGATED } from '@ngrx/router-store';

import { setIn } from '@fcom/core/utils';

import { DisruptionState } from '../store.interface';
import { DisruptionActions } from '../actions';
import { isInMmb, isMmbStep } from '../../utils/common-booking.utils';
import { MANAGE_BOOKING_STEPS } from '../../manage-booking-config';

export const initialState: DisruptionState = {};

const KEY = 'selectedBoundId';

export function disruptionReducer(state = initialState, action: DisruptionActions.ActionsUnion): DisruptionState {
  switch (action.type) {
    case DisruptionActions.setSelectedBoundId.type:
      return setIn(state, KEY, action.selectedBoundId);

    case ROUTER_NAVIGATED: {
      if (
        !isInMmb(action.payload.routerState.url) ||
        isMmbStep(action.payload.routerState.url, MANAGE_BOOKING_STEPS.VIEW)
      ) {
        return initialState;
      }
      return state;
    }
    default:
      return state;
  }
}
