import { mergeDeepMany } from '@fcom/core/utils';

import { inTranslation } from './localization-step2-in-translation';
import { workInProgress } from './localization-wip';
// =====================================================================================================================
// | ADD YOUR NEW AND CHANGED TRANSLATIONS HERE IN FINNISH AND ENGLISH.                                                |
// | TEXTS IN THIS FILE SHOULD ALREADY BE APPROVED BY COPYWRITERS AND CAN BE COPIED TO THE CMS.                        |
// =====================================================================================================================
import * as globalLocalizationEnNext from './localization/globalLocalizationEn.json';
import * as bookingLocalizationEnNext from './localization/bookingLocalizationEn.json';
import * as mmbLocalizationEnNext from './localization/mmbLocalizationEn.json';
import * as reaccommodationLocalizationEnNext from './localization/reaccommodationLocalizationEn.json';
import * as loyaltyLocalizationEnNext from './localization/loyaltyLocalizationEn.json';
import * as serviceformsLocalizationEnNext from './localization/serviceformsLocalizationEn.json';
import * as refundsLocalizationEnNext from './localization/refundsLocalizationEn.json';
import * as corporateLocalizationEnNext from './localization/corporateLocalizationEn.json';
import * as travelReadyLocalizationEnNext from './localization/travelReadyLocalizationEn.json';
import * as globalLocalizationFiFiNext from './localization/globalLocalizationFiFi.json';
import * as bookingLocalizationFiFiNext from './localization/bookingLocalizationFiFi.json';
import * as mmbLocalizationFiFiNext from './localization/mmbLocalizationFiFi.json';
import * as reaccommodationLocalizationFiFiNext from './localization/reaccommodationLocalizationFiFi.json';
import * as loyaltyLocalizationFiFiNext from './localization/loyaltyLocalizationFiFi.json';
import * as serviceformsLocalizationFiFiNext from './localization/serviceformsLocalizationFiFi.json';
import * as refundsLocalizationFiFiNext from './localization/refundsLocalizationFiFi.json';
import * as corporateLocalizationFiFiNext from './localization/corporateLocalizationFiFi.json';
import * as travelReadyLocalizationFiFiNext from './localization/travelReadyLocalizationFiFi.json';

export default {
  globalLocalizationEnNext,
  bookingLocalizationEnNext,
  mmbLocalizationEnNext,
  reaccommodationLocalizationEnNext,
  loyaltyLocalizationEnNext,
  serviceformsLocalizationEnNext,
  refundsLocalizationEnNext,
  corporateLocalizationEnNext,
  travelReadyLocalizationEnNext,
  globalLocalizationFiFiNext,
  bookingLocalizationFiFiNext,
  mmbLocalizationFiFiNext,
  reaccommodationLocalizationFiFiNext,
  loyaltyLocalizationFiFiNext,
  serviceformsLocalizationFiFiNext,
  refundsLocalizationFiFiNext,
  corporateLocalizationFiFiNext,
  travelReadyLocalizationFiFiNext,
};
export const localization = {
  en: mergeDeepMany(inTranslation.globalLocalizationEn, globalLocalizationEnNext, workInProgress.globalLocalizationEn),
  'fi-fi': mergeDeepMany(
    inTranslation.globalLocalizationFiFi,
    globalLocalizationFiFiNext,
    workInProgress.globalLocalizationFiFi
  ),
};

export const bookingLocalization = {
  en: mergeDeepMany(
    inTranslation.bookingLocalizationEn,
    bookingLocalizationEnNext,
    workInProgress.bookingLocalizationEn
  ),
  'fi-fi': mergeDeepMany(
    inTranslation.bookingLocalizationFiFi,
    bookingLocalizationFiFiNext,
    workInProgress.bookingLocalizationFiFi
  ),
};

export const featureLocalizations = {
  en: {
    MMB: mergeDeepMany(inTranslation.mmbLocalizationEn, mmbLocalizationEnNext, workInProgress.mmbLocalizationEn),
    REACCOMMODATION: mergeDeepMany(
      inTranslation.reaccommodationLocalizationEn,
      reaccommodationLocalizationEnNext,
      workInProgress.reaccommodationLocalizationEn
    ),
    LOYALTY: mergeDeepMany(
      inTranslation.loyaltyLocalizationEn,
      loyaltyLocalizationEnNext,
      workInProgress.loyaltyLocalizationEn
    ),
    SERVICE_FORMS: mergeDeepMany(
      inTranslation.serviceformsLocalizationEn,
      serviceformsLocalizationEnNext,
      workInProgress.serviceformsLocalizationEn
    ),
    REFUNDS: mergeDeepMany(
      inTranslation.refundsLocalizationEn,
      refundsLocalizationEnNext,
      workInProgress.refundsLocalizationEn
    ),
    CORPORATE: mergeDeepMany(
      inTranslation.corporateLocalizationEn,
      corporateLocalizationEnNext,
      workInProgress.corporateLocalizationEn
    ),
    TRAVEL_READY: mergeDeepMany(
      inTranslation.travelReadyLocalizationEn,
      travelReadyLocalizationEnNext,
      workInProgress.travelReadyLocalizationEn
    ),
  },
  'fi-fi': {
    MMB: mergeDeepMany(inTranslation.mmbLocalizationFiFi, mmbLocalizationFiFiNext, workInProgress.mmbLocalizationFiFi),
    REACCOMMODATION: mergeDeepMany(
      inTranslation.reaccommodationLocalizationFiFi,
      reaccommodationLocalizationFiFiNext,
      workInProgress.reaccommodationLocalizationFiFi
    ),
    LOYALTY: mergeDeepMany(
      inTranslation.loyaltyLocalizationFiFi,
      loyaltyLocalizationFiFiNext,
      workInProgress.loyaltyLocalizationFiFi
    ),
    SERVICE_FORMS: mergeDeepMany(
      inTranslation.serviceformsLocalizationFiFi,
      serviceformsLocalizationFiFiNext,
      workInProgress.serviceformsLocalizationFiFi
    ),
    REFUNDS: mergeDeepMany(
      inTranslation.refundsLocalizationFiFi,
      refundsLocalizationFiFiNext,
      workInProgress.refundsLocalizationFiFi
    ),
    CORPORATE: mergeDeepMany(
      inTranslation.corporateLocalizationFiFi,
      corporateLocalizationFiFiNext,
      workInProgress.corporateLocalizationFiFi
    ),
    TRAVEL_READY: mergeDeepMany(
      inTranslation.travelReadyLocalizationFiFi,
      travelReadyLocalizationFiFiNext,
      workInProgress.travelReadyLocalizationFiFi
    ),
  },
};
