/* eslint-disable */
export enum ServiceFlow {
  CHANGE_FLOW = 'CHANGE_FLOW',
  REVIEW_AND_PAY = 'REVIEW_AND_PAY',
  MMB_ANCILLARY = 'MMB_ANCILLARY',
  BOOKING = 'BOOKING',
  AWARD = 'AWARD',
  CANCEL_FLOW = 'CANCEL_FLOW'
}

