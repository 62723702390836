import { Pipe, PipeTransform } from '@angular/core';

import { replaceMsgFormat, type ReplaceParams } from '../../core/utils';

@Pipe({
  name: 'finMsgFormat',
  pure: true,
})
export class MsgFormatPipe implements PipeTransform {
  transform(input: string, args: ReplaceParams = []): string {
    return replaceMsgFormat(input, args);
  }
}
