<ng-container *ngIf="heroConfig$ | async as config">
  <fin-destination-hero
    [title]="config.title"
    [backgroundImage]="config.backgroundImage"
    [destinationCode]="config.destinationCode"
    [enableAward]="config.enableAward"
    [enableAurinkomatkat]="config.enableAurinkomatkat"
    [enablePromoCode]="config.enablePromoCode"
    [contentType]="config.contentType"
    [isInCampaign]="config.isInCampaign"
    [campaignLabel]="config.campaignLabel"
    [campaignEndText]="config.campaignEndText"
    [enableBlackTeaser]="config.campaignBlackTeaser"
  >
  </fin-destination-hero>
</ng-container>
