import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Store } from '@ngrx/store';

import { ButtonMode, ButtonTheme, NotificationTheme } from '@fcom/ui-components';
import { JoinService, LoginService } from '@fcom/common/login/services';
import { GtmService } from '@fcom/common/gtm/services/gtm.service';
import { ElementActions, ElementTypes, GaContext } from '@fcom/common/interfaces';
import { AppState } from '@fcom/core/interfaces';
import { LoginActions } from '@fcom/core/actions';
import { LoginStep } from '@fcom/core-api/login';
import { ConfigService } from '@fcom/core/services';

import { ScrollService } from '../../../services';

@Component({
  selector: 'fin-login-card',
  templateUrl: './login-card.component.html',
  styleUrls: ['./login-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginCardComponent {
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonMode = ButtonMode;
  readonly NotificationTheme = NotificationTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  /**
   * Whether to show the Finnair Plus icon in the right upper corner
   */
  @Input()
  showIcon: boolean;

  @Input()
  footnoteText: string;

  /**
   * Mode to determine how to show benefits of logging in.
   * `list` shows a list and `text` shows a sentence.
   */
  @Input()
  benefitMode: 'list' | 'text' = 'list';

  /**
   * Title shown at the top of the card
   */
  @Input()
  title: string;

  @Input()
  benefitText: string;

  @Input()
  showForgotPassword = false;

  @Input()
  scrollOnCta = false;

  constructor(
    private loginService: LoginService,
    private gtmService: GtmService,
    private store$: Store<AppState>,
    private scrollService: ScrollService,
    private configService: ConfigService,
    public joinService: JoinService
  ) {}

  onJoinClick(): void {
    this.gtmService.trackElement(
      'join-promotion',
      GaContext.FINNAIR_PLUS,
      ElementTypes.LINK,
      undefined,
      ElementActions.CLICK
    );
    if (this.configService.cfg.enableJoinDialog) {
      if (this.scrollOnCta) {
        this.scrollService.scrollTop();
      }
      this.joinService.joinDialogOpen$.next(true);
    } else {
      this.loginService.redirectToJoin();
    }
  }

  onLoginClick(): void {
    this.gtmService.trackElement(
      'login-promotion',
      GaContext.FINNAIR_PLUS,
      ElementTypes.LINK,
      undefined,
      ElementActions.CLICK
    );
    if (this.configService.cfg.enableLoginDialog) {
      if (this.scrollOnCta) {
        this.scrollService.scrollTop();
      }
      this.store$.dispatch(LoginActions.setLoginPhaseStep({ step: LoginStep.CREDENTIALS }));
    } else {
      this.loginService.redirectToLogin();
    }
  }

  onForgotPasswordClick(): void {
    this.gtmService.trackElement(
      'forgot-pwd-promotion',
      GaContext.FINNAIR_PLUS,
      ElementTypes.LINK,
      undefined,
      ElementActions.CLICK
    );
    this.loginService.redirectToForgotPassword();
  }
}
