import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { AspectRatioString } from '@fcom/common';
import { CmsArticle, CmsImageData, CmsTaxonomy, CmsTaxonomyGeneral, CmsTeaser } from '@fcom/core-api';
import { isPresent, safeMap } from '@fcom/core/utils';
import { finShare } from '@fcom/rx';

@Component({
  selector: 'cms-text-with-side-image',
  styleUrls: ['./cms-text-with-side-image.component.scss'],
  templateUrl: './cms-text-with-side-image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsTextWithSideImageComponent implements OnInit {
  @Input()
  content$: Observable<CmsArticle>;

  cmsTags$: Observable<Array<CmsTaxonomy | string>>;

  aspectRatio$: Observable<AspectRatioString> = of('16x9' as AspectRatioString);
  textContent$: Observable<CmsArticle>;
  image$: Observable<CmsImageData>;
  link$: Observable<CmsTeaser>;
  isBlackFriday$: Observable<boolean>;
  showSectionMarker$: Observable<boolean>;

  public readonly TAXONOMY: typeof CmsTaxonomyGeneral = CmsTaxonomyGeneral;

  ngOnInit(): void {
    this.textContent$ = this.content$.pipe(
      filter(isPresent),
      map(({ media: _, ...rest }: CmsArticle) => ({ ...rest, media: [] })),
      finShare()
    );
    this.image$ = this.content$.pipe(
      filter(isPresent),
      map(({ media = [] }: CmsArticle) => media[0] as CmsImageData),
      finShare()
    );
    this.link$ = this.content$.pipe(
      filter(isPresent),
      map(({ related = [] }: CmsArticle) => related[0] as CmsTeaser),
      finShare()
    );
    this.cmsTags$ = this.content$.pipe(
      filter(isPresent),
      map((content: CmsArticle) => safeMap(content, (c: CmsArticle) => c.subjectTaxonomyTags)),
      distinctUntilChanged()
    );
    this.isBlackFriday$ = this.cmsTags$.pipe(map((a) => a.includes(this.TAXONOMY.BLACK_FRIDAY)));
    this.showSectionMarker$ = this.cmsTags$.pipe(map((a) => !a.includes(this.TAXONOMY.NO_SECTION_MARKER)));
  }
}
