import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ConfigService, SentryLogger } from '@fcom/core/services';
import { ConsentTextId } from '@fcom/common';

import {
  ETicketReceiptSubmissionData,
  ETicketReceiptResponseData,
  ETicketReceiptFormData,
  UpdateProfileResponseData,
} from '../interfaces';

@Injectable()
export class SubmitService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private sentryLogger: SentryLogger
  ) {}

  public directMarketingPreferences(
    formData: any,
    memberNumber: string,
    token: string
  ): Observable<UpdateProfileResponseData> {
    const apiUrl = this.configService.cfg.loyaltyServices.legacy.loyaltyMemberUrl;

    const httpOptions = {
      headers: new HttpHeaders({
        // eslint-disable-next-line camelcase
        oauth_token: token,
      }),
    };

    const data = {
      language: formData.preferredLanguage.toLowerCase(),
      memberNumber,
      marketingPermissions: {
        suppressAllEmails: !formData[ConsentTextId.FLIGHT_OFFERS],
        suppressPartnerPromotions: !formData[ConsentTextId.THIRD_PARTY_OFFERS],
        suppressSMSPromos: !formData[ConsentTextId.SMS_MESSAGING],
        suppresseMailWeeklyOffers: !formData[ConsentTextId.MEMBER_OFFERS],
      },
    };

    return this.http.put<UpdateProfileResponseData>(apiUrl, data, httpOptions).pipe(
      map((response) => {
        if (response.status.message === 'OK') {
          return response;
        }
        throw new Error('Error submitting update profile request');
      }),
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      catchError((err) => {
        this.sentryLogger.warn(err.message, { error: err });
        return throwError(() => err);
      })
    );
  }

  public eTicketReceipt(formValues: ETicketReceiptFormData): Observable<ETicketReceiptResponseData> {
    const { apiUrl, keyId } = this.configService.cfg.serviceForm.eTicketReceipt;

    const submitQuery: ETicketReceiptSubmissionData = {
      lastName: formValues.eTicketReceipt.lastName,
      recLoc: formValues.eTicketReceipt.reservationNumber.toUpperCase(), // API requires uppercase
    };

    const httpOptions = {
      headers: this.buildHeaders({ 'x-api-key': keyId }),
    };

    return this.http.post<ETicketReceiptResponseData>(apiUrl, submitQuery, httpOptions).pipe(
      map((response) => {
        if (response.delivered) {
          return response;
        }
        throw new Error(response.status.message);
      }),
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      catchError((err) => {
        if (err.message !== 'booking_not_found') {
          this.sentryLogger.warn('Error submitting eTicketReceipt request', { error: err });
        }
        return throwError(() => err);
      })
    );
  }

  private buildHeaders(params: object): HttpHeaders {
    return new HttpHeaders(
      Object.assign(
        {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=utf-8',
        },
        params
      )
    );
  }
}
