import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { NEVER, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CmsTarget, CmsTaxonomy, CmsTaxonomyGeneral } from '@fcom/core-api';

@Component({
  selector: 'fin-link-block',
  styleUrls: ['./link-block.component.scss'],
  templateUrl: './link-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkBlockComponent implements OnInit {
  @Input()
  teaserTitle$: Observable<string>;
  @Input()
  target$: Observable<CmsTarget>;
  @Input()
  cmsTags$: Observable<Array<CmsTaxonomy | string>> = NEVER;

  isBlackFriday$: Observable<boolean>;

  ngOnInit(): void {
    this.isBlackFriday$ = this.cmsTags$.pipe(map((a) => a.includes(CmsTaxonomyGeneral.BLACK_FRIDAY)));
  }
}
