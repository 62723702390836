import { Component, OnInit } from '@angular/core';

import { LanguageService } from '@fcom/ui-translate';
import { RootPaths } from '@fcom/core/constants';

import { OndMarketingRoutesPath } from '../interfaces';

@Component({
  selector: 'fin-ond-marketing-ribbon',
  templateUrl: './ond-marketing-ribbon.component.html',
  styleUrls: ['./ond-marketing-ribbon.component.scss'],
})
export class OndMarketingRibbonComponent implements OnInit {
  links: string[] = [];
  linkTranslationKeys: string[] = ['from', 'fromCountry', 'cityToCity', 'countryToCountry'];
  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {
    const lang = `/${this.languageService.langValue}/`;

    this.links = Object.values(OndMarketingRoutesPath).map(
      (item) => `${lang}${RootPaths.OND_MARKETING_LANDING_ROOT}/${item}`
    );
  }
}
