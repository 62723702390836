import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'fin-form-input-group',
  templateUrl: 'form-input-group.component.html',
})
export class FormInputGroupComponent {
  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  id: string;

  @Input()
  translationPrefix: string;

  @Input()
  translationErrorPrefix: string;

  @Input()
  required = false;

  constructor() {}

  get ctrlField(): AbstractControl {
    return this.parentForm.get(this.id);
  }
}
