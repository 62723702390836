import { AppState } from '@fcom/core';

import { Trip } from './pax-trip.interface';
import { LOYALTY_FEATURE_KEY } from '../constants';

export interface TripsState {
  trips: Trip[];
  status: FetchTripsStatus;
}

export interface MilestoneState {
  dismissedWithPoints: number;
}

export interface LoyaltyState {
  trips: TripsState;
  milestones: MilestoneState;
}

export interface State extends AppState {
  readonly [LOYALTY_FEATURE_KEY]: LoyaltyState;
}

export enum FetchTripsStatus {
  INITIAL,
  OK,
  ERROR,
}
