import { AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';

import {
  BreadcrumbService,
  ElementActions,
  ElementTypes,
  isNavigationEvent,
  NavigationMenuService,
} from '@fcom/common';
import { isPresent, sanitizeWhiteListedPath, unsubscribe } from '@fcom/core/utils';
import { GaContext, LoaderType } from '@fcom/common/interfaces';
import { CmsContent, CmsContentType, CmsPage, CmsTemplate, CmsViewType, PageViewType } from '@fcom/core-api';
import { ConfigService } from '@fcom/core';
import { ddsLangToNewLang } from '@fcom/core/selectors/dds-languages';
import { LanguageService } from '@fcom/ui-translate';
import { finShare } from '@fcom/rx';
import { GtmService } from '@fcom/common/gtm';

import { CmsTemplateService } from '../../services';

const mapToRenderSwitch = (header: CmsContent | CmsPage): PageViewType => {
  if (header.viewTypeName && header.viewTypeName === PageViewType.SECTION_PAGE_EXTERNAL_TEMPLATE) {
    return PageViewType.SECTION_PAGE_EXTERNAL_TEMPLATE;
  } else if (header.navigationContentType && header.navigationContentType === CmsContentType.FIDestination) {
    return PageViewType.DESTINATION_PAGE;
  } else {
    return header.viewTypeName as PageViewType; // shorthand to return PageViewType that is the same
  }
};

const FRONTPAGE_TOP_DESTINATIONS_TEXT_MASTER_CONTENT_ID = 2430456;

/**
 * Renders a CMS-based route by fetching the template straight from CMS.
 */
@Component({
  selector: 'fin-dynamic-route',
  templateUrl: './dynamic-route.component.html',
  styleUrls: ['./dynamic-route.component.scss'],
})
export class DynamicRouteComponent implements AfterViewInit, OnInit, OnDestroy {
  @HostBinding('class.block') defaultClass = true;

  readonly DOCTYPE: typeof CmsContentType = CmsContentType;
  readonly VIEWTYPE: typeof CmsViewType = CmsViewType;
  readonly PAGETYPE: typeof PageViewType = PageViewType;
  readonly LoaderType = LoaderType;

  path$: ReplaySubject<string> = new ReplaySubject(1);
  template$: Observable<CmsTemplate>;
  pageViewType$: Observable<string>;
  hasContent$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  renderSwitch$: Observable<string>;
  title$: Observable<string>;
  teaserTitle$: Observable<string>;
  showSideTitle$: Observable<boolean>;
  page$: Observable<CmsPage>;
  private subscription: Subscription = new Subscription();

  enablePageFeedback: boolean;

  constructor(
    private router: Router,
    private elementRef: ElementRef,
    private cmsTemplateService: CmsTemplateService,
    private breadcrumbService: BreadcrumbService,
    private navigationMenuService: NavigationMenuService,
    private configService: ConfigService,
    private languageService: LanguageService,
    private gtmService: GtmService
  ) {
    this.subscription.add(
      this.router.events
        .pipe(
          startWith(new NavigationEnd(1, this.router.url, this.router.url)),
          filter(isNavigationEvent),
          map((event: NavigationEnd) => event.url.replace(/\(.*\)/, '')),
          map((url: string) => (ddsLangToNewLang(url) ? `/${ddsLangToNewLang(url)}/error` : url)),
          distinctUntilChanged((prev: string, next: string) => {
            return this.getUrlWithoutQueryParams(prev) === this.getUrlWithoutQueryParams(next);
          })
        )
        .subscribe((url) => {
          this.path$.next(sanitizeWhiteListedPath(url, this.configService.cfg));
        })
    );
  }

  ngOnInit(): void {
    const translation$ = this.languageService.translate('trendingDestinations').pipe(take(1));

    this.template$ = this.path$.pipe(
      tap(() => this.hasContent$.next(false)), // Start Spinner side effect
      switchMap((path) => combineLatest([this.cmsTemplateService.load(path), translation$])),
      tap(() => this.hasContent$.next(true)), // End Spinner side effect
      tap(([template]) => {
        const cmsPage = template?.header?.[0] as CmsPage;
        if (cmsPage?.navigationPathList) {
          this.breadcrumbService.setBreadcrumbNormal(cmsPage.navigationPathList);
        }
        if (cmsPage?.oneworld) {
          this.navigationMenuService.setOneworldLink(cmsPage?.oneworld);
        }
        if (isPresent(cmsPage?.isHeaderVisible)) {
          this.navigationMenuService.setHeaderVisibility(cmsPage.isHeaderVisible);
        }
        if (isPresent(cmsPage?.isFooterVisible)) {
          this.navigationMenuService.setFooterVisibility(cmsPage.isFooterVisible);
        }
      }),
      map(([template, translation]) => {
        const isFrontPage = template?.header?.[0]?.navigationPathList?.length === 1;
        if (isFrontPage) {
          template.main.forEach((item) => {
            // handling top destinations label
            if (item.masterContentId === FRONTPAGE_TOP_DESTINATIONS_TEXT_MASTER_CONTENT_ID) {
              item.detailText = `<h2>${translation}</h2>`;
              item.teaserText = `<h2>${translation}</h2>`;
            }
            // handling the trending destinations
            if (item.viewType === CmsViewType.OFFERS_GRID) {
              item.viewType = CmsViewType.TRENDING_DESTINATIONS;
            }
          });
        }
        return template;
      }),
      finShare()
    );

    this.page$ = this.template$.pipe(
      map((template) => template.header.find((i) => i.template === 'cms-page') as CmsPage),
      filter(Boolean)
    );

    this.renderSwitch$ = this.template$.pipe(map((a) => (a?.header?.[0] ? mapToRenderSwitch(a.header[0]) : '')));
    this.pageViewType$ = this.template$.pipe(map((a) => a?.header?.[0]?.viewTypeName ?? ''));

    // Show side title should be toggled to true if main content has a picture or disruption as first element
    this.showSideTitle$ = this.template$.pipe(
      map((template) => {
        return !!(template.main.length > 0 && template.main[0].picture);
      })
    );

    this.title$ = this.template$.pipe(
      map((template: CmsTemplate) => template?.header?.[0]?.title ?? ''),
      startWith('') // for aria-live announcer to notice page changes
    );

    this.teaserTitle$ = this.template$.pipe(
      map((template: CmsTemplate) => template?.header?.[0]?.teaserTitle ?? ''),
      startWith('') // for aria-live announcer to notice page changes
    );

    if (this.configService.cfg.enableQualtricsPageFeedback) {
      this.enablePageFeedback = true;
    }
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.addEventListener('click', this.linkListener);
  }

  ngOnDestroy(): void {
    this.path$.complete();
    this.elementRef.nativeElement.removeEventListener('click', this.linkListener);
    unsubscribe(this.subscription);
  }

  getUrlWithoutQueryParams(url: string): string {
    return url.split('?')[0];
  }

  private linkListener = this.linkHandler.bind(this);

  private linkHandler(e: MouseEvent): void {
    const target = (e.target as Element).closest('a');

    if (target) {
      this.trackTargetBlank(target);
    }
  }

  private trackTargetBlank(element: HTMLAnchorElement): void {
    if (element.target === '_blank' && !element.href.startsWith(this.configService.cfg.baseUrl)) {
      this.gtmService.trackElement('new-tab', GaContext.CONTENT, ElementTypes.LINK, element.href, ElementActions.CLICK);
    }
  }
}
