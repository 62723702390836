import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  Type,
  ViewContainerRef,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

/**
 * This component can be used to dynamically disable rendering of components on server side.
 *
 * @example
 * <code><pre>
 * @NgModule({
 *   imports: [ ComponentsModule ]
 *   declarations: [ TestComponent ],
 *   // make it possible to use component wihout selector
 *   entryComponents: [ TestComponent ],
 * })
 * export class TestModule {
 * }
 *
 *
 * @Component({
 *   template: '&lt;fin-client-only [componentType]="componentType">&lt;/fin-client-only>'
 * })
 * export class RootComponent {
 *  public readonly componentType: typeOf TestComponent = TestComponent;
 * }
 * </pre></code>
 *
 * @example
 * In the routes definition:
 *
 * <code><pre>
 * const route: Route = {
 *   path: 'route/to/client-component',
 *   component: ClientOnlyComponent,
 *   data: {
 *     componentType: TestComponent
 *   }
 * };
 * </code></pre>
 *
 */
@Component({
  selector: 'fin-client-only',
  template: '',
})
export class ClientOnlyComponent<T> implements OnInit, OnDestroy {
  @Input()
  componentType: Type<T>;

  componentReference: ComponentRef<T>;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      const componentType: Type<T> = this.componentType || this.activatedRoute.snapshot.data['componentType'];
      if (!componentType) {
        throw new Error('Component type not found from input or activatedRoute data');
      }
      this.load(componentType);
    }
  }

  ngOnDestroy(): void {
    if (this.componentReference) {
      this.componentReference.destroy();
    }
    this.viewContainerRef.clear();
  }

  private load(componentType: Type<T>): void {
    const componentFactory: ComponentFactory<T> = this.componentFactoryResolver.resolveComponentFactory(componentType);
    this.componentReference = this.viewContainerRef.createComponent(componentFactory);
  }
}
