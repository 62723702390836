export enum Stage {
  LOGIN = '',
  LOADING = 'loading',
  PASSENGERS = 'passengers',
  FLIGHTS = 'flights',
  REVIEW = 'review',
  PROCESSING = 'processing',
  SUCCESS = 'success',
  ERROR = 'error',
}
