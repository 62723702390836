import { FinnairAirBoundsResponse, FinnairBoundGroup } from '@fcom/dapi/api/models';
import { FinnairBoundItemWithLocation } from '@fcom/common-booking/interfaces/flight-selection.interface';

export interface FinnairBoundGroupWithLocation extends FinnairBoundGroup {
  details: FinnairBoundItemWithLocation;
  hasSpecialOffers?: boolean;
}

export interface FinnairAirBounds extends FinnairAirBoundsResponse {
  boundGroups: FinnairBoundGroupWithLocation[];
}

export enum SelectionPhase {
  OUTBOUND = 'OUTBOUND',
  INBOUND = 'INBOUND',
  MULTICITY = 'MULTICITY',
}
