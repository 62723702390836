import { Component, ElementRef, HostListener, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Store } from '@ngrx/store';
import { BehaviorSubject, filter, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { ButtonTheme, PopoverService } from '@fcom/ui-components';
import { LoginStatus, LoginStep, Profile, ProfileTier } from '@fcom/core-api/login';
import { loginStatus, profileOrUndefinedIfNotLoggedIn } from '@fcom/core/selectors';
import { AppState, ConfigService } from '@fcom/core';
import { addClass, removeClass } from '@fcom/core/utils';
import { finShare, keepState } from '@fcom/rx';
import { LoginActions } from '@fcom/core/actions';
import { GtmService } from '@fcom/common/gtm';

import { JoinService, LoginService } from '../../services';
import { ElementActions, ElementTypes, GaContext, onOpenGtmEvent } from '../../../interfaces';

const STEP_CHANGE_TRANSITION_DURATION_MS = 500;

export enum LoginWidgetContext {
  PORTAL = 'portal',
  BOOKING = 'booking',
  MMB = 'mmb',
}

@Component({
  selector: 'fin-login-widget',
  templateUrl: './login-widget.component.html',
  styleUrls: ['./login-widget.component.scss'],
})
export class LoginWidgetComponent implements OnInit {
  readonly LoginStatus = LoginStatus;
  readonly ButtonTheme = ButtonTheme;
  readonly loginStatus$: Observable<LoginStatus>;
  readonly LoginWidgetContext = LoginWidgetContext;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly darkBackgroundTiers = [
    ProfileTier.SILVER,
    ProfileTier.GOLD,
    ProfileTier.PLATINUM,
    ProfileTier.LUMO,
    ProfileTier.CORPORATE,
  ];

  @Input()
  context: LoginWidgetContext = LoginWidgetContext.PORTAL;

  profile$: Observable<Profile>;
  isOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  widgetInDom$: Observable<boolean>;
  subscription = new Subscription();
  darkBg$: Observable<boolean>;

  constructor(
    private store$: Store<AppState>,
    private elementRef: ElementRef,
    private loginService: LoginService,
    private joinService: JoinService,
    private configService: ConfigService,
    private popoverService: PopoverService,
    private gtmService: GtmService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.loginStatus$ = this.store$.pipe(loginStatus(), finShare());
    this.widgetInDom$ = this.isOpen$.pipe(keepState(STEP_CHANGE_TRANSITION_DURATION_MS));
  }

  ngOnInit(): void {
    this.profile$ = this.store$.pipe(profileOrUndefinedIfNotLoggedIn(), finShare());
    this.darkBg$ = this.profile$.pipe(map((userProfile) => this.darkBackgroundTiers.includes(userProfile?.tier)));
    this.subscription.add(
      this.popoverService.popoverOpen$.pipe(filter(Boolean)).subscribe(() => {
        this.close();
      })
    );
  }

  open(): void {
    this.popoverService.close();
    this.isOpen$.next(true);
    addClass(this.document.body, 'overflow-hidden-tablet-down');
  }

  close(): void {
    this.isOpen$.next(false);
    removeClass(this.document.body, 'overflow-hidden-tablet-down');
  }

  redirectToLogin(): void {
    if (this.configService.cfg.enableLoginDialog) {
      this.store$.dispatch(LoginActions.setLoginPhaseStep({ step: LoginStep.CREDENTIALS }));
      this.close();
    } else {
      this.loginService.redirectToLogin();
    }
  }

  redirectToJoin(): void {
    if (this.configService.cfg.enableJoinDialog) {
      this.gtmService.trackElement(
        `join-form-open-${onOpenGtmEvent}`,
        GaContext.FINNAIR_PLUS,
        ElementTypes.BUTTON,
        undefined,
        ElementActions.CLICK
      );

      this.joinService.joinDialogOpen$.next(true);
      this.close();
    } else {
      this.loginService.redirectToJoin();
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.close();
    }
  }
}
