import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiTranslateModule } from '@fcom/ui-translate';
import { DirectiveModule } from '@fcom/common/directives';

import { IconsModule } from '../icons/icons.module';
import { ButtonComponent } from './button/index';
import { CtaButtonComponent } from './cta-button/cta-button.component';
import { LoaderModule } from '../loader';
import { IconButtonComponent } from './icon-button/index';
@NgModule({
  declarations: [ButtonComponent, CtaButtonComponent, IconButtonComponent],
  imports: [CommonModule, UiTranslateModule, IconsModule, LoaderModule, DirectiveModule],
  exports: [ButtonComponent, CtaButtonComponent, IconButtonComponent],
  providers: [],
})
export class ButtonModule {}
