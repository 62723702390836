<div class="social-icons flex">
  <a
    target="_blank"
    href="https://www.facebook.com/dialog/share?display=popup&app_id=150585651712541&href={{ url() | finEncode }}"
    class="social-icon circle no-underline flex flex--center ms-xxsmall-r"
    [ngClass]="colour()"
  >
    <fcom-icon
      class="icon-small flex--nogrow white-text"
      [dataTestId]="'icon-' + SvgLibraryIcon.FACEBOOK"
      [name]="SvgLibraryIcon.FACEBOOK"
    ></fcom-icon>
  </a>
  <a
    target="_blank"
    href="https://twitter.com/intent/tweet?url={{ url() | finEncode }}"
    class="social-icon circle no-underline flex flex--center"
    [ngClass]="colour()"
  >
    <fcom-icon class="icon-small flex--nogrow white-text" [name]="SvgLibraryIcon.X"></fcom-icon>
  </a>
</div>
