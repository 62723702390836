<div *ngIf="activeNaviSection$ | async as section" class="section-navi-wrapper" data-elastic-exclude>
  <div class="section-navi df-laptop-down flex--column fill">
    <nav [attr.aria-label]="(activeNaviItem$ | async).teaserTitle">
      <ul
        class="section-navi-items no-list-style mr-xlarge-t mr-small-l ps-large-b nordic-blue-900-text section-desktop-body dn-laptop-down"
      >
        <li>
          <a
            [finLink]="section.link"
            [external]="section.external"
            [finElemTracker]="'level-1: ' + section.link"
            [finElemContext]="TRACKING_CONTEXT"
            [finElemType]="ElementTypes.LINK"
            [attr.target]="section.openInNewTab ? '_blank' : ''"
            [class.active]="section.active"
            [attr.aria-current]="section.active ? 'page' : null"
            [attr.aria-disabled]="section.active ? 'true' : null"
            class="section-navi-item nordic-blue-900-text block ps-small-l mr-xxsmall-y no-decoration flex flex--middle border-none bold-type"
          >
            {{ section.linkText || section.teaserTitle }}
            <span *ngIf="section.active" class="is-vishidden">{{ 'navigation.currentPage' | finLocalization }}</span
            ><fcom-icon
              *ngIf="section.external"
              class="icon-small ms-xxsmall-l"
              [name]="SvgLibraryIcon.EXTERNAL_TAB"
            ></fcom-icon>
            <fcom-icon
              *ngIf="section.loginRequired"
              class="icon-small ms-xxsmall-l"
              [name]="SvgLibraryIcon.LOCK_OPEN"
            ></fcom-icon>
          </a>
        </li>
        <ng-container *ngFor="let item of section.items; trackBy: trackItems">
          <li *ngIf="item.link && !item.hideInSectionNavi" class="ms-small-l">
            <a
              [finLink]="item.link"
              [external]="item.external"
              [finElemTracker]="'level-1: ' + item.link"
              [finElemContext]="TRACKING_CONTEXT"
              [finElemType]="ElementTypes.LINK"
              [class.active]="item.active"
              [attr.aria-disabled]="item.active ? 'true' : null"
              [attr.aria-current]="item.active ? 'page' : null"
              [attr.target]="item.openInNewTab ? '_blank' : ''"
              class="section-navi-item nordic-blue-900-text block ps-small-x mr-xxsmall-y no-decoration flex flex--middle"
            >
              {{ item.teaserTitle }}
              <span *ngIf="item.active" class="is-vishidden">{{ 'navigation.currentPage' | finLocalization }}</span
              ><fcom-icon
                *ngIf="item.external"
                class="icon-small ms-xxsmall-l"
                [name]="SvgLibraryIcon.EXTERNAL_TAB"
              ></fcom-icon>
              <fcom-icon
                *ngIf="item.loginRequired"
                class="icon-small ms-xxsmall-l"
                [name]="SvgLibraryIcon.LOCK_OPEN"
              ></fcom-icon>
            </a>
          </li>
        </ng-container>
      </ul>

      <fin-expand
        class="relative block fill section-accordion-body dn-laptop-up"
        [key]="'section-navi'"
        [id]="'section-navi'"
        [finElemTracker]="'navigation-open: ' + section.link"
        [finElemContext]="TRACKING_CONTEXT"
        [finElemType]="ElementTypes.LINK"
        [class.dn-laptop-down]="section.items.length < 1"
        #sectionNavi
      >
        <div class="trigger flex flex--center pr-medium-b pr-large-t">
          <div class="flex-1 ps-medium-l">
            <div class="flex flex--start nordic-blue-900-text transform-none font-body-1 bold-type">
              {{ (activeNaviItem$ | async).linkText || (activeNaviItem$ | async).teaserTitle }}
            </div>
          </div>
          <div class="ps-medium-r">
            <fcom-icon
              class="flex-1 flex--nogrow rotate nordic-blue-900-text inline-block icon-medium"
              [class.rotate-active]="sectionNavi.isOpen$ | async"
              [name]="SvgLibraryIcon.CHEVRON_DOWN"
            ></fcom-icon>
          </div>
        </div>
        <div class="content">
          <ul class="section-navi-items no-list-style ps-large nordic-blue-900-text">
            <li>
              <a
                [finLink]="section.link"
                [class.active]="section.active"
                (click)="toggleDetails(sectionNavi, section.external)"
                [external]="section.external"
                [finElemTracker]="'level-1: ' + section.link"
                [finElemContext]="TRACKING_CONTEXT"
                [finElemType]="ElementTypes.LINK"
                [attr.target]="section.openInNewTab ? '_blank' : ''"
                [attr.aria-disabled]="section.active ? 'true' : null"
                [attr.aria-current]="section.active ? 'page' : null"
                class="section-navi-item nordic-blue-900-text ps-small-l block mr-xsmall-y no-decoration flex flex--middle bold-type"
              >
                {{ section.linkText || section.teaserTitle }}
                <span *ngIf="section.active" class="is-vishidden">{{ 'navigation.currentPage' | finLocalization }}</span
                ><fcom-icon
                  *ngIf="section.external"
                  class="icon-small ms-xxsmall-l"
                  [name]="SvgLibraryIcon.EXTERNAL_TAB"
                ></fcom-icon>
                <fcom-icon
                  *ngIf="section.loginRequired"
                  class="icon-small ms-xxsmall-l"
                  [name]="SvgLibraryIcon.LOCK_OPEN"
                ></fcom-icon>
              </a>
            </li>
            <ng-container *ngFor="let item of section.items; trackBy: trackItems">
              <li *ngIf="item.link && !item.hideInSectionNavi" class="ms-small-l">
                <a
                  [finLink]="item.link"
                  [external]="item.external"
                  [finElemTracker]="'level-1: ' + section.link"
                  [finElemContext]="TRACKING_CONTEXT"
                  [finElemType]="ElementTypes.LINK"
                  [class.active]="item.active"
                  (click)="toggleDetails(sectionNavi, item.external)"
                  [attr.target]="item.openInNewTab ? '_blank' : ''"
                  [attr.aria-disabled]="item.active ? 'true' : null"
                  [attr.aria-current]="item.active ? 'page' : null"
                  class="section-navi-item nordic-blue-900-text block ps-small-x ms-small-y no-decoration flex flex--middle"
                >
                  {{ item.teaserTitle }}
                  <span *ngIf="item.active" class="is-vishidden">{{ 'navigation.currentPage' | finLocalization }}</span
                  ><fcom-icon
                    *ngIf="item.external"
                    class="icon-small ms-xxsmall-l"
                    [name]="SvgLibraryIcon.EXTERNAL_TAB"
                  ></fcom-icon>
                  <fcom-icon
                    *ngIf="item.loginRequired"
                    class="icon-small ms-xxsmall-l"
                    [name]="SvgLibraryIcon.LOCK_OPEN"
                  ></fcom-icon>
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
      </fin-expand>
    </nav>
  </div>
</div>
