const CONVERT_NEW_LINES_KEY = 'convertNewLines';

export type ReplaceParams = Array<string | number> | { [key: string]: string | number };
/**
 * @example:
 * replaceMsgFormat('Hei {1}, olen {0}', ['Matti','Teppo']) => 'Hei Teppo, olen Matti'
 * 'adt' | finLocalization: {amount: 3}
 *
 * @param input the message format string
 * @param args substitutions
 * @returns {any} string
 */
export const replaceMsgFormat = (input: any, args: ReplaceParams = []): string => {
  if (typeof input !== 'string') {
    return input;
  }
  let result = input;
  Object.keys(args).forEach((key) => {
    const regex = new RegExp(`\\{${key}\\}`, 'g');
    result = result.replace(regex, args[key]);

    if (key === CONVERT_NEW_LINES_KEY && args[key] === 'true') {
      result = result.replace(/\\n/g, '\n');
    }
  });
  return result;
};

/**
 * @example:
 * replaceURIFormat('https://url.to/path?param1={p1}&param2={p2}', { p1: 'param1value', p2: 2 })
 * => 'https://url.to/path?param1=param1value&param2=2'
 *
 * @param input the message format string
 * @param args substitutions
 * @returns {any} string
 */
export const replaceURIFormat = (input: any, args: ReplaceParams = []): string => {
  if (typeof input !== 'string') {
    return input;
  }
  let result = input;
  Object.keys(args).forEach((key) => {
    const regex = new RegExp(`\\{${key}\\}`, 'g');
    result = result.replace(regex, encodeURIComponent(args[key]));

    if (key === CONVERT_NEW_LINES_KEY && args[key] === 'true') {
      result = result.replace(/\\n/g, '\n');
    }
  });
  return result;
};
