// https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie/Simple_document.cookie_framework

import { Injectable } from '@angular/core';

@Injectable()
export class CookieService {
  constructor() {}

  getItem(cookieKey: string): any {
    if (!cookieKey) {
      return null;
    }
    return (
      decodeURIComponent(
        document.cookie.replace(
          new RegExp(
            `(?:(?:^|.*;)\\s*${encodeURIComponent(cookieKey).replace(/[-.+*]/g, '\\$&')}\\s*=\\s*([^;]*).*$)|^.*$`
          ),
          '$1'
        )
      ) || null
    );
  }

  setItem(key: string, value: any, expires?: any, path?: string, domain?: string, isSecure?: boolean): boolean {
    let expiresValue = '';
    if (!key || /^(?:expires|max-age|path|domain|secure)$/i.test(key)) {
      return false;
    }
    if (expires) {
      switch (expires.constructor) {
        // eslint-disable-next-line id-blacklist
        case Number:
          expiresValue = expires === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : `; max-age=${expires}`;
          break;
        // eslint-disable-next-line id-blacklist
        case String:
          expiresValue = `; expires=${expires}`;
          break;
        case Date:
          expiresValue = `; expires=${expires.toUTCString()}`;
          break;
        default:
          break;
      }
    }
    if (!path) {
      path = '/';
    }
    const domainStr = domain ? `; domain=${domain}` : '';
    const pathStr = path ? `; path=${path}` : '';
    const secureStr = isSecure ? '; secure' : '';
    document.cookie = `${encodeURIComponent(key)}=${encodeURIComponent(
      value
    )}${expiresValue}${domainStr}${pathStr}${secureStr}`;
    return true;
  }

  hasItem(key: string): boolean {
    if (!key) {
      return false;
    }
    return new RegExp(`(?:^|;\\s*)${encodeURIComponent(key).replace(/[-.+*]/g, '\\$&')}\\s*\\=`).test(document.cookie);
  }

  removeItem(key: string, path?: string, domain?: string): boolean {
    if (!this.hasItem(key)) {
      return false;
    }
    const domainStr = domain ? `; domain=${domain}` : '';
    const pathStr = path ? `; path=${path}` : '';
    document.cookie = `${encodeURIComponent(key)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT${domainStr}${pathStr}`;
    return true;
  }

  keys(): any {
    return (
      document.cookie
        // eslint-disable-next-line no-useless-backreference
        .replace(/((?:^|\s*;)[^=]+)(?=;|$)|^\s*|\s*(?:=[^;]*)?(?:\1|$)/g, '')
        .split(/\s*(?:=[^;]*)?;\s*/)
        .map(decodeURIComponent)
    );
  }
}
