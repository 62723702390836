import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiTranslateModule } from '@fcom/ui-translate';
import { ButtonModule, IconsModule } from '@fcom/ui-components';
import { PersonalizationModule } from '@fcom/loyalty-personalization';

import { GtmModule } from '../../gtm';
import { PipesModule } from '../../pipes';
import { DirectiveModule } from '../../directives';
import { LoginModule } from '../../login/login.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CampaignBannerComponent } from './campaign-banner/campaign-banner.component';
import { HeaderComponent } from './header.component';
import { HeaderWithoutNavigationComponent } from './header-without-navigation.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NavigationTriggerModule } from './navigation-trigger.module';
import { ComponentsModule } from '../components.module';
import { MultivariateTestModule } from './../../multivariate-test/multivariate-test.module';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    PipesModule,
    DirectiveModule,
    NavigationTriggerModule,
    LoginModule,
    GtmModule,
    UiTranslateModule,
    IconsModule,
    PersonalizationModule,
    MultivariateTestModule,
    ButtonModule,
  ],
  declarations: [
    HeaderComponent,
    HeaderWithoutNavigationComponent,
    NavigationComponent,
    BreadcrumbComponent,
    CampaignBannerComponent,
  ],
  exports: [
    HeaderComponent,
    HeaderWithoutNavigationComponent,
    NavigationComponent,
    BreadcrumbComponent,
    CampaignBannerComponent,
  ],
})
export class NavigationModule {}
