import { createSelector } from '@ngrx/store';

import { BookingFeatureState } from '@fcom/common/interfaces/booking';
import { findCheapestMultiCityOffer } from '@fcom/common/utils/booking.utils';
import { ExtendedFareFamily } from '@fcom/common-booking/interfaces';

import { bookingFeatureState } from './booking-feature-state.selector';

export const selectMultiCity = createSelector(bookingFeatureState, (state: BookingFeatureState) => state.multiCity);

export const selectMultiCityOffers = createSelector(
  bookingFeatureState,
  (state: BookingFeatureState) => state.multiCity.offers
);

export const selectMultiCityAmountOfBounds = createSelector(
  bookingFeatureState,
  (state: BookingFeatureState) => state.multiCity.offers?.[0]?.itinerary?.length
);

export const selectMultiCitySelectedFareFamily = createSelector(
  bookingFeatureState,
  (state: BookingFeatureState) => state.multiCity.selectedFareFamily
);

export const selectMultiCitySelectedItinerary = createSelector(
  bookingFeatureState,
  (state: BookingFeatureState) => state.multiCity.selectedItinerary
);

export const selectMultiCitySelectedBounds = createSelector(bookingFeatureState, (state: BookingFeatureState) => {
  return state.multiCity.selectedItinerary.map((itineraryId) => {
    return state.multiCity.bounds[itineraryId];
  });
});

export const selectMultiCitySelectedItineraryDetails = createSelector(
  bookingFeatureState,
  (state: BookingFeatureState) => {
    return state.multiCity.selectedItinerary.map((itineraryId) => {
      return state.multiCity.bounds[itineraryId].itinerary;
    });
  }
);

export const selectMultiCityAvailableFareFamilies = createSelector(
  bookingFeatureState,
  (state: BookingFeatureState) => {
    const offers = state.multiCity;

    return Object.values(offers.fareFamilies)
      .map((fareFamily): ExtendedFareFamily => {
        const offersForFareFamily = offers.offers.filter((offer) => {
          return Object.values(offer.offerItems[0].fareFamilyCodePerBound)[0] === fareFamily.fareFamilyCode;
        });
        const cheapestPrice = findCheapestMultiCityOffer(offersForFareFamily);

        return {
          ...fareFamily,
          totalPrice: String(cheapestPrice.price),
        } as ExtendedFareFamily;
      })
      .sort((fareA, fareB) => {
        const order = offers.sorting?.fareFamilies || [];

        return order.indexOf(fareA.fareFamilyCode) - order.indexOf(fareB.fareFamilyCode);
      });
  }
);

export const selectMultiCityStatus = createSelector(
  bookingFeatureState,
  (state: BookingFeatureState) => state.multiCity.status
);

export const selectMultiCityRequestedMoreFlightOfferIds = createSelector(
  bookingFeatureState,
  (state: BookingFeatureState) => state.multiCity.requestedMoreFlightOfferIds
);

export const selectMultiCityFareFamiliesPerCabin = createSelector(
  bookingFeatureState,
  (state: BookingFeatureState) => state.multiCity.fareFamiliesPerCabin
);

export const selectMultiCityCurrency = createSelector(
  bookingFeatureState,
  (state: BookingFeatureState) => state.multiCity.currency
);
