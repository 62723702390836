/* eslint-disable no-underscore-dangle */
import { createSelector } from '@ngrx/store';

import { safeSelect, StateSelector } from '@fcom/core/selectors/selector-utils';
import { BoundType, PaxDetailsFormData } from '@fcom/dapi';
import { asPaxTypeKey } from '@fcom/dapi/utils';
import { isNotEmpty } from '@fcom/core/utils/utils';
import {
  Bound,
  FinnairAirBoundsResponse,
  FinnairBoundItem,
  FinnairCart,
  FinnairItineraryItem,
  FinnairPassengerCode,
  FinnairServiceItem,
  Offer,
  OfferList,
} from '@fcom/dapi/api/models';
import { mapServicesToBookingSummary } from '@fcom/common-booking/store/utils/services.utils';
import { BookingSummaryBound, BookingSummaryPassenger, BookingSummaryService } from '@fcom/common-booking';
import { formatDateOfBirth } from '@fcom/common-booking/utils/date-utils';
import { cloneDeep } from '@fcom/core/utils';
import { BookingAppState, BoundFareInformation, FareFamilyMap } from '@fcom/common/interfaces/booking';
import { combineServices } from '@fcom/common-booking/modules/ancillaries/utils';

import {
  inboundsSelector,
  outboundsSelector,
  selectedInboundAirBoundIdSelector,
  selectedInboundIdBoundsSelector,
  selectedOutboundAirBoundIdSelector,
  selectedOutboundIdBoundsSelector,
} from './bounds.selector';
import { cartDataSelector } from './cart.selector';
import {
  _currentOffer as currentOfferSelector,
  currentOffersSelector,
  fareFamiliesSelector,
  selectedInboundFareFamilyCodeSelector,
  selectedInboundIdSelector,
  selectedOutboundFareFamilyCodeSelector,
  selectedOutboundIdSelector,
} from './offers.selector';
import { paxDetailTravelerSelector } from './pax-details.selector';

const _createBookingSummaryBound = (
  bound: FinnairBoundItem,
  boundFareInformation: BoundFareInformation[],
  type: BoundType
): BookingSummaryBound => {
  const generateItinerary = (): FinnairItineraryItem[] => {
    if (boundFareInformation) {
      return bound.itinerary.reduce(
        (all, it) => {
          if (it.type === 'FLIGHT') {
            all.arr.push({
              ...it,
              cabinClass: boundFareInformation?.[all.index]
                ? boundFareInformation[all.index].cabinClass.toLowerCase()
                : undefined,
              bookingClass: boundFareInformation?.[all.index]
                ? boundFareInformation[all.index].bookingClass
                : undefined,
            });
            all.index = all.index + 1;
          } else {
            all.arr.push(it);
          }
          return all;
        },
        { index: 0, arr: [] }
      ).arr;
    }
    return bound.itinerary;
  };
  return {
    ...bound,
    itinerary: generateItinerary(),
    type,
  };
};

const _selectedOfferSummaryProjector =
  (type: BoundType) =>
  (
    flightOffers: OfferList,
    currentOffer: Offer,
    boundId: string,
    fareFamilies: FareFamilyMap,
    selectedFareFamilyCode: string
  ): BookingSummaryBound => {
    if (!flightOffers || !boundId) {
      return;
    }

    const journeyDirection = type === BoundType.outbound ? 'outbounds' : 'inbounds';
    const bound: Bound = flightOffers?.[journeyDirection]?.[boundId];
    const boundFareInformation: BoundFareInformation[] = currentOffer
      ? type === BoundType.outbound
        ? currentOffer.outboundFareInformation
        : currentOffer.inboundFareInformation || []
      : [];
    const fareFamilyName = fareFamilies?.[selectedFareFamilyCode]?.brandName;

    const boundWithFareFamilyDetails: Bound & any = {
      ...bound,
      fareFamily: {
        ...(bound as any).fareFamily,
        name: fareFamilyName,
      },
    };

    const summaryBound = fareFamilyName ? boundWithFareFamilyDetails : bound;

    return _createBookingSummaryBound(summaryBound, boundFareInformation, type);
  };

const _selectedOfferOutboundSummary = createSelector(
  currentOffersSelector,
  currentOfferSelector,
  selectedOutboundIdSelector,
  fareFamiliesSelector,
  selectedOutboundFareFamilyCodeSelector,
  _selectedOfferSummaryProjector(BoundType.outbound)
);

const _selectedOfferInboundSummary = createSelector(
  currentOffersSelector,
  currentOfferSelector,
  selectedInboundIdSelector,
  fareFamiliesSelector,
  selectedInboundFareFamilyCodeSelector,
  _selectedOfferSummaryProjector(BoundType.inbound)
);

const _selectedAirBoundSummaryProjector =
  (type: BoundType) =>
  (bounds: FinnairAirBoundsResponse, boundId: string, fareFamilyId: string): BookingSummaryBound => {
    if (!bounds || !boundId || !fareFamilyId) {
      return;
    }

    const bound = bounds.boundGroups.find((boundGroup) => boundGroup.sortingId === boundId);

    if (!bound) {
      return;
    }

    const boundFareFamily = bound.fareFamilies.find(({ id }) => id === fareFamilyId);

    if (!boundFareFamily) {
      return;
    }

    const { fareFamilyCode, fareInformation } = boundFareFamily;

    const enrichedBound: FinnairBoundItem = bound
      ? {
          ...bound.details,
          fareFamily: {
            ...bound.details.fareFamily,
            name: bounds.fareFamilies[fareFamilyCode]?.brandName,
          },
        }
      : undefined;

    return _createBookingSummaryBound(enrichedBound, fareInformation, type);
  };

const _selectedAirBoundOutboundSummary = createSelector(
  outboundsSelector,
  selectedOutboundIdBoundsSelector,
  selectedOutboundAirBoundIdSelector,
  _selectedAirBoundSummaryProjector(BoundType.outbound)
);

const _selectedAirBoundInboundSummary = createSelector(
  inboundsSelector,
  selectedInboundIdBoundsSelector,
  selectedInboundAirBoundIdSelector,
  _selectedAirBoundSummaryProjector(BoundType.inbound)
);

const _cartOfferSummaries = createSelector(cartDataSelector, (cartData: FinnairCart): BookingSummaryBound[] => {
  const bounds = cartData?.bounds || [];

  return bounds.map((bound, index) =>
    cartBoundToBookingSummaryBound(bound, index === 0 ? BoundType.outbound : BoundType.inbound)
  );
});

const _airBoundSummaries = createSelector(
  _selectedAirBoundOutboundSummary,
  _selectedAirBoundInboundSummary,
  (outbound, inbound): BookingSummaryBound[] => [outbound, inbound].filter(Boolean)
);

const _offerSummaries = createSelector(
  _selectedOfferOutboundSummary,
  _selectedOfferInboundSummary,
  (outbound, inbound): BookingSummaryBound[] => [outbound, inbound].filter(Boolean)
);

const _currentOfferSelection = createSelector(
  _cartOfferSummaries,
  _airBoundSummaries,
  _offerSummaries,
  (
    cartOfferSummaries: BookingSummaryBound[],
    airBoundSummaries: BookingSummaryBound[],
    offerSummaries: BookingSummaryBound[]
  ): BookingSummaryBound[] =>
    [cartOfferSummaries, airBoundSummaries, offerSummaries].find((bookingSummaryBounds) =>
      isNotEmpty(bookingSummaryBounds)
    ) || []
);

const _currentPassengers = createSelector(paxDetailTravelerSelector, (paxDetail) => {
  return paxDetail
    .filter((pax) => pax.type !== FinnairPassengerCode.INF)
    .map((p) => paxDetailFormToBookingSummaryTraveler(p, paxDetail));
});

const _currentServices = createSelector(cartDataSelector, (data) =>
  mapServicesToBookingSummary(data?.prices.unpaid?.services, data?.passengers)
);

const _combinedServices = createSelector(cartDataSelector, (cartData) =>
  combineServices([cartData?.services?.included ?? [], cartData?.services?.unpaid ?? []])
);
const _combinedServicesMap = createSelector(_combinedServices, (_services) =>
  _services.reduce((categories, service) => {
    categories[service.category] = cloneDeep(service);
    return categories;
  }, {})
);

export const paxDetailFormToBookingSummaryTraveler = (
  t: PaxDetailsFormData,
  allPassengers: PaxDetailsFormData[]
): BookingSummaryPassenger => {
  const infant = allPassengers.find((i) => i.travelling === t.id);
  return {
    firstName: t.firstName,
    lastName: t.lastName,
    type: `passenger.${asPaxTypeKey(t.type).toLowerCase()}`,
    withInfantFullName: infant ? `${infant.firstName || ''} ${infant.lastName || ''}` : '',
    email: t.email,
    birthDate: t.birthDate ? formatDateOfBirth(t.birthDate) : null,
    phone:
      t.phone?.phoneNumber && t.phone?.phonePrefix
        ? `+${t.phone.phonePrefix.split('|')[1]} ${t.phone.phoneNumber}`
        : null,
    frequentFlyerCard: t.frequentFlyerCard,
  };
};

export const cartBoundToBookingSummaryBound = (b: FinnairBoundItem, boundType: BoundType): BookingSummaryBound => ({
  ...b,
  type: boundType,
});

export const currentOfferSelection = (): StateSelector<BookingAppState, BookingSummaryBound[]> =>
  safeSelect(_currentOfferSelection);

export const currentPassengers = (): StateSelector<BookingAppState, BookingSummaryPassenger[]> =>
  safeSelect(_currentPassengers);

export const currentServices = (): StateSelector<BookingAppState, BookingSummaryService[]> =>
  safeSelect(_currentServices);

export const combinedCartServicesMap = (): StateSelector<BookingAppState, { [category: string]: FinnairServiceItem }> =>
  safeSelect(_combinedServicesMap);
