import { Abbreviation, CurrencySettings, PriceSettings } from '../../interfaces/currency.interface';

const NORDIC_EXACT_PRICE_FORMAT = '1$XYZ';

export const MAX_ABBR_DIGITS = 7;

export const DEFAULT_ABBR_SETTINGS: Abbreviation = {
  divider: 1000000,
  decimals: 1,
};

export const ABBR_SETTINGS: { [digits: number]: Abbreviation } = {
  5: {
    divider: 1000,
    decimals: 1,
  },
  6: {
    divider: 1000,
    decimals: 0,
  },
  7: {
    divider: 1000000,
    decimals: 2,
  },
};

const ABBR_KO_JA_SETTINGS: { [digits: number]: Abbreviation } = {
  5: {
    divider: 10000,
    decimals: 1,
  },
  6: {
    divider: 10000,
    decimals: 1,
  },
  7: {
    divider: 10000,
    decimals: 0,
  },
};

export const DEFAULT_SETTINGS: PriceSettings = {
  roundPrice: false,
  useSymbol: true,
  absoluteValue: false,
  abbreviate: false,
  stripZeroCents: false,
  roundDigits: 1,
  useCustomFormat: false,
};

export const ROUNDER = {
  1: 1,
  2: 10,
  3: 100,
  4: 1000,
};

export const currencySettings: { [currencyCode: string]: CurrencySettings } = {
  // symbol: default is the 3-language code if no symbol is defined
  // roundDigits: Default is 0 (no rounding)
  EUR: {
    symbol: '€',
  },
  CNY: {},
  KRW: {
    symbol: '₩',
    roundDigits: 3,
    stripZeroCents: true,
    customAbbrSettings: {
      symbol: '만',
      settings: ABBR_KO_JA_SETTINGS,
      useSymbol: ['kr-ko'],
    },
  },
  JPY: {
    symbol: '￥',
    roundDigits: 4,
    stripZeroCents: true,
    customAbbrSettings: {
      symbol: '万',
      settings: ABBR_KO_JA_SETTINGS,
      useSymbol: ['jp-ja'],
    },
  },
  GBP: {
    symbol: '£',
  },
  RUB: {
    symbol: '₽',
  },
  DKK: {
    customAbbrSettings: {
      symbol: ',-',
      format: NORDIC_EXACT_PRICE_FORMAT,
    },
  },
  NOK: {
    customAbbrSettings: {
      symbol: ',-',
      format: NORDIC_EXACT_PRICE_FORMAT,
    },
  },
  SEK: {
    customAbbrSettings: {
      symbol: ':-',
      format: NORDIC_EXACT_PRICE_FORMAT,
    },
  },
};
