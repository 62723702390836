<div [formGroup]="parentForm" class="ms-xlarge-b">
  <div class="relative ps-xxsmall-b">
    <label class="font-body-1 light-type nordic-blue-900-text" [for]="idOrControlName">
      {{ labelPrefix }}
      {{ translationPrefix + '.label' | finLocalization }}
      {{ labelSuffix }}
      <span *ngIf="isRequired"> *</span>
    </label>
    <input
      class="font-body-1 light-type"
      [class.form-field-empty]="!(field.value || placeHolder)"
      [class.sensitive]="isSensitive"
      type="number"
      [formControlName]="controlName"
      [id]="idOrControlName"
      [attr.placeholder]="placeHolder ? (placeHolder | finLocalization) : null"
      [attr.aria-required]="isRequired"
      [min]="min"
      [max]="max"
      [step]="step"
      (focus)="hasFocus = true"
      (blur)="hasFocus = false"
    />
    <span class="divider"></span>
  </div>
  <div *ngIf="field.invalid && field.touched" class="error-label font-body-2 light-type" aria-live="polite">
    <span *ngIf="field.errors?.required" class="heather-700-text">
      {{ translationPrefix + '.required' | finLocalization }}
    </span>
    <span *ngIf="field.errors?.min" class="heather-700-text">
      {{ translationPrefix + '.min' | finLocalization }}
    </span>
  </div>
</div>
