import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';

import { IconsService } from '../services/icons.service';

@Component({
  selector: 'fcom-generic-icon',
  template: `
    <svg aria-hidden="true">
      <use [attr.xlink:href]="'#' + iconRef" />
    </svg>
  `,
  styleUrls: ['./generic-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericIconComponent implements OnChanges {
  private _iconRef: string;
  @Input()
  set iconRef(ref: string) {
    this._iconRef = ref;
  }
  get iconRef(): string {
    return this._iconRef || this.url;
  }

  @Input() url: string;

  constructor(private readonly iconService: IconsService) {}

  ngOnChanges(): void {
    this.addIcon();
  }

  private async addIcon(): Promise<void> {
    if (this.url) {
      await this.iconService.addIcon(this.iconRef, this.url);
    }
  }
}
