export enum ButtonSize {
  MEDIUM = 'button-medium',
  LARGE = 'button-large',
  SMALL = 'button-small',
}

export enum ButtonMode {
  FILL = 'fill',
  INLINE = 'inline',
  FILL_MOBILE = 'fill-mobile',
}

export enum ButtonTheme {
  LIGHT_PRIMARY_NORDIC_BLUE = 'light-primary-nordic-blue',
  LIGHT_PRIMARY_CTA_HEATHER = 'light-primary-cta-heather',
  LIGHT_SECONDARY_OUTLINE = 'light-secondary-outline',
  LIGHT_TERTIARY_GHOST = 'light-tertiary-ghost',
  DARK_PRIMARY_NORDIC_BLUE = 'dark-primary-nordic-blue',
  DARK_PRIMARY_CTA_HEATHER = 'dark-primary-cta-heather',
  DARK_SECONDARY_OUTLINE = 'dark-secondary-outline',
  DARK_TERTIARY_GHOST = 'dark-tertiary-ghost',
}

export enum ButtonType {
  SUBMIT = 'submit',
  BUTTON = 'button',
  MENU = 'menu',
  RESET = 'reset',
}
