<section
  [attr.data-testid]="sectionTestId$ | async"
  [ngClass]="{
    'mr-large-b': (text$ | async) && (layoutVariant$ | async) !== 'section-two-thirds-right',
    'mr-xlarge-b': (text$ | async) && (layoutVariant$ | async) !== 'section-full-width',
  }"
>
  <div
    class="image-container"
    [ngClass]="{
      'pr-large-b':
        (layoutVariant$ | async) === 'section-two-thirds-right' &&
        !(this.subjectTaxonomyTags$ | async).includes('cmstyle-image-container-no-padding'),
      'pr-small-b': (layoutVariant$ | async) !== 'section-two-thirds-right',
    }"
    *ngIf="image$ | async as img"
  >
    <cms-text-on-image [content$]="content$" [noText]="true"></cms-text-on-image>
  </div>
  <ng-container *ngIf="title$ | async as t">
    <ng-container [ngSwitch]="headerLevel">
      <h1 *ngSwitchCase="1" class="teaser-title" [class.pr-clear-b]="icon$ | async">{{ t }}</h1>
      <h3 *ngSwitchCase="3" class="teaser-title" [class.pr-clear-b]="icon$ | async">{{ t }}</h3>
      <h4 *ngSwitchCase="4" class="teaser-title" [class.pr-clear-b]="icon$ | async">{{ t }}</h4>
      <h2 *ngSwitchDefault class="teaser-title" [class.pr-clear-b]="icon$ | async">{{ t }}</h2>
    </ng-container>
  </ng-container>
  <div *ngIf="icon$ | async as i" class="teaser-icon-container">
    <img [src]="i" [alt]="title$ | async | finEmpty" class="grow" />
  </div>
  <div
    class="teaser-text"
    [ngClass]="{
      'mr-xxlarge-b': (layoutVariant$ | async) === 'section-two-thirds-right',
      'mr-large-b': (layoutVariant$ | async) !== 'section-two-thirds-right',
    }"
    [innerHTML]="text$ | async | finSafe: 'html'"
  ></div>
  <div
    class="teaser-text ingredients"
    *ngIf="ingredients$ | async"
    [innerHTML]="ingredients$ | async | finSafe: 'html'"
  ></div>
</section>
