/* eslint-disable */

/**
 * List of ineligibility reasons for cancel flow.
 */
export enum FinnairCancelIneligibilityReason {
  NOT_ELIGIBLE_EMAIL_MISSING = 'NOT_ELIGIBLE_EMAIL_MISSING',
  TRAVEL_AGENT_TICKETS = 'TRAVEL_AGENT_TICKETS',
  ALL_FLIGHTS_ALREADY_FLOWN = 'ALL_FLIGHTS_ALREADY_FLOWN',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  ID_TICKET = 'ID_TICKET',
  NOT_TICKETED = 'NOT_TICKETED',
  CHECKED_IN_AND_BOARDED_OR_BAGGAGE_DROPPED = 'CHECKED_IN_AND_BOARDED_OR_BAGGAGE_DROPPED',
  HAS_DISRUPTION = 'HAS_DISRUPTION',
  HAS_SHORT_DISRUPTION = 'HAS_SHORT_DISRUPTION'
}

