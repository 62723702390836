import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { unique, uniqueBy } from '@fcom/core/utils';
import { FareFamily, TranslatedBenefitRow } from '@fcom/dapi/api/models';
import { ScrollHandleContainerComponent } from '@fcom/common/components/scroll-handle-container/scroll-handle-container.component';

export interface FareFamilyWithMappedBenefits extends FareFamily {
  benefits: {
    [id: string]: TranslatedBenefitRow;
  };
}

@Component({
  selector: 'fin-fare-family-table',
  styleUrls: ['./fare-family-table.component.scss'],
  templateUrl: './fare-family-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FareFamilyTableComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @ViewChild('scrollContainer', { static: false }) scrollContainer: ScrollHandleContainerComponent;
  @ViewChildren('tableHeader', { read: ElementRef }) tableHeaders: QueryList<ElementRef>;

  @Input()
  showIcons = true;

  @Input()
  set fareFamilies(fareFamilies: FareFamily[]) {
    if (!fareFamilies || !fareFamilies[0]) {
      return;
    }

    this.fareFamiliesWithMappedBenefits = fareFamilies
      .map((fareFamily) => ({
        ...fareFamily,
        benefits: fareFamily.translatedBenefitRows.reduce(
          (acc, benefitRow) => ({ ...acc, [benefitRow.key]: benefitRow }),
          {} as { [id: string]: TranslatedBenefitRow }
        ),
      }))
      .filter(uniqueBy((fareFamily) => fareFamily.fareFamilyCode));

    const fareFamily = fareFamilies[0];
    const allBenefitKeys = fareFamily.translatedBenefitRows.map((benefitRow) => benefitRow.key);

    this.benefitKeys = fareFamily.highlightedBenefitKeys
      ? [...fareFamily.highlightedBenefitKeys, ...allBenefitKeys].filter(unique)
      : allBenefitKeys;

    if (this.scrollContainer) {
      this.scrollContainer.updateScrollHandles();
    }
  }

  @Input()
  showKoreaNotification: boolean;

  public benefitKeys: string[];
  public fareFamiliesWithMappedBenefits: FareFamilyWithMappedBenefits[] = [];
}
