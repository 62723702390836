import { DeepLinkSource } from '@fcom/common/interfaces';
import { LocalDate } from '@fcom/core/utils';
import { FinnairAlternativeOffers, FinnairOrder, FinnairSelfReacConfirmationResponse } from '@fcom/dapi/api/models';

import { BookingInfo, Stage } from './';

export enum OrderSummaryStatus {
  INITIAL,
  PENDING,
  LOAD_ERROR,
  OK,
}

export enum AlternativeOffersStateStatus {
  /** There are no offers to show and no request has been fired. */
  INITIAL,

  /** A request for loading the air offers has been initiated, but not yet completed */
  PENDING,

  /** The request for air offers was successful. */
  OK,

  /** The request for air offers resulted in error */
  ERROR,

  /** The request for air offers resulted with no flight found */
  NO_FLIGHT_FOUND,
}

export interface AlternativeOffersState {
  /**
   * Current alternative offers
   */
  alternativeOffers: Record<string, FinnairAlternativeOffers>;

  /**
   * The current status of the air offers data.
   */
  status: AlternativeOffersStateStatus;

  /**
   * Ids of the offer selected per boundIndex
   */
  selectedOffers: Record<string, string>;

  /**
   * Id of the disrupted bound that should be reaccomodated during this flow.
   * The self reaccommodation flow manages only one bound
   * per flow (in case of multiple bounds, user needs to do multiple flows).
   */
  disruptedBoundIndex: number;

  /**
   * The selected date for the bounds.
   * Undefined if not selected.
   */
  selectedDates?: Record<string, LocalDate>;

  /**
   * The current stage in the flow
   */
  currentStage: Stage;

  /**
   * Deeplink source parameter to indicate from which endpoint user has entered
   */
  deeplinkSource?: DeepLinkSource;

  /*
   * Response received after the confirmation of the alternative offers.
   */
  confirmationResponse: FinnairSelfReacConfirmationResponse;

  /*
   * Whether the confimartion summary event has been already sent to GA, used to prevent sending it multiple times
   */
  analyticsSent: boolean;

  /*
   * Used to determine if session is expired and user is forced to refresh flow with fresh offers
   */
  sessionExpired: boolean;

  /*
   * Value in milliseconds of when user has started the session
   */
  sessionStartedMs: number;

  /*
   * Booking infromation of the pnr
   */
  booking: BookingInfo;

  /*
   * To check if GA event has to be send when user has landed on the page by refreshing expired session
   */
  sendRefreshOffersEvent: boolean;

  /*
   * Set in ticket selection phase if acknowledge wanted instead of new offer
   */
  acknowledgeFlow: boolean;

  alternativeConfirmStatusUrl: string;

  orderSummary: {
    orderSummaryData: FinnairOrder;
    orderStatus: OrderSummaryStatus;
  };

  reviewConfirmed: boolean;

  showAlternativeOffers: boolean;
}
