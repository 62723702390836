<ng-container data-testid="flight-selection-filters" *ngIf="showFlightSelectionFilters">
  <fcom-carousel
    *ngIf="(isMobile$ | async) === false"
    class="block overflow-auto"
    [leftIcon]="SvgLibraryIcon.ARROW_LEFT_OUTLINE"
    [rightIcon]="SvgLibraryIcon.ARROW_RIGHT_OUTLINE"
    [whiteIcon]="true"
    [prevButtonAriaLabel]="'carousel.previous' | finLocalization"
    [nextButtonAriaLabel]="'carousel.next' | finLocalization"
    [contentChanged$]="resizedWatcher$"
  >
    <div
      data-testid="desktop-filters"
      class="flex nowrap flex--middle flex--nowrap ps-xxsmall-x ps-xxsmall-t ps-small-b ms-xxsmall-b"
    >
      <!--DIRECT FILTER-->
      <fcom-filters
        data-testid="stops-filter"
        *ngIf="showStopsFilter$ | async"
        class="ms-small-r"
        [isMobile$]="isMobile$"
        [hasFiltering]="selectedStopsOption !== null"
        [headerText]="'flightFilters.stopsCount.title' | finLocalization"
        [buttonText]="'flightFilters.stopsCount.title' | finLocalization"
        [buttonTextWhenFiltered]="
          selectedStopsOption === 1
            ? ('flightFilters.stopsCount.title' | finLocalization)
            : ('flightFilters.stopsCount.directFlight' | finLocalization)
        "
        [primaryFooterButtonTitle]="'flightFilters.action.showResults' | finLocalization: { flights: resultsAmount }"
        [secondaryFooterButtonTitle]="'flightFilters.action.clear' | finLocalization"
        (primaryFooterButtonClick)="closePopover()"
        (secondaryFooterButtonClick)="clearFilter('stopsCount')"
        (popoverOpeningClick)="sendAnalytics('stops')"
      >
        <div popover-content class="ps-medium-x ps-large-b">
          <fcom-radio-item-group
            [options]="[
              { label: 'flightFilters.stopsCount.all', value: null },
              { label: 'flightFilters.stopsCount.stops', value: '1' },
              { label: 'flightFilters.stopsCount.direct', value: '0' },
            ]"
            [parentForm]="filtersForm"
            [controlName]="'stopsCount'"
            [translationPrefix]="'flightFilters.stopsCount'"
            [titleText]="false"
          >
          </fcom-radio-item-group>
        </div>
      </fcom-filters>

      <!-- TRAVEL CLASS FILTER-->
      <fcom-filters
        *ngIf="selectedTravelClass"
        class="ms-small-r"
        [isMobile$]="isMobile$"
        [hasFiltering]="selectedTravelClass !== Cabin.MIXED"
        [disabled]="shouldNotClearTravelClass"
        [headerText]="'flightFilters.travelClass.title' | finLocalization"
        [buttonText]="'flightFilters.travelClass.title' | finLocalization"
        [buttonTextWhenFiltered]="
          selectedTravelClass === Cabin.MIXED
            ? ('flightFilters.travelClass.title' | finLocalization)
            : ('travelClass.' + (selectedTravelClass | lowercase) + 'Class' | finLocalization)
        "
        [primaryFooterButtonTitle]="'flightFilters.action.showResults' | finLocalization: { flights: resultsAmount }"
        [secondaryFooterButtonTitle]="'flightFilters.action.clear' | finLocalization"
        (primaryFooterButtonClick)="closePopover()"
        (secondaryFooterButtonClick)="clearFilter('cabin')"
        (popoverOpeningClick)="sendAnalytics('travel-class')"
      >
        <div popover-content class="ps-medium-x ps-large-b">
          <fcom-radio-item-group
            [options]="travelClassFilters"
            [parentForm]="filtersForm"
            [controlName]="'cabin'"
            [translationPrefix]="'flightFilters.travelClass'"
            [titleText]="false"
          >
          </fcom-radio-item-group>
        </div>
      </fcom-filters>

      <!-- REFUNDABILITY FILTER-->
      <fcom-filters
        *ngIf="showRefundability"
        class="ms-small-r"
        [isMobile$]="isMobile$"
        [hasFiltering]="selectedRefundabilityOption !== false"
        [headerText]="'flightFilters.refundability.title' | finLocalization"
        [buttonText]="'flightFilters.refundability.title' | finLocalization"
        [buttonTextWhenFiltered]="
          selectedRefundabilityOption === false
            ? ('flightFilters.refundability.title' | finLocalization)
            : ('flightFilters.refundability.refundableTicket' | finLocalization)
        "
        [primaryFooterButtonTitle]="'flightFilters.action.showResults' | finLocalization: { flights: resultsAmount }"
        [secondaryFooterButtonTitle]="'flightFilters.action.clear' | finLocalization"
        (primaryFooterButtonClick)="closePopover()"
        (secondaryFooterButtonClick)="clearFilter('refundable')"
        (popoverOpeningClick)="sendAnalytics('refundability')"
      >
        <div popover-content class="ps-medium-x ps-large-b">
          <fcom-radio-item-group
            [options]="[
              { label: 'flightFilters.refundability.anyTicketType', value: 'false' },
              { label: 'flightFilters.refundability.onlyRefundable', value: 'true' },
            ]"
            [parentForm]="filtersForm"
            [controlName]="'refundable'"
            [translationPrefix]="'flightFilters.refundability'"
            [titleText]="false"
          >
          </fcom-radio-item-group>
        </div>
      </fcom-filters>

      <!-- Times filter  -->
      <fcom-filters
        [isMobile$]="isMobile$"
        [hasFiltering]="selectedDepartureTimes?.length < 3 || selectedArrivalTimes?.length < 3"
        [headerText]="'flightFilters.times.title' | finLocalization"
        [buttonText]="'flightFilters.times.title' | finLocalization"
        [buttonTextWhenFiltered]="'flightFilters.times.title' | finLocalization"
        [primaryFooterButtonTitle]="'flightFilters.action.showResults' | finLocalization: { flights: resultsAmount }"
        [secondaryFooterButtonTitle]="'flightFilters.action.clear' | finLocalization"
        (primaryFooterButtonClick)="closePopover()"
        (secondaryFooterButtonClick)="
          activeTab$.getValue() === 0 ? clearFilter('departureTime') : clearFilter('arrivalTime')
        "
        (popoverOpeningClick)="sendAnalytics('times')"
      >
        <div popover-content class="ps-medium-x flex flex--column">
          <fcom-tabs
            (clickedTab)="setActiveTimesTab($event)"
            [theme]="TabTheme.DEFAULT"
            [prevButtonAriaLabel]="'previous'"
            [nextButtonAriaLabel]="'next'"
          >
            <fcom-tab [title]="'flightFilters.times.departure.title' | finLocalization">
              <span data-testid="departure-from" class="font-body-1">
                {{ 'flightFilters.times.departure.from' | finLocalization }}
                <b>{{ departureCityName }}</b>
              </span>

              <div class="flex flex--column ms-medium-t">
                <ng-container *ngFor="let departureTime of FlightTimeOption | keyvalue: keepOrder">
                  <fcom-checkbox
                    class="ms-small-b"
                    [id]="departureTime.key"
                    [name]="departureTime.key.toLowerCase()"
                    [checked]="selectedDepartureTimes?.includes(departureTime.value)"
                    (change)="onSelectDepartureTime(departureTime.value)"
                  >
                    <div class="flex flex--column">
                      <span class="font-body-1 medium-type">
                        {{ 'flightFilters.times.departure.' + departureTime.key.toLowerCase() | finLocalization }}
                      </span>
                      <span class="font-body-2 light-type ms-xxsmall-t">{{ getTimeRange(departureTime.value) }}</span>
                    </div>
                  </fcom-checkbox>
                </ng-container>
              </div>
            </fcom-tab>
            <fcom-tab [title]="'flightFilters.times.arrival.title' | finLocalization">
              <span data-testid="arriving-in" class="font-body-1">
                {{ 'flightFilters.times.arrival.landing' | finLocalization }}
                <b>{{ arrivalCityName }}</b></span
              >
              <div class="flex flex--column ms-medium-t">
                <ng-container *ngFor="let arrivalTime of FlightTimeOption | keyvalue: keepOrder">
                  <fcom-checkbox
                    class="ms-small-b"
                    [id]="arrivalTime.key"
                    [name]="arrivalTime.key.toLowerCase()"
                    [checked]="selectedArrivalTimes?.includes(arrivalTime.value)"
                    (change)="onSelectArrivalTime(arrivalTime.value)"
                  >
                    <div class="flex flex--column">
                      <span class="font-body-1 medium-type">
                        {{ 'flightFilters.times.arrival.' + arrivalTime.key.toLowerCase() | finLocalization }}
                      </span>
                      <span class="font-body-2 light-type ms-xxsmall-t">{{ getTimeRange(arrivalTime.value) }}</span>
                    </div>
                  </fcom-checkbox>
                </ng-container>
              </div>
            </fcom-tab>
          </fcom-tabs>
        </div>
      </fcom-filters>

      <!-- Clear all filters -->
      <fcom-button
        *ngIf="showClearAllBtn"
        [theme]="ButtonTheme.LIGHT_TERTIARY_GHOST"
        [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.TRASH, position: IconPosition.LEFT }"
        [text]="'flightFilters.clearAll' | finLocalization"
        (clickBtn)="clearAllFilters('desktop')"
        class="ms-small-l"
        data-testid="clear-all-filters"
      ></fcom-button>
    </div>
  </fcom-carousel>

  <ng-container *ngIf="isMobile$ | async">
    <fcom-filters
      *ngIf="travelClasses$ | async as availableCabinClasses"
      data-testid="mobile-filters"
      [isMobile$]="isMobile$"
      [isModalOpen]="isModalOpen"
      [hasFiltering]="(selectedFiltersLength$ | async) > 0"
      [buttonText]="'flightFilters.title' | finLocalization"
      [modalTitle]="'flightFilters.modalTitle' | finLocalization"
      [notificationContent]="'flightFilters.modalNotification' | finLocalization"
      [numberToShowWhenFiltered]="selectedFiltersLength$ | async"
      [displayNotification]="resultsAmount === 0"
      [primaryFooterButtonTitle]="'flightFilters.action.showResults' | finLocalization: { flights: resultsAmount }"
      (primaryFooterButtonClick)="closeModal()"
      [secondaryFooterButtonTitle]="'flightFilters.action.clear' | finLocalization"
      (secondaryFooterButtonClick)="clearAllFilters('mobile')"
      (showModal)="showModal()"
      (closeModal)="closeModal()"
    >
      <ng-container modal-content>
        <div class="ms-large ps-large-b border-bottom">
          <div class="nordic-blue-900-text font-body-1 medium-type ms-medium-b">
            {{ 'flightFilters.stopsCount.title' | finLocalization }}
          </div>
          <fcom-radio-item-group
            [options]="[
              { label: 'flightFilters.stopsCount.all', value: null },
              { label: 'flightFilters.stopsCount.stops', value: '1' },
              { label: 'flightFilters.stopsCount.direct', value: '0' },
            ]"
            [parentForm]="filtersForm"
            [controlName]="'stopsCount'"
            [translationPrefix]="'flightFilters.stopsCount'"
            [titleText]="false"
          >
          </fcom-radio-item-group>
        </div>

        <div class="ms-large ps-large-b border-bottom">
          <div class="nordic-blue-900-text font-body-1 medium-type ms-medium-b">
            {{ 'flightFilters.travelClass.title' | finLocalization }}
          </div>
          <fcom-radio-item-group
            [options]="travelClassFilters"
            [parentForm]="filtersForm"
            [controlName]="'cabin'"
            [translationPrefix]="'flightFilters.travelClass'"
            [titleText]="false"
          >
          </fcom-radio-item-group>
        </div>

        <div class="ms-large border-bottom">
          <div class="flex flex--column">
            <span class="nordic-blue-900-text font-body-1 medium-type ps-xsmall-b">{{
              'flightFilters.times.departure.title' | finLocalization
            }}</span>
            <span class="font-body-1">
              {{ 'flightFilters.times.departure.from' | finLocalization }}
              <b>{{ departureCityName }}</b>
            </span>
          </div>

          <div class="flex flex--column ms-medium-t">
            <ng-container *ngFor="let departureTime of FlightTimeOption | keyvalue: keepOrder">
              <fcom-checkbox
                class="ms-large-b"
                [id]="departureTime.key"
                [name]="departureTime.key.toLowerCase()"
                [checked]="selectedDepartureTimes?.includes(departureTime.value)"
                (change)="onSelectDepartureTime(departureTime.value)"
              >
                <div class="flex flex--column">
                  <span class="font-body-1 medium-type">
                    {{ 'flightFilters.times.departure.' + departureTime.key.toLowerCase() | finLocalization }}
                  </span>
                  <span class="font-body-1 light-type ms-xxsmall-t">{{ getTimeRange(departureTime.value) }}</span>
                </div>
              </fcom-checkbox>
            </ng-container>
          </div>
        </div>

        <div class="ms-large border-bottom">
          <div class="flex flex--column">
            <span class="nordic-blue-900-text font-body-1 medium-type ps-xsmall-b">{{
              'flightFilters.times.arrival.title' | finLocalization
            }}</span>
            <span class="font-body-1">
              {{ 'flightFilters.times.arrival.landing' | finLocalization }}
              <b>{{ arrivalCityName }}</b></span
            >
          </div>
          <div class="flex flex--column ms-medium-t">
            <ng-container *ngFor="let arrivalTime of FlightTimeOption | keyvalue: keepOrder">
              <fcom-checkbox
                class="ms-large-b"
                [id]="arrivalTime.key"
                [name]="arrivalTime.key.toLowerCase()"
                [checked]="selectedArrivalTimes?.includes(arrivalTime.value)"
                (change)="onSelectArrivalTime(arrivalTime.value)"
              >
                <div class="flex flex--column">
                  <span class="font-body-1 medium-type">
                    {{ 'flightFilters.times.arrival.' + arrivalTime.key.toLowerCase() | finLocalization }}
                  </span>
                  <span class="font-body-2 light-type ms-xxsmall-t">{{ getTimeRange(arrivalTime.value) }}</span>
                </div>
              </fcom-checkbox>
            </ng-container>
          </div>
        </div>

        <div *ngIf="showRefundability" class="ms-large ps-large-b">
          <div class="nordic-blue-900-text font-body-1 medium-type ms-medium-b">
            {{ 'flightFilters.refundability.title' | finLocalization }}
          </div>
          <fcom-radio-item-group
            [options]="[
              { label: 'flightFilters.refundability.anyTicketType', value: 'false' },
              { label: 'flightFilters.refundability.onlyRefundable', value: 'true' },
            ]"
            [parentForm]="filtersForm"
            [controlName]="'refundable'"
            [translationPrefix]="'flightFilters.refundability'"
            [titleText]="false"
          >
          </fcom-radio-item-group>
        </div>
      </ng-container>
    </fcom-filters>
  </ng-container>
</ng-container>
