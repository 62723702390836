import { Component, Input } from '@angular/core';

@Component({
  selector: 'fin-section-review',
  templateUrl: 'section-review.component.html',
  styleUrls: ['section-review.component.scss'],
})
export class SectionReviewComponent {
  @Input()
  title: string;

  @Input()
  fullWidth = false;

  @Input()
  endTitle: string;

  @Input()
  noContent: boolean;

  @Input()
  noButton: boolean;

  @Input()
  endTitleClasses: string;

  @Input()
  showHighlightedContent = false;

  @Input()
  roundedBottom = true;

  @Input()
  alignParent = false;

  @Input()
  hasBorder = true;
}
