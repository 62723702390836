import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS } from 'ng-lazyload-image';

import { ImagesModule as FcomImagesModule } from '@fcom/ui-components';

import { PipesModule } from '../../pipes/pipes.module';
import { BlockImageComponent } from './block-image.component';
import { ContentImageComponent } from './content-image.component';
import { LazyLoadImageHooks } from './images.utils';
@NgModule({
  imports: [CommonModule, PipesModule, FcomImagesModule, LazyLoadImageModule],
  providers: [{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: LazyLoadImageHooks }],
  declarations: [BlockImageComponent, ContentImageComponent],
  exports: [BlockImageComponent, ContentImageComponent, FcomImagesModule],
})
export class ImagesModule {}
