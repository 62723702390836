import { WeekdayMap } from './date';
import { pad } from './format';
import { LocalDate, TzDate } from './tz-date';

// == PRIVATE =================================================================

interface Extractor<TzDate> {
  year(t: TzDate): number;
  month(t: TzDate): number;
  day(t: TzDate): number;
  weekday(t: TzDate): number;
  hours(t: TzDate): number;
  minutes(t: TzDate): number;
}

class TzDateExtractor implements Extractor<TzDate> {
  private weekdayMap: WeekdayMap = new WeekdayMap('MON');

  year(t: TzDate): number {
    return t.localYear;
  }

  month(t: TzDate): number {
    return t.localMonth;
  }

  day(t: TzDate): number {
    return t.localDay;
  }

  weekday(t: TzDate): number {
    return this.weekdayMap.get(t.weekday);
  }

  hours(t: TzDate): number {
    return t.localHours;
  }

  minutes(t: TzDate): number {
    return t.localMinutes;
  }
}
const tzDateExtractor: TzDateExtractor = new TzDateExtractor();

class UserDateExtractor implements Extractor<TzDate> {
  private weekdayMap: WeekdayMap = new WeekdayMap('MON');

  year(t: TzDate): number {
    return t.toDate().getFullYear();
  }

  month(t: TzDate): number {
    return t.toDate().getMonth() + 1;
  }

  day(t: TzDate): number {
    return t.toDate().getDate();
  }

  weekday(t: TzDate): number {
    return this.weekdayMap.get(t.toDate().getDay());
  }

  hours(t: TzDate): number {
    return t.toDate().getHours();
  }

  minutes(t: TzDate): number {
    return t.toDate().getMinutes();
  }
}
const userDateExtractor: UserDateExtractor = new UserDateExtractor();

const MATCHER = /yy(?:yy)?|M{1,4}|d{1,2}|E{1,2}|hh|mm/g;

const safe = (n: number, callback: (n: number) => string): string => {
  if (typeof n === 'undefined' || n === null || isNaN(n)) {
    return '';
  }
  return callback(n);
};

const REPLACEMENTS: any = {
  yy: <T>(t: T, extractor: Extractor<T>): string => {
    return safe(extractor.year(t), (n) => {
      return n.toString().slice(-2);
    });
  },
  yyyy: <T>(t: T, extractor: Extractor<T>): string => {
    return safe(extractor.year(t), (n) => {
      return n.toString();
    });
  },
  M: <T>(t: T, extractor: Extractor<T>): string => {
    return safe(extractor.month(t), (n) => {
      return n.toString();
    });
  },
  MM: <T>(t: T, extractor: Extractor<T>): string => {
    return safe(extractor.month(t), (n) => {
      return pad(n);
    });
  },
  MMM: <T>(t: T, extractor: Extractor<T>, translations: any): string => {
    return safe(extractor.month(t), (n) => {
      return translations['monthNamesShort'] ? translations['monthNamesShort'][n - 1] : '';
    });
  },
  MMMM: <T>(t: T, extractor: Extractor<T>, translations: any): string => {
    return safe(extractor.month(t), (n) => {
      return translations['monthNames'] ? translations['monthNames'][n - 1] : '';
    });
  },
  d: <T>(t: T, extractor: Extractor<T>): string => {
    return safe(extractor.day(t), (n) => {
      return n.toString();
    });
  },
  dd: <T>(t: T, extractor: Extractor<T>): string => {
    return safe(extractor.day(t), (n) => {
      return pad(n);
    });
  },
  E: <T>(t: T, extractor: Extractor<T>, translations: any): string => {
    return safe(extractor.weekday(t), (n) => {
      return translations['weekdaysShort'] ? translations['weekdaysShort'][n] : '';
    });
  },
  EE: <T>(t: T, extractor: Extractor<T>, translations: any): string => {
    return safe(extractor.weekday(t), (n) => {
      return translations['weekdays'] ? translations['weekdays'][n] : '';
    });
  },
  hh: <T>(t: T, extractor: Extractor<T>): string => {
    return safe(extractor.hours(t), (n) => {
      return pad(n);
    });
  },
  mm: <T>(t: T, extractor: Extractor<T>): string => {
    return safe(extractor.minutes(t), (n) => {
      return pad(n);
    });
  },
};

// == PUBLIC ==================================================================

/*
 * Pattern is related to Java SimpleDateFormat syntax (examples for SAT 2016-01-02 on Finnish locale):
 * yy = year in 2 digits => 16
 * yyyy = year in 4 digits => 2016
 * M = month in 1-2 digit => 1
 * MM = month in 2 digits => 01
 * MMM = month's short name => Tam
 * MMMM = month's name => Tammikuu
 * d = day of month in 1-2 digits => 2
 * dd = day of month in 2 digits => 02
 * E = weekday's short name => la
 * EE = weekday's name => Lauantai
 *
 * "localized = true" means that the pattern's value is considered to be a localization key rather than a date format
 */
export class Pattern {
  constructor(
    private val: string,
    private localized: boolean = false
  ) {}

  getValue(translations: any): string {
    if (this.localized) {
      return translations[this.val];
    }
    return this.val;
  }
}

export type DateFormatInput = string | LocalDate;

export class DateFormat {
  static FULL: Pattern = new Pattern('dateFormatFull', true);
  static FULL_NO_YEAR: Pattern = new Pattern('dateFormatFullNoYear', true);
  static DATE: Pattern = new Pattern('dateFormatLong', true);
  static DATE_WITH_TIME: Pattern = new Pattern('dateFormatLongWithTime', true);
  static DATE_SHORT: Pattern = new Pattern('dateFormatShort', true);
  static MONTH: Pattern = new Pattern('MMMM');
  static MONTH_SHORT: Pattern = new Pattern('MMM');
  static WEEKDAY: Pattern = new Pattern('EE');
  static WEEKDAY_SHORT: Pattern = new Pattern('E');
  static VOICE_OVER_DATE: Pattern = new Pattern('EE, d MMMM');
  static SHORT_MONTH_AND_YEAR: Pattern = new Pattern('MMM yyyy');
  static LONG_MONTH_AND_YEAR: Pattern = new Pattern('MMMM yyyy');

  constructor(public translations: any = {}) {}

  format(input: DateFormatInput, pattern: Pattern, useUserDate = false): string {
    let inputObj: any = input;
    if (typeof input === 'string') {
      inputObj = new TzDate(input);
    } else if (input instanceof LocalDate) {
      inputObj = input.toTzDate();
    }

    const extractor: Extractor<TzDate> = useUserDate ? userDateExtractor : tzDateExtractor;
    const strPattern = pattern.getValue(this.translations);
    if (!strPattern) {
      return '';
    }

    return strPattern.replace(MATCHER, (m) => {
      return REPLACEMENTS[m] ? REPLACEMENTS[m](inputObj, extractor, this.translations) : m;
    });
  }
}
