import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  take,
} from 'rxjs';
import { Store } from '@ngrx/store';

import { CartStatus } from '@fcom/dapi';
import { AppConstants } from '@fcom/booking/app-constants';
import { ElementActions, ElementTypes, GaContext, SelectOption } from '@fcom/common/interfaces';
import { ProfileType } from '@fcom/core-api/login';
import {
  ControlData,
  FinnairCart,
  FinnairFrequentFlyerCardItem,
  FinnairOrder,
  FinnairPassengerCode,
  FinnairPassengerItem,
} from '@fcom/dapi/api/models';
import { PaxDetailsFormData } from '@fcom/dapi/interfaces';
import { asPaxTypeKey } from '@fcom/dapi/utils';
import { ButtonTheme, IconPosition, ModalButtons, NotificationTheme } from '@fcom/ui-components';
import { getAirlineNameByCode, rangeFrom, unsubscribe } from '@fcom/core/utils';
import { setSinglePaxDetails } from '@fcom/booking/store/actions/pax-details.actions';
import { BookingAppState, PaxDetailsState } from '@fcom/common/interfaces/booking';
import { snapshot } from '@fcom/rx';
import { cartStatus, paxDetails } from '@fcom/booking/store/selectors';
import { BookingQueueService, BookingService } from '@fcom/booking/services';
import { LanguageService } from '@fcom/ui-translate';
import { BOOKING_STEPS } from '@fcom/common/config/booking-config';
import { GtmService } from '@fcom/common/gtm';

import { PassengerDetails, PassengerFormService, PaxFormFields, PrefillSource } from '../../services';

@Component({
  selector: 'fin-passenger-info-list',
  templateUrl: './passenger-info-list.component.html',
  styleUrls: ['./passenger-info-list.component.scss'],
})
export class PassengerInfoListComponent implements OnInit, OnDestroy {
  readonly FinnairPassengerCode = FinnairPassengerCode;
  readonly ButtonTheme = ButtonTheme;
  readonly IconPosition = IconPosition;
  readonly ModalButtons = ModalButtons;
  readonly FIELDS = PaxFormFields;
  readonly NotificationTheme = NotificationTheme;
  public readonly C = AppConstants;

  public readonly TRACKING_CONTEXT = GaContext.BOOKING_FLOW;

  private subscription: Subscription = new Subscription();

  @Input()
  passengers$: Observable<FinnairPassengerItem[]>;

  @Input()
  cartData$: Observable<FinnairCart | FinnairOrder>;

  @Input()
  showEdit = false;

  @Input()
  isOrder: boolean;

  @Input()
  isSaving$: Observable<boolean>;

  @Input()
  allFrequentFlyerCards$: Observable<FinnairFrequentFlyerCardItem[]>;

  @Input()
  controlData$: Observable<ControlData>;

  @Input()
  profileType$: Observable<ProfileType>;

  extendedPassengers$: Observable<
    (FinnairPassengerItem & {
      paxTypeKey: string;
      cards: (FinnairFrequentFlyerCardItem & { airlineName: string })[];
    })[]
  >;

  travelerIdBeingModified$: BehaviorSubject<string> = new BehaviorSubject('');

  infantTravelingWithLocked$: Observable<boolean>;
  frequentFlyerAirlines$: Observable<SelectOption[]>;

  availableDays: { days$: Observable<number[]>; months: number[]; years: number[] }[];
  passengerFormGroups: PassengerDetails[];
  selectableLeadPassengers$: Observable<SelectOption[]>;

  constructor(
    private passengerFormService: PassengerFormService,
    private fb: UntypedFormBuilder,
    private store$: Store<BookingAppState>,
    private bookingQueueService: BookingQueueService,
    private languageService: LanguageService,
    private bookingService: BookingService,
    private gtmService: GtmService
  ) {}

  ngOnInit(): void {
    this.extendedPassengers$ = this.passengers$.pipe(
      map((passengers) =>
        passengers.map((passenger) => ({
          ...passenger,
          paxTypeKey: asPaxTypeKey(passenger.passengerTypeCode).toLowerCase(),
          passengerTypeCode: passenger.passengerTypeCode,
          cards: (passenger.frequentFlyerCards ?? []).map((card) => ({
            ...card,
            airlineName: getAirlineNameByCode(card?.companyCode),
          })),
        }))
      )
    );

    this.subscription.add(
      this.passengers$
        .pipe(
          take(1),
          switchMap((passengers) =>
            combineLatest(
              passengers.map((passenger) => {
                return this.passengerFormService.createPassengerFormGroup(passenger.id, this.isOrder, [
                  'joinFinnairPlus',
                  'frequentFlyerCard',
                  'travelling',
                ]);
              })
            )
          ),
          switchMap((passengerFormGroups) => {
            return combineLatest(
              passengerFormGroups.map((passengerFormGroup) => {
                return this.passengerFormService.prefillData(passengerFormGroup, [
                  this.isOrder ? PrefillSource.ORDER : PrefillSource.CART,
                ]);
              })
            );
          })
        )
        .subscribe((passengerFormGroups) => {
          this.availableDays = passengerFormGroups.map((passengerFormGroup) => ({
            days$: passengerFormGroup.availableDays$,
            months: rangeFrom(1, 12),
            years: passengerFormGroup.years,
          }));
          passengerFormGroups.forEach((passengerFormGroup, i) =>
            passengerFormGroup.group.addControl('number', this.fb.control(i + 1))
          );

          this.passengerFormGroups = passengerFormGroups;

          this.setupTravellingWithSelectOptions(passengerFormGroups);
        })
    );

    this.subscription.add(
      this.store$.pipe(cartStatus()).subscribe((status) => {
        if (status === CartStatus.OK || status === CartStatus.LOAD_ERROR) {
          this.travelerIdBeingModified$.next('');
        }
      })
    );
  }

  private setupTravellingWithSelectOptions(selectableLeadPassengers: PassengerDetails[]) {
    this.selectableLeadPassengers$ = combineLatest(
      selectableLeadPassengers.map((pax) => this.createPassengerOption(pax))
    );
  }

  private createPassengerOption(pax: PassengerDetails) {
    return pax.group.valueChanges.pipe(
      startWith(pax.group.value),
      distinctUntilChanged(),
      switchMap((ad) => this.getTranslatedName(ad)),
      map((name) => ({ name, value: pax.group.value.id }))
    );
  }

  private getTranslatedName(paxDetails: PaxDetailsFormData) {
    const params = {
      ...paxDetails,
      firstName: paxDetails.firstName || '',
      lastName: paxDetails.lastName || '',
    };

    return this.languageService.translate('paxDetails.travellingWith', params);
  }

  ngOnDestroy(): void {
    this.subscription = unsubscribe(this.subscription);
  }

  handleSave(paxData: PaxDetailsFormData): void {
    this.travelerIdBeingModified$.next(paxData.travelerId);
    this.store$.dispatch(setSinglePaxDetails({ passenger: paxData }));

    const passengerFormDetails: PaxDetailsState = snapshot(this.store$.pipe(paxDetails()));
    this.bookingQueueService.queuePassengersAndCorporateCodeUpdate(passengerFormDetails);
  }

  backToPaxDetails(): void {
    this.bookingService.setStep({ step: BOOKING_STEPS.PAXDETAILS });

    this.gtmService.trackElement(
      'review-page-back-to-pax-details-button',
      GaContext.BOOKING_FLOW,
      ElementTypes.BUTTON,
      undefined,
      ElementActions.CLICK
    );
  }
}
