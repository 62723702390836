import { OfferListFetchParams, Step } from '../interfaces';
import { GlobalBookingState } from '../store';
import { BOOKING_STEPS } from '../config/booking-config';

export const createParamsForStep = (
  step: Step,
  globalBooking: GlobalBookingState,
  offerListParams: OfferListFetchParams
): { json?: string } => {
  if (step.id !== BOOKING_STEPS.FLIGHT_SELECTION.id) {
    return null;
  }

  /**
   * If the session has expired and there is no longer a search query in the store then don't try
   * and encode any "initialState" data into the url.
   **/
  if (!globalBooking?.flights?.[0]?.origin) {
    return null;
  }

  const flightSelectionParams = {
    flights: globalBooking?.flights?.map((globalBookingFlight) => ({
      origin: globalBookingFlight.origin?.locationCode,
      destination: globalBookingFlight.destination?.locationCode,
      departureDate: globalBookingFlight.departureDate,
    })),
    cabin: globalBooking?.travelClasses?.selected,
    adults: globalBooking?.paxAmount?.adults,
    c15s: globalBooking?.paxAmount?.c15s,
    children: globalBooking?.paxAmount?.children,
    infants: globalBooking?.paxAmount?.infants,
    directFlights: offerListParams?.directFlights,
    isAward: offerListParams?.isAward || null,
  };

  return {
    json: encodeURIComponent(JSON.stringify(flightSelectionParams)),
  };
};
