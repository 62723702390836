<fcom-modal
  [fitToContent]="true"
  [buttons]="null"
  [forceRemainOpen]="modalOpen"
  [open]="!!(cookiePolicy$ | async) && modalOpen"
  [fullHeightMobile]="isShowCookieSettings"
  [fullHeight]="true"
  ><div class="cookie-policy mr-large-x mr-small-y" data-testid="cookie-consent-modal">
    <ng-container *ngIf="!isShowCookieSettings">
      <div
        class="cookie-policy-title flex-1 nordic-blue-900 font-heading-2 bold"
        [innerHTML]="cookiePolicy$ | finMap: 'teaserTitle' | async | finSafe: 'html'"
      ></div>
      <div
        class="cookie-policy-text flex-1 mr-small-y grey-800-text font-body-1 regular-type"
        [innerHTML]="cookiePolicy$ | finMap: 'detailText' | async | finSafe: 'html'"
      ></div>
      <div class="flex--nogrow align-center mr-small-t">
        <fcom-button
          [text]="'cookieConsent.allowAllCookies' | finLocalization"
          (clickBtn)="quickSetCookieClicked$.next(ConsentStatus.ACCEPTED)"
          [mode]="ButtonMode.FILL"
          [aria]="{ label: 'cookieConsent.allowAllCookies' | finLocalization, role: 'button' }"
          class="mr-xsmall-b font-body-1 fill"
          [id]="'allow-all-btn'"
          data-testid="cookie-allow-all-btn"
        ></fcom-button>
        <fcom-button
          [text]="'cookieConsent.manageCookies' | finLocalization"
          (clickBtn)="showCookieSettings()"
          [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
          [mode]="ButtonMode.FILL"
          [aria]="{ label: 'cookieConsent.manageCookies' | finLocalization, role: 'button' }"
          class="mr-xsmall-b font-body-1 fill"
          [id]="'manage-cookie-btn'"
          data-testid="manage-cookie-btn"
        ></fcom-button>
        <fcom-button
          [text]="'cookieConsent.useNecessary' | finLocalization"
          (clickBtn)="quickSetCookieClicked$.next(ConsentStatus.DECLINED)"
          [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
          [mode]="ButtonMode.FILL"
          [aria]="{ label: 'cookieConsent.useNecessary' | finLocalization, role: 'button' }"
          [id]="'necessary-cookie-btn'"
          class="font-body-1 fill"
          data-testid="necessary-cookie-btn"
        ></fcom-button>
      </div>
    </ng-container>
    <fin-cookie-settings
      data-testid="cookie-settings"
      *ngIf="isShowCookieSettings"
      [consents$]="consents$"
      (consentDataSelection)="confirmAndClose($event)"
      (modalClose)="closeModal()"
    ></fin-cookie-settings>
    <div class="flex flex--center mr-xsmall-t">
      <fcom-icon [name]="SvgLibraryIcon.INFO" class="icon-medium mr-xsmall-r"></fcom-icon>
      <div class="light-type grey-800-text font-body-1">
        {{ 'cookieConsent.cookiesTips' | finLocalization }}
      </div>
    </div>
  </div>
</fcom-modal>
