/* eslint-disable */

/**
 * Returns possible fare rules categories.
 */
export enum FareRuleCategory {
  MINIMUM_STAY = 'MINIMUM_STAY',
  MAXIMUM_STAY = 'MAXIMUM_STAY',
  CHANGE = 'CHANGE',
  REFUND = 'REFUND',
  SALES_RESTRICTIONS = 'SALES_RESTRICTIONS',
  FLIGHT_APPLICATION = 'FLIGHT_APPLICATION',
  CHILD_DISCOUNTS = 'CHILD_DISCOUNTS',
  TOUR_CONDUCTOR = 'TOUR_CONDUCTOR',
  AGENTS_DISCOUNTS = 'AGENTS_DISCOUNTS',
  OTHER_DISCOUNTS = 'OTHER_DISCOUNTS',
  STOPOVERS = 'STOPOVERS',
  TRANSFERS = 'TRANSFERS',
  ENDORSEMENT = 'ENDORSEMENT',
  COMBINABILITY = 'COMBINABILITY',
  HIGHER_INTERMEDIATE_POINTS = 'HIGHER_INTERMEDIATE_POINTS',
  MISCELLANEOUS = 'MISCELLANEOUS',
  SEASONALITY = 'SEASONALITY',
  BLACKOUTS = 'BLACKOUTS',
  DAY_TIME = 'DAY_TIME',
  TRAVEL_RESTRICTIONS = 'TRAVEL_RESTRICTIONS',
  PENALTIES = 'PENALTIES',
  SURCHARGES = 'SURCHARGES',
  ELIGIBILITY = 'ELIGIBILITY',
  VOLUNTARY_REFUNDS = 'VOLUNTARY_REFUNDS',
  RESERVATIONS_AND_TICKETING = 'RESERVATIONS_AND_TICKETING',
  ACCOMPANIED_TRAVEL = 'ACCOMPANIED_TRAVEL',
  GROUPS = 'GROUPS',
  TOURS = 'TOURS',
  FARE_BY_RULE = 'FARE_BY_RULE',
  VISIT_ANOTHER_COUNTRY = 'VISIT_ANOTHER_COUNTRY',
  NEGOTIATED_FARES = 'NEGOTIATED_FARES',
  RULE_APPLICATION = 'RULE_APPLICATION'
}

