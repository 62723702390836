import { select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseState } from '@fcom/core/index';

import { CommonBookingState, PassengerNameRecord } from '../../index';
import { selectCommonBookingState } from './feature.selector';

export const getRedirectPnrOrUndefined =
  () =>
  (state$: Observable<BaseState>): Observable<PassengerNameRecord | undefined> =>
    state$.pipe(
      select(selectCommonBookingState),
      map((state: CommonBookingState) => state?.redirect?.pnr)
    );
