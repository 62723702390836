export const typeGuard = (object: any, ...fields: string[]): boolean => {
  if (!object || !fields) {
    return false;
  }
  return fields.reduce((result, field) => result && field in object, true);
};

/** Coerces a data-bound value (typically a string) to a boolean. */
export const coerceBooleanProperty = (value: unknown): boolean => {
  return value != null && `${String(value)}` !== 'false';
};
