import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

export type TranslateOptions = {
  /** If errors should be triggered for keys that miss translations. Default is true. */
  logErrors?: boolean;
};

export abstract class LanguageService {
  static TTL: number = 7 * 24 * 60 * 60 * 1000;
  static FALLBACK_LANGUAGE = 'en';
  allowableLangs: string[] = [];

  abstract verifyLangAllowed(lang: string): void;
  abstract setLang(lang: string): void;
  abstract get countryCode(): Observable<string>;
  abstract get cookieValue(): string;
  abstract get lang(): Observable<string>;
  abstract get langKey(): Observable<string>;
  abstract get locale(): Observable<string>;
  abstract get localeValue(): string; // ex. 'en_HK' for hk-en
  abstract get langValue(): string; // ex. 'hk-en' for hk-en
  abstract get langKeyValue(): string; // ex. 'EN' for hk-en
  abstract get ddsLocaleValue(): any; // ex. { countrySite: 'HK', language: 'GB' } for hk-en
  abstract get localizations(): Observable<any>;
  abstract translate(key: string | string[], args?: any, options?: TranslateOptions): Observable<any>;
  abstract translate<T>(key: string | string[], args?: any, options?: TranslateOptions): Observable<T>;
  abstract translateMultiple(keys: string[], args?: any, options?: TranslateOptions): Observable<any[]>;
  abstract translateMultiple<T>(keys: string[], args?: any, options?: TranslateOptions): Observable<T>;
}

@Injectable()
export class MockLanguageService extends LanguageService {
  constructor() {
    super();
  }

  get countryCode(): Observable<string> {
    return of('');
  }
  get cookieValue(): string {
    return '';
  }
  get lang(): Observable<string> {
    return of('');
  }
  get langKey(): Observable<string> {
    return of('');
  }
  get locale(): Observable<string> {
    return of('');
  }
  get localeValue(): string {
    return '';
  }
  get langValue(): string {
    return '';
  }
  get langKeyValue(): string {
    return '';
  }
  get ddsLocaleValue(): any {
    return;
  }
  get localizations(): Observable<any> {
    return of();
  }

  /* istanbul ignore next */
  verifyLangAllowed(_lang: string): void {}

  /* istanbul ignore next */
  setLang(_lang: string): void {}

  translate(key: string | string[], _args?: any, _options?: TranslateOptions): Observable<any> {
    if (Array.isArray(key)) {
      return of(key[key.length - 1]);
    }
    return of(key);
  }

  translateMultiple(keys: string[], _args?: any, _options?: TranslateOptions): Observable<string[]> {
    return of(keys);
  }
}
