import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { UiTranslateModule } from '@fcom/ui-translate';
import { CmsModule } from '@fcom/cms/components';

import { QualtricsModule } from '../../qualtrics/qualtrics.module';
import { ComponentsModule } from '../../components';
import { FeedbackWidgetComponent } from '../../components/feedback-widget/feedback-widget.component';
import { DirectiveModule } from '../../directives';
import { FinFormsModule } from '../../forms';
import { PipesModule } from '../../pipes';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    DirectiveModule,
    PipesModule,
    CmsModule,
    QualtricsModule,
    FinFormsModule,
    FormsModule,
    UiTranslateModule,
  ],
  declarations: [FeedbackWidgetComponent],
  exports: [FeedbackWidgetComponent],
})
export class FeedbackWidgetModule {}
