import { FinnairAmount, SeatmapSvgAndControlData } from '@fcom/dapi/api/models';
import { SeatCharacteristics } from '@fcom/dapi/interfaces';

import { Aircraft } from './aircrafts';

export const SEAT_TYPE_EXIT = 'EXIT';

export enum SeatMapStatus {
  /**
   * No request for seat map has been placed.
   */
  INITIAL,

  /**
   * A request for fetching the seat map is ongoing.
   */
  PENDING,

  /**
   * The request for the seat map failed.
   */
  LOAD_ERROR,

  /**
   * The seat map request passed succesfully and should be in the state.
   */
  OK,

  /**
   * We don't have availability for current segment
   */
  OK_NO_AVAILABILITY,
}

export const AIRCRAFT_TRANSLATION_MAP = {
  [Aircraft.ATR]: 'atr',
  [Aircraft.E90]: 'embraer',
  [Aircraft.A319]: 'a319',
  [Aircraft.A320]: 'a320',
  [Aircraft.A32B]: 'a321',
  [Aircraft.A321]: 'a321',
  [Aircraft.A330]: 'a330',
  [Aircraft.A350]: 'a350',
};

export enum SeatTypeCabinClass {
  // Cabin class strings that can be found from SeatType like A350_ECONOMY_COMFORT
  ECONOMY = 'ECONOMY',
  BUSINESS = 'BUSINESS',
  PREMIUMECO = 'ECOPREMIUM',
}

export interface SeatMapForFlight {
  flightId: string;
  seatMap: SeatmapSvgAndControlData;
}

export interface SeatSelectionForFlight {
  seatNumber: string;
  seatType: string;
  characteristics: SeatCharacteristics[];
  paid?: boolean;
  ticketed?: boolean;
  modifiable?: boolean;
  travelerId: string;
  originalPricePerItem?: FinnairAmount;
  pricePerItem?: FinnairAmount;
}

export interface FlightSeatSelections {
  [travelerId: string]: SeatSelectionForFlight;
}

export interface SeatMapWithStatus {
  status: SeatMapStatus;
  seatMap?: SeatmapSvgAndControlData;
  selections?: FlightSeatSelections;
}

export interface SeatMapState {
  [cartFlightId: string]: SeatMapWithStatus;
}
