import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { isPresent } from '@fcom/core/utils';

import { ConfigService, StorageService } from '../services';

const containsUrl = (urlsToCheck: string[], url: string): boolean =>
  urlsToCheck && isPresent(urlsToCheck.find((urlToCheck: string) => url.indexOf(urlToCheck) >= 0));

const USE_DAPI_BACKEND_KEY = 'X-RequestedBackend';

@Injectable({
  providedIn: 'root',
})
export class DapiUatInterceptor implements HttpInterceptor {
  constructor(
    private configService: ConfigService,
    private storageService: StorageService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const dapiBackendToUse = this.storageService.LOCAL.get(USE_DAPI_BACKEND_KEY);
    if (
      !this.configService.cfg.enableDapiBackendSwitch ||
      !dapiBackendToUse ||
      !containsUrl(this.configService.useDapiUATForUrls, req.url)
    ) {
      return next.handle(req);
    }

    const request: HttpRequest<any> = req.clone({
      headers: req.headers.set(USE_DAPI_BACKEND_KEY, dapiBackendToUse),
    });
    return next.handle(request);
  }
}
