import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  fromEvent,
  merge,
  Observable,
  of,
  skipWhile,
  Subject,
  Subscription,
} from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, throttleTime, withLatestFrom } from 'rxjs/operators';

import { finShare } from '@fcom/rx';
import { WindowRef } from '@fcom/core';
import { MediaQueryService } from '@fcom/common/services';

import { PopoverData, PopoverOptions } from '../popover.interface';
import { PopoverPosition } from '../popover.enum';
import { findFirstFocusableElement } from '../../../utils/dom.utils';
import { PopoverService } from '../service/popover.service';

interface PopoverPositionProperties {
  width: string;
  top?: string;
  left?: string;
  'z-index'?: string;
}

const ABSOLUTE_X_PADDING = 10;

/**
 * The popover component is used for showing extra information to the user in a small popover,
 * there are a number of options you can use for positioning and opening conditions
 *
 * @example
 * <button
 *   fcomPopover
 *   [options]="options"
 *   [content]="contentPopover"
 *   [closeText]="closeText"
 *   [ariaCloseText]="ariaCloseText">
 *   Click
 * </button></div>
 */
@Component({
  selector: 'fcom-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  private readonly updatePosition$ = new Subject<Event>();
  private readonly resize$ = fromEvent(window, 'resize');
  private readonly scroll$ = fromEvent(window, 'scroll').pipe(debounceTime(30));
  private readonly arrowWidth = 14;

  /**
   * The element that triggered the popover and will be used for positioning
   */
  @Input()
  triggerRef: ElementRef<HTMLElement>;

  /**
   * An object containing a number of options for the popover
   */
  @Input()
  popoverOptions: PopoverOptions;

  /**
   * The content for the popover
   */
  @Input()
  popoverData: PopoverData;

  @Input()
  focusReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * This event will trigger when the popover is closed
   */
  @Output()
  closeClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Used by the popover directive to control whether the popover should remain visible when hovering
   */
  @Output()
  mouseOnContent: EventEmitter<boolean> = new EventEmitter<boolean>();

  private popoverClosed = false;
  private subscription: Subscription = new Subscription();
  private tearDown$ = new Subject<void>();

  top = 0;
  bottom = 0;
  left = 0;
  height = 0;

  arrowPositionClass: 'arrow-top' | 'arrow-bottom';
  triggerRect$: Observable<DOMRect>;
  popoverPositionProperties$: Observable<PopoverPositionProperties>;
  arrowPosition$: Observable<number>;

  @ViewChild('closeButton')
  closeButton: ElementRef;

  @ViewChild('popoverContent', { static: true })
  popoverContent: ElementRef;

  constructor(
    private windowRef: WindowRef,
    private mediaQueryService: MediaQueryService,
    private renderer: Renderer2,
    private popoverService: PopoverService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (
      event.target instanceof Node &&
      this.popoverOptions.enableOnClick &&
      !this.popoverContent.nativeElement.contains(event.target) &&
      !this.triggerRef.nativeElement.contains(event.target)
    ) {
      this.closePopover();
    }
  }

  @HostListener('focusin')
  @HostListener('mouseenter')
  onmouseenter(): void {
    this.mouseOnContent.emit(true);
  }

  @HostListener('focusout')
  @HostListener('mouseleave')
  onmouseleave(): void {
    this.mouseOnContent.emit(false);
    if (this.popoverOptions.enableOnHover) {
      this.closePopover();
    }
  }

  ngOnInit(): void {
    this.validInputs();

    this.triggerRect$ = merge(this.resize$, this.scroll$, this.updatePosition$).pipe(
      throttleTime(50),
      startWith(''),
      map((): [DOMRect, number] => [
        this.getTriggerPosition(this.triggerRef.nativeElement, this.isFixedPosition),
        this.height,
      ]),
      distinctUntilChanged(
        ([aRect, aHeight], [bRect, bHeight]) => aRect.x === bRect.x && aRect.y === bRect.y && aHeight === bHeight
      ),
      map(([rect]) => rect),
      finShare()
    );

    this.popoverPositionProperties$ = combineLatest([this.triggerRect$, this.isFullscreen$]).pipe(
      map(([triggerRect, isFullscreen]) => ({
        top: isFullscreen ? '0px' : `${this.top}px`,
        left: isFullscreen ? '0px' : `${this.left}px`,
        width: isFullscreen ? '100vw' : `${this.popoverWidth}px`,
        ...(this.isFixedPosition && {
          ['max-height']: isFullscreen ? '100vh' : `calc(100vh - ${triggerRect.bottom + 20}px)`,
        }),
        ...(this.popoverOptions?.zIndex && { 'z-index': this.popoverOptions.zIndex }),
      })),
      startWith({
        width: `${this.popoverWidth}px`,
      }),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );

    this.arrowPosition$ = this.triggerRect$.pipe(
      map((triggerRect: DOMRect) => this.computeArrowPosition(triggerRect)),
      distinctUntilChanged()
    );

    this.subscription.add(
      this.triggerRect$.subscribe((rect) => {
        this.computeOffsetLeft(rect);
        this.computeOffsetTop(rect);
      })
    );

    this.subscription.add(
      this.isFullscreen$.subscribe((isFullscreen) => {
        if (isFullscreen) {
          this.renderer.addClass(this.document.body, 'overflow-hidden');
          this.renderer.addClass(this.document.body, 'grow');
        } else {
          this.renderer.removeClass(this.document.body, 'overflow-hidden');
          this.renderer.removeClass(this.document.body, 'grow');
        }
      })
    );

    this.subscription.add(
      this.tearDown$
        .pipe(
          withLatestFrom(this.isFullscreen$),
          filter(([, isFullscreen]) => isFullscreen)
        )
        .subscribe(() => {
          this.renderer.removeClass(this.document.body, 'overflow-hidden');
          this.renderer.removeClass(this.document.body, 'grow');
        })
    );
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.focusReady$.pipe(skipWhile(() => this.popoverOptions.disableAutoFocus)).subscribe((ready) => {
        if (ready) {
          findFirstFocusableElement(this.popoverContent.nativeElement)?.focus();
        }
      })
    );
  }

  ngAfterViewChecked(): void {
    if (this.height !== this.popoverContent.nativeElement.offsetHeight) {
      this.height = this.popoverContent.nativeElement.offsetHeight;
      this.updatePosition$.next(null);
    }
  }

  ngOnDestroy(): void {
    this.tearDown$.next();
    this.subscription.unsubscribe();
  }

  validInputs(): never | boolean {
    if (!this.triggerRef) {
      throw new Error('Element reference is required');
    }
    return true;
  }

  private popoverShouldBeOnTop(triggerRect: DOMRect, popoverHeight: number): boolean {
    return popoverHeight + this.arrowWidth < triggerRect.top;
  }

  private getTriggerPosition(trigger: Element, isFixedPosition: boolean): DOMRect {
    const { top, left, width, height } = trigger.getBoundingClientRect();
    const { scrollTop, scrollLeft, clientTop, clientLeft } = document.documentElement;
    const rect = {
      y: isFixedPosition ? top + clientTop : top + scrollTop - clientTop,
      x: isFixedPosition ? left + clientLeft : left + scrollLeft - clientLeft,
      width,
      height,
    };
    return new DOMRect(rect.x, rect.y, rect.width, rect.height);
  }

  private computeOffsetLeft(triggerRect: DOMRect): void {
    if (this.popoverOptions.alignToLeft) {
      this.left = triggerRect.left;
      return;
    }

    if (triggerRect.right >= this.popoverWidth) {
      this.left = triggerRect.right - this.popoverWidth;
    } else {
      this.left = this.minOffsetLeft;
    }
  }

  private computeOffsetTop(triggerRect: DOMRect): void {
    const popoverHeight = this.popoverContent.nativeElement.getBoundingClientRect().height;
    if (
      this.popoverPosition === PopoverPosition.TOP ||
      (this.popoverPosition === PopoverPosition.AUTOMATIC && this.popoverShouldBeOnTop(triggerRect, popoverHeight))
    ) {
      this.arrowPositionClass = 'arrow-bottom';
      this.top = triggerRect.top - popoverHeight - this.arrowWidth;
    } else {
      this.arrowPositionClass = 'arrow-top';
      this.top = triggerRect.top + triggerRect.height + this.arrowWidth;
    }
  }

  computeArrowPosition(triggerRect: DOMRect): number {
    const triggerCenter = triggerRect.left + triggerRect.width / 2;

    if (this.isFixedPosition) {
      return triggerCenter - this.left - this.arrowWidth / 2;
    } else {
      return triggerCenter - this.left - this.arrowWidth;
    }
  }

  closePopover(): void {
    this.popoverService.close();

    if (!this.popoverClosed) {
      this.popoverClosed = true;
      this.closeClick.emit(true);
    }
  }

  get minOffsetLeft(): number {
    return this.popoverOptions?.minOffsetLeft ?? ABSOLUTE_X_PADDING;
  }

  get popoverWidth(): number {
    const windowWidth = this.windowRef.nativeWindow.innerWidth;
    const popoverWidth = this.popoverOptions?.popoverWidth ?? 342;
    const padding = ABSOLUTE_X_PADDING * 2;
    if (windowWidth && popoverWidth + padding > windowWidth) {
      return windowWidth - padding;
    }
    return popoverWidth;
  }

  get popoverPosition(): PopoverPosition {
    return this.popoverOptions?.popoverPosition ?? PopoverPosition.AUTOMATIC;
  }

  get isFixedPosition(): boolean {
    return Boolean(this.popoverOptions?.useFixedPosition);
  }

  get content(): TemplateRef<ElementRef> {
    return this.popoverData.content ?? null;
  }

  get closeText(): string {
    return this.popoverData.closeText ?? '';
  }

  get ariaCloseText(): string {
    return this.popoverData.ariaCloseText ?? 'close';
  }

  get showLeftBorder(): boolean {
    return this.popoverOptions?.showLeftBorder ?? true;
  }

  get showArrowCaret(): boolean {
    return this.popoverOptions?.showArrowCaret ?? true;
  }

  get roundedCorners(): boolean {
    return this.popoverOptions?.roundedCorners ?? false;
  }

  get hasPadding(): boolean {
    return this.popoverOptions?.hasPadding ?? true;
  }

  get isFullscreen$(): Observable<boolean> {
    if (this.popoverOptions.showFullscreenOnBreakpoint === undefined) {
      return of(false);
    }
    return this.mediaQueryService.isBreakpoint$(this.popoverOptions.showFullscreenOnBreakpoint);
  }
}
