import { isBlank } from '@fcom/core/utils';

export interface AdultAndChildrenCount {
  adults: number;
  c15s: number;
  children: number;
}

export const getAdultsAndChildren = (adults = 0, c15s = 0, children = 0, childrenAges = ''): AdultAndChildrenCount => {
  const listOfAges = childrenAges.split(',').filter((age) => !isBlank(age));
  const numberOfAges = listOfAges.length;
  const childrenIsDefined = children === numberOfAges;
  return listOfAges.reduce(
    (adultsAndChilds, currentValue) => {
      if (parseInt(currentValue, 10) > 15) {
        adultsAndChilds.adults++;
        if (childrenIsDefined) {
          adultsAndChilds.children--;
        }
      } else if (parseInt(currentValue, 10) > 11) {
        adultsAndChilds.c15s++;
        if (childrenIsDefined) {
          adultsAndChilds.children--;
        }
      } else {
        if (!childrenIsDefined) {
          adultsAndChilds.children++;
        }
      }
      return adultsAndChilds;
    },
    { adults, c15s, children }
  );
};

export const isFrequentFlyerFieldFilled = (cardNumber: string, companyCode: string): boolean => {
  return !!cardNumber || !!companyCode;
};
