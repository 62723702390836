<h2 class="font-heading-4">{{ 'MMB.cancelBooking.involuntary.refund.subTitle' | finLocalization }}</h2>
<fcom-notification
  *ngIf="(services$ | async)?.length && showNotifications"
  class="flex--basis-full"
  [layout]="NotificationLayout.BLOCK"
  [excludeColumns]="true"
  [theme]="NotificationTheme.TRANSPARENT"
>
  <cms-unstyled-fragment
    class="font-body-1"
    [contentUrl$]="
      'MMB.cancelBooking.involuntary.refund.refundStatusChecker.linkWithService.url' | finLocalization | finToObservable
    "
    [showTitle]="false"
    [showIcon]="true"
  >
  </cms-unstyled-fragment>
</fcom-notification>

<fin-section-review
  *ngIf="(cancelRefund$ | finMap: 'order' | async) !== null"
  class="mr-xsmall-t"
  [ngClass]="{ 'mr-xsmall-b': showNotifications }"
  [title]="'MMB.cancelBooking.involuntary.refund.flight.title' | finLocalization"
  [noButton]="true"
  [fullWidth]="false"
>
  <ng-container ngProjectAs="content">
    <fin-refund-price-breakdown
      class="flex--basis-full"
      [prices$]="cancelRefund$ | finMap: 'refundAmount'"
      [passengers$]="cancelRefund$ | finMap: 'order.passengers'"
      [expandOpen]="true"
    >
    </fin-refund-price-breakdown>
    <div class="border-top-dashed fill"></div>
    <div class="flex--basis-full block pr-small-x" *ngIf="(cancelRefund$ | async)?.order?.passengers?.length > 1">
      <div class="font-body-1 content">
        <div class="ps-small flex flex--center" *ngIf="cancelRefund$ | finMap: 'refundAmount' | async as prices">
          <button
            (click)="openSummaryModal($event, prices)"
            class="detailed-breakdown underline nordic-blue-900-text bold-type"
          >
            {{ 'purchase.priceBreakdown.detailedBreakdown' | finLocalization }}
          </button>
        </div>
      </div>
    </div>
    <fin-section-review
      class="flex--basis-full mr-small-t purchase-review-total-prices"
      [title]="'MMB.cancelBooking.involuntary.refund.totalRefund' | finLocalization"
      [endTitle]="(cancelRefund$ | async)?.refundAmount.total?.total?.totalAmount | finPrice: { useSymbol: true }"
      [noContent]="true"
      [noButton]="true"
      [fullWidth]="false"
      [alignParent]="true"
      [hasBorder]="false"
    >
    </fin-section-review>
    <fcom-notification class="ps-small-y flex--basis-full" [layout]="NotificationLayout.BLOCK" [excludeColumns]="true">
      <ng-container *ngIf="!showNotifications; else confirmationNotification">
        {{ flightNotificationKey | finLocalization }}
      </ng-container>
    </fcom-notification>
  </ng-container>
</fin-section-review>

<fin-section-review
  *ngIf="(cancelRefund$ | finMap: 'order' | async) !== null && (services$ | async)?.length"
  class="mr-xsmall-t"
  [ngClass]="{ 'mr-xsmall-b': showNotifications }"
  [title]="'MMB.cancelBooking.involuntary.refund.travelExtra.title' | finLocalization"
  [noButton]="true"
  [fullWidth]="false"
>
  <ng-container ngProjectAs="content">
    <ng-content></ng-content>
  </ng-container>
</fin-section-review>

<fcom-modal
  *ngIf="(cancelRefund$ | async)?.order?.passengers?.length > 1"
  [(open)]="summaryModalOpen"
  [title]="'purchase.priceBreakdown.detailedBreakdownPassenger' | finLocalization"
  [buttons]="ModalButtons.DONE"
  (close)="closeSummaryModal()"
>
  <ng-container *ngFor="let breakdown of breakdowns$ | finObsToArray">
    <div class="pr-xsmall-t pr-large-l pr-xxlarge-r">
      <div class="trigger flex" *ngIf="breakdown | async as passenger">
        <div class="flex-1">
          <h3 class="font-heading-5 nordic-blue-50-bg">
            <span class="flex-1 flex--self-center ps-xsmall-l medium-type" data-hj-suppress>
              {{ passenger?.title ? ('passenger.title.' + passenger?.title | lowercase | finLocalization) : '' }}
              {{ passenger?.firstName }} {{ passenger?.lastName }} ({{
                'passenger.' + asPaxTypeKey(passenger.passengerTypeCode).toLowerCase() | finLocalization | titlecase
              }})
            </span>
          </h3>
        </div>
      </div>
      <div class="font-body-1 ps-xsmall-t content" *ngIf="breakdown | async as pax">
        <table class="fill price-breakdown-per-passenger">
          <caption class="is-vishidden">
            {{
              'purchase.priceBreakdown.detailedBreakdownPassenger' | finLocalization
            }}
          </caption>

          <thead>
            <tr>
              <th class="flex medium-type ps-xxsmall-b" scope="col" colspan="2">
                {{ 'purchase.priceBreakdown.flights' | finLocalization }}
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              [ngTemplateOutlet]="priceLine"
              [ngTemplateOutletContext]="{
                label: 'purchase.priceBreakdown.flights' | finLocalization,
                originalValue: pax.prices?.originalBaseFare ? (pax.prices?.originalBaseFare | finPrice) : undefined,
                value: (pax.prices?.baseFare | finPrice),
                bold: false,
              }"
            >
            </ng-container>

            <tr class="ps-xxsmall-b" [class.border-bottom]="pax.services.length > 0">
              <td colspan="3">
                <fin-expand
                  class="price-breakdown-modal"
                  [key]="'price-breakdown-expand-' + (breakdown | async)?.id"
                  [id]="'price-breakdown-expand-' + (breakdown | async)?.id"
                  #expand
                >
                  <div class="trigger flex">
                    <div class="flex-1">
                      <span class="underline medium-type">
                        {{ 'purchase.review.taxTotal' | finLocalization }}
                      </span>
                    </div>
                    <div>
                      <span class="medium-type"
                        >{{
                          pax.prices?.totalTax || { amount: '0', currencyCode: pax.prices?.baseFare.currencyCode }
                            | finPrice
                        }}
                      </span>
                      <span class="ps-xsmall-l">
                        <fcom-icon
                          class="flex-1 flex--nogrow rotate flex--self-start icon-medium nordic-blue-900-text"
                          [class.rotate-active]="expand.isOpen$ | async"
                          [name]="SvgLibraryIcon.CHEVRON_DOWN"
                        ></fcom-icon>
                      </span>
                    </div>
                  </div>
                  <div class="font-body-1 content">
                    <ul class="list-disc-style">
                      <li *ngFor="let tax of pax.prices.taxes">
                        <div class="flex flex--space-between">
                          <span>
                            {{ 'TAX_CODES.' + tax.code.slice(0, 2) | finLocalization | finEmpty: tax.code.slice(0, 2) }}
                          </span>
                          <span>
                            {{ tax | finPrice }}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </fin-expand>
              </td>
            </tr>
            <ng-container *ngFor="let fee of pax.prices?.fees">
              <ng-container
                [ngTemplateOutlet]="priceLine"
                [ngTemplateOutletContext]="{
                  label: 'purchase.review.' + fee.code | finLocalization,
                  value: fee | finPrice,
                  bold: false,
                }"
              >
              </ng-container>
            </ng-container>
          </tbody>
          <tfoot class="border-top">
            <ng-container
              [ngTemplateOutlet]="priceLine"
              [ngTemplateOutletContext]="{
                label: ('purchase.priceBreakdown.totalPrice' | finLocalization),
                value: (pax.total | finPrice),
                bold: true,
              }"
            >
            </ng-container>
          </tfoot>
        </table>
      </div>
    </div>
  </ng-container>
</fcom-modal>

<ng-template #confirmationNotification>
  <ng-container *ngIf="(services$ | async)?.length; else refundNotificationWithLink">
    <span>
      {{ 'MMB.cancelBooking.involuntary.refund.flight.confirmation.notification' | finLocalization }}
    </span>
  </ng-container>
</ng-template>

<ng-template #refundNotificationWithLink>
  <cms-unstyled-fragment
    class="font-body-1"
    [contentUrl$]="
      'MMB.cancelBooking.involuntary.refund.refundStatusChecker.link.url' | finLocalization | finToObservable
    "
    [showTitle]="false"
    [showIcon]="true"
  >
  </cms-unstyled-fragment>
</ng-template>
<ng-template
  #priceLine
  let-label="label"
  let-value="value"
  let-originalValue="originalValue"
  let-bold="bold"
  let-borderBottom="borderBottom"
>
  <tr class="ps-xxsmall-b" [ngClass]="{ 'border-bottom': borderBottom }">
    <th
      scope="row"
      class="align-left ps-xxsmall-b"
      [ngClass]="{ 'light-type': !bold, 'medium-type ms-xsmall-t': bold }"
    >
      <ng-container *ngIf="label">{{ label }}</ng-container>
    </th>
    <td class="align-right ps-xxsmall-b" [ngClass]="{ 'medium-type ms-xsmall-t': bold }">
      <span class="nordic-blue-900-text strike-through pr-xxsmall-r" *ngIf="originalValue">{{ originalValue }}</span
      >{{ value }}
    </td>
  </tr>
</ng-template>
