import { SpinnerType } from '@fcom/common-booking/interfaces';
import { ElementTypes, Breakpoint } from '@fcom/common/interfaces';
import { FinnairBoundNote, FinnairItineraryNote, FinnairPassengerCode } from '@fcom/dapi/api/models';
import { BoundType } from '@fcom/dapi';

export class AppConstants {
  public static readonly FinnairPassengerCode = FinnairPassengerCode;
  public static readonly BoundType = BoundType;
  public static readonly SpinnerType = SpinnerType;
  public static readonly ElementTypes = ElementTypes;
  public static readonly FinnairBoundNote = FinnairBoundNote;
  public static readonly FinnairItineraryNote = FinnairItineraryNote;
  public static readonly Breakpoint = Breakpoint;
}
