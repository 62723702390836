import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CmsMedia } from '@fcom/core-api';

@Component({
  selector: 'fin-detail-text',
  templateUrl: './detail-text.component.html',
  styleUrls: ['./detail-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailTextComponent {
  @Input()
  mediaObject: CmsMedia[];

  @Input()
  markup = '';
}
