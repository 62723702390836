import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'fin-editorial-page-head',
  templateUrl: './editorial-page-head.component.html',
  styleUrls: ['./editorial-page-head.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorialPageHeadComponent {
  @Input()
  titleAsHeading = true;
  @Input()
  title: string;
}
