import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { combineLatest, map, Observable } from 'rxjs';

import { BoundType, LocationInfoMap, Duration } from '@fcom/dapi';
import { FinnairBoundItem, FinnairDisruptedBoundItem } from '@fcom/dapi/api/models';
import { NotificationLayout, NotificationTheme } from '@fcom/ui-components';
import { getCityName } from '@fcom/common/utils/booking.utils';
import { finShare } from '@fcom/rx';

import { BUS_AIRCRAFT_CODE, EndPointChanges } from '../../../interfaces';

@Component({
  selector: 'fin-itinerary-timeline-bound-header',
  styleUrls: ['../arrow-block.scss'],
  templateUrl: './itinerary-timeline-bound-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryTimelineBoundHeaderComponent implements OnInit {
  public readonly BoundType = BoundType;
  public readonly BUS_AIRCRAFT_CODE = BUS_AIRCRAFT_CODE;

  public readonly NotificationLayout = NotificationLayout;
  public readonly NotificationTheme = NotificationTheme;
  public readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  boundType: BoundType;

  @Input()
  bound$: Observable<FinnairBoundItem | FinnairDisruptedBoundItem>;

  @Input()
  locations$: Observable<LocationInfoMap>;

  @Input()
  departureChanges: EndPointChanges;

  @Input()
  arrivalChanges: EndPointChanges;

  @Input()
  boundChangeDuration: Duration;

  @Input()
  showNoChangesNotification: boolean;

  @Input()
  title: string;

  departureCity$: Observable<string>;
  arrivalCity$: Observable<string>;

  ngOnInit(): void {
    const latestBoundAndLocations$ = combineLatest([this.bound$, this.locations$]);

    this.departureCity$ = latestBoundAndLocations$.pipe(
      map(([bound, locations]) => getCityName(bound.departure.locationCode, locations)),
      finShare()
    );

    this.arrivalCity$ = latestBoundAndLocations$.pipe(
      map(([bound, locations]) => getCityName(bound.arrival.locationCode, locations)),
      finShare()
    );
  }
}
