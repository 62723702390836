import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

interface LetContext<T> {
  finLet: T;
}

@Directive({
  selector: '[finLet]',
})
export class LetDirective<T> {
  private context: LetContext<T> = { finLet: null };

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<LetContext<T>>
  ) {
    this.viewContainer.createEmbeddedView(this.templateRef, this.context);
  }

  @Input()
  set finLet(value: T) {
    this.context.finLet = value;
  }
}
