import { createReducer, on } from '@ngrx/store';

import { MilestoneState } from '../../interfaces/store.interface';
import { MilestonesActions } from '../actions';

export const initialState: MilestoneState = Object.seal({ dismissedWithPoints: 0 });

const milestonesReducer = createReducer(
  initialState,
  on(MilestonesActions.setDismissed, (state, { points }) => ({
    ...state,
    dismissedWithPoints: points,
  }))
);

export function reducer(state: MilestoneState, action: MilestonesActions.MilestonesActionsUnion): MilestoneState {
  return milestonesReducer(state, action);
}
