<div class="flex flex--column border rounded-large white-bg ms-medium-b overflow-hidden">
  <div class="flex flex--space-between ps-medium">
    <h3 class="mr-clear-t font-heading-4">{{ title | finLocalization }}</h3>
    <fcom-icon
      *ngIf="showIcon"
      [dataTestId]="'pax-logic-icon'"
      [name]="SvgLibraryIcon.FINNAIR_EMBLEM_BLOCK"
    ></fcom-icon>
  </div>
  <div data-testid="pax-login-benefits" class="flex ps-medium-x ps-xsmall-y pax-login-row">
    <ul *ngIf="benefitMode === 'list'" class="flex-1 pax-login-list">
      <li>{{ 'loginPromotion.benefitList.personalDetailsFilled' | finLocalization }}</li>
      <li>{{ 'loginPromotion.benefitList.earnPoints' | finLocalization }}</li>
      <li>{{ 'loginPromotion.benefitList.membershipBenefits' | finLocalization }}</li>
    </ul>
    <p *ngIf="benefitMode === 'text'" class="pax-login-benefit-text regular-type ms-medium-r">
      {{ benefitText | finLocalization }}
    </p>
    <div class="flex flex-1 flex--column">
      <fcom-button
        (clickBtn)="onLoginClick()"
        [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
        [mode]="ButtonMode.FILL"
        [text]="'loginPromotion.loginButtonText' | finLocalization"
        data-testid="pax-login-card-log-in-button"
      >
      </fcom-button>
      <fcom-button
        (clickBtn)="showForgotPassword ? onForgotPasswordClick() : onJoinClick()"
        [theme]="ButtonTheme.LIGHT_TERTIARY_GHOST"
        [mode]="ButtonMode.FILL"
        [text]="
          (showForgotPassword ? 'loginPromotion.forgotPassword' : 'loginPromotion.createAccount') | finLocalization
        "
        class="underline ms-xsmall-t"
        data-testid="pax-login-card-secondary-button"
      >
      </fcom-button>
    </div>
  </div>
  <span
    data-testid="pax-login-footnote"
    *ngIf="footnoteText"
    class="grey-100-bg rounded-bottom-large ps-medium-x ps-xsmall-y regular-type font-body-1"
    >{{ footnoteText | finLocalization }}
  </span>
</div>
