// TODO: Refactor this soon for the Blue Wings footer offer and merge the logic either into FooterOfferComponent or into the template using it.
// This component is currently not referred to in cms.module.ts or index.ts.
import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { Amount } from '@fcom/dapi';
import { LocationRouteCffService } from '@fcom/core/services';
import { GlobalBookingTravelClass } from '@fcom/core';
import { unsubscribe } from '@fcom/core/utils';

import { CheapestPriceForAllDestinationsService } from '../../services';
import { FooterOffer, MarketingOffer } from '../../interfaces';

@Component({
  selector: 'cms-book-now',
  template: ' <fin-footer-offer [offer]="offer"></fin-footer-offer> ',
  styleUrls: ['./cms-book-now.component.scss'],
})
export class CmsBookNowComponent implements OnInit, OnDestroy {
  offer: FooterOffer;
  price$: Observable<Amount>;

  @Input()
  locationCode: string;

  private subscription = new Subscription();

  constructor(
    private cheapestPriceForAllDestinationsService: CheapestPriceForAllDestinationsService,
    private locationRouteCffService: LocationRouteCffService,
    private languageService: LanguageService
  ) {}

  ngOnInit(): void {
    this.offer = {
      locationCode: this.locationCode,
      location: '',
    };

    if (this.locationCode) {
      this.subscription.add(
        this.locationRouteCffService
          .bestGuessFor(this.locationCode, this.languageService.localeValue)
          .pipe(take(1))
          .subscribe((res) => {
            this.offer.location = res.title;
          })
      );
    }

    this.price$ = this.cheapestPriceForAllDestinationsService.offerForSingleTrip(this.offer.locationCode, true).pipe(
      map(
        (offer: MarketingOffer) =>
          offer.prices.find((t) => t.travelClass.toUpperCase() === GlobalBookingTravelClass.ECONOMY)?.price
      ),
      filter<Amount>(Boolean)
    );
  }

  ngOnDestroy(): void {
    this.subscription = unsubscribe(this.subscription);
  }
}
