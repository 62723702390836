import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { CheckboxOptionList, TypedFormArray, TypedFormGroup } from '../../interfaces';

@Component({
  selector: 'fin-form-input-checkbox-list',
  templateUrl: 'form-input-checkbox-list.component.html',
})
export class FormInputCheckboxListComponent {
  @Input()
  parentForm: TypedFormGroup<UntypedFormGroup | TypedFormArray<UntypedFormGroup>>;

  @Input()
  controlName: string;

  @Input()
  checkboxList: CheckboxOptionList;

  get formArray(): TypedFormArray<UntypedFormGroup> {
    return this.parentForm.get(this.controlName) as TypedFormArray<UntypedFormGroup>;
  }
}
