import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  forkJoin,
  map,
  of,
  switchMap,
  take,
  withLatestFrom,
  iif,
  throwError,
} from 'rxjs';

import { finShare, retryWithBackoff } from '@fcom/rx';
import { Location, LocationSuggestions, LocationGeoLocData } from '@fcom/core-api';
import {
  LatLng,
  LocationRouteCffService,
  equals,
  isPresent,
  unsubscribe,
  isEmptyObjectOrHasEmptyValues,
  StorageService,
} from '@fcom/core';
import { FlightSearchParams, RecommendationService } from '@fcom/common';
import {
  CommonFeatureState,
  GlobalBookingActions,
  GlobalBookingFlight,
  LocationPair,
  globalBookingLocations,
  globalBookingFlights,
} from '@fcom/common/store';
import { LanguageService } from '@fcom/ui-translate';
import { akamaiGeolocation } from '@fcom/core/selectors';

import { createPreviousSearches } from '../utils/utils';
import { PREVIOUS_SEARCHES_KEY } from '../constants';
import { PreviousSearch } from '../interfaces';
import { BookingWidgetAppState } from '../store';
import { BookingWidgetGtmService } from './booking-widget-gtm.service';

@Injectable({ providedIn: 'root' })
export class BookingWidgetFlightService implements OnDestroy {
  readonly locations$: Observable<LocationPair>;
  readonly bookingLocations$: Observable<LocationPair[]>;
  readonly flights$: Observable<GlobalBookingFlight[]>;
  readonly suggestedLocations$: Observable<LocationSuggestions>;
  readonly trendingDestinations$: Observable<Location[]>;
  readonly selectedPreviousSearches$: Observable<PreviousSearch>;
  private suggestedLocationsMap$ = new BehaviorSubject<Record<string, LocationSuggestions>>({});
  private trendingDestinationsMap$ = new BehaviorSubject<Record<string, Location[]>>({});
  private subscription = new Subscription();
  private defaultLocations$ = new BehaviorSubject<LocationPair[]>([]);
  private _selectedPreviousSearches$ = new BehaviorSubject<PreviousSearch>(undefined);

  constructor(
    private store$: Store<CommonFeatureState & BookingWidgetAppState>,
    private languageService: LanguageService,
    private bookingWidgetGtmService: BookingWidgetGtmService,
    private locationRouteCffService: LocationRouteCffService,
    private recommendationService: RecommendationService,
    private storageService: StorageService
  ) {
    // selectors
    this.bookingLocations$ = this.store$.pipe(
      globalBookingLocations(),
      distinctUntilChanged((prev, next) => prev.length === next.length && next.every((l, i) => equals(l, prev[i]))),
      finShare()
    );

    this.locations$ = this.bookingLocations$.pipe(
      map((locations) => locations[0]),
      distinctUntilChanged((prev, next) => equals(prev, next)),
      finShare()
    );

    this.flights$ = this.store$.pipe(
      globalBookingFlights(),
      distinctUntilChanged((prev, next) => equals(prev, next)),
      finShare()
    );

    this.suggestedLocations$ = combineLatest([this.languageService.countryCode, this.suggestedLocationsMap$]).pipe(
      map(([countryCode, suggestedLocationsMap]) => suggestedLocationsMap[countryCode]),
      finShare()
    );

    this.trendingDestinations$ = combineLatest([this.locations$, this.trendingDestinationsMap$]).pipe(
      map(([locations, trendingDestinationsMap]) => trendingDestinationsMap[locations?.origin?.locationCode]),
      finShare()
    );
    this.selectedPreviousSearches$ = this._selectedPreviousSearches$.asObservable();
    //get suggested locations, trending destinations and default locations
    this.getSuggestedLocationBasedOnCountryCode();
    this.getTrendingDestinationsBasedOnOrigin();
    this.initDefaultLocations();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  removeFlight(index: number): void {
    this.store$.dispatch(GlobalBookingActions.removeFlight({ index }));
  }

  addFlight(): void {
    this.store$.dispatch(GlobalBookingActions.addFlight());
  }

  setLocations({ origin, destination }: LocationPair, index = 0): void {
    this.bookingWidgetGtmService.trackElementEvent(
      'locations',
      `${origin?.locationCode} - ${destination?.locationCode}`
    );

    this.store$.dispatch(GlobalBookingActions.updateFlight({ flight: { origin, destination }, index }));
  }

  setDefaultLocations(defaultLocations$: Observable<LocationPair[]>): void {
    this.subscription.add(
      defaultLocations$.subscribe((defaultLocations) => {
        this.defaultLocations$.next(defaultLocations);
      })
    );
  }

  getSuggestedLocationBasedOnCountryCode(): void {
    this.subscription.add(
      this.languageService.countryCode
        .pipe(
          distinctUntilChanged(),
          withLatestFrom(this.suggestedLocationsMap$),
          filter(([countryCode, suggestedLocationsMap]) => !suggestedLocationsMap[countryCode]),
          switchMap(([countryCode, suggestedLocationsMap]) =>
            this.locationRouteCffService.getSuggestedLocations(countryCode, this.languageService.localeValue).pipe(
              map((suggestedLocationByCountryCode) => ({
                ...suggestedLocationsMap,
                [countryCode]: suggestedLocationByCountryCode,
              }))
            )
          )
        )
        .subscribe((suggestedLocations) => {
          this.suggestedLocationsMap$.next(suggestedLocations);
        })
    );
  }

  getTrendingDestinationsBasedOnOrigin(): void {
    this.subscription.add(
      this.locations$
        .pipe(
          distinctUntilChanged(),
          withLatestFrom(this.trendingDestinationsMap$),
          filter(
            ([locations, trendingDestinationsMap]) =>
              locations?.origin?.locationCode && !trendingDestinationsMap[locations?.origin?.locationCode]
          ),
          switchMap(([locations, trendingDestinationsMap]) =>
            this.recommendationService.getTrendingDestinations(locations?.origin?.locationCode, 0, 10).pipe(
              map((trendingDestinations) => ({
                ...trendingDestinationsMap,
                [locations?.origin?.locationCode]: trendingDestinations,
              }))
            )
          )
        )
        .subscribe((trendingDestinations) => {
          this.trendingDestinationsMap$.next(trendingDestinations);
        })
    );
  }

  initDefaultLocations(): void {
    this.defaultLocations$
      .pipe(
        distinctUntilChanged((prev, next) => equals(prev, next)),
        map((defaultLocations) => (isEmptyObjectOrHasEmptyValues(defaultLocations) ? null : defaultLocations)),
        filter(Boolean),
        withLatestFrom(this.locations$),
        switchMap(([locationsToBeSet, locationFromStore]) => {
          const origin = isPresent(locationsToBeSet?.[0]?.origin?.locationCode)
            ? locationsToBeSet?.[0]?.origin
            : locationFromStore?.origin;
          const destination = isPresent(locationsToBeSet?.[0]?.destination?.locationCode)
            ? locationsToBeSet?.[0]?.destination
            : locationFromStore?.destination;

          // fetch default locations
          return forkJoin({
            origin: origin?.locationCode
              ? this.locationRouteCffService.bestGuessFor(origin.locationCode, this.languageService.localeValue)
              : of(origin),
            destination: destination?.locationCode
              ? this.locationRouteCffService.bestGuessFor(destination?.locationCode, this.languageService.localeValue)
              : of(destination),
          });
        }),
        finShare()
      )
      .subscribe((location) => {
        this.setLocations(location);
      });
  }

  // fetch user geoLocation data and set to origin, when origin data is missing or default location is missing
  fetchUserLocationAndSetDefaultOrigin(): void {
    this.subscription.add(
      combineLatest([this.locations$, this.defaultLocations$, this.store$.pipe(akamaiGeolocation())])
        .pipe(
          take(1),
          filter(
            ([{ origin }, defaultLocations]) =>
              !isPresent(origin) || (defaultLocations.length > 0 && !isPresent(defaultLocations[0]?.origin))
          ),
          map(([_, __, geoLocation]) => geoLocation)
        )
        .pipe(
          switchMap((value: LatLng) =>
            this.locationRouteCffService.geolocMatchFor(value.lat, value.lng, this.languageService.localeValue)
          ),
          switchMap((location: LocationGeoLocData) =>
            this.locationRouteCffService.bestGuessFor(location.item.locationCode, this.languageService.localeValue)
          ),
          retryWithBackoff(2),
          catchError((err: unknown) => {
            return throwError(() => err);
          }),
          withLatestFrom(this.defaultLocations$),
          switchMap(([cffLocation, defaultLocations]) =>
            iif(
              () => isPresent(defaultLocations?.[0]?.destination?.locationCode),
              this.locationRouteCffService
                .bestGuessFor(defaultLocations?.[0]?.destination?.locationCode, this.languageService.localeValue)
                .pipe(map((destination) => [cffLocation, destination])),
              of([cffLocation])
            )
          ),
          finShare()
        )
        .subscribe(([cffLocation, destination]) => {
          this.setLocations({
            origin: cffLocation,
            ...(isPresent(destination) && { destination }),
          });
        })
    );
  }

  setPreviousSearchToLocalStorage = (flightSearchParams: FlightSearchParams): void => {
    if (flightSearchParams.isAward) {
      return;
    }

    let previousSearches: PreviousSearch[];
    const previousSearchesStorage = this.storageService.LOCAL.get(PREVIOUS_SEARCHES_KEY);

    try {
      previousSearches = JSON.parse(previousSearchesStorage) ?? [];
    } catch {
      previousSearches = [];
    }

    const updatedPreviousSearches = createPreviousSearches(flightSearchParams, previousSearches);
    this.storageService.LOCAL.set(PREVIOUS_SEARCHES_KEY, JSON.stringify(updatedPreviousSearches));
  };

  setPreviousFlightSelection(previousSearch: PreviousSearch, isGlobalBookingWidget: boolean): void {
    if (isGlobalBookingWidget) {
      this._selectedPreviousSearches$.next(previousSearch);
    }
    this.store$.dispatch(GlobalBookingActions.setSelection({ selection: previousSearch }));
  }

  resetSelectedPreviousSearch(): void {
    this._selectedPreviousSearches$.next(undefined);
  }
}
