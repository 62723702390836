import { Injectable, OnDestroy } from '@angular/core';

import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { SentryLogger, mapErrorForSentry } from '@fcom/core';
import { finShare, triggerWhenPreviousCompletes } from '@fcom/rx';
import { unsubscribe } from '@fcom/core/utils';

@Injectable()
export class QueueService implements OnDestroy {
  private currentOffer$: Subject<string> = new BehaviorSubject('unknown_or_refreshed_page');
  private currentQueue$: Observable<Subject<Observable<any>>> = new ReplaySubject(1);
  private currentCancelPayment$ = new Subject<void>();
  public cancelServiceAvailability$ = new Subject<void>();

  private subscriptions = new Subscription();

  constructor(private sentryLogger: SentryLogger) {
    this.currentQueue$ = this.currentOffer$.pipe(
      distinctUntilChanged(),
      map(() => new Subject<Observable<any>>()),
      finShare()
    );
    this.subscriptions.add(
      this.currentQueue$.pipe(switchMap((queue) => queue.pipe(triggerWhenPreviousCompletes()))).subscribe()
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscriptions);
  }

  sendToQueue(actionName: string, obs$: Observable<any>, cancelStream$: Observable<any>): void {
    let canceledBeforeTrigger = false;
    const subscription = new Subscription();
    subscription.add(cancelStream$.subscribe(() => (canceledBeforeTrigger = true)));

    this.subscriptions.add(
      this.currentQueue$.pipe(take(1)).subscribe((queue) => {
        const task$ = new Observable((o) => {
          try {
            if (canceledBeforeTrigger) {
              o.complete();
            } else {
              subscription.add(
                // eslint-disable-next-line rxjs/no-nested-subscribe
                obs$.subscribe({
                  complete: () => o.complete(),
                  error: () => o.complete(),
                })
              );
            }
          } catch (error) {
            this.sentryLogger.error('Queueing action failed', {
              actionName,
              error: mapErrorForSentry(error),
            });
            o.error(error);
            // eslint-disable-next-line rxjs/no-redundant-notify
            o.complete();
          }
          return () => {
            o.complete();
            unsubscribe(subscription);
          };
        }).pipe(takeUntil(cancelStream$));
        queue.next(task$);
      })
    );
  }

  public startQueue(queueId: string): void {
    this.currentOffer$.next(queueId);
  }

  public cancelOngoingPayment(): void {
    return this.currentCancelPayment$.next();
  }

  get cancelPayment(): Subject<void> {
    return this.currentCancelPayment$;
  }

  /**
   * Replays any event from the stream after this method is called.
   * Completes the stream after the first event.
   */
  toCancelEvent = <T>(obs$: Observable<T>): Observable<T> => obs$.pipe(finShare());
}
