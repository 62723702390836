<div class="ps-small-x">
  <table
    class="monthWrapper fill"
    role="grid"
    [attr.aria-multiselectable]="isDateRange ? 'true' : 'false'"
    (keydown)="onKeyDown($event)"
  >
    <caption class="title font-body-1 nordic-blue-900-text bold-type ps-medium">
      {{
        month.label
      }}
    </caption>
    <thead>
      <tr>
        @for (dayName of month?.weekDays?.weekdays; track dayName) {
          <th class="weekDay font-body-2 light-type" scope="col" id="{{ dayName }}">
            <abbr title="{{ dayName }}">{{ (month?.weekDays?.weekdaysShort)[$index] }}</abbr>
          </th>
        }
      </tr>
    </thead>
    <tbody class="calendarBlock">
      @for (week of month.weeks; track week) {
        <tr>
          @for (day of week.days; track day) {
            @if (day?.spacer !== 7 || shouldShowEmptyWeeks) {
              <td
                class="dayWrapper"
                [class.dayCell]="!day.spacer"
                [attr.colspan]="day.spacer"
                [attr.aria-selected]="day.selected"
              >
                <ng-container [ngTemplateOutlet]="dayTemplate" [ngTemplateOutletContext]="{ day }"> </ng-container>
              </td>
            }
          }
        </tr>
      }
    </tbody>
  </table>
</div>
