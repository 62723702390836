import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LoaderType } from '../../interfaces';

@Component({
  selector: 'fin-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  readonly LoaderType = LoaderType;

  @Input()
  type: LoaderType = LoaderType.EMBEDDED;
  @Input()
  title = 'loader.title';
  @Input()
  hideTitle = false;
  @Input()
  waitingFor = '';
}
