import { Component, ViewEncapsulation } from '@angular/core';

/**
 * Component that only contains the global styles (ViewEncapsulation.None) that are used to hide the chat in certain sections
 * of the site and to raise the Chat button in certain sections of the site so that it doesn't float on top of critical information
 *
 * All styles for the inside of the chat button and chat dialog are encapsulated within the chat.component and are not here.
 */
@Component({
  selector: 'fin-chat-global-visibility',
  template: '',
  styleUrls: ['chat-global-visibility.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChatGlobalVisibilityComponent {}
