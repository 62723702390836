import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';

import { Subscription } from 'rxjs';

import { unsubscribe } from '@fcom/core/utils';

import { FormInputType } from '../../interfaces';

@Component({
  selector: 'fin-form-input-text',
  templateUrl: 'form-input-text.component.html',
  styleUrls: ['./form-input-text.component.scss'],
})
export class FormInputTextComponent implements OnInit, OnDestroy {
  public readonly FormInputType = FormInputType;

  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  controlName: string;

  @Input()
  translationPrefix: string;

  @Input()
  placeHolder: string = null;

  @Input()
  characterCount: number;

  @Input()
  characterLimit: number;

  @Input()
  isSensitive = false;

  @Input()
  isResizeable = false;

  @Input()
  type: FormInputType = FormInputType.INPUT;

  @Input()
  rows = 6;

  @Input()
  id: string;

  @Input()
  labelPrefix: string;

  @Input()
  labelSuffix: string;

  @Input()
  displayMargin = true;

  @Input()
  visualDisable = false;

  @Input()
  required = false;

  isRequired = false;
  idOrControlName: string;
  control: AbstractControl;

  @ViewChild('inputElement', { static: false })
  inputElement: ElementRef;

  @ViewChild('textareaElement', { static: false })
  textareaElement: ElementRef;

  private subscriptions: Subscription = new Subscription();

  get fieldRequired(): boolean {
    return this.required || this.ctrlField.errors?.required;
  }

  get ctrlField(): AbstractControl {
    return this.parentForm.get(this.controlName);
  }

  setFocus(): void {
    if (this.type === FormInputType.INPUT) {
      this.inputElement.nativeElement.focus();
    } else if (this.type === FormInputType.TEXTAREA) {
      this.textareaElement.nativeElement.focus();
    }
  }

  ngOnInit(): void {
    this.control = this.ctrlField;
    this.isRequired = this.fieldRequired;
    this.idOrControlName = this.id || this.controlName;

    this.subscriptions.add(
      this.control.statusChanges.subscribe(() => {
        if (this.control.value) {
          return;
        }
        this.isRequired = this.fieldRequired;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);
  }
}
