<ng-container *ngIf="content$ | async as content">
  <div>
    <h1 class="nordic-blue-900-text mr-small-b ms-clear-t font-heading-1">{{ content.teaserTitle }}</h1>
    <div
      *ngIf="content.teaserText"
      [innerHTML]="content.teaserText | finSafe: 'html'"
      class="ms-large-t ms-large-b"
    ></div>
  </div>

  <form
    class="service-form radio-list"
    [attr.data-testid]="formTestId$ | async"
    [formGroup]="form"
    (ngSubmit)="navigateBasedOnTheChoice()"
  >
    <div class="rounded-large white-bg border">
      <fin-form-input-radio
        *ngFor="let radioItem of content.items"
        [controlName]="RadioLevels.TOP_LEVEL"
        [parentForm]="group"
        [isSensitive]="true"
        [description]="true"
        [descriptionTitle]="radioItem.teaserTitle"
        [descriptionText]="radioItem.teaserText | finStripHtml"
        [radioKey]="$any(radioItem)"
        data-testid="radio-list-compensation-form-categories"
        #category
      >
        <div *ngIf="radioItem.contentType === 'CMCollection' && group.value.topLevel === radioItem">
          <fin-form-input-radio
            *ngFor="let radioSubItem of radioItem.items"
            [controlName]="RadioLevels.SUB_LEVEL"
            [parentForm]="group"
            [isSensitive]="true"
            [description]="true"
            [descriptionTitle]="radioSubItem.teaserTitle"
            [descriptionText]="radioSubItem.teaserText | finStripHtml"
            [radioKey]="$any(radioSubItem)"
            data-testid="radio-list-compensation-sub-categories"
          ></fin-form-input-radio>
        </div>
      </fin-form-input-radio>
    </div>

    <div class="mr-medium-y ps-small-t fill">
      <fcom-button
        class="submit-data"
        [mode]="ButtonMode.FILL"
        [type]="ButtonType.SUBMIT"
        [disabled]="!form.valid"
        [text]="'SERVICE_FORMS.CSServiceForms.wizard.continue' | finLocalization"
        data-testid="compensation-form-submit-button"
      ></fcom-button>
    </div>
  </form>
</ng-container>
