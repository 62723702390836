import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

import { RadioButtonTheme } from '../enums';
import { RadioItemGroupOption } from '../interfaces';
@Component({
  selector: 'fcom-radio-item-group',
  templateUrl: './radio-item-group.component.html',
  styleUrls: ['./radio-item-group.component.scss'],
})
export class RadioItemGroupComponent implements OnInit {
  readonly RadioButtonTheme = RadioButtonTheme;

  @Input() theme = RadioButtonTheme.ROUND;

  @Input({ required: true }) parentForm: UntypedFormGroup;

  @Input({ required: true }) controlName: string;

  @Input({ required: true }) options: RadioItemGroupOption[];

  @Input() name?: string;

  @Input() isRequired = false;

  /**
   * This input is needed if you want to use title, helper or error text
   */
  @Input() translationPrefix: string;

  /**
   * translationPrefix is needed if you want to use this text
   */
  @Input() helperText = false;

  /**
   * translationPrefix is needed if you want to use this text
   */
  @Input() titleText = true;

  /**
   * translationPrefix is needed if you want to use this text
   */
  @Input() errorText = true;

  /**
   * the field input hidden in analytics such as Hotjar
   */
  @Input() isSensitive = false;

  /**
   * The round theme is by default vertical, but it can be forced to horizontal
   */
  @Input() isHorizontal = false;

  control: AbstractControl;

  ngOnInit(): void {
    this.control = this.parentForm.get(this.controlName);
  }
}
