import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { isPresent } from '../utils/utils';
import { ConfigService } from '../services/config/config.service';

const containsUrl = (urlsToCheck: string[], url: string): boolean =>
  urlsToCheck && isPresent(urlsToCheck.find((urlToCheck: string) => url.indexOf(urlToCheck) >= 0));

@Injectable({
  providedIn: 'root',
})
export class AwsApiGwInterceptor implements HttpInterceptor {
  constructor(private configService: ConfigService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerName = 'x-api-key';
    if (!containsUrl(this.configService.sendApiGwKeyForUrls, req.url) || req.headers.get(headerName)) {
      return next.handle(req);
    }

    const token: string = this.configService.cfg.loyaltyServices.legacy.apiProfileApiKey;
    const request: HttpRequest<any> = isPresent(token)
      ? req.clone({
          headers: req.headers.set(headerName, token),
          withCredentials: req.withCredentials !== false,
        })
      : req;
    return next.handle(request);
  }
}
