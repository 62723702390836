import { AfterViewInit, Component, ElementRef, Inject, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { RootPaths } from '@fcom/core';
import { LanguageService } from '@fcom/ui-translate';
import { OndMarketingRoutesPath } from '@fcom/ond-marketing-landing-core';

const SMARTVEL_WIDGET_DESTINATION_FIELD = 'smt-combobox-labeledby-destination';
const SMARTVEL_WIDGET_ORIGIN_FIELD = 'smt-combobox-labeledby-origin';

@Component({
  selector: 'fin-travel-restrictions',
  templateUrl: './travel-restrictions.component.html',
  styleUrls: ['./travel-restrictions.component.scss'],
})
export class TravelRestrictionsComponent implements AfterViewInit {
  readonly smartvelSource = 'https://cdn.smartvel.com/scripts/gcovwidget/boot.min.js';
  readonly smartvelApiKey = '76556e09-358b-452c-bb0d-c639c16c9fc3';

  @ViewChild('smartvelWidget', { static: false }) smartvelWidget;

  origin$: Observable<string | never>;
  destination$: Observable<string | never>;
  oneway$: Observable<boolean>;
  flightsPageRedirectionUrl$: Observable<string>;

  private destinationLocationCodeSubject: Subject<string> = new Subject<string>();
  private originLocationCodeSubject: Subject<string> = new Subject<string>();

  constructor(
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private ref: ElementRef,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platform: object
  ) {
    this.origin$ = this.parseLocationParamFromRoute('originLocationCode');
    this.destination$ = this.parseLocationParamFromRoute('destinationLocationCode');
    this.oneway$ = this.route.queryParamMap.pipe(map((params: ParamMap) => params.get('oneway') === 'true'));
    this.flightsPageRedirectionUrl$ = combineLatest([
      this.originLocationCodeSubject,
      this.destinationLocationCodeSubject,
    ]).pipe(
      map(([originLocationCode, destinationLocationCode]) => {
        return originLocationCode && destinationLocationCode
          ? this.createRedirectionUrl(originLocationCode, destinationLocationCode)
          : null;
      })
    );
  }

  ngAfterViewInit(): void {
    // Wait until elements are in place before loading the scripts
    this.loadScript(this.smartvelSource);
  }

  onWidgetChange(record: MutationRecord): void {
    if (record.attributeName == 'data-value') {
      const id = (record.target as Element)?.id;
      if (id === SMARTVEL_WIDGET_DESTINATION_FIELD) {
        this.destinationLocationCodeSubject.next(
          this.parseLocCodeFromSmartvelWidget(SMARTVEL_WIDGET_DESTINATION_FIELD)
        );
      }
      if (id === SMARTVEL_WIDGET_ORIGIN_FIELD) {
        this.originLocationCodeSubject.next(this.parseLocCodeFromSmartvelWidget(SMARTVEL_WIDGET_ORIGIN_FIELD));
      }
    }
  }

  private parseLocationParamFromRoute(param: string): Observable<string> {
    return this.route.queryParamMap.pipe(
      map((params: ParamMap) => params.get(param)),
      filter((iataCode) => !!iataCode && iataCode.length === 3)
    );
  }

  private createRedirectionUrl(originLocationCode: string, destinationLocationCode: string): string {
    return `${this.languageService.langValue}/${RootPaths.OND_MARKETING_LANDING_ROOT}/${OndMarketingRoutesPath.CITY_TO_CITY}/${originLocationCode}/${destinationLocationCode}`;
  }

  private parseLocCodeFromSmartvelWidget(selector: string): string | null {
    const location = this.smartvelWidget.nativeElement.querySelector(`#${selector}`)?.value;
    // Value is like 'Berlin, Germany (BER)' or 'New York, United States of America (USA) (JFK)' or 'Everywhere' when defined
    if (!location || location === 'Everywhere') {
      return null;
    }
    return [...location.matchAll(/\(([A-Z]+)+\)/g)].pop()[1];
  }

  private loadScript(src: string): void {
    if (isPlatformServer(this.platform)) {
      return;
    }

    const script: HTMLScriptElement = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    this.renderer.appendChild(this.ref.nativeElement, script);
  }
}
