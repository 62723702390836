<a
  [href]="target()"
  [attr.target]="targetBlank() ? '_blank' : null"
  class="flex flex--middle flex--space-between pr-xxsmall-y link-hover no-underline"
>
  <div class="left">
    <span class="font-body-1 bold-type nordic-blue-900-text">{{ content().teaserTitle }}</span>
  </div>
  <div class="right flex--inline">
    <fin-date class="inline-block" [date]="content().externallyDisplayedDate"></fin-date>
    <fcom-icon
      role="presentation"
      class="nordic-blue-900-text icon-medium ms-xsmall-l"
      [name]="SvgLibraryIcon.ARROW_RIGHT"
    ></fcom-icon>
  </div>
</a>
