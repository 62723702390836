import { Component, HostBinding, Input, type OnChanges, type OnInit } from '@angular/core';

import { IconLibrary } from '@finnairoyj/fcom-ui-styles/enums';

import { icons } from '@finnairoyj/fcom-ui-styles';

import { toKebabCase } from '../../../utils/string.utils';
import { type IconName } from '../icons.interfaces';

@Component({
  selector: 'fcom-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnChanges, OnInit {
  @Input()
  category: IconLibrary = IconLibrary.SVG_LIBRARY;

  @Input()
  dataTestId: string;

  @Input()
  name: IconName = null;

  // when this is set to true, make sure the translations are available in cms
  @Input()
  informative = false;

  private errorThrown = false;
  private icons: typeof icons = icons;

  @HostBinding('attr.data-testid')
  attrDataTestId: string;

  /** @default category-name-icon-name */
  get dataTestIdValue(): string {
    return this.dataTestId || toKebabCase(`${this.category}-${this.name ?? 'unknown'}`);
  }

  get iconRef(): string {
    return `${this.category}/${this.name}`;
  }

  get svgPath(): string {
    if (this.icons?.[this.category]?.[this.name]) {
      return this.icons[this.category][this.name];
    }

    this.throwSingleError(`Invalid Icon: ${String(this.category)}/${this.name}`);
    return '';
  }

  ngOnChanges(): void {
    this.attrDataTestId = this.dataTestIdValue;
  }

  ngOnInit(): void {
    this.attrDataTestId = this.dataTestIdValue;
  }

  // Throw an error in a timeout to stop it crashing other components but still report to sentry.
  throwSingleError(err): void {
    if (this.errorThrown) {
      return;
    }

    this.errorThrown = true;

    setTimeout(() => {
      throw new Error(err);
    }, 0);
  }
}
