export enum BoundReaccommodationStatus {
  NEW = 'new',
  CANCELLED = 'cancelled',
  REBOOKED = 'rebooked',
  DEFAULT = 'default',
}

export enum BoundReaccommodationType {
  INBOUND = 'return',
  OUTBOUND = 'departure',
  COMPLEX = 'complex',
}
