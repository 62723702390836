<fin-booking-widget-selector
  class="flex flex-1"
  [label]="'aurinkomatkat.duration' | finLocalization"
  [disabled]="disabled"
  [position]="'left'"
  (click)="openModal()"
  data-testid="am-duration-selector"
>
  <div class="flex flex--middle">
    <span *ngIf="!selectedDuration">{{ 'aurinkomatkat.selectDuration' | finLocalization }}</span>
    <span *ngIf="selectedDuration">{{ selectedDuration.label }}</span>
  </div>
</fin-booking-widget-selector>

<fcom-modal
  [(open)]="modalOpen"
  [title]="'aurinkomatkat.selectDuration' | finLocalization"
  [fitToContent]="true"
  data-testid="am-duration-selector-modal"
>
  <ul class="flex flex--column pr-large-x nordic-blue-900-text" tabindex="-1">
    <li
      class="flex flex--column"
      *ngFor="let duration of durationOptions; let last = last"
      [ngClass]="{ 'border-bottom': !last }"
    >
      <button
        [attr.data-testid]="'duration-option-' + duration.code"
        class="flex flex--space-between fill ps-medium-y"
        role="option"
        (click)="onDurationSelect(duration)"
      >
        <span class="font-body-1">{{ duration.label }}</span>
        <span *ngIf="selectedDuration?.code === duration.code" class="inline-block ms-xsmall-r">
          <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.CHECKMARK"></fcom-icon>
        </span>
      </button>
    </li>
  </ul>
</fcom-modal>
