import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'finToLowerCase',
})
export class FinLowerCasePipe implements PipeTransform {
  transform(input: string, split: string = undefined): string {
    if (!input) {
      return input;
    }
    const splitted: string[] = input.split(split);
    return `${splitted[0].toLowerCase()}${split ? split : ''}${splitted.slice(1).join('')}`;
  }
}
