import { Component, input, InputSignal } from '@angular/core';

import { CmsQueryList, CmsTaxonomy, CmsTaxonomyGeneral } from '@fcom/core-api';
import { ButtonTheme } from '@fcom/ui-components';

import { getFontLevel, getHeaderLevel } from '../../services/cms-utils/cms-utils';

@Component({
  selector: 'cms-cmrelated',
  templateUrl: './cms-cmrelated.component.html',
  styleUrl: './cms-cmrelated.component.scss',
})
export class CmsCmrelatedComponent {
  readonly ButtonTheme = ButtonTheme;
  readonly CmsTaxonomyGeneral = CmsTaxonomyGeneral;
  readonly DEFAULT_HEADER_LEVEL = 3;

  content: InputSignal<CmsQueryList> = input<CmsQueryList>();

  public headerLevel(tags: CmsTaxonomy[]): number {
    return getHeaderLevel(tags);
  }

  public headerFontLevel(tags: CmsTaxonomy[]): string {
    return getFontLevel(tags);
  }
}
