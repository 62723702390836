import { OnDestroy, Pipe, PipeTransform } from '@angular/core';

import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

/**
 * Performance optimization for *ngFor
 * Converts an Observable<Array<any>> to Array<Observable<any>>.
 * <p>
 * Each observable element of the array is optimized to emit signals
 * only when the value changes.
 * <p>
 * <b>NOTE:</b> The values are compared by reference, not deeply.
 * <p>
 * This allows for rendering optimization since only the necessary
 * parts are re-rendered.
 *
 * @example
 * <pre>
 *  &lt;li *ngFor="let obs of (myObservableArray | finObsToArray)">
 *    &lt;!-- obs => Observable<any> -->
 *  &lt;/li>
 * </pre>
 */
@Pipe({
  name: 'finObsToArray',
  pure: false,
})
export class ObservableToArrayPipe implements OnDestroy, PipeTransform {
  private arr: Array<BehaviorSubject<any>> = [];
  private val: Array<Observable<any>> = [];
  private s: Subscription;
  private cachedInput: any;

  transform<T>(input$: Observable<Array<T>>): Array<Observable<T>> {
    if (!this.s || input$ !== this.cachedInput) {
      this.s = this.createSubscription(input$);
      this.cachedInput = input$;
    }
    return this.val;
  }

  ngOnDestroy(): void {
    if (this.s) {
      this.s.unsubscribe();
    }
    if (this.arr) {
      this.arr.forEach((e) => e.complete());
    }
    this.arr = undefined;
    this.val = undefined;
    this.s = undefined;
  }

  private createSubscription(input$: Observable<Array<any>>): Subscription {
    if (this.s) {
      this.s.unsubscribe();
    }
    let shouldThrow = false;
    const sub = input$.subscribe((d: Array<any>) => {
      if (!Array.isArray(d)) {
        shouldThrow = true;
      }
      if (d.length < this.arr.length) {
        for (let i = this.arr.length - 1; i >= d.length; i--) {
          const obs$ = this.arr[i];
          delete this.arr[i];
          delete this.val[i];
          this.arr.length = this.arr.length - 1;
          this.val.length = this.val.length - 1;
          obs$.complete();
        }
      }
      for (let i = 0; i < d.length; i++) {
        if (!this.arr[i]) {
          this.arr[i] = new BehaviorSubject(d[i]);
          this.val[i] = this.arr[i].pipe(distinctUntilChanged());
        } else {
          this.arr[i].next(d[i]);
        }
      }
    });
    if (shouldThrow) {
      throw new Error('Input should be an observable of array');
    }
    return sub;
  }
}
