import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiTranslateModule } from '@fcom/ui-translate';

import { IconComponent } from './icon/icon.component';
import { GenericIconComponent } from './generic-icon/generic-icon.component';
import { IconsConfig } from './icons.config';

@NgModule({
  declarations: [GenericIconComponent, IconComponent],
  imports: [CommonModule, UiTranslateModule],
  exports: [GenericIconComponent, IconComponent],
})
export class IconsModule {
  static forRoot(config?: IconsConfig): ModuleWithProviders<IconsModule> {
    return {
      ngModule: IconsModule,
      providers: [{ provide: IconsConfig, useValue: config }],
    };
  }
}
