import { Pipe, PipeTransform } from '@angular/core';

const TAGS = /<[^>]*>/g;

@Pipe({
  name: 'finStripHtml',
})
export class StripHtmlPipe implements PipeTransform {
  transform(input: string, replaceWith = ''): string {
    if (typeof input !== 'string') {
      return input;
    }
    return input.replace(TAGS, replaceWith);
  }
}
