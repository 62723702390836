<ng-container *ngIf="openWithParams$ | async">
  <fcom-datepicker
    *ngIf="'datepicker' | finLocalization as uiLabels"
    [uiLabels]="uiLabels"
    [dateLabels]="'date' | finLocalization"
    [titleAreaConfiguration]="{
      titleAreaStyle: 'ps-large-x ps-xsmall-y',
      title: datePickerTitleLabel$ | async | finLocalization,
      titleStyle: 'font-heading-4',
      subTitle: showSubtitle ? (subTitle$ | async) : undefined,
    }"
    [isDateRange]="(params$ | async)?.tripType === TripType.RETURN"
    [selectableDatesRange]="calendarRange"
    [selectedDates]="[(travelDates$ | async)?.departureDate, (travelDates$ | async)?.returnDate]"
    [doneBtnHidden]="true"
    [showTags]="false"
    [headerTemplate]="
      (fullYearPrices$ | async)?.calendar
        ? (fullYearPrices$ | async)?.histogram?.length > 0
          ? calendarHeader
          : undefined
        : calendarHeaderLoader
    "
    [dayTemplate]="dayTemplate"
    [scrollToMonthIndex$]="scrollToHistogramMonth$"
    [showAddReturn]="(params$ | async)?.tripType === TripType.ONEWAY && showAddReturn"
    (selectedDatesChange)="updateCalendarDates($event)"
    (close)="closeModal()"
    (monthChange)="setHistogramMonth($event?.monthArrayIndex)"
    (addReturn)="addReturn()"
  ></fcom-datepicker>

  <!-- custom modal footer -->
  <div
    class="df-tablet-up flex--middle ps-large-x grey-50-bg font-body-1 ps-small"
    [ngClass]="{
      'price-calendar-sticky-footer': datesSelected$ | async,
    }"
    data-testid="price-calendar-footer"
  >
    <span class="flex flex--align-start flex--space-between fill price-calendar-footer-container">
      <ng-container>
        <span data-testid="date-modal-price">
          <span
            >{{
              ((params$ | async)?.tripType === TripType.ONEWAY
                ? 'bookingWidget.priceCalendar.oneWayPerAdult'
                : 'bookingWidget.priceCalendar.roundTripPerAdult'
              ) | finLocalization
            }}
          </span>
          <span class="bold-type">{{
            pricePerAdult$ | async | finPrice: { stripZeroCents: true, useSymbol: true } | finEmpty: '&ndash;'
          }}</span>
        </span>
      </ng-container>
    </span>
    <div class="done-button align-center">
      <fcom-button
        [text]="'bookingSearch.search' | finLocalization"
        [disabled]="ctaDisabled$ | async"
        (clickBtn)="emitCTAClicked()"
        data-testid="price-calendar-cta-button"
      ></fcom-button>
    </div>
  </div>
</ng-container>

<ng-template #calendarHeader>
  <fin-histogram
    [barData$]="calendarPrices$ | finMap: 'histogram'"
    [selected$]="selectedHistogramMonth$"
    [isActive$]="(params$ | async) !== undefined | finToObservable"
    (barSelected)="handleHistogramClick($event)"
  ></fin-histogram>
</ng-template>
<ng-template #calendarHeaderLoader>
  <div class="flex flex--middle flex--center nordic-blue-50-bg border-bottom border-bottom histogram-loader">
    <fcom-loader></fcom-loader>
  </div>
</ng-template>

<ng-template #dayTemplate let-dayValue="dayValue" let-dayString="dayString">
  <div
    class="calendar-day"
    *finLet="(calendarPrices$ | async)?.calendar?.[dayValue.id] as price"
    [attr.data-testid]="'calendar-day-' + dayValue.id"
  >
    <div class="ps-xxsmall-t">
      {{ dayString }}
    </div>
    @if ((calendarPrices$ | async)?.calendar) {
      <div
        class="calendar-day-price font-body-2 regular-type"
        [ngClass]="{
          'heather-700-text bold-type': price?.isLowestPrice,
        }"
        [attr.data-testid]="'calendar-price-' + dayValue.id"
      >
        <ng-container
          *ngTemplateOutlet="
            priceTemplate;
            context: {
              amount:
                dayValue.id >= (travelDates$ | async)?.departureDate?.id &&
                dayValue.id < (travelDates$ | async)?.returnDate?.id &&
                (params$ | async)?.tripType === TripType.RETURN
                  ? undefined
                  : price?.amount,
            }
          "
        ></ng-container>
      </div>
    } @else {
      <div class="flex flex--center ps-xxsmall">
        <div class="grey-bar"></div>
      </div>
    }
    <ng-template #priceTemplate let-amount="amount">
      {{ amount | finPrice: { abbreviate: true, useCustomFormat: true } | finEmpty: '&nbsp;' }}
    </ng-template>

    <div
      *ngIf="price"
      class="day-border fill absolute"
      [class.birch-300-bg]="!price?.noFlight"
      [class.grey-400-bg]="price?.noFlight"
      [attr.data-testid]="'calendar-price-border-' + dayValue.id"
    ></div>
  </div>
</ng-template>
