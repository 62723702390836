import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  NEVER,
  Observable,
  of,
  startWith,
  switchMap,
  take,
} from 'rxjs';

import { CorporateUser } from '@fcom/common/interfaces/corporate/corporate.interface';
import { FinnairCart, FinnairOrder, FinnairPassengerCode, FinnairPassengerItem } from '@fcom/dapi/api/models';
import { finShare } from '@fcom/rx';
import {
  EMAIL_PATTERN,
  isNotEmpty,
  isTruthy,
  LocalDate,
  NAME_PATTERN,
  rangeBetween,
  rangeFrom,
} from '@fcom/core/utils';
import { cartData as selectCartData, paxDetails as selectPaxDetails } from '@fcom/booking/store/selectors';
import { BookingAppState, PaxDetailsState } from '@fcom/common/interfaces/booking';
import { asPaxTypeKey, safePhonePrefix } from '@fcom/dapi/utils';
import { TypedFormGroup } from '@fcom/service-forms/interfaces/forms.interface';
import { PhonePrefixService } from '@fcom/common/services/phone-prefix/phone-prefix.service';
import { PaxDetailsFormData } from '@fcom/dapi/interfaces';
import { loginStatus, profileOrUndefinedIfNotLoggedIn } from '@fcom/core/selectors/login.selector';
import { NativeBridgeService } from '@fcom/core/services';
import { LoginStatus, ParsedPhone, Profile, ProfileTier } from '@fcom/core-api/login';
import { AppState } from '@fcom/core/interfaces';
import { LanguageService } from '@fcom/ui-translate';
import { TranslatedCountryCode } from '@fcom/common/services';

import { formatToDateOfBirth } from '../utils/date-utils';
import {
  corporateSalutationToGenderOption,
  finnairGenderToGenderOption,
  loginGenderToGenderOption,
  numberOfDaysInMonth,
  splitPhoneNumber,
} from '../utils/common-booking.utils';
import { PaxValidationService } from './pax-validation.service';
import { orderData } from '../store';

export const PaxFormFields = {
  gender: 'gender',
  firstName: 'firstName',
  lastName: 'lastName',
  type: 'type',
  email: 'email',
  phone: 'phone',
  phones: 'phones',
  phoneNumber: 'phoneNumber',
  phonePrefix: 'phonePrefix',
  birthDate: 'birthDate',
  yearOfBirth: 'yearOfBirth',
  monthOfBirth: 'monthOfBirth',
  dateOfBirth: 'dateOfBirth',
  frequentFlyerCard: 'frequentFlyerCard',
  frequentFlyerCards: 'frequentFlyerCards',
  companyCode: 'companyCode',
  cardNumber: 'cardNumber',
  joinFinnairPlus: 'joinFinnairPlus',
  id: 'id',
  travelerId: 'travelerId',
  number: 'number',
  travelling: 'travelling',
  isPrimaryPax: 'isPrimaryPax',
};

export interface PassengerDetails {
  group: TypedFormGroup<UntypedFormGroup | UntypedFormControl>;
  availableDays$?: Observable<number[]>;
  years?: number[];
  typeLocalizationKey?: string;
}

export enum PrefillSource {
  CART = 'CART',
  PROFILE = 'PROFILE',
  SESSION = 'SESSION',
  ORDER = 'ORDER',
  CORPORATE = 'CORPORATE',
}

const setValueIfEmpty = (control: UntypedFormControl, value: string | boolean, overwrite: boolean): void => {
  if (control && (!control?.value || overwrite) && value) {
    control.setValue(value);
    control.markAsTouched();
  }
};

const setGroupValueIfEmpty = <T>(group: TypedFormGroup<UntypedFormControl>, values: T, overwrite: boolean): void => {
  if (!group || !values) {
    return;
  }

  Object.keys(group.controls).forEach((controlName) => {
    const control: UntypedFormControl = group.get(controlName);

    setValueIfEmpty(control, values[controlName], overwrite);
  });
};

@Injectable()
export class PassengerFormService {
  cartData$: Observable<FinnairCart>;
  sessionData$: Observable<PaxDetailsState>;
  profile$: Observable<Profile>;
  orderData$: Observable<FinnairOrder>;
  countryCodes$: Observable<TranslatedCountryCode[]>;

  constructor(
    private store$: Store<BookingAppState>,
    private store: Store<AppState>,
    private fb: UntypedFormBuilder,
    private paxValidationService: PaxValidationService,
    private phonePrefixService: PhonePrefixService,
    private nativeBridgeService: NativeBridgeService,
    private languageService: LanguageService
  ) {
    this.cartData$ = this.store$.pipe(selectCartData());
    this.orderData$ = this.store.pipe(orderData());
    this.sessionData$ = this.store$.pipe(selectPaxDetails());
    this.countryCodes$ = this.languageService.translate('countryCodes');

    this.profile$ = this.store$.pipe(
      loginStatus(),
      switchMap((status) => {
        if (status === LoginStatus.INITIAL && this.nativeBridgeService.isInsideNativeWebView) {
          return of(undefined);
        }

        return this.store$.pipe(profileOrUndefinedIfNotLoggedIn());
      })
    );
  }

  /**
   * Search on the cart or order for a passenger with the ID passed to the function and create an empty
   * FormGroup for this passenger.
   */
  createPassengerFormGroup(
    paxId: string,
    isOrder: boolean = false,
    disabledFields: Array<keyof typeof PaxFormFields> = []
  ): Observable<PassengerDetails> {
    const data$ = (isOrder ? this.orderData$ : this.cartData$) as Observable<FinnairCart | FinnairOrder>;

    return data$.pipe(
      take(1),
      map((data) => {
        const { id, index } = this.getLocalPaxIdAndIndex(paxId, data.passengers);
        const passenger = data.passengers.find((pax) => pax.id === paxId);
        const controlData = data.controlData;
        const isPrimaryPax = data.passengers.findIndex((pax) => pax.id === paxId) === 0;

        const formGroup = this.fb.group({
          [PaxFormFields.type]: passenger.passengerTypeCode,
          [PaxFormFields.travelerId]: passenger.id,
          [PaxFormFields.gender]: ['', Validators.required],
          [PaxFormFields.firstName]: [
            {
              value: '',
              disabled: passenger.firstNameInputDisabled,
            },
            Validators.compose([
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(20),
              Validators.pattern(controlData?.nameRegex || NAME_PATTERN),
            ]),
          ],
          [PaxFormFields.lastName]: [
            {
              value: '',
              disabled: passenger.lastNameInputDisabled,
            },
            Validators.compose([
              Validators.required,
              Validators.minLength(2),
              Validators.maxLength(30),
              Validators.pattern(controlData?.nameRegex || NAME_PATTERN),
            ]),
          ],
          isPrimaryPax,
          id,
          number: index,
        });

        if (passenger.birthdayRequired) {
          formGroup.addControl(PaxFormFields.birthDate, this.createBirthdayGroup(passenger));
        }

        if (passenger.passengerTypeCode === FinnairPassengerCode.INF) {
          formGroup.addControl(PaxFormFields.travelling, this.fb.control('', Validators.required));
        } else {
          const emailValidators = [Validators.pattern(controlData?.emailRegex || EMAIL_PATTERN)];

          if (isPrimaryPax) {
            emailValidators.push(Validators.required);
          }

          formGroup.addControl(PaxFormFields.email, this.fb.control('', emailValidators));
          formGroup.addControl(
            PaxFormFields.phone,
            this.fb.group({
              [PaxFormFields.phonePrefix]: '',
              [PaxFormFields.phoneNumber]: '',
            })
          );
          formGroup.addControl(
            PaxFormFields.frequentFlyerCard,
            this.fb.group({
              [PaxFormFields.companyCode]: '',
              [PaxFormFields.cardNumber]: '',
            })
          );
          formGroup.addControl(PaxFormFields.joinFinnairPlus, this.fb.control(''));
        }

        disabledFields.forEach((name: keyof typeof PaxFormFields) => {
          formGroup.get(name)?.disable();
        });

        return {
          group: formGroup as TypedFormGroup<UntypedFormGroup | UntypedFormControl>,
          years: this.getPassengerYearLimits(passenger),
          typeLocalizationKey: asPaxTypeKey(passenger.passengerTypeCode).toLowerCase(),
          availableDays$: this.getAvailableDays(formGroup),
        };
      })
    );
  }

  /**
   * Pass in a single passenger FormGroup and a list of sources you want to prefill.
   *
   * The sources are prioritised so that if sources[0] fills in a value, sources[1] will skip
   * it as it is lower priority.
   */
  prefillData(
    passenger: PassengerDetails,
    sources: PrefillSource[],
    corporateUser?: CorporateUser
  ): Observable<PassengerDetails> {
    const prefillMap = {
      [PrefillSource.ORDER]: this.getPrefillFromOrder,
      [PrefillSource.CART]: this.getPrefillFromCart,
      [PrefillSource.PROFILE]: this.getPrefillFromProfile,
      [PrefillSource.SESSION]: this.getPrefillFromSession,
      [PrefillSource.CORPORATE]: () => this.getPrefillFromCorporate(corporateUser),
    };
    // Corporate prefill should overwrite any existing values
    const overwrite = !!corporateUser;

    return combineLatest(
      sources.map((source): Observable<Partial<PaxDetailsFormData>> => {
        // .apply is used here because we lose scope when calling the prefill methods
        return prefillMap[source].apply(this, [
          passenger.group.get(PaxFormFields.travelerId).value,
          passenger.group.get(PaxFormFields.id).value,
        ]);
      })
    ).pipe(
      take(1),
      map((prefillFromSource) => {
        prefillFromSource.forEach((data: Partial<PaxDetailsFormData>) => {
          setValueIfEmpty(passenger.group.get(PaxFormFields.gender), data.gender, overwrite);
          setValueIfEmpty(passenger.group.get(PaxFormFields.firstName), data.firstName, overwrite);

          setValueIfEmpty(passenger.group.get(PaxFormFields.lastName), data.lastName, overwrite);
          setValueIfEmpty(passenger.group.get(PaxFormFields.email), data.email, overwrite);
          setValueIfEmpty(passenger.group.get(PaxFormFields.travelling), data.travelling, overwrite);
          setValueIfEmpty(passenger.group.get(PaxFormFields.joinFinnairPlus), data.joinFinnairPlus, overwrite);

          setGroupValueIfEmpty(passenger.group.get(PaxFormFields.phone), data.phone, overwrite);
          setGroupValueIfEmpty(passenger.group.get(PaxFormFields.birthDate), data.birthDate, overwrite);
          setGroupValueIfEmpty(passenger.group.get(PaxFormFields.frequentFlyerCard), data.frequentFlyerCard, overwrite);
        });

        return passenger;
      })
    );
  }

  private getPrefill(
    paxId,
    _id: string,
    cartOrOrder$: Observable<FinnairCart | FinnairOrder>,
    sessionData$: Observable<PaxDetailsState>
  ): Observable<Partial<PaxDetailsFormData>> {
    return combineLatest([cartOrOrder$, sessionData$]).pipe(
      take(1),
      map(([cartData, sessionData]) => {
        const matchedPassenger = cartData.passengers.find((pax) => pax.id === paxId);

        if (!matchedPassenger) {
          return {};
        }

        const cartPrefill: Partial<PaxDetailsFormData> = {
          firstName: matchedPassenger.firstName,
          lastName: matchedPassenger.lastName,
          gender: finnairGenderToGenderOption(matchedPassenger.gender),
          travelling: sessionData?.passengers.find((pax) => pax.travelerId === matchedPassenger.accompanyingTravelerId)
            ?.id,
          email: matchedPassenger.email,
        };

        if (matchedPassenger.phoneNumbers?.[0]) {
          const prefix = matchedPassenger.phoneNumbers[0].countryPrefix;
          const code = matchedPassenger.phoneNumbers[0].countryCode.replace('+', '');

          cartPrefill.phone = {
            phonePrefix: `${code}|${prefix}`,
            phoneNumber: matchedPassenger.phoneNumbers[0].number,
          };
        }

        if (matchedPassenger.dateOfBirth) {
          cartPrefill.birthDate = formatToDateOfBirth(matchedPassenger.dateOfBirth);
        }

        const frequentFlyerCard = matchedPassenger.frequentFlyerCards?.[0];

        if (frequentFlyerCard) {
          cartPrefill.frequentFlyerCard = {
            cardNumber: frequentFlyerCard.cardNumber,
            companyCode: frequentFlyerCard.companyCode,
          };
        }

        return cartPrefill;
      })
    );
  }

  private getPrefillFromCart(paxId: string, _id: string) {
    return this.getPrefill(paxId, _id, this.cartData$, this.sessionData$);
  }

  private getPrefillFromOrder(paxId: string, _id: string) {
    return this.getPrefill(paxId, _id, this.orderData$, of(null));
  }

  /**
   * The "session" refers to `paxDetails` in the store, which are saved into session storage
   */
  private getPrefillFromSession(_paxId: string, id: string): Observable<Partial<PaxDetailsFormData>> {
    return this.sessionData$.pipe(
      take(1),
      map((paxDetails) => {
        const matchedPax = paxDetails?.passengers.find((pax) => {
          return pax.id === id;
        });

        if (!matchedPax) {
          return {};
        }

        return matchedPax;
      })
    );
  }

  private getPrefillFromProfile(_paxId: string, id: string): Observable<Partial<PaxDetailsFormData>> {
    // We can only get the first passenger from the profile, so therefore returning empty Observable if not the first passenger
    if (id !== 'ADULT1') {
      return of({});
    }

    return this.profile$.pipe(
      switchMap((profile) => {
        return combineLatest([
          of(profile),
          this.phonePrefixService.getCountryCodeFromPhonePrefix(profile?.parsedMobilePhone?.countryCode),
        ]);
      }),
      map(([profile, countryCode]) => {
        if (!profile) {
          return {};
        }

        const mobilePhone: ParsedPhone = profile.parsedMobilePhone;
        const safeMobilePhone = {
          phoneNumber: mobilePhone?.nationalNumber ? mobilePhone.nationalNumber.toString() : null,
          regionCode: mobilePhone && isNotEmpty(mobilePhone.regionCodes) ? mobilePhone.regionCodes[0] : null,
          countryCode: mobilePhone?.countryCode ? mobilePhone.countryCode.toString() : null,
        };

        const birthDate = formatToDateOfBirth(profile.dateOfBirth);

        const getFrequentFlyerDetailsFromProfile = () => {
          return {
            companyCode: profile.tier !== ProfileTier.ACCOUNT && profile.memberNumber ? 'AY' : '',
            cardNumber: profile.memberNumber || '',
          };
        };

        return {
          email: profile.email,
          firstName: profile.firstname,
          gender: loginGenderToGenderOption(profile.gender),
          frequentFlyerCard: getFrequentFlyerDetailsFromProfile(),
          joinFinnairPlus: false,
          lastName: profile.lastname,
          phone: {
            phoneNumber: safeMobilePhone.phoneNumber,
            phonePrefix: safePhonePrefix(countryCode, safeMobilePhone.countryCode),
          },
          birthDate: birthDate || { yearOfBirth: '', monthOfBirth: '', dateOfBirth: '' },
        };
      })
    );
  }

  private getPrefillFromCorporate(user: CorporateUser): Observable<Partial<PaxDetailsFormData>> {
    return this.countryCodes$.pipe(
      take(1),
      map((countryCodes) => {
        const phone = splitPhoneNumber(user.mobile || user.workPhone, countryCodes);
        return {
          email: user.email,
          firstName: user.firstname,
          gender: corporateSalutationToGenderOption(user.title),
          frequentFlyerCard: {
            companyCode: user.finnairPlus ? 'AY' : '',
            cardNumber: user.finnairPlus,
          },
          joinFinnairPlus: false,
          lastName: user.lastname,
          phone: {
            phonePrefix: phone[0],
            phoneNumber: phone[1],
          },
        };
      })
    );
  }

  private createBirthdayGroup(passenger: FinnairPassengerItem): UntypedFormGroup {
    return this.fb.group(
      {
        dateOfBirth: ['', Validators.required],
        monthOfBirth: ['', Validators.required],
        yearOfBirth: ['', Validators.required],
      },
      {
        validator: this.ageValidation(
          passenger.passengerTypeCode,
          passenger.minBirthDateInclusive,
          passenger.maxBirthDateInclusive
        ),
      }
    );
  }

  private ageValidation(
    type: FinnairPassengerCode | string,
    minBirthDateInclusiveStr: string,
    maxBirthDateInclusiveStr: string
  ): (formGroup: UntypedFormGroup) => ValidationErrors {
    const minBirthDateInclusive: LocalDate = new LocalDate(minBirthDateInclusiveStr);
    const maxBirthDateInclusive: LocalDate = new LocalDate(maxBirthDateInclusiveStr);

    return (formGroup: UntypedFormGroup) => {
      const yearOfBirth = formGroup.get(PaxFormFields.yearOfBirth).value;
      const monthOfBirth = formGroup.get(PaxFormFields.monthOfBirth).value;
      const dateOfBirth = formGroup.get(PaxFormFields.dateOfBirth).value;
      if (!yearOfBirth || !monthOfBirth || !dateOfBirth) {
        return {};
      }
      const birthDate: LocalDate = LocalDate.forDate(yearOfBirth, monthOfBirth, dateOfBirth);

      return this.paxValidationService.validatePaxAge(type, birthDate, minBirthDateInclusive, maxBirthDateInclusive);
    };
  }

  private getAvailableDays(formGroup: UntypedFormGroup): Observable<number[]> {
    if (!formGroup?.get(PaxFormFields.birthDate)) {
      return NEVER as Observable<number[]>;
    }

    const yearOfBirth = formGroup.get(PaxFormFields.birthDate).get(PaxFormFields.yearOfBirth);
    const monthOfBirth = formGroup.get(PaxFormFields.birthDate).get(PaxFormFields.monthOfBirth);

    return combineLatest({
      year: yearOfBirth.valueChanges.pipe(startWith(yearOfBirth.value), filter(isTruthy)),
      month: monthOfBirth.valueChanges.pipe(startWith(monthOfBirth.value), filter(isTruthy)),
    }).pipe(
      map(({ year, month }) => numberOfDaysInMonth(Number(year))[Number(month) - 1]),
      map((numberOfDays) => rangeFrom(1, numberOfDays)),
      startWith(rangeFrom(1, 31)),
      distinctUntilChanged(),
      finShare()
    );
  }

  private getPassengerYearLimits(passenger: FinnairPassengerItem): number[] {
    return rangeBetween(
      new Date(passenger.minBirthDateInclusive).getFullYear(),
      new Date(passenger.maxBirthDateInclusive).getFullYear()
    ).reverse();
  }

  /**
   * Creates an `id` that is in the format `ADULT1` `ADULT2` `C151`, `INFANT1`. The number at the
   * end of the pax type increases by 1 for each passenger of that type (e.g Adult, C15, Child,
   * Infant).
   *
   * Creates an `index` that is a NOT specific to a passenger type. If there is 5 passengers in the
   * cart then `5` will be returned for the last passenger.
   *
   * All indexes are human readable, starting at 1.
   */
  private getLocalPaxIdAndIndex(paxId: string, passengers: FinnairPassengerItem[]): { id: string; index: number } {
    const pax = passengers.find((passenger) => passenger.id === paxId);
    const paxType = pax?.passengerTypeCode;
    const paxFilteredByType = passengers.filter((passenger) => {
      return passenger?.passengerTypeCode === paxType;
    });
    const indexInType = paxFilteredByType.findIndex((passenger) => {
      return passenger.id === paxId;
    });
    const indexInTotal = passengers.findIndex((passenger) => {
      return passenger.id === paxId;
    });

    return { id: `${asPaxTypeKey(paxType)}${indexInType + 1}`, index: indexInTotal + 1 };
  }
}
