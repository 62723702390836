import { Location } from '@fcom/core-api';
import { LocalDate } from '@fcom/core/utils';

import { AmRoomSelection } from './paxAmount.interface';

export interface AmOrigin {
  name: string;
  city: string;
  code: string;
  countryCode: string;
}

export interface AmSearchable {
  from: string;
  to: string;
}

export enum AmHolidayType {
  AM = 'AM',
  DP = 'DP',
}

export interface AmDestination {
  city: string;
  destinationCode: string;
  countryCode: string;
  country: string;
  holidayType: AmHolidayType;
  searchable: AmSearchable[];
}

export interface AmHolidayTypeDuration {
  label: string;
  code: string;
}

export interface AmHolidayTypeItem {
  label: string;
  type: 'list' | 'days';
  items?: AmHolidayTypeDuration[];
  minDays?: number;
  maxDays?: number;
}

export interface AmDestinationsResponse {
  destinations: AmDestination[];
  holidayTypes: {
    [key: string]: AmHolidayTypeItem;
  };
}

export interface AmLocation {
  location: Location;
  holidayType: AmHolidayType;
  holidayTypeItem: AmHolidayTypeItem;
  searchable: AmSearchable[];
}

export interface AmAvailability {
  [date: string]: {
    [durationCode: string]: number;
  };
}

export interface AmContinueParams {
  originLocationCode: string;
  destinationLocationCode: string;
  departureDate: LocalDate;
  returnDate?: LocalDate;
  durationCode?: string;
  rooms: AmRoomSelection[];
  flexible: boolean;
  holidayType: AmHolidayType;
}
