<div class="flex flex--column border rounded-large white-bg ms-medium-b overflow-hidden">
  <div class="ps-medium">
    <h3 data-testid="greeting-title" data-hj-suppress>
      {{ 'loginPromotion.greeting.title.inPaxDetails' | finLocalization: { name: firstName } }}
    </h3>
    <p data-testid="greeting-description" class="pr-xxsmall-t">
      {{ 'loginPromotion.greeting.text.inPaxDetails' | finLocalization }}
    </p>
  </div>
</div>
