import { Location } from '@fcom/core-api/interfaces';

export interface FlightSearchEventPayload {
  locale: string;
  sessionId?: string;
  requestId?: string;
  multivariateTest?: {
    testId?: string;
    segment: number;
    source: string;
    variant: string;
  };
  data: {
    label: EventLabel;
    departureAirport?: string;
    arrivalAirport?: string;
    departureCity?: string;
    arrivalCity?: string;
    departureDate: string;
    returnDate?: string;
    passengers?: {
      main?: number;
      children?: number;
    };
  };
}

export enum EventLabel {
  SEARCH_CONNECTIONS = 'SearchConnections',
}

export type TrendingDestinationsResponse = {
  locations: TrendingDestinationLocation[];
};

export interface TrendingDestinationLocation extends Location {
  destinationUrl: string;
  trend: number;
  isIncludedInCampaign: boolean;
  enableBlackTeaser: boolean;
  badgeLabel: string;
}
