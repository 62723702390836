import { FareRuleCategory, FinnairBoundItem, FinnairCart, FinnairPassengerItem } from '../api/models';
import { Amount } from './dapi.interface';
import { Prices } from './price.interface';
import { CartService } from './services.interface';

export enum CartStatus {
  /**
   * The cart has not been initialized
   */
  INITIAL,

  /**
   * A request for creating the cart is in progress loading the air offers has been initiated, but not yet completed
   */
  PENDING_CREATION,

  /**
   * The cart was created successfully, but not yet loaded. The cart url should be available.
   */
  CREATION_SUCCESS,

  /**
   * The creation of the cart failed
   */
  CREATION_ERROR,

  /**
   * The cart is created, but is waiting for data to be refreshed from server
   */
  PENDING,

  /**
   * The cart failed to load from the given location
   */
  LOAD_ERROR,

  /**
   * The cart has been created and is ok
   */
  OK,
}

export interface IdAndHash {
  id: string;
  hash: string;
  orderChangeId?: string;
}

export interface FrequentFlyerCard {
  countryCode?: string;
  companyCode: string;
  cardNumber: string;
}

export interface CartOffer {
  id: string;
  itemId: string;
  fareFamilyCode?: string;
  fareFamilyBrandName?: string;
  newBounds: FinnairBoundItem[];
  prices: Prices;
}

// TODO: refactor these to real cart services
export interface CartServices {
  baggage?: { [boundId: string]: CartService[] };
  seats?: CartServiceSelections;
  lounge?: CartServiceSelections;
  meals?: CartServiceSelections;
  wifi?: CartServiceSelections;
  cover?: CartServiceSelections;
  travelComforts?: CartServiceSelections;
  pet?: CartServiceSelections;
  sport?: CartServiceSelections;
}

export interface CartPassengersRequest {
  passengers: FinnairPassengerItem[];
  joinFinnairPlus: boolean;
  corporateCode: string;
  additionalEmails: string[];
}

/**
 * Seat characteristics.
 */
export enum SeatCharacteristics {
  EXIT = 'E',
  SEAT_AND_MEAL = 'M',
  BASSINET = 'B',
  EXTRA_LEG_SPACE = 'L',
  FRONT_ROW = 'K',
  ECO_COMFORT = 'EK',
  PREFERRED = 'O',
  FRONT_OF_CABIN = 'FC',
  WINDOW = 'W',
  AISLE = 'A',
  CENTER = 'CH',
  PREMIUM = 'Q',
}

export interface TravelerSeatBinding {
  /**
   * The Amadeus-supplied id for the passenger, e.g., SKH-21-EXT
   * NOTE: you must specify either travelerId or travelerTid
   */
  travelerId: string;

  /**
   * The selected seat for a given passenger. Set to `null` to remove seat.
   */
  seatNumber: string;

  /**
   * Type of seats
   */
  variant: string;

  /**
   * The quantity of service to buy
   */
  quantity: number;
}

export interface CartTravelerSeatBinding {
  /**
   * The Amadeus-supplied id for the passenger, e.g., SKH-21-EXT
   * NOTE: you must specify either travelerId or travelerTid
   */
  travelerId: string;

  /**
   * The selected seat for a given passenger. Set to `null` to remove seat.
   */
  seatNumber: string;

  /**
   * Type of seats
   */
  seatType: string;

  characteristics: SeatCharacteristics[];

  displayPrice: Amount;
}

export interface TravelerBinding {
  /**
   * @see BaseService#category
   */
  category: string;

  /**
   * @see BaseService#subCategory
   */
  subCategory: string;

  /**
   * @see BaseService#variant
   */
  variant: string;

  /**
   * The Amadeus-supplied id for the passenger, e.g., SKH-21-EXT
   * NOTE: you must specify either travelerId or travelerTid
   */
  travelerId: string;

  /**
   * The quantity of service to buy
   */
  quantity: number;
}

export interface CartServiceSelections {
  [fragmentId: string]: TravelerBinding[] | CartTravelerSeatBinding[];
}

export enum CartPartStatus {
  OK,
  UPDATING,
  ERROR,
}
export interface UpdatingCartPart {
  [isUpdatingId: string]: CartPartStatus;
}

export interface CartFareRule {
  flightId: string;
  rule: string;
}

/**
 * Partial record of fare rules for a given category.
 * This has to be a Partial because there are as many rules as the user has clicked and fetched from the server.
 */
export type PartialCartFareRuleRecord = Partial<Record<FareRuleCategory, CartFareRule[]>>;

export interface CartFareRules {
  [travelerID: string]: {
    isLoading: FareRuleCategory[];
    hasError: FareRuleCategory[];
    ticketRules: PartialCartFareRuleRecord;
  };
}

export interface CartState {
  /**
   * The current status of the cart.
   */
  cartStatus: CartStatus;

  /**
   * When the cart has been created, this is the URL where the cart can be fetched / updated
   */
  cartUrl?: string;

  /**
   * This is the actual cart data from the `GET ${cartUrl}` response.
   */
  cartData?: FinnairCart;

  /**
   * Ancillaries updated
   */
  dirty?: boolean;

  isUpdating?: UpdatingCartPart;

  /** Are the terms accepted? */
  acceptTerms?: boolean;

  /** Full ticket rules in cryptic */
  fareRules: CartFareRules;
}

export const NO_MEAL_VARIANT = '_NOMEAL';

export const SPECIAL_MEAL_VARIANT = '_SC_MEAL';

export const NO_UPGR_MEAL_VARIANT = '_NO_UPGRML';

export const NO_WIFI_VARIANT = '_NOWIFI';

export const NO_VARIANTS = [NO_MEAL_VARIANT, NO_UPGR_MEAL_VARIANT, NO_WIFI_VARIANT];
