/* eslint-disable */

/**
 * type of travel document
 */
export enum FinnairTravelDocumentType {
  PASSPORT = 'PASSPORT',
  PASSPORTCARD = 'PASSPORTCARD',
  IDENTITYCARD = 'IDENTITYCARD',
  CREWMEMBERCERTIFICATE = 'CREWMEMBERCERTIFICATE',
  BORDERCROSSINGCARD = 'BORDERCROSSINGCARD',
  MILITARYIDENTITYCARD = 'MILITARYIDENTITYCARD',
  REENTRYPERMIT = 'REENTRYPERMIT',
  NATURALISATIONCARDUS = 'NATURALISATIONCARDUS',
  APPROVEDIDENTITYDOCUMENT = 'APPROVEDIDENTITYDOCUMENT',
  VISA = 'VISA',
  RESIDENTALIENCARD = 'RESIDENTALIENCARD',
  PERMANENTRESIDENTCARD = 'PERMANENTRESIDENTCARD',
  REDRESSNUMBER = 'REDRESSNUMBER',
  KNOWNTRAVELLERNUMBER = 'KNOWNTRAVELLERNUMBER',
  NEXUSCARD = 'NEXUSCARD',
  UNKNOWN = 'UNKNOWN'
}

