import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  Location,
  LocationGeoLocData,
  LocationMatchData,
  LocationRouteCffData,
  LocationSuggestions,
  SuggestedLocationsResponse,
} from '@fcom/core-api';

import { ConfigService } from '../config/config.service';
import { SentryLogger } from '../sentry/sentry.logger';

export enum LocationMatchOnline {
  TRUE = 'true',
  FALSE = 'false',
  ALL = 'all',
}

const endpoints = {
  ROUTE_CFF: '/routecff',
  LOCATION_MATCH: '/locationmatch',
  GEOLOCATION: '/geolocation',
  LOCATION: '/location/',
  SUGGESTIONS: '/suggestedlocations',
};

const locationMatchFields = {
  QUERY: 'query',
  LOCALE: 'locale',
  ONLINE: 'online',
  CONTINENT_CODE: 'continentCode',
};

const geolocationMatchFields = {
  LOCALE: 'locale',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
};

enum RouteCffFields {
  ORIGIN = 'originLocation',
  DESTINATION = 'destinationLocation',
  IS_AWARD = 'isAward',
  LOCALE = 'locale',
  IS_CORPORATE = 'isCorporate',
}

enum SuggestedLocationsFields {
  COUNTRY_CODE = 'countryCode',
  LOCALE = 'locale',
}

@Injectable()
export class LocationRouteCffService {
  private routeCFFUrl: string;
  private locationMatchUrl: string;
  private geolocationMatchUrl: string;
  private singleLocationUrl: string;
  private suggestedLocationsUrl: string;
  private locationCache: Record<string, Record<string, Location>>;

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private sentryLogger: SentryLogger
  ) {
    this.routeCFFUrl = configService.cfg.locationRouteCffUrl + endpoints.ROUTE_CFF;
    this.locationMatchUrl = configService.cfg.locationRouteCffUrl + endpoints.LOCATION_MATCH;
    this.geolocationMatchUrl = configService.cfg.locationRouteCffUrl + endpoints.GEOLOCATION;
    this.singleLocationUrl = configService.cfg.locationRouteCffUrl + endpoints.LOCATION;
    this.suggestedLocationsUrl = configService.cfg.locationRouteCffUrl + endpoints.SUGGESTIONS;
    this.locationCache = {};
  }

  locationMatchesFor(
    query: string,
    locale: string,
    online: LocationMatchOnline = undefined,
    bail = false,
    continentCode?: string
  ): Observable<LocationMatchData> {
    let search: HttpParams = new HttpParams()
      .set(locationMatchFields.QUERY, query)
      .set(locationMatchFields.LOCALE, locale);
    if (online) {
      search = search.set(locationMatchFields.ONLINE, online);
    }

    if (continentCode) {
      search = search.set(locationMatchFields.CONTINENT_CODE, continentCode);
    }

    return this.getLocationMatchesFor(this.locationMatchUrl, search, bail);
  }

  bestGuessFor(query: string, locale: string): Observable<Location> {
    if (this.locationCache[locale]?.[query]) {
      return of(this.locationCache[locale][query]);
    } else {
      return this.getLocationWithIataCode(this.singleLocationUrl, query, locale).pipe(
        catchError((error: unknown) => {
          this.sentryLogger.warn('Could not find exact location match for query', { query, error });
          return this.locationMatchesFor(query, locale, undefined, true).pipe(
            map((l: LocationMatchData) => l.items[0])
          );
        }),
        catchError((error: unknown) => {
          this.sentryLogger.error('Could not get best guess for query', {
            query,
            error,
          });
          return EMPTY;
        })
      );
    }
  }

  geolocMatchFor(
    latitude: string | number,
    longitude: string | number,
    locale: string
  ): Observable<LocationGeoLocData> {
    const search: HttpParams = new HttpParams()
      .set(geolocationMatchFields.LATITUDE, parseFloat(`${latitude}`).toFixed(1))
      .set(geolocationMatchFields.LONGITUDE, parseFloat(`${longitude}`).toFixed(1))
      .set(geolocationMatchFields.LOCALE, locale);

    return this.getGeolocMatchFor(this.geolocationMatchUrl, search);
  }

  getLocationMatchesFor(endpointUrl: string, params: HttpParams, bail: boolean): Observable<LocationMatchData> {
    return this.http.get<LocationMatchData>(endpointUrl, { params }).pipe(
      map((data: LocationMatchData) => {
        // Ensure that LocationMatchData items is an array
        return Array.isArray(data.items) ? data : Object.assign({}, data, { items: [] });
      }),
      catchError((err: unknown) => {
        this.sentryLogger.error('Error getting location match data', {
          error: err,
        });
        const errorResponse: LocationMatchData = {
          ok: false,
          count: 0,
          locale: '',
          items: [],
        };
        return bail ? throwError(() => err) : of(errorResponse);
      })
    );
  }

  getGeolocMatchFor(endpointUrl: string, params: HttpParams): Observable<LocationGeoLocData> {
    return this.http.get<LocationGeoLocData>(endpointUrl, { params }).pipe(
      catchError((err: unknown) => {
        this.sentryLogger.error('Error getting geoloc data', { error: err });
        const errorResponse: LocationGeoLocData = {
          ok: false,
          locale: '',
          item: undefined,
        };
        return of(errorResponse);
      })
    );
  }

  routeCffsFor(
    departureLocation: string,
    destinationLocation: string,
    isAward?: string,
    locale?: string,
    isCorporate?: string
  ): Observable<LocationRouteCffData> {
    let search: HttpParams = new HttpParams()
      .set(RouteCffFields.ORIGIN, departureLocation)
      .set(RouteCffFields.DESTINATION, destinationLocation);

    if (isAward) {
      search = search.set(RouteCffFields.IS_AWARD, isAward);
    }

    if (locale) {
      search = search.set(RouteCffFields.LOCALE, locale);
    }

    if (this.configService.cfg.enableCorporateTravelClassLimit && isCorporate) {
      search = search.set(RouteCffFields.IS_CORPORATE, isCorporate);
    }

    return this.getRouteCffsFor(this.routeCFFUrl, search);
  }

  getSuggestedLocations(countryCode: string, locale: string): Observable<LocationSuggestions> {
    const params: HttpParams = new HttpParams()
      .set(SuggestedLocationsFields.COUNTRY_CODE, countryCode)
      .set(SuggestedLocationsFields.LOCALE, locale);
    return this.http.get<SuggestedLocationsResponse>(this.suggestedLocationsUrl, { params }).pipe(
      catchError((error: unknown) => {
        this.sentryLogger.error('Error getting location suggestions', { error });
        return of({
          ok: false,
          departures: {
            count: 0,
            items: [],
          },
          destinations: {
            count: 0,
            items: [],
          },
        });
      }),
      map((suggestedLocationResponse) => {
        return {
          departures: suggestedLocationResponse.departures.items ?? [],
          destinations: suggestedLocationResponse.destinations.items ?? [],
        };
      })
    );
  }

  getLocaleCityName(query: string, targetLocale: string): Observable<string> {
    return this.http.get(this.singleLocationUrl + query).pipe(
      map(
        (exactLocationData: any): string =>
          exactLocationData[`cityName_${targetLocale}`] || exactLocationData['cityName']
      ),
      catchError((err: unknown) => {
        this.sentryLogger.warn('Error finding LocaleCityName', { query, error: err });
        return throwError(() => err);
      })
    );
  }

  private getLocationWithIataCode(endpointUrl: string, query: string, locale: string): Observable<Location> {
    return this.http.get(endpointUrl + query).pipe(
      map((exactLocationData: any): Location => {
        const title = exactLocationData[`title_${locale}`] || exactLocationData['title'];
        const country = exactLocationData[`country_${locale}`] || exactLocationData['country'];
        const cityName = exactLocationData[`cityName_${locale}`] || exactLocationData['cityName'];
        const location: Location = {
          title,
          country,
          cityName,
          countryCode: exactLocationData.countryCode,
          locationCode: exactLocationData.locationCode,
          AJBPartner: exactLocationData.ajbPartner,
          SJBPartner: exactLocationData.sjbPartner,
          airAlliance: exactLocationData.airAlliance,
          airAward: exactLocationData.airAward,
          airPartner: exactLocationData.airPartner,
          latitude: exactLocationData.latitude,
          longitude: exactLocationData.longitude,
          type: exactLocationData.type,
          picture: exactLocationData.picture,
          continentCode: exactLocationData.continentCode,
        };
        if (exactLocationData.locationCityCode) {
          location.locationCityCode = exactLocationData.locationCityCode;
        }

        if (!this.locationCache[locale]) {
          this.locationCache[locale] = {};
        }
        this.locationCache[locale][query] = location;

        return location;
      }),
      catchError((err: unknown) => {
        this.sentryLogger.warn('Error finding location', { query, error: err });
        return throwError(() => err);
      })
    );
  }

  private getRouteCffsFor(endpointUrl: string, params: HttpParams): Observable<LocationRouteCffData> {
    return this.http.get<LocationRouteCffData>(endpointUrl, { params }).pipe(
      catchError((err: unknown) => {
        this.sentryLogger.error('Error getting route cff data', { error: err });
        return of({} as LocationRouteCffData);
      })
    );
  }
}
