import { HttpParams } from '@angular/common/http';

import { TimeUnit, diff } from '@fcom/core/utils';

import { AmRoomSelection, AmContinueParams, AmHolidayType } from '../interfaces';

export const formatAmPaxGroup = (rooms: AmRoomSelection[]): string =>
  rooms.reduce((formatted, room, currentIndex) => {
    if (currentIndex > 0) {
      formatted += '_';
    }
    formatted += `${room.numberOfAdults}A`;
    if (room.childrenAges.length) {
      formatted += `-${room.childrenAges.join('-')}`;
    }
    return formatted;
  }, '');

export const amDeepLink = (params: AmContinueParams, amDeeplinkUrl: string): string => {
  const urlParams = new HttpParams()
    .set('dep', params.originLocationCode)
    .set('arr', params.destinationLocationCode)
    .set('dd', params.departureDate.toISOString().substring(0, 10))
    .set(
      'dur',
      params.holidayType === AmHolidayType.AM
        ? params.durationCode
        : `${diff(params.departureDate.toDate(), params.returnDate.toDate(), TimeUnit.day)}`
    )
    .set('g', formatAmPaxGroup(params.rooms))
    .set('flex', !!params.flexible);
  return `${amDeeplinkUrl}?${urlParams.toString()}`;
};
