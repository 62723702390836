import { PaxAmount } from '@fcom/dapi';

import { GtmPassengers } from '../interfaces';

export const getGtmPassengers = (paxAmount: PaxAmount): GtmPassengers => {
  const { adults, c15s, children, infants } = paxAmount;
  return {
    adults,
    children: children + c15s,
    infants,
  };
};
