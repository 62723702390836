import {
  Component,
  ChangeDetectorRef,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  EventEmitter,
  Output,
  OnInit,
} from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { v4 as uuid } from 'uuid';

import { isPresent } from '@fcom/core/utils/utils';

import { CarouselTheme } from '../../carousel/enums';
import { TabComponent } from '../tab/tab.component';
import { TabTheme, TabIconPosition, TabLayoutType } from './enums';
import { CarouselComponent } from '../../carousel';

/**
 * This component will display a list of tabs. You should pass through a number of `fcom-tab`
 * components in the `ng-content`.
 *
 * @example
 * <fcom-tabs [theme]="theme" [align]="'center'">
 *   <fcom-tab [title]="tabTitle + ' ' + (i + 1)" *ngFor="let item of Arr(numberOfTabs).fill(1); let i = index">
 *     {{ tabContent }} {{ i + 1 }}
 *   </fcom-tab>
 * </fcom-tabs>
 */
@Component({
  selector: 'fcom-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ height: '*' })),
      state('false', style({ height: '0px' })),
      transition('false <=> true', animate(200)),
    ]),
  ],
})
export class TabsComponent implements OnInit, AfterViewInit {
  readonly CarouselTheme = CarouselTheme;
  readonly SvgLibraryIcon = SvgLibraryIcon;
  readonly TabIconPosition = TabIconPosition;
  readonly TabLayoutType = TabLayoutType;
  readonly TabTheme = TabTheme;

  private id: string;

  @ViewChild('carousel', { static: true })
  carousel: CarouselComponent;

  @ViewChild('tabsContainer', { static: true })
  tabsContainer: ElementRef;

  @ViewChild('tabsNavi', { static: true })
  tabsNavi: ElementRef;

  /**
   * Various options for displaying compacted or full size.
   */
  @Input() theme: TabTheme = TabTheme.DEFAULT;

  /**
   * Various options of layout of tabs.
   */
  @Input() layoutType: TabLayoutType = TabLayoutType.DEFAULT;

  /**
   * How the tabs should be aligned in the container
   */
  @Input()
  align: 'left' | 'center' | 'right' = 'left';

  /**
   * Aria Label for the previous button in the carousel
   */
  @Input()
  prevButtonAriaLabel: string;

  /**
   * Aria Label for the next button in the carousel
   */
  @Input()
  nextButtonAriaLabel: string;

  @Input()
  hasGreyBackground = false;

  @Input()
  tabStyleOverrideClasses = '';

  @Input()
  bottomBorder = true;

  @Input()
  whiteSpaceNowrap = false;

  @Output()
  clickedTab = new EventEmitter<{
    index: number;
    data: unknown;
  }>();

  tabs: TabComponent[] = [];

  private _defaultActivationUsed = false;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.id = uuid();
  }

  ngAfterViewInit(): void {
    this.scrollToSelectedTab();
  }

  selectTab(tab: TabComponent): void {
    if (isPresent(tab)) {
      this.tabs = this.tabs.map((t) => {
        t.active = t.id === tab.id;
        return t;
      });

      this.changeDetectorRef.markForCheck();
      this.clickedTab.emit({
        index: this.tabs.findIndex((t) => t.active),
        data: this.tabs.find((t) => t.active).data,
      });
    }
  }

  addTab(tab: TabComponent): void {
    tab.id = `tabs-${this.id}-${this.tabs.length}`;

    this.tabs.push(tab);

    if (this.tabs.length === 1 && !tab.active && !tab.disabled) {
      this.enableDefaultActivation(true);
    } else if (this._defaultActivationUsed && tab.active) {
      this.enableDefaultActivation(false);
    }
  }

  removeTab(tab: TabComponent): void {
    this.tabs = this.tabs.filter((t) => t.id !== tab.id);
  }

  scrollToSelectedTab(): void {
    const activeTab = this.tabsNavi.nativeElement.querySelector('.tab--active');
    if (
      activeTab &&
      (activeTab.offsetLeft as number) + (activeTab.offsetWidth as number) >
        this.tabsContainer.nativeElement.offsetWidth
    ) {
      this.carousel.scrollTo(activeTab.offsetLeft);
    }
  }

  private enableDefaultActivation(enable): void {
    if (this.tabs[0]) {
      this._defaultActivationUsed = enable;
      this.tabs[0].active = enable;
    }
  }
}
