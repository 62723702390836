import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { ButtonTheme, ButtonSize } from '../../buttons/button/enums';

@Component({
  selector: 'fcom-error-block',
  templateUrl: './error-block.component.html',
})
export class ErrorBlockComponent {
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonSize = ButtonSize;

  /**
   * Name of the icon to be displayed
   *
   * @example SvgLibraryIcon.PET
   */
  @Input() icon: SvgLibraryIcon;

  /**
   * The pre-translated text to show for heading
   */
  @Input() heading: string;

  /**
   * The pre-translated text to show for sub heading
   */
  @Input() subHeading: string;

  /**
   * The pre-translated text to show for paragraph
   */
  @Input() text: string;

  /**
   * Whether to show the button
   */
  @Input() showButton = true;

  /**
   * The pre-translated text to show for the button
   */
  @Input() buttonText: string;

  /**
   * Event emitter indicating that the button has been pressed.
   */
  @Output() buttonClicked: EventEmitter<Event> = new EventEmitter();
}
