import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { FinnairItineraryItemFlight } from '@fcom/dapi/api/models';
import { LocationInfoMap } from '@fcom/dapi/interfaces';

import { BUS_AIRCRAFT_CODE } from '../../../interfaces';

@Component({
  selector: 'fin-itinerary-timeline-leg-technical-stops',
  styleUrls: ['../itinerary.scss', '../arrow-block.scss'],
  templateUrl: './leg-technical-stops.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItineraryTimelineLegTechnicalStopsComponent {
  readonly BUS_AIRCRAFT_CODE = BUS_AIRCRAFT_CODE;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  leg: FinnairItineraryItemFlight;

  @Input()
  locations: LocationInfoMap;

  @Input()
  firstLeg = false;

  @Input()
  lastLeg = false;

  @Input()
  oldArrivalDateTime: string;

  @Input()
  oldArrivalLocationCode: string;
}
