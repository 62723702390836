<div *ngIf="items$ | async as items" class="unique-selling-point fill white-bg">
  <div class="border-top">
    <div class="ups-container flex flex--space-around grid-max-width pr-medium-y center">
      <div
        *ngFor="let item of items"
        class="usp-item nordic-blue-900-text medium-type is-hidden"
        [innerHTML]="item.teaserText | finSafe: 'html'"
      ></div>
    </div>
  </div>
</div>
