import { MatDatepickerIntl } from '@angular/material/datepicker';
import { Injectable, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

import { LanguageService } from '@fcom/ui-translate';
import { unsubscribe } from '@fcom/core/utils';

@Injectable({ providedIn: 'root' })
export class DatePickerLocalizationService extends MatDatepickerIntl implements OnDestroy {
  private subscription = new Subscription();

  constructor(private languageService: LanguageService) {
    super();
    this.subscription.add(
      this.languageService
        .translate('SERVICE_FORMS.NTServiceForms.helpers.datePicker')
        .subscribe((values) => this.attachTranslations(values))
    );
  }

  attachTranslations(values: Record<string, string>): void {
    this.calendarLabel = values.calendarLabel;
    this.openCalendarLabel = values.openCalendarLabel;
    this.prevMonthLabel = values.prevMonthLabel;
    this.nextMonthLabel = values.nextMonthLabel;
    this.prevYearLabel = values.prevYearLabel;
    this.nextYearLabel = values.nextYearLabel;
    this.prevMultiYearLabel = values.prevMultiYearLabel;
    this.nextMultiYearLabel = values.nextMultiYearLabel;
    this.switchToMonthViewLabel = values.switchToMonthViewLabel;
    this.switchToMultiYearViewLabel = values.switchToMultiYearViewLabel;
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }
}
