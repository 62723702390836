import { pad } from './format';
import { isPresent } from './utils';
import { DateString } from './interfaces';

const MINUTE_IN_MILLIS = 60 * 1000;
const HOUR_IN_MILLIS = 60 * MINUTE_IN_MILLIS;
const DAY_IN_MILLIS = 24 * HOUR_IN_MILLIS;

export class TzDate {
  private _millis: number;
  private _localDate: string;
  private _localTime: string;
  private _tzString: string;
  private _weekday: number; // [0-6]

  /**
   * Examples:
   * new Date("2016-10-28T08:20:23.897Z"), offset=undefined -> "11:20"
   * "2016-10-28T08:20:23.897Z", offset=undefined -> "08:20"
   * "2016-10-28T08:20:23.897+03:00", offset=undefined -> "08:20"
   * new Date("2016-10-28T08:20:23.897Z"), offset=0 -> "08:20"
   * "2016-10-28T08:20:23.897Z", offset=0 -> "08:20"
   * "2016-10-28T08:20:23.897+03:00", offset=0 -> "05:20"
   * "2016-10-28T08:20:23.897+03:00", offset = undefined -> 08:20
   * "2016-10-28T08:20:23.897+03:00", offset = 180000 => 08:20 11:20
   * Conversions from number
   * number, offset => date from millis, format in given timezone, offset = 0 => Z
   * Conversions from date (with / without timezone)
   * Date("2016-10-28T08:20:23.897Z"), offset=-6*60*60*1000 => 02:20
   * Date("2016-10-28T11:20:23.897+03:00"), offset=-6*60*60*1000 => 02:20
   * @param millis
   * @param tzOffsetMillis
   * @returns {string}
   */
  static fromMillis(millis: number, tzOffsetMillis = 0): TzDate {
    const localDateStringInWrongTimezone = new Date(millis + tzOffsetMillis).toISOString();
    const dateStringWithoutTimezone = localDateStringInWrongTimezone.substr(0, 23);

    if (tzOffsetMillis === 0) {
      return new TzDate(`${dateStringWithoutTimezone}Z`);
    }
    const timeZoneHours = parseInt(`${tzOffsetMillis / HOUR_IN_MILLIS}`, 10);
    const timeZoneMinutes = Math.abs(tzOffsetMillis % HOUR_IN_MILLIS);
    const prefix = timeZoneHours < 0 ? '-' : '+';
    const dateStringInCorrectTimezone = `${dateStringWithoutTimezone}${prefix}${pad(Math.abs(timeZoneHours))}:${pad(
      timeZoneMinutes
    )}`;
    return new TzDate(dateStringInCorrectTimezone);
  }

  static now(): TzDate {
    return this.fromMillis(Date.now());
  }

  static of(dateString: string): TzDate {
    return isPresent(dateString) ? new TzDate(dateString) : undefined;
  }

  /**
   * Construct a TzDate based on fullYear, month (1 = January, 12 = December), and day.
   * @param fullYear
   * @param monthOneBased
   * @param day
   * @return {TzDate}
   */
  static utcMidnight(fullYear: string | number, monthOneBased: string | number, day: string | number): TzDate {
    return TzDate.of(`${fullYear}-${pad(monthOneBased)}-${pad(day)}T00:00:00Z`);
  }

  constructor(ds: string) {
    const dateString = String(ds);

    // eslint-disable-next-line id-blacklist
    if (Number.isNaN(Date.parse(dateString))) {
      throw new Error(`Invalid TzDate: ${dateString}`);
    }

    this._millis = Date.parse(dateString);
    this._localDate = new Date(dateString.substr(0, 10)).toISOString().substr(0, 10);
    this._localTime = dateString.substr(11, 12);
    this._tzString = dateString.substr(23);
    this._weekday = this.toLocalDate().weekday;
  }

  get localHours(): number {
    return parseInt(this._localTime.substr(0, 2), 10);
  }

  get localMinutes(): number {
    return parseInt(this._localTime.substr(3, 2), 10);
  }

  get localDay(): number {
    return parseInt(this._localDate.substr(8, 2), 10);
  }

  get localMonth(): number {
    return parseInt(this._localDate.substr(5, 2), 10);
  }

  get localYear(): number {
    return parseInt(this._localDate.substr(0, 4), 10);
  }

  get millis(): number {
    return this._millis;
  }

  /**
   * Returns weekday in range [0-6] like Date does
   * @returns {number}
   */
  get weekday(): number {
    return this._weekday;
  }

  get localDateString(): string {
    return this._localDate;
  }

  get timeAndTzString(): string {
    return `${this._localTime}${this._tzString}`;
  }

  get tzOffsetInMilliseconds(): number {
    if (this._tzString.includes('Z')) {
      return 0;
    }

    const direction = this._tzString.substring(0, 1);
    const hours = Number(this._tzString.substring(1, 3));
    const minutes = Number(this._tzString.substring(4, 6));

    if (!['+', '-'].includes(direction)) {
      return 0;
    }

    const total = (hours * 60 * 60 + minutes * 60) * 1000;

    return direction === '+' ? total : -total;
  }

  equals(other: TzDate): boolean {
    return other && other.toTzString === this.toTzString;
  }

  toTzString(): string {
    return `${this._localDate}T${this.timeAndTzString}`;
  }

  toISOString(): string {
    return new Date(this._millis).toISOString();
  }

  toString(): string {
    return this.toTzString();
  }

  toJSON(): string {
    return this.toTzString();
  }

  toDate(): Date {
    return new Date(this._millis);
  }

  toLocalDate(): LocalDate {
    return new LocalDate(this.localDateString);
  }

  isBefore(date: TzDate): boolean {
    return this._millis - date.millis < 0;
  }

  isAfter(date: TzDate): boolean {
    return this._millis - date.millis > 0;
  }

  hoursTo(date: TzDate): number {
    const hoursTo = (date.millis - this.millis) / (60 * 60 * 1000);
    return hoursTo > 0 ? Math.floor(hoursTo) : 0;
  }

  daysTo(date: TzDate): number {
    const hoursTo = this.hoursTo(date);
    return hoursTo > 0 ? Math.floor(hoursTo / 24) : 0;
  }

  addHours(amount: number): TzDate {
    return TzDate.fromMillis(this.toDate().getTime() + amount * HOUR_IN_MILLIS, this.tzOffsetInMilliseconds);
  }

  removeHours(amount: number): TzDate {
    return this.addHours(-amount);
  }
}

export enum DateField {
  DAY,
  MONTH,
}

export class LocalDate {
  static EXPECTED_LENGTH = 10;
  private readonly localDateString;

  static forDate(year: number | string, month: number | string, day: number | string): LocalDate {
    return new LocalDate(`${pad(year, 4)}-${pad(month, 2)}-${pad(day, 2)}`);
  }

  static forDateObj(date: Date): LocalDate {
    return new LocalDate(date.toISOString().substr(0, 10));
  }

  static now(): LocalDate {
    return LocalDate.forDateObj(new Date());
  }

  static fromTzDate(tzDate?: TzDate): LocalDate {
    return tzDate?.toLocalDate();
  }

  static equals(a: LocalDate, b: LocalDate): boolean {
    return (!a && !b) || (a && b && a.equals(b));
  }

  static isLocalDate(localDateString: string): boolean {
    /* eslint-disable id-blacklist */
    return !(
      !isPresent(localDateString) ||
      localDateString.length !== LocalDate.EXPECTED_LENGTH ||
      Number.isNaN(Date.parse(localDateString))
    );
    /* eslint-enable id-blacklist */
  }

  static toDDSDate(date: LocalDate): string {
    return `${date.localYear}${pad(date.localMonth)}${pad(date.localDay)}0000`;
  }

  /**
   * Month should be one-indexed. e.g. December will be 12
   */
  static getAmountOfDaysInMonth(year: number, month: number): number {
    const rolloverYear = month === 12 ? year + 1 : year;
    const rolloverMonth = month === 12 ? 1 : month;

    return LocalDate.forDate(rolloverYear, rolloverMonth + 1, 1).minusDays(1).localDay;
  }

  /**
   * Create a new local date from the ISO8601 date string
   * @param localDateString the date string, e.g. 2016-12-31 or 0012-01-04
   */
  constructor(localDateString: string) {
    const ls = String(localDateString);
    if (!LocalDate.isLocalDate(ls)) {
      throw new Error(`Invalid localDate: ${ls}`);
    }
    // Convert, e.g., 2018-02-31 using browsers parser
    this.localDateString = new Date(ls).toISOString().substr(0, 10);
  }

  plusYears(amount: number): LocalDate {
    return this.withYear(this.localYear + amount);
  }

  minusYears(amount: number): LocalDate {
    return this.plusYears(-amount);
  }

  plus(dateField: DateField, amount: number): LocalDate {
    return dateField === DateField.MONTH ? this.plusMonths(amount) : this.plusDays(amount);
  }

  minus(dateField: DateField, amount: number): LocalDate {
    return this.plus(dateField, -amount);
  }

  plusMonths(amount: number): LocalDate {
    if (!amount) {
      return this;
    }
    const daysInMonth = (year, monthOneBased) => new Date(year, monthOneBased, 0).getDate();
    const neg = amount < 0 ? -1 : 1;
    const yearsToMove = neg * Math.floor(Math.abs(amount) / 12);
    const monthsToMove = amount % 12;

    const { localYear, localMonth, localDay } = this;
    const tmpTargetMonth = monthsToMove + localMonth;
    let targetMonth;
    let targetYear;
    if (tmpTargetMonth <= 0) {
      targetMonth = 12 + tmpTargetMonth;
      targetYear = localYear + yearsToMove - 1;
    } else if (tmpTargetMonth > 12) {
      targetMonth = tmpTargetMonth % 12;
      targetYear = localYear + yearsToMove + 1;
    } else {
      targetYear = localYear + yearsToMove;
      targetMonth = localMonth + monthsToMove;
    }

    const daysInTargetMonth = daysInMonth(targetYear, targetMonth);
    const targetDay = daysInTargetMonth <= localDay ? daysInTargetMonth : localDay;

    return LocalDate.forDate(targetYear, targetMonth, targetDay);
  }

  minusMonths(amount: number): LocalDate {
    return this.plusMonths(-amount);
  }

  plusDays(amount: number): LocalDate {
    if (!amount) {
      return this;
    }
    const newUtcMillis = this.toDate().getTime() + amount * DAY_IN_MILLIS;
    return LocalDate.forDateObj(new Date(newUtcMillis));
  }

  minusDays(amount: number): LocalDate {
    return this.plusDays(-amount);
  }

  get firstDayOfMonth(): LocalDate {
    return new LocalDate(`${this.localDateString.substr(0, 8)}01`);
  }

  get lastDayOfMonth(): LocalDate {
    return new LocalDate(
      `${this.localDateString.substr(0, 8)}${LocalDate.getAmountOfDaysInMonth(this.localYear, this.localMonth)}`
    );
  }

  lte(other: LocalDate): boolean {
    return other && this.localDateString <= other.localDateString;
  }

  lt(other: LocalDate): boolean {
    return other && this.localDateString < other.localDateString;
  }

  gt(other: LocalDate): boolean {
    return other && this.localDateString > other.localDateString;
  }

  gte(other: LocalDate): boolean {
    return other && this.localDateString >= other.localDateString;
  }

  isBetween(start: LocalDate, end: LocalDate): boolean {
    return this.gte(start) && this.lte(end);
  }

  get localDay(): number {
    return parseInt(this.localDateString.substr(8, 2), 10);
  }

  get localMonth(): number {
    return parseInt(this.localDateString.substr(5, 2), 10);
  }

  get localYear(): number {
    return parseInt(this.localDateString.substr(0, 4), 10);
  }

  /**
   * Returns weekday in range [0-6] like Date does
   * @returns {number}
   */
  get weekday(): number {
    return this.toDate().getUTCDay();
  }

  equals(other: LocalDate): boolean {
    return other && other.localDateString === this.localDateString;
  }

  get id(): string {
    return this.localDateString;
  }

  toString(): DateString {
    return this.localDateString;
  }

  toJSON(): DateString {
    return this.localDateString;
  }

  toDate(): Date {
    return new Date(`${this.localDateString}T00:00:00Z`);
  }

  toTzDate(): TzDate {
    return new TzDate(`${this.localDateString}T00:00:00Z`);
  }

  toISOString(): string {
    return `${this.localDateString}T00:00:00.000Z`;
  }

  toDDSDate(): string {
    return LocalDate.toDDSDate(this);
  }

  toDottedUIString(): string {
    return `${pad(this.localDay)}.${pad(this.localMonth)}.${this.localYear}`;
  }

  toDottedString(): string {
    return `${this.localYear}.${pad(this.localMonth)}.${pad(this.localDay)}`;
  }

  private withYear(newYear: number): LocalDate {
    const dateStringWithoutYears = this.localDateString.substr(4);
    const newDateString = `${pad(newYear, 4, '0')}${dateStringWithoutYears}`;
    return new LocalDate(newDateString);
  }
}
