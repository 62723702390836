import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Meta } from '@angular/platform-browser';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { FinnairPassengerItem } from '@fcom/dapi/api/models';
import { isFunction, isPresent } from '@fcom/core/utils';

import { WindowRef } from '../../providers/window';
import { StorageService } from '../storage/storage.service';
import { ConfigService } from '../config/config.service';

const IS_NATIVE_KEY = 'X-IsNative';

export interface OrderDataForNative {
  recloc: string;
  travelers?: FinnairPassengerItem[];
  rawBooking: any;
}

type RequiredPostMessageFunc<T = string> = { postMessage: (t: T) => void };
type OptionalPostMessageFunc<T = string> = { postMessage: (t?: T) => void };
type PostMessageFunc<T> = RequiredPostMessageFunc<T> | OptionalPostMessageFunc<T>;

interface NativeIosApp {
  addBooking?: RequiredPostMessageFunc<OrderDataForNative>;
  bookingFlowInitialized?: OptionalPostMessageFunc;
  openMenu?: OptionalPostMessageFunc;
}

interface NativeAndroidApp {
  addBooking?: (str: string) => void;
  bookingFlowInitialized?: () => void;
  openMenu?: () => void;
  showMenu?: () => void;
}

@Injectable()
export class NativeBridgeService {
  private nativeiOSApp: NativeIosApp;
  private nativeAndroidApp: NativeAndroidApp;
  private _appHideStatus$: Subject<boolean> = new Subject();
  private backButtonPressed$: Subject<void> = new Subject();
  private showHeaderHamburgerMenu$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    private windowRef: WindowRef,
    private meta: Meta,
    private ngZone: NgZone,
    private configService: ConfigService,
    private storageService: StorageService,
    @Inject(PLATFORM_ID) private platform: object
  ) {
    const webkitRef = this.windowRef.nativeWindow['webkit'];
    const androidRef = this.windowRef.nativeWindow['Android'];
    if (isPresent(webkitRef) && isPresent(webkitRef.messageHandlers.isNativeFinnairIosApp)) {
      this.nativeiOSApp = webkitRef.messageHandlers;
    }
    if (isPresent(androidRef)) {
      this.nativeAndroidApp = androidRef;
    }
    windowRef.nativeWindow['nativeBridge'] = this;
  }
  init(): void {
    this.makeUiTakeFullAreaInIosWebView();
  }

  /**
   * Passes forward a new hide status
   * @param {boolean} status the next hide status
   */
  setAppHideStatus(status: boolean): void {
    this.ngZone.run(() => this._appHideStatus$.next(status));
  }

  get appHideStatus(): Observable<boolean> {
    return this._appHideStatus$;
  }

  get isInsideNativeWebView(): boolean {
    const isNativeOverride =
      !!this.configService.cfg.enableDevToolPath && this.storageService.LOCAL.get(IS_NATIVE_KEY) === 'true';
    return isPresent(this.nativeiOSApp) || isPresent(this.nativeAndroidApp) || isNativeOverride;
  }

  get isInsideNativeiOSWebView(): boolean {
    return isPresent(this.nativeiOSApp);
  }

  get isInsideNativeAndroidWebView(): boolean {
    return isPresent(this.nativeAndroidApp);
  }

  get isLocalView(): boolean {
    return !isPresent(this.nativeiOSApp) && !isPresent(this.nativeAndroidApp);
  }

  get backButtonPressed(): Observable<void> {
    return this.backButtonPressed$;
  }

  get isHamburgerMenuVisible(): Observable<boolean> {
    return this.showHeaderHamburgerMenu$;
  }

  urlForAddAJourneyInApp(recloc: string, lastName: string): string {
    return `https://app.finnair.com/addjourney/${recloc}/${lastName}?source=booking`;
  }

  openNativeMenu(): void {
    this.iOSCallIfAccessible(() => this.nativeiOSApp.openMenu)();
    if (this.isAndroidCallAccessible('showMenu')) {
      this.nativeAndroidApp.showMenu();
    }
  }

  addBooking(booking: OrderDataForNative): void {
    this.iOSCallIfAccessible(() => this.nativeiOSApp.addBooking)(booking);
    if (this.isAndroidCallAccessible('addBooking')) {
      this.nativeAndroidApp.addBooking(JSON.stringify(booking));
    }
  }

  /**
   * Note: this should be called only after all hooks have been installed and client is ready
   * to receive postMessages from the application.
   */
  clientReady(): void {
    this.iOSCallIfAccessible(() => this.nativeiOSApp.bookingFlowInitialized)();
    if (this.isAndroidCallAccessible('bookingFlowInitialized')) {
      this.nativeAndroidApp.bookingFlowInitialized();
    }
  }

  /**
   * Passes forward a notion that a mobile device's back button has been pressed
   */
  onBackPressed(): void {
    this.ngZone.run(() => this.backButtonPressed$.next());
  }

  /**
   * Passes forward a information about hamburger menu visibility
   */
  onShowHamburgerMenu(isOpen: boolean): void {
    this.ngZone.run(() => this.showHeaderHamburgerMenu$.next(isOpen));
  }

  private iOSCallIfAccessible<T>(target: () => OptionalPostMessageFunc<T>): (T?) => void;
  private iOSCallIfAccessible<T>(target: () => RequiredPostMessageFunc<T>): (T) => void;
  private iOSCallIfAccessible<T>(target: () => PostMessageFunc<T>): (T?) => void {
    if (this.isInsideNativeiOSWebView && isPresent(target())) {
      return (msg = '') => target().postMessage(msg);
    }
    return () => {};
  }

  private isAndroidCallAccessible(fnName: keyof NativeAndroidApp): boolean {
    return this.isInsideNativeAndroidWebView && isFunction(this.nativeAndroidApp[fnName]);
  }

  private makeUiTakeFullAreaInIosWebView(): void {
    // TODO: Remove this when index.template.ejs has viewport-fit always
    if (isPlatformBrowser(this.platform) && this.isInsideNativeiOSWebView) {
      this.meta.updateTag(
        {
          content: 'width=device-width, initial-scale=1, viewport-fit=cover',
        },
        'name="viewport"'
      );
    }
  }
}
