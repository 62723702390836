import { UntypedFormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { isAmadeusPNRCode } from '@fcom/core/utils';
import { GtmService } from '@fcom/common/gtm';
import { ElementActions, ElementTypes, GaContext, SelectOption } from '@fcom/common/interfaces';
import { LocationMatchData } from '@fcom/core-api/interfaces';

export const trackFormState = (formName: string, state: string, gtmService: GtmService): void => {
  let action: ElementActions = ElementActions.SUBMIT;

  // Start is a click event, others are related to form submit status
  if (state === 'start') {
    action = ElementActions.CLICK;
  }

  gtmService.trackElement(`${formName}-${state}`, GaContext.SERVICE_FORMS, ElementTypes.FORM, state, action);
};

export const disableConditionalFields = (fieldSet: string[], form: UntypedFormGroup): void => {
  fieldSet.forEach((field) => {
    const inputField = form.get(field);
    if (inputField) {
      inputField.reset();
      inputField.disable();
    }
  });
};

export const enableConditionalFields = (fieldSet: string[], form: UntypedFormGroup): void => {
  fieldSet.forEach((field) => {
    const inputField = form.get(field);
    if (inputField) {
      inputField.enable();
    }
  });
};

export const isPnrCode = (value: string): boolean => {
  return isAmadeusPNRCode(value) && !value.includes('0') && !value.includes('1');
};

export const createQueryString = <T>(query: T): string => {
  return Object.keys(query)
    .filter((key) => {
      return query[key];
    })
    .map((key) => {
      return `${key}=${query[key]}`;
    })
    .reduce((rest, curr) => {
      return `${rest}&${curr}`;
    });
};

export const orderLocationsByTitle = (matchedLocations: LocationMatchData): LocationMatchData => {
  matchedLocations.items.sort((locationA, locationB) => {
    return locationA.title > locationB.title ? 1 : -1;
  });
  return matchedLocations;
};

export const AUTOCOMPLETE_DEBOUNCE_TIME = 500;

export const getCountryOptions = (
  languageService: LanguageService,
  defaultOption?: SelectOption
): Observable<SelectOption[]> => {
  return languageService.translate('countries').pipe(
    map((countries) =>
      Object.keys(countries)
        .map(
          (country) =>
            ({
              value: country.toUpperCase(),
              name: countries[country],
            }) as SelectOption
        )
        .sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else if (b.name > a.name) {
            return -1;
          }
          return 0;
        })
    ),
    map((countries) => {
      if (defaultOption) {
        return [defaultOption, ...countries];
      }
      return countries;
    })
  );
};
