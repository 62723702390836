import { Component } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'fin-continue-reading',
  templateUrl: './continue-reading.component.html',
  styleUrls: ['./continue-reading.component.scss'],
})
export class ContinueReadingComponent {
  public readonly inputId: string = `continuereading-${uuid()}`;
  public readonly SvgLibraryIcon = SvgLibraryIcon;
}
