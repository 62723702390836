<fin-skip-link [main]="main"></fin-skip-link>
<fin-schema></fin-schema>
<fin-cms-global-disruption-banner></fin-cms-global-disruption-banner>

<router-outlet name="campaign-banner"></router-outlet>
<router-outlet name="header"></router-outlet>

<fcom-notification
  *ngIf="showIndiaRedirectNotification"
  [isClosable]="true"
  (closeClick)="onCloseIndiaNotification()"
  >{{ 'indiaRedirectNotification' | finLocalization }}</fcom-notification
>

<main #main [class.layout--footer-open]="isFooterOpen$ | async" role="main">
  <router-outlet></router-outlet>
</main>

<footer>
  <router-outlet name="footer"></router-outlet>
</footer>
