<button
  *ngIf="!href"
  (click)="onClick($event)"
  [type]="type"
  [class]="theme"
  class="block relative"
  [class.button-small]="size === ButtonSize.SMALL"
  [class.button-medium]="size === ButtonSize.MEDIUM"
  [class.button-large]="size === ButtonSize.LARGE"
  [class.ps-small-x]="size === ButtonSize.MEDIUM"
  [class.ps-medium-x]="size === ButtonSize.LARGE"
  [class.ps-xsmall-x]="size === ButtonSize.SMALL"
  [class.ps-xsmall-y]="size === ButtonSize.SMALL && isCircle"
  [class.btn-disabled]="appearDisabled"
  [class.btn-fill]="mode === ButtonMode.FILL"
  [class.btn-fill-mobile]="mode === ButtonMode.FILL_MOBILE"
  [disabled]="disabled || (loading && !appearDisabled)"
  [attr.tabindex]="removeFromNavigation ? '-1' : undefined"
  [attr.title]="title"
  [attr.role]="ariaRole"
  [attr.aria-label]="loadingLabel"
  [attr.aria-describedby]="aria && aria.describedby"
  [attr.aria-controls]="aria && aria.controls"
  [attr.aria-disabled]="aria && aria.disabled"
  [attr.aria-selected]="aria && aria.selected"
  [attr.aria-hidden]="aria && aria.hidden"
  [attr.aria-expanded]="aria && aria.expanded"
  [attr.id]="id"
  (blur)="blurBtn.emit()"
  #buttonElement
>
  <ng-container
    *ngTemplateOutlet="
      textTemplate;
      context: {
        icon: icon,
        loading: loading,
        size: size,
        text: text,
      }
    "
  >
  </ng-container>
</button>

<a
  *ngIf="href"
  (click)="onClick($event)"
  [finLink]="href"
  [external]="externalLink"
  [openInNewTab]="openInNewTab"
  class="block relative no-underline"
  [class]="theme"
  [class.button-small]="size === ButtonSize.SMALL"
  [class.button-medium]="size === ButtonSize.MEDIUM"
  [class.button-large]="size === ButtonSize.LARGE"
  [class.ps-small-x]="size === ButtonSize.MEDIUM"
  [class.ps-medium-x]="size === ButtonSize.LARGE"
  [class.ps-xsmall-x]="size === ButtonSize.SMALL"
  [class.ps-xsmall-y]="size === ButtonSize.SMALL"
  [class.btn-disabled]="appearDisabled"
  [class.btn-fill]="mode === ButtonMode.FILL"
  [class.btn-fill-mobile]="mode === ButtonMode.FILL_MOBILE"
  [attr.tabindex]="removeFromNavigation ? '-1' : undefined"
  [attr.title]="title"
  [attr.role]="ariaRole"
  [attr.aria-label]="loadingLabel"
  [attr.aria-describedby]="aria && aria.describedby"
  [attr.aria-controls]="aria && aria.controls"
  [attr.aria-selected]="aria && aria.selected"
  [attr.aria-hidden]="aria && aria.hidden"
  [attr.aria-expanded]="aria && aria.expanded"
  [attr.id]="id"
>
  <ng-container
    *ngTemplateOutlet="
      textTemplate;
      context: {
        icon: icon,
        loading: loading,
        size: size,
        text: text,
      }
    "
  >
  </ng-container>
</a>

<ng-template #textTemplate let-loading="loading" let-size="size" let-text="text">
  <span class="flex">
    <ng-container
      *ngTemplateOutlet="
        iconTemplate;
        context: { showIcon: icon && icon.position === IconPosition.LEFT, icon, loading, size }
      "
    >
    </ng-container>
    <span
      *ngIf="text"
      class="force-width text flex font-body-1 bold-type"
      [class.ps-xxsmall-r]="icon && IconPosition.RIGHT"
      [class.ps-xxsmall-l]="icon && IconPosition.LEFT"
    >
      <span
        class="break-word nowrap truncate overflow-hidden"
        [class.hide-element]="loading"
        [attr.aria-hidden]="loading"
        >{{ text }}</span
      >
      <fcom-loader *ngIf="loading" class="absolute center-icon" [loaderType]="LoaderTheme.BUTTON"></fcom-loader>
    </span>
    <ng-container
      *ngTemplateOutlet="
        iconTemplate;
        context: { showIcon: icon && icon.position === IconPosition.RIGHT, icon, loading, size }
      "
    >
    </ng-container>
  </span>
</ng-template>

<ng-template #iconTemplate let-showIcon="showIcon" let-icon="icon" let-loading="loading" let-size="size">
  <fcom-icon
    *ngIf="showIcon"
    class="flex--nogrow flex--self-center"
    role="presentation"
    [category]="icon.category"
    [name]="icon.name"
    [ngClass]="{
      'icon-medium': size === ButtonSize.MEDIUM || size === ButtonSize.LARGE,
      'hide-element': loading,
    }"
  >
  </fcom-icon>
</ng-template>
