<div *ngIf="items$ | async as items" class="important-links pr-xlarge-t mr-large-b">
  <h2>{{ (content$ | async)?.teaserTitle }}</h2>

  <div class="important-links-area flex flex--wrap rounded border-top border-left">
    <a
      *ngFor="let item of items; last as isLast"
      [finLink]="item?.target?.url"
      [external]="item?.target?.external"
      class="important-link flex flex--middle nordic-blue-900-text bold-type font-body-1 ps-medium no-underline border-bottom border-right underline-hover"
      [class.rounded-bottom-right]="isLast"
    >
      <span class="title">
        {{ item?.teaserTitle }}
      </span>
      <span class="cta">
        <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.ARROW_RIGHT"></fcom-icon>
      </span>
    </a>
  </div>
</div>
