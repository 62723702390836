import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IconsModule } from '../icons';
import { InformBlockComponent } from './inform-block.component';

@NgModule({
  declarations: [InformBlockComponent],
  imports: [CommonModule, IconsModule],
  exports: [InformBlockComponent],
})
export class InformBlockModule {}
