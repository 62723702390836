import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { compareAsJson } from '@fcom/core/utils';

import { CampaignBannerService } from '../../../services/campaign-banner/campaign-banner.service';
import { ElementTypes } from '../../../interfaces';

interface CampaignBanner {
  text: string;
  link: { url: string };
  test?: {
    text: string;
    link: { url: string };
  };
  enableBlackTeaser?: boolean;
}

@Component({
  selector: 'fin-campaign-banner',
  templateUrl: './campaign-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignBannerComponent implements OnInit {
  readonly ElementTypes = ElementTypes;
  visibility$: Observable<boolean>;

  constructor(
    private languageService: LanguageService,
    private route: ActivatedRoute,
    private campaignBannerService: CampaignBannerService
  ) {}
  campaignLink$: Observable<CampaignBanner>;

  ngOnInit(): void {
    this.visibility$ = this.campaignBannerService.visibility$.pipe(distinctUntilChanged());
    this.campaignLink$ = combineLatest([this.languageService.translate('campaignBanner'), this.route.queryParams]).pipe(
      distinctUntilChanged(compareAsJson),
      filter(([translations]) => !!(translations?.link?.url && translations?.text)),
      filter(([, queryParams]) => !(queryParams.utm_campaign && queryParams.utm_source)),
      map(([translations]) => translations),
      distinctUntilChanged()
    );
  }
}
