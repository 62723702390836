import { ElementRef } from '@angular/core';
import { isPlatformServer } from '@angular/common';

import { combineLatest, EMPTY, fromEvent, Observable } from 'rxjs';
import { filter, map, share } from 'rxjs/operators';

import { isPresent } from '@fcom/core/utils/utils';

import { WindowRef } from '../providers';

/**
 * PostMessenger can be used to communicate with iframes using the `postMessage` callback.
 * Supports reading and writing both in string and JSON format (newer browsers).
 */
export class PostMessenger<In, Out = void> {
  public static readonly TARGET_ORIGIN: string = '*';

  public readonly inbound$: Observable<In>;

  /**
   * Whether a message was received in stringified format (as opposed to JSON). Used
   * for configuring the postMessage the other way round to send the payload as string.
   * @type {boolean}
   */
  private stringifiedInteraction = false;

  /**
   * @param {WindowRef} windowRef the window instance, whose events should be listened to.
   * @param {Observable<string>} iFrameSrcs$ the expected origins of the iFrame message.
   * @param {object} platform the PLATFORM_ID for determining if server or browser.
   * @param {ElementRef} iFrameGetter Function to get ElementRef to the iFrame instance, required only if you need to post messages
   * instead of just listening.
   */
  constructor(
    windowRef: WindowRef,
    iFrameSrcs$: Observable<string[]>,
    platform: object,
    private iFrameGetter?: () => ElementRef
  ) {
    if (isPlatformServer(platform)) {
      this.inbound$ = EMPTY;
      return;
    }
    this.inbound$ = combineLatest([fromEvent<MessageEvent>(windowRef.nativeWindow, 'message'), iFrameSrcs$]).pipe(
      filter(([message, srcs]) => srcs.some((src) => src.indexOf(message.origin) !== -1)), // Ensure the message.origin
      // is the same as the iframe's loaded source
      map(([message]) => this.decode(message)),
      share()
    );
  }

  post(msg: Out): void {
    const iFrame = this.iFrameGetter();
    if (!isPresent(iFrame)) {
      console.warn('PostMessenger iFrame is missing => can not post messages');
      return;
    }
    iFrame.nativeElement.contentWindow.postMessage(this.encode(msg), PostMessenger.TARGET_ORIGIN);
  }

  private decode(message: any): In {
    if (typeof message.data === 'string') {
      try {
        this.stringifiedInteraction = true;
        return JSON.parse(message.data);
      } catch (_err) {
        return message.data;
      }
    }
    this.stringifiedInteraction = false;
    return message.data;
  }

  private encode(message: Out): any {
    return this.stringifiedInteraction ? JSON.stringify(message) : message;
  }
}
