<ng-template #dateBlock let-date="date">
  <div class="flex date-block flex--column flex--center">
    <div class="is-vishidden">
      {{ date | finDateFormat: 'VOICE_OVER_DATE' }}
    </div>
    <div class="font-body-2 regular-type" aria-hidden="true">
      {{ date | finDateFormat: 'WEEKDAY_SHORT' | finEmpty }}
    </div>
    <div class="nordic-blue-900-text weekday" aria-hidden="true">
      {{ date | finDateFormat: 'd' | finEmpty }}
    </div>
  </div>
  <div class="font-body-2 regular-type" aria-hidden="true">
    {{ date | finDateFormat: 'MONTH_SHORT' | finEmpty }}
  </div>
</ng-template>

<div>
  <span aria-live="polite" class="is-vishidden" *ngIf="(announceDates$ | async) === true">
    {{ 'datepicker.selectedDates' | finLocalization }}:
    {{ selectedDeparture$ | async | finDateFormat: 'dd.MM.yyyy' | finEmpty }},
    {{ selectedReturn$ | async | finDateFormat: 'dd.MM.yyyy' | finEmpty }}
  </span>
  <table class="aircalendar-table center align-center" [class.booking]="isBookingCalendar">
    <caption class="is-vishidden" data-testid="price-matrix-table-caption">
      {{
        (isBookingCalendar ? 'airCalendar.title' : 'MMB.view.changeFlightsModal.tableCaption') | finLocalization
      }}
    </caption>
    <col *ngIf="isBookingCalendar && showReturn && showDeparture" span="1" class="left-side-filler" />
    <tr *ngIf="isBookingCalendar">
      <th *ngIf="showReturn && showDeparture" scope="col" colspan="2" class="grey-100-bg"></th>
      <!-- horizontal blue line -->
      <th colspan="7" scope="col" class="nordic-blue-900-bg white-text tr-xsmall align-left">
        <div class="nowrap medium-type pr-xxsmall-l ps-xxsmall-y departure-date-title font-body-1">
          <fcom-icon class="icon-medium flex--nogrow" [name]="SvgLibraryIcon.DEPARTURE"></fcom-icon>
          <span class="ms-xxsmall-l">
            {{ 'bookingSearch.boundSelection.departureDate' | finLocalization }}
          </span>
        </div>
      </th>
    </tr>
    <tr>
      <th *ngIf="isBookingCalendar && showReturn && showDeparture" scope="col" colspan="2" class="grey-100-bg"></th>
      <th *ngIf="!isBookingCalendar" scope="col" class="border font-body-2 nordic-blue-900-text capitalize font-body-2">
        <div class="ps-xxsmall-y" [ngClass]="{ 'border-bottom': showDeparture && showReturn }" *ngIf="showDeparture">
          <span class="dn-laptop-down" data-testid="price-matrix-departure-label">{{
            (isBookingCalendar ? 'bookingSearch.departure' : 'MMB.view.changeFlightsModal.departure') | finLocalization
          }}</span>
          <fcom-icon class="icon-small dn-laptop dn-desktop" [name]="SvgLibraryIcon.DEPARTURE"></fcom-icon>
          <fcom-icon class="icon-small" [name]="SvgLibraryIcon.CHEVRON_RIGHT"></fcom-icon>
        </div>
        <div class="ps-xxsmall-y" *ngIf="showReturn">
          <span class="dn-laptop-down" data-testid="price-matrix-return-label">{{
            (isBookingCalendar ? 'bookingSearch.return' : 'MMB.view.changeFlightsModal.return') | finLocalization
          }}</span>

          <fcom-icon class="icon-small dn-laptop dn-desktop" [name]="SvgLibraryIcon.ARRIVAL"></fcom-icon>
          <fcom-icon
            *ngIf="showReturn && showDeparture"
            class="icon-small"
            [name]="SvgLibraryIcon.CHEVRON_DOWN"
          ></fcom-icon>
          <fcom-icon
            *ngIf="showReturn && !showDeparture"
            class="icon-small"
            [name]="SvgLibraryIcon.CHEVRON_RIGHT"
          ></fcom-icon>
        </div>
      </th>
      <th
        scope="col"
        *ngFor="let departureDate of departureDates"
        class="border ps-xxsmall-y"
        [ngClass]="{
          'nordic-blue-50-bg': (selectedDeparture$ | async) === departureDate,
          'grey-100-bg': isBookingCalendar && showDeparture && (selectedDeparture$ | async) !== departureDate,
        }"
      >
        <ng-container [ngTemplateOutlet]="dateBlock" [ngTemplateOutletContext]="{ date: departureDate }"></ng-container>
      </th>
    </tr>
    <tr *ngFor="let returnDate of returnDates; let row = index; let first = first">
      <!-- vertical blue box -->
      <th
        *ngIf="isBookingCalendar && showReturn && showDeparture && first"
        rowspan="7"
        scope="row"
        class="relative nordic-blue-900-bg white-text tr-xsmall return-date-title left-side-filler font-body-1"
      >
        <div class="absolute nowrap medium-type tr-xsmall medium-type pr-xsmall-r">
          <fcom-icon class="icon-medium flex--nogrow" [name]="SvgLibraryIcon.ARRIVAL"></fcom-icon>
          <span class="ms-xxsmall-l">
            {{ 'bookingSearch.boundSelection.returnDate' | finLocalization }}
          </span>
        </div>
      </th>
      <th
        *ngIf="!isBookingCalendar || showReturn"
        scope="row"
        class="border ps-xxsmall-y"
        [ngClass]="{
          'nordic-blue-50-bg': (selectedReturn$ | async) === returnDate && (selectedReturn$ | async) !== '',
          'grey-100-bg':
            isBookingCalendar &&
            showDeparture &&
            (selectedReturn$ | async) !== returnDate &&
            (selectedReturn$ | async) !== '',
        }"
      >
        <ng-container [ngTemplateOutlet]="dateBlock" [ngTemplateOutletContext]="{ date: returnDate }"></ng-container>
      </th>
      <td
        class="day-price border font-body-2 ps-small-t ps-small-b"
        [ngClass]="{
          'nordic-blue-900-bg white-text':
            !isBookingCalendar &&
            (selectedReturn$ | async) === returnDate &&
            (selectedDeparture$ | async) === departureDate,
          'nordic-blue-50-bg nordic-blue-900-text':
            (((selectedDeparture$ | async) === departureDate && (selectedReturn$ | async) >= returnDate) ||
              ((selectedReturn$ | async) !== '' &&
                (selectedReturn$ | async) === returnDate &&
                (selectedDeparture$ | async) >= departureDate)) &&
            (!((selectedReturn$ | async) === returnDate && (selectedDeparture$ | async) === departureDate) ||
              (isBookingCalendar &&
                (selectedReturn$ | async) === returnDate &&
                (selectedDeparture$ | async) === departureDate)),
          'nordic-blue-900-text': (selectedReturn$ | async) === '' && (selectedDeparture$ | async) !== departureDate,
          'bold-type selected':
            isBookingCalendar &&
            (selectedReturn$ | async) === returnDate &&
            (selectedDeparture$ | async) === departureDate,
          'heather-700-text bold-type':
            isBookingCalendar &&
            cheapestPrice.toString() === (pricesForDates | finMap: [departureDate, returnDate] | finMap: 'amount'),
        }"
        *ngFor="let departureDate of departureDates; let column = index"
        [tabIndex]="0"
        (keyup.space)="selectDate(departureDate, returnDate)"
        (keyup.enter)="selectDate(departureDate, returnDate)"
        (click)="selectDate(departureDate, returnDate)"
      >
        {{
          isAward
            ? (pricesForDates
              | finMap: [departureDate, returnDate]
              | finMap: 'amount'
              | finPoints: true
              | finEmpty: '-')
            : (pricesForDates
              | finMap: [departureDate, returnDate]
              | finPrice: { abbreviate: isBookingCalendar }
              | finEmpty: '-')
        }}
      </td>
    </tr>
  </table>
</div>
