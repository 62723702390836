<div
  class="relative pr-xxsmall-x nordic-blue-50-bg border-bottom"
  data-testid="histogram"
  #histogramContainer
  aria-role="presentation"
>
  <fin-scroll-handle-container
    #scrollContainer
    [elements]="bars"
    class="nordic-blue-50-bg flex flex--center"
    (scrollElements)="barsScrolled.emit($event)"
    [disableKeyboardNavigation]="true"
  >
    <div class="flex flex--column-row flex--align-end ps-medium-t bar-container">
      <ng-container *ngFor="let bar of range$ | async; let i = index">
        <ng-container *ngIf="bar?.spacer">
          <ng-container *ngTemplateOutlet="verticalSeparator; context: { label: bar?.spacer?.value }"></ng-container>
        </ng-container>

        <div
          class="flex flex--column flex--end shadow-small link-hover border-top border-left border-right rounded-top-medium bar"
          [attr.data-testid]="'histogram-bar-' + i"
          [class.cheapest]="bar?.isCheapest"
          [class.noFlight]="bar.noFlight"
          [class.selected]="(selected$ | async) === i"
          #bar
          [style.height]="bar.height"
          (click)="scrollToBarByIndex(bar)"
        >
          <p class="text-align center font-body-2" data-testid="histogram-label">{{ bar.text }}</p>
          <p *ngIf="!bar.noFlight" class="text-align center font-body-2 medium-type" data-testid="histogram-price">
            {{
              { amount: bar.amount, currencyCode: bar.currencyCode }
                | finPrice: { abbreviate: true, useCustomFormat: true }
            }}
          </p>
          <fcom-icon
            *ngIf="bar.noFlight"
            class="center icon-small ms-xxsmall-b"
            [name]="SvgLibraryIcon.FLIGHTS_UNAVAILABLE"
          ></fcom-icon>
        </div>
      </ng-container>
    </div>
  </fin-scroll-handle-container>
</div>

<ng-template #verticalSeparator let-label="label">
  <div class="flex flex--center flex--column flex--self-stretch" data-testid="histogram-spacer">
    <p class="font-body-2" data-testid="histogram-spacer-label">{{ label }}</p>
    <div class="border-left grow"></div>
  </div>
</ng-template>
