export interface Trip {
  journeys: Journey[];
  passengers: Passengers[];
  lastName: string;
  recLoc: string;
}

export interface OutboundJourney {
  departure: string;
  arrival: string;
  scheduledDateTime: string;
  scheduledDateTimeUnix: number;
  passengerAmount: number;
  bookingRef: string;
  mmbLink: string;
}

export interface Journey {
  departure: FlightDetail;
  arrival: FlightDetail;
  passengerAmount?: number;
  recLoc: string;
}

export interface Passengers {
  firstName?: string;
  lastName: string;
  passengerNum?: string;
}

interface FlightDetail {
  airport: string;
  airportName: string;
  municipality: string;
  scheduledDateTime: string;
}

export enum AddFrequentFlyerError {
  PASSENGER_NOT_FOUND = 'passenger_not_found',
  BOOKING_NOT_FOUND = 'booking_not_found',
  ALREADY_EXISTS = 'other_ff_already_exists',
  GROUP_BOOKING = 'group_booking',
}

export interface AddFrequentFlyerResponse {
  status: {
    code: number;
    status: string;
    message: string;
  };
  error?: {
    status: {
      message: AddFrequentFlyerError;
    };
  };
}
