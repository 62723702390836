import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { LocationRouteType } from '@fcom/core-api';
import { FinnairCabinClass, TranslatedBenefitRow } from '@fcom/dapi/api/models';

export const BENEFITS_ICON_MAP: Record<string, SvgLibraryIcon> = {
  checkedBaggage: SvgLibraryIcon.CHECKED_BAGGAGE,
  cabinBaggage: SvgLibraryIcon.CARRY_ON_BAG,
  seatSelection: SvgLibraryIcon.SEAT_OR_ECONOMY,
  rebook: SvgLibraryIcon.MMB_TICKET_CHANGES,
  cancel: SvgLibraryIcon.TICKET_REFUND,
  nameChange: SvgLibraryIcon.SINGLE_PAX,
  plusPoints: SvgLibraryIcon.FINNAIR_PLUS,
  loungeAccess: SvgLibraryIcon.LOUNGE,
  prioritySecurity: SvgLibraryIcon.PRIORITY_SECURITY,
  priorityCheckIn: SvgLibraryIcon.CHECK_IN,
  wifiAccess: SvgLibraryIcon.WIFI,
  cabinBaggageWithWeight: SvgLibraryIcon.CARRY_ON_BAGGAGE,
  cabinBagWithAmount: SvgLibraryIcon.CARRY_ON_BAG_TROLLEY,
  smallBag: SvgLibraryIcon.BACKPACK,
  carryOnBag: SvgLibraryIcon.CARRY_ON_BAGGAGE,
  checkedBaggageWithAmountAndWeightSingular: SvgLibraryIcon.CHECKED_BAGGAGE,
  checkedBaggageWithAmountAndWeightPlural: SvgLibraryIcon.CHECKED_BAGGAGE,
  checkedBaggageWithWeight: SvgLibraryIcon.CHECKED_BAGGAGE,
};

export const BENEFITS = {
  checkedBaggage: 'checkedBaggage',
  cabinBaggage: 'cabinBaggage',
  seatSelection: 'seatSelection',
  rebook: 'rebook',
  cancel: 'cancel',
  nameChange: 'nameChange',
  plusPoints: 'plusPoints',
  loungeAccess: 'loungeAccess',
  prioritySecurity: 'prioritySecurity',
  priorityCheckIn: 'priorityCheckIn',
  wifiAccess: 'wifiAccess',
};

export const koreanColoredBenefits: (keyof typeof BENEFITS_ICON_MAP)[] = ['rebook', 'cancel'];

export const shouldDisplayBenefit = (benefit: TranslatedBenefitRow): boolean => {
  return (
    (benefit.isPositive && benefit.key !== BENEFITS.plusPoints && benefit.key !== BENEFITS.nameChange) ||
    benefit.key === BENEFITS.cancel ||
    benefit.key === BENEFITS.rebook
  );
};

const economyChanges = {
  shortHaul: {
    [LocationRouteType.DEFAULT]: [],
    [LocationRouteType.AJB]: [],
    [LocationRouteType.SJB]: [],
  },
  longHaul: {
    [LocationRouteType.DEFAULT]: [],
    [LocationRouteType.AJB]: [],
    [LocationRouteType.SJB]: [],
  },
};

export const CHANGED_BENEFITS_BY_CLASS = {
  [FinnairCabinClass.BUSINESS]: {
    shortHaul: {
      [LocationRouteType.DEFAULT]: [
        'cabinBaggageWithWeight',
        'smallBag',
        'cabinBagWithAmount',
        'checkedBaggageWithAmountAndWeightSingular',
        'checkedBaggageWithAmountAndWeightPlural',
      ],
      [LocationRouteType.AJB]: [],
      [LocationRouteType.SJB]: [],
    },
    longHaul: {
      [LocationRouteType.DEFAULT]: [
        'cabinBaggageWithWeight',
        'smallBag',
        'cabinBagWithAmount',
        'checkedBaggageWithAmountAndWeightSingular',
        'checkedBaggageWithAmountAndWeightPlural',
      ],
      [LocationRouteType.AJB]: ['cabinBaggageWithWeight', 'smallBag', 'cabinBagWithAmount'],
      [LocationRouteType.SJB]: ['cabinBaggageWithWeight', 'smallBag', 'cabinBagWithAmount'],
    },
  },
  [FinnairCabinClass.ECONOMY]: economyChanges,
  [FinnairCabinClass.ECOPREMIUM]: economyChanges,
  [FinnairCabinClass.FIRST]: economyChanges,
};
