import { isNotBlank } from '@fcom/core/utils';

import { FinnairPassengerCode, FinnairPassengerItem } from '../api/models';

export const asPaxTypeKey = (type: FinnairPassengerCode | string) => {
  switch (type) {
    case FinnairPassengerCode.ADT:
      return 'ADULT';
    case FinnairPassengerCode.CHD:
      return 'CHILD';
    case FinnairPassengerCode.C_15:
      return 'C15';
    case FinnairPassengerCode.INF:
      return 'INFANT';
    default:
      return 'ADULT';
  }
};

export const asPaxSortId = (type: FinnairPassengerCode): number => {
  switch (type) {
    case FinnairPassengerCode.ADT:
      return 1;
    case FinnairPassengerCode.C_15:
      return 5;
    case FinnairPassengerCode.CHD:
      return 6;
    case FinnairPassengerCode.INF:
      return 7;
    default:
      return 1;
  }
};

export const isAdult = (passenger: FinnairPassengerItem): boolean =>
  passenger.passengerTypeCode === FinnairPassengerCode.ADT;

const safeNumberString = (value: string): string => {
  return value && isNaN(Number(value)) ? '' : value;
};

export const safePhonePrefix = (countryCodeParameter: string, areaCodeParameter: string): string => {
  const safeCountryCode = countryCodeParameter || '';
  const safeAreaCode = safeNumberString(areaCodeParameter);
  return isNotBlank(safeCountryCode) && isNotBlank(safeAreaCode) ? `${safeCountryCode}|${safeAreaCode}` : '';
};
