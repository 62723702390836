import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { CmsArticle, CmsCollection, CmsTaxonomyGeneral } from '@fcom/core-api/interfaces/cms-interface';
import { isNotEmpty, safeMap } from '@fcom/core/utils';

@Component({
  selector: 'cms-discover-now',
  styleUrls: ['./cms-discover-now.component.scss'],
  templateUrl: './cms-discover-now.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsDiscoverNowComponent implements OnInit {
  @Input()
  content$: Observable<CmsCollection>;
  itemsObs$: Observable<CmsArticle[]>;

  public readonly SvgLibraryIcon: typeof SvgLibraryIcon = SvgLibraryIcon;
  public readonly TAXONOMY: typeof CmsTaxonomyGeneral = CmsTaxonomyGeneral;

  ngOnInit(): void {
    this.itemsObs$ = this.content$.pipe(
      map((content: CmsCollection) => safeMap(content, (c: CmsCollection) => c.items as CmsArticle[])),
      filter(isNotEmpty),
      distinctUntilChanged()
    );
  }
}
