import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiTranslateModule } from '@fcom/ui-translate';

import { IconsModule } from '../icons/icons.module';
import { BookingHeaderComponent } from './booking-header/booking-header.component';
import { PlainHeaderAreaComponent } from './plain-header-area/plain-header-area.component';
import { ButtonModule } from '../buttons/button.module';

@NgModule({
  declarations: [BookingHeaderComponent, PlainHeaderAreaComponent],
  exports: [BookingHeaderComponent, PlainHeaderAreaComponent],
  providers: [],
  imports: [CommonModule, UiTranslateModule, IconsModule, ButtonModule],
})
export class HeadersModule {}
