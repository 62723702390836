<div class="wrapper">
  <div
    @openClose
    *ngIf="open"
    (@openClose.start)="onAnimationStart($event)"
    role="region"
    [id]="ariaId"
    [attr.aria-labelledby]="ariaLabelledBy"
  >
    <ng-content></ng-content>
  </div>
</div>
