import { Injectable, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { NEVER, Observable, Subscription } from 'rxjs';
import { share, takeUntil } from 'rxjs/operators';

import { SentryLogger } from '@fcom/core';
import { mapErrorForSentry, unsubscribe } from '@fcom/core/utils';
import { snapshot } from '@fcom/rx';
import { CommonBookingAncillaryService } from '@fcom/common-booking/modules/ancillaries/services';
import { SeatMapActions } from '@fcom/common-booking/store';
import { SeatmapSvgAndControlData } from '@fcom/dapi/api/models';
import { BookingAppState } from '@fcom/common/interfaces/booking/booking-state.interface';

import { cartFlight } from '../../../store/selectors';

@Injectable()
export class AncillaryService implements OnDestroy {
  private seatMapSubscription: Subscription;

  constructor(
    private commonAncillaryService: CommonBookingAncillaryService,
    private store$: Store<BookingAppState>,
    private sentryLogger: SentryLogger
  ) {}

  ngOnDestroy(): void {
    unsubscribe(this.seatMapSubscription);
  }

  /**
   * Triggers seat map fetching for the given parameters.
   * @param flightId
   * @param cartId
   * @param cartHash
   * @param currencyCode
   * @param cancelStream$ when this observable emits for the first time, the stream will be cancelled.
   */
  triggerFetchSeatMap(
    flightId: string,
    cartId: string,
    cartHash: string,
    currencyCode?: string,
    cancelStream$: Observable<void> = NEVER
  ): Observable<SeatmapSvgAndControlData> {
    unsubscribe(this.seatMapSubscription);
    const departureDateTime = snapshot(this.store$.pipe(cartFlight(flightId)))?.departure.dateTime;
    this.store$.dispatch(SeatMapActions.startLoading({ flightId }));
    const stream$ = this.commonAncillaryService
      .getCartSeatMapForFlight(flightId, departureDateTime, cartId, cartHash, currencyCode)
      .pipe(takeUntil(cancelStream$), share());
    this.seatMapSubscription = stream$.subscribe({
      next: (seatMap) => {
        this.store$.dispatch(SeatMapActions.setSeatMap({ seatMapForFlight: { seatMap, flightId } }));
        unsubscribe(this.seatMapSubscription);
      },
      // eslint-disable-next-line rxjs/no-implicit-any-catch
      error: (error: Error) => {
        this.sentryLogger.error('Error fetching seat map', {
          error: mapErrorForSentry(error),
        });
        this.store$.dispatch(SeatMapActions.error({ flightId }));
        unsubscribe(this.seatMapSubscription);
      },
      complete: () => {
        unsubscribe(this.seatMapSubscription);
      },
    });
    return stream$;
  }
}
