<div
  class="white-bg"
  [ngClass]="{
    'rounded-large border': hasBorder && roundedBottom,
    'rounded-top-large border-top border-left border-right': hasBorder && !roundedBottom,
  }"
  [class.pr-small-t]="fullWidth"
  [class.pr-small]="!fullWidth && !alignParent"
>
  <h2
    class="font-heading-4 flex flex--center flex--space-between"
    [class.nordic-blue-900-text]="!showHighlightedContent"
    [class.heather-700-text]="showHighlightedContent"
    [class.pr-small-x]="fullWidth"
    [class.pr-small-b]="!fullWidth && !noContent"
    *ngIf="title || endTitle"
  >
    <span class="block">{{ title }}</span>
    <span class="block {{ endTitleClasses }}">{{ endTitle }}</span>
  </h2>
  <div class="flex section-list" *ngIf="!noContent">
    <ng-content select="content"></ng-content>
  </div>
  <div class="align-right font-body-1" *ngIf="!noButton">
    <ng-content select="button"></ng-content>
  </div>
</div>
