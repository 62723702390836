import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { Category } from '@fcom/dapi/api/models';

export type BookingSummaryAncillaryKey =
  | 'purchase.payment.seatCount'
  | 'purchase.payment.baggageCount'
  | 'purchase.payment.mealCount'
  | 'purchase.payment.wifiCount'
  | 'purchase.payment.loungeCount'
  | 'purchase.payment.coverCount'
  | 'purchase.payment.sportCount'
  | 'purchase.payment.petCount'
  | 'purchase.payment.cabinBaggageCount';

export interface ServiceMapping {
  category: Category;
  icon: SvgLibraryIcon;
  bookingSummaryKey: BookingSummaryAncillaryKey;
}

export const serviceMapping: ServiceMapping[] = [
  {
    category: Category.CABIN_BAGGAGE,
    icon: SvgLibraryIcon.CARRY_ON_BAG,
    bookingSummaryKey: 'purchase.payment.cabinBaggageCount',
  },
  {
    category: Category.BAGGAGE,
    icon: SvgLibraryIcon.CHECKED_IN_BAGGAGE,
    bookingSummaryKey: 'purchase.payment.baggageCount',
  },
  {
    category: Category.MEAL,
    icon: SvgLibraryIcon.FOOD,
    bookingSummaryKey: 'purchase.payment.mealCount',
  },
  {
    category: Category.SEAT,
    icon: SvgLibraryIcon.SEAT_OR_ECONOMY,
    bookingSummaryKey: 'purchase.payment.seatCount',
  },
  {
    category: Category.WIFI,
    icon: SvgLibraryIcon.WIFI,
    bookingSummaryKey: 'purchase.payment.wifiCount',
  },
  {
    category: Category.LOUNGE,
    icon: SvgLibraryIcon.LOUNGE,
    bookingSummaryKey: 'purchase.payment.loungeCount',
  },
  {
    category: Category.SPORT,
    icon: SvgLibraryIcon.BIKE,
    bookingSummaryKey: 'purchase.payment.sportCount',
  },
  {
    category: Category.PET,
    icon: SvgLibraryIcon.PET,
    bookingSummaryKey: 'purchase.payment.petCount',
  },
  {
    category: Category.COVER,
    icon: SvgLibraryIcon.INSURANCE,
    bookingSummaryKey: 'purchase.payment.coverCount',
  },
];
