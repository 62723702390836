<section class="calendarHeader border-bottom white-bg fill z2">
  <div class="titleArea" [ngClass]="titleAreaConfig.titleAreaStyle">
    <div
      class="title grow flex"
      [ngClass]="{ 'flex--column flex--baseline flex--align-start': titleAreaConfig?.subTitle }"
    >
      <h2
        *ngIf="titleAreaConfig?.title || uiLabels?.title"
        class="overflow-hidden nordic-blue-900-text"
        [ngClass]="titleAreaConfig.titleStyle"
        [class.flex--self-center]="titleAreaConfig?.subTitle === undefined"
        tabindex="-1"
        data-focus-on-modal-open
        data-testid="datepicker-title"
        #titleElement
      >
        {{ dateSelectedLabel ? dateSelectedLabel : titleAreaConfig?.title ? titleAreaConfig.title : uiLabels?.title }}
      </h2>
      @if (titleAreaConfig?.subTitle) {
        <h3 class="font-body-1 subTitle medium-type">{{ titleAreaConfig?.subTitle }}</h3>
      }
    </div>

    <div class="controls flex flex--middle flex--start">
      <button
        (click)="clearDates()"
        class="reset font-body-1 regular-type nordic-blue-900-text underline ms-small-r"
        data-testid="datepicker-reset"
        data-focus-on-modal-open
      >
        {{ uiLabels?.reset }}
      </button>

      <div class="inputs flex flex--wrap ms-xsmall-x">
        <div class="fill flex border rounded">
          <div
            class="inputArea flex flex-1 ps-xxsmall-x ps-xsmall-y"
            [class.border-right]="isDateRange"
            [class.invalid]="startDateError"
          >
            <fcom-icon class="icon-medium flex--nogrow" [name]="SvgLibraryIcon.DEPARTURE"></fcom-icon>
            <input
              data-testid="datepicker-departure-date"
              [attr.aria-label]="uiLabels?.selectStartDate"
              aria-describedby="dp-startDate-error"
              autocomplete="off"
              class="flex-1 font-body-1 regular-type nordic-blue-900-text border-none mr-xxsmall-l"
              id="dp-startDate"
              maxlength="10"
              [attr.placeholder]="uiLabels?.inputPlaceholder"
              type="text"
              name="startDate"
              [(ngModel)]="startDate"
              (blur)="setDate($event)"
              (keyup.enter)="setDate($event)"
              inputmode="text"
            />
          </div>
          <div
            *ngIf="isDateRange"
            class="inputArea flex flex-1 ps-xsmall-l ps-xxsmall-r ps-xsmall-y"
            [class.invalid]="endDateError"
          >
            <fcom-icon class="icon-medium flex--nogrow" [name]="SvgLibraryIcon.ARRIVAL"></fcom-icon>
            <input
              data-testid="datepicker-return-date"
              [attr.aria-label]="uiLabels?.selectEndDate"
              aria-describedby="dp-endDate-error"
              autocomplete="off"
              class="flex-1 font-body-1 regular-type nordic-blue-900-text border-none mr-xxsmall-l"
              id="dp-endDate"
              maxlength="10"
              [attr.placeholder]="uiLabels?.inputPlaceholder"
              type="text"
              name="endDate"
              [(ngModel)]="endDate"
              (blur)="setDate($event)"
              (keyup.enter)="setDate($event)"
              inputmode="text"
            />
          </div>
        </div>
        <span
          id="dp-startDate-error"
          class="inputError fill ps-xxsmall-l font-body-1 heather-700-text heather-50-bg border heather-700-border rounded-bottom-left rounded-bottom-right"
          [class.is-hidden]="!startDateError"
          aria-live="assertive"
        >
          {{ startDateError }}
        </span>
        <span
          *ngIf="isDateRange"
          id="dp-endDate-error"
          class="inputError fill ps-xxsmall-l font-body-1 heather-700-text heather-50-bg border heather-700-border rounded-bottom-left rounded-bottom-right"
          [class.is-hidden]="!endDateError"
          aria-live="assertive"
        >
          {{ endDateError }}
        </span>
      </div>

      <fcom-button
        data-testid="datepicker-add-return"
        *ngIf="showAddReturn"
        [text]="'uiLabels.addReturn' | finLocalization"
        [icon]="{ category: IconLibrary.SVG_LIBRARY, name: SvgLibraryIcon.PLUS, position: IconPosition.LEFT }"
        [theme]="ButtonTheme.LIGHT_TERTIARY_GHOST"
        (click)="addReturn.emit()"
      ></fcom-button>
    </div>

    <div class="close flex flex--end">
      <button [attr.aria-label]="uiLabels?.close" class="rounded" (click)="closeDatepicker()">
        <fcom-icon class="icon-medium" [name]="SvgLibraryIcon.CLOSE_DELETE"></fcom-icon>
      </button>
    </div>
  </div>
  <ng-container *ngIf="headerTemplate" [ngTemplateOutlet]="headerTemplate"></ng-container>
</section>

<fcom-notification
  data-testid="datepicker-top-notification"
  *ngIf="topNotification"
  class="block pr-small-t pr-clear-y"
  [theme]="NotificationTheme.INFO"
  [layout]="NotificationLayout.EDGE_TO_EDGE"
>
  {{ topNotification }}
</fcom-notification>

<fcom-calendar
  class="block ms-xsmall-x ms-xsmall-t"
  [dateLabels]="dateLabels"
  [uiLabels]="uiLabels"
  [isDateRange]="isDateRange"
  [selectedDates]="selectedDateSelection"
  [disabledDateRanges]="disabledDateRanges"
  [showTags]="showTags"
  [dayTemplate]="dayTemplate"
  [scrollToMonthIndex$]="scrollToMonthIndex$"
  [minDate]="minDate"
  [maxDate]="maxDate"
  (monthChange)="monthChange.emit($event)"
  (datesSelected)="onDatesSelected($event)"
></fcom-calendar>

<div data-testid="datepicker-vishidden-dates" class="is-vishidden" role="status" *ngIf="startDate || endDate">
  {{ uiLabels?.selectedDates }}: {{ startDate }}, {{ endDate }}
</div>

<section class="ps-large nordic-blue-50-bg fill flex--center flex stickyFooter" *ngIf="!doneBtnHidden">
  <fcom-button (clickBtn)="closeDatepicker()" [text]="uiLabels?.done" class="font-body-2"></fcom-button>
</section>
