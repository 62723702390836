import { NgModule } from '@angular/core';

import { OrderApiModule } from '@fcom/dapi/api/order-api.module';

import { OrderPreviewsService } from './services/order-previews.service';

@NgModule({
  declarations: [],
  exports: [],
  imports: [OrderApiModule],
  providers: [OrderPreviewsService],
})
export class OrderModule {}
