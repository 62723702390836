import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { CmsCollection, CmsContent } from '@fcom/core-api/interfaces/cms-interface';
import { isNotEmpty, safeMap } from '@fcom/core/utils';

@Component({
  selector: 'cms-unique-selling-point',
  styleUrls: ['./cms-unique-selling-point.component.scss'],
  templateUrl: './cms-unique-selling-point.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsUniqueSellingPointComponent implements OnInit {
  @Input()
  content$: Observable<CmsCollection>;
  items$: Observable<CmsContent[]>;

  ngOnInit(): void {
    this.items$ = this.content$.pipe(
      map((content: CmsCollection) => safeMap(content, (c: CmsCollection) => c.items)),
      filter(isNotEmpty),
      distinctUntilChanged()
    );
  }
}
