<ng-container *ngIf="steps">
  <fcom-carousel
    class="dn-laptop-down"
    [prevButtonAriaLabel]="'carousel.previous' | finLocalization"
    [nextButtonAriaLabel]="'carousel.next' | finLocalization"
  >
    <ul class="breadcrumbs flex flex--center flex--self-center flex--no-wrap pr-small-y">
      <li
        *ngFor="let step of steps; let i = index"
        (click)="onBreadcrumbClick(step, i)"
        class="breadcrumb phase flex-1 flex flex--center"
        [class.selectable]="i < activeIndex"
        [class.active]="step.active"
        [id]="id + '-' + i"
      >
        <button class="flex" [disabled]="step.disabled" [attr.aria-disabled]="step.disabled === true">
          <div class="phase-block flex--nogrow"></div>
          <div class="phase-text flex-1 nordic-blue-900-text">{{ step.name | finLocalization }}</div>
        </button>
      </li>
    </ul>
  </fcom-carousel>
  <div class="dn-laptop-up breadcrumbs-mobile pr-small-t">
    <ul *ngIf="steps" class="flex">
      <li
        class="breadcrumb phase flex-1 flex flex--center"
        [class.selectable]="i < activeIndex"
        [class.active]="step.active"
        *ngFor="let step of steps; let i = index"
      >
        <div class="phase-block"></div>
      </li>
    </ul>
  </div>
</ng-container>
