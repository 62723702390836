import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { filter, map, take, throttleTime } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { GtmService } from '@fcom/common/gtm';
import { NavigatedPathToContentWrapperService, NavigationMenuService, PageMetaService } from '@fcom/common/services';
import { TimeAndGeoipProvider } from '@fcom/core/services';
import { unsubscribe, urlQueryString, urlToPathname } from '@fcom/core/utils';
import { PersonalizationTrackingService } from '@fcom/loyalty-personalization/services';
import { DataCloudService } from '@fcom/common/datacloud';
import { LoginIframeComponent } from '@fcom/common/login';
import { MultivariateTestService } from '@fcom/common/multivariate-test/services/multivariate-test.service';
import { Cms3DSeatMapService } from '@fcom/cms/services';
import { isNavigationEvent } from '@fcom/common/utils';

@Component({
  selector: 'fin-app',
  templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewInit, OnDestroy, OnInit {
  isNavigationMenuOpen$: Observable<boolean>;
  title$: Observable<string>;
  removeGlobalListener: () => void;
  contentWrapperNavigatedClass$: Observable<string>;
  public readonly LOGIN_IFRAME_COMPONENT_TYPE = LoginIframeComponent;

  private subscriptions: Subscription = new Subscription();

  @ViewChild('contentWrapper', { static: true })
  contentWrapper: ElementRef;

  constructor(
    private navigationMenuService: NavigationMenuService,
    private timeAndGeoipProvider: TimeAndGeoipProvider,
    private pageMetaService: PageMetaService,
    private router: Router,
    private renderer: Renderer2,
    private languageService: LanguageService,
    private dataLayerService: GtmService,
    private dataCloudService: DataCloudService,
    private multiVariateTestService: MultivariateTestService,
    private cms3DSeatMapService: Cms3DSeatMapService,
    private navigatedPathToContentWrapperService: NavigatedPathToContentWrapperService,
    private personalizationTrackingService: PersonalizationTrackingService,
    @Inject(PLATFORM_ID) private platform: object
  ) {
    this.isNavigationMenuOpen$ = this.navigationMenuService.isNavigationMenuOpen$;
    this.title$ = this.pageMetaService.title$;

    this.subscriptions.add(
      this.languageService.lang.subscribe((l) => {
        this.pageMetaService.setDocumentLanguage(l, this.renderer);
      })
    );

    this.contentWrapperNavigatedClass$ = this.navigatedPathToContentWrapperService.contentWrapperNavigatedClass$;
  }

  ngOnInit(): void {
    this.pushNavigationEventsToDataLayer();
    this.navigatedPathToContentWrapperService.setRouter(this.router);
    this.multiVariateTestService.init();
    this.personalizationTrackingService.init();
  }

  ngAfterViewInit(): void {
    this.timeAndGeoipProvider.triggerTimeAndGeoipFetch();

    this.globalClicksHandler();
  }

  ngOnDestroy(): void {
    this.subscriptions = unsubscribe(this.subscriptions);

    if (this.removeGlobalListener) {
      this.removeGlobalListener();
    }
  }

  /**
   * The throttle is implented to prevent duplication of page view tracking events when app is handling entrypoint urls that contain query params.
   * In these cases 2 navigationEnd events are fired in short time, one for initial landing and one after the params have been stripped out.
   * Throttle was chosen instead of debounce time to capture query params of the initial event.
   * 200ms was chosen as it is short enough not to harm natural navigation behaviour, but long enough to cover this case.
   */
  private pushNavigationEventsToDataLayer() {
    if (isPlatformBrowser(this.platform)) {
      this.subscriptions.add(
        this.router.events.pipe(filter(isNavigationEvent), take(1)).subscribe((event: NavigationEnd) => {
          this.dataLayerService.originalPageView(event.urlAfterRedirects);
        })
      );

      this.subscriptions.add(
        this.router.events.pipe(filter(isNavigationEvent), throttleTime(200)).subscribe((event: NavigationEnd) => {
          this.dataLayerService.pageView(event.urlAfterRedirects);
        })
      );

      this.subscriptions.add(
        this.router.events
          .pipe(
            filter(isNavigationEvent),
            map((event) => new URLSearchParams(urlQueryString(event.urlAfterRedirects)).get('sfmc_sub')),
            filter(Boolean),
            take(1)
          )
          .subscribe((subscriberId: string) => {
            this.dataCloudService.subscriberId(subscriberId);
          })
      );
    }
  }

  private globalClicksHandler() {
    this.removeGlobalListener = this.renderer.listen('document', 'click', (event) => {
      const target = event.target;

      const link = (target as Element).closest('a');
      const url: string = link?.href;

      if (link && url?.includes?.('#3dseatmap')) {
        event.preventDefault();
        event.stopPropagation();
        this.seatMapLinkListener(url);
        return;
      }

      if (
        target &&
        typeof target.tagName === 'string' &&
        target.tagName.toLowerCase() === 'a' &&
        target.target !== '_blank' &&
        target.getAttribute('data-contentid')
      ) {
        // Application internal URL, use the router to navigate
        this.router.navigateByUrl(urlToPathname(target.href));
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    });
  }

  private seatMapLinkListener(url: string): void {
    const parts = url.split('/');
    const cabinClass = parts.pop();
    const fleet = parts.pop();
    this.cms3DSeatMapService.open3dSeatMap(fleet, cabinClass);
  }
}
