<fin-expand
  class="block rounded"
  [ngClass]="{ 'white-bg': isOpen$ | async }"
  [initiallyOpen]="initiallyOpen"
  [key]="expandId"
  [id]="expandId"
>
  <div
    class="trigger flex flex--center"
    [ngClass]="{
      'pr-xsmall-x ps-xsmall-y': (isOpen$ | async) === false,
      'pr-small-t pr-small-x': isOpen$ | async,
    }"
    [attr.aria-label]="expandButtonAriaLabelKey | finLocalization"
  >
    <div *ngIf="(isOpen$ | async) === false" class="flex expand-closed">
      <span class="flex-1 gray-50-text ps-xxsmall-r" data-testid="total-expand-translation"
        >{{ totalTranslationKey$ | async | finLocalization }}:</span
      >
      <span class="flex-1 medium-type" data-testid="total-expand-total-price"
        >{{ total$ | async | finPrice: { absoluteValue: true } }}
        <fcom-icon
          *ngIf="(loading$ | async) === true"
          [attr.aria-label]="'ctaButton.loading' | finLocalization"
          class="ms-xxsmall-l icon-medium loading-icon"
          [name]="SvgLibraryIcon.TRANSFER_LOADER"
        ></fcom-icon>
      </span>
    </div>
    <div
      data-testid="total-expand-title"
      class="bold flex flex-1"
      [ngClass]="{
        'font-heading-4 nordic-blue-900-text': isOpen$ | async,
        'underline medium-type flex--end': (isOpen$ | async) === false,
      }"
    >
      <div class="flex flex--center" [ngClass]="{ fill: isOpen$ | async }">
        <div class="flex-1 pr-xxsmall-r">{{ titleTranslationKey | finLocalization }}</div>
        <fcom-icon
          class="rotate icon-medium"
          [dataTestId]="'icon-' + SvgLibraryIcon.CHEVRON_DOWN"
          [name]="SvgLibraryIcon.CHEVRON_DOWN"
          [ngClass]="{ 'nordic-blue-900-text rotate-active': isOpen$ | async }"
        ></fcom-icon>
      </div>
    </div>
  </div>
  <div class="content">
    <ng-content></ng-content>
  </div>
</fin-expand>
