import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { UiTranslateModule } from '@fcom/ui-translate';
import { IconsModule } from '@fcom/ui-components';
import { ComponentsModule } from '@fcom/common/components';
import { DirectiveModule } from '@fcom/common/directives';
import { PipesModule } from '@fcom/common/pipes/pipes.module';

import { OndMarketingOffersListComponent } from './components/ond-marketing-offers-list.component';
import { OndMarketingOffersComponent } from './components/ond-marketing-offers.component';
import { OndMarketingCrossLinkingComponent } from './components/ond-marketing-cross-linking.component';
import { OndMarketingRibbonComponent } from './components/ond-marketing-ribbon.component';

@NgModule({
  imports: [CommonModule, DirectiveModule, PipesModule, ComponentsModule, UiTranslateModule, IconsModule],
  declarations: [
    OndMarketingOffersListComponent,
    OndMarketingOffersComponent,
    OndMarketingCrossLinkingComponent,
    OndMarketingRibbonComponent,
  ],
  exports: [
    OndMarketingOffersListComponent,
    OndMarketingOffersComponent,
    OndMarketingCrossLinkingComponent,
    OndMarketingRibbonComponent,
  ],
})
export class OndMarketingLandingCoreModule {}
