import { createAction, props, State, UPDATE } from '@ngrx/store';

import { AppState } from '../interfaces';

/**
 * Adds the state synced from local and session storage to the @ngrx/store/init event.
 * Can be used, e.g., when wanting to repopulate data from other parts of the state tree.
 */
export const initStoreAction = createAction(State.INIT, props<{ payload: AppState }>());
export const updateStoreAction = createAction(UPDATE, props<{ payload: AppState; features: string[] }>());
