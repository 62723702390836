<div class="border rounded-medium pr-large-y pr-medium-x align-center white-bg mr-large-y">
  <fcom-icon *ngIf="icon" [name]="icon" class="ms-xsmall-b nordic-blue-900-text icon-xlarge"></fcom-icon>

  <h1 *ngIf="heading" class="nordic-blue-900-text ms-xsmall-b font-heading-2">{{ heading }}</h1>
  <h2 *ngIf="subHeading" class="nordic-blue-900-text ms-medium-b font-heading-3">{{ subHeading }}</h2>

  <p *ngIf="text" class="mr-medium-b">{{ text }}</p>

  <fcom-button
    *ngIf="showButton && buttonText"
    (clickBtn)="buttonClicked.emit($event)"
    [theme]="ButtonTheme.LIGHT_SECONDARY_OUTLINE"
    [text]="buttonText"
  >
  </fcom-button>
</div>
