import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

export enum GlobalBookingTravelClass {
  ECONOMY = 'ECONOMY',
  ECOPREMIUM = 'ECOPREMIUM',
  BUSINESS = 'BUSINESS',
  FIRST = 'FIRST',
  MIXED = 'MIXED',
}

export type GlobalBookingTravelClassValue = keyof typeof GlobalBookingTravelClass;

export type GlobalBookingTravelClassDataMap = {
  [k in GlobalBookingTravelClassValue]: {
    icon: string;
    translationKey: string;
  };
};

export const COMMON_BOOKING_TRAVEL_CLASSES: GlobalBookingTravelClassDataMap = {
  FIRST: {
    translationKey: 'travelClass.first',
    icon: SvgLibraryIcon.BUSINESS_CLASS,
  },
  BUSINESS: {
    translationKey: 'travelClass.business',
    icon: SvgLibraryIcon.BUSINESS_CLASS,
  },
  ECONOMY: {
    translationKey: 'travelClass.economy',
    icon: SvgLibraryIcon.SEAT_OR_ECONOMY,
  },
  ECOPREMIUM: {
    translationKey: 'travelClass.ecopremium',
    icon: SvgLibraryIcon.SEAT_OR_ECONOMY,
  },
  MIXED: {
    translationKey: 'travelClass.mixed',
    icon: SvgLibraryIcon.SEAT_OR_ECONOMY,
  },
};
