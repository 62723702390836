import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DirectiveModule } from '@fcom/common/directives';

import { LinkComponent } from './link.component';
import { IconsModule } from '../icons';

@NgModule({
  declarations: [LinkComponent],
  imports: [CommonModule, DirectiveModule, IconsModule],
  exports: [LinkComponent],
})
export class LinkModule {}
