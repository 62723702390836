import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { TabsComponent } from '../tabs/tabs.component';
import { TabIconPosition } from '../tabs/enums';

interface TabIcon {
  name: SvgLibraryIcon;
  position: TabIconPosition;
}

@Component({
  selector: 'fcom-tab',
  templateUrl: './tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent implements OnInit, OnDestroy {
  private activeValue: boolean;
  private readonly tabs: TabsComponent;
  private _id: string;

  @Input() disabled: boolean;

  @Input()
  set id(_id: string) {
    if (_id !== this._id) {
      this._id = _id;
      this.changeDetectorRef.markForCheck();
    }
  }

  get id(): string {
    return this._id;
  }

  @Input({ required: true }) title: string;
  @Input() subtitle: string;
  @Input() contentPopover: TemplateRef<ElementRef>;
  @Input() data: unknown;

  @Input()
  set active(active: boolean) {
    this.activeValue = active;
    this.changeDetectorRef.detectChanges();
  }

  get active(): boolean {
    return this.activeValue;
  }

  @Input() icon: TabIcon = {
    name: '' as SvgLibraryIcon,
    position: TabIconPosition.TOP,
  };

  constructor(
    tabs: TabsComponent,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.tabs = tabs;
  }

  ngOnInit(): void {
    this.tabs.addTab(this);
  }

  ngOnDestroy(): void {
    this.tabs.removeTab(this);
  }
}
