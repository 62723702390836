import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, ReplaySubject, Subject, merge, NEVER } from 'rxjs';
import { take, timeout, catchError, tap, switchMap, map, filter, takeUntil } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { Location, LocationGeoLocData } from '@fcom/core-api';
import { combineTemplate, finShare } from '@fcom/rx';
import { isPresent, mergeDeep } from '@fcom/core/utils';

import { LocationRouteCffService } from '../location-routecff/location-routecff.service';
import { NavigatorActions } from '../../actions';
import { AppState, LatLng } from '../../interfaces';
import { akamaiGeolocation, browserGeolocation } from '../../selectors';
import { mapErrorForSentry } from '../../utils';
import { SentryLogger } from '../sentry/sentry.logger';

@Injectable()
export class GeolocationService {
  static INITIAL_LOCATION_TIMEOUT = 1000;
  static NAVIGATOR_GEOLOCATION_TIMEOUT_MS = 20000;
  static GEOLOCATION_MAX_AGE_MS: number = 60 * 60 * 1000;

  private browserGeolocation$: Observable<LatLng>;
  private akamaiGeolocation$: Observable<LatLng>;
  private userLocation$: Observable<LatLng>;
  private givenLocation$: Subject<LatLng> = new ReplaySubject(1);
  private nearestAirport$: Observable<Location>;
  private watchId: number;

  constructor(
    private locationRouteCffService: LocationRouteCffService,
    private languageService: LanguageService,
    private store$: Store<AppState>,
    private sentryLogger: SentryLogger
  ) {
    this.akamaiGeolocation$ = this.store$.pipe(akamaiGeolocation());
    this.browserGeolocation$ = this.store$.pipe(browserGeolocation());

    this.userLocation$ = merge(this.givenLocation$, this.browserGeolocation$).pipe(
      take(1),
      timeout(GeolocationService.INITIAL_LOCATION_TIMEOUT),
      catchError(() => this.akamaiGeolocation$),
      finShare()
    );

    this.nearestAirport$ = this.getNearestAirportForLocation(this.userLocation$);
  }

  init(paramsLocation?: LatLng): void {
    if (paramsLocation) {
      this.givenLocation$.next(paramsLocation);
      this.givenLocation$.complete();
    }
  }

  get nearestAirport(): Observable<Location> {
    return this.nearestAirport$;
  }

  getGeolocationFromNavigator(cancelGeolocationFetch$: Observable<boolean>): Observable<Location> {
    this.store$.dispatch(NavigatorActions.setBrowserGeolocationLoading());

    return new Observable<LatLng>((subscriber) => {
      try {
        this.clearWatchId();
        this.watchId = navigator.geolocation.watchPosition(
          (position) => {
            const latLng: LatLng = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            this.store$.dispatch(NavigatorActions.setBrowserGeolocation({ location: latLng }));
            subscriber.next(latLng);
            subscriber.complete();
            this.clearWatchId();
          },
          (error) => {
            this.sentryLogger.warn('Navigator.geolocation fetch failed', {
              error: mapErrorForSentry(error),
            });
            subscriber.error(error);
            // eslint-disable-next-line rxjs/no-redundant-notify
            subscriber.complete();
            this.clearWatchId();
          },
          {
            enableHighAccuracy: false,
            timeout: GeolocationService.NAVIGATOR_GEOLOCATION_TIMEOUT_MS,
            maximumAge: GeolocationService.GEOLOCATION_MAX_AGE_MS,
          }
        );
      } catch (e) {
        subscriber.error(e);
        // eslint-disable-next-line rxjs/no-redundant-notify
        subscriber.complete();
        this.clearWatchId();
      }
      return function dispose() {};
    }).pipe(
      this.getNearestAirportForLocation,
      tap(() => this.store$.dispatch(NavigatorActions.removeBrowserGeolocationLoading())),
      catchError(() => {
        this.store$.dispatch(NavigatorActions.removeBrowserGeolocationLoading());
        return NEVER;
      }),
      takeUntil(
        cancelGeolocationFetch$.pipe(
          tap(() => this.store$.dispatch(NavigatorActions.removeBrowserGeolocationLoading()))
        )
      )
    );
  }

  private clearWatchId = () => {
    if (this.watchId) {
      try {
        navigator.geolocation.clearWatch(this.watchId);
        // eslint-disable-next-line no-empty
      } catch {}
    }
  };

  private getNearestAirportForLocation = (location$: Observable<LatLng>) => {
    return combineTemplate({
      userLocation: location$,
      locale: this.languageService.locale,
    }).pipe(
      switchMap(({ userLocation, locale }) =>
        this.locationRouteCffService.geolocMatchFor(userLocation.lat, userLocation.lng, locale)
      ),
      filter((locationData: LocationGeoLocData) => locationData.ok),
      map((locationData: LocationGeoLocData) => locationData.item),
      map((l: Location) => mergeDeep<Location>(l, { isFromUserLocation: true })),
      filter(isPresent)
    );
  };
}
