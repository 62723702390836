import { InjectionToken, NgModule, PLATFORM_ID, Optional, SkipSelf } from '@angular/core';

import { StoreModule, META_REDUCERS } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { WINDOW_PROVIDERS } from '../providers';
import {
  reducers,
  dispatchInitialStateReducer,
  localStorageSyncMetaReducer,
  sessionStorageSyncMetaReducer,
} from '../reducers/root.reducer';
import {
  LOCAL_STORAGE_VERSION,
  SESSION_STORAGE_VERSION,
  SentryLogger,
  SentryModule,
  StorageService,
} from '../services';
import { generateLocalStorageHash, sessionStorageVersionFactory } from './storage-versions';

export const ALLOW_REHYDRATION = new InjectionToken<boolean>('allowStateRehydration');

@NgModule({
  imports: [
    StoreModule.forRoot(reducers, {
      metaReducers: [dispatchInitialStateReducer],
      runtimeChecks: {
        strictStateImmutability: false, // todo: re-enable runtime checks
        strictActionImmutability: false, // todo: re-enable runtime checks
        strictStateSerializability: false, // todo: re-enable runtime checks
        strictActionSerializability: false, // todo: re-enable runtime checks
      },
    }),
    EffectsModule.forRoot([]),
    SentryModule,
  ],
  providers: [
    {
      provide: META_REDUCERS,
      deps: [ALLOW_REHYDRATION, StorageService, PLATFORM_ID, SentryLogger],
      useFactory: localStorageSyncMetaReducer,
      multi: true,
    },
    {
      provide: META_REDUCERS,
      deps: [ALLOW_REHYDRATION, StorageService, PLATFORM_ID, SentryLogger],
      useFactory: sessionStorageSyncMetaReducer,
      multi: true,
    },
    { provide: ALLOW_REHYDRATION, useValue: true },
    { provide: SESSION_STORAGE_VERSION, useFactory: sessionStorageVersionFactory },
    { provide: LOCAL_STORAGE_VERSION, useFactory: generateLocalStorageHash },
    ...WINDOW_PROVIDERS,
  ],
})
export class StateTreeModule {
  constructor(@Optional() @SkipSelf() parentModule: StateTreeModule) {
    if (parentModule) {
      throw new Error(
        'StateTreeModule is already loaded. This module should only be imported once withing the application.'
      );
    }
  }
}
