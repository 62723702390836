import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Directive({
  selector: '[finFormErrorFocus]',
})
export class FormErrorFocusDirective {
  @Input('finFormErrorFocus') formGroup: UntypedFormGroup;

  constructor(private el: ElementRef) {}

  @HostListener('submit', ['$event'])
  onFormSubmit(event: Event) {
    if (this.formGroup && this.formGroup.status === 'INVALID') {
      event.preventDefault();
      const invalidElements = this.el.nativeElement.querySelectorAll(
        'input.ng-invalid, select.ng-invalid, textarea.ng-invalid'
      );
      if (invalidElements.length > 0) {
        invalidElements[0].focus();
      }
    }
  }
}
