import { Injectable } from '@angular/core';

import { WindowRef } from '@fcom/core';
import { ConfigService } from '@fcom/core/services';

import { ChatServiceConfig, ChatServiceQueueId } from '../interfaces';

/**
 * Init values in this file and in /cfg/chat.js were provided to Finnair by Fluido
 *
 * The way the Salesforce Chat config is built is a little complicated:
 * -There's a bunch of settings (Settings below) and then
 * -There's a set of parameters that are given to the .init() function.
 *    - Part of these change based on the chat environment
 *    - Part of these change based on the service queue config (chat language) chosen
 *    - Part of them don't change (isOfflineSupportEnabled)
 *    - Some of them are inside the 5th parameter of the .init (we call it InitParamsSubConfig) while others are direct unnamed params for .init() (we call them paramA, paramB, paramD).
 */

/**
 * Settings that go to document.embedded_svc.settings
 *
 * Settings described in https://resources.docs.salesforce.com/218/latest/en-us/sfdc/pdf/snapins_web_dev_guide.pdf
 * Settings marked with UNDOCUMENTED were reverse-engineered from source of esw.min.js and liveagent.esw.min.js
 *
 */
type Settings = {
  displayHelpButton: boolean; // display the default chat button
  language: string; // For example 'en' or 'en-US', no underscores
  storageDomain: string;
  enabledFeatures: string[];
  entryFeature: string; // default "FieldService"
  loadingText?: string;
  disabledMinimizedText?: string; // Defaults to Agent Offline
  defaultMinimizedText?: string; // Defaults to Chat with an Expert
  targetElement?: HTMLElement; // UNDOCUMENTED, default document.body
  extraPrechatInfo?: any;
  prepopulatedPrechatFields?: any;
  extraPrechatFormDetails?: any;
  offlineSupportMinimizedText?: string; // Defaults to Contact Us
  devMode?: boolean; // UNDOCUMENTED
  externalScripts?: string[];
  externalStyles?: string[];
  // These are supported but we don't currently use them, so they are commented out:
  // avatarImgURL?: string;
  // prechatBackgroundImgURL?: string;
  // waitingStateBackgroundImgURL?: string;
  // smallCompanyLogoImgURL?: string;
  // appendHelpButton: boolean; // UNDOCUMENTED
  // isExternalPage: boolean; // UNDOCUMENTED
  // elementForOnlineDisplay: void 0; // UNDOCUMENTED
  // elementForOfflineDisplay: void 0; // UNDOCUMENTED
  // showIcon: void 0; // UNDOCUMENTED
  // linkAction: { feature: void 0, name: void 0, valid: !1 }; // UNDOCUMENTED
  // linkActionParameters: {}; // UNDOCUMENTED
  // useCustomAuthentication: boolean; // UNDOCUMENTED, default !1
  // allowGuestUsers: boolean; // UNDOCUMENTED, default !1
  // requireSLDS: boolean; // UNDOUMENTED, default !1
};

/**
 * Settings that are provided to document.embedded_svc.init() as the last argument
 */
type InitParamsSubConfig = {
  baseLiveAgentContentURL: string;
  deploymentId: string;
  buttonId: string;
  baseLiveAgentURL: string;
  eswLiveAgentDevName: string;
  isOfflineSupportEnabled: boolean;
};

/**
 * Parameters that go to document.embedded_svc.init() function's params
 */
type InitParams = [string, string, string, string, ChatServiceQueueId, InitParamsSubConfig];

/**
 * Our SalesforceChatConfigInterface consists of the main settings and the parameters that are put into the init() call
 * when the chat is booting.
 */
interface SalesforceChatConfigInterface {
  settings: Settings;
  initParams: InitParams;
}

/**
 * Mapping from our langValue (like master-en) to chat's Service queue id
 */
const LOCALE_TO_SERVICE_QUEUE_ID: { [langValue: string]: ChatServiceQueueId } = {
  _DEFAULT: 'Chatbot_EN_SGS',
  _DEFAULT_PREPROD: 'Chatbot_EN',
  'fi-fi': 'RTGS_FINNISH',
  'fi-sv': 'RGTS_SWEDISH', // intentional to use the typoed id in salesforce
  'ca-fr': 'SGS_FRENCH',
  'fr-fr': 'SGS_FRENCH',
  'ch-fr': 'SGS_FRENCH',
  'at-de': 'SGS_GERMAN',
  'de-de': 'SGS_GERMAN',
  'ch-de': 'SGS_GERMAN',
  'it-it': 'SGS_ITALIAN',
  'es-es': 'SGS_SPANISH',
  'cn-zh': 'SGS_CHINESE',
  'kr-ko': 'SGS_KOREAN',
  'jp-ja': 'SGS_JAPANESE',
  'hk-zh': 'SGS_CHINESE_HK',
  'ru-ru': 'SGS_RUSSIAN',
};

/**
 *
 */
export class SalesforceChatConfig implements SalesforceChatConfigInterface {
  gslbBaseURL: string = null;
  serviceQueueId: ChatServiceQueueId;
  settings: Settings = {
    displayHelpButton: false,
    language: '',
    storageDomain: 'finnair.com',
    enabledFeatures: ['LiveAgent'],
    entryFeature: 'LiveAgent',
    loadingText: '...', // Defaults to Loading
    devMode: false,
    extraPrechatInfo: [
      {
        entityFieldMaps: [
          { doCreate: false, doFind: false, fieldName: 'LastName', isExactMatch: true, label: 'Last Name' },
          { doCreate: false, doFind: false, fieldName: 'FirstName', isExactMatch: true, label: 'First Name' },
          { doCreate: false, doFind: true, fieldName: 'Email', isExactMatch: true, label: 'Email' },
        ],
        entityName: 'Contact',
        saveToTranscript: 'Contact',
        showOnCreate: false,
      },
      {
        entityName: 'Account',
        showOnCreate: false,
        saveToTranscript: 'Account',
        entityFieldMaps: [
          { isExactMatch: true, fieldName: 'PersonEmail', doCreate: false, doFind: true, label: 'Email' },
        ],
      },
    ],
    prepopulatedPrechatFields: {},
    extraPrechatFormDetails: [
      {
        label: 'Site Origin',
        value: this.windowRef.nativeWindow.location.href,
        transcriptFields: ['SiteOrigin__c'],
      },
    ],
  };
  initParams: InitParams = [
    null, // baseCoreURL in /cfg/chat.js INIT_PARAMS
    null, // communityEndpointURL in /cfg/chat.js INIT_PARAMS
    null, // gslbBaseURL in /cfg/chat.js INIT_PARAMS
    null, // orgId in /cfg/chat.js INIT_PARAMS
    null, // set based on serviceQueueId
    {
      baseLiveAgentContentURL: null, // baseLiveAgentContentURL in /cfg/chat.js INIT_PARAMS
      deploymentId: null, // deploymentId in /cfg/chat.js INIT_PARAMS
      buttonId: null, // set based on serviceQueueId
      baseLiveAgentURL: null, // baseLiveAgentURL in /cfg/chat.js INIT_PARAMS
      eswLiveAgentDevName: null, // set based on serviceQueueId
      isOfflineSupportEnabled: true,
    },
  ];
  defaultChatLanguage: string = '_DEFAULT';

  constructor(
    private windowRef: WindowRef,
    private csc: ChatServiceConfig
  ) {
    this.initParams[0] = csc.chatInitParamsConfig.baseCoreURL;
    this.initParams[1] = csc.chatInitParamsConfig.communityEndpointURL;
    this.initParams[2] = csc.chatInitParamsConfig.gslbBaseURL;
    this.initParams[3] = csc.chatInitParamsConfig.orgId;
    this.initParams[5].baseLiveAgentContentURL = csc.chatInitParamsConfig.baseLiveAgentContentURL;
    this.initParams[5].deploymentId = csc.chatInitParamsConfig.deploymentId;
    this.initParams[5].baseLiveAgentURL = csc.chatInitParamsConfig.baseLiveAgentURL;

    this.defaultChatLanguage = csc.chatDefaultLanguage;
  }

  /*
   * Given serviceQueueId, set chat UI's language, service queue id, button id and eswLiveAgentDevName.
   */
  setServiceQueueId(serviceQueueId: ChatServiceQueueId) {
    this.serviceQueueId = serviceQueueId;
    if (serviceQueueId !== null) {
      this.settings.language = this.csc.chatServiceQueueConfig[serviceQueueId].language;
      this.initParams[4] = serviceQueueId;
      this.initParams[5].buttonId = this.csc.chatServiceQueueConfig[serviceQueueId].buttonId;
      this.initParams[5].eswLiveAgentDevName = this.csc.chatServiceQueueConfig[serviceQueueId].eswLiveAgentDevName;

      // Enable Ultimate.ai chatbot: Sisu FI (prod, preprod), Sisu EN-FI (prod, preprod) and Sisu EN SGS (prod, preprod)
      const chatbotEnabledChatButtonIds = [
        '5731o000000GsW4',
        '5731q00000000Ns',
        '5731o0000004IiY',
        '5733N000000009w',
        '5731o000000GsWT',
        '5733N00000000Az',
      ];
      if (chatbotEnabledChatButtonIds.includes(this.csc.chatServiceQueueConfig[serviceQueueId].buttonId)) {
        this.enableChatBot();
      } else {
        this.disableChatBot();
      }
    }
  }

  /*
   * Given site lang ("fi-sv"), set serviceQueueId
   */
  setLanguage(langValue: string) {
    if (LOCALE_TO_SERVICE_QUEUE_ID[langValue] !== undefined) {
      this.setServiceQueueId(LOCALE_TO_SERVICE_QUEUE_ID[langValue]);
    } else {
      this.setServiceQueueId(LOCALE_TO_SERVICE_QUEUE_ID[this.defaultChatLanguage]);
    }
  }

  setTargetElement(targetElement: HTMLElement) {
    this.settings.targetElement = targetElement;
  }

  private enableChatBot() {
    this.settings.externalScripts = ['CustomEvents_AddButtons'];
    this.settings.externalStyles = ['CustomEvents_AddButtons_Stylesheet'];
  }

  private disableChatBot() {
    if (this.settings.externalScripts) {
      delete this.settings.externalScripts;
    }
    if (this.settings.externalStyles) {
      delete this.settings.externalStyles;
    }
  }
}

/**
 * Service that provides configurations to boot up the chat. Same instance of the Service
 * can provide multiple different configuration classes.
 * @class SalesforceChatConfigService
 */
@Injectable()
export class SalesforceChatConfigService {
  scriptSrcURL: string;
  /** Configurations specific to CHAT_ENV */
  private chatServiceConfig: ChatServiceConfig;

  constructor(
    private configService: ConfigService,
    private windowRef: WindowRef
  ) {
    this.scriptSrcURL = this.configService.cfg.chatScriptSrcURL;
    this.chatServiceConfig = this.configService.cfg.chat;
  }

  /**
   * returns a new SalesforceChatConfig that can be customized by calling its .setLanguage
   * that calls .setServiceQueueId with the Service Queue Id of that language
   */
  get config(): SalesforceChatConfig {
    return new SalesforceChatConfig(this.windowRef, this.chatServiceConfig);
  }
}
