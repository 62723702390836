<div class="relative" data-testid="table-container" *ngIf="fareFamiliesWithMappedBenefits?.length > 0">
  <fin-scroll-handle-container #scrollContainer [elements]="tableHeaders">
    <table class="align-left">
      <caption class="is-vishidden">
        {{
          'ticketSelection.compareBenefits' | finLocalization
        }}
      </caption>
      <tr>
        <th
          *ngFor="let fareFamily of fareFamiliesWithMappedBenefits; let first = first; let last = last"
          #tableHeader
          scope="col"
          class="nordic-blue-50-bg fare-family-table-header ps-medium-x ps-xsmall-y border-left border-top"
          [ngClass]="{
            'rounded-top-left-large': first && !last,
            'border-right': last,
            'rounded-top-right-large': last && !first,
            'rounded-top-large': last && first,
          }"
        >
          <div class="font-body-1 nordic-blue-900-text" data-testid="brand-name">
            {{ fareFamily.brandName }}
          </div>
        </th>
      </tr>
      <tr *ngFor="let benefitKey of benefitKeys; let lastRow = last; odd as isOdd" data-testid="benefit-row">
        <ng-container
          *ngFor="let fareFamily of fareFamiliesWithMappedBenefits; let firstCell = first; let lastCell = last"
        >
          <td
            *ngIf="fareFamily.benefits[benefitKey]"
            class="ps-medium-x ps-xsmall-y border-left"
            [ngClass]="{
              'nordic-blue-50-bg': isOdd,
              'border-bottom': lastRow,
              'border-right': lastCell,
              'rounded-bottom-large': lastRow && firstCell && lastCell,
              'rounded-bottom-left-large': lastRow && firstCell && !lastCell,
              'rounded-bottom-right-large': lastRow && lastCell && !firstCell,
            }"
          >
            <div class="flex flex--row flex--top">
              <div class="flex-1">
                <div
                  class="nordic-blue-900-text bold-type font-body-2 benefitLabel"
                  data-testid="benefit-label"
                  [class.heather-700-text]="
                    showKoreaNotification && (benefitKey === 'rebook' || benefitKey === 'cancel')
                  "
                >
                  {{ fareFamily.benefits[benefitKey]?.label }}
                </div>
                <div class="nordic-blue-900-text regular-type font-body-2" data-testid="benefit-value">
                  {{ fareFamily.benefits[benefitKey]?.value }}
                </div>
              </div>
              <div class="flex--nogrow" *ngIf="showIcons">
                <fcom-icon
                  class="icon-medium heather-700-text"
                  [dataTestId]="'benefit-icon-' + SvgLibraryIcon.CHECKMARK"
                  [name]="SvgLibraryIcon.CHECKMARK"
                  *ngIf="fareFamily.benefits[benefitKey]?.isPositive"
                ></fcom-icon>
                <fcom-icon
                  class="icon-medium"
                  [dataTestId]="'benefit-icon-' + SvgLibraryIcon.CLOSE_DELETE"
                  [name]="SvgLibraryIcon.CLOSE_DELETE"
                  *ngIf="fareFamily.benefits[benefitKey] && !fareFamily.benefits[benefitKey].isPositive"
                ></fcom-icon>
              </div>
            </div>
            <ng-container *ngIf="fareFamily.benefits[benefitKey]?.children">
              <div
                class="flex flex--row flex--top pr-small-l"
                *ngFor="let childBenefit of fareFamily.benefits[benefitKey].children"
              >
                <div class="flex-1">
                  <div
                    class="nordic-blue-900-text bold-type font-body-2 benefitLabel"
                    data-testid="benefit-label"
                    [class.heather-700-text]="
                      showKoreaNotification && (benefitKey === 'rebook' || benefitKey === 'cancel')
                    "
                  >
                    {{ childBenefit.label }}
                  </div>
                  <div class="nordic-blue-900-text regular-type font-body-2" data-testid="benefit-value">
                    {{ childBenefit.value }}
                  </div>
                </div>
                <div class="flex--nogrow" *ngIf="showIcons">
                  <fcom-icon
                    class="icon-medium heather-700-text"
                    [dataTestId]="'benefit-icon-' + SvgLibraryIcon.CHECKMARK"
                    [name]="SvgLibraryIcon.CHECKMARK"
                    *ngIf="childBenefit.isPositive"
                  ></fcom-icon>
                  <fcom-icon
                    class="icon-medium"
                    [dataTestId]="'benefit-icon-' + SvgLibraryIcon.CLOSE_DELETE"
                    [name]="SvgLibraryIcon.CLOSE_DELETE"
                    *ngIf="!childBenefit.isPositive"
                  ></fcom-icon>
                </div>
              </div>
            </ng-container>
          </td>
        </ng-container>
      </tr>
    </table>
  </fin-scroll-handle-container>
</div>
