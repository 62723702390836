import { ChangeDetectorRef, Injectable, Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { Observable } from 'rxjs';

import { isPresent } from '@fcom/core/utils';
import { CurrencyService } from '@fcom/core';
import { Amount } from '@fcom/dapi';
import { PriceSettings } from '@fcom/core/interfaces/currency.interface';

/**
 * Asynchronous price pipe that supports localizations.
 * Uses currencyService to get price in localized format.
 *
 */
@Injectable()
@Pipe({
  name: 'finPrice',
  pure: false,
})
export class PricePipe implements OnDestroy, PipeTransform {
  private async: AsyncPipe;
  private cached$: Observable<any>;
  private cachedAmount: string;
  private cachedCurrencyCode: string;

  constructor(
    private ref: ChangeDetectorRef,
    private currencyService: CurrencyService
  ) {
    this.async = new AsyncPipe(this.ref);
  }

  transform(amount: Amount, settings?: PriceSettings): string {
    if (!isPresent(amount)) {
      return undefined;
    }
    if (this.valueChanged(amount)) {
      this.updatePrice(amount, settings);
    }

    return this.async.transform(this.cached$);
  }

  ngOnDestroy(): void {
    this.async.ngOnDestroy();
  }

  private valueChanged(amount: Amount): boolean {
    return !this.cached$ || amount.amount !== this.cachedAmount || amount.currencyCode !== this.cachedCurrencyCode;
  }

  private updatePrice(amount: Amount, settings?: PriceSettings): void {
    this.cached$ = this.currencyService.formatPrice(amount, settings);
    this.cachedAmount = amount.amount;
    this.cachedCurrencyCode = amount.currencyCode;
  }
}
