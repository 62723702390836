import { Component, Input, Output, EventEmitter } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

@Component({
  selector: 'fcom-block-selection-group',
  templateUrl: './block-selection-group.component.html',
  styleUrls: ['./block-selection-group.component.scss'],
})
export class BlockSelectionGroupComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  name = '';
  @Input()
  heading: string;
  @Input()
  headingInfo: string;
  @Input()
  hiddenText: string;

  @Output()
  headingInfoClick: EventEmitter<MouseEvent> = new EventEmitter();
}
