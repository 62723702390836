<div class="grow flex flex--center align-center">
  <div class="absolute inner-circle-content">
    <ng-content></ng-content>
  </div>
  <svg viewBox="0 0 42 42" class="donut">
    <circle
      class="donut-ring"
      cx="21"
      cy="21"
      r="15.91549430918954"
      fill="transparent"
      stroke="transparent"
      stroke-width="2"
    ></circle>

    <circle
      *ngFor="let step of steps"
      class="donut-segment"
      [class.donut-segment--blinking]="step.state === StepProgressState.ACTIVE"
      [class.donut-segment--success]="step.state === StepProgressState.COMPLETED"
      [class.donut-segment--danger]="step.state === StepProgressState.ERROR"
      cx="21"
      cy="21"
      r="15.91549430918954"
      fill="transparent"
      stroke="currentColor"
      stroke-width="2"
      [attr.stroke-dasharray]="segmentStrokeDasharray"
      [attr.stroke-dashoffset]="step.strokeDashOffset"
    ></circle>
  </svg>
</div>
