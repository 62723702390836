import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { CheapestPriceForAllDestinationsService } from './cheapest-price/cheapest-price-for-all-destinations.service';

// Exports these shared services in one shared module. For importing on both levels: cms module and it's child modules.
@NgModule({
  imports: [CommonModule],
  declarations: [],
  exports: [],
})
export class ContentServicesModule {
  static forRoot(): ModuleWithProviders<ContentServicesModule> {
    return {
      ngModule: ContentServicesModule,
      providers: [CheapestPriceForAllDestinationsService],
    };
  }
}
