import { ChangeDetectorRef, Injectable, Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { AsyncPipe } from '@angular/common';

import { Observable, of } from 'rxjs';

import { isPresent } from '@fcom/core/utils';
import { CurrencyService } from '@fcom/core';

/**
 * Asynchronous price pipe that supports localizations.
 * Uses currencyService to get points in localized format.
 *
 */
@Injectable()
@Pipe({
  name: 'finPoints',
  pure: false,
})
export class PointsPipe implements OnDestroy, PipeTransform {
  private async: AsyncPipe;
  private cached$: Observable<string>;
  private cachedAmount: number;

  constructor(
    private ref: ChangeDetectorRef,
    private currencyService: CurrencyService
  ) {
    this.async = new AsyncPipe(this.ref);
  }

  transform(amount: number | string, abbreviate?: boolean): string {
    if (!isPresent(amount)) {
      return undefined;
    }
    if (this.valueChanged(amount)) {
      this.updatePoints(amount, abbreviate);
    }

    return this.async.transform(this.cached$);
  }

  ngOnDestroy(): void {
    this.async.ngOnDestroy();
  }

  private valueChanged(amount: number | string): boolean {
    return !this.cached$ || amount !== this.cachedAmount;
  }

  private updatePoints(amount: number | string, abbreviate = false): void {
    if (typeof amount === 'string') {
      const numberAmount = parseFloat(amount);
      this.cachedAmount = numberAmount;
      this.cached$ = amount === '0' ? of('0') : this.currencyService.formatPoints(numberAmount, abbreviate);
    }
    if (typeof amount === 'number') {
      this.cachedAmount = amount;
      this.cached$ = amount === 0 ? of('0') : this.currencyService.formatPoints(amount, abbreviate);
    }
  }
}
