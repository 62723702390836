<div class="video-wrapper" [class.mr-xsmall-y]="!noMargin">
  <button
    [class.is-vishidden]="(previewVisible$ | async) ? null : true"
    [attr.disabled]="(previewVisible$ | async) ? null : true"
    (click)="playVideo()"
    title="{{ 'playVideo' | finLocalization }}"
    class="preview"
  >
    <fin-block-image [imageData]="youtubePreviewPicture$ | async" [overlay]="true"></fin-block-image>
    <fcom-icon [name]="SvgLibraryIcon.YOUTUBE" class="icon-xxlarge preview__play"></fcom-icon>
    <span class="is-vishidden" *ngIf="videoTitle$">{{ videoTitle$ | async }}</span>
  </button>
  <fcom-modal
    *ngIf="showPopup; else iframe"
    [open]="iframeVisible$ | async"
    [buttons]="ModalButtons.CLOSE"
    (close)="iframeVisible$.next(false)"
  >
    <ng-container [ngTemplateOutlet]="iframe"></ng-container>
  </fcom-modal>

  <ng-template #iframe>
    <iframe
      *ngIf="iframeVisible$ | async"
      #videoIframe
      width="854"
      height="480"
      [src]="videoFinalUrl$ | async"
      frameborder="0"
      [attr.title]="videoTitle$ ? (videoTitle$ | async) : null"
      allow="autoplay; fullscreen"
    ></iframe>
  </ng-template>
</div>
