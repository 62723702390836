import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'finTimestamp',
  pure: false, // Depends on date
})
export class TimestampPipe implements PipeTransform {
  transform(timestamp: any): string {
    return formatDate(timestamp, 'dd.MM.yyyy', 'en-FI');
  }
}
