import { Params, Router } from '@angular/router';

import { ERROR_CTA_ACTIONS } from '@fcom/common/components';
import { RootPaths } from '@fcom/core/constants';
import { isInMmb } from '@fcom/common-booking/utils/common-booking.utils';

const defaultQueryParams: Params = {
  cta: 'errors.booking.cta',
  info: 'errors.booking.description',
  title: 'sorry',
  subtitle: 'errors.booking.title',
  ctaAction: ERROR_CTA_ACTIONS.NAVIGATE_TO_BOOKING,
};

const defaultQueryParamsInMmb: Params = {
  cta: 'errors.booking.cta',
  info: 'errors.booking.description',
  title: 'sorry',
  subtitle: 'errors.booking.title',
  ctaAction: ERROR_CTA_ACTIONS.NAVIGATE_TO_MANAGE_BOOKING_VIEW_PNR,
  showButton: true,
  noGrid: true,
};

export const navigateToError = (router: Router, lang: string, error: string, queryParams: Params = {}): void => {
  const inMmb = isInMmb(router.url);
  router.navigate([lang, inMmb ? RootPaths.MANAGE_BOOKING_ROOT : RootPaths.BOOKING_ROOT, 'error', error], {
    skipLocationChange: true,
    queryParams: { ...(inMmb ? defaultQueryParamsInMmb : defaultQueryParams), ...queryParams },
  });
};
