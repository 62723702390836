import { BoundType } from '@fcom/dapi/interfaces';

export enum DataCloudFlightSelection {
  AIRCRAFT = 'aircraft',
  BOUND_TYPE = 'boundType',
  CURRENCY_CODE = 'currencyCode',
  FARE_FAMILY_NAME = 'fareFamilyName',
  FLIGHT_NUMBER = 'flightNumber',
  PAX_AMOUNT = 'paxAmount',
  POINTS = 'points',
  PRICE = 'price',
  PURCHASE_FLOW = 'purchaseFlow',
  ROUTE = 'route',
  TRAVEL_CLASS = 'travelClass',
}

export interface DataCloudFlightSelectionData {
  [DataCloudFlightSelection.AIRCRAFT]: string;
  [DataCloudFlightSelection.BOUND_TYPE]: BoundType;
  [DataCloudFlightSelection.CURRENCY_CODE]: string;
  [DataCloudFlightSelection.FARE_FAMILY_NAME]: string;
  [DataCloudFlightSelection.FLIGHT_NUMBER]: string;
  [DataCloudFlightSelection.PAX_AMOUNT]: string;
  [DataCloudFlightSelection.POINTS]: string;
  [DataCloudFlightSelection.PRICE]: string;
  [DataCloudFlightSelection.PURCHASE_FLOW]: string;
  [DataCloudFlightSelection.ROUTE]: string;
  [DataCloudFlightSelection.TRAVEL_CLASS]: string;
}

export interface DataCloudIdentityData {
  firstName: string;
  lastName: string;
  emailAddress: string;
  countryCode: string;
  phoneNumber: string;
  memberProgram: string;
  memberNumber: string;
}
