import { OfferList } from '@fcom/dapi/api/models';
import { SortBy } from '@fcom/common-booking';
import { FlightFilters } from '@fcom/common-booking/interfaces/flight-filters.interface';

import { OfferListFetchParams } from '..';

export enum AirOffersStatus {
  /** There are no offers to show and no request has been fired. */
  INITIAL,

  /** A request for loading the air offers has been initiated, but not yet completed */
  PENDING,

  /** The request for air offers was successful. */
  OK,

  /** The request for air offers resulted in error */
  ERROR,

  /** The input for air offers request contains invalid values */
  INVALID_INPUT,

  /** No flights or available seats were found for the given input criteria */
  NO_FLIGHTS_FOUND,
}

// Create a mapping from enum error offers statuses to text status
export const AirOffersStatusToText: Partial<{ [key in AirOffersStatus]: string }> = {
  [AirOffersStatus.ERROR]: 'General Error',
  [AirOffersStatus.INVALID_INPUT]: 'Invalid Input',
  [AirOffersStatus.NO_FLIGHTS_FOUND]: 'No flights found',
};

export interface AirOffersState {
  /**
   * The current status of the air offers data.
   */
  status: AirOffersStatus;

  /**
   * The full response of offers from the Offer API
   */
  currentOffers: OfferList;

  /** Last used params for query */
  lastRequestParams?: OfferListFetchParams;

  /**
   * Selected id of the outbound flight.
   * Undefined if not selected.
   */
  selectedOutboundId?: string;

  /**
   * The fare family code of the selected outbound direction.
   * Undefined if not selected.
   */
  selectedOutboundFareFamilyCode?: string;

  /**
   * The id of the selected inbound direction.
   * Undefined if not selected.
   */
  selectedInboundId?: string;

  /**
   * The fare family code of the selected inbound direction.
   * Undefined if not selected.
   */
  selectedInboundFareFamilyCode?: string;

  /** Saved ticket sort by. Kept on reload. */
  sortBy: SortBy;

  /** Filters that will be applied in bounds */
  filters: FlightFilters;
}
