import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { LanguageService } from '@fcom/ui-translate';
import { combineTemplate } from '@fcom/rx';
import { sortBy, uniqueBy } from '@fcom/core/utils';
import { SelectOption } from '@fcom/common/interfaces';

export interface TranslatedCountryCode {
  countryCode: string;
  firstOption: string;
  name: string;
  phonePrefix: string;
}

const countryCodeToOption = (cc: TranslatedCountryCode): SelectOption =>
  cc && {
    name: `${cc.name} (+${cc.phonePrefix})`,
    value: `${cc.countryCode}|${cc.phonePrefix}`,
  };

const matchingCountryCode =
  (countryCode = '') =>
  (cc: TranslatedCountryCode) =>
    countryCode.toLowerCase() === (cc.countryCode || '').toLowerCase();

@Injectable()
export class PhonePrefixService {
  SEPARATOR: SelectOption = {
    name: '-------------------',
    value: '-------------------',
    disabled: true,
  };
  DEFAULT: SelectOption;

  constructor(private languageService: LanguageService) {}

  setupPhonePrefixes(showDefault = true): Observable<SelectOption[]> {
    return combineTemplate({
      countryCodes: this.languageService.translate('countryCodes'),
      defaultOption: this.languageService.translate('selectPhonePrefix'),
      activeCountry: this.languageService.countryCode,
    }).pipe(
      map(
        ({
          countryCodes,
          defaultOption,
          activeCountry,
        }: {
          countryCodes: TranslatedCountryCode[];
          defaultOption: string;
          activeCountry: string;
        }) => {
          this.DEFAULT = {
            name: defaultOption,
            value: null,
          };
          const baseOptions = sortBy(countryCodes.map(countryCodeToOption), (so) => so.name);
          const optionsBasedOnLocations = [countryCodeToOption(countryCodes.find(matchingCountryCode(activeCountry)))]
            .filter(Boolean)
            .filter(uniqueBy((country) => country['value']));
          const options = [...optionsBasedOnLocations, this.SEPARATOR, ...baseOptions];
          return optionsBasedOnLocations.length === 0
            ? baseOptions
            : showDefault
              ? [this.DEFAULT, ...options]
              : options;
        }
      )
    );
  }

  getCountryCodeFromPhonePrefix(phonePrefix: number): Observable<string> {
    return this.languageService.translate('countryCodes').pipe(
      map((countryCodes) => {
        return (
          countryCodes.find((countryCode) => countryCode.phonePrefix === phonePrefix?.toString())?.countryCode ?? ''
        );
      })
    );
  }
}
