import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from '@fcom/common/pipes';

import { PromoCardComponent } from './promo-card.component';
import { IconsModule } from '../icons/icons.module';
import { ButtonModule } from '../buttons/button.module';
import { ImagesModule } from '../images/images.module';

@NgModule({
  declarations: [PromoCardComponent],
  imports: [CommonModule, IconsModule, ButtonModule, ImagesModule, PipesModule],
  providers: [],
  exports: [PromoCardComponent],
})
export class PromoCardModule {}
