import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { LanguageService } from '@fcom/ui-translate';
import { CmsDataService, SentryLogger } from '@fcom/core/';
import { finShare } from '@fcom/rx';
import { recursiveParse } from '@fcom/cms/services/cms-utils/cms-utils';
import { CmsCollection, CmsContent } from '@fcom/core-api/interfaces';

@Injectable()
export class FragmentService {
  constructor(
    private languageService: LanguageService,
    private cmsDataService: CmsDataService,
    private sentryLogger: SentryLogger
  ) {}

  getFragment<T>(fragmentKey: string, extraParams?: { [key: string]: string }): Observable<T> {
    return this.languageService.translate(fragmentKey).pipe(
      filter(Boolean),
      switchMap((url) => this.cmsDataService.getFragmentJson<T>(url, extraParams)),
      map((data) => {
        if (data['contentId']) {
          return recursiveParse(data as CmsContent | CmsCollection, this.sentryLogger) as T;
        }
        return data;
      }),
      finShare()
    );
  }
}
