<h1 *ngIf="content.teaserTitle" class="article-title nordic-blue-900-text mr-small-b ms-clear-t font-heading-2">
  {{ content.teaserTitle }}
</h1>
<div
  class="article-lead nordic-blue-900-text bold-type ms-large-b"
  [innerHTML]="content.teaserText | finSafe: 'html'"
></div>
<figure *ngFor="let contentImage of content.media" class="hero-image ms-clear-x ms-clear-t ms-small-b">
  <fin-content-image class="block image-height" [imageData$]="contentImage | finToObservable"></fin-content-image>
  <figcaption
    *ngIf="contentImage.teaserText"
    [innerHTML]="contentImage.teaserText | finSafe: 'html'"
    class="hero-image-caption font-body-2 light-type nordic-blue-900-text ms-xxsmall-t ms-large-b"
  ></figcaption>
</figure>
<div class="flex">
  <div class="">
    <fin-author class="block" *ngFor="let author of content.authors" [author]="author.htmlTitle"></fin-author>

    <ng-container
      *ngIf="content.subjectTaxonomyTags?.includes(CmsTaxonomyGeneral.TRAVEL_UPDATE_UPDATED); else defaultDateRender"
    >
      <fin-date
        class="block"
        [text]="'shared.published' | finLocalization"
        [date]="content.localSettings?.firstPublished ? content.localSettings.firstPublished : content.creationDate"
      ></fin-date>
      <fin-date
        class="block"
        [text]="'shared.updated' | finLocalization"
        [date]="content.externallyDisplayedDate"
      ></fin-date>
    </ng-container>

    <ng-template #defaultDateRender>
      <fin-date
        *ngIf="content.localSettings?.firstPublished"
        class="block"
        [text]="'shared.published' | finLocalization"
        [date]="content.localSettings.firstPublished"
      ></fin-date>
      <fin-date
        class="block"
        [text]="content.localSettings?.firstPublished ? ('shared.updated' | finLocalization) : null"
        [date]="content.externallyDisplayedDate"
      ></fin-date>
    </ng-template>
  </div>
  <div class="flex flex-1 flex--end">
    <fin-social [url]="content.url" [title]="content.teaserTitle"></fin-social>
  </div>
</div>
<div class="ms-large-t article-markup ms-large-b" [innerHTML]="content.detailText | finSafe: 'html'"></div>
<fin-detail-text
  *ngFor="let detailTextItem of content.detailTextItems"
  [mediaObject]="detailTextItem.media"
  [markup]="detailTextItem.markup"
></fin-detail-text>
