<ng-container *ngIf="content$ | async as content">
  <div *ngIf="content.items.length > 0" [ngClass]="content.subjectTaxonomyTags">
    <div [ngClass]="{ 'grid-col-12 grid-max-width no-row-gap': useGrid }">
      <div class="col-1-12 md-col-2-11">
        <div [class]="sectionWrapperClass">
          <h2
            data-testid="collection-teaser-title"
            *ngIf="!disableTeaserTitle && content.teaserTitle !== ''"
            [ngClass]="content.subjectTaxonomyTags"
          >
            {{ content.teaserTitle }}
          </h2>
          <div [ngClass]="{ 'grid-col-12 grid-max-width no-row-gap no-gutters': useGrid }">
            <div *ngFor="let item of content.items" [class]="sectionClass">
              <fin-video
                *ngIf="item.contentType === CmsContentType.CMVideo"
                [ngClass]="'white-text ' + content.subjectTaxonomyTags.join(' ')"
                [videoTitle$]="item.teaserTitle | finToObservable"
                [videoUrl$]="item.dataUrl | finToObservable"
                [showPopup]="item.viewType === CmsViewType.POPUP"
                [noMargin]="true"
              ></fin-video>

              <ng-container *ngIf="item.contentType === CmsContentType.CMPicture">
                <div
                  data-testid="collection-image"
                  class="image-wrapper relative flex border rounded-medium overflow-hidden"
                  *ngIf="item.picture as imageData"
                >
                  <fin-content-image
                    [imageData$]="imageData | finToObservable"
                    [aspectRatio$]="aspectRatio$"
                  ></fin-content-image>
                </div>
              </ng-container>

              <cms-rich-text
                *ngIf="item.contentType === CmsContentType.CMArticle"
                [attr.data-testid]="'item-teaser-title-' + item.contentId"
                [extraClasses]="'font-body-2 mr-small-b ' + item.subjectTaxonomyTags.join(' ')"
                [headerLevel]="content.teaserTitle !== '' ? 3 : 2"
                [headerStyles]="content.teaserTitle !== '' ? [] : ['font-heading-3']"
                [scaleImage]="false"
                [content$]="item | finToObservable"
              >
              </cms-rich-text>

              <ng-container *ngIf="item.related?.length">
                <div *ngFor="let link of item.related" class="font-body-1 bold-type flex flex--start">
                  <a
                    [finLink]="link.target.url"
                    [external]="link.target.external"
                    [attr.target]="link.target.openInNewTab ? '_blank' : ''"
                    [ngClass]="link.subjectTaxonomyTags"
                    class="descriptive-link no-decoration flex flex--center"
                    ><span class="pr-xxsmall-r" data-testid="collection-teaser-link-title">{{ link.teaserTitle }}</span
                    ><fcom-icon
                      class="icon-medium"
                      [ngClass]="link.subjectTaxonomyTags"
                      [name]="SvgLibraryIcon.ARROW_RIGHT"
                    ></fcom-icon
                  ></a>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
