import { createSelector, MemoizedSelector } from '@ngrx/store';

import { BookingAppState, BookingFeatureState } from '@fcom/common/interfaces/booking/booking-state.interface';

/**
 * Temporary selector for getting the booking feature root state
 * @type {MemoizedSelector<BookingAppState, any> | MemoizedSelector<any, any>}
 */
export const bookingFeatureState: MemoizedSelector<BookingAppState, BookingFeatureState> = createSelector(
  (appState: BookingAppState) => appState as BookingFeatureState,
  (a) => a
);
