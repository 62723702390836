<div class="relative">
  <ng-container *ngIf="target$ | async as target; else imageContainer">
    <a [finLink]="target.url" [external]="target.external" [openInNewTab]="target.openInNewTab" class="no-underline">
      <fin-text-on-image-no-link
        [imageData$]="imageData$"
        [teaserTitle$]="teaserTitle$"
        [teaserText$]="teaserText$"
        [imageAspectRatio$]="imageAspectRatio$"
      ></fin-text-on-image-no-link>
      <span data-testid="vishidden-element" *ngIf="target.openInNewTab" class="is-vishidden"
        >({{ 'ariaLabels.newTab' | finLocalization }})</span
      >
    </a>
  </ng-container>
</div>

<ng-template #imageContainer>
  <fin-text-on-image-no-link
    [imageData$]="imageData$"
    [teaserTitle$]="teaserTitle$"
    [teaserText$]="teaserText$"
    [imageAspectRatio$]="imageAspectRatio$"
  ></fin-text-on-image-no-link>
</ng-template>
