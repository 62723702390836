import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';

@Injectable()
export class FormPostService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  /**
   * Send the given data to the given url as traditional form post request, not XHR.
   *
   * NOTE: Used only in the browser after user interaction, will not work on server
   */
  post(url: string, data: any, method = 'POST', target = '_self'): void {
    // Construct form for POSTing data
    const form: HTMLFormElement = this.document.createElement('form');
    form.method = method;
    form.target = target;
    form.action = url;

    Object.keys(data).forEach((key) => {
      const value = data[key];
      const input: HTMLInputElement = this.document.createElement('input');
      form.appendChild(input);
      input.type = 'hidden';
      input.name = key;
      input.value = value;
    });

    this.document.body.appendChild(form);
    form.submit();
  }
}
