import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { ConfigService, NativeBridgeService, StorageService } from '../services';

const containsUrl = (urlsToCheck: string[] = [], url: string): boolean =>
  urlsToCheck.some((urlToCheck: string) => url.includes(urlToCheck));

const CLIENT_ID_KEY = 'X-Client-Id';
const SESSION_ID_KEY = 'X-Session-Id';

@Injectable({
  providedIn: 'root',
})
export class DapiHeadersInterceptor implements HttpInterceptor {
  private clientId: string;

  constructor(
    private configService: ConfigService,
    private storageService: StorageService,
    private nativeBridgeService: NativeBridgeService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!containsUrl(this.configService.useDapiUATForUrls, req.url)) {
      return next.handle(req);
    }

    if (!this.storageService.SESSION.get(SESSION_ID_KEY)) {
      this.storageService.SESSION.set(SESSION_ID_KEY, uuid());
    }

    if (!this.clientId) {
      this.clientId = this.getClientId();
    }

    const request: HttpRequest<unknown> = req.clone({
      headers: req.headers
        .set(CLIENT_ID_KEY, this.clientId)
        .set(SESSION_ID_KEY, this.storageService.SESSION.get(SESSION_ID_KEY) ?? ''),
    });
    return next.handle(request);
  }

  private getClientId(): string {
    if (this.nativeBridgeService.isInsideNativeAndroidWebView) {
      return 'MOBILEAPP_ANDROID';
    }
    if (this.nativeBridgeService.isInsideNativeiOSWebView) {
      return 'MOBILEAPP_IOS';
    }

    return 'FCOM';
  }
}
