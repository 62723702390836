<div class="teaser-item">
  <a
    class="relative teaser-link shadow-small interactions-tile fill flex flex--column no-underline rounded-large overflow-hidden"
    (click)="emitClick()"
    [finLink]="content.url"
  >
    <div class="teaser-image">
      <fin-block-image
        *ngFor="let teaserImage of getPics(content.media)"
        [aspectRatio]="aspectRatio"
        [imageData]="teaserImage"
      ></fin-block-image>
    </div>
    <div class="fill white-bg ps-large-y ps-medium-x teaser-text-content flex flex--column flex-1">
      <h3 class="teaser-title font-body-1 ms-clear-t nordic-blue-900-text" *ngIf="content.teaserTitle">
        {{ content.teaserTitle }}
      </h3>
      <fin-date
        class="flex flex--align-end teaser-date"
        [ngClass]="{
          'flex--end': !alignLeft,
        }"
        [date]="content.externallyDisplayedDate"
      ></fin-date>
    </div>
  </a>
</div>
