import { createReducer, on } from '@ngrx/store';

import { PseudoLocalState } from '../interfaces';
import { PseudoLocalActions } from '../actions';

export const initialState: PseudoLocalState = Object.seal({});

const pseudoLocalReducer = createReducer(
  initialState,
  /**
   * Sets the given value to key
   */
  on(PseudoLocalActions.setValue, (state, { key, data }) => ({
    ...state,
    [key]: data,
  })),

  /**
   * Destroys the data for given key
   */
  on(PseudoLocalActions.destroyValue, (state, { key }) => {
    const { [key]: _deleted, ...newState } = state;
    return newState;
  })
);

export function reducer(state: PseudoLocalState, action: PseudoLocalActions.PseudoLocalActionsUnion): PseudoLocalState {
  return pseudoLocalReducer(state, action);
}
