import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

/**
 * @deprecated Use the new `RadioItemGroupComponent` for simple radio lists.
 * For nested elements, use the old `RadioListComponent`, which uses this
 * component to generate the radio options.
 */
@Component({
  selector: 'fin-form-input-radio',
  styleUrls: ['./form-input-radio.component.scss'],
  templateUrl: 'form-input-radio.component.html',
})
export class FormInputRadioComponent implements OnInit {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  controlName: string;

  @Input()
  translationPrefix: string;

  @Input()
  radioKey: string;

  @Input()
  isSensitive = false;

  @Input()
  description = false;

  @Input()
  descriptionTitle: string;

  @Input()
  descriptionText: string;

  @Input()
  displayContainerStyles = true;

  control: AbstractControl;

  ngOnInit(): void {
    this.control = this.parentForm.get(this.controlName);
  }
}
