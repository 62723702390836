import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { isPlatformServer } from '@angular/common';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { ModalButtons } from '@fcom/ui-components';
import { LanguageService } from '@fcom/ui-translate';
import { equals, unsubscribe } from '@fcom/core/utils';

import { ElementActions, ElementTypes, GaContext } from '../../interfaces';
import { GtmService } from '../../gtm';
import { MediaQueryService } from '../../services';
import {
  getQualtricsUrl,
  messageAllowedForSurvey,
  QUALTRICS_DOMAIN,
  QualtricsMessageTypes,
  QualtricsSurvey,
} from '../qualtrics.utils';

interface QualtricsPostMessage {
  bubbles: boolean;
  cancelBubble: boolean;
  cancealable: boolean;
  composed: boolean;
  currentTarget: any;
  data: any;
  defaultPrevented: boolean;
  eventPhase: number;
  isTrusted?: boolean;
  lastEventId?: string;
  message?: string;
  origin: string;
  originalTarget?: any;
  path: Array<any>;
  ports: Array<any>;
  returnValue: boolean;
  source: any;
  srcElement: any;
  target: any;
  timestamp: number;
  type: string;
  userActivation?: null;
}

@Component({
  selector: 'fin-qualtrics',
  templateUrl: './qualtrics.component.html',
  styleUrls: ['./qualtrics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QualtricsComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  survey: QualtricsSurvey = { name: '', url: '' };

  @Input()
  set open(open) {
    if (open) {
      this.openModal();
    }
  }

  @Output()
  openChange: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('surveyContainer') iframe;

  readonly ModalButtons = ModalButtons;
  readonly ElementTypes = ElementTypes;
  readonly ElementActions = ElementActions;

  qualtricsUrl$: Observable<string>;
  removeGlobalListener: Function;
  previousEvent: any = {};
  modalOpen = false;
  private survey$: BehaviorSubject<QualtricsSurvey>;
  private subscription = new Subscription();

  constructor(
    private languageService: LanguageService,
    private mediaQueryService: MediaQueryService,
    private gtmService: GtmService,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  ngOnInit(): void {
    this.survey$ = new BehaviorSubject<QualtricsSurvey>(this.survey);
    this.qualtricsUrl$ = this.survey$.pipe(
      filter(Boolean),
      distinctUntilChanged((a, b) => a.name === b.name && a.url === b.url),
      switchMap((survey) => this.languageService.translate(survey.url)),
      filter(Boolean),
      map((url) => getQualtricsUrl(this.languageService.langKeyValue, this.languageService.langValue, url))
    );
    this.watchPostMessages();

    this.subscription.add(
      this.mediaQueryService.getResizeEvent$().subscribe(() => {
        this.adjustIframeHeight();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['survey'] && !changes['survey'].firstChange) {
      this.survey$.next(changes['survey'].currentValue);
    }
  }

  ngOnDestroy(): void {
    if (this.removeGlobalListener) {
      this.removeGlobalListener();
    }
    unsubscribe(this.subscription);
  }

  openModal(): void {
    this.modalOpen = true;

    this.gtmService.trackElement(
      this.survey.name,
      GaContext.QUALTRICS,
      ElementTypes.SURVEY,
      undefined,
      ElementActions.OPEN
    );
  }

  onClose(): void {
    this.modalOpen = false;

    this.openChange.emit(false);

    this.gtmService.trackElement(
      this.survey.name,
      GaContext.QUALTRICS,
      ElementTypes.SURVEY,
      undefined,
      ElementActions.CLOSE
    );
  }

  surveyLoaded(): void {
    this.adjustIframeHeight();
  }

  private adjustIframeHeight(): void {
    const parentHeight: number = this.iframe?.nativeElement.parentElement?.offsetHeight;
    if (!parentHeight) {
      return;
    }
    const margin = 8;
    this.iframe.nativeElement.style.minHeight = 'auto';
    this.iframe.nativeElement.style.height = `${parentHeight - margin}px`;
  }

  private watchPostMessages() {
    if (isPlatformServer(this.platform)) {
      return;
    }

    this.removeGlobalListener = this.renderer.listen('window', 'message', (event) => {
      this.processMessage(event);
    });
  }

  private processMessage(event: QualtricsPostMessage) {
    if (event.origin !== QUALTRICS_DOMAIN) {
      return;
    }

    const key = event.message ? 'message' : 'data';
    const data = event[key];
    if (!data) {
      return;
    }
    const currentMessage = Object.keys(data)[0];

    if (
      this.messageHasRealValue(currentMessage) &&
      !equals(this.previousEvent, event.data) &&
      messageAllowedForSurvey(this.survey.name, currentMessage)
    ) {
      this.previousEvent = event.data; // Sometimes qualtrics sends duplicate messages, so we filter those out by saving the previous one

      switch (currentMessage) {
        case QualtricsMessageTypes.OPTIN_RESPONSE:
          this.gtmService.trackElement(
            `${event.data.optInResponse.surveyName}-${event.data.optInResponse.result}`,
            GaContext.QUALTRICS,
            ElementTypes.SURVEY,
            `${event.data.optInResponse.result}`,
            ElementActions.CLICK
          );
          break;
        case QualtricsMessageTypes.NPS_RATING_RESPONSE:
          this.gtmService.trackElement(
            `${event.data.npsRatingResponse.surveyName}-${event.data.npsRatingResponse.result}`,
            GaContext.QUALTRICS,
            ElementTypes.SURVEY,
            `${event.data.npsRatingResponse.result}`,
            ElementActions.CLICK
          );
          break;
        case QualtricsMessageTypes.SURVEY_DONE:
          this.gtmService.trackElement(
            `${event.data.surveyDone}`,
            GaContext.QUALTRICS,
            ElementTypes.SURVEY,
            undefined,
            ElementActions.SUBMIT
          );
          break;
        case QualtricsMessageTypes.CONFSURVEY_DONE:
          this.gtmService.trackElement(
            `${event.data.confSurveyDone}`,
            GaContext.QUALTRICS,
            ElementTypes.SURVEY,
            undefined,
            ElementActions.SUBMIT
          );
          break;
        default:
          break;
      }
    }
  }

  private messageHasRealValue(messageValue: string): boolean {
    return (
      messageValue.indexOf(QualtricsMessageTypes.OPTIN_RESPONSE) > -1 ||
      messageValue.indexOf(QualtricsMessageTypes.NPS_RATING_RESPONSE) > -1 ||
      messageValue.indexOf(QualtricsMessageTypes.SURVEY_DONE) > -1 ||
      messageValue.indexOf(QualtricsMessageTypes.CONFSURVEY_DONE) > -1
    );
  }
}
