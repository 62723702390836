@if (showFeedback && (consentGiven$ | async)) {
  <div class="grid-col-12 grid-max-width qualtrics-container" data-testid="qualtrics-page-feedback">
    <div [id]="surveyName() + '-container'" [class]="surveyName() + ' col-1-12 flex'" [ngClass]="classByPageType()">
      <iframe
        #pageFeedbackContainer
        [ngClass]="surveyName() + '-iframe'"
        [title]="surveyName()"
        [src]="qualtricsUrl$ | async | finSafe: 'resource'"
      ></iframe>
    </div>
  </div>
}
