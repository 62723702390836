import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'fin-greeting-card',
  templateUrl: './greeting-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GreetingCardComponent {
  @Input() firstName: string;
}
