import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';
import { Observable, BehaviorSubject, NEVER, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { switchMap, map, filter, tap, withLatestFrom } from 'rxjs/operators';

import { ButtonTheme, ButtonMode } from '@fcom/ui-components';
import { LanguageService } from '@fcom/ui-translate';
import { CmsTeaser } from '@fcom/core-api';
import { AppState } from '@fcom/core/interfaces';
import { pseudoLocal } from '@fcom/core/selectors';
import { CmsDataService } from '@fcom/core/services';
import { finShare } from '@fcom/rx';
import { ConfigService, unsubscribe } from '@fcom/core';

import { Consent, ConsentData, ConsentGroup, ConsentStatus, ConsentTextId } from '../../interfaces';
import { GtmService } from '../../gtm/';
import { ConsentService } from '../../login';

@Component({
  selector: 'fin-cookie-policy',
  styleUrls: ['./cookie-policy.component.scss'],
  templateUrl: './cookie-policy.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiePolicyComponent implements OnInit, OnDestroy {
  readonly ButtonTheme = ButtonTheme;
  readonly ButtonMode = ButtonMode;
  readonly ConsentStatus = ConsentStatus;
  readonly SvgLibraryIcon = SvgLibraryIcon;

  cookiePolicy$: Observable<CmsTeaser>;
  consents$: Observable<Consent[]>;
  continueShows$: Observable<boolean>;
  modalOpen = false;
  isShowCookieSettings = false;
  quickSetCookieClicked$: BehaviorSubject<ConsentStatus | null> = new BehaviorSubject<ConsentStatus | null>(null);

  private hasCookieConsentSubmitted$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private subscription = new Subscription();

  constructor(
    private consentService: ConsentService,
    private languageService: LanguageService,
    private cmsDataService: CmsDataService,
    private gtmService: GtmService,
    private store$: Store<AppState>,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.continueShows$ = this.store$.pipe(pseudoLocal('continueShows'));
    this.consentService.injectCookieSnippet();

    this.consents$ = this.consentService.cookieConsents$.pipe(
      map((cg) => {
        return [
          {
            consentTextId: ConsentTextId.NOT_REAL_CONSENT_VALUE,
            effectiveFrom: -1,
            text: cg.introductionText,
            textHash: '',
            consentStatus: ConsentStatus.WITHDRAWN,
          },
        ].concat(cg.consents);
      }),
      finShare()
    );

    this.cookiePolicy$ = this.consentService.cookieConsents$.pipe(
      filter((cg) => this.configService.cfg.enableCookiePolicy && !this.allConsentsAreSet(cg)),
      tap(() => {
        this.modalOpen = true;
      }),
      switchMap(() => this.languageService.translate('fragments.cookiePolicy.url')),
      switchMap((url) => (!url ? NEVER : this.cmsDataService.getFragmentJson(url))),
      finShare()
    ) as Observable<CmsTeaser>;

    this.subscription.add(
      this.consentService.cookieConsents$
        .pipe(
          withLatestFrom(this.hasCookieConsentSubmitted$),
          filter(([cg, hasSubmitted]) => this.allConsentsAreSet(cg) && !hasSubmitted),
          map(([cg]) => this.createConsentData(cg)),
          finShare()
        )
        .subscribe((consentData: ConsentData) => {
          this.gtmService.setConsents(consentData);
        })
    );

    this.subscription.add(
      this.quickSetCookieClicked$
        .pipe(
          filter(Boolean),
          switchMap((type: ConsentStatus) =>
            this.consentService.cookieConsents$.pipe(
              withLatestFrom(this.hasCookieConsentSubmitted$),
              filter(([, hasSubmitted]) => !hasSubmitted),
              map(([cg]) => ({
                ...cg,
                consents: cg.consents?.map((c) => ({
                  ...c,
                  consentStatus: type,
                })),
              })),
              map((cg: ConsentGroup) => this.createConsentData(cg)),
              finShare()
            )
          )
        )
        .subscribe((consentData: ConsentData) => {
          this.hasCookieConsentSubmitted$.next(true);
          this.consentService.setCookieConsents(consentData);
          this.closeModal();
        })
    );
  }

  ngOnDestroy(): void {
    unsubscribe(this.subscription);
  }

  allConsentsAreSet(consentGroup: ConsentGroup): boolean {
    return consentGroup?.consents?.every((c) => c.consentStatus !== ConsentStatus.WITHDRAWN);
  }

  confirmAndClose(consentData?: ConsentData): void {
    this.consentService.setCookieConsents(consentData);
    this.hasCookieConsentSubmitted$.next(true);
    this.closeModal();
  }

  showCookieSettings(): void {
    this.isShowCookieSettings = true;
  }

  openModal(event: MouseEvent): void {
    event.stopPropagation();
    this.modalOpen = true;
  }

  closeModal(): void {
    this.modalOpen = false;
  }

  private createConsentData(consentGroup: ConsentGroup): ConsentData {
    return consentGroup?.consents.reduce((all, consent) => {
      all[consent.consentTextId] = consent.consentStatus !== ConsentStatus.DECLINED;
      return all;
    }, {});
  }
}
