import {
  Component,
  forwardRef,
  ChangeDetectionStrategy,
  Injector,
  ChangeDetectorRef,
  Directive,
  Attribute,
  HostBinding,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, CheckboxRequiredValidator } from '@angular/forms';

import { SvgLibraryIcon } from '@finnairoyj/fcom-ui-styles/enums';

import { AbstractCheckboxComponent } from '../abstracts/abstract-checkbox.component';

@Component({
  selector: 'fcom-block-selection',
  templateUrl: './block-selection.component.html',
  styleUrls: ['./block-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BlockSelectionComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlockSelectionComponent extends AbstractCheckboxComponent {
  readonly SvgLibraryIcon = SvgLibraryIcon;

  @HostBinding('attr.tabindex')
  hostTabindex = null;

  @HostBinding('class.block-selection-selected')
  get checkedState(): boolean {
    return this.checked;
  }

  @HostBinding('class.block-selection-focused')
  get focusedState(): boolean {
    return this.focused;
  }

  @HostBinding('class.block-selection-disabled')
  get disabledState(): boolean {
    return this.disabled;
  }

  tabIndex: number;

  constructor(cdRef: ChangeDetectorRef, injector: Injector, @Attribute('tabindex') tabIndex: string) {
    super(cdRef, injector);
    this.tabIndex = parseInt(tabIndex, 10) || 0;
  }
}

/**
 * Validator for checkbox's required attribute in template-driven checkbox.
 * Current CheckboxRequiredValidator only work with `input type=checkbox` and does not
 * work with `fcom-block-selection`.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: `fcom-block-selection[required][formControlName],
             fcom-block-selection[required][formControl], fcom-block-selection[required][ngModel]`,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BlockSelectionRequiredValidator),
      multi: true,
    },
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: { '[attr.required]': 'required ? "" : null' },
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class BlockSelectionRequiredValidator extends CheckboxRequiredValidator {}
