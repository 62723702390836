<div class="relative ps-xxsmall-b" [class.ps-medium-t]="floatLabel">
  <label class="font-body-1 light-type grey-600-text" [for]="cid">
    <span class="is-vishidden" *ngIf="!!hiddenLabel">{{ hiddenLabel }}</span>
    {{ label }}
    <span *ngIf="required">*</span>
  </label>
  <div class="flex">
    <input
      class="font-body-1 light-type"
      [id]="cid"
      [type]="type"
      [placeholder]="placeholder"
      [readonly]="readonly"
      [disabled]="disabled"
      [required]="required"
      [attr.placeholder]="placeholder"
      (blur)="blurEvent()"
      (focus)="focusEvent()"
      [(ngModel)]="value"
      [attr.pattern]="inputPattern"
      [attr.autocomplete]="autocomplete"
      [attr.aria-required]="required"
      [attr.name]="name"
      [tabIndex]="tabIndex"
      [ngClass]="{ 'form-field-empty': empty, sensitive: sensitive, simple: simple, 'with-icon': iconName }"
    />
    <ng-template [ngIf]="iconName">
      <div class="icon-container">
        <ng-template #contentTooltip>
          <h2 class="font-body-1 bold-type nordic-blue-900-text">{{ tooltipHeading }}</h2>
          <p class="pr-xxsmall-t">{{ tooltipContent }}</p>
        </ng-template>
        <button
          type="button"
          fcomPopover
          [options]="tooltipOptions"
          [content]="contentTooltip"
          [ariaCloseText]="'actions.close' | finLocalization"
          [attr.aria-label]="iconAriaLabel"
          class="icon nordic-blue-900-text align-left"
          (click)="emitClickedIcon($event)"
        >
          <fcom-icon [name]="iconName" class="icon-medium"></fcom-icon>
        </button>
      </div>
    </ng-template>
    <span class="divider"></span>
  </div>
</div>
<div class="flex flex--space-between flex--wrap flex--row-reverse">
  <span *ngIf="instruction" class="align-right font-body-2 nordic-blue-900-text flex-1 ps-large-l">
    {{ instruction }}
  </span>

  <div *ngIf="!hideError && errorState" class="error-label font-body-2 flex-1 light-type" aria-live="polite">
    <span *ngIf="printError" class="error-900-text">
      {{ printError }}
    </span>
  </div>
</div>
