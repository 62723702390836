import { ROUTER_NAVIGATED } from '@ngrx/router-store';

import { DataUtils, deleteIn, setIn } from '@fcom/core/utils';
import { VOLUNTARY_CHANGE_STEPS } from '@fcom/voluntary-change/voluntary-change-config';

import { OrderPartStatus, OrderState, OrderStatus, UpdatingOrderPart } from '../store.interface';
import { OrderActions } from '../actions';
import {
  isChangeFlowStep,
  isInBookingPurchase,
  isInMmb,
  isMmbStep,
  isTravelReady,
} from '../../utils/common-booking.utils';
import { MANAGE_BOOKING_STEPS } from '../../manage-booking-config';

export const initialState: OrderState = Object.seal({
  orderStatus: OrderStatus.INITIAL,
});

// Extra type safety to make sure assigned constants are valid keys
type OrderStateKey<T> = T extends keyof OrderState ? T : never;

const ORDER_STATUS: OrderStateKey<'orderStatus'> = 'orderStatus';
const ORDER_RESPONSE_ERROR_STATUS: OrderStateKey<'orderResponseErrorStatus'> = 'orderResponseErrorStatus';
const ORDER_DATA: OrderStateKey<'orderData'> = 'orderData';
const ORDER_FETCH_INFORMATION: OrderStateKey<'orderFetchInformation'> = 'orderFetchInformation';
const PNR: OrderStateKey<'PNR'> = 'PNR';
const IS_UPDATING: OrderStateKey<'isUpdating'> = 'isUpdating';

const setOrderPartUpdateStatus = (state: OrderState, newStatus: OrderPartStatus, partIds: string[] = []) =>
  DataUtils.wrap(state)
    .updateIn(IS_UPDATING, (updatingState: UpdatingOrderPart = {}) =>
      partIds.reduce((acc: UpdatingOrderPart, category: string) => setIn(acc, `${category}`, newStatus), updatingState)
    )
    .value();
const ACCEPT_TERMS: OrderStateKey<'acceptTerms'> = 'acceptTerms';
export function orderReducer(state = initialState, action: OrderActions.ActionsUnion): OrderState {
  switch (action.type) {
    case OrderActions.setOrderData.type:
      return DataUtils.wrap(state)
        .setIn(ORDER_DATA, action.order)
        .setIn(ORDER_STATUS, OrderStatus.OK)
        .deleteIn(ACCEPT_TERMS)
        .value();

    case OrderActions.reset.type:
      return initialState;

    case OrderActions.loadStart.type:
      return setIn(state, ORDER_STATUS, OrderStatus.PENDING);

    case OrderActions.loadError.type:
      return DataUtils.wrap(state)
        .setIn(ORDER_STATUS, OrderStatus.LOAD_ERROR)
        .setIn(ORDER_RESPONSE_ERROR_STATUS, action.status)
        .deleteIn(ORDER_DATA)
        .value();
    case OrderActions.setOrderFetchInformation.type:
      return setIn(state, ORDER_FETCH_INFORMATION, action.orderInformation);
    case OrderActions.setPNR.type:
      return setIn(state, PNR, action.PNR);
    case OrderActions.clearPNR.type:
      return deleteIn(state, PNR);
    case OrderActions.setCategoryUpdating.type:
      return setOrderPartUpdateStatus(
        state,
        OrderPartStatus.UPDATING,
        Array.isArray(action.orderPartIds) ? action.orderPartIds : [action.orderPartIds]
      );
    case OrderActions.resetCategoriesUpdating.type:
      return deleteIn(state, IS_UPDATING);
    case OrderActions.clearCategoryUpdating.type:
      return setOrderPartUpdateStatus(
        state,
        OrderPartStatus.OK,
        Array.isArray(action.orderPartIds) ? action.orderPartIds : [action.orderPartIds]
      );

    // Navigate outside confirmation page
    case ROUTER_NAVIGATED: {
      if (isInMmb(action.payload.routerState.url) || isTravelReady(action.payload.routerState.url)) {
        if (
          !isMmbStep(action.payload.routerState.url, MANAGE_BOOKING_STEPS.CONFIRMATION) &&
          !isMmbStep(action.payload.routerState.url, MANAGE_BOOKING_STEPS.CHECKOUT) &&
          !isChangeFlowStep(action.payload.routerState.url, VOLUNTARY_CHANGE_STEPS.PURCHASE_REVIEW)
        ) {
          return DataUtils.wrap(state).deleteIn(ORDER_FETCH_INFORMATION).value();
        }
        return state;
      }
      if (isInBookingPurchase(action.payload.routerState.url)) {
        return state;
      }
      return DataUtils.wrap(state)
        .setIn(ORDER_STATUS, OrderStatus.INITIAL)
        .deleteIn(ORDER_RESPONSE_ERROR_STATUS)
        .deleteIn(ORDER_DATA)
        .deleteIn(ORDER_FETCH_INFORMATION)
        .deleteIn(PNR)
        .deleteIn(ACCEPT_TERMS)
        .value();
    }
    case OrderActions.acceptTerms.type:
      return setIn(state, ACCEPT_TERMS, action.accept);
    default:
      return state;
  }
}
