<header *ngIf="boundType" class="pr-xsmall-x">
  <h3 class="font-heading-4 medium-type pr-xsmall-t">
    <ng-container *ngIf="boundType === BoundType.outbound">
      {{ 'itinerary.departure' | finLocalization }}
    </ng-container>
    <ng-container *ngIf="boundType === BoundType.inbound">
      {{ 'itinerary.return' | finLocalization }}
    </ng-container>
    <ng-container *ngIf="boundType === BoundType.multi">
      {{ title }}
    </ng-container>
    <span *ngIf="departureChanges?.dateTime" class="grey-700-text strike-through mr-xxsmall-r">{{
      departureChanges.dateTime | finDateFormat: 'FULL'
    }}</span>
    <span class="nordic-blue-900-text regular-type">{{
      (bound$ | async).departure.dateTime | finDateFormat: 'FULL'
    }}</span>
  </h3>
  <p class="font-body-1">
    <span *ngIf="departureChanges?.location" class="grey-700-text strike-through mr-xxsmall-r">{{
      departureChanges.locationCity
    }}</span>
    {{ departureCity$ | async }}
    –
    <span *ngIf="arrivalChanges?.location" class="grey-700-text strike-through mr-xxsmall-r">{{
      arrivalChanges.locationCity
    }}</span>
    {{ arrivalCity$ | async }}
  </p>
  <p class="font-body-1 nordic-blue-900-text">
    {{ 'itinerary.totalDuration' | finLocalization: { duration: '' } }}
    <span *ngIf="boundChangeDuration" class="grey-700-text strike-through mr-xxsmall-r">
      {{ boundChangeDuration | finDuration | async }}
    </span>
    {{ (bound$ | async).duration | finDuration | async }}
  </p>
</header>
<div
  *ngIf="departureChanges?.notification"
  class="timeline__right medium-type heather-700-text pr-medium-y pr-xxsmall-x"
>
  <fcom-notification
    *ngIf="departureChanges.notification"
    [theme]="NotificationTheme.WARNING"
    [layout]="NotificationLayout.BLOCK"
    [iconName]="SvgLibraryIcon.INFO"
    class="block"
  >
    {{ 'MMB.notifications.disruption.' + departureChanges.notification + '.text' | finLocalization }}
  </fcom-notification>
</div>
<div *ngIf="showNoChangesNotification" class="timeline__right medium-type heather-700-text pr-medium-y pr-xxsmall-x">
  <fcom-notification
    [theme]="NotificationTheme.INFO"
    [layout]="NotificationLayout.BLOCK"
    [iconName]="SvgLibraryIcon.INFO"
    class="block"
  >
    {{ 'MMB.reaccommodation.notes.NO_CHANGES' | finLocalization }}
  </fcom-notification>
</div>
