import { Component } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { combineLatest, EMPTY, map, NEVER, Observable } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, take } from 'rxjs/operators';

import { AppState, ConfigService } from '@fcom/core';
import { LanguageService } from '@fcom/ui-translate';
import { CmsDataService } from '@fcom/core/services';
import { CmsCollection } from '@fcom/core-api';
import { getViewedNotifications } from '@fcom/common/store';

@Component({
  selector: 'fin-cms-global-disruption-banner',
  templateUrl: './cms-global-disruption-banner.component.html',
})
export class CmsGlobalDisruptionBannerComponent {
  collection$: Observable<CmsCollection> = EMPTY;
  notificationNumbers$: Observable<number> = EMPTY;
  enableGlobalBanner: boolean = false;

  constructor(
    private languageService: LanguageService,
    private cmsDataService: CmsDataService,
    private configService: ConfigService,
    private store$: Store<AppState>
  ) {
    this.enableGlobalBanner = this.configService.cfg.enableGlobalBanner;

    if (this.enableGlobalBanner) {
      this.collection$ = this.languageService.translate('fragments.globalDisturbanceBanner.url').pipe(
        filter(Boolean),
        distinctUntilChanged(),
        switchMap((url) => (url ? this.cmsDataService.getFragmentJson(url) : NEVER)),
        take(1)
      ) as Observable<CmsCollection>;

      this.notificationNumbers$ = combineLatest([
        this.store$.pipe(select(getViewedNotifications)),
        this.collection$,
      ]).pipe(
        map(
          ([viewedNotificationsIds, collection]) =>
            collection.items.filter(({ contentId }) => !viewedNotificationsIds.includes(contentId)).length
        )
      );
    }
  }
}
