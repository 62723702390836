import { Route } from '@angular/router';

import { createAction, props, union } from '@ngrx/store';

export const confirmation = createAction('[GlobalActions] Confirmation', props<{ route?: Route }>());
export const paymentVerified = createAction('[GlobalActions] Payment verified');
export const setPaymentIdPaid = createAction(
  '[GlobalActions] Payment setPaymentIdPaid',
  props<{ paymentId: string }>()
);
export const paymentNotPaid = createAction('[GlobalActions] Payment not paid');

const all = union({ confirmation, paymentVerified, paymentNotPaid });

export type ActionsUnion = typeof all;
