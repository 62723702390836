import { createAction, union, props } from '@ngrx/store';

import { LatLng } from '../interfaces';

export const setAkamaiGeolocation = createAction('NavigatorActions/SetAkamai', props<{ location: LatLng }>());
export const setBrowserGeolocation = createAction('NavigatorActions/SetBrowser', props<{ location: LatLng }>());
export const setBrowserGeolocationLoading = createAction('NavigatorActions/SetBrowserLoading');
export const removeBrowserGeolocationLoading = createAction('NavigatorActions/RemoveBrowserLoading');
export const setServerTime = createAction(
  'NavigatorActions/SetServerTime',
  props<{ serverMillisFromEpoch: number; timeOffset: number }>()
);

const all = union({
  setAkamaiGeolocation,
  setBrowserGeolocation,
  setBrowserGeolocationLoading,
  removeBrowserGeolocationLoading,
  setServerTime,
});
export type NavigatorActionsUnion = typeof all;
