import { InjectionToken } from '@angular/core';

export const RESPONSE_CODE_HANDLER: InjectionToken<ResponseCodeHandler> = new InjectionToken('responseCodeHandler');

export abstract class ResponseCodeHandler {
  /**
   * Trigger setting the Universal application's response status
   * @param setterRef a human-readable reference for
   * @param httpStatusCode the status code for universal render
   * @param templateUrl the url we are returning
   * @param headers a key-value dictionary of response headers to set
   */
  abstract setUniversalResponseStatus(
    setterRef: string,
    httpStatusCode: number,
    templateUrl?: string,
    headers?: { [headerName: string]: string }
  ): void;
}
