export enum AspectRatios {
  'ORIGINAL' = 'original',
  '1x1' = 'portrait_ratio1x1',
  '3x4' = 'portrait_ratio3x4',
  '4x3' = 'landscape_ratio4x3',
  '16x9' = 'landscape_ratio16x9',
  '21x9' = 'landscape_ratio21x9',
}

export enum FetchPriorityEnum {
  'low' = 'low',
  'high' = 'high',
  'auto' = 'auto',
}
